import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';
import { toast } from 'react-toastify';

class Customer extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                  =   {
            searchedCustomer              :   this.props.searchedCustomer, 
            labelDisable                :   props?.labelDisable ? props?.labelDisable : false, 
            customer_listing            :   [],
            listing_loading             :   false,
            listingMeta                 :   null,
            listing_tbl_page            :   1,
            totalListingCount           :   0,
            selectedBillingAddress      :   null,
            selectedShippingAddress     :   null,
            addressSource               :  'customer',
            tempSelectedShippingAddress : null,
            tempSelectedBillingAddress  : null
        };
       
        this.customerURL                =   INVENTORY_API_BASE_URL_2 + '/customer_project/list';
    }
    
    componentDidMount(){
        const source = this.state.addressSource
        if(this.props.selectedProject){
            this.setState({selectedProject : this.props.selectedProject,tempSelectedProject:this.props.selectedProject})
        }
        if (this.props.selectedBillingAddress) {
            this.setState({
                selectedBillingAddress: { ...this.props.selectedBillingAddress, source },
                tempSelectedBillingAddress: { ...this.props.selectedBillingAddress, source }
            });
        }
        if (this.props.selectedShippingAddress) {
            this.setState({
                selectedShippingAddress: { ...this.props.selectedShippingAddress, source },
                tempSelectedShippingAddress: { ...this.props.selectedShippingAddress, source }
            });
        }
        this.addCustomerModal                 =   new Modal(document.getElementById('addCustomerModal'), {keyboard: false, backdrop :false});
        this.quotationListingModal                =   new Modal(document.getElementById('quotationListingModal'), {keyboard: false, backdrop :false});
        this.CustomerDetailsModal             =   new Modal(document.getElementById('CustomerDetailsModal'), {keyboard: false, backdrop :false});
        this.CustomerAddressModal             =   new Modal(document.getElementById('CustomerAddressModal'), {keyboard: false, backdrop :false});
        this.billingAddressModal             =   new Modal(document.getElementById('billingAddressModal'), {keyboard: false, backdrop :false});
        this.billingAddressModal             =   new Modal(document.getElementById('billingAddressModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        const source = this.state.addressSource
        if(nextProps.searchedCustomer !== this.props.searchedCustomer) {
           this.setState({
               searchedCustomer : nextProps.searchedCustomer
           })
        }
        if(nextProps.selectedProject !== this.props.selectedProject) {
            this.setState({
                selectedProject : nextProps.selectedProject,
                tempSelectedProject:nextProps.selectedProject
            })
         }
         if (nextProps.selectedBillingAddress !== this.props.selectedBillingAddress) {
            this.setState({
                selectedBillingAddress: { ...nextProps.selectedBillingAddress, source },
                tempSelectedBillingAddress: { ...nextProps.selectedBillingAddress, source }
            });
        }
        if (nextProps.selectedShippingAddress !== this.props.selectedShippingAddress) {
            this.setState({
                selectedShippingAddress: { ...nextProps.selectedShippingAddress, source },
                tempSelectedShippingAddress: { ...nextProps.selectedShippingAddress, source }
            });
        }
        
    }

    viewCustomerDetailsInit                   =   ()  =>  {
        this.CustomerDetailsModal.show()
        this.props.getCustomerDetails()
    }

    addCustomerModalInit                      =   ()  =>  {
        if(this.addCustomerModal) {
            this.addCustomerModal.show();
        }
    }
    
    viewCustomerAddressInit                   =   ()  =>  {
        this.CustomerAddressModal.show()
        this.props.getCustomerDetails()
    }

    viewCustomerAddressModalClose                     =   ()  =>  {
        this.CustomerAddressModal.hide()
    }

    //*********************************Customer DETAIL JSX*************************
    viewCustomerDetailsModalJsx              =   ()  =>  {
        let  CustomerDetails = this.props.CustomerDetails
        return (
            <div className="modal fade" id="CustomerDetailsModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="CustomerDetailsModalLabel">View Customer</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {!this.props.details_loading ?
                            CustomerDetails ? <div className="modal-body">
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Details</span>
                                </div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Customer Name</td>
                                            <th style={{ "width": "66%" }}>{CustomerDetails.name ? CustomerDetails.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Code</td>
                                            <th style={{ "width": "66%" }}>{CustomerDetails.code ? CustomerDetails.code : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Status</td>
                                            <th style={{ "width": "66%" }}>{CustomerDetails.status ? <Status color={CustomerDetails.status_color}>{CustomerDetails.status_text}</Status> : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Notes</td>
                                            <th style={{ "width": "66%" }}>{CustomerDetails.notes ? CustomerDetails.notes : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper align-items-center">
                                    <span className="content_heading">Contact Details </span>
                                </div>
                                <table className="table table-hover table-bordered my-3 table-sm">
                                    <thead className="table-secondary" >
                                        <tr>
                                            <th style={{ width: "5%" }} className='text-center'>S.No</th>
                                            <th style={{ width: "25%" }}>Name</th>
                                            <th style={{ width: "30%" }}>Email ID</th>
                                            <th style={{ width: "20%" }} className='text-center'>Mobile Number</th>
                                            <th className='text-center' style={{ width: "15%" }}>Primary Contact</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {CustomerDetails && CustomerDetails.contacts && CustomerDetails.contacts.length > 0
                                            ? CustomerDetails.contacts.map((c, i) => {
                                                return (<tr>
                                                    <td className='text-center'>{i + 1}</td>
                                                    <td>{c.name}</td>
                                                    <td> {c.email ? c.email : '-'}</td>
                                                    <td className='text-center'> {c.mobile ? c.mobile : '-'}</td>
                                                    <td className='text-center'> {c.is_primary == "Y" ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} /> : ""}</td>
                                                </tr>)
                                            })
                                            : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                                    </tbody>
                                </table>
                               
                            </div> : null
                            : <Loader />}
                    </div>
                </div>
            </div>
        );
    }

    viewBillingAddressInit      =       ()      =>  {
        this.billingAddressModal.show()
        this.props.getCustomerDetails() 
    }

    viewShippingAddressInit      =       ()      =>  {
        this.billingAddressModal.show()
        this.props.getCustomerDetails() 
    }

    selectShippingAddress = (address) => {
        this.setState({ selectedShippingAddress: address }, () => {
          if (this.props.onAddressSelect) {
            this.props.onAddressSelect({
              shipping: this.state.selectedShippingAddress,
              billing: this.state.selectedBillingAddress,
            });
          }
        });
      };
    
      // Method to handle setting the selected billing address
      selectBillingAddress = (address) => {
        this.setState({ selectedBillingAddress: address }, () => {
          if (this.props.onAddressSelect) {
            this.props.onAddressSelect({
              shipping: this.state.selectedShippingAddress,
              billing: this.state.selectedBillingAddress,
            });
          }
        });
      };
   
    //*********************************Customer ADDRESS JSX*************************
    viewCustomerAddressModalJsx               =   ()  =>  {
        return (
            <div className="modal fade" id="CustomerAddressModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="CustomerDetailsModalLabel">Customer Address Lists</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {!this.props.Customer_details_loading ?
                            this.props.CustomerAddressList ? <div className="modal-body">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                        {this.props.CustomerAddressList.length > 0 ? this.props.CustomerAddressList.map((a, i) => {
                                            return (<tr>

                                                <td style={{ width: "80%" }}>
                                                    <div className='py-1'>Address : <b>{a.address1 ? a.address1 + ", " : ""}
                                                        {a.address2 ? a.address2 + ", " : ""}
                                                        {a.landmark ? a.landmark + ", " : ""}
                                                        {a.city ? a.city + ". " : "-"}</b></div>
                                                    <div className='py-1'>District, State & Pincode : <b>{a.district_name ? a.district_name + ", " : "- , "}
                                                        {a.state_name ? a.state_name : " - "}
                                                        {a.pincode ? " & " + a.pincode : ""}</b></div>
                                                    <div className='py-1'>GSTIN : <b>{a.gstin ? a.gstin : "- "}</b></div>
                                                </td>
                                                <td className='text-center' style={{ width: "10%" }}>
                                                    {this.props.selectedCustomerAdress && (a.id == this.props.selectedCustomerAdress.id) ?
                                                        <button type="button" className='btn btn-secondary'>Selected</button>
                                                        : <button type='button' className='btn btn-primary' onClick={() => { this.props.changeAddressHandler(a.id); this.viewCustomerAddressModalClose() }}>Select</button>}
                                                </td>
                                            </tr>)
                                        })
                                            : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                                    </tbody>
                                </table>
                            </div> : null
                            : <Loader />}
                    </div>
                </div>
            </div>
        );
    }


    handleSelectShippingAddress = (address) => {
        this.setState({ tempSelectedShippingAddress: { ...address, source: this.state.addressSource } });
        //this.selectShippingAddress({ ...address, source: this.state.addressSource })
    };

    handleSelectBillingAddress = (address) => {
        this.setState({ tempSelectedBillingAddress: { ...address, source: this.state.addressSource } });
        //this.selectBillingAddress({ ...address, source: this.state.addressSource })
    };

    
    viewBillingAddressModalJsx = () => {
        const isCustomerSource = this.state.addressSource === 'customer';
    
        // Function to handle Ship To selection

        let CustomerAddressList      =   this.props.CustomerAddressList || [] ;
      
        return (
            <div className="modal fade" id="billingAddressModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="billingAddressModal">Select Address</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* Radio buttons to select Project or Customer */}
                            <div className="mb-3">
                                <label className="form-check-label me-3">Choose Address Source:</label>
                                <input
                                    type="radio"
                                    name="addressSource"
                                    value="project"
                                    checked={this.state.addressSource === 'project'}
                                    onChange={() => {
                                        this.setState({ addressSource: 'project' },
                                        () => !this.state.listingLoaded ? this.loadListingTblData(1) : null);
                                    }}
                                /> Project
                                <input
                                    type="radio"
                                    name="addressSource"
                                    value="customer"
                                    checked={this.state.addressSource === 'customer'}
                                    onChange={() => {
                                        this.setState({ addressSource: 'customer' });
                                    }}
                                    className="ms-3"
                                /> Customer
                            </div>
                            {this.state.addressSource == 'project' && <Ax>
                                     <table className="table table-bordered table-fixed bg-white table-sm">
                                 <thead className="table-secondary">
                                     <tr className="text-center">
                                         <th scope="col" style={{ width: "5%" }}>Select</th>
                                         <th scope="col" style={{ width: "17%" }}>Project Name</th>
                                         <th scope="col" style={{ width: "17%" }}>Project Code</th>
                                        <th scope="col">Location</th>
                                     </tr>
                                 </thead>
                                <tbody>
                                     {this.state.listing_loading
                                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                        : (this.state.customer_listing.length > 0
                                            ? (this.state.customer_listing.map((customer, index) => {
                                                return (
                                                    <tr key={customer.id}>
                                                        <td className="text-center">
                                                            <input
                                                                type="radio"
                                                                name="selectedProject"
                                                                value={customer.id}
                                                                checked={this.state.tempSelectedProject === customer.id}
                                                                onChange={() => this.setState({ tempSelectedProject: customer.id })}
                                                            />
                                                        </td>
                                                        <td>{customer.project_name}</td>
                                                        <td>{customer.project_code ? customer.project_code : ''}</td>
                                                       <td>{customer.address  ? this.formatAddress(customer.address) : ''}</td>
                                                    </tr>
                                                );
                                            }))
                                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                        )
                                    }
                                </tbody>
                            </table>
                            <DisplayListPagination
                                meta={this.state.listingMeta}
                                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                            />
                                    </Ax>}
    
                            {/* Show Customer Address List if Source is Customer */}
                            {isCustomerSource && <table className="table table-hover table-bordered">
                                <thead className="table-secondary">
                                    <tr>
                                        <th>Address</th>
                                        <th className="text-center">Ship To</th>
                                        <th className="text-center">Bill To</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CustomerAddressList.length > 0 ? (
                                        CustomerAddressList.map((address, index) => (
                                            <tr key={index}>
                                                <td style={{ width: "60%" }}>
                                                    {this.formatAddress(address, false)}
                                                </td>
                                                <td className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.tempSelectedShippingAddress?.id === address.id}
                                                    onChange={() => this.handleSelectShippingAddress({ ...address, source: 'customer' })}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.tempSelectedBillingAddress?.id === address.id}
                                                    onChange={() => this.handleSelectBillingAddress({ ...address, source: 'customer' })}
                                                />
                                            </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">No Customer Addresses Available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>}
                        </div>
    
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => this.handleCancel('cancel')}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.handleSelectedProject()}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    
    

    addCustomerModalInit          =       ()           =>      {
        this.props.CustomerRef.current.customerAddModalInit();  
    }

    editCustomerModalInit          =       ()           =>      {
        this.props.CustomerRef.current.customerAddModalInit(this.props.CustomerDetails);  
    }

    quotationModalInit                   =   ()  =>  {
        this.quotationListingModal.show();
        //if(!this.state.listingLoaded){
           this.loadListingTblData(1)
        //}
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,search_customer_ids : this.props.CustomerDetails ? [this.props.CustomerDetails.id] : []};
        HttpAPICall.withAthorization('GET',  this.customerURL, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                customer_listing    : respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount   : respData.total,
                listingLoaded       :   true
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    handleSelectedProject() {
        if(this.state.addressSource == 'customer'){
            const { selectedShippingAddress, selectedBillingAddress } = this.state;

        // Check if both addresses are from the same source
        if (
            selectedShippingAddress &&
            selectedBillingAddress &&
            selectedShippingAddress.source !== selectedBillingAddress.source
        ) {
            toast.error("Both Ship To and Bill To addresses must be from the same source.", {position: toast.POSITION.TOP_RIGHT});
            return;
        }else{
            this.setState({
                selectedShippingAddress: this.state.tempSelectedShippingAddress,
                selectedBillingAddress: this.state.tempSelectedBillingAddress
            },() => {
                if (this.props.onAddressSelect) {
                    this.props.onAddressSelect({
                      shipping: this.state.selectedShippingAddress,
                      billing: this.state.selectedBillingAddress,
                    });
                  }
            });
            
        }
        this.billingAddressModal.hide();
        }
        if (this.state.tempSelectedProject) {
            this.setState({ 
                addressSource   : "project",
                
                selectedProject: this.state.customer_listing.find(ct => {return(ct.id == this.state.tempSelectedProject)}) }, () => {
              if(this.props.onSelectProject){
                this.props.onSelectProject(this.state.selectedProject);  
              }
              this.quotationListingModal.hide();
              this.billingAddressModal.hide();
            });
        } else {
            
        }
    }

    handleCancel(type='cancel') {
        if(type !== 'cancel'){
            this.setState({ tempSelectedProject: null,selectedProject : null,addressSource:'customer' },() => {
                if(this.props.onSelectProject){
                    this.props.onSelectProject(null);  
                  }
            });
        }else{
            this.setState({ tempSelectedProject: this.state.tempSelectedProject ? this.state.tempSelectedProject : null})
        }
    }
    
    

    viewProjectListingModalJsx               =   ()  =>  {
        return (
            <div className="modal fade" id="quotationListingModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="CustomerDetailsModalLabel">Customer Project List</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-bordered table-fixed bg-white table-sm">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }}>Select</th>
                                        <th scope="col" style={{ width: "14%" }}>Project Type</th>
                                        <th scope="col" style={{ width: "17%" }}>Project Name</th>
                                        <th scope="col" style={{ width: "17%" }}>Project Code</th>
                                        <th scope="col" style={{ width: "17%" }}>Customer</th>
                                        <th scope="col" style={{ width: "17%" }}>Location</th>
                                        <th scope="col" style={{ width: "8%" }} className='text-center'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listing_loading
                                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                        : (this.state.customer_listing.length > 0
                                            ? (this.state.customer_listing.map((customer, index) => {
                                                return (
                                                    <tr key={customer.id}>
                                                        <td className="text-center">
                                                            <input
                                                                type="radio"
                                                                name="selectedProject"
                                                                value={customer.id}
                                                                checked={this.state.tempSelectedProject === customer.id}
                                                                onChange={() => this.setState({ tempSelectedProject: customer.id })}
                                                            />
                                                        </td>
                                                        <td>{customer.project_type ? customer.project_type.name : ''}</td>
                                                        <td>{customer.project_name}</td>
                                                        <td>{customer.project_code ? customer.project_code : ''}</td>
                                                        <td>{customer.customer ? customer.customer.name : ''}</td>
                                                        <td>{customer.address && customer.address.state_name ? customer.address.state_name : ''}</td>
                                                        <td>{customer.status ? customer.status.name : ''}</td>
                                                    </tr>
                                                );
                                            }))
                                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                        )
                                    }
                                </tbody>
                            </table>
                            <DisplayListPagination
                                meta={this.state.listingMeta}
                                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => this.handleCancel('cancel')}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.handleSelectedProject()}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    formatAddress(address,showProject=true ) {
        return <>
            {address.address1 ? address.address1 + ", " : ""}  {address.address2 ? address.address2 + ", " : ""}
            {address.landmark ? address.landmark + ", " : ""} {address.city ? address.city + ". " : ""}<br />
            Location : <b>{address.state_name ? address.state_name + ", " : ""}  {address.district_name ? address.district_name + "& " : ""}  {address.pincode ? address.pincode + "." : ""} <br /></b>
            GSTIN   :   <b>{address.gstin ? address.gstin : "-"}</b>
        </>
    }
    
    render() {

        let  disabledPerformAction      =   this.props.disabledPerformAction || false;
       
        return (<Ax>
            <div className={`row align-items-center ${this.state.labelDisable ? "" : "my-3"}`}>
                {
                    this.state.labelDisable 
                    ? 
                    ''
                    :
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Customer</label>
                    </div>
                }
                <div className={`${this.state.labelDisable ? "col-sm-12" : "col-sm-6"}`}>
                    <InputCustomerSearch
                        required={true}
                        placeholder="Search Customer"
                        changeEvent={(selectedOption) => {
                            this.props.onChange(selectedOption);
                            this.setState({
                                listingLoaded   : false,
                                searchedCustomer: selectedOption ? selectedOption : null,
                            }, () => this.props.getCustomerDetails(selectedOption ? selectedOption.value : selectedOption));
                        }}
                        disabled={this.props.disabled}
                        only_active="Y"
                        value={this.state.searchedCustomer ? { ...this.state.searchedCustomer, label: this.state.searchedCustomer.display_label } : null}
                    />
                </div>
                {!disabledPerformAction  && !this.state.labelDisable && <div className="col-sm-3">
                    {this.state.searchedCustomer ?
                        <Ax>
                            <button type="button" className='btn btn-primary pl0 pr0 ' style={{ height: "38px" }} onClick={this.viewCustomerDetailsInit}>
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEye} className='img-fluid' />
                            </button>
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary mx-2 pl0 pr0" style={{ height: "38px" }}>
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end cursor_pointer" aria-labelledby="dropdownMenuButton1">
                            {(!this.state.selectedProject && this.props.handleShipToAddress !== true)  &&  <li><a className="dropdown-item" onClick={this.viewCustomerAddressInit}>Change Address</a></li>}
                            {this.props.handleShipToAddress && <Ax>
                                <li><a className="dropdown-item" onClick={this.viewBillingAddressInit}>Change  Address</a></li>
                                </Ax>}
                            <li><a className="dropdown-item" onClick={this.editCustomerModalInit}>Edit Customer</a></li>
                            </ul>
                        </Ax>
                        : null}
                    {!this.props.disabled && <a role="button" onClick={this.addCustomerModalInit} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Customer</a>}
                    
                </div>}

                {this.viewCustomerAddressModalJsx()}
                {this.viewCustomerDetailsModalJsx()}
                {this.viewBillingAddressModalJsx()}
                {/* {this.viewShippingAddressModalJsx()} */}
            </div>
            {this.props.CustomerDetails ?
                <div className="row align-items-center mt1 pb-2 fs13">
                    {!this.props.Customer_details_loading ? (
                        (this.props.selectedCustomerAdress || this.state.selectedProject) && (
                            <Ax>
                                <div className={`${this.state.labelDisable ? "" : "offset-3"} col-sm-8`}>
                                    
                                    {this.props.handleShipToAddress ? <div className='row align-items-center'>
                                        <div className='col-sm-4'>
                                            
                                            <div className='mb-2'>
                                                <b>Bill To Address</b>
                                            </div>
                                           {this.state.selectedProject && <label className="form-label">
                                        Project: {this.state.selectedProject ? (
                                            <span>
                                                {this.state.selectedProject.project_name} ({this.state.selectedProject.project_code})
                                            </span>
                                        ) : (null)}
                                    </label>}
                                            
                                            <label className="form-label">
                                                Address: {this.state.selectedBillingAddress ? this.formatAddress(this.state.selectedBillingAddress) : "-"}
                                            </label>
                                        </div>
                                        <div className='col-sm-4'>
                                           
                                            <div className='mb-2'>
                                                <b>Ship To Address</b>
                                            </div>
                                            {this.state.selectedProject && <label className="form-label">
                                        Project: {this.state.selectedProject ? (
                                            <span>
                                                {this.state.selectedProject.project_name} ({this.state.selectedProject.project_code})
                                            </span>
                                        ) : (null)}
                                    </label>}
                                           
                                            <label className="form-label">
                                                Address: {this.state.selectedShippingAddress ? this.formatAddress(this.state.selectedShippingAddress) : "-"}
                                            </label>
                                        </div>
                                    </div> : <Ax>
                                    <label className="form-label">
                                        Project: {this.state.selectedProject ? (
                                            <span>
                                                {this.state.selectedProject.project_name} ({this.state.selectedProject.project_code})
                                                &nbsp;&nbsp;
                                                {!this.props.disabled && <span className="link-primary cursor_pointer text-decoration-underline" onClick={this.quotationModalInit}>Change</span>}
                                                &nbsp;&nbsp;
                                                {!this.props.disabled && <span className="link-primary cursor_pointer text-decoration-underline" onClick={() => this.handleCancel('remove')}>Remove</span>}
                                            </span>
                                        ) : (
                                            !this.props.disabled && <span className="link-primary cursor_pointer" onClick={this.quotationModalInit}>Not Yet Added</span>
                                        )}
                                    </label>
                                    <br />
                                    <label className="form-label">
                                            Address: {this.state.selectedProject ? this.formatAddress(this.state.selectedProject.address) : this.formatAddress(this.props.selectedCustomerAdress)}
                                        </label>
                                    </Ax>
                                       }
                                    <br />
                                   
                                </div>
                            </Ax>
                        )
                    ) : <Loader />}
                   
                </div> : null}
                {this.viewProjectListingModalJsx()}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [] ,
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(Customer);