import React from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from "moment";
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import Ax from "../../../../../components/hoc/Ax";
import Loader from "../../../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../../../layouts/ApplicationLayout';
import tapIcon from '../../../../../services/TapIcon';
import AssetDetailHorizontalCard from '../../../includes/AssetDetailHorizontalCard';
import RunningStatusViewModal from './RunningStatusViewModal';
import LinkedItemsViewModal from './LinkedItemsViewModal';
import InputItemSearch from '../../../../includes/ui/InputItemSearch';
import TapSelect from '../../../../../components/ui/TapSelect';
import { DisplaySearchCriteria } from '../../../../../components/TapUi';
import download from '../../../../../services/DownloadFile';
import AssetHeader from '../../../includes/AssetHeader';
import { Helmet } from 'react-helmet';
import ItemDetailsModal from '../../../../items/itemDetails/ItemDetailsModal';
import ItemsConsumptionHistory from '../../../../inventory/consumption/ItemsConsumptionHistory';

class ServiceChecksheetRunningStatus extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.executeFormInit            =       {
          odometer                     :       '',
          operation_cycle               :       '',
          hourmeter                     :       '',
          date_time                     :       '',
          hourmeter2                    :       '' , 
          hourmeter3                    :       '' , 
          hourmeter4                    :       '' ,
          odometer2                    :       '',
          odometer3                    :       '',
          operation_cycle2              :       '',
        }

        this.initRunningStatusSearchForm      =       {
            search_item_tags                  :       [],
            search_item_ids                   :       [],
            search_name                       :       '',
            search_scs_running_status                     :       [],
        }

        this.runningStatusSearchFormLabel     =       {
            search_item_tags                :       'Item Tags : ',
            search_item_ids                 :       'Items : ', 
            search_name                        :       'Service Checksheet Name : ',
            search_scs_running_status                      :       'Status : '
        }


        this.state                       =       {
            access_token                :       '',
            assetDetail                 :       null,
            listing_loading             :       false,
            download_loading            :   false,
            listing_tbl_page            :       1,
            scs_listing                 :       [],
            listingMeta                 :       null,
            totalListingCount           :       0,
            linkItemRows                :       [{item : null}],
            searchedItem                :       [],
            current_page                :       1,
            scs_id                      :       null,
            scs_name                    :       '',
            scsRowKey                   :       0,
            assetId                     :       null,
            linkedItemFormSubmitting    :       false,
            asset_loading               :       false,
            linked_items                :       [],
            item_loading                :       false,
            scsRunningData              :       null,
            isScrolling                 :       false,
            linkedItemData              :       null,
            selectedAssetForExecution   :       [],
            allItemTags                 :       [],
            execution_loading           :       false,
            executeForm                 :       {...this.executeFormInit},
            executeFormSubmitting       :       false,
            runningStatusFilterForm     :       {...this.initRunningStatusSearchForm},
            submitRunningStatusForm     :       {...this.initRunningStatusSearchForm},
            formSearchedElems           :       [],
            parameterOptions            :       [{value : 'ok' , label : 'Checked and OK' },
                                                {value : 'required_attention' , label : 'May Required Attention' },
                                                {value : 'required_immediateattention' , label : 'Required Immediate Attention' }],
            allStatusOptions            :       [{value : 'over_due',label : 'OverDue'},{value : 'due_soon',label : 'Due-Soon'},{value : 'in_limit',label : 'Within Limits'}]
        }
        
        this.RunningStatusModalRef           =     React.createRef();  
        this.LinkedItemsViewModalRef         =    React.createRef(); 
        this.itemDetailsRef                  =    React.createRef(); 
        this.itemsConsumptionHistoryRef      =    React.createRef();  
    }
    
    componentDidMount           =   ()  =>  {
       this.initalizeData(this.props)    
       this.executeCounterlogModal            =   new Modal(document.getElementById('executeCounterlogModal'), {keyboard: false, backdrop :false});
       this.runningStatusSearchModal          =    new Modal(document.getElementById('runningStatusSearchModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        this.setState({assetId              :       pr.match?.params?.id,
                      allItemTags           :       pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],}
            ,() => {
                this.loadListingTblData(1);
                this.getAsssetDetails(pr.match?.params?.id)
            })
    }

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
            console.log(response.data.data)
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    //************************GET PO LISTING**************** */
    loadListingTblData                 =   (page = 1,merge_list = false)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
    
        let params                      =   {page:page,search_asset_ids: [this.state.assetId],get_asset_profile : "Y",...this.state.submitRunningStatusForm };
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/scs/running_status', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                scs_listing         :       merge_list == true ? resp.data.data : [...this.state.scs_listing, ...resp.data.data],
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                 totalListingCount   :       respData.total,
                current_page        :       respData.current_page,
        },() => {
             let all_scs_ids       =   this.state.scs_listing.map((scs,key) => scs.id);
             if(this.state.scs_listing && this.state.scs_listing.length > 0){
                this.getAllLinkedItemsList(all_scs_ids)
             }
        });
        }).then(() => this.setState({listing_loading: false}));
    }
    
    downloadScsList                     =   (filter='active')  =>  {
        this.setState({download_loading: true});
        let params                      =   {
            ...this.state.submitRunningStatusForm, 
            search_asset_ids                :   [this.state.assetId], 
            download                        :   'download',
            only_activated                  :   filter == 'active' ? 'Y' : 'N'
        };
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/scs/running_status', this.props.access_token, params, {}, (resp) => {
            download.file(resp.data.file_path);
        }).then(() => this.setState({download_loading: false}));
    }
    
    

    runningStausFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        
        let serachFormKeys                  =   Object.keys(this.state.runningStatusFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};

        serachFormKeys.map((key)        =>  {
            let label                       =   this.runningStatusSearchFormLabel[key];
            let value                       =   this.state.runningStatusFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                  
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'search_scs_running_status') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_name') {
                        show_val            =  this.state.runningStatusFilterForm.search_name;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submitRunningStatusForm           :   {...this.state.runningStatusFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.runningStatusSearchModal.hide()
           this.loadListingTblData(1,true);
        });
    }

    getAllLinkedItemsList                 =   (all_ids)  =>         {
        
        let params                      =   {scs_setting_ids : all_ids};
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/scs/asset/item_linked_scs_setting', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                linked_items        :      respData.data,
            })
        })
    }

    runningStatusSearchModalInit               =       ()             =>        {
        this.runningStatusSearchModal.show();
    }
    

    runningStatusModalInit     =       (id,name)      =>  {
        let scsRunningData   = this.state.scs_listing.find(sc => sc.id == id)

        if(scsRunningData){
            this.RunningStatusModalRef.current.runningStatusModalInit({...scsRunningData,asset_profile : this.state.assetDetail ? this.state.assetDetail : null}); 
        }
       
    }

    assetSetting                    =   ()  =>  this.props.history.push({ pathname: `/assets/settings/${this.state.assetId}`})

    viewLinkedItemInit        =       (id,name)      =>      {
        let linkedItemData   = this.state.linked_items.find(sc => sc.scs_setting_id == id)
        if(linkedItemData){
            this.LinkedItemsViewModalRef.current.linkedItemsModalInit(linkedItemData,name); 
        }
    }

    loadMoreList                    =   ()  =>  {
        if(this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page) {
            this.loadListingTblData(this.state.current_page + 1);
        }
    }
    

    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    runningStatusSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems           :       [],
            runningStatusFilterForm     :       {...this.initRunningStatusSearchForm},
            submitRunningStatusForm     :       {...this.initRunningStatusSearchForm},
            searchedItem                :       [],
        }, () => {
            this.loadListingTblData(1,true);
            this.runningStatusSearchModal.hide();
        });
    }

    //********************************SCS EXECUTION HANDLER********************** */
    checkAllList() {
        let scsItemCheckboxCollections      =   document.getElementsByClassName('scs_item_id_checkbox')
        if(scsItemCheckboxCollections && scsItemCheckboxCollections.length > 0) {
            for (let i = 0; i < scsItemCheckboxCollections.length; i++) {
                scsItemCheckboxCollections[i].checked = document.getElementById('all_check_scs').checked;
            }
        }
    }

    addToDemand = () => {
        let itemList = [];
        let showErrorMessage = false;
        let scsItemCheckboxCollections = document.getElementsByClassName('scs_item_id_checkbox');
    
        if (scsItemCheckboxCollections && scsItemCheckboxCollections.length > 0) {
            for (let i = 0; i < scsItemCheckboxCollections.length; i++) {
                if (scsItemCheckboxCollections[i].checked) {
                    let asset_scs_id = scsItemCheckboxCollections[i].value;
                    let linkedData = this.state.linked_items.find(scs => scs.scs_setting_id == asset_scs_id);
                    
                    if (linkedData && linkedData.items) {
                        linkedData.items.forEach(item => {
                            itemList.push({ 
                                ...item, 
                                scs_item_id: item.id,
                                linked_scs_id: asset_scs_id 
                            });
                        });
                    }
                }
            }
        }
    
        if (itemList.length === 0 && !showErrorMessage) {
            toast.error('In this ServiceCheckSheet have no item ', { position: toast.POSITION.TOP_RIGHT });
        } else {
            let demandData = { 
                items: itemList,
                asset_detail: this.state.assetDetail,
                demand_for :"asset"
            };
            this.props.history.push({ pathname: `/item_demand_add`, state: { addDemandFromScsRunning: demandData } });
        }
    }
    
    executeModalInit     =       ()      =>  {
     this.setState({execution_loading : true,selectedAssetForExecution : []})
        let scsItemCheckboxCollections     =   document.getElementsByClassName('scs_item_id_checkbox');
        let selectedAssetForExecution          =   [];
       
        
            if(scsItemCheckboxCollections && scsItemCheckboxCollections.length > 0) {
                for (let i = 0; i < scsItemCheckboxCollections.length; i++) {
                    if(scsItemCheckboxCollections[i].checked) {
                       let asset_scs_id         =       scsItemCheckboxCollections[i].value;
                       let newList              =       this.state.scs_listing.find(scs => scs.id == asset_scs_id);
                        selectedAssetForExecution.push(newList)    
                    }
                }
            }
        
        if(selectedAssetForExecution.length == 0){
            toast.error('Please select atleast one Service Checksheet', { position: toast.POSITION.TOP_RIGHT });
        }else{
            this.executeCounterlogModal.show()
            this.setState({selectedAssetForExecution : selectedAssetForExecution,execution_loading : false,})
        }
        
    }

    executeSingleModalInit     =       (id)      =>  {
        this.setState({ execution_loading: true, selectedAssetForExecution: [] })
        let selectedAssetForExecution       =       [];
        let asset_scs_id                    =       id;
        let newList                         =       this.state.scs_listing.find(scs => scs.id == id);

        selectedAssetForExecution.push(newList)

        this.executeCounterlogModal.show()
        this.setState({ selectedAssetForExecution: selectedAssetForExecution, execution_loading: false, })
              
       }
   

    submitExecuteFormHandler        =       (e)      =>  {
        e.preventDefault();

        let scs_setting_ids     =       this.state.selectedAssetForExecution.map((s,k) => s.id)
        
        let parameter       =   [];
        parameter = this.state.selectedAssetForExecution.filter(s => s.enable_parameter == "Y");
      
        let executeForm           =       {...this.state.executeForm}
        let frmData               =       {scs_setting_ids        :       scs_setting_ids ,
                                            ...executeForm,
                                            parameter                   :       parameter.length > 0
                                                                        ?       parameter.map((p,key) => {
                                                                                    if(p.execute_level){
                                                                                        return({scs_setting_id : p.id, execute_level : p.execute_level ? parseInt(p.execute_level) : null })
                                                                                    }else{
                                                                                        return({scs_setting_id : p.id,execute_val : p.execute_val ? p.execute_val : null})
                                                                                    }
                                                                                })      
                                                                        :        []};
    
    this.setState({executeFormSubmitting: true})
        HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/scs/execute_with_reading' , this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.executeCounterlogModal.hide()
            this.loadListingTblData(1,true);
            this.getAsssetDetails(this.state.assetId)
            this.setState({executeForm : {...this.executeFormInit}})
            this.checkAllList()
        }).then(() => this.setState({ executeFormSubmitting: false }));
    }

    viewItemModalInit                    =   (item,linkedQty)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item,linkedQty,true)
        }
    }

    viewItemConumptionHistoryInit       =       (id)          =>      {
        let item_ids = []
        if (this.state.linked_items && this.state.linked_items.length > 0) {
            item_ids = this.state.linked_items.filter(li => li.scs_setting_id == id).map((item, index) => {
                return (item.items?.length > 0 ? item.items.map((i, itemKey) => { return (i.item_id) }) : [])
            })
            this.itemsConsumptionHistoryRef.current.modalInit(item_ids.flat(),this.state.assetId)
        } 
    }

   
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader
                    assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}
                 />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div id="assetCard" className="collapse pr-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />}
        </div>)
    }
    
    subHeaderJsx        =       ()      =>      {
        return (<div className="row page_title m0 " style={{ height: "45px" }}>
            <div className="col"><h3 style={{ paddingTop: "13px" }}>Service CheckSheet Running Status</h3></div>
            <div className='col text-center'>
                <button type="button" className="btn btn-primary btn-sm"  role="button" onClick={this.assetCardHandler}>
                        Asset Card
                </button>
            </div>
            <div className=" col text-end mt-2 ">
                <button type="button" className="btn btn-primary" onClick={this.executeModalInit}>
                    Execute
                </button>
                {this.props.permissions.group_modules.includes("isc") ? <button type="button" className="btn btn-secondary" onClick={this.addToDemand} disabled={!this.state.assetDetail}>
                    Add To Demand
                </button> : ""}
                <button type="button" className="btn btn-secondary" onClick={this.runningStatusSearchModalInit}>
                    <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                </button> 
                <button type="button" id='actionMenuButton' className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" >
                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} /> 
                    {this.state.download_loading ? <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-1" /> : null }
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a className="dropdown-item" role="button" onClick={this.assetSetting}>Settings</a></li>
                    <li><Link className="dropdown-item" onClick={() => {this.downloadScsList('active')}} >Download Active SCS</Link></li>
                    <li><Link className="dropdown-item" onClick={() => {this.downloadScsList('all')}} >Download All SCS</Link></li>
                </ul>
            </div>
        </div>)
    }

    serviceChecksheetListingTable                   =   ()  =>  {
       
        return (
            <div className='page_containt' style={{ maxHeight: this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page ? "70vh" : this.state.formSearchedElems.length > 0 ? "64vh" : "74vh" }}>
                <div className='pageTbl'>
                    <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.runningStatusSearchClear} />

                    <table className="table table-bordered bg-white align-items-center   table-sm table-hover table-fixed" id="scsTable">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllList} id="all_check_scs" /></th>
                                <th scope="col" style={{ width: "22%" }}>Name</th>
                                {this.props.permissions && this.props.permissions.group_modules && this.props.permissions.group_modules.includes("isc")
                                    ? <th scope="col" style={{ width: "20%" }}>Linked Items</th> : null}
                                
                                <th scope="col" style={{ width: "20%" }} className="text-start">Last CS Done</th>
                                <th scope="col" style={{ width: "17%" }}>Running Status</th>
                                <th scope="col" style={{ width: "10%" }} className="text-center">Due Date </th>
                                <th scope="col" style={{ width: "5%" }}>Action</th>
                            </tr>
                        </thead>
                        {!this.state.listing_loading
                            ? <tbody>
                                {this.state.scs_listing.length > 0 ? this.state.scs_listing.map((scs, key) => {
                                    let item_ids = [];
                                    if (this.state.linked_items && this.state.linked_items.length > 0) {
                                        item_ids = this.state.linked_items.filter(li => li.scs_setting_id == scs.id).map((item, index) => {
                                            return (item.items?.length > 0 ? item.items.map((i, itemKey) => { return (i.item_id) }) : [])
                                        })
                                    }
                                    let item_all_ids = item_ids.flat()

                                    return (<tr key={key} >
                                        <td className="text-center" >
                                            <input type='checkbox'
                                                className="scs_item_id_checkbox"
                                                value={scs.id}
                                            />
                                        </td>
                                        <td><a role="button" className="link-primary " onClick={() => this.runningStatusModalInit(scs.id, scs.name)}><b>{scs.alias_name ? scs.alias_name : scs.name}</b></a> </td>
                                        {this.props.permissions && this.props.permissions.group_modules && this.props.permissions.group_modules.includes("isc")
                                            ? <td>
                                                {this.state.linked_items && this.state.linked_items.length > 0
                                                    ? this.state.linked_items.filter(li => li.scs_setting_id == scs.id).map((item, index) => {

                                                        return <Ax>{item.items?.length > 0 ? item.items.map((i, itemKey) => <div className='mt-1 link-primary cursor_pointer' onClick={() => this.viewItemModalInit(i.item, i.qty)}>{itemKey + 1}.{i.item.name} : {i.qty} {i.item && i.item.measuring_unit ? i.item.measuring_unit.name : ''} <br /><small className='form-text mt-1'> (Code : {i.item?.item_code})</small></div>) : " -"}<br /></Ax>

                                                    })
                                                    : "-"}
                                            </td> : null}
                                      
                                        <td className="text-start"> {scs.latest_execution_cl && scs.latest_execution_cl.length > 0 ? scs.latest_execution_cl.map((item, index) => { return (<div className="mt-1"> {item.label} : {item.reading}  {item.unit} <br /></div>) })
                                            : "-"}</td>
                                        <td className='text-center'>
                                            {scs.latest_execution_id ?
                                                <div>
                                                    <div className="progress">
                                                        <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${scs.max_running}%`, backgroundColor: `${scs.bar_bg_color}` }}
                                                            aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                    <small className='form-text'>{scs.max_running}%</small>
                                                </div>
                                                : <span>NYA <sup>#</sup></span>}

                                        </td>
                                        <td className='text-center'>{scs.expected_due_date ?  scs.expected_due_date : "-"}</td>
                                        <td className="text-center">
                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button" onClick={() => this.runningStatusModalInit(scs.id, scs.name)}>View</a></li>

                                                {this.props.permissions && this.props.permissions.group_modules && this.props.permissions.group_modules.includes("isc") ?
                                                    <Ax>
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewLinkedItemInit(scs.id, scs.alias_name ?? scs.name)}>View Linked Items</a></li>
                                                        <li><a className={item_all_ids && item_all_ids.length == 0 ? "dropdown-item disabled" : "dropdown-item "} disabled={item_all_ids && item_all_ids.length == 0 ? true : false} role="button" onClick={() => this.viewItemConumptionHistoryInit(scs.id)}>View Item Consumption History</a></li>

                                                    </Ax>
                                                    : null}
                                                <li> <Link className="dropdown-item" target="_blank" to={{ pathname: `/assets/execution_history/${this.state.assetId}`, search: "?" + new URLSearchParams({ search_scs_name: scs.name ? scs.name : scs.alias_name }).toString() }}>History</Link></li>
                                                <li><a className="dropdown-item" role="button" onClick={() => this.executeSingleModalInit(scs.id)}>Execute</a></li>
                                            </ul>
                                        </td>

                                    </tr>)
                                })
                                    : <tr><td colSpan="7" className="text-center">No Records</td></tr>
                                }

                            </tbody>
                            : null}
                    </table>
                    
                    <div className='bg-white'>
                        {this.state.listing_loading
                            ? (<div className='text-center'><Loader /></div>)
                            : (<small className='text-muted'>#Not Yet Activated</small>)}
                    </div>

                    <div className='text-end mt-1 mb-5'>
                        {this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page
                            ? <button role="button" className="btn btn-secondary me-3 " style={{ lineHeight: "6.3px!important", height: "27px" }} onClick={() => this.loadMoreList()} >Load More</button>
                            : null}
                    </div>

                </div>
            </div>
        );
    }


    executeCounterlogModalJsx                  =   ()  =>  {
       let labels                          =   this.state.assetDetail && this.state.assetDetail.labels ? this.state.assetDetail.labels : {};
        return (
            <div className="modal fade" id="executeCounterlogModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Execute Service CheckSheet</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body ">
                            <table className="table table-bordered bg-white align-items-center  table-sm table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                                        <th scope="col" style={{ width: "65%" }} >Service Checksheet Name</th>
                                        <th scope="col" style={{ width: "35%" }} >Parameter</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.execution_loading
                                        ? <tr colSpan={2}><td className='text-center'><Loader /></td></tr>
                                        : this.state.selectedAssetForExecution?.length > 0 && this.state.selectedAssetForExecution.map(((sa, key) => {

                                            return (<tr key={key + 1}>
                                                <td className='text-center'>{key + 1}</td>
                                                <td>{sa?.alias_name ? sa?.alias_name : "-"}</td>
                                                <td>{sa.enable_parameter == "Y"
                                                    ? <div>{sa.parameter && sa.parameter.type == "range"
                                                        ? <span>
                                                            <input
                                                                type="number"
                                                                className='form-control'
                                                                name={"execute_level"}
                                                                onChange={(e) => this.dynamicInputHandlerByKey(e, key, 'selectedAssetForExecution')}
                                                                value={this.state.selectedAssetForExecution[key]?.execute_level}
                                                                placeholder={`${sa.parameter.type_display ?? ""}`}
                                                                style={{ height: "30px", fontSize: "93%" }} />
                                                           </span>
                                                        : <TapSelect
                                                            changeEvent={(selectedOption) => {
                                                                let selectedAssetForExecution         =   this.state.selectedAssetForExecution;
                                                                selectedAssetForExecution[key]        =   {...selectedAssetForExecution[key] , execute_val : selectedOption ? selectedOption.value : ''};
                                                                this.setState({selectedAssetForExecution : [...selectedAssetForExecution]});
                                                                
                                                            }}
                                                            
                                                            options={this.state.parameterOptions}
                                                            isClearable={true}
                                                            value={this.state.parameterOptions.find(m => m.value == this.state.selectedAssetForExecution[key]?.execute_val)}
                                                            placeholder="Please Select Options"
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                        />}</div>
                                                    : "-"}</td>
                                            </tr>)
                                        }))}
                                </tbody>
                            </table>
                            <form onSubmit={this.submitExecuteFormHandler} id="executeForm">
                                <div className='counterlog'>
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Counterlog</span>
                                    </div>
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.hour_meter ? labels.hour_meter : 'Hour Meter'} </label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="hourmeter"
                                                    type="number"
                                                    value={this.state.executeForm.hourmeter}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Hour"
                                                    onChange={(e) => { this.formInputHandler(e, 'executeForm') }}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter2 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.hour_meter2 ? labels.hour_meter2 : 'Hour Meter 2'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="hourmeter2"
                                                    type="number"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.executeForm.hourmeter2}
                                                    placeholder="Input in Hour"
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs2}</div>
                                                    : <div>No Counterlog</div>}

                                            </label>


                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter3 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.hour_meter3 ? labels.hour_meter3 : 'Hour Meter 3'} </label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="hourmeter3"
                                                    type="number"
                                                    value={this.state.executeForm.hourmeter3}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Hour"
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs3}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter4 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.hour_meter4 ? labels.hour_meter4 : 'Hour Meter 4'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="hourmeter4"
                                                    type="number"
                                                    value={this.state.executeForm.hourmeter4}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Hour"
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs4}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">{labels && labels.odo_meter ? labels.odo_meter : 'Odo Meter'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="odometer"
                                                    type="number"
                                                    value={this.state.executeForm.odometer}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Kms"
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter2 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.odo_meter2 ? labels.odo_meter2 : 'Odo Meter 2'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="odometer2"
                                                    type="number"
                                                    value={this.state.executeForm.odometer2}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Kms"
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs2}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>


                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter3 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.odo_meter3 ? labels.odo_meter3 : 'Odo Meter 3'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="odometer3"
                                                    type="number"
                                                    value={this.state.executeForm.odometer3}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Kms"
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs3}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>


                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.operation_cycle == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.operation_cycle ? labels.operation_cycle : 'Operation Cycle'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="operation_cycle"
                                                    type="number"
                                                    value={this.state.executeForm.operation_cycle}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Operation Cycle Reading"
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.cycle_abs}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.operation_cycle2 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.operation_cycle2 ? labels.operation_cycle2 : 'Operation Cycle 2'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="operation_cycle2"
                                                    type="number"
                                                    value={this.state.executeForm.operation_cycle2}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Operation Cycle Reading"
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.cycle_abs2}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}

                                    <div className="row my-3 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm  ">Counterlog Date</label>
                                        <div className="col-sm-4 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.executeForm.date_time
                                                        ? moment(this.state.executeForm.date_time).toDate()
                                                        : false
                                                }
                                                name="date_time"
                                                onChange={(value, event) => {
                                                    this.formDateHandler('date_time', value, 'executeForm','YYYY-MM-DD HH:mm:ss')
                                                }}
                                                popperPlacement="right-start"
                                                className={"form-control"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Due Date`}
                                                showTimeSelect
                                                timeIntervals={5}
                                                timeCaption="Time"
                                                dateFormat="dd-MMM-yyyy hh:mm a"
                                                maxDate={moment(new Date(),'YYYY-MM-DD').toDate()}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon" />
                                        </div>
                                        <label className="col-sm-5 form-text ">
                                            {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.feed_date_time_display}</div>
                                                : null}
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" disabled={this.state.executeFormSubmitting} data-bs-dismiss="modal" >Cancel</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.executeFormSubmitting} form="executeForm">Update {this.state.executeFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    runningStatusSearchModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="runningStatusSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Service Checksheet Running Status Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="poSearchForm" onSubmit={this.runningStausFormSubmit}>
                            <div className="modal-body">
                                <div className="row my-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Service Checksheet Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="search_name"
                                            type="text"
                                            value={this.state.runningStatusFilterForm.search_name}
                                            onChange={(e) => this.formInputHandler(e, 'runningStatusFilterForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Service Checksheet Name / Alias Name"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                </div>


                                <div className="row my-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Item</label>
                                    <div className="col-sm-9">
                                        <InputItemSearch
                                            placeholder="Search Item"
                                            isMulti={true}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'runningStatusFilterForm', 'search_item_ids');
                                                this.setState({
                                                    searchedItem: selectedOption ? selectedOption : null
                                                }, () => { });
                                            }}
                                            value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Item Tags</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allItemTags}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'runningStatusFilterForm', 'search_item_tags')}
                                            value={this.state.allItemTags.filter(u => this.state.runningStatusFilterForm?.search_item_tags?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            menuPlacement="top"
                                            placeholder="Select Item Tags"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Status</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allStatusOptions}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'runningStatusFilterForm', 'search_scs_running_status')}
                                            value={this.state.allStatusOptions.filter(u => this.state.runningStatusFilterForm?.search_scs_running_status?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            menuPlacement="top"
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

    render                      =   ()  =>  {
       
        return (<ApplicationLayout>
            <Helmet><title>Service CheckSheet Running Status</title></Helmet>
            {this.pageTitleBarJsx()}
        
            <div className="container-fluid pl5">
                <div className='page_containt row'>
                    <div className='pr0  col-sm-12'>
                        {this.horizontalAssetCardJsx()}
                        {this.subHeaderJsx()}
                        {this.serviceChecksheetListingTable()}
                     
                     </div>
                </div>
            </div>
            {this.executeCounterlogModalJsx()}
            {this.runningStatusSearchModalJsx()}
            <LinkedItemsViewModal parentProps={this.props} ref={this.LinkedItemsViewModalRef}/>
            <RunningStatusViewModal parentProps={this.props} ref={this.RunningStatusModalRef}/>
            <ItemDetailsModal ref={this.itemDetailsRef}  /> 
            <ItemsConsumptionHistory ref={this.itemsConsumptionHistoryRef}/>
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ServiceChecksheetRunningStatus);