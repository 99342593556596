import {connect} from "react-redux";
import {Tooltip} from "bootstrap";
import AppBaseComponent from '../../components/AppBaseComponent';
import HttpAPICall from "../../services/HttpAPICall";
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from "../../components/ui/TapSelect";
import tapIcon from "../../services/TapIcon";
import Ax from "../../components/hoc/Ax"
import React from "react";
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from "../../services/TapApiUrls";
import InputItemSearch from '../includes/ui/InputItemSearch';
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AllocationDetailItemWise from "../inventory/allocation/AllocationDetailItemWise";
import TransferItemWiseListModal from "../inventory/inventoryTransfer/TransferItemWiseListModal";


class ItemInventory extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                  =           {
            itemId                  :               this.props.item_enc_id,
            itemName                :               this.props.itemName,
            listing_tbl_page        :               1,
            listingData             :               [],
            listingMeta             :               [],
            totalListingCount       :               0,
            listing_loading         :               false,
            isc_configuration       :               null,
        }

        this.itemWiseAllocationRef      =   React.createRef();
        this.itemWiseInventoryRef       =   React.createRef();
        this.itemCurrentStockUrl        =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';
    }

    componentDidMount() {
        this.loadListingTblData(this.props.item_enc_id);
       
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.item_enc_id !== this.props.item_enc_id){
            this.setState({itemId : nextProps.item_enc_id,itemName : this.props.itemName})
            this.loadListingTblData(nextProps.item_enc_id);
        }
        if(nextProps.refreshInventoryLevel == true){
            this.refreshInventoryLevel(nextProps.item_enc_id);
        }
    }
    
    loadListingTblData                  =   (id)  =>  {
        this.setState({listing_loading: true});
        HttpAPICall.withAthorization('GET', this.itemCurrentStockUrl + '/' + id, this.props.access_token , {interchangeable_items_current_stock : "Y"}, {}, (resp) => {
            this.setState({
                listingData     :   resp.data,
            })
        }).then(() => this.setState({listing_loading: false}))
    }

    refreshInventoryLevel                  =   (id)  =>  {
        this.setState({listing_loading: true});
        HttpAPICall.withAthorization('PUT', this.itemCurrentStockUrl + '/' + id, this.props.access_token , {}, {}, (resp) => {
            this.loadListingTblData(id);
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({listing_loading: false}))
    }
    
    changeTab       =       (key)       =>   {
        let ParamObject                 =   new URLSearchParams(this.props.props.location.search);
        let itemName,itemCategory;
        if(ParamObject.get('item_name')){
           itemName = ParamObject.get('item_name')
        }
        if(ParamObject.get('item_category')){
            itemCategory = ParamObject.get('item_category')
         }
        let obj = {item_name : itemName,item_category : itemCategory}
        this.props.props.history.push({ pathname: `/item_list/${key}/${this.state.itemId}`, search: "?" + new URLSearchParams(obj).toString()})
    }

    allocationDetailInit        =       (item_id='',warehouse_name,warehouse_id)        =>      {
        let ParamObject                 =   new URLSearchParams(this.props.props.location.search);
        let itemName;
        if(ParamObject.get('item_name')){
           itemName = ParamObject.get('item_name')
        }
        this.itemWiseAllocationRef.current.modalInit(item_id,itemName,warehouse_name,warehouse_id)
    }

    inventoryTransferDetailInit        =       (item_id='',warehouse_id)        =>      {
        let ParamObject                 =   new URLSearchParams(this.props.props.location.search);
        let itemName;
        if(ParamObject.get('item_name')){
           itemName = ParamObject.get('item_name')
        }
        let data = {id: item_id,name:itemName}
        this.itemWiseInventoryRef.current.modalInit(data,'','',warehouse_id,35)
    }


    render                                  =     ()           =>     {
        let ParamObject                 =   new URLSearchParams(this.props.props.location.search);
       
        let itemCategory;
        if(ParamObject.get('item_category')){
           itemCategory = ParamObject.get('item_category')
        }

        return (<Ax>
        <Helmet><title>Item - Inventory Levels </title></Helmet>
            <section className="item_view_container bg-white">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/details/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab('details')}>
                            <button className="nav-link " id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                                type="button" role="tab" aria-controls="details" aria-selected="true">Details
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/interchangeable/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab('interchangeable')}>
                            <button className="nav-link " id="interchangeable-tab" data-bs-toggle="tab" data-bs-target="#interchangeable"
                                type="button" role="tab" aria-controls="interchangeable" aria-selected="false">Interchangeable Items
                            </button>
                        </Link>

                    </li>
                    {itemCategory !== "services" && this.props.category !== "services" ?
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/inventory/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab('inventory')}>
                                <button className="nav-link active" id="inventory-tab" data-bs-toggle="tab" data-bs-target="#inventory"
                                    type="button" role="tab" aria-controls="inventory" aria-selected="false">Inventory Levels
                                </button>
                            </Link>
                        </li> : null}
                    {itemCategory !== "services" && this.props.category !== "services" ?
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/configuration/${this.state.itemId}`} onClick={() => this.changeTab('configuration')} role={"button"}>
                                <button className="nav-link" id="config-tab" data-bs-toggle="tab" data-bs-target="#config"
                                    type="button" role="tab" aria-controls="config" aria-selected="false">
                                    Configuration
                                </button>
                            </Link>
                        </li>
                    : null}
                     <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/additional_attribute/${this.state.itemId}`}
                            role={"button"}
                            onClick={() => this.changeTab("additional_attribute")}
                        >
                            <button
                                className="nav-link "
                                id="additional_attribute-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#additional_attribute"
                                type="button"
                                role="tab"
                                aria-controls="additional_attribute"
                                aria-selected="false"
                            >
                                Additional Attributes
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/document/${this.state.item_enc_id}`}
                            role={"button"}
                            onClick={() => this.changeTab("document")}
                        >
                            <button
                                className="nav-link "
                                id="document-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#document"
                                type="button"
                                role="tab"
                                aria-controls="document"
                                aria-selected="false"
                            >
                              Document
                            </button>
                        </Link>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/child_item/${this.state.item_enc_id}`}
                            role={"button"}
                            onClick={() => this.changeTab("child_item")}
                        >
                            <button
                                className="nav-link "
                                id="child_item-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#child_item"
                                type="button"
                                role="tab"
                                aria-controls="child_item"
                                aria-selected="false"
                            >
                               Child Items
                            </button>
                        </Link>
                    </li>
                     */}
                </ul>
                <div className="tab-content" id="myTabContent"></div>
                {itemCategory !== "services" && this.props.category !== "services" ?
                    <div className="tab_content_header">
                        <table className="table table-hover table-bordered table-responsive bg-white my-3">
                            <thead className="table-secondary">
                                <tr className="text-end">
                                    <th style={{ "width": '5%' }} className="text-center">S.No</th>
                                    <th style={{ width: "25%" }} className="text-start">Warehouse</th>
                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                        <Ax>
                                            <th style={{ width: "15%" }} className="text-start">Batch Number</th>
                                            <th style={{ width: "15%" }} className="text-center">Expiry Date</th>
                                        </Ax>
                                    ) : null}
                                     <th style={{ width: "8%" }}>Total Qty</th>
                                    <th style={{ width: "8%" }}>On Hold</th>
                                    <th style={{ width: "8%" }}>Allocated</th>
                                    <th style={{ width: "8%" }}>In Transit</th>
                                    <th style={{ width: "8%" }}>Free Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listing_loading
                                    ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                    : (this.state.listingData?.current_stock?.length > 0
                                        ? (this.state.listingData?.current_stock?.map((item, k) => {
                                            return (<tr key={k} className="text-end">
                                                <td className="text-center">{k + 1}</td>
                                                <td className="text-start">{item.warehouse ? item.warehouse.name : "-"} {item.warehouse && item.warehouse.code && <span>({item.warehouse.code})</span>}</td>
                                                {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                    <Ax> <td className="text-start">{item.batch_number ? item.batch_number : "-"}</td>
                                                        <td className="text-center">{item.expiry_date ? moment(item.expiry_date).format("DD-MMM-YYYY") : "-"}</td>
                                                    </Ax>) : null}
                                                    <td>{item.total_qty ? item.total_qty : "0"}</td>
                                                <td>{item.on_hold ? item.on_hold : "0"}</td>
                                                
                                                <td className="link-primary cursor_pointer" onClick={() => this.allocationDetailInit(item.item_id,item?.warehouse?.name,item.warehouse_id)}>{item.allocated_qty ? item.allocated_qty : "0"}</td>
                                                <td className="link-primary cursor_pointer" onClick={() => this.inventoryTransferDetailInit(item.item_id,item.warehouse_id)}>{item.intransit ? item.intransit : "0"}</td>
                                                <td>{item.free_qty ? item.free_qty : "0"}</td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="9" className="text-center">No Records</td></tr>)
                                    )
                                }
                            </tbody>
                        </table>
                        <div className="tab_content_wrapper"><span className="content_heading">Interchangeable</span> </div>
                        <table className="table table-hover table-bordered table-responsive bg-white my-3">
                            <thead className="table-secondary">
                                <tr className="text-end">
                                    <th style={{ "width": '5%' }} className="text-center">S.No</th>
                                    <th style={{ width: "24%" }} className="text-start">Item</th>
                                    <th style={{ width: "15%" }} className="text-start">Warehouse</th>
                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                        <Ax>
                                            <th style={{ width: "8%" }} className="text-start">Batch Number</th>
                                            <th style={{ width: "8%" }} className="text-center">Expiry Date</th>
                                        </Ax>)
                                        : null}
                                    <th style={{ width: "8%" }}>Total Qty</th>
                                    <th style={{ width: "8%" }}>On Hold</th>
                                    <th style={{ width: "8%" }}>Allocated</th>
                                    <th style={{ width: "8%" }}>In Transit</th>
                                    <th style={{ width: "8%" }}>Free Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listing_loading
                                    ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                    : (this.state.listingData?.interchangable?.length > 0
                                        ? (this.state.listingData?.interchangable?.map((item, k) => {
                                            return (<tr key={k} className="text-end">
                                                <td className="text-center">{k + 1}</td>
                                                <td className="text-start text-capitalize">{item.item ? item.item.name : "-"} <br />
                                                    <small>Code : {item.item ? item.item.item_code : "-"}</small><br/>
                                                    <small>Mfr  : <b> {item && item.item.manufacturer ? item.item.manufacturer.name : '-'} </b>  (MPN : <b> {item.item.manufacturer_part_no ?? '-'}</b>)</small>
                                                </td>
                                                <td className="text-start">{item.warehouse ? item.warehouse.name : "-"}</td>
                                                {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                    <Ax><td className="text-start">{item.batch_number ? item.batch_number : "-"}</td>
                                                        <td className="text-center">{item.expiry_date ? moment(item.expiry_date).format("DD-MMM-YYYY") : "-"}</td>
                                                    </Ax>) : null}
                                                <td>{item.total_qty ? item.total_qty : "0"}</td>
                                                <td>{item.on_hold ? item.on_hold : "0"}</td>
                                                <td className="link-primary cursor_pointer" onClick={() => this.allocationDetailInit(item.item_id,item?.warehouse?.name,item.warehouse_id)}>{item.allocated_qty ? item.allocated_qty : "0"}</td>
                                                <td className="link-primary cursor_pointer" onClick={() => this.inventoryTransferDetailInit(item.item_id,item.warehouse_id)}>{item.intransit ? item.intransit : "0"}</td>
                                                <td>{item.free_qty ? item.free_qty : "0"}</td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                    )
                                }
                            </tbody>
                        </table>
                    </div> : <div className="tab_content_header">

                    </div>}
            </section>
            <TransferItemWiseListModal ref={this.itemWiseInventoryRef}/>
            <AllocationDetailItemWise ref={this.itemWiseAllocationRef}/>
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        isc_configuration       :        state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps)(ItemInventory);