import React from 'react';
import { connect } from "react-redux";
import assetStyle from './AssetCard.module.css';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import ChangeAssetStatus from '../../assets/includes/ChangeAssetStatus';
import { Link } from 'react-router-dom';
import HttpAPICall from '../../../services/HttpAPICall';
import Loader from '../../../components/ui/Loader/Loader';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AssetCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            card_loading                 :         false,
            assetCardDetails             :          [],
            assetId                       :         ''
        }
        this.ChangeAssetStatusModalRef      =           React.createRef();
    }
     
    componentDidMount(){
        this.initalizeData(this.props)
        if(this.props && this.props.assetId){
            this.getAssetDetails(this.props.assetId)
        }
    }

    componentWillReceiveProps(nextProps) {
      
        if(nextProps.assetCardDetails !== this.props.assetCardDetails ){
         this.initalizeData(nextProps)
        }
        if(nextProps.assetId !== this.props.assetId ){
            this.getAssetDetails(nextProps.assetId)
        }
     }

     getAssetDetails       = (id) => {
        
        this.setState({ card_loading: true })
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + id, this.props.access_token)
        .then((response) => {
            let respData = response.data;
            this.setState({
                assetCardDetails: respData.data,
            });
        }).then(() => {
            this.setState({ card_loading: false });
        });
     }

    initalizeData  =   (pr)        =>       {
        this.setState({assetCardDetails : pr.assetCardDetails})
    }

    changeStatusModal                       =   ()  =>  {
        if(this.state.assetCardDetails){
            this.ChangeAssetStatusModalRef.current.changeStatusModalInit(this.state.assetCardDetails.asset_id);
        }  
    }

    render() {
        let details = this.state.assetCardDetails
        return (
            <Ax>
                {this.state.card_loading
                    ? <Loader />
                    : <table className="table table-hover table-bordered bg-white">
                        <tbody>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    <img src={details?.image} className={`img-fluid ${assetStyle.asset_card_img}`} alt={"asset_card"} />
                                </td>
                            </tr>
                            <tr>

                                <td className={assetStyle.asset_card_details}>
                                    Asset Name : <br />
                                    <div className="pt-2">
                                        {details && details.can_access == true
                                            ? <Link to={`/asset/dashboard/${details.asset_id}`}>{details?.name ?? "-"}</Link>
                                            : details?.name ?? "-"}

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    Asset Code :<br />
                                    <div className="pt-2"> <b>{details?.asset_code ?? "-"}</b></div>
                                </td>
                            </tr>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    Asset Type  : <br />
                                    <div className="pt-2"> <b>{details?.asset_type_name ?? "-"}</b></div>
                                </td>
                            </tr>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    Asset Matrix Id  : <br />
                                    <div className="pt-2">  <b className='link-primary'><Link 
                                    to={`/asset_matrix/details/${details?.asset_matrix_id}`}
                                    // to={{ pathname: `/asset_matrix`, search: "?" + new URLSearchParams({ transaction_id: details?.asset_matrix_unique_id }).toString() }}
                                    >{details?.asset_matrix_unique_id ?? "-"}</Link></b></div>
                                </td>
                            </tr>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    Manufacturer   : <br />
                                    <div className="pt-2"> <b>{details?.manufacturer_name ?? "-"}</b></div>
                                </td>
                            </tr>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    Model   : <br />
                                    <div className="pt-2"> <b>{details?.model_name ?? "-"}</b></div>
                                </td>
                            </tr>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    Last Counter Log Reading : <br />
                                    <div className="pt-2"> <b>{details && details.latest_counterlog && details.latest_counterlog.display && details.latest_counterlog.display.length > 0 ? details?.latest_counterlog?.display.map((item, index) => {
                                        return (<span>{item.label} : {item.reading} {item.unit}
                                            <br /></span>)
                                    }) : "-"}</b></div>
                                </td>
                            </tr>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    Site   : <br />
                                    <div className="pt-2"> <b>{details?.site ?? "-"} ({details?.site_code})</b></div>
                                </td>
                            </tr>
                            <tr>
                                <td className={assetStyle.asset_card_details}>
                                    Asset Status  : <br />
                                    <div className="pt-2"> <tapIcon.FontAwesomeIcon className="mt10" icon={tapIcon.faCircle} color={details?.status_color} />
                                        <span className="ml5"><b>{details?.status_text ?? "-"}</b></span>
                                        <button type="button" className="btn  btn-sm mx-2" disabled={details && details.can_access == false} title={details && details.can_access == false ? 'You do not have permission to perform this action' : ''} style={{ pointerEvents: "all" }} role="button" onClick={this.changeStatusModal}>
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPencilAlt} alt="item-action" /></button>
                                        {/* <tapIcon.imageIcon icon={tapIcon.EditIcon} className="img-fluid mx-2" alt="item-action"   />  */}
                                    </div>
                                </td>
                            </tr>
                            {this.props.permissions && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-rental")
                                ? <tr>
                                    <td className={assetStyle.asset_card_details}>
                                        Commercial Status  : <br />
                                        {details &&  details.rental_detail && details.rental_detail.status_data
                                         ?  <div className="pt-2"> <tapIcon.FontAwesomeIcon className="mt10" icon={tapIcon.faCircle} color={details.rental_detail.status_data.color} />
                                            <span className="ml5"><b>{details.rental_detail.status_data.name}</b></span>

                                        </div> : <b>No Status</b>}
                                    </td>
                                </tr> : null}
                        </tbody>
                    </table>
                }
                <ChangeAssetStatus ref={this.ChangeAssetStatusModalRef} afterChangeStatus={() => { 
                    if (this.props.refreshAssetDetail) { 
                        this.props.refreshAssetDetail() 
                    } else{
                        this.getAssetDetails(this.props.assetId)
                    }
                    
                    }} />
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(AssetCard);
