import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import uuid from 'react-uuid';
import ProductivityView from '../../../transactions/productivity/ProductivityView';
import InputEmployeeSearch from '../../../includes/ui/InputEmployeeSearch';
import InputWorkstationSearch from '../../../includes/ui/InputWorkstationSearch';


class Productivity extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initProductivityForm   =   {
            activity_new                :   '',
            activity_id                 :   '',
            unit                        :   '',
            unit_new                    :   '',
            material_id                 :   '',
            material_new                :   '',
            workstation_id              :   '',
            workstation_new             :   '',
            operator_name               :   '',
            operator_name_new           :   '',
            attachment_id               :   '',
            attachment_new              :   '',
            production                  :   '',
            activity_count              :   '',
            remarks                     :   '',
            operator_ids                :   []
        }

        this.state                  =   {
            access_token                :   '',
            counterlog                  :   null,
            assetData                   :   null,
            shift                       :   null,
            transaction_id              :   '',
            date                        :   '',
            addNewActivity              :   'N',
            addNewUnit                  :   'N',
            addNewMaterial              :   'N',
            addNewWorkstation           :   'N',
            addNewOperators             :   'N',
            addNewAttachment            :   'N',
            addProductivityForm         :   {...this.initProductivityForm},
            formDataLoading             :   false,
            allActivityList             :   [],
            allAttachmentList           :   [],
            allMaterialList             :   [],
            allUsersList                :   [],
            allWorkstationList          :   [],
            allAdditonalAttribute       :   [],
            previousProductivityData    :   null,
            allProductionUnits          :   [],
            addProductivityFormSubmitting   :   false,
            copy_reading                    :   'N',
            productivityData                :   null,
            productivityViewData            :   null,
            viewDataLoading                 :   false,
            permissions                     :   [],
            allProductionFormulas           :   [],
            linkedProductivityFormula       :   null,
            linkedErrorMessage              :   '',
            formulaApplicable               :   'N',
            searchedAssets                  :   [],
            searchedEmployee                :   [],
            runtime_material_creation       :   'N',
            runtime_activity_creation       :   'N',
            runtime_productivity_unit_creation  :   'N',
            searchedWorkstation                 :   null
        }
        this.uploadProductivityModalId      =   uuid();
        this.viewProductivityModalId      =   uuid();
    }
    
    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);
        this.uploadProductivityModal        =   new Modal(document.getElementById(this.uploadProductivityModalId), {keyboard: false, backdrop: false}); 
        this.viewProductivityModal        =   new Modal(document.getElementById(this.viewProductivityModalId), {keyboard: false, backdrop: false}); 
    }
    
    componentWillReceiveProps(nextProps) {
        this.initalizeComponent(nextProps);
    }
    
    initalizeComponent                  =   (pr)  =>  {
        this.setState({access_token : pr.access_token,permissions : pr.permissions});
    }
    
    
    uploadProductivityModalInit             =   (shiftDetail, counterlog, assetData = null,productivityData = null)  =>  {
        this.uploadProductivityModal.show();
        this.setState({
            addProductivityForm         :   {...this.initProductivityForm},
            copy_reading                :   'N',
            addNewActivity              :   'N',
            addNewUnit                  :   'N',
            addNewMaterial              :   'N',
            addNewWorkstation           :   'N',
            addNewOperators             :   'N',
            linkedProductivityFormula   :   null,
            searchedWorkstation         :   null,
            linkedErrorMessage          :    '',
            addNewAttachment            :   'N',}
            ,() => {
                this.setState({
                    assetData           :   assetData,
                    shift               :   shiftDetail,
                    counterlog          :   counterlog,
                    date                :   counterlog ? counterlog.feed_date : '',
                    transaction_id      :   counterlog ? counterlog.transaction_id : '',
                    productivityData    :   productivityData ? productivityData : null
                });
                if(counterlog){
                    this.loadFormDataFromApi(counterlog.transaction_id ,productivityData)
                }
                if(document.getElementById("logProductivityForm")) {
                    let form = document.getElementById("logProductivityForm")
                    form.reset();
                }
            })
       
    }

    loadFormDataFromApi                 =   (id ,productivityData)  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/productivity/form_data/' + id, this.state.access_token , {}, {}, (response) => {
           let respData = response.data;
            
            this.setState({
                allAdditonalAttribute       :       respData && respData.additional_attributes && respData.additional_attributes.length > 0 ? respData.additional_attributes : [],
                allActivityList             :       respData && respData.activities && respData.activities.length > 0 ? respData.activities.map(a => { return ({ value: a.id, label: a.activity_name, production_units: a.production_units }) }) : [],
                allMaterialList             :       respData && respData.materials && respData.materials.length > 0 ? respData.materials.map(a => { return ({ value: a.id, label: a.material_name }) }) : [],
                //allUsersList                :       respData && respData.users_list && respData.users_list.length > 0 ? respData.users_list.map(a => { return ({ value: a.display_full_name, label: a.display_full_name }) }) : [],
                allWorkstationList          :       respData && respData.workstations && respData.workstations.length > 0 ? respData.workstations.map(a => { return ({ value: a.id, label: a.workstation_name }) }) : [],
                previousProductivityData    :       respData && respData.last_productivity  && JSON.stringify(respData.last_productivity) !== JSON.stringify({}) ? respData.last_productivity : null,
                allAttachmentList           :       respData && respData.attachments && respData.attachments.length > 0 ? respData.attachments.map(a => { return ({ value: a.id, label: a.name }) }) : [],
                allProductionFormulas       :       respData && respData.apply_prodution_formula == 'Y' && respData.production_formulas && respData.production_formulas.length > 0 ? respData.production_formulas : [],
                formulaApplicable           :        respData && respData.apply_prodution_formula == 'Y' ? 'Y' : 'N',
                runtime_material_creation   :       respData && respData.runtime_material_creation && respData.runtime_material_creation == 'Y'  ? 'Y' : 'N',
                runtime_activity_creation   :       respData && respData.runtime_activity_creation && respData.runtime_activity_creation == 'Y'  ? 'Y' : 'N',
                runtime_productivity_unit_creation   :       respData && respData.runtime_productivity_unit_creation && respData.runtime_productivity_unit_creation == 'Y'  ? 'Y' : 'N',
            },() => {
                if(productivityData){
                  
                    let previousData                     =      productivityData;
                    let updateFormState = [];
                    if (previousData.additional_attributes) {
                        Object.keys(previousData.additional_attributes).map((k) => {
                            let name =  'additional_attributes_' + k;
                            updateFormState[name] = previousData.additional_attributes[k]
                        });
                    }
                   
                    let activityListContainingProduction =      this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.find(p => { return (p.value == this.state.previousProductivityData.activity) });
                    let allProductionUnitList           =       activityListContainingProduction && activityListContainingProduction.production_units && activityListContainingProduction.production_units.length > 0   ?  activityListContainingProduction.production_units.map((d) => {  return ({ value: d.measuring_unit, label: d.measuring_unit }) })  : [];
                    
                    let activity_id_exists          =   previousData.activityData && this.state.allActivityList &&  this.state.allActivityList.length > 0 && this.state.allActivityList.find(us =>  us.value == previousData.activityData.id) ? 'Y'  : 'N'
                    

                    this.setState({
                        allProductionUnits : allProductionUnitList,
                        allActivityList     :       activity_id_exists == 'N' ? [...this.state.allActivityList,{value:previousData.id,label:previousData.activity_name}] : this.state.allActivityList,
                    }, () => {
                        this.setState({
                           
                            addProductivityForm : {
                            ...this.initProductivityForm,
                            activity_id            :      previousData.activityData ? previousData.activityData.id : '' ,
                            unit                   :      previousData.production_unit ? previousData.production_unit : '',
                            production             :      previousData.production ? previousData.production : '',
                            activity_count          :   previousData.activity_count ? previousData.activity_count : '',
                            material_id            :      previousData.materialData ? previousData.materialData.id : '',
                            workstation_id         :      previousData.workstation ? previousData.workstation : '',
                            operator_name          :      previousData.operator_name ? previousData.operator_name : '',
                            attachment_id          :      previousData.attachmentData ? previousData.attachmentData.id : '',
                            remarks                :      previousData.remarks ? previousData.remarks : '',
                            productivity_id        :      previousData.id ? previousData.id : '',
                            operator_ids           :     previousData.operators && previousData.operators.length > 0 ?  previousData.operators.map(op => {return(op.enc_id)})   : [],
                            ...updateFormState
                        },
                            previousProductivityData :      null,
                            searchedEmployee            :   previousData.operators && previousData.operators.length > 0 ?  previousData.operators.map(op => {return({value:op.enc_id,label : op.display_full_name})})   : []
                        },() => {
                            
                                let workstation = previousData.workStationdata;
                                if (workstation) {
                                    this.setState({ searchedWorkstation: { value: previousData.workstation, label: workstation.workstation_name } });
                                }
                                
                            this.getLinkedProductivityFormula()
                        });
                    })
                }
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }

    copyPreviousHandler                 =   ()  =>  {
        if(this.state.copy_reading == "Y" && this.state.previousProductivityData) {
           
            let previousData                     =      this.state.previousProductivityData;
          
            let updateFormState = [];
            if (previousData.additional_attributes) {
                Object.keys(previousData.additional_attributes).map((k) => {
                    let name =  'additional_attributes_' + k;
                    updateFormState[name] = previousData.additional_attributes[k]
                });
            }
            
            let activityListContainingProduction =      this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.find(p => { return (p.value == previousData.activity) });
            let allProductionUnitList           =       activityListContainingProduction && activityListContainingProduction.production_units && activityListContainingProduction.production_units.length > 0   ?  activityListContainingProduction.production_units.map((d) => {  return ({ value: d.measuring_unit, label: d.measuring_unit }) })  : [];
            
            this.setState({allProductionUnits : allProductionUnitList},
                () => {
                    this.setState({addProductivityForm : {
                        ...this.state.addProductivityForm,
                       activity_id            :      previousData.activityData ? previousData.activityData.id : '' ,
                       unit                   :      previousData.production_unit ? previousData.production_unit : '',
                       production             :      '',
                       material_id            :      previousData.materialData ? previousData.materialData.id : '',
                       workstation_id         :      previousData.workstation ? previousData.workstation : '',
                       operator_name          :      previousData.operator_name ? previousData.operator_name : '',
                       attachment_id          :      previousData.attachmentData ? previousData.attachmentData.id : '',
                       remarks                :      previousData.remarks ? previousData.remarks : '',
                       ...updateFormState
                    }},() =>
                    {
                        let workstation = previousData.workStationdata;
                        if (workstation) {
                            this.setState({ searchedWorkstation: { value: previousData.workstation, label: workstation.workstation_name } });
                        }
                        this.getLinkedProductivityFormula()
                    }

                            );
                    
                })
            
        }else{
            this.setState({
                addProductivityForm          :           { ...this.initProductivityForm},
                addNewActivity           :           'N',
                addNewUnit               :           'N',
                addNewMaterial           :           'N',
                addNewWorkstation           :        'N',
                addNewOperators             :       'N',
                addNewAttachment            :       'N',
                linkedProductivityFormula   :       null,
                linkedErrorMessage          :       ''
            })
        }
    }
   
    
   

    deleteProductivityInit                      =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', IAM_API_BASE_URL_2 + '/counterlog/productivity/' + id , this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteProductivity) {
                        this.props.afterDeleteProductivity()
                    }
                }).then(() => { });
            }
        });
    }

    getLinkedProductivityFormula        =       ()      =>      {
        let linkedFormula = null;
        if(this.state.addProductivityForm && this.state.addProductivityForm.activity_id){
             linkedFormula = this.state.allProductionFormulas && this.state.allProductionFormulas.length > 0 ? this.state.allProductionFormulas.find(formula => {return(formula.activity_id == this.state.addProductivityForm.activity_id)}) : null;
           
             if (linkedFormula && linkedFormula.formula) {
                let linkedFormulaHavingMeter = linkedFormula.formula.includes('running_hrm') || linkedFormula.formula.includes('running_opc') || linkedFormula.formula.includes('running_km') ? true : false;

                if (linkedFormulaHavingMeter == false) {
                    this.setState({
                        linkedProductivityFormula: linkedFormula,
                        addProductivityForm: {
                            ...this.state.addProductivityForm,
                            unit: linkedFormula.unit_name
                        },
                        linkedErrorMessage: ''
                    })
                } else {
                    this.setState({
                        linkedProductivityFormula: null,
                        linkedErrorMessage: this.state.formulaApplicable == 'Y' && linkedFormulaHavingMeter == true ? 'The formula contain total running (hours/km/opc) hence it cannot be calculated on this screen, Use Asset Activity' : ''
                    })
                }
            }else{
                this.setState({
                    linkedProductivityFormula: null,
                    linkedErrorMessage: ''
                })
            }
        }
    }

    calulateProductivityFormula     =       (e)      =>      {
        if(document.getElementById('input_trip_count')) {
            document.getElementById('input_trip_count').required = false;
            if(this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula && this.state.linkedProductivityFormula.formula.includes('trip_count')) {
                document.getElementById('input_trip_count').required = "required";
            }
        }
        window['trip_count']    =   Number(this.state.addProductivityForm.activity_count);
        if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ) {
           let  list = this.state.allAdditonalAttribute.filter(key => key.type == "numeric"  ? true : false)
            list.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                window[i.formula_key] = this.state.addProductivityForm[name] ? Number(this.state.addProductivityForm[name]) : '';
                if (this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula && this.state?.linkedProductivityFormula?.formula?.includes(i.formula_key) && document.getElementById(i.formula_key)) {
                    document.getElementById(i.formula_key).required = "required"
                }
            });
        }
        try {   
            let net_productivity =  eval(this.state.linkedProductivityFormula.formula);
            this.setState({
                addProductivityForm     :       {
                    ...this.state.addProductivityForm,
                    production                :       net_productivity ? parseFloat(net_productivity).toFixed(2) : ''
                }
            })
        }catch(e){
        }
    }

    submitProductivityForm                  =   (e) =>  {
        e.preventDefault()
        this.setState({addProductivityFormSubmitting : true});
        let additional_attributes = {};
        if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ){
            this.state.allAdditonalAttribute.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addProductivityForm[name] ? this.state.addProductivityForm[name] : '';
            })
        } 
 
         let addProductivityForm     =       this.state.addProductivityForm;
         addProductivityForm         =       {
                                                 activity_new        :        this.state.addProductivityForm.activity_new,
                                                 activity_id         :        this.state.addProductivityForm.activity_id,
                                                 unit                :        this.state.addProductivityForm.unit,
                                                 unit_new            :        this.state.addProductivityForm.unit_new,
                                                 material_id         :        this.state.addProductivityForm.material_id,
                                                 material_new        :        this.state.addProductivityForm.material_new,
                                                 workstation_id      :        this.state.addProductivityForm.workstation_id,
                                                 workstation_new     :        this.state.addProductivityForm.workstation_new, 
                                                 operator_ids        :         this.state.addProductivityForm.operator_ids,
                                                 attachment_id       :        this.state.addProductivityForm.attachment_id,
                                                 attachment_new      :        this.state.addProductivityForm.attachment_new,
                                                 production          :        this.state.addProductivityForm.production,
                                                 activity_count     :   this.state.addProductivityForm.activity_count,
                                                 remarks             :        this.state.addProductivityForm.remarks,
                                                 productivity_id     :        this.state.addProductivityForm && this.state.addProductivityForm.productivity_id ? this.state.addProductivityForm.productivity_id : null,
                                             }
 
        let formData = {...addProductivityForm, additional_attributes : additional_attributes,transaction_id : this.state.transaction_id}
        if(this.state.productivityData){
            HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/counterlog/productivity', this.props.access_token, null, { ...formData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.uploadProductivityModal.hide()
                this.setState({ addProductivityForm: { ...this.initProductivityForm } })
                if (this.props.afterSaveProductivity) {
                    let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveProductivity(this.state.date, asset_id);
                }
            }).then(() => this.setState({ addProductivityFormSubmitting: false }))
        }else{
            HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/counterlog/productivity', this.props.access_token, null, { ...formData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.uploadProductivityModal.hide()
                this.setState({ addProductivityForm: { ...this.initProductivityForm } })
                if (this.props.afterSaveProductivity) {
                    let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveProductivity(this.state.date, asset_id);
                }
            }).then(() => this.setState({ addProductivityFormSubmitting: false }))
        }
     }

    viewProductivityModalInit             =   (shiftDetail, counterlog, assetData = null,data)  =>  {
        this.viewProductivityModal.show()
        this.setState({productivityViewData : null , viewDataLoading : true}
            , () => {
                this.setState({
                    assetData               :   assetData,
                    shift                   :   shiftDetail,
                    counterlog              :   counterlog,
                    date                    :   counterlog ? counterlog.feed_date : '',
                    transaction_id          :   counterlog ? counterlog.transaction_id : '',
                    productivityViewData    :   data ? data : null,
                    viewDataLoading         :   false
                });
         })
    }
    
    uploadProductivityModalJsx              =   ()  =>  {
        let formula                 =   this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula ? this.state.linkedProductivityFormula.formula : null;
        this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 && this.state.allAdditonalAttribute.forEach(production => {
            if (formula && formula.includes(production.formula_key)) {
                formula = formula.replace(production.formula_key, production.name)
            }
        });
        return (
            <div className="modal fade" id={this.uploadProductivityModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>Log Productivity for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" disabled={this.state.addProductivityFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">

                            {this.state.formDataLoading
                                ? <Loader />
                                :
                                <form className={['p-2',].join(' ')} onSubmit={this.submitProductivityForm} id="logProductivityForm">
                                    <div className="row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require">Activity Name</label>
                                        <div className="col-sm-7">
                                            {this.state.addNewActivity == "Y"
                                                ? <input
                                                    name="activity_new"
                                                    type="text"
                                                    value={this.state.addProductivityForm.activity_new}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                                    placeholder="Please enter Activity Name"
                                                    required={true}
                                                />
                                                : <TapSelect
                                                    options={this.state.allActivityList}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addProductivityForm', 'activity_id');
                                                        this.setState({
                                                            allProductionUnits: selectedOption && selectedOption.production_units && selectedOption.production_units.length > 0 ? selectedOption.production_units.map(p => { return ({ value: p.measuring_unit, label: p.measuring_unit }) }) : []
                                                        }, (e) => { this.getLinkedProductivityFormula(); this.calulateProductivityFormula(e); })
                                                    }}
                                                    isSearchable={true}
                                                    value={this.state.allActivityList.find(s => this.state.addProductivityForm.activity_id == s.value)}
                                                    isClearable={true}
                                                    placeholder="Select Activity Name"
                                                    autoCompelete="off"
                                                    required={true}
                                                    autoFocus={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />}
                                                <div className='text-danger text-sm my-1'>{this.state.linkedErrorMessage ? this.state.linkedErrorMessage : ""}</div>
                                        </div>
                                        {this.state.runtime_activity_creation == 'Y' &&  <div className="col-sm-2">
                                            <input
                                                name="addNewActivity"
                                                type="checkbox"
                                                value={this.state.newActivity}
                                                onChange={(e) => {
                                                    let allProductionUnits = [];
                                                    let activityListContainingProduction = this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.filter(p => { return (p.production_units && p.production_units.length > 0 ? true : false) });
                                                    let allProductionUnitList = activityListContainingProduction && activityListContainingProduction.length > 0 && allProductionUnits.concat(activityListContainingProduction.flatMap((d) => { return d.production_units.map(p => { return ({ value: p.measuring_unit, label: p.measuring_unit }) }) }))
                                                    const key = 'value';
                                                    const UniqueProductionUnitByKey = allProductionUnitList && allProductionUnitList.length > 0 && [...new Map(allProductionUnitList.map(item => [item[key], item])).values()];

                                                    this.setState({
                                                        addNewActivity: this.state.addNewActivity == 'N' ? 'Y' : 'N',
                                                        allProductionUnits: this.state.addNewActivity == 'N' && UniqueProductionUnitByKey && UniqueProductionUnitByKey.length > 0 ? UniqueProductionUnitByKey : [],
                                                        addProductivityForm: this.state.addNewActivity == 'N' ? { ...this.state.addProductivityForm, activity_id: '' } : { ...this.state.addProductivityForm }
                                                    })
                                                }}
                                                checked={this.state.addNewActivity == 'Y'}
                                                className="form-check-input"
                                                id="newActivity"

                                            />
                                            <label className="form-check-label mx-2 text-sm" htmlFor="newActivity">Add New </label>
                                        </div>}
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require">Net Productivity</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="production"
                                                type="number"
                                                value={this.state.addProductivityForm.production}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                                placeholder="Please enter Net Productivity"
                                                required={true}
                                                step="0.01" min="0"
                                                disabled={this.state.linkedProductivityFormula ? true : false}
                                            />
                                            <div className='form-text text-sm my-1'>{this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula ? <span>Net Productivity = {formula}</span> : ""}</div>
                                        </div>

                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require">Productivity Units</label>
                                        <div className="col-sm-7">
                                            {this.state.addNewUnit == "Y"
                                                ? <input
                                                    name="unit_new"
                                                    type="text"
                                                    value={this.state.addProductivityForm.unit_new}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                                    placeholder="Please enter Productivity Unit"
                                                    required={true}
                                                />
                                                :
                                                <TapSelect
                                                    options={this.state.allProductionUnits}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addProductivityForm', 'unit');

                                                    }}
                                                    isSearchable={true}
                                                    value={this.state.allProductionUnits.find(s => this.state.addProductivityForm.unit == s.value)}
                                                    isClearable={true}
                                                    placeholder="Select Productivity"
                                                    autoCompelete="off"
                                                    required={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    isDisabled={this.state.linkedProductivityFormula ? true : false}
                                                />
                                            }
                                        </div>
                                       {this.state.runtime_productivity_unit_creation == 'Y' && <div className="col-sm-2">
                                            <input
                                                name="addNewUnit"
                                                type="checkbox"
                                                value={this.state.addNewUnit}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewUnit: this.state.addNewUnit == 'N' ? 'Y' : 'N',
                                                        addProductivityForm: this.state.addNewUnit == 'N' ? { ...this.state.addProductivityForm, unit: '' } : { ...this.state.addProductivityForm }
                                                    })
                                                }}
                                                checked={this.state.addNewUnit == 'Y'}
                                                className="form-check-input"
                                                id="addNewUnit"
                                                disabled={this.state.linkedProductivityFormula ? true : false}

                                            />
                                            <label className="form-check-label text-sm mx-2" htmlFor="addNewUnit">Add New </label>
                                        </div>}
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">No. of Trip/Session</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="activity_count"
                                                id="input_trip_count"
                                                type="number"
                                                value={this.state.addProductivityForm.activity_count}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addProductivityForm", (e) => {
                                                    this.calulateProductivityFormula(e);
                                                })}
                                                placeholder="Please enter No. of Trip / Session"
                                                step="0.01"
                                                min="0"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Material Name</label>
                                        <div className="col-sm-7">
                                            {this.state.addNewMaterial == "Y"
                                                ? <input
                                                    name="material_new"
                                                    type="text"
                                                    value={this.state.addProductivityForm.material_new}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                                    placeholder="Please enter Material"

                                                />
                                                : <TapSelect
                                                    options={this.state.allMaterialList}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addProductivityForm', 'material_id');
                                                    }}
                                                    isSearchable={true}
                                                    value={this.state.allMaterialList.find(s => this.state.addProductivityForm.material_id == s.value)}
                                                    isClearable={true}
                                                    placeholder="Select Material"
                                                    autoCompelete="off"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />}
                                        </div>
                                        {this.state.runtime_material_creation == 'Y' && <div className="col-sm-2">
                                            <input
                                                name="addNewMaterial"
                                                type="checkbox"
                                                value={this.state.addNewMaterial}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewMaterial: this.state.addNewMaterial == 'N' ? 'Y' : 'N',
                                                        addProductivityForm: this.state.addNewMaterial == 'N' ? { ...this.state.addProductivityForm, material_id: '' } : { ...this.state.addProductivityForm }
                                                    })
                                                }}
                                                checked={this.state.addNewMaterial == 'Y'}
                                                className="form-check-input"
                                                id="addNewMaterial"

                                            />
                                            <label className="form-check-label text-sm mx-2" htmlFor="addNewMaterial">Add New </label>
                                        </div>}
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Workstation</label>
                                        <div className="col-sm-7">
                                            {this.state.addNewWorkstation == "Y" 
                                                ? <input
                                                    name="workstation_new"
                                                    type="text"
                                                    value={this.state.addProductivityForm.workstation_new}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                                    placeholder="Please enter Workstation"

                                                />
                                                : 
                                                <InputWorkstationSearch
                                                placeholder="Please Select Workstation"
                                                changeEvent={(selectedOption) => {
                                                    this.setState({
                                                        searchedWorkstation: selectedOption ? selectedOption : null,
                                                        addProductivityForm: { ...this.state.addProductivityForm, workstation_id: selectedOption ? selectedOption.value : '' }
                                                    })

                                                }}
                                                menuPlacement="top"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                                            />
                                                // <TapSelect
                                                //     options={this.state.allWorkstationList}
                                                //     changeEvent={(selectedOption) => {
                                                //         this.tapSelectChange(selectedOption, 'addProductivityForm', 'workstation_id');
                                                //     }}
                                                //     isSearchable={true}
                                                //     value={this.state.allWorkstationList.find(s => this.state.addProductivityForm.workstation_id == s.value)}
                                                //     isClearable={true}
                                                //     placeholder="Select WorkStation"
                                                //     autoCompelete="off"
                                                //     containerHeight="30px"
                                                //     fontSize="93%"
                                                // />
                                                }
                                        </div>
                                        {this.props.permissions.permissions.includes('iam-workstation-add') && <div className="col-sm-2">
                                            <input
                                                name="addNewWorkstation"
                                                type="checkbox"
                                                value={this.state.addNewWorkstation}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewWorkstation: this.state.addNewWorkstation == 'N' ? 'Y' : 'N',
                                                        addProductivityForm: this.state.addNewWorkstation == 'N' ? { ...this.state.addProductivityForm, workstation_id: '' } : { ...this.state.addProductivityForm }
                                                    })
                                                }}
                                                checked={this.state.addNewWorkstation == 'Y'}
                                                className="form-check-input"
                                                id="addNewWorkstation"

                                            />
                                            <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstation">Add New </label>
                                        </div>}
                                    </div>
                                    {this.state.permissions && this.state.permissions.group_modules && this.state.permissions.group_modules.includes("hrm") ?
                                        <div className="row align-items-center my-2">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">Employee</label>
                                            <div className="col-sm-7">
                                                <InputEmployeeSearch
                                                    placeholder="Please Select Employee"
                                                    changeEvent={(selectedOption) => {
                                                        this.setState({
                                                            searchedEmployee: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                        });
                                                        this.tapSelectChange(selectedOption, 'addProductivityForm', 'operator_ids')
                                                    }}
                                                    transaction_date={this.state.counterlog && this.state.counterlog.feed_datetime ? this.state.counterlog.feed_datetime : null}
                                                    menuPlacement="top"
                                                    search_site_ids={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    isMulti={true}
                                                    value={this.state.searchedEmployee && this.state.searchedEmployee.length > 0 ? this.state.searchedEmployee.map(a => { a.label = a.label; return a; }) : []}
                                                />
                                            </div>
                                        </div>
                                        : null}
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Attachment</label>
                                        <div className="col-sm-7">
                                            {this.state.addNewAttachment == "Y"
                                                ? <input
                                                    name="attachment_new"
                                                    type="text"
                                                    value={this.state.addProductivityForm.attachment_new}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                                    placeholder="Please enter Attachment"

                                                />
                                                : <TapSelect
                                                    options={this.state.allAttachmentList}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addProductivityForm', 'attachment_id');
                                                    }}
                                                    isSearchable={true}
                                                    value={this.state.allAttachmentList.find(s => this.state.addProductivityForm.attachment_id == s.value)}
                                                    isClearable={true}
                                                    placeholder="Select Attachment"
                                                    autoCompelete="off"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="addNewAttachment"
                                                type="checkbox"
                                                value={this.state.addNewAttachment}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewAttachment: this.state.addNewAttachment == 'N' ? 'Y' : 'N',
                                                        addProductivityForm: this.state.addNewAttachment == 'N' ? { ...this.state.addProductivityForm, attachment_id: '' } : { ...this.state.addProductivityForm }
                                                    })
                                                }}
                                                checked={this.state.addNewAttachment == 'Y'}
                                                className="form-check-input"
                                                id="addNewAttachment"

                                            />
                                            <label className="form-check-label text-sm mx-2" htmlFor="addNewAttachment">Add New </label>
                                        </div>
                                    </div>
                                    

                                    {
                                        this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0
                                            ? (
                                                <Ax>

                                                    {
                                                        this.state.allAdditonalAttribute.map((i, k) => {
                                                            let name = 'additional_attributes_' + i.key;
                                                            let input = null;
                                                            if (i.type == 'text') {
                                                                input = <input
                                                                    name={name}
                                                                    type="text"
                                                                    value={this.state.addProductivityForm[name] ? this.state.addProductivityForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addProductivityForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'numeric') {
                                                                input = <input
                                                                    name={name}
                                                                    type="number"
                                                                    id={i.formula_key}
                                                                    value={this.state.addProductivityForm[name] ? this.state.addProductivityForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addProductivityForm', (e) => {
                                                                        this.calulateProductivityFormula(e)
                                                                    })}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'date') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.addProductivityForm[name]
                                                                            ? moment(this.state.addProductivityForm[name]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.formDateHandler(name, value, 'addProductivityForm')}
                                                                    dateFormat="dd-MMM-yyyy"
                                                                    className={"form-control form-control-sm"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${i.name}`}

                                                                />
                                                            } else if (i.type == 'dropdown') {
                                                                let options = i.options.map((t) => { return { value: t, label: t } });
                                                                input = <TapSelect
                                                                    menuPlacement="top"
                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addProductivityForm', name)}
                                                                    options={options}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    value={options.find(o => o.value == this.state.addProductivityForm[name])}
                                                                    placeholder={`Please Select ${i.name}`}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                />
                                                            }

                                                            return (<div key={k} className="row align-items-center mb-3 mt-3">
                                                                <div className="col-sm-3 col-form-label-sm col-form-label">{i.name}</div>
                                                                <div className="col-sm-7">{input}</div>

                                                            </div>);
                                                        })
                                                    }
                                                </Ax>
                                            )
                                            : null
                                    }
                                    <div className="row my-2">
                                        <label className="col-sm-3 col-form-label ">Remarks</label>
                                        <div className="col-sm-7">

                                            <textarea
                                                name="remarks"
                                                className="form-control"
                                                placeholder="Enter Remarks"
                                                onChange={(e) => this.formInputHandler(e, 'addProductivityForm')}
                                                style={{ height: "80px", fontSize: "93%" }}
                                                autoComplete="off"
                                                value={this.state.addProductivityForm.remarks}
                                            />

                                        </div>
                                    </div>

                                    {/* <div className="tab_content_wrapper fw-bold my-2">Copy Productivity to other Asset</div>
                                    <div className="row my-2">
                                        <label className="col-sm-3 col-form-label "> Asset</label>
                                        <div className="col-sm-7">

                                          
                                          <InputAssetSearch
                                             changeEvent={(selectedOption) => {
                                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                          />

                                        </div>
                                    </div> */}
                                </form>
                            }
                        </div>
                        <div className="modal-footer d-flex justify-content-between row">
                            <div className="col text-start">
                                <input
                                    name="copy_reading"
                                    type="checkbox"
                                    value={this.state.copy_reading}
                                    onChange={(e) => {
                                        this.setState({ copy_reading: this.state.copy_reading == 'N' ? 'Y' : 'N' }, () => this.copyPreviousHandler())
                                    }}
                                    checked={this.state.copy_reading == 'Y'}
                                    className="form-check-input"
                                    id="copy_readingProduct"
                                    disabled={this.state.previousProductivityData ? false : true || this.state.productivityData ? true : false}
                                />
                                <label className="form-check-label mx-2" htmlFor="copy_readingProduct">Copy Reading from Previous Productivity </label>
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn btn-secondary mx-2" disabled={this.state.addProductivityFormSubmitting} data-bs-dismiss="modal" onClick={() => { this.setState({ addProductivityForm: { ...this.initProductivityForm } }) }}>Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.formulaApplicable == 'Y' && this.state.linkedErrorMessage || this.state.addProductivityFormSubmitting ? true : false} form="logProductivityForm">Update {this.state.addProductivityFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    viewProductivityModalJsx              =   ()  =>  {
        let productivity = this.state.productivityViewData
        return (
            <div className="modal fade" id={this.viewProductivityModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>View Productivity for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" disabled={this.state.addProductivityFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                            {this.state.productivityViewData
                                ? <ProductivityView
                                    productivityId={this.state.productivityViewData.productivity_id}
                                    counterlog={this.state.counterlog}
                                />
                                : null}
                        </div>
                        <div className="modal-footer d-flex justify-content-between row">
                            <div className="col text-start">

                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" >Close</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                              =   ()  =>  {
        return (
            <Ax>
            {this.uploadProductivityModalJsx()}
            {this.viewProductivityModalJsx()}
            </Ax>
        )
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(Productivity);

