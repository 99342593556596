import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import tapIcon from '../../../../services/TapIcon';
import AssetDetailHorizontalCard from '../../includes/AssetDetailHorizontalCard';
import TapSelect from '../../../../components/ui/TapSelect';
import Status from '../../../../components/ui/Status';
import AssetHeader from '../../includes/AssetHeader';
import { Helmet } from 'react-helmet';

class SettingsEdit extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.settingsEditFormInit           =       {
           name                             :       '',
           alias_name                       :       '',
           asset_matrix                     :       '',
           asset_type                       :       '',
           section_name                     :       '',
           sub_section_name                 :       '',
           description                      :       '',
           status                           :        1,
           enable_parameter                 :       'N',
           parameter_type                   :       '',
           options                          :       [{key : 'ok' , val : 'Checked and OK' },
                                                    {key : 'required_attention' , val : 'May Required Attention' },
                                                    {key : 'required_immediateattention' , val : 'Required Immediate Attention' }],
           from                             :       '',
           to                               :       '',
           critical_level                   :       '',
           interval                         :       []
        }

        this.state                          =       {
            access_token                    :       '',
            assetDetail                     :       null,
            assetId                         :       null,
            settingsEditForm                :       {...this.settingsEditFormInit},
            settingsEditFormSubmitting      :       false,
            scs_id                          :       null,
            scsDetail                      :       null,
            scs_details_loading             :       false,

        }
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        this.setState({assetId : pr.match?.params?.id,scs_id : pr.match?.params?.scs_id}
            ,() => {
                this.getAsssetDetails(pr.match?.params?.id);
                
            })
    }

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
            this.getServiceChecksheetDetails(this.state.scs_id)
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    allExtraParameterOption         =       [
        {value : 'range' , label : 'Range Start - Close'},
        {value : 'options' , label : 'Predefined Drop-down'},
    ]
    
    getServiceChecksheetDetails     =       (id)        =>  {
        this.setState({scs_details_loading   :    true})
       HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/scs/asset/scs_setting_detail/' + id , this.props.access_token, {}, {}, (response) => {
           let respData = response.data;
           let meterReading                =   {};
           if(respData && respData.data && respData.data.interval && respData.data.interval.length > 0){
                respData.data.interval.forEach((meter, mk) => {
                    let input_state_key = meter.name;
                    meterReading[input_state_key] = meter.interval;
                });
           }

           this.setState({    scsDetail         :  respData.data  ,
                            settingsEditForm      :       {
                                ...this.state.settingsEditFormInit,
                                ...respData.data,
                                ...meterReading,
                                name                             :       respData.data.name,
                                alias_name                       :       respData.data.alias_name,
                                asset_matrix                     :       this.state.assetDetail ? this.state.assetDetail.asset_matrix_unique_id : "",
                                asset_type                       :       this.state.assetDetail  ? this.state.assetDetail.asset_type_name : "",
                                sub_section_name                 :       respData.data.sub_section ? respData.data.sub_section.subcomponent : '',
                                section_name                     :       respData.data.section ? respData.data.section.component : '',
                                description                      :       respData.data.description ? respData.data.description : '',
                                status                           :       respData.data.status,
                                interval                         :       respData.data.interval && respData.data.interval.length > 0 ? respData.data.interval.map(st => {return({...st,[st.key] : st.status,[st.name] : st.interval})}) : [],
                                enable_parameter                 :       respData.data.enable_parameter ? respData.data.enable_parameter : 'N',
                                parameter_type                   :       respData.data.parameter ? respData.data.parameter.type : '',
                                to                               :        respData.data.parameter && respData.data.parameter.type == "range" ? respData.data.parameter.to : '',
                                from                             :        respData.data.parameter && respData.data.parameter.type == "range" ? respData.data.parameter.from : '',
                                critical_level                   :        respData.data.parameter && respData.data.parameter.type == "range" ? respData.data.parameter.critical_level : '', 
                                options                          :       [{key : 'ok' , val : 'Checked and OK' },
                                                                            {key : 'required_attention' , val : 'May Required Attention' },
                                                                            {key : 'required_immediateattention' , val : 'Required Immediate Attention' }],
                               
                          }  })
       }).then(() => this.setState({scs_details_loading   :    false }));
    }

    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

   

    closeSettings                             =       ()      =>  {
        this.props.history.goBack()
    }

    submitSettingsForm              =           (e)         =>      {
        e.preventDefault();

        let settingsEditForm;
         if(this.state.settingsEditForm.parameter_type == "range"){
           settingsEditForm             =            {...this.state.settingsEditForm,
                                                            parameter : {
                                                                type : this.state.settingsEditForm.parameter_type,
                                                                to   : this.state.settingsEditForm.to ,
                                                                from : this.state.settingsEditForm.from,
                                                                critical_level      :       this.state.settingsEditForm.critical_level, 
                                                            },
                                                     }
         }else{
            settingsEditForm             =            {...this.state.settingsEditForm,
                                                            parameter : {
                                                                type : this.state.settingsEditForm.parameter_type,
                                                                options : this.state.settingsEditForm.options 
                                                            }
                                                        }
          }
        
        this.setState({ settingsEditFormSubmitting: true });
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/scs/asset/scs_setting_edit', this.props.access_token, {}, { ...settingsEditForm, scs_setting_id: this.state.scs_id }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.closeSettings()
        }).then(() => this.setState({ settingsEditFormSubmitting: false }));
    
    }

    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}  />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div id="assetCard" className="collapse pr-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail}/>}
        </div>)
    }
    
    subHeaderJsx        =       ()      =>      {
        return (<div className="row page_title m0 " style={{ height: "45px" }}>
            <div className="col-sm-4"><h3 style={{ paddingTop: "13px" }}>Edit Service Checksheet</h3></div>
            <div className='col-sm-4 text-center'><button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>

                Asset Card</button></div>
            <div className=" col-sm-4 text-end mt-2 ">

                <button type="button" className="btn btn-secondary" onClick={this.closeSettings}>
                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" />
                </button>
            </div>
        </div>)
    }

    settingsFormJsx         =       ()      =>      {
        let from                =   this.state.settingsEditForm.from;
        let to                  =   this.state.settingsEditForm.to;
        let critical_level      =   this.state.settingsEditForm.critical_level;
        let danderlevel,successlevel;

        if(from >= 0 && to >= 0 && critical_level > 0) {
            if(from > to && critical_level > to && critical_level < from) {
                danderlevel     =   parseInt(((critical_level-to)/(from-to))*100);
                successlevel    =   100 - danderlevel;
            }else if(to > from && critical_level > from && critical_level < to) {
                successlevel    =   parseInt(((critical_level-from)/(to-from))*100);
                danderlevel     =   100 - successlevel;
            }             
        }

        return (<div style={{ maxHeight: "74vh" }} className="bg-white mr-2 mt-1 pageTbl" id="serviceChecksheetSettingsEdit">
            {this.state.scs_details_loading || this.state.asset_loading
                ? <div className='text-center'><Loader /></div>
                : <Ax>
                    {this.state.scsDetail ?
                        <form className={['p-3', 'filter_form'].join(' ')} onSubmit={this.submitSettingsForm}>
                            <div className="row my-3">
                                <label className="col-sm-4 col-form-label  require">Name</label>
                                <div className="col-sm-4">
                                    <input
                                        name="name"
                                        type="text"
                                        value={this.state.settingsEditForm.name}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                        placeholder="Please enter Name"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3">
                                <label className="col-sm-4 col-form-label require">Alias Name</label>
                                <div className="col-sm-4">
                                    <input
                                        name="alias_name"
                                        type="text"
                                        value={this.state.settingsEditForm.alias_name}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                        placeholder="Please enter Alias Name"
                                    />
                                </div>
                            </div>
                            <div className="row my-3">
                                <label className="col-sm-4 col-form-label">Asset Matrix ID</label>
                                <div className="col-sm-4">
                                    <input
                                        name="asset_matrix"
                                        type="text"
                                        value={this.state.settingsEditForm.asset_matrix}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                        placeholder="Please enter Asset Matrix ID"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3">
                                <label className="col-sm-4 col-form-label">Asset Type</label>
                                <div className="col-sm-4">
                                    <input
                                        name="asset_type"
                                        type="text"
                                        value={this.state.settingsEditForm.asset_type}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                        placeholder="Please enter Asset  Type"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3">
                                <label className="col-sm-4 col-form-label">Section</label>
                                <div className="col-sm-4">
                                    <input
                                        name="section_name"
                                        type="text"
                                        value={this.state.settingsEditForm.section_name}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                        placeholder="Please enter Section Name"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3">
                                <label className="col-sm-4 col-form-label">Sub-Section</label>
                                <div className="col-sm-4">
                                    <input
                                        name="sub_section_name"
                                        type="text"
                                        value={this.state.settingsEditForm.sub_section_name}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                        placeholder="Please enter Sub Section Name"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3">
                                <label className="col-sm-4 col-form-label">Description</label>
                                <div className="col-sm-4">
                                    <textarea
                                        name="description"
                                        value={this.state.settingsEditForm.description}
                                        onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                        className="form-control"
                                        style={{ height: "40px" }}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row  my-3">
                                <div className="col-sm-4">
                                    <label className="col-sm-4 col-form-label">Tracking Status</label>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="status"
                                            type="radio"
                                            value={1}
                                            onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                            checked={this.state.settingsEditForm.status == 1}
                                            className="form-check-input"
                                            required={true}
                                            id="trackingStatusFormEnable"
                                        />
                                        <label className="form-check-label mr-2" htmlFor="trackingStatusFormEnable">Enable</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="status"
                                            type="radio"
                                            value={2}
                                            onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                            checked={this.state.settingsEditForm.status == 2}
                                            className="form-check-input "
                                            required={true}
                                            id="trackingStatusFormDisable"
                                        />
                                        <label className="form-check-label" htmlFor="trackingStatusFormDisable">Disable</label>
                                    </div>
                                </div>

                            </div> {this.state.settingsEditForm.status == 1
                                ? <Ax>
                                    {this.state.settingsEditForm && this.state.settingsEditForm.interval && this.state.settingsEditForm.interval.map((int, key) => {
                                        
                                        return (
                                            <div className="row my-3" key={key}>
                                                <div className="col-sm-4">
                                                    <label htmlFor={`site_id_${key}`} className="col-form-label">{int.label}</label>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            name={`enable_${int.key}`}
                                                            type="radio"
                                                            value="Y"
                                                            onChange={(e) => {
                                                                this.setState((prevState) => ({
                                                                    settingsEditForm: {
                                                                        ...prevState.settingsEditForm,
                                                                        [int.key]: e.target.value,
                                                                    },
                                                                }));
                                                            }}
                                                            checked={this.state.settingsEditForm[int.key] === 'Y'}
                                                            className="form-check-input"
                                                            id={`enable_${int.key}`}
                                                        />
                                                        <label className="form-check-label" htmlFor={`enable_${int.key}`}>Enable</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            name={`enable_${int.key}`}
                                                            type="radio"
                                                            value="N"
                                                            onChange={(e) => {
                                                                this.setState((prevState) => ({
                                                                    settingsEditForm: {
                                                                        ...prevState.settingsEditForm,
                                                                        [int.key]: e.target.value,
                                                                    },
                                                                }));
                                                            }}
                                                            checked={this.state.settingsEditForm[int.key] === 'N'}
                                                            className="form-check-input"
                                                            id={`disable_${int.key}`}
                                                        />
                                                        <label className="form-check-label" htmlFor={`disable_${int.key}`}>Disable</label>
                                                    </div>
                                                </div>



                                                <div className="col-sm-2">
                                                    <input
                                                        name={this.state.settingsEditForm[int.name]}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Enter ${int.label}`}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                settingsEditForm: {
                                                                    ...this.state.settingsEditForm,
                                                                    [int.name]: e && e.target && e.target.value ? e.target.value : '',
                                                                }
                                                            })
                                                        }}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                        autoComplete="off"
                                                        value={this.state.settingsEditForm[int.name] || ''}
                                                        key={key}
                                                        disabled={this.state.settingsEditForm[int.key] === 'N'}
                                                    />
                                                </div>




                                            </div>
                                        );
                                    })}

                                </Ax>
                                : null}
                            {/* {this.state.settingsEditForm.status == 1
                                ? <Ax>
                                    {this.state.settingsEditForm && this.state.settingsEditForm.interval && this.state.settingsEditForm.interval.map((int, key) => {
                                        
                                        return (
                                            <div className="row my-3" key={key}>
                                                <div className="col-sm-4">
                                                    <label htmlFor={`site_id_${key}`} className="col-form-label">{int.label}</label>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            name={`${int.key}`}
                                                            type="radio"
                                                            value="Y"
                                                            onChange={(e) => this.dynamicInputHandlerTwoLevelByKeyNameVal(e.target.value, int.key, key, 'interval', 'settingsEditForm')}
                                                            checked={this.state.settingsEditForm.interval[key][`${int.key}`] === "Y"}
                                                            className="form-check-input"
                                                            id={`enable_${int.key}`}
                                                        />
                                                        <label className="form-check-label" htmlFor={`enable_${int.key}`}>Enable</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            name={`${int.key}`}
                                                            type="radio"
                                                            value="N"
                                                            onChange={(e) => this.dynamicInputHandlerTwoLevelByKeyNameVal(e.target.value, int.key, key, 'interval', 'settingsEditForm')}
                                                            checked={this.state.settingsEditForm.interval[key][`${int.key}`] === "N"}
                                                            className="form-check-input"
                                                            id={`disable_${int.key}`}
                                                        />
                                                        <label className="form-check-label" htmlFor={`disable_${int.key}`}>Disable</label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-2">
                                                    <input
                                                        name={int.name}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={`Enter ${int.label}`}
                                                        onChange={(e) => {
                                                            const updatedInterval = [...this.state.settingsEditForm.interval];
                                                            updatedInterval[key] = {
                                                                ...updatedInterval[key],
                                                                [int.name]: e.target.value
                                                            };
                                                            this.setState({
                                                                settingsEditForm: {
                                                                    ...this.state.settingsEditForm,
                                                                    interval: updatedInterval
                                                                }
                                                            });
                                                        }}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                        autoComplete="off"
                                                        value={this.state.settingsEditForm.interval[key][int.name] || ''}
                                                        key={key}
                                                    />
                                                </div>




                                            </div>
                                        );
                                    })}

                                </Ax>
                                : null} */}

                            <div className="row  my-3">
                                <div className="col-sm-4">
                                    <label className="col-sm-12 col-form-label">Extra Parameter Required ?</label>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="enable_parameter"
                                            type="radio"
                                            value='Y'
                                            onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                            checked={this.state.settingsEditForm.enable_parameter == 'Y'}
                                            className="form-check-input"
                                            required={true}
                                            id="extraparameterFormEnable"
                                        />
                                        <label className="form-check-label" htmlFor="extraparameterFormEnable">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="enable_parameter"
                                            type="radio"
                                            value='N'
                                            onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                            checked={this.state.settingsEditForm.enable_parameter == 'N'}
                                            className="form-check-input"
                                            required={true}
                                            id="extraparamterFormDisable"
                                        />
                                        <label className="form-check-label" htmlFor="extraparamterFormDisable">No</label>
                                    </div>
                                </div>


                            </div>
                            {this.state.settingsEditForm.enable_parameter == 'Y'
                                ? <Ax>
                                    <div className="row  my-3">
                                        <div className="col-sm-4">
                                            <label className="col-sm-12 col-form-label">Extra Parameter Option ?</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                options={this.allExtraParameterOption}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'settingsEditForm', 'parameter_type')}
                                                isClearable={true}
                                                value={this.allExtraParameterOption.find(s => this.state.settingsEditForm.parameter_type == s.value)}
                                                placeholder="Select Extra Parameter Option"
                                                menuPlacement="top"
                                            />
                                        </div>
                                    </div>
                                    {this.state.settingsEditForm.parameter_type == 'options'
                                        ? <div className="row  my-3">
                                            <div className="col-sm-4">
                                                <label className="col-sm-12 col-form-label">Available Options</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <ol>
                                                    {this.state.settingsEditForm.options && this.state.settingsEditForm.options.length > 0
                                                        ? this.state.settingsEditForm.options.map((o, i) => {
                                                            return (<li className='my-1' ><Status color={o.key == 'ok' ? '#198754' : o.key == 'required_attention' ? '#ffc107' : '#dc3545'}>{o.val}</Status></li>)
                                                        })
                                                        : null}
                                                </ol>
                                            </div>
                                        </div>
                                        : <Ax>
                                            <div className="row  my-3">
                                                <div className="col-sm-4">
                                                    <label className="col-sm-12 col-form-label">Range ( Start - Close )</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="from"
                                                        type="text"
                                                        value={this.state.settingsEditForm.from}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                        placeholder="Please enter Start Range - "

                                                    />

                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="to"
                                                        type="text"
                                                        value={this.state.settingsEditForm.to}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                        placeholder="Please enter Close Range"

                                                    />
                                                </div>
                                            </div>
                                            <div className="row  my-3">
                                                <div className="col-sm-4">
                                                    <label className="col-sm-12 col-form-label">Critical Level</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="critical_level"
                                                        type="number"
                                                        value={this.state.settingsEditForm.critical_level}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                        placeholder="Enter Critical Level "

                                                    />

                                                </div>
                                                <div className="col-sm-4">
                                                    {danderlevel && successlevel
                                                        ? <Ax><div className="progress height20 bg-danger">
                                                            <div className="progress-bar bg-success" style={{ width: `${successlevel * 90 / 100}%` }}>
                                                                <span className="badge bg-success">{successlevel}%</span>
                                                                <span className="sr-only">{successlevel}% Complete (success)</span>
                                                            </div>
                                                            <div className="progress-bar bg-warning" style={{ width: `${danderlevel * 90 / 100}%` }}>
                                                                <span className="badge text-right bg-warning">{danderlevel}%</span>
                                                                <span className="sr-only">{danderlevel}% Complete (danger)</span>
                                                            </div>
                                                        </div>

                                                        </Ax>
                                                        : null}

                                                </div>

                                            </div>
                                            <div className="row  my-3">

                                                <div className=" offset-4 col-sm-4">
                                                    <ol>
                                                        {this.state.settingsEditForm.options && this.state.settingsEditForm.options.length > 0
                                                            ? this.state.settingsEditForm.options.map((o, i) => {
                                                                return (<li className='my-1' ><Status color={o.key == 'ok' ? '#198754' : o.key == 'required_attention' ? '#ffc107' : '#dc3545'}>{o.val}</Status></li>)
                                                            })
                                                            : null}
                                                    </ol>
                                                </div>
                                            </div>
                                        </Ax>}

                                </Ax>
                                : null}
                            <div className='row'>
                                <div className="col-12 text-end fixed_footer">
                                    <button type="button" disabled={this.state.settingsEditFormSubmitting} onClick={this.closeSettings} className="btn btn-secondary ms-2">Cancel</button>
                                    <button type="submit" disabled={this.state.settingsEditFormSubmitting} className="btn btn-primary ms-2">
                                        Save {this.state.settingsEditFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </div>
                        </form>
                        : null}
                </Ax>}
        </div>)
    }


    render                      =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Edit - Service CheckSheet Settings</title></Helmet>
             {this.pageTitleBarJsx()}
             <div className="container-fluid pl5">
                <div className='page_containt row'>
                    <div className='pr0 col-sm-12'>
                        {this.horizontalAssetCardJsx()}
                        {this.subHeaderJsx()}
                        {this.settingsFormJsx()}
                     </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SettingsEdit);