import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import TapSelect from '../../../components/ui/TapSelect';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import { Helmet } from 'react-helmet';
import ItemSearchModal from '../../items/itemSearchModal';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import WarehouseAddress from '../../inventory/includes/WarehouseAddress';
import Customer from '../includes/Customer';
import InputDealSearch from '../includes/InputDealSearch';
import AddCustomerModal from '../includes/AddCustomerModal';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';

class SalesAllocationAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initAllocationForm = {
            transaction_date: new Date(),
            warehouse_id: '',
            voucher_number: '',
            voucher_date: '',
            deal_id:'',
            items: [],
            notes: '',
            adjustment_amount: 0,
            additional_attributes: [],
        }

        this.initAllocationItemRows = [{
            item: null,
            stock_id: null,
            remark: '',
            amount: 0,
            qty: 0,
            stock_qty: 0,
            free_qty: 0,
            rate: 0,
            current_stocks: [],
            selected_stock: null,
            stock_loading: false,
            allApiCallsCompleted: false,
        }];

        this.initalState = {
            allocationFormDataLoaded: false,
            allWarehouses: [],
            allGstSlabs: [],
            allStatus: [],
            isc_configuration: null,
            track_batch_expiry: 'N',
            negative_stock: 'N',
            allocationForm: this.initAllocationForm,
            saveFormSubmitting: false,
            allocationDetail: null,
            selectedSite: null,
            warehouseDetailLoading: false,
            warehouseDetail: null,
            warehouseSites: [],
            selectedAsset: null,
            formData: '',
            allocationItemRows: [...this.initAllocationItemRows],
            pickSingleItem: null,
            itemRowNumber: 0,
            cloneallocation: false,
            consumtionHistoryList: [],
            allocationDetailLoading: false,
            only_stocked_item: 'Y',
            searchedEmployee: null,
            Customer_details_loading: false,
            buttonDisabled: false,
            allAllocationsFor: [],
            itemSearchedDetails: null,
            allSOListing: [],
            allSelectedSOList: [],
            allItemWiseSOListing: [],
            so_listing_loading: false,
            searchedWorkstation: null,
            itemSOLoaded: false,
            showItemSearchModal: false,
            showItemAddModal: false,
            showItemEditModal: false,
            CustomerDetails: null,
            searchedDeal: null,
            selectedCustomerAdress: null,
            CustomerAddressList: [],
            searchedCustomer: null,
            searchedCustomerAdressList: [],
            selectedShippingAddress: null,
            selectedBillingAddress: null,
            sales_order: "sales_work_order",
            sales_order_list: [],
            allSalesOrder: [],
            selectedSalesOrder: false,
            batch_data: [],
            listing_loading: [],
            selectedBatchNumber: '',
            selectedExpiryDate: '',
            freeQty: '',
            qty: "",
            selectedBatchData: [],
            allCheckboxesDisabled: true,
            choose_batch_number: true,
            allItemCurrentStocks: { data: {} },
            selected_batch_no_item_id: null
        }
        this.state = {
            ...this.initalState,
            showItemDetailModal: false,
            selectedItemDetails: null,
            loadingItemDetails: false
        };
        this.addItemModalRef = React.createRef();
        this.editItemModalRef = React.createRef();
        this.allocationHistoryRef = React.createRef();
        this.itemDetailsRef = React.createRef();
        this.itemSearchRef = React.createRef();
        this.addCustomerModalRef = React.createRef();
        this.formDataUrl = INVENTORY_API_BASE_URL_2 + '/sales/allocation_add_form_data';
        this.allocationUrl = INVENTORY_API_BASE_URL_2 + '/sales/allocation';
        this.fetchSOUrl = INVENTORY_API_BASE_URL_2 + '/so/list';
        this.itemCurrentStockUrl = INVENTORY_API_BASE_URL_2 + '/item_current_stock';
        this.itemDetailUrl = INVENTORY_API_BASE_URL_2 + '/item'
        this.warehouseUrl = ISC_API_BASE_URL_2 + '/warehouse';
        this.CustomerUrl = INVENTORY_API_BASE_URL_2 + '/customer'
        this.salesOrderListUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/list';
        this.salesOrderDetailUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/detail';
    }

    componentDidMount() {

        this.viewItemModal = new Modal(document.getElementById('viewItemModal'), { keyboard: false, backdrop: false });
        this.pickItemModal = new Modal(document.getElementById('pickItemModal'), { keyboard: false, backdrop: false });
        this.initilaizeFormData(this.props);
        this.pickItemDemandModal = new Modal(document.getElementById('pickItemDemandModal'), { keyboard: false, backdrop: false });
        this.batchListingModal = new Modal(document.getElementById('batchListingModal'), { keyboard: false, backdrop: false });
        this.EditItemModal = new Modal(document.getElementById('EditItemModal'), { keyboard: false, backdrop: false });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location && this.props.location.state) {
            if (nextProps.location.state !== this.props.location.state) {
                this.initilaizeFormData(nextProps)
            }
        }
    }
    // buttonDisabled
    initilaizeFormData = (pr) => {
        let sites = pr.iam_group_sites;
        if (!this.state.allocationFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, null, null, (response) => {
                let formData = response.data
                let updateFormState = [];
                if (formData.additional_attribute_data) {
                    formData.additional_attribute_data.map((i, k) => {
                        let name = 'additional_attributes_' + i.key;
                        updateFormState[name] = i.value
                    });
                }
                this.setState({
                    formData,

                    allSalesOrder: formData && formData.sales_order_types && formData.sales_order_types.length > 0 ? formData.sales_order_types.map((s) => { return { value: s.key, label: s.name, type_data: s } }) : [],

                    allStatusOptions: formData && formData.status && formData.status.length > 0 ? formData.status.map((s) => { return { value: s.id, label: s.name }; }) : [],

                    allocationFormDataLoaded: formData ? true : false,

                    allWarehouses: pr.all_warehouses && pr.all_warehouses.length > 0 ? pr.all_warehouses.map((s) => { return { value: s.id, label: `${s.name} (${s.code})`, site_id: s.site_ids }; }) : [],

                    allAllocationsFor: [{ value: 'asset', label: 'Asset' }, { value: 'workstation', label: 'Workstation' }, { value: 'employee', label: 'Employee' }, { value: 'sub_assembly', label: 'Sub Assembly' }],

                    allStatus: formData && formData.status && formData.status.length > 0 ? formData.status.map((s) => { return { value: s.id, label: s.name }; }) : [],

                    track_batch_expiry: pr.isc_configuration && pr.isc_configuration.track_batch_expiry ? pr.isc_configuration.track_batch_expiry : 'N',

                    negative_stock: pr.isc_configuration && pr.isc_configuration.negative_stock ? pr.isc_configuration.negative_stock : 'N',

                    only_stocked_item: pr.isc_configuration && pr.isc_configuration.negative_stock && pr.isc_configuration.negative_stock == 'N' ? 'Y' : 'N',
                    ...updateFormState

                }, () => {

                    let ParamObject         =   new URLSearchParams(this.props.location.search);

                    if (pr.location && pr.location.state && pr.location.state.allocationData) {

                        let allocationDetail = pr.location.state.allocationData;
                        let allocationItems = allocationDetail.items;
                        this.setState({
                            allocationDetail: allocationDetail,
                            allocationForm: {
                                ...this.initAllocationForm,

                                transaction_date: pr.location && pr.location.state && pr.location.state.clone ? moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD') : allocationDetail.transaction_date,

                                warehouse_id: allocationDetail.warehouse.id,

                                customer_id: allocationDetail.customer ? allocationDetail.customer.id : '',

                                // workstation_id: allocationDetail.workstation ? allocationDetail.workstation.id : null,

                                voucher_number: allocationDetail.voucher_number,

                                voucher_date: allocationDetail.voucher_date ? allocationDetail.voucher_date : '',

                                notes: allocationDetail.notes,

                                adjustment_amount: allocationDetail.adjustment_amount,

                                status: allocationDetail.status.id,

                                so_transaction_id: allocationDetail?.sales_order?.transaction_id,

                                deal_id: allocationDetail.deal_data ? allocationDetail.deal_data.id : '',
                                ...updateFormState

                            },
                            buttonDisabled: true,
                            searchedDeal: allocationDetail.deal_data ? {
                                value: allocationDetail.deal_data.id,
                                display_label: allocationDetail.deal_data.header, label: allocationDetail.deal_data.header
                            } : null,
                            searchedCustomer: { value: allocationDetail.customer.id, display_label: allocationDetail.customer.name },
                            CustomerDetails: { value: allocationDetail.customer.id, display_label: allocationDetail.customer.name },

                            selectedCustomerAdress: allocationDetail.customer_shipping_address ? { ...allocationDetail.customer_shipping_address, gstin: allocationDetail.customer_shipping_address.gstin, id: allocationDetail.customer_shipping_address.id, country_id: allocationDetail.customer_shipping_address.country_id ? allocationDetail.customer_shipping_address.country_id : 99 } : null,

                            selectedBillingAddress: allocationDetail.customerProject ? allocationDetail.customerProject.address : allocationDetail.customer_billing_address ? { ...allocationDetail.customer_billing_address, gstin: allocationDetail.customer_billing_address.gstin, id: allocationDetail.customer_billing_address.id, country_id: allocationDetail.customer_billing_address.country_id ? allocationDetail.customer_billing_address.country_id : 99 } : null,
                            selectedShippingAddress: allocationDetail.customerProject ? allocationDetail.customerProject.address : allocationDetail.customer_shipping_address ? { ...allocationDetail.customer_shipping_address, gstin: allocationDetail.customer_shipping_address.gstin, id: allocationDetail.customer_shipping_address.id, country_id: allocationDetail.customer_shipping_address.country_id ? allocationDetail.customer_shipping_address.country_id : 99 } : null,
                            selectedProject: allocationDetail.customerProject ? allocationDetail.customerProject : null,
                            allStatus: allocationDetail && allocationDetail.user_status && allocationDetail.user_status.length > 0 ? allocationDetail.user_status.map((s) => { return { value: s.id, label: s.name }; }) : [],
                        }, () => {
                            if (allocationDetail.sales_order?.transaction_id) {
                                this.getItemSOLwisting(allocationDetail.sales_order?.transaction_id, false)
                            }
                            this.getWarehouseDetail(allocationDetail.warehouse.id, true);
                            this.getCustomerDetails(allocationDetail.customer_id, true);
                            this.handleCustomerChange(this.state.searchedCustomer, true);
                            let transactionIdSetting = this.state.allSalesOrder ? this.state.allSalesOrder.find(pt => pt.value == this.state.allocationDetail.sales_order) : null
                            this.setState({
                                transactionIdSetting: transactionIdSetting ? transactionIdSetting.type_data : null
                            })
                            //Remanage Status of Purchase Order :-
                            this.filterAllStatusOptions();
                            let assetProfile = allocationDetail.assetProfile;
                            if (assetProfile) {
                                this.setState({ selectedAsset: { ...assetProfile, label: `${assetProfile.name} (${assetProfile.asset_code})` } });
                            }

                            let employeeProfile = allocationDetail.employee;
                            if (employeeProfile) {
                                this.setState({ searchedEmployee: { value: employeeProfile.enc_id, label: `${employeeProfile.display_full_name}`, employee: employeeProfile } });
                            }

                            let workstation = allocationDetail.workstation;
                            if (workstation) {
                                this.setState({ searchedWorkstation: { value: workstation.id, label: workstation.workstation_name } });
                            }
                            // {
                            //     selectedBatchData.length > 0 &&
                            //     selectedBatchData.map(bd => (
                            //         bd?.item_id == item.id
                            //         &&
                            //         <div className='py-1'>
                            //             Batch No : <b> {bd?.selectedBatchNumber} </b> <br />
                            //             Expiry Date : <b> {bd?.selectedExpiryDate} </b><br />
                            //         </div>
                            //     ))}
                            allocationItems.forEach((consumedItem, key) => {
                                // let pre_selected_stock = { batch_number: consumedItem.batch_number, expiry_date: consumedItem.expiry_date, qty: consumedItem.qty, allocation_item_id: consumedItem.id, pending_qty: consumedItem.pending_qty, item_current_qty: consumedItem.qty };
                                this.onItemSelect({ ...consumedItem, so_pending_qty: consumedItem.qty ? consumedItem.qty : 0, so_item_id: consumedItem.so_item_id, so_transaction_id: consumedItem.so_transaction_id ? consumedItem.so_transaction_id : '', itemdata: { ...consumedItem.item_profile, isAddRemark: consumedItem.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate,

                                );
                            });
                        });
                    }

                    if (pr.location && pr.location.state && pr.location.state.addAllocationFromPurchase) {

                        let allocationDetail = pr.location.state.addAllocationFromPurchase;

                        let consumedItems = allocationDetail.items;

                        setTimeout(() => {
                            this.setState({
                                allocationDetail: null,
                                buttonDisabled: true,
                                allocationForm: {
                                    ...this.initAllocationForm,
                                    transaction_date: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                    warehouse_id: allocationDetail.warehouse_id,
                                    customer_id: allocationDetail.customer ? allocationDetail.customer.id : '',
                                }
                            }, () => {
                                this.getWarehouseDetail(allocationDetail.warehouse.id, true);
                                this.getCustomerDetails(allocationDetail.customer_id, true);
                                this.handleCustomerChange(this.state.searchedCustomer, true);
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({ ...consumedItem, itemdata: { ...consumedItem.item_profile, isAddRemark: consumedItem?.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate, null, false);
                                });
                                let transactionIdSetting = this.state.allSalesOrder ? this.state.allSalesOrder.find(pt => pt.value == this.state.allocationDetail.sales_order) : null
                                this.setState({
                                    transactionIdSetting: transactionIdSetting ? transactionIdSetting.type_data : null
                                })
                                //Remanage Status of Purchase Order :-
                                this.filterAllStatusOptions();
                            });
                        }, 500)

                    }

                    if (pr.location && pr.location.state && pr.location.state.addAllocationFromInventoryTransfer) {

                        let allocationDetail = pr.location.state.addAllocationFromInventoryTransfer;

                        let consumedItems = allocationDetail.items;

                        setTimeout(() => {
                            this.setState({
                                allocationDetail: null,
                                buttonDisabled: true,
                                allocationForm: {
                                    ...this.initAllocationForm,
                                    transaction_date: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                    warehouse_id: allocationDetail.warehouse_id,

                                },
                                only_stocked_item: 'N'
                            }, () => {
                                this.getWarehouseDetail(allocationDetail.warehouse.id, true);
                                this.getCustomerDetails(allocationDetail.customer_id, true);
                                this.handleCustomerChange(this.state.searchedCustomer, true);
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({ ...consumedItem, itemdata: { ...consumedItem.item_profile, isAddRemark: consumedItem?.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate, null, false);
                                });
                                let transactionIdSetting = this.state.allSalesOrder ? this.state.allSalesOrder.find(pt => pt.value == this.state.allocationDetail.sales_order) : null
                                this.setState({
                                    transactionIdSetting: transactionIdSetting ? transactionIdSetting.type_data : null
                                })
                                //Remanage Status of Purchase Order :-
                                this.filterAllStatusOptions();
                            });
                        }, 500)

                    }

                    if (pr.location && pr.location.state && pr.location.state.addAllocationFromSO) {

                        let allocationData = pr.location.state.addAllocationFromSO;
                        let allocationDetail = pr.location.state.addAllocationFromSO.soData;

                        let consumedItems = allocationDetail.items && allocationDetail.items.length > 0 ? allocationDetail.items : allocationData.items && allocationData.items.length > 0 ? allocationData.items : [];

                        setTimeout(() => {
                            this.setState({
                                allocationDetail: null,
                                buttonDisabled: true,
                                allocationForm: {
                                    ...this.initAllocationForm,
                                    transaction_date: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                    customer_id: allocationDetail.customer ? allocationDetail.customer.id : '',
                                    warehouse_id: allocationDetail.warehouse.id,
                                    deal_id: allocationDetail.deal_data ? allocationDetail.deal_data.id : '',
                                    so_transaction_id: allocationDetail.sales_order?.transaction_id ? allocationDetail.sales_order?.transaction_id : '',

                                    workstation_id: allocationDetail.workstation ? allocationDetail.workstation.id : null,
                                },
                            }, () => {
                                this.getWarehouseDetail(allocationDetail.warehouse.id, true);
                                let assetProfile = allocationDetail.asset;
                                if (assetProfile) {
                                    this.setState({ selectedAsset: { ...assetProfile, label: `${assetProfile.name} (${assetProfile.asset_code})` } });
                                }

                                let employeeProfile = allocationDetail.employee;
                                if (employeeProfile) {
                                    this.setState({ searchedEmployee: { value: employeeProfile.enc_id, label: `${employeeProfile.name}` } });
                                }
                                let workStation = allocationDetail.workstation;
                                if (workStation) {
                                    this.setState({ searchedWorkstation: { value: workStation.id, label: `${workStation.workstation_name}` } });
                                }
                                if (allocationDetail.sales_order?.transaction_id) {
                                    this.getItemSOLwisting(allocationDetail.sales_order?.transaction_id)
                                }
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({
                                        ...consumedItem,
                                        so_pending_qty: consumedItem.pending_qty ? consumedItem.pending_qty : 0, so_item_id: consumedItem.so_item_id, so_transaction_id: allocationDetail.sales_order?.transaction_id, itemdata: { ...consumedItem.item_profile, isAddRemark: consumedItem?.remark ? true : false, so_item_id: consumedItem.so_item_id }
                                    }, key, consumedItem.qty, consumedItem.rate, null, false);
                                });
                            });
                        }, 500)

                    }
                    if (pr.location && pr.location.state && pr.location.state.addSalesAllocationFromSalesOrder) {
                        let allocationDetail = pr.location.state.addSalesAllocationFromSalesOrder;
                        let allocationItems = allocationDetail?.items || []; 
                        this.setState({
                            allocationDetail: null,
                            buttonDisabled: true,
                            allocationForm: {
                                ...this.initAllocationForm,
                                transaction_date: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                warehouse_id: allocationDetail?.warehouse?.id,
                                customer_id: allocationDetail?.customer ? allocationDetail?.customer?.id : '',
                                so_transaction_id: allocationDetail?.transaction_id,
                                deal_id: allocationDetail?.deal_data ? allocationDetail?.deal_data?.id : '',
                            },
                            buttonDisabled: true,
                            searchedDeal: allocationDetail?.deal_data ? {
                                value: allocationDetail?.deal_data.id,
                                display_label: allocationDetail?.deal_data.header,
                                label: allocationDetail?.deal_data.header
                            } : null,
                            searchedCustomer: { value: allocationDetail?.customer.id, display_label: allocationDetail?.customer?.name },
                            CustomerDetails: { value: allocationDetail?.customer.id, display_label: allocationDetail?.customer.name },
                            selectedCustomerAdress: allocationDetail?.customer_shipping_address ? { ...allocationDetail?.customer_shipping_address } : null,
                            selectedBillingAddress: allocationDetail?.customer_billing_address ? { ...allocationDetail?.customer_billing_address } : null,
                            selectedShippingAddress: allocationDetail?.customer_shipping_address ? { ...allocationDetail?.customer_shipping_address } : null,
                        }, () => {
                            if (allocationDetail.transaction_id) {
                                this.getItemSOLwisting(allocationDetail.transaction_id, false)
                            }
                            this.getWarehouseDetail(allocationDetail.warehouse.id, true);
                            this.getCustomerDetails(allocationDetail.customer_id, true);
                            this.handleCustomerChange(this.state.searchedCustomer, true);
                    
                            allocationItems.forEach((soItem, key) => {
                                this.onItemSelect({
                                    ...soItem,
                                    so_pending_qty: soItem?.pending_qty ? soItem?.pending_qty : 0,
                                    so_item_id: soItem?.so_item_id,
                                    so_transaction_id: soItem?.so_transaction_id ? soItem?.so_transaction_id : '',
                                    itemdata: { ...soItem?.item_profile, isAddRemark: soItem?.remark ? true : false }
                                }, key, soItem?.pending_qty, soItem?.rate);
                            });
                        });
                    }
                   
                    if(ParamObject.get('dealId')) {
                        const storedData = localStorage.getItem('selectedDealToAddAllocation');
        
                        if (storedData) {
                            const {deal_header,customerData,customerProjectData } = JSON.parse(storedData);
                            let deal_id = ParamObject.get('dealId');
        
                            this.setState({
                                allocationForm  :   {
                                    ...this.initSalesForm,
                                    deal_id             :   deal_id ? deal_id : '',
                                    customer_id         :   customerData ? customerData.id : '',
                                    customer_project_id :   customerProjectData ? customerProjectData.id : '',
                                },
                                searchedCustomer        :   {value : customerData.id  ,display_label : customerData.name },
                                CustomerDetails         :   {value : customerData.id  ,display_label : customerData.name },
                                selectedCustomerAdress  :  customerProjectData ? customerProjectData.address :  null,
                                selectedProject         :   customerProjectData ? customerProjectData : null,
                                transactionFromDeal     :   true,
                                searchedDeal            :  deal_id ?  {value : deal_id  ,display_label : deal_header,label:deal_header } : null,
                                }, () => {
                                    // let transactionIdSetting = this.state.allSalesTypes ? this.state.allSalesTypes.find(pt => pt.value == "sales_order") : null
                                    // this.setState({
                                    //     transactionIdSetting   : transactionIdSetting ? transactionIdSetting.type_data : null
                                    // })
                                    this.getCustomerDetails(this.state.allocationForm.customer_id, this.state.allocationForm.customer_project_id ? true : false);
                                    this.handleCustomerChange(this.state.searchedCustomer, false);
                                    //Remanage Status of Sales Order Order :-
                                    //this.filterAllStatusOptions();
                            });
                        } 
                    } 

                });
            });
        }
    }
    handleCheckboxChange = () => {
        const indentItemForCheckbox = document.getElementsByClassName('demand_id_checkbox');
        let anyChecked = false;

        for (let checkbox of indentItemForCheckbox) {
            if (checkbox.checked) {
                anyChecked = true;
                break;
            }
        }

        this.setState({ allCheckboxesDisabled: !anyChecked });
    };

    changeAddressHandler = (id) => {
        if (id) {
            let selectedCustomerAdress = this.state.CustomerDetails && this.state.CustomerDetails.addressList
                ? this.state.CustomerDetails.addressList.find(a => a.id == id) : null;
            this.setState({
                selectedCustomerAdress: selectedCustomerAdress
            })
        }
    }
    filterAllStatusOptions = () => {
        let allStatusOptions = this.state.allStatusOptions;
        if (this.state.user_status?.length > 0) {
            allStatusOptions = allStatusOptions.filter(allStatus => this.state.user_status.find(ns => ns.id == allStatus.value) ? true : false);
            this.setState({
                allStatusOptions: allStatusOptions
            });
        }
    }

    onWarehouseChange = (selectedOption) => {

        this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
    }

    checkAllItemDemandList() {
        let indentItemForCheckbox = document.getElementsByClassName('demand_id_checkbox')
        if (indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                indentItemForCheckbox[i].checked = document.getElementById('all_check_demand').checked;
            }
        }
    }

    handleSelectedProject = (selectedProject = null) => {

        if (selectedProject) {
            this.setState({
                selectedProject,
                selectedBillingAddress: selectedProject.address,
                selectedShippingAddress: selectedProject.address,
            });
        } else {
            let selectedCustomerAdress = this.state.CustomerAddressList && this.state.CustomerAddressList.length > 0 ? this.state.CustomerAddressList[0] : null;
            this.setState({
                selectedProject: null,
                selectedBillingAddress: selectedCustomerAdress,
                selectedShippingAddress: selectedCustomerAdress,
            });
        }
    }

    handleAddressSelect = (addresses) => {
        this.setState({
            selectedShippingAddress: addresses.shipping,
            selectedBillingAddress: addresses.billing,
        });
    };
    getWarehouseDetail = (warehouse_id, editallocation = false) => {
        if (warehouse_id) {
            this.setState({ warehouseDetailLoading: true, warehouseDetail: null, warehouseSites: [] });
            HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    warehouseDetail: respData.data,
                    warehouseSites: respData.data.sites
                });
                if (!editallocation) {

                }
            }).then(() => {
                this.setState({ warehouseDetailLoading: false });
            });
        } else {
            this.setState({ warehouseDetail: null })
        }
    }

    onItemSelect = (selectedOption, key, qty = 1, allocation_price = null, pre_selected_stock = null, addRow = true, fromPickItem = false) => {

        if (selectedOption) {
            let allocationItemRows = this.state.allocationItemRows;
            let itemdata = selectedOption.itemdata;
            if (!allocation_price) {
                allocation_price = itemdata && itemdata.allocation_price ? itemdata.allocation_price : 0;
            }

            let row = {
                ...allocationItemRows[key], so_pending_qty: selectedOption && selectedOption.so_pending_qty ? selectedOption.so_pending_qty : 0, so_item_id: selectedOption && selectedOption.so_item_id ? selectedOption.so_item_id : '', so_transaction_id: selectedOption && selectedOption.so_transaction_id ? selectedOption.so_transaction_id : '', item: itemdata, qty: qty, rate: allocation_price ? allocation_price : itemdata && itemdata.purchase_price ? itemdata.purchase_price : '',
                remark: selectedOption.remark ? selectedOption.remark : "", allocation_item_id: pre_selected_stock && pre_selected_stock.allocation_item_id ? pre_selected_stock.allocation_item_id : null, pending_qty: pre_selected_stock && pre_selected_stock.pending_qty ? pre_selected_stock.pending_qty : 0, item_current_qty: pre_selected_stock && pre_selected_stock.qty ? pre_selected_stock.qty : 0
            };
            allocationItemRows[key] = row;
            this.setState({ allocationItemRows: [...allocationItemRows] }, () => {
                if (!this.state.allocationItemRows.find(ir => !ir.item)) {
                    if (addRow == true) {
                        setTimeout(() => { this.addNewItemRow() }, 1000);
                    }
                }

                if (itemdata) {
                    //load Inventory Level of this item at given warehouse for this key :-
                    this.getItemInventoryLevel(itemdata.id, key, pre_selected_stock);
                }

            });
        }

    }

    getItemSOLwisting = (transaction_id, updateRows = true) => {
        if (transaction_id) {
            this.setState({ allocationItemRows: updateRows == true ? [{ item: null }] : this.state.allocationItemRows });
            let params = {
                action: 'data',
                search_warehouse_ids: [this.state.allocationForm.warehouse_id],
                search_customer_ids: [this.state.allocationForm.customer_id],
                search_transaction_id: transaction_id,
                // search_status_levels:[],
                min_pending_qty: 0.01

            };
            HttpAPICall.withAthorization('GET', this.salesOrderDetailUrl + '/' + transaction_id, this.props.access_token, null, params, (resp) => {
                let respData = resp.data;
                let item_so_list = {
                    value: respData.transaction_id,
                    label: respData.transaction_id,
                    so_data: respData,
                }
                let allocationItemRows = this.state.allocationItemRows;
                if (item_so_list.so_data.items && item_so_list.so_data.items.length > 0) {
                    item_so_list.so_data.items.map((so, key) => {
                        let itemData = so ? so : null;
                        if (updateRows == true) {
                            let row = {
                                ...allocationItemRows[key],
                                item: { ...itemData.item_profile },
                                qty: itemData.pending_qty,
                                rate: itemData.item_profile && itemData.item_profile.purchase_price ? itemData.item_profile.purchase_price : 0,
                                gst: 0,
                                so_pending_qty: itemData.pending_qty,
                                so_item_id: itemData.so_item_id,
                                so_transaction_id: itemData.so_transaction_id,
                                remark: '',
                            };
                            allocationItemRows[key] = row;
                            this.getItemInventoryLevel(itemData.item_id, key)

                        }


                    })
                    this.setState({ allocationItemRows: allocationItemRows, })
                }

                this.setState({
                    so_loading: false,
                    allItemWiseSOListing: item_so_list,
                    //itemSOLoaded : true
                })

            })
        }


    }

    getItemInventoryLevel = (item_id, key, pre_selected_stock = null, item_category = 'goods') => {
        this.setState({ buttonDisabled: true, loadingItemDetails: true });
        let allocationItemRows = this.state.allocationItemRows;

        if (item_category == 'goods') {
            allocationItemRows[key] = { ...allocationItemRows[key], stock_loading: true, };
            this.setState({ allocationItemRows: [...allocationItemRows] });

            let params = { interchangeable_items_current_stock: "N", warehouse_id: this.state.allocationForm.warehouse_id };
            HttpAPICall.withAthorization('GET', this.itemCurrentStockUrl + '/' + item_id, this.props.access_token, params, {}, (resp) => {

                let current_stocks = resp.data.current_stock
                this.setState({
                    allItemCurrentStocks: {
                        ...this.state.allItemCurrentStocks,
                        data: {
                            ...this.state.allItemCurrentStocks.data,
                            [item_id]: current_stocks.length > 0 ? current_stocks.map((cs, i) => ({ ...cs, selected: i === 0 ? true : false, choose_batch_number: true })) : []
                        }
                    }
                })
                let allocationItemRows = this.state.allocationItemRows;
                allocationItemRows[key] = {
                    ...allocationItemRows[key],
                    stock_loading: false,
                    current_stocks: current_stocks,
                    allApiCallsCompleted: true,
                };

                let selectedStock = current_stocks.length > 0 ? current_stocks[0] : null;
                let findSelectedStockKey = null;
                if (pre_selected_stock) {
                    let preSelectedStock = current_stocks.find(cs => {
                        return cs.stock.batch_number == pre_selected_stock.batch_number
                            && cs.stock.expiry_date == pre_selected_stock.expiry_date;
                    });
                    if (!preSelectedStock) {

                        selectedStock = {
                            allocation_item_id: pre_selected_stock.allocation_item_id,
                            batch_number: pre_selected_stock.batch_number,
                            expiry_date: pre_selected_stock.expiry_date,
                            stock: {
                                qty: pre_selected_stock.qty,
                                free_qty: pre_selected_stock.qty
                            }
                        };

                    } else {
                        let cs = preSelectedStock.stock;
                        let qty = cs.qty;
                        let free_qty = cs.free_qty;


                        if (this.state.allocationDetail) {
                            qty = pre_selected_stock.qty;
                            free_qty = free_qty + pre_selected_stock.qty;

                        }

                        selectedStock = {
                            ...preSelectedStock, allocation_item_id: pre_selected_stock.allocation_item_id, stock: {
                                ...cs,
                                qty: qty,
                                free_qty: free_qty
                            }
                        };
                    }
                }
                this.setState({ allocationItemRows: [...allocationItemRows] }, () => {
                    this.onBatchChange(selectedStock, key);
                    this.handleButtonEnable()
                });
            }).then(() => { this.setState({ loadingItemDetails: false }) });
        } else {
            allocationItemRows[key] = { ...allocationItemRows[key], stock_loading: false, allApiCallsCompleted: true };
            this.setState({ allocationItemRows: [...allocationItemRows], loadingItemDetails: false });
        }
    }

    handleButtonEnable = () => {
        const allApiCallsCompleted = this.state.allocationItemRows.filter(i => i.item ? true : false).every(item => item.allApiCallsCompleted);
        this.setState({ buttonDisabled: !allApiCallsCompleted });
    };

    onBatchChange = (selectedOption, key) => {
        if (selectedOption && selectedOption.stock) {
            let selected_stock = selectedOption.stock;
            let allocationItemRows = this.state.allocationItemRows;
            allocationItemRows[key] = {
                ...allocationItemRows[key],
                selected_stock: selected_stock,
                stock_qty: selected_stock.qty,
                free_qty: selected_stock.free_qty,
                allocation_item_id: selectedOption && selectedOption.allocation_item_id ? selectedOption.allocation_item_id : null,
            };
            this.setState({ allocationItemRows: [...allocationItemRows] });
        }
    }

    onSOChange = (selectedOption, key) => {
        this.setState({ allocationItemRows: [{ item: null }] });
        if (selectedOption) {
            let allocationItemRows = this.state.allocationItemRows;
            if (this.state.allSOListing && this.state.allSOListing.length > 0) {
                this.state.allSOListing.filter(dl => dl.value == this.state.allocationForm.so_transaction_id).map((so, key) => {
                    let itemData = so.so_data ? so.so_data : null;
                    let row = {
                        ...allocationItemRows[key],
                        item: { ...itemData.item_profile },
                        qty: itemData.pending_qty,
                        rate: itemData.item_profile && itemData.item_profile.purchase_price ? itemData.item_profile.purchase_price : 0,
                        gst: 0,
                        so_pending_qty: itemData.pending_qty,
                        so_item_id: itemData.so_item_id,
                        so_transaction_id: itemData.so_transaction_id,
                        remark: '',
                    };
                    allocationItemRows[key] = row;
                    this.getItemInventoryLevel(itemData.item_id, key)
                })
                this.setState({ allocationItemRows: allocationItemRows })
            }
        }
    }

    itemViewModalInit = (item, k) => {
        if (item) {
            this.itemDetailsRef.current.modalInit(item);
            this.setState({ itemRowNumber: k, selectedItemDetails: item, showItemDetailModal: true })
        }
    }
    batchListingModalInit = (item, itemCurrentStocks, field) => {
        this.setState({
            batch_data: itemCurrentStocks.map((cs, index) => index === field ? { ...cs, selected: true } : cs)
        }, () => {
            this.batchListingModal.show(item);
            if (item) {
                this.setState({ selectedItemDetails: item });
            }
        })

    }
    addRemark = (data, k) => {
        let allRows = this.state.allocationItemRows;
        let itemdata = { ...data, isAddRemark: true }
        allRows[k] = { ...this.state.allocationItemRows[k], item: itemdata };
        this.setState({ allocationItemRows: allRows });
    }

    pickItemDemandModalInit = () => {
        this.pickItemDemandModal.show();
    }

    pickDemandItemModalJsx = () => {
        return (
            <div className="modal fade" id="pickItemDemandModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="vendorDetailsModalLabel">Pick Item from Sales Order </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-hover table-borderless my-2">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>Warehouse</td>
                                        <td>{this.state?.warehouseDetail?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <form onSubmit={this.submitPickItemSOFormSubmit} className="bg-white" id="pickDemandForm" method="post" encType="multipart/form-data">
                                <table className="table table-hover table-bordered table-responsive table-sm bg-white mt-3">
                                    <thead className="table-secondary">
                                        <tr className="text-end">
                                            <th scope="col" style={{ width: "5%" }} className="text-center">
                                                <input type="checkbox" onChange={this.checkAllItemDemandList} id="all_check_demand" disabled={this.state.allCheckboxesDisabled} />
                                            </th>
                                            <th scope="col" className="text-start" style={{ width: "25%" }}>Item Name</th>
                                            <th scope="col" className="text-start" style={{ width: "25%" }}>Item Code</th>
                                            <th scope="col" style={{ width: "15%" }}>Manufacturer</th>
                                            <th scope="col" style={{ width: "15%" }}>Total Qty</th>
                                            <th scope="col" style={{ width: "15%" }}>Pending Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.so_loading ?
                                            <tr colSpan={5}>
                                                <td colSpan={5} className='text-center'><Loader />
                                                </td>
                                            </tr> :
                                            this.state.allItemWiseSOListing && this.state.allItemWiseSOListing.so_data?.items.length > 0 ? this.state.allItemWiseSOListing?.so_data?.items.map((dl, k) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className='text-center'>
                                                                <input
                                                                    type='checkbox'
                                                                    name="demand_id_checkbox"
                                                                    className="demand_id_checkbox"
                                                                    value={dl.item_id}
                                                                    data-itemId={dl.item_id}
                                                                    data-qty={dl?.new_qty}
                                                                    onChange={this.handleCheckboxChange}
                                                                    disabled={this.state.allocationItemRows.find(element => {
                                                                        if (element?.so_item_id == dl.so_item_id && element?.item?.id == dl.item_id) {
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    })}
                                                                />
                                                            </td>
                                                            <td className="text-start">{dl.item_profile.name || ""}</td>
                                                            <td className="text-start">{dl.item_profile.item_code || ""}</td>
                                                            <td className="text-center">{dl.manufacturer}</td>
                                                            <td className="text-center">{dl.qty}</td>
                                                            <td className="text-center">{dl.pending_qty}</td>

                                                        </tr>
                                                    </>)
                                            }) :
                                                <tr colSpan={7}><td colSpan={7} className='text-center'>No Record</td></tr>}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" disabled={this.state.savePickItemSubmitting || this.state.allCheckboxesDisabled} className="btn btn-primary" form="pickDemandForm">Add to Allocation {this.state.savePickItemSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    itemSearchModalInit = () => {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows = this.state.allocationItemRows;
                let item_ids = [];
                if (allRows && allRows.length > 0) {
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => { return (item?.item?.id) })
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });
    }

    editItemModalInit = (data) => {
        this.setState({ itemSearchedDetails: data})
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal }), () => {
            if (this.state.showItemEditModal) {
                let allRows = this.state.allocationItemRows;
                let item_ids = [];
                if (allRows && allRows.length > 0) {
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => { return (item?.item?.id) })
                }
                if (this.editItemModalRef.current) {
                    this.editItemModalRef.current.itemEditModalInit(data);
                }
            }
        }, () => { this.EditItemModal.show(data); });

    }

    addItemModalInit = () => {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows = this.state.allocationItemRows;
                let item_ids = [];
                if (allRows && allRows.length > 0) {
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => { return (item?.item?.id) })
                }
                if (this.addItemModalRef.current) {
                    this.addItemModalRef.current.itemAddModalInit();
                }
            }
        });
    }
    //********************EDIT ITEM DETAILS ******************************* */
    submitEditItem = () => {
        HttpAPICall.withAthorization('GET', this.itemDetailUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
            let selectedItem = {
                label: (<Ax>
                    <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br />
                    <small>
                        <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br />
                        <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value: response.data.data.id,
                display_label: `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata: response.data.data
            };
            this.setState({
                itemSearchedDetails: response.data.data,
            }, () => {
                this.onItemSelect(selectedItem, this.state.itemRowNumber, this.state.allocationItemRows[this.state.itemRowNumber]?.qty)
            });
        })
    }

    addNewItemRow = () => {
        this.setState({ allocationItemRows: [...this.state.allocationItemRows, ...this.initAllocationItemRows] });
    }

    removeItemRow = (item, key) => {
        if (item && item.id) {
            let allocationItemRow = this.state.allocationItemRows.filter(listItem => listItem.item && listItem.item.id !== item.id);
            this.setState({ allocationItemRows: allocationItemRow });
        } else {
            console.error("Invalid item passed to removeItemRow:", item);
        }
    }

    //****************************CLOSE allocation *********************/
    closeEvent = () => {
        if (this.state.allocationDetail) {
            this.props.history.push({ pathname: `/sales_allocation_list`, state: { allocationData: this.state?.allocationDetail } })
        } else {
            this.props.history.push({ pathname: `/sales_allocation_list` })
        }
    }

    addItemsfromSearch = (selectedItems) => {
        let allocationItemRows = selectedItems.map((pu_item, k) => {
            return {
                item: { ...pu_item.item, isAddRemark: pu_item.remark ? true : false },
                req_allocation_loading: true,
                req_allocation: [],
                id: pu_item.item.id,
                qty: pu_item.qty,
                rate: pu_item.rate,
                remark: '',
                gst: pu_item.gst,
                discount_type: '',
                discount_rate: '',
                po_item_id: '',
                req_item_id: '',
                selected_allocation: null,
                expiry_date: '',
                batch_number: '',
                stock_id: null,
                so_transaction_id:""
            };
        });
        let allRows = this.state.allocationItemRows ? this.state.allocationItemRows.filter(i => i.item ? true : false) : [];
        let allocationItems = allRows.concat(allocationItemRows);
        allocationItems.forEach((consumedItem, key) => {
            this.onItemSelect({ ...consumedItem, itemdata: { ...consumedItem.item_profile, isAddRemark: consumedItem?.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate, false, false);
        });
    }

    allocationFormSubmitHandler = (event, status = 1) => {
        event.preventDefault();
        let showErrors = [];

        let additional_attributes = {};
        this.state.formData.additional_attributes.forEach((i, k) => {
            let name = 'additional_attributes_' + i.key;
            additional_attributes[i.key] = this.state.allocationForm[name] ? this.state.allocationForm[name] : '';
        });

        let formData = {
            ...this.state.allocationForm,
            status: status,
            additional_attributes,
            transaction_date: moment(this.state.allocationForm.transaction_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            customer_billing_address_id: this.state.selectedBillingAddress && this.state.selectedBillingAddress.id ? this.state.selectedBillingAddress.id : null,
            customer_shipping_address_id: this.state.selectedShippingAddress && this.state.selectedShippingAddress.id ? this.state.selectedShippingAddress.id : null,
        };

        let allItemCurrentStocks = this.state.allItemCurrentStocks.data;

        let items = this.state.allocationItemRows.filter(ir => ir.item).map(i => {
            let batchData   =   allItemCurrentStocks[i.item.id] && allItemCurrentStocks[i.item.id]?.length > 0 && allItemCurrentStocks[i.item.id].find(cs => cs.selected);
            return {
                id: i.item.id,
                batch_number: batchData ? batchData.batch_number : '',
                expiry_date: batchData ? batchData.expiry_date : '',
                allocation_item_id: i.allocation_item_id ? i.allocation_item_id : '',
                qty: i.qty,
                free_qty: i.item.category !== "services" ? i.free_qty : i.qty,
                rate: i.rate,
                remark: i.remark, so_item_id: i.so_item_id,
                so_pending_qty: i.so_pending_qty ? i.so_pending_qty : 0,
                so_transaction_id: this.state.allocationForm.so_transaction_id
            };
        })

        // Validate basic fields
        if (formData.transaction_date === '') {
            showErrors = [...showErrors, 'Please enter Transaction Date.'];
        }
        if (formData.warehouse_id === '') {
            showErrors = [...showErrors, 'Please select Warehouse.'];
        }
        if (items.length === 0) {
            showErrors = [...showErrors, 'Please select Item and their allocation qty.'];
        } else if (items.find(i => parseFloat(i.qty) <= 0)) {
            showErrors = [...showErrors, 'Every Item\'s allocation qty must be more than 0.'];
        } else if (status === '34') {
            if (items.find(i => i.qty > i.free_qty)) {
                showErrors = [...showErrors, 'In Allocate Items, allocation qty must be less than Free Qty.'];
            }
        }
        // else if (this.state.negative_stock === 'N') {
        //     if (items.find(i => i.qty > i.free_qty)) {
        //         showErrors = [...showErrors, 'allocation qty can not more than Free Qty.'];
        //     }
        // }
        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        } else {
            formData = {
                ...formData,
                items: items,
            };
            if (this.state.allocationDetail) {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('PUT', this.allocationUrl, this.props.access_token, {}, { ...formData, transaction_id: this.state.allocationDetail.transaction_id }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/sales_allocation_list`, state: { allocationId: response.data.transaction_id } });
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('POST', this.allocationUrl, this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/sales_allocation_list`, state: { allocationId: response.data.transaction_id } });
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }



    pickItemModalInit = () => {
        this.pickItemModal.show()
    }

    getAllSOListing = (selectedOption) => {


        if (selectedOption) {
            this.setState({ so_listing_loading: true })
            let params = {
                action: 'data',
                search_workstation_ids: this.state.allocationForm.workstation_id ? [this.state.allocationForm.workstation_id] : [],
                search_status_levels: [3, 4],
                more_than_pending_qty: 0,
                per_page: 100,
                page: 1
            };
            HttpAPICall.withAthorization('GET', this.fetchSOUrl, this.props.access_token, { ...params }, null, (resp) => {
                let respData = resp.data;
                this.setState({
                    allSOListing: respData && respData.data && respData.data.length > 0 ? respData.data.map(dt => { return ({ value: dt.transaction_id, label: dt.transaction_id }) }) : []
                })

            }, (err) => {

            }).then(() => this.setState({ so_listing_loading: false }))
        }

    }


    pageTitlejsx = () => {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3>{this.state.allocationDetail ? "Edit" : "New"} Sales Allocation {this.state.allocationDetail ? <span> of Transaction ID : {this.state.allocationDetail.transaction_id}</span> : null}</h3>
                <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }

    allocationItemsJsx = () => {
        const { selectedBatchData, allocationItemRows, stock_loading, allItemCurrentStocks } = this.state;
      let alldata=  this.state.allItemWiseSOListing && this.state.allItemWiseSOListing.so_data?.items.length > 0 && this.state.allItemWiseSOListing?.so_data?.items.map((dl, k)=>dl.rate_vc);
        return (<div className="my-3 row">
            <div className="col-sm-12">
                <table className="table table-bordered table-responsive bg-white ">
                    <thead className="table-secondary align-middle">
                        <tr className="">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" >Item Details</th>
                            {this.state.track_batch_expiry == "Y"
                                ? <th scope="col" className="text-center" style={{ width: "18%" }}>Batch Number <br /> Expiry Date</th>
                                : null}
                            <th scope="col" className="text-center" style={{ width: "12%" }}>SO Pending Qty</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Amount</th>
                            <th scope="col" className="" style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stock_loading ?
                            <tr colSpan={7}>
                                <td colSpan={7} className='text-center'><Loader />
                                </td>
                            </tr> :
                            allocationItemRows.map((itemRow, itemKey) => {
                              
                                let item = itemRow?.item;
                                let item_id = item?.id;
                                let itemCurrentStocksData = item_id && allItemCurrentStocks.data?.[item_id]?.length > 0 ? allItemCurrentStocks?.data?.[item_id] : [];
                                let currentStockIndex = 0;
                                let choose_batch_number = true;
                                let itemCurrentStocks = itemCurrentStocksData.length > 0 ? itemCurrentStocksData.filter((ics, i) => {
                                    if (ics.selected) {
                                        currentStockIndex = i;
                                        choose_batch_number = ics.choose_batch_number
                                        return ics;
                                    }
                                }) : [];

                                return <tr className="" key={itemKey}>
                                    <td className="text-center align-middle">{itemKey + 1}</td>
                                    {(item
                                        ? <Ax>
                                            <td>
                                                <div className="row">
                                                    <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}> {item.name}</span></div>
                                                    <div className='col-sm-2 text-end'>
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                        <ul className="dropdown-menu">
                                                            <li><a href="#" className="dropdown-item" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>View Details</a></li>

                                                            {this.props?.permissions?.permissions?.includes("isc-item-edit") && <li><a href="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    this.editItemModalInit(itemRow.item);
                                                                }}
                                                            // onClick= {  () => { this.itemViewModalInit(item, itemKey); this.editItemModalInit(item) } }
                                                            >
                                                                Edit Item</a></li>}

                                                            <li className="dropdown-item" onClick={() => { this.addRemark(itemRow.item, itemKey) }}>Add Remark</li>
                                                        </ul></div>
                                                </div>
                                                <div className="form-text"><b>Code: </b>{item.item_code}</div>
                                                <div className="form-text">
                                                    <b>Mfr: </b>{item.manufacturer ? item.manufacturer.name : '-'} (<b>MPN:</b> {item.manufacturer_part_no ? item.manufacturer_part_no : '-'})
                                                </div>
                                                {itemRow?.item && itemRow?.item.isAddRemark && itemRow?.item.isAddRemark == true
                                                    ?
                                                    <input
                                                        name="remark"
                                                        type="text"
                                                        value={itemRow.remark}
                                                        onChange={(e) => this.dynamicInputHandlerByKey(e, itemKey, 'allocationItemRows')}
                                                        className="form-controlgetItemInventoryLevel mt10"
                                                        autoComplete="off"
                                                        placeholder="Item Remarks"
                                                    />
                                                    : null}
                                            </td>
                                            {
                                                itemRow?.stock_loading && item.category == "goods"
                                                    ? <td colSpan={this.state.track_batch_expiry == 'Y' ? 5 : 4}><Loader /></td>
                                                    : (<Ax>
                                                        {
                                                            itemCurrentStocks && itemCurrentStocks.length > 0
                                                                ?
                                                                <>
                                                                    <div className='py-1'>
                                                                        BN : <b> {itemCurrentStocks[0]?.batch_number || "-"} </b> <br />
                                                                        ED: <b> {itemCurrentStocks[0]?.expiry_date_display || "-"} </b><br />
                                                                    </div>
                                                                    <br />
                                                                    {
                                                                        itemCurrentStocksData.length > 1 &&
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => {
                                                                                this.batchListingModalInit(item, itemCurrentStocksData, currentStockIndex);
                                                                            }}
                                                                            className="btn btn-link text-decoration-none text-center">
                                                                            {choose_batch_number ? "Choose Batch No." : "Change Batch No"}
                                                                        </button>
                                                                    }
                                                                </>
                                                                : "N/A"
                                                        }

                                                        <td>
                                                            <small className={['my-1',].join(' ')}>
                                                                {this.state.allocationForm.so_transaction_id ? <><b>Pending Qty : </b> {itemRow?.so_pending_qty === 0 ? "-" : itemRow?.so_pending_qty} <br /></> : "-"}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <TapInputNumber
                                                                name="qty"
                                                                value={itemRow.qty}
                                                                onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'allocationItemRows', null, 'qty', e)}
                                                                placeholder="Qty"
                                                                required={true}
                                                                min={.01}
                                                                disabled={this.state.allocationDetail && itemRow?.pending_qty !== itemRow?.item_current_qty ? true : false}
                                                            />
                                                            <span className="text-helping input-group-text text-end pr0">{item && item.measuring_unit ? item.measuring_unit.name : '-'}</span>
                                                            {
                                                                itemCurrentStocks
                                                                &&
                                                                <>
                                                                    <div className='py-1'>
                                                                        <small>Stock In Hand:  <b> {itemCurrentStocks[0]?.total_qty || "-"} </b></small> <br />
                                                                        <small>Free Stock:<b> {itemCurrentStocks[0]?.free_qty || "-"} </b></small> <br />
                                                                    </div>

                                                                </>

                                                            }<br />

                                                        </td>
                                                         <td>
                                                            <TapInputNumber
                                                                name="rate"
                                                                value={itemRow?.rate}
                                                                onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'allocationItemRows', null, 'rate', e)}
                                                                placeholder="Unit Rate"
                                                                required={true}
                                                                disabled={this.state.allocationDetail && itemRow.pending_qty !== itemRow.item_current_qty ? true : false}
                                                            />
                                                        </td>
                                                        <td className="pt-4 text-end">{(itemRow.rate * itemRow.qty).toFixed(2)}</td>

                                                    </Ax>)}
                                        </Ax>
                                        : <Ax>
                                            <td className="align-middle">
                                                <InputItemSearch
                                                    changeEvent={(selectedOption) => {
                                                        this.onItemSelect(selectedOption, itemKey);
                                                    }}
                                                    only_stocked_item={this.state.only_stocked_item}
                                                    stock_warehouse_ids={[this.state.allocationForm.warehouse_id]}
                                                    only_active_item='Y'
                                                />
                                            </td>
                                            <td colSpan={this.state.track_batch_expiry == 'Y' ? 5 : 4}></td>
                                        </Ax>
                                    )}
                                    <td className="text-center align-middle">
                                        {/* {this.state.allocationDetail && itemRow.item_current_qty == itemRow.pending_qty ? */}
                                        {allocationItemRows.length > 1 && !this.state.allocationDetail
                                            ? <a href="#" onClick={() => this.removeItemRow(itemRow.item, itemKey)}>
                                                <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                            </a>
                                            : allocationItemRows.length > 1 && this.state.allocationDetail && itemRow.item_current_qty == itemRow.pending_qty
                                                ? <a href="#" onClick={() => this.removeItemRow(itemRow.item, itemKey)}>
                                                    <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                                </a> : null}
                                    </td>
                                </tr>
                            })}

                    </tbody>
                </table>
            </div>
            <div className="col-sm-12">
                <div className="row justify-content-end">
                    {/* <div className="col-sm-8">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={this.state.only_stocked_item == "Y" ? "N" : "Y"}
                                checked={this.state.only_stocked_item == "Y"}
                                onChange={(e) => { this.setState({ only_stocked_item: e.target.value, }); }}
                                id="onlyStockedItem"
                            // disabled={this.state.negative_stock == 'N' ? true : false}
                            />
                            <label className="form-check-label" htmlFor="onlyStockedItem">
                                Show only those items that are available in the selected warehouse
                            </label>
                        </div>
                    </div> */}
                    {!this.state.allocationForm.so_transaction_id && <div className="col-sm-4 text-end">
                        {this.state.allocationForm && this.state.allocationForm.warehouse_id ?
                            <a role="button" onClick={() => { this.setState({ showItemSearchModal: false }, () => this.itemSearchModalInit()) }} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                            : null}
                        {this.props?.permissions?.permissions?.includes("isc-item-add") && <a role="button" onClick={() => { this.setState({ showItemAddModal: false }, () => this.addItemModalInit()) }} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                        <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                    </div>}

                </div>
            </div>
        </div>);
    }

    checkAllItemSOList() {
        let indentItemForCheckbox = document.getElementsByClassName('so_id_checkbox')
        if (indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                indentItemForCheckbox[i].checked = document.getElementById('all_check_so').checked;
            }
        }
    }

    submitPickItemSOFormSubmit = (e) => {
        e.preventDefault();

        let allocationItemRows = [];
        let indentItemForCheckbox = document.getElementsByClassName('demand_id_checkbox');

        if (indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if (indentItemForCheckbox[i].checked) {
                    let selectedItemRow = this.state.allItemWiseSOListing?.so_data?.items.find(item => item.item_id === indentItemForCheckbox[i].value)
                    const data = {
                        gst: selectedItemRow?.gst,
                        item: selectedItemRow?.item_profile,
                        qty: selectedItemRow?.qty,
                        rate: selectedItemRow?.rate,
                        remark: selectedItemRow?.remark,
                        so_item_id: selectedItemRow?.so_item_id,
                        so_pending_qty: selectedItemRow?.pending_qty,
                        so_transaction_id: selectedItemRow?.so_transaction_id

                    }
                    if (this.state.allocationItemRows.length > 0) {
                        this.state.allocationItemRows.map((oldData) => {
                            data.item.id != oldData.item.id && allocationItemRows.push(data);
                        });
                    } else {
                        allocationItemRows.push(data);
                    }
                }
            }
        }


        this.setState({
            allocationItemRows: [...this.state.allocationItemRows, ...allocationItemRows]
        }, () => {
            this.pickItemDemandModal.hide();
        });

        // let additional_attributes = {};
        // if (Array.isArray(this.state.allocationForm.additional_attributes)) {
        //     this.state.allocationForm.additional_attributes.forEach((i, k) => {
        //         let name = 'additional_attributes_' + i.key;
        //         additional_attributes[i.key] = this.state.allocationDetail[name] ? this.state.allocationDetail[name] : '';
        //     });
        // } else {
        //     console.error("additional_attributes is not defined or is not an array");
        // }

        // if (allocationItemRows.length == 0) {
        //     toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        // } else {

        //     allocationItemRows.forEach((consumedItem, key) => {
        //         let data = consumedItem & consumedItem.so_data ? consumedItem.so_data : null;
        //         let item = data && data.item_profile ? data.item_profile : null
        //         this.onItemSelect({
        //             ...consumedItem,
        //             so_item_id: consumedItem.so_item_id ? consumedItem.so_item_id : '',
        //             so_pending_qty: consumedItem.so_pending_qty ? consumedItem.so_pending_qty : '',
        //             so_transaction_id: consumedItem.so_transaction_id ? consumedItem.so_transaction_id : '',
        //             // itemdata: { ...consumedItem.so_data.item_profile, isAddRemark: consumedItem?.remark ? true : false, so_item_id: data && data.so_item_id ? data.so_item_id : '', so_transaction_id: data && data.transaction_id ? data.transaction_id : '' }
        //         }, this.state.allocationItemRows.length + key, consumedItem.new_qty ? consumedItem.new_qty : 0, 0, null, false, true);
        //     });
        // }
    }

    getCustomerDetails = (id, edit = false) => {
        if (id) {
            this.setState({ Customer_details_loading: true })
            HttpAPICall.withAthorization('GET', this.CustomerUrl + '/' + id, this.props.access_token, null, null, (response) => {
                let selectedCustomerAdress = response.data.data && response.data.data.addressList ? response.data.data.addressList[0] : [];
                this.setState({
                    CustomerDetails: response.data.data,
                    CustomerAddressList: response.data.data.addressList,
                    selectedCustomerAdress: edit == false ? selectedCustomerAdress : this.state.selectedCustomerAdress,
                    selectedBillingAddress: edit == false ? selectedCustomerAdress : this.state.selectedBillingAddress,
                    selectedShippingAddress: edit == false ? selectedCustomerAdress : this.state.selectedShippingAddress,
                });

            }).then(() => {
                this.setState({ Customer_details_loading: false });
                this.setState({ gstTypeName: this.state.selectedBillingAddress && this.state.warehouseDetail && this.state.selectedBillingAddress.state_id == this.state.warehouseDetail.state_id ? 'GST' : 'IGST' });
            });
        }
    }

    handleCustomerChange = (selectedOption, edit = false) => {        
        this.setState({
            allocationForm: {
                ...this.state.allocationForm,
                customer_id: selectedOption ? selectedOption.value : null
            },
            CustomerDetails: selectedOption ? selectedOption : null,
            selectedProject: edit == true ? this.state.selectedProject : null,
            searchedCustomerAdressList: selectedOption && selectedOption.Customer ? selectedOption.Customer.addressList : null,
            selectedCustomerAdress: selectedOption && selectedOption.Customer && edit == false ? selectedOption.Customer.addressList[0] : this.state.selectedCustomerAdress,
            searchedCustomer: selectedOption ? selectedOption : null,
            selectedBillingAddress: selectedOption && selectedOption.Customer && edit == false ? selectedOption.Customer.addressList[0] : this.state.selectedBillingAddress,
            selectedShippingAddress: selectedOption && selectedOption.Customer && edit == false ? selectedOption.Customer.addressList[0] : this.state.selectedShippingAddress,

        }, () => {
            if (selectedOption?.value) {
                this.salesOrderList(selectedOption, edit);
            }
        })
    };

    salesOrderList = (selectedOption = null, edit) => {
        const { allocationDetail, allocationForm } = this.state;
    
        const params = {
            search_status_levels: [4, 5],
            more_than_pending_qty: 0,
            per_page: 100,
            page: 1,
            search_customer_ids: [
                edit ? (allocationDetail?.customer?.id || allocationForm?.customer_id) : selectedOption.value
            ],
            search_warehouse_ids: [
                edit ? (allocationDetail?.warehouse?.id || allocationForm?.warehouse_id) : this.state.allocationForm?.warehouse_id
            ]
        };
    
    
        HttpAPICall.withAthorization('GET', this.salesOrderListUrl, this.props.access_token, params, {}, (resp) => {
            let soData = resp.data.data;
            this.setState({
                sales_order_list: soData && soData.map((so) => ({ label: so?.transaction_id, value: so?.transaction_id }))
            });
        })
    }


    saveallocationFormJsx = () => {
        let sub_total_amount = 0;
        this.state.allocationItemRows.forEach((item, key) => {
            if (item && item.item) {
                sub_total_amount += item.rate * item.qty;
            }
        });

        let transactionDate = this.state.allocationForm && this.state.allocationForm.transaction_date ? moment(this.state.allocationForm.transaction_date).format('YYYY-MM-DD 00:00:00') : null;
        return (<form className="bg-white p-3" onSubmit={this.allocationFormSubmitHandler}>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.allocationForm.transaction_date
                                ? moment(this.state.allocationForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'allocationForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Date of Transaction`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'allocationForm', 'warehouse_id', () => {
                                this.onWarehouseChange(selectedOption);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        required={true}
                        isDisabled={this.state.selectedSalesOrder}
                        value={this.state.allWarehouses.find(m => m.value == this.state.allocationForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    />
                </div>
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }
            <Customer
                CustomerDetails={this.state.CustomerDetails}
                details_loading={this.state.details_loading}
                CustomerAddressList={
                    this.state.CustomerAddressList?.length > 0
                        ? this.state.CustomerAddressList.map(address => ({
                            ...address,
                            source: 'customer'
                        }))
                        : []
                }
                Customer_details_loading={this.state.Customer_details_loading}
                selectedCustomerAdress={this.state.selectedCustomerAdress ? { ...this.state.selectedCustomerAdress, source: 'customer' } : this.state.selectedCustomerAdress}
                searchedCustomer={this.state.searchedCustomer}
                onChange={this.handleCustomerChange}
                getCustomerDetails={this.getCustomerDetails}
                CustomerRef={this.addCustomerModalRef}
                changeAddressHandler={this.changeAddressHandler}
                onSelectProject={this.handleSelectedProject}
                selectedProject={this.state.selectedProject}
                handleShipToAddress={true}
                selectedShippingAddress={this.state.selectedShippingAddress}
                selectedBillingAddress={this.state.selectedBillingAddress}
                onAddressSelect={this.handleAddressSelect}
                disabled={this.state.selectedSalesOrder || this.state.transactionFromDeal}
                disabledPerformAction={this.state.selectedSalesOrder}
            />

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'allocationForm', 'status')
                        }}
                        options={this.state.allStatus}
                        isSearchable={true}
                        required={true}
                        value={this.state.allStatus.find(m => m.value == this.state.allocationForm.status)}
                        placeholder="Please Select Status"
                        isDisabled={this.state.allocationDetail ? true : false}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Deals</label>
                </div>


                <div className="col-sm-6">
                    <InputDealSearch
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'allocationForm', 'deal_id');
                            this.setState({ searchedDeal: selectedOption ? selectedOption : null });
                        }}
                        menuPlacement="top"
                        search_customer_id={this.state.allocationForm.customer_id}
                        search_customer_project_id={this.state.selectedProject ? this.state.selectedProject.id : ''}
                        value={this.state.searchedDeal}
                        changeDeal={(deal) => {
                            this.tapSelectChange(deal ? deal.id : '', 'allocationForm', 'deal_id');
                            this.setState({ searchedDeal: deal ? deal : null });
                        }}
                        showSearch={true}
                        disabled={!this.state.allocationForm.customer_id || this.state.transactionFromDeal}
                    />
                </div>

            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require "> Sales Order </label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, "allocationForm", "so_transaction_id", () => {
                                if (selectedOption == null) {
                                    this.setState({ selectedSalesOrder: false })
                                } else {
                                    this.getItemSOLwisting(selectedOption ? selectedOption.value : '')
                                    this.setState({ selectedSalesOrder: true })
                                }
                            });
                        }}
                        options={this.state.sales_order_list}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        // isDisabled={this.state.salesDetail}
                        value={this.state.sales_order_list.find((s) => s.value == this.state.allocationForm.so_transaction_id)}
                        placeholder="Select Sales Order"
                    />
                </div>
                <div className="col-sm-4">
                    <button
                        type="button"
                        disabled={this.state.allocationForm.so_transaction_id ? false : true}
                        className="btn btn-link text-decoration-none"
                        onClick={() => this.pickItemDemandModalInit()}
                    ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} />  Pick Item from Approved Sales Order</button>
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="voucher_number" className="form-label">Voucher Number & Date</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="voucher_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.allocationForm.voucher_number}
                        onChange={(e) => this.formInputHandler(e, 'allocationForm')}
                        placeholder="Enter Voucher Number"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.allocationForm.voucher_date ? moment(this.state.allocationForm.voucher_date).toDate() : false}
                        name="voucher_date"
                        onChange={(value, event) => this.formDateHandler('voucher_date', value, 'allocationForm')}
                        // maxDate={moment().toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Enter Voucher Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="allocation_date" className="add_calender-icon" />
                </div>
            </div>
            {this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0 && <AdditionalAttributeForm
                additionalAttributes={this.state.formData.additional_attributes}
                formState={this.state.allocationForm}
                onAttributeChange={(value, attributeName) => {
                    this.setState(() => ({
                        allocationForm: {
                            ...this.state.allocationForm,
                            [attributeName]: value
                        }
                    }))
                }
                }
            />}
            {this.allocationItemsJsx()}
            <div className="row align-items-center my-3">
                <div className="col-sm-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.allocationForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'allocationForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-sm-6">
                    <table className="table table-borderless">
                        <tbody>
                            <tr className="subtotal_bg_color"><th>Sub Total</th><td className="text-end">{(sub_total_amount).toFixed(2)}</td></tr>
                            <tr className="subtotal_bg_color pt-2">
                                <th>Adjustment</th>
                                <td>
                                    <input
                                        name="adjustment_amount"
                                        type="number"
                                        className="form-control text-end"
                                        autoComplete="off"
                                        value={this.state.allocationForm.adjustment_amount}
                                        onChange={(e) => this.formInputHandler(e, 'allocationForm')}
                                        placeholder="Adjustment Amount"
                                    />
                                </td>
                            </tr>
                            <tr className="subtotal_bg_color">
                                <th>Final Total</th>
                                <td className="text-end">
                                    {this.state.allocationForm.adjustment_amount
                                        ? (sub_total_amount + parseFloat(this.state.allocationForm.adjustment_amount)).toFixed(2)
                                        : (sub_total_amount).toFixed(2)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {!this.state.buttonDisabled && <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                {/* {!this.state.allocationDetail || (this.state.allocationDetail && this.state.allocationDetail.status == 0)
                    ? <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} className="btn btn-secondary ms-2" onClick={(e) => { this.allocationFormSubmitHandler(e, 1); }}>
                        Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                    : null
                } */}
                <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} className="btn btn-primary ms-2" onClick={(e) => { this.allocationFormSubmitHandler(e, this.state.allocationForm.status); }}>
                    Save  {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>

            </div>}
        </form>);
    }
    //**********************Batch Number ITEM JSX********************** */
    // Inside your SalesAllocationAdd class

    // itemViewModalInit = (item) => {
    //     if (item) {
    //         this.setState({ selectedItemDetails: item, showItemDetailModal: true });
    //     }
    // };

    handleCancel = () => {
        this.setState({ batch_data: [] }, () => {
            this.batchListingModal.hide();
        })
    };

    handleBatchNumber = () => {

        let allBatchData = this.state.batch_data;
        let item_id = this.state.selected_batch_no_item_id;

        this.setState({
            choose_batch_number: true,
            allItemCurrentStocks: {
                ...this.state.allItemCurrentStocks,
                data: {
                    ...this.state.allItemCurrentStocks.data, [item_id]: allBatchData
                }
            }
        }, () => {
            this.batchListingModal.hide();
        });

    };

    handleSelectedbatchNumberData = (item_id, field) => {
        this.setState({
            selected_batch_no_item_id: item_id,
            batch_data: this.state.allItemCurrentStocks.data[item_id].map((bd, i) => i === field ? { ...bd, selected: true, choose_batch_number: false } : { ...bd, selected: false, choose_batch_number: false })
        });
    }

    viewbatchListingModalJsx = () => {
        const { selectedItemDetails, warehouseDetail, CustomerDetails, batch_data } = this.state;

        return (
            <div className="modal fade" id="batchListingModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Batch Number and Expiry Date</h5>
                            <button type="button" onClick={() => this.handleCancel()} className="btn-close" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Item Detail</span>
                            </div>
                            <table className="table table-hover table-borderless my-2">
                                <tbody>
                                    {selectedItemDetails ? (<>
                                        <tr>
                                            <td style={{ width: "15%" }}>Item Name</td>
                                            <td>
                                                {selectedItemDetails?.name}
                                            </td>
                                            <td style={{ width: "15%" }}>Item Code</td>
                                            <td>
                                                {selectedItemDetails?.item_code}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Warehouse</td>
                                            <td>
                                                {warehouseDetail?.name}
                                            </td>
                                            <td>Customer</td>
                                            <td>
                                                {CustomerDetails?.name}
                                            </td>

                                        </tr>
                                    </>
                                    ) : (
                                        <p>No item selected.</p>
                                    )}

                                </tbody>
                            </table>
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Batch & Expiry List</span>
                            </div>
                            <table
                                className="table table-bordered table-sm bg-white my-2"
                                id="allocationTable"
                            >
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" rowSpan="2" style={{ width: "5%" }} className="text-center">S.No</th>
                                        <th scope="col" style={{ width: "20%" }}>
                                            Batch Number
                                        </th>
                                        <th scope="col" style={{ width: "15%" }}>
                                            Expiry Date
                                        </th>
                                        <th scope="col" style={{ width: "15%" }}>
                                            Total Qty
                                        </th>
                                        <th scope="col" style={{ width: "15%" }}>
                                            Free Qty
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.stock_loading
                                            ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                            : (
                                                batch_data && batch_data.length > 0
                                                    ? (batch_data.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr className='text-center' key={index}>
                                                                    <td className="text-center">
                                                                        <input
                                                                            type="radio"
                                                                            name="selectedBatchNumber"
                                                                            checked={item.selected}
                                                                            onChange={() => this.handleSelectedbatchNumberData(item.item_id, index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.batch_number || "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.expiry_date_display || "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.total_qty || "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.free_qty || "-"
                                                                        }
                                                                    </td>
                                                                </tr></>
                                                        )
                                                    }))
                                                    : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                            )
                                    }
                                </tbody>
                            </table>

                        </div>
                        <div className="modal-footer">
                            <button onClick={() => this.handleCancel()}
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                disabled={this.state.loadingItemDetails}
                            >
                                Close{" "}
                            </button>
                            <button onClick={() => this.handleBatchNumber()} className="btn btn-primary" disabled={this.state.loadingItemDetails}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>Add Allocation</title></Helmet>
            {this.pageTitlejsx()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.state.allocationFormDataLoaded
                            ? this.saveallocationFormJsx()
                            : <Loader />
                        }
                    </div>
                </div>
            </div>
            {this.pickDemandItemModalJsx()}
            {this.viewbatchListingModalJsx()}
            {this.state.showItemAddModal && <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
            {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
            <ItemDetailsModal ref={this.itemDetailsRef} />
            <AddCustomerModal parentProps={this.props} ref={this.addCustomerModalRef} afterEditSubmit={(selectedCustomer) => this.handleCustomerChange(selectedCustomer)} />
            {/* <AllocationHistoryModal ref={this.allocationHistoryRef} /> */}
            {this.state.showItemSearchModal && <ItemSearchModal ref={this.itemSearchRef}
                afterSelectingItems={(selectedItems) => {
                    this.addItemsfromSearch(selectedItems);
                }}
            />}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        iam_group_sites: state.app && state.app.user_sites ? state.app.group_sites : [],
        permissions: state.app.acl_info,
        all_warehouses: state.app && state.app.warehouses ? state.app.warehouses : [],
    };
};

export default connect(mapStateToProps)(SalesAllocationAdd);

