import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import Ax from "../../../components/hoc/Ax";
import { Link } from 'react-router-dom';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { toast } from 'react-toastify';
import moment from 'moment';

class EngagementTimeView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                      =             {
            engagementTimeData            :            null, 
            viewDataLoading             :           false,       
        }
       
    }

    componentDidMount() {
           this.intializeData(this.props) 
           if(this.props.engagementId){
               this.getEngagementDetail(this.props.engagementId,this.props.counterlog)
           }   
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.engagementTimeData != nextProps.engagementTimeData) {
            this.intializeData(nextProps);
        }
        if(this.props.engagementId !== nextProps.engagementId){
            this.getEngagementDetail(nextProps.engagementId,nextProps.counterlog)
        }
    }

    intializeData       =       (pr)      =>      {
        this.setState({access_token : pr.access_token,engagementTimeData : pr.engagementTimeData})
    }

    getEngagementDetail                 =   (engagement_id='')  =>  {
        let total_hours = "00:00";
        this.setState({viewDataLoading: true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/engagement_time/detail' , this.state.access_token , {transaction_id : engagement_id,need_counterlog:'Y'}, {}, (response) => {
            let respData = response.data;
           this.setState({
                engagementTimeData : respData.data,  
            },() => {
               
            })
            
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({viewDataLoading: false}))
    }

     SecondsToHHMM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }   

    render                              =   ()  =>  {
        let engagement = this.state.engagementTimeData;
    
        return (<Ax>
            {this.state.viewDataLoading ? <Loader />
                : <Ax>
                    <div className="tab_content_wrapper"> <span className="content_heading">Engagement Detail</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-1 ">
                        {engagement && <tbody>
                            <tr>
                                <td style={{ width: "25%" }}>Transaction ID</td>
                                <th>{engagement.transaction_id ? engagement.transaction_id : ''} </th>
                            </tr>
                            <tr>
                                <td style={{ width: "25%" }}>Duration</td>
                                <th>{engagement.engagement_seconds ? this.SecondsToHHMM(engagement.engagement_seconds) : ''} </th>
                            </tr>
                            {engagement.start_time && <tr>
                                <td style={{ width: "25%" }}>Start Time</td>
                                <th>{engagement.start_time ? moment(engagement.start_time, 'HH:mm:ss').format('HH:mm A') : ''} </th>
                            </tr>}
                            {engagement.end_time && <tr>
                                <td style={{ width: "25%" }}>End Time</td>
                                <th>{engagement.end_time ? moment(engagement.end_time, 'HH:mm:ss').format('HH:mm A') : ''} </th>
                            </tr>}

                            {engagement.additional_attributes_data && engagement.additional_attributes_data.length > 0 ?
                                engagement.additional_attributes_data.map((attribute, index) => {
                                    return (<tr>
                                        <td style={{ width: "25%" }}>{attribute.name}</td>
                                        <th>{attribute.value_display ? attribute.value_display : "-"}</th>
                                    </tr>)
                                })
                                : null}
                            <tr>
                                <td style={{ width: "25%" }}>Remarks</td>
                                <th>{engagement.remarks ? engagement.remarks : ''} </th>
                            </tr>
                        </tbody>}
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Counterlog  Detail</span></div>
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "25%" }}>Site</td>
                                <th>{engagement && engagement.site ? engagement.site.site_name : '-'}   </th>
                            </tr>
                            {engagement && engagement.meter_reading && engagement.meter_reading.length > 0 && engagement.meter_reading.map(mr => {
                                return (
                                    <tr>
                                        <td style={{ "width": "25%" }}>{mr.label}</td>
                                        <th>{mr.reading} {mr.unit}   </th>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Transaction  Detail</span></div>
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "25%" }}>Transaction ID</td>
                                <th>{engagement && engagement.transaction_id ? engagement.transaction_id : '-'}   </th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Transaction</td>
                                <th>{engagement && engagement.feed_datetime ? engagement.feed_datetime : "-"}   </th>
                            </tr>
                            <tr>
                                <td>Executed By</td>
                                <th>{engagement && engagement.createdBy ? engagement.createdBy.full_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Entry</td>
                                <th >{engagement && engagement.created_at ? engagement.created_at : '-'}   </th>
                            </tr>
                            <tr>
                                <td>Updated By</td>
                                <th>{engagement && engagement.updatedBy ? engagement.updatedBy.full_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Update</td>
                                <th>{engagement && engagement.updated_at ? engagement.updated_at : '-'}   </th>
                            </tr>

                        </tbody>
                    </table>
                </Ax>}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};

export default connect(mapStateToProps)(EngagementTimeView);