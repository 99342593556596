import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import swal from "sweetalert";
import AdditionalAttributeForm from '../../../includes/ui/AdditionalAttributeForm';
import EngagementTimeView from '../../../transactions/engagementTime/EngagementTimeView';


class EngagementTime extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.formDataUrl            =   IAM_API_BASE_URL_2 + '/counterlog/engagement_time/form_data';
        this.engagementDetailUrl    =   IAM_API_BASE_URL_2 + '/counterlog/engagement_time/detail';
        this.engagementAddUrl       =   IAM_API_BASE_URL_2 + '/counterlog/engagement_time/add';
        this.engagementEditUrl      =   IAM_API_BASE_URL_2 + '/counterlog/engagement_time/edit';
        this.engagementDeleteUrl    =   IAM_API_BASE_URL_2 + '/counterlog/engagement_time/delete';

        this.EngagementFormInit       =   {
           status                   :   '',
           total_hour               :   '',
           full_shift               :   'N',
           start_time               :   '',
           end_time                 :   '',
        }

        this.state                  =   {
            access_token             :      '',
            formDataLoading          :      false,
            engagementDetail          :      null,
            engagementShiftDetail     :      null,
            allEngagementTypes        :      [],
            addEngagementForm         :       {...this.EngagementFormInit},
            saveFormSubmitting      :       false,
            prevEngagementData        :       null,
            editEngagement            :       false,
            engagement_id :      '',
            viewDataLoading          :      false,
            EngagementViewData          :     null,
            allAdditonalAttribute       :    []
        }
        this.logEngagementModalId      =   uuid();
        this.viewEngagementModalId    =   uuid();
    }

    componentDidMount               =   ()  =>  {
        this.initScreen(this.props);
        this.viewEngagementModal              =   new Modal(document.getElementById(this.viewEngagementModalId), {keyboard: false, backdrop: false}); 
        this.uploadEngagementModal            =   new Modal(document.getElementById(this.logEngagementModalId), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    uploadEngagementModalInit               =   (shiftDetail, counterlog, assetData = null,engagement_id = '')  =>  {
        this.uploadEngagementModal.show()
        this.setState({addEngagementForm : {...this.EngagementFormInit},copy_reading:'N'},() => {
          
                this.loadengagementDetailFromApi(counterlog && counterlog.transaction_id,engagement_id);
            
            this.setState({
                assetData           :   assetData,
                shift               :   shiftDetail,
                counterlog          :   counterlog,
                date                :   counterlog ? counterlog.feed_date : '',
                transaction_id      :   counterlog ? counterlog.transaction_id : '',
                editEngagement        :   engagement_id ? true : false,
                engagement_id        :   engagement_id ? engagement_id : ''
            },() => {
               
            });
            if(document.getElementById(`uploadEngagementForm`)) {
                document.getElementById(`uploadEngagementForm`).reset();
            }
        })
        
    }

    loadengagementDetailFromApi                 =   (id,engagement_id='')  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', this.formDataUrl , this.state.access_token , {counterlog_transaction_id : id,include_prev_Engagement:'Y'}, {}, (response) => {
        
            this.setState({
              engagementDetail        :   JSON.stringify(response.data.Engagement) !== JSON.stringify({}) ? response.data.Engagement : null,
              engagementShiftDetail   :   response.data && response.data.shift ? response.data.shift : null,
              setting               :   response.data && response.data.setting ? response.data.setting : null,
              allAdditonalAttribute :   response.data && response.data.additional_attributes && response.data.additional_attributes.length > 0 ? response.data.additional_attributes : [],
             }, () => {
               if(engagement_id){
                    this.getEngagementDetail(engagement_id)
               }
        
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
   }

   getEngagementDetail                 =   (engagement_id='')  =>  {
    let total_hours = "00:00";
    this.setState({viewDataLoading: true})
    HttpAPICall.withAthorization('GET', this.engagementDetailUrl, this.state.access_token , {transaction_id : engagement_id,need_counterlog:'Y',need_site:'Y'}, {}, (response) => {
        let respData = response.data;
        let updateFormState = [];
        if (respData.data && respData.data.additional_attributes_data && respData.data.additional_attributes_data.length > 0) {
            respData.data.additional_attributes_data.map((i,k) => {
                let name = 'additional_attributes_' + i.key;
                updateFormState[name] = i.value
            });
        }
        if(respData && respData.data && respData.data.engagement_seconds){
            let seconds = respData.data.engagement_seconds;
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            total_hours = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        }
         this.setState({
           EngagementViewData : respData.data,
            
            addEngagementForm     : {
                ...this.EngagementFormInit,
                ...updateFormState,
                full_shift    :   this.state.engagementShiftDetail && this.state.engagementShiftDetail.seconds && respData.data && respData.data.engagement_seconds && this.state.engagementShiftDetail.seconds == respData.data.engagement_seconds ? 'Y' : 'N',
                engagement_seconds : respData.data && respData.data.engagement_seconds ? respData.data.engagement_seconds : 0 ,
                total_hour      :  moment(respData.data.feed_datetime).startOf('day').seconds(respData.data.engagement_seconds).toDate(),
                transaction_id  : respData.data && respData.data.transaction_id ? respData.data.transaction_id : '',
                start_time      :   respData.data && respData.data.start_time ? moment(respData.data.start_time,"hh:mm a").toDate() : '',
                end_time      :   respData.data && respData.data.start_time ? moment(respData.data.end_time,"hh:mm a").toDate() : '',
                remarks         :  respData.data && respData.data.remarks ? respData.data.remarks : '',
            }
        },() => {
           
        })
        
    }, (error) => {
        toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
    }).then(() => this.setState({viewDataLoading: false}))
}

    
    initScreen                     =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    viewEngagementDetailModalInit         =       (shiftDetail, counterlog, assetData = null,id='')      =>      {
        this.viewEngagementModal.show()
        this.setState({EngagementViewData : null , viewDataLoading : true}
            , () => {
                this.getEngagementDetail(id)
                this.setState({
                    assetData               :   assetData,
                    shift                   :   shiftDetail,
                    counterlog              :   counterlog,
                    date                    :   counterlog ? counterlog.feed_date : '',
                    transaction_id          :   counterlog ? counterlog.transaction_id : '',
                    EngagementViewData        :   null,
                });
         })
    }

    submitEngagementHandler       =       (e)      =>      {
        e.preventDefault()
         this.setState({saveFormSubmitting : true});
        let additional_attributes = {};
        if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ){
            this.state.allAdditonalAttribute.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addEngagementForm[name] ? this.state.addEngagementForm[name] : '';
            })
        } 
        
        let engagement_seconds =  this.state.addEngagementForm.total_hour  ? moment(this.state.addEngagementForm.total_hour,'HH:mm').format('HH:mm') : "00:00";
        var timeParts = engagement_seconds.split(":");
        var hours = parseInt(timeParts[0], 10);
        var minutes = parseInt(timeParts[1], 10);

        // Convert hours and minutes to seconds
        var totalSeconds = (hours * 3600) + (minutes * 60);
        let formData = {
            transaction_id      : this.state.addEngagementForm.transaction_id,
            remarks : this.state .addEngagementForm.remarks,
            additional_attributes : additional_attributes,
            full_shift : this.state.addEngagementForm.full_shift ? this.state.addEngagementForm.full_shift : "N",
            counterlog_transaction_id : this.state.counterlog && this.state.counterlog.transaction_id ? this.state.counterlog.transaction_id : '',
            start_time             : this.state.addEngagementForm.start_time  ? moment(this.state.addEngagementForm.start_time,'HH:mm:ss').format('HH:mm:ss') : "",
            end_time             : this.state.addEngagementForm.end_time  ? moment(this.state.addEngagementForm.end_time,'HH:mm:ss').format('HH:mm:ss') : "",
        }
        
        if(this.state.editEngagement){
            HttpAPICall.withAthorization('PUT', this.engagementEditUrl, this.props.access_token, null, {...formData}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.uploadEngagementModal.hide();
                if(this.props.afterSaveEngagementTime) {
                    let asset_id            =   this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveEngagementTime(this.state.date, asset_id);
                }
                this.setState({
                    addEngagementForm         :   {...this.addEngagementFormInit},
                });
            }).then(() => this.setState({saveFormSubmitting: false}))
        }else{
            HttpAPICall.withAthorization('POST', this.engagementAddUrl, this.props.access_token, null, {...formData}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.uploadEngagementModal.hide();
                if(this.props.afterSaveEngagementTime) {
                    let asset_id            =   this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveEngagementTime(this.state.date, asset_id);
                }
                this.setState({
                    addEngagementForm         :   {...this.addEngagementFormInit},
                });
            }).then(() => this.setState({saveFormSubmitting: false}))
        }
       
        //}).then(() => this.setState({saveFormSubmitting: false}))
    }

    deleteEngagementTimeInit        =   (transaction_id,id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.engagementDeleteUrl , this.props.access_token, {counterlog_transaction_id : transaction_id,transaction_id : id},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteEngagement) {
                        this.props.afterDeleteEngagement()
                    }
                }).then(() => { });
            }
        });
    }

    
    getTimeDiffernce = () => {
        if (this.state.addEngagementForm.start_time && this.state.addEngagementForm.end_time) {
            const startTime = moment(this.state.addEngagementForm.start_time);
            const endTime = moment(this.state.addEngagementForm.end_time);
            
            // Check if shift detail indicates that the shift spans across two days
            const shiftSpansTwoDays = this.state.engagementShiftDetail && this.state.engagementShiftDetail.same_day === 'N';
    
            // If the shift spans two days and the end time is before the start time, adjust the end time to the next day
            if (shiftSpansTwoDays && endTime.isBefore(startTime)) {
                endTime.add(1, 'day');
            }
    
            // If the shift spans two days and the start time is after 00:00, adjust the start time to the previous day
            if (shiftSpansTwoDays && startTime.hour() === 0 && startTime.minute() === 0) {
                startTime.subtract(1, 'day');
            }
    
            // Calculate the difference in seconds
            const differenceInSeconds = endTime.diff(startTime, 'seconds');
    
            // Update the state with the time difference
            this.setState({
                addEngagementForm: {
                    ...this.state.addEngagementForm,
                    engagement_seconds: differenceInSeconds
                }
            });
        }
    }
    

  

    uploadEngagementModalJsx                  =   ()  =>  {
        
        let max_time_start = moment(new Date(), "hh:mm a").toDate();
        let min_time_start = moment("00:05", "HH:mm").startOf('day').toDate();
        let max_time_end = moment(new Date(), "hh:mm a").toDate();
        let min_time_end = moment("00:05", "HH:mm").startOf('day').toDate();

        if (this.state.engagementShiftDetail) {
            
            if (this.state.engagementShiftDetail.shift_start === this.state.engagementShiftDetail.shift_end) {
                // For 24-hour shift, remove min and max time filters
                min_time_start = null;
                max_time_start = null;
                min_time_end = null;
                max_time_end = null;
            } else {
                // For non-24-hour shift, adjust min_time and max_time for both start_time and end_time
                min_time_start = moment(this.state.engagementShiftDetail.shift_start, "hh:mm a").toDate();
                max_time_start = moment(this.state.engagementShiftDetail.shift_end, "hh:mm a").toDate();
                min_time_end = min_time_start;
                max_time_end = max_time_start;
            }
        }
        let feed_date = this.state.counterlog ? this.state.counterlog.feed_date : ''
        return (
            <div className="modal fade" id={this.logEngagementModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Log Engagement for  : {this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                    {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}</h5>

                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>

                            <div className='p-1 bg-light border-bottom'>
                                <table className='table table-sm mb-0 table-borderless text-sm'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                            <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-body">
                                {this.state.formDataLoading || this.state.viewDataLoading
                                    ? <Loader />
                                    :
                                    <form id={`uploadEngagementForm`} onSubmit={this.submitEngagementHandler}>
                                        
                                        <div className="row my-2 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Duration</label>
                                            <div className="col-sm-7">
                                                {this.state.addEngagementForm.full_shift && this.state.addEngagementForm.full_shift == 'Y'
                                                    ? <input
                                                        // name="total_hour"
                                                        className="form-control form-control-sm"
                                                        placeholder="Duration"
                                                        disabled={true}
                                                        autoComplete="off"
                                                        value={this.state.engagementShiftDetail && this.state.engagementShiftDetail.shift_time ? this.state.engagementShiftDetail.shift_time : ""}
                                                    />
                                                    : <DatePicker
                                                        onChange={(value, event) => {
                                                            //this.formDateHandler('engagement_seconds',moment.duration(moment(value).format('HH:mm:00')).asSeconds() , 'addEngagementForm');
                                                            this.setState({ addEngagementForm: { ...this.state.addEngagementForm, engagement_seconds: moment.duration(moment(value).format('HH:mm:00')).asSeconds()} });
                                                        }}
                                                        selected={this.state.addEngagementForm.engagement_seconds > 0 
                                                            ? new Date(new Date().setHours(0, 0, this.state.addEngagementForm.engagement_seconds, 0))
                                                            : new Date(new Date().setHours(0, 0, 0, 0))}
                                                        //selected={this.state.addEngagementForm?.total_hour ? this.state.addEngagementForm.total_hour : ''}
                                                        className="form-control form-control-sm"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeFormat="HH:mm"
                                                        timeIntervals={5}
                                                        timeCaption="Hours"
                                                        dateFormat="HH:mm"
                                                        autoComplete={"off"}
                                                        placeholderText={`Select Limit`}
                                                        minTime={moment("00:05", "HH:mm").startOf('day').toDate()}
                                                        maxTime={max_time_start}
                                                        disabled={ true}
                                                    />}
                                            </div>
                                            <div className='col-sm-2'>
                                                <input
                                                    name="full_shift"
                                                    type="checkbox"
                                                    value="Y"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            addEngagementForm: {
                                                                ...this.state.addEngagementForm,
                                                                engagement_seconds: this.state.engagementShiftDetail ? this.state.engagementShiftDetail.seconds  : 0,
                                                               // total_hour: this.state.engagementShiftDetail ? moment(this.state.engagementShiftDetail.shift_time, "hh:mm a").toDate() : new Date(),
                                                                full_shift: (this.state.addEngagementForm.full_shift ? this.state.addEngagementForm.full_shift : 'N') == 'Y' ? 'N' : 'Y',
                                                                start_time: this.state.engagementShiftDetail ? moment(this.state.engagementShiftDetail.shift_start, "hh:mm a").toDate() : '',
                                                                end_time: this.state.engagementShiftDetail ? moment(this.state.engagementShiftDetail.shift_end, "hh:mm a").toDate() : '',
                                                            }
                                                        });
                                                    }}
                                                    className="form-check-input"
                                                    id="full_Shift"
                                                    checked={this.state.addEngagementForm.full_shift === "Y"}
                                                />
                                                <label className="form-check-label mx-2" htmlFor="full_Shift">Full Shift</label>
                                            </div>
                                        </div>
                                        <div className="row my-2 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Start & End Time</label>
                                            <div className="col-sm-7">
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <DatePicker
                                                            onChange={(value, event) => {
                                                                this.setState({ addEngagementForm: { ...this.state.addEngagementForm, start_time: value } },() => {
                                                                    this.getTimeDiffernce();
                                                                });
                                                            }}
                                                            selected={this.state.addEngagementForm?.start_time ? this.state.addEngagementForm.start_time : ''}
                                                            className="form-control form-control-sm"
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeFormat="HH:mm"
                                                            timeIntervals={5}
                                                            timeCaption="Hours"
                                                            dateFormat="HH:mm a"
                                                            autoComplete={"off"}
                                                            placeholderText={`Start Time`}
                                                            minTime={min_time_start}
                                                            maxTime={max_time_start}
                                                            disabled={this.state.addEngagementForm && this.state.addEngagementForm.full_shift == "Y" ? true : false}
                                                        />
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <DatePicker
                                                            onChange={(value, event) => {
                                                                this.setState({ addEngagementForm: { ...this.state.addEngagementForm, end_time: value } },() => {
                                                                    this.getTimeDiffernce();
                                                                });
                                                            }}
                                                            selected={this.state.addEngagementForm?.end_time ? this.state.addEngagementForm.end_time : ''}
                                                            className="form-control form-control-sm"
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeFormat="HH:mm"
                                                            timeIntervals={5}
                                                            timeCaption="Hours"
                                                            dateFormat="HH:mm a"
                                                            autoComplete={"off"}
                                                            placeholderText={`End Time`}
                                                            minTime={min_time_end}
                                                            maxTime={max_time_end}
                                                            disabled={this.state.addEngagementForm && this.state.addEngagementForm.full_shift == "Y" ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        {
                                            this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0
                                                ? (
                                                    <AdditionalAttributeForm
                                                        additionalAttributes={this.state.allAdditonalAttribute}
                                                        formState={this.state.addEngagementForm}
                                                        onAttributeChange={(value, attributeName) => {
                                                            this.setState(() => ({
                                                                addEngagementForm: {
                                                                    ...this.state.addEngagementForm,
                                                                    [attributeName]: value
                                                                }
                                                            }))
                                                        }
                                                        }
                                                        formSmall={true}
                                                        colSpan={"col-sm-7"}
                                                    />
                                                )
                                                : null
                                        }
                                       
                                        
                                        <div className="row my-2 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Remarks</label>
                                            <div className="col-sm-7">
                                                <textarea
                                                    name="remarks"
                                                    className="form-control"
                                                    placeholder="Enter Remarks"
                                                    onChange={(e) => this.formInputHandler(e, 'addEngagementForm')}
                                                    style={{ height: "80px", fontSize: "93%" }}
                                                    autoComplete="off"

                                                    value={this.state.addEngagementForm.remarks}
                                                />
                                            </div>

                                        </div>
                                    </form>}

                            </div>
                            <div className="modal-footer">
                                
                               <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={`uploadEngagementForm`}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    SecondsToHHMM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }   

    viewEngagementModalJsx              =   ()  =>  {
        let engagement = this.state.EngagementViewData; 
         return (
             <div className="modal fade" id={this.viewEngagementModalId} tabIndex="-1">
                 <div className="modal-dialog modal-lg modal-dialog-scrollable">
                     <div className="modal-content">
                         <div className="modal-header">
                             <h5 className="fs-6 m-0">
                                 <span>View Engagement Time for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                 {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                             </h5>
                             <button type="button" className="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                         <div className='p-1 bg-light border-bottom'>
                             <table className='table table-sm mb-0 table-borderless text-sm'>
                                 <tbody>
                                     <tr>
                                         <td style={{ width: "25%" }}> Asset Name (Code)   </td>
                                         <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                     </tr>
                                     <tr>
                                         <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                     </tr>
                                 </tbody>
                             </table>
                         </div>
                         <div className="modal-body">
                             {this.state.viewDataLoading ? <Loader />
                                 : <Ax>
                                    <EngagementTimeView engagementTimeData= {engagement}/>
                                 </Ax>}
 
                         </div>
                         <div className="modal-footer d-flex justify-content-between row">
                             <div className="col text-start">
 
                                 <div className="col text-end">
                                     <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" >Close</button>
 
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         );
     }
    
    render                              =   ()  =>  {
        return (<Ax>
            {this.uploadEngagementModalJsx()}
            {this.viewEngagementModalJsx()}
            
        </Ax>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(EngagementTime);