import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from '../../../services/TapIcon';
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import moment from 'moment';
import Status from '../../../components/ui/Status';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import DateService from '../../../services/DateService';
import SubAssemblyDetail from './SubAssemblyDetail';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import LinkingModal from './LinkingModal';

class SubAssemblyList extends AppBaseComponent {

    constructor(props) {
        super(props);
    
        this.subAssemblySearchInitState =   {
            'search_name'                   :   '',
            'search_card_number'            :   '', 
            'search_category_id'            :   '',  
            'search_serial_number'          :   '',
            'search_site_ids'               :   [],
            'search_asset_ids'              :   [],
            'search_status_ids'             :   [],
            'search_linking_status'         :   '',
        };

        this.subAssemblySearchFormLabel =   {
            'search_name'                   :   'Name : ',
            'search_card_number'            :   'Code : ',
            'search_serial_number'          :   'Serial Number : ',
            'search_site_ids'               :   'Sites : ',
            'search_asset_ids'              :   'Assets : ',
            'search_status_ids'             :   'Status : ',
            'search_category_id'            :   'Category : ',
            'search_linking_status'         :   'Linking Status : '
        };

        this.executeFormInit            =       {
            odometer                     :       '',
            operation_cycle               :       '',
            hourmeter                     :       '',
            date_time                     :       '',
            hourmeter2                    :       '' , 
            hourmeter3                    :       '' , 
            hourmeter4                    :       '' ,
            odometer2                    :       '',
            odometer3                    :       '',
            operation_cycle2              :       '',
          }

        this.changeSiteTranstionFormInit        =       {
            site_since_date                     :       "",
        }

        this.changeStatusFormInit               =   {
            status_id                               :   "",
            status_since_date                       :   "",
            remark                                  :   "",
        };

        this.changeSiteFormInit               =   {
            site_id                               :   "",
            remark                                :   "",
            transfer_date                         :   "",
        }; 
        
        this.linkAssetFormInit               =   {
           name                             :       "",
           remark                           :       "",
           odometer                     :       '',
           operation_cycle               :       '',
           hourmeter                     :       '',
           date_time                     :       '',
           hourmeter2                    :       '' , 
           hourmeter3                    :       '' , 
           hourmeter4                    :       '' ,
           odometer2                    :       '',
           odometer3                    :       '',
           operation_cycle2              :       '',
           date_time                    :       '',
           linked_hour_meter            :       '',
           linked_odo_meter             :       '',
           linked_opc                   :       ''
        };  

        this.state                      =   {  
            minimizeTable                   :   false,   
            listing_loading                 :   false,
            listing_tbl_page                :   1,
            sub_assembly_listing            :   [],
            listingMeta                     :   null,
            totalListingCount               :   0,

            subAssemblySearchForm           :   {...this.subAssemblySearchInitState},
            submittedSubAssemblySearchForm  :   {...this.subAssemblySearchInitState},
            file_downloading                :   false,
            viewDataLoading                 :   false,
            access_token                    :   '',

            formSearchedElems               :   [],
            allStatus                       :   [],
            subAssemblyCategories           :   [],
            subAssemblyTags                 :   [],
            siteList                        :   [],
            changeStatusForm                :   {...this.changeStatusFormInit},
            changeSiteForm                  :   {...this.changeSiteFormInit},
            linkAssetForm                   :   {...this.linkAssetFormInit},
            countelogForm                   :   {...this.executeFormInit},
            
            saveFormSubmitting              :   false,
            subAssemblyId                   :   '',
            subAssemblyDetail               :   null,
            searchedAssets                  :   [],
            linkedAsset                     :   null,
            assetDetail                     :   null,
            proceedForm                     :   false,
            enable_hour_meter               :   'N',
            enable_odo_meter               :   'N',
            enable_opc                     :   'N',
            currentActionType               :   '',
            linkingStatus                    :   []
        };

        this.subAssemblyUrl         =   IAM_API_BASE_URL_2 + '/' + 'sub_assembly';
        this.viewLoadData           =   React.createRef();
        this.changeStatusModal      =   React.createRef();
        this.changeSiteModal        =   React.createRef();
        this.searchModal            =   React.createRef();
        this.linkAssetModalRef      =   React.createRef();
    }
    
    
    componentDidMount() {
        this.initilaizeFilterFormData(this.props);
        this.changeStatusModal      =   new Modal(document.getElementById('changeStatusModal'), {keyboard: false, backdrop :false});
        this.changeSiteModal        =   new Modal(document.getElementById('changeSiteModal'), {keyboard: false, backdrop :false});
        this.searchModal            =    new Modal(document.getElementById('searchModal'), {keyboard: false, backdrop :false});
        this.linkAssetModal            =    new Modal(document.getElementById('linkAssetModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeFilterFormData(nextProps);
        }
            
    }

    initilaizeFilterFormData        =       (pr)        =>      {

        this.setState({listing_loading: true}); 
        HttpAPICall.withAthorization('GET',  this.subAssemblyUrl + '/form_data', this.state.access_token ? this.state.access_token : pr.access_token, {}, {}, (resp) => {
            let formData = resp.data;
            this.setState({
                subAssemblyCategories       :   formData && formData.sub_assembly_categories && formData.sub_assembly_categories.length > 0 ?  formData.sub_assembly_categories.map((c) => { return {value: c.id, label: c.name}; }) : [],      
                subAssemblyTags             :   formData && formData.sub_assembly_tags && formData.sub_assembly_tags.length > 0 ?  formData.sub_assembly_tags.map((tag) => { return {value: tag.name, label: tag.name}; }) : [],      
                allStatus                   :   formData && formData.sub_assembly_status && formData.sub_assembly_status.length > 0 ?  formData.sub_assembly_status.map((s) => { return {value: s.id, label: s.name}; }) : [],      
                // additionalAttributes        :   formData && formData.additional_attributes && formData.additional_attributes.length > 0 ?  formData.additional_attributes : [],      
                siteList                    :    pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`,site_code : s.site_code}}) : [],
                linkingStatus                :   [{value : 'Y', label : 'Yes'}, {value: 'N', label : 'No'}],
            }, () => {
                if(this.props.match && this.props.match.params && this.props.match.params.id) {
                    this.viewSubAssembly(this.props.match.params.id, 'minimize');
                }
                if(pr && pr.location && pr.location.search && pr.location.search){
                    const paramObject =   new URLSearchParams(this.props.location.search);
                    this.setState({
                        subAssemblySearchForm : {
                            search_category_id : paramObject.getAll('search_category_id')[0] ? parseInt(paramObject.getAll('search_category_id')[0]) : '', 
                            search_site_ids : paramObject.getAll('search_site_id')[0] ? [parseInt(paramObject.getAll('search_site_id')[0])] : '',
                            search_linking_status : paramObject.getAll('linking_status')[0]? paramObject.getAll('linking_status')[0] : 0,
                            search_status_ids : paramObject.getAll('search_status_id')[0] ? [parseInt(paramObject.getAll('search_status_id')[0])] : []
                        }
                    }, () => {
                        this.searchFormSubmit();
                    })
                }else {
                    this.loadListingTblData(1);
                }
            });
        })
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedSubAssemblySearchForm};
        HttpAPICall.withAthorization('GET',  this.subAssemblyUrl + '/list', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                sub_assembly_listing    :   respData.data,
                listingMeta             :   respData.meta,
                totalListingCount       :   respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    closeView           =       ()        =>      {
        this.setState({minimizeTable : false}, () => {
            this.loadListingTblData(this.state.listing_tbl_page)
        });
    }


    viewSubAssembly         =   (id, type = null)  => {
        if(type === 'minimize') {
            this.setState({
                minimizeTable   :   true,
            },() => {
                this.viewLoadData.current.subAssemblyDetail(id)
                this.viewLoadData.current.getDashboardDetail(id)
                this.viewLoadData.current.getSiteLinking(id)
                this.viewLoadData.current.getAssetLinkinglisting(id) 
            })
            
            
        }else {
            this.setState({
                minimizeTable   :   true,
                subAssemblyId   :   id
            });
        }
    }



    viewSubAssemblyDetail   =   (subAssemblyDetail) => {
        this.setState({
            subAssemblyDetail   : subAssemblyDetail
        });
    }


    addSubAssemblyInit             =   () => {
        this.props.history.push('/sub_assembly_add');   
    }

    //***********************Sub Assembly JSX****************
    subAssemblyListjsx          =   ()  =>  {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Sub Assembly </h3>
                {!this.state.minimizeTable
                    ? <div className="text-end mt15">
                        <button type="button" 
                        disabled={this.state.listing_loading || this.state.file_downloading || !this.props.permissions.includes('iam-sub-assembly-add')} className="btn btn-primary" onClick={this.addSubAssemblyInit}>
                            Add Sub Assembly
                        </button>
                        <button type="button" disabled={this.state.listing_loading || this.state.file_downloading} onClick={this.searchModalInit} className="btn btn-secondary" >
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                        </button>
                        {
                        //     <span className="dropdown">
                        //     <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={this.state.excelDownloading == true}>
                        //         <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        //     </button>
                        //     <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        //         <li><a className="dropdown-item"  role="button" >Download</a></li>
                        //     </ul>

                        // </span>
                    }
                    </div>
                    : null}
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3 className="text-capitalize">Sub Assembly : {this.state.subAssemblyDetail && this.state.subAssemblyDetail.name + " (" + this.state.subAssemblyDetail.card_number + ")" }</h3>
                            <div className="text-end mt15">
                                <span className="dropdown">
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary"
                                    disabled={ this.state.subAssemblyDetail ? false : true}
                                    >
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                       {this.props.permissions.includes('iam-sub-assembly-edit') && <li> 
                                            <Link role="button" className={['dropdown-item'].join(' ')} 
                                            to= {{
                                                pathname : '/sub_assembly_update', 
                                                state : {subAssemblyDetail : this.state.subAssemblyDetail, isClone : false}
                                            }}
                                            style={{ "pointerEvents": "all" }}
                                            >
                                                Edit 
                                            </Link>
                                        </li>}
                                        {this.state.subAssemblyDetail && this.state.subAssemblyDetail.status?.id !== 2 && this.props.permissions.includes('iam-sub-assembly-edit') &&  <li>
                                            <Link role="button" className={['dropdown-item'].join(' ')} style={{ "pointerEvents": "all" }} onClick={this.showSiteChangeModalInit}>
                                                Change Linked Site
                                            </Link>
                                        </li>}
                                        {this.props.permissions.includes('iam-sub-assembly-change-status') && <li>
                                            <Link role="button" className={['dropdown-item'].join(' ')} style={{ "pointerEvents": "all" }} onClick={this.showStatusChangeModalInit}>
                                                Change Status
                                            </Link>
                                        </li>} 
                                       {this.state.subAssemblyDetail && !this.state.subAssemblyDetail.asset_profile && this.state.subAssemblyDetail.status?.id !== 2 && this.props.permissions.includes('iam-sub-assembly-asset-manage') && <li>
                                            <Link role="button" className={['dropdown-item'].join(' ')} style={{ "pointerEvents": "all" }} onClick={() => this.linkAssetModalInit('link')}>
                                                Link Asset
                                            </Link>
                                        </li>}
                                        {this.state.subAssemblyDetail && this.state.subAssemblyDetail.asset_profile && this.state.subAssemblyDetail.status?.id !== 2 && this.props.permissions.includes('iam-sub-assembly-asset-manage') &&  <li>
                                            <Link role="button" className={['dropdown-item'].join(' ')} style={{ "pointerEvents": "all" }} onClick={() => this.linkAssetModalInit('delink')}>
                                                DeLink Asset
                                            </Link>
                                        </li>}
                                        {this.props.permissions.includes('iam-sub-assembly-add') && <li> 
                                            <Link role="button" className={['dropdown-item'].join(' ')} 
                                            to= {{
                                                pathname : '/sub_assembly_add', 
                                                state : {subAssemblyDetail : this.state.subAssemblyDetail, isClone : true}
                                            }}
                                            style={{ "pointerEvents": "all" }}>
                                                Clone 
                                            </Link>
                                        </li>}
                                    </ul>
                                </span>
                                <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >
                        {this.subAssemblyListingTableJsx()}
                    </div>
                    {this.state.minimizeTable && <div className="col-9 pr4 pl4">
                        <SubAssemblyDetail ref={this.viewLoadData} closeView={this.closeView} viewSubAssemblyDetail = {this.viewSubAssemblyDetail} subAssemblyId = {this.state.subAssemblyId}/>
                    </div>}
                </div>
            </div>
        </Ax>);
    }

    subAssemblyListingTableJsx  =   ()  =>  {
        return (<Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.searchFormClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Sub Assembly</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "15%" }}>Sub-Assembly</th>
                            <th scope="col" style={{ width: "11%" }}>Code</th>
                            <th scope="col" style={{ width: "12%" }}>Category</th>
                            <th scope="col" style={{ width: "16%" }}>Site</th>
                            <th scope="col" style={{ width: "16%" }}>Linked Asset</th>
                            <th scope="col" style={{ width: "15%" }}>Date of Linking</th>
                            <th scope="col" style={{ width: "10%" }}>Status</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="8"><Loader /></td></tr>)
                        : (this.state.sub_assembly_listing.length > 0
                            ? (this.state.sub_assembly_listing.map((data, index) => {
                                return (<tr key={index} >
                                    {
                                        data.is_Loading && data.is_Loading == true ? <td colSpan={10} className="text-center"><Loader /></td> 
                                         :
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <div className="text-capitalize link-primary cursor_pointer"><Link to={'/sub_assembly/' + data.enc_id} onClick={() => this.viewSubAssembly(data.enc_id, 'minimize')}>{data.name ? data.name : "-"}</Link></div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{data.card_number ? data.card_number : "-"} </small>
                                                            <small className="float-end">
                                                            <Status color={data.status && data.status.color_code}>{data.status && data.status.name}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">
                                                {index+1}
                                                </td>
                                                <td className='text-capitalize link-primary cursor_pointer'>
                                                <Link to={'/sub_assembly/' + data.enc_id} onClick={() => this.viewSubAssembly(data.enc_id, 'minimize')}>
                                                {data.name ? data.name : "-"}</Link>
                                                </td>
                                                <td>{data.card_number ? data.card_number : "-"}</td>
                                                <td>{data.category ? data.category.name : "-"}</td>
                                                <td>{data.site ? data.site.site_name : "-"}</td>
                                                <td>{data.asset_profile ? <div>{data.asset_profile.name} <br/><span className='form-text'>(Code : {data.asset_profile.asset_code})</span></div> : "-"}</td>
                                                 <td className='text-capitalize'>{data.asset_date_change ? DateService.dateTimeFormat(data.asset_date_change,'DD-MMM-YYYY HH:mm A') : "" }
                                                    
                                                </td>
                                                <td><Status color={data.status ? data.status.color_code : ''}>{data.status ? data.status.name : '-'}</Status></td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    searchFormSubmit    =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.subAssemblySearchForm);
        let searchedElems               =   [];
        let subAssemblySearchParams     =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.subAssemblySearchFormLabel[key];
            let value                       =   this.state.subAssemblySearchForm[key];
            if(value && value.toString().length > 0) {
                subAssemblySearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_name') {
                        show_val            =   this.state.subAssemblySearchForm.search_name;
                    }
                    if(key == 'search_serial_number') {
                        show_val            =   this.state.subAssemblySearchForm.search_serial_number;
                    }
                    if(key == 'search_card_number') {
                        show_val            =   this.state.subAssemblySearchForm.search_card_number;
                    }
                    if(key == 'search_category_id') {
                        show_val            =   this.state.subAssemblyCategories.filter((c) => c.value == value).map(c => c.label).join(', ');
                    }
                    if(key == 'search_linking_status') {
                        show_val            =   this.state.linkingStatus.filter((l) => l.value == value).map(l => l.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.siteList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }                    
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets.filter((a) => value.includes(a.value)).map(a => a.label).join(', ');
                    }                    
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatus.filter((a) => value.includes(a.value)).map(a => a.label).join(', ');
                    }                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedSubAssemblySearchForm  :   {...this.state.subAssemblySearchForm},
            formSearchedElems               :   searchedElems
        }, () => {
            this.searchModal.hide();
            this.loadListingTblData(1)
        });
    }

        
    searchModalInit  = () => {
        this.searchModal.show();
    }

    searchFormClear     =   ()  =>  {
        this.setState({
            subAssemblySearchForm           :   {...this.subAssemblySearchInitState},
            submittedSubAssemblySearchForm  :   {...this.subAssemblySearchInitState},
            formSearchedElems               :   []
        }, () => {
            this.searchModal.hide();
            this.loadListingTblData(1)
        });
    }


    subAssemblySearchModalJsx   =   ()  =>  {
        return (
            <div className="modal fade" id="searchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchModalLabel">Sub Assembly Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.searchFormSubmit} id="searchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Name</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_name"
                                            className="form-control form-control-sm"
                                            placeholder="Enter Sub Assembly Name"
                                            autoComplete="off"
                                            value={this.state.subAssemblySearchForm.search_name}
                                            onChange={(e) => this.formInputHandler(e, "subAssemblySearchForm")}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Code</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_card_number"
                                            className="form-control form-control-sm"
                                            placeholder="Enter Sub Assembly Code"
                                            autoComplete="off"
                                            value={this.state.subAssemblySearchForm.search_card_number}
                                            onChange={(e) => this.formInputHandler(e, "subAssemblySearchForm")}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Status</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allStatus}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'subAssemblySearchForm', 'search_status_ids');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            value={this.state.allStatus.filter(s => this.state.subAssemblySearchForm?.search_status_ids?.includes(s.value))}
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Serial Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_serial_number"
                                            className="form-control form-control-sm"
                                            placeholder="Enter Serial Number"
                                            autoComplete="off"
                                            value={this.state.subAssemblySearchForm.search_serial_number}
                                            onChange={(e) => this.formInputHandler(e, "subAssemblySearchForm")}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Category</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.subAssemblyCategories}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'subAssemblySearchForm', 'search_category_id');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            value={this.state.subAssemblyCategories.find(c => c.value == this.state.subAssemblySearchForm.search_category_id)}
                                            placeholder="Select Category"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Linked Status</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.linkingStatus}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'subAssemblySearchForm', 'search_linking_status');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            value={this.state.linkingStatus.find(l => l.value == this.state.subAssemblySearchForm.search_linking_status)}
                                            placeholder="Select Linked Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.siteList}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'subAssemblySearchForm', 'search_site_ids');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            value={this.state.siteList.filter(s => this.state.subAssemblySearchForm?.search_site_ids?.includes(s.value))}
                                            placeholder="Select Sites"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Assets</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'subAssemblySearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    showStatusChangeModalInit = () => {
        this.changeStatusModal.show();
    }
    

    submitChangeStatusForm  =   (e)  => {
        e.preventDefault();
        if(this.state.subAssemblyDetail.enc_id) {
            this.setState({ saveFormSubmitting: true });
            const id        =   this.state.subAssemblyDetail.enc_id;
            const data      =   {...this.state.changeStatusForm}; 
            HttpAPICall.withAthorization('PUT',  this.subAssemblyUrl + '/status_change/' + id, this.props.access_token, null, data, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({
                    changeStatusForm : this.changeStatusFormInit
                });
                this.viewSubAssembly(id, 'minimize');
                this.loadListingTblData();
                this.changeStatusModal.hide();
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
    }

    statusChangeModalJsx    =   ()  =>  {
        return (
            <div className="modal fade" id="changeStatusModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changeStatusModalLabel">Change Status</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitChangeStatusForm} >
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Sub Assembly</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control form-control-sm"
                                            placeholder=" Sub Assembly"
                                            autoComplete="off"
                                            disabled={true}
                                            value={this.state.subAssemblyDetail ? this.state.subAssemblyDetail.name : ''}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Current Status</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="status"
                                            className="form-control form-control-sm"
                                            placeholder="Current Status"
                                            autoComplete="off"
                                            disabled={true}
                                            value={this.state.subAssemblyDetail && this.state.subAssemblyDetail.status && this.state.subAssemblyDetail.status.name}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Status Since Date</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="current_status_since_date"
                                            className="form-control form-control-sm"
                                            placeholder="Status Since Date"
                                            autoComplete="off"
                                            disabled={true}
                                            value={this.state.subAssemblyDetail && this.state.subAssemblyDetail.status_since_date && DateService.dateTimeFormat(this.state.subAssemblyDetail.status_since_date, 'DD-MMM-YYYY')}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require"> Status</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allStatus}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'changeStatusForm', 'status_id');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            required={true}
                                            value={this.state.allStatus.find(s => this.state.changeStatusForm.status_id === s.value)}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Status Date</label>
                                    <div className="col-sm-9">
                                        <DatePicker
                                        selected={
                                            this.state.changeStatusForm.status_since_date
                                                ? moment(this.state.changeStatusForm.status_since_date).toDate()
                                                : false
                                        }
                                        name="status_since_date"
                                        onChange={(value, event) => { this.formDateHandler('status_since_date', value, 'changeStatusForm', 'YYYY-MM-DD HH:mm:ss') }}
                                        dateFormat="dd-MMM-yyyy hh:mm a"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        showTimeSelect
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        maxDate={new Date()}
                                        // minDate={this.state. ? moment(this.state.status_since_date).toDate() : new Date()}
                                        placeholderText={`Please Enter Status Date`}
                                    />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Remark</label>
                                    <div className="col-sm-9">
                                        <textarea
                                            name="remark"
                                            onChange={(e) => this.formInputHandler(e, 'changeStatusForm')}
                                            className="form-control"
                                            placeholder="Please Add Remark"
                                            required
                                            style={{ height: "100px" }}
                                            value={this.state.changeStatusForm.remark}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    showSiteChangeModalInit = () => {
        this.changeSiteModal.show();
    }

    submitChangeSiteForm  =   (e)  => {
        e.preventDefault();
        if(this.state.subAssemblyDetail.enc_id) {
            this.setState({ saveFormSubmitting: true });
            const id        =   this.state.subAssemblyDetail.enc_id;
            const data      =   {...this.state.changeSiteForm}; 
            HttpAPICall.withAthorization('PUT',  this.subAssemblyUrl + '/site_change/' + id, this.props.access_token, null, data, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({
                    changeSiteForm : this.changeSiteFormInit
                });
                this.viewSubAssembly(id, 'minimize');
                this.loadListingTblData();
                this.changeSiteModal.hide();
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
    }

    siteChangeModalJsx    =   ()  =>  {
        return (
            <div className="modal fade" id="changeSiteModal" tabIndex="-1">
                <div className="modal-dialog modal-lg ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changeSiteModalLabel">Change Linked Site</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitChangeSiteForm} >
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Sub Assembly</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control form-control-sm"
                                            placeholder=" Sub Assembly"
                                            autoComplete="off"
                                            disabled={true}
                                            value={this.state.subAssemblyDetail ? this.state.subAssemblyDetail.name : ''}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Current Site</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="site"
                                            className="form-control form-control-sm"
                                            placeholder="Current Site"
                                            autoComplete="off"
                                            disabled={true}
                                            value={this.state.subAssemblyDetail && this.state.subAssemblyDetail.site && this.state.subAssemblyDetail.site.site_name}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Current Transfer Date</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="current_transfer_date"
                                            className="form-control form-control-sm"
                                            placeholder="Site Since Date"
                                            autoComplete="off"
                                            disabled={true}
                                            value={this.state.subAssemblyDetail && this.state.subAssemblyDetail.site_since_date && DateService.dateTimeFormat(this.state.subAssemblyDetail.site_since_date, 'DD-MMM-YYYY')}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require"> Site</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.siteList}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'changeSiteForm', 'site_id');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            placeholder="Select Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            required={true}
                                            value={this.state.siteList.find(s => this.state.changeSiteForm.site_id === s.value)}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require">Transfer Date</label>
                                        <div className="col-sm-9">
                                            <DatePicker
                                                selected={
                                                    this.state.changeSiteForm.transfer_date
                                                        ? moment(this.state.changeSiteForm.transfer_date).toDate()
                                                        : false
                                                }
                                                name="transfer_date"
                                                onChange={(value, event) => { this.formDateHandler('transfer_date', value, 'changeSiteForm', 'YYYY-MM-DD HH:mm:ss') }}
                                                dateFormat="dd-MMM-yyyy hh:mm a"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                timeIntervals={5}
                                                timeCaption="Time"
                                                autoComplete="off"
                                                // popperPlacement="right-start"
                                                scrollMonthYearDropdown
                                                required={true}
                                                maxDate={new Date()}
                                                // minDate={this.state. ? moment(this.state..status_since_date).toDate() : new Date()}
                                                placeholderText={`Please Enter Date of Transfer`}
                                            />
                                        </div>

                                    </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Remark</label>
                                    <div className="col-sm-9">
                                        <textarea
                                            name="remark"
                                            onChange={(e) => this.formInputHandler(e, 'changeSiteForm')}
                                            className="form-control"
                                            placeholder="Please Add Remark"
                                            style={{ height: "100px" }}
                                            value={this.state.changeSiteForm.remark}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    linkAssetModalInit = (type= '') => {
        let asset_id = this.state.subAssemblyDetail && this.state.subAssemblyDetail.asset_profile ? this.state.subAssemblyDetail.asset_profile.asset_id : ''
        this.linkAssetModalRef.current.linkAssetModalInit(this.state.subAssemblyDetail,type,asset_id)   
    }
   
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Sub Assembly Listing</title></Helmet>
            {this.subAssemblyListjsx()}
            {this.subAssemblySearchModalJsx()}
            {this.statusChangeModalJsx()}
            {this.siteChangeModalJsx()}
            <LinkingModal ref={this.linkAssetModalRef} afterSubmitForm={(id) => {this.viewSubAssembly(id, 'minimize');this.loadListingTblData(1)}}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        asset_groups            :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};

export default connect(mapStateToProps)(SubAssemblyList);