import React, { useState, useEffect } from 'react';
import { DisplayListPagination } from '../../components/TapUi';
import TapIcon from '../../services/TapIcon';
import "../reports/Report.module.css";
import { ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { useSelector } from 'react-redux';
import Loader from '../../components/ui/Loader/Loader';

const Reportdata = () => {
    const access_token = useSelector(state => state.auth.access_token);
    let initFormData = {
        loading: false,
    };
    const [formData, setSchedularReportData] = useState({ ...initFormData });
    const [reportData, setReportData] = useState([]);
    const [list, setList] = useState({
        current_page: 1,
        last_page: 1,
        total: 0,
        from: 1,
        to: 10,
        entries: []
    });
    let schedularUserSaveUrl = ORG_API_BASE_URL_2 + '/schedular/user';
    const schedularReportdata = (report_key) => {
        setSchedularReportData(pd => ({ ...pd, loading: true }));
        HttpAPICall.withAthorization('GET', schedularUserSaveUrl, access_token, { report: report_key }, {}, (response) => {
            let data = response.data;
            setSchedularReportData(pd => ({ ...pd, report: report_key }));
            setReportData(data.data);
            // Update pagination details
            setList(prevList => ({
                ...prevList,
                current_page: data.current_page,
                last_page: data.last_page,
                total: data.total,
                entries: data.data,
            }));
        }).then(() => {
            setSchedularReportData(pd => ({ ...pd, loading: false }));
        });
    }
    useEffect(() => {
        schedularReportdata();
    }, [access_token]);
    const loadListingTblData = (page) => {
        const report_key = "";
        schedularReportdata(report_key, page);
    };
    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    {formData.loading ? (
                        <Loader />
                    ) : (<>
                        <table className="table table-bordered bg-white table-hover table-fixed table-sm ">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Report Name</th>
                                    <th scope="col" style={{ width: "15%" }} className="text-center">Criteria</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Frequency </th>
                                    <th scope="col" style={{ width: "11%" }} className="text-center">Notification type </th>
                                    <th scope="col" style={{ width: "8%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.map((entry, i) => (
                                    <tr key={i}>
                                        <td className="text-center">{entry.report.name || ""}</td>
                                        <td className="text-center">{entry.criteria || ""}</td>
                                        <td className="text-center">{entry.schedule_data.frequency || ""}</td>
                                        <td className="text-center">{entry.schedule_data.types?.join(', ') || ""}</td>
                                        <td className="text-center">
                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn text-secondary">
                                                <TapIcon.imageIcon
                                                    icon={TapIcon.ListActionIcon}
                                                    className="img-fluid"
                                                    alt="item-action"
                                                />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button">Edit</a></li>
                                                <li><a className="dropdown-item" role="button">Delete</a></li>
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>)}
                    <DisplayListPagination
                        meta={list}
                        onPageChange={(e) => loadListingTblData(e.selected + 1)}
                    />
                </div>
            </div>
        </>
    );
}

export default Reportdata;
