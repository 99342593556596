import React from 'react';
import Loader from '../../../components/ui/Loader/Loader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Status from '../../../components/ui/Status';


const EmployeeDetailHorizontalCard = ({ employeeDetail, employee_loading, employeeData }) => {
    let details = employeeData;
    return (
        <div className="container-fluid p-0">
            {employee_loading
                ? <div className='text-center'> <Loader /></div>
                : <table className="table table-borderless va_middle mb-1 bg-white">
                    {details
                        ?
                        <tbody>
                            <tr>
                            {details?.image &&  <td style={{ width: "20%" }} rowSpan="3" className="p0 text-center">
                                    <img style={{ maxHeight: "14em" }} src={details?.image} className="img-fluid card-image" alt={"image-card"} />
                                </td>}
                               
                                <td style={{ width: "30%" }}>
                                    Employee Name : 
                                    <Link to={{ pathname: '/employee_list', state: { id: details.enc_id } }} className="link-primary cursor_pointer"> <b>{details.name ?? "-"}</b></Link>
                                </td>
                                <td style={{ width: "20%" }}>Email ID : <b>{details.email ? details.email : "-"}</b></td>
                                <td style={{ width: "20%" }}>Employee Code : <b>{details.employee_code ||  "-"}</b></td>
                            </tr>
                            <tr>
                        
                                <td style={{ width: "20%" }}>Mobile : <b>{details.mobile ? details.mobile : "-"}</b></td>
                                <td style={{ width: "20%" }}>Status :  <Status color={details?.status_color_code} className="mt10"><b>{details?.status_text}</b></Status> </td>
                            </tr>
                        </tbody>
                        : "-"
                    }
                </table>
            }
        </div>
    );
};


export default EmployeeDetailHorizontalCard;
