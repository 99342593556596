import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Ax from "../../components/hoc/Ax";
import HttpAPICall from "../../services/HttpAPICall";
import TapApiUrls, { IAM_API_BASE_URL_2 } from "../../services/TapApiUrls";
import ApplicationLayout from "../../layouts/ApplicationLayout";
import AppBaseComponent from "../../components/AppBaseComponent";
import { Helmet } from "react-helmet";
import TapIcon from "../../services/TapIcon";
import Loader from "../../components/ui/Loader/Loader";
import { Link } from "react-router-dom";
import Status from "../../components/ui/Status";
import {
  DisplayListPagination,
  DisplaySearchCriteria,
} from "../../components/TapUi";
import { Collapse, Modal } from "bootstrap";
import TapSelect from "../../components/ui/TapSelect";
import ProjectStructureDetail from "./ProjectStructureDetail";
import TicketTransactionList from "../transactions/ticketTransaction/TicketTransactionList";
import DateService from "../../services/DateService";
import DatePicker from "react-datepicker";
import moment from "moment";
import StartProjectModal from "./StartProjectModal";
import swal from "sweetalert";

// simport StartProjectModal from './StartProjectModal';

class Project extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.initialProjectStructure = {
      name: "",
      notes: "",
    };

    this.initialSearch = {
      search_by_name: "",
      search_status_id: "",
    };
    this.searchFormLabel = {
      search_by_name: "Name : ",
      search_status_id: "Status : ",
    };

    this.initialProjectStartForm = {
      site_id: "",
      notes: "",
      starting_date: "",
      structure_id: "",
      name: "",
    };

    this.state = {
      modalLoading: false,
      minimizeTable: false,
      listing_loading: false,
      formSubmitting: false,
      totalListingCount: 0,
      listingMeta: null,
      listingData: [],
      configurationData: null,
      addProjectStructureForm: { ...this.initialProjectStructure },
      projectStructureId: "",
      status: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      searchFormData: { ...this.initialSearch },
      submittedSearchFormData: { ...this.initialSearch },
      formSearchedElems: [],
      startProjectForm: { ...this.initialProjectStartForm },
      siteList: [],
      structures: [],
      recalculate_loading: false
    };

    this.projectListUrl             =   IAM_API_BASE_URL_2 + "/project/list";
    this.projectDeleteUrl           =   IAM_API_BASE_URL_2 + "/project/delete/__pid";
    this.recalculateProjectStatus   =   IAM_API_BASE_URL_2 + "/project/recalculate_project_status";

    this.detailDataLoad = React.createRef();
    this.startProjectModalRef = React.createRef();
  }

  componentDidMount() {
    this.loadListingTblData();
    this.startProject = new Modal(document.getElementById("startProject"), {
      keyboard: false,
      backdrop: false,
    });
  }
  loadListingTblData = (page = 1) => {
    this.setState({ listing_loading: true });
    let params = { page: page };
    HttpAPICall.withAthorization(
      "GET",
      this.projectListUrl,
      this.props.access_token,
      params,
      null,
      (response) => {
        const projects = response.data;
        this.setState({
          listingData: projects.data,
          listingMeta: projects.meta,
          totalListingCount: projects.meta.total,
        });
      }
    ).then(() => this.setState({ listing_loading: false }));
  };

  startProjectInit = () => {
    this.startProjectModalRef.current.showModal();
  };

  deleteProjectHandler            =   (project_id)  =>  {
    if(this.props.permissions.includes('iam-project-delete')) {
        swal({
            title: "Delete", dangerMode: true, buttons: ["No", "Yes"], icon: "warning",
            text: "Are you sure want to delete this project, This action cannot be reversed, Are you sure you want to proceed.",
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.projectDeleteUrl.replace('__pid', project_id),
                    this.props.access_token, null, null, (response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.loadListingTblData();
                }).then(() => {

                } );
            }
        });
    } else {
        toast.error('You are not authorized to delete project', {position: toast.POSITION.TOP_RIGHT})
    }
}
    recalculateProject    =   ()  =>  {
        this.setState({listing_loading: true, recalculate_loading : true});
        HttpAPICall.withAthorization('GET', this.recalculateProjectStatus, this.props.access_token, null, null, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.loadListingTblData();
        }).then(() => {
            this.setState({recalculate_loading: false});
        });

    }

  listDatajsx = () => {
    return (
      <Ax>
        <div className="page_title row m0">
          <div className={"col-sm-6"}>
            <h3>Project</h3>
          </div>
          <div className="col-sm-6 text-end mt15">
            <button type="button" className="btn btn-secondary" disabled={this.state.listing_loading || !this.props.permissions.includes('iam-project-add')} onClick={this.startProjectInit}>Start Project</button>
            <button type="button" className="btn btn-secondary" onClick={this.recalculateProject} disabled={this.state.listing_loading}>
            {!this.state.recalculate_loading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-refresh" />}  {this.state.recalculate_loading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}
            </button>
          </div>
        </div>
        <div className="container-fluid pl5">
          <div className="page_containt row">
            <div className={["pageTbl", "col-12"].join(" ")}>
              {this.tableDataJsx()}
            </div>
          </div>
        </div>
      </Ax>
    );
  };

  tableDataJsx = () => {
    const projectListing = this.state.listingData;
    return (
      <Ax>
        {/* <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.searchFormClear} /> */}
        <table className="table table-hover table-bordered table-responsive bg-white">
          <thead className="table-secondary">
            <tr>

              <th scope="col" style={{ width: "5%" }} className="text-center">
                S.No
              </th>
              <th scope="col" style={{ width: "9%" }}>
                Project ID
              </th>
              <th scope="col" style={{ width: "16%" }}>
                Name
              </th>
              <th scope="col" style={{ width: "14%" }}>
                Site
              </th>
              <th scope="col" style={{ width: "23%" }}>
                Notes
              </th>
              <th scope="col" style={{ width: "5%" }}>
                No. of Ticket
              </th>
              <th scope="col" style={{ width: "8%" }}>
                Project Start Date
              </th>
              <th scope="col" style={{ width: "8%" }}>
                Completion Date
              </th>
              <th scope="col" style={{ width: "7%" }}>
                Status
              </th>
              <th scope="col" style={{ width: "5%" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.listing_loading ? (
              <tr>
                <td className="text-center  " colSpan={10}>
                  <Loader />
                </td>
              </tr>
            ) : projectListing.length > 0 ? (
              projectListing.map((data, i) => {
                return (
                  <tr key={i}>

                    <td className="text-center">{i + 1}</td>
                    <td>{data.transaction_id}</td>
                    <td>
                      <Link to={{ pathname: "/project/" + data.id }}>
                        {data.name}
                      </Link>
                    </td>
                    <td>{data.site ? data.site.site_name : "-"}</td>
                    <td>{data.notes ? data.notes : "-"}</td>
                    <td className="text-end">{data.count_tickets}</td>
                    <td>
                      {data.starting_date
                        ? DateService.dateTimeFormat(
                          data.starting_date,
                          "DD-MMM-YYYY"
                        )
                        : "-"}
                    </td>
                    <td>
                      {data.closure_date
                        ? DateService.dateTimeFormat(
                          data.closure_date,
                          "DD-MMM-YYYY"
                        )
                        : "-"}
                    </td>
                    <td>
                      <Status color={data.status?.color_code}>
                        {data.status?.status}
                      </Status>
                    </td>
                    <td className="text-center">
                      <TapIcon.imageIcon
                        icon={TapIcon.ListActionIcon}
                        alt="action-icon"
                        className="img-fluid px-3"
                        data-bs-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-expanded="false"
                      />
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                        <Link
                            to={{ pathname: "/project/" + data.id }}
                            className="dropdown-item"
                            role="button"
                          >
                            View
                          </Link>
                          </li>
                          <li>
                                <Link role="button" href
                                    onClick={() => {
                                        if (this.props.permissions.includes('iam-project-delete')) {
                                            return this.deleteProjectHandler(data.id);
                                        }
                                    }}
                                    className={['dropdown-item', this.props.permissions.includes('iam-project-delete') ? '' : 'disabled'].join(' ')}
                                    title={!this.props.permissions.includes('iam-project-delete') ? 'You dont have permission for this action' : ''}
                                    style={{ "pointerEvents": "all" }}
                                >Delete</Link>
                            </li>
                          
                        
                      </ul>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center  " colSpan={10}>
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <DisplayListPagination
          meta={this.state.listingMeta}
          onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
        />
      </Ax>
    );
  };

  render = () => {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Project</title>
        </Helmet>
        {this.listDatajsx()}
        <StartProjectModal
          ref={this.startProjectModalRef}
          loadListingTblData={this.loadListingTblData}
        />
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions:
      state.app.acl_info &&
        state.app.acl_info.permissions &&
        state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
    group_sites:
      state.app.group_sites && state.app.group_sites.length > 0
        ? state.app.group_sites
        : [],
  };
};

export default connect(mapStateToProps)(Project);
