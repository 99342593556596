import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import TapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from "../../../services/HttpAPICall";
import { Modal } from "bootstrap";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { toast } from "react-toastify";
import swal from "sweetalert";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import {
  IAM_API_BASE_URL_2,
  ORG_API_BASE_URL_2,
  INVENTORY_API_BASE_URL_2,
} from "../../../services/TapApiUrls";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ExpenseAddModal from "../../accounts/ExpenseAddModal";
import { DisplayListPagination } from "../../../components/TapUi";
import AllocationDetail from "../../inventory/allocation/AllocationDetail";
import ConsumptionDetailsModal from "../../inventory/consumption/ConsumptionDetailsModal";
import CurrencyFormatter from "../../../services/CurrencyFormatter";
import Status from "../../../components/ui/Status";
import AssetCard from "../../includes/assetCard/AssetCard";
import ChangeAssetStatus from "../../assets/includes/ChangeAssetStatus";
import Expense from "../../assets/counterlog/ShiftActivityModals/expense/Expense";
import ExpenseView from "../../assets/counterlog/ShiftActivityModals/expense/ExpenseView";
import InputWorkstationSearch from "../../includes/ui/InputWorkstationSearch";
import InputEmployeeSearch from "../../includes/ui/InputEmployeeSearch";
import InputSubAssemblySearch from "../../includes/ui/InputSubAssemblySearch";
import AdditionalAttributeForm from "../../includes/ui/AdditionalAttributeForm";
import InputTicketSearch from "../../includes/ui/InputTicketSearch";
import DateService from "../../../services/DateService";
import ItemDemandDetail from "../../inventory/itemDemand/ItemDemandDetail";

class TicketDetail extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.initEditTicketForm = {
      ticket_type_id: "",
      header: "",
      tagged_users: [],
      due_date: null,
      asset_id: "",
      tags: null,
      workstation: "",
      employees: null,
      sub_assembly: "",
      parent_ticket_activity_id: "",
      estimate_days: "",
      failure_start_time: "",
      failure_closing_time: "",
      description: ""
    };

    this.initAddDocument = {
      file: "",
      doc_number: "",
      doc_name: "",
      file_name: null,
      base64: null,
    };

    this.initAddRemarkForm = {
      status: null,
      asset_status: "",
      defect_codes: [],
      remarks: "",
      send_email_notification: "N",
    };

    this.state = {
      ticketData: null,
      ticketEditForm: { ...this.initEditTicketForm },
      formDataLoading: false,
      allTicketTypes: [],
      allUsers: [],
      additionalAttributes: [],
      saveFormSubmitting: false,
      addDocumentForm: { ...this.initAddDocument },
      remark_form_loading: false,
      remarkFormData: [],
      allRemarkStatus: [],
      allAssetStatus: [],
      allDefectCode: [],
      general_config_list: [],
      generalConfigloading: [],
      searchedAsset: null,
      addRemarkForm: { ...this.initAddRemarkForm },
      other: "N",
      documentTypes: [],
      formDataLoading: false,
      ticketExpenseLoading: false,
      ticketExpenseData: [],
      expenseListingMeta: null,
      searchedTagEmployee: [],
      listingMeta: null,
      allocation_loading: false,
      allocation_listing: [],
      allocationlistingMeta: null,
      allocationtotalListingCount: 0,
      selectedAllocationId: "",
      consumption_loading: false,
      consumption_listing: [],
      consumptionlistingMeta: null,
      consumptiontotalListingCount: 0,
      selectedConsumptionId: "",
      assetId: "",
      card_loading: false,
      asset_card_details: null,
      addRemark: props.addRemark && props.addRemark == "N" ? "N" : "Y",
      performAction:
        props.performAction && props.performAction == "N" ? "N" : "Y",
      showOtherDetail:
        props.showOtherDetail && props.showOtherDetail == "N" ? "N" : "Y",
      ticketExpenseId: "",
      showAssetCard:
        props.showAssetCard && props.showAssetCard == "N" ? "N" : "Y",
      ticketTags: [],
      searchedEmployee: [],
      searchedWorkstation: {},
      searchedAssembly: "",
      ticket_allow_tag: "N",
      ticket_allow_employees: "N",
      addFormState: "",
      ticketForOption: [],
      iam_user_sites: [],
      onlyDefectCode: false,
      searchedTicket: "",
      ticketTypeFormData: [],
    };

    this.expenseAddRef = React.createRef();
    this.logExpenseRef = React.createRef();
    this.expenseDetailRef = React.createRef();
    this.expenseUrl = IAM_API_BASE_URL_2 + "/expense";
    this.ChangeAssetStatusModalRef = React.createRef();
    this.documentsNVoucherBtnRef = React.createRef();
    this.consumptionListUrl = INVENTORY_API_BASE_URL_2 + "/consumptions";
    this.allocationListUrl = INVENTORY_API_BASE_URL_2 + "/allocations";
    this.demandListUrl = INVENTORY_API_BASE_URL_2 + "/item_demand/list";

    this.documentTypeUrl = ORG_API_BASE_URL_2 + "/setting/doc_types";
    this.ticketEditUrl = IAM_API_BASE_URL_2 + "/ticket/edit";
    this.editFormDataUrl = IAM_API_BASE_URL_2 + "/ticket/edit_form_data";
    this.remarkStatusFormDataUrl =
      IAM_API_BASE_URL_2 + "/ticket/remark_status_form_data";
    this.remarkStatusUrl = IAM_API_BASE_URL_2 + "/ticket/remark_status";
    this.ticketDetailUrl = IAM_API_BASE_URL_2 + "/ticket/detail";
  }

  componentDidMount() {
    if (
      (this.props && this.props.ticketId) ||
      (this.props && this.props.ticketData)
    ) {
      this.initalizeDetailData(this.props);
    }

    this.addRemarksModal = new Modal(
      document.getElementById("addRemarksModal"),
      { keyboard: false, backdrop: false }
    );
    this.updateTicketModal = new Modal(
      document.getElementById("updateTicketModal"),
      { keyboard: false, backdrop: false }
    );
    this.addDocumentModal = new Modal(
      document.getElementById("addDocumentModal"),
      { keyboard: false, backdrop: false }
    );
    this.viewExpenseModal = new Modal(
      document.getElementById("viewExpenseModal"),
      { keyboard: false, backdrop: false }
    );
    this.viewAllocationDetailModal = new Modal(
      document.getElementById("viewAllocationDetailModal"),
      { keyboard: false, backdrop: false }
    );
    this.viewConsumptionDetailModal = new Modal(
      document.getElementById("viewConsumptionDetailModal"),
      { keyboard: false, backdrop: false }
    );
    this.viewDemandDetailModal = new Modal(
      document.getElementById("viewDemandDetailModal"),
      { keyboard: false, backdrop: false }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.ticketId !== this.props.ticketId ||
      nextProps.ticketData !== this.props.ticketData
    ) {
      this.initalizeDetailData(nextProps);
    }
  }

  initalizeDetailData = (pr) => {
    this.setState({
      iam_user_sites: pr.iam_user_sites
        ? pr.iam_user_sites.map((s) => {
          return {
            value: s.id,
            label: `${s.site_name} (${s.site_code})`,
            code: s.site_code,
          };
        })
        : [],
    });
    let ticket_id =
      pr && pr.ticketId
        ? pr.ticetId
        : pr.ticketData
          ? pr.ticketData.activity_id
          : "";
    let ticketData = pr.ticketData ? pr.ticketData : null;
    if (ticket_id && !ticketData) {
      this.getTicketDetail(ticket_id);
    } else if (ticketData) {
      this.setState({ ticketData: ticketData }, () => {
        if (ticketData.asset) {
          this.setState({ assetId: ticketData.asset.asset_id });
          this.getAssetCard(ticketData.asset.asset_id);
        }
        if (
          ticketData &&
          ticketData.setting &&
          ticketData.setting.ticket_allocation == "Y" &&
          this.props.group_permission &&
          this.props.group_permission.group_modules.includes("isc") &&
          this.state.allocation_listing.length === 0
        ) {
          ticketData.total_allocation && this.getAllocationList(1, ticket_id);
        }
        if (
          ticketData &&
          ticketData.setting &&
          ticketData.setting.ticket_consumption == "Y" &&
          this.props.group_permission &&
          this.props.group_permission.group_modules.includes("isc") &&
          this.state.consumption_listing.length === 0
        ) {
          ticketData.total_consumption && this.getConsumptionList(1, ticket_id);
        }
        if (
          ticketData &&
          ticketData.setting &&
          ticketData.setting.ticket_expense == "Y" &&
          this.state.ticketExpenseData.length === 0 &&
          this.state.consumption_listing.length === 0
        ) {
          ticketData.total_expense && this.getTicketExpenseData(1, ticket_id);
        }
        if (ticketData) {
          this.getDemandList(1, ticket_id);
        }
      });
    }

    //    this.getGeneralSettingListing();
  };

  getTicketDetail = (id) => {
    this.setState({ view_loading: true });
    let params = {
      need_total_expense: "Y",
      need_ticket_allocation: "Y",
      need_ticket_consumption: "Y",
      need_parent_ticket: "Y",
      need_child_tickets: "Y",
    };
    HttpAPICall.withAthorization(
      "GET",
      this.ticketDetailUrl + "/" + id,
      this.props.access_token,
      params,
      {},
      (resp) => {
        let respData = resp.data;
        this.setState(
          {
            ticketData: respData.data,
            ticketHeader: respData.data.header,
            assetId:
              respData.data && respData.data.asset
                ? respData.data.asset.asset_id
                : null,
          },
          () => {
            let ticketData = this.state.ticketData;
            if (
              ticketData &&
              ticketData.setting &&
              ticketData.setting.ticket_allocation == "Y" &&
              this.props.group_permission &&
              this.props.group_permission.group_modules.includes("isc") &&
              this.state.allocation_listing.length === 0
            ) {
              this.getAllocationList(1, ticketData.activity_id);
            }
            if (
              ticketData &&
              ticketData.setting &&
              ticketData.setting.ticket_consumption == "Y" &&
              this.props.group_permission &&
              this.props.group_permission.group_modules.includes("isc") &&
              this.state.consumption_listing.length === 0
            ) {
              this.getConsumptionList(1, ticketData.activity_id);
            }
            if (
              ticketData &&
              ticketData.setting &&
              ticketData.setting.ticket_expense == "Y" &&
              this.state.ticketExpenseData.length === 0
            ) {
              this.getTicketExpenseData(1, ticketData.activity_id);
            }
          }
        );
        if (respData.data.asset) {
          this.getAssetCard(respData.data.asset.asset_id);
        }
      }
    ).then(() => this.setState({ view_loading: false }));
  };

  getAssetCard = (id) => {
    let Id = id ? id : this.state.assetId;
    this.setState({ card_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      IAM_API_BASE_URL_2 + "/asset/" + Id,
      this.props.access_token,
      {},
      {},
      (resp) => {
        this.setState({ asset_card_details: resp.data.data });
      }
    ).then(() => this.setState({ card_loading: false }));
  };

  getTicketExpenseData = (page = 1, search_ticket_id) => {
    this.setState({ ticketExpenseLoading: true });
    const params = {
      page: page,
      search_ticket_id: this.state.ticketData
        ? this.state.ticketData.activity_id
        : null,
    };

    HttpAPICall.withAthorization(
      "GET",
      `${this.expenseUrl}/list`,
      this.props.access_token,
      params,
      null,
      (response) => {
        const resp = response.data;
        this.setState(
          {
            ticketExpenseData: resp.data,
            expenseListingMeta: {
              from: resp.meta.from,
              to: resp.meta.to,
              total: resp.meta.total,
              last_page: resp.meta.last_page,
              per_page: resp.meta.per_page,
              current_page: resp.meta.current_page,
            },
          },
          () => {
            this.setState({ ticketExpenseLoading: false });
          }
        );
      }
    );
  };

  getAllocationList = (page = 1, search_ticket_id = "") => {
    this.setState({ allocation_loading: true });
    let params = {
      page: page,
      search_ticket_id: this.state.ticketData
        ? this.state.ticketData.activity_id
        : null,
    };
    HttpAPICall.withAthorization(
      "GET",
      this.allocationListUrl,
      this.props.access_token,
      params,
      {},
      (resp) => {
        let respData = resp.data;
        this.setState({
          allocation_listing: respData.data,
          allocationlistingMeta: respData.meta,
          allocationtotalListingCount: respData.meta.total,
        });
      }
    ).then(() => this.setState({ allocation_loading: false }));
  };

  getDemandList = (page = 1, search_ticket_id = "") => {
    this.setState({ demand_loading: true });
    let params = {
      page: page,
      search_ticket_id: this.state.ticketData
        ? this.state.ticketData.activity_id
        : [],
    };
    HttpAPICall.withAthorization(
      "GET",
      this.demandListUrl,
      this.props.access_token,
      params,
      {},
      (resp) => {
        let respData = resp.data;
        this.setState({
          demand_listing: respData.data,
          demandlistingMeta: {
            from: respData.from,
            to: respData.to,
            total: respData.total,
            last_page: respData.last_page,
            per_page: respData.per_page,
            current_page: respData.current_page,
          },
          demandtotalListingCount: respData.total,
        });
      }
    ).then(() => this.setState({ demand_loading: false }));
  };

  getConsumptionList = (page = 1, search_ticket_id = "") => {
    this.setState({ consumption_loading: true });
    let params = {
      page: page,
      search_ticket_id: this.state.ticketData
        ? this.state.ticketData.activity_id
        : null,
    };
    HttpAPICall.withAthorization(
      "GET",
      this.consumptionListUrl,
      this.props.access_token,
      params,
      {},
      (resp) => {
        let respData = resp.data;
        this.setState({
          consumption_listing: respData.data,
          consumptionlistingMeta: respData.meta,
          consumptiontotalListingCount: respData.meta.total,
        });
      }
    ).then(() => this.setState({ consumption_loading: false }));
  };

  // getGeneralSettingListing              =    ()           =>     {
  //     this.setState({generalConfigloading : true});
  //     HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/ticket/configuration/general', this.props.access_token , {}, {}, (resp) => {
  //         this.setState({general_config_list  : resp.data});
  //     }).then(() => this.setState({generalConfigloading: false}));
  // }

  getTicketEditFormData = (id) => {
    this.setState({ formDataLoading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.editFormDataUrl + "/" + id,
      this.props.access_token,
      {},
      {},
      (resp) => {
        this.setState(
          {
            ticketFormData: resp.data,
            ticketTypeFormData: resp.data.ticket_types
              ? resp.data.ticket_types
              : [],
            allTicketTypes: resp.data.ticket_types?.map((s) => {
              return { value: s.id, label: s.name };
            }),
            allUsers: resp.data.users?.map((s) => {
              return { value: s.id, label: s.full_name };
            }),
            ticketTags:
              resp.data && resp.data.tags && resp.data.tags.length > 0
                ? resp.data.tags.map((tag) => {
                  return { value: tag, label: tag };
                })
                : [],
            ticket_allow_tag:
              resp.data &&
              resp.data.setting &&
              resp.data.setting.ticket_allow_tag,
            ticket_allow_employees:
              resp.data &&
              resp.data.setting &&
              resp.data.setting.ticket_allow_employees,
            additionalAttributes:
              resp.data &&
                resp.data.additional_attributes &&
                resp.data.additional_attributes.length > 0
                ? resp.data.additional_attributes
                : [],
            ticketForOption:
              resp.data &&
                resp.data.ticket_for &&
                resp.data.ticket_for.length > 0
                ? resp.data.ticket_for.map((p) => {
                  return { value: p.key, label: p.name };
                })
                : [],
            allDefectCode:
              resp.data &&
                resp.data.defect_codes &&
                resp.data.defect_codes.length > 0
                ? resp.data.defect_codes.map((p) => {
                  return { value: p.id, label: p.name };
                })
                : [],
          },
          () => {
            let ticketData = this.state.ticketData;
            
            let updateFormState = [];
            if (ticketData.additional_attributes_data) {
              ticketData.additional_attributes_data.map((i, k) => {
                let name = "additional_attributes_" + i.key;
                updateFormState[name] = i.value;
              });
            }
            this.setState({
              ticketEditForm: {
                ...updateFormState,
                description: ticketData.description,
                ticket_type_id: ticketData.ticket_type
                  ? ticketData.ticket_type.id
                  : "",
                ticket_for: ticketData.ticket_for,
                sub_assembly_id: ticketData.subAssembly
                  ? ticketData.subAssembly.enc_id
                  : "",
                employee_id: ticketData.employee
                  ? ticketData.employee.enc_id
                  : "",
                workstation_id: ticketData.workStation
                  ? ticketData.workStation.id
                  : "",
                site_id: ticketData.site ? ticketData.site.id : "",
                header: ticketData.header,
                tagged_users: ticketData.tagged_users_display.map((u) => u.id),
                activity_id: ticketData.activity_id,
                employees:
                  ticketData.employees && ticketData.employees.length > 0
                    ? ticketData.employees.map((emp) => emp.enc_id)
                    : [],
                tags:
                  ticketData.tags && ticketData.tags.length > 0
                    ? ticketData.tags.map((tag) => tag)
                    : [],
                defect_codes:
                  ticketData.defect_codes && ticketData.defect_codes.length > 0
                    ? ticketData.defect_codes.map((tag) => tag.id)
                    : [],
                asset_id:
                  ticketData.asset && ticketData.asset.asset_id
                    ? ticketData.asset.asset_id
                    : "",
                // due_date      :  ticketData?.due_date !== null ? ticketData.due_date : new Date(),
                estimate_days: ticketData?.estimate_days
                  ? ticketData.estimate_days
                  : "",
                parent_ticket_activity_id: ticketData.parent_ticket
                  ? ticketData.parent_ticket.activity_id
                  : "",
                failure_start_time: ticketData.failure_start_time,
                failure_closing_time: ticketData.failure_closing_time,
              },
              editTicket: true,
              searchedAsset: ticketData.asset
                ? {
                  ...ticketData.asset,
                  value: ticketData.asset.asset_id,
                  label: ticketData.asset.name,
                  display_label: ticketData.asset.name,
                  asset: ticketData.asset,
                }
                : null,
              searchedAssembly: ticketData.subAssembly
                ? {
                  ...ticketData.subAssembly,
                  subAssembly: ticketData.subAssembly,
                  value: ticketData.subAssembly.enc_id,
                  label: ticketData.subAssembly.name,
                  display_label: ticketData.subAssembly.name,
                }
                : null,
              searchedEmployee: ticketData.employee
                ? {
                  ...ticketData.employee,
                  value: ticketData.employee.enc_id,
                  label: ticketData.employee.display_full_name,
                }
                : null,
              searchedTagEmployee:
                ticketData.employees && ticketData.employees.length > 0
                  ? ticketData.employees.map((emp) => {
                    return {
                      ...emp,
                      value: emp.enc_id,
                      label: emp.display_full_name,
                    };
                  })
                  : null,
              searchedWorkstation: ticketData.workStation
                ? {
                  ...ticketData.workStation,
                  value: ticketData.workStation.id,
                  label: ticketData.workStation.name,
                }
                : null,
            });
          }
        );
      }
    ).then(() => this.setState({ formDataLoading: false }));
  };

  submitEditTicket = (e) => {
    e.preventDefault();
    this.setState({ saveFormSubmitting: true });
    let additional_attributes = {};
    this.state.additionalAttributes.forEach((i, k) => {
      let name = "additional_attributes_" + i.key;
      additional_attributes[i.key] = this.state.ticketEditForm[name]
        ? this.state.ticketEditForm[name]
        : "";
    });
    let formdata = {
      ...this.state.ticketEditForm,
      // due_date : this.state.ticketEditForm.due_date ? moment(this.state.ticketEditForm.due_date,'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      // additional_attributes,
      // due_date: this.state.ticketEditForm.due_date ? moment(this.state.ticketEditForm.due_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
    };
    if (this.state.addFormState == "detail") {
      formdata = {
        activity_id: this.state.ticketEditForm.activity_id,
        header: this.state.ticketEditForm.header,
        ticket_type_id: this.state.ticketEditForm.ticket_type_id,
        estimate_days: this.state.ticketEditForm.estimate_days,
        // due_date : this.state.ticketEditForm.due_date ? moment(this.state.ticketEditForm.due_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        parent_ticket_activity_id:
          this.state.ticketEditForm.parent_ticket_activity_id,
      };
    } else if (this.state.addFormState == "other_detail") {
      formdata = {
        additional_attributes: additional_attributes,
        activity_id: this.state.ticketEditForm.activity_id,
        defect_codes: this.state.ticketEditForm.defect_codes,
        employees: this.state.ticketEditForm.employees,
        tags: this.state.ticketEditForm.tags,
        description: this.state.ticketEditForm.description
      };
    } else if (this.state.addFormState == "tagging_detail") {
      formdata = {
        activity_id: this.state.ticketEditForm.activity_id,
        tagged_users: this.state.ticketEditForm.tagged_users,
        ticket_for: this.state.ticketEditForm.ticket_for,
        site_id: this.state.ticketEditForm.site_id,
        asset_id: this.state.ticketEditForm.asset_id,
        sub_assembly_id: this.state.ticketEditForm.sub_assembly_id,
        employee_id: this.state.ticketEditForm.employee_id,
        workstation_id: this.state.ticketEditForm.workstation_id,
      };
    } else if (this.state.addFormState == "mttr") {
      formdata = {
        activity_id: this.state.ticketEditForm.activity_id,
        failure_start_time: this.state.ticketEditForm.failure_start_time,
        failure_closing_time: this.state.ticketEditForm.failure_closing_time,
      };
    }
    HttpAPICall.withAthorization(
      "PUT",
      this.ticketEditUrl,
      this.props.access_token,
      {},
      { ...formdata },
      (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.updateTicketModal.hide();
        this.setState({ ticketEditForm: { ...this.initEditTicketForm } });
        this.getTicketDetail(this.state.ticketData.activity_id);

        this.props?.projectDetails && this.props.listingUpdateWithProject();
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  //****************************** UPDATE  DOCUMENT  DETAILS ******************
  addDocumentModalInit = () => {
    let ticketData = this.state.ticketData;
    if (
      ticketData.documents &&
      ticketData.setting &&
      ticketData.documents.length >= ticketData.setting.max_doc
    ) {
      toast.error(
        "Maximum number of documents allowed to upload in a Ticket exceeded",
        { position: toast.POSITION.TOP_RIGHT }
      );
    } else {
      this.addDocumentModal.show();
      this.setState({
        addDocumentForm: { ...this.initAddDocument },
        other: "N",
      });
      this.loadDocTypesFromApi();
    }
  };

  loadDocTypesFromApi = () => {
    this.setState({ formDataLoading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.documentTypeUrl,
      this.props.access_token,
      {},
      {},
      (response) => {
        this.setState({
          documentTypes: response.data.map((s) => {
            return { value: s, label: s };
          }),
        });
      },
      (error) => {
        toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
      }
    ).then(() => this.setState({ formDataLoading: false }));
  };

  handleFileChange = async (e) => {
    let file = e.target.files[0];
    let filename = file.name;
    const base64 = await this.convertBase64(file);
    this.setState({
      addDocumentForm: {
        ...this.state.addDocumentForm,
        base64: base64,
        file_name: filename,
      },
    });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  addTicketDocument = (e) => {
    e.preventDefault();
    this.setState({ saveFormSubmitting: true });
    let frmData = {
      activity_id: this.state.ticketData.activity_id,
      doc_name: this.state.addDocumentForm.doc_name,
      doc_number: this.state.addDocumentForm.doc_number,
      file_name: this.state.addDocumentForm.file_name,
      base64: this.state.addDocumentForm.base64,
    };
    HttpAPICall.withAthorization(
      "PUT",
      process.env.REACT_APP_API_SERVER + "/quick_ticket/document",
      this.props.access_token,
      {},
      { ...frmData },
      (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.addDocumentModal.hide();
        this.getTicketDetail(this.state.ticketData.activity_id);
        this.setState({ addDocumentForm: { ...this.initAddDocument } });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  deleteDocument = (id) => {
    swal({
      title: "Delete",
      text: "This action cannot be reversed, Are you sure you want to proceed?",
      icon: "warning",
      // dangerMode: true,
      buttons: ["No", "Yes"],
    }).then((willDelete) => {
      if (willDelete) {
        HttpAPICall.withAthorization(
          "DELETE",
          process.env.REACT_APP_API_SERVER + "/quick_ticket/document",
          this.props.access_token,
          {},
          { doc_id: id, activity_id: this.state.ticketData.activity_id },
          (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.getTicketDetail(this.state.ticketData.activity_id);
          }
        ).then(() => { });
      }
    });
  };

  //****************************** UPDATE REMARK ****** ******************
  getAddRemarkFormData = (id) => {
    this.setState({ remark_form_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.remarkStatusFormDataUrl + "/" + id,
      this.props.access_token,
      {},
      {},
      (resp) => {
        this.setState({
          remarkFormData: resp.data,
          addRemarkForm: {
            ...this.state.addRemarkForm,
            status: this.state.ticketData.status,
            defect_codes:
              this.state.ticketData.defect_codes.length > 0
                ? this.state.ticketData.defect_codes.map((s) => s.id)
                : [],
          },
          allRemarkStatus: resp.data.status_list?.map((s) => {
            return { value: s.key, label: s.name };
          }),
          allAssetStatus: resp.data.asset_status_list?.map((s) => {
            return { value: s.key, label: s.name };
          }),
        });
      }
    ).then(() => this.setState({ remark_form_loading: false }));
  };

  addRemarkInit = () => {
    this.addRemarksModal.show();
    this.getAddRemarkFormData(this.state.ticketData.activity_id);
  };

  submitAddRemarkForm = (e) => {
    e.preventDefault();
    let frmData = {
      ...this.state.addRemarkForm,
      activity_id: this.state.ticketData.activity_id,
      send_email_notification: this.state.addRemarkForm.send_email_notification,
    };

    this.setState({ saveFormSubmitting: true });
    HttpAPICall.withAthorization(
      "PUT",
      this.remarkStatusUrl,
      this.props.access_token,
      {},
      { ...frmData },
      (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.addRemarksModal.hide();
        this.setState({ addRemarkForm: { ...this.initAddRemarkForm } });
        this.getTicketDetail(this.state.ticketData.activity_id);
        if (this.props.afterUpdate) {
          this.props.afterUpdate(this.state.ticketData.activity_id);
        }
        this.props?.projectDetails && this.props.listingUpdateWithProject();
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  ticketExpenseHandle = (expenseDetail = null) => {
    let data = {};
    if (this.state.ticketData !== null) {
      data = {
        assetName:
          this.state.ticketData && this.state.ticketData.asset
            ? `${this.state.ticketData?.asset.name} (${this.state.ticketData?.asset.asset_code})`
            : "",
        assetType:
          this.state.ticketData && this.state.ticketData.asset
            ? `${this.state.ticketData?.asset.asset_type_name}`
            : "",
        ticetId: `${this.state.ticketData.activity_id}`,
        siteId:
          this.state.ticketData && this.state.ticketData.asset
            ? this.state.ticketData?.asset.site_id
            : "",
        asset:
          this.state.ticketData && this.state.ticketData.asset
            ? this.state.ticketData?.asset
            : null,
        siteName:
          this.state.ticketData && this.state.ticketData.site.site_name
            ? this.state.ticketData.site.site_name
            : "",
      };
    }
    this.expenseAddRef.current.expenseModalShow("Ticket", data, expenseDetail);
  };

  handleExpenseDetailModal = (transation_id) => {
    this.setState({ ticketExpenseId: transation_id });
    this.viewExpenseModal.show();
  };

  viewAllocationDetail = (id) => {
    if (this.state.showOtherDetail == "Y") {
      this.setState({ selectedAllocationId: id }, () =>
        this.viewAllocationDetailModal.show()
      );
    }
  };

  viewConsumptionDetail = (id) => {
    if (this.state.showOtherDetail == "Y") {
      this.setState({ selectedConsumptionId: id }, () =>
        this.viewConsumptionDetailModal.show()
      );
    }
  };

  viewDemandDetail = (id) => {
    if (this.state.showOtherDetail == "Y") {
      this.setState({ selectedDemandId: id }, () =>
        this.viewDemandDetailModal.show()
      );
    }
  };

  changeAssetStatusModalInit = () => {
    this.ChangeAssetStatusModalRef.current.changeStatusModalInit(
      this.state.assetId
    );
  };

  expenseDeleteInit = (id) => {
    this.logExpenseRef.current.deleteExpenseInit(id);
  };

  addToAllocation = (type = null) => {
    let ticketData = this.state.ticketData ? this.state.ticketData : null;
    let ticketFor = "";
    let ticketForData = null;
    if (ticketData) {
      ticketFor = ticketData.ticket_for;
      if (ticketData.ticket_for === "sub_assembly") {
        ticketForData = ticketData.subAssembly && ticketData.subAssembly;
      } else if (ticketData.ticket_for === "employee") {
        ticketForData = ticketData.employee && ticketData.employee;
      } else if (ticketData.ticket_for === "workstation") {
        ticketForData = ticketData.workStation && ticketData.workStation;
      } else if (ticketData.ticket_for === "site") {
        ticketForData = ticketData.site && ticketData.site;
      } else if (ticketData.ticket_for === "asset") {
        ticketForData = ticketData.asset && ticketData.asset;
      }
    }

    let site_id = ticketData && ticketData.site ? ticketData.site.id : "";
    let obj = {
      site_id: site_id,
      ticket_header: ticketData.header,
      ticketFor,
      ticketForData,
    };
    localStorage.setItem("selectedTicketToAdd" + type, JSON.stringify(obj));
  };

  ticketViewJsx = () => {
    let ticketData = this.state.ticketData;
    let totalExpense = 0;
    let totalAllocationQty = 0;
    let totalPendingQty = 0;
    let totalConsumptionQty = 0;
    let totalConsumptionAmt = 0;
    let ticketFor = ticketData
      ? ticketData.ticket_for === "sub_assembly"
        ? ticketData.subAssembly && ticketData.subAssembly.name
        : ticketData.ticket_for === "employee"
          ? ticketData.employee && ticketData.employee.name
          : ticketData.ticket_for === "workstation"
            ? ticketData.workStation && ticketData.workStation.name
            : ticketData.ticket_for === "site"
              ? ticketData.site && ticketData.site.site_name
              : ticketData.ticket_for === "asset"
                ? ticketData.asset && ticketData.asset.name
                : ""
      : "";

    return (
      <div className="px-3 pb-3 bg-white">
        {ticketData ? (
          <Ax>
            <ul className="nav nav-tabs" id="myTab">
              <li className="nav-item" role="ticketTab">
                <button
                  className="nav-link active"
                  id="detail-tab"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#tab_1"
                  aria-controls="tab_1"
                  role="tab"
                  aria-selected="true"
                >
                  {" "}
                  Detail{" "}
                </button>
              </li>
              <li className="nav-item" role="ticketTab">
                <button
                  className="nav-link"
                  id="document-tab"
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#tab_2"
                  aria-controls="tab_2"
                  role="tab"
                  aria-selected="false"
                  ref={this.documentsNVoucherBtnRef}
                >
                  {" "}
                  Documents & Voucher{" "}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane show active"
                id="tab_1"
                aria-labelledby="detail-tab"
              >
                <div className="">
                  <div className="row mt-1">
                    <div className="col-sm-6">
                      <div className="tab_content_wrapper">
                        <span className="content_heading">
                          {" "}
                          Ticket Details{" "}
                        </span>
                      </div>
                      <table className="table mt-2 table-hover table-bordered">
                        <tbody>
                          <tr>
                            <td style={{ width: "25%" }}>Ticket Type</td>
                            <th style={{ width: "75%" }}>
                              {ticketData.ticket_type
                                ? ticketData.ticket_type.name
                                : "-"}{" "}
                            </th>
                          </tr>
                          <tr>
                            <td style={{ width: "25%" }}>Ticket Header</td>
                            <th style={{ width: "75%" }}>
                              {" "}
                              {ticketData.header ?? "-"}
                            </th>
                          </tr>
                          <tr>
                            <td style={{ width: "25%" }}>Ticket ID</td>
                            <th style={{ width: "75%" }}>
                              {ticketData.activity_id ?? "-"}
                            </th>
                          </tr>
                          <tr>
                            <td style={{ width: "25%" }}>Estimate Days</td>
                            <th style={{ width: "75%" }}>
                              {ticketData.estimate_days ?? "-"}{" "}
                              {ticketData.estimated_resolution_date
                                ? " (" +
                                ticketData.estimated_resolution_date +
                                ")"
                                : ""}{" "}
                            </th>
                          </tr>
                          <tr>
                            <td style={{ width: "25%" }}>Ticket Start Time</td>
                            <th style={{ width: "75%" }}>{DateService.dateTimeFormat(ticketData.start_date)}</th>
                          </tr>
                        </tbody>
                      </table>
                      {ticketData && ticketData.project && (
                        <Ax>
                          <div className="tab_content_wrapper">
                            <span className="content_heading">
                              {" "}
                              Project Details{" "}
                            </span>
                          </div>
                          <table className="table mt-2 table-hover table-bordered">
                            <tbody>
                              <tr>
                                <td style={{ width: "25%" }}>Project Name</td>
                                <th style={{ width: "75%" }}>
                                  {ticketData && ticketData.project
                                    ? ticketData.project.name
                                    : "-"}{" "}
                                </th>
                              </tr>
                              <tr>
                                <td style={{ width: "25%" }}>Project ID</td>
                                <th style={{ width: "75%" }}>
                                  {ticketData && ticketData.project
                                    ? ticketData.project.transaction_id
                                    : "-"}
                                </th>
                              </tr>
                              <tr>
                                <td style={{ width: "25%" }}>Parent Ticket</td>
                                <th style={{ width: "75%" }}>
                                  {ticketData && ticketData.parent_ticket
                                    ? ticketData.parent_ticket.header +
                                    " (" +
                                    ticketData.parent_ticket.activity_id +
                                    ")"
                                    : "-"}
                                </th>
                              </tr>
                              <tr>
                                <td style={{ width: "25%" }}>Child Ticket</td>
                                <th style={{ width: "75%" }}>
                                  {ticketData &&
                                    ticketData.child_tickets &&
                                    ticketData.child_tickets.length > 0
                                    ? ticketData.child_tickets.map((ct) => {
                                      return (
                                        <div>
                                          {" "}
                                          {ct.header} ({ct.activity_id})
                                        </div>
                                      );
                                    })
                                    : "-"}
                                </th>
                              </tr>
                              <tr>
                                <td style={{ width: "25%" }}>On Critical Path</td>
                                <th style={{ width: "70%" }}> {ticketData && ticketData.is_in_critical_path === "Y" ? "Yes" : "No"} </th>
                              </tr>
                            </tbody>
                          </table>
                        </Ax>
                      )}
                      <div className="tab_content_wrapper mt-2">
                        <span className="content_heading">Tagging Details</span>
                      </div>
                      <table className="table my-2 table-hover table-bordered">
                        <tbody>
                          <tr>
                            <td style={{ width: "25%" }}>Ticket For</td>
                            <th style={{ width: "75%" }}>
                              {ticketData.ticket_for_name}
                            </th>
                          </tr>
                          {ticketData.ticket_for &&
                            ticketData.ticket_for !== "site" && (
                              <tr>
                                <td style={{ width: "25%" }}>
                                  {ticketData.ticket_for_name}
                                </td>
                                <th style={{ width: "75%" }}>{ticketFor}</th>
                              </tr>
                            )}
                          <tr>
                            <td style={{ width: "25%" }}>Site</td>
                            <th style={{ width: "75%" }}>
                              {ticketData.site
                                ? ticketData.site.site_name
                                : "-"}
                            </th>
                          </tr>
                          <tr>
                            <td style={{ width: "25%" }}>User</td>
                            <th style={{ width: "75%" }}>
                              {ticketData?.tagged_users_display?.length > 0
                                ? ticketData?.tagged_users_display?.map(
                                  (item, key) => {
                                    return (
                                      <span key={key}>
                                        {item.full_name}
                                        <br />
                                      </span>
                                    );
                                  }
                                )
                                : "-"}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-sm-6">
                      <div className="row">
                        <div className="col-sm-6">
                          <div
                            className="card my-2"
                            style={{ minHeight: "40px" }}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <div className="row">
                                  <div className="col-sm-9">
                                    <span className="content_heading">
                                      Status
                                    </span>
                                  </div>
                                  <div className="col-sm-2">
                                    <button
                                      className="btn btn-link"
                                      onClick={this.addRemarkInit}
                                    >
                                      <TapIcon.FontAwesomeIcon
                                        icon={TapIcon.faPencilAlt}
                                        alt="item-action"
                                      />
                                    </button>
                                  </div>
                                  <div className="col-sm-1"></div>
                                </div>
                              </div>
                              <div>
                                <Status color={ticketData.status_color}>
                                  {ticketData.status_text}
                                </Status>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="card my-2"
                            style={{ minHeight: "40px" }}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <div className="row">
                                  <div className="col-sm-9">
                                    <span className="content_heading">
                                      Total Documents
                                    </span>
                                  </div>
                                  <div className="col-sm-2">
                                    <button
                                      className="btn btn-link"
                                      onClick={this.addDocumentModalInit}
                                    >
                                      <TapIcon.FontAwesomeIcon
                                        icon={TapIcon.faPlus}
                                        alt="item-action"
                                      />
                                    </button>
                                  </div>
                                  <div className="col-sm-1"></div>
                                </div>
                              </div>
                              <div>
                                <Link onClick={this.handleDocumentsNVoucher}>
                                  {ticketData.documents &&
                                    ticketData.documents.length}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="card my-2"
                            style={{ minHeight: "95px" }}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <span className="content_heading">
                                  Resolution
                                </span>
                              </div>
                              <div className="mb-1">
                                Date: {ticketData.resolution_date}
                              </div>
                              <div>Days: {ticketData.resolution_days}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="card my-2"
                            style={{ minHeight: "95px" }}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <span className="content_heading">Closure</span>
                              </div>
                              <div className="mb-1">
                                Date:{" "}
                                {ticketData.closure_date
                                  ? ticketData.closure_date
                                  : "-"}
                              </div>
                              <div>Days: {ticketData.closure_days}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="card my-2"
                            style={{ minHeight: "95px" }}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <span className="content_heading">Ageing</span>
                              </div>
                              <div className="mb-1">
                                Ticket Ageing: {ticketData.aging}
                              </div>
                              <div>
                                Remark Ageing: {ticketData.remark_aging}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div
                            className="card my-2"
                            style={{ minHeight: "95px" }}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <div className="row">
                                  <div className="col-sm-9">
                                    <span className="content_heading">
                                      Defect Code
                                    </span>
                                  </div>
                                  <div className="col-sm-2 text-end">
                                    <button
                                      className="btn btn-link"
                                      onClick={() =>
                                        this.updateTicketModalInit(
                                          ticketData.activity_id,
                                          "other_detail",
                                          true
                                        )
                                      }
                                    >
                                      <TapIcon.FontAwesomeIcon
                                        icon={TapIcon.faPencilAlt}
                                        alt="item-action"
                                      />
                                    </button>
                                  </div>
                                  <div className="col-sm-1"></div>
                                </div>
                              </div>
                              <div className="mb-1">
                                {ticketData &&
                                  ticketData.defect_codes &&
                                  ticketData.defect_codes.length > 0
                                  ? ticketData.defect_codes
                                    .map((code) => code.name)
                                    .join(", ")
                                  : "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                        {ticketData &&
                          ticketData.setting &&
                          ticketData.setting.enable_ticket_mttr == "Y" && (
                            <div className="col-sm-12">
                              <div
                                className="card my-2"
                                style={{ minHeight: "40px" }}
                              >
                                <div className="card-body">
                                  <div className="card-title">
                                    <div className="row">
                                      <div className="col-sm-10">
                                        <span className="content_heading">
                                          Mean Time to Repair (MTTR)
                                          {ticketData.ticket_type && ticketData.ticket_type.is_repair === 'Y' && <span style={{"color" : "red"}}> *</span>}
                                        </span>
                                      </div>
                                      <div className="col-sm-2 text-end">
                                        {![0, 10, 26].includes(ticketData.status) && <button className="btn btn-link" onClick={() => this.updateTicketModalInit(ticketData.activity_id, "mttr")}>
                                            <TapIcon.FontAwesomeIcon icon={TapIcon.faPencilAlt} alt="item-action" />
                                          </button>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-1">
                                    <span>Failure Start Time: </span>
                                    <span className="float-end">{ticketData.failure_start_time ? DateService.dateTimeFormat(ticketData.failure_start_time) : "-"}
                                    </span>
                                  </div>
                                  <div className="">
                                    <span>Failure Closing Time: </span>
                                    <span className="float-end">{ticketData.failure_closing_time ? DateService.dateTimeFormat(ticketData.failure_closing_time) : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {ticketData &&
                          ticketData.setting &&
                          ticketData.setting.ticket_allocation == "Y" &&
                          this.props.group_permission &&
                          this.props.group_permission.group_modules.includes(
                            "isc"
                          ) && (
                            <div className="col-sm-6">
                              <div className="card my-2">
                                <div
                                  className="card-body"
                                  style={{ minHeight: "40px" }}
                                >
                                  <div className="card-title">
                                    <div className="row">
                                      <div className="col-sm-10">
                                        <span className="content_heading">
                                          Allocation
                                        </span>
                                      </div>
                                      {ticketData &&
                                        ticketData.status !== 0 && (
                                          <div className="col-sm-2 text-end">
                                            <Link
                                              target="_blank"
                                              disabled={
                                                !this.props.permissions.includes(
                                                  "isc-allocation-add"
                                                )
                                              }
                                              to={{
                                                pathname:
                                                  "/allocation_list/add",
                                                search:
                                                  "?" +
                                                  new URLSearchParams({
                                                    ticketId:
                                                      ticketData.activity_id
                                                        ? ticketData.activity_id
                                                        : "",
                                                  }).toString(),
                                              }}
                                              onClick={() =>
                                                this.addToAllocation(
                                                  "allocation"
                                                )
                                              }
                                            >
                                              <TapIcon.FontAwesomeIcon
                                                icon={TapIcon.faPlus}
                                                alt="item-action"
                                              />
                                            </Link>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div>
                                    Qty:{" "}
                                    {ticketData.total_allocation &&
                                      ticketData.total_allocation.total_qty ? (
                                      <Link
                                        onClick={this.handleDocumentsNVoucher}
                                      >
                                        {ticketData.total_allocation.total_qty}
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {ticketData &&
                          ticketData.setting &&
                          ticketData.setting.ticket_consumption == "Y" &&
                          this.props.group_permission &&
                          this.props.group_permission.group_modules.includes(
                            "isc"
                          ) && (
                            <div className="col-sm-6">
                              <div
                                className="card my-2"
                                style={{ minHeight: "40px" }}
                              >
                                <div className="card-body">
                                  <div className="card-title">
                                    <div className="row">
                                      <div className="col-sm-10">
                                        <span className="content_heading">
                                          Consumption
                                        </span>
                                      </div>
                                      {ticketData &&
                                        ticketData.status !== 0 && (
                                          <div className="col-sm-2 text-end">
                                            <Link
                                              target="_blank"
                                              disabled={
                                                !this.props.permissions.includes(
                                                  "isc-consumption-add"
                                                )
                                              }
                                              to={{
                                                pathname:
                                                  "/consumption_list/add",
                                                search:
                                                  "?" +
                                                  new URLSearchParams({
                                                    ticketId:
                                                      ticketData.activity_id
                                                        ? ticketData.activity_id
                                                        : "",
                                                  }).toString(),
                                              }}
                                              onClick={() =>
                                                this.addToAllocation(
                                                  "consumption"
                                                )
                                              }
                                            >
                                              <TapIcon.FontAwesomeIcon
                                                icon={TapIcon.faPlus}
                                                alt="item-action"
                                              />
                                            </Link>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div>
                                    Qty:{" "}
                                    {ticketData.total_consumption &&
                                      ticketData.total_consumption.total_qty ? (
                                      <Link
                                        onClick={this.handleDocumentsNVoucher}
                                      >
                                        {ticketData.total_consumption.total_qty}{" "}
                                        (Amt :{" "}
                                        {
                                          ticketData.total_consumption
                                            .sum_total_amount
                                        }
                                        )
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {ticketData &&
                          ticketData.setting &&
                          ticketData.setting.ticket_expense == "Y" && (
                            <div className="col-sm-6">
                              <div
                                className="card my-2"
                                style={{ minHeight: "40px" }}
                              >
                                <div className="card-body">
                                  <div className="card-title">
                                    <div className="row">
                                      <div className="col-sm-9">
                                        <span className="content_heading">
                                          Expense
                                        </span>
                                      </div>
                                      <div className="col-sm-2 text-end">
                                        <button
                                          className="btn btn-link"
                                          onClick={() =>
                                            this.ticketExpenseHandle(null)
                                          }
                                        >
                                          <TapIcon.FontAwesomeIcon
                                            icon={TapIcon.faPlus}
                                            alt="item-action"
                                          />
                                        </button>
                                      </div>
                                      <div className="col-sm-1"></div>
                                    </div>
                                  </div>
                                  <div>
                                    Amt:{" "}
                                    {ticketData.total_expense &&
                                      ticketData.total_expense.total_expense ? (
                                      <Link
                                        onClick={this.handleDocumentsNVoucher}
                                      >
                                        {ticketData.total_expense.total_expense}
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {ticketData &&
                          ticketData.setting &&
                          ticketData.setting.ticket_demand == "Y" && (
                            <div className="col-sm-6">
                              <div
                                className="card my-2"
                                style={{ minHeight: "40px" }}
                              >
                                <div className="card-body">
                                  <div className="card-title">
                                    <div className="row">
                                      <div className="col-sm-10">
                                        <span className="content_heading">
                                          Item Demand
                                        </span>
                                      </div>
                                      {ticketData &&
                                        ticketData.status !== 0 && (
                                          <div className="col-sm-2 text-end">
                                            <Link
                                              target="_blank"
                                              // disabled={!this.props.permissions.includes('isc-consumption-add')}
                                              to={{
                                                pathname: "/item_demand_add",
                                                search:
                                                  "?" +
                                                  new URLSearchParams({
                                                    ticketId:
                                                      ticketData.activity_id
                                                        ? ticketData.activity_id
                                                        : "",
                                                  }).toString(),
                                              }}
                                              onClick={() =>
                                                this.addToAllocation("demand")
                                              }
                                            >
                                              <TapIcon.FontAwesomeIcon
                                                icon={TapIcon.faPlus}
                                                alt="item-action"
                                              />
                                            </Link>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div>
                                    Qty:{" "}
                                    {ticketData.total_demands &&
                                      ticketData.total_demands.total_qty ? (
                                      <Link
                                        onClick={this.handleDocumentsNVoucher}
                                      >
                                        {ticketData.total_demands.total_qty}
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-sm-9">
                      <div className="tab_content_wrapper mt-2">
                        <span className="content_heading">Other Details</span>
                      </div>
                      <table className="table mt-2 table-hover table-bordered">
                        <tbody>
                          {ticketData &&
                            ticketData.setting &&
                            ticketData.setting.ticket_allow_tag === "Y" && (
                              <tr>
                                <td style={{ width: "25%" }}>Tags</td>
                                <th style={{ width: "75%" }}>
                                  {ticketData.tags && ticketData.tags.length > 0
                                    ? ticketData.tags
                                      .map((tag, i) => tag)
                                      .join(", ")
                                    : "-"}
                                </th>
                              </tr>
                            )}
                          {ticketData &&
                            ticketData.setting &&
                            ticketData.setting.ticket_allow_employees ===
                            "Y" && (
                              <tr>
                                <td style={{ width: "25%" }}>Employees</td>
                                <th style={{ width: "75%" }}>
                                  {ticketData.employees &&
                                    ticketData.employees.length > 0
                                    ? ticketData.employees
                                      .map((employee, i) => employee.name)
                                      .join(", ")
                                    : "-"}
                                </th>
                              </tr>
                            )}
                            {ticketData &&(
                              <tr>
                                <td style={{ width: "25%" }}>Description</td>
                                <th style={{ width: "75%" }}>
                                  {ticketData.description ? ticketData.description : '-'}
                                </th>
                              </tr>
                            )}
                          {ticketData &&
                            ticketData.defect_codes &&
                            ticketData.defect_codes.length > 0 && (
                              <tr>
                                <td style={{ width: "25%" }}>Defect Codes</td>
                                <th style={{ width: "75%" }}>
                                  {ticketData.defect_codes
                                    .map((def, i) => {
                                      return def.name;
                                    })
                                    .join(", ")}
                                </th>
                              </tr>
                            )}
                          {ticketData &&
                            ticketData.additional_attributes_data &&
                            ticketData.additional_attributes_data.length > 0
                            ? ticketData.additional_attributes_data.map(
                              (attr) => {
                                return (
                                  <tr key={attr.key}>
                                    <td
                                      scope="col"
                                      className="text-capitalize"
                                    >
                                      {attr.name ? attr.name : "-"}
                                    </td>
                                    <th scope="col" colSpan={2}>
                                      {attr.value_display
                                        ? attr.value_display
                                        : "-"}
                                    </th>
                                  </tr>
                                );
                              }
                            )
                            : null}
                        </tbody>
                      </table>

                      <div className="tab_content_wrapper mt-2">
                        <span className="content_heading">
                          Transaction Detail
                        </span>
                      </div>
                      <table className="table mt-2 table-hover table-bordered">
                        <thead className="table-secondary">
                          <tr>
                            <th style={{ width: "15%" }}>Attribute</th>
                            <th style={{ width: "15%" }}>Date</th>
                            <th style={{ width: "25%" }}>User Name</th>
                            <th style={{ width: "25%" }}>Counterlog</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Ticket Creation</td>
                            <td>
                              {ticketData.created_at_date
                                ? ticketData.created_at_date
                                : "-"}
                            </td>
                            <td>
                              {ticketData.qt_created_by
                                ? ticketData.qt_created_by
                                : "-"}
                            </td>
                            <td>
                              {ticketData.create_on_cl
                                ? ticketData.create_on_cl.display
                                  .map((cl) => cl)
                                  .join(" | ")
                                : "NA"}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Updated</td>
                            <td>
                              {ticketData.last_updated_on_display
                                ? ticketData.last_updated_on_display
                                : "-"}
                            </td>
                            <td>
                              {ticketData.qt_updated_by
                                ? ticketData.qt_updated_by
                                : "-"}
                            </td>
                            <td>
                              {ticketData.updated_on_cl
                                ? ticketData.updated_on_cl.display
                                  .map((cl) => cl)
                                  .join(" | ")
                                : "NA"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="tab_content_wrapper mt-2">
                        <span className="content_heading">Remarks</span>
                      </div>
                      <div className="row my-1">
                        <div className="col-12">
                          {ticketData && ticketData.description && (
                            <div>
                              <span className="fw-bold link-primary">
                                {" "}
                                Opening Description :{" "}
                              </span>
                              <br />
                              <div className="py-2">
                                {ticketData.description}{" "}
                              </div>
                            </div>
                          )}
                        </div>
                        {ticketData &&
                          ticketData.remarks &&
                          ticketData.remarks.length > 0
                          ? ticketData.remarks.map((c, k) => {
                            return (
                              <Ax key={k}>
                                <div className="row">
                                  <div className="col-sm-12">
                                    <span className="fw-bold link-primary"> {c.submit_by_name} </span>
                                    Added a remark on <span className="fw-bold"> {c.created_at}</span>
                                  </div>
                                  <div className="col-sm-12 my-1"> <span> {c.remark}</span> </div>
                                </div>
                                <hr />
                              </Ax>
                            );
                          })
                          : null}
                        <div className="text-end pe-0">
                          <button
                            className="btn btn-primary"
                            onClick={this.addRemarkInit}
                          >
                            Add Remark
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      {ticketData &&
                        ticketData.asset &&
                        this.state.showAssetCard == "Y" && (
                          <div>
                            {this.state.card_loading ? (
                              <Loader />
                            ) : (
                              <AssetCard
                                assetCardDetails={this.state.asset_card_details}
                                refreshAssetDetail={() => this.getAssetCard()}
                              />
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Documents & Voucher Details */}
              <div
                className="tab-pane"
                id="tab_2"
                aria-labelledby="document-tab"
              >
                <div className="tab_content_wrapper mt-2">
                  <span className="content_heading">Documents</span>
                </div>
                <table className="table mt-2 table-hover  table-bordered">
                  <tbody>
                    {ticketData &&
                      ticketData.documents &&
                      ticketData.documents.length > 0 ? (
                      ticketData?.documents?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td scope="col" style={{ width: "5%" }}>
                              {index + 1}
                            </td>
                            <td
                              scope="col"
                              style={{ width: "65%" }}
                              className="text-capitalize"
                            >
                              {item.doc_name}
                              {item.doc_number && `(${item.doc_number})`}
                            </td>
                            {item.longitude && item.latitue && (
                              <td
                                scope="col"
                                style={{ width: "6%" }}
                                className="text-center"
                              >
                                <a
                                  href={`https://www.google.com/maps/search/${item.latitude},${item.longitude}`}
                                >
                                  <TapIcon.FontAwesomeIcon
                                    icon={TapIcon.faMapMarker}
                                  />
                                </a>
                              </td>
                            )}
                            <td
                              scope="col"
                              className="text-center"
                              style={{ width: "6%" }}
                            >
                              <a
                                href={item.path}
                                target="_blank"
                                className="asset_link"
                              >
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faEye} />
                              </a>
                            </td>
                            <td
                              scope="col"
                              style={{ width: "6%" }}
                              className="text-center"
                            >
                              <a href={item.download_link}>
                                <TapIcon.FontAwesomeIcon
                                  icon={TapIcon.faDownload}
                                />
                              </a>
                            </td>
                            {this.state.performAction == "Y" && (
                              <td
                                className="text-center"
                                style={{ width: "6%" }}
                                onClick={() => this.deleteDocument(item.id)}
                              >
                                <TapIcon.FontAwesomeIcon
                                  icon={TapIcon.faTrashAlt}
                                  className="asset_link"
                                />
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No Documents Added
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {ticketData &&
                  ticketData.setting &&
                  ticketData.setting.ticket_expense &&
                  ticketData.setting.ticket_expense == "Y" && (
                    <Ax>
                      <div className="tab_content_wrapper mt-2">
                        <span className="content_heading">Expense</span>
                      </div>
                      <table className="table table-hover table-bordered table-sm my-1">
                        <thead className="table-secondary">
                          <tr>
                            <th colSpan={4}>Total Expense</th>
                            <td className="text-end">
                              {ticketData.total_expense
                                ? ticketData.total_expense.total_expense
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th scope="col" style={{ width: "15%" }}>
                              Date
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                              Transaction ID
                            </th>
                            <th scope="col" style={{ width: "30%" }}>
                              Expense Head
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                              Amount
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.ticketExpenseLoading ? (
                            <tr>
                              <td className="text-center" colSpan="5">
                                <Loader />
                              </td>
                            </tr>
                          ) : this.state.ticketExpenseData &&
                            this.state.ticketExpenseData.length > 0 ? (
                            <>
                              {this.state.ticketExpenseData.map((data, i) => {
                                totalExpense += parseFloat(data.amount);
                                return (
                                  <tr key={i}>
                                    <td>{data.expense_date_display}</td>
                                    <td>
                                      <Link
                                        onClick={() =>
                                          this.handleExpenseDetailModal(
                                            data.transaction_id
                                          )
                                        }
                                      >
                                        {data.transaction_id}
                                      </Link>
                                    </td>
                                    <td>
                                      {data &&
                                        data.expense_heads &&
                                        data.expense_heads.length > 0 &&
                                        data.expense_heads.map((head, i) => {
                                          let name = head.expense_head;
                                          if (
                                            data.expense_heads.length - 1 !==
                                            i
                                          ) {
                                            name += ", ";
                                          }
                                          return name;
                                        })}
                                    </td>
                                    <td className="text-end">{data.amount}</td>
                                    <td className="text-center">
                                      <button
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-toggle="dropdown"
                                        role="button"
                                        aria-expanded="false"
                                      >
                                        <TapIcon.imageIcon
                                          icon={TapIcon.ListActionIcon}
                                          className="img-fluid"
                                          alt="item-action"
                                        />
                                      </button>
                                      <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                        <li>
                                          <a
                                            role="button"
                                            onClick={() => {
                                              if (
                                                this.props.permissions.includes(
                                                  "iam-asset-expense-update"
                                                ) &&
                                                data.can_edit == "Y"
                                              ) {
                                                return this.ticketExpenseHandle(
                                                  data
                                                );
                                              }
                                            }}
                                            className={[
                                              "dropdown-item",
                                              this.props.permissions.includes(
                                                "iam-asset-expense-update"
                                              ) && data.can_edit == "Y"
                                                ? ""
                                                : "disabled",
                                            ].join(" ")}
                                            title={
                                              !this.props.permissions.includes(
                                                "iam-asset-expense-update"
                                              ) && data.can_edit == "N"
                                                ? "You do not have permission to perform this action"
                                                : ""
                                            }
                                            style={{
                                              pointerEvents: "all",
                                            }}
                                          >
                                            Edit
                                          </a>
                                        </li>
                                        <li
                                          className="dropdown-item"
                                          onClick={() =>
                                            this.handleExpenseDetailModal(
                                              data.transaction_id
                                            )
                                          }
                                        >
                                          View
                                        </li>
                                        <li>
                                          <a
                                            role="button"
                                            onClick={() => {
                                              if (
                                                this.props.permissions.includes(
                                                  "iam-asset-expense-delete"
                                                )
                                              ) {
                                                return this.expenseDeleteInit(
                                                  data.transaction_id
                                                );
                                              }
                                            }}
                                            className={[
                                              "dropdown-item",
                                              this.props.permissions.includes(
                                                "iam-asset-expense-delete"
                                              )
                                                ? ""
                                                : "disabled",
                                            ].join(" ")}
                                            title={
                                              !this.props.permissions.includes(
                                                "iam-asset-expense-delete"
                                              )
                                                ? "You do not have permission to perform this action"
                                                : ""
                                            }
                                            style={{
                                              pointerEvents: "all",
                                            }}
                                          >
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <tr>
                              <td className="text-center" colSpan="5">
                                No Record
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <DisplayListPagination
                        meta={this.state.expenseListingMeta}
                        onPageChange={(e) =>
                          this.getTicketExpenseData(e.selected + 1)
                        }
                      />
                    </Ax>
                  )}
                {this.props.group_permission &&
                  this.props.group_permission.group_modules.includes("isc") && (
                    <Ax>
                      {ticketData &&
                        ticketData.setting &&
                        ticketData.setting.ticket_demand &&
                        ticketData.setting.ticket_demand == "Y" && (
                          <Ax>
                            <div className="tab_content_wrapper mt-2">
                              <span className="content_heading">
                                Item Demand
                              </span>
                            </div>
                            <table className="table table-hover table-bordered table-sm my-2 mb-4">
                              <thead className="table-secondary">
                                <tr>
                                  <td colSpan={6}>
                                    <b>Total Demand Qty</b>
                                  </td>
                                  <td className="text-end">
                                    {ticketData.total_demands
                                      ? ticketData.total_demands.total_qty
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col" style={{ width: "5%" }}>
                                    S.no
                                  </th>
                                  <th scope="col" style={{ width: "10%" }}>
                                    Date
                                  </th>
                                  <th scope="col" style={{ width: "10%" }}>
                                    Transaction ID
                                  </th>
                                  <th scope="col" style={{ width: "20%" }}>
                                    Warehouse
                                  </th>
                                  <th scope="col" style={{ width: "35%" }}>
                                    Demand For
                                  </th>
                                  <th scope="col" style={{ width: "10%" }}>
                                    Total Qty
                                  </th>

                                  <th scope="col" style={{ width: "10%" }}>
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.demand_loading ? (
                                  <tr>
                                    <td className="text-center" colSpan="7">
                                      <Loader />
                                    </td>
                                  </tr>
                                ) : this.state.demand_listing &&
                                  this.state.demand_listing.length > 0 ? (
                                  <>
                                    {this.state.demand_listing.map(
                                      (item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              {this.state.demandlistingMeta
                                                ? this.state.demandlistingMeta
                                                  .from + index
                                                : index}
                                            </td>
                                            <td className="text-center">
                                              {DateService.dateTimeFormat(
                                                item.transaction_date,
                                                "DD-MMM-YYYY"
                                              )}
                                            </td>
                                            <td className="text-center">
                                              <a
                                                role="button"
                                                className="link-primary"
                                                onClick={() =>
                                                  this.viewDemandDetail(
                                                    item.transaction_id
                                                  )
                                                }
                                              >
                                                {item.transaction_id
                                                  ? item.transaction_id
                                                  : "-"}
                                              </a>
                                            </td>
                                            <td className="text-capitalize">
                                              {item.warehouse ? (
                                                <div>{item.warehouse.name}</div>
                                              ) : (
                                                "-"
                                              )}
                                            </td>
                                            <td>
                                              {item.asset ? (
                                                <div>
                                                  <small className="form-text">
                                                    {item.demand_for == "asset"
                                                      ? "Asset : "
                                                      : ""}
                                                  </small>{" "}
                                                  {item.asset.name} (
                                                  {item.asset.asset_code})
                                                </div>
                                              ) : item.workstation ? (
                                                <div>
                                                  <small className="form-text">
                                                    {item.demand_for ==
                                                      "workstation"
                                                      ? "Workstation : "
                                                      : ""}
                                                  </small>{" "}
                                                  {
                                                    item.workstation
                                                      .workstation_name
                                                  }{" "}
                                                </div>
                                              ) : item.sub_assembly ? (
                                                <div>
                                                  <small className="form-text">
                                                    {item.demand_for ==
                                                      "sub_assembly"
                                                      ? "Sub Assembly : "
                                                      : ""}
                                                  </small>{" "}
                                                  {item.sub_assembly.name}{" "}
                                                </div>
                                              ) : item.employee ? (
                                                <div>
                                                  <small className="form-text">
                                                    {item.demand_for ==
                                                      "employee"
                                                      ? "Employee : "
                                                      : ""}
                                                  </small>{" "}
                                                  {item.employee.name}{" "}
                                                </div>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </td>
                                            <td>{item.total_qty}</td>
                                            <td>
                                              {item.status
                                                ? item.status.name
                                                : ""}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <tr>
                                    <td className="text-center" colSpan={7}>
                                      No Records
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <DisplayListPagination
                              meta={this.state.demandlistingMeta}
                              onPageChange={(e) =>
                                this.getDemandList(e.selected + 1)
                              }
                            />
                          </Ax>
                        )}
                      {ticketData &&
                        ticketData.setting &&
                        ticketData.setting.ticket_allocation &&
                        ticketData.setting.ticket_allocation == "Y" && (
                          <Ax>
                            <div className="tab_content_wrapper mt-2">
                              <span className="content_heading">
                                Allocation
                              </span>
                            </div>
                            <table className="table table-hover table-bordered table-sm my-2">
                              <thead className="table-secondary">
                                <tr>
                                  <th colSpan={6}>Total Allocation Qty</th>
                                  <td className="text-end">
                                    {ticketData.total_allocation
                                      ? ticketData.total_allocation.total_qty
                                      : "-"}
                                  </td>
                                  {/* <td colSpan={2}></td> */}
                                </tr>
                                <tr>
                                  <th scope="col" style={{ width: "5%" }}>
                                    S.no
                                  </th>
                                  <th scope="col" style={{ width: "15%" }}>
                                    Date
                                  </th>
                                  <th scope="col" style={{ width: "15%" }}>
                                    Transaction ID
                                  </th>
                                  <th scope="col" style={{ width: "30%" }}>
                                    Warehouse
                                  </th>
                                  <th scope="col" style={{ width: "10%" }}>
                                    Total Qty
                                  </th>
                                  <th scope="col" style={{ width: "10%" }}>
                                    Pending Qty
                                  </th>
                                  <th scope="col" style={{ width: "15%" }}>
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.allocation_loading ? (
                                  <tr>
                                    <td className="text-center" colSpan="7">
                                      <Loader />
                                    </td>
                                  </tr>
                                ) : this.state.allocation_listing &&
                                  this.state.allocation_listing.length > 0 ? (
                                  <>
                                    {this.state.allocation_listing.map(
                                      (item, index) => {
                                        totalAllocationQty += item.total_qty
                                          ? parseInt(item.total_qty)
                                          : 0;
                                        totalPendingQty +=
                                          item.total_pending_qty
                                            ? parseInt(item.total_pending_qty)
                                            : 0;
                                        return (
                                          <tr key={index}>
                                            <td>
                                              {this.state.allocationlistingMeta
                                                ? this.state
                                                  .allocationlistingMeta
                                                  .from + index
                                                : index}
                                            </td>
                                            <td className="text-center">
                                              {item.transaction_date_display}
                                            </td>
                                            <td className="text-center">
                                              <a
                                                role="button"
                                                className="link-primary"
                                                onClick={() =>
                                                  this.viewAllocationDetail(
                                                    item.transaction_id
                                                  )
                                                }
                                              >
                                                {item.transaction_id
                                                  ? item.transaction_id
                                                  : "-"}
                                              </a>
                                            </td>
                                            <td className="text-capitalize">
                                              {item.warehouse ? (
                                                <div>{item.warehouse.name}</div>
                                              ) : (
                                                "-"
                                              )}
                                            </td>

                                            <td className="text-end">
                                              {item.total_qty ? item.total_qty : 0}
                                            </td>
                                            <td className="text-end">
                                              {item.total_pending_qty
                                                ? item.total_pending_qty
                                                : 0}
                                            </td>
                                            <td className="text-center">
                                              {item.status_data
                                                ? item.status_data.name
                                                : "-"}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <tr>
                                    <td className="text-center" colSpan={7}>
                                      No Records
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <DisplayListPagination
                              meta={this.state.allocationlistingMeta}
                              onPageChange={(e) =>
                                this.getAllocationList(e.selected + 1)
                              }
                            />
                          </Ax>
                        )}
                      {ticketData &&
                        ticketData.setting &&
                        ticketData.setting.ticket_consumption &&
                        ticketData.setting.ticket_consumption == "Y" && (
                          <Ax>
                            <div className="tab_content_wrapper mt-2">
                              <span className="content_heading">
                                Consumption
                              </span>
                            </div>
                            <table className="table table-hover table-bordered table-sm my-2 mb-4">
                              <thead className="table-secondary">
                                <tr>
                                  <td colSpan={4}>
                                    <b>Total Consumption Qty/Amount</b>
                                  </td>
                                  <td className="text-end">
                                    {ticketData.total_consumption
                                      ? ticketData.total_consumption.total_qty
                                      : "-"}
                                  </td>
                                  <td className="text-end">
                                    {ticketData.total_consumption
                                      ? ticketData.total_consumption
                                        .sum_total_amount
                                      : "-"}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <th scope="col" style={{ width: "5%" }}>
                                    S.no
                                  </th>
                                  <th scope="col" style={{ width: "15%" }}>
                                    Date
                                  </th>
                                  <th scope="col" style={{ width: "15%" }}>
                                    Transaction ID
                                  </th>
                                  <th scope="col" style={{ width: "30%" }}>
                                    Warehouse
                                  </th>
                                  <th scope="col" style={{ width: "10%" }}>
                                    Total Qty
                                  </th>
                                  <th scope="col" style={{ width: "10%" }}>
                                    Amount
                                  </th>
                                  <th scope="col" style={{ width: "15%" }}>
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.consumption_loading ? (
                                  <tr>
                                    <td className="text-center" colSpan="7">
                                      <Loader />
                                    </td>
                                  </tr>
                                ) : this.state.consumption_listing &&
                                  this.state.consumption_listing.length > 0 ? (
                                  <>
                                    {this.state.consumption_listing.map(
                                      (item, index) => {
                                        totalConsumptionAmt += parseFloat(
                                          item.total_amount
                                        );
                                        totalConsumptionQty += item.total_qty
                                          ? item.total_qty
                                          : 0;
                                        return (
                                          <tr key={index}>
                                            <td>
                                              {this.state.consumptionlistingMeta
                                                ? this.state
                                                  .consumptionlistingMeta
                                                  .from + index
                                                : index}
                                            </td>
                                            <td className="text-center">
                                              {item.transaction_date_display}
                                            </td>
                                            <td className="text-center">
                                              <a
                                                role="button"
                                                className="link-primary"
                                                onClick={() =>
                                                  this.viewConsumptionDetail(
                                                    item.transaction_id
                                                  )
                                                }
                                              >
                                                {item.transaction_id
                                                  ? item.transaction_id
                                                  : "-"}
                                              </a>
                                            </td>
                                            <td className="text-capitalize">
                                              {item.warehouse ? (
                                                <div>{item.warehouse.name}</div>
                                              ) : (
                                                "-"
                                              )}
                                            </td>

                                            <td className="text-end">
                                              {item.total_qty
                                                ? item.total_qty
                                                : 0}
                                            </td>
                                            <td className="text-end">
                                              {item.total_amount ? (
                                                <CurrencyFormatter
                                                  currencyCode={
                                                    this.props.default_currency
                                                  }
                                                  amount={parseFloat(
                                                    item.total_amount
                                                  ).toFixed(2)}
                                                />
                                              ) : (
                                                0
                                              )}
                                            </td>
                                            <td className="text-center">
                                              {item.status_data
                                                ? item.status_data.name
                                                : "-"}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <tr>
                                    <td className="text-center" colSpan={7}>
                                      No Records
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <DisplayListPagination
                              meta={this.state.consumptionlistingMeta}
                              onPageChange={(e) =>
                                this.getConsumptionList(e.selected + 1)
                              }
                            />
                          </Ax>
                        )}
                    </Ax>
                  )}
              </div>
            </div>
          </Ax>
        ) : null}
      </div>
    );
  };

  handleDocumentsNVoucher = () => {
    this.documentsNVoucherBtnRef.current.click();
  };

  //****************************** UPDATE  TICKET  DETAILS ******************
  updateTicketModalInit = (id, addFormState = "detail", defectCode = false) => {
    this.updateTicketModal.show();
    const ticketData = this.state.ticketData;
    this.setState({
      addFormState: addFormState,
      onlyDefectCode: defectCode,
      searchedTicket: ticketData.parent_ticket
        ? {
          display_label: (
            <Ax>
              {ticketData.parent_ticket.activity_id} :{" "}
              {ticketData.parent_ticket.header}
            </Ax>
          ),
          value: ticketData.parent_ticket.activity_id,
          label: (
            <Ax>
              {ticketData.parent_ticket.activity_id} :{" "}
              {ticketData.parent_ticket.header}
            </Ax>
          ),
        }
        : "",
    });
    this.getTicketEditFormData(id);
  };

  updateTicketModalJsx = () => {
    let ticketData = this.state.ticketData;
    const allowNdConQty =
      ticketData &&
      ((ticketData.total_allocation &&
        ticketData.total_allocation.total_qty > 0) ||
        (ticketData.total_consumption &&
          ticketData.total_consumption.total_qty > 0)
        ? true
        : false);

    return (
      <div className="modal fade" id="updateTicketModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addAssetModalLabel">
                Update Ticket
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => this.updateTicketModal.hide()}
              ></button>
            </div>
            {this.state.formDataLoading ? (
              <Loader />
            ) : (
              <form id="editTicketForm" onSubmit={this.submitEditTicket}>
                <div className="modal-body">
                  {this.state.addFormState == "detail" && (
                    <>
                      <div className="tab_content_wrapper mb-2">
                        <span className="content_heading">Ticket Detail</span>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="form-label require">
                            Ticket Type{" "}
                          </label>
                        </div>
                        <div className="col-md-8">
                          <TapSelect
                            options={this.state.allTicketTypes}
                            changeEvent={(selectedOption) =>
                              this.tapSelectChange(
                                selectedOption,
                                "ticketEditForm",
                                "ticket_type_id"
                              )
                            }
                            value={this.state.allTicketTypes.find(
                              (a) =>
                                a.value ==
                                this.state.ticketEditForm.ticket_type_id
                            )}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            placeholder="Please Select Ticket Type"
                            containerHeight="30px"
                            fontSize="93%"
                          />
                        </div>
                      </div>
                      {ticketData.parent_ticket && (
                        <div className="row mt-2">
                          <div className="col-md-4">
                            <label
                              className={`form-label ${ticketData.parent_ticket ? "require" : ""
                                }`}
                            >
                              Parent Ticket
                            </label>
                          </div>
                          <div className="col-md-8">
                            <InputTicketSearch
                              changeEvent={(selectedOption) => {
                                this.tapSelectChange(
                                  selectedOption,
                                  "ticketEditForm",
                                  "parent_ticket_activity_id"
                                );
                                this.setState({
                                  searchedTicket: selectedOption
                                    ? selectedOption
                                    : "",
                                });
                              }}
                              search_project_id={this.props?.projectDetails?.id}
                              required={ticketData.parent_ticket ? true : false}
                              disabled={ticketData.parent_ticket ? false : true}
                              value={this.state.searchedTicket}
                              search_exclude_ticket_status={
                                this.props?.projectDetails ? [10] : [26, 10]
                              }
                              containerHeight="30px"
                              fontSize="93%"
                            />
                          </div>
                        </div>
                      )}
                      <div className="row mt-2">
                        <div className="col-md-4">
                          <label className="form-label require">
                            Ticket Header{" "}
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            name="header"
                            type="text"
                            value={this.state.ticketEditForm.header}
                            className="form-control form-control-sm"
                            required={true}
                            autoComplete="off"
                            onChange={(e) =>
                              this.formInputHandler(e, "ticketEditForm")
                            }
                            placeholder="Please enter Ticket Header"
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-4">
                          <label className="form-label require">
                            Estimate Days
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            autoComplete="off"
                            name="estimate_days"
                            required
                            value={this.state.ticketEditForm.estimate_days}
                            onChange={(e) =>
                              this.formInputHandler(e, "ticketEditForm")
                            }
                            placeholder="Enter Estimate Days"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.addFormState == "tagging_detail" && (
                    <>
                      <div className="tab_content_wrapper mt-2">
                        <span className="content_heading">Tagging Detail</span>
                      </div>
                      <div className="row align-items-center my-3">
                        <div className="col-sm-4">
                          <label className="form-label require">
                            Ticket For
                          </label>
                        </div>
                        <div className="col-sm-8">
                          <TapSelect
                            options={this.state.ticketForOption}
                            changeEvent={(selectedOption) => {
                              this.tapSelectChange(
                                selectedOption,
                                "ticketEditForm",
                                "ticket_for",
                                () => {
                                  this.setState({
                                    ticketEditForm: {
                                      ...this.state.ticketEditForm,
                                      site_id: "",
                                      asset_id: "",
                                      employee_id: "",
                                      workstation_id: "",
                                      sub_assembly_id: "",
                                    },
                                    searchedAssembly: null,
                                    searchedAsset: null,
                                    searchedWorksation: null,
                                    searchSite: null,
                                    searchedEmployee: null,
                                  });
                                }
                              );
                            }}
                            value={this.state.ticketForOption.find(
                              (a) =>
                                a.value === this.state.ticketEditForm.ticket_for
                            )}
                            placeholder="Please Select Ticket For"
                            containerHeight="30px"
                            fontSize="93%"
                            isDisabled={allowNdConQty}
                          />
                        </div>
                      </div>
                      {this.state.ticketEditForm.ticket_for ===
                        "sub_assembly" && (
                          <>
                            <div className="row align-items-center my-3">
                              <div className="col-4">
                                <label
                                  htmlFor="site_id"
                                  className="form-label require"
                                >
                                  Sub Assembly
                                </label>
                              </div>
                              <div className="col-8">
                                <InputSubAssemblySearch
                                  placeholder="Please Select Sub Assembly"
                                  changeEvent={(selectedOption) => {
                                    this.setState(
                                      {
                                        searchedAssembly: selectedOption
                                          ? selectedOption
                                          : "",
                                      },
                                      () => {
                                        this.setState({
                                          ticketEditForm: {
                                            ...this.state.ticketEditForm,
                                            site_id:
                                              selectedOption &&
                                                selectedOption.subAssembly
                                                ? selectedOption.subAssembly.site
                                                  .id
                                                : "",
                                            sub_assembly_id: selectedOption
                                              ? selectedOption.value
                                              : "",
                                          },
                                        });
                                      }
                                    );
                                  }}
                                  required={true}
                                  menuPlacement="top"
                                  isMulti={false}
                                  value={
                                    this.state.searchedAssembly &&
                                    this.state.searchedAssembly
                                  }
                                  search_site_id={
                                    this.props?.projectDetails?.site?.id
                                  }
                                  disabled={allowNdConQty}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {this.state.ticketEditForm.ticket_for === "asset" && (
                        <div className="row align-items-center my-3">
                          <div className="col-4">
                            <label
                              htmlFor="site_id"
                              className={[
                                "form-label",
                                this.state.ticketFormData.asset_validation ==
                                  "Y"
                                  ? "require"
                                  : "",
                              ].join(" ")}
                            >
                              Asset
                            </label>
                          </div>
                          <div className="col-8">
                            <InputAssetSearch
                              changeEvent={(selectedOption) => {
                                this.setState(
                                  {
                                    searchedAsset: selectedOption
                                      ? selectedOption
                                      : null,
                                    ticketEditForm: {
                                      ...this.state.ticketEditForm,
                                      asset_id: selectedOption
                                        ? selectedOption.value
                                        : "",
                                      site_id: selectedOption
                                        ? selectedOption.asset.site_id
                                        : "",
                                    },
                                  },
                                  () => { }
                                );
                              }}
                              placeholder="Search Asset"
                              required={
                                this.state.ticketFormData.asset_validation ==
                                  "Y"
                                  ? true
                                  : false
                              }
                              value={
                                this.state.searchedAsset
                                  ? {
                                    ...this.state.searchedAsset,
                                    label:
                                      this.state.searchedAsset.display_label,
                                  }
                                  : null
                              }
                              search_asset_id={this.state.searchedAsset}
                              // disabled={this.state.ticketEditForm.ticket_for === "sub_assembly" && true}
                              search_site_id={
                                this.props?.projectDetails?.site?.id
                              }
                              disabled={allowNdConQty}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.ticketEditForm.ticket_for ===
                        "workstation" && (
                          <div className="row align-items-center my-3">
                            <div className="col-sm-4">
                              <label
                                htmlFor="site_id"
                                className="form-label require"
                              >
                                Workstation
                              </label>
                            </div>
                            <div className="col-8">
                              <InputWorkstationSearch
                                placeholder="Please Select Workstation"
                                changeEvent={(selectedOption) => {
                                  this.setState(
                                    {
                                      searchedWorkstation: selectedOption
                                        ? selectedOption
                                        : "",
                                    },
                                    () => {
                                      this.setState({
                                        ticketEditForm: {
                                          ...this.state.ticketEditForm,
                                          site_id:
                                            selectedOption?.workstation?.site_id,
                                          workstation_id: selectedOption
                                            ? selectedOption.value
                                            : "",
                                        },
                                      });
                                    }
                                  );
                                  // this.tapSelectChange(selectedOption, 'ticketEditForm', 'workstation')
                                }}
                                required={true}
                                menuPlacement="top"
                                isMulti={false}
                                value={
                                  this.state.searchedWorkstation &&
                                  this.state.searchedWorkstation
                                }
                                search_site_id={
                                  this.props?.projectDetails?.site?.id
                                }
                                disabled={allowNdConQty}
                              />
                            </div>
                          </div>
                        )}
                      {this.state.ticketEditForm.ticket_for === "employee" && (
                        <div className="row align-items-center my-3">
                          <div className="col-sm-4">
                            <label
                              htmlFor="site_id"
                              className="form-label require"
                            >
                              Employee
                            </label>
                          </div>
                          <div className="col-8">
                            <InputEmployeeSearch
                              placeholder="Please Select Employee"
                              changeEvent={(selectedOption) => {
                                this.setState(
                                  {
                                    searchedEmployee: selectedOption
                                      ? selectedOption
                                      : "",
                                  },
                                  () => {
                                    this.setState({
                                      ticketEditForm: {
                                        ...this.state.ticketEditForm,
                                        site_id:
                                          selectedOption?.employee?.site_id,
                                        employee_id: selectedOption
                                          ? selectedOption.value
                                          : "",
                                      },
                                      // ticketForSite: selectedOption?.employee?.site_id,
                                    });
                                  }
                                );
                                //this.tapSelectChange(selectedOption, 'ticketEditForm', 'employee_id')
                              }}
                              menuPlacement="top"
                              required={true}
                              value={
                                this.state.searchedEmployee &&
                                this.state.searchedEmployee
                              }
                              search_site_ids={[
                                this.props?.projectDetails?.site?.id,
                              ]}
                              disabled={allowNdConQty}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.ticketEditForm.ticket_for === "site" ||
                        this.state.ticketEditForm.site_id ? (
                        <div className="row align-items-center my-3">
                          <div className="col-4">
                            <label
                              htmlFor="site_id"
                              className="form-label require"
                            >
                              Site
                            </label>
                          </div>
                          <div className="col-8">
                            <TapSelect
                              options={this.state.iam_user_sites}
                              changeEvent={(selectedOption) => {
                                this.tapSelectChange(
                                  selectedOption,
                                  "ticketEditForm",
                                  "site_id"
                                );
                                // this.getTaggedUsers(selectedOption)
                                // this.setState({ allUsers: this.state.allUsers.filter((user) => user.linked_sites.includes(selectedOption.value)) })
                              }}
                              required={true}
                              value={this.state.iam_user_sites.find(
                                (a) =>
                                  a.value == this.state.ticketEditForm.site_id
                              )}
                              isSearchable={true}
                              placeholder="Please Select Site"
                              isDisabled={
                                allowNdConQty ||
                                this.state.ticketEditForm.ticket_for !== "site"
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="row mt-2">
                        <div className="col-md-4">
                          <label className="form-label require">
                            Tagged User
                          </label>
                        </div>
                        <div className="col-md-8">
                          <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Please Select Users"
                            containerHeight="30px"
                            fontSize="93%"
                            options={this.state.allUsers}
                            changeEvent={(selectedOption) =>
                              this.tapSelectChange(
                                selectedOption,
                                "ticketEditForm",
                                "tagged_users"
                              )
                            }
                            value={this.state.allUsers.filter((u) =>
                              this.state.ticketEditForm?.tagged_users?.includes(
                                u.value
                              )
                            )}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.addFormState == "other_detail" && (
                    <>
                      {this.state.allDefectCode &&
                        this.state.allDefectCode.length > 0 ? (
                        <div className="row align-items-center my-3">
                          <div className="col-sm-4">
                            <label className="form-label">Defect Code</label>
                          </div>
                          <div className="col-sm-8">
                            <TapSelect
                              options={this.state.allDefectCode}
                              changeEvent={(selectedOption) =>
                                this.tapSelectChange(
                                  selectedOption,
                                  "ticketEditForm",
                                  "defect_codes"
                                )
                              }
                              value={this.state.allDefectCode.filter((u) =>
                                this.state.ticketEditForm?.defect_codes?.includes(
                                  u.value
                                )
                              )}
                              isSearchable={true}
                              isMulti={true}
                              isClearable={true}
                              placeholder="Select Defect Code"
                              containerHeight="30px"
                              fontSize="93%"
                            />
                          </div>
                        </div>
                      ) : null}
                      {!this.state.onlyDefectCode &&
                        this.state.ticket_allow_tag === "Y" && (
                          <div className="row mt-2">
                            <div className="col-md-4">
                              <label className="form-label">Ticket Tags </label>
                            </div>
                            <div className="col-md-8">
                              <TapSelect
                                changeEvent={(selectedOption) =>
                                  this.tapSelectChange(
                                    selectedOption,
                                    "ticketEditForm",
                                    "tags"
                                  )
                                }
                                options={this.state.ticketTags}
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.ticketTags.filter(
                                  (s) =>
                                    this.state.ticketEditForm.tags &&
                                    this.state.ticketEditForm.tags.includes(
                                      s.value
                                    )
                                )}
                                placeholder="Select Tags"
                              />
                            </div>
                          </div>
                        )}
                      {!this.state.onlyDefectCode &&
                        this.state.ticket_allow_employees === "Y" && (
                          <div className="row my-2">
                            <div className="col-md-4">
                              <label htmlFor="site_id" className="form-label">
                                Employees
                              </label>
                            </div>
                            <div className="col-md-8">
                              <InputEmployeeSearch
                                placeholder="Please Select Employee"
                                changeEvent={(selectedOption) => {
                                  this.setState({
                                    searchedTagEmployee:
                                      selectedOption &&
                                        selectedOption.length > 0
                                        ? selectedOption
                                        : [],
                                  });
                                  this.tapSelectChange(
                                    selectedOption,
                                    "ticketEditForm",
                                    "employees"
                                  );
                                }}
                                menuPlacement="top"
                                // search_site_ids={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                                isMulti={true}
                                value={
                                  this.state.searchedTagEmployee &&
                                    this.state.searchedTagEmployee.length > 0
                                    ? this.state.searchedTagEmployee.map(
                                      (a) => {
                                        a.label = a.label;
                                        return a;
                                      }
                                    )
                                    : []
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="row align-items-center my-3">
                            <div className="col-md-4">
                                <label htmlFor="description" className="form-label ">
                                Description
                                </label>
                            </div>
                            <div className="col-md-8">
                                <textarea
                                id="description"
                                name="description"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.ticketEditForm.description}
                                onChange={(e) => this.formInputHandler(e, "ticketEditForm")}
                                style={{ height: "80px" }}
                                placeholder="Please Add Description"
                                />
                            </div>
                        </div>
                      {!this.state.onlyDefectCode &&
                        this.state.additionalAttributes &&
                        this.state.additionalAttributes.length > 0 ? (
                        <AdditionalAttributeForm
                          additionalAttributes={this.state.additionalAttributes}
                          formState={this.state.ticketEditForm}
                          colSpan="offset-md-1 col-md-8"
                          onAttributeChange={(value, attributeName) => {
                            this.setState(() => ({
                              ticketEditForm: {
                                ...this.state.ticketEditForm,
                                [attributeName]: value,
                              },
                            }));
                          }}
                        />
                      ) : null}
                    </>
                  )}
                  {this.state.addFormState == "mttr" && (
                    <>
                      <div className="row align-items-center my-3">
                        <div className="col-sm-4">
                          <label className="form-label require">
                            Failure Start Time
                          </label>
                        </div>
                        <div className="col-sm-8">
                          <DatePicker
                            selected={
                              this.state.ticketEditForm.failure_start_time
                                ? moment(
                                  this.state.ticketEditForm.failure_start_time
                                ).toDate()
                                : false
                            }
                            onChange={(value, event) => {
                              this.formDateHandler(
                                "failure_start_time",
                                value,
                                "ticketEditForm",
                                "YYYY-MM-DD HH:mm:ss"
                              );
                            }}
                            name="failure_start_time"
                            dateFormat="dd-MMM-yyyy hh:mm a"
                            className="form-control"
                            showMonthDropdown
                            showYearDropdown
                            showTimeSelect
                            timeIntervals={5}
                            timeCaption="Time"
                            autoComplete="off"
                            minDate={
                              ticketData?.start_date &&
                              moment(
                                ticketData.start_date,
                                "YYYY-MM-DD"
                              ).toDate()
                            }
                            required
                            scrollMonthYearDropdown
                            placeholderText="Enter Failure Start Time"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center my-3">
                        <div className="col-sm-4">
                          <label className="form-label">
                            Failure Closing Time
                          </label>
                        </div>
                        <div className="col-sm-8">
                          <DatePicker
                            selected={
                              this.state.ticketEditForm.failure_closing_time
                                ? moment(
                                  this.state.ticketEditForm
                                    .failure_closing_time
                                ).toDate()
                                : false
                            }
                            onChange={(value, event) => {
                              this.formDateHandler(
                                "failure_closing_time",
                                value,
                                "ticketEditForm",
                                "YYYY-MM-DD HH:mm:ss"
                              );
                            }}
                            name="failure_closing_time"
                            dateFormat="dd-MMM-yyyy hh:mm a"
                            className="form-control"
                            showMonthDropdown
                            showYearDropdown
                            showTimeSelect
                            timeIntervals={5}
                            timeCaption="Time"
                            autoComplete="off"
                            minDate={
                              (this.state.ticketEditForm.failure_start_time ||
                                ticketData?.start_date) &&
                              moment(
                                this.state.ticketEditForm.failure_start_time ||
                                ticketData?.start_date,
                                "YYYY-MM-DD"
                              ).toDate()
                            }
                            maxDate={
                              ticketData?.resolved_date &&
                              moment(
                                ticketData.resolved_date,
                                "YYYY-MM-DD HH:mm:ss"
                              ).toDate()
                            }
                            scrollMonthYearDropdown
                            placeholderText="Enter Failure Closing Time"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.setState({
                        ticketEditForm: { ...this.initEditTicketForm },
                      });
                      this.updateTicketModal.hide();
                    }}
                    disabled={this.state.saveFormSubmitting ? true : false}
                  >
                    Close{" "}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.saveFormSubmitting ? true : false}
                  >
                    Save{" "}
                    {this.state.saveFormSubmitting ? (
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };

  addDocumentModalJsx = () => {
    return (
      <div className="modal fade" id="addDocumentModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addAssetModalLabel">
                Add Document
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => this.addDocumentModal.hide()}
              ></button>
            </div>
            {this.state.formDataLoading ? (
              <Loader />
            ) : (
              <form id="addModalForm" onSubmit={this.addTicketDocument}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label require">
                        Document Type
                      </label>
                    </div>
                    <div className="col-sm-7 align-items-center">
                      {this.state.other == "Y" ? (
                        <input
                          name="doc_name"
                          type="text"
                          value={this.state.addDocumentForm.doc_name}
                          className="form-control"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "addDocumentForm")
                          }
                          placeholder="Please enter Type Name"
                          required={true}
                        // autoFocus={true}
                        />
                      ) : (
                        <TapSelect
                          options={this.state.documentTypes}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "addDocumentForm",
                              "doc_name"
                            )
                          }
                          value={this.state.documentTypes.find(
                            (u) =>
                              this.state.addDocumentForm.doc_name == u.value
                          )}
                          isClearable={true}
                          isSearchable={true}
                          placeholder="Select Document Type"
                          autoCompelete="off"
                          required={true}
                          autoFocus={true}
                        />
                      )}
                    </div>
                    <div className="col-sm-2">
                      <input
                        name="other"
                        type="checkbox"
                        value={this.state.other}
                        onChange={(e) => {
                          this.setState({
                            other: this.state.other == "N" ? "Y" : "N",
                          });
                        }}
                        checked={this.state.other == "Y"}
                        className="form-check-input"
                        id="other"
                      />
                      <label className="form-check-label mx-2" htmlFor="other">
                        New{" "}
                      </label>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-3">
                      <label className="form-label">Document Number</label>
                    </div>
                    <div className="col-md-7">
                      <input
                        name="doc_number"
                        type="text"
                        value={this.state.addDocumentForm.doc_number}
                        onChange={(e) =>
                          this.formInputHandler(e, "addDocumentForm")
                        }
                        className="form-control"
                        autoComplete="off"
                        placeholder="Enter Document Number"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-3">
                      <label className="form-label require">Document</label>
                    </div>
                    <div className="col-md-7">
                      <input
                        name="file"
                        type="file"
                        value={this.state.addDocumentForm.file}
                        onChange={(e) => {
                          this.formInputHandler(e, "addDocumentForm");
                          this.handleFileChange(e);
                        }}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Upload Document"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.setState({
                        addDocumentForm: { ...this.initAddDocument },
                      });
                      this.addDocumentModal.hide();
                    }}
                    disabled={this.state.saveFormSubmitting ? true : false}
                  >
                    Close{" "}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.saveFormSubmitting ? true : false}
                  >
                    Save{" "}
                    {this.state.saveFormSubmitting ? (
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };

  addRemarkModalJsx = () => {
    return (
      <div className="modal fade" id="addRemarksModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addRemrk">
                Add Remark
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => this.addRemarksModal.hide()}
              ></button>
            </div>
            {this.state.remark_form_loading ? (
              <Loader />
            ) : (
              <form
                className="bg-white p-2"
                onSubmit={this.submitAddRemarkForm}
              >
                <div className="modal-body">
                  <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                      <label className="form-label require">
                        Ticket Status
                      </label>
                    </div>
                    <div className="col-sm-5">
                      <TapSelect
                        options={this.state.allRemarkStatus}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "addRemarkForm",
                            "status"
                          )
                        }
                        value={this.state.allRemarkStatus.find(
                          (a) => a.value == this.state.addRemarkForm.status
                        )}
                        isSearchable={true}
                        isClearable={false}
                        required={true}
                        placeholder="Please Select Status"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                  </div>
                  <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                      <label className="form-label require">Remarks</label>
                    </div>
                    <div className="col-sm-5">
                      <textarea
                        required={true}
                        name="remarks"
                        value={this.state.addRemarkForm.remarks}
                        onChange={(e) =>
                          this.formInputHandler(e, "addRemarkForm")
                        }
                        className="form-control"
                        style={{ height: "95px" }}
                      />
                    </div>
                  </div>
                  {this.state.remarkFormData.change_asset_status == "Y" ? (
                    <div className="row align-items-center my-3">
                      <div className="col-sm-3">
                        <label className="form-label ">
                          Change Asset Status
                        </label>
                      </div>
                      <div className="col-sm-5">
                        <TapSelect
                          options={this.state.allAssetStatus}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "addRemarkForm",
                              "asset_status"
                            )
                          }
                          value={this.state.allAssetStatus.find(
                            (a) =>
                              a.value == this.state.addRemarkForm.asset_status
                          )}
                          isSearchable={true}
                          isClearable={true}
                          placeholder="New Asset Status"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <div className="mt-0">
                    <input
                      name="send_email_notification"
                      type="checkbox"
                      value="N"
                      onChange={(e) => {
                        let addRemarkForm = this.state.addRemarkForm;
                        addRemarkForm = {
                          ...addRemarkForm,
                          send_email_notification:
                            (addRemarkForm.send_email_notification
                              ? addRemarkForm.send_email_notification
                              : "N") == "Y"
                              ? "N"
                              : "Y",
                        };
                        this.setState({ addRemarkForm });
                      }}
                      className="form-check-input"
                    />
                    <label className="form-check-label mx-2">
                      Send Email Notification
                    </label>
                  </div>
                  <div>
                    <button
                      className="btn btn-secondary mx-2"
                      onClick={() => {
                        this.setState({
                          addRemarkForm: { ...this.initAddRemarkForm },
                        });
                        this.addRemarksModal.hide();
                      }}
                    >
                      Close{" "}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={this.state.saveFormSubmitting ? true : false}
                    >
                      Save{" "}
                      {this.state.saveFormSubmitting ? (
                        <TapIcon.FontAwesomeIcon
                          icon={TapIcon.faSyncAlt}
                          className="fa-spin"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };

  allocationDetailsModalJsx = () => {
    return (
      <div className="modal fade" id="viewAllocationDetailModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchItemModalLabel">
                Allocation Detail{" "}
              </h5>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div className="modal-body">
              <AllocationDetail
                allocationId={this.state.selectedAllocationId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  consumptionDetailsModalJsx = () => {
    return (
      <div className="modal fade" id="viewConsumptionDetailModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchItemModalLabel">
                Consumption Details
              </h5>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div className="modal-body">
              <ConsumptionDetailsModal
                consumptionId={this.state.selectedConsumptionId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  demandDetailsModalJsx = () => {
    return (
      <div className="modal fade" id="viewDemandDetailModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchItemModalLabel">
                Demand Details
              </h5>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div className="modal-body">
              <ItemDemandDetail demandId={this.state.selectedDemandId} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  viewTransactionJsx = () => {
    return (
      <div className="modal fade" id="viewExpenseModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchFundTransferModalLabel">
                Ticket Expense : {this.state.ticketExpenseId}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <ExpenseView
                need_asset_profile="Y"
                expenseId={this.state.ticketExpenseId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <Ax>
        {this.state.view_loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          this.ticketViewJsx()
        )}

        {this.updateTicketModalJsx()}
        {this.addDocumentModalJsx()}
        {this.addRemarkModalJsx()}
        {this.viewTransactionJsx()}
        {this.props.showOtherDetail && this.state.showOtherDetail == "Y" && (
          <Ax>
            {this.allocationDetailsModalJsx()}
            {this.consumptionDetailsModalJsx()}
            {this.demandDetailsModalJsx()}
          </Ax>
        )}
        <ExpenseAddModal
          ref={this.expenseAddRef}
          afterSubmitFormModal={(ticket_id) =>
            this.getTicketExpenseData(1, ticket_id)
          }
          ticketDetail={() =>
            this.getTicketDetail(this.state.ticketData.activity_id)
          }
        />
        <ChangeAssetStatus
          ref={this.ChangeAssetStatusModalRef}
          afterChangeStatus={() => this.getAssetCard()}
        />
        <Expense
          ref={this.logExpenseRef}
          afterDeleteExpense={() => {
            this.getTicketExpenseData(1, this.state?.ticketData?.activity_id);
          }}
        />
      </Ax>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions:
      state.app.acl_info &&
        state.app.acl_info.permissions &&
        state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
    iam_user_sites:
      state.app && state.app.user_sites ? state.app.user_sites : [],
    default_currency:
      state.app && state.app.default_currency
        ? state.app.default_currency
        : "INR",
    group_permission: state.app.acl_info,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  TicketDetail
);
