import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import DatePicker from "react-datepicker";
import Loader from '../../../components/ui/Loader/Loader';
import Status from '../../../components/ui/Status';
import TapIcon from '../../../services/TapIcon';
// import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
// import TapHelper from '../../../services/TapHelper';



const CustomerDetail = forwardRef((props, ref) => {
    
    let {loading, customerDetails}       =   props

    return <>
        {loading || !customerDetails ? <Loader /> : <>
            <div className="tab_content_wrapper">
                <span className="content_heading">Details</span>
            </div>
            <table className="table table-hover table-borderless">
                <tbody>
                    <tr>
                        <td style={{ "width": "34%" }}>Customer Name</td>
                        <th style={{ "width": "66%" }}>{customerDetails.name ? customerDetails.name : "-"}</th>
                    </tr>
                    <tr>
                        <td style={{ "width": "34%" }}>Code</td>
                        <th style={{ "width": "66%" }}>{customerDetails.code ? customerDetails.code : "-"}</th>
                    </tr>
                    <tr>
                        <td style={{ "width": "34%" }}>Status</td>
                        <th style={{ "width": "66%" }}>{customerDetails.status ? <Status color={customerDetails.status_color}>{customerDetails.status_text}</Status> : "-"}</th>
                    </tr>
                    <tr>
                        <td style={{ "width": "34%" }}>Notes</td>
                        <th style={{ "width": "66%" }}>{customerDetails.notes ? customerDetails.notes : "-"}</th>
                    </tr>
                </tbody>
            </table>
            <div className="tab_content_wrapper align-items-center">
                <span className="content_heading">Contact Details</span>
            </div>
            <table className="table table-hover table-bordered my-3 table-sm">
                <thead className="table-secondary" >
                    <tr>
                        <th style={{ width: "5%" }} className='text-center'>S.No</th>
                        <th style={{ width: "30%" }}>Name</th>
                        <th style={{ width: "35%" }}>Email ID</th>
                        <th style={{ width: "25%" }} className='text-center'>Mobile Number</th>
                        <th className='text-center' style={{ width: "15%" }}>Primary Contact</th>
                        {props.performAction && <th style={{ width: "5%" }}>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {customerDetails?.contacts?.length > 0 ? customerDetails?.contacts?.map((c, i) => {
                        return (<tr>
                            <td className='text-center'>{i + 1}</td>
                            <td>{c.name}</td>
                            <td> {c.email ?? "-"}</td>
                            <td className='text-center'> {c.mobile ?? "-"}</td>
                            <td className='text-center'> {c.is_primary == "Y" ? <TapIcon.FontAwesomeIcon color="green" icon={TapIcon.faCheck} /> : ""}</td>
                            {props.performAction && <td className="text-center">
                                {
                                    <>
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        {props.editContact && <li><a className="dropdown-item" role="button" onClick={() => props.editContact(c.id)}>Edit</a></li>}
                                        {props.deletePrimaryContact && <li><a className={`dropdown-item ${customerDetails.contacts.length === 1 ? "disabled" : ""}`} role="button" onClick={() => props.deletePrimaryContact(c.id)}>Delete</a></li>}
                                    </ul>
                                    </>
                                }  
                            </td>}
                        </tr>)
                    })
                        : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                </tbody>
            </table>
        </>}
    </>
});

export default CustomerDetail;