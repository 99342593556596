import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';


class FundTransferAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initFundTransferAddForm        =       {
            wallet_from                     :        '',
            wallet_to                       :        '',
            amount                          :        '',
            transfer_date                   :        '',
            notes                           :        '',
            ref_no                          :        ''
        }

        this.state                        =        {
            saveFormSubmitting            :       false,
            addFundTransferForm           :        {...this.initFundTransferAddForm},
            fundTransferFormData          :        [],
            formDataLoading               :        false,
            allWalletTypes                :        [],
            editFund                      :        false
             };
    }

    componentDidMount() {
       this.getFundFormData()
        if(this.props.location && this.props.location.state && this.props.location.state.id){
           this.updateFundFormData(this.props.location.state.id)
       }
      }

    getFundFormData         =       ()       =>      {
        this.setState({formDataLoading: true, });
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/wallet/fund_transfer/form_data', this.props.access_token, {}, {}, (resp) => {
             if(Object.keys(this.props.match.params).length > 0 && this.props.match.params.id !== undefined ){
                let selectedWalletId = this.props.match.params.id
                let selectedFundTransferTo =  resp.data.wallets.find((s) => s.id == selectedWalletId )
                if(selectedFundTransferTo !== undefined){
                    this.setState({addFundTransferForm : { ...this.state.addFundTransferForm,wallet_from : selectedFundTransferTo.id}})
                }
            }
              this.setState({FormData :    resp.data,
                     allWalletTypes :    resp.data.wallets.map((s) => { return {value: s.id, label: s.wallet_name}})})
                }).then(() => this.setState({formDataLoading: false}));
    }

    updateFundFormData      =      (id)         =>     {
        this.setState({editLoading: true, });
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/wallet/fund_transfer/' + id, this.props.access_token, {}, {}, (resp) => {
           let respData = resp.data
          
            this.setState({addFundTransferForm : {
                            fund_transfer_id :        respData.activity_id,
                            wallet_from     :        respData.wallet_from.id,
                            wallet_to       :       respData.wallet_to.id,
                            amount          :        respData.amount,
                            transfer_date   :        respData.transfer_date,
                            notes           :        respData.notes,
                            ref_no          :        respData.ref_no
                         },editFund:true})
         }).then(() => this.setState({editLoading: false}));
    }

    submitAddFundTransferForm        =    (e)       =>     {
        e.preventDefault()
        if (this.state.editFund === false) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/wallet/fund_transfer', this.props.access_token, {}, { ...this.state.addFundTransferForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                setTimeout(
                    () => { this.props.history.push({ pathname: '/fund_transfer' })}, 
                     1000
                  );
                
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({ saveFormSubmitting: true })
            let params = {...this.state.addFundTransferForm,transfer_date : moment(this.state.addFundTransferForm.transfer_date).format('YYYY-MM-DD')}
            HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/wallet/fund_transfer', this.props.access_token, {}, { ...params}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                setTimeout(
                    () => {setTimeout(
                        () => { this.props.history.push({ pathname: '/fund_transfer' })}, 
                        
                      );}, 
                    1000
                  );
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
    }
   

    closeAddFundTransferScreen      =          ()      =>    {
        this.props.history.goBack();
    }
  
    saveFundTransferFormJsx         =         ()        =>    {
    
        return (<form className="bg-white p-3" onSubmit={this.submitAddFundTransferForm}>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Fund Transfer From</label>
                </div>
                <div className="col-sm-4 ">
                <TapSelect  options={this.state.allWalletTypes.filter(option => option.value !== this.state.addFundTransferForm.wallet_to)}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addFundTransferForm', 'wallet_from')}
                                value={this.state.allWalletTypes.find(a => a.value == this.state.addFundTransferForm.wallet_from)}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                placeholder="Please Select Fund Transfer From"
                                containerHeight="33px"
                                fontSize="93%" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Fund Transfer To</label>
                </div>
                <div className="col-sm-4 ">
                <TapSelect  options={this.state.allWalletTypes.filter(option => option.value !== this.state.addFundTransferForm.wallet_from)}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addFundTransferForm', 'wallet_to')}
                                value={this.state.allWalletTypes.find(a => a.value == this.state.addFundTransferForm.wallet_to)}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                placeholder="Please Select Fund Transfer To"
                                containerHeight="33px"
                                fontSize="93%" />

                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Amount</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="amount"
                        type="text"
                        value={this.state.addFundTransferForm.amount}
                        className="form-control"
                        required={true}
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e,"addFundTransferForm")}
                        placeholder="Enter Amount"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Fund Transfer Date</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addFundTransferForm.transfer_date
                                ? moment(this.state.addFundTransferForm.transfer_date).toDate()
                                : false
                        }
                        name="opening_balance_date"
                        onChange={(value, event) => this.formDateHandler('transfer_date', value, 'addFundTransferForm')}
                        maxDate={moment().toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Date`}

                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                        alt="requisition_date"
                        className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Reference No</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="ref_no"
                        type="text"
                        value={this.state.addFundTransferForm.ref_no}
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e,"addFundTransferForm")}
                        placeholder="Enter Reference Number"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Notes</label>
                </div>
                <div className="col-sm-4 ">
                    <textarea
                        className="form-control"
                        autoComplete="off"
                        name="notes"
                        value={this.state.addFundTransferForm.notes}
                        onChange={(e) => this.formInputHandler(e,"addFundTransferForm")}
                        placeholder="Please Enter Notes"
                        style={{height: "100px"}}
                    />
                </div>
            </div>

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeAddFundTransferScreen} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
              <Helmet><title>Fund Transfer Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>Fund Transfer</h3>
                    <div className="text-end mt15">
                      <button onClick={this.closeAddFundTransferScreen} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                     </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                      {this.state.formDataLoading ? <Loader/> :
                        this.saveFundTransferFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
    };
};

export default connect(mapStateToProps)(FundTransferAdd);