import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';



class VendorAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.addVendorFormInitialState =     { 
            name                        :       '',
            code                        :       '',
            notes                       :       '',
            state_id                    :       null,
            gstin                       :       '',
            address1                    :       '',
            label                       :       '',
            address2                    :       '',
            city                        :       '',
            landmark                    :       '',
            district_name                 :       '',
            pincode                     :       null,
            country_id                  :       99,
            domain                      :       [],
            authorization               :       '',
            operations_state            :       [],
            operations_district         :       []
        }

        this.state                     =     {
            saveFormSubmitting         :     false,
            addVendorForm              :     {...this.addVendorFormInitialState},
            allStateList               :     [],
            allCountryList             :     [],
            allDomainList              :     [],
            allDistrictList            :     [],
            allPincodeList             :     [],
            allOperationsDistrictList   :       [],
            allOperationsStateList      :       [],
            vendorFormDataLoaded       :     false,
            vendorStateListLoaded      :     false,
            editVendor                 :     false,
            contacts                   :     [{name:'',email:'',mobile:'',primary:'Y'}],
            addNewDistrict             :       false,
            addNewPinCode             :       false
        };

        this.stateListUrl   =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl   =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl   =   ORG_API_BASE_URL_2  +   '/pincodes';

    }

    componentDidMount() {
        this.initilaizeFormData()
    }
    
    initilaizeFormData                  =   () =>  {
        this.setState({vendorFormDataLoaded : true})
        const headers                         =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + this.props.access_token};
        Promise.all([
            axios({ method: 'GET', headers: headers, url: INVENTORY_API_BASE_URL_2 + '/vendor' + '/add_form_data'}),
        ])
        .then((results) => {
            let formData = results[0].data
            this.setState({
                allCountryList                  :       formData && formData.country ?  formData.country.map((s) => { return {value: s.id, label: s.name}; }) : [],
                allDomainList                   :       formData && formData.domain ? formData.domain.map((s) => { return {value: s, label: s}}) : []                  
         
            },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.vendorData) {
                     let vendorData = this.props.location.state.vendorData
                    this.setState({
                        addVendorForm         :  {
                            ...this.addVendorFormInitialState,
                            id                          : vendorData.id,
                            name                        :  vendorData.name,
                            code                        :  vendorData.code ? vendorData.code : "",
                            notes                       :  vendorData.notes ? vendorData.notes : "",
                            domain                      :  vendorData.addressList ? vendorData.domain : [],
                            authorization               :  vendorData.addressList ? vendorData.authorization : [],
                            operations_state            :  vendorData.operations_state ? vendorData.operations_state : [],
                            operations_district         :  vendorData.operations_district ? vendorData.operations_district : [],
                        },
                            contacts                    :  vendorData.contacts ? vendorData.contacts : this.state.contacts,
                            editVendor                  :  true
                        
                    }, () => {
                        this.stateChangeHandler(vendorData.addressList[0].state_id)
                    });
                } else {
                    this.setState({ editVendor: false })
                }

                if(this.state.addVendorForm && this.state.addVendorForm.country_id){
                    this.countryChangeHandler(this.state.addVendorForm.country_id)
                }

            });
        }).then(() => { this.setState({ vendorFormDataLoaded: false }) })
    }

    //FUNCTION TO GET STATE LIST ON SELECTING COUNTRY
    countryChangeHandler              =        (selected_country)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    allOperationsStateList      :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    addVendorForm               :    {...this.state.addVendorForm,state_id: '',district_name : '',pincode:''},
                    addNewDistrict              :   false ,
                    addNewPinCode               :   false 
                });
            });
        }
        
    }

    //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
    stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList             :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    addVendorForm               :    {...this.state.addVendorForm,pincode:'',pincode_name:''}
                });
            });
        }
    }

    operationstateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allOperationsDistrictList  :  response.data.map((s) => { return {value: s.id, label: s.name}; })
                });
            });
        }
    }

      //FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT
      districtChangeHandler              =        (selected_district)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            
            let params = {district_name : selectedDistrict,state_id:this.state.addVendorForm.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList              :   response.data.map((s) => { return {value: s.pincode, label: s.pincode}; }),
                    addNewPinCode               :   response.data && response.data.length > 0 ? false : true,
                });
            });
        }
    }

    //****************************CLOSE VENDOR *********************/
    closeEvent              =           ()      =>      {
        if(this.state.editVendor){
            this.props.history.push({pathname: `/vendor`, state: {vendorData : this.props.location.state.vendorData }})
        }else{
            this.props.history.push({pathname: `/vendor`})
        }
    }

    //submit add Vendor Form
    addVendorFormSubmitEvent            =       (e)      =>  {
       e.preventDefault()
       const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        let errors                  =   [];
        if(this.state.addVendorForm.gstin && this.state.addVendorForm.gstin.trim() != '' && !pattern.test(this.state.addVendorForm.gstin.trim())) {
            errors                  =   [...errors, 'Please enter valid GST No.'];
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            toast.error(errorMsg, {position: toast.POSITION.TOP_RIGHT});
        } else {
            this.setState({ saveFormSubmitting: true })
            let frmData;
            if (this.state.editVendor) {
                frmData = { ...this.state.addVendorForm }
            } else {
                frmData = { ...this.state.addVendorForm, contacts: this.state.contacts }
            }
            if (this.state.editVendor) {
                HttpAPICall.withAthorization('PUT', INVENTORY_API_BASE_URL_2 + '/vendor', this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/vendor`, state: {vendorData : this.props.location.state.vendorData }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                HttpAPICall.withAthorization('POST', INVENTORY_API_BASE_URL_2 + '/vendor', this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/vendor`})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }

    saveVendorFormJsx                  =   ()  =>  {
        return (<form className="bg-white p-3" onSubmit={this.addVendorFormSubmitEvent}>
            <div className="tab_content_wrapper">
                <span className="content_heading">Details</span>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Vendor Name</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="name"
                        type="text"
                        className="form-control"
                        required={true}
                        value={this.state.addVendorForm.name}
                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                        autoComplete="off"
                        maxLength="250"
                        placeholder="Enter Vendor Name"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Code</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="code"
                        type="text"
                        className="form-control"
                        value={this.state.addVendorForm.code}
                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                        autoComplete="off"
                        maxLength="250"
                        placeholder="Enter Vendor Code"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Notes</label>
                </div>
                <div className="col-sm-4">
                    <textarea
                        name="notes"
                        className="form-control"
                        value={this.state.addVendorForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                        autoComplete="off"
                        maxLength="250"
                        style={{ height: "60px" }}
                        placeholder="Enter Note"
                    />
                </div>
            </div>
            {this.state.editVendor ? null
                : (<Ax> <div className="tab_content_wrapper">
                    <span className="content_heading">Address</span>
                </div>
                <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label require">Label</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="label"
                                type="text"
                                className="form-control"
                                value={this.state.addVendorForm.label}
                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Label"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Address Line 1</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="address1"
                                type="text"
                                className="form-control"
                                value={this.state.addVendorForm.address1}
                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Line 1"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Address Line 2</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="address2"
                                type="text"
                                className="form-control"
                                value={this.state.addVendorForm.address2}
                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Line 2"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Landmark</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="landmark"
                                type="text"
                                className="form-control"
                                value={this.state.addVendorForm.landmark}
                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Landmark"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">City</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="city"
                                type="text"
                                className="form-control"
                                value={this.state.addVendorForm.city}
                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter City"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Country</label>
                        </div>
                        <div className="col-sm-4">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addVendorForm', 'country_id');
                                    this.countryChangeHandler(selectedOption)
                                 }
                                }
                                options={this.state.allCountryList}
                                isSearchable={true}
                                isClearable={false}
                                value={this.state.allCountryList.find(m => m.value == this.state.addVendorForm.country_id)}
                                placeholder="Please Select Country"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label require">State (Source of Supply)</label>
                        </div>
                        <div className="col-sm-4">
                            <TapSelect
                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                options={this.state.allStateList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allStateList.find(m => m.value == this.state.addVendorForm.state_id)}
                                placeholder="Please Select State"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">District</label>
                        </div>
                        <div className="col-sm-4">
                            {this.state.addNewDistrict
                                ? <input
                                    name="district_name"
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.addVendorForm.district_name}
                                    onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                    maxLength="20"
                                    placeholder="Please Enter District"
                                />
                                : <TapSelect
                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                    options={this.state.allDistrictList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allDistrictList.find(m => m.value == this.state.addVendorForm.district_name)}
                                    placeholder="Please Select District"
                                />}
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="addNewDistrict"
                                type="checkbox"
                                value={this.state.addNewDistrict}
                                onChange={(e) => {
                                    this.setState({ 
                                        addNewDistrict: !this.state.addNewDistrict,
                                        addVendorForm   :   {...this.state.addVendorForm,district_id: '',pincode:''}
                                     })
                                }}
                                checked={this.state.addNewDistrict}
                                className="form-check-input"
                                id="newDistrict"

                            />
                            <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">PIN Code</label>
                        </div>
                        <div className="col-sm-4">
                            {this.state.addNewPinCode
                                ? <input
                                    name="pincode"
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.addVendorForm.pincode}
                                    onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                    maxLength="20"
                                    placeholder="Please Enter PinCode"
                                />
                                : <TapSelect
                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'pincode') }}
                                    options={this.state.allPincodeList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allPincodeList.find(m => m.value == this.state.addVendorForm.pincode)}
                                    placeholder="Please Select PIN Code"
                                />}
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="addNewPinCode"
                                type="checkbox"
                                value={this.state.addNewPinCode}
                                onChange={(e) => {
                                    this.setState({ 
                                        addNewPinCode: !this.state.addNewPinCode ,
                                        addVendorForm   :   {...this.state.addVendorForm,pincode:''}
                                    })
                                }}
                                checked={this.state.addNewPinCode}
                                className="form-check-input"
                                id="newPinCode"

                            />
                            <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">GSTIN</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="gstin"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addVendorForm.gstin}
                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                maxLength="20"
                                placeholder="Please Enter GSTIN Number"
                            />
                        </div>
                    </div>
                    <div className="tab_content_wrapper">
                        <span className="content_heading">Primary Contact Details </span>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Contact Person Name</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="name"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.contacts[0].name}
                                onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                maxLength="200"
                                placeholder="Please Enter Person Name"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Email ID</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="email"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                maxLength="200"
                                required={this.state.contacts[0].name !== "" ? true : false}
                                value={this.state.contacts[0].email}
                                onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                placeholder="Please Enter Person Email ID"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label"> Mobile Number </label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="mobile"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.contacts[0].mobile}
                                required={this.state.contacts[0].name !== "" ? true : false}
                                onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                placeholder="Please Enter Person Mobile Number"
                                pattern="\d*"
                                max="20"
                            />
                        </div>
                    </div>
                </Ax>)}
            <div className="tab_content_wrapper">
                <span className="content_heading">Domain </span>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Vendor Domain</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addVendorForm', 'domain')}
                        options={this.state.allDomainList}
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Please Select Vendor Domain"
                        value={this.state.allDomainList.filter(t => this.state.addVendorForm.domain && this.state.addVendorForm.domain.length > 0 && this.state.addVendorForm.domain.includes(t.value) ? true : false)}
                        isMulti={true}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Authorization</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="authorization"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addVendorForm.authorization}
                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                        placeholder="Please Enter Authorization"
                    />
                </div>
            </div>
            <div className="tab_content_wrapper">
                <span className="content_heading">Area of Operations</span>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">State</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'operations_state'); this.operationstateChangeHandler(selectedOption) }}
                        options={this.state.allOperationsStateList}
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Please Select States"
                        menuPlacement='top'
                        value={this.state.allOperationsStateList.filter(t => this.state.addVendorForm.operations_state.includes(t.value))}
                        isMulti={true}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">District</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'operations_district') }}
                        options={this.state.allOperationsDistrictList}
                        isSearchable={true}
                        isClearable={true}
                        menuPlacement='top'
                        placeholder="Please Select Districts"
                        value={this.state.allOperationsDistrictList.filter(t => this.state.addVendorForm.operations_district.includes(t.value))}
                        isMulti={true}
                    />
                </div>
            </div>

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
          <Helmet><title>Vendor Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.props.location && this.props.location.state && this.props.location.state.vendorData  ? "Edit" : "Add"} Vendor</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       {!this.state.vendorFormDataLoaded ? this.saveVendorFormJsx()
                        : <Loader/> }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(VendorAdd);