import { Modal } from 'bootstrap';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import Loader from '../../../components/ui/Loader/Loader';
import DateService from '../../../services/DateService';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapIcon from '../../../services/TapIcon';
import DataService from '../../../services/DataService';
import TapHelper from '../../../services/TapHelper';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import e from 'cors';

const DualCounterlogModal                  =   forwardRef((props, ref) => {


    let initFormData                        =   {
        loading                                 :   false,
        submitting                              :   false,
        asset_id                                :   null,
        feed_date                               :   null,
        shift_id                                :   null,

        site                                    :   null,
        asset                                   :   null,
        opening_shift                           :   null,
        closing_shift                           :   null,
        available_meters                        :   [],
        previous_cl                             :   null,
        opening_shift_data                      :   null,
        opening_shift_cl                        :   null,
        closing_shift_cl                        :   null,
        opening_cl                              :   null,
        closing_cl                              :   null,
        remarks                                 :   '',

        selected_workstation                    :   null,
        workstation_id                          :   '',

        shift_operator_required                 :   "N",
        shift_operator_track                    :   "N",
        operator_exist                          :   'Y',
        linked_employees                        :   [],
        searched_operators                      :   [],
        operator_ids                            :   [],
    };


    //Edit Modal :-
    const [editModalId, setEditModalId]         =   useState(uuid());
    const editModalElem                         =   useRef(null);
    const editModal                             =   useRef(null);
    const editModalOpen                         =   ()  => editModal.current && editModal.current.show()
    const editModalClose                        =   ()  => editModal.current && editModal.current.hide()
    useEffect(() => {
        editModalElem.current                   =   document.getElementById(editModalId);
        editModal.current                       =   new Modal(editModalElem.current, {keyboard: false, backdrop :false});
        //Cleanup on unmount
        return () => {
            editModal.current && editModal.current.dispose();
        };
    }, []);

    const access_token                      =   useSelector(state => state.auth.access_token);
    const app_setting                       =   useSelector(state => state.app.setting || []);
    const [formData, setFormData]           =   useState({...initFormData});
    const clFormDataAPI                     =   IAM_API_BASE_URL_2 + "/counterlog/dual_shift_form_data";
    const clDataSaveAPI                     =   IAM_API_BASE_URL_2 + "/counterlog/dual_shift_reading";
    

    useImperativeHandle(ref, () => ({
        initModalHandler: (shift_id, date, asset_id) => {
            openCounterLogModalHandler(shift_id, date, asset_id);
        },
        initModalClose: () => {
            editModalClose();
            if(props.afterCounterlogSave) {
                let refreshDates            =   [];
                refreshDates.push(formData.opening_shift.shift_date);
                props.afterCounterlogSave(refreshDates,formData.asset_id);
            }
        }
    }));

    const openCounterLogModalHandler            =   (shift_id, date, asset_id)  =>  {
        //CounterLog Dual Shift Add FormData :-
        counterLogDualShiftFormData(shift_id, date, asset_id);
    }

    const counterLogDualShiftFormData           =   (shift_id, feed_date, asset_id)  =>  {

        //Open Modal :-
        editModalOpen();

        //Call Api to get FormData :-
        let params                  =   {
            shift_id                    :   shift_id,
            feed_date                   :   feed_date,
            asset_id                    :   asset_id,
            get_previous_cl             :   'Y'
        }
        setFormData(pd => ({...pd, ...initFormData, loading : true, ...params }));
        HttpAPICall.withAthorization('GET', clFormDataAPI, access_token, params, {}, (resp) => {
            let respData            =   resp.data;
            let opening_cl          =   {};
            let closing_cl          =   {};
            let opening_shift_data  =   respData.opening_shift_data ? respData.opening_shift_data : null;
            let opening_shift_cl    =   opening_shift_data ? opening_shift_data.meter_display : [];

            respData.available_meters.forEach((meter,k) => {
                let openMeterData       =   opening_shift_cl ? opening_shift_cl.find(r => r.key == meter.key) : null;
                let closeMeterData      =   respData.closing_shift_cl ? respData.closing_shift_cl.find(r => r.key == meter.key) : null;
                opening_cl[meter.key]   =   openMeterData ? openMeterData.reading : '';
                closing_cl[meter.key]   =   closeMeterData ? closeMeterData.reading : '';
            });

            let searched_operators      =   opening_shift_data &&  opening_shift_data.employees.length > 0 ? opening_shift_data.employees : [];
            let linked_employees        =   respData.linked_employees && respData.linked_employees.length > 0 
                                                ? respData.linked_employees 
                                                : [];

            setFormData(pd => ({...pd,
                site                    :   respData.site,
                asset                   :   respData.asset,
                opening_shift           :   respData.opening_shift,
                closing_shift           :   respData.closing_shift,
                available_meters        :   respData.available_meters,
                previous_cl             :   respData.previous_cl,
                opening_shift_cl        :   opening_shift_cl,
                closing_shift_cl        :   respData.closing_shift_cl,
                opening_cl              :   opening_cl,
                closing_cl              :   closing_cl,
                opening_shift_data      :   opening_shift_data,
                remarks                 :   opening_shift_data ? opening_shift_data.shift_remarks : '',

                selected_workstation    :   opening_shift_data ? opening_shift_data.workstation : null,
                workstation_id          :   opening_shift_data && opening_shift_data.workstation ? opening_shift_data.workstation.id : null,


                shift_operator_required :   respData.shift_operator_required,
                shift_operator_track    :   respData.shift_operator_track,
                linked_employees        :   [...linked_employees, ...searched_operators],
                operator_exist          :   opening_shift_data ? opening_shift_data.shift_operator_exist : 'Y',
                searched_operators      :   searched_operators.map(e => {
                                                return { label :    e.display_full_name, value : e.enc_id, employee : e };
                                            }),
                operator_ids            :   opening_shift_data ? opening_shift_data.employees.map(e => e.enc_id) : [],
                 
            }));
        } , (err) => {
            editModalClose();
        } ).then(() => {
            setFormData(pd => ({...pd, loading : false }));
        });
    }

    //2.7.1.11 - CounterLog Dual Shift Add FormData
    const clModalSubmitHandler                  =   (event)  =>  {
        event.preventDefault();
        let data                        =   TapHelper.pick(formData, ['asset_id', 'feed_date', 'shift_id', 'opening_cl', 'closing_cl', 'workstation_id', 'remarks', 'operator_exist', 'operator_ids']);
         
        setFormData(pd => ({...pd, submitting : true }));
        HttpAPICall.withAthorization('POST', clDataSaveAPI, access_token, {}, data, (resp) => {
            let refreshDates            =   [];
            refreshDates.push(formData.opening_shift.shift_date);
            if(formData.opening_shift.shift_date != formData.closing_shift.shift_date) {
                refreshDates.push(formData.closing_shift.shift_date);
            }
            if(props.afterCounterlogSave) {
                props.afterCounterlogSave(refreshDates,formData.asset_id);
            }
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            editModalClose();
        }).then(() => {
            setFormData(pd => ({...pd, submitting : false }));
        });
    }

    const deleteCounterlog      =   (transaction_id)  =>  {
        if(props.deleteCounterlog) {
            props.deleteCounterlog(transaction_id);
        }
    }
    
    return <>
         <div className="modal fade" id={editModalId} tabIndex="-1" ref={editModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Update Counterlog for : {DateService.dateFormat(formData.feed_date)}
                        </h5>
                        <h5 className='text-end modal-title'>Site : {formData.site && formData.site.site_name} </h5>
                    </div>
                    <div className="modal-body py-0">
                        <form name='' id={editModalId + 'Form'} onSubmit={clModalSubmitHandler}>
                            {formData.loading ? <Loader /> : <>
                                <div className='border-bottom bg-light text-sm px-2' style={{marginLeft:"-15px", marginRight:"-15px"}}>
                                    <div className='row py-1'>
                                        <div className='col-sm-3'>Asset Name (Code)</div>
                                        <div className='col-sm-9 fw-bold'>{formData.asset && <>{formData.asset.name} ({formData.asset.asset_code}) </>}</div>
                                    </div>
                                    <div className='row py-1'>
                                        <div className='col-sm-3'>Asset Type</div>
                                        <div className='col-sm-9 fw-bold'>{formData.asset && formData.asset.asset_type_name}</div>
                                    </div>
                                    <div className='row py-1'>
                                        <div className='col-sm-3'>Shift</div>
                                        <div className='col-sm-9 fw-bold'>
                                            {formData.opening_shift && <>
                                                {formData.opening_shift.shift_name}
                                                {" "} 
                                                ({DateService.dateFormat(formData.opening_shift.shift_date)} {formData.opening_shift.shift_start}
                                                {formData.closing_shift && <>{" - "} {DateService.dateFormat(formData.closing_shift.shift_date)} {formData.closing_shift.shift_start}</>})
                                            </>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-3'>
                                    <div className='col-sm-3 form-text text-sm'>Meter (Unit)</div>
                                    <div className='col-sm-3 form-text text-sm text-center'>Previous Counterlog Reading</div>
                                    <div className='col-sm-3 form-text text-sm text-center'>Shift Opening Reading</div>
                                    <div className='col-sm-3 form-text text-sm text-center'>Shift Closing Reading</div>
                                </div>
                                {formData.available_meters.map((meter,i) => {
                                    let prevMeter           =   formData.previous_cl ? formData.previous_cl.find(r => r.key == meter.key) : null;
                                    let opeingMeter         =   formData.opening_shift_cl ? formData.opening_shift_cl.find(r => r.key == meter.key) : null;
                                    let closingMeter        =   formData.closing_shift_cl ? formData.closing_shift_cl.find(r => r.key == meter.key) : null;
                                    return <div key={i} className='row my-2'>
                                        <label className="col-sm-3 text-sm require pt-2"><b>{meter.label}</b> ({meter.unit})</label>
                                        <div className='col-sm-3'>
                                            <input
                                                className="form-control"
                                                value={prevMeter ? prevMeter.reading : ''}
                                                disabled={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                        <div className='col-sm-3'>
                                            <input
                                                className="form-control"
                                                value={formData.opening_cl && formData.opening_cl[meter.key] ? formData.opening_cl[meter.key] : ''}
                                                onChange={(e) => {
                                                    setFormData(pd => ({...pd,
                                                        opening_cl    : {...formData.opening_cl, [meter.key] : e.target.value }       
                                                    }));
                                                }}
                                                required={formData.opening_shift}
                                                disabled={!formData.opening_shift}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                        <div className='col-sm-3'>
                                            <input
                                                className="form-control"
                                                value={formData.closing_cl && formData.closing_cl[meter.key] ? formData.closing_cl[meter.key] : ''}
                                                onChange={(e) => {
                                                    setFormData(pd => ({...pd,
                                                        closing_cl    : {...formData.closing_cl, [meter.key] : e.target.value }       
                                                    }));
                                                }}
                                                required={formData.opening_shift}
                                                disabled={!formData.closing_shift}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                })}
                                <div className='row my-2'>
                                    <label className="col-sm-3 fw-bold text-sm pt-2">Counterlog Date</label>
                                    <div className="col-sm-3 add_calender_section">
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            disabled={true}
                                            value={formData.previous_cl && DateService.dateTimeFormat(formData.previous_cl.find(r => r.key == 'feed_datetime').reading)}
                                        />
                                        <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "5px" }} />
                                    </div>
                                    <div className="col-sm-3 add_calender_section">
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            disabled={true}
                                            value={formData.opening_shift && DateService.dateFormat(formData.opening_shift.shift_date) + " "+ formData.opening_shift.shift_start}
                                        />
                                        <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "5px" }} />
                                    </div>
                                    <div className="col-sm-3 add_calender_section">
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            disabled={true}
                                            value={formData.closing_shift && DateService.dateFormat(formData.closing_shift.shift_date) + " "+ formData.closing_shift.shift_start}
                                        />
                                        <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "5px" }} />
                                    </div>
                                </div>
                                <div className='row my-2'>
                                    <label className="col-sm-3 form-label text-sm pt-2">Remark</label>
                                    <div className="col-sm-9">
                                        <textarea
                                            name="remarks"
                                            value={formData.remarks}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Remark"
                                            onChange={(e) => DataService.handleFormState('remarks',e.target.value, formData, setFormData)}
                                        />
                                    </div>
                                </div>
                                {app_setting && app_setting.enable_workStation_cl && app_setting.enable_workStation_cl == 'Y' && <>
                                    <div className='row my-2'>
                                        <label className="col-sm-3 form-label text-sm pt-2">WorkStation</label>
                                        <div className="col-sm-9">
                                            <InputWorkstationSearch
                                                placeholder="Search Workstation"
                                                menuPlacement="top"
                                                containerHeight="36px"
                                                fontSize="93%"
                                                search_site_id={[formData.site ? formData.site.id : null]}
                                                value={formData.selected_workstation}
                                                changeEvent={(so) => {
                                                    setFormData(d => ({...d, 
                                                        workstation_id      :   so ? so.value : null,
                                                        selected_workstation:   so ? so : null
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>}
                                {formData.shift_operator_track == 'Y' && <div className='row my-2'>
                                    <label className={['col-sm-3 form-label text-sm pt-2', formData.shift_operator_required == "Y" ? 'require' : ''].join(" ")}>Operator Name</label>
                                    <div className="col-sm-9">
                                        <InputEmployeeSearch
                                            placeholder="Please Select Employee"
                                            menuPlacement="top"
                                            containerHeight="36px"
                                            fontSize="93%"
                                            isMulti={true}
                                            disabled={formData.operator_exist == "N"}
                                            required={formData.shift_operator_required == "Y" && formData.operator_exist == 'Y' ? true : false}
                                            search_site_ids={[formData.site ? formData.site.id : null]} 
                                            transaction_date={formData.feed_date ? formData.feed_date : null}
                                            changeEvent={(so) => {
                                                setFormData(d => ({...d, 
                                                    operator_ids : so.map(e => e.value),
                                                    searched_operators : so && so.length > 0 ? so : [] 
                                                }))
                                            }}
                                            value={formData.searched_operators ? formData.searched_operators.map(a => { a.label = a.label; return a; }) : []}
                                            loadDefaultOptions={true}
                                            linkedEmployee={formData.linked_employees}
                                        />
                                        {formData.shift_operator_required =='Y' && <div className="form-check">
                                            <input
                                                id="id_shift_operator_required"
                                                className="form-check-input"
                                                type="checkbox"
                                                value={formData.operator_exist == "Y" ? "N" : "Y"}
                                                checked={formData.operator_exist == "N"}
                                                onChange={(e) => { 
                                                    setFormData(d => ({...d, 
                                                        operator_exist      :   e.target.value,
                                                        operator_ids        :   e.target.value == 'N' ? [] : formData.operator_ids,
                                                        searched_operators  :   e.target.value == 'N' ? [] : formData.searched_operators,
                                                    }));
                                                }}
                                            />
                                            <label className="form-check-label " htmlFor="id_shift_operator_required">Operator Not Available</label>
                                        </div>}
                                    </div>
                                </div>}
                            </>}
                        </form>
                    </div>
                    <div className="border-top p-2">
                        <div className='row'>
                            <div className='col-sm-6 text-start'>
                                {formData.opening_shift_data && <button title='Delete Shift Opening Reading' onClick={() => {
                                    deleteCounterlog(formData.opening_shift_data.transaction_id)
                                }} className='btn btn-sm'><TapIcon.FontAwesomeIcon className='text-danger' icon={TapIcon.faTrashAlt} /></button>}
                            </div>
                            <div className='col-sm-6 text-end'>
                                <button type="button" disabled={formData.submitting || formData.loading} className="btn btn-secondary me-2" onClick={editModalClose}>Close</button>
                                <button form={editModalId + 'Form'} disabled={formData.submitting || formData.loading} type="submit" className="btn btn-primary">Save {formData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
});

export default DualCounterlogModal;