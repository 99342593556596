import React from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL_2 } from '../../../services/TapApiUrls';

class FieldsChangeWithStatus extends AppBaseComponent {
    constructor(props) {
        super(props);

        this.logisticUrl                =   APP_BASE_URL_2 + '/logistic';
        this.logisticUpdateStatusUrl    =   APP_BASE_URL_2 + '/logistic/update_status';

        this.state                      =   {
            logisticFormDataLoaded          :   false,
            addLogisticForm                 :   {},        
            paymentMode                     :   [],
            allStatusOptions                :   [],
            transportersList                :   [],
            cnfAgentsList                   :   [],
            divisionsList                   :   [],
            handlersList                    :   [],
            logistic_steps                  :   [],
            changeStatus                    :   false,
            statusStepFields                :   null,
            addCommentFormSubmitting        :   false,
            logisticStatus                  :   [],
            logisticStatusData          :   {status : this.props.logisticViewData.status ? this.props.logisticViewData.status.id : '', notes : this.props.logisticViewData.notes, id : this.props.logisticViewData.id},
        }

        this.logisticFormDataUrl        =   APP_BASE_URL_2 + '/logistic/form_data';

        this.unitOfMeasurement     =   [
            {value: "Tonne", label: "Tonne"},
            {value: "Kilogram", label: "Kilogram"}
        ]

    }

    componentDidMount () {
        this.initialData();
    }

    initialData = () => {
        const {logisticViewData} = this.props;
        console.log(logisticViewData?.status);
        HttpAPICall.withAthorization('GET', this.logisticFormDataUrl, this.props.access_token, {'logistic_id' : logisticViewData.id, status_level: logisticViewData?.status?.level}, null, (response) => {
            const formData                   =   response.data;
            this.setState({
                logisticStatus              :  formData && formData.status && formData.status.map((s) => { return { value: s.id, label: s.name, level: s.level}}),  
            });
        })

    }

    submitUpdateCommentForm=(e) =>{
        e.preventDefault();

        
        const total_cost    =   this.totalCost();  
        let params          =   {...this.state.logisticStatusData, ...this.state.addLogisticForm, total_cost};
        
        this.setState({addCommentFormSubmitting : true});
        HttpAPICall.withAthorization('PUT', this.logisticUpdateStatusUrl, this.props.access_token, params, null, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.props.getLogisticViewDetails(this.props.logisticViewData.transaction_id);
            this.props.loadListingTblData();
        }).then(()  => this.setState({addCommentFormSubmitting : false}));

    }

    fieldHandleWithStatus   =   (status_level, type)  =>  {
        const { logisticViewData }  =   this.props; 
        console.log(status_level);
        
        if(status_level && ![1,2,3].includes(status_level) && type !== 'Cancel' && logisticViewData.status.name !== type && status_level > logisticViewData.status.level) {
            this.setState({
                changeStatus        :   true,
                addLogisticForm     :   {},
                // change_status_id    :   status_id
            })
            HttpAPICall.withAthorization('GET', this.logisticFormDataUrl, this.props.access_token, {status_handle: "status_handle", status_level : status_level}, null, (response) => {
                const formData      =   response.data;

                // const status_level  =   formData && formData.user_status && formData.user_status.length > 0 ?  (formData.user_status.find((s) => s.id === status_id)).level : null;
                
                this.setState({
                    logistic_steps              :   formData.logistic_steps,
                    statusStepFields            :   formData.logistic_steps && status_level ? formData.logistic_steps.filter(step => step.status_level > logisticViewData.status.level && step.status_level <= status_level) : null,
                    paymentMode                 :   formData.payment_mode,
                    allStatusOptions            :   formData && formData.user_status && formData.user_status.length > 0 ?  formData.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    transportersList            :   formData.transporter.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                    cnfAgentsList               :   formData.cnfAgent.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                    divisionsList               :   formData.division.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                    handlersList                :   formData.handler.map((s) => { return { value: s.id, label: `${s.name}`}}), 
                });
            }).then(() => this.setState({changeStatus : false}));
        }else {
            this.setState({
                statusStepFields    :   null,
                addLogisticForm     :   {},
            });
        }
    }

    formFields = (field) => {
        const {addLogisticForm}     =   this.state;
        const { logisticViewData }  =   this.props; 
        const totalCost             =   this.totalCost();
        return (
            <>
            {
                field ===  "delivery_date_at_godown" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Delivery Date at Godown</label>
                    </div>
                    <div className="col-sm-4 add_calender_section mt-3">
                        <DatePicker
                            selected={
                                addLogisticForm?.delivery_date_at_godown
                                    ? moment(addLogisticForm?.delivery_date_at_godown, 'YYYY-MM-DD').toDate()
                                    : false
                            }
                            name="delivery_date_at_godown"
                            onChange={(value, event) => this.formDateHandler('delivery_date_at_godown', value, 'addLogisticForm')}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete="off"
                            scrollMonthYearDropdown
                            
                            placeholderText={`Enter Delivery Date at Godown`}
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date at Godown" className="add_calender-icon" />
                    </div>
                </>
            }
            {
                field ===  "delivery_date_at_site" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Delivery Date at Site</label>
                    </div>
                    <div className="col-sm-4 add_calender_section mt-3">
                        <DatePicker
                            selected={
                                addLogisticForm?.delivery_date_at_site
                                    ? moment(addLogisticForm?.delivery_date_at_site, 'YYYY-MM-DD').toDate()
                                    : false
                            }
                            name="delivery_date_at_site"
                            onChange={(value, event) => this.formDateHandler('delivery_date_at_site', value, 'addLogisticForm')}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete="off"
                            scrollMonthYearDropdown
                            
                            placeholderText={`Enter Delivery Date at Site`}
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date at Site" className="add_calender-icon" />
                    </div>
                </>
            }
            {
                field ===  "receiver_name" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Receiver Name</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="receiver_name"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.receiver_name}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Receiver Name"
                        />
                    </div>
                </>
            }
            {
                field ===  "type_of_load" && 
                <>
                    <div className="col-sm-2 mt-3 require">
                        <label htmlFor="site_id" className="form-label ">Type of Load</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addLogisticForm', 'load_type');
                            }}
                            options={this.state.internationDataLoad ? this.internationalLoadType : this.domesticLoadType}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            value={
                                this.state.internationDataLoad 
                                ? 
                                this.internationalLoadType.find(s => s.value == addLogisticForm?.load_type)
                                :
                                this.domesticLoadType.find(s => s.value == addLogisticForm?.load_type)
                            }
                            placeholder="Select Load Type"
                        />
                    </div>
                </>
            }
            {/* Consigment Booked */}
            {
                field ===  "transporter_name" && 
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Courier/Transporter Name</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addLogisticForm', 'transporter_id');
                            }}
                            options={this.state.transportersList}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.transportersList.find(s => s.value == addLogisticForm?.transporter_id)}
                            placeholder="Select Courier/Transporter"
                        />
                    </div>
                </>
            }
            {
                field ===  "consigment_number" && 
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Consigment Number</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="consigment_number"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.consigment_number}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Consigment Number"
                        />
                    </div>
                </>
            }
            {
                field ===  "date_of_dispatch" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Date of Dispatch</label>
                    </div>
                    <div className="col-sm-4 add_calender_section mt-3">
                        <DatePicker
                            selected={
                                addLogisticForm?.dispatch_date
                                    ? moment(addLogisticForm?.dispatch_date, 'YYYY-MM-DD').toDate()
                                    : false
                            }
                            name="dispatch_date"
                            onChange={(value, event) => this.formDateHandler('dispatch_date', value, 'addLogisticForm')}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete="off"
                            scrollMonthYearDropdown
                            placeholderText="Dispatch Date"
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Dispatch Date" className="add_calender-icon" />
                    </div>
                </>
            }
            {
                field ===  "expected_delivery_date" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Expected Delivery Date</label>
                    </div>
                    <div className="col-sm-4 add_calender_section mt-3">
                        <DatePicker
                            selected={
                                addLogisticForm?.delivery_date
                                    ? moment(addLogisticForm?.delivery_date, 'YYYY-MM-DD').toDate()
                                    : false
                            }
                            name="delivery_date"
                            onChange={(value, event) => this.formDateHandler('delivery_date', value, 'addLogisticForm')}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete="off"
                            scrollMonthYearDropdown
                            
                            placeholderText={`Enter Delivery Date`}
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date" className="add_calender-icon" />
                    </div>
                </>
            }
            {
                field ===  "booking_place" && 
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Booking Place</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="booking_place"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.booking_place}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Booking Place"
                        />
                    </div>
                </>
            }
            {
                field ===  "vehicle_number" && 
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="vehicle_number" className="form-label ">Vehicle Number</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="vehicle_number"
                            id="vehicle_number"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.vehicle_number}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Vehicle Number"
                        />
                    </div>
                </>
            }
            { 
                field ===  "weight"  &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="weight" className="form-label ">Weight</label>
                    </div>
                    <div className="col-sm-2 mt-3">
                        <input
                            name="weight"
                            id="weight"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.weight}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Weight"
                        />
                    </div>
                    <div className="col-sm-2 mt-3">
                        <TapSelect
                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addLogisticForm', 'unit_of_measurement');}}
                            options={this.unitOfMeasurement} 
                            id="unit_of_measurement"
                            isSearchable={true}
                            isClearable={true}
                            value={this.unitOfMeasurement.find(unit => unit.value === addLogisticForm?.unit_of_measurement)}
                            placeholder="Select Unit"
                        />
                    </div>
                </>
            }
            {
                field === "no_of_boxes" && 
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="no_of_box" className="form-label ">No. of Boxes</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="number_of_boxes"
                            id="no_of_box"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.number_of_boxes}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter No. of Boxes"
                        />
                    </div>
                </>
            }
            {
                field === 'driver_contact_number' &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="driver_contact_number" className="form-label ">Driver Contact Number</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="driver_contact_number"
                            id="driver_contact_number"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.driver_contact_number}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Driver Contact Number"
                        />
                    </div>
                </>   
            }
            {
                field === 'driver_name' &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="driver_name" className="form-label ">Driver Name</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="driver_name"
                            id="driver_name"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.driver_name}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Driver Name"
                        />
                    </div>
                </>   
            }
            {
                field ===  "invoice_number" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="invoice_number" className="form-label ">Invoice Number</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="gst_invoice_number"
                            id="invoice_number"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.gst_invoice_number}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Invoice Number"
                        />
                    </div>
                </>
            }
            {
                field ===  "invoice_date" && 
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="invoice_date" className="form-label ">Invoice Date</label>
                    </div>
                    <div className="col-sm-4 add_calender_section mt-3">
                        <DatePicker
                            selected={
                                addLogisticForm?.invoice_date
                                    ? moment(addLogisticForm?.invoice_date, 'YYYY-MM-DD').toDate()
                                    : false
                            }
                            name="invoice_date"
                            id="invoice_date"
                            onChange={(value, event) => this.formDateHandler('invoice_date', value, 'addLogisticForm')}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete="off"
                            scrollMonthYearDropdown
                            placeholderText={`Enter Invoice Date`}
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Invoice Date" className="add_calender-icon" />
                    </div>
                </>
            }
            {
                field ===  "invoice_value" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="invoice_value" className="form-label ">Invoice Value</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="invoice_value"
                            id="invoice_value"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.invoice_value}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Invoice Value"
                        />
                    </div>
                </>
            }
            {
                field ===  "eway_bill_number" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="eway_bill_number" className="form-label ">Eway Bill Number</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="eway_bill_number"
                            id="eway_bill_number"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.eway_bill_number}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Eway Bill Number"
                        />
                    </div>
                </>
            }
            {
                field ===  "payment_mode" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="payment_mode" className="form-label ">Payment Mode</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <TapSelect
                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addLogisticForm', 'payment_mode');}}
                            options={this.state.paymentMode} 
                            id="payment_mode"
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.paymentMode.find(mode => mode.value == addLogisticForm?.payment_mode)}
                            placeholder="Select Payment Mode"
                        />
                    </div>
                </>
            }
            {
                field ===  "payment_date" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="payment_date" className="form-label ">Payment Date</label>
                    </div>
                    <div className="col-sm-4 add_calender_section mt-3">
                        <DatePicker
                            selected={
                                addLogisticForm?.payment_date
                                    ? moment(addLogisticForm?.payment_date, 'YYYY-MM-DD').toDate()
                                    : false
                            }
                            name="payment_date"
                            id="payment_date"
                            onChange={(value, event) => this.formDateHandler('payment_date', value, 'addLogisticForm')}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete="off"
                            scrollMonthYearDropdown
                            placeholderText={`Enter Payment Date`}
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Payment Date" className="add_calender-icon" />
                    </div>
                </>
            }
            {
                field ===  "material_description" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="material_description" className="form-label ">Machinery/Material Description</label>
                    </div>
                    <div className="col-sm-10 mt-3">
                        <input
                            name="machinery_description"
                            type="text"
                            id="material_description"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.machinery_description}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter Machinery / Material Description"
                        />
                    </div>
                </>
            }
            {
                field ===  "cnf_agent" && logisticViewData.logistic_type !== "Domestic" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="cnf_agent" className="form-label ">CHA Agent</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addLogisticForm', 'cnf_agent_id');
                            }}
                            options={this.state.cnfAgentsList}
                            id="cnf_agent"
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.cnfAgentsList.find(s => s.value == addLogisticForm?.cnf_agent_id)}
                            placeholder="Select CHA Agent"
                        />
                    </div>
                </>
            }
            {
                field ===  "material_handler_name" && logisticViewData.logistic_type !== "Domestic" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="material_handler_name" className="form-label ">Freight Forwarder Name</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addLogisticForm', 'handler_id');
                            }}
                            options={this.state.handlersList}
                            id="material_handler_name"
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.handlersList.find(s => s.value == addLogisticForm?.handler_id)}
                            placeholder="Select Freight Forwarder"
                        />
                    </div>
                </>
            }
            {
                field ===  "boe_number" && logisticViewData.logistic_type !== "Domestic" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="boe_number" className="form-label ">BOE Number</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="boe_number"
                            id="boe_number"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.boe_number}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter BOE Number"
                        />
                    </div>
                </>
            }
            {
                field ===  "logistic_cost" && 
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="logistic_cost" className="form-label ">Freight Forwarding Cost</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="logistic_cost"
                            id="logistic_cost"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.logistic_cost}
                            onChange={(e) => {
                                this.formInputHandler(e, 'addLogisticForm');
                            }}
                            placeholder="Enter Freight Forwarding Cost"
                        />
                    </div>
                </>
            }
            {
                field ===  "cha_cost" && logisticViewData.logistic_type !== "Domestic" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">CHA Cost</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="cha_cost"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.cha_cost}
                            onChange={(e) => {
                                this.formInputHandler(e, 'addLogisticForm');
                            }}
                            placeholder="Enter CHA Cost"
                        />
                    </div>
                </>
            }
            {
                field ===  "delivery_order_cost" && logisticViewData.logistic_type !== "Domestic" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="site_id" className="form-label ">Delivery Order Cost</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="delivery_order_cost"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.delivery_order_cost}
                            onChange={(e) => {
                                this.formInputHandler(e, 'addLogisticForm');
                            }}
                            placeholder="Enter Delivery Order Cost"
                        />
                    </div>
                </>
            }
            {
                field ===  "handling_cost" && 
                <>
                    <div className="col-sm-2 my-3">
                        <label htmlFor="site_id" className="form-label ">Transporter Cost</label>
                    </div>
                    <div className="col-sm-4 my-3">
                        <input
                            name="handling_cost"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={addLogisticForm?.handling_cost}
                            onChange={(e) => {
                                this.formInputHandler(e, 'addLogisticForm');
                            }}
                            placeholder="Enter Transporter Cost"
                        />
                    </div>
                </>
            }
            {
                field ===  "total_cost" && 
                <>
                    <div className="col-sm-2 my-3">
                        <label htmlFor="site_id" className="form-label ">Total Cost</label>
                    </div>
                    <div className="col-sm-4 my-3">
                        <input
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            disabled={true}
                            value={totalCost}
                            placeholder="Enter Total Cost"
                        />
                    </div>
                </>
            }
            </>
        )
    }

    totalCost   =   ()  =>  {
        const handlingCost  =   this.state.addLogisticForm?.handling_cost ? parseFloat(this.state.addLogisticForm.handling_cost) : 0;
        const logisticCost  =   this.state.addLogisticForm.logistic_cost ? parseFloat(this.state.addLogisticForm.logistic_cost) : 0;
        
        let totalCost       =   handlingCost + logisticCost
        if(this.props.logisticViewData?.logistic_type !== "Domestic") {
            const chaCost           =   this.state.addLogisticForm.cha_cost ? parseFloat(this.state.addLogisticForm.cha_cost) : 0;
            const deliveryOrderCost =   this.state.addLogisticForm?.delivery_order_cost ? parseFloat(this.state.addLogisticForm.delivery_order_cost) : 0;

            totalCost       +=  (chaCost + deliveryOrderCost);
        }
        return totalCost;
    }


    render () {
        const { 
            logisticViewData
        }                       =   this.props; 
        let status              =   logisticViewData.status ? logisticViewData.status.name : null;
        const canEdit           =   logisticViewData.can_edit;
        
        const allSteps          =   this.state.statusStepFields && this.state.statusStepFields.map(step => step.steps);

        let dataSteps = [];
        const steps             =   allSteps && allSteps.length > 0 && allSteps.map(step => step && step.map(st => {dataSteps = [...dataSteps, st]}));
        
        return (
            <>
                <div className="tab_content_wrapper mt-4">
                    <span className="content_heading">Comments & History</span>
                </div>
                <form onSubmit={this.submitUpdateCommentForm}>
                    <div className="my-3 row">
                        <label className="col-sm-3 col-form-label">Change status to</label>
                        <div className="col-sm-5">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'logisticStatusData', 'status');
                                    this.fieldHandleWithStatus(selectedOption?.level, selectedOption?.label);
                                }}
                                value={this.state.logisticStatus.find(s => s.value == this.state.logisticStatusData.status)}
                                options={this.state.logisticStatus}
                                isDisabled={(status === "Cancel" || canEdit === "N" || !(this.props.permissions && this.props.permissions.includes('logistic_edit'))) ? true : false}
                                isSearchable={false}
                                placeholder="Please Select Status"
                            />
                        </div>
                    </div>
                   
                {
                    this.state.changeStatus 
                    ? <Loader/>
                    :
                    dataSteps  && dataSteps.length > 0 && dataSteps.map((step, index) => {
                    return (<Ax key={index}>
                        {
                            logisticViewData.logistic_type === "International"
                            ?
                            <div className="tab_content_wrapper mt-2">
                                <span className="content_heading">{step.step_name}</span>
                            </div>
                            :  step.step_name !== "CHA Details" &&
                            <div className="tab_content_wrapper mt-2">
                                <span className="content_heading">{step.step_name}</span>
                            </div>
                        }
                        <div className="row align-items-center my-3">
                        {
                            step.fields && step.fields.length > 0 && step.fields.map((field) => {
                                return (<Ax key={field}>
                                    {this.formFields(field)}
                                </Ax>)
                            })
                        }
                        </div>
                    </Ax>)
                    })
                }
                <div className="my-2">
                    <label className="form-label require">Comments</label>
                    <textarea
                        name="notes"
                        value={this.state.logisticStatusData.notes}
                        onChange={(e) => this.formInputHandler(e, 'logisticStatusData')}
                        className="form-control"
                        placeholder="Please Add a comment"
                        required
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-12 text-end">
                    <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                        Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                </div>
            </form>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(FieldsChangeWithStatus);