import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import style from './Index.module.css';


const DisplaySearchCriteria                  =   forwardRef((props, ref) => {


    return  <>
        {props.searchedElems && props.searchedElems.length > 0 && <div className={[style.filtered_search].join( )}>
            {props.onClearFilter &&  <button type="button" onClick={props.onClearFilter} className={['btn', 'btn-link', style.filter_clear_link].join(' ')}>Clear</button>}
            <ul>
                <li>Search Criteria</li>
                {props.searchedElems.map((ise, k) => <li key={k} className='text-capitalize'><div dangerouslySetInnerHTML={{__html: ise.replace(/(<? *script)/gi, 'illegalscript')}} ></div></li>)}
            </ul>
        </div>}
    </>

});

export default DisplaySearchCriteria;