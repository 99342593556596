import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";

import Ax from "../../../components/hoc/Ax";

import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';

import ApplicationLayout from '../../../layouts/ApplicationLayout';

import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from '../../../services/TapIcon';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import FuelFeedInView from './FuelFeedInView';
import AssetCard from '../../includes/assetCard/AssetCard';
import TapSelect from '../../../components/ui/TapSelect';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import moment from 'moment';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import FuelFeedIn from "../../assets/counterlog/ShiftActivityModals/FuelFeedIn"
import TapIcon from '../../../services/TapIcon';

class FuelFeedInList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.fuelFeedInSearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_quantity'               :   '',
            'search_amount'                 :   '',
            'search_asset_ids'              :   [],
            'asset_group_ids'               :    [],   
            'search_asset_type_ids'         :   [],
            'search_site_ids'               :   [],
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'fuel_transaction_delay'         :   ''
        };

        this.fuelFeedInSearchFormLabel         =   {
            'search_asset_ids'               :   'Asset : ',
            'search_quantity'                :   'Quantity : ',
            'search_amount'                  :   'Amount : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            'search_asset_type_ids'          :   'Asset Type : ',
            'search_site_ids'                :   'Site : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'fuel_transaction_delay'         :   'Transaction Delay : '
        };

        this.state                         =     {  
            minimizeTable                   :       false,   
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            fuelFeedIn_listing              :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            fuelFeedInData                  :       null,
            viewDataLoading                 :       false,
            access_token                    :       '',
            all_periods                     :        [],
            searchedAssets                  :        [],
            iam_asset_types                 :        [],
            iam_group_sites                 :        [],
            all_periods                     :        [],
            fuelFeedInSearchForm            :         {...this.fuelFeedInSearchInitState},
            formSearchedElems               :         [],
            fuelFeedInSearchParams          :         null,
            file_downloading                :         false,
            allTransactionDelay             :         [],
            allAssetTypesGroupsList     :            [],
        };
        this.logFuelFeedInRef              =   React.createRef();



        this.FuelFeedInFormDataUrl      =   IAM_API_BASE_URL_2 + "/counterlog/fuelfeedin_listing_form_data";
        this.FuelFeedInListUrl          =   IAM_API_BASE_URL_2 + "/counterlog/fuelfeedin_listing";
        this.FuelFeedInDetailUrl        =   IAM_API_BASE_URL_2 + "/counterlog/fuelfeedin_detail/_replace_transaction_id";
    }
    
    
    componentDidMount() {
        this.setState({ access_token: this.props.access_token,listing_loading:true });
        // this.loadListingTblData(1);
        setTimeout(() => {
            this.searchFormDataInit(this.props);
        },1000)
        this.fuelFeedInSearchModal = new Modal(document.getElementById('fuelFeedInSearchModal'), { keyboard: false, backdrop: false });
      }


    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,need_asset_profile : 'Y',...this.state.fuelFeedInSearchParams};
        HttpAPICall.withAthorization('GET',  this.FuelFeedInListUrl, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                fuelFeedIn_listing         :           respData.data,
                listingMeta                :           respData.meta,
                totalListingCount          :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        HttpAPICall.withAthorization('GET', this.FuelFeedInFormDataUrl, this.props.access_token, {})
            .then((response) => {
        
                this.setState({
                    iam_asset_types         :       props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                    iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                    all_periods             :       all_periods,
                    allTransactionDelay     :    response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : [],
                    allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                },() => {
                    let ParamObject                 =       new URLSearchParams(this.props.location.search);
              
                    if(ParamObject.get('search_site_ids') || ParamObject.get('entry_date_range') || ParamObject.get('fuel_transaction_delay') ) {
                         let updateFilterForm = {'entry_date_range' : ParamObject.get('entry_date_range'),'fuel_transaction_delay' : ParamObject.get('fuel_transaction_delay') };
                       
                       if(ParamObject.get('search_site_ids')){
                            updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_ids')]
                        }
     
                        setTimeout(() => {
                            this.setState({fuelFeedInSearchForm : {...this.state.fuelFeedInSearchForm,...updateFilterForm}},
                            function(){
                                 this.fuelFeedInSearchFormSubmit();
                                });
                        }, 1)
                      
                     }else{
                        this.loadListingTblData(1)
                     }
                });
            })
        
    }

    fuelFeedInSearchModalInit        =       ()      =>      {
        this.fuelFeedInSearchModal.show()
    }

    viewFuelFeedInDetail           =       (id)        =>      {
        this.setState({minimizeTable : true})
         this.getFuelFeedInDetail(id)
    }

    closeView           =       ()        =>      {
        this.setState({minimizeTable : false})
    }

    getFuelFeedInDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', this.FuelFeedInDetailUrl.replace('_replace_transaction_id', id), this.state.access_token ? this.state.access_token : this.props.access_token, {need_asset_profile : 'Y'}, {}, (response) => {
            this.setState({
                fuelFeedInData: response.data.data
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    fuelFeedInSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems   :   [],
            fuelFeedInSearchParams   :   null,
            searchedAsset       :   null,
            fuelFeedInSearchForm     :   {...this.fuelFeedInSearchInitState}
        }, () => {
            this.fuelFeedInSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    fuelFeedInSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.fuelFeedInSearchForm);
        let searchedElems               =   [];
        let fuelFeedInSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.fuelFeedInSearchFormLabel[key];
            let value                       =   this.state.fuelFeedInSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                fuelFeedInSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_quantity') {
                        show_val            =   this.state.fuelFeedInSearchForm && this.state.fuelFeedInSearchForm.search_quantity ? this.state.fuelFeedInSearchForm.search_quantity : '';
                    }
                    if(key == 'search_amount') {
                        show_val            =   this.state.fuelFeedInSearchForm && this.state.fuelFeedInSearchForm.search_amount ? this.state.fuelFeedInSearchForm.search_amount : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.fuelFeedInSearchForm && this.state.fuelFeedInSearchForm.search_transaction_id ? this.state.fuelFeedInSearchForm.search_transaction_id : '';
                    }
                    if(key == 'fuel_transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.fuelFeedInSearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.fuelFeedInSearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range          =      moment(this.state.fuelFeedInSearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =     moment(this.state.fuelFeedInSearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =     `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            fuelFeedInSearchParams      :       fuelFeedInSearchParams
        }, () => {
            this.fuelFeedInSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    fuelFeedInDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.fuelFeedInSearchParams};
        if(this.state.fuelFeedInSearchParams !== null){
            this.fuelFeedInDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_month"}
                    this.fuelFeedInDownloadStart(params);
                }
            });
        }
    }
    

    fuelFeedInDownloadStart              =    (params)     =>         {
        this.setState({ file_downloading: true});
        HttpAPICall.withAthorization('GET', this.FuelFeedInListUrl, this.props.access_token,{download : "download",...params},{},(response) => {
            download.file(response.data.file_path,response.data.msg,response.data.navigation,this.props);
        })
        .then(() => this.setState({file_downloading: false}));
    }

    deleteFuelFeedInInit               =   (id) =>  {
        this.logFuelFeedInRef.current.deleteFuelFeedInInit(id);
    }


     //***********************FuelFeedIn JSX****************

    fuelFeedInListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Fuel FeedIn List</h3>
                <div className="text-end mt15">
                    <button type="button"  disabled={this.state.listing_loading} onClick={this.fuelFeedInSearchModalInit} className="btn btn-secondary" >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <button type="button" className="btn btn-secondary"
                        disabled={this.state.file_downloading || this.state.listing_loading}
                        onClick={this.fuelFeedInDownloadHandler} >
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                        {this.state.file_downloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3 className="text-capitalize">Fuel FeedIn  : {this.state.fuelFeedInData && this.state.fuelFeedInData.transaction_id ? this.state.fuelFeedInData.transaction_id : '-'}  </h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary ms-1" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                    <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                    <li className='dropdown-item' onClick={() => this.deleteFuelFeedInInit(this.state.fuelFeedInData?.transaction_id)}>Delete</li>
                                </ul>
                                <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.fuelFeedInListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4 bg-white ">{this.state.viewDataLoading ? <Loader /> : <div className='tab_content_header' style={{ height: "80vh" }}>{this.fuelFeedInViewJsx()}</div>}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    fuelFeedInListingTableJsx            =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.fuelFeedInSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Fuel FeedIn</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "10%" }} className='text-center'>Date</th>
                            <th scope="col" style={{ width: "12%" }}> Transaction ID</th>
                            <th scope="col" style={{ width: "15%" }}>Asset</th>
                            <th scope="col" style={{ width: "15%" }}>Site</th>
                            <th scope="col" style={{ width: "15%" }}>Fuel Station</th>
                            <th scope="col" style={{ width: "15%" }}>Fuel Type</th>
                            <th scope="col" style={{ width: "8%" }} className='text-end'>Quantity</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.fuelFeedIn_listing.length > 0
                            ? (this.state.fuelFeedIn_listing.map((FuelFeedIn, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} onClick={() => this.viewFuelFeedInDetail(FuelFeedIn.transaction_id)} >
                                                        <div className="text-capitalize link-primary cursor_pointer">{FuelFeedIn.asset ? FuelFeedIn.asset.name : "-"}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{FuelFeedIn.site && FuelFeedIn.site.site_name ? FuelFeedIn.site.site_name : '-'} </small>
                                                            <small className="float-end">{FuelFeedIn.qty ? <span>{parseInt(FuelFeedIn.qty)} {FuelFeedIn.fuel_unit ?? "-"}</span> : '-'}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-center' >{FuelFeedIn.transaction_date_display ? FuelFeedIn.transaction_date_display.substr(0, FuelFeedIn.transaction_date_display.indexOf(' ')) : "-"}</td>
                                                <td className='link-primary cursor_pointer' onClick={() => this.viewFuelFeedInDetail(FuelFeedIn.transaction_id)} >{FuelFeedIn.transaction_id ? FuelFeedIn.transaction_id : "-"}</td>
                                                <td>{FuelFeedIn.asset ? FuelFeedIn.asset.name : "-"}</td>
                                                <td>{FuelFeedIn.site && FuelFeedIn.site.site_name ? FuelFeedIn.site.site_name : '-'}</td>
                                                <td className='text-capitalize'>{FuelFeedIn.fuelStation && FuelFeedIn.fuelStation.fuelstation_name ? FuelFeedIn.fuelStation.fuelstation_name : FuelFeedIn.other_station ? FuelFeedIn.other_station : "-"}</td>
                                                <td>{FuelFeedIn.fuel_type ? FuelFeedIn.fuel_type : '-'}</td>

                                                <td className='text-end'>{FuelFeedIn.qty ? parseInt(FuelFeedIn.qty) : '-'}</td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewFuelFeedInDetail(FuelFeedIn.transaction_id)}  >View</a></li>

                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    fuelFeedInViewJsx           =       ()      =>      {
        return (<Ax>
            <div className='row'>
                <div className='col-sm-9'>
                    <FuelFeedInView fuelFeedInData={this.state.fuelFeedInData} />
                </div>
                <div className='col-sm-3'>
                    {this.state.fuelFeedInData && this.state.fuelFeedInData.asset_id
                        ? <AssetCard assetId={this.state.fuelFeedInData.asset_id} />
                        : null}
                </div>
            </div>

        </Ax>)
    }

    fuelFeedInSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="fuelFeedInSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="fuelFeedInSearchModalLabel">Fuel FeedIn Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.fuelFeedInSearchFormSubmit} id="fuelFeedInSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "fuelFeedInSearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Fuel Quantity</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_quantity"
                                            className="form-control form-control-sm"
                                            placeholder=" Example. 0-5 for a range or 4 for specific Fuel Quantity"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "fuelFeedInSearchForm")}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Fuel Amount</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_amount"
                                            className="form-control form-control-sm"
                                            placeholder=" Example. 0-5 for a range or 4 for specific Fuel Amount"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "fuelFeedInSearchForm")}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'fuelFeedInSearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type Group</label>
                                    <div className="col-sm-10">
                                    <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    fuelFeedInSearchForm: {
                                                        ...this.state.fuelFeedInSearchForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.fuelFeedInSearchForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'fuelFeedInSearchForm', 'search_asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.fuelFeedInSearchForm.search_asset_type_ids.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Executed Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'fuelFeedInSearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.fuelFeedInSearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Executed Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'fuelFeedInSearchForm', 'fuel_transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.fuelFeedInSearchForm.fuel_transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.fuelFeedInSearchForm.entry_date_range}
                                            startDate={this.state.fuelFeedInSearchForm.entry_date_range_start}
                                            endDate={this.state.fuelFeedInSearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    fuelFeedInSearchForm
                                                        : {
                                                        ...this.state.fuelFeedInSearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.fuelFeedInSearchForm.date_range}
                                            startDate={this.state.fuelFeedInSearchForm.date_range_start}
                                            endDate={this.state.fuelFeedInSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    fuelFeedInSearchForm
                                                        : {
                                                        ...this.state.fuelFeedInSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>FuelFeedIn Listing</title></Helmet>
            {this.fuelFeedInListjsx()}
            {this.fuelFeedInSearchModalJsx()}
            <FuelFeedIn ref={this.logFuelFeedInRef} afterSaveFuelFeedIn={() => {
                this.viewFuelFeedInDetail(this.state.fuelFeedInData.transaction_id)
            }} afterDeleteFuelFeedIn={() => {
                this.setState({minimizeTable : false},() => this.loadListingTblData(1))
            }} />
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],     
    };
};

export default connect(mapStateToProps,null,null,{forwardRef : true})(FuelFeedInList);