import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import swal from "sweetalert";

const download  =   {
    
    file    :   function(file_path,message='',navigation=null,props = {}) {

        // console.log(file_path,message='',navigation);

        if(file_path){
            const link                      =   document.createElement('a');
            link.setAttribute('download', file_path);
            link.href                       =   file_path;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            //saveAs(file_path,file_name)
           // var file_name = file_path.substring(file_path.lastIndexOf('=') + 1);
        }else{
            if(navigation){
                swal({
                    title: "Download Request",
                    text: message,
                    icon: "success",
                    dangerMode: false,
                    showCancelButton: true,
                    confirmButtonText: 'View',
                    cancelButtonText: 'Cancel',
                    buttons: {
                        cancel:'Cancel',
                        confirm: { text: 'View', className: 'bg-green' },
                    },
                    buttonsStyling: false,
                }).then(willNavigate => {
                    if (willNavigate) {
                        props.history.push('/report/download_request')
                    }
                });
            }else{
                toast.success(message, {position: toast.POSITION.TOP_RIGHT});
            }
        }
       
    }
}

export default download;