import React from 'react';
import { connect } from 'react-redux';
import TapIcon from '../../../services/TapIcon';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { DisplayListPagination } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import DateService from '../../../services/DateService';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import QuotationDetailModal from '../quotation/QuotationDetailModal';

class DocumentAndVoucherTabs extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                  =   {
            dealData                    :   props.dealData,

            documentTypes               :   [],

            quotation_loading           :   false,
            quotation_listing           :   [],
            quotationlistingMeta        :   null,
            sales_order_listing         :   [],
            soListingMeta               :   null,
            so_loading                  :   false,
            sales_allocation_listing    :   [],     
            saListingMeta               :   null,
            sa_loading                  :   false,     
            sales_invoice_listing       :   [],
            siListingMeta               :   [],
            si_loading                  :   false,
            quotationtotalListingCount  :   0,
            performAction               :   props.performAction && props.performAction == 'N' ? 'N' : 'Y',

        }

        this.documentsNVoucherBtnRef    =   React.createRef();
        
        // this.documentTypeUrl            =   ORG_API_BASE_URL_2  + '/setting/doc_types';
        // this.addDocumentUrl             =   INVENTORY_API_BASE_URL_2 + '/deal/document/add';
        // this.deleteDocumentUrl          =   INVENTORY_API_BASE_URL_2 + '/deal/document/delete';

        this.quotationListUrl           =   INVENTORY_API_BASE_URL_2 + '/quotation/list';
        this.salesOrderListUrl          =   INVENTORY_API_BASE_URL_2 + '/sales_order/list';
        this.salesAllocationlistingUrl  =   INVENTORY_API_BASE_URL_2 + '/sales/allocations';
        this.salesInvoiceListUrl        =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/list";
    }

    componentDidMount() {
        if((this.props && this.props.dealId) || (this.props && this.props.dealData) ){
            this.initalizeDetailData(this.props)
        }
    
        // this.addDocumentModal           =   new Modal(document.getElementById('addDocumentModal'), {keyboard: false, backdrop :false});         
    }

    initalizeDetailData     =   (pr)    =>  {
        
        let dealData    =  pr.dealData ? pr.dealData : null;
        this.setState({
            dealData : dealData
        },() => {
            // if (this.state.dealData && this.state.dealData.config_data &&  this.state.dealData.config_data.enable_deal_quotation == "Y") {
            //     this.state.dealData.total_quotation && this.state.dealData.total_quotation.voucher_count > 0 && this.getQuotationList(1, dealData.id);
            // } 

            if (this.state.dealData && this.state.dealData.config_data &&  this.state.dealData.config_data.enable_sales_order == "Y") {
                this.state.dealData.total_sales_order && this.state.dealData.total_sales_order.voucher_count > 0 && this.getSalesOrder(1, dealData.id);
            } 

            if (this.state.dealData && this.state.dealData.config_data &&  this.state.dealData.config_data.enable_sales_allocation == "Y") {
                this.state.dealData.total_sales_allocation && this.state.dealData.total_sales_allocation.voucher_count > 0 && this.getSalesAllocation(1, dealData.id);
            } 

            if (this.state.dealData && this.state.dealData.config_data &&  this.state.dealData.config_data.enable_sales_invoice == "Y") {
                this.state.dealData.total_sales_invoice && this.state.dealData.total_sales_invoice.voucher_count > 0 && this.getSalesInvoice(1, dealData.id);
            } 
        });

    }

   

    // Get Sales Order
    getSalesOrder = (page = 1, search_deal_id = "") => {
        this.setState({ so_loading: true });
        let params = {page: page,search_deal_ids: search_deal_id ? [search_deal_id] : []};
        HttpAPICall.withAthorization('GET', this.salesOrderListUrl, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                sales_order_listing: respData.data,
                soListingMeta: {
                    from: respData.from,
                    to: respData.to,
                    total: respData.total,
                    last_page: respData.last_page,
                    per_page: respData.per_page,
                    current_page: respData.current_page
                },
                totalListingCount: respData.total,
            });
        }).then(() => this.setState({ so_loading: false }));
    };

    // Get Sales Allocation
    getSalesAllocation = (page = 1, search_deal_id = "") => {
        this.setState({ sa_loading: true });
        let params = {page: page,search_deal_ids: search_deal_id ? [search_deal_id] : []};
        HttpAPICall.withAthorization('GET', this.salesAllocationlistingUrl, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                sales_allocation_listing: respData.data,
                saListingMeta: {
                    from: respData.from,
                    to: respData.to,
                    total: respData.total,
                    last_page: respData.last_page,
                    per_page: respData.per_page,
                    current_page: respData.current_page
                },
            });
        }).then(() => this.setState({ sa_loading: false }));
    };

    // Get Sales Invoice
    getSalesInvoice = (page = 1, search_deal_id = "") => {
        this.setState({ si_loading: true });
        let params = {page: page,search_deal_ids: search_deal_id ? [search_deal_id] : []};
        HttpAPICall.withAthorization("GET",this.salesInvoiceListUrl,this.props.access_token, params,{},(resp) => {
            let respData = resp.data;
            
            this.setState({
                sales_invoice_listing       : respData.data,
                siListingMeta               : {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page,
                },
                quotationtotalListingCount        : respData.total,
            });
            }
        ).then(() => this.setState({ si_loading: false }));
    };

    
    vouchersJsx     =   ()  =>  {
        let dealData = this.state.dealData;
       
        return (
            <>
            {
                dealData 
                ?
                <>
                {dealData.config_data && dealData.config_data.enable_sales_order && dealData.config_data.enable_sales_order === "Y" && (
                    <Ax>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Sales Order</span>
                        </div>
                        <table className="table table-hover table-bordered table-sm my-2">
                            <thead className="table-secondary">
                                <tr>
                                    <th colSpan={6}>Total Sales Order Amount</th>
                                    <td className="text-end">{dealData.total_sales_order ? dealData.total_sales_order.total_amount : "-"}</td>

                                </tr>
                                <tr>
                                    <th scope="col" style={{ width: "5%" }}>
                                        S.no
                                    </th>
                                    <th scope="col" style={{ width: "15%" }}>
                                        Date
                                    </th>
                                    <th scope="col" style={{ width: "15%" }}>
                                        Transaction ID
                                    </th>
                                    <th scope="col" style={{ width: "25%" }}>
                                        Customer
                                    </th>
                                    <th scope="col" style={{ width: "12%" }}>
                                        Currency
                                    </th>
                                    <th scope="col" style={{ width: "12%" }}>
                                        Amount
                                    </th>
                                    <th scope="col" style={{ width: "16%" }}>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.so_loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : this.state.sales_order_listing &&
                                    this.state.sales_order_listing.length > 0 ? (
                                    <>
                                        {this.state.sales_order_listing.map(
                                            (item, index) => {

                                                return (
                                                    <tr key={index}>
                                                        <td>{this.state.soListingMeta ? this.state.soListingMeta.from + index : index}</td>
                                                        <td>{item.transaction_date ? DateService.dateTimeFormat(item.transaction_date, 'DD-MMM-YYYY') : ""}</td>
                                                        <td>
                                                            {/* <a role="button" className="link-primary text-center" onClick={() => this.viewQuotationDetail(item.transaction_id)}>{item.transaction_id}</a> */}
                                                            {
                                                                item.transaction_id
                                                            }
                                                        </td>
                                                        <td className='text-capitalize'>{item.customer ? <div>{item.customer.name}</div> : "-"}</td>

                                                        <td className="text-center">{item.currency}</td>
                                                        <td className="text-end">{item.currency ? item.currency : "INR"} <CurrencyFormatter currencyCode={item.currency ? item.currency : "INR"} amount={parseFloat(item.total_amount_vc ? item.total_amount_vc : 0).toFixed(2)} />

                                                        </td>
                                                        <td className='text-center'>{item.status ? item.status.name : '-'}</td>

                                                    </tr>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan={7}>
                                            No Records
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <DisplayListPagination
                            meta={this.state.soListingMeta}
                            onPageChange={(e) =>
                                this.getSalesOrder(e.selected + 1)
                            }
                        />
                    </Ax>
                )}
                {dealData && dealData.config_data && dealData.config_data.enable_sales_allocation && dealData.config_data.enable_sales_allocation === "Y" && (
                    <Ax>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Sales Allocation</span>
                        </div>
                        <table className="table table-hover table-bordered table-sm my-2">
                            <thead className="table-secondary">
                                <tr>
                                    <th colSpan={6}>Total Sales Allocation Amount</th>
                                    <td className="text-end">{dealData.total_sales_allocation ? dealData.total_sales_allocation.total_amount : "-"}</td>

                                </tr>
                                <tr>
                                    <th scope="col" style={{ width: "5%" }}>
                                        S.no
                                    </th>
                                    <th scope="col" style={{ width: "15%" }}>
                                        Date
                                    </th>
                                    <th scope="col" style={{ width: "15%" }}>
                                        Transaction ID
                                    </th>
                                    <th scope="col" style={{ width: "25%" }}>
                                        Customer
                                    </th>
                                    <th scope="col" style={{ width: "12%" }}>
                                        Total Qty
                                    </th>
                                    <th scope="col" style={{ width: "12%" }}>
                                        Pending Qty
                                    </th>
                                    <th scope="col" style={{ width: "16%" }}>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sa_loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : this.state.sales_allocation_listing &&
                                    this.state.sales_allocation_listing.length > 0 ? (
                                    <>
                                        {this.state.sales_allocation_listing.map(
                                            (item, index) => {

                                                return (
                                                    <tr key={index}>
                                                        <td>{this.state.saListingMeta ? this.state.saListingMeta.from + index : index}</td>
                                                        <td>{item.transaction_date ? DateService.dateTimeFormat(item.transaction_date, 'DD-MMM-YYYY') : ""}</td>
                                                        <td>
                                                            {/* <a role="button" className="link-primary text-center" onClick={() => this.viewQuotationDetail(item.transaction_id)}>{item.transaction_id}</a> */}
                                                            {
                                                                item.transaction_id
                                                            }
                                                        </td>
                                                        <td className='text-capitalize'>{item.customer ? <div>{item.customer.name}</div> : "-"}</td>
                                                        <td>{item.total_qty}</td>
                                                        <td className="text-center">{item.total_pending_qty}</td>
                                                        <td className='text-center'>{item.status ? item.status.name : '-'}</td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan={7}>
                                            No Records
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <DisplayListPagination
                            meta={this.state.saListingMeta}
                            onPageChange={(e) =>
                                this.getSalesAllocation(e.selected + 1)
                            }
                        />
                    </Ax>
                )}
                
                {/* {
                dealData && dealData.config_data && dealData.config_data.enable_sales_invoice && dealData.config_data.enable_sales_invoice === "Y" && (
                    <Ax>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Sales Invoice</span>
                        </div>
                        <table className="table table-hover table-bordered table-sm my-2">
                            <thead className="table-secondary">
                                <tr>
                                    <th colSpan={6}>Total Sales Invoice Amount</th>
                                    <td className="text-end">{dealData.total_sales_invoice ? dealData.total_sales_invoice.total_amount : "-"}</td>

                                </tr>
                                <tr>
                                    <th scope="col" style={{ width: "5%" }}>
                                        S.no
                                    </th>
                                    <th scope="col" style={{ width: "15%" }}>
                                        Date
                                    </th>
                                    <th scope="col" style={{ width: "15%" }}>
                                        Transaction ID
                                    </th>
                                    <th scope="col" style={{ width: "25%" }}>
                                        Customer
                                    </th>
                                    <th scope="col" style={{ width: "12%" }}>
                                        Total Quantity
                                    </th>
                                    <th scope="col" style={{ width: "12%" }}>
                                        Amount
                                    </th>
                                    <th scope="col" style={{ width: "16%" }}>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.si_loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : this.state.sales_invoice_listing &&
                                    this.state.sales_invoice_listing.length > 0 ? (
                                    <>
                                        {this.state.sales_invoice_listing.map(
                                            (item, index) => {

                                                return (
                                                    <tr key={index}>
                                                        <td>{this.state.siListingMeta ? this.state.siListingMeta.from + index : index}</td>
                                                        <td>{item.transaction_date ? DateService.dateTimeFormat(item.transaction_date, 'DD-MMM-YYYY') : ""}</td>
                                                        <td>
                                                            {item.transaction_id}
                                                        </td>
                                                        <td className='text-capitalize'>{item.customer ? <div>{item.customer.name}</div> : "-"}</td>

                                                        <td className="text-center">{item.total_qty}</td>
                                                        <td className="text-end">
                                                            {item.total_amount}
                                                        </td>
                                                        <td className='text-center'>{item.status_data ? item.status_data.name : '-'}</td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    <tr>
                                        <td className="text-center" colSpan={7}>
                                            No Records
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {
                            this.state.siListingMeta &&
                                <DisplayListPagination
                                    meta={this.state.siListingMeta}
                                    onPageChange={(e) =>
                                        this.getSalesInvoice(e.selected + 1)
                                    }
                                />
                        }

                    </Ax>
                )
                } */}
                </>
                : null     
            }
            
            </>
        )
    }

    render          = ()  =>  {
        return (<> 
          {this.vouchersJsx()}
        </>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DocumentAndVoucherTabs);