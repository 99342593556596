import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import TapApiUrls from "../../../services/TapApiUrls";
import { r } from "react-select/dist/index-4bd03571.esm";
import { toast } from "react-toastify";
import InputItemSearch from "../../includes/ui/InputItemSearch";

class ItemQuickNavigation extends AppBaseComponent {

    screenOptions                   =   [
        {value:"details",label:"Details"},
        {value:"inventory",label:"Inventory Level"},
        {value:"interchangeable",label:"Interchangeable Items"},
        {value:"configuration",label:"Configuration"},
        {value:"additional_attribute",label:"Additional Attributes"},
        {value:"document",label:"Document"},
      ];

    constructor(props) {
        super(props);

        this.state                      =     {
            searchedItem              :       null,
            selectedScreen              :       null,
            itemSearch                 :       {
                item_id                 :       null
            }
        }
    }

    componentDidMount(){
        if(localStorage.getItem('selectedItemScreenValue') !== null){
         
            this.setState({selectedScreen : {value : localStorage.getItem('selectedItemScreenValue'),label : localStorage.getItem('selectedItemScreenLabel')}})
        }
    }
   
    onScreenChange = selectedOptions => {
        this.setState({
            selectedScreen:selectedOptions
        })
    }


    onNavigationSubmit          =       (e)     =>      {
        e.preventDefault();
        localStorage.setItem('selectedItemScreenValue', this.state.selectedScreen?.value);
        localStorage.setItem('selectedItemScreenLabel', this.state.selectedScreen?.label);
        let obj = {item_name : this.state.searchedItem && this.state.searchedItem.itemdata ? this.state.searchedItem.itemdata.name : "", item_category : this.state.searchedItem && this.state.searchedItem.itemdata ? this.state.searchedItem.itemdata.category : ""}
        if(this.props){
            this.props.props.history.push({ pathname: `/item_list/${this.state.selectedScreen?.value}/${this.state.itemSearch.item_id}`, search: "?" + new URLSearchParams(obj).toString()})
        }else{
            toast.error("Something went Wrong", { position: toast.POSITION.TOP_RIGHT });
        }
    }

    render                      =   ()  =>  {
       
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold primary_color">QUICK NAVIGATION - ITEM</h6>
                    </div>
                </div>
                <form onSubmit={this.onNavigationSubmit}>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <InputItemSearch
                                required={true}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'itemSearch', 'item_id');
                                    this.setState({
                                        searchedItem: selectedOption ? selectedOption : null
                                    }, () => {
                                    });
                                }}
                                value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-9 pr0">
                            <TapSelect
                                isSearchable={true}
                                isClearable={false}
                                changeEvent={(selectedOption) => this.onScreenChange(selectedOption)}
                                value={this.state?.selectedScreen}
                                placeholder="Please Select Screen"
                                options={this.state.searchedItem && this.state.searchedItem.itemdata.category == "services" ? this.screenOptions.filter(s => s.value !== "inventory") : this.screenOptions}
                                required={true}
                            />
                        </div>
                        <div className="col-sm-3 text-end">
                            <button type="submit" className="btn btn-primary" style={{ height: "38px" }}>Go</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        );
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(ItemQuickNavigation);