import React from "react";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import { INVENTORY_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import { Helmet } from "react-helmet";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import TapIcon from "../../../services/TapIcon";
import { Link } from "react-router-dom";
import Loader from "../../../components/ui/Loader/Loader";
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from "../../../components/TapUi";
import DownloadFile from "../../../services/DownloadFile";
import { Collapse } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import moment from "moment";
import InputItemSearch from "../../includes/ui/InputItemSearch";
import CurrencyFormatter from "../../../services/CurrencyFormatter";

class StockMovementItemReport extends AppBaseComponent {

    constructor(props) {
        super(props);

        // this.warehouses_id          =   [];
        // this.date_range             =   'current_quarter';

        this.reportFilterFormInitialState   =   {
            search_site_ids                     :   [],
            search_warehouse_ids                :   [],
            date_range                          :   'current_quarter',
            date_range_start                    :   '',
            date_range_end                      :   '',
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_manufacturer_ids             :   [],
            search_item_code                    :   '',
            item_category                       :   'ignore_zero_cb',

        };

        this.reportFilterFormLabel          =   {
            search_warehouse_ids                :   'Warehouse : ',
            date_range                          :   'Date Range : ',
            search_item_ids                     :   'Items : ',
            search_item_tags                    :   'Item Tags : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            search_site_ids                     :   'Site : ',
            search_item_code                    :   'Item Code : ',
            item_category                       :   'Category : ',
        };

        this.state          =   {
            listing_loading             :   false,
            reportDownloading           :   false,
            listingMeta                 :   null,
            listingData                 :   [],
            allWarehouses               :   [],
            allPeriods                  :   [],
            allItemTags                 :   [],
            manufacturers               :   [],
            reportFilterForm            :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm   :   {...this.reportFilterFormInitialState},
            iam_user_sites              :   [],
            transactions                :   [],
            download_access             :   false,     
            reportData                  :   null               
        }

        this.stockItemUrl               =   INVENTORY_API_BASE_URL_2 + '/report/stock-movement-item-wise';
    }

    componentDidMount           ()  {  
        setTimeout(() => {
            this.initializeData(this.props)
        },1000)
    }

    initializeData          (pr)  {
        
        const report_name   =   pr?.location?.pathname ? pr.location.pathname.split('/')[2] : '';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];

        let date_range      =   this.date_range;
        let ParamObject     =   new URLSearchParams(pr.location.search);
        

        HttpAPICall.withAthorization('GET', this.stockItemUrl, this.props.access_token, {action:"formData"}, {}, (res) => {
            this.setState({
                reportData                  :   reportData.length > 0 ? reportData[0] : null,
                download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                manufacturers   :   res.data && res.data.manufacturers && res.data.manufacturers.length > 0  ? res.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                transactions    :   res.data && res.data.transactions && res.data.transactions.length > 0  ? res.data.transactions.map((t) => { return {value: t.key, label: t.value}}) : [],
                allWarehouses   :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                allPeriods      :   pr.all_periods,
                allItemTags     :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                iam_user_sites  :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
               
            }, ()=>{
                let updateFilterForm = {};
               if(ParamObject.get('date_range') || ParamObject.get('warehouse_id') || (pr.location && pr.location.state && pr.location.state.warehouses_id) || (pr.location && pr.location.state && pr.location.state.date_range) ){
                if (ParamObject.get('date_range')) {
                    date_range = ParamObject.get('date_range');
                    let period = pr.all_periods.find(p => p.key == ParamObject.get('date_range'));
                    let startDate = period && period.dateFromYmd && moment(period.dateFromYmd).format('YYYY-MM-DD');
                    let endDate = period && period.dateToYmd && moment(period.dateToYmd).format('YYYY-MM-DD')

                    updateFilterForm['date_range']  = ParamObject.get('date_range');
                    updateFilterForm['date_range_start']  = ParamObject.get('date_range') && ParamObject.get('date_range') !== "custom" ? startDate : ParamObject.get('date_range_start');
                    updateFilterForm['date_range_end']  =ParamObject.get('date_range') && ParamObject.get('date_range') !== "custom" ? endDate : ParamObject.get('date_range_end');
                       
            
                }
                if (pr.location && pr.location.state && pr.location.state.warehouses_id) {
                    updateFilterForm['search_warehouse_ids']      =   pr.location.state.warehouses_id;
                   
                }
                if (pr.location && pr.location.state && pr.location.state.date_range) {
                    updateFilterForm['date_range']      =   pr.location.state.date_range;
                } 
        
                if(ParamObject.get('warehouse_id')) {
                    updateFilterForm['search_warehouse_ids']      =   [parseInt(ParamObject.get('warehouse_id'))]
                    
                }
                
                this.setState({
                         reportFilterForm   :   {...this.reportFilterFormInitialState,...updateFilterForm},
                     },() => {this.reportFilterFormSubmit()})
               
               }else{
                 this.setState({
                         reportFilterForm   :   {...this.reportFilterFormInitialState,date_range:"current_quarter"},
                     },() => {this.reportFilterFormSubmit()})
                
               }
                
            });
        }, (err) => {});

    }    

    loadListData            =       (page = 1)  =>  {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloading           :   false
        });
        let params                      =   {page:page, ...this.state.submittedReportFilterForm, action:"data"};
        HttpAPICall.withAthorization('GET', this.stockItemUrl, this.props.access_token, params, {}, (res) => {
            let respData                =   res.data;
            this.setState({
                listingData                 :   respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    closeReport         =   ()  =>  {
        this.props.history.push('/report')
    }

    reportDownload          =   ()  =>  {
        this.setState({
            reportDownloading   :   true,
        });
        HttpAPICall.withAthorization('GET', this.stockItemUrl, this.props.access_token, {action : 'download',...this.state.submittedReportFilterForm} , {} , (res) => 
        DownloadFile.file(res.data.file_path))
        .then(() => this.setState({reportDownloading: false}));
    }

    navigateToStockTransactionLedger    =   (i)  =>  {
        if(i && i.item_id && this.state.submittedReportFilterForm.search_warehouse_ids.length == 1) {
            let state_data          =   {
                item_id                 :   i.item_id, 
                item_name               :   i.name,
                date_range              :   this.state.submittedReportFilterForm.date_range,
                date_range_start        :   this.state.submittedReportFilterForm.date_range_start,
                date_range_end          :   this.state.submittedReportFilterForm.date_range_end,
                warehouses_id           :   this.state.submittedReportFilterForm.search_warehouse_ids[0]
            };
            this.props.history.push({pathname: "/report/item_stock_transaction_ledger", state : state_data  });
        }
    }

    stockMovementItemList          =  ()  =>   {
        return (
          <Ax>
            <table className="table table-bordered table-sm align-middle bg-white mt-2">
              <thead className="align-middle table-secondary">
                <tr>
                  <th className="text-center" style={{ width: "25%" }}>Item</th>
                  <th className="text-center" style={{ width: "15%" }}>Item Code</th>
                  <th className="text-center" style={{ width: "10%" }}>Opening Qty</th>
                  <th className="text-center" style={{ width: "10%" }}>Receive Qty</th>
                  <th className="text-center" style={{ width: "10%" }}>Issue Qty</th>
                  <th className="text-center" style={{ width: "10%" }}>Balance Qty</th>
                  <th className="text-center" style={{ width: "10%" }}>Total Amount</th>
                  <th className="text-center" style={{ width: "10%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.listing_loading ? (
                  <tr>
                    <td className="text-center" colSpan="7">
                      <Loader />
                    </td>
                  </tr>
                ) : this.state.listingData.length > 0 ? (
                  this.state.listingData.map((i, key) => {
                    return (
                      <tr key={key}>
                        <td>{i.name}</td>
                        <td>{i.item_code}</td>
                        <td className="text-end">{i.opening_qty}</td>
                        <td className="text-end">{i.receive_qty}</td>
                        <td className="text-end">{i.issue_qty}</td>
                        <td className="text-end">{i.balance_qty}</td>
                        <td className="text-end"><CurrencyFormatter currencyCode={this.props.default_currency} amount={i.balance_amount}/></td>
                        <td className="text-center">
                            <button onClick={() => this.navigateToStockTransactionLedger(i)} className={['btn btn-link text-decoration-none', (this.state.submittedReportFilterForm.search_warehouse_ids && this.state.submittedReportFilterForm.search_warehouse_ids.length==1) ? '' : 'disabled-link' ].join(" ")}>View Detail</button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan="8">No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Ax>
        );
    };

    reportFilterhandler         =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_item_code') {
                        show_val            =   this.state.reportFilterForm.search_item_code;
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.allWarehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'item_category') {
                        show_val            =   this.state.transactions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                       
                        let  start_range        =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range           =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY');
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`;
                        show_val                =     this.state.allPeriods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.loadListData(1);
        });
    }

    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse" >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                                placeholder="Search Item"
                                isMulti={true}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                    this.setState({
                                        searchedItem: selectedOption ? selectedOption : null
                                    }, () => { });
                                }}
                                value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end"> Item Code</label>
                    <div className='col-sm-6'>
                        <input
                            type="text"
                            name="search_item_code"
                            value={this.state.reportFilterForm.search_item_code}
                            className="form-control"
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            placeholder="Item Code"
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                                options={this.state.allItemTags}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                                value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                                isSearchable={true}
                                isMulti={true}
                                isClearable={true}
                                placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                                options={this.state.manufacturers}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                                }}
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.manufacturers.filter(s => this.state.reportFilterForm?.search_manufacturer_ids?.includes(s.value))}
                                placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm?.search_site_ids?.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allWarehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.state.allWarehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Category</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.transactions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'item_category')}
                            value={this.state.transactions.filter(u => this.state.reportFilterForm?.item_category?.includes(u.value))}
                            isSearchable={true}
                            placeholder="Select Category"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.allPeriods ? this.state.allPeriods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range: period,
                                        date_range_start: startDate ? startDate : null,
                                        date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}
                            required={true}
                        />
                    </div>
                </div>  
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }

    pageTitleBar            =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><TapIcon.FontAwesomeIcon icon={TapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    <button type="button"  className="btn btn-secondary" onClick={this.reportFilterhandler}>Change Filter</button>
                    <button disabled={this.state.reportDownloading || !this.state.download_access} type="button" onClick={this.reportDownload} className="btn btn-primary"><TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>
                    
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }

    render          =   ()  =>  {
        return (
          <ApplicationLayout>
            <Helmet>
              <title>Item wise Inventory Movement Details - Report</title>
            </Helmet>
            {this.pageTitleBar()}
              <div className="container-fluid pl5">
                <div className="page_containt row">
                  <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.reportFilterFormJsx()}
                    <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}/>
                    {this.stockMovementItemList()}
                    { this.state.listingMeta && <DisplayListPagination  meta={this.state.listingMeta} onPageChange={(e) => this.loadListData(e.selected + 1)}/> }
                  </div>
                </div>
              </div>
          </ApplicationLayout>
        );
    }

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        all_periods             :   state.app && state.app.all_periods ? [...state.app.all_periods , {key : 'custom' , display : 'Custom Date Range'}] : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(StockMovementItemReport);