import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import LogisticDetail from './LogisticDetail';
import TapSelect from '../../../components/ui/TapSelect';
import DateService from '../../../services/DateService';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import InputPurchaseSearch from '../../includes/ui/InputPurchaseSearch';
import InputPurchaseOrderSearch from '../../includes/ui/InputPurchaseOrderSearch';
import InputInventoryTransferSearch from '../../includes/ui/InputInventoryTransferSearch';

class LogisticList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initLogisticSearchForm         =   {
            search_like_transaction_id               :   '',
            date_range                          :   null,
            date_range_start                    :   null,
            date_range_end                      :   null,
            search_warehouse_ids                :   [],
            search_vendor_ids                   :   [],
            search_status_ids                   :   [],
            search_division_id                  :   null,
            search_transporter_id               :   null,
            search_cnf_agent_id                 :   null,
            search_handler_id                   :   null,
            search_customer_id                  :   null,
            search_eway_bill_number             :   null,
            search_consigment_number            :   null,
            search_invoice_number               :   null,
            search_logistic_type                :   null,
            search_load_types                   :   [],
            search_purchase_transaction_ids     :   [],
            search_purchase_order_transaction_ids   :   [],
            search_inv_transfer_transaction_ids     :   [],
            search_tagged_users                 :   [],
        }

        this.logisticSearchFormLabel        =   {
            search_like_transaction_id          :   'Transaction ID : ',
            date_range                          :   'Date Range : ',
            search_warehouse_ids                :   'Warehouse : ',
            search_vendor_ids                   :   'Vendor : ',
            search_status_ids                   :   'Status : ',
            search_division_id                  :   'Division : ',
            search_transporter_id               :   'Transporter : ',
            search_cnf_agent_id                 :   'CHA Agent : ',
            search_handler_id                   :   'Handler : ',
            search_customer_id                  :   'Customer : ',
            search_eway_bill_number             :   'Eway Bill Number : ',
            search_invoice_number               :   'Invoice Number : ',
            search_consigment_number            :   'Consigment Number : ',
            search_logistic_type                :   'Logistic Type : ',
            search_purchase_transaction_ids     :   'Purchase Transaction Ids : ',
            search_purchase_order_transaction_ids   :   'Purchase Order Transaction Ids : ',
            search_inv_transfer_transaction_ids     :   'Inventory Transaction Ids : ',
            search_tagged_users                 :   'Tagged Users : ',
            search_load_types                    :   'Load Types : '
        }

        this.state                          =   {
            minimizeTable                       :   false,
            listing_loading                     :   false,
            view_loading                        :   false,
            download_loading                    :   false,
            listing_tbl_page                    :   1,
            logistic_listing                    :   [],
            listingMeta                         :   null,
            totalListingCount                   :   0,
            logisticViewData                    :   [],
            logisticViewHeader                  :   '',
            all_periods                         :   [],
            all_warehouses                      :   [],
            logisticStatus                      :   [],
            searchedVendor                      :   [],
            searchedPurchase                    :   [],
            searchedPO                          :   [],
            searchedInv                         :   [],
            searchedCustomer                    :   null,
            handlersList                        :   [],
            transportersList                    :   [],
            divisionsList                       :   [],
            cnfAgentsList                       :   [],
            logisticSearchForm                  :   {...this.initLogisticSearchForm},
            submittedLogisticFilterForm         :   {...this.initLogisticSearchForm},
            formSearchedElems                   :   [],
            pdfNeedComment                      :   'N',
            logisticType                        :   [
                { value: 'International', label: 'International'},
                { value: 'Domestic', label: 'Domestic'}
            ],
            logistic_steps                      :   [],
            allUsers                            :   [],
            logisticConfig                      :   null,
        };

        this.logisticUrl                    =   APP_BASE_URL_2 + '/logistic';
        this.logisticFormDataUrl            =   APP_BASE_URL_2 + '/logistic/form_data';
        this.logisticListUrl                =   APP_BASE_URL_2 + '/logistic/list';
        this.logisticDetailUrl              =   APP_BASE_URL_2 + '/logistic/detail';
        this.myRef                          =   React.createRef();

        this.loadType   =   [      
            {value: 'FULL TRUCK CONSIGNMENT', label: 'FULL TRUCK CONSIGNMENT'},
            {value: 'PARTIAL CONSIGNMENT', label: 'PARTIAL CONSIGNMENT'},
            {value: 'COURIER CONSIGNMENT', label: 'COURIER CONSIGNMENT'},
            {value: 'AIR CARGO', label: 'AIR CARGO'},
            {value: 'SEA CARGO', label: 'SEA CARGO'}
        ];

    }

    componentDidMount() {
        this.initializeData(this.props);
        this.logisticSearchModal         =    new Modal(document.getElementById('logisticSearchModal'), {keyboard: false, backdrop :false});
        this.customPDFDownloadModal      =    new Modal(document.getElementById('customPDFDownloadModal'), {keyboard: false, backdrop :false});
    }

    initializeData     =      (pr)      =>      {
        HttpAPICall.withAthorization('GET', this.logisticFormDataUrl, this.props.access_token, null, null, (response) => {
            const formData                   =   response.data;
            this.setState({
                all_warehouses              :   pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                all_periods                 :   [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}],
                paymentMode                 :   formData.payment_mode,
                logisticStatus              :   formData.user_status.map((s) => { return { value: s.id, label: s.name}}),  
                transportersList            :   formData.transporter.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                cnfAgentsList               :   formData.cnfAgent.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                divisionsList               :   formData.division.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                handlersList                :   formData.handler.map((s) => { return { value: s.id, label: `${s.name}`}}),    
                logistic_steps              :   formData.logistic_steps,    
                allUsers                    :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name}}) : [],    
            });
        })
        if(this.state.logistic_listing.length === 0) {
            this.loadListingTblData(1);
        }
    }

    //************************GET Logistic LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {...this.state.submittedLogisticFilterForm,page:page};
        HttpAPICall.withAthorization('GET', this.logisticListUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                logistic_listing    :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    openAddLogisticScreen   =   ()  => {
        this.props.history.push('/logistic_add');   
    }

    viewLogisticDetail              =   (transaction_id)    =>  {
        if (transaction_id) {
            this.setState({ minimizeTable: true })
            this.getLogisticViewDetails(transaction_id)
        }
    }

    getLogisticViewDetails          =   (transaction_id)    =>  {
        this.setState({view_loading : true});
        let params                  =   {need_documents : "Y", need_commet : "Y"}; 
        HttpAPICall.withAthorization('GET', this.logisticDetailUrl + '/' + transaction_id, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                logisticViewData        :   respData.data,
                logisticViewHeader      :   respData.data.transaction_id,
            });
           
        },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({view_loading: false}));
    }    

    logisticViewJsx                =   ()   =>   {
        // let site_transaction_count = siteTransitionList ?  siteTransitionList.length : 0;
        return ( <LogisticDetail closeView={this.closeView} logisticViewData={this.state.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.getLogisticViewDetails(transaction_id)} logistic_steps={this.state.logistic_steps} loadListingTblData={this.loadListingTblData}/> ) 
    }

    closeView                       =   () => {
        this.setState({
            minimizeTable   : false
        });
    }

    logisticSearchClear             =   () => {
        this.setState({
            formSearchedElems             :   [],
            searchedVendor                :   [],
            logisticSearchForm            :   {...this.initLogisticSearchForm},
            submittedLogisticFilterForm   :   {...this.initLogisticSearchForm}
        }, () => {
            this.loadListingTblData(1);
            this.logisticSearchModal.hide();
        });
    }

    logisticDownload                =   ()  => {

        const date_range            =   this.state.submittedLogisticFilterForm.date_range ? this.state.submittedLogisticFilterForm.date_range : 'current_month'
        let params                  =   {download : 'download', ...this.state.submittedLogisticFilterForm, date_range};

        if(JSON.stringify(this.state.submittedLogisticFilterForm) !== JSON.stringify(this.initLogisticSearchForm)){
            this.downloadFile(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    this.downloadFile(params);
                }
            });
        }
    }

    downloadFile       =   (params)  =>  {
        this.setState({download_loading: true});
        HttpAPICall.withAthorization('GET', this.logisticListUrl, this.props.access_token, params, {}, (resp) => {
            download.file(resp.data.file_path)
        }).then(() => this.setState({download_loading: false}));
    }

    pdfDownloadHandler  =   (e)  =>  {
        e.preventDefault();
        this.setState({download_loading: true});
        const params = {download: "download", need_commet : this.state.pdfNeedComment};
        const transaction_id = this.state.logisticViewData.transaction_id;
        HttpAPICall.withAthorization('GET', this.logisticDetailUrl + '/' + transaction_id, this.props.access_token, params, {}, (resp) => {
            this.customPDFDownloadModal.hide();
            download.file(resp.data.file_path)
        }).then(() => this.setState({download_loading: false}));
    }

    customDownloadInit              =       ()          =>          {
        this.customPDFDownloadModal.show();
    }

    logisticSearchModalInit = () =>{
        this.logisticSearchModal.show();
    }



    logisticListingTableJsx         =   ()  =>  {
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.logisticSearchClear} />
                <table className="table table-bordered table-fixed  bg-white table-sm">
                    <thead className="table-secondary" >
                        {
                            this.state.minimizeTable
                            ? <tr>
                                <th scope="col" style={{ width: "15%" }}>Logistic</th>
                            </tr>
                            :  
                            <tr>
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" style={{ width: "10%" }}>Date</th>
                                <th scope="col" style={{ width: "10%" }}> Transaction ID</th>
                                <th scope="col" style={{ width: "13%" }}>Type</th>
                                <th scope="col" style={{ width: "22%" }}>Origin</th>
                                <th scope="col" style={{ width: "22%" }}>Destination</th>
                                <th scope="col" style={{ width: "13%" }} className='text-center'>Status</th>
                                <th scope="col" style={{ width: "5%" }}>Action</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {
                            this.state.listing_loading
                            ? 
                                <tr><td colSpan="8"><Loader /></td></tr>
                            : 
                                this.state.logistic_listing.length > 0
                                ? 
                                this.state.logistic_listing.map((data, index) => {
                                    return ( 
                                        <tr key={index}>
                                            {
                                                this.state.minimizeTable
                                                ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <div className="text-capitalize link-primary cursor_pointer">
                                                            <Link onClick={() => this.viewLogisticDetail(data.transaction_id)}>
                                                            {data.transaction_id ? data.transaction_id : "-"}
                                                            </Link>
                                                        </div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{data.transaction_date ? data.transaction_date : "-"}</small>
                                                            <small className="float-end">
                                                                {data.status ? data.status?.name : '-'}
                                                            </small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            :(
                                                <Ax>
                                                    <td>{index + 1}</td>
                                                    <td>{data.transaction_date ? DateService.dateTimeFormat(data.transaction_date, 'DD-MMM-YYYY') : "-"}</td>
                                                    <td>
                                                        <Link onClick={() => this.viewLogisticDetail(data.transaction_id)}>
                                                            {data.transaction_id ? data.transaction_id : "-"}
                                                        </Link>
                                                    </td>
                                                    <td>{data.logistic_type ? data.logistic_type : '-'}</td>
                                                    <td>
                                                        {data?.origin_type_detail ? `${data?.origin_type_detail.name} (${data?.origin_type_detail.code})` : ''}
                                                    </td>
                                                    <td>
                                                        {data?.destination_type_detail ? `${data?.destination_type_detail.name} (${data?.destination_type_detail.code})` : ''}
                                                    </td>
                                                    <td>{data.status ? data.status?.name : '-'}</td>
                                                    <td className="text-center">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <Link className="dropdown-item" role="button" onClick={() => this.viewLogisticDetail(data.transaction_id)}>View</Link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </Ax>
                                            )
                                            }
                                        </tr>
                                    )
                                })
                                : <tr><td colSpan="8" className="text-center">No Records</td></tr>
                        }
                    </tbody>
                </table>
                <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
            </Ax>
        )
    }
    
    logisticListjsx            =       ()      =>    {
        // let employeeData = this.state.employeeData
        return (
            <Ax>
                <div className="page_title row m0">
                    <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                        <h3>Logistic </h3>
                        {!this.state.minimizeTable && <div className="text-end mt15">
                            { this.props.permissions && this.props.permissions.includes('logistic_add') && <button type="button" className="btn btn-primary" disabled={this.state.listing_loading} onClick={this.openAddLogisticScreen}>New Logistic</button>}
                            <button type="button" disabled={this.state.listing_loading || this.state.download_loading} className="btn btn-secondary" onClick={this.logisticDownload}>
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                {this.state.download_loading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}
                            </button>
                            <button type="button" disabled={this.state.listing_loading} className="btn btn-secondary" onClick={this.logisticSearchModalInit}>
                                <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                        </div> }
                        {
                            this.state.minimizeTable 
                            ?
                            <div className="text-end mt15"> 
                                <button type="button" className="btn btn-secondary" onClick={this.logisticSearchModalInit}>
                                <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><Link className="dropdown-item" onClick={this.openAddLogisticScreen}>New Logistic</Link></li>
                                    <li><Link className="dropdown-item">Download</Link></li>
                                </ul>
                                </span> 
                            </div>
                            : null
                        }

                    </div>
                    {
                        this.state.minimizeTable
                            ? 
                            (
                                <div className="col-9 tap_border_left">
                                    <h3 className="text-capitalize">Logistic : {this.state.logisticViewData.transaction_id}  </h3>
                                    <div className="text-end mt15">
                                        <button type="button" disabled={this.state.listing_loading || this.state.download_loading} className="btn btn-secondary" onClick={this.customDownloadInit}>
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                        </button>
                                        <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </div>
                            )
                            : null
                    }
                </div>
                <div className="container-fluid pl5">
                    <div className="page_containt row">
                        <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.logisticListingTableJsx()}</div>

                        {this.state.minimizeTable ? <div className="col-9 pr4 pl4 pageTbl"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.logisticViewJsx()}</div></div> : null}
                    </div>
                </div>
            </Ax>
        );
    }

    submitLogisticSearchForm = (e) => {
        e.preventDefault();

        e &&  e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.logisticSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.logisticSearchFormLabel[key];
            let value                       =   this.state.logisticSearchForm[key];
           
            if(value   && value.length > 0) {
                searchParams[key]      =   value;
           if(label) {
               
                    let show_val            =   value;
                    if(key == 'search_vendor_ids') {
                        show_val            =   this.state.searchedVendor ? this.state.searchedVendor.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_purchase_transaction_ids') {
                        show_val            =   this.state.searchedPurchase ? this.state.searchedPurchase.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_purchase_order_transaction_ids') {
                        show_val            =   this.state.searchedPO ? this.state.searchedPO.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_inv_transfer_transaction_ids') {
                        show_val            =   this.state.searchedInv ? this.state.searchedInv.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_load_types') {
                        show_val            =   this.loadType.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_tagged_users') {
                        show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_like_transaction_id') {
                        show_val            =   this.state.logisticSearchForm ? this.state.logisticSearchForm.search_like_transaction_id : '';
                    }
                    if(key == 'search_eway_bill_number') {
                        show_val            =   this.state.logisticSearchForm ? this.state.logisticSearchForm.search_eway_bill_number : '';
                    }
                    if(key == 'search_invoice_number') {
                        show_val            =   this.state.logisticSearchForm ? this.state.logisticSearchForm.search_invoice_number : '';
                    }
                    if(key == 'search_consigment_number') {
                        show_val            =   this.state.logisticSearchForm ? this.state.logisticSearchForm.search_consigment_number : '';
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.logisticStatus.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'search_customer_id') {
                        show_val            =   this.state.allPriorityOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_division_id') {
                        show_val            =   this.state.divisionsList.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_transporter_id') {
                        show_val            =   this.state.transportersList.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_cnf_agent_id') {
                        show_val            =   this.state.cnfAgentsList.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_handler_id') {
                        show_val            =   this.state.handlersList.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_logistic_type') {
                        show_val            =   this.state.logisticType.filter((s) => value.includes(s.value)).map(s => s.label);
                    }

                    if(key == 'date_range') {
                        
                        let  start_range = moment(this.state.logisticSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.logisticSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                        
                    }
                   
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedLogisticFilterForm     :   {...this.state.logisticSearchForm},
            formSearchedElems               :   searchedElems
        }, () => {
            this.logisticSearchModal.hide()
            this.loadListingTblData(1);
        });

    }

    logisticClear               =   ()  =>  {
        this.setState({
            formSearchedElems               :   [],
            searchedVendor                  :   [],
            searchedPurchase                  :   [],
            searchedPO                  :   [],
            searchedInv                  :   [],
            logisticSearchForm              :   {...this.initLogisticSearchForm},
            submittedLogisticFilterForm     :   {...this.initLogisticSearchForm}
        }, () => {
            this.logisticSearchModal.hide();
        });
    }

    logisticSearchModalJsx      =   ()  =>  {
        return (
            <div className="modal fade" id="logisticSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchLogisticModalLabel">Logistic Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="logisticSearchForm" onSubmit={this.submitLogisticSearchForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_like_transaction_id"
                                            type="text"
                                            value={this.state.logisticSearchForm.search_like_transaction_id}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "logisticSearchForm")}
                                            placeholder="Please enter Transaction ID"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Consigment Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_consigment_number"
                                            type="text"
                                            value={this.state.logisticSearchForm.search_consigment_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "logisticSearchForm")}
                                            placeholder="Please enter Consigment Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Logistic Type</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.logisticType}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_logistic_type')}
                                            value={this.state.logisticType.filter(u => this.state.logisticSearchForm?.search_logistic_type?.includes(u.value))}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Logistic Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Type of Load</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.loadType}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_load_types')}
                                            value={this.loadType.filter(t => this.state.logisticSearchForm.search_load_types.includes(t.value))}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            placeholder="Select Users"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Division</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                        options={this.state.divisionsList}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_division_id')}
                                        value={this.state.divisionsList.filter(u => this.state.logisticSearchForm?.search_division_id?.includes(u.value))}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder="Select Division"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">CHA Agent</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                        options={this.state.cnfAgentsList}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_cnf_agent_id')}
                                        value={this.state.cnfAgentsList.filter(u => this.state.logisticSearchForm?.search_cnf_agent_id?.includes(u.value))}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder="Select CHA Agent"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Customer</label>
                                    <div className="col-sm-4">
                                        <InputCustomerSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_customer_id');
                                            this.setState({ searchedCustomer: selectedOption ? selectedOption : null });
                                        }}
                                        menuPlacement="top"
                                        value={this.state.searchedCustomer}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Status</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.logisticStatus}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_status_ids')}
                                            value={this.state.logisticStatus.filter(u => this.state.logisticSearchForm?.search_status_ids?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Handler</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                        options={this.state.handlersList}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_handler_id')}
                                        value={this.state.handlersList.filter(u => this.state.logisticSearchForm?.search_handler_id?.includes(u.value))}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder="Select Handler"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Warehouse</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.all_warehouses}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_warehouse_ids')}
                                            value={this.state.all_warehouses.filter(u => this.state.logisticSearchForm?.search_warehouse_ids?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Warehouses"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Vendor</label>
                                    <div className="col-sm-4">
                                        <InputVendorSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_vendor_ids');
                                            this.setState({ searchedVendor: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                        }}
                                        isMulti={true}
                                        value={this.state.searchedVendor.length > 0 ? this.state.searchedVendor.map(a => { a.label = a.display_label; return a; }) : []}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Tagged Users</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allUsers}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_tagged_users')}
                                            value={this.state.allUsers.filter(t => this.state.logisticSearchForm.search_tagged_users.includes(t.value))}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            placeholder="Select Users"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Transporter</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                        options={this.state.transportersList}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_transporter_id')}
                                        value={this.state.transportersList.filter(u => this.state.logisticSearchForm?.search_transporter_id?.includes(u.value))}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder="Select Transporter"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Purchase</label>
                                    <div className="col-sm-10">
                                        <InputPurchaseSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_purchase_transaction_ids');
                                            this.setState({ searchedPurchase: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                        }}
                                        isMulti={true}
                                        value={this.state.searchedPurchase.length > 0 ? this.state.searchedPurchase.map(a => { a.label = a.display_label; return a; }) : []}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Purchase Order</label>
                                    <div className="col-sm-10">
                                        <InputPurchaseOrderSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_purchase_order_transaction_ids');
                                            this.setState({ searchedPO: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                        }}
                                        isMulti={true}
                                        value={this.state.searchedPO.length > 0 ? this.state.searchedPO.map(a => { a.label = a.display_label; return a; }) : []}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Inventory</label>
                                    <div className="col-sm-10">
                                        <InputInventoryTransferSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'logisticSearchForm', 'search_inv_transfer_transaction_ids');
                                            this.setState({ searchedInv: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                        }}
                                        isMulti={true}
                                        value={this.state.searchedInv.length > 0 ? this.state.searchedInv.map(a => { a.label = a.display_label; return a; }) : []}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods && this.state.all_periods !== undefined ? this.state.all_periods : []}
                                            value={this.state.logisticSearchForm.date_range}
                                            startDate={this.state.logisticSearchForm.date_range_start}
                                            endDate={this.state.logisticSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    logisticSearchForm: {
                                                        ...this.state.logisticSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.logisticClear}>Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    customPDFDownloadModalJsx      =   ()  =>  {
        return (
            <div className="modal fade" id="customPDFDownloadModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Custom PDF Download</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form onSubmit={this.pdfDownloadHandler}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-5 mx-3">
                                        <input
                                            name="custom_download"
                                            type="radio"
                                            value={"N"}
                                            required={true}
                                            onChange={(e) => this.setState({pdfNeedComment : "N"})}
                                            className="form-check-input"
                                            id="withOutComment"
                                        />
                                        <label className="form-check-label mx-2" htmlFor="withOutComment">Without Comment</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <input
                                            name="custom_download"
                                            type="radio"
                                            required={true}
                                            value={"Y"}
                                            onChange={(e) => this.setState({pdfNeedComment : "Y"})}
                                            className="form-check-input"
                                            id="withComment"
                                        />
                                        <label className="form-check-label mx-2" htmlFor="withComment">With Comment</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.logisticClear}>Close</button>
                                <button type="submit" className="btn btn-primary" disable={this.state.download_loading}>Submit {this.state.download_loading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

 
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Logistic Listing</title></Helmet>
            {this.logisticListjsx()}
            {this.logisticSearchModalJsx()}
            {this.customPDFDownloadModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(LogisticList);