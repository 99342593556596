import React from 'react';
import "./RoleList.css";
import {connect} from "react-redux";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import ReactPaginate from "react-paginate";
import Ax from "../../../components/hoc/Ax";
import AddRole from "../addRole/AddRole";
import tapIcon from "../../../services/TapIcon"
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import {Modal} from "bootstrap";

import {AllCheckerCheckbox, Checkbox, CheckboxGroup} from "@createnl/grouped-checkboxes";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import AppPermission from '../AppPermission';
import { API_SERVER_2, INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class RoleList extends React.Component {
    constructor() {
        super();
        this.state = {
           roleAddScreenView           :         false,
            minimizeTable              :         false,
            roleUpdateScreenView       :         null,
            roleList                   :         [],
            listing_loading            :          false,
            listingMeta                :         [],
            page                       :          1,
            roleData                   :          [],
            selectedReportee           :         null,
            selectedVisibility         :         null,
            showDialog                 :         false,
            label                      :         "",
            roleFormData               :         [],
            canUpdate                  :         "Y",
            visibilityOpt              :         [{name:"site",display:"Site"},{name:"asset",display:"Asset"}],
            updateRole                 :          {
               name                    :           "",
                locking_period        :           null,
                locking_applicable     :           "Y"
            },
            permissionName             :           [],
            moduleListChecked          :           [],
            permissionModuleList       :           {},
            isChecked                  :           false,
            allPermissionList          :           [],
            allTransactionLockingList  :           [],
            updateAssetPermission      :           {
                permission_asset       :           "N"
            }
        }

        this.onCheckboxChange            =        this.onCheckboxChange.bind(this)
    }

    //number - 64
    
    //function to close
    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }

    //function to close screen
    addNewRoleScreenClose               =   ()  =>  {
        this.setState({roleAddScreenView : false, roleUpdateScreenView : false});
        this.getRoleList(this.state.page)
    }


    componentDidMount() {
        this.getRoleList(this.state.page)
        this.getRoleFormData()
        this.updateRoleModal             =        new Modal(document.getElementById('updateRoleModal'), {keyboard: false, backdrop :false});
        this.updateVisibilityModal       =        new Modal(document.getElementById('updateVisibilityModal'), {keyboard: false, backdrop :false});
        this.updateTransactionModal       =        new Modal(document.getElementById('updateTransactionModal'), {keyboard: false, backdrop :false});
        this.updateAssetPermissionModal       =   new Modal(document.getElementById('updateAssetPermissionModal'), {keyboard: false, backdrop :false});

    }

    //function to open
    viewRoleDetail                      =      (id)  =>    {
        let roleData                =   this.state.roleList.find((i) => i.id == id);

        if(roleData) {
            this.setState({minimizeTable : true, roleData : roleData,canUpdate:roleData.can_update });
        }
        this.getPermissionData(id)
    }

    addNewRoleScreenOpen               =   ()  =>  {
        this.props.history.push('/add_role')
    }

    //handle pagination
    handlePageClick = (e) => {
        this.setState({page: e.selected + 1})
        this.state.page = e.selected + 1;
        this.getRoleList(this.state.page);
    }

    //function to get role list
    getRoleList(page){
        this.setState({listing_loading: true})
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            params:{page:page},
            url: process.env.REACT_APP_API_SERVER + '/group/roles'
        }).then((response) => {
            {this.setState({roleList: response.data.data,listingMeta:response.data.meta})}
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to Load Item.', {position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    getRoleFormData(){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/group/allroles'
        }).then((response) => {
            {this.setState({roleFormData: response.data.data})}
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to Load Item.', {position: toast.POSITION.TOP_RIGHT});
        })
    }

    getUpdatedRoleData(id){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/group/role/' + id
        }).then((response) => {
            {this.setState({roleData: response.data.data})}
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to Load Item.', {position: toast.POSITION.TOP_RIGHT});
        })
    }

    getPermissionData(id){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: API_SERVER_2 + '/group/role/permission/' + id
        }).then((response) => {
            {this.setState({permissionModuleList: response.data.data,allPermissionList : response.data.all_permissions})}
            let allTransactionLockingPermission = response.data.all_permissions.filter(i => i.transaction_locking == "Y")
           let allTransactionList = allTransactionLockingPermission.map(value => Object.values(value).filter(i=> i !== "Y").map((acc, curr)=>{return acc}))
           let allTransactionLockingList = allTransactionList.flat()
            this.setState({allTransactionLockingList})

        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to Load Item.', {position: toast.POSITION.TOP_RIGHT});
        })
    }

    getViewValue(name,id) {

        let returnVal = false;
        if(id == id){
            for(let i in this.state.permissionModuleList){
               
                if (name == this.state.permissionModuleList[i]) {
                    returnVal = true;
                }
            }
        }
        return returnVal;
    }

    selectReportee = (selected)=>{
        {this.setState({selectedReportee:selected})}
    }
    selectVisbility = (selected)=>{
        {this.setState({selectedVisibility:selected})}
    }

    handleFormChange(e){
        let updateRole = this.state.updateRole
        updateRole[e.target.name] = e.target.value
        this.setState({updateRole})
        if(this.state.updateRole.locking_applicable === "N"){
            this.setState({updateRole:{...updateRole,locking_period:""}})
        }
    }

    openConfirmDialog(e,label){
        e.preventDefault()
        this.setState({label:label,showDialog:true})
    }

    //function for Update Role
    updateRoleModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateRoleModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Role</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.updateRoleForm} id="updateRoleModal">
                            <div className="modal-body">
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Role Name</label></div>
                                    <div className="col-8">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            value={this.state.updateRole.name}
                                            placeholder="Please Enter Name"
                                            onChange={(e) => this.handleFormChange(e)}/>
                                    </div>
                                </div>
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Reportee Role</label></div>
                                    <div className="col-8">
                                        <TapSelect
                                            options={this.state.roleFormData.filter(r => r.id != this.state.updateRole.id).map((s) => { return {value: s.id, label: s.role}})}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Please Select Reportee Role"
                                            required={true}
                                            value={this.state.selectedReportee}
                                            changeEvent={(selectedOption) => {this.selectReportee(selectedOption);
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    //add custom fields
    updateRoleModalInit                 =   (id)  =>  {
        this.updateRoleModal.show();
        if(this.state.minimizeTable === false) {
            let roleData                =   this.state.roleList.find((i) => i.id == id)
            this.setState({
                    updateRole          :   { name : roleData.role , id : roleData.id },
                    roleData            :   {id:id},
                    selectedReportee    :   {value : roleData?.reportee.id, label : roleData?.reportee.role } 
                })
        }else{
            this.setState({
                updateRole              :   {name : this.state.roleData.role, id : this.state.roleData.id},
                selectedReportee : {value : this.state.roleData?.reportee.id,
                    label : this.state.roleData?.reportee.role
                }})
        }

    }

    updateRoleForm     =    (e)   =>    {
        e.preventDefault();
        this.setState({showDialog:false})
        const access_token = localStorage.getItem('access_token');
        let  frmData = {
            id          :    this.state.roleData.id,
            role          :    this.state.updateRole.name,
            parent_role   :    this.state.selectedReportee.value,
        };
        axios({
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token},
            data: {...frmData},
            url: process.env.REACT_APP_API_SERVER + '/group/role'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.updateRoleModal.hide()
            if(this.state.minimizeTable === false){
                this.getRoleList(this.state.page)
            }else{
                this.getUpdatedRoleData(this.state.roleData.id)
            }
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})});
    }

   //function to update visibility
    updateVisibilityModalInit                 =   (id)  =>  {
        this.updateVisibilityModal.show();
        if(this.state.minimizeTable === false){
            let roleData                =   this.state.roleList.find((i) => i.id == id)
            this.setState({updateRole:{
                    name : roleData.role},roleData:{id:id},
                selectedVisibility : {value : roleData?.visibility,
                    label : roleData?.visibility
                }})
        }else{
            this.setState({updateRole:{
                    name : this.state.roleData.role},
                selectedVisibility : {value : this.state.roleData?.visibility,
                    label : this.state.roleData?.visibility
                }})
        }

    }

    updateVisibilityModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateVisibilityModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Visibility</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.updateVisibilityForm} id="updateRoleModal">
                            <div className="modal-body">
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Role Name</label></div>
                                    <div className="col-8">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            value={this.state.updateRole.name}
                                            placeholder="Please Enter Name"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Visibility</label></div>
                                    <div className="col-8 text-capitalize" >
                                        <TapSelect
                                            options={this.state?.visibilityOpt?.map((s) => { return {value: s.name, label: s.display}})}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.selectedVisibility}
                                            placeholder="Please Select Visibility"
                                            changeEvent={(selectedOption) => {this.selectVisbility(selectedOption);
                                            }}
                                            required={true}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updateVisibilityForm    =     (e)   => {
        e.preventDefault()
        this.setState({showDialog:false})
        const access_token = localStorage.getItem('access_token');
        let  frmData = {
            id          :    this.state.roleData.id,
            role          :    this.state.updateRole.name,
            visibility   :    this.state.selectedVisibility.value,
        };
        axios({
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token},
            data: {...frmData},
            url: process.env.REACT_APP_API_SERVER + '/group/role/visibility'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.updateVisibilityModal.hide()
            if(this.state.minimizeTable === false){
                this.getRoleList(this.state.page)
            }else{
                this.getUpdatedRoleData(this.state.roleData.id)
            }
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})});
    }

    //function to update visibility
    updateTransactionModalInit                 =   (id)  =>  {
        this.updateTransactionModal.show();
        if(this.state.minimizeTable === false){
            let roleData                =   this.state.roleList.find((i) => i.id == id)
            this.setState({updateRole:{
                    name : roleData.role,locking_period:roleData.transaction_locking,locking_applicable:roleData.transaction_locking === null ? "N" : "Y"},roleData:{id:id},})
        }else{
            this.setState({updateRole:{
                    name : this.state.roleData.role,locking_period:this.state.roleData.transaction_locking,locking_applicable:this.state.roleData.transaction_locking === null ? "N" : "Y"}})
        }

    }

    updateTransactionModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Transaction</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.updateTransactionForm} id="updateRoleModal">
                            <div className="modal-body">
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Role Name</label></div>
                                    <div className="col-8">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            value={this.state.updateRole.name}
                                            placeholder="Please Enter Name"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Locking
                                        Applicable</label></div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-6">
                                                <input
                                                    name="locking_applicable"
                                                    type="radio"
                                                    value={"Y"}
                                                    onChange={(e) => this.handleFormChange(e)}
                                                    className="form-check-input"
                                                    required={true}
                                                    checked={this.state.updateRole.locking_applicable === "Y"}
                                                />
                                                <label className="form-check-label"
                                                       htmlFor={this.state.updateRole.locking_applicable}>Yes</label>
                                            </div>
                                            <div className="col-6">
                                                <input
                                                    name="locking_applicable"
                                                    type="radio"
                                                    value={"N"}
                                                    onChange={(e) => this.handleFormChange(e)}
                                                    className="form-check-input"
                                                    required={true}
                                                    checked={this.state.updateRole.locking_applicable=== "N"}
                                                />
                                                <label className="form-check-label"
                                                       htmlFor={this.state.updateRole.locking_applicable}>No</label>
                                            </div>
                                        </div>
                                       </div>
                                </div>
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Enter Transaction Lock Period (Days) </label></div>
                                    <div className="col-8">
                                        <input name="locking_period"
                                               type="text"
                                               value={this.state.updateRole.locking_period}
                                               className="form-control"
                                               disabled={this.state.updateRole.locking_applicable === "N" ? true : false}
                                               placeholder="Please Enter Locking Period"
                                               onChange={(e) => this.handleFormChange(e)}/>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updateTransactionForm     =   (e)   =>  {
        e.preventDefault()
        this.setState({showDialog:false})
        const access_token = localStorage.getItem('access_token');
        let  frmData = {
            id                  :    this.state.roleData.id,
            locking_applicable   :   this.state.updateRole.locking_applicable,
            transaction_locking   :  this.state.updateRole.locking_period
        };
        axios({
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token},
            data: {...frmData},
            url: process.env.REACT_APP_API_SERVER + '/group/role/transaction_locking'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.updateTransactionModal.hide()
            if(this.state.minimizeTable === false){
                this.getRoleList(this.state.page)
            }else{
                this.getUpdatedRoleData(this.state.roleData.id)
            }
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})});
    }

    deleteRole(id) {
        swal({
            title: "Role Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    const access_token = localStorage.getItem('access_token');
                    if (this.state.minimizeTable === false) {
                        axios({
                            method: 'DELETE',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + access_token},
                            url: process.env.REACT_APP_API_SERVER + '/group/role/' + id
                        }).then((response) => {
                            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
                            this.getRoleList(this.state.page)
                        }).catch((err) => {
                            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})});
                    } else {
                        axios({
                            method: 'DELETE',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + access_token},
                            url: process.env.REACT_APP_API_SERVER + '/group/role/' + this.state.roleData.id
                        }).then((response) => {
                            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
                            this.setState({minimizeTable:false})
                            this.getRoleList(this.state.page)
                        }).catch((err) => {
                            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})});
                    }
                }
            });
    }

    updatePermission(e){
        e.preventDefault()
        let list;
        let newArray = this.moduleListChecked.filter(value => Object.keys(value).length !== 0);
        for (let i = 0; i < newArray.length; i++) { // the plainest of array loops
            list =  newArray.map(value => Object.keys(value).filter(i => i !== 'module_id').map((acc, curr)=>{return acc}))
        }

        const access_token = localStorage.getItem('access_token');
         let  frmData = {
             role_id : this.state.roleData.id,
             permission : list.flat()
        }; 
        
        axios({
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token},
            data: {...frmData},
            url: API_SERVER_2 + '/group/role/permission'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.getPermissionData(this.state?.roleData?.id)
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})});

    }

    //update Asset Permission JSX
    updatePermissionAssetInit                 =   (id)  =>  {
        this.updateAssetPermissionModal.show();
        // if(this.state.minimizeTable === false){
        //     let roleData                =   this.state.roleList.find((i) => i.id == id)
        //     this.setState({updateRole:{
        //             name : roleData.role,locking_period:roleData.transaction_locking,locking_applicable:roleData.transaction_locking === null ? "N" : "Y"},roleData:{id:id},})
        // }else{
        //     this.setState({updateRole:{
        //             name : this.state.roleData.role,locking_period:this.state.roleData.transaction_locking,locking_applicable:this.state.roleData.transaction_locking === null ? "N" : "Y"}})
        // }

    }

    updateAssetPermissionForm(e){
        e.preventDefault()
    }

    updatePermissionModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateAssetPermissionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Permission</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.updateAssetPermissionForm} id="updateRoleModal">
                            <div className="modal-body">
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Permission for Asset Management</label></div>
                                    <div className="col-8">
                                        <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        name="permission_asset"
                                                        type="radio"
                                                        value={"Y"}
                                                        onChange={(e) => this.handlePermissionChange(e)}
                                                        className="form-check-input"
                                                        required={true}
                                                        checked={this.state.updateAssetPermission.permission_asset === "Y"}
                                                    />
                                                    <label className="form-check-label"
                                                           htmlFor={this.state.updateAssetPermission.permission_asset}>Yes</label>
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        name="permission_asset"
                                                        type="radio"
                                                        value={"N"}
                                                        onChange={(e) => this.handlePermissionChange(e)}
                                                        className="form-check-input"
                                                        required={true}
                                                        checked={this.state.updateAssetPermission.permission_asset === "N"}
                                                    />
                                                    <label className="form-check-label"
                                                           htmlFor={this.state.updateAssetPermission.permission_asset}>No</label>
                                                </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    closeDialog(){
        this.setState({showDialog : false})
    }

    //function for role header
    roleListjsx                         =   ()  =>  {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Role List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button"  className="btn btn-primary" onClick={this.addNewRoleScreenOpen}>Add Role</button> : null}
                    <span className="dropdown">
                              {this.state.minimizeTable ?  <button type="button" id='actionMenuButton' data-bs-toggle="dropdown"
                                                                   aria-expanded="false" className="btn btn-secondary">
                                  <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button> : null }
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {this.state.minimizeTable ? <li><a className="dropdown-item" href="#">Add Role</a></li>:null}
                                </ul>
                            </span>
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>{this.state.roleData.role} </h3>
                            <div className="text-end mt15">
                                <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action"/></button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.roleListingTable()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.roleDetailJsx()}</div> : null }
                </div>
            </div>
        </Ax>);
    }

    //function for role listing
    roleListingTable                   =   ()  =>  {
        return (
            <Ax>
                <table className="table table-bordered bg-white">
                    <thead className="table-secondary">
                    { this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{width:"20%"}}>Role</th>
                        </tr> )
                        :   ( <tr>
                            <th scope="col" style={{width:"5%"}} className="text-center"> S.No</th>
                            <th scope="col" style={{width:"20%"}}>Role</th>
                            <th scope="col" style={{width:"20%"}}>Reportee Role</th>
                            <th scope="col" style={{width:"20%"}}>Transaction Lock Duration</th>
                            <th scope="col" style={{width:"20%"}}>Visibility</th>
                            <th scope="col" style={{width:"5%"}}>Action</th>
                        </tr> )
                    }
                    </thead>
                    <tbody>
                    { this.state.listing_loading
                        ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                        : this.state.roleList.map((item, index) => {
                            return( <tr key={index}>
                                {
                                    this.state.minimizeTable
                                        ?   (
                                            <Ax>
                                                <td>
                                                    <a role="button" onClick={() => this.viewRoleDetail(item.id)} className="link-primary">{item.role}</a>
                                                    <br/>
                                                </td>
                                            </Ax>
                                        )
                                        :   (
                                            <Ax>
                                                <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index } </td>
                                                <td><a role="button" onClick={() => this.viewRoleDetail(item.id)} className="link-primary">{item.role}</a></td>
                                                <td>{item?.reportee?.role ?? "-"}</td>
                                                <td>{item?.transaction_locking ?? "-"}</td>
                                                <td className="text-capitalize">{item?.visibility ?? "-"}</td>
                                                <td>
                                    <span className="">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />

                                        </button>
                                        {item.can_update === "N" ?
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button"  onClick={() => this.viewRoleDetail(item.id)} >Permission</a></li>
                                                <li><a className="dropdown-item disabled" role="button">Update Role</a></li>
                                                <li><a className="dropdown-item disabled" role="button">Update Visibility</a></li>
                                                <li><a className="dropdown-item disabled" role="button">Delete Role</a></li>
                                            </ul> :
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button" onClick={() => this.viewRoleDetail(item.id)} >Permission</a></li>
                                                <li><a className="dropdown-item" role="button" onClick={() => this.updateRoleModalInit(item.id)}>Update Role</a></li>
                                                <li><a className="dropdown-item" role="button"onClick={() => this.updateVisibilityModalInit(item.id)}>Update Visibility</a></li>
                                                <li><a className="dropdown-item" role="button"onClick={() => this.updateTransactionModalInit(item.id)}>Set Trn Lock Period</a></li>
                                                <li><a className="dropdown-item" role="button" onClick={() => this.deleteRole(item.id)}>Delete Role</a></li>
                                            </ul>}

                                    </span>
                                                </td>
                                            </Ax>
                                        )
                                }

                            </tr>)
                        })
                    }
                    </tbody>
                </table>
                <div className="row m0">
                    <div className="col-md-6">{this.state.listingMeta ?
                     `Showing ${this.state.listingMeta.from||0} to ${this.state.listingMeta.to||0} of
                     ${this.state.listingMeta.total} entries` : ``}</div>
                    <div className="col-md-6">
                        <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            pageCount={this.state.listingMeta?.last_page ?? 0}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageActive={this.state.listingMeta?.current_page ?? 1}
                            onPageChange={(e) => this.handlePageClick(e)}
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination justify-content-end"
                            pageClassName="page-item"
                            nextClassName="page-item"
                            previousClassName="page-item"
                            pageLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                </div>
            </Ax>
        );
    }

     moduleListChecked=[]
    onCheckboxChange = (id, checkboxes) => {
        let str = ''
        let obj = {}
        if (id == id) {
            for (let key in checkboxes) {
                let subset = (({value, checked}) => ({value, checked}))(checkboxes[key]);
                if(subset.checked === true){
                    obj[subset.value] = subset.checked;
                    obj['module_id'] = id
                }
            }
            this.removeByAttr(this.moduleListChecked, 'module_id', id);
            this.moduleListChecked.push(obj)

        }

        return this.moduleListChecked

    }

    removeByAttr = function (arr, attr, value) {
        let i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {
                arr.splice(i, 1);
            }
        }
         return arr;
    }


    //function for role view
    roleDetailJsx                       =   ()  =>  {
        let role                           =   this.state.roleData;
        return (<section className=" bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="permission-tab" data-bs-toggle="tab" data-bs-target="#permission"
                        type="button" role="tab" aria-controls="permission" aria-selected="false">Permission (Asset Management)
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="mobileapp-tab" data-bs-toggle="tab" data-bs-target="#mobileapp"
                        type="button" role="tab" aria-controls="mobileapp" aria-selected="false">Mobile App Visibility
                    </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <button className="nav-link" id="isc-tab" data-bs-toggle="tab" data-bs-target="#isc"
                            type="button" role="tab" aria-controls="isc" aria-selected="false">Permission (Inventory Module)
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="accounts-tab" data-bs-toggle="tab" data-bs-target="#accounts"
                            type="button" role="tab" aria-controls="accounts" aria-selected="false">Permission (Accounts Module)
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="hrm-tab" data-bs-toggle="tab" data-bs-target="#hrm"
                            type="button" role="tab" aria-controls="hrm" aria-selected="false">Permission (HRM Module)
                    </button>
                </li> */}

            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel"
                    aria-labelledby="dashboard-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Details</span>
                            {
                                this.state.canUpdate === "Y" ?
                                    <div className="float-end">
                                        <button className="btn btn-primary" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false">
                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={this.updateRoleModalInit}>Update Role</a></li>
                                            <li><a className="dropdown-item" role="button" onClick={(e) => this.openConfirmDialog(e, "Delete Role")}>Delete Role</a></li>
                                        </ul>
                                    </div> : null
                            }
                        </div>
                        <table className="table table-hover table-borderless mt-2">
                            <tbody>
                                <tr>
                                    <td className="details-label" style={{ "width": "34%" }}>Role Name </td>
                                    <td className="details-name" style={{ "width": "66%" }}>{role?.role}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{ "width": "34%" }}>Reportee Role </td>
                                    <td className="details-name" style={{ "width": "66%" }}>{role?.reportee === null ? "NA" : role.reportee.role}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Asset Management</span>
                            {
                                this.state.canUpdate === "Y" ?
                                    <div className="float-end">
                                        <button className="btn btn-primary" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false">
                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={this.updatePermissionAssetInit}>Update Permission</a></li>
                                            <li><a className="dropdown-item" role="button" onClick={this.updateVisibilityModalInit}>Update Visibility</a></li>
                                            <li><a className="dropdown-item" role="button" onClick={this.updateTransactionModalInit}>Update Tr. Locking Period</a></li>

                                        </ul>
                                    </div> : null
                            }
                        </div>
                        <table className="table table-hover table-borderless mt-2">
                            <tbody>
                                <tr>
                                    <td className="details-label" style={{ "width": "34%" }}>Permission for Asset Management </td>
                                    <td className="details-name text-capitalize" style={{ "width": "66%" }}>Yes</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{ "width": "34%" }}>Transaction Lock Duration </td>
                                    <td className="details-name" style={{ "width": "66%" }}>{role?.transaction_locking === null ? "-" : role.transaction_locking}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{ "width": "34%" }}>Visibility </td>
                                    <td className="details-name text-capitalize" style={{ "width": "66%" }}>{role?.visibility}</td>
                                </tr>

                            </tbody>
                        </table>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Inventory Module</span></div>
                        <table className="table table-hover table-borderless mt-2">
                            <tbody>
                                <tr>
                                    <td className="details-label" style={{ "width": "34%" }}>Permission for Inventory Module</td>
                                    <td className="details-name text-capitalize" style={{ "width": "66%" }}>Yes</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{ "width": "34%" }}>Transaction Lock Duration </td>
                                    <td className="details-name" style={{ "width": "66%" }}>-</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{ "width": "34%" }}>Visibility </td>
                                    <td className="details-name" style={{ "width": "66%" }}>-</td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div className="tab-pane " id="permission" role="tabpanel" aria-labelledby="permission-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Permission Details</span></div>
                        <div className="mt-3 mb-3">
                            <form className="bg-white p-3" onSubmit={this.updatePermission.bind(this)}>
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>Assets</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="permission_row">
                                            <Ax>
                                                <CheckboxGroup
                                                    onChange={this.onCheckboxChange.bind(this, 1)}>
                                                    <td>Asset</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <Checkbox value="iam-asset-add" checked={this.getViewValue("iam-asset-add", 1)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <Checkbox value="iam-asset-delete" checked={this.getViewValue("iam-asset-delete", 1)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        {/* <tr>
                                            <Ax>
                                                <CheckboxGroup
                                                    onChange={this.onCheckboxChange.bind(this, 2)}>
                                                    <td>Linked User for Visibility</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <Checkbox value="iam-asset-user-visibility" checked={this.getViewValue("iam-asset-user-visibility", 2)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-user-visibility') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr> */}
                                        <tr>
                                            <Ax><CheckboxGroup onChange={this.onCheckboxChange.bind(this, 3)}>
                                                <td>Asset-Site Linking</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center"><Checkbox value="iam-asset-site-linking-view"
                                                    checked={this.getViewValue("iam-asset-site-linking-view", 3)} />
                                                    {this.state.allTransactionLockingList.includes('iam-asset-site-linking-view') ? <span className="require require_alignment"> </span> : null}

                                                </td>
                                                <td className="text-center"><Checkbox value="iam-asset-site-linking-change"
                                                    checked={this.getViewValue("iam-asset-site-linking-change", 3)} />
                                                    {this.state.allTransactionLockingList.includes('iam-asset-site-linking-change') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td></td>
                                                <td className="text-center"><Checkbox value="iam-asset-site-linking-delete"
                                                    checked={this.getViewValue("iam-asset-site-linking-delete", 3)} />
                                                    {this.state.allTransactionLockingList.includes('iam-asset-site-linking-delete') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"></td>
                                            </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 4)}>
                                                    <td>Asset-Employee Linking</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td><Checkbox value="iam-asset-employee-linking" checked={this.getViewValue("iam-asset-employee-linking", 4)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-employee-linking') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 38)}>
                                                    <td>Asset-Commercial</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>

                                                    <td className="text-center"><Checkbox value="iam-asset-rental-update" checked={this.getViewValue("iam-asset-rental-update", 38)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-rental-update') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td> </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 39)}>
                                                    <td>Asset-Compliance</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-compliance-add" checked={this.getViewValue("iam-compliance-add", 39)} />
                                                        {this.state.allTransactionLockingList.includes('iam-compliance-add') ? <span className="require require_alignment"> </span> : null}</td>

                                                    <td className="text-center">
                                                        <Checkbox value="iam-compliance-edit" checked={this.getViewValue("iam-compliance-edit", 39)} />
                                                        {this.state.allTransactionLockingList.includes('iam-compliance-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td>
                                                        <Checkbox value="iam-compliance-delete" checked={this.getViewValue("iam-compliance-delete", 39)} />
                                                        {this.state.allTransactionLockingList.includes('iam-compliance-delete') ? <span className="require require_alignment"> </span> : null} </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>

                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 5)}>
                                                    <td>Reset Counter Log</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-counterlog-reset "
                                                        checked={this.getViewValue("iam-asset-counterlog-reset", 5)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-counterlog-reset') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-counterlog-reset-delete"
                                                        checked={this.getViewValue("iam-asset-counterlog-reset-delete", 5)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-counterlog-reset-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax><CheckboxGroup onChange={this.onCheckboxChange.bind(this, 34)}>
                                                <td>Asset-Roster Linking</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">   </td>
                                                <td className="text-center"><Checkbox value="iam-asset-roster-add"
                                                    checked={this.getViewValue("iam-asset-roster-add", 34)} />
                                                    {this.state.allTransactionLockingList.includes('iam-asset-roster-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td></td>
                                                <td className="text-center"> </td>
                                                <td className="text-center"></td>
                                            </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 6)}>
                                                    <td>Approved Fuel Limit (Asset)</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 7)}>
                                                    <td>Invoice Rule</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-invoice-view" checked={this.getViewValue("iam-asset-invoice-view", 7)} /> {this.state.allTransactionLockingList.includes('iam-asset-invoice-view') ? <span className="require require_alignment"></span> : null}</td>
                                                    <td className="text-center"><Checkbox value="iam-asset-invoice-add" checked={this.getViewValue("iam-asset-invoice-add", 7)} /> {this.state.allTransactionLockingList.includes('iam-asset-invoice-add') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"><Checkbox value="iam-asset-invoice-update" checked={this.getViewValue("iam-asset-invoice-update", 7)} /> {this.state.allTransactionLockingList.includes('iam-asset-invoice-update') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <span className="">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="iam-asset-invoice-linking"
                                                                    checked={this.getViewValue("iam-asset-invoice-linking", 7)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-asset-invoice-linking') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2" >Invoice linking to Asset</span></li>
                                                                <li className="dropdown-item" style={{ display: "flex" }} ><Checkbox value="iam-asset-matrix-invoice-linking"
                                                                    checked={this.getViewValue("iam-asset-matrix-invoice-linking", 7)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-asset-matrix-invoice-linking') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2" >  Invoice linking to Asset Matrix</span></li>
                                                            </ul>
                                                        </span>
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 35)}>
                                                    <td>Save Invoice Rule Calculation</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-invoice-calculation-view" checked={this.getViewValue("iam-asset-invoice-calculation-view", 35)} /> {this.state.allTransactionLockingList.includes('iam-asset-invoice-calculation-view') ? <span className="require require_alignment"></span> : null}</td>
                                                    <td className="text-center"><Checkbox value="iam-asset-invoice-calculation-save" checked={this.getViewValue("iam-asset-invoice-calculation-save", 35)} /> {this.state.allTransactionLockingList.includes('iam-asset-invoice-calculation-save') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"><Checkbox value="iam-asset-invoice-calculation-edit" checked={this.getViewValue("iam-asset-invoice-calculation-edit", 35)} /> {this.state.allTransactionLockingList.includes('iam-asset-invoice-calculation-edit') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"><Checkbox value="iam-asset-invoice-calculation-delete" checked={this.getViewValue("iam-asset-invoice-calculation-delete", 35)} /> {this.state.allTransactionLockingList.includes('iam-asset-invoice-calculation-delete') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr className="text-center">
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 31)}>
                                                    <td className="text-start">Overtime Rule</td>
                                                    <td><AllCheckerCheckbox /></td>
                                                    <td></td>
                                                    <td>
                                                        <Checkbox value="iam-asset-overtime-rule-add" checked={this.getViewValue("iam-asset-overtime-rule-add", 31)} /> {this.state.allTransactionLockingList.includes('iam-asset-overtime-rule-add') ? <span className="require require_alignment"></span> : null}
                                                    </td>
                                                    <td>
                                                        <Checkbox value="iam-asset-overtime-rule-edit" checked={this.getViewValue("iam-asset-overtime-rule-edit", 31)} /> {this.state.allTransactionLockingList.includes('iam-asset-overtime-rule-edit') ? <span className="require require_alignment"></span> : null}
                                                    </td>
                                                    <td>
                                                        <Checkbox value="iam-asset-overtime-rule-delete" checked={this.getViewValue("iam-asset-overtime-rule-delete", 31)} /> {this.state.allTransactionLockingList.includes('iam-asset-overtime-rule-delete') ? <span className="require require_alignment"></span> : null}
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr><Ax>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 8)}>
                                                <td>Fuel Station</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"><Checkbox value="iam-fuel-station-add"
                                                    checked={this.getViewValue("iam-fuel-station-add", 8)} />
                                                    {this.state.allTransactionLockingList.includes('iam-fuel-station-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="iam-fuel-station-update"
                                                    checked={this.getViewValue("iam-fuel-station-update", 8)} />
                                                    {this.state.allTransactionLockingList.includes('iam-fuel-station-update') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </CheckboxGroup>
                                        </Ax></tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 9)}>
                                                    <td>Inventory Asset List</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-inventory-add-asset"
                                                        checked={this.getViewValue("iam-inventory-add-asset", 9)} />
                                                        {this.state.allTransactionLockingList.includes('iam-inventory-add-asset') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 36)}>
                                                    <td>Fund Transfer</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-wallet-fund-add" checked={this.getViewValue("iam-wallet-fund-add", 36)} /> {this.state.allTransactionLockingList.includes('iam-wallet-fund-add') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"><Checkbox value="iam-wallet-fund-edit" checked={this.getViewValue("iam-wallet-fund-edit", 36)} /> {this.state.allTransactionLockingList.includes('iam-wallet-fund-edit') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"><Checkbox value="iam-wallet-fund-delete" checked={this.getViewValue("iam-wallet-fund-delete", 36)} /> {this.state.allTransactionLockingList.includes('iam-wallet-fund-delete') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>Site Productivity</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 61)}>
                                                    <td>Configuration</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="site-productivity-configuration-view"
                                                        checked={this.getViewValue("site-productivity-configuration-view", 61)} />
                                                        {this.state.allTransactionLockingList.includes('site-productivity-configuration-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="site-productivity-configuration-add"
                                                        checked={this.getViewValue("site-productivity-configuration-add",61)} />
                                                        {this.state.allTransactionLockingList.includes('site-productivity-configuration-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="site-productivity-configuration-edit"
                                                        checked={this.getViewValue("site-productivity-configuration-edit", 61)} />
                                                        {this.state.allTransactionLockingList.includes('site-productivity-configuration-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="site-productivity-configuration-change-status"
                                                                checked={this.getViewValue("site-productivity-configuration-change-status", 61)} />
                                                                {this.state.allTransactionLockingList.includes('site-productivity-configuration-change-status') ? <span className="require require_alignment"> </span> : null}
                                                                <span className="mx-2">Change Status</span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </CheckboxGroup>
                                               
                                            </Ax>
                                        </tr>
                                        <tr>
                                        <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 62)}>
                                                    <td>Site Productivity</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="site-productivity-view"
                                                        checked={this.getViewValue("site-productivity-view", 62)} />
                                                        {this.state.allTransactionLockingList.includes('site-productivity-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="site-productivity-add"
                                                        checked={this.getViewValue("site-productivity-add",62)} />
                                                        {this.state.allTransactionLockingList.includes('site-productivity-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="site-productivity-edit"
                                                        checked={this.getViewValue("site-productivity-edit", 62)} />
                                                        {this.state.allTransactionLockingList.includes('site-productivity-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="site-productivity-delete"
                                                        checked={this.getViewValue("site-productivity-delete", 62)} />
                                                        {this.state.allTransactionLockingList.includes('site-productivity-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                      
                                                    </td>
                                                </CheckboxGroup>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>Configuration</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Create</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 42)}>
                                                    <td>Configuration ( General)</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="group-configuration" checked={this.getViewValue("group-configuration", 42)} /> {this.state.allTransactionLockingList.includes('group-configuration') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 43)}>
                                                    <td>Configuration (Assets)</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-configuration" checked={this.getViewValue("iam-configuration", 43)} /> {this.state.allTransactionLockingList.includes('iam-configuration') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 44)}>
                                                    <td>Configuration (Inventory)</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="isc-configuration" checked={this.getViewValue("isc-configuration", 44)} /> {this.state.allTransactionLockingList.includes('isc-configuration') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="isc-configuration-edit" checked={this.getViewValue("isc-configuration-edit", 44)} /> {this.state.allTransactionLockingList.includes('isc-configuration-edit') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 41)}>
                                                    <td>User</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center">
                                                        <Checkbox value="grp-user-view"
                                                            checked={this.getViewValue("grp-user-view", 41)} />
                                                        {this.state.allTransactionLockingList.includes('grp-user-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <Checkbox value="grp-user-add"
                                                            checked={this.getViewValue("grp-user-add", 41)} />
                                                        {this.state.allTransactionLockingList.includes('grp-user-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <Checkbox value="grp-user-edit"
                                                            checked={this.getViewValue("grp-user-edit", 41)} />
                                                        {this.state.allTransactionLockingList.includes('grp-user-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <Checkbox value="grp-user-delete"
                                                            checked={this.getViewValue("grp-user-delete", 41)} />
                                                        {this.state.allTransactionLockingList.includes('grp-user-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="iam-site-user-linking"
                                                                    checked={this.getViewValue("iam-site-user-linking", 41)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-site-user-linking') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2" >Site Linking</span></li>
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="iam-asset-user-linking"
                                                                    checked={this.getViewValue("iam-asset-user-linking", 41)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-asset-user-linking') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2" >Asset Linking</span></li>
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="grp-user-role"
                                                                    checked={this.getViewValue("grp-user-role", 41)} />
                                                                    {this.state.allTransactionLockingList.includes('grp-user-role') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2" >Role Change</span></li>

                                                            </ul>
                                                        </span>
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 10)}>
                                                    <td>Role</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center">
                                                        <Checkbox value="iam-user-role-view"
                                                            checked={this.getViewValue("iam-user-role-view", 10)} />
                                                        {this.state.allTransactionLockingList.includes('iam-user-role-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <Checkbox value="iam-user-role-add"
                                                            checked={this.getViewValue("iam-user-role-add", 10)} />
                                                        {this.state.allTransactionLockingList.includes('iam-user-role-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <Checkbox value="iam-user-role-edit"
                                                            checked={this.getViewValue("iam-user-role-edit", 10)} />
                                                        {this.state.allTransactionLockingList.includes('iam-user-role-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <Checkbox value="iam-user-role-delete"
                                                            checked={this.getViewValue("iam-user-role-delete", 10)} />
                                                        {this.state.allTransactionLockingList.includes('iam-user-role-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="iam-user-role-change"
                                                                    checked={this.getViewValue("iam-user-role-change", 10)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-user-role-change') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2" >  Change Role of User</span></li>
                                                                <li className="dropdown-item" style={{ display: "flex" }}>
                                                                    <Checkbox value="iam-asset-user-visibility" checked={this.getViewValue("iam-asset-user-visibility", 10)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-asset-user-visibility') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2" >User Asset Visibility</span></li>
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="iam-user-role-visibility-edit"
                                                                    checked={this.getViewValue("iam-user-role-change", 10)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-user-role-visibility-edit') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2">Update User Role Visibility</span></li>
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="iam-user-role-visibility-edit"
                                                                    checked={this.getViewValue("iam-user-role-visibility-edit", 10)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-user-role-visibility-edit') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2"> Update Transaction Locking</span></li>
                                                            </ul>
                                                        </span>
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 11)}>
                                                    <td>Role Permission</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-user-role-permission-view"
                                                        checked={this.getViewValue("iam-user-role-permission-view", 11)} />
                                                        {this.state.allTransactionLockingList.includes('iam-user-role-permission-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-user-role-permission-edit"
                                                        checked={this.getViewValue("iam-user-role-permission-edit", 11)} />
                                                        {this.state.allTransactionLockingList.includes('iam-user-role-permission-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">

                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>

                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 12)}>
                                                    <td>Group Notification Setting</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-notification-setting-view"
                                                        checked={this.getViewValue("iam-notification-setting-view", 12)} />
                                                        {this.state.allTransactionLockingList.includes('iam-notification-setting-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-notification-setting-update"
                                                        checked={this.getViewValue("iam-notification-setting-update", 12)} />
                                                        {this.state.allTransactionLockingList.includes('iam-notification-setting-update') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 13)}>
                                                    <td>License Management</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-license-management-view"
                                                        checked={this.getViewValue("iam-license-management-view", 13)} />
                                                        {this.state.allTransactionLockingList.includes('iam-license-management-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 32)}>
                                                    <td>Transaction Delay Setting</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-configuration-transaction-setting" checked={this.getViewValue("iam-configuration-transaction-setting", 32)} /> {this.state.allTransactionLockingList.includes('iam-configuration-transaction-setting') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 33)}>
                                                    <td>Ticket Configuration</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-configuration-ticket-setting" checked={this.getViewValue("iam-configuration-ticket-setting", 33)} /> {this.state.allTransactionLockingList.includes('iam-configuration-ticket-setting') ? <span className="require require_alignment"> </span> : null}</td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        {this.props.group_permission && this.props.group_permission.group_sub_modules && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow")
                                            ? <tr>
                                                <Ax>
                                                    <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 46)}>
                                                        <td>Taskflow Configuration</td>
                                                        <td className="text-center"><AllCheckerCheckbox /></td>
                                                        <td className="text-center"><Checkbox value="iam-task-workflow-configuration" checked={this.getViewValue("iam-task-workflow-configuration", 46)} /> {this.state.allTransactionLockingList.includes('iam-task-workflow-configuration') ? <span className="require require_alignment"> </span> : null}</td>
                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                    </CheckboxGroup>
                                                </Ax>
                                            </tr>
                                            : null}

                                    </tbody>
                                </table>
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>Access Shift Counter Log</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 14)}>
                                                    <td>Counter Log</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-shift-counterlog-add"
                                                        checked={this.getViewValue("iam-shift-counterlog-add", 14)} />
                                                        {this.state.allTransactionLockingList.includes('iam-shift-counterlog-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-shift-counterlog-edit"
                                                        checked={this.getViewValue("iam-shift-counterlog-edit", 14)} />
                                                        {this.state.allTransactionLockingList.includes('iam-shift-counterlog-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-shift-counterlog-delete"
                                                        checked={this.getViewValue("iam-shift-counterlog-delete", 14)} />
                                                        {this.state.allTransactionLockingList.includes('iam-shift-counterlog-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 20)}>
                                                    <td>Fuel Feedin</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-feedin-add"
                                                        checked={this.getViewValue("iam-fuel-feedin-add", 20)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-feedin-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-feedin-update"
                                                        checked={this.getViewValue("iam-fuel-feedin-update", 20)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-feedin-update') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-feedin-delete"
                                                        checked={this.getViewValue("iam-fuel-feedin-delete", 20)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-feedin-delete') ? <span className="require require_alignment"> </span> :
                                                            <span className="require_alignment"> </span>
                                                        }
                                                    </td>
                                                    <td className="text-center"> </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 15)}>
                                                    <td>Opening Fuel Balance</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-counterlog-fuel-balance-add"
                                                        checked={this.getViewValue("iam-counterlog-fuel-balance-add", 15)} />
                                                        {this.state.allTransactionLockingList.includes('iam-counterlog-fuel-balance-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-counterlog-fuel-balance-delete"
                                                        checked={this.getViewValue("iam-counterlog-fuel-balance-delete", 15)} />
                                                        {this.state.allTransactionLockingList.includes('iam-counterlog-fuel-balance-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 16)}>
                                                    <td>Expense</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-shift-counterlog-expense-add"
                                                        checked={this.getViewValue("iam-shift-counterlog-expense-add", 16)} />
                                                        {this.state.allTransactionLockingList.includes('iam-shift-counterlog-expense-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-expense-update"
                                                        checked={this.getViewValue("iam-asset-expense-update", 16)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-expense-update') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-expense-delete"
                                                        checked={this.getViewValue("iam-asset-expense-delete", 16)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-expense-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 17)}>
                                                    <td>Productivity</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-productivity-add"
                                                        checked={this.getViewValue("iam-asset-productivity-add", 17)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-productivity-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-productivity-update"
                                                        checked={this.getViewValue("iam-asset-productivity-update", 17)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-productivity-update') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-productivity-delete"
                                                        checked={this.getViewValue("iam-asset-productivity-delete", 17)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-productivity-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 18)}>
                                                    <td>Asset Activity</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-activity-add"
                                                        checked={this.getViewValue("iam-asset-activity-add", 18)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-activity-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-activity-update"
                                                        checked={this.getViewValue("iam-asset-activity-update", 18)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-activity-update') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-activity-delete"
                                                        checked={this.getViewValue("iam-asset-activity-delete", 18)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-activity-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 19)}>
                                                    <td>Service Checksheet</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-scs-execute"
                                                        checked={this.getViewValue("iam-asset-scs-execute", 19)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-scs-execute') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-scs-execute-delete"
                                                        checked={this.getViewValue("iam-asset-scs-execute-delete", 19)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-scs-execute-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li className="dropdown-item" style={{ display: "flex" }} ><Checkbox value="iam-asset-scs-custom-add"
                                                                    checked={this.getViewValue("iam-asset-scs-custom-add", 19)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-asset-scs-custom-add') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2">Add Custom SCS</span> </li>
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="iam-asset-scs-setting-update"
                                                                    checked={this.getViewValue("iam-asset-scs-setting-update", 19)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-asset-scs-setting-update') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2">SCS Setting</span></li>
                                                            </ul></span>
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        {this.props.group_permission && this.props.group_permission.group_sub_modules && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow")
                                            ? <tr>
                                                <Ax>
                                                    <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 47)}>
                                                        <td>Task</td>
                                                        <td className="text-center"><AllCheckerCheckbox /></td>
                                                        <td className="text-center"><Checkbox value="iam-task-workflow-execution" checked={this.getViewValue("iam-task-workflow-execution", 47)} /> {this.state.allTransactionLockingList.includes('iam-task-workflow-execution') ? <span className="require require_alignment"> </span> : null}</td>
                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                    </CheckboxGroup>
                                                </Ax>
                                            </tr>
                                            : null}

                                    </tbody>
                                </table>
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>Group Data</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 21)}>
                                                    <td>Site</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-site-add"
                                                        checked={this.getViewValue("iam-site-add", 21)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-site-edit"
                                                        checked={this.getViewValue("iam-site-edit", 21)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <span className="">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="iam-site-status-change"
                                                                    checked={this.getViewValue("iam-site-status-change", 21)} />
                                                                    {this.state.allTransactionLockingList.includes('iam-site-status-change') ? <span className="require require_alignment"> </span> : null}
                                                                    <span className="mx-2">Change Status</span></li>


                                                            </ul>
                                                        </span>
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 22)}>
                                                    <td>Site Address</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-site-address-view"
                                                        checked={this.getViewValue("iam-site-address-view", 22)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-address-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-site-address-add"
                                                        checked={this.getViewValue("iam-site-address-add", 22)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-address-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-site-address-edit"
                                                        checked={this.getViewValue("iam-site-address-edit", 22)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-address-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-site-address-delete"
                                                        checked={this.getViewValue("iam-site-address-delete", 22)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-address-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 60)}>
                                                <td>Vendor</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="vendor-view"
                                                        checked={this.getViewValue("vendor-view", 60)} />
                                                    {this.state.allTransactionLockingList.includes('vendor-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="vendor-add"
                                                        checked={this.getViewValue("vendor-add", 60)} />
                                                    {this.state.allTransactionLockingList.includes('vendor-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="vendor-edit"
                                                    checked={this.getViewValue("vendor-edit", 60)} />
                                                    {this.state.allTransactionLockingList.includes('vendor-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"> </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="vendor-change-status"
                                                            checked={this.getViewValue("vendor-change-status", 60)} />
                                                            {this.state.allTransactionLockingList.includes('vendor-change-status') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="vendor-manage-address"
                                                            checked={this.getViewValue("vendor-manage-address", 60)} />
                                                            {this.state.allTransactionLockingList.includes('vendor-manage-address') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Manage Address</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="vendor-manage-contact"
                                                            checked={this.getViewValue("vendor-manage-contact", 60)} />
                                                            {this.state.allTransactionLockingList.includes('vendor-manage-contact') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Manage Contact</span>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 48)}>
                                                    <td>Workstation</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-workstation-add"
                                                        checked={this.getViewValue("iam-workstation-add", 48)} />
                                                        {this.state.allTransactionLockingList.includes('iam-workstation-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-workstation-edit"
                                                        checked={this.getViewValue("iam-workstation-edit", 48)} />
                                                        {this.state.allTransactionLockingList.includes('iam-workstation-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>

                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>Document</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Download</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 23)}>
                                                    <td>Site</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-site-document-view"
                                                        checked={this.getViewValue("iam-site-document-view", 23)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-document-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-site-document-add"
                                                        checked={this.getViewValue("iam-site-document-add", 23)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-document-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-site-document-download"
                                                        checked={this.getViewValue("iam-site-document-download", 23)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-document-download') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-site-document-delete"
                                                        checked={this.getViewValue("iam-site-document-delete", 23)} />
                                                        {this.state.allTransactionLockingList.includes('iam-site-document-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"> </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 24)}>
                                                    <td>Shift</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-counterlog-doc-view"
                                                        checked={this.getViewValue("iam-asset-counterlog-doc-view", 24)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-counterlog-doc-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-counterlog-doc-add"
                                                        checked={this.getViewValue("iam-asset-counterlog-doc-add", 24)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-counterlog-doc-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-counterlog-doc-download"
                                                        checked={this.getViewValue("iam-asset-counterlog-doc-download", 24)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-counterlog-doc-download') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-counterlog-doc-delete"
                                                        checked={this.getViewValue("iam-asset-counterlog-doc-delete", 24)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-counterlog-doc-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 40)}>
                                                    <td>Asset-Document</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-asset-doc-view"
                                                        checked={this.getViewValue("iam-asset-doc-view", 40)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-doc-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-doc-add"
                                                        checked={this.getViewValue("iam-asset-doc-add", 40)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-doc-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-doc-download"
                                                        checked={this.getViewValue("iam-asset-doc-download", 40)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-doc-download') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-asset-doc-delete"
                                                        checked={this.getViewValue("iam-asset-doc-delete", 40)} />
                                                        {this.state.allTransactionLockingList.includes('iam-asset-doc-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"> </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>Inventory Module</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 45)}>
                                                    <td>Item</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="isc-item-view"
                                                        checked={this.getViewValue("isc-item-view", 45)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-view') ? <span className="require require_alignment"> </span> : null} </td>
                                                    <td className="text-center">
                                                        <Checkbox value="isc-item-add"
                                                            checked={this.getViewValue("isc-item-add", 45)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="isc-item-edit"
                                                        checked={this.getViewValue("isc-item-edit", 45)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">  <span className="">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-item-opening-balance"
                                                                checked={this.getViewValue("isc-item-opening-balance", 45)} />
                                                                {this.state.allTransactionLockingList.includes('isc-item-opening-balance') ? <span className="require require_alignment"> </span> : null}
                                                                <span className="mx-2">Item Opening Balance</span></li>
                                                            <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-item-Interchangeable-edit"
                                                                checked={this.getViewValue("isc-item-Interchangeable-edit", 45)} />
                                                                {this.state.allTransactionLockingList.includes('isc-item-Interchangeable-edit') ? <span className="require require_alignment"> </span> : null}
                                                                <span className="mx-2">Item Interchangeable</span></li>


                                                        </ul>
                                                    </span>
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 49)}>
                                                    <td>Item Tags</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <Checkbox value="isc-item-tag-add"
                                                            checked={this.getViewValue("isc-item-tag-add", 49)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-tag-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="isc-item-tag-edit"
                                                        checked={this.getViewValue("isc-item-tag-edit", 49)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-tag-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="isc-item-tag-delete"
                                                        checked={this.getViewValue("isc-item-tag-delete", 49)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-tag-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">  <span className="">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-item-tag-deactivate"
                                                                checked={this.getViewValue("isc-item-tag-deactivate", 49)} />
                                                                {this.state.allTransactionLockingList.includes('isc-item-tag-deactivate') ? <span className="require require_alignment"> </span> : null}
                                                                <span className="mx-2">Deactivate Tags</span></li>
                                                        </ul>
                                                    </span>
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 50)}>
                                                    <td>Item Manufacturer</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <Checkbox value="isc-item-manufacturer-add"
                                                            checked={this.getViewValue("isc-item-manufacturer-add", 50)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-manufacturer-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="isc-item-manufacturer-edit"
                                                        checked={this.getViewValue("isc-item-manufacturer-edit", 50)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-manufacturer-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="isc-item-manufacturer-delete"
                                                        checked={this.getViewValue("isc-item-manufacturer-delete", 50)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-manufacturer-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"> </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                       
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 51)}>
                                                    <td>Item UOM</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <Checkbox value="isc-item-uom-add"
                                                            checked={this.getViewValue("isc-item-uom-add", 51)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-uom-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="isc-item-uom-edit"
                                                        checked={this.getViewValue("isc-item-uom-edit", 51)} />
                                                        {this.state.allTransactionLockingList.includes('isc-item-uom-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td></td>
                                                    <td className="text-center">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-item-uom-deactivate"
                                                                checked={this.getViewValue("isc-item-uom-deactivate", 51)} />
                                                                {this.state.allTransactionLockingList.includes('isc-item-uom-deactivate') ? <span className="require require_alignment"> </span> : null}
                                                                <span className="mx-2">Change Status</span></li>



                                                        </ul>
                                                    </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 52)}>
                                                <td>Warehouse Indent</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-warehouse-indent-view"
                                                        checked={this.getViewValue("isc-warehouse-indent-view", 52)} />
                                                    {this.state.allTransactionLockingList.includes('isc-warehouse-indent-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-warehouse-indent-add"
                                                        checked={this.getViewValue("isc-warehouse-indent-add", 52)} />
                                                    {this.state.allTransactionLockingList.includes('isc-warehouse-indent-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-warehouse-indent-edit"
                                                    checked={this.getViewValue("isc-warehouse-indent-edit", 52)} />
                                                    {this.state.allTransactionLockingList.includes('isc-warehouse-indent-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-warehouse-indent-delete"
                                                    checked={this.getViewValue("isc-warehouse-indent-delete", 52)} />
                                                    {this.state.allTransactionLockingList.includes('isc-warehouse-indent-delete') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-warehouse-indent-status_change"
                                                            checked={this.getViewValue("isc-warehouse-indent-status_change", 52)} />
                                                            {this.state.allTransactionLockingList.includes('isc-warehouse-indent-status_change') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span></li>

                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-warehouse-indent-cancel_item"
                                                            checked={this.getViewValue("isc-warehouse-indent-cancel_item", 52)} />
                                                            {this.state.allTransactionLockingList.includes('isc-warehouse-indent-cancel_item') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Cancel Item</span></li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 53)}>
                                                <td>Purchase Requisition</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-purchase-requisition-view"
                                                        checked={this.getViewValue("isc-purchase-requisition-view", 53)} />
                                                    {this.state.allTransactionLockingList.includes('isc-purchase-requisition-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-purchase-requisition-add"
                                                        checked={this.getViewValue("isc-purchase-requisition-add", 53)} />
                                                    {this.state.allTransactionLockingList.includes('isc-purchase-requisition-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-purchase-requisition-edit"
                                                    checked={this.getViewValue("isc-purchase-requisition-edit", 53)} />
                                                    {this.state.allTransactionLockingList.includes('isc-purchase-requisition-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-purchase-requisition-delete"
                                                    checked={this.getViewValue("isc-purchase-requisition-delete", 53)} />
                                                    {this.state.allTransactionLockingList.includes('isc-purchase-requisition-delete') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-purchase-requisition-status-change"
                                                            checked={this.getViewValue("isc-purchase-requisition-status-change", 53)} />
                                                            {this.state.allTransactionLockingList.includes('isc-purchase-requisition-status-change') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span></li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-purchase-requisition-status-manage"
                                                            checked={this.getViewValue("isc-purchase-requisition-status-manage", 53)} />
                                                            {this.state.allTransactionLockingList.includes('isc-purchase-requisition-status-manage') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Workflow Status</span></li>

                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-purchase-requisition-cancel_item"
                                                            checked={this.getViewValue("isc-purchase-requisition-cancel_item", 53)} />
                                                            {this.state.allTransactionLockingList.includes('isc-purchase-requisition-cancel_item') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Cancel Item</span></li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 54)}>
                                                <td>Purchase Order</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-po-view"
                                                        checked={this.getViewValue("isc-po-view", 54)} />
                                                    {this.state.allTransactionLockingList.includes('isc-po-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-po-add"
                                                        checked={this.getViewValue("isc-po-add", 54)} />
                                                    {this.state.allTransactionLockingList.includes('isc-po-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-po-edit"
                                                    checked={this.getViewValue("isc-po-edit", 54)} />
                                                    {this.state.allTransactionLockingList.includes('isc-po-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"> </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-po-status-change"
                                                            checked={this.getViewValue("isc-po-status-change", 54)} />
                                                            {this.state.allTransactionLockingList.includes('isc-po-status-change') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span></li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-po-status-manage"
                                                            checked={this.getViewValue("isc-po-status-manage", 54)} />
                                                            {this.state.allTransactionLockingList.includes('isc-po-status-manage') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Workflow Status</span></li>

                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-po-cancel_item"
                                                            checked={this.getViewValue("isc-po-cancel_item", 54)} />
                                                            {this.state.allTransactionLockingList.includes('isc-po-cancel_item') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Cancel Item</span></li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 55)}>
                                                <td>Purchase</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-purchase-view"
                                                        checked={this.getViewValue("isc-purchase-view", 55)} />
                                                    {this.state.allTransactionLockingList.includes('isc-purchase-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-purchase-add"
                                                        checked={this.getViewValue("isc-purchase-add", 55)} />
                                                    {this.state.allTransactionLockingList.includes('isc-purchase-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-purchase-edit"
                                                    checked={this.getViewValue("isc-purchase-edit", 55)} />
                                                    {this.state.allTransactionLockingList.includes('isc-purchase-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-purchase-delete"
                                                    checked={this.getViewValue("isc-purchase-delete", 55)} />
                                                    {this.state.allTransactionLockingList.includes('isc-purchase-delete') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-purchase-status-change"
                                                            checked={this.getViewValue("isc-purchase-status-change", 55)} />
                                                            {this.state.allTransactionLockingList.includes('isc-purchase-status-change') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-purchase-status-manage"
                                                            checked={this.getViewValue("isc-purchase-status-manage", 55)} />
                                                            {this.state.allTransactionLockingList.includes('isc-purchase-status-manage') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Workflow Status</span>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 56)}>
                                                <td>Allocation</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-allocation-view"
                                                        checked={this.getViewValue("isc-allocation-view", 56)} />
                                                    {this.state.allTransactionLockingList.includes('isc-allocation-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-allocation-add"
                                                        checked={this.getViewValue("isc-allocation-add", 56)} />
                                                    {this.state.allTransactionLockingList.includes('isc-allocation-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-allocation-edit"
                                                    checked={this.getViewValue("isc-allocation-edit", 56)} />
                                                    {this.state.allTransactionLockingList.includes('isc-allocation-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-allocation-delete"
                                                    checked={this.getViewValue("isc-allocation-delete", 56)} />
                                                    {this.state.allTransactionLockingList.includes('isc-allocation-delete') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-allocation-status-change"
                                                            checked={this.getViewValue("isc-allocation-status-change", 56)} />
                                                            {this.state.allTransactionLockingList.includes('isc-allocation-status-change') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-allocation-status-manage"
                                                            checked={this.getViewValue("isc-allocation-status-manage", 56)} />
                                                            {this.state.allTransactionLockingList.includes('isc-allocation-status-manage') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Workflow Status</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-allocation-item-cancel"
                                                            checked={this.getViewValue("isc-allocation-item-cancel", 56)} />
                                                            {this.state.allTransactionLockingList.includes('isc-allocation-item-cancel') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Cancel Item</span>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 57)}>
                                                <td>Consumption</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-consumption-view"
                                                        checked={this.getViewValue("isc-consumption-view", 57)} />
                                                    {this.state.allTransactionLockingList.includes('isc-consumption-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-consumption-add"
                                                        checked={this.getViewValue("isc-consumption-add", 57)} />
                                                    {this.state.allTransactionLockingList.includes('isc-consumption-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-consumption-edit"
                                                    checked={this.getViewValue("isc-consumption-edit", 57)} />
                                                    {this.state.allTransactionLockingList.includes('isc-consumption-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-consumption-delete"
                                                    checked={this.getViewValue("isc-consumption-delete", 57)} />
                                                    {this.state.allTransactionLockingList.includes('isc-consumption-delete') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-consumption-status-change"
                                                            checked={this.getViewValue("isc-consumption-status-change", 57)} />
                                                            {this.state.allTransactionLockingList.includes('isc-consumption-status-change') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-consumption-status-manage"
                                                            checked={this.getViewValue("isc-consumption-status-manage", 57)} />
                                                            {this.state.allTransactionLockingList.includes('isc-consumption-status-manage') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Workflow Status</span>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 58)}>
                                                <td>Inventory Transfer</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-inventory_transfer-view"
                                                        checked={this.getViewValue("isc-inventory_transfer-view", 58)} />
                                                    {this.state.allTransactionLockingList.includes('isc-inventory_transfer-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-inventory_transfer-add"
                                                        checked={this.getViewValue("isc-inventory_transfer-add", 58)} />
                                                    {this.state.allTransactionLockingList.includes('isc-inventory_transfer-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-inventory_transfer-edit"
                                                    checked={this.getViewValue("isc-inventory_transfer-edit", 58)} />
                                                    {this.state.allTransactionLockingList.includes('isc-inventory_transfer-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-inventory_transfer-delete"
                                                    checked={this.getViewValue("isc-inventory_transfer-delete", 58)} />
                                                    {this.state.allTransactionLockingList.includes('isc-inventory_transfer-delete') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-inventory_transfer-status-change"
                                                            checked={this.getViewValue("isc-inventory_transfer-status-change", 58)} />
                                                            {this.state.allTransactionLockingList.includes('isc-inventory_transfer-status-change') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-inventory_transfer-status-manage"
                                                            checked={this.getViewValue("isc-inventory_transfer-status-manage", 58)} />
                                                            {this.state.allTransactionLockingList.includes('isc-inventory_transfer-status-manage') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Workflow Status</span>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                        <tr>
                                            <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 59)}>
                                                <td>Inventory Adjustment</td>
                                                <td className="text-center"><AllCheckerCheckbox /></td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-inventory-adjustment-view"
                                                        checked={this.getViewValue("isc-inventory-adjustment-view", 59)} />
                                                    {this.state.allTransactionLockingList.includes('isc-inventory-adjustment-view') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <Checkbox value="isc-inventory-adjustment-add"
                                                        checked={this.getViewValue("isc-inventory-adjustment-add", 59)} />
                                                    {this.state.allTransactionLockingList.includes('isc-inventory-adjustment-add') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-inventory-adjustment-edit"
                                                    checked={this.getViewValue("isc-inventory-adjustment-edit", 59)} />
                                                    {this.state.allTransactionLockingList.includes('isc-inventory-adjustment-edit') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center"><Checkbox value="isc-inventory-adjustment-delete"
                                                    checked={this.getViewValue("isc-inventory-adjustment-delete", 59)} />
                                                    {this.state.allTransactionLockingList.includes('isc-inventory-adjustment-delete') ? <span className="require require_alignment"> </span> : null}
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-inventory-adjustment-status-change"
                                                            checked={this.getViewValue("isc-inventory-adjustment-status-change", 59)} />
                                                            {this.state.allTransactionLockingList.includes('isc-inventory-adjustment-status-change') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Change Status</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="isc-inventory-adjustment-status-manage"
                                                            checked={this.getViewValue("isc-inventory-adjustment-status-manage", 59)} />
                                                            {this.state.allTransactionLockingList.includes('isc-inventory-adjustment-status-manage') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Workflow Status</span>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </CheckboxGroup>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>HR Module</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 25)}>
                                                    <td> Employee</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="hrm-employee-view"
                                                        checked={this.getViewValue("hrm-employee-view", 25)} />
                                                        {this.state.allTransactionLockingList.includes('hrm-employee-view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="hrm-employee-add"
                                                        checked={this.getViewValue("hrm-employee-add", 25)} />
                                                        {this.state.allTransactionLockingList.includes('hrm-employee-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="hrm-employee-update"
                                                        checked={this.getViewValue("hrm-employee-update", 25)} />
                                                        {this.state.allTransactionLockingList.includes('hrm-employee-update') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"> </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 26)}>
                                                    <td>Employee Tags</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="hrm-employee-tag-add"
                                                        checked={this.getViewValue("hrm-employee-tag-add", 26)} />
                                                        {this.state.allTransactionLockingList.includes('hrm-employee-tag-add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="hrm-employee-tag-edit"
                                                        checked={this.getViewValue("hrm-employee-tag-edit", 26)} />
                                                        {this.state.allTransactionLockingList.includes('hrm-employee-tag-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="hrm-employee-tag-delete"
                                                        checked={this.getViewValue("hrm-employee-tag-delete", 26)} />
                                                        {this.state.allTransactionLockingList.includes('hrm-employee-tag-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 63)}>
                                                    <td>Employee Compliance</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="hrm_compliance_view"
                                                        checked={this.getViewValue("hrm_compliance_view", 63)} />
                                                        {this.state.allTransactionLockingList.includes('hrm_compliance_view') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="hrm_compliance_add"
                                                        checked={this.getViewValue("hrm_compliance_add", 63)} />
                                                        {this.state.allTransactionLockingList.includes('hrm_compliance_add') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="hrm_compliance_edit"
                                                        checked={this.getViewValue("hrm_compliance_edit", 63)} />
                                                        {this.state.allTransactionLockingList.includes('hrm_compliance_edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="hrm_compliance_delete"
                                                        checked={this.getViewValue("hrm_compliance_delete", 63)} />
                                                        {this.state.allTransactionLockingList.includes('hrm_compliance_delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="hrm_compliance_view_unmasked"
                                                            checked={this.getViewValue("hrm_compliance_view_unmasked", 63)} />
                                                            {this.state.allTransactionLockingList.includes('hrm_compliance_view_unmasked') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">View Imp Detail</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="hrm_compliance_doc_view"
                                                            checked={this.getViewValue("hrm_compliance_doc_view", 63)} />
                                                            {this.state.allTransactionLockingList.includes('hrm_compliance_doc_view') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Document View</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="hrm_compliance_doc_add"
                                                            checked={this.getViewValue("hrm_compliance_doc_add", 63)} />
                                                            {this.state.allTransactionLockingList.includes('hrm_compliance_doc_add') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Document Add</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="hrm_compliance_doc_delete"
                                                            checked={this.getViewValue("hrm_compliance_doc_delete", 63)} />
                                                            {this.state.allTransactionLockingList.includes('hrm_compliance_doc_delete') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Document Delete</span>
                                                        </li>
                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value="hrm_compliance_doc_download"
                                                            checked={this.getViewValue("hrm_compliance_doc_download", 63)} />
                                                            {this.state.allTransactionLockingList.includes('hrm_compliance_doc_download') ? <span className="require require_alignment"> </span> : null}
                                                            <span className="mx-2">Document Download</span>
                                                        </li>
                                                       
                                                    </ul>
                                                </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                               
                                <table className="table table-bordered table-hover bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>General Permissions</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 27)}>
                                                    <td>Fuel Ledger</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-ledger"
                                                        checked={this.getViewValue("hrm-employee-view", 27)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-ledger') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"> </td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 28)}>
                                                    <td>Receive Fuel (Fuel Station)</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-receive"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-receive", 28)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-receive') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-receive-edit"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-receive-edit", 28)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-receive-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-receive-delete"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-receive-delete", 28)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-receive-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 29)}>
                                                    <td>Issue Fuel (Fuel Station)</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-issue"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-issue", 29)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-issue') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-issue-edit"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-issue-edit", 29)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-issue-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-issue-delete"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-issue-delete", 29)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-issue-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>
                                        <tr>
                                            <Ax>
                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, 30)}>
                                                    <td>Fuel Transfer Within Fuel Station</td>
                                                    <td className="text-center"><AllCheckerCheckbox /></td>
                                                    <td className="text-center"></td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-transfer"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-transfer", 30)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-transfer') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-transfer-edit"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-transfer-edit", 30)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-transfer-edit') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"><Checkbox value="iam-fuel-station-fuel-transfer-delete"
                                                        checked={this.getViewValue("iam-fuel-station-fuel-transfer-delete", 30)} />
                                                        {this.state.allTransactionLockingList.includes('iam-fuel-station-fuel-transfer-delete') ? <span className="require require_alignment"> </span> : null}
                                                    </td>
                                                    <td className="text-center"></td>
                                                </CheckboxGroup>
                                            </Ax>
                                        </tr>

                                    </tbody>
                                </table>
                                <div className="text-end mb-3">
                                    <span className="small text-danger"> * Transaction Locking applicable in these permissions</span></div>
                                <div className="float-end">
                                    <button type="submit" className="btn btn-primary">Save Permissions</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="tab-pane " id="mobileapp" role="tabpanel" aria-labelledby="mobileapp-tab">
                    <div className="tab_content_header">
                        <AppPermission roleData={this.state.roleData} />
                    </div>
                </div>
                <div className="tab-pane " id="isc" role="tabpanel" aria-labelledby="isc-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Inventory Permission Details</span></div>
                        <table className="table table-bordered table-hover bg-white my-3">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" style={{ width: "30%" }}>Inventory</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                    <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="permission_row">
                                    <td>Item Add</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>
                                <tr className="permission_row">
                                    <td>Purchase Add</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>
                                <tr className="permission_row">
                                    <td>Consumption Add</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>
                                <tr className="permission_row">
                                    <td>Update Opening Balance</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>

                            </tbody>
                        </table>
                        <table className="table table-bordered table-hover bg-white my-3">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" style={{ width: "30%" }}>Configuration</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                    <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="permission_row">
                                    <td>Inventory Configuration</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="tab-pane " id="accounts" role="tabpanel" aria-labelledby="accounts-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Accounts Permission Details</span></div>
                        <table className="table table-bordered table-hover bg-white my-3">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" style={{ width: "30%" }}>Assets</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                    <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="permission_row">
                                    <td>Invoice Rule</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>
                                <tr className="permission_row">
                                    <td>Save Invoice Rule Calculation</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>
                                <tr className="permission_row">
                                    <td>Fund Transfer</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <table className="table table-bordered table-hover bg-white my-3">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" style={{ width: "30%" }}>Access Shift Counter Log</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                    <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="permission_row">
                                    <td>Asset Expense</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="tab-pane " id="hrm" role="tabpanel" aria-labelledby="hrm-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">HRM Permission Details</span></div>
                        <table className="table table-bordered table-hover bg-white my-3">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" style={{ width: "30%" }}>HRM</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                    <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="permission_row">
                                    <td>Employee</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>
                                <tr className="permission_row">
                                    <td>Employee Tags</td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"> <input type="checkbox" />  </td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                    <td className="text-center"><input type="checkbox" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </section>);
    }

    render() {
        // let pageScreen = null;
        // if(this.state.roleAddScreenView) {
        //     pageScreen  =   <AddRole closeEvent={this.addNewRoleScreenClose}/>
        // } else if(this.state.roleUpdateScreenView) {
        //     pageScreen  =   "Update"
        // } else {
        //     pageScreen  = this.roleListjsx();
        // }

        return (
            <ApplicationLayout>
                  <Helmet><title>Roles List</title></Helmet>
                <ToastContainer/>
                {
                   this.roleListjsx()
                }
                {this.updateRoleModalJsx()}
                {this.updateVisibilityModalJsx()}
                {this.updateTransactionModalJsx()}
                {this.updatePermissionModalJsx()}

            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};


export default connect(mapStateToProps)(RoleList);