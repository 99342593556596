import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon"
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class WalletConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

          this.initUpdateUsers             =        {
              users                       :         []
          }

          this.state                         =       {
            list_loading                   :       false,
            listingData                    :       [],
            allUsers                       :       [],
            form_loading                   :       false,
            formData                       :       [],
            editFormSubmiting              :       false,
            updateAdministratorForm        :       {...this.initUpdateUsers}
        };
    }

    componentDidMount() {
        this.getWalletConfigurationList();
        this.updateAdministrator        =        new Modal(document.getElementById('updateAdministratorModal'), {keyboard: false, backdrop :false});
    }

    getWalletConfigurationList             =         ()    =>  {
        this.setState({list_loading : true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/wallet/configuration/admin', this.props.access_token , {}, {}, (resp) => {
            this.setState({listingData  : resp.data.data})
        }).then(() => this.setState({list_loading: false}));
    }

    getUpdateAdministratorFormData  =    ()            =>    {
        this.setState({form_loading: true, });
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/wallet/configuration/admin_form_data', this.props.access_token, {}, {}, (resp) => {
            let selectedUsers = this.state.listingData.map(u => u.id)
            let users = resp.data.users.filter(u => selectedUsers.includes(u.id))
            this.setState({
                formData: resp.data,
                allUsers: resp.data.users.map((s) => { return { value: s.id, label: s.full_name } }),
                updateAdministratorForm: {
                    users: users.map(u => u.id)
                }
            })
         }).then(() => this.setState({form_loading: false}));
    }

    updateAdministratorInit          =    ()           =>     {
        this.updateAdministrator.show();
        this.getUpdateAdministratorFormData()
    }
    
    updateAdministratorModalJsx       =         ()        =>   {
        return (
            <div className="modal fade" id="updateAdministratorModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateAdministratorModal">Update Wallet Administrator</h5>
                        </div>
                        {this.state.form_loading ? <Loader/> 
                        : (<form onSubmit={this.submitUpdateAdministratorForm}>
                            <div className="modal-body p-3">

                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-4"><label className="form-label">Wallet Administrators</label></div>
                                    <div className="col-md-8">
                                        <TapSelect
                                            options = {this.state.allUsers}  
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'updateAdministratorForm', 'users')}
                                            value={this.state.allUsers.filter(u => this.state?.updateAdministratorForm?.users.includes(u.value))}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            isMulti={true}
                                            placeholder="Please Select Users"
                                            containerHeight="33px"
                                            fontSize="93%" />
                                            <div className=" form-text">
                                                <sup className="text-muted fs11">#</sup>
                                                Only Wallet Administrator will be able to edit wallet and change wallet visibility.All the wallets are visible to
                                                Wallet Adminsitrator.
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>)}
                    </div>
                </div>
            </div>
        );
    }

    submitUpdateAdministratorForm     =         (e)        =>   {
        e.preventDefault()
        this.setState({ editFormSubmiting: true })
        HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/wallet/configuration/admin', this.props.access_token, {}, { ...this.state.updateAdministratorForm}, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateAdministrator.hide()
            this.getWalletConfigurationList()
        }).then(() => this.setState({ editFormSubmiting: false }));
    }

    render() {

        return (
            <section className="container-fluid" style={{ padding: "0px 12px" }}>
                <div className=" row bg-white p-2">
                    <div className="col-12">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Wallet Administrator Configuration</span>
                            <div className="text-end "><button className="btn btn-primary" disabled={this.state.list_loading} onClick={() => this.updateAdministratorInit()}>Update </button></div>
                        </div>
                        <table className="table table-hover table-bordered my-4 ">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "10%" }}>S.No</th>
                                    <th scope="col" className="text-start">Wallet Administrator</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.list_loading ? (<tr><td colSpan="2"><Loader /></td></tr>)
                                    : (this.state.listingData.length > 0
                                        ? (this.state.listingData.map((g, k) => {
                                            return (<tr key={k} className="text-center">
                                                <td>{k + 1}</td>
                                                <td className="text-start">{g.full_name} {g.user_type == "g" ? <sup className="text-muted">*</sup> : null}</td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="3" className="text-center">No Records</td></tr>))}
                            </tbody>
                        </table>
                      
                    </div>
                    <div className=" col-12 form-text">
                      <sup className=" fs11">#</sup>
                         Only Wallet Administrator will be able to edit wallet and change wallet visibility.All the wallets are visible to
                        Wallet Adminsitrator.
                    </div>
                </div>
                {this.updateAdministratorModalJsx()}

            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(WalletConfiguration);