export const APP_BASE_URL             =   process.env.REACT_APP_API_SERVER;
export const ISC_API_BASE_URL         =   process.env.REACT_APP_ISC_API_SERVER;
export const INVENTORY_API_BASE_URL   =   process.env.REACT_APP_INVENTORY_API_SERVER;
export const IAM_API_BASE_URL         =   process.env.REACT_APP_IAM_API_SERVER;
export const ORG_API_BASE_URL         =   process.env.REACT_APP_API_SERVER + '/org';

export const API_SERVER_2             =   process.env.REACT_APP_API_SERVER_2;

export const APP_BASE_URL_2           =   process.env.REACT_APP_API_SERVER_2;
export const IAM_API_BASE_URL_2       =   process.env.REACT_APP_API_SERVER_4 + '/iam';
export const ISC_API_BASE_URL_2       =   process.env.REACT_APP_API_SERVER_2 + '/isc';
export const ORG_API_BASE_URL_2       =   process.env.REACT_APP_API_SERVER_2 + '/org';
export const HRM_API_BASE_URL_2       =   process.env.REACT_APP_API_SERVER_2 + '/hrm';
export const SCHEDULE_API_BASE_URL_2  =   process.env.REACT_APP_API_SERVER_2 + '/schedule';
export const INVENTORY_API_BASE_URL_2 =   process.env.REACT_APP_API_SERVER_2 + '/inventory';


//url for fund transfer
const TapApiUrls = () => {
  return {
    IAM                           :   IAM_API_BASE_URL,
  }
}

export default TapApiUrls()