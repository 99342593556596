import React from "react";
import AppBaseComponent from "../../components/AppBaseComponent";
import TapSelect from "../../components/ui/TapSelect";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { Modal } from "bootstrap";
import DatePicker from "react-datepicker";
import tapIcon from "../../services/TapIcon";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Ax from "../../components/hoc/Ax";
import InputAssetSearch from "../includes/ui/InputAssetSearch";
import { IAM_API_BASE_URL_2 } from "../../services/TapApiUrls";
import HttpAPICall from "../../services/HttpAPICall";
import Loader from "../../components/ui/Loader/Loader";
import InputVendorSearch from "../includes/ui/InputVendorSearch";
import InputEmployeeSearch from "../includes/ui/InputEmployeeSearch";
import TapHelper from "../../services/TapHelper";
import { Helmet } from 'react-helmet';

class ExpenseAddModal extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.expenseAddInitState        =   {
      expense_date                      :   moment(new Date()).format("YYYY-MM-DD"),
      details                           :   "",
      payee                             :   "other",
      payee_id                          :   "",
      payee_name                        :   "",
      wallet_id                         :   "",
      reference_number                  :   "",
      notes                             :   "",
      base64_document                   :   "",
      document_name                     :   "",
    };

    this.expenseAddFormLabel        =   {};

    this.state                      =   {
      modalLoading                      :   false,
      formButtonDis                     :   true,
      expense_heads_input               :   [{}],
      expenseTypeData                   :   [],
      expenseAddFormData                :   { ...this.expenseAddInitState },
      expenseType                       :   "Asset",
      modalData                         :   { ticetId: null, assetName: null, assetType: null },
      userSite                          :   [],
      expenseHeadData                   :   [],
      assets                            :   [],
      vendor_access                     :   "N",
      employee_access                   :   "N",
      allPayeeTypes                     :   [],
      searchedEmployee                  :   null,
      searchedVendor                    :   null,
      allWalletList                     :   [],
      showMore                          :   false,
      expense_details                   :   [{ head: null, amount: null }],
      addExpenseFormSubmitting          :   false,
      editData                          :   false
    };

    this.expenseModalId                 =   uuid();
    this.expenseUrl                     =   IAM_API_BASE_URL_2 + "/expense";
  }

    componentDidMount() {
        this.expenseModalInit       =   new Modal(document.getElementById(this.expenseModalId), { keyboard: false, backdrop: false });
        // this.formDataInit(this.props)
    }

  expenseModalShow              = (type = null, data = {},editData=null) => {

    const path                  =   this.expenseUrl + '/form_data';
   
    this.setState({
        addExpenseFormSubmitting    :   true,
        userSite                    :   this.props.iam_user_sites ? this.props.iam_user_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` };}): [],
        expenseType                 :   type,
        showMore                    :   editData ? true : false,
        expense_details             :   editData && editData.expense_detail && editData.expense_detail.length > 0 ? editData.expense_detail : [{ head: null, amount: null }],
        modalData                   :   { ...this.state.modalData, ...data },
        expenseAddFormData          :   {
            ...this.state.expenseAddFormData, 
            site_id             :   data?.siteId,
            transaction_id                     :   editData && editData.transaction_id ? editData.transaction_id : '',
            expense_date                      :   editData && editData.expense_date ? moment(new Date(editData.expense_date)).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
            details                           :   editData && editData.details ? editData.details : "",
            payee                             :   editData && editData.payee ? editData.payee : "other",
            payee_id                          :   editData && editData.payee_id ? editData.payee_id : "",
            payee_name                        :   editData && editData.payee_name ? editData.payee_name : "",
            wallet_id                         :   editData && editData.wallet_id ? editData.wallet_id : "",
            reference_number                  :   editData && editData.reference_number ? editData.reference_number : "",
            notes                             :   editData && editData.notes ? editData.notes : "",
            base64_document                   :   "",
            document_name                     :   "",
        },
        editData                    :   editData ? true : false,
        searchedEmployee            :   editData && editData.employeeData ? {value: editData.employeeData.id,label:editData.employeeData.display_full_name} : null,
        searchedVendor              :   editData && editData.vendorData ?  {value : editData.vendorData.id  ,display_label : editData.vendorData.name } : null,
        modalLoading                :   true,
      },() => {
        this.expenseModalInit.show();
        if(editData && editData.expense_detail && editData.expense_detail.length > 0){
            editData.expense_detail.forEach((ed) => {
                this.handleExpenseHeadsInput()
            })
        }
        this.setState({
            formButtonDis           :   false,
        })
    });

    HttpAPICall.withAthorization("GET", path, this.props.access_token, null, null, (response) => {
        const formData = response.data;
        this.setState({
            expenseHeadData         :   formData.heads ? formData.heads.map((h) => { return { value: h.id, label: `${h.expense_head}` };}) : [],
            allWalletList           :   formData.wallets && formData.wallets.length > 0 ? formData.wallets.map((wallet) => { return { label:    wallet.wallet_name, value: wallet.id }; }) : [],
            allPayeeTypes           :   formData.payee_type && formData.payee_type.length > 0 ? formData.payee_type.map((payee) => { return { label:  payee, value: payee }; }) : [],
            employee_access         :   formData.employee_access,
            vendor_access           :   formData.vendor_access,
          },() => {
            this.setState({ 
                modalLoading                : false,
                addExpenseFormSubmitting    : false,
            });
        });
      });
    };

    expenseDataSubmit               =   (e) =>  {
        e.preventDefault();
       
        this.setState({
            addExpenseFormSubmitting    : true,
        })

        let data            =   {
            ...this.state.expenseAddFormData, expense_detail : this.state.expense_details, ticket_id : this.state.modalData.ticetId
        };
       
       if(!this.state.editData){
            HttpAPICall.withAthorization("POST", `${this.expenseUrl}/ticket/add`, this.props.access_token, null, data, (response) => {
                this.setState({
                    expenseAddFormData          :   {...this.state.expenseAddFormData, ...this.expenseAddInitState, expense_details             :   [{ head: null, amount: null }], },
                    expense_details             :   [{ head: null, amount: null}],
                    expense_heads_input         :   [{}],
                    showMore                    :   false,
                })            
                this.props.afterSubmitFormModal(this.state.modalData.ticetId);
                this.props.ticketDetail();
                this.expenseModalInit.hide();
                document.getElementById(`${this.expenseModalId}Form`).reset();
            }).then(() => this.setState({ addExpenseFormSubmitting: false }))
       }else{
            HttpAPICall.withAthorization("PUT", `${this.expenseUrl}/ticket/edit`, this.props.access_token, null, data, (response) => {
                this.setState({
                    expenseAddFormData          :   {...this.state.expenseAddFormData, ...this.expenseAddInitState, expense_details             :   [{ head: null, amount: null }], },
                    expense_details             :   [{ head: null, amount: null}],
                    expense_heads_input         :   [{}],
                    showMore                    :   false,
                }) 
                document.getElementById(`${this.expenseModalId}Form`).reset();           
                this.props.afterSubmitFormModal(this.state.modalData.ticetId);
                this.expenseModalInit.hide();
            }).then(() => this.setState({ addExpenseFormSubmitting: false }))
       }
    }

    expenseAddModalJsx = () => {
        return (
            <div className="modal fade" id={this.expenseModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="expenseAddModalLabel"> {this.state.editData ? "Edit" : "Add"} {this.state.expenseType} Expense</h5>
                            <button type="button" className="btn-close" onClick={() => this.expenseModalInit.hide()}></button>
                        </div>
                        <div className="row mx-2 mt-2">
                            <table className="table table-sm mb-0 table-borderless text-sm">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>&nbsp;&nbsp;Ticket ID</td>
                                        <th>{this.state.modalData.ticetId !== null ? this.state.modalData.ticetId : ""}</th>
                                    </tr>
                                    {this.state.modalData && this.state.modalData.assetName && <Ax>
                                        <tr>
                                            <td style={{ width: "25%" }}>&nbsp;&nbsp;Asset Name (Code)</td>
                                            <th>{this.state.modalData.assetName}</th>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;&nbsp;Asset Type</td>
                                            <th>{this.state.modalData.assetType}</th>
                                        </tr>
                                    </Ax>}
                                    <tr>
                                        <td>&nbsp;&nbsp;Site Name</td>
                                        <th>{this.state.modalData.siteName}</th>
                                    </tr>
                                </tbody>
                            </table>
                            {this.state.expenseType !== "Ticket" ? (
                                <Ax>
                                    <div className="row mb-3 mt-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm text-left require">Asset Name</label>
                                        <div className="col-sm-7">
                                            <InputAssetSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, "expenseAddFormData", "asset_ids");
                                                    this.setState({
                                                        assets: selectedOption ? [selectedOption] : null,
                                                    });
                                                }}
                                                value={this.state.assets && this.state.assets.length > 0 ? this.state.assets.map((a) => {
                                                    a.label = a.display_label;
                                                    return a;
                                                }) : []
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm text-left require">Site Name</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                options={this.state.userSite}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, "expenseAddFormData", "site_id")}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                required={true}
                                                value={this.state.userSite.filter((s) => this.state.expenseAddFormData.site_id === s.value ? s.label : "")}
                                                placeholder="Select Site"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                </Ax>)
                                : ("")
                            }
                        </div>
                        <hr />
                        {
                            this.state.modalLoading ? (<Loader />)
                                : (
                                    <div className="modal-body">
                                        <form className="bg-white" onSubmit={this.expenseDataSubmit} id={`${this.expenseModalId}Form`}>
                                            <div className="row mb-3">
                                                <label className="col-sm-3 col-form-label col-form-label-sm text-left require">Expense Date</label>
                                                <div className="col-sm-7 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.expenseAddFormData.expense_date ? moment(this.state.expenseAddFormData.expense_date, "YYYY-MM-DD").toDate() : false
                                                        }
                                                        name="expense_date"
                                                        onChange={(value, event) => this.formDateHandler("expense_date", value, "expenseAddFormData")}
                                                        maxDate={moment(new Date(), "YYYY-MM-DD").toDate()}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control form-control-sm"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        required={true}
                                                        placeholderText={`Please Enter Date`}
                                                    />
                                                    <tapIcon.imageIcon
                                                        icon={tapIcon.CalenderIcon}
                                                        alt="purchase_date"
                                                        className="add_calender-icon"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                {this.state.expense_heads_input.map((data, i) => {
                                                    return (
                                                        <Ax>
                                                            <label className={`col-sm-3 col-form-label col-form-label-sm text-left ${i > 0 ? "" : "require"}`}>
                                                                {i > 0 ? "" : "Expense Head"}
                                                            </label>
                                                            <div className={`col-sm-4`}>
                                                                <TapSelect
                                                                    options={this.state.expenseHeadData}
                                                                    changeEvent={(selectedOption, e) => {
                                                                        selectedOption?.value &&
                                                                            this.dynamicInputHandlerByKeyNameVal(selectedOption?.value, "head", i, "expense_details");
                                                                    }}
                                                                    value={this.state.expenseHeadData.find((s) => s.value == this.state?.expense_details[i]?.head)}
                                                                    required={true}
                                                                    placeholder="Select Head"
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                />
                                                            </div>
                                                            <div className={`col-sm-3`}>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    value={this.state?.expense_details[i]?.amount}
                                                                    placeholder="Expense Amount"
                                                                    required={true}
                                                                    autoComplete="off"
                                                                    onChange={(e) => { this.dynamicInputHandlerByKeyNameVal(e.target.value, "amount", i, "expense_details"); }
                                                                    }
                                                                />
                                                            </div>
                                                            {i > 0 &&
                                                                <div className="col-sm-1 text-end">
                                                                    <Link className="d-block my-2 text-secondary" onClick={() => this.handleRemoveInput(i)} style={{ "color": "#6c757d", "font-weight": "500", "font-size": "17px" }}>
                                                                        x
                                                                    </Link>
                                                                </div>
                                                            }
                                                        </Ax>
                                                    );
                                                })}
                                                <div className="col-sm-1 text-left">
                                                    <Link className="d-block my-2 text-secondary" onClick={this.handleExpenseHeadsInput}>
                                                        <tapIcon.FontAwesomeIcon
                                                            icon={tapIcon.faPlus}
                                                            className="img-fluid"
                                                            alt="item-action"
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <label className="col-sm-3 col-form-label col-form-label-sm text-left require">
                                                    Expense Amount in ₹
                                                </label>
                                                <div className="col-7">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        placeholder="Total Expense Amount"
                                                        value={TapHelper.arraySumByKey(this.state.expense_details, 'amount')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <label className="col-sm-3 col-form-label col-form-label-sm text-left">
                                                    Expense Details
                                                </label>
                                                <div className="col-sm-7">
                                                    <textarea
                                                        className="form-control"
                                                        name="details"
                                                        value={this.state.expenseAddFormData.details}
                                                        onChange={(e) =>
                                                            this.formInputHandler(e, "expenseAddFormData")
                                                        }
                                                        style={{ height: "60px", fontSize: "93%" }}
                                                        placeholder="Enter Expense Details"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row my-3">
                                                <label className="col-sm-3 col-form-label col-form-label-sm text-left">
                                                    Payee
                                                </label>
                                                <div className="col-sm-3 my-1 mr0">
                                                    <TapSelect
                                                        options={this.state.allPayeeTypes}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(
                                                                selectedOption,
                                                                "expenseAddFormData",
                                                                "payee"
                                                            );
                                                        }}
                                                        value={this.state.allPayeeTypes.find(
                                                            (s) => this.state.expenseAddFormData.payee == s.value
                                                        )}
                                                        placeholder="Select Payee"
                                                        autoCompelete="off"
                                                        required={true}
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />
                                                </div>
                                                <div className="col-sm-4 my-1">
                                                    {this.state.expenseAddFormData &&
                                                        this.state.expenseAddFormData.payee == "vendor" ? (
                                                        <InputVendorSearch
                                                            placeholder="Search"
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(
                                                                    selectedOption,
                                                                    "expenseAddFormData",
                                                                    "payee_id"
                                                                );
                                                                this.setState({
                                                                    searchedVendor: selectedOption
                                                                        ? selectedOption
                                                                        : null,
                                                                });
                                                            }}
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                            value={
                                                                this.state.searchedVendor
                                                                    ? {
                                                                        ...this.state.searchedVendor,
                                                                        label:
                                                                            this.state.searchedVendor.display_label,
                                                                    }
                                                                    : null
                                                            }
                                                        />
                                                    ) : this.state.expenseAddFormData &&
                                                        this.state.expenseAddFormData.payee == "employee" ? (
                                                        <InputEmployeeSearch
                                                            placeholder="Search"
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(
                                                                    selectedOption,
                                                                    "expenseAddFormData",
                                                                    "payee_id"
                                                                );
                                                                this.setState({
                                                                    searchedEmployee: selectedOption
                                                                        ? selectedOption
                                                                        : null,
                                                                });
                                                            }}
                                                            search_site_ids={
                                                                this.state.counterlog &&
                                                                    this.state.counterlog.site_id
                                                                    ? [this.state.counterlog.site_id]
                                                                    : ""
                                                            }
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                            value={
                                                                this.state.searchedEmployee
                                                                    ? this.state.searchedEmployee
                                                                    : null
                                                            }
                                                        />
                                                    ) : (
                                                        <input
                                                            name="payee_name"
                                                            value={this.state.expenseAddFormData.payee_name}
                                                            className="form-control form-control-sm"
                                                            onChange={(e) =>
                                                                this.formInputHandler(e, "expenseAddFormData")
                                                            }
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder="Please enter Payee Name"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className={`row my-3`}>
                                                <label className="col-sm-3 col-form-label col-form-label-sm text-left ">Wallet</label>
                                                <div className="col-sm-7">
                                                    <TapSelect
                                                        options={this.state.allWalletList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, "expenseAddFormData", "wallet_id");
                                                        }}
                                                        value={this.state.allWalletList.find((s) => this.state.expenseAddFormData.wallet_id == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Wallet"
                                                        autoCompelete="off"
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.showMore
                                                    ? (
                                                        <Ax>
                                                            <div className="row">
                                                                <label className="col-sm-3 col-form-label col-form-label-sm  text-left">Upload Expense Voucher</label>
                                                                <div className="col-sm-7">
                                                                    <input
                                                                        onChange={(e) => { this.handleFileChange(e); }}
                                                                        type="file"
                                                                        name="voucher"
                                                                        className="form-control form-control-sm"
                                                                        autoComplete="off"
                                                                        placeholder="Please upload Expense Voucher"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-3 mt-3">
                                                                <label className="col-sm-3 col-form-label col-form-label-sm text-left">Reference Number</label>
                                                                <div className="col-7">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-sm"
                                                                        name="reference_number"
                                                                        autoComplete="off"
                                                                        value={this.state.expenseAddFormData.reference_number}
                                                                        onChange={(e) => this.formInputHandler(e, "expenseAddFormData")}
                                                                        placeholder="Please Enter Ref Number"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.expenseType !== "Ticket"
                                                                    ?
                                                                    <Ax>
                                                                        <div className={`row my-3`}>
                                                                            <label className="col-sm-3 col-form-label col-form-label-sm text-left require">Section</label>
                                                                            <div className="col-sm-7">
                                                                                <TapSelect
                                                                                    options={[1, 2, 3, 4]}
                                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, "expenseAddFormData", "site_ids")}
                                                                                    isSearchable={true}
                                                                                    isClearable={true}
                                                                                    isMulti={false}
                                                                                    required={true}
                                                                                    placeholder="Select Site"
                                                                                    containerHeight="30px"
                                                                                    fontSize="93%"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className={`row my-3`}>
                                                                            <label className="col-sm-3 col-form-label col-form-label-sm text-left require">Sub-Section</label>
                                                                            <div className="col-sm-7">
                                                                                <TapSelect
                                                                                    options={[1, 2, 3, 4]}
                                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, "expenseAddFormData", "site_ids")}
                                                                                    isSearchable={true}
                                                                                    isClearable={true}
                                                                                    isMulti={false}
                                                                                    required={true}
                                                                                    placeholder="Select Site"
                                                                                    containerHeight="30px"
                                                                                    fontSize="93%"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Ax>
                                                                    :
                                                                    ""
                                                            }
                                                            <div className="row my-3">
                                                                <label className="col-sm-3 col-form-label col-form-label-sm text-left">Notes</label>
                                                                <div className="col-sm-7">
                                                                    <textarea
                                                                        className="form-control"
                                                                        name="notes"
                                                                        value={this.state.expenseAddFormData.notes}
                                                                        onChange={(e) =>
                                                                            this.formInputHandler(e, "expenseAddFormData")
                                                                        }
                                                                        style={{ height: "60px", fontSize: "93%" }}
                                                                        placeholder="Enter Notes"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Ax>
                                                    )
                                                    :
                                                    (
                                                        <div className="col-sm-12 my-1 text-end link-primary cursor_pointer" onClick={this.handleShowMore}>
                                                            + Add more details
                                                        </div>
                                                    )}
                                        </form>
                                    </div>
                                )}

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.expenseModalInit.hide()}>
                                Close
                            </button>
                            <button type="submit" form={`${this.expenseModalId}Form`} className="btn btn-primary" disabled={this.state.addExpenseFormSubmitting}>
                                Save {this.state.addExpenseFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

  handleFileChange          =   async (e)   =>  {

    let file                =   e.target.files[0];
    if (file) {
      let filename = file.name;
      let base64_document   =   await this.convertBase64(file);
      this.setState({ 
        expenseAddFormData  :   {...this.state.expenseAddFormData, base64_document: base64_document, document_name: filename,
        },
      });
    }
  };

  convertBase64             =   (file)  =>  {

    return new Promise((resolve, reject) => {
      const fileReader      =   new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload     =   ()  =>  {
        resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
      };
      fileReader.onerror = (error) => { reject(error);};
    });
  };

  handleShowMore = () => {
    this.setState({
      showMore: true,
    });
  };

  handleExpenseHeadsInput = () => {
    this.setState({
      expense_heads_input: [...this.state.expense_heads_input, {}],
    });
  };

  handleRemoveInput         = (i) => {
    const allInputs         =   this.state.expense_heads_input;
    const expense_details   =   this.state.expense_details;
    if (allInputs.length > 1) {
      allInputs.splice(i, 1);
      expense_details.splice(i, 1);
      this.setState({ expense_heads_input : allInputs, expense_details : expense_details});
    }
  };

  render = () => {
        return <Ax>
            {this.expenseAddModalJsx()}
        </Ax>;
    };
}

const mapStateToProps           =   (state) =>  {
  return {
    access_token            :   state.auth.access_token,
    // all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
    // iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
    iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ExpenseAddModal);     
