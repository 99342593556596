import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { Collapse } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import tapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination } from '../../../components/TapUi';
import AssetHeader from '../includes/AssetHeader';
import { Helmet } from 'react-helmet';

class SiteTransitionList extends AppBaseComponent {
    
    constructor(props) {
        super(props);
   
        this.state                          =       {
            access_token                    :       '',
            assetDetail                     :       null,
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            siteTransitionList                    :       []
            
        }
       
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        this.setState({assetId              :       pr.match?.params?.id,
        },() => {
                this.loadListingTblData(1);
                this.getAsssetDetails(pr.match?.params?.id)
            })
    }

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    //************************GET PO LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page};
    
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/site/transaction/' + this.state.assetId , this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
          
            this.setState({
                siteTransitionList        :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
                current_page        :      respData.meta.current_page
        },() => {
           
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    changeAssetStatusModalInit          =       ()           =>      {
        this.ChangeAssetStatusModalRef.current.changeStatusModalInit(this.state.assetId) 
    }
    
    inactivateAssetModalInit          =       ()           =>      {
        this.InactivateAssetModalRef.current.inactiveAssetModalInit(this.state.assetId,true) 
    }
 
    activateAssetModalInit          =       ()           =>      {
        this.InactivateAssetModalRef.current.inactiveAssetModalInit(this.state.assetId,false) 
    }

    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    deleteSiteHistory                   =   (transaction_id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                let params = {transaction_id : transaction_id,asset_id : this.state.assetId}
                HttpAPICall.withAthorization('DELETE', IAM_API_BASE_URL_2 + '/asset/site/transaction', this.props.access_token, {...params}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.loadListingTblData(1);
                    this.getAsssetDetails(this.state.assetId);
                });
            }
        });
    }
  
    closeSettings                             =       ()      =>  {
        this.props.history.goBack()
    }
   
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader
                     assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}
                 />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div id="assetCard" className="collapse pr-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail}/>}
        </div>)
    }
    
    subHeaderJsx        =       ()      =>      {
        return (<div className="row page_title m0 " style={{ height: "45px" }}>
            <div className="col-sm-3"><h3 style={{ paddingTop: "13px" }}>Asset Site Transition</h3></div>
            <div className='col-sm-4 text-center'><button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>

                Asset Card</button></div>
            <div className=" col-sm-5 text-end mt-2 ">
               
                <button type="button" className="btn btn-secondary" onClick={this.closeSettings}>
                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" />
                </button>
            

            </div>
        </div>)
    }

    siteTransTableJsx                   =   ()  =>  {
       
        return (
            <Ax>
                <div className='page_containt' style={{ maxHeight: "70vh" }}>
                    <div className='pageTbl'>

                        <div className='table-responsive p4 pt-0  ' >
                            <table className="table table-bordered bg-white align-items-center  table-sm table-hover table-fixed mb-0" id="settingsTable">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                                        <th scope="col" style={{ width: "25%" }}>From</th>
                                        <th scope="col" style={{ width: "25%" }}>To</th>
                                        <th scope="col" style={{ width: "25%" }}>Site </th>
                                        <th scope="col" style={{ width: "15%" }} >No of Days</th>
                                        <th scope="col" style={{ width: "5%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listing_loading
                                        ? <tr><td className="text-center" colSpan="6"><Loader /></td></tr>
                                        : this.state.siteTransitionList && this.state.siteTransitionList.length > 0 ? this.state.siteTransitionList.map((site_transition, index) => {
                                            return <tr className='text-center'>
                                                <td >{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td>{site_transition.from_dispaly ? site_transition.from_dispaly : '-'}</td>
                                                <td>{site_transition.till_dispaly ? site_transition.till_dispaly : '-'}</td>
                                                <td>{site_transition.site_data && site_transition.site_data.site_name ? site_transition.site_data.site_name : '-'}</td>
                                                <td>{site_transition.diff_days ? site_transition.diff_days : '0'} Days</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">   <li>   <a role="button"
                                                            onClick={() => {
                                                                if (this.props.permissions.includes('iam-asset-site-linking-delete') && site_transition.can_delete == "Y") {
                                                                    return this.deleteSiteHistory(site_transition.id)
                                                                }
                                                            }}
                                                            className={['dropdown-item',this.props.permissions.includes('iam-asset-site-linking-delete') &&  site_transition.can_delete == "Y" ? '' : 'disabled'].join(' ')}
                                                            title={!this.props.permissions.includes('iam-asset-site-linking-delete') && site_transition.can_delete == "N" ? 'You do not have permission to perform this action' : ''}
                                                            style={{ "pointerEvents": "all" }}
                                                        >Delete</a></li>

                                                    </ul>
                                                </td>
                                             </tr>
                                        })
                                        : <tr><td className="text-center" colSpan="6">No Record</td></tr>
                                    }

                                </tbody>
                            </table>

                        </div>
                        <DisplayListPagination
                            meta={this.state.listingMeta}
                            onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                        />
                        
                    </div>
                </div>
            </Ax>

        );
    }

  
    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Site Transition List</title></Helmet>
             {this.pageTitleBarJsx()}
             <div className="container-fluid pl5">
                <div className='page_containt row'>
                    <div className='pr0 col-sm-12'>
                        {this.horizontalAssetCardJsx()}
                        {this.subHeaderJsx()}
                        {this.siteTransTableJsx()}
                     </div>
                </div>
            </div>
            
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SiteTransitionList);