import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link , useHistory} from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import swal from "sweetalert";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import TapNavTabs from '../../../../components/ui/TapNavTabs';
import AdditionalAttribute from '../../AdditionalAttribute';
import CounterlogView from './CounterlogView';
import { IAM_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapIcon from '../../../../services/TapIcon';

import * as actions from '../../../../store/actions/index';


const CounterlogSetting                  =   forwardRef((props, ref) => {
        
    const dispatch                      =   useDispatch();

    let configKeysText                  =   {
        enable_workStation_cl               :   'Capture Workstation at time of Shift Reading',
        enable_opening_closing_cl           :   'Capture Closing Reading for each Shift',
        previous_day_cl_required            :   'Prevent Counterlog entry, if previous day reading is not available'
    };

    let clConfigKeysText                =   {
        enable_asset_meter_code             :   'Enable Asset Meter Code',
        shift_operator_required             :   'Operator Name will be mandatory',
        shift_operator_track                :   'Capture operator Name'
    };

    let initFormData                    =   {
        loading                             :   false,
        submitting                          :   false,
        current_key                         :   null,
        current_value                       :   null,
        enable_workStation_cl               :   'N',
        enable_opening_closing_cl           :   'N',
        previous_day_cl_required            :   'N',
    };

    let initClConfigData                =   {
        loading                             :   false,
        submitting                          :   false,
        data                                :   null,
        enable_asset_meter_code             :   'N',
        shift_operator_required             :   'N',
        shift_operator_track                :   'N',
        current_key                         :   null,
        current_value                       :   null,
    };

    const [formData, setFormData]               =   useState({...initFormData});
    const [clConfigData, setClConfigData]       =   useState({...initClConfigData});
    const access_token                          =   useSelector(state => state.auth.access_token);

    //Edit Modal :-
    const [editModalId, setSearchModalId]   =   useState(uuid());
    const editModalElem                     =   useRef(null);
    const editModal                         =   useRef(null);
    const editModalOpen                     =   ()  => editModal.current && editModal.current.show()
    const editModalClose                    =   ()  => editModal.current && editModal.current.hide()
    useEffect(() => {
        editModalElem.current               =   document.getElementById(editModalId);
        editModal.current                   =   new Modal(editModalElem.current, {keyboard: false, backdrop :false});
        //Cleanup on unmount
        return () => {
            editModal.current && editModal.current.dispose();
        };
    }, []);

    //Edit clConfigModal :-
    const [clConfigModalId, setClConfigModalId] =   useState(uuid());
    const clConfigModalElem                 =   useRef(null);
    const clConfigModal                     =   useRef(null);
    const clConfigModalOpen                 =   ()  => clConfigModal.current && clConfigModal.current.show()
    const clConfigModalClose                =   ()  => clConfigModal.current && clConfigModal.current.hide()
    useEffect(() => {
        clConfigModalElem.current           =   document.getElementById(clConfigModalId);
        clConfigModal.current               =   new Modal(clConfigModalElem.current, {keyboard: false, backdrop :false});
        //Cleanup on unmount
        return () => {
            clConfigModal.current && clConfigModal.current.dispose();
        };
    }, []);



    let settingUrl                          =   ORG_API_BASE_URL_2 + "/settings";
    let settingEditUrl                      =   ORG_API_BASE_URL_2 + "/setting_edit";
    let clConfigUrl                         =   IAM_API_BASE_URL_2 + "/counterlog/config";
    

    useEffect(() => {
        getGeneralSettings();
        getCounterLogSettings();
    }, []);


    const getGeneralSettings        = () => {
        setFormData(pd => ({...pd, loading : true }));
        HttpAPICall.withAthorization('GET', settingUrl, access_token, {keys:Object.keys(configKeysText)}, {}, (resp) => {
            setFormData(pd => ({...pd, ...resp.data.data }));
        }).then(() => {
            setFormData(pd => ({...pd, loading : false }));
        });
    };

    const editConfigurationInit     =   (key)  =>  {
        setFormData(pd => ({...pd, submitting : false, current_key : key, current_value : formData[key]}));
        //Open Modal :-
        editModalOpen();
    }

    const editModalSubmitHandler    =   (event)  =>  {
        event.preventDefault();
        setFormData(pd => ({...pd, submitting : true }));
        let data =  { [formData.current_key]: formData.current_value } ;
        HttpAPICall.withAthorization('PUT', settingEditUrl, access_token, {}, data, (resp) => {
            getGeneralSettings();
            editModalClose();
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            setTimeout(() => {
                dispatch(actions.applicationInitLoading(access_token), false); 
            }, 1000);
        }).then(() => {
            setFormData(pd => ({...pd, submitting : false }));
        });
    }

    const getCounterLogSettings        = () => {
        setClConfigData(pd => ({...pd, loading : true }));
        HttpAPICall.withAthorization('GET', clConfigUrl, access_token, {}, {}, (resp) => {
            setClConfigData(pd => ({...pd, ...resp.data }));
        }).then(() => {
            setClConfigData(pd => ({...pd, loading : false }));
        });
    };
    
    const editClConfigModalInit     =    (key) => {
        setClConfigData(pd => ({...pd, submitting : false, current_key : key, current_value : clConfigData[key]}));
        clConfigModalOpen();
    }

    const clConfigModalSubmitHandler=   (event)  =>  {
        event.preventDefault();
        setClConfigData(pd => ({...pd, submitting : true }));
        let data =  { [clConfigData.current_key]: clConfigData.current_value } ;
        HttpAPICall.withAthorization('PUT', clConfigUrl, access_token, {}, data, (resp) => {
            getCounterLogSettings();
            clConfigModalClose();
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {
            setClConfigData(pd => ({...pd, submitting : false }));
        });
    }

    const editModalJsx                =   () => {
        return <div className="modal fade" id={editModalId} tabIndex="-1" ref={editModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit General Configuration</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form name='' id={editModalId + 'Form'} onSubmit={editModalSubmitHandler}>
                            {["previous_day_cl_required","enable_workStation_cl","enable_opening_closing_cl"].includes(formData.current_key) && <div className="row align-items-center m-2">
                                <div className="col-md-9">
                                    <label className="form-label">{configKeysText[formData.current_key]}</label>
                                </div>
                                <div className="col-md-3 text-end">
                                    {['Y','N'].map((v,i) => {
                                        return <div className="form-check form-check-inline ms-2" key={i}>
                                            <input
                                                className="form-check-input" type="radio"
                                                id={v+"inputcurrent_key"}
                                                onChange={(e) => setFormData(pd => ({...pd, current_value : v})) }
                                                checked={formData.current_value == v}
                                            />
                                            <label className="form-check-label" for={v+"inputcurrent_key"}>{v=='Y'?'Yes':'No'}</label>
                                        </div>
                                    })}
                                </div>
                            </div>}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" disabled={formData.submitting} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button form={editModalId + 'Form'} disabled={formData.submitting} type="submit" className="btn btn-primary">Save {formData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    const editClConfigModalJsx          =   () => {
        return <div className="modal fade" id={clConfigModalId} tabIndex="-1" ref={clConfigModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit CounterLog Configuration</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form name='' id={clConfigModalId + 'Form'} onSubmit={clConfigModalSubmitHandler}>
                            {clConfigData.hasOwnProperty(clConfigData.current_key) && <div className="row align-items-center m-2">
                                <div className="col-md-9">
                                    <label className="form-label">{clConfigKeysText[clConfigData.current_key]}</label>
                                </div>
                                <div className="col-md-3 text-end">
                                    {['Y','N'].map((v,i) => {
                                        return <div className="form-check form-check-inline ms-2" key={i}>
                                            <input
                                                className="form-check-input" type="radio"
                                                id={v+"inputcurrent_key"}
                                                onChange={(e) => setClConfigData(pd => ({...pd, current_value : v})) }
                                                checked={clConfigData.current_value == v}
                                            />
                                            <label className="form-check-label" for={v+"inputcurrent_key"}>{v=='Y'?'Yes':'No'}</label>
                                        </div>
                                    })}
                                </div>
                            </div>}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" disabled={clConfigData.submitting} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button form={clConfigModalId + 'Form'} disabled={clConfigData.submitting} type="submit" className="btn btn-primary">Save {clConfigData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    return  <>
        <div className="tab_content_wrapper my-3"><span className="content_heading">General Configuration</span></div>
        <table className="table table-hover table-sm table-bordered align-middle">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th style={{ width: "5%" }}>S.No</th>
                    <th style={{ width: "65%" }}>Configuration</th>
                    <th style={{ width: "20%" }}>Value</th>
                    <th style={{ width: "10%" }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {formData.loading 
                    ? <tr><td className="text-center" colSpan="4"><Loader /></td></tr>
                    : (Object.keys(configKeysText).map((ckey,i) => {
                        return <tr className="text-center" key={i} >
                            <td>{i+1}</td>
                            <td className="text-start">{configKeysText[ckey]}</td>
                            <td>{formData[ckey] == 'Y' ? 'Yes' : 'No'}</td>
                            <td>
                                <span className="dropdown">
                                    <button type="button" id="actionMenuButton" data-bs-toggle="dropdown" aria-expanded="false" className="btn" >
                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                        <li>
                                            <Link className="dropdown-item" role="button"
                                                onClick={() => editConfigurationInit(ckey)}
                                            > Edit</Link>
                                        </li>
                                    </ul>
                                </span>
                            </td>
                        </tr> 
                    }))
                }
            </tbody>
        </table>
        <div className="tab_content_wrapper my-3"><span className="content_heading">Operator</span></div>
        <table className="table table-hover table-sm table-bordered align-middle">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th style={{ width: "5%" }}>S.No</th>
                    <th style={{ width: "65%" }}>Operator Configuration</th>
                    <th style={{ width: "20%" }}>Value</th>
                    <th style={{ width: "10%" }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {clConfigData.loading 
                    ? <tr><td className="text-center" colSpan="4"><Loader /></td></tr>
                    :  <>
                        <tr className="text-center">
                            <td>1</td><td className="text-start">Capture operator Name</td>
                            <td>{clConfigData.shift_operator_track == "Y" ? "Yes" : 'No'} </td>
                            <td>
                                <span className="dropdown">
                                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn" >
                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                        <li><Link className="dropdown-item" role="button" onClick={() => editClConfigModalInit('shift_operator_track')} > Edit</Link></li>
                                    </ul>
                                </span>
                            </td>
                        </tr>
                        <tr className="text-center">
                            <td>2</td><td className="text-start">Operator Name will be mandatory</td>
                            <td>{clConfigData.shift_operator_required == "Y" ? "Yes" : 'No'} </td>
                            <td>
                                <span className="dropdown">
                                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn" >
                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                        <li><Link className="dropdown-item" role="button" onClick={() => editClConfigModalInit('shift_operator_required')} > Edit</Link></li>
                                    </ul>
                                </span>
                            </td>
                        </tr>
                    </>
                }
            </tbody>
        </table>

        <div className="tab_content_wrapper my-3"><span className="content_heading">Meter Setting</span></div>
        <table className="table table-hover table-sm table-bordered align-middle">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th style={{ width: "5%" }}>S.No</th>
                    <th style={{ width: "65%" }}>Meter Configuration</th>
                    <th style={{ width: "20%" }}>Value</th>
                    <th style={{ width: "10%" }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {clConfigData.loading 
                    ? <tr><td className="text-center" colSpan="4"><Loader /></td></tr>
                    :  <>
                        <tr className="text-center">
                            <td>3</td><td className="text-start">Enable Asset Meter Code</td>
                            <td>{clConfigData.enable_asset_meter_code == "Y" ? "Yes" : 'No'}</td>
                            <td>
                                <span className="dropdown">
                                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn" ><TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} /></button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                        <li><Link className="dropdown-item" role="button" onClick={() => editClConfigModalInit('enable_asset_meter_code')} > Edit</Link></li>
                                    </ul>
                                </span>
                            </td>
                        </tr>
                    </>
                }
            </tbody>
        </table>




        {editModalJsx()}
        {editClConfigModalJsx()}
    </>
});

export default CounterlogSetting;