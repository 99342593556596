import React from 'react';
import { connect } from 'react-redux';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Ax from '../../../components/hoc/Ax';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import moment from "moment";
import AppBaseComponent from '../../../components/AppBaseComponent';
import InterchangeableItemModal from '../InterchangeableItemModal';
import Status from '../../../components/ui/Status';
import ItemWhConfig from '../configuration/ItemWhConfig';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';


class ItemDetailsModal extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            itemData: null,
            listing_tbl_page: 1,
            listingData: [],
            listingMeta: [],
            totalListingCount: 0,
            listing_loading: false,
            interchangeableLoaded: false,
            tabName: "details",
            inventory_loading: false,
            inventoryData: [],
            showLinkedQty: false,
            linkedQty: 0,
            isInterchangeable: false,
            isConfig: false,
            additionalAttrData : {show : false, loading : false, data : []},
        }

        //this.itemModalId                 =   uuid();
        this.itemUrl                =   INVENTORY_API_BASE_URL_2 + '/item';
        this.itemCurrentStockUrl    =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';
        this.itemAdditionalAttrUrl  =   INVENTORY_API_BASE_URL_2 + "/item/additional_attr";
    }

    id = this.props.itemId;

    componentDidMount() {

        if (this.props && this.props.itemId) {
            this.getItemDetails(this.props.itemId)
        }
        this.viewItemModal = new Modal(document.getElementById('viewItemModal'), { keyboard: false, backdrop: false });
        if (this.props && this.props.itemData) {
            this.setState({ itemData: this.props.itemData })
        }
    }

    getItemDetails = (id) => {
        this.setState({ details_loading: true });
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/item/' + id, this.props.access_token, { opening_stock: "Y" }, {}, (resp) => {
            this.setState({ itemData: resp.data.data, tabName: "details" })
        }).then(() => { this.setState({ details_loading: false }) })
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.itemId !== this.props.itemId) {
            this.getItemDetails(nextProps.itemId)
            // if(this.state.tabName == "interchangeable"){
            this.getInterchangeableListing(this.state.listing_tbl_page, nextProps.itemId)
            //  } 
            //  if(this.state.tabName == "inventory"){
            this.getInventoryListing(nextProps.itemId)
            //  } 
        }
        if (nextProps.itemData !== this.props.itemData) {
            this.setState({ itemData: nextProps.itemData, tabName: "details" })
            // if(this.state.tabName == "interchangeable"){
            this.getInterchangeableListing(this.state.listing_tbl_page, nextProps.itemData.id)
            //  } 
            //  if(this.state.tabName == "inventory"){
            this.getInventoryListing(nextProps.itemData.id)
            //  } 
        }



    }

    modalInit = (item, linkedQty = 0, showLinkedQty = false) => {

        // this.setState({itemData : [],linkedQty : 0,showLinkedQty : false},() => {
        this.viewItemModal.show()
        if (item) {
            this.setState({ itemData: item, linkedQty: linkedQty, showLinkedQty: showLinkedQty });
            this.getInterchangeableListing(1, item.id)
            this.getInventoryListing(item.id)
        }
        // })

    }

    itemModalClose = () => {
        this.props.closeEvent();
    }

    changeTab = (name) => {
        this.setState({ tabName: name, additionalAttrData : {...this.state.additionalAttrData, show : false}  })
        if (name == "interchangeable") {
            this.getInterchangeableListing(this.state.listing_tbl_page, this.state.itemData.id)
        }
        if (name == "inventory") {
            this.getInventoryListing(this.state.itemData.id)
        }
    }

    additionalAttHandle = (item_id) => {
        this.setState({ 
            additionalAttrData: {...this.state.additionalAttrData, show: true , loading : true} 
        });
        HttpAPICall.withAthorization('GET', this.itemAdditionalAttrUrl + '/' + item_id, this.props.access_token, {}, {}, (resp) => {
            this.setState({ 
                additionalAttrData: {...this.state.additionalAttrData, show: true , loading : true, data: resp.data} 
            });
        }).then(() => { this.setState({ 
            additionalAttrData: {...this.state.additionalAttrData, loading : false} 
            }); 
        });
    }


    getInterchangeableListing = (page = 1, id) => {
        this.setState({ listing_loading: true, listing_tbl_page: page });
        HttpAPICall.withAthorization('GET', this.itemUrl + '/interchangeable_item/' + id, this.props.access_token, {}, {}, (resp) => {
            this.setState({
                listingData: resp.data.data,
                listingMeta: {
                    from: resp.data.from,
                    to: resp.data.to,
                    total: resp.data.total,
                    last_page: resp.data.last_page,
                    current_page: resp.data.current_page
                },
                totalListingCount: resp.data.total,

            })
        }).then(() => this.setState({ listing_loading: false }))
    }

    getInventoryListing = (id) => {
        this.setState({ inventory_loading: true });
        HttpAPICall.withAthorization('GET', this.itemCurrentStockUrl + '/' + id, this.props.access_token, { interchangeable_items_current_stock: "Y" }, {}, (resp) => {
            this.setState({
                inventoryData: resp.data,
            })
        }).then(() => this.setState({ inventory_loading: false }))
    }

    //*********************************ITEM ADD JSX*********************** */
    viewItemModalJsx = () => {
        
        return (
            <div className="modal fade" id={'viewItemModal'} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="viewItemModalLabel">Item : {this.state.itemData?.name}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                        </div>
                        <div className="modal-body">
                            {this.state.showLinkedQty
                                ? <div className='p-1 bg-light border-bottom'>
                                    <table className='table table-sm mb-0 table-borderless text-sm'>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "26%" }}> Item Name (Code)   </td>
                                                <th>{this.state.itemData ? <span>{this.state.itemData.name} ({this.state.itemData.item_code})</span> : "-"}</th>
                                            </tr>
                                            <tr>
                                                <td>Linked Qty </td><th>{this.state.linkedQty ? this.state.linkedQty : "0"}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> : null}
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation" onClick={() => this.changeTab("details")}>
                                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true" onClick={() => { this.setState({ isConfig: false, isInterchangeable: false }) }}>Details
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => this.changeTab("interchangeable")}>
                                    <button className="nav-link" id="interchangeable-tab" data-bs-toggle="tab" data-bs-target="#interchangeable"
                                        type="button" role="tab" onClick={() => this.setState({ isInterchangeable: true, isConfig: false })} aria-controls="interchangeable" aria-selected="false" >Interchangeable Items
                                    </button>
                                </li>
                                {this.state.itemData && this.state.itemData.category === "services" ? null
                                    : <li className="nav-item" role="presentation" onClick={() => this.changeTab("inventory")}>
                                        <button className="nav-link" id="inventory-tab" data-bs-toggle="tab" data-bs-target="#inventory"
                                            type="button" role="tab" aria-controls="inventory" aria-selected="false" onClick={() => { this.setState({ isConfig: false, isInterchangeable: false }) }}>Inventory Levels
                                        </button>
                                    </li>}
                                {this.state.itemData && this.state.itemData.category === "services" ? null
                                    : <li className="nav-item" role="presentation" onClick={() => { this.changeTab("configuration"); this.setState({ isConfig: true, isInterchangeable: false }) }}>
                                        <button className="nav-link" id="config-tab" data-bs-toggle="tab" data-bs-target="#config"
                                            type="button" role="tab" aria-controls="config" aria-selected="false">
                                            Configuration
                                        </button>
                                    </li>}
                                    <li className="nav-item" role="presentation" onClick={() => { this.additionalAttHandle(this.state.itemData.id); this.setState({ isConfig: false, isInterchangeable: false }) }}>
                                        <button className="nav-link" id="additional-attribute-tab" data-bs-toggle="tab" data-bs-target="#additional-attribute"
                                            type="button" role="tab" aria-controls="additional-attribute" aria-selected="false">
                                            Additional Attributes
                                        </button>
                                    </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">

                                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                                    {this.state.details_loading ? <Loader />
                                        : <div className="tab_content_header">
                                            {this.state.itemData ? <div>
                                                <div className="tab_content_wrapper"><span className="content_heading">Basic Details</span></div>
                                                <table className="table table-hover table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td className="details-label" style={{ "width": "34%" }}>Item Category </td>
                                                            <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData?.category_display}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">Item Name</td>
                                                            <td className="details-name">{this.state.itemData?.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">Item Code</td>
                                                            <td className="details-name">{this.state.itemData?.item_code ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">Item Status</td>
                                                            <td className="details-name">{this.state.itemData && this.state.itemData.status ? <Status color={this.state.itemData.status_color}>{this.state.itemData.status_text}</Status> : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">Item Description</td>
                                                            <td className="details-name">{this.state.itemData?.description ?? ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">Unit of Measurement</td>
                                                            <td className="details-name">{this.state.itemData?.measuring_unit?.name ? this.state.itemData?.measuring_unit?.name : (this.state.itemData?.unit_name ? this.state.itemData.unit_name : '-')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">Manufacturer</td>
                                                            <td className="details-name">{this.state.itemData?.manufacturer?.name ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">Manufacturer Part Number (MPN)</td>
                                                            <td className="details-name">{this.state.itemData?.manufacturer_part_no ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label" >Item Tags</td>
                                                            <td className="details-name" >
                                                                {
                                                                    this.state.itemData?.tags?.length > 0
                                                                        ? (this.state.itemData?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                        : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label" >HSN / SAC Code</td>
                                                            <td className="details-name" >{this.state.itemData?.hsn_code ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label" >Generate Scrap on Replacement</td>
                                                            <td className="details-name" >{this.state.itemData?.is_scrapble == 'N' ? 'NO' : 'Yes' ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label" >Created On</td>
                                                            <td className="details-name" >{this.state.itemData?.created_at_display ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label" >Created By</td>
                                                            <td className="details-name" >{this.state.itemData && this.state.itemData.created_user ? this.state.itemData.created_user.full_name : '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label" >Updated On</td>
                                                            <td className="details-name" >{this.state.itemData?.updated_at_display ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label" >Updated By</td>
                                                            <td className="details-name" >{this.state.itemData && this.state.itemData.updated_user ? this.state.itemData.updated_user.full_name : '-'}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div className="tab_content_wrapper"><span className="content_heading">Pricing</span></div>
                                                <table className="table table-hover table-borderless">
                                                    <tbody>

                                                        <tr>
                                                            <td className="details-label" style={{ "width": "34%" }}>Purchase Price</td>
                                                            <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData.purchase_currency && this.state.itemData?.purchase_currency} {this.state.itemData?.purchase_price ?? ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">Sale / Consumption Price</td>
                                                            <td className="details-name">{this.state.itemData.consumption_currency && this.state.itemData.consumption_currency} {this.state.itemData?.consumption_price ?? ''}</td>
                                                        </tr>
                                                        {this.state.itemData?.is_scrapble === "Y" ? <tr>
                                                            <td className="details-label">Scrap Price</td>
                                                            <td className="details-name">{this.state.itemData?.scrap_price ?? ''}</td>
                                                        </tr> : null}
                                                    </tbody>
                                                </table>
                                                <div className="tab_content_wrapper"><span className="content_heading">Taxation</span></div>
                                                <table className="table table-hover table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td className="details-label" style={{ "width": "34%" }}>Tax Status </td>
                                                            <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData?.is_taxable == 'Y' ? 'Taxable' : 'Non - Taxable'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">IGST Percentage</td>
                                                            <td className="details-name">{this.state.itemData?.igst ?? 0}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">CGST Percentage</td>
                                                            <td className="details-name">{this.state.itemData?.cgst ?? 0}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-label">SGST Percentage</td>
                                                            <td className="details-name">{this.state.itemData?.sgst ?? 0}%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> : null}

                                        </div>}
                                </div>
                                <div className="tab-pane  " id="interchangeable" role="tabpanel" aria-labelledby="interchangeable-tab">
                                    {this.state.isInterchangeable && this.state.itemData && this.state.itemData.id && <InterchangeableItemModal item_enc_id={this.state?.itemData?.id} />}
                                </div>
                                {this.state.itemData && this.state.itemData.category === "services" ? null
                                    : (<div className="tab-pane  " id="inventory" role="tabpanel" aria-labelledby="inventory-tab">
                                        <div className="tab_content_header">
                                            <table className="table table-hover table-bordered table-responsive bg-white my-3">
                                                <thead className="table-secondary">
                                                    <tr className="text-end">
                                                        <th style={{ "width": '5%' }} className="text-center">S.No</th>
                                                        <th style={{ width: "25%" }} className="text-start">Warehouse</th>
                                                        {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                            <Ax>
                                                                <th style={{ width: "15%" }} className="text-start">Batch Number</th>
                                                                <th style={{ width: "15%" }} className="text-center">Expiry Date</th>
                                                            </Ax>
                                                        ) : null}
                                                        <th style={{ width: "8%" }}>Total Qty</th>
                                                        <th style={{ width: "8%" }}>On Hold</th>
                                                        <th style={{ width: "8%" }}>Allocated</th>
                                                        <th style={{ width: "8%" }}>In Transit</th>
                                                        <th style={{ width: "8%" }}>Free Stock</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.inventory_loading
                                                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                                        : (this.state.inventoryData?.current_stock?.length > 0
                                                            ? (this.state.inventoryData?.current_stock?.map((item, k) => {
                                                                return (<tr key={k} className="text-end">
                                                                    <td className="text-center">{k + 1}</td>
                                                                    <td className="text-start">{item.warehouse ? item.warehouse.name : "-"} {item.warehouse && item.warehouse.code && <span>({item.warehouse.code})</span>}</td>
                                                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                                        <Ax> <td className="text-start">{item.batch_number ? item.batch_number : "-"}</td>
                                                                            <td className="text-center">{item.expiry_date ? moment(item.expiry_date).format("DD-MMM-YYYY") : "-"}</td>
                                                                        </Ax>) : null}
                                                                    <td>{item.total_qty ? item.total_qty : "0"}</td>
                                                                    <td>{item.on_hold ? item.on_hold : "0"}</td>

                                                                    <td>{item.allocated_qty ? item.allocated_qty : "0"}</td>
                                                                    <td>{item.intransit ? item.intransit : "0"}</td>
                                                                    <td>{item.free_qty ? item.free_qty : "0"}</td>
                                                                </tr>);
                                                            }))
                                                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="tab_content_wrapper"><span className="content_heading">Interchangeable</span> </div>
                                            <table className="table table-hover table-bordered table-responsive bg-white my-3">
                                                <thead className="table-secondary">
                                                    <tr className="text-end">
                                                        <th style={{ "width": '5%' }} className="text-center">S.No</th>
                                                        <th style={{ width: "16%" }} className="text-start">Item</th>
                                                        <th style={{ width: "15%" }} className="text-start">Warehouse</th>
                                                        {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                            <Ax>
                                                                <th style={{ width: "12%" }} className="text-start">Batch Number</th>
                                                                <th style={{ width: "12%" }} className="text-center">Expiry Date</th>
                                                            </Ax>)
                                                            : null}
                                                        <th style={{ width: "8%" }}>Total Qty</th>
                                                        <th style={{ width: "8%" }}>On Hold</th>
                                                        <th style={{ width: "8%" }}>Allocated</th>
                                                        <th style={{ width: "8%" }}>In Transit</th>
                                                        <th style={{ width: "8%" }}>Free Stock</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.inventory_loading
                                                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                                        : (this.state.inventoryData?.interchangable?.length > 0
                                                            ? (this.state.inventoryData?.interchangable?.map((item, k) => {
                                                                return (<tr key={k} className="text-end">
                                                                    <td className="text-center">{k + 1}</td>
                                                                    <td className="text-start text-capitalize">{item.item ? item.item.name : "-"} <br />
                                                                        <small>Code : {item.item ? item.item.item_code : "-"}</small>
                                                                    </td>
                                                                    <td className="text-start">{item.warehouse ? item.warehouse.name : "-"}</td>
                                                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                                        <Ax><td className="text-start">{item.batch_number ? item.batch_number : "-"}</td>
                                                                            <td className="text-center">{item.expiry_date ? moment(item.expiry_date).format("DD-MMM-YYYY") : "-"}</td>
                                                                        </Ax>) : null}
                                                                    <td>{item.total_qty ? item.total_qty : "0"}</td>
                                                                    <td>{item.on_hold ? item.on_hold : "0"}</td>
                                                                    <td>{item.allocated_qty ? item.allocated_qty : "0"}</td>
                                                                    <td>{item.intransit ? item.intransit : "0"}</td>
                                                                    <td>{item.free_qty ? item.free_qty : "0"}</td>
                                                                </tr>);
                                                            }))
                                                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                                        )
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>)}
                                {this.state.itemData && this.state.itemData.category === "services" ? null
                                    : (<div className="tab-pane  " id="config" role="tabpanel" aria-labelledby="config-tab">
                                        {this.state.isConfig && this.state.itemData && this.state.itemData.id && <ItemWhConfig item_enc_id={this.state?.itemData?.id} showTabHeader={false} />}
                                    </div>)}

                                    <div className="tab-pane  " id="additional-attribute" role="tabpanel" aria-labelledby="additional-attribute-tab">
                                    {
                                        this.state.additionalAttrData.show &&
                                        <div className="tab_content_header">
                                        <div className="tab_content_wrapper">
                                            <div>
                                                <span className="content_heading">Additional Attribute</span>
                                            </div>
                                        </div>
                                            <table className="table table-hover table-borderless">
                                                <tbody>
                                                    { 
                                                    this.state.additionalAttrData.loading  
                                                    ? 
                                                    <Loader/>
                                                    :
                                                    this.state.additionalAttrData.data.length > 0 
                                                        ? 
                                                        <>
                                                            {this.state.additionalAttrData.data.map((a, k) => (<tr key={k}>
                                                                <td className="details-label text-capitalize" style={{ "width": "34%" }}>{a.name}</td>
                                                                <td className="details-name" style={{ "width": "66%" }}>
                                                                    {a.value ? a.value : "-"}
                                                                </td>
                                                            </tr>))}
                                                        </>
                                                        : <tr className="text-center"><td>No Record Found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (<Ax>{this.viewItemModalJsx()}

        </Ax>);
    }
}


//<a role="button" className={this.props.className} onClick={this.addVendorModalInit}>{this.props.view}</a>  

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemDetailsModal);