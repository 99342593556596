import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { connect } from 'react-redux';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import HttpAPICall from '../../../services/HttpAPICall';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from '../../../components/TapUi';
import Status from "../../../components/ui/Status";
import {Modal} from "bootstrap";
import swal from "sweetalert";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import DealsDetail from './DealsDetail';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import CustomerProjectSearch from '../includes/CustomerProjectSearch';
import TapSelect from '../../../components/ui/TapSelect';
import DateService from '../../../services/DateService';

class DealsTransactionList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.dealSearchFormInitialState   =   {
            'search_deal_type_id'             :   '',
            'search_deal_id  '                :   null,
            'search_deal_header'              :   '',
            'search_tagged_users'               :   [],
            'search_ageing_days'                :   '',
            'closure_date_range'                :   '',
            'closure_date_range_start'          :   '',
            'closure_date_range_end'            :   '',
            'creation_date_range'               :   '',
            'creation_date_range_start'         :   '',
            'creation_date_range_end'           :   '',
            'estimate_day_range'                    :   '',
            'estimate_day_range_start'              :   '',
            'estimate_day_range_end'                :   '',
            'remark_ageing_days'                :   '',
            'search_deal_status'              :   [],
            'search_tags'                       :   [],
            'search_customer_ids'                :   [],
            'search_customer_project_ids'         :   []
        };

        this.dealSearchFormLabel      =   {
            'search_deal_type_id'         :   'Deal Type : ',
            'search_deal'                 :   'Deal : ',
            'search_deal_id'              :   'Deal ID : ',
            'search_deal_header'          :   'Deal Header : ',
            'search_tagged_users'           :   'Tagged Users : ',
            'search_ageing_days'            :   'Ageing Days : ',
            'closure_date_range'            :   'Completion Date Range : ',
            'creation_date_range'           :   'Creation Date Range : ',
            'estimate_day_range'                :   'Due Date Range : ',
            'remark_ageing_days'            :   'Remark Ageing Days : ',
            'search_deal_status'          :   'Deal Status : ',
            'deal_with_asset'             :   'Deal With Asset : ',
            'search_tags'                   :   'Tags : ',
            'search_customer_ids'              :   'Customers : ',
            'search_customer_project_ids'     :   'Customer Projects : ',
        };


        this.state                        =           {
            deal_listing                :           [],
            deal_view_data              :           [],
            listingMeta                   :           null,
            listing_tbl_page              :           1,
            totalListingCount             :           0,
            listing_loading               :           false,
            view_loading                  :           false,
            dealAddScreenView           :           false,
            dealUpdateScreenView        :           false,
            minimizeTable                 :           false,
            dealHeader                  :            "",
            dealSearchForm              :            {...this.dealSearchFormInitialState},
            submittedDealFilterForm     :            {...this.dealSearchFormInitialState},
            allDealType                 :            [],
            filterFormData                :            [],
            form_loading                  :            false,
            formSearchedElems             :            [],
            allTaggedUsers                :            [],
            allStatusList                 :            [],
            dealDownloading             :            false,
            saveFormSubmitting            :             false,
            remark_form_loading           :            false,
            remarkFormData                :             [],
            allRemarkStatus               :             [],
            addRemarkForm                :            {...this.initAddRemarkForm},
            options                      :             [],
            addDocumentForm              :              {...this.initAddDocument},
            reportDownloading             :             false,
            filterLoading                  :            false,
            dealTags                  :   [],  
            searchedCustomers           :   [],
            searchedCustomersProject    :   [] ,  
            all_periods                 :       []   
            
        }
        this.dealDetailRef            =   React.createRef();

        this.dealListUrl      =   INVENTORY_API_BASE_URL_2 + '/deal/list';
        this.dealFormDataUrl  =   INVENTORY_API_BASE_URL_2 + '/deal/form_data';
        this.dealShareUrl     =   INVENTORY_API_BASE_URL_2 + '/deal/share';
        this.dealDownloadUrl  =   INVENTORY_API_BASE_URL_2 + '/deal/download';
        this.dealDetailUrl        =   INVENTORY_API_BASE_URL_2 + '/deal/detail';

    }

    componentDidMount() {
        
        this.loadListingTblData(1);
        this.filterFormDataInit();
        if(this.props.all_periods){
            let all_periods             =              [...this.props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
            this.setState({ all_periods: all_periods })
        }
        
        
        this.dealSearchModal         =    new Modal(document.getElementById('dealSearchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.all_periods !== this.props.all_periods) {
            this.setState({ all_periods: [...nextProps.all_periods, { key: 'custom', display: 'Custom Date Range' }] })
        }

    } 
    
    //for add deal screen
    openCreateDealScreen             =        (data)           =>        {
        if(data){
            this.props.history.push({pathname : '/deals/add', state : {dealData : data}})
        }else{
            this.props.history.push({pathname : '/deals/add'})
        }
        
    }

    afterAddDealSubmit               =        ()           =>         {
        //Item is Added Successfully Need to refresh and close Add form
        this.setState({dealAddScreenView : false});
        //Load Add Item Form Data :-
        this.dealSearchClear();
    }

    
    closeDealView                    =      ()              =>         {
        this.setState({dealAddScreenView : false,minimizeTable:false}, () => {
            this.props.history.push("/deals");
        })
    }

   
    loadListingTblData = (page = 1,deal_id=null) => {
        this.setState({listing_loading:  true, listing_tbl_page: page});
        let params = {...this.state.submittedDealFilterForm, page: page};
        HttpAPICall.withAthorization('GET', this.dealListUrl, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            let updatedListing = deal_id
                ? this.state.deal_listing.map(item => item.id === deal_id ? respData.data.find(dataItem => dataItem.id === deal_id) || item : item)
                : respData.data;
            this.setState({
                deal_listing    : updatedListing,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount   : respData.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    //function for filter form data
    filterFormDataInit                 =        ()             =>        {
        this.setState({form_loading: true});
           HttpAPICall.withAthorization('GET', this.dealFormDataUrl, this.props.access_token, {}, {}, (resp) => {
           this.setState({filterFormData : resp.data,
               allDealType : resp.data.deal_types?.map((s) => { return {value: s.id, label: s.name}}),
               allTaggedUsers : resp.data.users?.map((s) => { return {value: s.id, label: s.full_name}}),
               allStatusList : resp.data.status?.map((s) => { return {value: s.id, label: s.name}}),
                dealTags                  :   resp.data.tags && resp.data.tags.length > 0 ? resp.data.tags.map((tag) => { return {value: tag, label: tag}; }) : [],    
                
           })
           
        }).then(() => {this.setState({form_loading: false})})
    }


    dealSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems              :   [],
            searchedCustomers                  :   [],
            searchedCustomersProject                  :   [],
            dealSearchForm               :   {...this.dealSearchFormInitialState},
            submittedDealFilterForm      :   {...this.dealSearchFormInitialState}
        }, () => {
            this.loadListingTblData(1);
            this.dealSearchModal.hide();
            this.props.history.push('/deals');
        });
    }

    dealSearchModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="dealSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Deal Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.form_loading ? <Loader />
                            : <form id="dealSearchForm" onSubmit={this.submitDealSearchForm}>
                                <div className="modal-body">
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Deal Type</label>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Select Deal Type"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                options={this.state.allDealType}
                                                value={this.state.allDealType.find(r => r.value === this.state.dealSearchForm.search_deal_type_id)}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_deal_type_id')}
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Tags</label>
                                        <div className="col-sm-4  ">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Select Tags"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                isMulti={true}
                                                options={this.state.dealTags}
                                                value={this.state.dealTags.filter(s => this.state.dealSearchForm.search_tags && this.state.dealSearchForm.search_tags.includes(s.value))}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_tags')}
                                            />
                                        </div>
                                       
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Deal Header</label>
                                        <div className="col-sm-4">
                                            <input
                                                type="text"
                                                name="search_deal_header"
                                                className="form-control form-control-sm"
                                                placeholder="Deal Header"
                                                autoComplete="off"
                                                value={this.state.dealSearchForm.search_deal_header}
                                                onChange={(e) => this.formInputHandler(e, 'dealSearchForm')}
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Deal Status</label>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                options={this.state.allStatusList}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_deal_status')}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.allStatusList.filter(s => this.state.dealSearchForm.search_deal_status.includes(s.value))}
                                                placeholder="Select Deal Status"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Customers</label>
                                        <div className="col-sm-4 ">

                                            <InputCustomerSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_customer_ids');
                                                    this.setState({ searchedCustomers: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                }}
                                                isMulti={true}
                                                menuPlacement="top"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedCustomers.length > 0 ? this.state.searchedCustomers.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Customers Project</label>
                                        <div className="col-sm-4 ">

                                            <CustomerProjectSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_customer_project_ids');
                                                    this.setState({ searchedCustomersProject: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                }}
                                                isMulti={true}
                                                menuPlacement="top"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedCustomersProject && this.state.searchedCustomersProject.length > 0 ? this.state.searchedCustomersProject.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Tagged User</label>
                                        <div className="col-sm-4 ">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Select User"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                isMulti={true}
                                                options={this.state.allTaggedUsers}
                                                value={this.state.allTaggedUsers.filter(s => this.state.dealSearchForm.search_tagged_users.includes(s.value))}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_tagged_users')}

                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Deal Ageing</label>
                                        <div className="col-sm-4 ">
                                            <input
                                                type="text"
                                                name="search_ageing_days"
                                                className="form-control form-control-sm"
                                                placeholder="Example. 0-5 for a range or 4 for Deal Aging"
                                                autoComplete="off"
                                                value={this.state.dealSearchForm.search_ageing_days}
                                                onChange={(e) => this.formInputHandler(e, 'dealSearchForm')}
                                            />

                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Creation Date </label>
                                        <div className="col-sm-4 ">
                                            <PeriodSelectionWithCustom
                                                periods={this.state.all_periods}
                                                required={false}
                                                value={this.state.dealSearchForm.creation_date_range}
                                                startDate={this.state.dealSearchForm.creation_date_range_start}
                                                endDate={this.state.dealSearchForm.creation_date_range_end}
                                                onSelectPeriod={(period, startDate, endDate) => {
                                                    this.setState({
                                                        dealSearchForm: { ...this.state.dealSearchForm, creation_date_range: period, creation_date_range_start: startDate, creation_date_range_end: endDate }
                                                    });
                                                }}
                                                className="form-control-sm"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />

                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Completion Date </label>
                                        <div className="col-sm-4 ">
                                            <PeriodSelectionWithCustom
                                                periods={this.state.all_periods}
                                                required={false}
                                                value={this.state.dealSearchForm.closure_date_range}
                                                startDate={this.state.dealSearchForm.closure_date_range_start}
                                                endDate={this.state.dealSearchForm.closure_date_range_end}
                                                onSelectPeriod={(period, startDate, endDate) => {
                                                    this.setState({
                                                        dealSearchForm: { ...this.state.dealSearchForm, closure_date_range: period, closure_date_range_start: startDate, closure_date_range_end: endDate }
                                                    });
                                                }}
                                                className="form-control-sm"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm   ">Due Date</label>
                                        <div className="col-sm-4 ">
                                            <PeriodSelectionWithCustom
                                                periods={this.state.all_periods}
                                                required={false}
                                                value={this.state.dealSearchForm.estimate_day_range}
                                                startDate={this.state.dealSearchForm.estimate_day_range_start}
                                                endDate={this.state.dealSearchForm.estimate_day_range_end}
                                                onSelectPeriod={(period, startDate, endDate) => {
                                                    this.setState({
                                                        dealSearchForm: { ...this.state.dealSearchForm, estimate_day_range: period, estimate_day_range_start: startDate, estimate_day_range_end: endDate }
                                                    });
                                                }}
                                                className="form-control-sm"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Remark Ageing</label>
                                        <div className="col-sm-4 ">
                                            <input
                                                type="text"
                                                name="remark_ageing_days"
                                                className="form-control form-control-sm"
                                                placeholder="Example. 0-5 for a range or 4 for Remark Aging"
                                                autoComplete="off"
                                                value={this.state.dealSearchForm.remark_ageing_days}
                                                onChange={(e) => this.formInputHandler(e, 'dealSearchForm')}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.dealSearchClear}>Clear</button>
                                    <button type="submit" className="btn btn-primary">Search</button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        );
    }

    dealSearchModalInit               =       ()             =>        {
        this.dealSearchModal.show();
        this.setState({filterLoading:true})
    }

    submitDealSearchForm              =        (e = null)            =>        {
         e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.dealSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.dealSearchFormLabel[key];
            let value                       =   this.state.dealSearchForm[key];
            if(value !== null && value.toString().length > 0) {
                searchParams[key]      =   value;
           if(label) {
                    let show_val            =   value;
                   
                    if(key == 'search_customer_ids') {
                        show_val            =   this.state.searchedCustomers && this.state.searchedCustomers.length > 0 ? this.state.searchedCustomers.map((emp, i) => emp.label).join(', ') : '';
                    }
                    if(key == 'search_customer_project_ids') {
                        show_val            =   this.state.searchedCustomersProject && this.state.searchedCustomersProject.length > 0 ? this.state.searchedCustomersProject.map((emp, i) => emp.label).join(', ') : '';
                    }
                    if(key == 'search_tags') {
                        show_val            =   this.state.dealTags.filter(s => this.state.dealSearchForm.search_tags && this.state.dealSearchForm.search_tags.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'search_deal_type_id') {
                        show_val            =   this.state.allDealType.filter((s) => value === s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_deal_header') {
                        show_val            =   this.state.dealSearchForm ? this.state.dealSearchForm.search_deal_header : '';
                    }
                   
                    if(key == 'search_ageing_days') {
                        show_val            =   this.state.dealSearchForm ? this.state.dealSearchForm.search_ageing_days : '';
                    }
                    if(key == 'closure_date_range') {
                        show_val            = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    if(key == 'creation_date_range') {
                        show_val            = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    if(key == 'estimate_day_range') {
                        show_val            = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    if(key == 'remark_ageing_days') {
                        show_val            = this.state.dealSearchForm ? this.state.dealSearchForm.remark_ageing_days : '';
                    }
                    if(key == 'search_tagged_users') {
                        show_val            =   this.state.allTaggedUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'search_deal_status') {
                        show_val            =   this.state.allStatusList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                   
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedDealFilterForm   :   {...this.state.dealSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.dealSearchModal.hide()
            this.loadListingTblData();
        });
    }

   
    getDealViewData                   =      (id)            =>        {
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET', this.dealDetailUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                deal_view_data  : respData,
                dealHeader      : respData.header,
            },() => {
              
            });
            
        }).then(() => this.setState({view_loading: false}));
    }

    viewDeal                          =        (id)           =>        {
        this.setState({minimizeTable:true, curent_activity_id : id});
         this.getDealViewData(id);
       
    }

    //function for download
    dealDownload                   =   ()  =>  {
        if(JSON.stringify(this.state.submittedDealFilterForm) !== JSON.stringify(this.dealSearchFormInitialState)){
            this.setState({ reportDownloading: true});
            let params  = {...this.state.submittedDealFilterForm}
            
            HttpAPICall.withAthorization('GET', this.dealListUrl, this.props.access_token,
                    {...params, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
             
        }else{
           swal({
            title: "Download",
            text: "The download will be for trailing three months period, in case you want a different criteria then please use search button before downloading.",
            icon: "warning",
            // dangerMode: true,
            buttons: ["Cancel", "Okay"],
        })
            .then(willDownload => {
                if (willDownload) {
                    this.setState({ reportDownloading: true});
                    let params = {creation_date_range: "current_quarter"}
                     HttpAPICall.withAthorization('GET', this.dealListUrl, this.props.access_token,
                        {...params, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
                }
            });
        }
       
    }

    addDocumentModal      =        (id)        =>      {
        this.dealDetailRef.current.addDocumentModalInit(id)
   }

    addRemarkModal      =        (id)        =>      {
        this.dealDetailRef.current.addRemarkInit(id)
    }


    dealTransactionListingJsx        =       ()             =>        {
        let dealData = this.state.deal_view_data ? this.state.deal_view_data : null;
        let setting     =   dealData && dealData.setting ? dealData.setting : null;

        return (<Ax>
            <div className="page_title row m0">
                <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                    <h3>Deals</h3>
                    <div className="text-end mt15">
                        {this.state.minimizeTable ? null : <button type="button"  disabled={!this.props.permissions.includes('deal_add')} className="btn btn-primary" onClick={() =>this.openCreateDealScreen()}>
                            Create Deals
                        </button>}
                        {this.state.minimizeTable && this.props.permissions.includes('deal_add') ?
                            <Ax>
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end " aria-labelledby="dropdownMenuButton1">
                                    <li><button className="dropdown-item" onClick={() => this.openCreateDealScreen()}>Create Deal</button></li>
                                </ul>
                            </Ax> : <Ax>
                                <button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={this.dealSearchModalInit}>
                                    <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                                </button>
                                
                            </Ax>
                        }
                    </div>
                </div>
                {
                    this.state.minimizeTable
                        ? (
                            <div className="col-9 tap_border_left">
                                <h3 className="text-capitalize">Deals : {this.state.dealHeader.slice(0, 40)}{this.state.dealHeader.length > 40 ? '...' : ''}  </h3>
                                <div className="text-end mt15">
                                    
                                    <button type="button" id="actionMenuButton" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-primary" >
                                        Update</button>
                                    <ul className="dropdown-menu dropdown-menu-end " aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a className={['dropdown-item', (this.state.detail_loading || !this.props.permissions.includes('deal_edit')) ? 'disabled' : ''].join(' ')} onClick={() => this.openCreateDealScreen(this.state.deal_view_data)}>Edit</a>
                                        </li>
                                        <li>
                                            <a className={['dropdown-item', (this.state.detail_loading || !this.props.permissions.includes('deal_change_status')) ? 'disabled' : ''].join(' ')} onClick={() => this.addRemarkModal()}>Add Remarks</a>
                                        </li>
                                        
                                        <li>
                                            <a
                                                className={["dropdown-item"].join(", ")}
                                                role="button"
                                                onClick={() => this.addDocumentModal()}
                                            >
                                                Add Document
                                            </a>
                                        </li>
                                    </ul>
                                  
                                    <button onClick={this.closeDealView} type="button" className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                    </button>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>

            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3 ' : 'col-12'].join(' ')} >{this.dealTransactionTableListJsx()}</div>
                    {this.state.minimizeTable 
                        ? <div className="col-9 px-0 pageTbl"><div className="bg-white">
                            {this.state.view_loading
                                ? <Loader />
                                : <DealsDetail
                                    allProps={this.props}
                                    dealData={this.state.deal_view_data}
                                    showOtherDetail='Y'
                                    performAction='Y'
                                    afterUpdate={(deal_id) =>{
                                        this.loadListingTblData(this.state.listing_tbl_page,deal_id)
                                    }}
                                    ref={this.dealDetailRef}
                                />
                            }
                        </div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    dealTransactionTableListJsx      =       ()             =>        {
        return (<Ax>
            <div className="">
                
              <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  onClearFilter={this.dealSearchClear}  />
                <table className="table table-bordered bg-white table-hover table-fixed table-sm">
                    <thead className="table-secondary">
                    { this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{width:"20%"}}>Deal </th>
                        </tr> )
                        :   ( <Ax>
                                <tr>
                                    <th scope="col" rowSpan="2" style={{width:"5%"}} className="text-center">S.No</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"8%"}}>Deal ID</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"20%"}}>Header</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"11%"}} >Deal Type</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"8%"}}>Creation Date</th>
                                    <th scope="col" rowSpan="2" style={{width:"15%"}} >Customer</th>
                                    <th scope="col" rowSpan="2" style={{width:"10%"}} >Last Updated</th>
                                    <th scope="col" colSpan="2" className="text-center" style={{width:"10%"}} >Aging (Days)</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"8%"}} >Status</th>
                                    <th scope="col" rowSpan="2" className="text-center" style={{width:"5%"}}>Action</th>
                                </tr>
                                <tr>
                                    <th scope="col"  className="text-center">Deal</th>
                                    <th scope="col"  className="text-center">Remarks</th>
                                </tr>
                            </Ax>
                        )
                    }

                    </thead>
                    <tbody>
                    { this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader/></td></tr>)
                        : (this.state.deal_listing.length > 0
                                ? (this.state.deal_listing.map((item , index) => {

                                    return(<tr key={index} >
                                        {
                                            this.state.minimizeTable
                                                ?   (
                                                    <Ax>
                                                        
                                                        <td style={{width:"25%"}} onClick={() => this.viewDeal(item.id)}>
                                                            <div className="text-capitalize link-primary"><Link >{item?.header}</Link>
                                                            <span className='float-end'>{item.transaction_id}</span>
                                                            </div>
                                                            <div className="mt-1">
                                                                <small className="text-capitalize ">{item.deal_type ? item.deal_type.name : ''} </small>
                                                                <small className="float-end"><Status color={item.status.color}>{item.status.name}</Status></small>
                                                            </div>
                                                        </td>
                                                    </Ax>
                                                )
                                                :   (<Ax>
                                                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index }</td>
                                                        <td>{item.transaction_id}</td>
                                                        <td><Link  role="button" className="link-primary text-capitalize" onClick={() => this.viewDeal(item.id)}>{item.header}</Link></td>
                                                        <td>{item.deal_type ? item.deal_type.name : "-"}</td>
                                                        <td>{item.created_at ? DateService.dateTimeFormat(item.created_at,'DD-MMM-YYYY') : ''}</td>
                                                        <td className="text-capitalize">
                                                            {item.customer && <span>{item.customer.name}</span>}
                                                        </td>
                                                        <td>{item.updated_at ? DateService.dateTimeFormat(item.updated_at,'DD-MMM-YYYY') : ''} <br/>
                                                            {item.latest_remark ? <small>{item.latest_remark?.remark}</small> : null}
                                                        </td>
                                                        <td className="text-center">{item?.aging}</td>
                                                        <td className="text-center">{item.remark_aging}</td>
                                                        <td  className="text-center"><Status color={item.status.color}>{item.status.name}</Status></td>
                                                        <td  className="text-center">
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><Link  className="dropdown-item" role="button" onClick={() => this.viewDeal(item.id)} >View</Link></li>
                                                            </ul>
                                                        </td>
                                                    </Ax>
                                                )}
                                    </tr>)
                                }))
                                : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                    </tbody>
                </table>
            </div>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
        </Ax>);
    }

    render                              =   ()  =>  {
        
        return (<ApplicationLayout>
           <Helmet><title>Deal Listing</title></Helmet>
            {this.dealTransactionListingJsx()}
            {this.dealSearchModalJsx()}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [], 
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DealsTransactionList);