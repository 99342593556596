
import { AssetProdConfigFormDataReducer } from "./assetProductivityReducer/AssetProductivityConfigurationReducer";
import { SiteProductivityBarWidgetReducer, SiteProductivityLineWidgetReducer, SiteProductivityPieWidgetReducer, SiteProductivityTargetWidgetReducer } from "./SiteProductivityReducers/SiteProductivityWidgetReducer";

export const allReducer = {
    AssetProdConfigFormDataReducer,
    SiteProductivityBarWidgetReducer,
    SiteProductivityLineWidgetReducer, 
    SiteProductivityPieWidgetReducer, 
    SiteProductivityTargetWidgetReducer
}