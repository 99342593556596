import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import AssetHeader from '../includes/AssetHeader';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import download from '../../../services/DownloadFile';
import moment from 'moment';
import ProductivityView from '../../transactions/productivity/ProductivityView';

class AssetProductivity extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.productivitySearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_activities'             :    [],
            'search_units'                   :    [],
            'search_material_ids'           :    [],
            'site_group_ids'                :    [],
            'search_site_ids'               :   [],
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'productivity_transaction_delay'     :   ''
        };

        this.productivitySearchFormLabel         =   {
            'site_group_ids'                 :   'Site Groups : ',
            'search_activities'              :   'Activity : ',
            'search_units'                   :    'Production Unit : ',
            'search_material_ids'           :    'Material : ',
            'search_site_ids'                :   'Site : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'activity_transaction_delay'     :   'Transaction Delay : '
        };


        this.state                          =       {
            access_token                    :       '',
            assetDetail                     :       null,
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            productivity_listing            :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            asset_loading                   :       false,
            assetId                         :       null,
            current_page                    :       1,
            minimizeTable                   :       false,
            view_loading                    :       false,
            iam_group_sites             :          [],
            all_periods                 :          [],
            iam_group_sites             :          [],
            productivitySearchForm      :          {...this.productivitySearchInitState},
            formSearchedElems           :          [],
            productivitySearchParams    :          null,
            allTransactionDelay         :          [],
            allActivitesList            :           [],
            allProductionUnitList       :           [],
            allMaterialList             :           [],
            allSiteGroupsList           :           [],
            file_downloading            :           false
        }
       
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.searchFormDataInit(this.props); 
        this.productivitySearchModal         =    new Modal(document.getElementById('productivitySearchModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        this.setState({assetId              :       pr.match?.params?.id,
            },() => {
                    
            this.getAsssetDetails(pr.match?.params?.id)
            this.loadListingTblData(1,pr.match?.params?.id)
        })
    }
    

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }


    closeassetProductivityView                    =      ()              =>         {
        this.setState({minimizeTable:false})
    }

    loadListingTblData                 =   (page = 1,id)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                     =   {page:page,need_asset_profile : 'Y',...this.state.productivitySearchParams,search_asset_id : id ? id : this.state.assetId};
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/productivity_list', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                productivity_listing    :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
           });
        }).then(() => this.setState({listing_loading: false}));
    }

    viewProductivityDetail           =       (id)        =>      {
        this.setState({minimizeTable : true})
        // if(this.state.productivityData && this.state.productivityData.productivity_id !== id){
            this.getProductivityDetail(id)
        // }
         
    }

    getProductivityDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/productivity/' + id, this.state.access_token ? this.state.access_token : this.props.access_token, {need_counterlog : 'Y',need_site : 'Y',need_user : 'Y'}, {}, (response) => {
            
            this.setState({
                productivityData: {...response.data.data,counterlog : {...response.data.data.counterlog,site: response.data.data.site}}
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/productivity_list_form-data', this.props.access_token, {})
            .then((response) => {
               let respData = response.data
              
              
                this.setState({
                    iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                    all_periods             :       all_periods,
                    allTransactionDelay     :       response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : [],
                    allActivitesList        :       respData && respData.activities ? respData.activities.map((td) => { return {value: td.id, label: td.activity_name,production_units : td.production_units}}) : [],
                    allSiteGroupsList       :       props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    allProductionUnitList   :       respData && respData.activities ? respData.activities.map((td) => { return {value: td.id, label: td.activity_name,production_units : td.production_units}}) : [],
                    allMaterialList         :       respData && respData.materials && respData.materials.length > 0 ? respData.materials.map(a => { return ({ value: a.id, label: a.material_name }) }) : [],
                },() => {
                    let allProductionUnits = [];
                    let activityListContainingProduction = this.state.allActivitesList && this.state.allActivitesList.length > 0 && this.state.allActivitesList.filter(p => { return (p.production_units && p.production_units.length > 0 ? true : false) });
                    let allProductionUnitList = activityListContainingProduction && activityListContainingProduction.length > 0 && allProductionUnits.concat(activityListContainingProduction.flatMap((d) => { return d.production_units.map(p => { return ({ value: p.id, label: p.measuring_unit }) }) }))
                    const key = 'value';
                    const UniqueProductionUnitByKey = allProductionUnitList && allProductionUnitList.length > 0 && [...new Map(allProductionUnitList.map(item => [item[key], item])).values()];
                    this.setState({allProductionUnitList : UniqueProductionUnitByKey})
                })
                   
            }) 
    }

    productivitySearchModalInit        =       ()      =>      {
        this.productivitySearchModal.show()
    }

    productivitySearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems           :   [],
            productivitySearchParams    :   null,
            searchedAssets              :   [],
            productivitySearchForm      :   {...this.productivitySearchInitState}
        }, () => {
            this.productivitySearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    productivitySearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.productivitySearchForm);
        let searchedElems               =   [];
        let productivitySearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.productivitySearchFormLabel[key];
            let value                       =   this.state.productivitySearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                productivitySearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                   
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activities') {
                        show_val            =  this.state.allActivitesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_units') {
                        show_val            =  this.state.allProductionUnitList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_material_ids') {
                        show_val            =  this.state.allMaterialList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.productivitySearchForm && this.state.productivitySearchForm.search_transaction_id ? this.state.productivitySearchForm.search_transaction_id : '';
                    }
                    if(key == 'productivity_transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.productivitySearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.productivitySearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range          =      moment(this.state.productivitySearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =     moment(this.state.productivitySearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =     `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            productivitySearchParams      :       productivitySearchParams
        }, () => {
            this.productivitySearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    productivityDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.productivitySearchParams};
        if(this.state.productivitySearchParams !== null){
            this.productivityDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_month"}
                    this.productivityDownloadStart(params);
                }
            });
        }
    }
    

    productivityDownloadStart              =    (params)     =>         {
        this.setState({ file_downloading: true});
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/productivity_list', this.props.access_token,{download : "download",...params,search_asset_id : this.state.assetId},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({file_downloading: false}));
    } 

     //***********************productivity JSX****************



    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader  assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props} />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div> 
            <div id="assetCard" className="collapse pr-1 mt-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />}
        </div>
        </div>)
    }

    assetProductivityListJsx            =       ()      =>    {

        return (<Ax>
            <div style={{ paddingTop: "4px" }}>
                <div className="page_title row m0" style={{ height: "45px" }}>
                    <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                        <h3 style={{ paddingTop: "13px" }}>Asset Productivity List</h3>
                    </div>
                    {!this.state.minimizeTable
                        ? <div className='col-sm-2 '>
                            <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                        </div> : null}
                    {!this.state.minimizeTable
                        ? <div className='col-sm-4 text-end mt7 '>
                            <button type="button" disabled={this.state.listing_loading} onClick={this.productivitySearchModalInit} className="btn btn-secondary" >
                                <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <button type="button" className="btn btn-secondary"
                                disabled={this.state.file_downloading || this.state.listing_loading}
                                onClick={this.productivityDownloadHandler} >
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                                {this.state.file_downloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>

                        </div> : null}

                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <div className="col-sm-3 tap_border_left">
                                        <h3 style={{ paddingTop: "13px" }}>Productivity : </h3>
                                    </div>
                                    <div className='col-sm-2 '>
                                        <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                    </div>
                                    <div className='col-sm-4 text-end mt7'>

                                        <button onClick={this.closeassetProductivityView} type="button" className="btn btn-secondary">
                                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </Ax>
                            )
                            : null
                    }
                </div>
            </div>
            <div className="container-fluid pl0 pr13">
                <div className="page_containt row">
                    <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.assetProductivityTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0 pageTbl pb-5"><div className=" bg-white p-2" >{this.state.viewDataLoading ? <Loader /> : <div className='tab_content_header' style={{ height: "80vh" }}>{this.productivityViewJsx()}</div>}</div> </div> : null}
                </div>
            </div>
        </Ax>);
    }

    assetProductivityTableJsx                   =   ()  =>  {
        return (
            <Ax>
                 <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.productivitySearchClear} />
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>
                    <table className="table table-bordered table-fixed  bg-white table-sm">
                        <thead className="table-secondary" >
                            {this.state.minimizeTable
                                ? (<tr>
                                    <th scope="col" style={{ width: "15%" }}>Productivity</th>
                                </tr>)
                                : (<tr>
                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                    <th scope="col" style={{ width: "10%" }} className='text-center'>Date</th>
                                    <th scope="col" style={{ width: "12%" }}> Transaction ID</th>
                                    <th scope="col" style={{ width: "15%" }}>Asset</th>
                                    <th scope="col" style={{ width: "15%" }}>Asset Type</th>
                                    <th scope="col" style={{ width: "15%" }}>Activity Type</th>
                                    <th scope="col" style={{ width: "15%" }}>Net Productivity</th>
                                    <th scope="col" style={{ width: "8%" }}>Material</th>
                                    <th scope="col" style={{ width: "5%" }}>Action</th>
                                </tr>)
                            }
                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                : (this.state.productivity_listing.length > 0
                                    ? (this.state.productivity_listing.map((productivity, index) => {
                                        return (<tr key={index} >
                                            {
                                                this.state.minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td style={{ width: "25%" }} >
                                                                <div className="text-capitalize link-primary"><a href="#" onClick={() => this.viewProductivityDetail(productivity.productivity_id)}>{productivity.productivity_id ? productivity.productivity_id : "-"}</a></div>
                                                                <div className="mt-1">
                                                                    <small className="text-capitalize ">{productivity.activityData ? productivity.activityData.activity_name : "-"}</small>
                                                                    <small className="float-end">{productivity.production ? productivity.production : null}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    : (<Ax>
                                                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                        <td className='text-center' >{productivity.activity_date_display ? productivity.activity_date_display : "-"}</td>
                                                        <td className='link-primary cursor_pointer' onClick={() => this.viewProductivityDetail(productivity.productivity_id)} >{productivity.productivity_id ? productivity.productivity_id : "-"}</td>
                                                        <td>{productivity.asset ? productivity.asset.name : "-"}</td>
                                                        <td>{productivity.asset ? productivity.asset.asset_type_name : "-"}</td>
                                                        <td>{productivity.activityData ? productivity.activityData.activity_name : "-"}</td>
                                                        <td>{productivity.production ? productivity.production : null} {productivity.production_unit ? <span>({productivity.production_unit})</span> : null}</td>
                                                        <td>{productivity.materialData ? productivity.materialData.material_name : "-"}</td>
                                                        <td className="text-center">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" role="button" onClick={() => this.viewProductivityDetail(productivity.productivity_id)} >View</a></li>

                                                            </ul>
                                                        </td>
                                                    </Ax>
                                                    )}
                                        </tr>)
                                    }))
                                    : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className='mx-3 my-1'>
                <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
                </div>

            </Ax>
        );
    }

    productivityViewJsx           =       ()      =>      {
        return (<Ax>
            <div className='row'>
                <div className='col-sm-12'>
                    <ProductivityView productivityData={this.state.productivityData} />
                </div>
                
            </div>

        </Ax>)
    }

    productivitySearchModalJsx         =   ()  =>  {
        return (
            <div className="modal fade" id="productivitySearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="productivitySearchModalLabel">Productivity Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.productivitySearchFormSubmit} id="productivitySearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.productivitySearchForm.search_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "productivitySearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Activity Name</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allActivitesList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_activities')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allActivitesList.filter(s => this.state.productivitySearchForm.search_activities.includes(s.value))}
                                            placeholder="Select Activity"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Production Unit</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allProductionUnitList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_units')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allProductionUnitList.filter(s => this.state.productivitySearchForm.search_units.includes(s.value))}
                                            placeholder="Select Production Unit"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Material</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allMaterialList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_material_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allMaterialList.filter(s => this.state.productivitySearchForm.search_material_ids.includes(s.value))}
                                            placeholder="Select Material"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                              
                                
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Group</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allSiteGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    productivitySearchForm: {
                                                        ...this.state.productivitySearchForm,
                                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allSiteGroupsList.filter(s => this.state.productivitySearchForm.site_group_ids.includes(s.value))}
                                            placeholder="Select Site Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Executed Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.productivitySearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Executed Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'activity_transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.productivitySearchForm.activity_transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.productivitySearchForm.entry_date_range}
                                            startDate={this.state.productivitySearchForm.entry_date_range_start}
                                            endDate={this.state.productivitySearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    productivitySearchForm
                                                        : {
                                                        ...this.state.productivitySearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.productivitySearchForm.date_range}
                                            startDate={this.state.productivitySearchForm.date_range_start}
                                            endDate={this.state.productivitySearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    productivitySearchForm
                                                        : {
                                                        ...this.state.productivitySearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
   
    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset - Productivity List</title></Helmet>
             {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.assetProductivityListJsx()}
            {this.productivitySearchModalJsx()}
        
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AssetProductivity);