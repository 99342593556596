import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import moment from 'moment';
import DatePicker from "react-datepicker";
import tapIcon from '../../../services/TapIcon';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import uuid from 'react-uuid';

class CounterlogExecutionModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.executeFormInit            =       {
            odometer                     :       '',
            operation_cycle               :       '',
            hourmeter                     :       '',
            date_time                     :       '',
            hourmeter2                    :       '' , 
            hourmeter3                    :       '' , 
            hourmeter4                    :       '' ,
            odometer2                    :       '',
            odometer3                    :       '',
            operation_cycle2              :       '',
        }

        this.state                      =   {
            execution_loading           :       false,
            selectedRowList             :       [],
            assetDetail                 :       null,
            counterlog                  :       null,
            executeForm                 :       {...this.executeFormInit},
            parameterOptions            :       [{value : 'ok' , label : 'Checked and OK' },
                                                {value : 'required_attention' , label : 'May Required Attention' },
                                                {value : 'required_immediateattention' , label : 'Required Immediate Attention' }],
        }
        this.counterlogExecutionModalId      =   uuid();
    }

    componentDidMount() {
        this.counterlogExecutionModal      =   new Modal(document.getElementById(this.counterlogExecutionModalId), {keyboard: false, backdrop: false});
    }
    
    executeCounterlogInit                =   (selectedRowList ,assetDetail,counterlog) => {
         
        this.counterlogExecutionModal.show()
        this.setState({
            selectedRowList     :       selectedRowList,
            assetDetail         :       assetDetail,
            counterlog          :       counterlog ? counterlog : null
        }, () => {
            this.setState({ execution_loading: false })
            if(counterlog){
                this.setState({
                    executeForm : {...this.executeFormInit,
                        odometer                      :       counterlog.odometer_abs,
                        operation_cycle               :       counterlog.cycle_abs,
                        hourmeter                     :       counterlog.hours_abs,
                        date_time                     :       counterlog.feed_datetime,
                        hourmeter2                    :       counterlog.hours_abs2 , 
                        hourmeter3                    :       counterlog.hours_abs3 , 
                        hourmeter4                    :       counterlog.hours_abs4 ,
                        odometer2                     :       counterlog.odometer_abs2,
                        odometer3                     :       counterlog.odometer_abs3,
                        operation_cycle2              :       counterlog.cycle_abs2,
                    }
                })
            }
        })
    }
    
    submitExecuteFormHandler        =       (e)      =>  {
        e.preventDefault();
    
        let asset_scs_setting_ids     =       this.state.selectedRowList.map((s,k) => s.id)
        
        let parameter       =   [];
        parameter = this.state.selectedRowList.filter(s => s.enable_parameter == "Y");
      
        let executeForm           =       {...this.state.executeForm,date_time : moment(this.state.executeForm.date_time,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')}
        let frmData               =       {scs_setting_ids        :       asset_scs_setting_ids ,
                                           counterlog_transaction_id    :       this.state.counterlog ? this.state.counterlog.transaction_id : '',
                                            ...executeForm,
                                            parameter                   :       parameter.length > 0
                                                                        ?       parameter.map((p,key) => {
                                                                                    if(p.execute_level){
                                                                                        return({scs_setting_id : p.id, execute_level : p.execute_level ? parseInt(p.execute_level) : null })
                                                                                    }else{
                                                                                        return({scs_setting_id : p.id,execute_val : p.execute_val ? p.execute_val : null})
                                                                                    }
                                                                                })      
                                                                        :        []};
    
        this.setState({ executeFormSubmitting: true })
        HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/scs/execute_with_counterlog', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.counterlogExecutionModal.hide()
            this.props.afterExecutionSubmit()

        }).then(() => this.setState({ executeFormSubmitting: false }));
    }

    render                              =   ()  =>  {
        let labels                          =   this.state.assetDetail && this.state.assetDetail.labels ? this.state.assetDetail.labels : {};
      
        return (
            <div className="modal fade" id={this.counterlogExecutionModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Execute Service CheckSheet</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body ">
                            <table className="table table-bordered bg-white align-items-center  table-sm table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                                        <th scope="col" style={{ width: "65%" }} >Service Checksheet Name</th>
                                        <th scope="col" style={{ width: "35%" }} >Parameter</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.execution_loading
                                        ? <tr colSpan={2}><td className='text-center'><Loader /></td></tr>
                                        : this.state.selectedRowList && this.state.selectedRowList.length > 0 && this.state.selectedRowList.map(((sa, key) => {

                                            return (<tr key={key + 1}>
                                                <td className='text-center'>{key + 1}</td>
                                                <td>{sa?.alias_name ? sa?.alias_name : "-"}</td>
                                                <td>{sa.enable_parameter == "Y"
                                                    ? <div>{sa.parameter && sa.parameter.type == "range"
                                                        ? <span>
                                                            <input
                                                                type="number"
                                                                className='form-control'
                                                                name={"execute_level"}
                                                                autoFocus={true}
                                                                onChange={(e) => this.dynamicInputHandlerByKey(e, key, 'selectedRowList')}
                                                                value={this.state.selectedRowList[key]?.execute_level}
                                                                placeholder={`${sa.parameter.type_display ?? ""}`}
                                                                style={{ height: "30px", fontSize: "93%" }} />
                                                        </span>
                                                        : <TapSelect
                                                            changeEvent={(selectedOption) => {
                                                                let selectedRowList = this.state.selectedRowList;
                                                                selectedRowList[key] = { ...selectedRowList[key], execute_val: selectedOption ? selectedOption.value : '' };
                                                                this.setState({ selectedRowList: [...selectedRowList] });

                                                            }}

                                                            options={this.state.parameterOptions}
                                                            isClearable={true}
                                                            autoFocus={true}
                                                            value={this.state.parameterOptions.find(m => m.value == this.state.selectedRowList[key]?.execute_val)}
                                                            placeholder="Please Select Options"
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                        />}</div>
                                                    : "-"}</td>
                                            </tr>)
                                        }))}
                                </tbody>
                            </table>
                            <form onSubmit={this.submitExecuteFormHandler} id={`${this.counterlogExecutionModalId}Form`}>
                                <div className='counterlog'>
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Counterlog</span>
                                    </div>
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">{labels && labels.hour_meter ? labels.hour_meter : 'Hour Meter'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="hourmeter"
                                                    type="number"
                                                    value={this.state.executeForm.hourmeter}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Hour"
                                                    autoFocus={true}
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'executeForm') }}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter2 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.hour_meter2 ? labels.hour_meter2 : 'Hour Meter 2'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="hourmeter2"
                                                    type="number"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.executeForm.hourmeter2}
                                                    placeholder="Input in Hour"
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs2}</div>
                                                    : <div>No Counterlog</div>}

                                            </label>


                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter3 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.hour_meter3 ? labels.hour_meter3 : 'Hour Meter 3'} </label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="hourmeter3"
                                                    type="number"
                                                    value={this.state.executeForm.hourmeter3}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Hour"
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs3}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter4 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.hour_meter4 ? labels.hour_meter4 : 'Hour Meter 4'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="hourmeter4"
                                                    type="number"
                                                    value={this.state.executeForm.hourmeter4}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Hour"
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs4}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.odo_meter ? labels.odo_meter : 'Odo Meter'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="odometer"
                                                    type="number"
                                                    value={this.state.executeForm.odometer}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Kms"
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter2 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.odo_meter2 ? labels.odo_meter2 : 'Odo Meter 2'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="odometer2"
                                                    type="number"
                                                    value={this.state.executeForm.odometer2}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Kms"
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs2}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>


                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter3 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.odo_meter3 ? labels.odo_meter3 : 'Odo Meter 3'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="odometer3"
                                                    type="number"
                                                    value={this.state.executeForm.odometer3}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Input in Kms"
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs3}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>


                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.operation_cycle == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.operation_cycle ? labels.operation_cycle : 'Operation Cycle'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="operation_cycle"
                                                    type="number"
                                                    value={this.state.executeForm.operation_cycle}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Operation Cycle Reading"
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.cycle_abs}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}
                                    {this.state.assetDetail && this.state.assetDetail.operation_cycle2 == "yes"
                                        ? <div className="row my-3 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">{labels && labels.operation_cycle2 ? labels.operation_cycle2 : 'Operation Cycle 2'}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="operation_cycle2"
                                                    type="number"
                                                    value={this.state.executeForm.operation_cycle2}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Operation Cycle Reading"
                                                    disabled={this.state.counterlog ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.cycle_abs2}</div>
                                                    : <div>No Counterlog</div>}
                                            </label>
                                        </div>
                                        : null}

                                    <div className="row my-3 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm  ">Counterlog Date</label>
                                        <div className="col-sm-4 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.executeForm.date_time
                                                        ? moment(this.state.executeForm.date_time).toDate()
                                                        : false
                                                }
                                                name="date_time"
                                                onChange={(value, event) => {
                                                    this.formDateHandler('date_time', value, 'executeForm','YYYY-MM-DD HH:mm:ss')
                                                }}
                                                popperPlacement="right-start"
                                                className={"form-control"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Due Date`}
                                                showTimeSelect
                                                timeIntervals={5}
                                                timeCaption="Time"
                                                dateFormat="dd-MMM-yyyy hh:mm a"
                                                disabled={true}
                                                maxDate={moment(new Date(),'YYYY-MM-DD').toDate()}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon" />
                                        </div>
                                        <label className="col-sm-5 form-text ">
                                            {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.feed_date_time_display}</div>
                                                : null}
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" disabled={this.state.executeFormSubmitting} data-bs-dismiss="modal" >Cancel</button>
                            <button type="button" className="btn btn-primary" disabled={this.state.executeFormSubmitting} form={`${this.counterlogExecutionModalId}Form`} onClick={(e) => this.submitExecuteFormHandler(e)}>Update {this.state.executeFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(CounterlogExecutionModal);