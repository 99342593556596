import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import Ax from "../../../components/hoc/Ax";
import { Link } from 'react-router-dom';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class FuelFeedInView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                        =             {
            fuelFeedInData                 :            null,        
        }
       
    }

    componentDidMount() {
           this.intializeData(this.props)
          
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.fuelFeedInData != nextProps.fuelFeedInData || this.props.fuelFeedInId != nextProps.fuelFeedInId) {
            this.intializeData(nextProps);
        }
        
    }

    intializeData       =       (pr)      =>      {
        this.setState({access_token : pr.access_token,fuelFeedInData : pr.fuelFeedInData ? pr.fuelFeedInData : null},
            () => {
                if(pr.fuelFeedInId){
                    this.getFuelFeedInDetail(pr.fuelFeedInId)
                }
            })
    }

    getFuelFeedInDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/fuelfeedin_detail/' + id, this.state.access_token ? this.state.access_token : this.props.access_token, {need_asset_profile : 'Y'}, {}, (response) => {
            this.setState({
                fuelFeedInData: response.data.data
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    render                              =   ()  =>  {
        let fuelFeedIn = this.state.fuelFeedInData;
        return (<Ax>
            {this.state.viewDataLoading ? <Loader />
                :
                fuelFeedIn
                    ?
                    <Ax>
                        <table className="table my-2 table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <td style={{ "width": "25%" }}>Fuel Station Name</td>
                                    <th style={{ "width": "66%" }}>{fuelFeedIn.fuelStation ? fuelFeedIn.fuelStation.fuelstation_name : 'Other'}   </th>
                                </tr>
                                {!fuelFeedIn.fuelStation
                                    ? <tr>
                                        <td style={{ "width": "25%" }}>Other Station Details</td>
                                        <th style={{ "width": "66%" }}>{fuelFeedIn.other_station ? fuelFeedIn.other_station : '-'}   </th>
                                    </tr>
                                    : null}
                                <tr>
                                    <td style={{ "width": "25%" }}>Quantity</td>
                                    <th style={{ "width": "66%" }}>{fuelFeedIn.qty ? `${fuelFeedIn.qty} ${fuelFeedIn.fuel_unit} ` : '-'}   </th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Unit Rate</td>
                                    <th style={{ "width": "66%" }}>{fuelFeedIn.unit_rate ? fuelFeedIn.unit_rate : '-'}   </th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Amount</td>
                                    <th style={{ "width": "66%" }}>{fuelFeedIn.amount ? fuelFeedIn.amount : '-'}   </th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Tank Status</td>
                                    <th style={{ "width": "66%" }}>{fuelFeedIn.full_tank &&  fuelFeedIn.full_tank == 'Y' ? "Full" : 'Partially filled'}   </th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Wallet</td>
                                    <th style={{ "width": "66%" }} className="text-capitalize">{fuelFeedIn.walletData && fuelFeedIn.walletData.wallet_name ? fuelFeedIn.walletData.wallet_name : "-"}   </th>
                                </tr>
                                {fuelFeedIn.additional_attributes_data && fuelFeedIn.additional_attributes_data.length > 0 ?
                                fuelFeedIn.additional_attributes_data.map((attribute, index) => {
                                    return (<tr>
                                        <td style={{ width: "25%" }}>{attribute.name}</td>
                                        <th>{attribute.value_display ? attribute.value_display : "-"}</th>
                                    </tr>)
                                })
                                : null}
                                <tr>
                                    <td style={{ "width": "25%" }}>Reference Number</td>
                                    <th style={{ "width": "66%" }} >{fuelFeedIn.fuel_station_ref ? fuelFeedIn.fuel_station_ref : "-"}   </th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Document</td>
                                    <th style={{ "width": "66%" }} >{fuelFeedIn.invoice_url ? <a target="_blank" href={fuelFeedIn.invoice_url}>View Document</a> : "-"}   </th>
                                </tr>

                                <tr>
                                    <td style={{ "width": "25%" }}>Notes</td>
                                    <th style={{ "width": "66%" }} >{fuelFeedIn.notes ? fuelFeedIn.notes : "-"}   </th>
                                </tr>


                            </tbody>
                        </table>
                        {fuelFeedIn.actual_counterlog && fuelFeedIn.actual_counterlog.reading && fuelFeedIn.actual_counterlog.reading.length > 0
                            ? <Ax>
                                <div className="tab_content_wrapper"> <span className="content_heading">Counterlog Detail</span></div>
                                <table className="table my-2 table-hover table-bordered">
                                    <tbody>
                                        {fuelFeedIn.actual_counterlog.reading.map(counterlog => {
                                            return (<tr>
                                                <td style={{ "width": "25%" }}> {counterlog.label ? counterlog.label : ''}</td>
                                                <th style={{ "width": "66%" }}>{counterlog.reading} {counterlog.unit}   </th>
                                            </tr>)

                                        })}
                                    </tbody>
                                </table>
                            </Ax>
                            : null}
                        <div className="tab_content_wrapper"> <span className="content_heading">Transaction Details</span></div>
                        <table className="table my-2 table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <td style={{ "width": "25%" }}>Transaction ID</td>
                                    <th style={{ "width": "66%" }}>{fuelFeedIn.actual_cl_transaction_id ? fuelFeedIn.actual_cl_transaction_id : fuelFeedIn.transaction_id ? fuelFeedIn.transaction_id :  '-'}   </th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Date of Transaction</td>
                                    <th style={{ "width": "66%" }}>{fuelFeedIn.actual_cl_transaction_id ? fuelFeedIn.actual_counterlog.feed_datetime :  fuelFeedIn.transaction_date_display ? fuelFeedIn.transaction_date_display : '-'}   </th>
                                </tr>

                                <tr>
                                    <td>Executed By</td>
                                    <th>{fuelFeedIn.user ? fuelFeedIn.user.full_name : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Date of Entry</td>
                                    <th style={{ "width": "66%" }}>{fuelFeedIn.created_at_display ? fuelFeedIn.created_at_display : '-'}   </th>
                                </tr>
                            </tbody>
                        </table>
                    </Ax>
                    : null}

        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(FuelFeedInView);