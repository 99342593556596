const TapHelper         =   {

    arraySumByKey           :   function(arr, key) {
        return arr.length === 0 
            ? 0 
            :  arr.reduce((accumulator, object) => {
                return parseFloat(accumulator) + ((key in object && object[key] !== null) ? parseFloat(object[key]) : 0);
            }, 0);
    },

    pick                :   function(obj, arr) {
        return arr.reduce((acc, record) => (record in obj && (acc[record] = obj[record]), acc), {});
    },


    firstStringPlusFormatter    :   function(arr) {
        if(arr.length === 0 ) {
            return '';
        } else {
            let text            =   arr[0];
            if(arr.length > 1) {
                text           +=   ' (+' + (parseInt(arr.length) - 1) + ')' ;
            }
            return text;
        }
    },

    numberFormatter              :   function(number, precision=2) {
        let parsedNumber            =   parseFloat(number);
        if (!isNaN(parsedNumber)) {
            return parseFloat(parsedNumber.toFixed(precision)); 
        }
        return ''
    },

    dispayAsAmount              :   function(num) {
        return this.numberFormatter(num,2);
    },

    textTruncate                :   function(str, length = 100, ending="...") {
        if (str && (str.length > length)) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    },

    range                :   function(a, b, step=1) {
        var A = [];
        if(typeof a == 'number'){
            A[0] = a;
            step = step || 1;
            while(a+step <= b){
                A[A.length]= a+= step;
            }
        }
        else {
            var s = 'abcdefghijklmnopqrstuvwxyz';
            if(a === a.toUpperCase()){
                b = b.toUpperCase();
                s = s.toUpperCase();
            }
            s = s.substring(s.indexOf(a), s.indexOf(b)+ 1);
            A = s.split('');        
        }
        return A;
    }

    
};

export default TapHelper;