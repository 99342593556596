import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import moment from 'moment';
import DatePicker from "react-datepicker";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { HRM_API_BASE_URL_2, SCHEDULE_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapIcon from '../../../services/TapIcon';
import Loader from '../../../components/ui/Loader/Loader';
import * as actions from "../../../store/actions/index";
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import EmployeeShiftLinkingModal from '../includes/EmployeeShiftLinkingModal';
import AttendanceMultipleEmployeeModal from './includes/AttendanceMultipleEmployeeModal';
import DateService from '../../../services/DateService';
import Status from '../../../components/ui/Status';
import EmpAttendanceDetailJsx from './includes/EmpAttendanceDetailJsx';
import EmployeeSearchModal from '../employee/EmployeeSearchModal';
import AttendenceUploadModal from '../attendence1/AttendenceUploadModal';

const DailyAttendance                   =   forwardRef((props, ref) => {

    const searchEmployeeModalRef            =   useRef(null);
    const bulkAttendenceModalRef            =   useRef(null);
    let initListData                        =   {
        loading                                 :   false,
        items                                   :   [],
        sites                                   :   [],
        meta                                    :   null,
        page                                    :   1,
        per_page                                :   50
    };
    let initFormData                        =   {
        submitting                              :   false,
        transaction_date                        :   new Date()
    };
    let initAttendanceSummary              =   {
        display                                 :   false
    }

    const empShiftLinkingModalRef           =   useRef(null);
    const attendanceMultipleEmployeeModalRef=   useRef(null);
    const dispatch                          =   useDispatch();
    const access_token                      =   useSelector(state => state.auth.access_token);

    const [formSearchedElems, setFormSearchedElems]     =   useState([]);
    const [submittedSearchForm, setSubmittedSearchForm] =   useState({});
    const [itemData, setItemData]           =   useState({...initListData});
    const [formData, setFormData]           =   useState({...initFormData});
    const [attendanceSummary, setAttendanceSummary]=  useState({...initAttendanceSummary});


    let itemFormDataUrl                     =   HRM_API_BASE_URL_2 + "/daily_attendance/form_data";
    let listUrl                             =   HRM_API_BASE_URL_2 + "/daily_attendance/employee";
    
    useEffect(() => {
        loadListData();
    }, [submittedSearchForm]);

    const loadListData                      =   (page = 1, date = null)  =>  {
        setItemData(pd => ({...pd, loading : true, page : page }));
        let params                      =   {
                                                ...submittedSearchForm,
                                                page : page,
                                                ...formData, 
                                                transaction_date : moment(date ? date : formData.transaction_date).format("YYYY-MM-DD")
                                            };
        HttpAPICall.withAthorization('GET', listUrl, access_token, params, {}, (response) => {
            let respData                =   response.data.employees;
            let meta                    =   {
                from                        :   respData.from,
                to                          :   respData.to,
                total                       :   respData.total,
                last_page                   :   respData.last_page,
                per_page                    :   respData.per_page,
                current_page                :   respData.current_page
            };
            setItemData(pd => ({...pd, items : respData.data, meta : meta, sites : response.data.sites }));
        }).then(() => {
            setItemData(pd => ({...pd, loading : false}));
        });
    }

    const refreshDataEvent                  =   ()  =>  {
        loadListData(itemData.page);
    }

    const checkAllItem                      =   (event) => {
        const employeeCollections = document.getElementsByClassName('employee_id_checkbox');
        for (let i = 0; i < employeeCollections.length; i++) {
            employeeCollections[i].checked = event.target.checked;
        }
    }

    const linkEmployeeToShift               =   (employee_id) => {
        if (empShiftLinkingModalRef.current) {
            empShiftLinkingModalRef.current.initModalHandler(employee_id);
        }
    }

    const dateChangeHandler                 =   (date)  =>  {
        if(date=='prev') {
            date                                =   DateService.subDays(1, formData.transaction_date);
        }
        if(date=='next') {
            date                                =   DateService.addDays(1, formData.transaction_date);
        }
        setFormData(pd => ({...pd, transaction_date : date }));
        setTimeout(() => {
            loadListData(1, date);    
        }, 500);
    }

    const getSelectedEmployeeIds            =   ()  =>  {
        const employeeCollections               =   document.getElementsByClassName('employee_id_checkbox');
        let employee_ids                        =   [];
        for (let i = 0; i < employeeCollections.length; i++) {
            if(employeeCollections[i].checked) {
                employee_ids.push(employeeCollections[i].value);
            }
        }
        return employee_ids;
    }

    const initAttendanceModal               =   (action)  =>  {
        try {
            let employee_ids                    =   getSelectedEmployeeIds();
            if(employee_ids.length == 0) {
                throw new Error("Please select employee");
            }
            let employees                       =   itemData.items.filter(i => employee_ids.includes(i.employee.enc_id)).map(e => e.employee);
            if (attendanceMultipleEmployeeModalRef.current) {
                attendanceMultipleEmployeeModalRef.current.initModalHandler(employees, action);
            }
        } catch (error) {
            toast.error(error.message, { position: toast.POSITION.TOP_RIGHT});
        }
    }

    const initDisplayAttendanceSummary      =   (attendance_id)  =>  {
        // setAttendanceSummary(pd => ({
        //     ...pd,
        //     display                 :   true,
        //     attendance_id           :   attendance_id,
        //     date                    :   DateService.dateTimeFormat(formData.transaction_date, 'DD-MMM-YYYY')
        // }));
    }

    const searchHandle                      =   (element,data)  =>  {
        setFormSearchedElems(element);
        setSubmittedSearchForm(data);
    }

    const employeeSearchClear               =   ()  =>  {
        setSubmittedSearchForm({});
        setFormSearchedElems([]);
    };

    const searchModalHandle                 =   () => {
        console.log();
        
        if(searchEmployeeModalRef.current) {
            searchEmployeeModalRef.current.initModalHandler()
        }
    }
    const attendanceModalHandle             =   ()  => {
        if(bulkAttendenceModalRef.current) {
            bulkAttendenceModalRef.current.modalHandle();
        }
    }


    const headerJsx                         =   ()  =>  {
        return <>
            <Helmet><title>Daily Employee Attendance</title></Helmet>
            <div className=" page_title m0 row ">
                <div className='col-sm-12'>
                    <div className='row '>
                        <div className='col-sm-4'>
                            <h3 className='mb-0'>Daily Employee Attendance</h3>
                        </div>
                        <div className='col-sm-4'>
                            <div className="justify-content-center attendence_input mt15">
                                <button className="btn btn-secondary" type="button" disabled={itemData.loading} onClick={() => dateChangeHandler('prev')}><TapIcon.FontAwesomeIcon icon={TapIcon.faArrowLeft} /></button>
                                <div className="add_calender_section mx-1">
                                    <DatePicker
                                        selected={formData.transaction_date}
                                        onChange={date => dateChangeHandler(date)}
                                        dateFormat="dd-MMM-yyyy"
                                        className="form-control form-control-sm"
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        maxDate={new Date()}
                                        disabled={itemData.loading}
                                        placeholderText={`Please Enter Date `}
                                    />
                                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "6px" }} />
                                </div>
                                <button className="btn btn-secondary ml0" type="button" disabled={itemData.loading || ( moment(formData.transaction_date).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD") )} onClick={() => dateChangeHandler('next')}><TapIcon.FontAwesomeIcon icon={TapIcon.faArrowRight} /></button>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='text-end d-flex gap-2 align-items-center justify-content-end mt15'>
                                <span className="dropdown">
                                    <button type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={itemData.loading}>Update Attendence</button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                        <li><Link className="dropdown-item" role="button" onClick={() => initAttendanceModal("checkin")}>Check-In</Link> </li>
                                        <li><Link className="dropdown-item" role="button" onClick={() => initAttendanceModal("checkout")}>Check-Out</Link> </li>
                                    </ul>
                                </span>
                                <button type="button" className="btn btn-secondary" disabled={itemData.loading} onClick={searchModalHandle}>
                                    <TapIcon.imageIcon icon={TapIcon.SearchIcon} />
                                </button>
                                <button type="button" id='actionHeaderButton' className="btn btn-secondary ml0" data-bs-toggle="dropdown" aria-expanded="false" disabled={itemData.loading}>
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="actionHeaderButton">
                                    <li><button  className="btn-link dropdown-item" role="button" onClick={attendanceModalHandle}>Upload Attendance</button></li>
                                </ul>
                                <button type="button" className="btn btn-secondary ml0" disabled={itemData.loading} onClick={refreshDataEvent} >
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className={[itemData.loading ? 'fa-spin' : '' ].join(" ")}   />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    const employeeListJsx                   =   ()  =>  {
        return <>
            <DisplaySearchCriteria
            searchedElems={formSearchedElems}
            onClearFilter={employeeSearchClear}
            />
            <table className="table table-bordered align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr className='text-center'>
                        <th style={{ width: "4%" }}><input type='checkbox' onChange={checkAllItem} id="all_check_employee" /></th>
                        <th style={{ width: "13%" }}>Employee Name</th>
                        <th style={{ width: "11%" }}>Employee Id</th>
                        <th style={{ width: "10%" }}>Site</th>
                        <th style={{ width: "10%" }}>Shift</th>
                        <th style={{ width: "10%" }}>Attendance Status</th>
                        <th style={{ width: "11%" }}>Check In </th>
                        <th style={{ width: "11%" }}>Check Out</th>
                        {/* <th style={{ width: "10%" }}>Analysis</th> */}
                        <th style={{ width: "6%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {itemData.loading 
                    ?   <tr><td className='text-center' colSpan={9}><Loader /></td></tr>
                    :   (itemData.items && itemData.items.length > 0 
                        ?   itemData.items.map((item,key) => {
                            let site            =   itemData.sites.find( s => s.id ==  item.site_id);
                            let rowspan         =   item.attendances ? (item.attendances.length || 1) : 1; 
                            
                            let Object_rows     =   [];
                            for (let i = 0; i < rowspan; i++) {
                                let attendance  =   item.attendances && item.attendances[i] || null;
                                let tr          =   (<tr key={key+'-'+i}>
                                    {i==0 && <>
                                        <td rowspan={rowspan} className='text-center va_middle'><input type='checkbox' className="employee_id_checkbox" value={item.employee.enc_id} /></td>
                                        <td rowspan={rowspan}>{item.employee.name}</td>
                                        <td rowspan={rowspan}>{item.employee.employee_code ? item.employee.employee_code : ''}</td>
                                        <td rowspan={rowspan}>{site ? site.site_name : ''}</td>
                                        <td rowspan={rowspan}>{item.shift ? item.shift.name : '---'}</td>
                                    </>}
                                {attendance ? (<>
                                        <td>
                                            {attendance.id ? <Link className="link-primary" onClick={() => initDisplayAttendanceSummary(attendance.id)}>
                                                {attendance.status ? <Status color={attendance.status.color_code}>{attendance.status.name}</Status> : ''}
                                            </Link> : (attendance.status && <Status color={attendance.status.color_code}>{attendance.status.name}</Status>) }
                                        </td>
                                        <td>{attendance.check_in_date} {attendance.check_in_minutes}</td>
                                        <td>{attendance.check_out_date} {attendance.check_out_minutes}</td>
                                </>) : <td colSpan={3}></td>}

                                {i==0 && <> <td rowspan={rowspan}>
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><button onClick={() => { linkEmployeeToShift(item.employee.enc_id); }} className="btn-link dropdown-item" role="button">{item.shift ? 'Change With' : 'Link With' } shift</button></li>
                                    </ul>
                                </td></>}
                                </tr>);
                                Object_rows.push(tr);
                            }
                            return <>{Object_rows}</>
                        })
                        :   <tr><td className='text-center' colSpan={9}>No Record</td></tr>
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination meta={itemData.meta} onPageChange={(e) => loadListData(e.selected + 1)} />
            {/* {JSON.stringify(itemData)} */}
        </>
    }

    return  <ApplicationLayout>
        {attendanceSummary.display 
            ? <EmpAttendanceDetailJsx attendanceData={attendanceSummary} onClose={() => setAttendanceSummary(pd => ({...pd, ...initAttendanceSummary})) } /> 
            : <>
                {headerJsx()}
                <div className="container-fluid pl5">
                    <div className='page_containt row'>
                        <div className='pageTbl col-12'>{employeeListJsx()}</div>
                    </div>
                </div>
            </>
        }
        <EmployeeShiftLinkingModal ref={empShiftLinkingModalRef} afterSucess={refreshDataEvent} />
        <EmployeeSearchModal ref={searchEmployeeModalRef} searchHandle={(element,searchData) => searchHandle(element, searchData)} listingLoading={itemData.loading} formSearchedElems={formSearchedElems}/>
        <AttendanceMultipleEmployeeModal ref={attendanceMultipleEmployeeModalRef} afterSucess={refreshDataEvent} transaction_date={formData.transaction_date} />
        <AttendenceUploadModal ref={bulkAttendenceModalRef} loadListingTblData={loadListData} submittedSearchForm={submittedSearchForm}/>
    </ApplicationLayout>

});

export default DailyAttendance;