import React, { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { Chart } from "react-google-charts";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import moment from "moment";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";
import Loader from "../../../components/ui/Loader/Loader";
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

const SiteProductivityWidget = (props) => {
    const [prodState, setProdState] = useState({
        iam_user_sites: [],
        iam_asset_types: [],
        productivityForm: { period: 'current_month', search_site_id: '' },
        loading: false,
        data: [],
        remark_time: '',
        all_periods: [],
    });

    const [reload, setReload] = useState(false);

    const itemListUrl = IAM_API_BASE_URL_2 + "/site_productivity_report";
    const updateMyDashboardRef = useRef();
    const access_token = useSelector(state => state.auth.access_token);
    const iam_user_sites = useSelector(state => state.app.user_sites || []);
    const all_periods = useSelector(state => state.app && state.app.all_periods ?
        [...state.app.all_periods.map((s) => ({ value: s.key, label: `${s.display}` }))] : []
    );
    const widget_keys = useSelector(state => state.app.user_info?.widget_keys || []);

    const filteredPeriods = all_periods.filter(p =>
        p.value === 'current_month' ||
        p.value === 'current_week' ||
        p.value === 'previous_week' ||
        p.value === 'previous_month'
    );

     useEffect(() => {
        initializeFormFilter();
        const {productivityForm} = prodState;
        const productivityData = JSON.parse(localStorage.getItem('SiteProductivityWidget'));
        if(productivityData && (reload || productivityForm.period !== 'current_month' || productivityForm.search_site_id) ) {
            const lastRefreshTime = moment(productivityData.time).valueOf();
            const nextDay = moment(lastRefreshTime).add(1, 'days').valueOf();
            const latestDataTime = nextDay - lastRefreshTime;

            if (latestDataTime > 86400000 || reload || productivityForm.period !== 'current_month' || productivityForm.search_site_id) {
                localStorage.removeItem('SiteProductivityWidget');
                getSiteProductivityListing();
            } else {
                setProdState((prevState) => ({
                    ...prevState,
                    data: productivityData.data,
                    remark_time: productivityData.time,
                }));
            }
        }
        if(!productivityData) {
            getSiteProductivityListing();
        }

    }, [prodState.productivityForm, reload === true]);

    const initializeFormFilter = () => {
        if (filteredPeriods && filteredPeriods.length > 0) {
            setProdState((prevState) => ({
                ...prevState,
                all_periods: filteredPeriods,
                iam_user_sites: iam_user_sites.map((s) => ({ value: s.id, label: `${s.site_name} (${s.site_code})` })),
            }));
        }
    };

    const getSiteProductivityListing = () => {
        setProdState((prevState) => ({ ...prevState, loading: true }));
        const params = {
            action: "widget",
            date_range: prodState.productivityForm.period,
            search_site_id: prodState.productivityForm.search_site_id ? prodState.productivityForm.search_site_id : null,
            reload: reload ? 'reload' : ''
        };

        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            setProdState((prevState) => ({
                ...prevState,
                data: [...response.data.data],
                remark_time: response.data.time,
            }));
            if (prodState.productivityForm.period === "current_month" && prodState.productivityForm.search_site_id === '' && !reload) {
                localStorage.setItem('SiteProductivityWidget', JSON.stringify(response.data));
            }
        }).then(() => {
            setProdState((prevState) => ({ ...prevState, loading: false }))
            setReload(false); 
        });
    };

    const pinMyDashboardHandler = (widgetData = null) => {
        updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData, { key: "iam-asset-utilization", name: "Asset Utilisation and Availability" });
    };

    const tapSelectChange = (selectedOption, formKey, fieldKey) => {
        setProdState((prevState) => ({
            ...prevState,
            [formKey]: {
                ...prevState[formKey],
                [fieldKey]: selectedOption ? selectedOption.value : []
            }
        }));
    };

    let widgetData = widget_keys.length > 0 ?
        widget_keys.find(st => st.key === "iam-asset-utilization") : null;
    const totalValue = prodState.data.reduce((acc, d) => acc + (parseInt(d.value) > 0 ? parseInt(d.value) : 0), 0);
    return (

        <div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Site Productivity wise Value </h6>
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            isClearable={false}
                            placeholder="Select Period"
                            containerHeight="33px"
                            fontSize="93%"
                            options={prodState.all_periods}
                            value={prodState.all_periods.find(r => r.value === prodState.productivityForm.period)}
                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivityForm', 'period')}
                        />
                    </div>
                    <div className="col-3">
                        <TapSelect
                            options={prodState.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={prodState.iam_user_sites.find(r => prodState.productivityForm.search_site_id === r.value)}
                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivityForm', 'search_site_id')}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setReload(true)}>Refresh</li>
                        </ul>
                    </div>
                </div>

                <div className="col-12 mt-2 p0">
                    {prodState.loading ? <Loader /> :


                        (prodState.data && totalValue > 0 ? (
                            <Chart
                                chartType="PieChart"
                                loader={<Loader />}
                                data={[['', '']].concat(prodState.data.map((d) => [d.activity_name, parseInt(d.value)]))}
                                options={{
                                    legend: { position: 'labeled', labeledValueText: 'both' },
                                    pieSliceText: 'none',
                                    sliceVisibilityThreshold: 0,
                                    slices: prodState.data.map((d) => ({ color: d.color_code })),
                                    chartArea: { width: '90%', height: '90%' },
                                }}
                                rootProps={{ 'data-testid': '1' }}
                            />
                        ) : null)}
                </div>
                <div className="col-12 mt-4">
                    <div style={{ overflowY: "auto", maxHeight: "300px" }}>
                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "20%" }} className="text-center">Activity Name</th>
                                    <th className="text-center" style={{ width: "20%" }}>Code</th>
                                    <th className="text-center" style={{ width: "10%" }}>UOM</th>
                                    <th className="text-center" style={{ width: "10%" }}>Total</th>
                                    <th className="text-center" style={{ width: "15%" }}>Unit Rate</th>
                                    <th className="text-center" style={{ width: "25%" }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prodState.loading ? (
                                    <tr><td colSpan="6"><Loader /></td></tr>
                                ) : (prodState.data && prodState.data.length > 0 ? (
                                    prodState.data.map((w, index) => (
                                        <tr key={index} className="text-center">
                                            <td className="text-start">{w.activity_name}</td>
                                            <td>{w.activity_code || "-"}</td>
                                            <td>{w.uom || "0"}</td>
                                            <td>{parseFloat(w.total || 0).toFixed(3).replace(/\.?0+$/, '')}</td>
                                            <td>{parseFloat(w.unit_rate || 0).toFixed(3).replace(/\.?0+$/, '')}</td>
                                            <td>{parseFloat(w.value || 0).toFixed(3).replace(/\.?0+$/, '')}</td>
                                        </tr>

                                    ))
                                ) : (
                                    <tr><td colSpan="6" className="text-center">No Records</td></tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-12 text-muted fs11">
                    Report as of {prodState.remark_time}
                </div>
            </div>
            <UpdateMyDashboardForm ref={updateMyDashboardRef} />
        </div>
    );
};

export default SiteProductivityWidget;
