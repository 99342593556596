
import React from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import TapSelect from "../../../components/ui/TapSelect";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import HttpAPICall from "../../../services/HttpAPICall";
import {
  INVENTORY_API_BASE_URL_2,
  ISC_API_BASE_URL_2,
  ORG_API_BASE_URL_2,
} from "../../../services/TapApiUrls";
import InputItemSearch from "../../includes/ui/InputItemSearch";
import AddItemModal from "../../items/itemsAdd/AddItemModal";
import AddVendorModal from "../../includes/ui/AddVendorModal";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import AssetMiniDetail from "../../includes/AssetMiniDetail";
import ItemDetailsModal from "../../items/itemDetails/ItemDetailsModal";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import ItemEditModal from "../../items/itemsAdd/ItemEditModal";
import { Helmet } from "react-helmet";
import Vendor from "../includes/Vendor";
import InputEmployeeSearch from "../../includes/ui/InputEmployeeSearch";
import ItemSearchModal from "../../items/itemSearchModal";
import NumberFormatter from "../../../services/NumberFormater";
import AdditionalAttributeForm from "../../includes/ui/AdditionalAttributeForm";
import TapInputNumber from "../../../components/ui/TapInputNumber";
import InputWorkstationSearch from "../../includes/ui/InputWorkstationSearch";
import InputSubAssemblySearch from "../../includes/ui/InputSubAssemblySearch";

class PurchaseAdd extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.initPurchaseForm = {
      transaction_date: new Date(),
      warehouse_id: null,
      vendor_id: null,
      status: "",
      vendor_address_id: null,
      purchase_bill_number: "",
      purchase_bill_date: "",
      e_way_bill_date: "",
      e_way_bill_number: "",
      purchase_order_number: "",
      purchase_order_date: "",
      wallet_id: "",
      notes: "",
      adjustment_amount: null,
      include_consumption: "N",
      tcs_rate: "",
      tcs_type: "Percentage",
    };

    this.initConsumptionForm = {
      consumption_type: "asset",
      consumption_price_as: "purchase_price",
      voucher_number: "",
      voucher_date: "",
      asset_id: "",
      workstation_id: "",
    };

    this.state = {
      saveFormSubmitting: false,
      purchaseItemRows: [{ item: null }],
      addPurchaseForm: { ...this.initPurchaseForm },
      consumptionForm: { ...this.initConsumptionForm },
      isc_configuration: {},
      all_warehouses: [],
      warehouseDetail: null,
      searchedItem: null,
      searchedVendor: null,
      vendorDetails: null,
      purchaseFormDataLoaded: false,
      details_loading: false,
      allWalletList: [],
      allDiscountType: [],
      allGstSlabs: [],
      warehouse_state_id: null,
      vendor_state_id: null,
      status: 31,
      validationError: false,
      item_loading: false,
      itemSearchedDetails: null,
      gstTypeName: "GST",
      editPurchase: false,
      purchaseData: null,
      searchedVendorAdressList: [],
      selectedVendorAdress: null,
      vendorAddressList: [],
      vendor_details_loading: false,
      selectedAsset: null,
      consumptionFormDataLoaded: false,
      warehouseSites: [],
      consumptionFormLoading: false,
      searchedWorkstation: null,
      itemRowNumber: 0,
      purchaseItemRowKey: 0,
      allPendingPOList: [],
      pendingPOListLoaded: false,
      pending_item_loading: false,
      savePickItemSubmitting: false,
      allAllocatedItemIds: [],
      include_req_pending_items: "N",
      update_item_purchase_price: "Y",
      update_item_consumption_price: "Y",
      allPOIdsList: [],
      searchedEmployee: null,
      allStatusList: [],
      allCurrencyList: [],
      multiple_currency_available: "N",
      editCurrency: false,
      purchase_only_with_po: "N",
      purchase_po_same_unit_rate: "N",
      disableCurrency: false,
      gst_not_applicable: "N",
      isc_enable_tcs: "Y",
      tcs_applicable: "Y",
      allTCSType: [],
      additionalAttributes: [],
      allConsumptionFor: [],
      totalAmount: 0,
    };
    this.addItemModalRef = React.createRef();
    this.itemDetailsRef = React.createRef();
    this.editItemModalRef = React.createRef();
    this.addVendorModalRef = React.createRef();
    this.itemSearchRef = React.createRef();

    this.currency_convert_url = ORG_API_BASE_URL_2;
    this.pendingPOReqUrl =
      INVENTORY_API_BASE_URL_2 + "/pending_items_purchase_order_requisition";
    this.purchaseUrl = INVENTORY_API_BASE_URL_2 + "/purchase";
    this.warehouseUrl = ISC_API_BASE_URL_2 + "/warehouse";
    this.vendorUrl = INVENTORY_API_BASE_URL_2 + "/vendor";
    this.itemUrl = INVENTORY_API_BASE_URL_2 + "/item";
    this.consumptionUrl = INVENTORY_API_BASE_URL_2 + "/consumption";
  }

  componentDidMount() {
    setTimeout(() => {
      this.initilaizeFormData(this.props);
    }, 1000);

    this.viewItemModal = new Modal(document.getElementById("viewItemModal"), {
      keyboard: false,
      backdrop: false,
    });
    this.pickPOItemModal = new Modal(
      document.getElementById("pickPOItemModal"),
      { keyboard: false, backdrop: false }
    );
    this.reqDetailModal = new Modal(document.getElementById("reqDetailModal"), {
      keyboard: false,
      backdrop: false,
    });
    this.addBatchNumberModal = new Modal(
      document.getElementById("addBatchNumberModal"),
      { keyboard: false, backdrop: false }
    );
  }

  //*******************INITIALIZE FORM DATA************************************** */
  initilaizeFormData = (pr) => {
    if (!this.state.purchaseFormDataLoaded) {
      HttpAPICall.withAthorization(
        "GET",
        this.purchaseUrl + "/add_form_data",
        this.props.access_token,
        null,
        null,
        (response) => {
          let formData = response.data;
          let defaultCurrency =
            pr && pr.default_currency ? pr.default_currency : "INR";
          let purchaseCurrency =
            pr && pr.default_currency ? pr.default_currency : "INR";
          let currencyList =
            pr && pr.currency_list && pr.currency_list.length > 0
              ? pr.currency_list.map((s) => {
                return { value: s.code, label: s.code };
              })
              : [];

          if (
            pr.location &&
            pr.location.state &&
            pr.location.state.purchaseData
          ) {
            let purchaseData = pr.location.state.purchaseData;
            purchaseCurrency = purchaseData.currency
              ? purchaseData.currency
              : "";
          }

          if (
            pr.location &&
            pr.location.state &&
            pr.location.state.clonePODetail
          ) {
            let purchaseData = pr.location.state.clonePODetail;
            purchaseCurrency = purchaseData.currency
              ? purchaseData.currency
              : "";
          }

          if (
            pr.location &&
            pr.location.state &&
            pr.location.state.addPurchaseFromPO
          ) {
            let purchaseData = pr.location.state.addPurchaseFromPO;
            purchaseCurrency = purchaseData.currency
              ? purchaseData.currency
              : "";
          }

          this.setState(
            {
              purchaseFormDataLoaded: formData ? true : false,
              allConsumptionFor: [
                { value: "asset", label: "Asset" },
                { value: "workstation", label: "Workstation" },
                { value: "employee", label: "Employee" },
                { value: "sub_assembly", label: "Sub Assembly" },
              ],
              allWalletList:
                formData && formData.wallets
                  ? formData.wallets.map((s) => {
                    return { value: s.id, label: s.wallet_name };
                  })
                  : [],
              allDiscountType:
                formData && formData.discount_type
                  ? formData.discount_type.map((s) => {
                    return {
                      value: s,
                      label: s === "Fixed" ? purchaseCurrency : "%",
                    };
                  })
                  : [],
              allGstSlabs:
                formData && formData.gst_slabs
                  ? formData.gst_slabs.map((s) => {
                    return { value: s, label: s + "%" };
                  })
                  : [],
              allStatusList:
                formData && formData.status && formData.status.length > 0
                  ? formData.status.map((s) => {
                    return { value: s.id, label: s.name };
                  })
                  : [],
              all_warehouses: this.props.all_warehouses.map((m) => {
                return { value: m.id, label: `${m.name} (${m.code})` };
              }),
              isc_configuration: this.props.isc_configuration,
              allCurrencyList: currencyList,
              defaultCurrency: defaultCurrency,
              multiple_currency_available:
                formData &&
                  formData.setting &&
                  formData.setting.multiple_currency
                  ? formData.setting.multiple_currency
                  : "N",
              addPurchaseForm: {
                ...this.state.addPurchaseForm,
                currency: defaultCurrency,
              },
              purchase_only_with_po:
                formData &&
                  formData.setting &&
                  formData.setting.purchase_only_with_po
                  ? formData.setting.purchase_only_with_po
                  : "N",
              purchase_po_same_unit_rate:
                formData &&
                  formData.setting &&
                  formData.setting.purchase_po_same_unit_rate
                  ? formData.setting.purchase_po_same_unit_rate
                  : "N",
              isc_enable_tcs:
                formData && formData.setting && formData.setting.isc_enable_tcs
                  ? formData.setting.isc_enable_tcs
                  : "Y",
              allTCSType:
                formData && formData.discount_type
                  ? formData.discount_type.map((s) => {
                    return {
                      value: s,
                      label: s === "Fixed" ? purchaseCurrency : "%",
                    };
                  })
                  : [],
              additionalAttributes:
                formData &&
                  formData.additional_attributes &&
                  formData.additional_attributes.length > 0
                  ? formData.additional_attributes
                  : [],
            },
            () => {
              // For Edit Purchase
              if (
                pr.location &&
                pr.location.state &&
                pr.location.state.purchaseData
              ) {
                let purchaseData = pr.location.state.purchaseData;
                let updateFormState = [];
                let employeeProfile = purchaseData.employee;
                let addtionalAttribute =
                  purchaseData["additional_attributes_data"];
                let addtionalAttributeData =
                  addtionalAttribute && addtionalAttribute.length > 0
                    ? addtionalAttribute.map((at) => {
                      return { key: at.key, value: at.value, name: at.name };
                    })
                    : [];

                // addition_attributes
                if (addtionalAttributeData.length > 0) {
                  addtionalAttributeData.map((k) => {
                    let name = "additional_attributes_" + k.key;
                    updateFormState[name] = k.value;
                  });
                }
                let purchaseItemRows = pr.location.state.purchaseData.items.map(
                  (pu_item, k) => {
                    return {
                      item: {
                        ...pu_item.item,
                        isAddRemark: pu_item.remark ? true : false,
                      },
                      req_allocation_loading: true,
                      req_allocation: [],
                      id: pu_item.item.id,
                      qty: pu_item.qty,
                      rate: pu_item.rate_vc,
                      remark: pu_item.remark,
                      gst: pu_item.gst,
                      discount_type: pu_item.discount_type,
                      discount_rate: pu_item.discount_rate_vc,
                      po_item_id: pu_item.po_item_id,
                      purchase_number: pu_item.purchase_number,
                      req_item_id: pu_item.req_item_id,
                      requisition_transaction_id:
                        pu_item.requisition_transaction_id,
                      selected_allocation: null,
                      batch_list:
                        pu_item.batch_list && pu_item.batch_list.length > 0
                          ? pu_item.batch_list.map((be) => {
                            return {
                              ...be,
                              batch_qty: be.qty,
                              stock_id: be.stock_id,
                            };
                          })
                          : [],
                      // expiry_date                 :           pu_item.expiry_date,
                      // batch_number                :           pu_item.batch_number,
                      stock_id: pu_item.stock_id ? pu_item.stock_id : null,
                      disable_rate:
                        pu_item.po_item_id &&
                          this.state.purchase_po_same_unit_rate == "Y"
                          ? "Y"
                          : "N",
                    };
                  }
                );

                let allAllocatedItemIds = purchaseItemRows.map((p) => {
                  // Extracting the req_allocation array from each poItemRow

                  const reqAllocation = {
                    po_item_id: p?.po_item_id,
                    req_item_id: p?.req_item_id,
                  };

                  // // Returning an array of objects with req_item_id values
                  return reqAllocation;
                });

                this.setState(
                  {
                    purchaseItemRows: purchaseItemRows,
                    allAllocatedItemIds: allAllocatedItemIds,
                  },
                  () => {
                    //get all Previous Allocation :-
                    this.getAllocationQuantity(
                      purchaseData.warehouse_id,
                      purchaseData.vendor.id,
                      purchaseItemRows
                    );
                  }
                );

                this.setState(
                  {
                    addPurchaseForm: {
                      ...this.state.addPurchaseForm,
                      ...updateFormState,
                      transaction_date:
                        pr.location &&
                          pr.location.state &&
                          pr.location.state.clone
                          ? moment(new Date(), "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                          )
                          : purchaseData.transaction_date,
                      transaction_id:
                        pr.location &&
                          pr.location.state &&
                          pr.location.state.clone
                          ? null
                          : purchaseData.transaction_id,
                      warehouse_id: purchaseData.warehouse_id,
                      vendor_id: purchaseData.vendor.id,
                      purchase_bill_number: purchaseData.purchase_bill_number,
                      purchase_bill_date: purchaseData.purchase_bill_date
                        ? moment(
                          purchaseData.purchase_bill_date,
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")
                        : "",
                      e_way_bill_date: purchaseData.e_way_bill_date
                        ? moment(
                          purchaseData.e_way_bill_date,
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")
                        : "",
                      e_way_bill_number: purchaseData.e_way_bill_number,
                      purchase_order_number: purchaseData.purchase_order_number,
                      purchase_order_date: purchaseData.purchase_order_date
                        ? moment(
                          purchaseData.e_way_bill_date,
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")
                        : "",
                      wallet_id: purchaseData.wallet
                        ? purchaseData.wallet.id
                        : null,
                      notes: purchaseData.notes,
                      status: purchaseData.status_data
                        ? purchaseData.status_data.id
                        : "",
                      adjustment_amount: purchaseData.adjustment_amount_vc,
                      conversion_rate: purchaseData.conversion_rate
                        ? parseFloat(purchaseData.conversion_rate).toFixed(2)
                        : "",
                      currency: purchaseData.currency
                        ? purchaseData.currency
                        : "INR",
                      include_consumption: "N",
                      tcs_rate: purchaseData.tcs_rate
                        ? purchaseData.tcs_rate
                        : "",
                      tcs_type: purchaseData.tcs_type
                        ? purchaseData.tcs_type
                        : "Percentage",
                      tcs_applicable:
                        purchaseData.tcs_rate && purchaseData.tcs_rate > 0
                          ? "Y"
                          : "N",
                    },
                    searchedVendor: {
                      value: purchaseData.vendor.id,
                      display_label: purchaseData.vendor.name,
                    },
                    warehouse_state_id: purchaseData.warehouse_state,
                    editPurchase:
                      pr.location &&
                        pr.location.state &&
                        pr.location.state.clone
                        ? false
                        : true,
                    status: purchaseData.status,
                    purchaseData: purchaseData,
                    searchedEmployee: employeeProfile
                      ? {
                        value: employeeProfile.enc_id,
                        label: `${employeeProfile.display_full_name}`,
                        employee: employeeProfile,
                      }
                      : null,

                    searchSubassembly: purchaseData.subAssembly && {
                      label: purchaseData.subAssembly.name,
                      value: purchaseData.subAssembly.enc_id,
                      display_label: purchaseData.subAssembly.name,
                      subAssembly: purchaseData.subAssembly,
                    },
                    vendorDetails: {
                      value: purchaseData.vendor.id,
                      display_label: purchaseData.vendor.name,
                    },
                    selectedVendorAdress: purchaseData.vendor_address
                      ? {
                        ...purchaseData.vendor_address,
                        gstin: purchaseData.vendor_gstin,
                        id: purchaseData.vendor_address_id,
                      }
                      : null,
                    gst_not_applicable: purchaseData.gst_not_applicable
                      ? purchaseData.gst_not_applicable
                      : "N",
                    isc_enable_tcs:
                      (purchaseData.tcs_rate && purchaseData.tcs_rate > 0) ||
                        this.state.isc_enable_tcs == "Y"
                        ? "Y"
                        : "N",
                  },
                  () => {
                    this.getWarehouseDetails(purchaseData.warehouse_id);
                    this.getVendorDetails(purchaseData.vendor.id, true);
                    this.handleVendorChange(this.state.searchedVendor, true);
                    let workstation = purchaseData.workstation;
                    if (workstation) {
                      this.setState({
                        searchedWorkstation: {
                          value: workstation.id,
                          label: workstation.workstation_name,
                        },
                      });
                    }
                  }
                );
              }

              // For Cloning Purchase
              if (
                pr.location &&
                pr.location.state &&
                pr.location.state.purchaseCloneData
              ) {
                let purchaseData = pr.location.state.purchaseCloneData;
                let employeeProfile = purchaseData.employee;

                let purchaseItemRows =
                  pr.location.state.purchaseCloneData.items.map(
                    (pu_item, k) => {
                      return {
                        item: {
                          ...pu_item.item,
                          isAddRemark: pu_item.remark ? true : false,
                        },
                        req_allocation_loading: true,
                        req_allocation: [],
                        id: pu_item.item.id,
                        qty: pu_item.qty,
                        rate: pu_item.rate_vc,
                        remark: pu_item.remark,
                        gst: pu_item.gst,
                        discount_type: pu_item.discount_type,
                        discount_rate: pu_item.discount_rate_vc,
                        po_item_id: pu_item.po_item_id,
                        req_item_id: pu_item.req_item_id,
                        //  expiry_date                :           pu_item.expiry_date,
                        // batch_number                :           pu_item.batch_number,
                        selected_allocation: null,
                        batch_list:
                          pu_item.batch_list && pu_item.batch_list.length > 0
                            ? pu_item.batch_list.map((be) => {
                              return { ...be, batch_qty: be.qty };
                            })
                            : [],
                        disable_rate:
                          pu_item.po_item_id &&
                            this.state.purchase_po_same_unit_rate == "Y"
                            ? "Y"
                            : "N",
                      };
                    }
                  );

                this.setState(
                  {
                    purchaseItemRows: purchaseItemRows,
                  },
                  () => {
                    this.getPendingQuantityForClone(
                      purchaseData.warehouse_id,
                      purchaseData.vendor.id,
                      purchaseItemRows
                    );
                    this.addNewItemRow();
                    //get all Previous Allocation :-
                    // this.getAllocationQuantity(purchaseData.warehouse_id, purchaseData.vendor.id,purchaseItemRows);
                  }
                );
                this.setState(
                  {
                    addPurchaseForm: {
                      ...this.state.addPurchaseForm,
                      transaction_date: moment(new Date(), "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                      ),
                      transaction_id: null,
                      warehouse_id: purchaseData.warehouse_id,
                      vendor_id: purchaseData.vendor.id,
                      purchase_bill_number: purchaseData.purchase_bill_number,
                      purchase_bill_date: purchaseData.purchase_bill_date
                        ? moment(
                          purchaseData.purchase_bill_date,
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")
                        : "",
                      e_way_bill_date: purchaseData.e_way_bill_date
                        ? moment(
                          purchaseData.e_way_bill_date,
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")
                        : "",
                      e_way_bill_number: purchaseData.e_way_bill_number,
                      purchase_order_number: purchaseData.purchase_order_number,
                      purchase_order_date: purchaseData.purchase_order_date
                        ? moment(
                          purchaseData.e_way_bill_date,
                          "YYYY-MM-DD"
                        ).format("YYYY-MM-DD")
                        : "",
                      wallet_id: purchaseData.wallet
                        ? purchaseData.wallet.id
                        : null,
                      notes: purchaseData.notes,
                      adjustment_amount: purchaseData.adjustment_amount_vc,
                      conversion_rate: purchaseData.conversion_rate
                        ? parseFloat(purchaseData.conversion_rate).toFixed(2)
                        : "",
                      currency: purchaseData.currency
                        ? purchaseData.currency
                        : "INR",
                      tcs_rate: purchaseData.tcs_rate
                        ? purchaseData.tcs_rate
                        : "",
                      tcs_type: purchaseData.tcs_type
                        ? purchaseData.tcs_type
                        : "Percentage",
                      tcs_applicable:
                        purchaseData.tcs_rate && purchaseData.tcs_rate > 0
                          ? "Y"
                          : "N",
                    },
                    searchedVendor: {
                      value: purchaseData.vendor.id,
                      display_label: purchaseData.vendor.name,
                    },
                    warehouse_state_id: purchaseData.warehouse_state,
                    editPurchase: false,
                    status: purchaseData.status,
                    purchaseData: null,
                    searchedEmployee: employeeProfile
                      ? {
                        value: employeeProfile.enc_id,
                        label: `${employeeProfile.display_full_name}`,
                        employee: employeeProfile,
                      }
                      : null,

                    searchSubassembly: purchaseData.subAssembly && {
                      label: purchaseData.subAssembly.name,
                      value: purchaseData.subAssembly.enc_id,
                      display_label: purchaseData.subAssembly.name,
                      subAssembly: purchaseData.subAssembly,
                    },
                    vendorDetails: {
                      value: purchaseData.vendor.id,
                      display_label: purchaseData.vendor.name,
                    },
                    selectedVendorAdress: purchaseData.vendor_address
                      ? {
                        ...purchaseData.vendor_address,
                        gstin: purchaseData.vendor_gstin,
                        id: purchaseData.vendor_address_id,
                      }
                      : null,
                    gst_not_applicable: purchaseData.gst_not_applicable
                      ? purchaseData.gst_not_applicable
                      : "N",
                    isc_enable_tcs:
                      (purchaseData.tcs_rate && purchaseData.tcs_rate > 0) ||
                        this.state.isc_enable_tcs == "Y"
                        ? "Y"
                        : "N",
                  },
                  () => {
                    this.getWarehouseDetails(purchaseData.warehouse_id);
                    this.getVendorDetails(purchaseData.vendor.id, true);
                    this.handleVendorChange(this.state.searchedVendor, true);
                    let workstation = purchaseData.workstation;
                    if (workstation) {
                      this.setState({
                        searchedWorkstation: {
                          value: workstation.id,
                          label: workstation.workstation_name,
                        },
                      });
                    }
                    let assetProfile = purchaseData.assetProfile;
                    if (assetProfile) {
                      this.setState({
                        selectedAsset: {
                          ...assetProfile,
                          label: `${assetProfile.name} (${assetProfile.asset_code})`,
                        },
                      });
                    }
                  }
                );
              }

              // For Add Purchase from  PO
              if (
                pr.location &&
                pr.location.state &&
                pr.location.state.addPurchaseFromPO
              ) {
                let purchaseData = pr.location.state.addPurchaseFromPO;
                let employeeProfile = purchaseData.employee;

                this.setState(
                  {
                    allPOIdsList: purchaseData.po_item_ids,
                    addPurchaseForm: {
                      ...this.state.addPurchaseForm,
                      warehouse_id: purchaseData.warehouse_id,
                      vendor_id: purchaseData.vendor_id,
                      currency: purchaseData.currency
                        ? purchaseData.currency
                        : "INR",
                      conversion_rate: purchaseData.conversion_rate
                        ? parseFloat(purchaseData.conversion_rate).toFixed(2)
                        : 1,
                    },
                    searchedVendor: {
                      value: purchaseData.vendor_id,
                      display_label: purchaseData.vendor_name,
                    },
                    warehouse_state_id: purchaseData.warehouse_state,
                    editPurchase: false,
                    status: purchaseData.status,
                    purchaseData: null,
                    disableCurrency: true,
                    searchedEmployee: employeeProfile
                      ? {
                        value: employeeProfile.enc_id,
                        label: `${employeeProfile.display_full_name}`,
                        employee: employeeProfile,
                      }
                      : null,

                    searchSubassembly: purchaseData.subAssembly && {
                      label: purchaseData.subAssembly.name,
                      value: purchaseData.subAssembly.enc_id,
                      display_label: purchaseData.subAssembly.name,
                      subAssembly: purchaseData.subAssembly,
                    },
                  },
                  () => {
                    this.getWarehouseDetails(purchaseData.warehouse_id);
                    this.getVendorDetails(purchaseData.vendor_id);
                    this.handleVendorChange(this.state.searchedVendor);
                    this.getAllSelectedPO(
                      purchaseData.items,
                      purchaseData.po_item_ids,
                      "Y"
                    );
                    let workstation = purchaseData.workstation;
                    if (workstation) {
                      this.setState({
                        searchedWorkstation: {
                          value: workstation.id,
                          label: workstation.workstation_name,
                        },
                      });
                    }
                    let assetProfile = purchaseData.assetProfile;
                    if (assetProfile) {
                      this.setState({
                        selectedAsset: {
                          ...assetProfile,
                          label: `${assetProfile.name} (${assetProfile.asset_code})`,
                        },
                      });
                    }
                    // this.convertCurrency({value:purchaseData.currency ? purchaseData.currency : "INR",label:purchaseData.currency ? purchaseData.currency: "INR"})
                  }
                );
              }
            }
          );
        }
      );
    }
  };

  //********************GET WAREHOUSE DETAILS API ******************************* */
  getWarehouseDetails = (selectedOption) => {
    if (selectedOption) {
      let id = selectedOption.value ? selectedOption.value : selectedOption;
      this.setState({
        warehouseDetail: null,
        warehouseSites: [],
        warehouse_state_id: null,
      });
      HttpAPICall.withAthorization(
        "GET",
        this.warehouseUrl + "/" + id,
        this.props.access_token,
        {},
        {},
        (resp) => {
          let respData = resp.data;
          if (this.state.vendor_state_id === respData.data.state_id) {
            this.setState({ gstTypeName: "GST" });
          } else {
            this.setState({ gstTypeName: "IGST" });
          }
          this.setState({
            warehouseDetail: respData.data,
            warehouse_state_id:
              respData.data && respData.data.state_id
                ? respData.data.state_id
                : null,
            warehouseSites: respData.data.sites,
            pendingPOListLoaded: false,
          });
        }
      );
    } else {
      this.setState({ warehouseDetail: null });
      this.setState({
        gstTypeName:
          this.state.selectedVendorAdress &&
            this.state.warehouseDetail &&
            this.state.selectedVendorAdress.state_id ==
            this.state.warehouseDetail.state_id
            ? "GST"
            : "IGST",
      });
    }
  };

  //********************GET VENDOR DETAILS ******************************* */
  getVendorDetails = (id, edit = false) => {
    if (id) {
      this.setState({ vendor_details_loading: true });
      HttpAPICall.withAthorization(
        "GET",
        this.vendorUrl + "/" + id,
        this.props.access_token,
        null,
        null,
        (response) => {
          let selectedVendorAdress =
            response.data.data && response.data.data.addressList
              ? response.data.data.addressList[0]
              : [];
          this.setState({
            vendorDetails: response.data.data,
            vendorAddressList: response.data.data.addressList,
            selectedVendorAdress:
              edit == false
                ? selectedVendorAdress
                : this.state.selectedVendorAdress,
            pendingPOListLoaded: false,
          });
        }
      ).then(() => {
        this.setState({ vendor_details_loading: false });
        this.setState({
          gstTypeName:
            this.state.selectedVendorAdress &&
              this.state.warehouseDetail &&
              this.state.selectedVendorAdress.state_id ==
              this.state.warehouseDetail.state_id
              ? "GST"
              : "IGST",
        });
      });
    }
  };

  handleVendorChange = (selectedOption, edit = false) => {
    this.setState(
      {
        addPurchaseForm: {
          ...this.state.addPurchaseForm,
          vendor_id: selectedOption ? selectedOption.value : null,
        },
        vendorDetails: selectedOption ? selectedOption : null,
        searchedVendorAdressList:
          selectedOption && selectedOption.vendor
            ? selectedOption.vendor.addressList
            : null,
        selectedVendorAdress:
          selectedOption && selectedOption.vendor && edit == false
            ? selectedOption.vendor.addressList[0]
            : this.state.selectedVendorAdress,
        searchedVendor: selectedOption ? selectedOption : null,
      },
      () => {
        if (edit == false) {
          this.setState({
            gst_not_applicable:
              this.state.selectedVendorAdress &&
                this.state.selectedVendorAdress.country_id !== 99
                ? "Y"
                : "N",
          });
        }
      }
    );
  };

  changeAddressHandler = (id) => {
    if (id) {
      let selectedVendorAdress =
        this.state.vendorDetails && this.state.vendorDetails.addressList
          ? this.state.vendorDetails.addressList.find((a) => a.id == id)
          : null;
      this.setState({
        selectedVendorAdress: selectedVendorAdress,
      });
    }
  };

  //********************GET ITEM DETAILS ******************************* */

  onItemSelect = (selectedOption, key, edit = false, qty = 1) => {
    if (
      this.state.addPurchaseForm.warehouse_id &&
      this.state.addPurchaseForm.vendor_id
    ) {
      if (selectedOption && selectedOption.itemdata) {
        let item = selectedOption.itemdata;
        let prefer_gst_key =
          this.state.vendor_state_id == this.state.warehouse_state_id
            ? "gst"
            : "igst";
        let purchaseItemRows = this.state.purchaseItemRows;
        purchaseItemRows[key] = {
          ...purchaseItemRows[key],
          item: item,
          qty: qty,
          rate: item.purchase_price ? item.purchase_price : 0,
          gst: item[prefer_gst_key],
          // batch_number            :   item.batch_number ? item.batch_number : '',
          // expiry_date             :   item.expiry_date ? item.expiry_date : '',
          discount_rate: 0,
          discount_type: "Percentage",
          batch_list: [{ batch_number: "", expiry_date: "", batch_qty: "" }],
        };
        this.setState({ purchaseItemRows: purchaseItemRows }, () => {
          this.getPendingQuantity(item.id, key);
          if (!this.state.purchaseItemRows.find((ir) => !ir.item)) {
            setTimeout(() => {
              this.addNewItemRow();
            }, 1000);
          }
        });
      }
    } else {
      toast.error("Please Select Warehouse & Vendor First", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  calculateTotalAmount = (purchaseItemRows) => {
    let totalAmount = 0;
    purchaseItemRows.forEach(item => {
      if (item.item) {
        const itemAmount = item.qty * item.rate;
        const discountAmount = item.discount_rate > 0 ? (itemAmount * item.discount_rate) / 100 : 0;
        totalAmount += itemAmount - discountAmount;
      }
    });
    this.setState({ totalAmount }); 
  };
  
  addNewItemRow = () => {
    this.setState({
      purchaseItemRows: [...this.state.purchaseItemRows, { item: null }],
    });
  };

  addItemModalInit = () => {
    this.addItemModalRef.current.itemAddModalInit();
  };

  getPendingQuantity = (item_id, key) => {
    this.dynamicInputHandlerByKeyNameVal(
      true,
      "req_allocation_loading",
      key,
      "purchaseItemRows",
      () => {
        this.dynamicInputHandlerByKeyNameVal(
          [],
          "req_allocation",
          key,
          "purchaseItemRows"
        );
      }
    );

    let params = {
      warehouse_id: this.state.addPurchaseForm.warehouse_id,
      item_id: item_id,
      vendor_id: this.state.addPurchaseForm.vendor_id,
    };
    HttpAPICall.withAthorization(
      "GET",
      this.pendingPOReqUrl,
      this.props.access_token,
      params,
      {},
      (resp) => {
        let itemReqData = resp.data;

        let req_allocation = itemReqData.map((ir, key) => {
          return {
            req_item_id: ir.req_item_id ? ir.req_item_id : null,
            req_id: ir.requisition_transaction_id
              ? ir.requisition_transaction_id
              : null,
            req_date: ir.requisition_date_display
              ? ir.requisition_date_display
              : null,
            po_item_id: ir.po_item_id ? ir.po_item_id : null,
            po_id: ir.purchase_number ? ir.purchase_number : null,
            po_date_display: ir.transaction_date_display
              ? ir.transaction_date_display
              : null,
            rate: ir.rate_vc ? ir.rate_vc : 0,
            po_rate: ir.rate_vc ? ir.rate_vc : 0,
            gst: ir.gst ? ir.gst : 0,
            discount_rate: ir.discount_rate ? ir.discount_rate : 0,
            discount_per_unit: ir.discount_per_unit ? ir.discount_per_unit : 0,
            discount_type: ir.discount_type ? ir.discount_type : "Percentage",
            pending_qty:
              ir.req_item_id && ir.po_item_id
                ? ir.requisition_po_qty
                : ir.po_item_id
                  ? ir.pending_qty
                  : ir.req_pending_qty,
            new_qty:
              ir.req_item_id && ir.po_item_id
                ? ir.requisition_po_qty
                : ir.po_item_id
                  ? ir.pending_qty
                  : ir.req_pending_qty,
            max_qty:
              ir.req_item_id && ir.po_item_id
                ? ir.requisition_po_qty
                : ir.po_item_id
                  ? ir.pending_qty
                  : ir.req_pending_qty,
          };
        });

        let total_item_qty = parseFloat(
          req_allocation.reduce((s, b) => s + parseFloat(b.qty ? b.qty : 0), 0)
        );

        let purchaseItemRows = this.state.purchaseItemRows;
        purchaseItemRows[key] = {
          ...purchaseItemRows[key],
          req_allocation_loading: false,
          req_allocation: req_allocation,
          unallocated_qty: 0,
          selected_allocation: null,
          qty:
            total_item_qty == 0
              ? this.state.purchaseItemRows[key]?.qty
              : total_item_qty + 0,
        };
        this.setState({ purchaseItemRows: purchaseItemRows }, () => {
          if (req_allocation && req_allocation.length > 0) {
            this.requistionDetailInit(key);
          }
        });
      }
    ).then(() => { });
  };

  //Get Previous Allocation
  getAllocationQuantity = (warehouse_id, vendor_id, purchaseItemRows) => {
    purchaseItemRows.forEach((pu_item, key) => {
      let params = {
        warehouse_id: warehouse_id,
        vendor_id: vendor_id,
        item_id: pu_item.id,
        po_item_id: pu_item.po_item_id,
        req_item_id: pu_item.req_item_id,
      };
      HttpAPICall.withAthorization(
        "GET",
        this.pendingPOReqUrl,
        this.props.access_token,
        params,
        {},
        (resp) => {
          let itemReqData = resp.data;
          let req_allocation = [];
          if (
            this.state.editPurchase &&
            itemReqData &&
            itemReqData.length == 0 &&
            (pu_item.purchase_number || pu_item.requisition_transaction_id)
          ) {
            req_allocation = [
              {
                req_item_id: pu_item.req_item_id ? pu_item.req_item_id : null,
                req_id: pu_item.requisition_transaction_id
                  ? pu_item.requisition_transaction_id
                  : null,
                req_date: "",
                po_item_id: pu_item.po_item_id ? pu_item.po_item_id : null,
                po_id: pu_item.purchase_number ? pu_item.purchase_number : "",
                po_date_display: null,
                rate: pu_item.rate ? pu_item.rate : 0,
                qty: pu_item.qty,
                allocated_item_key: 0,
                gst: pu_item.gst ? pu_item.gst : 0,
                discount_rate: pu_item.discount_rate
                  ? pu_item.discount_rate
                  : 0,
                discount_per_unit: pu_item.discount_per_unit
                  ? pu_item.discount_per_unit
                  : 0,
                discount_type: pu_item.discount_type
                  ? pu_item.discount_type
                  : "Percentage",
                new_pending_qty: pu_item.qty,
                pending_qty: pu_item.qty,
                old_pending_qty: pu_item.qty,
                new_qty: pu_item.qty,
                max_qty: pu_item.qty,
              },
            ];
          } else {
            req_allocation = itemReqData.map((ir, key) => {
              let new_pending_qty = 0;
              if (
                this.state.purchaseData &&
                this.state.purchaseData.status == 0
              ) {
                new_pending_qty =
                  ir.req_item_id && ir.po_item_id
                    ? Number(ir.requisition_po_qty)
                    : ir.po_item_id
                      ? Number(ir.pending_qty)
                      : Number(ir.req_pending_qty);
              } else {
                new_pending_qty =
                  ir.req_item_id && ir.po_item_id
                    ? Number(ir.requisition_po_qty) + Number(pu_item.qty)
                    : ir.po_item_id
                      ? Number(ir.pending_qty) + Number(pu_item.qty)
                      : Number(ir.req_pending_qty) + Number(pu_item.qty);
              }

              return {
                req_item_id: ir.req_item_id ? ir.req_item_id : null,
                req_id: ir.requisition_transaction_id
                  ? ir.requisition_transaction_id
                  : null,
                req_date: ir.requisition_date_display
                  ? ir.requisition_date_display
                  : null,
                po_item_id: ir.po_item_id ? ir.po_item_id : null,
                po_id: ir.purchase_number ? ir.purchase_number : null,
                po_date_display: ir.transaction_date_display
                  ? ir.transaction_date_display
                  : null,
                po_rate: ir.rate ? ir.rate : 0,
                rate: pu_item.rate ? pu_item.rate : "",
                qty: pu_item.qty,
                allocated_item_key: key,
                gst: ir.gst ? ir.gst : 0,
                discount_rate: ir.discount_rate ? ir.discount_rate : 0,
                discount_per_unit: ir.discount_per_unit
                  ? ir.discount_per_unit
                  : 0,
                discount_type: ir.discount_type
                  ? ir.discount_type
                  : "Percentage",
                new_pending_qty: new_pending_qty,
                pending_qty:
                  ir.req_item_id && ir.po_item_id
                    ? ir.requisition_po_qty
                    : ir.po_item_id
                      ? ir.pending_qty
                      : ir.req_pending_qty,
                old_pending_qty:
                  ir.req_item_id && ir.po_item_id
                    ? ir.requisition_po_qty
                    : ir.po_item_id
                      ? ir.pending_qty
                      : ir.req_pending_qty,
                new_qty: new_pending_qty,
                max_qty:
                  ir.req_item_id && ir.po_item_id
                    ? ir.requisition_po_qty
                    : ir.po_item_id
                      ? ir.pending_qty
                      : ir.req_pending_qty,
              };
            });
          }

          //    let req_allocation      =   itemReqData.map((ir,key) => {
          //       let new_pending_qty = 0;
          //       if(this.state.purchaseData && this.state.purchaseData.status == 0){
          //         new_pending_qty = ir.req_item_id && ir.po_item_id ? Number(ir.requisition_po_qty)    :  ir.po_item_id ? Number(ir.pending_qty) : Number(ir.req_pending_qty) ;
          //       }else{
          //         new_pending_qty = ir.req_item_id && ir.po_item_id ? Number(ir.requisition_po_qty) + Number(pu_item.qty)   :  ir.po_item_id ? Number(ir.pending_qty) + Number(pu_item.qty)  : Number(ir.req_pending_qty) + Number(pu_item.qty);
          //       }
          //        return {
          //            req_item_id         :   ir.req_item_id ? ir.req_item_id : null,
          //            req_id              :   ir.requisition_transaction_id ? ir.requisition_transaction_id : null,
          //            req_date            :   ir.requisition_date_display ? ir.requisition_date_display : null,
          //            po_item_id          :   ir.po_item_id ? ir.po_item_id : null,
          //            po_id               :   ir.purchase_number ? ir.purchase_number : null,
          //            po_date_display     :   ir.transaction_date_display ? ir.transaction_date_display : null,
          //            rate                :   ir.rate ? ir.rate : 0,
          //            qty                 :   pu_item.qty,
          //            allocated_item_key  :   key,
          //            gst                 :   ir.gst ? ir.gst : 0,
          //            discount_rate       :   ir.discount_rate ? ir.discount_rate : 0,
          //            discount_per_unit   :   ir.discount_per_unit ? ir.discount_per_unit : 0,
          //            discount_type       :   ir.discount_type ? ir.discount_type : 'Percentage',
          //            new_pending_qty     :   new_pending_qty,
          //            pending_qty         :   ir.req_item_id && ir.po_item_id ? ir.requisition_po_qty   :  ir.po_item_id ? ir.pending_qty  : ir.req_pending_qty,
          //            old_pending_qty     :   ir.req_item_id && ir.po_item_id ? ir.requisition_po_qty   :  ir.po_item_id ? ir.pending_qty  : ir.req_pending_qty,
          //            new_qty             :   new_pending_qty,
          //            max_qty             :   ir.req_item_id && ir.po_item_id ? ir.requisition_po_qty  :  ir.po_item_id ? ir.pending_qty : ir.req_pending_qty,
          //        };
          //    });

          let purchaseItemRows = this.state.purchaseItemRows;

          let selected_allocation =
            pu_item.po_item_id && pu_item.req_item_id
              ? req_allocation.find(
                (req) =>
                  req.po_item_id == pu_item.po_item_id &&
                  req.req_item_id == pu_item.req_item_id
              )
              : pu_item.po_item_id && pu_item.req_item_id == null
                ? req_allocation.find(
                  (req) =>
                    req.po_item_id == pu_item.po_item_id &&
                    req.req_item_id == pu_item.req_item_id
                )
                : pu_item.po_item_id == null && pu_item.req_item_id
                  ? req_allocation.find(
                    (req) =>
                      req.req_item_id == pu_item.req_item_id &&
                      req.po_item_id == pu_item.po_item_id
                  )
                  : null;

          let new_selected_allocation = {
            ...selected_allocation,
            new_pending_qty:
              this.state.purchaseData && this.state.purchaseData.status == 0
                ? Number(selected_allocation?.pending_qty)
                : Number(selected_allocation?.qty) +
                Number(selected_allocation?.pending_qty),
          };

          let allocationIndex = req_allocation.findIndex(
            (obj) =>
              obj.allocated_item_key == selected_allocation?.allocated_item_key
          );

          if (allocationIndex !== -1) {
            req_allocation[allocationIndex].new_qty = selected_allocation?.qty;
          }

          purchaseItemRows[key] = {
            ...purchaseItemRows[key],
            req_allocation_loading: false,
            //req_allocation          :      [],
            req_allocation:
              this.state.editPurchase &&
                itemReqData &&
                itemReqData.length == 0 &&
                (pu_item.requisition_transaction_id || pu_item.purchase_number)
                ? []
                : req_allocation,
            selected_allocation: new_selected_allocation,
            allocated_item_key: selected_allocation
              ? selected_allocation?.allocated_item_key
              : req_allocation?.length + 1,
            unallocated_qty:
              !pu_item.po_item_id && !pu_item.req_item_id ? pu_item.qty : 0,
          };

          let allAllocatedItemIds = purchaseItemRows.map((p, k) => {
            return {
              po_item_id: p?.selected_allocation?.po_item_id,
              req_item_id: p?.selected_allocation?.req_item_id,
            };
          });

          this.setState({
            purchaseItemRows: purchaseItemRows,
            allAllocatedItemIds: allAllocatedItemIds,
          });
        }
      );
    });
  };

  //GET Pending Quantity for Clone
  getPendingQuantityForClone = (warehouse_id, vendor_id, purchaseItemRows) => {
    purchaseItemRows.forEach((pu_item, key) => {
      let params = {
        warehouse_id: warehouse_id,
        vendor_id: vendor_id,
        item_id: pu_item.id,
      };
      HttpAPICall.withAthorization(
        "GET",
        this.pendingPOReqUrl,
        this.props.access_token,
        params,
        {},
        (resp) => {
          let itemReqData = resp.data;

          let req_allocation = itemReqData.map((ir, key) => {
            let new_pending_qty =
              ir.req_item_id && ir.po_item_id
                ? Number(ir.requisition_po_qty)
                : ir.po_item_id
                  ? Number(ir.pending_qty)
                  : Number(ir.req_pending_qty);

            return {
              req_item_id: ir.req_item_id ? ir.req_item_id : null,
              req_id: ir.requisition_transaction_id
                ? ir.requisition_transaction_id
                : null,
              req_date: ir.requisition_date_display
                ? ir.requisition_date_display
                : null,
              po_item_id: ir.po_item_id ? ir.po_item_id : null,
              po_id: ir.purchase_number ? ir.purchase_number : null,
              po_date_display: ir.transaction_date_display
                ? ir.transaction_date_display
                : null,
              rate: ir.rate ? ir.rate : 0,
              po_rate: ir.rate ? ir.rate : 0,
              qty: pu_item.qty,
              allocated_item_key: key,
              gst: ir.gst ? ir.gst : 0,
              discount_rate: ir.discount_rate ? ir.discount_rate : 0,
              discount_per_unit: ir.discount_per_unit
                ? ir.discount_per_unit
                : 0,
              discount_type: ir.discount_type ? ir.discount_type : "Percentage",
              new_pending_qty: new_pending_qty,
              pending_qty: new_pending_qty,
              old_pending_qty:
                ir.req_item_id && ir.po_item_id
                  ? ir.requisition_po_qty
                  : ir.po_item_id
                    ? ir.pending_qty
                    : ir.req_pending_qty,
              new_qty: new_pending_qty,
              max_qty: new_pending_qty,
            };
          });

          let purchaseItemRows = this.state.purchaseItemRows;

          let selected_allocation =
            pu_item.po_item_id && pu_item.req_item_id
              ? req_allocation.find(
                (req) =>
                  req.po_item_id == pu_item.po_item_id &&
                  req.req_item_id == pu_item.req_item_id
              )
              : pu_item.po_item_id && pu_item.req_item_id == null
                ? req_allocation.find(
                  (req) =>
                    req.po_item_id == pu_item.po_item_id &&
                    req.req_item_id == pu_item.req_item_id
                )
                : pu_item.po_item_id == null && pu_item.req_item_id
                  ? req_allocation.find(
                    (req) =>
                      req.req_item_id == pu_item.req_item_id &&
                      req.po_item_id == pu_item.po_item_id
                  )
                  : null;

          let new_selected_allocation = {
            ...selected_allocation,
            pending_qty: Number(selected_allocation?.pending_qty),
          };

          let allocationIndex = req_allocation.findIndex(
            (obj) =>
              obj.allocated_item_key == selected_allocation?.allocated_item_key
          );

          if (allocationIndex !== -1) {
            req_allocation[allocationIndex].new_qty = selected_allocation?.qty;
          }

          purchaseItemRows[key] = {
            ...purchaseItemRows[key],
            req_allocation_loading: false,
            req_allocation: req_allocation,

            //    selected_allocation     :   pu_item.po_item_id && pu_item.req_item_id
            //                                 ? req_allocation.find(req => req.po_item_id == pu_item.po_item_id && req.req_item_id == pu_item.req_item_id,'stage 1')
            //                                 : pu_item.po_item_id && pu_item.req_item_id == null ? req_allocation.find(req => req.po_item_id == pu_item.po_item_id && req.req_item_id == pu_item.req_item_id)
            //                                 : pu_item.po_item_id == null && pu_item.req_item_id ? req_allocation.find(req => req.req_item_id == pu_item.req_item_id && req.po_item_id == pu_item.po_item_id)
            //                                 : null,

            selected_allocation: new_selected_allocation,
            allocated_item_key: selected_allocation
              ? selected_allocation?.allocated_item_key
              : req_allocation?.length + 1,
            unallocated_qty:
              !pu_item.po_item_id && !pu_item.req_item_id ? pu_item.qty : 0,
          };

          let allAllocatedItemIds = purchaseItemRows.map((p, k) => {
            return {
              po_item_id: p?.selected_allocation?.po_item_id,
              req_item_id: p?.selected_allocation?.req_item_id,
            };
          });

          this.setState({
            purchaseItemRows: purchaseItemRows,
            allAllocatedItemIds: allAllocatedItemIds,
          });
        }
      );
    });
  };

  //********************EDIT ITEM DETAILS ******************************* */

  editItemModalInit = (data, k) => {
    this.setState({ itemSearchedDetails: data, itemRowNumber: k })
    this.editItemModalRef.current.itemEditModalInit(data);
  };

  submitEditItem = () => {
    HttpAPICall.withAthorization(
      "GET",
      this.itemUrl + "/" + this.state.itemSearchedDetails.id,
      this.props.access_token,
      null,
      null,
      (response) => {
        let selectedItem = {
          label: (
            <Ax>
              <b>Item : </b> {response.data.data.name}{" "}
              <small> ({response.data.data.item_code})</small>
              <br />
              <small>
                <b>Manufacturer : </b>{" "}
                {response.data.data.manufacturer
                  ? response.data.data.manufacturer.name
                  : ""}
                <br />
                <b>MPN : </b> {response.data.data.manufacturer_part_no}
              </small>
            </Ax>
          ),
          value: response.data.data.id,
          display_label: `${response.data.data.name} (${response.data.data.item_code})`,
          itemdata: response.data.data,
        };
        this.setState(
          {
            itemSearchedDetails: response.data.data,
          },
          () => {
            this.onItemSelect(
              selectedItem,
              this.state.itemRowNumber,
              "edit",
              this.state.purchaseItemRows[this.state.itemRowNumber]?.qty
            );
          }
        );
      }
    );
  };

  itemViewModalInit = (item, k) => {
    if (item) {
      this.itemDetailsRef.current.modalInit(item);
      this.setState({ itemRowNumber: k })
    }

  };

  addRemark = (data, k) => {
    let allRows = this.state.purchaseItemRows;
    let itemdata = { ...data, isAddRemark: true };
    allRows[k] = { ...this.state.purchaseItemRows[k], item: itemdata };
    this.setState({ purchaseItemRows: allRows });
  };

  removeItemRow = (k) => {
    let allRows = this.state.purchaseItemRows;
    if (allRows.length > 1) {
      allRows.splice(k, 1);
      this.setState({ purchaseItemRows: allRows }, () => {
        let allAllocatedItemIds = allRows.map((p, k) => {
          return {
            po_item_id: p?.selected_allocation?.po_item_id,
            req_item_id: p?.selected_allocation?.req_item_id,
          };
        });
        this.setState({ allAllocatedItemIds: allAllocatedItemIds });
      });
    }
  };

  //****************************PICK ITEM FOR PO MODAL From PO REPORT *********************/
  getAllSelectedPO = (items, po_item_ids = [], disable_rate = "N") => {
    if (
      this.state.addPurchaseForm.warehouse_id &&
      this.state.addPurchaseForm.vendor_id
    ) {
      let new_qty = true;
      this.getAllPendingItemsfromPO(
        this.state.addPurchaseForm.warehouse_id,
        this.state.addPurchaseForm.vendor_id,
        items,
        po_item_ids,
        new_qty,
        disable_rate
      );
      this.pickPOItemModal.show();
    } else {
      toast.error("Please Select Warehouse & Vendor First", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //****************************PICK ITEM FOR PO MODAL *********************/
  pickPOItemModalInit = () => {
    if (
      this.state.addPurchaseForm.warehouse_id &&
      this.state.addPurchaseForm.vendor_id
    ) {
      this.getAllPendingItemsfromPO(
        this.state.addPurchaseForm.warehouse_id,
        this.state.addPurchaseForm.vendor_id
      );
      this.pickPOItemModal.show();
    } else {
      toast.error("Please Select Warehouse & Vendor First", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //*****************************GET ALL PENDING ITEMS FROM PO************** */
  getAllPendingItemsfromPO = (
    warehouse_id,
    vendor_id,
    item_ids,
    po_item_ids,
    new_qty = false,
    disable_rate = "N"
  ) => {
    if (!this.state.pendingPOListLoaded) {
      this.setState({ pending_item_loading: true });
      let params = {
        warehouse_id: this.state.addPurchaseForm.warehouse_id,
        vendor_id: this.state.addPurchaseForm.vendor_id,
        need_item_detail: "Y",
        item_ids: item_ids && item_ids.length > 0 ? item_ids : [],
        po_item_ids: po_item_ids && po_item_ids.length > 0 ? po_item_ids : [],
        include_req_pending_items: this.state.include_req_pending_items,
      };

      HttpAPICall.withAthorization(
        "GET",
        this.pendingPOReqUrl,
        this.props.access_token,
        params,
        {},
        (resp) => {
          this.setState({
            allPendingPOList:
              new_qty == true
                ? resp.data.map((d) => {
                  return {
                    ...d,
                    new_qty:
                      d.req_item_id && d.po_item_id
                        ? d.requisition_po_qty
                        : d.po_item_id && d.req_item_id == null
                          ? d.pending_qty
                          : d.req_pending_qty,
                    disable_rate: disable_rate,
                  };
                })
                : resp.data,
            pendingPOListLoaded: new_qty == true ? false : true,
          });
        }
      ).then(() => {
        this.setState({ pending_item_loading: false }, () => { });

        setTimeout(() => {
          this.checkSelectedPOList(this.state.allPOIdsList);
        }, 1000);
      });
    }
  };

  checkSelectedPOList = (allPOIds = []) => {
    let poItemCheckboxCollections =
      document.getElementsByClassName("po_id_checkbox");
    if (
      poItemCheckboxCollections &&
      poItemCheckboxCollections.length > 0 &&
      allPOIds.length > 0
    ) {
      for (let i = 0; i < poItemCheckboxCollections.length; i++) {
        if (allPOIds.includes(poItemCheckboxCollections[i].value)) {
          poItemCheckboxCollections[i].checked = true;
        }
      }
    }
  };

  checkAllPOList = () => {
    let poItemCheckboxCollections =
      document.getElementsByClassName("po_id_checkbox");
    if (poItemCheckboxCollections && poItemCheckboxCollections.length > 0) {
      for (let i = 0; i < poItemCheckboxCollections.length; i++) {
        poItemCheckboxCollections[i].checked =
          document.getElementById("all_check_po").checked;
      }
    }
  };

  submitSelectedPOItem = (e) => {
    e.preventDefault();

    let poItemCheckboxCollections =
      document.getElementsByClassName("po_id_checkbox");
    let allSelectedItems = [];
    let allQty = [];
    let allCurrency = [];

    if (poItemCheckboxCollections && poItemCheckboxCollections.length > 0) {
      for (let i = 0; i < poItemCheckboxCollections.length; i++) {
        if (poItemCheckboxCollections[i].checked) {
          let po_item_id = poItemCheckboxCollections[i].value;
          let req_item_id =
            poItemCheckboxCollections[i].getAttribute("data-req_item_id");
          let qty = poItemCheckboxCollections[i].getAttribute("data-qty");
          allQty.push(qty);
          let currency =
            poItemCheckboxCollections[i].getAttribute("data-currency");
          allCurrency.push(currency);
          // if(currency == this.state.addPurchaseForm.currency){
          //     poItemCheckboxCollections[i].disabled = true;
          // }
          if (po_item_id && req_item_id) {
            let selectedPOItem = this.state.allPendingPOList.find(
              (po, o) =>
                po.po_item_id == po_item_id && po.req_item_id == req_item_id
            );
            allSelectedItems.push(selectedPOItem);
          }
          if ((po_item_id && req_item_id == null) || !req_item_id) {
            let selectedPOItem = this.state.allPendingPOList.find(
              (po, o) => po.po_item_id == po_item_id && po.req_item_id == null
            );
            allSelectedItems.push(selectedPOItem);
          }
          if (po_item_id == null || (!po_item_id && req_item_id)) {
            let selectedPOItem = this.state.allPendingPOList.find(
              (po, o) => po.req_item_id == req_item_id && po.po_item_id == null
            );
            allSelectedItems.push(selectedPOItem);
          }
        }
      }
    }

    let currency_unique = allCurrency.filter(
      (item, i, ar) => ar.indexOf(item) === i
    );
    //***************************HANDLE SELECTED ITEM FROM PO  **********************/
    if (allSelectedItems.length == 0) {
      toast.error("Please select atleast one Item", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (currency_unique.length > 1) {
      toast.error("Every item must be of Same Currency", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (currency_unique[0] !== this.state.addPurchaseForm.currency) {
      toast.error(`Pick Item with selected Purchase currency`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let checkBlankQty =
        allQty.filter(function (item) {
          return item == "" || item == null;
        }).length == 0;
      if (checkBlankQty == false) {
        toast.error("Please fill the allocated Qty", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        this.handleSelectedPOItems(allSelectedItems);
      }
    }
  };

  handleSelectedPOItems = (allItems) => {
    this.setState({ savePickItemSubmitting: true });
    allItems.forEach((list, key) => {
      let purchaseItemRows = this.state.purchaseItemRows;
      let itemkey =
        this.state.purchaseItemRows.length > 1
          ? this.state.purchaseItemRows.length + key
          : key;

      let params = {
        warehouse_id: this.state.addPurchaseForm.warehouse_id,
        item_id: list?.item?.id,
        vendor_id: this.state.addPurchaseForm.vendor_id,
      };
      HttpAPICall.withAthorization(
        "GET",
        this.pendingPOReqUrl,
        this.props.access_token,
        params,
        {},
        (resp) => {
          let itemReqData = resp.data;

          let req_allocation = itemReqData.map((ir, key) => {
            let selected_allocation_from_req =
              list.po_item_id && list.req_item_id
                ? ir.po_item_id == list.po_item_id &&
                ir.req_item_id == list.req_item_id
                : list.po_item_id && list.req_item_id == null
                  ? ir.po_item_id == list.po_item_id &&
                  ir.req_item_id == list.req_item_id
                  : list.po_item_id == null && list.req_item_id
                    ? ir.req_item_id == list.req_item_id &&
                    ir.po_item_id == list.po_item_id
                    : null;

            return {
              req_item_id: ir.req_item_id ? ir.req_item_id : null,
              req_id: ir.requisition_transaction_id
                ? ir.requisition_transaction_id
                : null,
              req_date: ir.requisition_date_display
                ? ir.requisition_date_display
                : null,
              po_item_id: ir.po_item_id ? ir.po_item_id : null,
              po_id: ir.purchase_number ? ir.purchase_number : null,
              po_date_display: ir.transaction_date_display
                ? ir.transaction_date_display
                : null,
              rate: ir.rate_vc ? ir.rate_vc : 0,
              po_rate: ir.rate_vc ? ir.rate_vc : 0,
              gst: ir.gst ? ir.gst : 0,
              allocated_item_key: key,
              discount_rate: ir.discount_rate_vc ? ir.discount_rate_vc : 0,
              discount_per_unit: ir.discount_per_unit
                ? ir.discount_per_unit
                : 0,
              discount_type: ir.discount_type ? ir.discount_type : "Percentage",
              new_qty:
                selected_allocation_from_req == true
                  ? list.new_qty
                  : ir.req_item_id && ir.po_item_id
                    ? ir.requisition_po_qty
                    : ir.po_item_id
                      ? ir.pending_qty
                      : ir.req_pending_qty,
              pending_qty:
                ir.req_item_id && ir.po_item_id
                  ? ir.requisition_po_qty
                  : ir.po_item_id
                    ? ir.pending_qty
                    : ir.req_pending_qty,
              max_qty:
                ir.req_item_id && ir.po_item_id
                  ? ir.requisition_po_qty
                  : ir.po_item_id
                    ? ir.pending_qty
                    : ir.req_pending_qty,
              disable_rate:
                ir.po_item_id && this.state.purchase_po_same_unit_rate == "Y"
                  ? "Y"
                  : "N",

              //disable_rate        :           list.disable_rate && list.disable_rate == 'Y' ? 'Y' : "N"
            };
          });

          let selected_allocation_from_req =
            list.po_item_id && list.req_item_id
              ? req_allocation.find(
                (req) =>
                  req.po_item_id == list.po_item_id &&
                  req.req_item_id == list.req_item_id
              )
              : list.po_item_id && list.req_item_id == null
                ? req_allocation.find(
                  (req) =>
                    req.po_item_id == list.po_item_id &&
                    req.req_item_id == list.req_item_id
                )
                : list.po_item_id == null && list.req_item_id
                  ? req_allocation.find(
                    (req) =>
                      req.req_item_id == list.req_item_id &&
                      req.po_item_id == list.po_item_id
                  )
                  : null;

          let selected_allocation = {
            ...list,
            req_item_id: list.req_item_id ? list.req_item_id : null,
            req_id: list.requisition_transaction_id
              ? list.requisition_transaction_id
              : null,
            req_date: list.requisition_date_display
              ? list.requisition_date_display
              : null,
            po_item_id: list.po_item_id ? list.po_item_id : null,
            po_id: list.purchase_number ? list.purchase_number : null,
            po_date_display: list.transaction_date_display
              ? list.transaction_date_display
              : null,
            rate: list.rate_vc ? list.rate_vc : 0,
            po_rate: list.rate_vc ? list.rate_vc : 0,
            qty: list.new_qty,
            old_pending_qty:
              list.req_item_id && list.po_item_id
                ? list.requisition_po_qty
                : list.po_item_id
                  ? list.pending_qty
                  : list.req_pending_qty,
            gst: list.gst ? list.gst : 0,
            discount_rate: list.discount_rate_vc ? list.discount_rate_vc : 0,
            discount_per_unit: list.discount_per_unit
              ? list.discount_per_unit
              : 0,
            discount_type: list.discount_type
              ? list.discount_type
              : "Percentage",
            pending_qty:
              list.req_item_id && list.po_item_id
                ? list.requisition_po_qty
                : list.po_item_id
                  ? list.pending_qty
                  : list.req_pending_qty,
            new_qty: list.new_qty,
            max_qty:
              list.req_item_id && list.po_item_id
                ? list.requisition_po_qty
                : list.po_item_id
                  ? list.pending_qty
                  : list.req_pending_qty,
            disable_rate: list.po_item_id ? "Y" : "N",
          };
          let allocated_item_key = selected_allocation_from_req
            ? selected_allocation_from_req.allocated_item_key
            : req_allocation?.length + 1;

          purchaseItemRows[itemkey] = {
            ...purchaseItemRows[itemkey],
            req_allocation_loading: false,
            req_allocation: req_allocation,

            selected_allocation: selected_allocation,
            item: list.item,
            qty: list.new_qty,
            rate: list.rate_vc,
            po_rate: list.rate_vc,
            gst: list.gst,
            allocated_item_key: allocated_item_key,
            new_qty: null,
            discount_rate: list.discount_rate,
            discount_type: list.discount_type,
            disable_rate: list.po_item_id ? "Y" : "N",
            batch_list: [{ batch_number: "", expiry_date: "", batch_qty: "" }],
          };

          const uniquePurchaseItemRows = purchaseItemRows.filter(
            (item, index, self) => {
              const pos = self.findIndex(
                (i) =>
                  i?.selected_allocation?.po_item_id ===
                  item?.selected_allocation?.po_item_id &&
                  i?.selected_allocation?.req_item_id ===
                  item?.selected_allocation?.req_item_id
              );
              return pos === index;
            }
          );

          let allAllocatedItemIds = uniquePurchaseItemRows.map((p, k) => {
            return {
              po_item_id: p?.selected_allocation?.po_item_id,
              req_item_id: p?.selected_allocation?.req_item_id,
            };
          });

          //   this.setState({
          //     purchaseItemRows            :   uniquePurchaseItemRows,
          //     savePickItemSubmitting      :           false,
          //   },() => this.pickPOItemModal.hide())
          this.setState(
            {
              purchaseItemRows: uniquePurchaseItemRows,
              savePickItemSubmitting: false,
              allAllocatedItemIds: allAllocatedItemIds,
            },
            () => this.pickPOItemModal.hide()
          );
        }
      );
    });
    this.addNewItemRow();
  };

  //******************************ALLOCATE ITEM FOR REQUISTION*************** */
  requistionDetailInit = (key) => {
    let purchaseItemRows = this.state.purchaseItemRows;
    let purchaseItemRow = purchaseItemRows[key];

    if (purchaseItemRow) {
      this.setState(
        { purchaseItemRows: purchaseItemRows, purchaseItemRowKey: key },
        () => {
          this.reqDetailModal.show();
        }
      );
    }
  };

  submitAllocatedItem = (e) => {
    e.preventDefault();

    let purchaseItemRows = this.state.purchaseItemRows;
    let purchaseItemRow = purchaseItemRows[this.state.purchaseItemRowKey];

    let allocated_item_key = purchaseItemRow.allocated_item_key
      ? purchaseItemRow.allocated_item_key
      : 0;

    let selected_allocation =
      purchaseItemRow.req_allocation[allocated_item_key];

    let req_allocation = {
      ...selected_allocation,
      new_qty: selected_allocation?.new_qty,
      qty: selected_allocation?.new_qty,
    };

    purchaseItemRow.selected_allocation =
      purchaseItemRow.allocated_item_key ==
        purchaseItemRow.req_allocation?.length + 1
        ? null
        : req_allocation;
    purchaseItemRow.rate =
      purchaseItemRow.selected_allocation == null
        ? purchaseItemRow.item.purchase_price
        : selected_allocation?.rate;
    purchaseItemRow.gst =
      purchaseItemRow.selected_allocation == null
        ? 0
        : selected_allocation?.gst;
    purchaseItemRow.discount_rate =
      purchaseItemRow.selected_allocation == null
        ? 0
        : selected_allocation?.discount_type == "Percentage"
          ? selected_allocation.discount_rate
          : (
            selected_allocation?.discount_per_unit * selected_allocation?.qty
          ).toFixed(2);
    purchaseItemRow.discount_type =
      purchaseItemRow.selected_allocation == null
        ? "Percentage"
        : selected_allocation?.discount_type;
    purchaseItemRow.allocated_qty = req_allocation ? req_allocation.qty : 0;
    purchaseItemRow.disable_rate =
      purchaseItemRow.selected_allocation == null
        ? "N"
        : selected_allocation?.po_item_id
          ? "Y"
          : "N";

    // purchaseItemRow.req_allocation.forEach(function(v){ delete v.allocated_item_key })

    purchaseItemRow.qty =
      purchaseItemRow.selected_allocation == null
        ? purchaseItemRow.unallocated_qty
        : purchaseItemRow.allocated_qty;
    purchaseItemRow.batch_list = purchaseItemRow.batch_list.map((bl) => {
      let batch_qty =
        purchaseItemRow.selected_allocation == null
          ? purchaseItemRow.unallocated_qty
          : purchaseItemRow.allocated_qty;
      let qty =
        purchaseItemRow.selected_allocation == null
          ? purchaseItemRow.unallocated_qty
          : purchaseItemRow.allocated_qty;

      // Divide batch_qty and qty if batch_list length is greater than 1
      if (purchaseItemRow.batch_list.length > 1) {
        batch_qty /= purchaseItemRow.batch_list.length;
        qty /= purchaseItemRow.batch_list.length;
      }

      return { ...bl, batch_qty, qty };
    });
    let allAllocatedItemIds = purchaseItemRows.map((p, k) => {
      return {
        po_item_id: p?.selected_allocation?.po_item_id,
        req_item_id: p?.selected_allocation?.req_item_id,
      };
    });
    purchaseItemRow[this.state.purchaseItemRowKey] = purchaseItemRow;
    this.setState(
      {
        purchaseItemRow: purchaseItemRow,
        allAllocatedItemIds: allAllocatedItemIds,
      },
      () => {
        let form = document.getElementById("allocatedItemForm");
        form.reset();
        this.reqDetailModal.hide();
      }
    );
  };

  //**************Add Batch Number and Expiry Date********************** */
  addBatchNumberInit = (key) => {
    let purchaseItemRows = this.state.purchaseItemRows;
    let purchaseItemRow = purchaseItemRows[key];

    if (purchaseItemRow) {
      this.setState(
        { purchaseItemRows: purchaseItemRows, purchaseItemRowKey: key },
        () => {
          this.addBatchNumberModal.show();
        }
      );
    }
  };

  addNewBatchRow() {
    // Clone the purchaseItemRows array to avoid mutating the state directly
    let updatedPurchaseItemRows = [...this.state.purchaseItemRows];

    // Get the specific purchaseItemRow using the purchaseItemRowKey
    let purchaseItemRow =
      updatedPurchaseItemRows[this.state.purchaseItemRowKey];

    if (purchaseItemRow && purchaseItemRow.batch_list) {
      let newBatch = {
        batch_number: "", // Initialize with empty values
        expiry_date: "",
        batch_qty: "",
      };

      // Add the new batch to the batch_list
      purchaseItemRow.batch_list.push(newBatch);

      // Update the purchaseItemRows array with the modified purchaseItemRow
      updatedPurchaseItemRows[this.state.purchaseItemRowKey] = purchaseItemRow;

      // Update the state with the updated purchaseItemRows
      this.setState({ purchaseItemRows: updatedPurchaseItemRows });
    }
  }

  removeItemBatchList(indexToRemove) {
    let updatedPurchaseItemRows = [...this.state.purchaseItemRows];
    let purchaseItemRow =
      updatedPurchaseItemRows[this.state.purchaseItemRowKey];

    if (purchaseItemRow && purchaseItemRow.batch_list) {
      purchaseItemRow.batch_list.splice(indexToRemove, 1);

      this.setState({ purchaseItemRows: updatedPurchaseItemRows });
    }
  }

  submitItemBatchNumber = (e) => {
    e && e.preventDefault();
    let updatedPurchaseItemRows = [...this.state.purchaseItemRows];
    let purchaseItemRow =
      updatedPurchaseItemRows[this.state.purchaseItemRowKey];

    if (
      purchaseItemRow &&
      purchaseItemRow.batch_list &&
      purchaseItemRow.batch_list.length >= 1
    ) {
      let totalNewQty = purchaseItemRow.batch_list.reduce(
        (accumulator, batch) => {
          return accumulator + Number(batch.batch_qty);
        },
        0
      );

      if (Number(totalNewQty) !== Number(purchaseItemRow.qty)) {
        toast.error("Total of Each Qty should be equal to Final Qty");

        const updatedBatchList = purchaseItemRow.batch_list.map((batch) => ({
          ...batch,
          expiry_date_display: batch.expiry_date
            ? moment(batch.expiry_date).format("DD-MMM-YYYY")
            : "",
          batch_qty: batch.batch_qty, // Set batch_qty to blank
        }));

        this.setState({
          purchaseItemRows: updatedPurchaseItemRows.map((item) => {
            if (item === purchaseItemRow) {
              return {
                ...item,
                batch_list: updatedBatchList,
              };
            }
            return item;
          }),
        });
      } else {
        this.addBatchNumberModal.hide();
        const updatedBatchList = purchaseItemRow.batch_list.map((batch) => ({
          ...batch,
          expiry_date_display: batch.expiry_date
            ? moment(batch.expiry_date).format("DD-MMM-YYYY")
            : "",
        }));

        this.setState({
          purchaseItemRows: updatedPurchaseItemRows.map((item) => {
            if (item === purchaseItemRow) {
              return {
                ...item,
                batch_list: updatedBatchList,
              };
            }
            return item;
          }),
        });
      }
    }
  };

  //****************************CLOSE PURCHASE *********************/
  closeEvent = () => {
    if (this.state?.purchaseData) {
      this.props.history.push({
        pathname: `/purchase_list`,
        state: { purchaseData: this.props.location?.state?.purchaseData },
      });
    } else {
      this.props.history.push({ pathname: `/purchase_list` });
    }
  };

  //****************************INCLUDE CONSUMPTION *********************/
  onSelectionConsumption = () => {
    let checkedConsumption = document?.getElementById(
      "include_consumption"
    )?.checked;
    if (checkedConsumption == true) {
      this.setState({
        addPurchaseForm: {
          ...this.state.addPurchaseForm,
          include_consumption: "Y",
        },
      });
      if (!this.state.consumptionFormDataLoaded) {
        this.setState({ consumptionFormLoading: true });
        HttpAPICall.withAthorization(
          "GET",
          this.consumptionUrl + "/add_form_data",
          this.props.access_token,
          null,
          null,
          (response) => {
            this.setState({
              consumptionFormDataLoaded: true,
            });
          }
        ).then(() => this.setState({ consumptionFormLoading: false }));
      }
    } else {
      this.setState({
        addPurchaseForm: {
          ...this.state.addPurchaseForm,
          include_consumption: "N",
        },
        selectedAsset: null,
        consumptionForm: { ...this.initConsumptionForm },
        searchedWorkstation: null,
      });
    }
  };

  itemSearchModalInit = () => {
    let allRows = this.state.purchaseItemRows;
    let item_ids = [];
    if (allRows && allRows.length > 0) {
      item_ids = allRows
        .filter((i) => (i.item ? true : false))
        .map((item) => {
          return item?.item?.id;
        });
    }
    this.itemSearchRef.current.searchItemModalInit(item_ids);
  };

  addItemsfromSearch = (selectedItems) => {
    let purchaseItemRows = selectedItems.map((pu_item, k) => {
      return {
        item: { ...pu_item.item, isAddRemark: pu_item.remark ? true : false },
        req_allocation_loading: true,
        req_allocation: [],
        id: pu_item.item.id,
        qty: pu_item.qty,
        rate: pu_item.rate,
        remark: "",
        gst: pu_item.gst,
        discount_type: "",
        discount_rate: "",
        po_item_id: "",
        req_item_id: "",
        selected_allocation: null,
        expiry_date: "",
        //batch_number                :           '',
        stock_id: null,
      };
    });
    let allRows = this.state.purchaseItemRows
      ? this.state.purchaseItemRows.filter((i) => (i.item ? true : false))
      : [];
    this.setState(
      {
        purchaseItemRows: allRows.concat(purchaseItemRows),
      },
      () => {
        //get all Previous Allocation :-
        this.getAllocationQuantity(
          this.state.addPurchaseForm.warehouse_id,
          this.state.addPurchaseForm.vendor_id,
          this.state.purchaseItemRows
        );
      }
    );
  };

  convertCurrency = (selectedOption) => {
    let params = { to: this.state.defaultCurrency, from: selectedOption.value };
    this.setState({ currency_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.currency_convert_url + "/currency/convert",
      this.props.access_token,
      params,
      null,
      (response) => {
        let resp = response.data;
        // let updatedPurchaseItemRows = [];
        // let purchaseItemRows          =   this.state.purchaseItemRows;
        // updatedPurchaseItemRows       =   purchaseItemRows.map((po,key) => {
        //     let item                =   po.item;
        //     let rate                =   item && item.purchase_price ? item.purchase_price : 0;
        //     // if(item && item.purchase_currency !== this.state.addPurchaseForm.currency && item.purchase_price && resp.rate){
        //     //     rate        =   Number(item.purchase_price) / Number(resp.rate);
        //     // }
        //         return({
        //             ...po,
        //             rate : parseFloat(rate).toFixed(2)
        //         })
        // })

        this.setState({
          addPurchaseForm: {
            ...this.state.addPurchaseForm,
            conversion_rate: resp.rate,
          },
          //purchaseItemRows      :   updatedPurchaseItemRows,
          editCurrency: false,
          allDiscountType: this.state.allDiscountType.map((s) => {
            return {
              value: s.value,
              label: s.value === "Fixed" ? selectedOption.value : "%",
            };
          }),
          allTCSType: this.state.allTCSType.map((s) => {
            return {
              value: s.value,
              label: s.value === "Fixed" ? selectedOption.value : "%",
            };
          }),
        });
      }
    ).then(() => this.setState({ currency_loading: false }));
  };

  //**********************PURCHASE FORM  SUBMIT ***************************/
  addPurchaseFormSubmitEvent = (e, status = 0) => {
    e.preventDefault();
    let items = [...this.state.purchaseItemRows];

    let itemsLists = items
      .filter((i) => (i.item ? true : false))
      .map((i) => {
        //let batch_list =  i && i.batch_list && i.batch_list.length == 1 && i.batch_list.some(batch => {return !batch.batch_number && !batch.expiry_date}) ? [] : i.batch_list;
        return {
          id: i.item.id,
          item: null,
          batch_list:
            i.batch_list &&
              i.batch_list.length > 0 &&
              i.batch_list.some((item) => item.batch_qty > 0)
              ? i.batch_list.map((bt) => {
                return {
                  ...bt,
                  qty: Number(bt.batch_qty),
                  stock_id: bt.stock_id && bt.stock_id ? bt.stock_id : "",
                };
              })
              : [
                {
                  qty: i.qty,
                  stock_id: i.stock_id ? i.stock_id : "",
                  batch_number: "",
                  expiry_date: "",
                },
              ],
          discount_rate: i.discount_rate,
          discount_type: i.discount_type,
          gst: i.gst,
          qty: i.qty,
          rate: i.rate,
          remark: i.remark,
          stock_id: i.stock_id ? i.stock_id : null,
          po_item_id:
            i.selected_allocation && i.selected_allocation.po_item_id
              ? i.selected_allocation.po_item_id
              : null,
          req_item_id:
            i.selected_allocation && i.selected_allocation.req_item_id
              ? i.selected_allocation.req_item_id
              : null,
        };
      });

    let itemAllocationCombination = itemsLists
      .map((v) => {
        return [{ po_id: v.po_item_id, req_id: v.req_item_id }];
      })
      .flat();
    let foundDuplicateCombination = itemAllocationCombination.find(
      (al, index) => {
        if (al.po_id !== null && al.req_id !== null) {
          return itemAllocationCombination.find(
            (x, ind) =>
              al.po_id === x.po_id && al.req_id === x.req_id && index !== ind
          );
        } else {
          return null;
        }
      }
    );

    if (foundDuplicateCombination) {
      toast.error(
        "Item Allocation can be done only one time for same combination of purchase order and Requisition",
        { position: toast.POSITION.TOP_RIGHT }
      );
    } else {
      let addPurchaseForm = {
        ...this.state.addPurchaseForm,
        adjustment_amount: parseFloat(
          this.state.addPurchaseForm.adjustment_amount
        ),
        transaction_date: moment(
          this.state.addPurchaseForm.transaction_date,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD"),
      };
      let frmData;
      if (this.state.addPurchaseForm.include_consumption == "N") {
        frmData = {
          ...addPurchaseForm,
          items: itemsLists,
          status: status,
          vendor_address_id: this.state.selectedVendorAdress
            ? this.state.selectedVendorAdress.id
            : "",
          update_item_purchase_price: this.state.update_item_purchase_price,
          gst_not_applicable: this.state.gst_not_applicable,
        };
      } else {
        frmData = {
          ...addPurchaseForm,
          ...this.state.consumptionForm,
          items: itemsLists,
          status: status,
          update_item_purchase_price: this.state.update_item_purchase_price,
          update_item_consumption_price:
            this.state.update_item_consumption_price,
          vendor_address_id: this.state.selectedVendorAdress
            ? this.state.selectedVendorAdress.id
            : "",
          gst_not_applicable: this.state.gst_not_applicable,
          employee_id:
            this.state.consumptionForm.consumption_type == "employee" &&
              this.state.searchedEmployee
              ? this.state.searchedEmployee.value
              : "",
        };
      }

      let additional_attributes = {};
      if (
        this.state.additionalAttributes &&
        this.state.additionalAttributes.length > 0
      ) {
        this.state.additionalAttributes.forEach((data, i) => {
          let name = "additional_attributes_" + data.key;
          additional_attributes[data.key] = this.state.addPurchaseForm[name]
            ? this.state.addPurchaseForm[name]
            : "";
        });
      }

      frmData = {
        ...frmData,
        update_item_consumption_price: this.state.update_item_consumption_price,
        additional_attributes,
      };

      this.setState({ saveFormSubmitting: true });

      if (this.state.purchaseData) {
        HttpAPICall.withAthorization(
          "PUT",
          this.purchaseUrl + "/edit",
          this.props.access_token,
          {},
          { ...frmData },
          (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.props.history.push({
              pathname: `/purchase_list`,
              state: { purchaseId: this.state.addPurchaseForm.transaction_id },
            });
          }
        ).then(() => this.setState({ saveFormSubmitting: false }));
      } else {
        HttpAPICall.withAthorization(
          "POST",
          this.purchaseUrl + "/add",
          this.props.access_token,
          {},
          { ...frmData },
          (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.props.history.push({
              pathname: `/purchase_list`,
              state: { purchaseId: response.data.transaction_id },
            });
          }
        ).then(() => this.setState({ saveFormSubmitting: false }));
      }
    }
  };

  //**********************ADD PURCHASE FORM JSX*******************/

  savePurchaseFormJsx = () => {
    let {
      searchSubassembly,
      searchedEmployee,
      searchedWorkstation,
      selectedAsset,
      consumptionForm,
    } = { ...this.state };
    let wallet_permission = this.props.permissions.group_sub_modules.find(
      (s) => s.sub_module == "iam-wallet"
    );
    let sub_total_amount = 0;
    let gst_total_amount = 0;
    let gst_slab = [];
    let total_amount = 0;
    let purchase_summary = [];
    let adjustment_amount = parseFloat(
      this.state.addPurchaseForm.adjustment_amount
    );

    let disableVendor = this.state.allAllocatedItemIds.some(
      (item) => item.po_item_id
    );
    this.state.purchaseItemRows.forEach((item, key) => {
      if (item && item.item) {
        let item_amount = parseFloat(item.qty * item.rate);
        let discount_amout = parseFloat(
          item.discount_rate && item.discount_rate > 0
            ? item.discount_type == "Percentage"
              ? (item_amount * item.discount_rate) / 100
              : item.discount_rate
            : 0
        );
        if (discount_amout > item_amount) {
          toast.error("Discount cannot be greater then item amount", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          let item_with_discount = item_amount - discount_amout;
          let tax_amount = 0;
          if (
            item.gst &&
            item.gst > 0 &&
            this.state.warehouse_state_id &&
            this.state.selectedVendorAdress &&
            this.state.selectedVendorAdress.gstin
          ) {
            tax_amount = (item_with_discount * item.gst) / 100;
            if (!gst_slab[item.gst]) {
              gst_slab[item.gst] = [];
            }
            gst_slab[item.gst].push(tax_amount);
          }
          let item_final_amount = item_with_discount + tax_amount;
          sub_total_amount += item_with_discount;
          gst_total_amount += tax_amount;
          total_amount += item_final_amount;
        }
      }
    });

    if (gst_total_amount >= 0) {
      purchase_summary.push({
        key: "Sub Total",
        value: sub_total_amount.toFixed(2),
      });
      gst_slab.forEach((tax_amounts, gst) => {
        let total_tax = tax_amounts.reduce((sum, a) => sum + a, 0);
        if (
          this.state.selectedVendorAdress &&
          this.state.warehouseDetail &&
          this.state.selectedVendorAdress.state_id !==
          this.state.warehouseDetail.state_id
        ) {
          purchase_summary.push({
            key: "IGST " + gst + "%",
            value: total_tax.toFixed(2),
          });
        } else {
          let tax = gst / 2;
          purchase_summary.push({
            key: "CGST " + tax + "%",
            value: (total_tax / 2).toFixed(2),
          });
          purchase_summary.push({
            key: "SGST " + tax + "%",
            value: (total_tax / 2).toFixed(2),
          });
        }
      });
    }

    let tcs_amount = Number(
      this.state.addPurchaseForm.tcs_rate &&
        this.state.addPurchaseForm.tcs_rate >= 0
        ? this.state.addPurchaseForm.tcs_type == "Percentage"
          ? (sub_total_amount * this.state.addPurchaseForm.tcs_rate) / 100
          : Number(this.state.addPurchaseForm.tcs_rate)
        : 0
    );
    // purchase_summary.push({key : 'Total Amount' , value : total_amount});
    let transactionDate =
      this.state.addPurchaseForm && this.state.addPurchaseForm.transaction_date
        ? moment(this.state.addPurchaseForm.transaction_date).format(
          "YYYY-MM-DD 00:00:00"
        )
        : null;

    return (
      <form className="bg-white p-3" onSubmit={this.addPurchaseFormSubmitEvent}>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label require">
              Date of Transaction
            </label>
          </div>
          <div className="col-sm-4 add_calender_section">
            <DatePicker
              selected={
                this.state.addPurchaseForm.transaction_date
                  ? moment(
                    this.state.addPurchaseForm.transaction_date,
                    "YYYY-MM-DD"
                  ).toDate()
                  : false
              }
              name="transaction_date"
              onChange={(value, event) =>
                this.formDateHandler(
                  "transaction_date",
                  value,
                  "addPurchaseForm"
                )
              }
              maxDate={moment(new Date(), "YYYY-MM-DD").toDate()}
              dateFormat="dd-MMM-yyyy"
              className={"form-control"}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
              scrollMonthYearDropdown
              required={true}
              disabled={
                this.state.purchaseData &&
                  this.state.purchaseData.can_edit == "N"
                  ? true
                  : false
              }
              placeholderText={`Please Enter Date of Transaction`}
            />

            <tapIcon.imageIcon
              icon={tapIcon.CalenderIcon}
              alt="purchase_date"
              className="add_calender-icon"
            />
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label require">
              Warehouse Name
            </label>
          </div>
          <div className="col-sm-7">
            <TapSelect
              changeEvent={(selectedOption) => {
                this.tapSelectChange(
                  selectedOption,
                  "addPurchaseForm",
                  "warehouse_id"
                );
                this.getWarehouseDetails(selectedOption);
              }}
              options={this.state.all_warehouses}
              isSearchable={true}
              isClearable={true}
              required={true}
              isDisabled={
                this.state.editPurchase == true || disableVendor == true
                  ? true
                  : false
              }
              value={this.state.all_warehouses.find(
                (m) => m.value == this.state.addPurchaseForm.warehouse_id
              )}
              placeholder="Please Select Warehouse"
            />
          </div>
        </div>
        {this.state.warehouseDetail ? (
          <div className="row align-items-center mt1 pb-2">
            <div className="offset-3 col-sm-8">
              <label className="form-label">
                Address :{" "}
                {this.state.warehouseDetail.address1
                  ? this.state.warehouseDetail.address1 + ", "
                  : ""}
                {this.state.warehouseDetail.address2
                  ? this.state.warehouseDetail.address2 + ", "
                  : ""}
                {this.state.warehouseDetail.landmark
                  ? this.state.warehouseDetail.landmark + ", "
                  : ""}
                {this.state.warehouseDetail.city
                  ? this.state.warehouseDetail.city + ". "
                  : "-"}
              </label>
              <br />
              <label className="form-label">
                Destination of Supply :{" "}
                {this.state.warehouseDetail &&
                  this.state.warehouseDetail.state ? (
                  <span>{this.state.warehouseDetail.state.name}</span>
                ) : (
                  "-"
                )}
              </label>{" "}
              <br />
              <label className="form-label">
                GSTIN :{" "}
                {this.state.warehouseDetail &&
                  this.state.warehouseDetail.gstin ? (
                  <span>{this.state.warehouseDetail.gstin}</span>
                ) : (
                  "-"
                )}
              </label>
            </div>
          </div>
        ) : null}

        <Vendor
          vendorDetails={this.state.vendorDetails}
          details_loading={this.state.details_loading}
          vendorAddressList={this.state.vendorAddressList}
          vendor_details_loading={this.state.vendor_details_loading}
          selectedVendorAdress={this.state.selectedVendorAdress}
          searchedVendor={this.state.searchedVendor}
          onChange={this.handleVendorChange}
          getVendorDetails={this.getVendorDetails}
          disabled={
            disableVendor == true && this.state.editPurchase == true
              ? true
              : false
          }
          vendorRef={this.addVendorModalRef}
          changeAddressHandler={this.changeAddressHandler}
        />
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label require ">
              Status
            </label>
          </div>
          <div className="col-sm-4">
            <TapSelect
              changeEvent={(selectedOption) => {
                this.tapSelectChange(
                  selectedOption,
                  "addPurchaseForm",
                  "status"
                );
              }}
              options={this.state.allStatusList}
              isSearchable={true}
              isClearable={true}
              required={true}
              isDisabled={this.state.editPurchase == true ? true : false}
              value={this.state.allStatusList.find(
                (s) => s.value == this.state.addPurchaseForm.status
              )}
              placeholder="Select Status"
            />
          </div>
        </div>
        <div className="row align-items-center mb-3 mt-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label ">
              Bill Number & Date
            </label>
          </div>
          <div className="col-sm-4">
            <input
              name="purchase_bill_number"
              type="text"
              className="form-control"
              autoComplete="off"
              value={this.state.addPurchaseForm.purchase_bill_number}
              onChange={(e) => this.formInputHandler(e, "addPurchaseForm")}
              placeholder="Enter Bill Number"
            />
          </div>
          <div className="col-sm-4 add_calender_section">
            <DatePicker
              selected={
                this.state.addPurchaseForm.purchase_bill_date
                  ? moment(
                    this.state.addPurchaseForm.purchase_bill_date,
                    "YYYY-MM-DD"
                  ).toDate()
                  : false
              }
              name="purchase_bill_date"
              onChange={(value, event) =>
                this.formDateHandler(
                  "purchase_bill_date",
                  value,
                  "addPurchaseForm"
                )
              }
              maxDate={moment(new Date(), "YYYY-MM-DD").toDate()}
              dateFormat="dd-MMM-yyyy"
              className={"form-control"}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
              scrollMonthYearDropdown
              placeholderText={`Please Enter Bill Date`}
            />

            <tapIcon.imageIcon
              icon={tapIcon.CalenderIcon}
              alt="purchase_date"
              className="add_calender-icon"
            />
          </div>
        </div>
        <div className="row align-items-center mb-3 mt-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label">
              Eway Number & Date
            </label>
          </div>
          <div className="col-sm-4">
            <input
              name="e_way_bill_number"
              type="text"
              className="form-control"
              autoComplete="off"
              value={this.state.addPurchaseForm.e_way_bill_number}
              onChange={(e) => this.formInputHandler(e, "addPurchaseForm")}
              placeholder="Enter Eway Number"
            />
          </div>
          <div className="col-sm-4 add_calender_section">
            <DatePicker
              selected={
                this.state.addPurchaseForm.e_way_bill_date
                  ? moment(
                    this.state.addPurchaseForm.e_way_bill_date,
                    "YYYY-MM-DD"
                  ).toDate()
                  : false
              }
              name="purchase_bill_date"
              onChange={(value, event) =>
                this.formDateHandler(
                  "e_way_bill_date",
                  value,
                  "addPurchaseForm"
                )
              }
              maxDate={moment(new Date(), "YYYY-MM-DD").toDate()}
              dateFormat="dd-MMM-yyyy"
              className={"form-control"}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
              scrollMonthYearDropdown
              placeholderText={`Please Enter Eway Date`}
            />

            <tapIcon.imageIcon
              icon={tapIcon.CalenderIcon}
              alt="purchase_date"
              className="add_calender-icon"
            />
          </div>
        </div>
        {this.state.multiple_currency_available == "Y" && (
          <div className="row align-items-center mb-3 mt-3">
            <div className="col-3">
              <label htmlFor="site_id" className="form-label ">
                Currency
              </label>
            </div>
            <div className={"col-4"}>
              <TapSelect
                placeholder="Currency"
                className="form-control "
                changeEvent={(selectedOption) => {
                  this.tapSelectChange(
                    selectedOption,
                    "addPurchaseForm",
                    "currency",
                    () => {
                      this.convertCurrency(selectedOption);
                    }
                  );
                }}
                options={this.state.allCurrencyList}
                value={this.state.allCurrencyList.find(
                  (m) => m.value == this.state.addPurchaseForm.currency
                )}
                isDisabled={this.state.disableCurrency == true ? true : false}
              />
            </div>
            {this.state.addPurchaseForm.currency ==
              this.state.defaultCurrency ? null : (
              <Ax>
                {this.state.currency_loading ? (
                  <div className="col-4 ">
                    {" "}
                    <Loader />
                  </div>
                ) : (
                  <div className="col-4 d-flex justify-content-between">
                    <Ax>
                      <div>
                        <input
                          placeholder="Currency"
                          className="form-control"
                          name="conversion_rate"
                          value={`1 ${this.state.addPurchaseForm.currency} = `}
                          disabled="true"
                        />
                      </div>
                      <div className="text-end">
                        <input
                          placeholder="Currency Ratio"
                          type="number"
                          min="0"
                          autoComplete="off"
                          className="form-control"
                          name="conversion_rate"
                          // disabled={this.state.disableCurrency == true ? true : false}
                          value={this.state.addPurchaseForm.conversion_rate}
                          onChange={(e) =>
                            this.formInputHandler(e, "addPurchaseForm")
                          }
                        />
                      </div>
                      <div>
                        <input
                          placeholder="Currency"
                          className="form-control"
                          name="conversion_rate"
                          value={this.state.defaultCurrency}
                          disabled="true"
                        />
                      </div>
                    </Ax>
                  </div>
                )}
              </Ax>
            )}
          </div>
        )}
        {wallet_permission ? (
          <div className="row align-items-center my-3 mt-3">
            <div className="col-sm-3">
              <label htmlFor="site_id" className="form-label">
                Paid from Wallet
              </label>
            </div>
            <div className="col-sm-4">
              <TapSelect
                menuPlacement="top"
                changeEvent={(selectedOption) => {
                  this.tapSelectChange(
                    selectedOption,
                    "addPurchaseForm",
                    "wallet_id"
                  );
                }}
                options={this.state.allWalletList}
                isSearchable={true}
                isClearable={true}
                value={this.state.allWalletList.find(
                  (m) => m.value == this.state.addPurchaseForm.wallet_id
                )}
                placeholder="Please Select Wallet"
              />
            </div>
          </div>
        ) : null}
        <div className="row align-items-center mb-3 ">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label">
              PO Number & Date <br />
            </label>
          </div>
          <div className="col-sm-3">
            <a
              role="button"
              className="link-primary"
              onClick={this.pickPOItemModalInit}
            >
              <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Pick Item from
              Purchase Order{" "}
            </a>
          </div>
          <div className="col-sm-4">
            <div className="form-check form-check-inline">
              <input
                name="include_req_pending_items"
                type="checkbox"
                value={this.state.include_req_pending_items == "Y" ? "N" : "Y"}
                onChange={(e) => {
                  this.setState({
                    include_req_pending_items: e.target.value,
                    pendingPOListLoaded: false,
                  });
                }}
                className="form-check-input"
                id="include_req_pending_items"
                checked={this.state.include_req_pending_items == "Y"}
              />
              <label
                className="form-check-label"
                htmlFor="iinclude_req_pending_items"
              >
                Include Approved Requisitions
              </label>
            </div>
          </div>
        </div>
        {this.state.additionalAttributes &&
          this.state.additionalAttributes.length > 0 && (
            <AdditionalAttributeForm
              additionalAttributes={this.state.additionalAttributes}
              formState={this.state.addPurchaseForm}
              onAttributeChange={(value, attributeName) => {
                this.setState(() => ({
                  addPurchaseForm: {
                    ...this.state.addPurchaseForm,
                    [attributeName]: value,
                  },
                }));
              }}
            />
          )}

        {this.purchaseItemJsx()}
        <div className="row align-items-center ">
          <div className="col-sm-6">
            <input
              name="update_item_purchase_price"
              type="checkbox"
              value={this.state.update_item_purchase_price}
              onChange={(e) => {
                this.setState({
                  update_item_purchase_price:
                    this.state.update_item_purchase_price == "N" ? "Y" : "N",
                });
              }}
              checked={this.state.update_item_purchase_price == "Y"}
              className="form-check-input"
              id="update_item_purchase_price"
            />
            <label
              className="form-check-label mx-2 text-sm"
              htmlFor="update_item_purchase_price"
            >
              Update Item Purchase Price{" "}
            </label>
            <br />
          </div>
        </div>

        <div className="col-sm-12">
          <input
            name="update_item_consumption_price"
            type="checkbox"
            value={this.state.update_item_consumption_price}
            onChange={(e) => {
              this.setState({
                update_item_consumption_price:
                  this.state.update_item_consumption_price == "N" ? "Y" : "N",
              });
            }}
            checked={this.state.update_item_consumption_price == "Y"}
            className="form-check-input"
            id="update_item_consumption_price"
          />
          <label
            className="form-check-label mx-2 text-sm"
            htmlFor="update_item_consumption_price"
          >
            Update Item Consumption Price{" "}
          </label>
          <br />
        </div>

        <div className="row align-items-center mb-3">
          <div className="col-sm-6">
            <label htmlFor="notes" className="form-label">
              Notes
            </label>
            <textarea
              name="notes"
              value={this.state.addPurchaseForm.notes}
              onChange={(e) => this.formInputHandler(e, "addPurchaseForm")}
              className="form-control"
              style={{ height: "100px" }}
            />
          </div>
          <div className="col-sm-6">
            <label htmlFor="exampleInputEmail1" className="form-label">
              <p> &nbsp; </p>
            </label>
            <table className="table  table-borderless">
              <tbody>
                {purchase_summary && purchase_summary.length > 0
                  ? purchase_summary.map((ps, k) => {
                    return (
                      <tr className="subtotal_bg_color">
                        <th>
                          {ps.key}{" "}
                          {ps.key == "Sub Total" && (
                            <span>
                              ({this.state.addPurchaseForm.currency})
                            </span>
                          )}{" "}
                        </th>
                        <td className="text-end">{ps.value}</td>
                      </tr>
                    );
                  })
                  : null}
                {this.state.selectedVendorAdress &&
                  this.state.selectedVendorAdress.gstin ? null : (
                  <tr className="subtotal_bg_color">
                    <th>GST</th>
                    <td className="float-end">Not Applicable</td>
                  </tr>
                )}
                {this.state.isc_enable_tcs == "Y" && (
                  <tr className="subtotal_bg_color">
                    <th>
                      <div className="row align-items-center">
                        <div className="col-sm-4">
                          <input
                            name="tcs_applicable"
                            type="checkbox"
                            value={this.state.tcs_applicable}
                            onChange={(e) => {
                              this.setState(
                                {
                                  tcs_applicable:
                                    this.state.tcs_applicable == "N"
                                      ? "Y"
                                      : "N",
                                },
                                () => {
                                  if (this.state.tcs_applicable == "N") {
                                    this.setState({
                                      addPurchaseForm: {
                                        ...this.state.addPurchaseForm,
                                        tcs_rate: 0,
                                      },
                                    });
                                  }
                                }
                              );
                            }}
                            checked={this.state.tcs_applicable == "Y"}
                            className="form-check-input"
                            id="tcs_applicable"
                          />
                          <label
                            className="form-check-label mx-1 "
                            htmlFor="tcs_applicable"
                          >
                            Enable TCS
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <TapSelect
                            changeEvent={(selectedOption, e) => {
                              this.tapSelectChange(
                                selectedOption,
                                "addPurchaseForm",
                                "tcs_type"
                              );
                            }}
                            options={this.state.allTCSType}
                            isSearchable={false}
                            value={this.state.allTCSType.find(
                              (s) =>
                                s.value == this.state.addPurchaseForm.tcs_type
                            )}
                            placeholder="TCS"
                            disabled={this.state.tcs_applicable == "N"}
                          />
                        </div>
                        <div className="col-sm-5">
                          <input
                            name="tcs_rate"
                            type="number"
                            value={this.state.addPurchaseForm.tcs_rate}
                            onChange={(e) =>
                              this.formInputHandler(e, "addPurchaseForm")
                            }
                            className="form-control text-end"
                            autoComplete="off"
                            placeholder="TCS Amount"
                            disabled={this.state.tcs_applicable == "N"}
                          />
                        </div>
                      </div>
                    </th>
                    <td className="float-end" style={{ lineHeight: 3 }}>
                      {" "}
                      {tcs_amount ? (
                        <NumberFormatter number={tcs_amount} precision={2} />
                      ) : (
                        <NumberFormatter number={tcs_amount} precision={2} />
                      )}
                    </td>
                  </tr>
                )}
                <tr className="subtotal_bg_color">
                  <th>Adjustment</th>
                  <td>
                    <input
                      name="adjustment_amount"
                      type="number"
                      value={this.state.addPurchaseForm.adjustment_amount}
                      onChange={(e) =>
                        this.formInputHandler(e, "addPurchaseForm")
                      }
                      className="form-control text-end"
                      autoComplete="off"
                      placeholder="Adjustment"
                    />
                  </td>
                </tr>

                <tr className="subtotal_bg_color">
                  <th>Final Total ({this.state.addPurchaseForm.currency})</th>
                  <td className="float-end">
                    {adjustment_amount
                      ? parseFloat(
                        total_amount + adjustment_amount + tcs_amount
                      ).toFixed(2)
                      : parseFloat(total_amount + tcs_amount).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {this.state.purchaseData ? null : (
          <div className="row align-items-center mb-3">
            <div className="col-sm-12">
              <div className="form-check form-check-inline">
                <input
                  name="include_consumption"
                  type="checkbox"
                  value="Y"
                  onChange={(e) => {
                    this.onSelectionConsumption(e, "include_consumption");
                  }}
                  className="form-check-input"
                  id="include_consumption"
                  checked={
                    this.state.addPurchaseForm.include_consumption == "Y"
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="include_consumption"
                >
                  Record consumption for this purchase
                </label>
              </div>
            </div>
          </div>
        )}
        {this.state.consumptionFormLoading ? (
          <Loader />
        ) : this.state.addPurchaseForm.include_consumption == "Y" ? (
          <Ax>
            <div className="tab_content_wrapper">
              <span className="content_heading">Consumption Details</span>
            </div>

            <div className="row align-items-center my-3">
              <div className="col-sm-3">
                <label className="form-label require">Consumption For</label>
              </div>
              <div className="col-sm-4">
                <TapSelect
                  changeEvent={(selectedOption) => {
                    this.tapSelectChange(
                      selectedOption,
                      "consumptionForm",
                      "consumption_type",
                      () => {
                        this.setState({
                          selectedAsset: null,
                          searchedWorkstation: null,
                          searchedEmployee: null,
                          searchSubassembly: null,

                          consumptionForm: {
                            ...this.state.consumptionForm,
                            workstation_id: "",
                            asset_id: "",
                            employee_id: "",
                            sub_assembly_id: "",
                          },
                        });
                      }
                    );
                  }}
                  options={this.state.allConsumptionFor}
                  isSearchable={true}
                  isClearable={true}
                  required={true}
                  // isDisabled={this.state.consumptionForm.ticket_id}
                  value={this.state.allConsumptionFor.find(
                    (s) =>
                      s.value == this.state.consumptionForm.consumption_type
                  )}
                  placeholder="Select Consumption For"
                />
              </div>
            </div>
            <>
              {this.state.consumptionForm.consumption_type === "asset" && (
                <div className="row align-items-center my-3">
                  <div className="col-sm-3">
                    <label className="form-label require">Asset</label>
                  </div>
                  <div className="col-sm-7">
                    <InputAssetSearch
                      changeEvent={(selectedOption) => {
                        this.tapSelectChange(
                          selectedOption,
                          "consumptionForm",
                          "asset_id"
                        );
                        this.setState({
                          selectedAsset: selectedOption
                            ? {
                              ...selectedOption.asset,
                              label: selectedOption.display_label,
                            }
                            : null,
                        });
                      }}
                      placeholder="Search Asset"
                      search_site_id={this.state.warehouseSites.map(
                        (w) => w.id
                      )}
                      filter={true}
                      required={true}
                      value={selectedAsset ? { ...selectedAsset } : null}
                    />
                  </div>
                </div>
              )}

              {selectedAsset && (
                <div className="row align-items-center mt-1">
                  <div className="offset-3 col-sm-8">
                    <AssetMiniDetail assetDetail={selectedAsset} />
                  </div>
                </div>
              )}

              {this.state.consumptionForm.consumption_type ===
                "workstation" && (
                  <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                      <label
                        htmlFor="workstation_id"
                        className="form-label require"
                      >
                        Workstation
                      </label>
                    </div>
                    <div className="col-sm-7">
                      <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                          this.setState({
                            searchedWorkstation: selectedOption
                              ? selectedOption
                              : null,
                            consumptionForm: {
                              ...consumptionForm,
                              workstation_id: selectedOption
                                ? selectedOption.value
                                : "",
                            },
                          });
                        }}
                        menuPlacement="top"
                        search_site_id={this.state.warehouseSites.map(
                          (wk) => wk.id
                        )}
                        value={searchedWorkstation ? searchedWorkstation : null}
                      />
                    </div>
                  </div>
                )}

              {this.props.permissions.group_modules.includes("hrm") &&
                this.state.consumptionForm.consumption_type === "employee" && (
                  <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                      <label htmlFor="voucher_number" className="form-label">
                        Employee
                      </label>
                    </div>
                    <div className="col-sm-7">
                      <InputEmployeeSearch
                        placeholder="Please Select Employee"
                        search_site_ids={this.state.warehouseSites.map(
                          (w) => w.id
                        )}
                        changeEvent={(selectedOption) => {
                          this.setState({
                            searchedEmployee: selectedOption
                              ? selectedOption
                              : null,
                          });
                        }}
                        transaction_date={
                          transactionDate ? transactionDate : null
                        }
                        value={searchedEmployee ? searchedEmployee : null}
                      />
                    </div>
                  </div>
                )}

              {this.state.consumptionForm.consumption_type ===
                "sub_assembly" && (
                  <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                      <label
                        htmlFor="sub_assembly_id"
                        className="form-label require"
                      >
                        Sub Assembly
                      </label>
                    </div>
                    <div className="col-sm-7">
                      <InputSubAssemblySearch
                        placeholder="Search Sub Assembly"
                        search_site_id={this.state.warehouseSites.map(
                          (w) => w.id
                        )}
                        changeEvent={(selectedOption) => {
                          this.tapSelectChange(
                            selectedOption,
                            "consumptionForm",
                            "sub_assembly_id"
                          );
                          this.setState({
                            searchSubassembly: selectedOption
                              ? selectedOption
                              : null,
                          });
                        }}
                        required={true}
                        menuPlacement="top"
                        isMulti={false}
                        filter={true}
                        value={searchSubassembly ? searchSubassembly : null}
                      />
                    </div>
                  </div>
                )}
            </>
            <div className="row align-items-center my-3">
              <div className="col-sm-3">
                <label htmlFor="site_id" className="form-label">
                  Voucher Number & Date
                </label>
              </div>

              <div className="col-sm-4">
                <input
                  name="voucher_number"
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  value={this.state.consumptionForm.voucher_number}
                  onChange={(e) => this.formInputHandler(e, "consumptionForm")}
                  placeholder="Enter Voucher Number"
                />
              </div>
              <div className="col-sm-4 add_calender_section">
                <DatePicker
                  selected={
                    this.state.consumptionForm.voucher_date
                      ? moment(
                        this.state.consumptionForm.voucher_date,
                        "YYYY-MM-DD"
                      ).toDate()
                      : false
                  }
                  name="voucher_date"
                  onChange={(value, event) =>
                    this.formDateHandler(
                      "voucher_date",
                      value,
                      "consumptionForm"
                    )
                  }
                  maxDate={moment(new Date(), "YYYY-MM-DD").toDate()}
                  dateFormat="dd-MMM-yyyy"
                  className={"form-control"}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                  scrollMonthYearDropdown
                  placeholderText={`Enter Voucher Date`}
                />
                <tapIcon.imageIcon
                  icon={tapIcon.CalenderIcon}
                  alt="consumption_date"
                  className="add_calender-icon"
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-sm-3">
                <label className="form-label require">Consumption Price</label>
              </div>
              <div className="col-sm-5">
                <div className="form-check">
                  <input
                    type="radio"
                    name="consumption_price_as"
                    required={true}
                    className="form-check-input"
                    value="purchase_price"
                    onChange={(e) =>
                      this.formInputHandler(e, "consumptionForm")
                    }
                    id="price_as_purchase_price"
                    checked={
                      this.state.consumptionForm.consumption_price_as ==
                      "purchase_price"
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="price_as_purchase_price"
                  >
                    Consumption Price = Purchase Price
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    name="consumption_price_as"
                    required={true}
                    className="form-check-input"
                    value="purchase_price_gst"
                    onChange={(e) =>
                      this.formInputHandler(e, "consumptionForm")
                    }
                    id="price_as_purchase_price_gst"
                    checked={
                      this.state.consumptionForm.consumption_price_as ==
                      "purchase_price_gst"
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="price_as_purchase_price_gst"
                  >
                    Consumption Price = Purchase Price + GST
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    name="consumption_price_as"
                    required={true}
                    className="form-check-input"
                    value="item_consumption_price"
                    onChange={(e) =>
                      this.formInputHandler(e, "consumptionForm")
                    }
                    id="price_as_item_consumption_price"
                    checked={
                      this.state.consumptionForm.consumption_price_as ==
                      "item_consumption_price"
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="price_as_item_consumption_price"
                  >
                    {" "}
                    Consumption Price = Item Consumption Price{" "}
                  </label>
                </div>
              </div>
            </div>
          </Ax>
        ) : null}

        <div className="col-12 text-end fixed_footer">
          <button
            type="button"
            disabled={this.state.saveFormSubmitting}
            onClick={this.closeEvent}
            className="btn btn-secondary ms-2"
          >
            Cancel
          </button>
          {this.state.purchaseData && this.state.status == 31 ? null : (
            <button
              type="button"
              disabled={this.state.saveFormSubmitting}
              className="btn btn-secondary ms-2"
              onClick={(e) => {
                this.addPurchaseFormSubmitEvent(e, 1);
              }}
            >
              Save as a Draft{" "}
              {this.state.saveFormSubmitting ? (
                <tapIcon.FontAwesomeIcon
                  icon={tapIcon.faSyncAlt}
                  className="fa-spin"
                />
              ) : (
                ""
              )}
            </button>
          )}
          <button
            type="button"
            disabled={this.state.saveFormSubmitting}
            className="btn btn-primary ms-2"
            onClick={(e) => {
              this.addPurchaseFormSubmitEvent(
                e,
                this.state.addPurchaseForm.status
              );
            }}
          >
            Save{" "}
            {this.state.saveFormSubmitting ? (
              <tapIcon.FontAwesomeIcon
                icon={tapIcon.faSyncAlt}
                className="fa-spin"
              />
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };

  purchaseItemJsx = () => {
    //   <div className="form-text">{be.batch_qty > 0 ? batchIndex+1 : null} {be.batch_number && <span><b>Batch Number: </b> {be.batch_number}, </span>} {be.expiry_date && <span><b>Expiry Date: </b> {be.expiry_date}, </span>} {be.batch_qty && <span><b>{!be.batch_number && !be.expiry_date ? "Qty Without Batch Number & Expiry" : "Qty"}: </b> {be.batch_qty??0}, </span>}</div>
    return (
      <div className=" my-2 row">
        <div className="col-12">
          <table className="table table-bordered table-responsive bg-white ">
            <thead className="table-secondary">
              <tr>
                <th scope="col" style={{ width: "5%" }}>
                  S.No
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ width: "33%" }}
                >
                  Item Details
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ width: "12%" }}
                >
                  Allocation
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ width: "10%" }}
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ width: "10%" }}
                >
                  Unit Rate
                </th>
                <th scope="col" className="text-center" style={{ width: "9%" }}>
                  Discount
                </th>
                <th scope="col" className="text-center" style={{ width: "9%" }}>
                  GST
                </th>
                <th scope="col" className="text-center" style={{ width: "9%" }}>
                  Amount
                </th>
                <th scope="col" className="" style={{ width: "5%" }}></th>
              </tr>
            </thead>
            <tbody>
              {this.state.purchaseItemRows.map((i, itemKey) => {
                let item_with_discount = 0;
                let item_final_amount = 0;
                if (i.item) {
                  let item_amount = parseFloat(i.qty * i.rate);
                  let discount_amout = parseFloat(
                    i.discount_rate && i.discount_rate > 0
                      ? i.discount_type == "Percentage"
                        ? (item_amount * i.discount_rate) / 100
                        : i.discount_rate
                      : 0
                  );
                  item_with_discount = parseFloat(
                    item_amount - discount_amout
                  ).toFixed(2);
                }

                let tdRows = i.item ? (
                  <Ax>
                    <td className="text-start">
                      <div className="">
                        <div className="row">
                          <div className="col-sm-10">
                            <span
                              className="fw-bold link-primary cursor_pointer"
                              onClick={() => {
                                this.itemViewModalInit(i.item);
                                this.viewItemModalInit();
                              }}
                            >
                              {i.item.name}
                            </span>
                          </div>
                          <div className="col-sm-2 text-end">
                            <tapIcon.imageIcon
                              icon={tapIcon.ListActionIcon}
                              alt="action-icon"
                              className="img-fluid pr10 pl10"
                              data-bs-toggle="dropdown"
                              href="#"
                              role="button"
                              aria-expanded="false"
                            />
                            <ul className="dropdown-menu cursor_pointer">
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  this.itemViewModalInit(i.item, itemKey);
                                  this.viewItemModalInit();
                                }}
                              >
                                View Details
                              </li>
                              {this.props?.permissions?.permissions?.includes(
                                "isc-item-edit"
                              ) && (
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      this.editItemModalInit(i.item, itemKey);
                                    }}
                                  >
                                    Edit Item
                                  </li>
                                )}
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  this.addRemark(i.item, itemKey);
                                }}
                              >
                                Add Remark
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="form-text">
                          <b>Code: </b>
                          {i.item.item_code}
                        </div>
                        <div className="form-text">
                          <b>Mfr: </b>
                          {i.item?.manufacturer?.name ?? "-"} (MPN :{" "}
                          {i.item?.manufacturer_part_no ?? "-"} )
                        </div>
                        {this.state.isc_configuration?.track_batch_expiry ==
                          "Y" && (
                            <div>
                              {i &&
                                i.batch_list &&
                                i.batch_list.length > 0 &&
                                i.batch_list.some(
                                  (item) => item.batch_qty > 0
                                ) && (
                                  <table className="table table-bordered bg-white my-2 table-sm ">
                                    <tbody>
                                      <tr>
                                        <td scope="col" style={{ width: "5%" }}>
                                          S.No
                                        </td>
                                        <td
                                          scope="col"
                                          className="text-center"
                                          style={{ width: "40%" }}
                                        >
                                          Batch No
                                        </td>
                                        <td
                                          scope="col"
                                          className="text-center"
                                          style={{ width: "40%" }}
                                        >
                                          Expiry Date
                                        </td>
                                        <td
                                          scope="col"
                                          className="text-center"
                                          style={{ width: "15%" }}
                                        >
                                          Qty
                                        </td>
                                      </tr>
                                      {i &&
                                        i.batch_list &&
                                        i.batch_list.length > 0 &&
                                        i.batch_list.map((be, batchIndex) => {
                                          return (
                                            <tr>
                                              <td className="text-center">
                                                {batchIndex + 1}
                                              </td>
                                              <td>
                                                {be.batch_number
                                                  ? be.batch_number
                                                  : ""}
                                              </td>
                                              <td>
                                                {be.expiry_date_display
                                                  ? be.expiry_date_display
                                                  : ""}
                                              </td>
                                              <td className="text-center">
                                                {be.batch_qty ? be.batch_qty : ""}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                )}
                            </div>
                          )}
                      </div>
                      {i.item &&
                        i.item.isAddRemark &&
                        i.item.isAddRemark == true ? (
                        <input
                          name="remark"
                          type="text"
                          value={this.state.purchaseItemRows[itemKey].remark}
                          onChange={(e) =>
                            this.dynamicInputHandlerByKeyNameVal(
                              e.target.value,
                              "remark",
                              itemKey,
                              "purchaseItemRows"
                            )
                          }
                          className="form-control mt10"
                          autoComplete="off"
                          placeholder="Item Remarks"
                        />
                      ) : null}
                    </td>
                    {this.state.purchaseItemRows[itemKey]
                      .req_allocation_loading ? (
                      <td colSpan="6">
                        <Loader />
                      </td>
                    ) : (
                      <Ax>
                        <td className="text-center">
                          <button
                            type="button"
                            onClick={() => this.requistionDetailInit(itemKey)}
                            className="btn btn-link text-decoration-none"
                            disabled={
                              this.state.purchaseItemRows[itemKey]
                                ?.req_allocation?.length == 0
                            }
                          >
                            + Allocate
                          </button>

                          {this.state.purchaseItemRows[itemKey]?.req_allocation
                            ?.length > 0 &&
                            this.state.purchaseItemRows[itemKey].unallocated_qty >
                            0 &&
                            this.state.purchaseItemRows[itemKey]
                              ?.selected_allocation == null ? (
                            <div className="text-center my-1">
                              UnAllocated :{" "}
                              {
                                this.state.purchaseItemRows[itemKey]
                                  .unallocated_qty
                              }
                            </div>
                          ) : this.state.purchaseItemRows[itemKey]
                            .selected_allocation &&
                            this.state.purchaseItemRows[itemKey]
                              .selected_allocation.qty > 0 ? (
                            <div className="text-center my-1">
                              {" "}
                              <span>
                                {this.state.purchaseItemRows[itemKey]
                                  .selected_allocation.po_id
                                  ? this.state.purchaseItemRows[itemKey]
                                    .selected_allocation.po_id
                                  : this.state.purchaseItemRows[itemKey]
                                    .selected_allocation.req_id}{" "}
                                :{" "}
                                {
                                  this.state.purchaseItemRows[itemKey]
                                    .selected_allocation.qty
                                }
                              </span>{" "}
                            </div>
                          ) : null}
                        </td>
                        <td className="pt0">
                          <TapInputNumber
                            name="qty"
                            value={this.state.purchaseItemRows[itemKey].qty}
                            onChange={(e) => {
                              const newQty = parseFloat(e);
                              const { purchaseItemRows } = this.state;
                            
                              if (
                                itemKey !== undefined &&
                                itemKey !== null &&
                                itemKey >= 0 &&
                                itemKey < purchaseItemRows.length
                              ) {
                                const purchaseItemRow = purchaseItemRows[itemKey];
                            
                                if (purchaseItemRow) {
                                  const updatedBatchList = Array.isArray(purchaseItemRow.batch_list) 
                                    ? purchaseItemRow.batch_list.map((bl) => ({
                                        ...bl,
                                        batch_qty: bl.batch_qty * (newQty / purchaseItemRow.qty),
                                      }))
                                    : []; 
                            
                                  const updatedPurchaseItemRows = [...purchaseItemRows];
                                  updatedPurchaseItemRows[itemKey] = {
                                    ...purchaseItemRow,
                                    qty: newQty,
                                    batch_list: updatedBatchList,
                                  };
                            
                                  this.setState({
                                    purchaseItemRows: updatedPurchaseItemRows,
                                  }, () => {
                                    this.calculateTotalAmount(updatedPurchaseItemRows);
                                  });
                                }
                              }
                            }}
                            
                            placeholder="Qty"
                            disabled={
                              (this.state.purchaseData &&
                                this.state.purchaseItemRows[itemKey].can_edit === "N") ||
                              (this.state.purchaseItemRows[itemKey].req_allocation &&
                                this.state.purchaseItemRows[itemKey]?.req_allocation?.length > 0)
                            }
                            required={true}
                          />
                          {i.item.measuring_unit && i.item.measuring_unit.name ? (
                            <span className="text-helping input-group-text text-end pr0">
                              {i.item?.measuring_unit?.name}
                            </span>
                          ) : null}
                          {this.state.purchaseData && this.state.purchaseItemRows[itemKey].can_edit === "N" ? (
                            <div className="text-danger">Item Consumed</div>
                          ) : null}
                          {this.state.isc_configuration?.track_batch_expiry === "Y" && (
                            <button
                              type="button"
                              onClick={() => this.addBatchNumberInit(itemKey)}
                              className="btn btn-link text-decoration-none"
                            >
                              + Batch No.
                            </button>
                          )}
                        </td>

                        <td className="pt0">
                          <TapInputNumber
                            name="rate"
                            value={this.state.purchaseItemRows[itemKey].rate}
                            onChange={(e) =>
                              this.dynamicInputHandlerByKeyNameVal(
                                e,
                                "rate",
                                itemKey,
                                "purchaseItemRows"
                              )
                            }
                            placeholder="Unit Rate"
                            required={true}
                            disabled={
                              this.state.purchaseItemRows[itemKey]
                                .disable_rate == "Y" &&
                                this.state.purchase_po_same_unit_rate == "Y"
                                ? true
                                : false
                            }
                          />
                        </td>

                        <td className="text-center rightSelectText">
                          <TapInputNumber
                            name="discount_rate"
                            value={
                              this.state.purchaseItemRows[itemKey].discount_rate
                            }
                            onChange={(e) =>
                              this.dynamicInputHandlerByKeyNameVal(
                                e,
                                "discount_rate",
                                itemKey,
                                "purchaseItemRows"
                              )
                            }
                            placeholder="Discount"
                          />
                          <TapSelect
                            changeEvent={(selectedOption, e) =>
                              this.dynamicInputHandlerByKeyNameVal(
                                selectedOption.value,
                                "discount_type",
                                itemKey,
                                "purchaseItemRows"
                              )
                            }
                            options={this.state.allDiscountType}
                            isSearchable={false}
                            value={this.state.allDiscountType.find(
                              (s) =>
                                s.value ==
                                this.state.purchaseItemRows[itemKey]
                                  .discount_type
                            )}
                            placeholder="Discount"
                          />
                        </td>
                        <td className="text-end rightSelectText">
                          {this.state.gst_not_applicable == "N" &&
                            this.state.selectedVendorAdress &&
                            this.state.selectedVendorAdress.country_id == 99 ? (
                            <TapSelect
                              changeEvent={(selectedOption, e) => {
                                this.dynamicInputHandlerByKeyNameVal(
                                  selectedOption.value,
                                  "gst",
                                  itemKey,
                                  "purchaseItemRows"
                                );
                              }}
                              options={this.state.allGstSlabs}
                              isSearchable={false}
                              value={this.state.allGstSlabs.find(
                                (s) =>
                                  s.value ==
                                  this.state.purchaseItemRows[itemKey].gst
                              )}
                              placeholder="GST"
                            />
                          ) : (
                            "-"
                          )}
                          {this.state.selectedVendorAdress &&
                            this.state.selectedVendorAdress.gstin &&
                            this.state.gst_not_applicable == "N" &&
                            this.state.selectedVendorAdress.country_id == 99 ? (
                            i.item ? (
                              <span className="text-helping input-group-text text-end pr0">
                                {this.state.gstTypeName}
                              </span>
                            ) : null
                          ) : (
                            <div className="text-center">
                              NA <sup className="text-danger">#</sup>
                            </div>
                          )}
                        </td>

                        <td className="text-end className='pt0'">
                          {item_with_discount}
                        </td>
                      </Ax>
                    )}
                  </Ax>
                ) : (
                  <Ax>
                    <td>
                      {this.state.purchase_only_with_po == "N" && (
                        <InputItemSearch
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "addPurchaseForm",
                              "item_id"
                            );
                            this.onItemSelect(selectedOption, itemKey);
                          }}
                          only_active_item="Y"
                          value={
                            this.state.searchedItem
                              ? {
                                ...this.state.searchedItem,
                                label: this.state.searchedItem.display_label,
                              }
                              : null
                          }
                        />
                      )}
                    </td>
                    <td
                      colSpan={
                        this.state.isc_configuration?.track_batch_expiry == "Y"
                          ? "6"
                          : "5"
                      }
                      className="text-center align-middle"
                    >
                      -
                    </td>
                  </Ax>
                );
                return (
                  <tr key={itemKey}>
                    <td className="text-center align-middle">{itemKey + 1}</td>
                    {tdRows}
                    <td className="text-center align-middle">
                      {this.state.purchaseData &&
                        this.state.purchaseItemRows[itemKey].can_edit ==
                        "N" ? null : (
                        <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                          <tapIcon.imageIcon
                            icon={tapIcon.CloseCircleIcon}
                            className="img-fluid"
                          />
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="col-sm-5 mt0">
          {this.state.selectedVendorAdress &&
            this.state.selectedVendorAdress.gstin ? null : (
            <small className="text-danger">
              #NA : GST Not Applicable to Vendor with No GSTIN
            </small>
          )}
        </div>
        {this.state.purchase_only_with_po == "N" && (
          <div className="col-sm-7 text-end">
            {this.state.addPurchaseForm &&
              this.state.addPurchaseForm.warehouse_id &&
              this.state.addPurchaseForm.vendor_id ? (
              <a
                role="button"
                onClick={this.itemSearchModalInit}
                className="link-primary me-3"
              >
                <tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item{" "}
              </a>
            ) : null}
            {this.props?.permissions?.permissions?.includes("isc-item-add") && (
              <a
                role="button"
                onClick={this.addItemModalInit}
                className="link-primary me-3"
              >
                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item
              </a>
            )}
            <a
              role="button"
              onClick={this.addNewItemRow}
              className="link-primary"
            >
              <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row{" "}
            </a>
          </div>
        )}
        <div className="col-sm-12 my-1">
          <input
            name="gst_not_applicable"
            type="checkbox"
            value={this.state.gst_not_applicable}
            onChange={(e) => {
              this.setState(
                {
                  gst_not_applicable:
                    this.state.gst_not_applicable == "N" ? "Y" : "N",
                },
                () => {
                  if (this.state.gst_not_applicable == "Y") {
                    let purchaseItemRows = this.state.purchaseItemRows;
                    let updatedPurchaseItemRows = purchaseItemRows.map((it) => {
                      return { ...it, gst: 0 };
                    });

                    this.setState({
                      purchaseItemRows: updatedPurchaseItemRows,
                      gst_as_applicable: "N",
                    });
                  }
                }
              );
            }}
            checked={this.state.gst_not_applicable == "Y"}
            className="form-check-input"
            id="gst_not_applicable"
            disabled={
              this.state.selectedVendorAdress &&
                this.state.selectedVendorAdress.country_id !== 99
                ? true
                : false
            }
          />
          <label
            className="form-check-label mx-2 text-sm"
            htmlFor="gst_not_applicable"
          >
            GST not Applicable
          </label>
          <br />
        </div>
      </div>
    );
  };

  //*********************************VENDOR DETAIL JSX*************************
  pickPOItemModalJsx = () => {
    let po_item_ids = [];
    po_item_ids =
      this.state.purchaseItemRows && this.state.purchaseItemRows.length > 0
        ? this.state.purchaseItemRows
          .filter((i) => (i.item ? true : false))
          .map((p) =>
            p.po_item_id
              ? { po_item_id: p.po_item_id }
              : {
                po_item_id: p[0]?.selected_allocation?.po_item_id,
                req_item_id: p[0]?.selected_allocation?.req_item_id,
              }
          )
        : [];

    return (
      <div className="modal fade" id="pickPOItemModal" tabIndex="-1">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="vendorDetailsModalLabel">
                Pick Item from Purchase Order{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <table className="table table-hover table-borderless my-2">
                <tbody>
                  <tr>
                    <td style={{ width: "25%" }}>Warehouse</td>
                    <td>
                      {this.state.warehouseDetail &&
                        this.state.warehouseDetail.name
                        ? this.state.warehouseDetail.name
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>Vendor</td>
                    <td>
                      {this.state.vendorDetails && this.state.vendorDetails.name
                        ? this.state.vendorDetails.name
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <form
                className="bg-white"
                onSubmit={this.submitSelectedPOItem}
                id="pickaddPurchaseForm"
                method="post"
                encType="multipart/form-data"
              >
                <table className="table table-hover table-bordered table-responsive table-sm bg-white mt-3">
                  <thead className="table-secondary">
                    <tr>
                      <th
                        scope="col"
                        style={{ width: "5%" }}
                        className="text-center"
                      >
                        <input
                          type="checkbox"
                          onChange={this.checkAllPOList}
                          id="all_check_po"
                          disabled={po_item_ids.length > 0}
                        />
                      </th>
                      <th
                        scope="col"
                        className="text-start"
                        style={{ width: "15%" }}
                      >
                        Item Name
                      </th>
                      <th
                        scope="col"
                        className="text-start"
                        style={{ width: "15%" }}
                      >
                        Item Code
                      </th>
                      <th
                        scope="col"
                        className="text-center"
                        style={{ width: "12%" }}
                      >
                        PO Number
                      </th>
                      <th
                        scope="col"
                        className="text-center"
                        style={{ width: "10%" }}
                      >
                        PO Date
                      </th>
                      <th
                        scope="col"
                        className="text-center"
                        style={{ width: "10%" }}
                      >
                        Req Number
                      </th>
                      <th
                        scope="col"
                        className="text-center"
                        style={{ width: "12%" }}
                      >
                        Req Date
                      </th>
                      <th
                        scope="col"
                        className="text-center"
                        style={{ width: "7%" }}
                      >
                        Currency
                      </th>
                      <th
                        scope="col"
                        className="text-end"
                        style={{ width: "7%" }}
                      >
                        Rate
                      </th>
                      <th
                        scope="col"
                        className="text-end"
                        style={{ width: "7%" }}
                      >
                        Pending Qty
                      </th>
                      <th
                        scope="col"
                        className="text-end"
                        style={{ width: "7%" }}
                      >
                        Allocate
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.pending_item_loading ? (
                      <tr>
                        <td colSpan={9}>
                          <Loader />
                        </td>
                      </tr>
                    ) : this.state.allPendingPOList &&
                      this.state.allPendingPOList.length > 0 ? (
                      this.state.allPendingPOList.map((po_item, i) => {
                        let pending_qty =
                          po_item.req_item_id && po_item.po_item_id
                            ? po_item.requisition_po_qty
                            : po_item.po_item_id && po_item.req_item_id == null
                              ? po_item.pending_qty
                              : po_item.req_pending_qty;

                        return (
                          <tr>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                className="po_id_checkbox"
                                name="poItem"
                                value={po_item.po_item_id}
                                data-req_item_id={po_item.req_item_id}
                                data-qty={po_item?.new_qty}
                                data-currency={
                                  po_item.currency ? po_item.currency : "INR"
                                }
                                // value={po_item.po_item_id}
                                // data-req_item_id={po_item.req_item_id}
                                // data-qty={po_item?.new_qty}
                                disabled={
                                  this.state.allAllocatedItemIds &&
                                    this.state.allAllocatedItemIds.length > 0
                                    ? this.state.allAllocatedItemIds.find(
                                      (element) => {
                                        if (
                                          element?.po_item_id ==
                                          po_item?.po_item_id &&
                                          element?.req_item_id ==
                                          po_item?.req_item_id
                                        ) {
                                          return true;
                                        } else {
                                          return false;
                                        }
                                      }
                                    )
                                    : false
                                }
                              //disabled={po_item_ids.length > 0 && po_item_ids.filter(e => e.po_item_id == po_item.po_item_id && e.req_item_id == po_item.req_item_id).length > 0}
                              />
                            </td>
                            <td>{po_item.item ? po_item.item.name : "-"}</td>
                            <td>
                              {po_item.item
                                ? po_item.item.item_code ?? "-"
                                : "-"}
                            </td>
                            <td>
                              {po_item.purchase_number
                                ? po_item.purchase_number
                                : "-"}
                            </td>
                            <td>
                              {po_item.transaction_date
                                ? po_item.transaction_date
                                : "-"}
                            </td>
                            <td>
                              {po_item.requisition_transaction_id
                                ? po_item.requisition_transaction_id
                                : "-"}
                            </td>
                            <td>
                              {po_item.requisition_date_display
                                ? po_item.requisition_date_display
                                : "-"}
                            </td>
                            <td className="text-center">
                              {po_item.currency ? po_item.currency : "INR"}
                            </td>
                            <td className="text-end">
                              {po_item.rate_vc ? po_item.rate_vc : 0}
                            </td>
                            <td>{pending_qty}</td>

                            <td>
                              <TapInputNumber
                                className="form-control form-control-sm text-end"
                                placeholder="Allocated Quantity"
                                name="new_qty"
                                value={po_item.new_qty}
                                onChange={(e) => {
                                  this.state.allPendingPOList[i] = {
                                    ...po_item,
                                    new_qty: e,
                                  };
                                  this.dynamicInputHandlerByKeyNameVal(
                                    e,
                                    "new_qty",
                                    i,
                                    "allPendingPOList"
                                  );
                                }}
                                max={pending_qty}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No Record
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                disabled={this.state.savePickItemSubmitting}
                className="btn btn-primary"
                form="pickaddPurchaseForm"
              >
                Add to Purchase{" "}
                {this.state.savePickItemSubmitting ? (
                  <tapIcon.FontAwesomeIcon
                    icon={tapIcon.faSyncAlt}
                    className="fa-spin"
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //**********************ALLOCATE ITEM JSX********************** */
  reqDetailModalJsx = () => {
    let purchaseItemRow =
      this.state.purchaseItemRows[this.state.purchaseItemRowKey];
    let allocated_qty =
      purchaseItemRow &&
        purchaseItemRow.req_allocation &&
        Array.isArray(purchaseItemRow.req_allocation)
        ? parseFloat(purchaseItemRow.req_allocation.map((s, b) => s.new_qty))
        : 0;
    let total_quantity = purchaseItemRow
      ? parseFloat(
        purchaseItemRow.unallocated_qty ? purchaseItemRow.unallocated_qty : 0
      ) + parseFloat(allocated_qty)
      : parseFloat(allocated_qty);

    return (
      <div className="modal fade" id="reqDetailModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="reqDetailModalLabel">
                Requisition Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="tab_content_wrapper">
                <span className="content_heading">Item Detail</span>
              </div>
              <table className="table table-hover table-borderless my-2">
                <tbody>
                  <tr>
                    <td style={{ width: "15%" }}>Item Name</td>
                    <td>
                      {purchaseItemRow && purchaseItemRow.item
                        ? purchaseItemRow.item.name
                        : ""}
                    </td>
                    <td style={{ width: "15%" }}>Item Code</td>
                    <td>
                      {purchaseItemRow && purchaseItemRow.item
                        ? purchaseItemRow.item.item_code
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Warehouse</td>
                    <td>
                      {this.state.warehouseDetail &&
                        this.state.warehouseDetail.name
                        ? this.state.warehouseDetail.name
                        : "-"}
                    </td>
                    <td>Vendor</td>
                    <td>
                      {this.state.vendorDetails && this.state.vendorDetails.name
                        ? this.state.vendorDetails.name
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <form
                className="bg-white"
                onSubmit={this.submitAllocatedItem}
                id="allocatedItemForm"
              >
                <table
                  className="table table-bordered table-sm bg-white my-2"
                  id="allocationTable"
                >
                  <thead className="table-secondary">
                    <tr className="text-center">
                      <th scope="col" style={{ width: "10%" }}></th>
                      <th scope="col" style={{ width: "15%" }}>
                        PO Number
                      </th>
                      <th
                        scope="col"
                        style={{ width: "12%" }}
                        className="text-end"
                      >
                        PO Date
                      </th>
                      <th scope="col" style={{ width: "15%" }}>
                        Requistion ID
                      </th>
                      <th
                        scope="col"
                        style={{ width: "12%" }}
                        className="text-end"
                      >
                        Req Date
                      </th>
                      <th
                        scope="col"
                        style={{ width: "12%" }}
                        className="text-end"
                      >
                        Unit Rate
                      </th>
                      <th
                        scope="col"
                        style={{ width: "12%" }}
                        className="text-end"
                      >
                        Pending Qty
                      </th>
                      <th
                        scope="col"
                        style={{ width: "12%" }}
                        className="text-end"
                      >
                        Allocated Quantity{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseItemRow &&
                      purchaseItemRow.req_allocation &&
                      purchaseItemRow.req_allocation.length > 0
                      ? purchaseItemRow.req_allocation.map((a, i) => {
                        let pending_qty =
                          purchaseItemRow &&
                            purchaseItemRow.selected_allocation &&
                            purchaseItemRow.selected_allocation
                              .allocated_item_key == a.allocated_item_key &&
                            this.state.editPurchase == true
                            ? purchaseItemRow?.selected_allocation
                              ?.new_pending_qty
                            : a.pending_qty;
                        let max_qty =
                          purchaseItemRow &&
                            purchaseItemRow.selected_allocation &&
                            purchaseItemRow.selected_allocation
                              .allocated_item_key == a.allocated_item_key &&
                            this.state.editPurchase == true
                            ? purchaseItemRow?.selected_allocation
                              ?.new_pending_qty
                            : a.max_qty;
                        return (
                          <tr className="text-center" key={i}>
                            <td>
                              <input
                                type="radio"
                                name="allocated_item_key"
                                value={i}
                                checked={
                                  purchaseItemRow &&
                                  purchaseItemRow.allocated_item_key == i
                                }
                                onChange={(e) => {
                                  let purchaseItemRows =
                                    this.state.purchaseItemRows;
                                  purchaseItemRows[
                                    this.state.purchaseItemRowKey
                                  ] = {
                                    ...purchaseItemRow,
                                    allocated_item_key: i,
                                    stock_id: null,
                                  };
                                  this.setState({
                                    purchaseItemRows: purchaseItemRows,
                                  });
                                }}
                                className="form-check-input"
                                required={true}
                                disabled={this.state.allAllocatedItemIds.find(
                                  (element) => {
                                    if (
                                      element?.po_item_id == a?.po_item_id &&
                                      element?.req_item_id == a?.req_item_id
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                )}
                              />
                            </td>
                            <td>{a.po_id ? a.po_id : "-"} </td>
                            <td>
                              {a.po_date_display ? a.po_date_display : "-"}
                            </td>
                            <td>{a.req_id ? a.req_id : "-"}</td>
                            <td>{a.req_date ? a.req_date : "-"}</td>
                            <td>{a.po_rate ? a.po_rate : "-"}</td>
                            <td>{pending_qty}</td>
                            <td>
                              <TapInputNumber
                                className="form-control form-control-sm text-end"
                                placeholder="Allocated Quantity"
                                name="new_qty"
                                value={a.new_qty}
                                onChange={(e) => {
                                  purchaseItemRow.req_allocation[i] = {
                                    ...a,
                                    new_qty: e,
                                  };
                                  this.dynamicInputHandlerByKeyNameVal(
                                    purchaseItemRow.req_allocation,
                                    "req_allocation",
                                    this.state.purchaseItemRowKey,
                                    "purchaseItemRows"
                                  );
                                }}
                                max={max_qty}
                                min={0}
                              />
                            </td>
                          </tr>
                        );
                      })
                      : null}
                    <tr>
                      <td className="text-center">
                        <input
                          type="radio"
                          name="allocated_item_key"
                          value={
                            purchaseItemRow?.req_allocation &&
                              purchaseItemRow.req_allocation?.length > 0
                              ? purchaseItemRow?.req_allocation?.length + 1
                              : 0
                          }
                          checked={
                            purchaseItemRow &&
                            purchaseItemRow?.allocated_item_key ==
                            purchaseItemRow?.req_allocation?.length + 1
                          }
                          onChange={(e) => {
                            let purchaseItemRows = this.state.purchaseItemRows;
                            purchaseItemRows[this.state.purchaseItemRowKey] = {
                              ...purchaseItemRow,
                              allocated_item_key:
                                purchaseItemRow?.req_allocation?.length + 1,
                              stock_id: null,
                            };
                            this.setState({
                              purchaseItemRows: purchaseItemRows,
                            });
                          }}
                          className="form-check-input"
                          required={true}
                        />
                      </td>
                      <td className="text-start" colSpan={6}>
                        Unallocated
                      </td>
                      <td>
                        <TapInputNumber
                          className="form-control form-control-sm text-end"
                          placeholder="Allocated Quantity"
                          name="unallocated_qty"
                          value={
                            purchaseItemRow
                              ? purchaseItemRow.unallocated_qty
                              : 0
                          }
                          onChange={(e) =>
                            this.dynamicInputHandlerByKeyNameVal(
                              e,
                              "unallocated_qty",
                              this.state.purchaseItemRowKey,
                              "purchaseItemRows"
                            )
                          }
                          min={0}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close{" "}
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //**********************Batch Number ITEM JSX********************** */
  addBatchNumberModalJsx = () => {
    let purchaseItemRow =
      this.state.purchaseItemRows[this.state.purchaseItemRowKey];
    let batch_list =
      purchaseItemRow &&
        purchaseItemRow.batch_list &&
        purchaseItemRow.batch_list.length == 1 &&
        purchaseItemRow.batch_list.some((batch) => {
          return !batch.batch_number && !batch.expiry_date;
        })
        ? []
        : purchaseItemRow?.batch_list;
    let totalBatchQty =
      batch_list && batch_list.length > 0
        ? batch_list.reduce((accumulator, batch) => {
          return accumulator + Number(batch.batch_qty);
        }, 0)
        : 0;
    return (
      <div className="modal fade" id="addBatchNumberModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addBatchNumberModalLabel">
                Add Batch Number and Expiry Date
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="tab_content_wrapper">
                <span className="content_heading">Item Detail</span>
              </div>
              <table className="table table-hover table-borderless my-2">
                <tbody>
                  <tr>
                    <td style={{ width: "15%" }}>Item Name</td>
                    <td>
                      {purchaseItemRow && purchaseItemRow.item
                        ? purchaseItemRow.item.name
                        : ""}
                    </td>
                    <td style={{ width: "15%" }}>Item Code</td>
                    <td>
                      {purchaseItemRow && purchaseItemRow.item
                        ? purchaseItemRow.item.item_code
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Warehouse</td>
                    <td>
                      {this.state.warehouseDetail &&
                        this.state.warehouseDetail.name
                        ? this.state.warehouseDetail.name
                        : "-"}
                    </td>
                    <td>Vendor</td>
                    <td>
                      {this.state.vendorDetails && this.state.vendorDetails.name
                        ? this.state.vendorDetails.name
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>

              <form
                className="bg-white"
                onSubmit={this.submitItemBatchNumber}
                id="addBatchNumberForm"
              >
                <div className="tab_content_wrapper">
                  <span className="content_heading">Batch & Expiry List</span>
                </div>
                <table
                  className="table table-bordered table-sm bg-white my-2"
                  id="allocationTable"
                >
                  <thead className="table-secondary">
                    <tr className="text-center">
                      <th scope="col" style={{ width: "35%" }}>
                        Batch Number
                      </th>
                      <th scope="col" style={{ width: "35%" }}>
                        Expiry Date
                      </th>
                      <th scope="col" style={{ width: "15%" }}>
                        Quantity
                      </th>
                      <th scope="col" style={{ width: "10%" }}>
                        Action{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseItemRow &&
                      purchaseItemRow.batch_list &&
                      purchaseItemRow.batch_list.length > 0
                      ? purchaseItemRow.batch_list.map((bt, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                placeholder="Batch Number"
                                name="batch_number"
                                type="text"
                                value={bt.batch_number}
                                onChange={(e) => {
                                  purchaseItemRow.batch_list[i] = {
                                    ...bt,
                                    batch_number: e.target.value,
                                  };
                                  this.dynamicInputHandlerByKeyNameVal(
                                    purchaseItemRow.batch_list,
                                    "batch_list",
                                    this.state.purchaseItemRowKey,
                                    "purchaseItemRows"
                                  );
                                }}
                                disabled={
                                  this.state.editPurchase && bt.stock_id
                                    ? true
                                    : false
                                }
                                autoComplete="off"
                              />
                            </td>
                            <td>
                              <DatePicker
                                selected={
                                  bt.expiry_date
                                    ? moment(
                                      bt.expiry_date,
                                      "YYYY-MM-DD"
                                    ).toDate()
                                    : false
                                }
                                name="expiry_date"
                                onChange={(value, event) => {
                                  const formattedExpiryDate = value
                                    ? moment(value).format("YYYY-MM-DD")
                                    : null;

                                  // Update the batch list with the same value for batch_number and expiry_date
                                  purchaseItemRow.batch_list[i] = {
                                    ...bt,
                                    expiry_date: formattedExpiryDate,
                                  };

                                  this.dynamicInputHandlerByKeyNameVal(
                                    purchaseItemRow.batch_list,
                                    "batch_list",
                                    this.state.purchaseItemRowKey,
                                    "purchaseItemRows"
                                  );
                                }}
                                dateFormat="dd-MMM-yyyy"
                                className={"form-control form-control-sm"}
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                scrollMonthYearDropdown
                                disabled={
                                  this.state.editPurchase && bt.stock_id
                                    ? true
                                    : false
                                }
                                placeholderText={`Please Enter Expiry Date`}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                placeholder="Qty"
                                name="batch_qty"
                                type="text"
                                value={bt.batch_qty}
                                onChange={(e) => {
                                  purchaseItemRow.batch_list[i] = {
                                    ...bt,
                                    batch_qty: e.target.value,
                                  };
                                  this.dynamicInputHandlerByKeyNameVal(
                                    purchaseItemRow.batch_list,
                                    "batch_list",
                                    this.state.purchaseItemRowKey,
                                    "purchaseItemRows"
                                  );
                                }}
                                autoComplete="off"
                              />
                            </td>
                            <td className="text-center">
                              {purchaseItemRow &&
                                purchaseItemRow.batch_list &&
                                purchaseItemRow.batch_list.length > 1 && (
                                  <a
                                    href="#"
                                    onClick={() =>
                                      this.removeItemBatchList(i)
                                    }
                                  >
                                    <tapIcon.imageIcon
                                      icon={tapIcon.CloseCircleIcon}
                                      className="img-fluid"
                                    />
                                  </a>
                                )}
                            </td>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </table>
                <div className="text-end  my-2">
                  <button
                    type="button"
                    onClick={() => this.addNewBatchRow()}
                    className="btn btn-link text-decoration-none"
                  >
                    + Add New Batch Row
                  </button>
                </div>
                <table className="table table-borderless table-responsive bg-white my-2">
                  <tbody>
                    <tr>
                      <td className="text-end">Total Batch Quantity : </td>
                      <td className="text-end">{totalBatchQty}</td>
                    </tr>
                    <tr>
                      <td className="text-end">Total Item Quantity : </td>
                      <td className="text-end">
                        {purchaseItemRow && purchaseItemRow.qty
                          ? purchaseItemRow.qty
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Remaining Quantity: </td>
                      <td className="text-end">
                        {purchaseItemRow && purchaseItemRow.qty
                          ? Number(purchaseItemRow.qty) - Number(totalBatchQty)
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close{" "}
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Add Purchase</title>
        </Helmet>
        <div className="page_title row m0">
          <div className="col-12">
            <h3>
              {this.state.purchaseData ? "Edit" : "Add"} Purchase{" "}
              {this.state.purchaseData ? (
                <span>
                  {" "}
                  of Transaction ID : {this.state.purchaseData.transaction_id}
                </span>
              ) : null}
            </h3>
            <div className="text-end mt15">
              <button
                onClick={this.closeEvent}
                disabled={this.state.saveFormSubmitting ? true : false}
                type="button"
                className="btn btn-secondary"
              >
                <tapIcon.imageIcon
                  icon={tapIcon.CloseIcon}
                  className="img-fluid"
                  alt="Close purchase"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="page_containt row pl2">
            <div className="pageTbl col-12 pl4">
              {this.state.purchaseFormDataLoaded ? (
                this.savePurchaseFormJsx()
              ) : (
                <Loader />
              )}
              {/* {this.itemDetailsModalJsx()} */}
              {this.pickPOItemModalJsx()}
              {this.reqDetailModalJsx()}
              {this.addBatchNumberModalJsx()}

              <ItemDetailsModal ref={this.itemDetailsRef} />
              <AddItemModal
                parentProps={this.props}
                ref={this.addItemModalRef}
              />
              <ItemEditModal
                parentProps={this.props}
                ref={this.editItemModalRef}
                afterSubmitItem={this.submitEditItem}
              />
              <AddVendorModal
                parentProps={this.props}
                ref={this.addVendorModalRef}
                afterEditSubmit={(selectedVendor) =>
                  this.handleVendorChange(selectedVendor)
                }
              />
              <ItemSearchModal
                ref={this.itemSearchRef}
                afterSelectingItems={(selectedItems) => {
                  this.addItemsfromSearch(selectedItems);
                }}
              />
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    isc_configuration:
      state.app && state.app.isc_configuration
        ? state.app.isc_configuration
        : null,
    all_warehouses:
      state.app && state.app.warehouses ? state.app.warehouses : [],
    iam_group_sites:
      state.app && state.app.user_sites ? state.app.group_sites : [],
    permissions: state.app.acl_info,
    default_currency:
      state.app && state.app.default_currency ? state.app.default_currency : "",
    currency_list:
      state.app && state.app.currency_list && state.app.currency_list.length > 0
        ? state.app.currency_list
        : [],
  };
};

export default connect(mapStateToProps)(PurchaseAdd);
