import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import TapSelect from '../../../../components/ui/TapSelect';
import DatePicker from "react-datepicker";
import Status from '../../../../components/ui/Status';
import moment from "moment";
import tapIcon from "../../../../services/TapIcon";


class AssetRentalView extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initRentalForm         =       {
            status                  :       '',
            status_change_date      :       '',
            client_id               :       '',
            client_name             :       '',
            rental_amount           :       null,
            potential_rent_amount   :       null,
            remark                  :       '',
            wo_detail               :       '',
            wo_date	                :       '',
            wo_due_date             :       '',
            date_dispatch           :       '',
            date_receipt            :       '',
            date_deployment         :       '',
            date_release            :       '',
            date_release_tentative  :       '',
            state_id                :       '',
            district_id             :       ''
        }

        this.initState              =       {
            additional_attribute    :        [],
            addNewClient            :        'N',
            addRentalForm           :        {...this.initRentalForm},
            saveFormSubmitting      :        false
        }

        this.state                        =             {
            rentalData              :        null, 
            rentalData              :        null,
            detailLoading           :        false,  
            showEdit                :        props.showEdit,  
            formDataLoaded          :        false,
            formDataLoading         :        false,
            formData                :        null,
            allStatusList           :        [],
            allClientList           :        [],
            allAttributeList        :        [],
            allStateList            :        [],
            allDistrictList         :        [],
            rentalData              :        null,
            detailLoading           :        false,
            ...this.initState  
        };

        this.stateListUrl   =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl   =   ORG_API_BASE_URL_2  +   '/district_list';
       
    }

    componentDidMount() {
           this.intializeData(this.props)
           if(this.props && this.props.assetId){
              this.getRentalDetail(this.props.assetId)
           }
           this.addRentalModal          =        new Modal(document.getElementById('addAssetRentalModal'), {keyboard: false, backdrop :false}); 
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.rentalData != nextProps.rentalData) {
            this.setState({detailLoading : true},() => {
                this.intializeData(nextProps);
            })
           
        }
        if(this.props.assetId !== nextProps.assetId){
            this.getRentalDetail(nextProps.assetId)
        }
        
    }

    editRentalModalInit        =       ()          =>      {
        this.addRentalModal.show()
        this.setState({...this.initState})
        this.getAddRentalFormData()
        this.fetchStateListfromApi()
       
    }


    getRentalDetail       =      (id)   =>     {
        this.setState({detailLoading : true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/rental_asset_detail/' + id , this.props.access_token , {}, {}, (resp) => {
            let respData                =   resp.data;
            this.setState({
                rentalData             :       respData && respData.data && JSON.stringify(respData.data) !== JSON.stringify({}) ? respData.data : null, 
           })
        }).then(() => this.setState({detailLoading :  false}));
    }

    intializeData       =       (pr)      =>      {
        this.setState({access_token : pr.access_token,rentalData : pr.rentalData,showEdit : pr.showEdit,assetId : pr.assetId,
            })
            setTimeout(() => {
                this.setState({
                    detailLoading            : false
                  
                });
            },2000)
    }

    getAddRentalFormData       =      ()   =>     {
        this.setState({formDataLoading : true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/rental/add_form_data', this.props.access_token , {}, {}, (resp) => {
            let respData                =   resp.data.data;
            this.setState({
                formDataLoaded           :        true,
                formData                 :        respData ? respData : null,
                allStatusList            :        respData.status && respData.status.length > 0 ? respData.status.map(u => {   return { value: u.id, label: <Status color={u.color}>{u.name}</Status>} }) : [],
                allClientList            :        respData.clients && respData.clients.length > 0 ? respData.clients.map(u => {   return { value: u.id, label: u.name} }) : [],
           },() => {
                if(this.state.rentalData){
                    let rentalData      =       this.state.rentalData;
                    let updateFormState = [];
                    if (rentalData.additional_attributes && rentalData.additional_attributes.length > 0 ) {
                        rentalData.additional_attributes.map((k) => {
                            let name                =  'additional_attributes_' + k.key; 
                            updateFormState[name]   =   k.value
                        });
                    }
                    
                    this.setState({
                        addRentalForm               :           {
                            status                  :           rentalData.status ? rentalData.status : '' ,
                            status_change_date      :           rentalData.status_change_date ? rentalData.status_change_date : '' ,
                            client_id               :           rentalData.client_id    ? rentalData.client_id : '',
                            remark                  :           rentalData.remark ? rentalData.remark : "",
                            state_id                :           rentalData.state_id ? rentalData.state_id : "",
                            district_id             :           rentalData.district_id ? rentalData.district_id : "",
                            rental_amount           :           rentalData.rental_amount ? rentalData.rental_amount : '',
                            potential_rent_amount   :           rentalData.potential_rent_amount ? rentalData.potential_rent_amount : '',
                            wo_detail               :           rentalData.wo_detail ? rentalData.wo_detail : '',
                            wo_date                 :           rentalData.wo_date ? rentalData.wo_date : '',
                            wo_due_date             :           rentalData.wo_due_date ? rentalData.wo_due_date : '',
                            date_dispatch           :           rentalData.date_dispatch ? rentalData.date_dispatch : '',
                            date_receipt            :           rentalData.date_receipt ? rentalData.date_receipt : '',
                            date_deployment         :           rentalData.date_deployment ? rentalData.date_deployment : '',
                            date_release            :           rentalData.date_release ? rentalData.date_release : '',
                            date_release_tentative  :           rentalData.date_release_tentative ? rentalData.date_release_tentative : '',
                            ...updateFormState
                        }  
                    })
                    if(rentalData.state_id){
                        this.stateChangeHandler(rentalData.state_id)
                    }
                    
                }else{
                   this.setState({
                    addRentalForm               :           {
                        ...this.initRentalForm,
                        status_change_date      :           moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm:ss') ,
                    }  
            
                   }) 
                }
           })
        }).then(() => this.setState({formDataLoading :  false}));
    }

    fetchStateListfromApi       =      ()   =>     {
        HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token , {}, {}, (resp) => {
            let respData                =   resp.data;
            this.setState({
                allStateList             :       respData ? respData.map((s) => { return {value: s.id, label: s.name}; }) : [], 
           })
        })
    }

     //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
     stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
           
                params = {state_ids : selected_state.value ? [selected_state.value] : [selected_state]}
           
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList  :  response.data.map((s) => { return {value: s.id, label: s.name}; })
                });
            });
        }
    }

    submitAssetRentalForm           =       (e)     =>      {
        e.preventDefault()
        let additional_attributes = {};
        this.state.formData.additional_attributes.forEach((i, k) => {
            let name = 'additional_attributes_' + i.key;
            additional_attributes[i.key] = this.state.addRentalForm[name] ? this.state.addRentalForm[name] : '';
        });
        let frmData = {...this.state.addRentalForm,asset_id : this.state.assetId,additional_attributes: additional_attributes};
      
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/rental_asset_detail' , this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.addRentalModal.hide();
            this.setState({...this.initState})
            this.getRentalDetail(this.state.assetId)
            if(this.props.refreshAssetDetail){
                this.props.refreshAssetDetail()
            }
        }).then(() => this.setState({ saveFormSubmitting : false }));
    }

    addRentalModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addAssetRentalModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addRentalModalLabel">Edit Asset Commercial</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitAssetRentalForm} id="addRentalModalForm">
                            {this.state.formDataLoading
                                ? <Loader />
                                : <div className="modal-body">
                                    <div className="tab_content_wrapper "> <span className="content_heading">Status</span>  </div>
                                    <div className="row mb-2 mt-1">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require ">Commercial Status</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Select Status"
                                                options={this.state.allStatusList}
                                                value={this.state.allStatusList.find(su => this.state.addRentalForm.status == su.value)}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRentalForm', 'status')}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                required={true}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require">Date of Status Change</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.status_change_date
                                                        ? moment(this.state.addRentalForm.status_change_date).toDate()
                                                        : false
                                                }
                                                name="status_change_date"
                                                onChange={(value, event) => this.formDateHandler('status_change_date', value, 'addRentalForm','YYYY-MM-DD hh:mm:ss')}
                                                dateFormat="dd-MMM-yyyy hh:mm a"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                maxDate={moment(new Date(),'YYYY-MM-DD').toDate()}
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Date of Status Change`}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                showTimeSelect
                                                timeFormat="hh:mm aa"
                                                timeIntervals={5}
                                                timeCaption="Time"
                                                required={true}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                style={{top:"8px"}}
                                                className="add_calender-icon"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Remarks</label>
                                        <div className="col-sm-7">
                                            <textarea
                                                name="remark"
                                                className='form-control form-control-sm'
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                value={this.state.addRentalForm.remark}
                                                placeholder='Enter Remarks' />
                                        </div>

                                    </div>
                                    <div className="tab_content_wrapper "> <span className="content_heading">Client</span>  </div>
                                    <div className="row mb-2 mt-1 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Client Name</label>
                                        <div className="col-sm-7">
                                            {this.state.addNewClient == "N"
                                                ? <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder="Select Client"
                                                    options={this.state.allClientList}
                                                    value={this.state.allClientList.filter(su => this.state.addRentalForm.client_id == su.value)}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRentalForm', 'client_id')}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                                :
                                                <input
                                                    name="client_name"
                                                    type="text"
                                                    value={this.state.addRentalForm.client_name}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                    placeholder="Please enter Client Name" />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="addNewClient"
                                                type="checkbox"
                                                value={this.state.addNewClient}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewClient: this.state.addNewClient == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.addNewClient == 'N' ? { ...this.state.addRentalForm, client_id: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.addNewClient == 'Y'}
                                                className="form-check-input"
                                                id="addNewClient"

                                            />
                                            <label className="form-check-label text-sm mx-2" htmlFor="addNewClient">Add New </label>
                                        </div>

                                    </div>
                                    <div className="row mb-2 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Location</label>
                                        <div className="col-sm-3">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addRentalForm', 'state_id');
                                                    this.stateChangeHandler(selectedOption)
                                                }}
                                                options={this.state.allStateList}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allStateList.find(m => m.value == this.state.addRentalForm.state_id)}
                                                placeholder="Please Select State"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addRentalForm', 'district_id') }}
                                                options={this.state.allDistrictList}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allDistrictList.find(m => m.value == this.state.addRentalForm.district_id)}
                                                placeholder="Select District"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                    <div className="tab_content_wrapper "> <span className="content_heading">Rental Amount</span>  </div>
                                    <div className="row mb-2 mt-1 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Rental Amount</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="rental_amount"
                                                type="text"
                                                value={this.state.addRentalForm.rental_amount}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                placeholder="Enter Rental Amount" />
                                        </div>

                                    </div>
                                    <div className="row my-1 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Potential Rent Amount</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="potential_rent_amount"
                                                type="text"
                                                value={this.state.addRentalForm.potential_rent_amount}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                placeholder="Enter Potential Rent Amount" />

                                        </div>

                                    </div>
                                   
                                    <div className="tab_content_wrapper "> <span className="content_heading">Work Order</span>  </div>
                                    <div className="row mb-2 mt-1 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Work Order Number</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="wo_detail"
                                                type="text"
                                                value={this.state.addRentalForm.wo_detail}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                placeholder="Enter Work Order Detail" />
                                        </div>

                                    </div>
                                    <div className="row mb-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">WO Date</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.wo_date
                                                        ? moment(this.state.addRentalForm.wo_date).toDate()
                                                        : false
                                                }
                                                name="wo_date"
                                                onChange={(value, event) => this.formDateHandler('wo_date', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter WO Date`}

                                            />
                                             <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{top:"8px"}}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">WO Expiry Date</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.wo_due_date
                                                        ? moment(this.state.addRentalForm.wo_due_date).toDate()
                                                        : false
                                                }
                                                name="wo_due_date"
                                                onChange={(value, event) => this.formDateHandler('wo_due_date', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter WO Due Date`}

                                            />
                                             <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{top:"8px"}}
                                            />
                                        </div>

                                    </div>
                                    <div className="tab_content_wrapper "> <span className="content_heading">Deployment</span>  </div>
                                    <div className="row mb-2 mt-1 ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Date of Disptach</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_dispatch
                                                        ? moment(this.state.addRentalForm.date_dispatch).toDate()
                                                        : false
                                                }
                                                name="date_dispatch"
                                                onChange={(value, event) => this.formDateHandler('date_dispatch', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Date of Dispatch`}

                                            />
                                             <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{top:"8px"}}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2  ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Date of Receipt at Site</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_receipt
                                                        ? moment(this.state.addRentalForm.date_receipt).toDate()
                                                        : false
                                                }
                                                name="date_receipt"
                                                onChange={(value, event) => this.formDateHandler('date_receipt', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Date of Receipt at Site`}

                                            />
                                          <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{top:"8px"}}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2  ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Date of Deployment</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_deployment
                                                        ? moment(this.state.addRentalForm.date_deployment).toDate()
                                                        : false
                                                }
                                                name="date_deployment"
                                                onChange={(value, event) => this.formDateHandler('date_deployment', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Date of Deployment`}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{top:"8px"}}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2  ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Tentative Release Date</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_release_tentative
                                                        ? moment(this.state.addRentalForm.date_release_tentative).toDate()
                                                        : false
                                                }
                                                name="date_release_tentative"
                                                onChange={(value, event) => this.formDateHandler('date_release_tentative', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Tentative Release Date `}

                                            />
                                             <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{top:"8px"}}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2  ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Release Date</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_release
                                                        ? moment(this.state.addRentalForm.date_release).toDate()
                                                        : false
                                                }
                                                name="date_release"
                                                onChange={(value, event) => this.formDateHandler('date_release', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Release Date`}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{top:"8px"}}
                                            />
                                        </div>
                                    </div>
                                   
                                    {
                                        this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0
                                            ? (
                                                <Ax>
                                                    <div className="tab_content_wrapper"><span className="content_heading">Additional Attributes</span></div>
                                                    {
                                                        this.state.formData.additional_attributes.map((i, k) => {
                                                            let name =  'additional_attributes_' + i.key;
                                                            let input = null;
                                                            if (i.type == 'text') {
                                                                input = <input
                                                                    name={name}
                                                                    type="text"
                                                                    value={this.state.addRentalForm[name] ? this.state.addRentalForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addRentalForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'numeric') {
                                                                input = <input
                                                                    name={name}
                                                                    type="number"
                                                                    value={this.state.addRentalForm[name] ? this.state.addRentalForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addRentalForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'date') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.addRentalForm[name]
                                                                            ? moment(this.state.addRentalForm[name]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.formDateHandler(name, value, 'addRentalForm')}
                                                                    dateFormat="dd-MMM-yyyy"
                                                                    className={"form-control form-control-sm"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${i.name}`}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                />
                                                            } else if (i.type == 'dropdown') {
                                                                let options = i.options.map((t) => { return { value: t, label: t } });
                                                                input = <TapSelect
                                                                    menuPlacement="top"
                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRentalForm', name)}
                                                                    options={options}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    value={options.find(o => o.value == this.state.addRentalForm[name])}
                                                                    placeholder={`Please Select ${i.name}`}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                />
                                                            }

                                                            return (<div key={k} className="row align-items-center mb-3 mt-3">
                                                                <div className="col-sm-3 col-form-label col-form-label-sm">{i.name}</div>
                                                                <div className="col-sm-7">{input}</div>
                                                               
                                                            </div>);
                                                        })
                                                    }
                                                </Ax>
                                            )
                                            : null
                                    }
                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"   disabled={this.state.saveFormSubmitting} onClick = {() => {this.setState({...this.initState})}}>Clear</button>
                                <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary"  disabled={this.state.saveFormSubmitting}>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
     

    render                              =   ()  =>  {
        let rentalData = this.state.rentalData;
        
        return (<Ax>
            {this.state.detailLoading
                ? <Loader />
                : <div className='pb-5'>

                    <div className="tab_content_wrapper ">
                        <span className="content_heading">Status</span>
                        {this.state.showEdit == "Y"
                            ? <div className='text-end'>
                                <button className='btn btn-primary' disabled={this.props.permissions.includes('iam-asset-rental-update') ? false : true} onClick={() => this.editRentalModalInit()}>Edit</button>
                            </div>
                            : null}
                    </div>
                    <table className="table table-hover table-borderless my-2">
                        <tbody >
                            <tr>
                                <td className="" style={{ "width": "25" }}>Commercial Status</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.status_data ? <Status color={rentalData.status_data.color}>{rentalData.status_data.name}</Status> : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>Date of Status Change</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData ? rentalData.status_change_date_display : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>Remarks</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.remark ? rentalData.remark : "-"}</td>
                            </tr>
                        </tbody>

                    </table>
                    <div className="tab_content_wrapper my-2 mb-2">  <span className="content_heading">Client</span> </div>
                    <table className="table table-hover table-borderless my-2">
                        <tbody >
                            <tr>
                                <td className="" style={{ "width": "25" }}>Client Name</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.client ? rentalData.client.name : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>Location</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.district ? <span>{rentalData.district.name}, </span> : ""} {rentalData && rentalData.state ? <span>{rentalData.state.name}. </span> : "-"}</td>
                            </tr>
                        </tbody>

                    </table>
                    <div className="tab_content_wrapper my-2 mb-2">  <span className="content_heading">Commercial Amount</span> </div>
                    <table className="table table-hover table-borderless my-2">
                        <tbody >

                            <tr>
                                <td className="" style={{ "width": "25" }}>Commercial Amount</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.rental_amount ? rentalData.rental_amount : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>Potential Rent Amount</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.potential_rent_amount ? rentalData.potential_rent_amount : "-"}</td>
                            </tr>
                        </tbody>

                    </table>
                    <div className="tab_content_wrapper my-2">
                        <span className="content_heading">Work Order</span>
                    </div>
                    <table className="table table-hover table-borderless my-2">
                        <tbody >
                            <tr>
                                <td className="" style={{ "width": "25" }}>Work Order Number</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.wo_detail ? rentalData.wo_detail : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>WO Date</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.wo_date_display ? rentalData.wo_date_display : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>WO Expiry Date </td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.wo_due_date_display && rentalData.wo_due_date_status ? <Status color={rentalData.wo_due_date_status.status_color_code}>{rentalData.wo_due_date_display}</Status> : "-"} </td>
                            </tr>

                        </tbody>

                    </table>
                    <div className="tab_content_wrapper my-2 mb-2">
                        <span className="content_heading">Deployment</span>
                    </div>
                    <table className="table table-hover table-borderless my-2">
                        <tbody >
                            <tr>
                                <td className="" style={{ "width": "25" }}>Date of Disptach</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.date_dispatch_display ? rentalData.date_dispatch_display : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>Date of Receipt at Site</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.date_receipt_display ? rentalData.date_receipt_display : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>Date of Deployment </td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.date_deployment_display ? rentalData.date_deployment_display : "-"} </td>
                            </tr>
                            
                            <tr>
                                <td className="" style={{ "width": "25" }}>Tentative Release Date </td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.date_release_tentative_display && rentalData.date_release_tentative_status ? <Status color={rentalData.date_release_tentative_status.status_color_code}>{rentalData.date_release_tentative_display}</Status> : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>Release Date </td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.date_release_display ? rentalData.date_release_display : "-"} </td>
                            </tr>

                        </tbody>

                    </table>

                    {rentalData && rentalData.additional_attributes && rentalData.additional_attributes.length > 0
                        ? <Ax>
                            <div className="tab_content_wrapper my-2 mb-2">  <span className="content_heading">Additional Attributes</span> </div>
                            <table className="table table-hover table-borderless my-2">
                                <tbody >
                                    {rentalData.additional_attributes.map(at => {
                                        return (<tr>
                                            <td className="" style={{ "width": "25" }}>{at.name}</td>
                                            <td className="fs14 fw500" style={{ "width": "75%" }}>{at.value_display ? at.value_display : "-"}</td>
                                        </tr>)
                                    })}

                                </tbody>

                            </table>
                        </Ax>
                        : null}
                    <div className="tab_content_wrapper my-2 mb-2">  <span className="content_heading">Transaction Detail</span> </div>
                    <table className="table table-hover table-borderless my-2">
                        <tbody >
                            <tr>
                                <td className="" style={{ "width": "25" }}>Updated By</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.user ? rentalData.user.full_name : "-"}</td>
                            </tr>
                            <tr>
                                <td className="" style={{ "width": "25" }}>Date of Updation</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{rentalData && rentalData.updated_at_display ? rentalData.updated_at_display 
                                : rentalData?.created_at_display ? rentalData?.created_at_display : "-"}</td>
                            </tr>


                        </tbody>

                    </table>

                </div>}
            {this.addRentalModalJsx()}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(AssetRentalView);