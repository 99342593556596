import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import DatePicker from "react-datepicker";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import PageHeader from '../../includes/PageHeader';
import TapIcon from '../../../services/TapIcon';
import Loader from '../../../components/ui/Loader/Loader';
import { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import TapSelect from '../../../components/ui/TapSelect';
import WarehouseAddress from '../../inventory/includes/WarehouseAddress';
import CustomerInput from '../includes/CustomerInput'; 
import AddCustomerModal from '../includes/AddCustomerModal';
import InputDealSearch from '../includes/InputDealSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import AssetMiniDetail from '../../includes/AssetMiniDetail';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import axios from 'axios';
import DataService from '../../../services/DataService';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import DateService from '../../../services/DateService';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import ItemSearchModal from '../../items/itemSearchModal';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import NumberFormatter from '../../../services/NumberFormater';
import moment from 'moment';
import TapHelper from '../../../services/TapHelper';
import { useLocation } from 'react-router-dom';

const SalesInvoiceAdd = forwardRef((props, ref) => {

    const location                              =   useLocation();
    const searchParams                          =   new URLSearchParams(location.search);


    let initFormData                            =   {
        data_loaded                             :   false,
        loading                                 :   false,
        submitting                              :   false,
        discount_types                          :   [],
        warehouses                              :   [],
        gst_slabs                               :   [],
        status_list                             :   [],
        voucher_for_list                        :   [
                                                        { value: 'asset', label: 'Asset' },
                                                        { value: 'workstation', label: 'Workstation' },
                                                        { value: 'employee', label: 'Employee' },
                                                        { value: 'sub_assembly', label: 'SubAssembly' }
                                                    ],
        sales_enable_tcs                        :   'Y',
        tcs_types                               :   [],
        transaction_id                          :   '',
        transaction_date                        :   new Date(),
        warehouse_id                            :   null,
        warehouse                               :   null,
        warehouseLoading                        :   false,
        warehouse_sites                         :   [],
        customer                                :   null,
        customer_id                             :   null,
        customer_billing_address_id             :   null,
        customer_billing_state_id               :   null,
        customer_shipping_address_id            :   null,
        customer_project_id                     :   null,
        status_id                               :   null,
        search_deal                             :   null,
        deal_id                                 :   null,
        invoice_type                            :   '',
        selected_asset                          :   null,
        selected_workstation                    :   null,
        selected_employee                       :   null,
        selected_subassembly                    :   null,
        workstation_id                          :   '',
        asset_id                                :   '',
        employee_id                             :   '',
        sub_assembly_id                         :   '',
        voucher_number                          :   '',
        voucher_date                            :   null,
        only_stocked_item                       :   'Y',
        update_item_sale_price                  :   'Y',
        selected_item                           :   null,
        selected_item_row                       :   null,
        tcs_rate                                :   0,
        tcs_type                                :   "Percentage",
        adjustment_amount                       :   0,
        notes                                   :   ''
    };

    let initVoucherItemRow                      =   {
        invoice_item_id                         :   null,
        sa_item_id                              :   null,
        so_item_id                              :   null,
        sa_transaction_id                       :   null,
        so_transaction_id                       :   null,
        so_sa_pending_qty                       :   0,
        item_id                                 :   null,
        qty                                     :   0,
        remark                                  :   '',
        isAddRemark                             :   'N',
        stock_loading                           :   false,

        batch_number                            :   null,
        expiry_date                             :   null,
        total_qty                               :   0,
        free_qty                                :   0,
        current_stocks                          :   [],
        selected_stock                          :   null,

        item                                    :   null,
        amount                                  :   0,
        gst                                     :   0,
        rate                                    :   0,
        discount_rate                           :   0,
        discount_type                           :   'Percentage',
        
        selected_allocation: null,
        allApiCallsCompleted: false
    }

    let initSwitchBatchNumberData               =   {
        item                                    :   null,
        selected_batch_number                   :   null,
        selected_expiry_date                    :   null,
        itemCurrentStocks                       :   [],
        itemSelectedStock                       :   null,
        itemCheckedStock                        :   null,
        selected_item_row                       :   null
    }

    let initSaSoItemData                        =   {
        loading                                 :   false,
        submitting                              :   false,
        items                                   :   [],
        selected_items                          :   [],
        include_so_item                         :   'N'
    }

    let history                                 =   useHistory();
    const access_token                          =   useSelector(state => state.auth.access_token);
    const default_currency                      =   useSelector(state => state.app.default_currency || 'INR');
    const track_batch_expiry                    =   useSelector(state => state.app.isc_configuration && state.app.isc_configuration.track_batch_expiry || 'N');
    const negative_stock                        =   useSelector(state => state.app.isc_configuration && state.app.isc_configuration.negative_stock || 'N');
    const all_warehouses                        =   useSelector(state => state.app.warehouses ? state.app.warehouses : []);
    const permissions                           =   useSelector(state => state.app.acl_info && state.app.acl_info.permissions ? state.app.acl_info.permissions : []);
    const group_modules                         =   useSelector(state => state.app.acl_info && state.app.acl_info.group_modules ? state.app.acl_info.group_modules : []);

    const [formData, setFormData]               =   useState({...initFormData});
    let initVoucherItemRows                     =   [initVoucherItemRow];
    const [voucherItemRows, setVoucherItemRows] =   useState([...initVoucherItemRows]);
    const [showitemSearchModal, setShowitemSearchModal] =   useState(false);


    let customerAddModalRef                     =   useRef(null);
    let customerInputRef                        =   useRef(null);
    let itemDetailsRef                          =   useRef(null);
    let itemEditModalRef                        =   useRef(null);
    let addItemModalRef                         =   useRef(null);
    let itemSearchRef                           =   useRef(null);

    //Switch Batch Number Modal :-
    const [switchBatchNumberData, setSwitchBatchNumberData] =   useState({...initSwitchBatchNumberData});
    const [switchBatchNumModalId, setSwitchBatchNumModalId] =   useState(uuid());
    const switchBatchNumModalElem               =   useRef(null);
    const switchBatchNumModal                   =   useRef(null);
    const switchBatchNumModalOpen               =   ()  => switchBatchNumModal.current && switchBatchNumModal.current.show()
    const switchBatchNumModalClose              =   ()  => switchBatchNumModal.current && switchBatchNumModal.current.hide()

    //Sales Allocation Item Modal :-
    const [saSoItemsData, setSaSoItemsData]     =   useState({...initSaSoItemData});
    const [saSoItemModalId, setSaSoItemModalId] =   useState(uuid());
    const saSoItemModal                         =   useRef(null);
    const saSoItemModalElem                     =   useRef(null);
    const saSoItemModalOpen                     =   ()  => saSoItemModal.current && saSoItemModal.current.show()
    const saSoItemModalClose                    =   ()  => saSoItemModal.current && saSoItemModal.current.hide()



    let addFormDataUrl                          =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/add_form_data";
    let addDataUrl                              =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/add";
    let warehouseUrl                            =   ISC_API_BASE_URL_2 + '/warehouse';
    let itemDetailUrl                           =   INVENTORY_API_BASE_URL_2 + "/item/__replace_item_id";
    let itemCurentStockUrl                      =   INVENTORY_API_BASE_URL_2 + "/item_current_stock/__replace_item_id";
    let saSoItemListUrl                         =   INVENTORY_API_BASE_URL_2 + "/sales/pending_sales_and_allocation_item";

    useEffect(() => {
        switchBatchNumModalElem.current         =   document.getElementById(switchBatchNumModalId);
        switchBatchNumModal.current             =   new Modal(switchBatchNumModalElem.current, {keyboard: false, backdrop :false});

        saSoItemModalElem.current               =   document.getElementById(saSoItemModalId);
        saSoItemModal.current                   =   new Modal(saSoItemModalElem.current, {keyboard: false, backdrop :false});

        //Load form Data :-
        loadFormData();
    }, []);

    const loadFormData                          =  ()  =>  {
        setFormData(pd => ({...pd, loading : true, data_loaded : false }));
        HttpAPICall.withAthorization('GET', addFormDataUrl, access_token, {}, {}, (response) => {
            let respData                =   response.data;

            let discount_types          =   respData.discount_type ? respData.discount_type.map((s) => { return {value: s, label: s === "Fixed" ? default_currency : "%"}; }) : [];
            setFormData(pd => ({...pd,
                discount_types  :   discount_types,
                data_loaded     :   true,
                warehouses      :   all_warehouses 
                                        ? all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) 
                                        : [],
                gst_slabs       :   respData.gst_slabs 
                                        ? respData.gst_slabs.map((s) => { return { value: s, label: s + '%' } })
                                        : [],
                status_list     :   respData.status && respData.status.length > 0 
                                        ? respData.status.map((s) => { return {value: s.id, label: s.name}; })
                                        : [],
                sales_enable_tcs:   respData.setting && respData.setting.sales_enable_tcs || 'Y',                        
                tcs_types       :   discount_types
            }));

        }).then(() => {



            setFormData(pd => ({...pd, loading : false }));
            if(props.location.state && props.location.state && props.location.state.editVoucherData) {
                let voucherData             =   props.location.state.editVoucherData;

                setFormData(pd => ({...pd,
                    transaction_id              :   voucherData.transaction_id,
                    transaction_date            :   new Date(voucherData.transaction_date),
                    warehouse_id                :   voucherData.warehouse_id,
                    warehouse                   :   voucherData.warehouse,
                    customer_id                 :   voucherData.customer_id,
                    customer_billing_address_id :   voucherData.customer_billing_address_id,
                    customer_shipping_address_id:   voucherData.customer_shipping_address_id,
                    customer_project_id         :   voucherData.customer_project_id,
                    status_id                   :   voucherData.status_id,
                    invoice_type                :   voucherData.invoice_for,
                    selected_asset              :   voucherData.asset 
                                                        ? {...voucherData.asset, label: voucherData.asset.name }
                                                        : null,
                    selected_workstation        :   voucherData.workstation 
                                                        ? {...voucherData.workstation, label: voucherData.workstation.workstation_name }
                                                        : null,
                    selected_employee           :   voucherData.employee 
                                                    ? {...voucherData.employee, label: voucherData.employee.name }
                                                    : null,
                    selected_subassembly        :   voucherData.sub_assembly 
                                                        ? {...voucherData.sub_assembly, label: voucherData.sub_assembly.name }
                                                        : null,
                    workstation_id              :   voucherData.workstation_id,
                    asset_id                    :   voucherData.asset_id,
                    employee_id                 :   voucherData.employee_id,
                    sub_assembly_id             :   voucherData.sub_assembly_id,
                    voucher_number              :   voucherData.voucher_number,
                    voucher_date                :   voucherData.voucher_date ? new Date(voucherData.voucher_date) : null,
                    tcs_rate                    :   voucherData.tcs_rate,
                    tcs_type                    :   voucherData.tcs_type,
                    adjustment_amount           :   voucherData.adjustment_amount,
                    notes                       :   voucherData.notes,
                    search_deal                 :   voucherData.deal,
                    deal_id                     :   voucherData.deal_id
                }));


                changeWarehouseHandler(voucherData.warehouse_id, true);

                handleCustomerChange(voucherData.customer_id, voucherData.customer_shipping_address_id, voucherData.customer_billing_address_id, voucherData.customer_project_id);

                console.log('voucherDatavoucherData', voucherData);

                let voucherItemRows     =   voucherData.items.map((vi,k) => {
                                                return {
                                                    ...initVoucherItemRow,
                                                    item_id             :   vi.item_id,
                                                    qty                 :   vi.qty,
                                                    stock_loading       :   false,
                                                    item                :   vi.item_profile,
                                                    rate                :   vi.rate,
                                                    sa_item_id          :   vi.sa_item_id,
                                                    so_item_id          :   vi.so_item_id,
                                                    sa_transaction_id   :   vi.sa_transaction_id,
                                                    so_transaction_id   :   vi.so_transaction_id,
                                                    batch_number        :   vi.batch_number,
                                                    expiry_date         :   vi.expiry_date,
                                                    invoice_item_id     :   vi.invoice_item_id,
                                                    discount_rate       :   vi.discount_rate,
                                                    discount_type       :   vi.discount_type,
                                                    remark              :   vi.remark,
                                                    isAddRemark         :   vi.remark ? 'Y' : 'N',
                                                    gst                 :   parseFloat(vi.gst)
                                                }
                                            });

                console.log('voucherItemRows', voucherItemRows);
                
                setVoucherItemRows(d => ([...voucherItemRows]));
 
                setTimeout(() => {
                    voucherItemRows.forEach((list, key) => {
                        let item_id                     =   list.item_id;
                        let batch_number                =   list.batch_number;
                        let expiry_date                 =   list.expiry_date;

                        fetchCurrentStock(item_id,batch_number,expiry_date,key,list, 1).then(() => {
                            if (voucherItemRows.length === key+1) {
                                //Add new item row line :-
                                addNewItemRow(); 
                            }
                        });
                    });
                }, 1000);

                console.log('voucherDatavoucherData', voucherData);
            
            }
            // if(props.location.state && props.location.state && props.location.state.addSalesInVoiceFromSalesOrder) {
            //     let voucherData             =   props.location.state.addSalesInVoiceFromSalesOrder;

            //     setFormData(pd => ({...pd,
            //         transaction_id              :   voucherData.transaction_id,
            //         transaction_date            :   new Date(voucherData.transaction_date),
            //         warehouse_id                :   voucherData.warehouse.id,
            //         warehouse                   :   voucherData.warehouse,
            //         customer_id                 :   voucherData.customer_id,
            //         customer_billing_address_id :   voucherData.customer_billing_address_id,
            //         customer_shipping_address_id:   voucherData.customer_shipping_address_id,
            //         customer_project_id         :   voucherData.customer_project_id,
            //         search_deal                 :   voucherData.deal,
            //         deal_id                     :   voucherData.deal_id
            //     }));


            //     changeWarehouseHandler(voucherData.warehouse.id, true);

            //     handleCustomerChange(voucherData.customer_id, voucherData.customer_shipping_address_id, voucherData.customer_billing_address_id, voucherData.customer_project_id);

            //     console.log('voucherDatavoucherData', voucherData);

            //     let voucherItemRows     =   voucherData.items.map((vi,k) => {
            //                                     return {
            //                                         ...initVoucherItemRow,
            //                                         item_id             :   vi.item_id,
            //                                         qty                 :   vi.qty,
            //                                         stock_loading       :   false,
            //                                         item                :   vi.item_profile,
            //                                         rate                :   vi.rate,
            //                                         sa_item_id          :   vi.sa_item_id,
            //                                         so_item_id          :   vi.so_item_id,
            //                                         sa_transaction_id   :   vi.sa_transaction_id,
            //                                         so_transaction_id   :   vi.so_transaction_id,
            //                                         batch_number        :   vi.batch_number,
            //                                         expiry_date         :   vi.expiry_date,
            //                                         invoice_item_id     :   vi.invoice_item_id,
            //                                         discount_rate       :   vi.discount_rate,
            //                                         discount_type       :   vi.discount_type,
            //                                         remark              :   vi.remark,
            //                                         isAddRemark         :   vi.remark ? 'Y' : 'N',
            //                                         gst                 :   parseFloat(vi.gst)
            //                                     }
            //                                 });
                
            //     setVoucherItemRows(d => ([...voucherItemRows]));
 
            //     setTimeout(() => {
            //         voucherItemRows.forEach((list, key) => {
            //             let item_id                     =   list.item_id;
            //             let batch_number                =   list.batch_number;
            //             let expiry_date                 =   list.expiry_date;

            //             fetchCurrentStock(item_id,batch_number,expiry_date,key,list, 1).then(() => {
            //                 if (voucherItemRows.length === key+1) {
            //                     //Add new item row line :-
            //                     addNewItemRow(); 
            //                 }
            //             });
            //         });
            //     }, 1000);

            //     console.log('voucherDatavoucherData', voucherData);
            
            // }

            if(searchParams.get('dealId')) {
                const storedData = localStorage.getItem('selectedDealToAddInvoice');
                // console.log(storedData);
                

                // if (storedData) {
                //     const {deal_header,customerData,customerProjectData } = JSON.parse(storedData);
                //     let deal_id = searchParams.get('dealId');

                //     setFormData(pd => ({...pd,
                //         deal_id             :   deal_id ? deal_id : '',
                //         customer_id         :   customerData ? customerData.id : '',
                //         customer_project_id :   customerProjectData ? customerProjectData.id : '',
                //     }));


                //     // this.setState({
                //     //     salesForm  :   {
                //     //         ...this.initSalesForm,
                //     //         deal_id             :   deal_id ? deal_id : '',
                //     //     },
                //     //     searchedCustomer        :   {value : customerData.id  ,display_label : customerData.name },
                //     //     CustomerDetails         :   {value : customerData.id  ,display_label : customerData.name },
                //     //     selectedCustomerAdress  :  customerProjectData ? customerProjectData.address :  null,
                //     //     selectedProject         :   customerProjectData ? customerProjectData : null,
                //     //     transactionFromDeal     :   true,
                //     //     searchedDeal            :  deal_id ?  {value : deal_id  ,display_label : deal_header,label:deal_header } : null,
                //     //     }, () => {
                //     //         // let transactionIdSetting = this.state.allSalesTypes ? this.state.allSalesTypes.find(pt => pt.value == "sales_order") : null
                //     //         // this.setState({
                //     //         //     transactionIdSetting   : transactionIdSetting ? transactionIdSetting.type_data : null
                //     //         // })
                //     //         this.getCustomerDetails(this.state.salesForm.customer_id,this.state.salesForm.customer_project_id ? true : false);
                //     //         this.handleCustomerChange(this.state.searchedCustomer,true);
                //     //         //Remanage Status of Sales Order Order :-
                //     //         //this.filterAllStatusOptions();
                        
                //     // });
                // } 
            } 

        });
    }




    const changeWarehouseHandler                =   (warehouse_id, edit_mode=false)  =>  {
        setFormData(d => ({...d, warehouse_id : warehouse_id}));
        if (warehouse_id) {
            setFormData(d => ({...d, warehouseLoading : true, warehouse_sites : [], warehouse : null}));
            HttpAPICall.withAthorization('GET', warehouseUrl + '/' + warehouse_id, access_token, {}, {}, (resp) => {
                let respData            =   resp.data;

                let data                =   {
                    warehouseLoading        :   false, 
                    warehouse_sites         :   respData.data.sites, 
                    warehouse               :   respData.data,
                };
                if(edit_mode == false) {
                    data                    =   {
                        ...data,
                        selected_asset          :   null,
                        selected_workstation    :   null,
                        selected_employee       :   null,
                        selected_subassembly    :   null,
                        workstation_id          :   '',
                        asset_id                :   '',
                        employee_id             :   '',
                        sub_assembly_id         :   ''
                    }
                    //Update Invoice Item List (Remove Warehouse Items (SA & SO & All Items)) :-
                    setVoucherItemRows(d => ([...initVoucherItemRows]));
                }
                setFormData(d => ({...d, ...data}));
            }).then(() => {
                setFormData(d => ({...d, warehouseLoading : false}));
            });
        } else {
            setFormData(d => ({...d, warehouse : null}));
        }
    }

    const handleCustomerChange                  =   (customer_id, customer_shipping_address_id = null, customer_billing_address_id=null, customer_project_id=null)  =>  {
        if(customerInputRef.current) {
            customerInputRef.current.updateCustomerDetail(customer_id, customer_shipping_address_id, customer_billing_address_id, customer_project_id);
        }
    }

    const viewItemDetailModal                   =   (item)  =>  {
        if(itemDetailsRef.current) {
            itemDetailsRef.current.modalInit(item);
        }
    }

    const viewItemEditModal                     =   (item, itemKey)  =>  {
        setFormData(d => ({...d, selected_item : item, selected_item_row : itemKey}));
        if (itemEditModalRef.current) {
            itemEditModalRef.current.itemEditModalInit(item);
        }
    }

    const submitEditItem                        =   ()  =>  {
        // HttpAPICall.withAthorization('GET', itemDetailUrl.replace('__replace_item_id', formData.selected_item.id), access_token, null, null, (res) => {
        // let selectedItem = {
        //     label: (<>
        //         <b>Item : </b> {res.data.data.name} <small> ({res.data.data.item_code})</small><br />
        //         <small>
        //             <b>Manufacturer : </b> {res.data.data.manufacturer ? res.data.data.manufacturer.name : ''}<br />
        //             <b>MPN : </b> {res.data.data.manufacturer_part_no}</small></>),
        //     value: res.data.data.id,
        //     display_label: `${res.data.data.name} (${res.data.data.item_code})`,
        //     itemdata: res.data.data
        // };

        // onItemSelect(selectedItem, formData.selected_item_row);
    }
    
    const addNewItemModalInit                   =   ()  =>  {
        if (addItemModalRef.current) {
            addItemModalRef.current.itemAddModalInit();
        }
    }

    const itemSearchModalInit                   =   async ()  =>  {
        await setShowitemSearchModal(true);
        let item_ids                        =   voucherItemRows.filter(i => i.item ? true : false).map(item => { return (item?.item?.id) });
        if (itemSearchRef.current) {
            itemSearchRef.current.searchItemModalInit(item_ids);
        }
    }

    const submitItemSearchForm                  =   (selectedItems)  =>  {
        let allRows                         =   voucherItemRows ? voucherItemRows.filter(i => i.item ? true : false) : [];
        setVoucherItemRows(d => ([...allRows]));
        selectedItems.map((itemData, k) => {
            let new_key                     =   k + allRows.length;
            let so                          =   itemData;
            onItemSelect(so, new_key);
        });
    }

    const removeItemRow                         =   (k, itemRow, item_id)  =>  {
        let invoiceItemRows                 =   voucherItemRows;
        if (invoiceItemRows.length >0) {
            invoiceItemRows.splice(k, 1);
            setVoucherItemRows(d => ([...invoiceItemRows]));
        }
    }

    const addNewItemRow                         =    () => {
        setVoucherItemRows(d => ([...voucherItemRows, ...initVoucherItemRows]));
    }

    const fetchCurrentStock                     =   (item_id,batch_number,expiry_date,itemRowKey, newInvoiceItemRowData, edit_mode=false)  =>  {
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token };
        return Promise.all([
            axios({ method: 'GET', headers: headers, url: itemDetailUrl.replace('__replace_item_id', item_id)}),
            axios({ method: 'GET', headers: headers, url: itemCurentStockUrl.replace('__replace_item_id', item_id), params: { interchangeable_items_current_stock: "N", warehouse_id: formData.warehouse_id} }),
        ])
        .then((results) => {
            let itemData                =   results[0].data.data;
            let current_stock_list      =   results[1].data;
            let current_stocks          =   current_stock_list.current_stock;

            //let newInvoiceItemRowData   =   newInvoiceItemRowsData[itemRowKey];

            let selectedStock           =   current_stocks.length >= 0 && newInvoiceItemRowData  ? (newInvoiceItemRowData.allocation_item_id 
                                                ? current_stocks.find(cs => cs.batch_number === batch_number && cs.expiry_date === expiry_date)
                                                : current_stocks[0]) : null;

            let invoiceItemRow          =   {
                ...initVoucherItemRow,
                ...newInvoiceItemRowData,
                stock_loading               :   false,
                item                        :   itemData,
                current_stocks              :   current_stocks && current_stocks.length > 0 ? current_stocks : [],
                batch_number                :   selectedStock ? selectedStock.batch_number : null,
                expiry_date                 :   selectedStock ? selectedStock.expiry_date : null,
                total_qty                   :   selectedStock ? selectedStock.total_qty : null,
                free_qty                    :   selectedStock ? selectedStock.free_qty : null,
                selected_stock              :   selectedStock
            };

            if(!edit_mode) {
                invoiceItemRow          =   {
                    rate                        :   itemData.consumption_price ? itemData.consumption_price : 0,
                };
            }






            let invoiceItemRows             =   voucherItemRows;
            invoiceItemRows[itemRowKey]            =   invoiceItemRow;
            setVoucherItemRows(d => ([...invoiceItemRows]));
            // newInvoiceItemRowsData[itemRowKey]     =   {...newInvoiceItemRowData};
            // setVoucherItemRows(d => ([...newInvoiceItemRowsData]));
        });
    }

    const onItemSelect                          =   (so,key) => {
        if(formData.warehouse_id == null) {
            toast.error("Warehose is not selected.", {position: toast.POSITION.TOP_RIGHT});
        } else if(so) {
            let itemData                =   so.itemdata;
            let invoiceItemRows         =   voucherItemRows;
            let invoiceItemRow          =   {...initVoucherItemRow, 
                                                item_id         :   itemData.id,
                                                qty             :   1,
                                                stock_loading   :   true,
                                                item            :   itemData,
                                                rate            :   itemData.consumption_price ? itemData.consumption_price : 0,
                                            };
            invoiceItemRows[key]        =   invoiceItemRow;
            setVoucherItemRows(d => ([...invoiceItemRows]));
            //Now load the Current Stock :-
            setTimeout(() => {
                fetchCurrentStock(itemData.id,null,null,key, invoiceItemRow);
            }, 1000);
        }
    }

    const changeBatchNumberModalInit            =   (itemRow, itemKey) => {
        setSwitchBatchNumberData(pd => ({
            ...pd,
            item                            :   itemRow.item,
            selected_batch_number           :   itemRow.batch_number,
            selected_expiry_date            :   itemRow.expiry_date,
            itemCurrentStocks               :   itemRow.current_stocks,
            itemSelectedStock               :   itemRow.selected_stock,
            itemCheckedStock                :   itemRow.selected_stock,
            selected_item_row               :   itemKey
        }));
        switchBatchNumModalOpen();
    }

    const handleSelectedbatchNumberData         =   (checked_stock)  =>  {
        setSwitchBatchNumberData(pd => ({
            ...pd,
            itemCheckedStock                :   checked_stock
        }));
    }

    const submitSelectedbatchNumberData         =   ()  => {
        let selectedStock                       =   switchBatchNumberData.itemCheckedStock;
        let itemRowKey                          =   switchBatchNumberData.selected_item_row;
        if(!selectedStock) {

        } else {
            setSwitchBatchNumberData(pd => ({
                ...pd,
                itemSelectedStock               :   selectedStock
            }));

            let newInvoiceItemRowsData      =   voucherItemRows;
            let newInvoiceItemRowData       =   voucherItemRows[itemRowKey];
            newInvoiceItemRowData           =   {
                ...newInvoiceItemRowData,
                batch_number                    :   selectedStock ? selectedStock.batch_number : null,
                expiry_date                     :   selectedStock ? selectedStock.expiry_date : null,
                total_qty                       :   selectedStock ? selectedStock.total_qty : null,
                free_qty                        :   selectedStock ? selectedStock.free_qty : null,
                selected_stock                  :   selectedStock
            };
            newInvoiceItemRowsData[itemRowKey]     =   {...newInvoiceItemRowData};
            setVoucherItemRows(d => ([...newInvoiceItemRowsData]));
            
            switchBatchNumModalClose();
        }
    }

    const saSoItemSubmitHandler                 =   ()  =>  {
        console.log('saSoItemsData1111', saSoItemsData);
        let selectedItems                           =   saSoItemsData.items ? saSoItemsData.items.filter(i => i.checked) : [];


        console.log('selectedItems', selectedItems);


        if(selectedItems.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(selectedItems.length > 0) {
            if(selectedItems.filter(i => i.new_qty > i.pending_qty).length > 0) {
                toast.error('Invoice Qty can not more than Pending Qty.', { position: toast.POSITION.TOP_RIGHT });
            } else if(selectedItems.filter(i => i.new_qty <= 0).length > 0) {
                toast.error('Invoice Qty must be more than 0.', { position: toast.POSITION.TOP_RIGHT });
            } else {
                handleSelectedItemsToAddInvoice(selectedItems);
            }
        } else {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const handleSelectedItemsToAddInvoice       =   async (selectedItems)  =>  {

        let newInvoiceItemRowsData  =   [...voucherItemRows].filter(ir => ir.item).filter(ir => ir.sa_item_id == null && ir.so_item_id == null );

        let remain_itemrow_count    =   newInvoiceItemRowsData.length;

        setSaSoItemsData(pd => ({...pd, submitting : true }));

        selectedItems.forEach((item, key) => {
            console.log(item, key);
            let data                =   {...initVoucherItemRow, 
                                            sa_item_id              :   item.sa_item_id,
                                            sa_transaction_id       :   item.sa_transaction_id,
                                            so_item_id              :   item.so_item_id,
                                            so_transaction_id       :   item.so_transaction_id,
                                            item_id                 :   item.item_id,
                                            qty                     :   parseFloat(item.new_qty),
                                            stock_loading           :   true,
                                            batch_number            :   item.batch_number,
                                            expiry_date             :   item.expiry_date,
                                            pending_qty             :   item.pending_qty,
                                            so_sa_pending_qty       :   item.pending_qty
                                        };
            newInvoiceItemRowsData.push(data);
        });

        await setVoucherItemRows(d => ([...newInvoiceItemRowsData]));
        
        setTimeout(() => {
            selectedItems.forEach((list, key) => {
                let item_id                     =   list.item_id;
                let batch_number                =   list.batch_number;
                let expiry_date                 =   list.expiry_date;
                let itemRowKey                  =   key + remain_itemrow_count;

                fetchCurrentStock(item_id,batch_number,expiry_date,itemRowKey,newInvoiceItemRowsData[itemRowKey]).then(() => {


                    console.log(selectedItems.length,  key+1);



                    if (selectedItems.length === key+1) {
                        setSaSoItemsData(pd => ({ ...pd, submitting: false }));
                        //Add new item row line :-
                        addNewItemRow();
                        //Close Modal :-
                        saSoItemModalClose();
                    }
                });
            });
        }, 1000);
    }

    const pickSaSoItemModalInit                 =   ()  =>  {
        if(formData.warehouse_id && formData.customer_id) {
            //Open Modal :-
            saSoItemModalOpen();
            let params                      = {
                search_warehouse_id         :   formData.warehouse_id,
                search_customer_id          :   formData.customer_id,
                include_item_sales_order    :   saSoItemsData.include_so_item,
            };
            setSaSoItemsData(pd => ({...pd, loading : true }));
            HttpAPICall.withAthorization('GET', saSoItemListUrl, access_token, params, {}, (resp) => {
                setSaSoItemsData(pd => ({
                    ...pd, 
                    items       :   resp.data.map(d => { return ({ ...d, new_qty: d.pending_qty, checked : false }) }) 
                }));
            }).then(() => {
                setSaSoItemsData(pd => ({...pd, loading : false }));
            });
        } else {
            toast.error('Please Select WareHouse and Customer', { position: toast.POSITION.TOP_RIGHT })
        }
    }

    const closeInvoiceAddForm                   =   ()  => {

        console.log(props.location.state);

        let state_data                              =   {};
        if (props.location && props.location.state && props.location.state.listing_page_number) {
            state_data['page_number']               =   props.location.state.listing_page_number;
        }
        if (props.location && props.location.state && props.location.state.voucherData) {
            state_data['voucherData']               =   props.location.state.voucherData;
        }

        console.log(state_data);

        history.push({pathname : '/sales_invoice', state : state_data});

    }

    const invoiceFormSubmitHandler              =   (event) =>   {
        event.preventDefault();

        let data                                =   {
            ...TapHelper.pick(formData, ['id','transaction_id','warehouse_id', 'customer_id', 'customer_billing_address_id', 'customer_shipping_address_id', 'customer_project_id', 'status_id', 'deal_id', 'invoice_type', 'sub_assembly_id','employee_id','asset_id','workstation_id', 'voucher_number','tcs_rate','tcs_type','adjustment_amount', 'notes', 'update_item_sale_price']),
            transaction_date                    :   formData.transaction_date ? moment(formData.transaction_date).format("YYYY-MM-DD") : null,
            voucher_date                        :   formData.voucher_date ? moment(formData.voucher_date).format("YYYY-MM-DD") : null,
        };

        let itemsData                           =   voucherItemRows.filter(i => i.item_id).map((voucherItemRow, itemRowKey) => {
            return {
                ...TapHelper.pick(voucherItemRow, ['invoice_item_id', 'sa_item_id', 'so_item_id', 'sa_transaction_id', 'so_transaction_id', 'so_sa_pending_qty', 'item_id', 'qty', 'remark', 'batch_number','free_qty', 'gst','rate', 'discount_rate', 'discount_type']),
                expiry_date                 :   voucherItemRow.expiry_date ? moment(voucherItemRow.expiry_date).format("YYYY-MM-DD") : null
            };
        });


        let showErrors                          =   [];

        if (data.transaction_date == '') {
            showErrors = [...showErrors, 'Please enter Transaction Date.'];
        }
        if (data.warehouse_id == '') {
            showErrors = [...showErrors, 'Please select Warehouse.'];
        }
        if (data.status_id == '') {
            showErrors = [...showErrors, 'Please select Status'];
        }
        if (itemsData.length == 0) {
            showErrors = [...showErrors, 'Please select Item and their invoice qty.'];
        }  else if (itemsData.find(i => i.item && parseFloat(i.qty) <= 0)) {
            showErrors = [...showErrors, 'Every Item\'s Invoice qty must be more than 0.'];
        } else if (itemsData.find(i => i.item && i.qty == '')) {
            showErrors = [...showErrors, 'Every Item\'s Invoice qty should not be blank'];
        }

        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        } else {
            data                        =   { ...data, items: itemsData};
            if(data.transaction_id) {

                console.log(data);


            } else {
                setFormData(d => ({...d, submitting : true}));
                HttpAPICall.withAthorization('POST', addDataUrl, access_token, {}, data, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    closeInvoiceAddForm();
                }).then(() => {
                    setFormData(d => ({...d, submitting : false}));
                });
            }
        }
        
      
    

  
        //console.log(data);
          


        // voucherItemRows
        // formData

        
    }

    const saSoItemListModalJsx                  =   ()  =>  {
        return <><div className="modal fade" id={saSoItemModalId} tabIndex="-1" ref={saSoItemModalElem}>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Pick Item from Sales Allocation & Sales Order</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={false} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-bordered mb-2">
                            <tbody>
                                <tr>
                                    <td style={{width:"25%"}}>Warehouse</td>
                                    <th style={{width:"75%"}}>{formData.warehouse && formData.warehouse.name || '-'}</th>
                                </tr>
                                <tr><td>Customer</td><th>{formData.customer && formData.customer.name || '-'}</th></tr>
                            </tbody>
                        </table>
                        <table className="table table-hover table-bordered table-sm my-2">
                            <thead className="table-secondary">
                                <tr>
                                    <th style={{ width: "5%" }} className="text-center">#</th>
                                    <th style={{ width: "15%" }}>Item Name</th>
                                    <th style={{ width: "15%" }}>Item Code</th>
                                    <th style={{ width: "15%" }}>Manufacturer</th>
                                    <th style={{ width: "12%" }} className="text-center">Sales Order ID</th>
                                    <th style={{ width: "10%" }} className="text-center">Sales Order Date</th>
                                    <th style={{ width: "12%" }} className="text-center">Allocation ID</th>
                                    <th style={{ width: "10%" }} className="text-center">Allocation Date</th>
                                    <th style={{ width: "10%" }} className="text-center">Total Qty</th>
                                    <th style={{ width: "12%" }} className="text-center">Pending Qty</th>
                                    <th style={{ width: "07%" }} className="text-center">Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {saSoItemsData.loading ? <tr><td colSpan={11}><Loader /></td></tr> : ( saSoItemsData.items.length > 0 ? (saSoItemsData.items.map((sasoItem, sak) => {
                                    return <tr key={sak} className='align-middle'>
                                    <td className='text-center'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox"
                                                id={"sa_so_item_id"+sak}
                                                checked={sasoItem.checked}
                                                onChange={(e) => {
                                                    let new_selected_items          =   saSoItemsData.items;
                                                    new_selected_items[sak].checked =   e.target.checked; 
                                                    setSaSoItemsData(pd => ({ ...pd, items : [...new_selected_items] }));
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td><label htmlFor={"sa_so_item_id"+sak}>{sasoItem.item_name ? sasoItem.item_name : '-'}</label></td>
                                    <td>{sasoItem.item_code ? sasoItem.item_code ?? "-" : '-'}</td>
                                    <td>{sasoItem.manufacturer_name ? sasoItem.manufacturer_name ?? "-" : '-'}</td>
                                    <td className='text-center'>{sasoItem.so_transaction_id ? sasoItem.so_transaction_id : "-"}</td>
                                    <td className='text-center'>{sasoItem.so_transaction_date ? DateService.dateFormat(sasoItem.so_transaction_date) : "-"}</td>
                                    <td className='text-center'>{sasoItem.sa_transaction_id ? sasoItem.sa_transaction_id : "-"}</td>
                                    <td className='text-center'>{sasoItem.sa_transaction_date ? DateService.dateFormat(sasoItem.sa_transaction_date) : "-"}</td>
                                    <td className='text-end'>{sasoItem.qty ? sasoItem.qty : "-"}</td>
                                    <td className='text-end'>{sasoItem.pending_qty ? sasoItem.pending_qty : "-"}</td>
                                    <td>
                                        <TapInputNumber
                                            className='form-control form-control-sm text-end'
                                            placeholder='Allocated Quantity'
                                            name="new_qty"
                                            value={sasoItem.new_qty}
                                            onChange={(v) => {
                                                let new_selected_items          =   saSoItemsData.items;
                                                new_selected_items[sak].new_qty =   v; 
                                                setSaSoItemsData(pd => ({ ...pd, items : [...new_selected_items] }));
                                            }}
                                            max={sasoItem.pending_qty}
                                        />
                                    </td>
                                </tr>}) ) : <tr><td>No Record</td></tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" onClick={saSoItemSubmitHandler} disabled={saSoItemsData.loading || saSoItemsData.submitting} className="btn btn-primary">Submit {saSoItemsData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />} </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    }

    const switchItemRowBatchNumberModalJsx      =   ()  =>  {
        return <><div className="modal fade" id={switchBatchNumModalId} tabIndex="-1" ref={switchBatchNumModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select Item's Batch Number and Expiry Date</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={false} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="tab_content_wrapper"><span className="content_heading">Item Detail</span></div>
                        <table className="table table-hover table-borderless my-2">
                            <tbody>
                                {switchBatchNumberData.item ? <>
                                    <tr>
                                        <td style={{ width: "15%" }}>Item Name</td>
                                        <th style={{width:"35%"}}>{switchBatchNumberData.item.name}</th>
                                        <td style={{ width: "15%" }}>Item Code</td>
                                        <th style={{width:"35%"}}>{switchBatchNumberData.item.item_code}</th>
                                    </tr>
                                    <tr>
                                        <td>Warehouse</td>
                                        <th colSpan={3}>{formData.warehouse ? formData.warehouse.name : ''}</th>
                                    </tr></>
                                    : <tr><th colSpan={4}>No item selected.</th></tr>
                                }
                            </tbody>
                        </table>
                        <div className="tab_content_wrapper"><span className="content_heading">Batch & Expiry List</span></div>
                        <table className="table table-bordered table-sm bg-white my-2">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" rowSpan="2" style={{ width: "5%" }} className="text-center">S.No</th>
                                    <th scope="col" style={{ width: "20%" }}> Batch Number </th>
                                    <th scope="col" style={{ width: "15%" }}>Expiry Date</th>
                                    <th scope="col" style={{ width: "15%" }}>Total Qty</th>
                                    <th scope="col" style={{ width: "15%" }}>Free Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {switchBatchNumberData.itemCurrentStocks.length > 0 
                                    ? (switchBatchNumberData.itemCurrentStocks.map((cs, csk) => <tr className='text-center' key={csk}>
                                        <td>
                                            <input
                                                type="radio"
                                                name="selectedBatchNumber"
                                                checked={cs == switchBatchNumberData.itemCheckedStock}
                                                onChange={() => handleSelectedbatchNumberData(cs)}
                                            />
                                        </td>
                                        <td>{cs.batch_number || "-"}</td>
                                        <td>{cs.expiry_date_display || "-"}</td>
                                        <td>{cs.total_qty || "-"}</td>
                                        <td>{cs.free_qty || "-"}</td>
                                      </tr>)) 
                                    : <tr><td colSpan="5" className="text-center">No Records</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" onClick={() => submitSelectedbatchNumberData()}  className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    }

    const voucherItemFormJsx                    =   ()  =>  {
        return <div className="row my-3">
            <div className="col-sm-12">
                <table className="table table-bordered table-responsive bg-white ">
                    <thead className="table-secondary align-middle">
                        <tr className="text-center">
                            <th style={{ width: "5%" }}>S.No</th>
                            <th style={{ width: "27%" }}>Item Details</th>
                            {track_batch_expiry == "Y" && <th style={{ width: "15%" }}>Batch Number <br />Expiry Date</th>}
                            <th style={{ width: "15%" }}>Sales Order <br />Sales Allocation</th>
                            <th style={{ width: "8%" }}>Quantity</th>
                            <th style={{ width: "8%" }}>Unit Rate</th>
                            <th style={{ width: "8%" }}>Discount</th>
                            <th style={{ width: "8%" }}>GST</th>
                            <th style={{ width: "7%" }}>Amount</th>
                            <th style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {voucherItemRows.map((itemRow,itemKey) => {
                        let item                    =   itemRow && itemRow.item ? itemRow.item : null;
                        let item_id                 =   itemRow && itemRow.item_id ? itemRow.item_id : null ;
                        let choose_batch_number     =   true;
                        
                        return <tr className="" key={itemKey}>
                            <td className="text-center align-middle">{itemKey + 1}</td>
                            {item ? <>
                            <td>
                                <div className="row">
                                    <div className='col-10'>
                                        <Link className="text-decoration-none fw-bold" >{item.name}</Link>
                                    </div>
                                    <div className='col-2'>
                                        <button type="button" className="btn btn-link text-decoration-none" data-bs-toggle="dropdown" role="button" aria-expanded="false"><TapIcon.imageIcon icon={TapIcon.ListActionIcon} /></button>
                                        <ul className="dropdown-menu">
                                            <li><Link className="dropdown-item" onClick={() => { viewItemDetailModal(item) }}>View Details</Link></li>
                                            {permissions.includes("isc-item-edit") && <li><Link className="dropdown-item" onClick={() => { viewItemEditModal(item, itemKey) }}>Edit Item</Link></li>}
                                            <li><Link className="dropdown-item" onClick={() => {
                                                DataService.dynamicInputHandlerByKeyVal(itemKey, 'isAddRemark', 'Y', voucherItemRows, setVoucherItemRows);
                                            }}>Add Remark</Link></li>
                                        </ul>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-text"><b>Code: </b>{item.item_code}</div>
                                        <div className="form-text">
                                            <b>Mfr: </b>{item.manufacturer ? item.manufacturer.name : '-'} (<b>MPN:</b> {item.manufacturer_part_no ? item.manufacturer_part_no : '-'})
                                        </div>
                                        {itemRow.isAddRemark == 'Y' &&
                                            <textarea className="form-control mt10" name="remark"  value={itemRow.remark}  placeholder="Item Remarks"
                                                onChange={(e) => { DataService.dynamicInputHandlerByKeyVal(itemKey, 'remark', e.target.value, voucherItemRows, setVoucherItemRows ) }}
                                            />
                                        }
                                    </div>
                                </div>
                            </td>
                            {itemRow.stock_loading 
                                ? <td colSpan={track_batch_expiry == 'Y' ? 7 : 6}> <Loader /></td> 
                                : <>
                                    {track_batch_expiry == 'Y' && <td>
                                        <div className='py-1'>
                                            BN : <b> {itemRow.batch_number || ""} </b> <br />
                                            ED: <b> {DateService.dateFormat(itemRow.expiry_date) || ""} </b><br />
                                        </div>
                                        {itemRow.current_stocks.length > 1  && !(itemRow.sa_transaction_id || itemRow.so_transaction_id) &&
                                            <Link 
                                                type="button" className="text-decoration-none text-center"
                                                onClick={() => { changeBatchNumberModalInit(itemRow, itemKey); }}
                                            > Change Batch No</Link>
                                        }
                                    </td>}
                                    <td>
                                        <div>SO : {itemRow.so_transaction_id && itemRow.so_transaction_id}</div>
                                        <div>SA : {itemRow.sa_transaction_id && itemRow.sa_transaction_id}</div>
                                        <div>Pending Qty : {itemRow.so_sa_pending_qty ? itemRow.so_sa_pending_qty : 'Nil'}</div>
                                    </td>
                                    <td>
                                        <TapInputNumber
                                            className="form-control text-end"
                                            placeholder="Qty"
                                            required={true}
                                            min={.01}
                                            name="qty"
                                            value={itemRow.qty}
                                            onChange={(val) => { DataService.dynamicInputHandlerByKeyVal(itemKey, 'qty', val, voucherItemRows, setVoucherItemRows ) }}
                                        />
                                        {item && item.measuring_unit && <span className="text-helping input-group-text text-end pr0">{item.measuring_unit.name}</span>}
                                        
                                        {itemRow.current_stocks && <> <div className='py-1'>
                                            <small>Stock In Hand:  <b> {itemRow.total_qty || "-"} </b></small> <br />
                                            <small>Free Stock:<b> {itemRow.free_qty || "-"} </b></small> <br />
                                        </div> </>}
                                    </td>
                                    <td>
                                        <TapInputNumber
                                            name="rate"
                                            placeholder="Unit Rate"
                                            required={true}
                                            min={0}
                                            value={itemRow.rate}
                                            onChange={(val) => { DataService.dynamicInputHandlerByKeyVal(itemKey, 'rate', val, voucherItemRows, setVoucherItemRows ) }}
                                        />
                                    </td>
                                    <td>
                                        <TapInputNumber
                                            name="discount_rate"
                                            placeholder="Discount"
                                            min={0}
                                            value={itemRow.discount_rate}
                                            onChange={(val) => { DataService.dynamicInputHandlerByKeyVal(itemKey, 'discount_rate', val, voucherItemRows, setVoucherItemRows ) }}
                                        />
                                        <TapSelect
                                            placeholder="Select Discount Type"
                                            options={formData.discount_types}
                                            changeEvent={(so) => { DataService.dynamicInputHandlerByKeyVal(itemKey, 'discount_type', so.value, voucherItemRows, setVoucherItemRows ) }}
                                            value={formData.discount_types.find(d => itemRow.discount_type == d.value)}
                                        />
                                    </td>
                                    <td className='text-end rightSelectText'>
                                        {formData.warehouse && formData.warehouse.gstin ? <>
                                            <TapSelect
                                                placeholder="GST"
                                                options={formData.gst_slabs}
                                                value={formData.gst_slabs.find(d => itemRow.gst == d.value)}
                                                changeEvent={(so) => { DataService.dynamicInputHandlerByKeyVal(itemKey, 'gst', so.value, voucherItemRows, setVoucherItemRows ) }}
                                            />
                                            <span className="text-helping input-group-text text-end pr0">{formData.warehouse.state_id  == formData.customer_billing_state_id ? 'GST' : 'IGST'}</span>
                                        </> : <div className='text-center'>NA <sup className='text-danger'>#</sup></div>}
                                    </td>
                                    <td className="pt-4 text-end">{(itemRow.rate * itemRow.qty).toFixed(2)}</td>
                                </> }
                            </> : <>
                                <td className="align-middle">
                                    <InputItemSearch
                                        changeEvent={(so) => {
                                            onItemSelect(so,itemKey);
                                        }}
                                        only_active_item='Y'
                                        only_stocked_item={formData.only_stocked_item}
                                        stock_warehouse_ids={[formData.warehouse_id]}
                                    />
                                </td>
                                <td colSpan={track_batch_expiry == 'Y' ? 7 : 6}>{itemRow.stock_loading && <Loader /> }</td>
                            </>}
                            <td className="text-center align-middle">
                                <button type='button' className='btn btn-link text-decoration-none' onClick={() => removeItemRow(itemKey, itemRow, item_id)}>
                                    <TapIcon.imageIcon icon={TapIcon.CloseCircleIcon} className="img-fluid" />
                                </button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="form-check">
                            <input
                                id="id_only_stocked_item"
                                className="form-check-input"
                                type="checkbox"
                                value={formData.only_stocked_item == "Y" ? "N" : "Y"}
                                checked={formData.only_stocked_item == "Y"}
                                onChange={(e) => { DataService.handleFormState('only_stocked_item', e.target.value, formData, setFormData ) }}
                            />
                            <label className="form-check-label" htmlFor="id_only_stocked_item">Show only those items that are available in the selected warehouse</label>
                        </div>
                        <div className="form-check">
                            <input
                                id="id_update_item_sale_price"
                                className="form-check-input"
                                type="checkbox"
                                value={formData.update_item_sale_price == "Y" ? "N" : "Y"}
                                checked={formData.update_item_sale_price == "Y"}
                                onChange={(e) => { setFormData(d => ({...d, update_item_sale_price : e.target.value})) }}
                            />
                            <label className="form-check-label" htmlFor="id_update_item_sale_price">Update Item Sales Price</label>
                        </div>
                    </div>
                    <div className="col-sm-4 text-end">
                        <Link onClick={itemSearchModalInit} className="link-primary me-3"><TapIcon.FontAwesomeIcon icon={TapIcon.faSearch} /> Search Item </Link>
                        {permissions.includes("isc-item-add") && <Link onClick={addNewItemModalInit} className="link-primary me-3"><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} /> Add Item</Link>}
                        <Link onClick={addNewItemRow} className="link-primary"><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} /> Add Row </Link>
                    </div>
                </div>
            </div>
        </div>
    }
    
    const saveInvoiceFormJsx                    =   ()  =>  {
        let sub_total_amount                        =   0;
        let gst_total_amount                        =   0;
        let gst_slab                                =   [];
        let total_amount                            =   0;
        let invoice_summary                         =   [];
        let adjustment_amount                       =   Number(formData.adjustment_amount);
        
        voucherItemRows.forEach((item,key) => {
            if(item && item.item) {
                let item_amount             =   Number(item.qty * item.rate);
                let discount_amout          =   Number(item.discount_rate && item.discount_rate > 0
                                                    ?  item.discount_type == "Percentage" ? (item_amount*item.discount_rate/100) : item.discount_rate
                                                    : 0);
                let item_with_discount      =   item_amount - discount_amout;
                let tax_amount              =   0;
                
                if(item.gst && item.gst > 0 && formData.warehouse && formData.warehouse.gstin) {
                    tax_amount              =   item_with_discount * item.gst / 100;
                    if(!gst_slab[item.gst]) {
                        gst_slab[item.gst]  =   [];
                    }
                    gst_slab[item.gst].push(tax_amount);
                }
                let item_final_amount       =   item_with_discount + tax_amount;
                sub_total_amount           +=   item_with_discount;
                gst_total_amount           +=   tax_amount;
                total_amount               +=   item_final_amount; 
            }
        });
        
        invoice_summary.push({key : 'Sub Total' , value : sub_total_amount.toFixed(2)});

        if (gst_total_amount > 0) {
            gst_slab.forEach((tax_amounts, gst) => {
                let total_tax = tax_amounts.reduce((sum, a) => sum + a, 0);
                if (formData.warehouse && formData.warehouse.state_id  == formData.customer_billing_state_id) {
                    let tax = gst / 2;
                    invoice_summary.push({ key: 'CGST ' + tax + '%', value: (total_tax / 2).toFixed(2) });
                    invoice_summary.push({ key: 'SGST ' + tax + '%', value: (total_tax / 2).toFixed(2) });
                } else {
                    invoice_summary.push({ key: 'IGST ' + gst + '%', value: total_tax.toFixed(2) });
                }
            });
        } else if (gst_total_amount == 0) {
            if (formData.warehouse && formData.warehouse.state_id  == formData.customer_billing_state_id) {
                invoice_summary.push({ key: 'CGST ', value: "As applicable" });
                invoice_summary.push({ key: 'SGST ', value: "As applicable" });
            } else {
                invoice_summary.push({ key: 'IGST ', value: "As applicable" });
            }
        }

        let tcs_amount              =   Number(formData.tcs_rate && formData.tcs_rate >= 0  ? formData.tcs_type == "Percentage" ? (sub_total_amount*formData.tcs_rate/100) :  Number(formData.tcs_rate) : 0);

        return <form className="bg-white p-3" onSubmit={invoiceFormSubmitHandler}>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 form-label require">Date of Transaction</label>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={formData.transaction_date}
                        onChange={date => setFormData(pd => ({...pd, transaction_date : date }))}
                        dateFormat="dd-MMM-yyyy"
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        maxDate={new Date()}
                        placeholderText={`Please Enter Date `}
                    />
                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center mt-3 mb-2">
                <label className="col-sm-3 form-label require">Warehouse</label>
                <div className="col-sm-6">
                    <TapSelect
                        changeEvent={o => {
                            changeWarehouseHandler(o.value);
                        }}
                        placeholder="Select Warehouse"
                        isSearchable={true}
                        required={true}
                        options={formData.warehouses}
                        value={formData.warehouses.find(w => formData.warehouse_id == w.value)}
                    />
                </div>
            </div>
            {formData.warehouse && <div className="row align-items-center mb-3"><div className="offset-3 col-sm-8">
                <WarehouseAddress warehouseDetail={formData.warehouse} />
            </div></div> || (formData.warehouseLoading && <Loader /> )}
            <div className="row mt-3 mb-2">
                <label className="col-sm-3 form-label require">Customer</label>
                <div className="col-sm-9">
                    <CustomerInput
                        ref={customerInputRef}
                        customerAddModalRef={customerAddModalRef}
                        required={true}
                        afterChangeData={(resp) => {
                            setFormData(pd => ({...pd,
                                customer                        :   resp.customer,
                                customer_id                     :   resp.customer_id,
                                customer_billing_address_id     :   resp.billing_address_id,
                                customer_billing_state_id       :   resp.billing_state_id,
                                customer_shipping_address_id    :   resp.shipping_address_id,
                                customer_project_id             :   resp.customer_project_id,
                            }));
                            if(resp.customer_id && formData.customer_id && resp.customer_id != formData.customer_id) {
                                //Update Invoice Item List (Remove Customer Items (SA & SO Items)) :-
                                setVoucherItemRows(d => (
                                    [...voucherItemRows]
                                    .filter(ir => ir.item)
                                    .filter(ir => ir.sa_item_id == null && ir.so_item_id == null )
                                ));
                            }
                        }}
                        add_customer_btn={true}
                    />
                </div>
            </div>
            <div className="row align-items-center mt-3 mb-2">
                <label className="col-sm-3 form-label require">Status</label>
                <div className="col-sm-6">
                    <TapSelect
                        changeEvent={o => {
                            setFormData(d => ({...d, status_id : o ? o.value : null}));
                        }}
                        placeholder="Select Status"
                        isSearchable={true}
                        required={true}
                        options={formData.status_list}
                        value={formData.status_list.find(d => formData.status_id == d.value)}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 form-label">Deals</label>
                <div className="col-sm-6">
                    <InputDealSearch
                        value={formData.search_deal}
                        menuPlacement="top"
                        search_customer_id={formData.customer_id}
                        search_customer_project_id={formData.customer_project_id}
                        changeDeal={(o) => {
                            setFormData(d => ({...d, deal_id : o.deal_id, search_deal : o}));
                        }}
                        showSearch={true}
                        disabled={!formData.customer_id}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 form-label pt-2">Tag (Invoice For)</label>
                <div className="col-sm-4">
                    <TapSelect
                        placeholder="Select Invoice For"
                        isClearable={true}
                        options={formData.voucher_for_list}
                        value={formData.voucher_for_list.find(d => formData.invoice_type == d.value)}
                        changeEvent={o => {
                            setFormData(d => ({...d, 
                                invoice_type            :   o ? o.value : null,
                                selected_asset          :   null,
                                selected_workstation    :   null,
                                selected_employee       :   null,
                                selected_subassembly    :   null,
                                workstation_id          :   '',
                                asset_id                :   '',
                                employee_id             :   '',
                                sub_assembly_id         :   ''
                            }));
                        }}
                    />
                </div>
                <div className="col-sm-4">
                    {formData.invoice_type == 'asset' && <>
                        <InputAssetSearch
                            placeholder="Search Asset"
                            filter={true}
                            required={true}
                            search_site_id={formData.warehouse_sites.map((w) => w.id)}
                            value={formData.selected_asset ? { ...formData.selected_asset } : null}
                            changeEvent={(so) => {
                                setFormData(d => ({...d, 
                                    asset_id            :   so ? so.value : null,
                                    selected_asset      :   so ? {...so.asset, label: so.display_label } : null
                                }));
                            }}
                        />
                        {formData.selected_asset && <AssetMiniDetail assetDetail={formData.selected_asset} />}
                    </>}
                    {formData.invoice_type == 'workstation' && <>
                        <InputWorkstationSearch
                            placeholder="Search Workstation"
                            menuPlacement="top"
                            search_site_id={formData.warehouse_sites.map((w) => w.id)}
                            value={formData.selected_workstation}
                            changeEvent={(so) => {
                                setFormData(d => ({...d, 
                                    workstation_id      :   so ? so.value : null,
                                    selected_workstation:   so ? so : null
                                }));
                            }}
                        />
                    </>}
                    {formData.invoice_type == 'employee' && group_modules.includes("hrm") && <>
                        <InputEmployeeSearch
                            placeholder="Search Employee"
                            menuPlacement="top"
                            search_site_ids={formData.warehouse_sites.map((w) => w.id)}
                            transaction_date={formData.transaction_date ? formData.transaction_date : null}
                            value={formData.selected_employee}
                            changeEvent={(so) => {
                                setFormData(d => ({...d, 
                                    employee_id      :   so ? so.value : null,
                                    selected_employee:   so ? so : null
                                }));
                            }}
                        />
                    </>}
                    {formData.invoice_type == 'sub_assembly' && <>
                        <InputSubAssemblySearch
                            placeholder="Search Sub Assembly"
                            menuPlacement="top"
                            search_site_id={formData.warehouse_sites.map((w) => w.id)}
                            value={formData.selected_subassembly}
                            changeEvent={(so) => {
                                setFormData(d => ({...d, 
                                    sub_assembly_id     :   so ? so.value : null,
                                    selected_subassembly:   so ? so : null
                                }));
                            }}
                        />
                    </>}
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 form-label">Voucher Number & Date</label>
                <div className="col-sm-4">
                    <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => { setFormData(d => ({...d, voucher_number : e.target.value})); }}
                        value={formData.voucher_number}
                        placeholder="Please Enter Voucher Number"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={formData.voucher_date}
                        onChange={date => setFormData(pd => ({...pd, voucher_date : date }))}
                        dateFormat="dd-MMM-yyyy"
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        maxDate={new Date()}
                        placeholderText={`Please Enter Date `}
                    />
                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center align-middle my-3">
                <label className="col-sm-3 form-label">Sales Allocate Item</label>
                <div className="col-sm-4">
                    <button type='button' role="button" className="btn btn-link text-decoration-none" onClick={pickSaSoItemModalInit}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} /> Pick Item from Allocation </button>
                </div>
                <div className="form-check col-sm-4">
                    <input
                        id="id_include_so_item"
                        className="form-check-input"
                        type="checkbox"
                        value={saSoItemsData.include_so_item == "Y" ? "N" : "Y"}
                        checked={saSoItemsData.include_so_item == "Y"}
                        onChange={(e) => { DataService.handleFormState('include_so_item', e.target.value, saSoItemsData, setSaSoItemsData ) }}
                    />
                    <label className="form-check-label" htmlFor="id_include_so_item">Include Approved Sales Order</label>
                </div>
            </div>
            {voucherItemFormJsx()}
            <div className="row align-items-center my-3">
                <div className="col-sm-6">
                    <label className="form-label">Notes</label>
                    <textarea
                        value={formData.notes}
                        onChange={e => { setFormData(pd => ({...pd, notes : e.target.value})); }}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-sm-6">
                    <table className="table table-borderless table-sm subtotal_bg_color">
                        <tbody>
                            {invoice_summary && invoice_summary.length > 0
                                ? (invoice_summary.map((ps, k) => {
                                    return <tr className='align-middle' key={k}><th>{ps.key} ({default_currency})</th><td colSpan="2" className="text-end">{ps.value}</td></tr>
                                }))
                                : null
                            }
                            <tr className='align-middle'>
                                <th style={{width:"33%"}}>TCS</th>
                                <th style={{width:"33%"}}>
                                    <TapSelect
                                        placeholder="Select TCS TYPE"
                                        options={formData.tcs_types}
                                        changeEvent={(so) => { DataService.handleFormState('tcs_type', so.value, formData, setFormData) }}
                                        value={formData.tcs_types.find(d => formData.tcs_type == d.value)}
                                    />
                                </th>
                                <th style={{width:"34%"}}>
                                    <TapInputNumber
                                        name="tcs_rate"
                                        placeholder="TCS"
                                        min={0}
                                        value={formData.tcs_rate}
                                        onChange={(val) => { DataService.handleFormState('tcs_rate', val, formData, setFormData) }}
                                    />
                                </th>
                            </tr>
                            <tr className="align-middle">
                                <th colSpan="2">Adjustment Amount</th>
                                <th>
                                    <TapInputNumber
                                        name="adjustment_amount"
                                        placeholder="Adjustment"
                                        min={0}
                                        value={formData.adjustment_amount}
                                        onChange={(val) => { DataService.handleFormState('adjustment_amount', val, formData, setFormData) }}
                                    />
                                </th>
                            </tr>
                            <tr className="align-middle">
                                <th>Final Total ({default_currency})</th>
                                <th></th>
                                <td className='float-end'>
                                    <NumberFormatter number={((total_amount || 0) + (adjustment_amount || 0) + (tcs_amount || 0))} precision={2} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 text-end fixed_footer">
                <button type='button' onClick={closeInvoiceAddForm} className="btn btn-secondary"  disabled={formData.loading || formData.submitting}>Cancel</button>
                <button type='submit' className="btn btn-primary ms-1" disabled={formData.loading || formData.submitting}>
                    Save {formData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}
                </button>
            </div>
        </form>
    }

    const topRightHeaderJsx                     =   ()  =>  {
        return <>
            <button type="button" className="btn btn-secondary" disabled={formData.loading || formData.submitting} onClick={closeInvoiceAddForm} >
                <TapIcon.imageIcon icon={TapIcon.CloseIcon} />
            </button>
        </>
    }

    return <ApplicationLayout>
        <PageHeader title="Sales Invoice Add" name={formData.transaction_id ? ("Sales Invoice edit : " + formData.transaction_id) : "Sales Invoice Add"} rightTopHeadingJsx={topRightHeaderJsx()} />
        <div className="container-fluid pl5">
            <div className='page_containt row'>
                <div className="pageTbl col-sm-12 ">
                    {formData.loading ? <div className='text-center bg-white'><Loader /></div> : saveInvoiceFormJsx()}
                </div>
            </div>
        </div>

        {switchItemRowBatchNumberModalJsx()}
        {saSoItemListModalJsx()}
        <AddCustomerModal ref={customerAddModalRef} afterEditFormSubmit={(id) => { handleCustomerChange(id); }} /> 
        <ItemDetailsModal ref={itemDetailsRef} />
        <ItemEditModal parentProps={props} ref={itemEditModalRef} afterSubmitItem={submitEditItem} />
        <AddItemModal parentProps={props} ref={addItemModalRef} />
        {showitemSearchModal && <ItemSearchModal ref={itemSearchRef} afterSelectingItems={(selectedItems) => { submitItemSearchForm(selectedItems); }} />}

        
    </ApplicationLayout>
});

export default SalesInvoiceAdd;