import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from 'react-toastify';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Link } from 'react-router-dom';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';
import uuid from 'react-uuid';
import CurrencyFormatter from '../../../services/CurrencyFormatter';

class ConsumptionHistoryModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                            =         {
            consumtionHistoryList             :         [],
            consumptionDetailLoading          :         false,
            itemName                          :         '',
            itemCode                            :       '',
            itemData                          :         null,
            asset_id                          :         null,
            workstation_id                    :         null,
            listing_tbl_page                  :          1,
            listingMeta                       :          null,
            totalListingCount                 :         0,
        };
        this.consumptionsDetailModalId      =   uuid();
    }
    
    
    
    componentDidMount(){
        this.viewConsumptionHistoryModal             =   new Modal(document.getElementById(this.consumptionsDetailModalId), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (data,workstation_id,asset_id,consumption_type)  =>  {
        this.viewConsumptionHistoryModal.show()
            if(data){
                  this.setState({
                    itemData        :        data,
                    workstation_id  :        workstation_id ? workstation_id : null,
                    asset_id        :        asset_id ? asset_id : null
                  },() => this.fetchConsumptionHistoryData())
            }
    }

    fetchConsumptionHistoryData     =       (page = 1)      =>      {
        this.setState({consumptionDetailLoading : true, consumtionHistoryList : [],listing_tbl_page: page});
        let params =  {
                    search_workstation_ids      :    this.state.workstation_id !== undefined && this.state.workstation_id !== null ? [this.state.workstation_id] : [],
                    search_item_ids             :    this.state.itemData ? [this.state.itemData.id] : null,
                    page                        :    page,
                    for_report                  :      'N',
                    search_asset_ids            :    this.state.asset_id !== undefined && this.state.asset_id !== null ? [this.state.asset_id] : []}
                    
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/report/item-wise-consumption', this.props.access_token, {...params}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                consumtionHistoryList :    respData.data,
                itemName              :    this.state.itemData ? this.state.itemData.name : '',
                itemCode              :    this.state.itemData ? this.state.itemData.item_code : '',
                listingMeta           :     respData,
                totalListingCount     :     respData.total,
            },()=>  this.viewConsumptionHistoryModal.show())
        
        }).then(() => {
            this.setState({consumptionDetailLoading : false});
        });
    }

    
      consumptionHistoryModalJsx                     =   ()  =>  {
          return (
              <div className="modal fade" id={this.consumptionsDetailModalId} tabIndex="-1">
                  <div className="modal-dialog modal-xl modal-dialog-scrollable">
                      <div className="modal-content  ">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addItemModalLabel">Consumption History</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className='p-1 bg-light border-bottom'>
                              <table className='table table-sm mb-0 table-borderless text-sm'>
                                  <tbody>
                                     
                                      <tr>
                                          <td style={{ width: "15%" }}> Item  </td>
                                          <th>{this.state.itemName  ?this.state.itemName : "-"}</th>
                                      </tr>
                                      <tr>
                                          <td style={{ width: "15%" }}> Item Code  </td>
                                          <th>{this.state.itemCode  ?this.state.itemCode : "-"}</th>
                                      </tr>

                                  </tbody>
                              </table>
                          </div>
                          <div className="modal-body ">
                              {!this.state.consumptionDetailLoading ?
                                  <table className="table table-bordered table-fixed table-sm align-middle bg-white">
                                      <thead className="align-middle table-secondary">
                                          <tr>
                                            
                                             <th className='text-center' style={{ width: "12%" }}>Transaction Date</th>
                                              <th className='text-center' style={{ width: "10%" }}>Transaction ID</th>
                                              <th className="text-center" style={{ width: "22%" }}>Warehouse</th>
                                              <th className="text-center" style={{ width: "20%" }}>Consumption for</th>
                                              <th scope="col" style={{ width: "8%" }} > Status</th>
                                              <th scope="col" style={{ width: "6%" }} > Qty</th>
                                              <th scope="col" style={{ width: "8%" }} > Amount</th>
                                              <th scope="col" style={{ width: "15%" }}  className="text-end"> Counterlog</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {this.state.consumtionHistoryList.length > 0
                                              ? (this.state.consumtionHistoryList.map((rowData, k) => {
                                                  return (<tr>
                                                    <td className='text-center'>{rowData.transaction_date_display}</td>
                                                      <td className='text-center'>{rowData.transaction_id}</td>
                                                      
                                                      <td>{rowData.warehouse_name}</td>
                                                      <td>
                                                          {rowData.consumption_type == 'asset'
                                                              ? (rowData.assetProfile ? rowData.assetProfile.name + ' ' : '')
                                                              : null
                                                          }
                                                          {rowData.consumption_type == 'workstation'
                                                              ? (rowData.workstation ? rowData.workstation.workstation_name + ' ' : '')
                                                              : null
                                                          }
                                                          {rowData.consumption_type == 'employee'
                                                              ? (rowData.employee ? rowData.employee.display_full_name + ' ' : '')
                                                              : null
                                                          }
                                                          ({rowData.consumption_type == 'asset' ? 'Asset' : rowData.consumption_type == 'workstation' ? 'Workstation' : "Employee"})
                                                      </td>
                                                      <td>{rowData.status && rowData.status.name ? rowData.status.name : "-"}</td>
                                                      <td className="text-center">{rowData.qty}</td>
                                                      <td className="text-center"><CurrencyFormatter currencyCode={this.props.default_currency} amount={rowData.amount}/></td>
                                                      <td className="text-end">{rowData.assetCounterlog && rowData.assetCounterlog.length > 0 ?  rowData.assetCounterlog.map(cl => {return(<div>{cl.label} : {cl.reading} {cl.unit}</div>)}) : "NA"}</td>
                                                  </tr>);

                                              }))
                                              : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                                          }
                                      </tbody>
                                  </table>
                                  : <Loader />}
                              <DisplayListPagination
                                  meta={this.state.listingMeta}
                                  onPageChange={(e) => this.fetchConsumptionHistoryData(e.selected + 1)}
                              />
                          </div>
                      </div>
                  </div>
              </div>
          );
    }

    render() {
        return (<Ax>{this.consumptionHistoryModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ConsumptionHistoryModal);
