import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'bootstrap';
import { toast } from "react-toastify";
import uuid from 'react-uuid';
import { Chart } from "react-google-charts";
import DateService from '../../services/DateService';
import { IAM_API_BASE_URL_2, SCHEDULE_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import Loader from '../../components/ui/Loader/Loader';


const ProjectGanttChart = forwardRef((props, ref) => {

    const access_token              =   useSelector(state => state.auth.access_token);
    
    let ticketDataUrl               =   IAM_API_BASE_URL_2 + "/project/tickets";

    let initListData                =   {
        loading                         :   false,
        items                           :   []
    };
    const [itemData, setItemData]   =   useState(initListData);
    const [rows, setRows]           =   useState([]);

    useEffect(() => {
        loadTicketsData();
    }, []);


    const loadTicketsData           =   ()  =>  {
        setItemData(pd => ({...pd, loading : true}));
        HttpAPICall.withAthorization('GET', ticketDataUrl, access_token, {project_id:props.project_id}, {}, (response) => {
            let items                   =   response.data.map((item,key) => {
                return [
                    item.activity_id,
                    item.activity_id + ' ' + (item.header).toString(),
                    item.start_date ? new Date(item.start_date) : null,
                    item.resolution_date ? new Date(item.resolution_date) : null,
                    DateService.daysToMilliseconds(item.estimate_days),
                    item.completed ? parseFloat(item.completed): 0,
                    item.parent_activity_id
                ];
            });

            console.log(items);

            setItemData(pd => ({...pd, items : items }));

            setRows(items);
        }).then(() => {
            setItemData(pd => ({...pd, loading : false}));
        });
    }

    const columns = [
        { type: "string", label: "Ticket ID" },
        { type: "string", label: "Task Name" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
    ];

    // const rows = [
    //     [
    //         "TK-001",
    //         "INITATE MACHINE OVERHAULING",
    //         new Date('2015-1-1'),
    //         new Date('2015-1-21'),
    //         null,
    //         120,
    //         null,
    //     ],
    //     ["TK-002", "Write paper", null, null, DateService.daysToMilliseconds(1), 25, "TK-003"],
    //     [
    //         "TK-004",
    //         "Create bibliography",
    //         null,
    //         null,
    //         DateService.daysToMilliseconds(5),
    //         60,
    //         "TK-001",
    //     ],
    //     ["Complete", "Hand in paper", null, null, DateService.daysToMilliseconds(3), 0, "TK-004"],
    //     [
    //         "TK-003",
    //         "Outline paper",
    //         null,
    //         null,
    //         DateService.daysToMilliseconds(12),
    //         100,
    //         "TK-001",
    //     ],
    // ];

    const data = [columns, ...rows];

    console.log('datadata', data);

    return <>
        {itemData.loading ? <Loader /> : <Chart chartType="Gantt" width="100%" height="1000%" options={{height:"100%", width:"100%"}} legendToggle data={data} />}
    </>
});

export default ProjectGanttChart;