import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';
import TapIcon from '../../services/TapIcon';

const SiteProductivityModal = forwardRef(({ onSave }, ref) => {
    const [workstation, setWorkstation] = useState('');
    const [activityId, setActivityId] = useState(''); 
    const [activityName, setActivityName] = useState('');
    const modalElem = useRef(null);
    const modal = useRef(null);

    useImperativeHandle(ref, () => ({
        initModalHandler: (id,name) => {
            setActivityId(id); 
            setActivityName(name);
            modalOpen();
        }
    }));

    const modalOpen = () => {
        if (modal.current) { modal.current.show(); }
    };

    const modalClose = () => {
        if (modal.current) { modal.current.hide(); }
    };

    useEffect(() => {
        modalElem.current = document.getElementById('siteProductivityModal');
        modal.current = new Modal(modalElem.current, { keyboard: false, backdrop: false });
        return () => {
            if (modal.current) {
                modal.current.dispose();
            }
        };
    }, []);

    const handleInputChange = (e) => {
        setWorkstation(e.target.value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(activityId, workstation); 
        modalClose();
    };
    

    return (
        <div className="modal fade" id="siteProductivityModal" tabIndex="-1" ref={modalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Activity : {activityName}</h5>
                        <button type="button" className="btn-close" onClick={modalClose} aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="row align-items-center mt-3">
                                <div className="col-sm-3">
                                    <label htmlFor="workstationInput" className="form-label require">Workstation</label>
                                </div>
                                <div className="col-sm-7">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="workstationInput"
                                        value={workstation}
                                        onChange={handleInputChange}
                                        placeholder="Enter workstation"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={modalClose}>Close</button>
                            <button type="submit" className="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
});

export default SiteProductivityModal;
