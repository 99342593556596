import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon"
import TapSelect from '../../../../components/ui/TapSelect';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import TapIcon from '../../../../services/TapIcon';
import TapApiUrls, { IAM_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import swal from 'sweetalert';

class Productivity extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.iscAdditionAttrUrl =   ISC_API_BASE_URL_2 + '/additional_attribute';

        this.attrInitForm       =   {
            category             :       "productivity",
            name                 :       '',
            type                 :       '',
            asset_type_ids       :       []
        }

        this.initProductivityFormulaForm        =       {
            asset_type_id          :       '',
            activity_id            :        '',
            activity_new           :        '',
            unit_id                    :        '',
            unit_new                :       '',
            formula                 :       ''
        }

        this.state                =          {
            listing_loading         :         false,
            attribute_list          :          [],
            saveFormSubmitting      :         false,
            selectedAttribute       :          null,
            addAttrForm             :          { ...this.attrInitForm },
            addProdFormulaForm      :           {...this.initProductivityFormulaForm},
            options                 :          ['Option 1', 'Option 2'],
            iam_asset_types         :          [],
            edit                    :          false,
            form_data_loading       :          false,

            allActivitiesList       :           [],
            allAttributeKeyList     :           [],
            addNewActivity          :           'N',
            addNewUnit              :           'N',
            allProductionUnits      :           [],
            formula_listing_loading  :          false,
            formula_listing          :          [],
            productivityData        :           null,
        };
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        this.getProductivityAttributes()
        this.getProductivityFormulaListing()
        this.productivityModal         =    new Modal(document.getElementById('productivityModal'), {keyboard: false, backdrop :false});
        this.autoCalculationModal         =    new Modal(document.getElementById('autoCalculationModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter                =   (props)  =>  {
        this.setState({
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
        });
    }

    getProductivityAttributes = () => {
        this.setState({ listing_loading: true })
        HttpAPICall.withAthorization('GET', this.iscAdditionAttrUrl, this.props.access_token, {category : "productivity"}, {}, (resp) => {            
            this.setState({attribute_list: resp.data.data })
        }).then(() => this.setState({ listing_loading: false }));
    }

    productivityModalInit       =       (id = null)      =>      {
        this.productivityModal.show() 
        if(id !== null && id !== undefined){
            let productivityAttrlist = this.state.attribute_list.find(function (attr) {
                return attr.id == id;
            });
            let selected_asset = productivityAttrlist.assetTypes.filter(s => !this.state.iam_asset_types.includes(s.id))
           
           if(productivityAttrlist){
                this.setState({
                    edit                :       true,
                    addAttrForm: {
                        ...this.attrInitForm,
                        name            :       productivityAttrlist.name,
                        type            :       productivityAttrlist.type,
                        key             :       productivityAttrlist.key,
                      asset_type_ids    :       productivityAttrlist.assetTypes && productivityAttrlist.assetTypes.length > 0 ? productivityAttrlist.assetTypes.filter(s => !this.state.iam_asset_types.includes(s.id)).map(s => s.id) : []
                    },
                    options             :       productivityAttrlist.options.length > 0 ? productivityAttrlist.options.map(i => i) : []
                })
           }
        }else{
            this.setState({addAttrForm: this.attrInitForm, edit: false })
            let form = document.getElementById('productivityForm')
            form.reset(); 
        }
         
    }

    AllAttributeTypes = [
        { value: 'text', label: 'Text' },
        { value: 'numeric', label: ' Numeric' },
        { value: 'date', label: ' Date' },
        { value: 'dropdown', label: 'Dropdown' }
    ]

    handleOptionValue = (e, i) => {
        let options = [...this.state.options]
        options[i] = e.target.value
        this.setState({
            options
        })
    }

    handleDeleteOption = i => e => {
        e.preventDefault()
        if (i >= 0) {
            let options = [
                ...this.state.options.slice(0, i),
                ...this.state.options.slice(i + 1)
            ]
            this.setState({
                options
            })
        }
    }

    addOption = e => {
        e.preventDefault()
        let options = this.state.options.concat([''])
        this.setState({
            options
        })
    }

    submitAdditonalAttribute          =      (e)    =>  {
        e.preventDefault()
        this.setState({saveFormSubmitting : true})
        let frmData ;
        if(this.state.addAttrForm.type == "dropdown"){
            frmData = {...this.state.addAttrForm,options: this.state.options}
        }else{
            frmData = {...this.state.addAttrForm}
        }
        if(this.state.edit){
            HttpAPICall.withAthorization('PUT', this.iscAdditionAttrUrl, this.props.access_token, {}, { ...frmData}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.productivityModal.hide()
                this.getProductivityAttributes()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ saveFormSubmitting: false }))
        }else{
            HttpAPICall.withAthorization('POST', this.iscAdditionAttrUrl, this.props.access_token, {}, { ...frmData}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.productivityModal.hide()
                this.getProductivityAttributes()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ saveFormSubmitting: false }))
        }
       
    }

    deleteAddionalAttr                  =           (id)            =>          {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {

                    HttpAPICall.withAthorization('DELETE', this.iscAdditionAttrUrl + '/' + id, this.props.access_token, {  }, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getProductivityAttributes()
                    }).then(() => { });
                }
            });
    }

    getProductivityFormulaListing = () => {
        this.setState({ formula_listing_loading: true })
        HttpAPICall.withAthorization('GET',IAM_API_BASE_URL_2 + '/group/productivity_formula', this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({formula_listing : respData.data})
        }).then(() => this.setState({ formula_listing_loading: false }));
    }

    autoCalculationModalInit        =       (data)      =>      {
        this.autoCalculationFormData(data)
       
        this.autoCalculationModal.show()
    }

    autoCalculationFormData = (data) => {
        this.setState({ form_data_loading: true })
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/group/productivity_formula/form_data', this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                allActivitiesList       :   respData.activities && respData.activities.length > 0 ?  respData.activities.map(a => { return ({ value: a.id, label: a.activity_name, production_units: a.production_units }) }) : [],
                allAttributeKeyList     :   respData.attr_keys && respData.attr_keys.length > 0 ?  respData.attr_keys.map(a => { return ({ value: a.key, label: a.name,asset_type_ids : a.asset_type_ids && a.asset_type_ids.length > 0 ? a.asset_type_ids : this.state.iam_asset_types?.map(as => as.value)}) }) : [],
            },() => {
                this.setState({
                    newActivity     :   'N',
                    addNewUnit      :    'N',
                    addProdFormulaForm  :   {...this.initProductivityFormulaForm}
                },() => {
                    if(data){
                        let activityListContainingProduction =      this.state.allActivitiesList && this.state.allActivitiesList.length > 0 && this.state.allActivitiesList.find(p => { return (p.value == data.activity_id) });
                    let allProductionUnitList            =       activityListContainingProduction && activityListContainingProduction.production_units && activityListContainingProduction.production_units.length > 0   ?  activityListContainingProduction.production_units.map((d) => {  return ({ value: d.id, label: d.measuring_unit }) })  : [];
               
                        this.setState({
                            addProdFormulaForm  :   {
                                asset_type_id       :        data.asset_type_id,
                                activity_id         :       data.activity_id,
                                activity_new        :        '',
                                unit_id             :       data.unit_id,
                                unit_new            :       data.unit_name,
                                formula             :       data.formula,
                                id                  :       data.id
                            },
                            productivityData        :       data,
                            allProductionUnits      :       allProductionUnitList
                        })
                    }
                })
            })
        }).then(() => this.setState({ form_data_loading: false }));
    }

    submitAutoCalculationForm       =       (e)     =>      {
        e.preventDefault();
        let allAttributeKeyList        =   this.state.allAttributeKeyList;
        let list = allAttributeKeyList.filter(key => key.asset_type_ids.includes(this.state.addProdFormulaForm.asset_type_id) ? true : false)
        list.forEach((el, k) => {
            window[el.value]    =   k + 1;
        });
        window['trip_count']    =   10;
        try {
            eval(this.state.addProdFormulaForm.formula);
            this.setState({ saveFormSubmitting: true })
            if(this.state.productivityData){
                HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/group/productivity_formula', this.props.access_token, null, { ...this.state.addProdFormulaForm }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.autoCalculationModal.hide();
                    this.getProductivityFormulaListing()
                    this.setState({
                        newActivity          :        'N',
                        addNewUnit           :        'N',
                        addProdFormulaForm  :          { ...this.initProductivityFormulaForm },
                        productivityData    :           null
                    })
                }).then(() => this.setState({ saveFormSubmitting: false }))
            }else{
                HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/group/productivity_formula', this.props.access_token, null, { ...this.state.addProdFormulaForm }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.autoCalculationModal.hide();
                    this.getProductivityFormulaListing()
                    this.setState({
                        newActivity          :        'N',
                        addNewUnit           :        'N',
                        addProdFormulaForm  :          { ...this.initProductivityFormulaForm },
                        productivityData    :           null
                    })
                }).then(() => this.setState({ saveFormSubmitting: false }))
            }
        } catch (e) {
            toast.error('Please check the formula', { position: toast.POSITION.TOP_RIGHT });
        }
    }

    autoCalculationDelete                     =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', IAM_API_BASE_URL_2 + '/group/productivity_formula/' + id , this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.getProductivityFormulaListing()
                }).then(() => { });
            }
        });
    }
         
    productivityModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="productivityModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Add Additional Attribute (Productivity)</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="productivityForm" onSubmit={this.submitAdditonalAttribute}>
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <label className="col-md-3 col-form-label col-form-label-sm">Attribute Name</label>
                                    <div className="col-md-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addAttrForm.name}
                                            onChange={(e) => this.formInputHandler(e, 'addAttrForm')}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            required={true}
                                            placeholder="Enter Attribute Name"

                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mt-3 mb-1">
                                    <label className="col-md-3 col-form-label col-form-label-sm">Asset Type</label>
                                    <div className="col-md-9">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addAttrForm', 'asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.addAttrForm.asset_type_ids.includes(s.value))}
                                            placeholder="All Asset Types"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mt-3 mb-1">
                                    <label className="col-md-3 col-form-label col-form-label-sm">Type</label>
                                    <div className="col-md-9">
                                        <TapSelect options={this.AllAttributeTypes}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addAttrForm', 'type')}
                                            value={this.AllAttributeTypes.find(a => a.value == this.state.addAttrForm.type)}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            placeholder="Please Select Attribute Type"
                                            containerHeight="33px"
                                            isDisabled={this.state.edit === true ? true : false}
                                            fontSize="93%" />
                                    </div>
                                </div>
                                <div className="row align-items-center my-1">
                                    <div className="offset-3 col-sm-9">
                                        {this.state.addAttrForm.type == "dropdown" ?
                                            <div>{this.state.options.length >= 2 &&
                                                this.state.options.map((i, index) => {
                                                    return (
                                                        <div className="row">
                                                            <div className={this.state.options.length > 2 ? "col-sm-10" : "col-sm-12"}>
                                                                <input
                                                                    name={i}
                                                                    type="text"
                                                                    className="form-control my-2"
                                                                    value={i}
                                                                    // disabled={this.state.edit === true ? true : false}
                                                                    placeholder="Please Enter Options"
                                                                    required={true}
                                                                    onChange={(e) => this.handleOptionValue(e, index)}
                                                                />
                                                            </div>
                                                            {this.state.options.length > 2 ?
                                                                <div className="col-sm-2"><button className="btn btn-secondary my-3" 
                                                                    onClick={this.handleDeleteOption(index)}>X</button></div> : null}
                                                        </div>
                                                    )
                                                })}
                                                <div className="link-primary text-end cursor_pointer" onClick={this.addOption}>Add New Option</div>
                                            </div>
                                            : null}
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    autoCalucationModalJsx               =        ()             =>        {

        return (
            <div className="modal fade" id="autoCalculationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Auto Calculation (Productivity)</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="autoCalculationForm" onSubmit={this.submitAutoCalculationForm}>
                            {this.state.form_data_loading ? <Loader />
                                : <div className="modal-body">

                                    <div className="row align-items-center mb-2">
                                        <label className="col-md-3 col-form-label col-form-label-sm require">Asset Type</label>
                                        <div className="col-md-7">
                                            <TapSelect
                                                options={this.state.iam_asset_types}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addProdFormulaForm', 'asset_type_id')}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.iam_asset_types.find(s => this.state.addProdFormulaForm.asset_type_id == s.value)}
                                                placeholder="select Asset Type"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                autoFocus={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require">Activity Name</label>
                                        <div className="col-sm-7">
                                            {this.state.addNewActivity == "Y"
                                                ? <input
                                                    name="activity_new"
                                                    type="text"
                                                    value={this.state.addProdFormulaForm.activity_new}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addProdFormulaForm")}
                                                    placeholder="Please enter Activity Name"
                                                    required={true}
                                                />
                                                : <TapSelect
                                                    options={this.state.allActivitiesList}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addProdFormulaForm', 'activity_id');
                                                        this.setState({
                                                            allProductionUnits: selectedOption && selectedOption.production_units && selectedOption.production_units.length > 0 ? selectedOption.production_units.map(p => { return ({ value: p.id, label: p.measuring_unit }) }) : []
                                                        })
                                                    }}
                                                    isSearchable={true}
                                                    value={this.state.allActivitiesList.find(s => this.state.addProdFormulaForm.activity_id == s.value)}
                                                    isClearable={true}
                                                    placeholder="Select Activity Name"
                                                    autoCompelete="off"
                                                    required={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="addNewActivity"
                                                type="checkbox"
                                                value={this.state.addNewActivity}
                                                onChange={(e) => {
                                                    let allProductionUnits = [];
                                                    let activityListContainingProduction = this.state.allActivitiesList && this.state.allActivitiesList.length > 0 && this.state.allActivitiesList.filter(p => { return (p.production_units && p.production_units.length > 0 ? true : false) });
                                                    let allProductionUnitList = activityListContainingProduction && activityListContainingProduction.length > 0 && allProductionUnits.concat(activityListContainingProduction.flatMap((d) => { return d.production_units.map(p => { return ({ value: p.id, label: p.measuring_unit }) }) }))
                                                    const key = 'value';
                                                    const UniqueProductionUnitByKey = allProductionUnitList && allProductionUnitList.length > 0 && [...new Map(allProductionUnitList.map(item => [item[key], item])).values()];

                                                    this.setState({
                                                        addNewActivity: this.state.addNewActivity == 'N' ? 'Y' : 'N',
                                                        allProductionUnits: this.state.addNewActivity == 'N' && UniqueProductionUnitByKey && UniqueProductionUnitByKey.length > 0 ? UniqueProductionUnitByKey : [],
                                                        addProdFormulaForm: this.state.addNewActivity == 'N' ? { ...this.state.addProdFormulaForm, activity_id: '' } : { ...this.state.addProdFormulaForm }
                                                    })
                                                }}
                                                checked={this.state.addNewActivity == 'Y'}
                                                className="form-check-input"
                                                id="newActivity"

                                            />
                                            <label className="form-check-label mx-2 text-sm" htmlFor="newActivity">Add New </label>
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-md-3 col-form-label col-form-label-sm require">Measuring Unit</label>
                                        <div className="col-sm-7">
                                            {this.state.addNewUnit == "Y"
                                                ? <input
                                                    name="unit_new"
                                                    type="text"
                                                    value={this.state.addProdFormulaForm.unit_new}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addProdFormulaForm")}
                                                    placeholder="Please enter Productivity Unit"
                                                    required={true}
                                                />
                                                :
                                                <TapSelect
                                                    options={this.state.allProductionUnits}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addProdFormulaForm', 'unit_id');
                                                    }}
                                                    isSearchable={true}
                                                    value={this.state.allProductionUnits.find(s => this.state.addProdFormulaForm.unit_id == s.value)}
                                                    isClearable={true}
                                                    placeholder="Select Productivity"
                                                    autoCompelete="off"
                                                    required={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            }
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="addNewUnit"
                                                type="checkbox"
                                                value={this.state.addNewUnit}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewUnit: this.state.addNewUnit == 'N' ? 'Y' : 'N',
                                                        addProdFormulaForm: this.state.addNewUnit == 'N' ? { ...this.state.addProdFormulaForm, unit_id: '' } : { ...this.state.addProdFormulaForm }
                                                    })
                                                }}
                                                checked={this.state.addNewUnit == 'Y'}
                                                className="form-check-input"
                                                id="addNewUnit"

                                            />
                                            <label className="form-check-label text-sm mx-2" htmlFor="addNewUnit">Add New </label>
                                        </div>
                                    </div>
                                    <div className='p-1 bg-light border-bottom'>
                                        <div className="row align-items-center my-2">
                                            <label className="col-md-3 col-form-label col-form-label-sm require">Formula</label>
                                            <div className="col-md-7">
                                                <input
                                                    type="text"
                                                    name="formula"
                                                    className="form-control form-control-sm"
                                                    placeholder="Write Formula"
                                                    autoComplete="off"
                                                    value={this.state.addProdFormulaForm.formula}
                                                    onChange={(e) => this.formInputHandler(e, "addProdFormulaForm")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-2">
                                            <label className="offset-3 col-md-7 form-text"># Available Keys are : <br />
                                                {this.state.addProdFormulaForm && this.state.addProdFormulaForm.asset_type_id ?
                                                    <ol>
                                                        {this.state.allAttributeKeyList && this.state.allAttributeKeyList.length > 0 && this.state.allAttributeKeyList.filter(key => key.asset_type_ids.includes(this.state.addProdFormulaForm.asset_type_id) ? true : false).map(attribute => {
                                                            return (<li>{attribute.value} : {attribute.label}</li>)
                                                        })
                                                        }
                                                        <li>trip_count : No. of Trip/Session</li>
                                                    </ol>
                                                    : <span>Please Select Asset Type first</span>}
                                            </label>

                                        </div>
                                        {this.state.addProdFormulaForm && this.state.addProdFormulaForm.asset_type_id
                                            ?
                                            <div className="row align-items-center my-1">
                                                <label className="offset-3 col-md-7 form-text"># Examples : <br />
                                                    {this.state.addProdFormulaForm && this.state.addProdFormulaForm.asset_type_id ?
                                                        <ol>
                                                            <li>Total Productivity = (attribute 1 * attribute 2) * Total Km</li>
                                                            <li>Total Productivity = (attr 1 * attr 2) * (Total Km * attr 3)</li>
                                                        </ol>
                                                        : <span>Please Select Asset Type first</span>}
                                                </label>

                                            </div>
                                            : null}
                                    </div>
                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false} form="autoCalculationForm">Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general"
                                type="button" role="tab" aria-controls="general" aria-selected="true">General Settings
                            </button>
                        </li> */}
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active " id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                            </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">
                        {/* <div className="tab-pane  show active" id="general" role="tabpanel"
                            aria-labelledby="general-tab">
                            <div className="tab_content_header">
                                <table className="table table-hover table-bordered my-4 ">
                                    <thead className="table-secondary">
                                        <tr className="text-center">
                                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="text-start">Configuration</th>
                                            <th scope="col" style={{ width: "25%" }} >Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center">
                                            <td>1</td>
                                            <td className="text-start">Allow copy of productivity to other asset</td>
                                            <td>No</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> */}

                        <div className="tab-pane show active" id="workflow" role="tabpanel"
                            aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <div className=" row  bg-white px-2 pb-5 mb-5" style={{ top: "1px" }}>
                                    <div className="col-sm-12 pageTbl">

                                        <div className='me-2'>
                                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Additonal Attributes</span>
                                                <div className="text-end "><button className="btn btn-primary" onClick={() => this.productivityModalInit()}>Add</button></div>
                                            </div>
                                            <table className="table table-hover table-bordered my-4 table-sm ">
                                                <thead className="table-secondary">
                                                    <tr>
                                                        <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                        <th scope="col" className="text-start">Name</th>
                                                        <th scope="col" style={{ width: "15%" }}>Type</th>
                                                        <th scope="col" style={{ width: "20%" }}>Asset Type</th>
                                                        <th scope="col" style={{ width: "25%" }}>Notes</th>
                                                        <th scope="col" style={{ width: "5%" }} className="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.listing_loading
                                                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                                        : (this.state.attribute_list.length > 0
                                                            ? (this.state.attribute_list.filter(i => i.category == "productivity").map((item, k) => {
                                                                return (<tr key={k}>
                                                                    <td>{k + 1}</td>
                                                                    <td className="text-capitalize ">{item.name}</td>
                                                                    <td className="text-capitalize">{item.type}</td>
                                                                    <td className="text-capitalize">{item.assetTypes && item.assetTypes.length > 0 ? item.assetTypes.map(type => { return (type.asset_type_name) }).join(', ')
                                                                        : <span className='sup text-sm form-text'># All Asset Types</span>}</td>
                                                                    <td>{item.options !== null && item.options.length > 1 ?
                                                                        <div>
                                                                            {item.options.map((itemNew, index) => {
                                                                                return (
                                                                                    <span>{itemNew} {index < item.options.length - 1 ? ", " : ""}</span>
                                                                                );
                                                                            })}
                                                                        </div> : "-"}</td>
                                                                    <td className='text-center'>  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                                                        role="button" aria-expanded="false" />
                                                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                                                            <li className="dropdown-item" onClick={() => this.productivityModalInit(item.id)}>Edit</li>
                                                                            <li className="dropdown-item" onClick={() => this.deleteAddionalAttr(item.id)}>Delete</li>
                                                                        </ul></td>
                                                                </tr>);
                                                            }))
                                                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Auto Calculation</span>
                                                <div className="text-end "><button className="btn btn-primary" onClick={() => this.autoCalculationModalInit()}>Add</button></div>
                                            </div>

                                            <table className="table table-hover table-bordered my-4 table-sm ">
                                                <thead className="table-secondary">
                                                    <tr>
                                                        <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                        <th scope="col" style={{ width: "20%" }}>Asset Type</th>
                                                        <th scope="col" style={{ width: "15%" }}>Activity Name</th>
                                                        <th scope="col" style={{ width: "15%" }}>Measuring Unit</th>
                                                        <th scope="col" style={{ width: "20%" }}>Productivity Formula</th>
                                                        <th scope="col" style={{ width: "5%" }} className="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.formula_listing_loading ? <tr><td colSpan={6} className="text-center"><Loader /></td></tr>
                                                        : (this.state.formula_listing && this.state.formula_listing.length > 0
                                                            ? (this.state.formula_listing.map((item, k) => {
                                                                return (<tr key={k}>
                                                                    <td className='text-center'>{k + 1}</td>
                                                                    <td>{item.asset_type && item.asset_type.asset_type_name ? item.asset_type.asset_type_name : "-"}</td>
                                                                    <td>{item.activity_name ? item.activity_name : "-"}</td>
                                                                    <td>{item.unit_name ? item.unit_name : "-"}</td>
                                                                    <td>{item.formula ? item.formula : "-"}</td>
                                                                    <td className='text-center'>  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                                                        role="button" aria-expanded="false" />
                                                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                                                            <li className="dropdown-item" onClick={() => this.autoCalculationModalInit(item)}>Edit</li>
                                                                            <li className="dropdown-item" onClick={() => this.autoCalculationDelete(item.id)}>Delete</li>
                                                                        </ul></td>
                                                                </tr>);
                                                            }))
                                                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>))}



                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.productivityModalJsx()}
                {this.autoCalucationModalJsx()}
            </section>
          
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        iam_asset_types         :        state.app && state.app.asset_type ? state.app.asset_type : [],
    };
};


export default connect(mapStateToProps)(Productivity);