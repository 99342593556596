import React from 'react';
import { connect } from 'react-redux';
import AsyncSelect from "react-select/async";
import HttpAPICall from '../../../services/HttpAPICall';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import Ax from '../../../components/hoc/Ax';
import ReactSelectStyle from '../../../components/ui/ReactSelectStyle';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class InputVendorSearch extends React.Component {
    
    constructor(props) {
        super(props);
        this.state              =   {
            selectedVendor           :   null,
            timer                   :   false
        };
        this.cancelToken                =   null;
        this.apiTimeout              =   null;
    }
    
    onInputChange                   =   (selectedOption)  =>  {
        this.props.changeEvent(selectedOption);
        
    }
    
    displayStringSearch             =   (vendor)  =>  {
        return vendor.map(vendor => {
            return {
                label               :   (<Ax>
                                         {vendor.name} {vendor.code && <span>({vendor.code})</span>} <br/>
                                        </Ax>),
                value               :   vendor.id,
                display_label       :   (<Ax>
                                         {vendor.name} {vendor.code && <span>({vendor.code})</span>} <br/>
                                        </Ax>),
                vendor              :   vendor
            };
        });
    };

    loadingOptions                  =   (inputText,callback)  =>  {
//        setTimeout(
//            () => this.setState({ position: 1 }),
//            3000
//        );
        if(inputText.length > 2) {
            clearTimeout(this.apiTimeout)
            if (this.cancelToken) {
                this.cancelToken.cancel("Operation canceled due to new request.")
            }
            this.cancelToken = axios.CancelToken.source();
            let params = {search_by_text: inputText, per_page: 50};
        
            if(this.props.only_active && this.props.only_active == 'Y') {
                params      =   {...params , only_active : 'Y'};
            }

            if(this.props.search_ids && this.props.search_ids.length > 0) {
                params      =   {...params , search_ids : this.props.search_ids.map(vd => vd)};
            }
            
            this.apiTimeout  = setTimeout(() => {this.getLoadingOptions(params,callback)},1000);
        }
    }

    getLoadingOptions         =        (params,callback)            =>         {
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
            params : params,
            url: INVENTORY_API_BASE_URL_2 + '/vendor',
            cancelToken : this.cancelToken.token
        })
            .then((response) =>  {callback(this.displayStringSearch(response.data.data))})
            .catch((err) => {
                if (axios.isCancel(err)) {
                    
                } else {
                    ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
                }
            })
    }
    
    render() {
          return (<Ax>
            <AsyncSelect
                styles          =   {ReactSelectStyle(this.props)}
                menuPlacement   =   "bottom"
                placeholder     =   {this.props.placeholder || "Search Vendor by Name, Code" }
                loadingMessage  =   {(input) => {
                    if(input.inputValue && input.inputValue.length <=2 ) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'Searching...';
                    }
                }}
                noOptionsMessage  =   {(input) => {
                    if(input.inputValue.length == 0) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'No Vendor found';
                    }
                }}
                minimumInput    =   {5}
                complete        =   {true}
                onChange        =   {this.onInputChange}
                isClearable     =   {true}
                loadOptions     =   {this.loadingOptions}
                value           =   {this.props.value}
                isMulti         =   {this.props.isMulti || false}
                required        =   {true}
                isDisabled      =   {this.props.disabled || false}
        
            />
            <input
                name=''
                tabIndex={-1}
                required={this.props.required || false}
                value={this.props.isMulti ? (this.props.value.length > 0 ? this.props.value[0].label : [].join(' ')) : (this.props.value ? this.props.value.label : '')}
                onChange={() => {} }
                style={{
                    opacity: 0,
                    width: 0,
                    height: 0,
                    padding: 0,
                    margin: 0,
                    display: 'table'
                  }}
            />        
            </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(InputVendorSearch);