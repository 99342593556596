import React from 'react';
import { connect } from 'react-redux';
import Ax from "../../../../../components/hoc/Ax";
import uuid from 'react-uuid';
import tapIcon from '../../../../../services/TapIcon';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import Loader from '../../../../../components/ui/Loader/Loader';
import { toast } from 'react-toastify';
import CounterlogExecutionModal from '../../../counterlog/CounterlogExecutionModal';
import RunningStatusViewModal from './RunningStatusViewModal';
import { Helmet } from 'react-helmet';

class RunningStatusListing extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                  =   {
            assetData               :       null,
            shift                   :       null,
            counterlog              :       null,
            date                    :       '',
            transaction_id          :       '',
            listing_loading             :       false,
            listing_tbl_page            :       1,
            scs_listing                 :       [],
            listingMeta                 :       null,
            totalListingCount           :       0,
            current_page                :       1,
            linked_items                :       [],
            formSearchedElems           :       [],
            assetId                     :       '',
            execution_loading           :       false,
            selectedSCSForExecution     :       []
        }
        this.serviceChecksheetModalId      =   uuid();
        this.counterlogExecutionRef        =   React.createRef();
        this.RunningStatusModalRef         =   React.createRef();  
    }
    
    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);  
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.assetData !== this.props.assetData || nextProps.counterlog !== this.props.counterlog){
            this.initalizeComponent(nextProps);
        }
       
    }
    
    initalizeComponent                  =   (pr)  =>  {
        this.setState({
            access_token        :       pr.access_token,
            assetId             :       pr.assetData && pr.assetData.asset_id ? pr.assetData.asset_id : '',
            counterlog          :       pr.counterlog ? pr.counterlog : null,
            assetData           :        pr.assetData ? pr.assetData : null
        },
            () => {
                if (pr.assetData) {
                    this.loadListingTblData(1, true, pr.assetData.asset_id)
                }
            }
        );
    }

    loadListingTblData                 =   (page = 1,merge_list = false,asset_id)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let assetId = asset_id ? asset_id : this.state.assetId
        let params                      =   {page:page,search_asset_ids: [assetId],get_asset_profile : "Y",...this.state.submitRunningStatusForm };
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/scs/running_status', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                scs_listing         :       merge_list == true ? resp.data.data : [...this.state.scs_listing, ...resp.data.data],
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                 totalListingCount   :       respData.total,
                current_page        :       respData.current_page,
        },() => {
             let all_scs_ids       =   this.state.scs_listing.map((scs,key) => scs.id);
             if(this.state.scs_listing && this.state.scs_listing.length > 0){
                this.getAllLinkedItemsList(all_scs_ids)
             }
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    getAllLinkedItemsList                 =   (all_ids)  =>         {
        
        let params                      =   {scs_setting_ids : all_ids};
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/scs/asset/item_linked_scs_setting', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                linked_items        :      respData.data,
            })
        })
    }

    loadMoreList                    =   ()  =>  {
        if(this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page) {
            this.loadListingTblData(this.state.current_page + 1);
        }
    }
    
    //********************************SCS EXECUTION HANDLER********************** */
    checkAllList() {
        let scsItemCheckboxCollections      =   document.getElementsByClassName('scs_item_id_checkbox')
        if(scsItemCheckboxCollections && scsItemCheckboxCollections.length > 0) {
            for (let i = 0; i < scsItemCheckboxCollections.length; i++) {
                scsItemCheckboxCollections[i].checked = document.getElementById('all_check_scs').checked;
            }
        }
    }

    executeModalInit     =       (assetData,counterlog)      =>  {
        this.setState({execution_loading : true,selectedSCSForExecution : []})
        let scsItemCheckboxCollections     =   document.getElementsByClassName('scs_item_id_checkbox');
        let selectedSCSForExecution          =   [];
       
        
            if(scsItemCheckboxCollections && scsItemCheckboxCollections.length > 0) {
                for (let i = 0; i < scsItemCheckboxCollections.length; i++) {
                    if(scsItemCheckboxCollections[i].checked) {
                       let asset_scs_id         =       scsItemCheckboxCollections[i].value;
                       let newList              =       this.state.scs_listing.find(scs => scs.id == asset_scs_id);
                        selectedSCSForExecution.push(newList)    
                    }
                }
            }
        if(selectedSCSForExecution.length == 0){
            toast.error('Please select atleast one Service Checksheet', { position: toast.POSITION.TOP_RIGHT });
        }else{
             this.setState({selectedSCSForExecution : selectedSCSForExecution})
             this.counterlogExecutionRef.current.executeCounterlogInit(selectedSCSForExecution,this.state.assetData,counterlog)
        }
        
    }

    executeSingleModalInit     =       (id)      =>  {
       
        this.setState({ execution_loading: true, selectedSCSForExecution: [] })
        let selectedSCSForExecution         =       [];
        let newList                         =       this.state.scs_listing.find(scs => scs.id == id);

        selectedSCSForExecution.push(newList)

        this.setState({selectedSCSForExecution : selectedSCSForExecution})
        this.counterlogExecutionRef.current.executeCounterlogInit(selectedSCSForExecution,this.state.assetData,this.state.counterlog)
              
    }

    runningStatusModalInit     =       (id,name)      =>  {
        let scsRunningData   = this.state.scs_listing.find(sc => sc.id == id)

        if(scsRunningData){
            this.RunningStatusModalRef.current.runningStatusModalInit(scsRunningData); 
        }
       
    }

      
    runningStatusListingJsx              =   ()  =>  {
       
        return (
            <Ax>
                <table className="table table-bordered bg-white align-items-center  table-sm table-hover mb-0" id="scsTable">
                    <thead>
                        <tr>
                        <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllList} id="all_check_scs" /></th>
                                <th scope="col" style={{ width: "22%" }}>Name</th>
                                {this.props.permissions && this.props.permissions.group_modules && this.props.permissions.group_modules.includes("isc")
                                    ? <th scope="col" style={{ width: "20%" }}>Linked Items</th> : null}
                               
                                <th scope="col" style={{ width: "22%" }} className="text-start">Last CS Done</th>
                                <th scope="col" style={{ width: "15%" }}>Running Status</th>
                                <th scope="col" style={{ width: "10%" }} className="text-center">Due Date </th>
                                <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>
                    </thead>
                    {!this.state.listing_loading
                        ? <tbody>
                            {this.state.scs_listing && this.state.scs_listing.length > 0 ? this.state.scs_listing.map((scs, key) => {
                                return (<tr key={key} >
                                    <td className="text-center" >
                                        <input type='checkbox'
                                            className="scs_item_id_checkbox"
                                            value={scs.id}
                                            
                                        />
                                    </td>
                                    <td><a role="button" className="link-primary " onClick={() => this.runningStatusModalInit(scs.id, scs.name)}>{scs.alias_name ? scs.alias_name : scs.name}</a> </td>
                                    <td>
                                        {this.state.linked_items && this.state.linked_items.length > 0
                                            ? this.state.linked_items.filter(li => li.scs_setting_id == scs.id).map((item, index) => {

                                                return <Ax>{item.items?.length > 0 ? item.items.map((i, itemKey) => <div className='mt-1'>{itemKey + 1}.<b>{i.item.name}</b> : {i.qty} {i.item && i.item.measuring_unit ? i.item.measuring_unit.name : ''} <br /><small className='form-text mt-1'> (Code : {i.item?.item_code})</small></div>) : " -"}<br /></Ax>

                                            })
                                            : "-"}
                                    </td>
                                  
                                        <td className="text-start"> {scs.latest_execution_cl && scs.latest_execution_cl.length > 0 ? scs.latest_execution_cl.map((item, index) => { return (<div className="mt-1"> {item.label} : {item.reading}  {item.unit} <br /></div>) })
                                            : "-"}</td>
                                    <td className='text-center'>
                                    {scs.latest_execution_id ?
                                                <div>
                                                    <div className="progress">
                                                        <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${scs.max_running}%`, backgroundColor: `${scs.bar_bg_color}` }}
                                                            aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                    <small className='form-text'>{scs.max_running}%</small>
                                                </div>
                                                : <span>NYA <sup>#</sup></span>}

                                    </td>
                                    <td className='text-center'>{scs.expected_due_date ?  scs.expected_due_date : "-"}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={() => {this.executeSingleModalInit(scs.id)}}>Execute</a></li>
                                        </ul>
                                    </td>

                                </tr>)
                            })
                                : <tr><td colSpan="7" className="text-center">No Records</td></tr>
                            }

                        </tbody>
                        : null}
                </table>
                <div className='bg-white '>
                    {this.state.listing_loading
                        ? (<div className='text-center'><Loader /></div>)
                        : (<small className='text-muted'>#Not Yet Activated</small>)}
                </div>

                <div className='text-end mt-1 mb-5'>
                    {this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page
                        ? <button type="button" className="btn btn-secondary me-3 " style={{ lineHeight: "6.3px!important", height: "27px" }} onClick={() => this.loadMoreList()} >Load More</button>
                        : null}
                </div>
                <RunningStatusViewModal parentProps={this.props} ref={this.RunningStatusModalRef}/>
                <CounterlogExecutionModal ref={this.counterlogExecutionRef} afterExecutionSubmit={() => {this.props.afterExecutionSubmit(); this.loadListingTblData(1, true, this.state.assetId)}}/>
            </Ax>
        );
    }
    
    render() {
        return (<Ax>
             <Helmet><title>Asset - SCS Running Listing</title></Helmet>
            {this.runningStatusListingJsx()}
        </Ax>)
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(RunningStatusListing);

