import React from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL_2, INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import Vendor from '../includes/Vendor';
import ManualEntryModal from './ManualEntryModal';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import DateService from '../../../services/DateService';

class LogisticAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initLogisticForm               =   {
            logistic_type                       :   '', 
            transaction_date                    :   DateService.dateTimeFormat(new Date(), 'YYYY-MM-DD HH:mm:ss'),
            status                              :   '',
            notes                               :   '',
            division_id                         :   '',
            type_of_vehicle                     :   '',
            origin_type                         :   '',
            users                               :   [],
            origin_warehouse_id                 :   null,
            origin_vendor_id                    :   null,
            origin_customer_id                  :   null,
            origin_address                      :   null,

            destination_type                    :   '',  
            destination_warehouse_id            :   null,
            destination_vendor_id               :   null,
            destination_customer_id             :   null,
            destination_address                 :   null,

            // RTD Details
            rtd_per_po                          :   '',
            eta_days                            :   '',
            booking_date                        :   '',
            dispatch_date                       :   '',
            delivery_date                       :   '',
            delivery_date_at_godown             :   '',
            delivery_date_at_site               :   '',
            transport_days                      :   '',
            receiver_name                       :   '',

            // Consigment Details
            load_type                           :   '',
            consigment_number                   :   '',
            booking_place                       :   '',
            transporter_id                      :   '',
            vehicle_number                      :   '',
            weight                              :   '',
            unit_of_measurement                 :   '',
            number_of_boxes                     :   '',
            driver_name                         :   '', 
            driver_contact_number               :   '',
            
            // Billing Details
            gst_invoice_number                  :   '',
            invoice_date                        :   '',
            invoice_value                       :   '',
            machinery_description               :   '',
            payment_mode                        :   '',
            payment_date                        :   '',
            eway_bill_number                    :   '',
            
            // CHA Details
            cnf_agent_id                        :   '',
            handler_id                          :   '',
            boe_number                          :   '',
            
            // Expense Details
            logistic_cost                       :   '',
            cha_cost                            :   '',
            handling_cost                       :   '',
            total_cost                          :   '',
        }

        this.state                          =   {
            isc_configuration                   :   {},
            gstTypeName                         :   'GST',      
            saveFormSubmitting                  :   false,
            logisticFormDataLoaded              :   false,
            origin_detail_loading               :   false,
            destination_detail_loading          :   false,
            addLogisticForm                     :   {...this.initLogisticForm},
            allStatusOptions                    :   [],
            all_warehouses                      :   [],
            transportersList                    :   [],
            divisionsList                       :   [],
            cnfAgentsList                       :   [],
            handlersList                        :   [],
            destinationManualAddress            :   null,
            destinationCustomerDetail           :   null,
            destinationWarehouseDetail          :   null,
            destinationManualChecked            :   false,
            destinationVendorDetailsLoading     :   false,
            destinationDetailsLoading           :   false,
            destinationVendorDetails            :   null,
            destinationSearchedVendor           :   null,
            destinationSelectedVendorAdress     :   null,
            destinationVendorAddressList        :   [],
            searchedVendorAdressList            :   [],
            searchedDestinationCustomer         :   [],
            originManualAddress                 :   null,
            originCustomerDetail                :   null,
            originWarehouseDetail               :   null,
            originManualChecked                 :   false,
            originVendorDetailsLoading          :   false,
            originDetailsLoading                :   false,
            originVendorDetails                 :   null,
            originSearchedVendor                :   null,
            originSelectedVendorAdress          :   null,
            originVendorAddressList             :   [],
            searchedOriginCustomer              :   [],
            vendor_state_id                     :   null,
            internationDataLoad                 :   true,
            paymentMode                         :   [],
            logistic_steps                      :   [],
            formStepsWithFields                 :   [],
            allUsers                            :   [],
            logisticConfig                      :   false,
        };

        this.domesticLoadType   =   [      
            {value: 'FULL TRUCK CONSIGNMENT', label: 'FULL TRUCK CONSIGNMENT'},
            {value: 'PARTIAL CONSIGNMENT', label: 'PARTIAL CONSIGNMENT'},
            {value: 'COURIER CONSIGNMENT', label: 'COURIER CONSIGNMENT'}
        ];
        this.internationalLoadType   =   [      
            {value: 'AIR CARGO', label: 'AIR CARGO'},
            {value: 'SEA CARGO', label: 'SEA CARGO'}
        ];

        this.unitOfMeasurement     =   [
            {value: "Tonne", label: "Tonne"},
            {value: "Kilogram", label: "Kilogram"}
        ]

        this.manualAddressModalRef  =   React.createRef();
        this.logisticUrl            =   APP_BASE_URL_2 + '/logistic';
        this.warehouseUrl           =   ISC_API_BASE_URL_2 + '/warehouse';
        this.customerURL            =   INVENTORY_API_BASE_URL_2 + '/customer';   

    }

    componentDidMount() {
        this.initilaizeFormData(this.props);
    }

    //*******************INITIALIZE FORM DATA************************************** */
    initilaizeFormData                  =   (pr) =>  {
        if(!this.state.logisticFormDataLoaded) {
            HttpAPICall.withAthorization('GET', `${this.logisticUrl}/form_data`, this.props.access_token, null, null, (response) => {
               const formData           =   response.data;
               this.setState({
                    all_warehouses              :    this.props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    logisticFormDataLoaded      :   formData ? true : false,
                    paymentMode                 :   formData.payment_mode,
                    allStatusOptions            :   formData && formData.status && formData.status.length > 0 ?  formData.status.filter(status => status.name !== 'Cancel').map((s) => { return {value: s.id, label: s.name}; }) : [],
                    transportersList            :   formData.transporter.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                    cnfAgentsList               :   formData.cnfAgent.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                    divisionsList               :   formData.division.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                    handlersList                :   formData.handler.map((s) => { return { value: s.id, label: `${s.name}`}}), 
                    logistic_steps              :   formData.logistic_steps,
                    allUsers                    :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],       
                }, () => {
                    this.handleLogisticSteps(1);
                });
            });
        }
    }

    //********************GET WAREHOUSE DETAILS API ******************************* */
    getWarehouseDetails             =       (selectedOption, type)        =>          {

        if(selectedOption){
            let  id = selectedOption.value ? selectedOption.value : selectedOption
            if(type === 'destinationWarehouseDetail') {
                this.setState({
                    destinationWarehouseDetail      :  null, 
                    destination_detail_loading      :  true
                });
            }else {
                this.setState({
                    originWarehouseDetail       :   null,
                    origin_detail_loading       :   true
                }); 
            }

            HttpAPICall.withAthorization('GET',  this.warehouseUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;

                const address1      =   respData.data.address1; 
                const address2      =   respData.data.address2; 
                const landmark      =   respData.data.landmark; 
                const city          =   respData.data.city;   
                const country_id    =   respData.data.country_id; 
                const state_id      =   respData.data.state_id; 
                const district_id   =   respData.data.district_id; 
                const gstin         =   respData.data.gstin; 
                const pincode       =   respData.data.pincode; 


                if(this.state.vendor_state_id === respData.data.state_id){
                    this.setState({gstTypeName : 'GST'})
                }else{
                    this.setState({gstTypeName : 'IGST'}) 
                }
                if(type === 'destinationWarehouseDetail') {
                    
                    this.setState({
                        destinationWarehouseDetail      :   respData.data, 
                        destination_detail_loading      :   false,
                        addLogisticForm                 :   {...this.state.addLogisticForm, destination_address :   {address1, address2, landmark, city, country_id, state_id, district_id, pincode, gstin}},
                    });
                }else {
                    this.setState({
                        originWarehouseDetail       :   respData.data,
                        origin_detail_loading       :   false,
                        addLogisticForm             :   {
                            ...this.state.addLogisticForm, origin_address : {address1, address2, landmark, city, country_id, state_id, district_id, pincode, gstin}
                        }
                    }); 
                }
            });
        }else{
            this.setState({
                origin_detail_loading           :   false,
                destination_detail_loading      :   false,
                destinationWarehouseDetail      :   null,
                originWarehouseDetail           :   null
            }); 
        }
    }

    //********************GET CUSTOMER DETAILS API ******************************* */
    getCustomerDetail       =   (selectedOption, type) => {
        if(selectedOption){
            const  id = selectedOption.value ? selectedOption.value : selectedOption;
            // this.state.origin_detail_loading 
            if(type === 'destinationCustomerDetail') {
                this.setState({
                    destinationCustomerDetail       :  null, 
                    destination_detail_loading      :  true
                });
            }else {
                this.setState({
                    originCustomerDetail        :   null,
                    origin_detail_loading       :   true
                }); 
            }

            HttpAPICall.withAthorization('GET', this.customerURL + '/' + id, this.props.access_token, {}, {}, (resp) => {
                const respData = resp.data.data;
                const customerAddress   =   respData.addressList[0];     

                const address1      =   customerAddress.address1; 
                const address2      =   customerAddress.address2; 
                const landmark      =   customerAddress.landmark; 
                const city          =   customerAddress.city;   
                const country_id    =   customerAddress.country_id; 
                const state_id      =   customerAddress.state_id; 
                const district_id   =   customerAddress.district_id; 
                const gstin         =   customerAddress.gstin; 
                const pincode       =   customerAddress.pincode; 
                
                if(type === 'destinationCustomerDetail') {
                    this.setState({
                        destinationCustomerDetail       :   respData.addressList[0], 
                        destination_detail_loading      :   false,
                        addLogisticForm             :   {
                            ...this.state.addLogisticForm, 
                            destination_address :  {address1, address2, landmark, city, country_id, state_id, district_id, pincode, gstin}
                        },
                    });
                }else {
                    this.setState({
                        originCustomerDetail            :   respData.addressList[0],
                        origin_detail_loading           :   false,
                        addLogisticForm             :   {
                            ...this.state.addLogisticForm, 
                            origin_address          :   {address1, address2, landmark, city, country_id, state_id, district_id, pincode, gstin}
                        },
                    }); 
                }
            });
        }else{
            this.setState({
                origin_detail_loading           :   false,
                destination_detail_loading      :   false,
                destinationCustomerDetail       :   null,
                originCustomerDetail            :   null
            }); 
        }
    }

    //********************GET Destination VENDOR DETAILS ******************************* */
    getDestinationVendorDetails     =   (id,edit=false) =>  {
        if(id) {
            this.setState({destinationVendorDetailsLoading : true})
            HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/vendor' + '/' + id, this.props.access_token, null, null, (response) => {
                let destinationSelectedVendorAdress   =   response.data.data && response.data.data.addressList ? response.data.data.addressList[0] : [];

                const address   =   { address1 : destinationSelectedVendorAdress.address1, address2 : destinationSelectedVendorAdress.address2, landmark : destinationSelectedVendorAdress.landmark, city : destinationSelectedVendorAdress.city, country_id : destinationSelectedVendorAdress.country_id, state_id : destinationSelectedVendorAdress.state_id, district_id : destinationSelectedVendorAdress.district_id, pincode : destinationSelectedVendorAdress.pincode, gstin : destinationSelectedVendorAdress.gstin };

                this.setState({
                    destinationVendorDetails            :   response.data.data,
                    destinationVendorAddressList        :   response.data.data.addressList,
                    destinationSelectedVendorAdress     :   edit == false ? destinationSelectedVendorAdress : this.state.destinationSelectedVendorAdress,
                    addLogisticForm                     :   {...this.state.addLogisticForm, destination_address : address},
                });
            }).then(() => {
                this.setState({ destinationVendorDetailsLoading : false });
                this.setState({gstTypeName :  this.state.destinationSelectedVendorAdress && this.state.warehouseDetail && this.state.destinationSelectedVendorAdress.state_id == this.state.warehouseDetail.state_id ? 'GST' : 'IGST'});
            });
        }
    }

    //********************Handle Destination VENDOR ******************************* */
    handleDestinationVendor     =   (selectedOption,edit=false) => {
        this.setState({
            addLogisticForm                 :   {
                ...this.state.addLogisticForm,
                destination_vendor_id           :   selectedOption ? selectedOption.value : null
            },
            destinationVendorDetails            :   selectedOption ? selectedOption : null,
            searchedVendorAdressList            :   selectedOption && selectedOption.vendor ? selectedOption.vendor.addressList : null,
            destinationSelectedVendorAdress     :   selectedOption && selectedOption.vendor && edit == false ? selectedOption.vendor.addressList[0] : this.state.destinationSelectedVendorAdress,
            destinationSearchedVendor           :   selectedOption ? selectedOption : null
        },() => {
            if(edit == false){
                this.setState({
                   gst_not_applicable          :      this.state.destinationSelectedVendorAdress && this.state.destinationSelectedVendorAdress.country_id !== 99 ? 'Y' : 'N'
                })
           }
        })
    }

    //********************GET Origin VENDOR DETAILS ******************************* */
    getOriginVendorDetails     =   (id,edit=false) =>  {

        if(id) {
            this.setState({originVendorDetailsLoading : true})
            HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/vendor' + '/' + id, this.props.access_token, null, null, (response) => {
                let originSelectedVendorAdress   =   response.data.data && response.data.data.addressList ? response.data.data.addressList[0] : [];
                const address1      =   originSelectedVendorAdress ? originSelectedVendorAdress.address1 : null; 
                const address2      =   originSelectedVendorAdress.address2; 
                const landmark      =   originSelectedVendorAdress ? originSelectedVendorAdress.landmark : null; 
                const city          =   originSelectedVendorAdress ? originSelectedVendorAdress.city : null;  
                const country_id    =   originSelectedVendorAdress ? originSelectedVendorAdress.country_id : null; 
                const state_id      =   originSelectedVendorAdress ? originSelectedVendorAdress.state_id : null; 
                const district_id   =   originSelectedVendorAdress ? originSelectedVendorAdress.district_id : null; 
                const gstin         =   originSelectedVendorAdress ? originSelectedVendorAdress.gstin : null; 
                const pincode       =   originSelectedVendorAdress ? originSelectedVendorAdress.pincode : null;

                this.setState({
                    originVendorDetails            :   response.data.data,
                    originVendorAddressList        :   response.data.data.addressList,
                    originSelectedVendorAdress     :   edit == false ? originSelectedVendorAdress : this.state.originSelectedVendorAdress,
                    addLogisticForm                 :   {
                        ...this.state.addLogisticForm, 
                        origin_address : {address1, address2, landmark, city, country_id, state_id, district_id, pincode, gstin}
                    },
                });
            }).then(() => {
                this.setState({ originVendorDetailsLoading : false });
                this.setState({gstTypeName :  this.state.originSelectedVendorAdress && this.state.warehouseDetail && this.state.originSelectedVendorAdress.state_id == this.state.warehouseDetail.state_id ? 'GST' : 'IGST'});
            });
        }
    }
    //********************Handle Origin VENDOR ******************************* */
    handleOriginVendor     =   (selectedOption,edit=false) => {
        this.setState({
            addLogisticForm             :   {
                ...this.state.addLogisticForm,
                origin_vendor_id            :   selectedOption ? selectedOption.value : null
            },
            originVendorDetails             :   selectedOption ? selectedOption : null,
            searchedVendorAdressList        :   selectedOption && selectedOption.vendor ? selectedOption.vendor.addressList : null,
            originSelectedVendorAdress      :   selectedOption && selectedOption.vendor && edit == false ? selectedOption.vendor.addressList[0] : this.state.originSelectedVendorAdress,
            originSearchedVendor   :     selectedOption ? selectedOption : null
        },() => {
            if(edit == false){
                this.setState({
                   gst_not_applicable          :      this.state.originSelectedVendorAdress && this.state.originSelectedVendorAdress.country_id !== 99 ? 'Y' : 'N'
                })
           }
        })
    }


    //********************** Handle Destination Type Radio Button *******************/
    handleDestinationType           =   (destination_type) => {
        if(destination_type === "warehouse") {
            this.setState({
                addLogisticForm             :   {
                    ...this.state.addLogisticForm,
                    destination_type            :   destination_type,
                    destination_vendor_id       :   "",
                    destination_customer_id     :   "",
                },
                destinationWarehouseDetail  :   null,
                destinationCustomerDetail   :   null,
                destinationManualAddress    :   null,
                destinationManualChecked    :   false,
            });
        }else if(destination_type === "vendor") {
            this.setState({
                addLogisticForm             :   {
                    ...this.state.addLogisticForm,
                    destination_type            :   destination_type,
                    destination_warehouse_id    :   "",
                    destination_customer_id     :   "",
                },
                destinationWarehouseDetail  :   null,
                destinationCustomerDetail   :   null,
                destinationManualAddress    :   null,
                destinationManualChecked    :   false,
            });
        }else if(destination_type === "customer") {
            this.setState({
                addLogisticForm             :   {
                    ...this.state.addLogisticForm,
                    destination_type            :   destination_type,
                    destination_vendor_id       :   "",
                    destination_warehouse_id    :   "",
                },
                destinationWarehouseDetail  :   null,
                destinationCustomerDetail   :   null,
                destinationManualAddress    :   null,
                destinationManualChecked    :   false,
            });
        }else {
            this.setState({
                addLogisticForm             :   {
                    ...this.state.addLogisticForm,
                    destination_type            :   destination_type,
                    destination_vendor_id       :   "",
                    destination_warehouse_id    :   "",
                    destination_customer_id     :   "",
                },
                destinationWarehouseDetail  :   null,
                destinationCustomerDetail   :   null,
                destinationManualAddress    :   null
            });
        }
    }
    
    manualAddressModalShow          =   (type)  => {
        if(type === 'Destination') {
            this.setState({
                destinationManualChecked : true
            })
        }else if(type === 'Origin') {
            this.setState({
                originManualChecked : true,
            });
        }
        this.manualAddressModalRef.current.showModalInit(type);
    }

    //********************** Handle Origin Type Radio Button *******************/
    handleOriginType                  =   (origin_type) => {
        if(origin_type === "warehouse") {
            this.setState({
                addLogisticForm         :   {
                    ...this.state.addLogisticForm,
                    origin_type           :   origin_type,
                    origin_vendor_id      :   "",
                    origin_customer_id    :   "",
                },
                originWarehouseDetail       :   null,
                originCustomerDetail        :   null,
                originManualAddress         :   null,
                originManualChecked         :   false,
            });
        }else if(origin_type === "vendor") {
            this.setState({
                addLogisticForm         :   {
                    ...this.state.addLogisticForm,
                    origin_type           :   origin_type,
                    origin_warehouse_id   :   "",
                    origin_customer_id    :   "",
                },
                originWarehouseDetail       :   null,
                originCustomerDetail        :   null,
                originManualAddress         :   null,
                originManualChecked         :   false,
            });
        }else if(origin_type === "customer") {
            this.setState({
                addLogisticForm         :   {
                    ...this.state.addLogisticForm,
                    origin_type           :   origin_type,
                    origin_vendor_id      :   "",
                    origin_warehouse_id   :   "",
                },
                originWarehouseDetail       :   null,
                originCustomerDetail        :   null,
                originManualAddress         :   null,
                originManualChecked         :   false,
            });
        }else {
            this.setState({
                addLogisticForm         :   {
                    ...this.state.addLogisticForm,
                    origin_type           :   origin_type,
                    origin_vendor_id      :   "",
                    origin_warehouse_id   :   "",
                    origin_customer_id    :   "",
                },
                originWarehouseDetail       :   null,
                originCustomerDetail        :   null,
                originManualAddress         :   null
            });
        }
    }


    addLogisticFormSubmitEvent      =   (e)  =>  {

        e.preventDefault();
        const totalCost             =   this.totalCost();
        const data                  =   {...this.state.addLogisticForm, total_cost : totalCost};
        this.setState({ saveFormSubmitting: true });        
        HttpAPICall.withAthorization('POST', `${this.logisticUrl}/add`, this.props.access_token, null, data, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.props.history.push({pathname: `/logistic_list`});
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }


    handleLogisticType              =   (type)  =>  {
        if(type === "domestic") {
            this.setState({
                internationDataLoad     :   false,
            });
        }else if(type === "international") {
            this.setState({
                internationDataLoad     :   true,
            });
        }
    }

    totalCost   =   ()  =>  {
        const handlingCost  =   this.state.addLogisticForm.handling_cost === '' ? 0 : Number(this.state.addLogisticForm.handling_cost);
        const logisticCost  =   this.state.addLogisticForm.logistic_cost === '' ? 0 : Number(this.state.addLogisticForm.logistic_cost);
        
        let totalCost       =   handlingCost  + logisticCost;
        if(this.state.internationDataLoad) {
            const chaCost   =   this.state.addLogisticForm.cha_cost === '' ? 0 : Number(this.state.addLogisticForm.cha_cost);
            totalCost       +=  chaCost;
        }
        return totalCost;
    }

    handleLogisticSteps =   (status_id, type = "")  =>  {
        if(type !== 'Cancel') {
            const allSteps  =   this.state.logistic_steps;
            let steps       =   status_id && allSteps.find(step => step.status_id === status_id);
            this.setState({
                formStepsWithFields :   steps ? steps.steps : [],
            });
        }else{
            this.setState({
                formStepsWithFields :   [],
            }); 
        }
    }

    //**********************ADD LOGISTIC FORM JSX*******************/
    logisticFormJsx                  =   ()  =>  {
        const totalCost = this.totalCost();

        return (
            <form className="bg-white p-3" onSubmit={(e) => this.addLogisticFormSubmitEvent(e)} id="logisticForm">
                {
                    this.state.logisticFormDataLoaded 
                    ? <>
                        <div className="row align-items-center my-3">
                            <div className="col-sm-2 require">
                                <label htmlFor="site_id" className="form-label">Status</label>
                            </div>
                            <div className="col-sm-4">
                                <TapSelect
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addLogisticForm', 'status');
                                        this.handleLogisticSteps(selectedOption?.value, selectedOption?.label);
                                    }}
                                    options={this.state.allStatusOptions}
                                    isSearchable={true}
                                    isClearable={true}
                                    required={true}
                                    value={this.state.allStatusOptions.find(s => s.value == this.state.addLogisticForm.status)}
                                    placeholder="Select Status"
                                />
                            </div>
                        </div>
                        <div className="row align-items-center my-3">
                            <div className="col-sm-2 require">
                                <label htmlFor="site_id" className="form-label">Logistic Type</label>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className="col-sm-5">
                                        <input
                                            name="logistic_type"
                                            type="radio"
                                            value={"Domestic"}
                                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                                            className="form-check-input "
                                            id="domestic"
                                            required={true}
                                            onClick={() => this.handleLogisticType("domestic")}
                                        />
                                        <label className="form-check-label mx-2" htmlFor="domestic">Domestic</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <input
                                            name="logistic_type"
                                            type="radio"
                                            value={"International"}
                                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                                            className="form-check-input "
                                            id="international"
                                            required={true}
                                            onClick={() => this.handleLogisticType("international")}
                                        />
                                        <label className="form-check-label mx-2" htmlFor="international">International</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.logisticFormFields(totalCost)}
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Comments & History</span>
                        </div>
                        <div className="row align-items-center my-3">
                            <div className="col-sm-2 require">
                                <label htmlFor="notes" className="form-label">Comments</label>
                            </div>
                            <div className="col-sm-5">
                                <textarea
                                    name="notes"
                                    value={this.state.addLogisticForm.notes}
                                    onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                                    id="notes"
                                    className="form-control"
                                    placeholder="Please Add a comment"
                                    required
                                    style={{ height: "75px" }}
                                />
                            </div>
                        </div>
                    </>
                    : <Loader />
                }
                <div className="col-12 text-end fixed_footer">
                    <button type="button" disabled={this.state.saveFormSubmitting || !this.state.logisticFormDataLoaded} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                    <button type="submit" disabled={this.state.saveFormSubmitting || !this.state.logisticFormDataLoaded} className="btn btn-primary ms-2">
                        Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                </div>
            </form>
        );
    }

    logisticFormFields = (totalCost) => {
        return (
        <>
            {
                this.state.formStepsWithFields.length > 0 && this.state.formStepsWithFields.map((step, index) => {
                    return (<Ax key={index}>
                    {
                        this.state.internationDataLoad ?
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">{step.step_name }</span>
                        </div>
                        : step.step_name !== "CHA Details" && 
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">{step.step_name}</span>
                        </div>
                    }
                        <div className="row align-items-center my-3">
                        {
                            step.fields && step.fields.length > 0 && step.fields.map((field) => {
                                return (<Ax key={field}>
                                    {this.formFields(totalCost, field)}
                                </Ax>)
                            })
                        }
                        </div>
                    </Ax>)
                })
            }
        </>)
    }

    formFields = (totalCost, field) => {
        return (
        <>
        { field ===  'division' &&
            <>
                <div className="col-sm-2">
                    <label htmlFor="site_id" className="form-label ">Division</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addLogisticForm', 'division_id');
                        }}
                        options={this.state.divisionsList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.divisionsList.find(s => s.value == this.state.addLogisticForm.division_id)}
                        placeholder="Select Division"
                    />
                </div>
            </>   
        }
        {
            field === 'tag_users' &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Tag Users</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addLogisticForm', 'users')}
                        options={this.state.allUsers}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allUsers.filter(t => this.state.addLogisticForm.users.includes(t.value))}
                        placeholder="Please Select User"
                    />
                </div>
            </>   
        }
        {
            field === 'type_of_vehicle' &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="type_of_vehicle" className="form-label ">Type of Vehicle</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="type_of_vehicle"
                        id="type_of_vehicle"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.type_of_vehicle}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter vehicle type"
                    />
                </div>
            </>   
        }
        { field ===  'origin_type' &&
            <>
                <div className="col-sm-2 require">
                    <label htmlFor="site_id" className="form-label ">Origin Type</label>
                </div>
                <div className="col-sm-10">
                    <div className="row">
                        <div className="col-sm-2">
                            <input
                                name="origin_type"
                                type="radio"
                                value={"warehouse"}
                                required={true}
                                onChange={(e) => this.handleOriginType('warehouse')}
                                className="form-check-input "
                                id="origin_warehouse"
                            />
                            <label className="form-check-label mx-2" htmlFor="origin_warehouse">Warehouse</label>
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="origin_type"
                                type="radio"
                                value={"vendor"}
                                required={true}
                                onChange={(e) => this.handleOriginType('vendor')}
                                className="form-check-input "
                                id="origin_vendor"
                            />
                            <label className="form-check-label mx-2" htmlFor="origin_vendor">Vendor</label>
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="origin_type"
                                type="radio"
                                value={"customer"}
                                required={true}
                                onChange={(e) => this.handleOriginType('customer')}
                                className="form-check-input "
                                id="origin_customer"
                            />
                            <label className="form-check-label mx-2" htmlFor="origin_customer">Customer</label>
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="origin_type"
                                type="radio"
                                required={true}
                                value={"manual_entry"}
                                onChange={(e) => this.handleOriginType('manual_entry')}
                                className="form-check-input "
                                checked={this.state.originManualChecked}
                                id="origin_manual_entry"
                                onClick={() => this.manualAddressModalShow("Origin")}
                            />
                            <label className="form-check-label mx-2" htmlFor="origin_manual_entry">Manual Entry</label>
                        </div>
                    </div>
                </div>
                {
                    this.state.addLogisticForm.origin_type === 'warehouse' && 
                    <>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-6 mt-3">
                            <TapSelect
                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addLogisticForm', 'origin_warehouse_id'); this.getWarehouseDetails(selectedOption, "originWarehouseDetail") }}
                                options={this.state.all_warehouses}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                value={this.state.all_warehouses.find(m => m.value == this.state.addLogisticForm.origin_warehouse_id)}
                                placeholder="Select Warehouse"
                            />
                        </div>
                    </>
                }
                {
                    this.state.addLogisticForm.origin_type === 'vendor' &&
                    <>
                    <div className="col-sm-2"></div>
                        <div className="col-sm-6 mt-3">
                            <Vendor
                                labelDisable={true}
                                showAddVendor={false}
                                vendorDetails={this.state.originVendorDetails}
                                details_loading={this.state.originDetailsLoading}
                                vendorAddressList={this.state.originVendorAddressList}
                                vendor_details_loading={this.state.originVendorDetailsLoading}
                                selectedVendorAdress={this.state.originSelectedVendorAdress}
                                searchedVendor={this.state.originSearchedVendor}
                                onChange={this.handleOriginVendor}
                                getVendorDetails={this.getOriginVendorDetails}
                                // changeAddressHandler={this.changeAddressHandler}
                            />
                        </div>
                    </>
                }
                {    
                    this.state.addLogisticForm.origin_type === 'customer' &&
                    <>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-6 mt-3">
                            <InputCustomerSearch
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addLogisticForm', 'origin_customer_id');
                                    this.getCustomerDetail(selectedOption, "originCustomerDetail");
                                    this.setState({ searchedOriginCustomer: selectedOption ? selectedOption : null });
                                }}
                                required={true}
                                menuPlacement="top"
                                value={this.state.searchedOriginCustomer}
                            />
                        </div>
                    </>
                }
                {
                    this.state.origin_detail_loading 
                    ?
                    <Loader/>
                    :
                    this.state.originWarehouseDetail ?
                        <div className="offset-2 col-sm-8 mt-3">
                            <label className='form-label'>Address : {this.state.originWarehouseDetail.address1 ? this.state.originWarehouseDetail.address1 + ", " : ""}
                                {this.state.originWarehouseDetail.address2 ? this.state.originWarehouseDetail.address2 + ", " : ""}
                                {this.state.originWarehouseDetail.landmark ? this.state.originWarehouseDetail.landmark + ", " : ""}
                                {this.state.originWarehouseDetail.city ? this.state.originWarehouseDetail.city + ". " : "-"}</label><br />
                            <label className='form-label'>origin of Supply : {this.state.originWarehouseDetail && this.state.originWarehouseDetail.state ? <span>{this.state.originWarehouseDetail.state.name}</span> : "-"}</label> <br />
                            <label className='form-label'>GSTIN : {this.state.originWarehouseDetail && this.state.originWarehouseDetail.gstin ? <span>{this.state.originWarehouseDetail.gstin}</span> : "-"}</label>
                        </div>
                    : 
                    (this.state.originCustomerDetail 
                    ? 
                    <div className="offset-2 col-sm-8 mt-3">
                        <label className='form-label'>Address : {this.state.originCustomerDetail.address1 ? this.state.originCustomerDetail.address1 + ", " : ""}
                            {this.state.originCustomerDetail.address2 ? this.state.originCustomerDetail.address2 + ", " : ""}
                            {this.state.originCustomerDetail.landmark ? this.state.originCustomerDetail.landmark + ", " : ""}
                            {this.state.originCustomerDetail.city ? this.state.originCustomerDetail.city + ". " : "-"}</label><br />
                        <label className='form-label'>origin of Supply : {this.state.originCustomerDetail && this.state.originCustomerDetail.state ? <span>{this.state.originCustomerDetail.state.name}</span> : "-"}</label> <br />
                        <label className='form-label'>GSTIN : {this.state.originCustomerDetail && this.state.originCustomerDetail.gstin ? <span>{this.state.originCustomerDetail.gstin}</span> : "-"}</label>
                    </div>
                    : (
                    this.state.originManualAddress 
                    ? 
                    <div className="offset-2 col-sm-8 mt-3">
                        <label className='form-label'>Address : {this.state.originManualAddress.address1 ? this.state.originManualAddress.address1 + ", " : ""}
                            {this.state.originManualAddress.address2 ? this.state.originManualAddress.address2 + ", " : ""}
                            {this.state.originManualAddress.landmark ? this.state.originManualAddress.landmark + ", " : ""}
                            {this.state.originManualAddress.city ? this.state.originManualAddress.city + ". " : "-"}</label><br />
                        <label className='form-label'>origin of Supply : {this.state.originManualAddress && this.state.originManualAddress.state ? <span>{this.state.originManualAddress.state.name}</span> : "-"}</label> <br />
                        <label className='form-label'>GSTIN : {this.state.originManualAddress && this.state.originManualAddress.gstin ? <span>{this.state.originManualAddress.gstin}</span> : "-"}</label>
                    </div>
                    : null))
                }
            </>
        }
        { field ===  'destination' &&
            <>
                <div className="col-sm-2 require">
                    <label htmlFor="site_id" className="form-label">Choose Destination</label>
                </div>
                <div className="col-sm-10">
                    <div className="row">
                        <div className="col-sm-2">
                            <input
                                name="destination_type"
                                type="radio"
                                value={"warehouse"}
                                required={true}
                                onChange={(e) => this.handleDestinationType('warehouse')}
                                className="form-check-input "
                                id="warehouse"
                            />
                            <label className="form-check-label mx-2" htmlFor="warehouse">Warehouse</label>
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="destination_type"
                                type="radio"
                                value={"vendor"}
                                required={true}
                                onChange={(e) => this.handleDestinationType('vendor')}
                                className="form-check-input "
                                id="vendor"
                            />
                            <label className="form-check-label mx-2" htmlFor="vendor">Vendor</label>
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="destination_type"
                                type="radio"
                                value={"customer"}
                                required={true}
                                onChange={(e) => this.handleDestinationType('customer')}
                                className="form-check-input "
                                id="customer"
                            />
                            <label className="form-check-label mx-2" htmlFor="customer">Customer</label>
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="destination_type"
                                type="radio"
                                value={"manual_entry"}
                                required={true}
                                onChange={(e) => this.handleDestinationType('manual_entry')}
                                className="form-check-input "
                                checked={this.state.destinationManualChecked}
                                id="destination_manual_entry"
                                onClick={() => this.manualAddressModalShow("Destination")}
                            />
                            <label className="form-check-label mx-2" htmlFor="destination_manual_entry">Manual Entry</label>
                        </div>
                    </div>
                </div>
                {
                    this.state.addLogisticForm.destination_type === 'warehouse' && 
                    <>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-6 mt-3">
                            <TapSelect
                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addLogisticForm', 'destination_warehouse_id'); this.getWarehouseDetails(selectedOption, "destinationWarehouseDetail") }}
                                options={this.state.all_warehouses}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                value={this.state.all_warehouses.find(m => m.value == this.state.addLogisticForm.destination_warehouse_id)}
                                placeholder="Select Warehouse"
                            />
                        </div>
                    </>
                }
                {
                    
                    this.state.addLogisticForm.destination_type === 'vendor' &&
                    <>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-6 mt-3">
                            <Vendor
                                labelDisable={true}
                                showAddVendor={false}
                                vendorDetails={this.state.destinationVendorDetails}
                                details_loading={this.state.destinationDetailsLoading}
                                vendorAddressList={this.state.destinationVendorAddressList}
                                vendor_details_loading={this.state.destinationVendorDetailsLoading}
                                selectedVendorAdress={this.state.destinationSelectedVendorAdress}
                                searchedVendor={this.state.destinationSearchedVendor}
                                onChange={this.handleDestinationVendor}
                                getVendorDetails={this.getDestinationVendorDetails}
                                // changeAddressHandler={this.changeAddressHandler}
                            />
                        </div>
                    </>
                }
                {
                    
                    this.state.addLogisticForm.destination_type === 'customer' &&
                    <>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-6 mt-3">
                            <InputCustomerSearch
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addLogisticForm', 'destination_customer_id');
                                    this.getCustomerDetail(selectedOption, "destinationCustomerDetail");
                                    this.setState({ searchedDestinationCustomer: selectedOption ? selectedOption : null });
                                }}
                                required={true}
                                menuPlacement="top"
                                value={this.state.searchedDestinationCustomer}
                            />
                        </div>
                    </>
                }
                {
                    this.state.destination_detail_loading 
                    ?
                    <Loader/>
                    :
                    this.state.destinationWarehouseDetail ?
                        <div className="offset-2 col-sm-8 mt-3">
                            <label className='form-label'>Address : {this.state.destinationWarehouseDetail.address1 ? this.state.destinationWarehouseDetail.address1 + ", " : ""}
                                {this.state.destinationWarehouseDetail.address2 ? this.state.destinationWarehouseDetail.address2 + ", " : ""}
                                {this.state.destinationWarehouseDetail.landmark ? this.state.destinationWarehouseDetail.landmark + ", " : ""}
                                {this.state.destinationWarehouseDetail.city ? this.state.destinationWarehouseDetail.city + ". " : "-"}</label><br />
                            <label className='form-label'>Destination of Supply : {this.state.destinationWarehouseDetail && this.state.destinationWarehouseDetail.state ? <span>{this.state.destinationWarehouseDetail.state.name}</span> : "-"}</label> <br />
                            <label className='form-label'>GSTIN : {this.state.destinationWarehouseDetail && this.state.destinationWarehouseDetail.gstin ? <span>{this.state.destinationWarehouseDetail.gstin}</span> : "-"}</label>
                        </div>
                    : (this.state.destinationCustomerDetail 
                    ? 
                    <div className="offset-2 col-sm-8 mt-3">
                        <label className='form-label'>Address : {this.state.destinationCustomerDetail.address1 ? this.state.destinationCustomerDetail.address1 + ", " : ""}
                            {this.state.destinationCustomerDetail.address2 ? this.state.destinationCustomerDetail.address2 + ", " : ""}
                            {this.state.destinationCustomerDetail.landmark ? this.state.destinationCustomerDetail.landmark + ", " : ""}
                            {this.state.destinationCustomerDetail.city ? this.state.destinationCustomerDetail.city + ". " : "-"}</label><br />
                        <label className='form-label'>origin of Supply : {this.state.destinationCustomerDetail && this.state.destinationCustomerDetail.state ? <span>{this.state.destinationCustomerDetail.state.name}</span> : "-"}</label> <br />
                        <label className='form-label'>GSTIN : {this.state.destinationCustomerDetail && this.state.destinationCustomerDetail.gstin ? <span>{this.state.destinationCustomerDetail.gstin}</span> : "-"}</label>
                    </div>
                    : (
                    this.state.destinationManualAddress 
                    ? 
                    <div className="offset-2 col-sm-8 mt-3">
                        <label className='form-label'>Address : {this.state.destinationManualAddress.address1 ? this.state.destinationManualAddress.address1 + ", " : ""}
                            {this.state.destinationManualAddress.address2 ? this.state.destinationManualAddress.address2 + ", " : ""}
                            {this.state.destinationManualAddress.landmark ? this.state.destinationManualAddress.landmark + ", " : ""}
                            {this.state.destinationManualAddress.city ? this.state.destinationManualAddress.city + ". " : "-"}</label><br />
                        <label className='form-label'>origin of Supply : {this.state.destinationManualAddress && this.state.destinationManualAddress.state ? <span>{this.state.destinationManualAddress.state.name}</span> : "-"}</label> <br />
                        <label className='form-label'>GSTIN : {this.state.destinationManualAddress && this.state.destinationManualAddress.gstin ? <span>{this.state.destinationManualAddress.gstin}</span> : "-"}</label>
                    </div>
                    : null))
                }
            </>
        }
    
        {
            field ===  "date_of_dispatch" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Date of Dispatch</label>
                </div>
                <div className="col-sm-4 add_calender_section mt-3">
                    <DatePicker
                        selected={
                            this.state.addLogisticForm.dispatch_date
                                ? moment(this.state.addLogisticForm.dispatch_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="dispatch_date"
                        onChange={(value, event) => this.formDateHandler('dispatch_date', value, 'addLogisticForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        
                        placeholderText={`Dispatch Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Dispatch Date" className="add_calender-icon" />
                </div>
            </>
        }
        {
            field ===  "expected_delivery_date" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Expected Delivery Date</label>
                </div>
                <div className="col-sm-4 add_calender_section mt-3">
                    <DatePicker
                        selected={
                            this.state.addLogisticForm.delivery_date
                                ? moment(this.state.addLogisticForm.delivery_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="delivery_date"
                        onChange={(value, event) => this.formDateHandler('delivery_date', value, 'addLogisticForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        
                        placeholderText={`Enter Delivery Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date" className="add_calender-icon" />
                </div>
            </>
        }
        {
            field ===  "delivery_date_at_godown" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Delivery Date at Godown</label>
                </div>
                <div className="col-sm-4 add_calender_section mt-3">
                    <DatePicker
                        selected={
                            this.state.addLogisticForm.delivery_date_at_godown
                                ? moment(this.state.addLogisticForm.delivery_date_at_godown, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="delivery_date_at_godown"
                        onChange={(value, event) => this.formDateHandler('delivery_date_at_godown', value, 'addLogisticForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        
                        placeholderText={`Enter Delivery Date at Godown`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date at Godown" className="add_calender-icon" />
                </div>
            </>
        }
        {
            field ===  "delivery_date_at_site" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Delivery Date at Site</label>
                </div>
                <div className="col-sm-4 add_calender_section mt-3">
                    <DatePicker
                        selected={
                            this.state.addLogisticForm.delivery_date_at_site
                                ? moment(this.state.addLogisticForm.delivery_date_at_site, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="delivery_date_at_site"
                        onChange={(value, event) => this.formDateHandler('delivery_date_at_site', value, 'addLogisticForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        
                        placeholderText={`Enter Delivery Date at Site`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date at Site" className="add_calender-icon" />
                </div>
            </>
        }
        {
            field ===  "receiver_name" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Receiver Name</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="receiver_name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.receiver_name}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Receiver Name"
                    />
                </div>
            </>
        }
        {
            field ===  "type_of_load" && 
            <>
                <div className="col-sm-2 require">
                    <label htmlFor="site_id" className="form-label ">Type of Load</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addLogisticForm', 'load_type');
                        }}
                        options={this.state.internationDataLoad ? this.internationalLoadType : this.domesticLoadType}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={
                            this.state.internationDataLoad 
                            ? 
                            this.internationalLoadType.find(s => s.value == this.state.addLogisticForm.load_type)
                            :
                            this.domesticLoadType.find(s => s.value == this.state.addLogisticForm.load_type)
                        }
                        placeholder="Select Load Type"
                    />
                </div>
            </>
        }
        {
            field ===  "consigment_number" && 
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Consigment Number</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="consigment_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.consigment_number}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Consigment Number"
                    />
                </div>
            </>
        }
        {
            field ===  "booking_place" && 
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Booking Place</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="booking_place"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.booking_place}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Booking Place"
                    />
                </div>
            </>
        }
        {
            field ===  "transporter_name" && 
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">Courier/Transporter Name</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addLogisticForm', 'transporter_id');
                        }}
                        options={this.state.transportersList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.transportersList.find(s => s.value == this.state.addLogisticForm.transporter_id)}
                        placeholder="Select Courier/Transporter"
                    />
                </div>
            </>
        }
        {
            field ===  "vehicle_number" && 
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="vehicle_number" className="form-label ">Vehicle Number</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="vehicle_number"
                        id="vehicle_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.vehicle_number}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Vehicle Number"
                    />
                </div>
            </>
        }
        { 
            field ===  "weight"  &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="weight" className="form-label ">Weight</label>
                </div>
                <div className="col-sm-2 mt-3">
                    <input
                        name="weight"
                        id="weight"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.weight}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Weight"
                    />
                </div>
                <div className="col-sm-2 mt-3">
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addLogisticForm', 'unit_of_measurement');}}
                        options={this.unitOfMeasurement} 
                        id="unit_of_measurement"
                        isSearchable={true}
                        isClearable={true}
                        value={this.unitOfMeasurement.find(unit => unit.value == this.state.addLogisticForm.unit_of_measurement)}
                        placeholder="Select Unit"
                    />
                </div>
            </>
        }
        {
            field === "no_of_boxes" && 
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="no_of_box" className="form-label ">No. of Boxes</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="number_of_boxes"
                        id="no_of_box"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.number_of_boxes}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter No. of Boxes"
                    />
                </div>
            </>
        }
        {
            field === 'driver_contact_number' &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="driver_contact_number" className="form-label ">Driver Contact Number</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="driver_contact_number"
                        id="driver_contact_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.driver_contact_number}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Driver Contact Number"
                    />
                </div>
            </>   
        }
        {
            field === 'driver_name' &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="driver_name" className="form-label ">Driver Name</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="driver_name"
                        id="driver_name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.driver_name}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Driver Name"
                    />
                </div>
            </>   
        }

        {
            field ===  "invoice_number" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="invoice_number" className="form-label ">Invoice Number</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="gst_invoice_number"
                        id="invoice_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.gst_invoice_number}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Invoice Number"
                    />
                </div>
            </>
        }
        {
            field ===  "invoice_date" && 
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="invoice_date" className="form-label ">Invoice Date</label>
                </div>
                <div className="col-sm-4 add_calender_section mt-3">
                    <DatePicker
                        selected={
                            this.state.addLogisticForm.invoice_date
                                ? moment(this.state.addLogisticForm.invoice_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="invoice_date"
                        id="invoice_date"
                        onChange={(value, event) => this.formDateHandler('invoice_date', value, 'addLogisticForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Enter Invoice Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Invoice Date" className="add_calender-icon" />
                </div>
            </>
        }
        {
            field ===  "invoice_value" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="invoice_value" className="form-label ">Invoice Value</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="invoice_value"
                        id="invoice_value"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.invoice_value}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Invoice Value"
                    />
                </div>
            </>
        }
        {
            field ===  "eway_bill_number" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="eway_bill_number" className="form-label ">Eway Bill Number</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="eway_bill_number"
                        id="eway_bill_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.eway_bill_number}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Eway Bill Number"
                    />
                </div>
            </>
        }
        {
            field ===  "payment_mode" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="payment_mode" className="form-label ">Payment Mode</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addLogisticForm', 'payment_mode');}}
                        options={this.state.paymentMode} 
                        id="payment_mode"
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.paymentMode.find(mode => mode.value == this.state.addLogisticForm.payment_mode)}
                        placeholder="Select Payment Mode"
                    />
                </div>
            </>
        }
        {
            field ===  "payment_date" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="payment_date" className="form-label ">Payment Date</label>
                </div>
                <div className="col-sm-4 add_calender_section mt-3">
                    <DatePicker
                        selected={
                            this.state.addLogisticForm.payment_date
                                ? moment(this.state.addLogisticForm.payment_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="payment_date"
                        id="payment_date"
                        onChange={(value, event) => this.formDateHandler('payment_date', value, 'addLogisticForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Enter Payment Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Payment Date" className="add_calender-icon" />
                </div>
            </>
        }
        {
            field ===  "material_description" &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="material_description" className="form-label ">Machinery/Material Description</label>
                </div>
                <div className="col-sm-10 mt-3">
                    <input
                        name="machinery_description"
                        type="text"
                        id="material_description"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.machinery_description}
                        onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                        placeholder="Enter Machinery / Material Description"
                    />
                </div>
            </>
        }
        {
        this.state.internationDataLoad &&
        <>
            {
                field ===  "cnf_agent" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="cnf_agent" className="form-label ">CHA Agent</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addLogisticForm', 'cnf_agent_id');
                            }}
                            options={this.state.cnfAgentsList}
                            id="cnf_agent"
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.cnfAgentsList.find(s => s.value == this.state.addLogisticForm.cnf_agent_id)}
                            placeholder="Select CHA Agent"
                        />
                    </div>
                </>
            }
            {
                field ===  "material_handler_name" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="material_handler_name" className="form-label ">Freight Forwarder Name</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addLogisticForm', 'handler_id');
                            }}
                            options={this.state.handlersList}
                            id="material_handler_name"
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.handlersList.find(s => s.value == this.state.addLogisticForm.handler_id)}
                            placeholder="Select Freight Forwarder"
                        />
                    </div>
                </>
            }
            {
                field ===  "boe_number" &&
                <>
                    <div className="col-sm-2 mt-3">
                        <label htmlFor="boe_number" className="form-label ">BOE Number</label>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <input
                            name="boe_number"
                            id="boe_number"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={this.state.addLogisticForm.boe_number}
                            onChange={(e) => this.formInputHandler(e, 'addLogisticForm')}
                            placeholder="Enter BOE Number"
                        />
                    </div>
                </>
            }
        </>
        } 
        {
            field ===  "logistic_cost" && 
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="logistic_cost" className="form-label ">Freight Forwarding Cost</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="logistic_cost"
                        id="logistic_cost"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.logistic_cost}
                        onChange={(e) => {
                            this.formInputHandler(e, 'addLogisticForm');
                        }}
                        placeholder="Enter Freight Forwarding Cost"
                    />
                </div>
            </>
        }
        {
            field ===  "cha_cost" && this.state.internationDataLoad &&
            <>
                <div className="col-sm-2 mt-3">
                    <label htmlFor="site_id" className="form-label ">CHA Cost</label>
                </div>
                <div className="col-sm-4 mt-3">
                    <input
                        name="cha_cost"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.cha_cost}
                        onChange={(e) => {
                            this.formInputHandler(e, 'addLogisticForm');
                        }}
                        placeholder="Enter CHA Cost"
                    />
                </div>
            </>
        }
        {
            field ===  "handling_cost" && 
            <>
                <div className="col-sm-2 my-3">
                    <label htmlFor="site_id" className="form-label ">Transporter Cost</label>
                </div>
                <div className="col-sm-4 my-3">
                    <input
                        name="handling_cost"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addLogisticForm.handling_cost}
                        onChange={(e) => {
                            this.formInputHandler(e, 'addLogisticForm');
                        }}
                        placeholder="Enter Transporter Cost"
                    />
                </div>
            </>
        }
        {
            field ===  "total_cost" && 
            <>
                <div className="col-sm-2 my-3">
                    <label htmlFor="site_id" className="form-label ">Total Cost</label>
                </div>
                <div className="col-sm-4 my-3">
                    <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        disabled={true}
                        value={totalCost}
                        placeholder="Enter Total Cost"
                    />
                </div>
            </>
        }
        </>
        )
    }


    handleModalCancel   =   (type) => {
        if(type === 'Destination') {
            this.setState({
                destinationManualChecked : false,
            })
        }else if(type === 'Origin') {
            this.setState({
                originManualChecked : false,
            })
        }
    }

    manualAddress        =   (address, type) => {
        if(type === 'Destination') {
            this.setState({
                destination_detail_loading : true,
                addLogisticForm : {...this.state.addLogisticForm, destination_address: address},
                destinationManualAddress : address,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        destination_detail_loading : false,
                    })
                }, 1000);
            })
        }else if(type === 'Origin') {
            this.setState({
                origin_detail_loading : true,
                addLogisticForm : {...this.state.addLogisticForm, origin_address: address},
                originManualAddress : address,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        origin_detail_loading : false,
                    })
                }, 1000);
            })
        }
    }

    //****************************CLOSE*********************/
    closeEvent                      =   ()  =>  {
        this.props.history.push({pathname: `/logistic_list`})
    }

    render                                  =   ()  =>  {
     
        return (<ApplicationLayout>
        <Helmet><title>Add Logistic</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>Add Logistic</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close logistic" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {
                            this.logisticFormJsx()
                        }
                        <ManualEntryModal ref = {this.manualAddressModalRef} manualAddress = {(address, type) => this.manualAddress(address, type)} modalCancel = {(type) => this.handleModalCancel(type)}/>
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_group_sites         :   state.app && state.app.user_sites ? state.app.group_sites : [],
        permissions             :   state.app.acl_info,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : '',
        currency_list           :   state.app && state.app.currency_list && state.app.currency_list.length > 0 ? state.app.currency_list : []
    };
};

export default connect(mapStateToProps)(LogisticAdd);