import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import uuid from "react-uuid";
import { Modal } from "bootstrap";
import Ax from "../../../components/hoc/Ax";
import AdditionalAttribute from "../AdditionalAttribute";
import { ToastContainer, toast } from "react-toastify";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { IAM_API_BASE_URL_2, ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import TapIcon from "../../../services/TapIcon";
import swal from "sweetalert";

class Downtime extends AppBaseComponent {
    constructor(props) {
        super(props);

        this.downtimeTypeFormDataInit = {
            id: null,
            name: "",
            key: "",
            impact_on_availability: "N",
            generic: "N",
        };
        this.state = {
            config: {
                defect_code_downtime: "Y",
                enable_start_end_time: "N",
                display_sub_section: "N",
            },
            configLoading: false,
            configEditFormSubmiting: false,
            currentConfigKey: "",
            generalConfigForm: {},

            downtimeTypeLoading: false,
            downtimeTypeFormSubmiting: false,
            downtimeTypes: [],
            downtimeType: null,
            downtimeTypeFormData: { ...this.downtimeTypeFormDataInit },
        };
        this.configEditModalId = uuid();
        this.downtimeTypeEditModalId = uuid();

        this.downtimeTypeUrl = IAM_API_BASE_URL_2 + "/downtime_type";
        this.congigKeyText = {
            defect_code_downtime: "Enable Asset Defect Code in Downtime",
            enable_start_end_time:
                "Enable Tracking of Downtime with Start and End Time",
            display_sub_section: "Enable Tracking of Sub-Section",
        };

        this.settingEditUrl = ORG_API_BASE_URL_2 + "/setting_edit";
        this.settingUrl = ORG_API_BASE_URL_2 + "/settings";
    }

    componentDidMount = () => {
        this.configEditModal = new Modal(
            document.getElementById(this.configEditModalId),
            { keyboard: false, backdrop: false }
        );
        this.downtimeTypeEditModal = new Modal(
            document.getElementById(this.downtimeTypeEditModalId),
            { keyboard: false, backdrop: false }
        );

        this.getGeneralSettings();
        this.getDowntimeTypes();
    };

    getGeneralSettings = () => {
        let params = {
            keys: [
                "defect_code_downtime",
                "enable_start_end_time",
                "display_sub_section",
            ],
        };
        this.setState({ configLoading: true });
        HttpAPICall.withAthorization(
            "GET",
            this.settingUrl,
            this.props.access_token,
            params,
            {},
            (resp) => {
                this.setState({ config: resp.data.data });
            }
        ).then(() => this.setState({ configLoading: false }));
    };

    editConfigurationInit = (key) => {
        this.configEditModal.show();
        this.setState({
            currentConfigKey: key,
            generalConfigForm: { [key]: this.state.config[key] },
        });
    };

    configEditModalSubmit = (e) => {
        e.preventDefault();
        this.setState({ configEditFormSubmiting: true });
        HttpAPICall.withAthorization(
            "PUT",
            this.settingEditUrl,
            this.props.access_token,
            {},
            { ...this.state.generalConfigForm },
            (response) => {
                toast.success(response.data.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                this.configEditModal.hide();
                this.getGeneralSettings();
            }
        ).then(() => this.setState({ configEditFormSubmiting: false }));
    };

    getDowntimeTypes = () => {
        this.setState({ downtimeTypeLoading: true });
        HttpAPICall.withAthorization("GET", this.downtimeTypeUrl + "/list", this.props.access_token, {}, {}, (resp) => {
                this.setState({ downtimeTypes: resp.data.data });
            }
        ).then(() => this.setState({ downtimeTypeLoading: false }));
    };

    downTimeTypeActionInit = (id = null) => {
        this.downtimeTypeEditModal.show();
        let downtimeType = null;
        if (id) {
            downtimeType = this.state.downtimeTypes.find((d) => d.id == id);
        }
        this.setState({
            downtimeType: downtimeType,
            downtimeTypeFormData: {
                ...this.state.downtimeTypeFormData,
                id: downtimeType ? downtimeType.id : null,
                name: downtimeType ? downtimeType.name : "",
                key: downtimeType ? downtimeType.key : "",
                impact_on_availability: downtimeType
                    ? downtimeType.impact_on_availability
                    : "N",
                generic: downtimeType ? downtimeType.generic : "N",
            },
        });
    };

    downTimeTypeDeleteInit = (id = null) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            buttons: ["No", "Yes"],
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({ downtimeTypeLoading: true });
                HttpAPICall.withAthorization(
                    "DELETE",
                    this.downtimeTypeUrl + "/delete",
                    this.props.access_token,
                    { id: id },
                    {},
                    (response) => {
                        toast.success(response.data.msg, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        this.getDowntimeTypes();
                    },
                    () => {
                        this.setState({ downtimeTypeLoading: false });
                    }
                ).then(() => { });
            }
        });
    };

    downTimeTypeSubmit = (e) => {
        e.preventDefault();
        this.setState({ downtimeTypeFormSubmiting: true });
        if (this.state.downtimeType) {
            HttpAPICall.withAthorization(
                "PUT",
                this.downtimeTypeUrl + "/edit",
                this.props.access_token,
                {},
                this.state.downtimeTypeFormData,
                (response) => {
                    toast.success(response.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.downtimeTypeEditModal.hide();
                    this.getDowntimeTypes();
                }
            ).then(() => this.setState({ downtimeTypeFormSubmiting: false }));
        } else {
            HttpAPICall.withAthorization(
                "POST",
                this.downtimeTypeUrl + "/add",
                this.props.access_token,
                {},
                this.state.downtimeTypeFormData,
                (response) => {
                    toast.success(response.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.downtimeTypeEditModal.hide();
                    this.getDowntimeTypes();
                }
            ).then(() => this.setState({ downtimeTypeFormSubmiting: false }));
        }
    };

    configEditModalJsx = () => {
        return (
            <div className="modal fade" id={this.configEditModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">
                                Edit General Configuration
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form
                                className="row"
                                id={this.configEditModalId + "Form"}
                                onSubmit={this.configEditModalSubmit}
                            >
                                {[
                                    "defect_code_downtime",
                                    "enable_start_end_time",
                                    "display_sub_section",
                                ].includes(this.state.currentConfigKey) && (
                                        <div className="my-2">
                                            <label className="form-label">
                                                {this.congigKeyText[this.state.currentConfigKey]}
                                            </label>
                                            <div className="my-2">
                                                <div className="form-check form-check-inline me-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={this.state.currentConfigKey}
                                                        value="Y"
                                                        id="inputYes"
                                                        onChange={(e) =>
                                                            this.formInputHandler(e, "generalConfigForm")
                                                        }
                                                        checked={
                                                            this.state.generalConfigForm[
                                                            this.state.currentConfigKey
                                                            ] == "Y"
                                                        }
                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="inputYes">
                                                        {" "}
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline me-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={this.state.currentConfigKey}
                                                        value="N"
                                                        id="inputNo"
                                                        onChange={(e) =>
                                                            this.formInputHandler(e, "generalConfigForm")
                                                        }
                                                        checked={
                                                            this.state.generalConfigForm[
                                                            this.state.currentConfigKey
                                                            ] == "N"
                                                        }
                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="inputNo">
                                                        {" "}
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={this.state.configEditFormSubmiting}
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                form={this.configEditModalId + "Form"}
                                disabled={this.state.configEditFormSubmiting}
                            >
                                Save{" "}
                                {this.state.configEditFormSubmiting && (
                                    <TapIcon.FontAwesomeIcon
                                        icon={TapIcon.faSyncAlt}
                                        className="fa-spin"
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    downTimeTypeActionModalJax = () => {
        return (
            <div
                className="modal fade"
                id={this.downtimeTypeEditModalId}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {this.state.downtimeType ? "Edit" : "Add"} Downtime Type
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form
                                id={this.downtimeTypeEditModalId + "Form"}
                                onSubmit={this.downTimeTypeSubmit}
                            >
                                <div className="row mb-3">
                                    <div className="col-sm-6">
                                        <label className="form-label require">
                                            Downtime Type Name
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                this.formInputHandler(e, "downtimeTypeFormData")
                                            }
                                            disabled={this.state.downtimeTypeFormData.generic == "Y"}
                                            value={this.state.downtimeTypeFormData.name}
                                            type="text"
                                            className="form-control"
                                            placeholder="Downtime Type Name"
                                            name="name"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <label className="form-label require">
                                            Downtime Type Key
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                this.formInputHandler(e, "downtimeTypeFormData")
                                            }
                                            disabled={this.state.downtimeTypeFormData.generic == "Y"}
                                            value={this.state.downtimeTypeFormData.key}
                                            type="text"
                                            className="form-control"
                                            placeholder="Downtime Type Key"
                                            name="key"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={
                                            this.state.downtimeTypeFormData.impact_on_availability
                                        }
                                        checked={
                                            this.state.downtimeTypeFormData.impact_on_availability ==
                                            "Y"
                                        }
                                        onChange={(e) => {
                                            this.setState({
                                                downtimeTypeFormData: {
                                                    ...this.state.downtimeTypeFormData,
                                                    impact_on_availability:
                                                        this.state.downtimeTypeFormData
                                                            .impact_on_availability == "N"
                                                            ? "Y"
                                                            : "N",
                                                },
                                            });
                                        }}
                                        id="impact_on_availability"
                                    />
                                    <label
                                        className="form-check-label mx-2"
                                        htmlFor="impact_on_availability"
                                    >
                                        Impact On Availability
                                    </label>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={this.state.downtimeTypeFormSubmiting}
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                form={this.downtimeTypeEditModalId + "Form"}
                                disabled={this.state.downtimeTypeFormSubmiting}
                            >
                                Save{" "}
                                {this.state.downtimeTypeFormSubmiting && (
                                    <TapIcon.FontAwesomeIcon
                                        icon={TapIcon.faSyncAlt}
                                        className="fa-spin"
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render = () => {
        let { config } = { ...this.state };
        return (
            <section className="item_view_container bg-white">
                <Helmet>
                    <title>Assets Configuration : Downtime</title>
                </Helmet>
                <ToastContainer />
                <ul className="nav nav-tabs" id="myTab">
                    <li className="nav-item" role="downtimeTab">
                        <button
                            className="nav-link active"
                            id="setting-tab"
                            type="button"
                            data-bs-toggle="tab"
                            data-bs-target="#tab_1"
                            aria-controls="tab_1"
                            role="tab"
                            aria-selected="true"
                        >
                            Setting
                        </button>
                    </li>
                    <li className="nav-item" role="downtimeTab">
                        <button
                            className="nav-link"
                            id="additional-tab"
                            type="button"
                            data-bs-toggle="tab"
                            data-bs-target="#tab_2"
                            aria-controls="tab_2"
                            role="tab"
                            aria-selected="false"
                        >
                            Additional Attribute
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane show active"
                        id="tab_1"
                        aria-labelledby="setting-tab"
                    >
                        <div className="tab_content_header">
                            <div className="tab_content_wrapper mb-2">
                                <span className="content_heading">General Configuration</span>
                            </div>
                            <table className="table table-hover table-bordered">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th style={{ width: "5%" }}>S.No</th>
                                        <th className="text-start">Configuration</th>
                                        <th style={{ width: "25%" }}>Value</th>
                                        <th style={{ width: "10%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.configLoading ? (
                                        <tr>
                                            <td className="text-center" colSpan="4">
                                                <Loader />
                                            </td>
                                        </tr>
                                    ) : (
                                        <Ax>
                                            <tr className="text-center">
                                                <td>1</td>
                                                <td className="text-start">
                                                    {this.congigKeyText.defect_code_downtime}
                                                </td>
                                                <td>
                                                    {config &&
                                                        config.defect_code_downtime &&
                                                        config.defect_code_downtime == "Y"
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                                <td>
                                                    <span className="dropdown">
                                                        <button
                                                            type="button"
                                                            id="actionMenuButton"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            className="btn"
                                                        >
                                                            <TapIcon.FontAwesomeIcon
                                                                icon={TapIcon.faEllipsisV}
                                                            />{" "}
                                                        </button>
                                                        <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    role="button"
                                                                    onClick={() =>
                                                                        this.editConfigurationInit(
                                                                            "defect_code_downtime"
                                                                        )
                                                                    }
                                                                >
                                                                    Edit
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="text-center">
                                                <td>2</td>
                                                <td className="text-start">
                                                    {this.congigKeyText.enable_start_end_time}
                                                </td>
                                                <td>
                                                    {config &&
                                                        config.enable_start_end_time &&
                                                        config.enable_start_end_time == "Y"
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                                <td>
                                                    <span className="dropdown">
                                                        <button
                                                            type="button"
                                                            id="actionMenuButton"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            className="btn"
                                                        >
                                                            <TapIcon.FontAwesomeIcon
                                                                icon={TapIcon.faEllipsisV}
                                                            />{" "}
                                                        </button>
                                                        <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    role="button"
                                                                    onClick={() =>
                                                                        this.editConfigurationInit(
                                                                            "enable_start_end_time"
                                                                        )
                                                                    }
                                                                >
                                                                    Edit
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="text-center">
                                                <td>3</td>
                                                <td className="text-start">
                                                    {this.congigKeyText.display_sub_section}
                                                </td>
                                                <td>
                                                    {config &&
                                                        config.display_sub_section &&
                                                        config.display_sub_section == "Y"
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                                <td>
                                                    <span className="dropdown">
                                                        <button
                                                            type="button"
                                                            id="actionMenuButton"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            className="btn"
                                                        >
                                                            <TapIcon.FontAwesomeIcon
                                                                icon={TapIcon.faEllipsisV}
                                                            />{" "}
                                                        </button>
                                                        <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton1"
                                                        >
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    role="button"
                                                                    onClick={() =>
                                                                        this.editConfigurationInit(
                                                                            "display_sub_section"
                                                                        )
                                                                    }
                                                                >
                                                                    Edit
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </td>
                                            </tr>
                                        </Ax>
                                    )}
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-4 mb-2">
                                <span className="content_heading">Downtime Types</span>
                                <button
                                    className="text-end btn btn-light"
                                    onClick={this.downTimeTypeActionInit}
                                >
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} />
                                </button>
                            </div>
                            <table className="table table-hover table-bordered">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th style={{ width: "5%" }}>S.No</th>
                                        <th className="text-start" style={{ width: "50%" }}>
                                            Name
                                        </th>
                                        <th style={{ width: "10%" }}>key</th>
                                        <th style={{ width: "25%" }}>Impact On availability</th>
                                        <th style={{ width: "10%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.downtimeTypeLoading ? (
                                        <tr>
                                            <td className="text-center" colSpan="5">
                                                <Loader />
                                            </td>
                                        </tr>
                                    ) : (
                                        <Ax>
                                            {this.state.downtimeTypes.map((d, k) => {
                                                return (
                                                    <tr key={k} className="text-center">
                                                        <td>{k + 1}</td>
                                                        <td className="text-start">
                                                            {d.name}{" "}
                                                            {d.generic == "Y" && (
                                                                <sup className="form-text">*</sup>
                                                            )}
                                                        </td>
                                                        <td>{d.key}</td>
                                                        <td>
                                                            {d.impact_on_availability == "Y" ? "Yes" : "No"}
                                                        </td>
                                                        <td>
                                                            <span className="dropdown">
                                                                <button
                                                                    type="button"
                                                                    id="actionMenuButton"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                    className="btn"
                                                                >
                                                                    <TapIcon.FontAwesomeIcon
                                                                        icon={TapIcon.faEllipsisV}
                                                                    />{" "}
                                                                </button>
                                                                <ul
                                                                    className="dropdown-menu"
                                                                    aria-labelledby="dropdownMenuButton1"
                                                                >
                                                                    <li>
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            role="button"
                                                                            onClick={() =>
                                                                                this.downTimeTypeActionInit(d.id)
                                                                            }
                                                                        >
                                                                            Edit
                                                                        </Link>
                                                                    </li>
                                                                    {d.generic == "N" && (
                                                                        <li>
                                                                            <Link
                                                                                className="dropdown-item"
                                                                                role="button"
                                                                                onClick={() =>
                                                                                    this.downTimeTypeDeleteInit(d.id)
                                                                                }
                                                                            >
                                                                                Delete
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </Ax>
                                    )}
                                </tbody>
                            </table>
                            <div className="form-text my-2">* Global DownTime Type</div>
                        </div>
                    </div>
                    <div className="tab-pane" id="tab_2" aria-labelledby="additional-tab">
                        <div className="tab_content_header">
                            <AdditionalAttribute category="downtime" />
                        </div>
                    </div>
                </div>
                {this.configEditModalJsx()}
                {this.downTimeTypeActionModalJax()}
            </section>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        access_token: state.auth.access_token,
    };
};

export default connect(mapStateToProps)(Downtime);
