import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import HttpAPICall from "../../../../services/HttpAPICall";
import Ax from "../../../../components/hoc/Ax";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';

class Documents extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state                  =        {
            assetBasicDetails       :         [],
            matrix_id                :        null
        }
    }

    id              =   this.props.matrix_enc_id;

    componentDidMount() {
        this.setState({matrix_id : this.props.matrix_enc_id});
    }

    componentWillReceiveProps(nextProps) {
      
        if(nextProps.matrix_enc_id !== this.state.matrix_id ) {
            this.setState({matrix_id:nextProps.matrix_enc_id})
        }
        
    

    }

    getBasicAssetDetails                =     (id)    =>  {
        this.setState({basic_details_loading : true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/section_wise_detail/' + id,
            this.props.access_token)
            .then((response) => {
                let respData                =   response.data;
                this.setState({
                    assetBasicDetails        :    respData,
                });
            }).then(() => {
            this.setState({basic_details_loading: false});
        });
    }

   
    render() {
        let  id = this.props.matrix_enc_id

        return (
            <Ax>
                <Helmet><title>Asset Matrix Details</title></Helmet>

                <div className="row ml1">
                    <div className="col-12 pl0 bg-white ">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <Link to={`/asset_matrix/details/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        Details
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset_matrix/documents/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize show active" role="button" >
                                        Documents
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset_matrix/scs_setting/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        SCS Setting
                                    </button>
                                </Link>
                            </li>


                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane active " id="documents" role="tabpanel" aria-labelledby="documents-tab">
                                <div className="page_containt">
                                    <div className="pageTbl p-2" style={{ height: "100%" }}>
                                        <table className="table table-hover table-bordered my-3">
                                            <thead className='table-secondary'>
                                                <tr >
                                                    <th style={{ width: "5%" }} className='text-center'>S.No</th>
                                                    <th style={{ width: "30%" }}>Document Name</th>
                                                    <th style={{ width: "30%" }}>Document Number</th>
                                                    <th style={{ width: "30%" }} >Document File</th>
                                                    <th style={{ width: "5%" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
    }
}

export default connect(mapStateToProps)(Documents);
