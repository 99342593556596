import React, {useState, useEffect} from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapIcon from '../../services/TapIcon';
import TapSelect from '../../components/ui/TapSelect';
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Loader from '../../components/ui/Loader/Loader';
import { toast } from 'react-toastify';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { useLocation } from 'react-router-dom';

const AssetConfigurationAdd = (props) => {

    const assetProdConfigUrl    =   IAM_API_BASE_URL_2 + '/asset_productivity_config';

    const accessToken           =   useSelector(state => state.auth.access_token);
    const iamAssetType          =   useSelector(state => state.app.asset_type);
    const iamAssetMatrix        =   useSelector(state => state.app.asset_matrix);
    const iamAssetVariants      =   useSelector(state => state.app.asset_variants);
    const iamAssetmanufactures  =   useSelector(state => state.app.manufactures);
    const iamAssetModels        =   useSelector(state => state.app.models);
    const iamUserSite           =   useSelector(state => state.app.user_sites);

    const allTargetTypes        =   [{value:"onetime",label:"One Time"},{value:"daily",label:"Daily"},{value:"weekly",label:"Weekly"},{value:"monthly",label:"Monthly"},{value:"quarterly",label:"Quarterly"},{value:"yearly",label:"Yearly"}];

    const initAddConfigForm     =   {
        asset_type_ids              :   [],
        asset_matrix_ids            :   [],
        manufacturer_ids            :   [],
        model_ids                   :   [],
        asset_variants              :   [],
        material_ids                :   [],
        attachment_ids              :   [],
        workstation_ids             :   [],
        // employee_ids                :   [],
        site_ids                    :   [],

        name                        :   '',
        activity_id                 :   '',
        unit_of_measurement         :   '',
        account_code_id             :   '',
        status                      :   '',
        starting_date               :   '',
        closing_date                :   '',
        target_type                 :   '',
        total_productivity_target   :   '',
        target_per_day              :   '',
        cost_per_unit_target        :   '',
        notes                       :   ''
    }
    const [addConfigFormField, setAddConfigFormField]   =   useState(initAddConfigForm);
    const [formSubmitLoading, setFormSubmitLoading]     =   useState(false);
    const [allSiteCheck, setAllSiteCheck]               =   useState(false);

    const [formData, setFormData]   =   useState({loading: false, assetTypes : [], assetMatrix : [], assetVariants : [], manufactures : [], assetModels: [], allUnitsList : [], userSites : [], allStatus : [], allActivity: [], allAccountCode : [], allUOM : [], allMaterials : [], allAttachments : [], allWorkstations : [], activityData : []});

    const location                  =   useLocation();
    const editData                  =   location?.state; 

    useEffect(() => {
        initalFormData();
    }, []);

    useEffect(() => {
        addConfigFormField.total_productivity_target &&  calculateTragetPerDay(); 
              
        // Production Unit Get with Activity Id
        const { activityData }  =   formData;
        const productionUnits   =   activityData.length > 0 && addConfigFormField.activity_id ? activityData.filter(ac => ac.id == addConfigFormField.activity_id).map(pu => pu.production_units).flat() : [];
        productionUnits.length > 0 &&  setFormData(pre => ({...pre, allUOM : productionUnits.map(pu => ({label : pu.measuring_unit, value : pu.id}))}));

    }, [addConfigFormField.total_productivity_target, addConfigFormField.activity_id])
    

    const initalFormData    =   ()  =>  {
        setFormData(pre => ({...pre, loading : true}));
        HttpAPICall.withAthorization('GET', assetProdConfigUrl + '/form_data', accessToken, {}, {}, (res) => {
            const {status, activity_data, account_codes, materials, attachments, workstations}  =   res.data;
            setFormData(pre => ({
                ...pre, 
                activityData    :   activity_data.length > 0 ? activity_data : [],
                allMaterials    :   materials.length > 0 
                                    ? materials.map(s => { return ({ value: s.id, label: s.material_name })}) 
                                    : [],
                allAttachments  :   attachments.length > 0 
                                    ? attachments.map(s => { return ({ value: s.id, label: s.name })}) 
                                    : [],
                allWorkstations :   workstations.length > 0 
                                    ? workstations.map(s => { return ({ value: s.id, label: s.workstation_name })}) 
                                    : [],
                allStatus       :   status.length > 0 
                                    ? status.map(s => { return ({ value: s.key, label: s.status })}) 
                                    : [],
                allActivity     :   activity_data.length > 0 
                                    ? activity_data.map(av => { return ({ value: av.id, label: av.activity_name })}) 
                                    : [],
                allAccountCode  :   account_codes.length > 0 
                                    ? account_codes.map(ac => { return ({ value: ac.id, label: ac.name })}) 
                                    : [],
                assetTypes      :   iamAssetType.length > 0 
                                    ? iamAssetType.map(at => ({label : at.asset_type_name, value : at.id})) 
                                    : [],   
                assetMatrix     :   iamAssetMatrix.length > 0 
                                    ? iamAssetMatrix.map(am => ({label : am.asset_matrix_unique_id, value : am.id})) 
                                    : [], 
                assetVariants   :   iamAssetVariants.length > 0 
                                    ? iamAssetVariants.map(av => ({label : av, value : av})) 
                                    : [], 
                manufactures    :   iamAssetmanufactures.length > 0 
                                    ? iamAssetmanufactures.map(mn => ({label : mn.manufacturer_name, value : mn.id})) 
                                    : [], 
                assetModels     :   iamAssetModels.length > 0 
                                    ? iamAssetModels.map(mo => ({label : mo.model_name, value : mo.id})) 
                                    : [], 
                userSites       :   iamUserSite.length > 0 
                                    ? iamUserSite.map(s => ({label : s.site_name, value : s.id}))
                                    : [], 
            }));
        }).then(() => {
            setFormData(pre => ({...pre, loading : false}));
            if(editData) {
                setAddConfigFormField(pre=> ({
                    ...pre,
                    asset_type_ids              :   editData.asset_type 
                                                        ? editData.asset_type.map(at => at.id) : [],
                    asset_matrix_ids            :   editData.asset_matrix 
                                                        ? editData.asset_matrix.map(am => am.id) : [],
                    manufacturer_ids            :   editData.manufacturers 
                                                        ? editData.manufacturers.map(m => m.id) : [],
                    model_ids                   :   editData.models 
                                                        ? editData.models.map(m => m.id) : [],
                    asset_variants              :   editData.asset_variants 
                                                        ? editData.asset_variants.map(av => av) : [],
                    material_ids                :   editData.materials 
                                                        ? editData.materials.map(m => m.id) : [],
                    attachment_ids              :   editData.attachments 
                                                        ? editData.attachments.map(at => at.id) : [],
                    workstation_ids             :   editData.workstations 
                                                        ? editData.workstations.map(w => w.id) : [],
                    site_ids                    :   editData.sites 
                                                        ? editData.sites.map(s => s.id) : [],

                    activity_id                 :   editData.activity_detail.id,
                    name                        :   editData.name,
                    unit_of_measurement         :   editData.uom.id,
                    account_code_id             :   editData.account_code?.id,
                    status                      :   editData.status.status,
                    starting_date               :   editData.starting_date,
                    closing_date                :   editData.closing_date,
                    target_type                 :   editData.target_type,
                    total_productivity_target   :   editData.total_productivity_target,
                    target_per_day              :   editData.target_per_day,
                    cost_per_unit_target        :   editData.cost_per_unit_target,
                    notes                       :   editData.notes
                }));
               
            }
        }).catch((err) => {
            setFormData(pre => ({...pre, loading : false}));
        });
    }

    // Handle Close Button
    const handleClose           =   ()  =>  {
        if(editData) {
            props.history.push({ pathname: `/asset_productivity_configuration/${editData.id}`});
        }else {
            props.history.push({ pathname: `/asset_productivity_configuration`});
        }
    }
    // Handle Select Field
    const handleSelectField     =   (selectedOption, name) => {
 
        let value               =   Array.isArray(selectedOption) ? selectedOption.map(d => d.value) : selectedOption?.value;
        setAddConfigFormField(pre => ({...pre, [name] : value}));  
        
        if(name === 'target_type') {
            setAddConfigFormField(pre => ({...pre, total_productivity_target : '', target_per_day: '', cost_per_unit_target: ''}));  
        }
    }
    // Handle Input Changes
    const handleOnChange        =   (e) => {
        setAddConfigFormField(pre => ({...pre, [e.target.name] : e.target.value}));   
    }
    // Handle All Site
    const handleAllSites        =   (value) => {
        setAllSiteCheck(pre => !pre);
        setAddConfigFormField(pre => ({...pre, site_ids: value ? formData.userSites.map(s => s.value) : []}));
    }
    // Handle Date Field
    const handleDate            =   (value, name) => {
        let data                =    value ? moment(value).format("YYYY-MM-DD") : "";
        setAddConfigFormField(pre => ({
            ...pre, 
            [name] : data,
            target_type                 :   '',
            total_productivity_target   :   '',
            target_per_day              :   '',
        }));
        // calculateTragetPerDay()
    }

    const calculateTragetPerDay =   ()  =>  {
        let startDate           =   addConfigFormField.starting_date;
        let endDate             =   addConfigFormField.closing_date;
        let durationInDays      =    1;
        if(addConfigFormField.target_type === 'onetime'){
           if(startDate && endDate){
                let starting_date   =   moment(startDate);
                let closing_date    =   moment(endDate);
                durationInDays      =   closing_date.diff(starting_date, 'days');
           }else{
            toast.error("For One Time first select Starting and Closing Date", { position: toast.POSITION.TOP_RIGHT });
            setAddConfigFormField(pre => ({...pre, target_per_day:"",total_productivity_target:""}));
           }
        }else if(addConfigFormField.target_type  ==  'daily'){
            durationInDays  =   1;
        }else if(addConfigFormField.target_type  ==  'weekly'){
            durationInDays  =   7;
        }else if(addConfigFormField.target_type  ==  'monthly'){
            durationInDays  =   30;
        }else if(addConfigFormField.target_type  ==  'quartly'){
            durationInDays  =   91;
        }else{
            durationInDays  =   365;
        }
        // if(startDate && endDate){
        //     let starting_date   =   moment(startDate);
        //     let closing_date    =   moment(endDate);
        //     durationInDays      =   closing_date.diff(starting_date, 'days') + 1;
        // }

        if (addConfigFormField.target_type == 'onetime' && startDate && endDate) {
            let target_per_day = (Number(addConfigFormField.total_productivity_target) / durationInDays);
            setAddConfigFormField(pre => ({...pre, target_per_day : parseFloat(target_per_day).toFixed(3)}));
        } else if(addConfigFormField.target_type !== 'onetime') {
            let target_per_day = (Number(addConfigFormField.total_productivity_target) / durationInDays);
            setAddConfigFormField(pre => ({...pre, target_per_day : parseFloat(target_per_day).toFixed(3)}));
           
        }
    }


    const addConfigFormHandle   =   (e)  =>  {
        e.preventDefault();
        setFormSubmitLoading(pre => !pre);

        let id          =   '';
        let formData    =   addConfigFormField;
        let url         =   assetProdConfigUrl;
        let method      =   'POST';
        if(editData) {
            id          =   editData.id;
            formData    =   {...formData, id};
            url         =   url + '/edit';
            method      =   'PUT';
        }else {
            url         =   url + '/add';
        }

        HttpAPICall.withAthorization(method, url, accessToken, null, formData, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            if(editData) {
                props.history.push({ pathname: `/asset_productivity_configuration/` + id}); 
            }else {
                props.history.push({ pathname: `/asset_productivity_configuration`});
            }
            setAddConfigFormField(initAddConfigForm);
        }).then(() => setFormSubmitLoading(pre => !pre)).catch(() => setFormSubmitLoading(pre => !pre));
    }

    const lableClass    =   "col-sm-3 col-form-label col-form-label-sm";
    const rawDivClass   =   "row align-items-center my-3";
    const callDivClass  =   "col-sm-6";
    return (
    
        <>
            <ApplicationLayout>
                <Helmet><title>Asset Productivity - Configuration</title></Helmet>
                <div className="page_title row m0">
                    <div className="col-12">
                        <h3>{editData ? 'Edit' : 'Add'} Asset Productivity Configuration</h3>
                        <div className="text-end mt15">
                            <button onClick={handleClose} disabled={formData.loading || formSubmitLoading} type="button" className="btn btn-secondary">
                                <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="page_containt row pl2">
                        {
                            formData.loading 
                            ?
                            <Loader/>
                            : 
                            <div className="pageTbl col-12 pl4">
                                <form className="bg-white p-3" onSubmit={addConfigFormHandle} id="saveConfigurationForm">
                                    <div className={rawDivClass}>
                                        <label className={`${lableClass} require`}>Name</label>
                                        <div className={callDivClass}>
                                            <input
                                            name="name"
                                            type="text"
                                            value={addConfigFormField.name}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => handleOnChange(e)}
                                            placeholder="Please Enter Name"
                                            required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={`${lableClass} require`}>Activity Name</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.allActivity}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'activity_id')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={formData.allActivity.filter(ac => ac.value === addConfigFormField.activity_id)}
                                            placeholder="Select Activity Name"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={`${lableClass} require`}>Unit of Measurement</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.allUOM}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'unit_of_measurement')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={formData.allUOM.filter(uom => uom.value === addConfigFormField.unit_of_measurement)}
                                            placeholder="Select Unit of Measurement"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={`${lableClass} require`}>Sites</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.userSites}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.userSites.filter(s => addConfigFormField.site_ids.includes(s.value))}
                                            isDisabled={allSiteCheck}
                                            placeholder="Select Sites"
                                            />
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="all_sites"
                                                    type="checkbox"
                                                    onChange={() => handleAllSites(!allSiteCheck)}
                                                    value={allSiteCheck}
                                                    className="form-check-input"
                                                    id="all_sites"
                                                    checked={allSiteCheck}
                                                />
                                                <label className="form-check-label" htmlFor="all_sites">All Sites</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Asset Type</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.assetTypes}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.assetTypes.filter(at => addConfigFormField.asset_type_ids.includes(at.value))}
                                            placeholder="Select Asset Type"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Asset Matrix</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.assetMatrix}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'asset_matrix_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.assetMatrix.filter(am => addConfigFormField.asset_matrix_ids.includes(am.value))}
                                            placeholder="Select Asset Matrix"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Manufacturer</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.manufactures}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'manufacturer_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.manufactures.filter(mn => addConfigFormField.manufacturer_ids.includes(mn.value))}
                                            placeholder="Select Asset Manufacturers"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Model</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.assetModels}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'model_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.assetModels.filter(m => addConfigFormField.model_ids.includes(m.value))}
                                            placeholder="Select Asset Models"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Asset Variants</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.assetVariants}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'asset_variants')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.assetVariants.filter(av => addConfigFormField.asset_variants.includes(av.value))}
                                            placeholder="Select Asset Variants"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={`${lableClass} require`}>Status</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.allStatus}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'status')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={formData.allStatus.filter(s => s.value === addConfigFormField.status)}
                                            placeholder="Select Status"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Material</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.allMaterials}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'material_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.allMaterials.filter(m => addConfigFormField.material_ids.includes(m.value))}
                                            placeholder="Select Material"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Attachment</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.allAttachments}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'attachment_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.allAttachments.filter(atc => addConfigFormField.attachment_ids.includes(atc.value))}
                                            placeholder="Select Attachment"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>WorkStation</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.allWorkstations}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'workstation_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={formData.allWorkstations.filter(w => addConfigFormField.workstation_ids.includes(w.value))}
                                            placeholder="Select WorkStation"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className={rawDivClass}>
                                        <label className={lableClass}>Employee</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={[]}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'employee_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            // value={formData.allStatus.filter(s => s.value === addConfigFormField.status)}
                                            placeholder="Select Employee"
                                            />
                                        </div>
                                    </div> */}
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Accounting Code</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={formData.allAccountCode}
                                            changeEvent={(selectedOption) =>  handleSelectField(selectedOption, 'account_code_id')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={formData.allAccountCode.filter(ac =>  ac.value === addConfigFormField.account_code_id)}
                                            placeholder="Select Accounting Code"
                                            />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Starting Date</label>
                                        <div className={`${callDivClass} add_calender_section`}>
                                            <DatePicker
                                                selected={addConfigFormField.starting_date ? moment(addConfigFormField.starting_date, 'YYYY-MM-DD').toDate() : false }
                                                name="starting_date"
                                                onChange={(value) => handleDate(value, 'starting_date')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Starting Date`}
                                                required={false}
                                            />
                                            <TapIcon.imageIcon icon={TapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Closing Date</label>
                                        <div className={`${callDivClass} add_calender_section`}>
                                            <DatePicker
                                                selected={addConfigFormField.closing_date ? moment(addConfigFormField.closing_date, 'YYYY-MM-DD').toDate() : false }
                                                name="closing_date"
                                                onChange={(value) => handleDate(value, 'closing_date')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Closing Date`}
                                                required={false}
                                            />
                                            <TapIcon.imageIcon icon={TapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                        </div>
                                    </div>
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Target Type</label>
                                        <div className={callDivClass}>
                                            <TapSelect
                                            options={allTargetTypes}
                                            changeEvent={(selectedOption) => handleSelectField(selectedOption, 'target_type')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={allTargetTypes.filter(t => t.value === addConfigFormField.target_type)}
                                            placeholder="Select Target Type"
                                            />
                                        </div>
                                    </div>
                                    {addConfigFormField.target_type && <>
                                    <div className="row align-items-center my-3">
                                        <label className={`${lableClass} ${addConfigFormField.target_type ? 'require' : ''}`}>Total Productivity Target</label>
                                        <div className={callDivClass}>
                                            <input
                                                name="total_productivity_target"
                                                type="number"
                                                value={addConfigFormField.total_productivity_target}
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => handleOnChange(e) }
                                                required={addConfigFormField.target_type ? true : false}
                                                placeholder="Please Enter Total Productivity Target"
                                                min={0}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className={lableClass}>Target Per Day</label>
                                        <div className={callDivClass}>
                                            <input
                                                name="target_per_day"
                                                type="number"
                                                value={addConfigFormField.target_per_day}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Please Enter Target Per Day"
                                                disabled={true}
                                                min={0}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className={lableClass}>Cost Per Unit Target</label>
                                        <div className={callDivClass}>
                                            <input
                                                name="cost_per_unit_target"
                                                type="number"
                                                value={addConfigFormField.cost_per_unit_target}
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => handleOnChange(e)}
                                                placeholder="Please Enter Cost Per Unit Target"
                                            />
                                        </div>
                                    </div>
                                    </>
                                    }
                                    <div className={rawDivClass}>
                                        <label className={lableClass}>Notes</label>
                                        <div className={callDivClass}>
                                        <textarea
                                            name="notes"
                                            value={addConfigFormField.notes}
                                            onChange={(e) => handleOnChange(e)}
                                            className="form-control"
                                            style={{ height: "100px" }}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-12 text-end fixed_footer">
                                        <button type="button" disabled={formSubmitLoading} onClick={handleClose} className="btn btn-secondary mx-3">Cancel</button>
                                        <button type="submit" disabled={formSubmitLoading} className="btn btn-primary mx-2">
                                            Save 
                                            {formSubmitLoading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </ApplicationLayout>
        </>
    )
};

export default AssetConfigurationAdd;
