import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import { Modal } from 'bootstrap';
import TapIcon from '../../../../services/TapIcon';
import TapSelect from "../../../../components/ui/TapSelect";
import { ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import HttpAPICall from '../../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import Loader from '../../../../components/ui/Loader/Loader';


class Setting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initGeneralConfigForm      =   {
            enable_deal_quotation           :   'N',
            enable_deal_tags                :   'N',
            enable_sales_allocation         :   'N',
            enable_sales_order              :   'N',
            enable_sales_invoice            :   'N'
        };

        this.state                      =   {
            saveFormSubmitting              :   false,
            allAdditonalAttributes          :   [],
            generalConfigloading            :   false,
            general_config_list             :   null,
            generalConfigForm               :   {...this.initGeneralConfigForm},
            editFormSubmiting               :   false,
            currentTaskKey                  :   '',
            configuration_form_data         :   {key : '', name: ''},
        };

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';

        this.configData     =   [
            {key : 'enable_deal_tags', name : 'Enable Tags'},
            {key : 'enable_deal_quotation', name : 'Enable Quotation'},
            {key : 'enable_sales_order', name : 'Enable Sales Order'},
            {key : 'enable_sales_allocation', name : 'Enable Sales Allocation'},
            {key : 'enable_sales_invoice', name : 'Enable Sales Invoice'},
        ];

    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
    }

    getGeneralSettingListing = () => {
        let params = {
            keys : [ 'enable_deal_quotation','enable_deal_tags', 'enable_sales_allocation', 'enable_sales_order', 'enable_sales_invoice']
        }
        this.setState({ generalConfigloading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            this.setState({ general_config_list: resp.data.data })
        }).then(() => this.setState({ generalConfigloading: false }));
    }

    editGeneralConfigurationInit = (key = '', name = '') => {
        
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigForm[name] = val;
                });
            }
              
            this.setState({
                generalConfigForm : generalConfigForm,currentTaskKey : key, 
                configuration_form_data : {key, name}
            }, () => {
                this.editGeneralConfiguration.show();
            });
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , {...this.state.generalConfigForm} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }


    editGeneralConfigurationModalJsx      =    ()           =>     {
        const {configuration_form_data} =   this.state;
        return (
            <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-6"><label className="form-label">{configuration_form_data.name}</label></div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name={configuration_form_data.key}
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm[configuration_form_data.key] == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name={configuration_form_data.key}
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm[configuration_form_data.key] == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
  }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
        return (<table className="table table-hover table-bordered table-sm mt-2">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                    <th scope="col" className="text-start">Configuration</th>
                    <th scope="col" style={{ width: "25%" }} >Value</th>
                    <th scope="col" style={{ width: "10%" }} >Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.configData.map((val, i) => {
                        return (
                            <tr className="text-center">
                                <td>{++i}</td>
                                <td className="text-start">{val.name}</td>
                                <td>
                                    {
                                        data && data[val.key] === 'Y' ? 'Yes' : 'No'
                                    }
                                </td>
                                <td> <span className="dropdown">
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit(val.key, val.name)}>Edit</a></li>
                                    </ul>
                                </span></td>
                            </tr>
                        )
                    })
                }
                      
            </tbody>
        </table>)
    }

    render() {
      
        return (<div>

            <div className="p-1">
                <div className="tab_content_wrapper mt-2"><span className="content_heading">General Setting</span>
                </div>
                {this.state.generalConfigloading ? <Loader />
                    : this.generalConfigurationJsx()}
                {this.editGeneralConfigurationModalJsx()}
            </div>
            {this.editGeneralConfigurationModalJsx()}
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(Setting);