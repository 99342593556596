import moment from "moment";
import DateService from "./DateService";

function DateRangeService(first, last, step = '+1 day', format = 'YYYY-MM-DD') {
    const dates = [];
    let current = new Date(first);
    let endDate = new Date(last);
    
    const [value, unit] = step.split(' ');

    if(unit === 'year' || unit === 'years') {
        return getAllYear(current, endDate);
    }else {
        while (moment(current).startOf('month') <= moment(endDate).startOf('month')) {
            dates.push(DateService.dateFormat(current, format));
            current = addStep(current, step);
        }
    }

    return dates;
}

function getAllYear(current, endDate) {
    const startYear = current.getFullYear();
    const endYear = endDate.getFullYear();
    const yearsArray = [];
    for (let year = startYear; year <= endYear; year++) {
        yearsArray.push(year);
    }
    return yearsArray;
}

function addStep(date, step) {
    const [value, unit] = step.split(' ');
    const amount = parseInt(value, 10);
    const newDate = new Date(date);
    switch (unit) {
        case 'day':
        case 'days':
            newDate.setDate(newDate.getDate() + amount);
            break;
        case 'month':
        case 'months':
            newDate.setMonth(newDate.getMonth() + amount);
            break;
        // case 'year':
        // case 'years':
        //     newDate.setFullYear(newDate.getFullYear() + amount);
        //     break;
        default:
            throw new Error(`Unsupported step unit: ${unit}`);
    }

    return newDate;
}


export default DateRangeService;