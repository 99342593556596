import React, { useEffect, useState } from "react";
import TapIcon from "../../services/TapIcon";
import { Link } from "react-router-dom/cjs/react-router-dom";
import HttpAPICall from "../../services/HttpAPICall";
import { IAM_API_BASE_URL_2 } from "../../services/TapApiUrls";
import Loader from "../../components/ui/Loader/Loader";
import { useSelector } from "react-redux";
import DateService from "../../services/DateService";
import { DisplayListPagination } from "../../components/TapUi";

const ProjectDocument = (props) => {

    const [documents, setDocuments]          =   useState([]);
    const [loading, setLoading]              =   useState(false);
    const [listingMeta, setListingMeta]      =   useState(null);
    const accessToken                        =   useSelector(state => state.auth.access_token);

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = (page = 1) => {
        setLoading(pre => !pre);
        const documentUrl                   =  `${IAM_API_BASE_URL_2}/project/document/${props.project_id}`;
        HttpAPICall.withAthorization('GET',documentUrl, accessToken, {page}, {}, (resp) => {
            const respData                  = resp.data;
            setDocuments(respData.data)
            setListingMeta({
                from                        :   respData.from,
                to                          :   respData.to,
                total                       :   respData.total,
                last_page                   :   respData.last_page,
                per_page                    :   respData.per_page,
                current_page                :   respData.current_page
            });
        }).then(()  =>  setLoading(pre => !pre)).catch(() => setLoading(pre => !pre));
    }

    return (
        <>
            <table className="table table-bordered bg-white table-hover table-fixed table-sm">
                <thead className="table-secondary">
                    <tr className="text-center">
                        <th style={{ width: "5%" }}> S.No.</th>
                        <th style={{ width: "13%" }}>Ticket ID</th>
                        <th style={{ width: "19%" }}>Ticket Header</th>
                        <th style={{ width: "15%" }}>Document Name</th>
                        <th style={{ width: "15%" }}>Document Type</th>
                        <th style={{ width: "10%" }}>Upload By</th>
                        <th style={{ width: "15%" }}>Upload Timestamp</th>
                        <th style={{ width: "8%" }}> Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading 
                        ? <tr><td className="text-center" colSpan={8}><Loader/></td></tr>
                        :
                        documents.length > 0 ? documents.map((doc, index) => (
                            <tr className="text-center" key={doc.id}>
                                <td>{listingMeta ? listingMeta?.from + index : index}</td>
                                <td>{doc.ticket && doc.ticket.activity_id || "-"}</td>
                                <td>{doc.ticket && doc.ticket.header || "-"}</td>
                                <td>{doc?.name || "-"}</td>
                                <td className="text-capitalize"> {doc?.link_type || "-"}</td>
                                <td>{doc.created_by && doc.created_by.full_name || "-"}</td>
                                <td>{DateService.dateTimeFormat(doc?.uploaded_on, "DD-MMM-YYYY")}</td>
                                <td className="text-center">
                                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <Link to={{ pathname: doc?.path }} target='_blank'
                                            className={`dropdown-item ${doc.path ? '' : 'disabled-link'}`} role="button">View</Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: doc?.download_link }} target='_blank' role="button" className={`dropdown-item ${doc.download_link ? '' : 'disabled-link'}`}>Download
                                            </Link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        )) : <tr>
                            <td className="text-center" colSpan={8}>
                                No Record Found
                            </td>
                        </tr>

                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={listingMeta}
                onPageChange={(e) => fetchDocuments(e.selected + 1)}
            />
        </>
    );
};

export default ProjectDocument;
