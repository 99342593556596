import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import App from './pages/App';
//import 'bootstrap/dist/js/bootstrap.js';
import './assets/scss/main.css';
import 'bootstrap';
import TagManager from 'react-gtm-module'

import authReducer from './store/reducers/authRedicer';
import appReducer from './store/reducers/appRedicer';
import { allReducer } from './store/reducers/allReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth        :   authReducer,
    app         :   appReducer,
    ...allReducer
});
 
if(process.env.REACT_APP_ENV == 'production') {
    const tagManagerArgs = {
        gtmId: 'GTM-P33MNKL'
    };
    TagManager.initialize(tagManagerArgs);
}

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('app')
);

reportWebVitals();