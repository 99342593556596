import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import React from 'react';
import swal from "sweetalert";
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import POHistoryModal from '../../inventory/purchaseOrder/POHistoryModal';
import ReqHistoryItemWiseModal from '../../inventory/requisition/ReqHistoryItemWiseModal';
import PurchaseItemWiseModal from '../../inventory/purchase/PurchaseItemWiseModal';
import TransferItemWiseListModal from '../../inventory/inventoryTransfer/TransferItemWiseListModal';
import IndentDetailModal from '../../inventory/warehouseIndent/IndentDetailModal';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ItemWiseWarehouseIndent extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.reportFilterFormInitialState   =   {
            search_transaction_id               :   '',
            search_warehouse_ids                :   [],
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_status_ids                   :   [],
            search_manufacturer_ids             :   [],
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
            search_workstation_ids              :   [],
            search_asset_ids                    :   [],
            search_employee_ids                 :   [],
            tagged_user_filter                  :   '',
            tagged_user_ids                     :   [],
        };
        
        this.reportFilterFormLabel          =   {
            search_transaction_id               :   'Transaction ID : ',
            search_warehouse_ids                :   'Warehouse : ',
            search_workstation_ids              :   'WorkStation : ',
            search_asset_ids                    :   'Asset : ',
            search_item_ids                     :   'Items : ',
            search_item_tags                    :   'Item Tags : ',
            search_status_ids                   :   'Status : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            date_range                          :   'Date Range : ',
            search_employee_ids                 :   'Employees : ',
            tagged_user_filter                  :   'User Tagging : ',
            tagged_user_ids                     :   'Tagged Users : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            allItemTags                         :   [],
            all_periods                         :   [],
            allStatusOptions                    :   [],
            manufactures                        :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null, 
            searchedAssets                      :   [],
            searchedEmployees                   :   [], 
            workstations                        :   [] ,
            userFilters                         :   [
                {value  :   'tagged_me',        label : 'My Tagged Indent'},
                {value  :   'created_by_me',    label : 'Indent Created By Me'},
            ],
            allUsers                            :   [],
            download_access                     :   false,     
            reportData                          :   null,                
        };
        this.itemWisePORef                      =   React.createRef();
        this.itemWiseReqRef                     =   React.createRef();
        this.itemWisePurchaseRef                =   React.createRef();
        this.itemWiseTransferRef                =   React.createRef();
        this.indentDetailRef                    =   React.createRef();

        this.warehouseIndentUrl         =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-warehouse-indent';
        this.poReportUrl                =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase-order';

    }

    componentDidMount(){        
        this.initializeData(this.props)
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.all_warehouses !== this.props.all_warehouses || nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
         }     
    }

    initializeData     =      (pr)      =>      {

        const report_name   =   pr.location.pathname ? pr.location.pathname.split('/')[2] : '';
        if(pr){

            const reportData =  pr.report_listing.filter(d => d.key === report_name);

            let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            HttpAPICall.withAthorization('GET', this.warehouseIndentUrl, pr.access_token, {action:"formData"}, null, (response) => {
                this.setState({
                    reportData                  :   reportData.length > 0 ? reportData[0] : null,
                    download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                    reportFilterFormData        :   {...response.data},
                    all_warehouses              :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    formDataLoaded              :   true,
                    all_periods                 :   all_periods ,
                    manufactures                :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                    allStatusOptions            :   response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name,level : s.level}; }) : [],
                    allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    workstations                :   response.data.workstations && response.data.workstations.length > 0 ?  response.data.workstations.map((w) => {return {value: w.id, label: `${w.workstation_name} (${w.workstation_nickname})`}}) : [],
                    allUsers                    :   response.data && response.data.users ? response.data.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],

                }, () => {
                    let status_ids               =    [];
                    let updateFilterForm        =       {};
                    let ParamObject                 =       new URLSearchParams(pr.location.search);
                  
                    if(ParamObject.get('search_status_ids') ||  ParamObject.get('search_item_ids')){
                        if(ParamObject.get('search_status_ids')){
                            status_ids  =  ParamObject.get('search_status_ids').split(',').map(Number); 
                            if (ParamObject.get('search_warehouse_ids')) {
                                let warehouse =  this.state.all_warehouses.find(us => us.value == ParamObject.get('search_warehouse_ids'));
                                if (warehouse) {
                                    updateFilterForm['search_warehouse_ids'] = [warehouse.value];
                                }
                            }
                        }
                        if (ParamObject.get('tagged_user')) {
                            updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                        }
                        setTimeout(() => {
                            this.setState({
                                reportFilterForm        :   {...this.state.reportFilterForm,search_status_ids : status_ids,...updateFilterForm},
                               
                            },() => {
                                this.reportFilterFormSubmit()
                            })
                        },1000)
        
                    }else{
                        this.loadListingTblData(1)
                    }
                });
            }, (err) => {
            });
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_transaction_id : '';
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_workstation_ids') {
                        show_val            =   this.state.workstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'tagged_user_filter') {
                        show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'tagged_user_ids') {
                        show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                            let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                            show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm,action:"data"};
        HttpAPICall.withAthorization('GET', this.warehouseIndentUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   ()  =>  {
        let formData                            =   {...this.state.submittedReportFilterForm};
        if(formData.search_transaction_id != '' || formData.date_range != '') {
            this.reportDownloadApiCall(formData);
        } else if((JSON.stringify(formData) === JSON.stringify(this.reportFilterFormInitialState)) || formData.date_range == '') {
            swal({ 
                title: "Download", icon: "warning", buttons: ["Cancel", "Okay"],
                text: "The download will be for Trailing Tweleve Months, in case you want a different Period then apply the criteria before downloading.",
            }).then(willDownload => {
                if (willDownload) { this.reportDownloadApiCall({...formData, date_range: "trailing_twelve_months"}); }
            });
        }
    }
    
    reportDownloadApiCall                   =   (formData)  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.warehouseIndentUrl, this.props.access_token, {...formData, action : 'download'} , {} , (response) => DownloadFile.file(null,response.data.navgation,response.data.msg,this.props)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    viewItemWisePOInit          =       (itemData=null,warehouse_id='',indent_transacton_id='') => {
        // if(this.props.permissions.includes('isc-po-view')){
            let data = {id : itemData.item_id,name:itemData.item_name}
            this.itemWisePORef.current.modalInit(data,'',indent_transacton_id)
       // }
       
    }

    viewItemWiseReqInit          =       (itemData=null,warehouse_id='',indent_transacton_id='') => {
        let data = {id : itemData.item_id,name:itemData.item_name}
        this.itemWiseReqRef.current.modalInit(data ,'',indent_transacton_id)
    }

    viewItemWisePurchaseInit          =       (itemData=null,warehouse_id='',indent_transacton_id='') => {
        let data = {id : itemData.item_id,name:itemData.item_name}
        this.itemWisePurchaseRef.current.modalInit(data,'',indent_transacton_id)
    }

    viewItemWiseTransferInit          =       (itemData=null,warehouse_id='',indent_transacton_id='') => {
        let data = {id : itemData.item_id,name:itemData.item_name}
        this.itemWiseTransferRef.current.modalInit(data,'',indent_transacton_id)
    }

    viewIndentDetailModalInit                    =   (transaction_id)  =>  { 
        this.indentDetailRef.current.modalIndentInit(transaction_id)    
   }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name} </h3>
                <div className="text-end mt15">
                    {/* <button type="button" className="btn btn-primary" onClick={this.addPurchaseHandler}>Add Purchase</button>  */}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2"/>) : null}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link className={['dropdown-item', !this.state.download_access ? 'disabled' : ''].join(' ')} onClick={this.reportDownloadHandler}>Download</Link></li>
                    </ul>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
            <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse Indent</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.userFilters}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_filter')}
                            value={this.state.userFilters.find(u => u.value == this.state.reportFilterForm.tagged_user_filter)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Indent"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                    <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Tagged User</label>
                    <div className="col-sm-6">
                        <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Tagged User"
                            options={this.state.allUsers}
                            value={this.state.allUsers.filter(s => this.state.reportFilterForm.tagged_user_ids.includes(s.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_ids')}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.all_warehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.state.all_warehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
              
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Transaction ID</label>
                    <div className="col-sm-6">
                        <input
                            name="search_transaction_id"
                            type="text"
                            value={this.state.reportFilterForm.search_transaction_id}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Please enter Transaction ID"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Workstation</label>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_workstation_ids')}
                            options={this.state.workstations}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.workstations.filter(t => this.state.reportFilterForm.search_workstation_ids.includes(t.value))}
                            placeholder="Please Select Workstation"
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Employees</label>
                    <div className="col-sm-6">
                        <InputEmployeeSearch
                            changeEvent={(selectedOption) => {
                                
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_employee_ids');
                                this.setState({
                                    reportFilterForm  : {...this.state.reportFilterForm,search_employee_ids : selectedOption.length > 0 ? selectedOption.map(o => o.value) : []},
                                    searchedEmployees : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedEmployees && this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isMulti={true}
                            isClearable={true}
                            value={this.state.allStatusOptions.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range: period,
                                        date_range_start: startDate ? startDate : null,
                                        date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-fixed  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr>
                    {/* <th className='text-center' style={{ width: "5%" }}></th> */}
                        <th className='text-center' style={{ width: "9%" }}>Transaction ID</th>
                        <th className='text-center' style={{ width: "7%" }}>Transaction Date</th>
                        <th className="text-center" style={{ width: "15%" }}>Warehouse</th>
                        <th className="text-center" style={{ width: "17%" }}>Item Name</th>
                        <th className='text-center' scope="col" style={{ width: "7%" }}>Status</th>
                        <th className='text-end' scope="col" style={{ width: "4%" }} >Indent</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >UOM</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Fullfilled</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Pur. Req</th>
                        <th className='text-end' scope="col" style={{ width: "4%" }} >PO</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Purchase</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >InTransit</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Cancelled</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Pending</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((rowData, k) => {
                          
                                return (<tr key={k} className="text-end">
                                    {/* <td className='text-center'>
                                        <input type='checkbox'
                                            className="po_item_id_checkbox"
                                            // disabled={rowData.status_level !== 4 && rowData.status_level !== 5}
                                            // name="po"
                                            // value={rowData.po_item_id}
                                            // data-warehouseId={rowData.warehouse.id} 
                                            // data-vendorId={rowData.vendor_id}
                                            // data-vendorName={rowData.vendor_name}
                                            // data-pendingQty={rowData.pending_qty}
                                
                                        />
                                    </td> */}
                                    <td className='text-center link-primary cursor_pointer' onClick={() => this.viewIndentDetailModalInit(rowData.transaction_id)}>{rowData.transaction_id}</td>
                                    <td className='text-center'>{rowData.transaction_date_display}</td>
                                   <td className='text-start'>{rowData.warehouse ? rowData.warehouse.name : ""}</td>
                                   <td className='text-start'>{rowData.item_name ? rowData.item_name : ""}</td>
                                   <td className='text-center'>{rowData.status_data ? rowData.status_data.name : ""}</td>
                                    <td>{rowData.indent_qty ? rowData.indent_qty : 0}</td>
                                    <td>{rowData.measuring_unit ? rowData.measuring_unit.name : ''}</td>
                                    <td>{rowData.fullfilled_qty ? rowData.fullfilled_qty : 0}</td>
                                    <td onClick={() => this.viewItemWiseReqInit(rowData, rowData.warehouse.id, rowData.transaction_id)} className='link-primary cursor_pointer'>{rowData.req_qty ? rowData.req_qty : 0}</td>
                                    <td onClick={() => this.viewItemWisePOInit(rowData, rowData.warehouse.id, rowData.transaction_id)} className={'link-primary cursor_pointer'}>{rowData.po_qty ? rowData.po_qty : 0}</td>
                                    <td onClick={() => this.viewItemWisePurchaseInit(rowData, rowData.warehouse.id, rowData.transaction_id)} className='link-primary cursor_pointer'>{rowData.purchase_qty ? rowData.purchase_qty : 0}</td>
                                    <td onClick={() => this.viewItemWiseTransferInit(rowData, rowData.warehouse.id, rowData.transaction_id)} className='link-primary cursor_pointer'>{rowData.transit_qty ? rowData.transit_qty : 0}</td>
                                    <td>{rowData.cancelled_qty ? rowData.cancelled_qty : 0}</td>
                                    <td>{rowData.pending_qty ? rowData.pending_qty : 0}</td>
                                </tr>);
                           
                        }))
                        : (<tr><td className="text-center" colSpan={11}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>ItemWise Warehouse Indent - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta 
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
            <POHistoryModal ref={this.itemWisePORef}/> 
             <ReqHistoryItemWiseModal ref={this.itemWiseReqRef}/> 
            <PurchaseItemWiseModal ref={this.itemWisePurchaseRef}/>
            <TransferItemWiseListModal ref={this.itemWiseTransferRef}/>
            <IndentDetailModal ref={this.indentDetailRef}/>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemWiseWarehouseIndent);