import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import swal from "sweetalert";
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import tapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import TapSelect from '../../../components/ui/TapSelect';
import Status from '../../../components/ui/Status';
import AssetHeader from '../includes/AssetHeader';
import { Helmet } from 'react-helmet';
import AssetCard from '../../includes/assetCard/AssetCard';
import ResetCounterlogModal from '../counterlog/ResetCounterlogModal';
import Button from '../../../components/ui/Button';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';

class AssetSettings extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.initRosterMappingForm          =       {
            activation_date                 :       '',
            roster_id                       :       ''
        }

        this.initConfigurationForm         =        {
            "fuel_consumption"              :       " ",
            "fuel_economy"                   :       '',
            "planned_hour_meter"             :       '',
            "planned_hour_meter2"           :       '',
            "planned_hour_meter3"           :       '',
            "planned_hour_meter4"           :       '',
            "planned_odo_meter"             :       '',
            "planned_odo_meter2"            :       '',
            "planned_odo_meter3"            :       '',
            "planned_operation_cycle"       :       '',
            "planned_operation_cycle2"      :        ''
        }
        
        this.state                          =       {
            access_token                    :       '',
            assetDetail                     :       null,
            assetId                         :       null,
            listing_loading                 :       false,
            counterlog_list                 :       [],
            previousReadingData             :       null,
            viewLoading                     :       false,
            history_loading                 :       false,
            roster_listing                  :       [],
            rosterMappingForm               :       {...this.initRosterMappingForm},
            allRosterLists                  :       [],
            roster_lists                    :       [],
            formDataLoaded                  :       false,
            form_data_loading               :       false,
            generalConfigurationLabel       :       '',
            generalConfigurationKey         :       '',
            editConfigurationForm           :       {...this.initConfigurationForm},
            configuration_listing           :       null,
            configuration_loading           :       false
        }
        this.resetCounterlogRef             =       React.createRef()
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.rostertMappingModal            =   new Modal(document.getElementById('rostertMappingModal'), {keyboard: false, backdrop :false});
        this.viewPreviousReadingModal       =   new Modal(document.getElementById('viewPreviousReadingModal'), {keyboard: false, backdrop :false});
        this.generalSettingModal            =   new Modal(document.getElementById('generalSettingModal'), {keyboard: false, backdrop :false});
        this.assetLinkingModal              =   new Modal(document.getElementById('assetLinkingModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        this.setState({assetId : pr.match?.params?.id,scs_id : pr.match?.params?.scs_id}
            ,() => {
                this.getAsssetDetails(pr.match?.params?.id);
                this.getPreviousCounterlogListing(pr.match?.params?.id)
                this.getRosterHistoryListing(pr.match?.params?.id)
                this.getGeneralConfigurationListing(pr.match?.params?.id)
            })
    }

    getAsssetDetails                =   (id = null)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    getPreviousCounterlogListing        =       (id = null)          =>      {
        let Id  = id ? id  : this.state.assetId
        this.setState({listing_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/reset_meter/' + Id, this.props.access_token , {}, {}, (response) => {
           this.setState({counterlog_list : response.data.data})
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({listing_loading: false})})
    }

    getRosterHistoryListing        =       (id = null)          =>      {
        let Id  = id ? id  : this.state.assetId
        this.setState({history_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/roster_history/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({roster_listing : response.data})
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({history_loading: false})})
    }

    getGeneralConfigurationListing        =       (id = null)          =>      {
        let Id  = id ? id  : this.state.assetId
        this.setState({configuration_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/asset_setting/' + Id, this.props.access_token , {need_meter_code : 'Y'}, {}, (response) => {
            this.setState({configuration_listing : response.data.data})
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({configuration_loading: false})})
    }

    getRosterMappingFormData        =       ()      =>      {
        this.setState({form_data_loading: true});
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/asset/roster_listing', this.props.access_token,{}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                allRosterLists      :    respData,
                formDataLoaded      :    true,
                roster_lists        :    resp.data && resp.data.length > 0 ? resp.data.map(r => {return({value : r.id,label : r.roster_name})})  : []
            });
        }).then(() => this.setState({form_data_loading: false}));
    }

    closeSettings                             =       ()      =>  {
        this.props.history.goBack()
    }

    resetCounterlogInit         =       ()          =>      {
        this.resetCounterlogRef.current.resetCounterlogInit(this.state.assetDetail,this.state?.configuration_listing?.enable_asset_meter_code,this.state.configuration_listing);
    }

    rosterMappingInit         =       ()          =>      {
        this.rostertMappingModal.show();
        this.setState({
            rosterMappingForm    :       {...this.initRosterMappingForm},
        })
        if(!this.state.formDataLoaded){
            this.getRosterMappingFormData()
        }
    }

    
    viewPreviousReadingInit         =       (data)          =>      {
        this.viewPreviousReadingModal.show();
        this.setState({viewLoading     : true });
        setTimeout(() => {
            this.setState({
                previousReadingData      : data,
                viewLoading            : false
              
            });
        },2000)
    }

    deletePreviousReading                   =   (transaction_id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', IAM_API_BASE_URL_2 + '/counterlog/reset_meter/'+ transaction_id, this.props.access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.getAsssetDetails()
                    this.getPreviousCounterlogListing()
                });
            }
        });
    }

    submitRosterHistoryForm      =       (e)     =>      {
        e.preventDefault();
       
        let frmData = {...this.state.rosterMappingForm,asset_id : this.state.assetId}
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/asset/roster_change', this.props.access_token, {}, { ...frmData }, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.rostertMappingModal.hide();
            this.getRosterHistoryListing()
           this.setState({
            rosterMappingForm    :       {...this.initRosterMappingForm},
        })
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    generalSettingModalinit         =       (key,value)       =>      {
        this.setState({
            editConfigurationForm       :       {[key]  : value },
            generalConfigurationKey     :        key
        })
        this.generalSettingModal.show()
    }

    submitGeneralSettingForm        =       (e)      =>      {
        e.preventDefault();
        let frmData = {...this.state.editConfigurationForm}
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/asset/asset_setting/' + this.state.assetId, this.props.access_token, {}, { ...frmData }, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.generalSettingModal.hide();
            this.getGeneralConfigurationListing()
           this.setState({
            editConfigurationForm    :      {...this.initConfigurationForm},
            generalConfigurationKey  :      ''
        })
        }).then(() => this.setState({ saveFormSubmitting: false }));
      
    }

    assetLinkingModalInit           =       ()          =>      {
        this.assetLinkingModal.show()
    }

    counterlogMeter         =   [{value :'hour_meter',label : 'Hour Meter'},{value :'odo_meter',label : 'Odo Meter'},{value :'operation_cycle',label : 'Operation Cycle'}]
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails}  parentProps={this.props} />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div id="assetCard" className="collapse pr-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail}/>}
        </div>)
    }
    
    subHeaderJsx        =       ()      =>      {
        return (<div  className="pr0">
            <div className="row page_title m0 " style={{ height: "45px" }}>
            <div className="col-sm-4"><h3 style={{ paddingTop: "13px" }}>Asset Settings</h3></div>
            <div className='col-sm-4 text-center'></div>
            <div className=" col-sm-4 text-end mt-2 ">

                <button type="button" className="btn btn-secondary" onClick={this.closeSettings}>
                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" />
                </button>
            </div>
        </div>
        </div>)
    }

    assetSettingJsx         =       ()      =>      {
        let labels                          =   this.state.assetDetail && this.state.assetDetail.labels ? this.state.assetDetail.labels : {};
        return (<div style={{ maxHeight: "74vh" }} className="bg-white  mt-1 pageTbl" id="assetSetting">
            <div className='p-2'>
                <div className="tab_content_wrapper"> <span className="content_heading">General Setting</span></div>
                <table className="table mt-3 table-hover table-bordered">
                    <thead>
                        <tr className='bg-light'>
                            <th>Configuration</th>
                            <th style={{ width: "25%" }} className='text-center'>Value</th>
                            <th className='text-center' style={{ width: "5%" }}>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.configuration_loading
                            ? <tr><td rowSpan={3}><Loader /></td></tr>
                            : this.state.configuration_listing ?
                                <Ax>
                                    <tr>
                                        <td>Approved Consumption (Ltr/Hr)</td>
                                        <td className='text-center'>{this.state.assetDetail && this.state.assetDetail.hour_meter == "yes" && this.state.assetDetail.fuel_feed_in == "yes"  ? this.state.configuration_listing.fuel_consumption??"-"  : "NA"}</td>
                                        <td className='text-center'>
                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                <a className={this.state.assetDetail && this.state.assetDetail.hour_meter == "yes" && this.state.assetDetail.fuel_feed_in == "yes" ? "dropdown-item " : "dropdown-item disabled"} role="button" disabled={this.state.assetDetail && this.state.assetDetail.hour_meter == "yes" && this.state.assetDetail.fuel_feed_in == "yes" ? false : true} onClick={() => this.generalSettingModalinit('fuel_consumption',this.state.configuration_listing?.fuel_consumption)}>Edit</a>
                                                  </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Approved Economy (Km/Ltr)</td>
                                        <td className='text-center'>{this.state.assetDetail && this.state.assetDetail.odo_meter == "yes" && this.state.assetDetail.fuel_feed_in == "yes"  ? this.state.configuration_listing.fuel_economy??"-"  : "NA"}</td>
                                        <td className='text-center'>
                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a className={this.state.assetDetail && this.state.assetDetail.odo_meter == "yes" && this.state.assetDetail.fuel_feed_in == "yes" ? "dropdown-item " : "dropdown-item disabled"} role="button" disabled={this.state.assetDetail && this.state.assetDetail.odo_meter == "yes" && this.state.assetDetail.fuel_feed_in == "yes" ? false : true} onClick={() => this.generalSettingModalinit('fuel_economy',this.state.configuration_listing?.fuel_economy)}>Edit</a>
                                                  </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.hour_meter ? labels.hour_meter : 'Hour Meter'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_hour_meter ? this.state.configuration_listing.planned_hour_meter  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_hour_meter', this.state.configuration_listing?.planned_hour_meter)}>Edit</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter2 == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.hour_meter2 ? labels.hour_meter2 : 'Hour Meter 2'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_hour_meter2 ? this.state.configuration_listing.planned_hour_meter2  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_hour_meter2', this.state.configuration_listing?.planned_hour_meter2)}>Edit</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter3 == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.hour_meter3 ? labels.hour_meter3 : 'Hour Meter 3'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_hour_meter3 ? this.state.configuration_listing.planned_hour_meter3  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_hour_meter3', this.state.configuration_listing?.planned_hour_meter3)}>Edit</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> : null}
                                    {this.state.assetDetail && this.state.assetDetail.hour_meter4 == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.hour_meter3 ? labels.hour_meter4 : 'Hour Meter 4'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_hour_meter4 ? this.state.configuration_listing.planned_hour_meter4  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_hour_meter4', this.state.configuration_listing?.planned_hour_meter4)}>Edit</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.odo_meter ? labels.odo_meter : 'Odo Meter'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_odo_meter ? this.state.configuration_listing.planned_odo_meter  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_odo_meter', this.state.configuration_listing?.planned_odo_meter)}>Edit</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter2 == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.odo_meter ? labels.odo_meter2 : 'Odo Meter 2'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_odo_meter2 ? this.state.configuration_listing.planned_odo_meter2  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_odo_meter2', this.state.configuration_listing?.planned_odo_meter2)}>Edit</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> : null}
                                    {this.state.assetDetail && this.state.assetDetail.odo_meter3 == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.odo_meter ? labels.odo_meter3 : 'Odo Meter 3'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_odo_meter3 ? this.state.configuration_listing.planned_odo_meter3  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_odo_meter3', this.state.configuration_listing?.planned_odo_meter3)}>Edit</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> : null}
                                    {this.state.assetDetail && this.state.assetDetail.operation_cycle == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.operation_cycle ? labels.operation_cycle : 'Operation cycle'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_operation_cycle ? this.state.configuration_listing.planned_operation_cycle  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_operation_cycle', this.state.configuration_listing?.planned_operation_cycle)}>Edit</a>
                                                    </li>

                                                </ul>
                                            </td>
                                        </tr> : null}
                                    {this.state.assetDetail && this.state.assetDetail.operation_cycle2 == "yes"
                                        ? <tr>
                                            <td>Planned {labels && labels.operation_cycle2 ? labels.operation_cycle2 : 'Operation cycle 2'}</td>
                                            <td className='text-center'>{this.state.configuration_listing.planned_operation_cycle2 ? this.state.configuration_listing.planned_operation_cycle2  : "-"}</td>
                                            <td className='text-center'>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                        <a className={"dropdown-item "} role="button" onClick={() => this.generalSettingModalinit('planned_operation_cycle2', this.state.configuration_listing?.planned_operation_cycle2)}>Edit</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> : null}

                                </Ax>
                                : <tr><td className='text-center' rowSpan={3}>No Record</td></tr>}
                    </tbody>
                </table>
                <div className='mb-3 form-text'>
                  # <sup>Planned Meters is based on 24 hrs</sup>
                </div>
             
                <div className="tab_content_wrapper"> <span className="content_heading">Previous Reset Counter Log</span>
                    <div className='text-end'>
                        <Button className={'btn btn-primary'} onClick={this.resetCounterlogInit} disabled={this.props.permissions.includes('iam-asset-counterlog-reset') ? false : true} >
                            Reset Counterlog
                        </Button>
                    </div>
                </div>
                <table className="table my-3 table-hover table-bordered">
                    <thead className='bg-light'>
                        <tr>
                            <td style={{ width: "5%" }} className="text-center">S.No</td>
                            <td style={{ width: "20%" }} className="text-center">Date Time</td>
                            <td style={{ width: "20%" }}>Activity ID</td>
                            <td style={{ width: "25%" }}>Old Reading 
                            {this.state.configuration_listing && this.state.configuration_listing.enable_asset_meter_code &&  this.state.configuration_listing.enable_asset_meter_code == 'Y' ? <span> (Meter Code)</span> : null}</td>
                            <td style={{ width: "25%" }}>New Reading   {this.state.configuration_listing && this.state.configuration_listing.enable_asset_meter_code &&  this.state.configuration_listing.enable_asset_meter_code == 'Y' ? <span> (Meter Code)</span> : null}</td>
                            <td style={{ width: "5%" }} className="text-center">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? <tr className='text-center'><td colSpan={6}><Loader /></td></tr>
                            :
                            this.state.counterlog_list && this.state.counterlog_list.length > 0
                                ? this.state.counterlog_list.map((reading, k) => {
                                    let meter_code_enable = 'N';
                                    if(this.state.configuration_listing && this.state.configuration_listing.enable_asset_meter_code &&  this.state.configuration_listing.enable_asset_meter_code == 'Y'){
                                        meter_code_enable   =   'Y'
                                    }

                                    return (<tr key={k + 1}>
                                        <td className="text-center">{k + 1}</td>
                                        <td>{reading.reset_datetime_display ? reading.reset_datetime_display : "-"}</td>
                                        <td className='link-primary cursor_pointer' onClick={() => this.viewPreviousReadingInit(reading)}>{reading.transaction_id ? reading.transaction_id : "-"}</td>
                                        <td>{reading.display && reading.display.length > 0 ? reading.display.map(d => { return (<div><b>{d.label}</b> : {d.old_reading} {d.unit} {meter_code_enable == 'Y' && d.meter_code_old ? <span>({d.meter_code_old})</span>  : null}</div>) }) : "-"}</td>
                                        <td>{reading.display && reading.display.length > 0 ? reading.display.map(d => { return (<div><b>{d.label}</b> : {d.new_reading} {d.unit} {meter_code_enable == 'Y' && d.meter_code ? <span>({d.meter_code})</span>  : null}</div>) }) : "-"}</td>
                                        <td className="text-center">
                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button" onClick={() => this.viewPreviousReadingInit(reading)}>View</a></li>
                                                <li>   <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-asset-counterlog-reset-delete') && reading.can_delete == "Y") {
                                                            return this.deletePreviousReading(reading.transaction_id)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-asset-counterlog-reset-delete') && reading.can_delete == "Y" ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-asset-counterlog-reset-delete') || reading.can_delete == "N" ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete</a></li>

                                            </ul>
                                        </td>
                                    </tr>)
                                })
                                : <tr><td colSpan={6} className="text-center">No Record</td></tr>
                        }
                    </tbody>


                </table>
                <div className="tab_content_wrapper"> <span className="content_heading">Asset Roster Mapping History</span>
                    <div className='text-end'>
                        <Button className={'btn btn-primary'} onClick={this.rosterMappingInit} disabled={this.props.permissions.includes('iam-asset-roster-add') ? false : true} >
                            Asset Roster Mapping
                        </Button>

                    </div>
                </div>
                <table className="table my-3 table-hover table-bordered">
                    <thead className='bg-light'>
                        <tr>
                            <td style={{ width: "5%" }} className="text-center">S.No</td>
                            <td style={{ width: "10%" }} className="text-center">Roster ID</td>
                            <td style={{ width: "30%" }}>Roster Name</td>
                            <td style={{ width: "15%" }} className="text-center">No of Shifts</td>
                            <td style={{ width: "40%" }}>Date Range</td>

                        </tr>
                    </thead>
                    <tbody>
                        {this.state.history_loading
                            ? <tr className='text-center'><td colSpan={6}><Loader /></td></tr>
                            :
                            this.state.roster_listing && this.state.roster_listing.length > 0
                                ? this.state.roster_listing.map((reading, k) => {
                                    return (<tr key={k + 1}>
                                        <td className="text-center">{k + 1}</td>
                                        <td>{reading.roster && reading.roster.tap_roster_id ? reading.roster.tap_roster_id : "-"}</td>
                                        <td className='text-capitalize'>{reading.roster && reading.roster.roster_name ? reading.roster.roster_name : "-"}</td>
                                        <td className="text-center">{reading.roster && reading.roster.no_of_shift ? reading.roster.no_of_shift : "0"}</td>
                                        <td>{reading.activation_from_display && reading.activation_end_display ? <span>{reading.activation_from_display} To {reading.activation_end_display}</span> : "-"}</td>
                                    </tr>)
                                })
                                : <tr><td colSpan={6} className="text-center">No Record</td></tr>
                        }

                    </tbody>


                </table>
                {/* <div className="tab_content_wrapper"> <span className="content_heading">Asset Linking</span></div> 
                <table className="table my-3 table-hover table-bordered">
                    <thead className='bg-light'>
                        <tr className="text-center">
                            <td style={{ width: "5%" }} >S.No</td>
                            <td></td>
                            <td style={{ width: "25%" }}>Value</td>
                            <td style={{ width: "5%" }}>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">1</td>
                            <td>Is it a child Asset</td>
                            <td className="text-center">Yes</td>
                            <td className="text-center">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" role="button" onClick={() => this.assetLinkingModalInit()}>Edit</a></li>

                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">2</td>
                            <td>Select Counterlog Meter for measurement</td>
                            <td className="text-center">Hour Meter,Odo Meter</td>
                            <td className="text-center">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" role="button">Edit</a></li>

                                </ul>
                            </td>
                        </tr>

                    </tbody>


                </table> */}

            </div>
        </div>)
    }

    rostertMappingModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="rostertMappingModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Roster Mapping</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.form_data_loading ? <div className='text-center'><Loader /></div>
                            : <div className="modal-body">
                                <form onSubmit={this.submitRosterHistoryForm} id="addRosterHistoryForm">
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm require"> Activation Date</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.rosterMappingForm.activation_date
                                                        ? moment(this.state.rosterMappingForm.activation_date, 'YYYY-MM-DD').toDate()
                                                        : false
                                                }
                                                name="activation_date"
                                                onChange={(value, event) => this.formDateHandler('activation_date', value, 'rosterMappingForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Activation Date`}
                                                required={true}
                                                maxDate={moment(new Date(),'YYYY-MM-DD').toDate()}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm require">Select Roster</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'rosterMappingForm', 'roster_id');
                                                }}
                                                options={this.state.roster_lists}
                                                isSearchable={true}
                                                required={true}
                                                value={this.state.roster_lists.find(m => m.value == this.state.rosterMappingForm.roster_id)}
                                                placeholder="Please Select Roster"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <table className="table my-3 table-hover table-bordered">
                                        <thead className='bg-light'>
                                            <tr>
                                                <td style={{ width: "5%" }} className="text-center">S.No</td>

                                                <td style={{ width: "10%" }}>Roster ID</td>
                                                <td style={{ width: "25%" }}>Roster Name</td>
                                                <td style={{ width: "15%" }} className="text-center">No of Shifts</td>
                                                <td style={{ width: "20%" }}>Shift Name</td>
                                                <td style={{ width: "15%" }}>Shift Start Time</td>
                                                <td style={{ width: "10%" }}>Shift Hours</td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.allRosterLists && this.state.allRosterLists.length > 0
                                                ? this.state.allRosterLists.map((roster, k) => {
                                                    return (<Ax>
                                                        {roster.shifts && roster.shifts.length > 0
                                                            ? roster.shifts.map((ros, key) => {
                                                                return (<tr>
                                                                    {key == 0 && <Ax>
                                                                        <td className="text-center" rowSpan={roster.no_of_shift}>{k + 1}</td>
                                                                        <td rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.tap_roster_id ? roster.tap_roster_id : "-"}</td>
                                                                        <td rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.roster_name ? roster.roster_name : "-"}</td>
                                                                        <td className="text-center" rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.no_of_shift ? roster.no_of_shift : "0"}</td>

                                                                    </Ax>}
                                                                    <td>{ros.shift_name}</td>
                                                                    <td>{ros.shift_start}</td>
                                                                    <td>{ros.shift_time}</td>
                                                                </tr>)
                                                            }) : null}
                                                    </Ax>)
                                                })
                                                : <tr><td colSpan={6} className="text-center">No Record</td></tr>}
                                        </tbody>


                                    </table>
                                </form>

                            </div>}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form="addRosterHistoryForm">Submit {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
   }

   viewPreviousReadingModalJsx               =        ()             =>        {
    let previousData = this.state.previousReadingData;
       return (
           <div className="modal fade" id="viewPreviousReadingModal" tabIndex="-1">
               <div className="modal-dialog modal-lg">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h5 className="modal-title" id="searchAssetModalLabel">View Previous Reading for : {previousData && previousData.transaction_id ? previousData.transaction_id : "-"}</h5>
                           <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                       </div>
                       <div className='p-1 bg-light border-bottom'>
                                <table className='table table-sm mb-0 table-borderless text-sm'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                            <th>{this.state.assetDetail ? `${this.state.assetDetail.name} (${this.state.assetDetail.asset_code})` : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td>Asset Type </td><th>{this.state.assetDetail ? `${this.state.assetDetail.asset_type_name} ` : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                       <div className="modal-body">
                           {this.state.viewLoading
                               ? <Loader />
                               : <div>
                                   {this.state.previousReadingData
                                       ? <table className="table my-2 table-hover table-bordered align-items-center">
                                           <tbody>
                                               <tr>
                                                   <td style={{ "width": "26%" }}>Activity ID</td>
                                                   <th style={{ "width": "74%" }}>{previousData.transaction_id ? previousData.transaction_id : "-"} </th>
                                               </tr>
                                               <tr>
                                                   <td style={{ "width": "26%" }}>Date Time</td>
                                                   <th style={{ "width": "74%" }}>{previousData.reset_datetime_display ? previousData.reset_datetime_display : "-"} </th>
                                               </tr>
                                               <tr>
                                                   <td style={{ "width": "26%" }}>Site</td>
                                                   <th style={{ "width": "74%" }}>{previousData.site ? previousData.site.site_name : "-"} </th>
                                               </tr>
                                               <tr>
                                               <td style={{ "width": "26%" }}>Reading</td>
                                                   <td  style={{ "width": "74%" }}>
                                                       <table className="table table-bordered">
                                                           <thead className='bg-light'>
                                                             <tr>
                                                                <th style={{ "width": "25%" }}>Meter</th>
                                                                <th style={{ "width": "30%" }}>Old Reading</th>
                                                                <th style={{ "width": "30%" }}>New Reading</th>
                                                                <th style={{ "width": "15%" }}>Unit</th>
                                                             </tr>
                                                           </thead>
                                                           <tbody>
                                                               {previousData.display && previousData.display.length > 0 ? previousData.display.map(d => { return (<tr>
                                                                  <td>{d.label}</td>
                                                                  <td>{d.old_reading}</td>
                                                                  <td>{d.new_reading}</td>
                                                                  <td>{d.unit}</td>
                                                               </tr>) }) : "-"}
                                                           </tbody>
                                                       </table>
                                                   </td>
                                               </tr>
        
                                               {/* <tr>
                                                   <td style={{ "width": "26%" }}>Reading</td>
                                                   <th style={{ "width": "74%" }}>{previousData.display && previousData.display.length > 0  ? previousData.display.map(d => {return(<div><b>{d.label}</b> : {d.old_reading} {d.unit}</div>)}) : "-"} </th>
                                                   <th style={{ "width": "74%" }}>{previousData.display && previousData.display.length > 0  ? previousData.display.map(d => {return(<div><b>{d.label}</b> : {d.new_reading} {d.unit}</div>)}) : "-"} </th>
                                               </tr> */}
                                              
                                               <tr>
                                                   <td style={{ "width": "26%" }}>Remarks</td>
                                                   <th style={{ "width": "74%" }}>{previousData.remarks  ? previousData.remarks : "-"} </th>
                                               </tr>
                                               <tr>
                                                   <td style={{ "width": "26%" }}>Updated By</td>
                                                   <th style={{ "width": "74%" }}>{previousData.user  ? previousData.user.full_name : "-"} </th>
                                               </tr>
                                              

                                           </tbody>
                                       </table>
                                       : null}
                               </div>
                           }

                       </div>
                       <div className="modal-footer">
                           <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                       </div>

                   </div>
               </div>
           </div>
       );
   }

   generalSettingsModalJsx               =        ()             =>        {
    let labels                          =   this.state.assetDetail && this.state.assetDetail.labels ? this.state.assetDetail.labels : {};
    return (
        <div className="modal fade" id="generalSettingModal" tabIndex="-1">
            <div className="modal-dialog modal-md modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchAssetModalLabel">Update General Setting</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {this.state.form_data_loading ? <div className='text-center'><Loader /></div>
                        : <div className="modal-body">
                            <form onSubmit={this.submitGeneralSettingForm} id="addGeneralSettingForm">

                                {this.state.generalConfigurationKey == "fuel_consumption"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Consumption (Ltr/Hr)</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="fuel_consumption"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.fuel_consumption}
                                                required={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                {this.state.generalConfigurationKey == "fuel_economy"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Economy (Km/Ltr)</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="fuel_economy"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.fuel_economy}
                                                required={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                    {this.state.generalConfigurationKey == "planned_hour_meter"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.hour_meter ? labels.hour_meter : 'Hour Meter'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_hour_meter"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.planned_hour_meter}
                                                required={true}
                                                max={24}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                    {this.state.generalConfigurationKey == "planned_hour_meter2"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.hour_meter2 ? labels.hour_meter2 : 'Hour Meter 2'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_hour_meter2"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.planned_hour_meter2}
                                                required={true}
                                                max={24}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                    {this.state.generalConfigurationKey == "planned_hour_meter3"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.hour_meter3 ? labels.hour_meter3 : 'Hour Meter 3'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_hour_meter3"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.planned_hour_meter3}
                                                required={true}
                                                max={24}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                    {this.state.generalConfigurationKey == "planned_hour_meter4"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.hour_meter3 ? labels.hour_meter4 : 'Hour Meter 4'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_hour_meter4"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.planned_hour_meter4}
                                                required={true}
                                                max={24}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                      {this.state.generalConfigurationKey == "planned_odo_meter"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.odo_meter ? labels.odo_meter : 'Odo Meter'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_odo_meter"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.odo_meter}
                                                required={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                     {this.state.generalConfigurationKey == "planned_odo_meter2"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.odo_meter ? labels.odo_meter2 : 'Odo Meter 2'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_odo_meter2"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.odo_meter2}
                                                required={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                    {this.state.generalConfigurationKey == "planned_odo_meter3"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.odo_meter ? labels.odo_meter3 : 'Odo Meter 3'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_odo_meter3"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.odo_meter3}
                                                required={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                     {this.state.generalConfigurationKey == "planned_operation_cycle"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.operation_cycle ? labels.operation_cycle : 'Operation cycle'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_operation_cycle"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.planned_operation_cycle}
                                                required={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                     {this.state.generalConfigurationKey == "planned_operation_cycle2"
                                    ? <div className="row my-3">
                                        <label className="col-sm-4 col-form-label col-form-label-sm ">Planned {labels && labels.operation_cycle2 ? labels.operation_cycle2 : 'Operation cycle 2'}</label>
                                        <div className='col-sm-8'>
                                            <input
                                                name="planned_operation_cycle2"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, 'editConfigurationForm')}
                                                placeholder="Please Enter Reading"
                                                value={this.state.editConfigurationForm && this.state.editConfigurationForm.planned_operation_cycle2}
                                                required={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    : null}

                            </form>

                        </div>}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form="addGeneralSettingForm">Submit {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>

                </div>
            </div>
        </div>
    );
    }

    assetLinkingModalJsx        =       ()      =>       {

        return (
            <div className="modal fade" id="assetLinkingModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Update Asset Linking</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form  id="addGeneralSettingForm">

                                <div className="row my-3">
                                    <label className="col-sm-4 col-form-label col-form-label-sm ">Is it a child Asset</label>
                                    <div className="col-sm-8">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="is_child_asset"
                                                type="radio"
                                                value="yes"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="is_child_asset">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="is_child_asset"
                                                type="radio"
                                                value="no"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="is_child_asset">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab_content_wrapper"> <span className="content_heading">Select Counterlog for measurement</span></div>
                                <div className="row my-3">
                                    <label className="col-sm-4 col-form-label col-form-label-sm ">Hour Meter</label>
                                    <div className="col-sm-8">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="is_child_asset"
                                                type="radio"
                                                value="yes"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="is_child_asset">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="is_child_asset"
                                                type="radio"
                                                value="no"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="is_child_asset">No</label>
                                        </div>
                                    </div>
                                </div>
                                 <div className="row my-3">
                                    <label className="col-sm-4 col-form-label col-form-label-sm ">Hour Meter 2</label>
                                    <div className="col-sm-8">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="is_child_asset"
                                                type="radio"
                                                value="yes"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="is_child_asset">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="is_child_asset"
                                                type="radio"
                                                value="no"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="is_child_asset">No</label>
                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form="addGeneralSettingForm">Submit {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
    render                      =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Asset Settings</title></Helmet>
             {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className='page_containt row'>
                    <div className='col-sm-12'>
                        {this.subHeaderJsx()}
                        <div className='row'>
                            <div className='col-sm-9 pr0 mr0'>
                                {this.assetSettingJsx()}
                            </div>
                            <div className="page_containt col-sm-3 pr4 pl0 pb-3" style={{ top: "0px" }}>
                                <div className="pageTbl">
                                    <AssetCard assetCardDetails={this.state.assetDetail} refreshAssetDetail={() => this.getAsssetDetails} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ResetCounterlogModal 
            ref={this.resetCounterlogRef}
             afterResetCounterlogSubmit={() => {
                this.getAsssetDetails() ;
                 this.getPreviousCounterlogListing();
                 if(this.state.configuration_listing && this.state.configuration_listing.enable_asset_meter_code && this.state.configuration_listing.enable_asset_meter_code == 'Y'){
                    this.getGeneralConfigurationListing()
                 }
             }}/>
            {this.rostertMappingModalJsx()}
            {this.viewPreviousReadingModalJsx()}
            {this.generalSettingsModalJsx()}
            {this.assetLinkingModalJsx()}
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AssetSettings);