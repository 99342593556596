import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Ax from "../../../components/hoc/Ax";
import AppBaseComponent from "../../../components/AppBaseComponent";
import TapSelect from "../../../components/ui/TapSelect";
import tapIcon from "../../../services/TapIcon";
import Status from "../../../components/ui/Status";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import DateService from "../../../services/DateService";
import { Helmet } from "react-helmet";
import { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import TapHelper from "../../../services/TapHelper";

class AssetDashboard extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state          =   {
            asset_id            :   props.asset_enc_id,
            data_loading        :   false,
            selected_period     :   { period1: "previous_month", period2: "current_month" },
            all_periods         :   [],
            show_period         :   { period1: "Previous Month", period2: "Current Month" },
            listingData         :   []
        };
        this.dashboardUrl   =   IAM_API_BASE_URL_2 + '/asset_dashboard';
    }

    componentDidMount() {
        this.setState({
            all_periods     :   this.props.all_periods
                                ? this.props.all_periods.map((s) => ({
                                    value: s.key,
                                    label: `${s.display}`,
                                    display_label: s.display_with_date,
                                  }))
                                : [],
        }, () => {
            this.initialize(this.props);
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.all_periods !== nextProps.all_periods) {
            this.setState({
                all_periods :   nextProps.all_periods
                                ? nextProps.all_periods.map((s) => {
                                    return { value: s.key, label: s.display, display_label: s.display_with_date};
                                })
                                : [],
            });
        }
    }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.asset_enc_id !== this.props.asset_enc_id ||
      prevState.selected_period.period1 !==
        this.state.selected_period.period1 ||
      prevState.selected_period.period2 !== this.state.selected_period.period2
    ) {
      this.initialize(this.props);
    }
  }

  initialize = (props, type = null) => {
    const { asset_enc_id } = this.props;
    const period1 = this.state.selected_period.period1;
    const period2 = this.state.selected_period.period2;
    const key1 = asset_enc_id + "--" + period1;
    const key2 = asset_enc_id + "--" + period2;
    let params = { periods: [period1, period2] };

    this.setState({ data_loading: true });
    if (type === "period1") {
      params = { periods: [period1] };
    } else if (type === "period2") {
      params = { periods: [period2] };
    }
    
    const key1Data = JSON.parse(localStorage.getItem(key1));
    const key2Data = JSON.parse(localStorage.getItem(key2));

    if (key1Data === null || key2Data === null || type === "refresh") {
      HttpAPICall.withAthorization("GET", `${this.dashboardUrl}/${asset_enc_id}`, props.access_token,
        params, {}, (resp) => {
          let respData = resp.data;
          let listingData = respData;
          if (type === "period1") {
            listingData = [respData[0], key2Data];
          } else if (type === "period2") {
            listingData = [key1Data, respData[0]];
          }
          this.setState({
            listingData,
          });

          switch (type) {
            case "period1":
              localStorage.setItem(key1, JSON.stringify(respData[0]));
              break;
            case "period2":
              localStorage.setItem(key2, JSON.stringify(respData[1]));
              break;
            default:
              localStorage.setItem(key1, JSON.stringify(respData[0]));
              localStorage.setItem(key2, JSON.stringify(respData[1]));
          }
        }
      ).then(() => {
        this.setState({ data_loading: false });
      });
    } else {
      this.setState({
        listingData: [key1Data, key2Data],
        data_loading: false,
      });
    }
  };

  changePer = (current = 0, pervious = 0, type = null) => {
    if (
      current == pervious ||
      pervious == 0 ||
      current == 0 ||
      pervious == null ||
      current == null
    ) {
      return "";
    }
    let value =
      pervious !== 0 ? (((current - pervious) / pervious) * 100).toFixed(2) : 0;
    return value >= 0 ? value + "%" : -value + "%";
  };

  statusJsx = (period1, period2, type = null) => {
    const arrCondition = [0, undefined, null, false];
    if (
      period1 === period2 ||
      arrCondition.includes(period1) ||
      arrCondition.includes(period2)
    ) {
      return "";
    } else {
      return type === "increase" ? (
        <Status
          color={parseInt(period1) > parseInt(period2) ? "#ec4e4e" : "#2A974E"}
        ></Status>
      ) : (
        <Status
          color={parseInt(period1) < parseInt(period2) ? "#ec4e4e" : "#2A974E"}
        ></Status>
      );
    }
  };

  dashboardTblJsx = () => {
    let asset = this.props.assetData ? this.props.assetData : null;
    let assetData1 =
      this.state.listingData && this.state.listingData.length > 0
        ? this.state.listingData[0]
        : null;
    let assetData2 =
      this.state.listingData && this.state.listingData.length > 0
        ? this.state.listingData[1]
        : null;
    let cl_data1 =
      assetData1 && assetData1.cl && assetData1.cl.length > 0
        ? assetData1.cl
        : [];
    let cl_data2 =
      assetData2 && assetData2.cl && assetData2.cl.length > 0
        ? assetData2.cl
        : [];
    let fuel_data1 = assetData1 && assetData1.fuel ? assetData1.fuel : null;
    let fuel_data2 = assetData2 && assetData2.fuel ? assetData2.fuel : null;
    let expense_data1 =
      assetData1 && assetData1.expense ? assetData1.expense : null;
    let expense_data2 =
      assetData2 && assetData2.expense ? assetData2.expense : null;
    const prod_data1 =
      assetData1 &&
      assetData1.productivity &&
      assetData1.productivity.producutvity_data.length > 0
        ? assetData1.productivity.producutvity_data
        : [];
    const prod_data2 =
      assetData2 &&
      assetData2.productivity &&
      assetData2.productivity.producutvity_data.length > 0
        ? assetData2.productivity.producutvity_data
        : [];
    const production1 =
      assetData1 && assetData1.productivity ? assetData1.productivity : null;
    const production2 =
      assetData2 && assetData2.productivity ? assetData2.productivity : null;
    const downtime1 =
      assetData1 && assetData1.downtime ? assetData1.downtime : null;
    const downtime2 =
      assetData2 && assetData2.downtime ? assetData2.downtime : null;
    let downtimeTypes = downtime1 ? downtime1 : downtime2;
    let task1 = assetData1 && assetData1.task ? assetData1.task : null;
    let task2 = assetData2 && assetData2.task ? assetData2.task : null;
    const ticket1 = assetData1 && assetData1.ticket ? assetData1.ticket : null;
    const ticket2 = assetData2 && assetData2.ticket ? assetData2.ticket : null;
    const scs1 = assetData1 && assetData1.scs ? assetData1.scs : null;
    const scs2 = assetData2 && assetData2.scs ? assetData2.scs : null;
    let allProds = [...prod_data1, ...prod_data2];
    let production_keys = new Set(allProds.map((p) => p.key));
    let production_keys_withunits = [...production_keys].map((pk) =>
      allProds.find((p) => p.key == pk)
    );
    let task_submodule_available =
      this.props.permissions &&
      this.props.permissions.group_sub_modules &&
      this.props.permissions.group_sub_modules.find(
        (s) => s.sub_module == "iam-task-workflow"
      )
        ? "Y"
        : "N";

    return (
      <div className="row mt-2 mb-5">
        <div className="col-12">
          <table className="table table-hover table-bordered">
            <thead className="table-secondary">
              <tr>
                <th style={{ width: "32%" }}>Parameter</th>
                <th style={{ width: "17%" }}>Units</th>
                <th style={{ width: "17%" }}>
                  {this.state.show_period.period1}
                </th>
                <th style={{ width: "17%" }}>
                  {this.state.show_period.period2}
                </th>
                <th style={{ width: "17%" }}>% Difference</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data_loading ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : (
                <Ax>
                  <tr>
                    <td colSpan={5}>
                      <b>Opening Closing Reading</b>
                    </td>
                  </tr>
                  {cl_data1 &&
                    cl_data1.length > 0 &&
                    cl_data1.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data.label}</td>
                          <td className="text-center">{data.unit}</td>
                          <th className="text-center">
                            {data.running_display}
                          </th>
                          <th className="text-center">
                            {cl_data2[i]?.running_display}
                          </th>
                          <td className="text-center">
                            {this.statusJsx(
                              data.running,
                              cl_data2[i]?.running,
                              "increase"
                            )}
                            {this.changePer(cl_data2[i]?.running, data.running)}
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan={5}>&nbsp;</td>
                  </tr>
                  <tr>
                    <th colSpan={5}>Fuel</th>
                  </tr>
                  <tr>
                    <td>Total Fuel</td>
                    <td className="text-center">Ltr</td>
                    <th className="text-center">
                      {fuel_data1 && fuel_data1.fuel_consume
                        ?  TapHelper.numberFormatter(fuel_data1.fuel_consume, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {fuel_data2 && fuel_data2.fuel_consume
                        ? TapHelper.numberFormatter(fuel_data2.fuel_consume, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        fuel_data1?.fuel_consume,
                        fuel_data2?.fuel_consume,
                        "decrease"
                      )}
                      {this.changePer(
                        fuel_data2?.fuel_consume,
                        fuel_data1?.fuel_consume
                      )}
                    </td>
                  </tr>
                  {asset && asset.hour_meter == "yes" && (
                    <Ax>
                      <tr>
                        <td>Fuel Consumption</td>
                        <td className="text-center">Ltrs / Hr</td>
                        <th className="text-center">
                          {fuel_data1 && fuel_data1.fuel_consumption
                            ? TapHelper.numberFormatter(fuel_data1.fuel_consumption, 3)
                            : ""}
                        </th>
                        <th className="text-center">
                          {fuel_data2 && fuel_data2.fuel_consumption
                            ? TapHelper.numberFormatter(fuel_data2.fuel_consumption, 3)
                            : ""}
                        </th>
                        <td className="text-center">
                          {this.statusJsx(
                            fuel_data1?.fuel_consumption,
                            fuel_data2?.fuel_consumption,
                            "decrease"
                          )}
                          {this.changePer(
                            fuel_data2?.fuel_consumption,
                            fuel_data1?.fuel_consumption
                          )}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>Fuel Consumption Limit</td>
                        <td className="text-center">Ltrs / Hr</td>
                        <th className="text-center">
                          {fuel_data1 && fuel_data1.approved_fuel_consumption
                            ? TapHelper.numberFormatter(fuel_data1.approved_fuel_consumption, 3)
                            : ""}
                        </th>
                        <th className="text-center">
                          {fuel_data2 && fuel_data2.approved_fuel_consumption
                            ? TapHelper.numberFormatter(fuel_data2.approved_fuel_consumption, 3)
                            : ""}
                        </th>
                        <td className="text-center"></td>
                      </tr>
                      <tr>
                        <td>Fuel Consumption Variance</td>
                        <td className="text-center">Percentage</td>
                        <th className="text-center">
                          {fuel_data1 && fuel_data1.fuel_consumption_variance
                            ? parseFloat(
                                fuel_data1.fuel_consumption_variance
                              ).toFixed(2)
                            : ""}
                        </th>
                        <th className="text-center">
                          {fuel_data2 && fuel_data2.fuel_consumption_variance
                            ? parseFloat(
                                fuel_data2.fuel_consumption_variance
                              ).toFixed(2)
                            : ""}
                        </th>
                        <td className="text-center"></td>
                      </tr>
                    </Ax>
                  )}
                  {asset && asset.odo_meter == "yes" && (
                    <Ax>
                      <tr>
                        <td>Fuel Economy</td>
                        <td className="text-center">KM / Ltr</td>
                        <th className="text-center">
                          {fuel_data1 && fuel_data1.fuel_economy_display
                            ? TapHelper.numberFormatter(fuel_data1.fuel_economy_display, 3)
                            : ""}
                        </th>
                        <th className="text-center">
                          {fuel_data2 && fuel_data2.fuel_economy_display
                            ? TapHelper.numberFormatter(fuel_data2.fuel_economy_display, 3)
                            : ""}
                        </th>
                        <td className="text-center">
                          {this.statusJsx(
                            fuel_data1?.fuel_economy,
                            fuel_data2?.fuel_economy,
                            "increase"
                          )}
                          {this.changePer(
                            fuel_data2?.fuel_economy,
                            fuel_data1?.fuel_economy
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Fuel Economy Limit</td>
                        <td className="text-center">KM / Ltr</td>
                        <th className="text-center">
                          {fuel_data1 && fuel_data1.approved_fuel_economy
                            ? TapHelper.numberFormatter(fuel_data1.approved_fuel_economy, 3)
                            : ""}
                        </th>
                        <th className="text-center">
                          {fuel_data2 && fuel_data2.approved_fuel_economy
                            ? TapHelper.numberFormatter(fuel_data2.approved_fuel_economy, 3)
                            : ""}
                        </th>
                        <td className="text-center"></td>
                      </tr>
                      <tr>
                        <td>Fuel Economy Variance</td>
                        <td className="text-center">Percentage</td>
                        <th className="text-center">
                          {fuel_data1 && fuel_data1.fuel_economy_variance
                            ? parseFloat(
                                fuel_data1.fuel_economy_variance
                              ).toFixed(2)
                            : ""}
                        </th>
                        <th className="text-center">
                          {fuel_data2 && fuel_data2.fuel_economy_variance
                            ? parseFloat(
                                fuel_data2.fuel_economy_variance
                              ).toFixed(2)
                            : ""}
                        </th>
                        <td className="text-center"></td>
                      </tr>
                    </Ax>
                  )}
                  <tr>
                    <td colSpan={5}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <b>Expense</b>
                    </td>
                  </tr>

                  <tr>
                    <td>Fuel Expense</td>
                    <td className="text-center">INR</td>
                      <th className="text-center">
                        {expense_data1 && expense_data1.fuel_expense
                          ? TapHelper.numberFormatter(expense_data1.fuel_expense, 3)
                          : ""}

                      </th>
                      <th className="text-center">
                        {expense_data2 && expense_data2.fuel_expense
                          ? TapHelper.numberFormatter(expense_data2.fuel_expense, 3)
                          : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        expense_data1?.fuel_expense,
                        expense_data2?.fuel_expense,
                        "decrease"
                      )}
                      {this.changePer(
                        Number(expense_data2?.fuel_expense),
                        Number(expense_data1?.fuel_expense)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Asset Expense</td>
                    <td className="text-center">INR</td>
                    <th className="text-center">
                      {expense_data1 && expense_data1.asset_expense
                        ? TapHelper.numberFormatter(expense_data1.asset_expense, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {expense_data2 && expense_data2.asset_expense
                        ? TapHelper.numberFormatter(expense_data2.asset_expense, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        expense_data1?.asset_expense,
                        expense_data1?.asset_expense,
                        "decrease"
                      )}
                      {this.changePer(
                        expense_data2?.asset_expense,
                        expense_data1?.asset_expense
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Item Expense</td>
                    <td className="text-center">INR</td>
                    <th className="text-center">
                      {expense_data1 && expense_data1
                        ? TapHelper.numberFormatter(expense_data1.item_expense, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {expense_data2 && expense_data2.item_expense
                        ? TapHelper.numberFormatter(expense_data2.item_expense, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        expense_data1?.item_expense,
                        expense_data2?.item_expense,
                        "decrease"
                      )}
                      {this.changePer(
                        expense_data2?.item_expense,
                        expense_data1?.item_expense
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Rental Expense</td>
                    <td className="text-center">INR</td>
                    <th className="text-center">
                      {expense_data1 && expense_data1.rental_expense
                        ? TapHelper.numberFormatter(expense_data1.rental_expense, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {expense_data2 && expense_data2.rental_expense
                        ? TapHelper.numberFormatter(expense_data2.rental_expense, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        expense_data1?.rental_expense,
                        expense_data2?.rental_expense,
                        "decrease"
                      )}
                      {this.changePer(
                        expense_data2?.rental_expense,
                        expense_data1?.rental_expense
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Expense</td>
                    <td className="text-center">INR</td>
                    <th className="text-center">
                      {expense_data1 && expense_data1.total_expense
                        ? TapHelper.numberFormatter(expense_data1.total_expense, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {expense_data2 && expense_data2.total_expense
                        ? TapHelper.numberFormatter(expense_data2.total_expense, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        expense_data1?.total_expense,
                        expense_data2?.total_expense,
                        "decrease"
                      )}
                      {this.changePer(
                        expense_data2?.total_expense,
                        expense_data1?.total_expense
                      )}
                    </td>
                  </tr>
                  {asset && asset.hour_meter == "yes" && (
                    <tr>
                      <td>Expense Per Hr</td>
                      <td className="text-center">INR</td>
                      <th className="text-center">
                        {expense_data1 && expense_data1.expense_per_hr
                          ? TapHelper.numberFormatter(expense_data1.expense_per_hr, 3)
                          : ""}
                      </th>
                      <th className="text-center">
                        {expense_data2 && expense_data2.expense_per_hr
                          ? TapHelper.numberFormatter(expense_data2.expense_per_hr, 3)
                          : ""}
                      </th>
                      <td className="text-center">
                        {this.statusJsx(
                          expense_data1?.expense_per_hr,
                          expense_data2?.expense_per_hr,
                          "decrease"
                        )}
                        {this.changePer(
                          expense_data2?.expense_per_hr,
                          expense_data1?.expense_per_hr
                        )}
                      </td>
                    </tr>
                  )}
                  {asset && asset.odo_meter == "yes" && (
                    <tr>
                      <td>Expense Per KM</td>
                      <td className="text-center">INR</td>
                      <th className="text-center">
                        {expense_data1 && expense_data1.expense_per_km
                          ?  TapHelper.numberFormatter(expense_data1.expense_per_km, 3)
                          : ""}
                      </th>
                      <th className="text-center">
                        {expense_data2 && expense_data2.expense_per_km
                          ?  TapHelper.numberFormatter(expense_data2.expense_per_km, 3)
                          : ""}
                      </th>
                      <td className="text-center">
                        {this.statusJsx(
                          expense_data1?.expense_per_km,
                          expense_data2?.expense_per_km,
                          "decrease"
                        )}
                        {this.changePer(
                          expense_data2?.expense_per_km,
                          expense_data1?.expense_per_km
                        )}
                      </td>
                    </tr>
                  )}
                  {/* {task_submodule_available == 'Y' && <Ax>
										<tr>
											<td colSpan={5}>&nbsp;</td>
										</tr>
										<tr><td colSpan={5}><b>Task</b></td></tr>

										{task1 && task1.length > 0 && task1.map((td, key) => {
											return (<tr key={key}>
												<td>{td.status}</td>
												<td></td>
												<th className='text-center'>{td.count}</th>
												<th className='text-center'>{task2 && task2.length > 0 ? task2[key].count : ""}</th>
												<td className='text-center'>{this.statusJsx(td.count, task2[key]?.count, 'increase')}
													{this.changePer(task2[key]?.count, td.count)}
												</td>
											</tr>)
										})}
									</Ax>} */}
                  <tr>
                    <td colSpan={5}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <b>Productivity</b>
                    </td>
                  </tr>
                  {production_keys_withunits &&
                    production_keys_withunits.length > 0 &&
                    production_keys_withunits.map((val, i) => {
                      let production_val1 =
                        prod_data1 && prod_data1.length > 0
                          ? prod_data1.find((p) => p.key == val.key)?.production
                          : "";

                      let production_val2 =
                        prod_data2 && prod_data2.length > 0
                          ? prod_data2.find((p) => p.key == val.key)?.production
                          : "";
                      return (
                        <tr key={i}>
                          <td>{val.display}</td>
                          <td className="text-center">{val.uom}</td>
                          <th className="text-center">{TapHelper.numberFormatter(production_val1, 3)}</th>
                          <th className="text-center">{TapHelper.numberFormatter(production_val2, 3)}</th>
                          <td className="text-center">
                            {production_val1 &&
                              production_val2 &&
                              this.statusJsx(
                                production_val1,
                                production_val2,
                                "increase"
                              )}
                            {production_val1 &&
                              production_val2 &&
                              this.changePer(
                                !production_val2 ? 0 : Number(production_val2),
                                production_val1 ? Number(production_val1) : 0
                              )}
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td>Total Productivity</td>
                    <td className="text-center"></td>
                    <th className="text-center">
                      {production1 && production1.total_producutvity
                        ? TapHelper.numberFormatter(production1.total_producutvity, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {production2 && production2.total_producutvity
                        ? TapHelper.numberFormatter(production2.total_producutvity, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        production1?.total_producutvity,
                        production2?.total_producutvity,
                        "increase"
                      )}
                      {this.changePer(
                        production2?.total_producutvity,
                        production1?.total_producutvity
                      )}
                    </td>
                  </tr>
                  {asset && asset.hour_meter == "yes" && (
                    <tr>
                      <td>Productivity per Hr</td>
                      <td className="text-center"></td>
                      <th className="text-center">
                        {production1 && production1.productivity_per_hr
                          ? TapHelper.numberFormatter(production1.productivity_per_hr, 3)
                          : ""}
                      </th>
                      <th className="text-center">
                        {production2 && production2.productivity_per_hr
                          ? TapHelper.numberFormatter(production2.productivity_per_hr, 3)
                          : ""}
                      </th>
                      <td className="text-center">
                        {this.statusJsx(
                          production1?.productivity_per_hr,
                          production2?.productivity_per_hr,
                          "increase"
                        )}
                        {this.changePer(
                          production2?.productivity_per_hr,
                          production1?.productivity_per_hr
                        )}
                      </td>
                    </tr>
                  )}
                  {asset && asset.odo_meter == "yes" && (
                    <tr>
                      <td>Productivity per KM</td>
                      <td className="text-center"></td>
                      <th className="text-center">
                        {production1 && production1.productivity_per_km
                          ? TapHelper.numberFormatter(production1.productivity_per_km, 3)
                          : ""}
                      </th>
                      <th className="text-center">
                        {production2 && production2.productivity_per_km
                          ? TapHelper.numberFormatter(production2.productivity_per_km, 3)
                          : ""}
                      </th>
                      <td className="text-center">
                        {this.statusJsx(
                          production1?.productivity_per_km,
                          production2?.productivity_per_km,
                          "increase"
                        )}
                        {this.changePer(
                          production2?.productivity_per_km,
                          production1?.productivity_per_km
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Productivity per Ltr</td>
                    <td className="text-center"></td>
                    <th className="text-center">
                      {production1 && TapHelper.numberFormatter(production1.productivity_per_ltr, 3)}
                    </th>
                    <th className="text-center">
                      {production2 && TapHelper.numberFormatter(production2?.productivity_per_ltr, 3)}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        production1?.productivity_per_ltr,
                        production2?.productivity_per_ltr,
                        "increase"
                      )}
                      {this.changePer(
                        production2?.productivity_per_ltr,
                        production1?.productivity_per_ltr
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Ltr per Productivity</td>
                    <td className="text-center"></td>
                    <th className="text-center">
                      {production1 && TapHelper.numberFormatter(production1?.ltr_per_productivity, 3)}
                    </th>
                    <th className="text-center">
                      {production2 && TapHelper.numberFormatter(production2?.ltr_per_productivity, 3)}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        production1?.ltr_per_productivity,
                        production2?.ltr_per_productivity,
                        "decrease"
                      )}
                      {this.changePer(
                        production2?.ltr_per_productivity,
                        production1?.ltr_per_productivity
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Expense per Productivity</td>
                    <td className="text-center"></td>
                    <th className="text-center">
                      {production1 && TapHelper.numberFormatter(production1?.expense_per_productivity, 3)}
                    </th>
                    <th className="text-center">
                      {production2 && TapHelper.numberFormatter(production2?.expense_per_productivity, 3)}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        production1?.expense_per_productivity,
                        production2?.expense_per_productivity,
                        "decrease"
                      )}
                      {this.changePer(
                        production2?.expense_per_productivity,
                        production1?.expense_per_productivity
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <b>Downtime</b>
                    </td>
                  </tr>

                  {downtimeTypes &&
                    downtimeTypes.length > 0 &&
                    downtimeTypes.map((dt, k) => {
                      let downtime1_dt =
                        downtime1 && downtime1.find((d) => d.key == dt.key);
                      let downtime2_dt =
                        downtime2 && downtime2.find((d) => d.key == dt.key);
                      return (
                        <tr key={k}>
                          <td>{dt.name}</td>
                          <td className="text-center">Hours</td>
                          <th className="text-center">
                            {TapHelper.numberFormatter(downtime1_dt?.downtime, 3)}
                          </th>
                          <th className="text-center">
                            {TapHelper.numberFormatter(downtime2_dt?.downtime, 3)}
                          </th>
                          <td className="text-center">
                            {this.statusJsx(
                              downtime1_dt?.downtime_seconds,
                              downtime2_dt?.downtime_seconds,
                              "decrease"
                            )}
                            {this.changePer(
                              downtime2_dt?.downtime_seconds,
                              downtime1_dt?.downtime_seconds
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  {task_submodule_available == "Y" && (
                    <Ax>
                      <tr>
                        <td colSpan={5}>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <b>Task</b>
                        </td>
                      </tr>
                    </Ax>
                  )}
                  {task_submodule_available == "Y" &&
                    task1 &&
                    task1.length > 0 &&
                    task1.map((t, k) => {
                      let task1Data =
                        task1 && task1.find((t1) => t1.key == t.key);
                      let task2Data =
                        task2 && task2.find((t2) => t2.key == t.key);
                      return (
                        <tr key={k}>
                          <td>{t.status}</td>
                          <td className="text-center">Count</td>
                          <td className="text-center">
                            {task1Data ? TapHelper.numberFormatter(task1Data.count, 3) : ""}
                          </td>
                          <td className="text-center">
                            {task2Data ? TapHelper.numberFormatter(task2Data.count, 3) : ""}
                          </td>
                          <td className="text-center">
                            {this.statusJsx(
                              task1Data?.count,
                              task2Data?.count,
                              "decrease"
                            )}
                            {this.changePer(task2Data?.count, task1Data?.count)}
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan={5}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <b>Tickets</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Tickets Created</td>
                    <td className="text-center">Count</td>
                    <th className="text-center">
                      {ticket1 && ticket1.ticket_created_count
                        ? TapHelper.numberFormatter(ticket1.ticket_created_count, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {ticket2 && ticket2.ticket_created_count
                        ? TapHelper.numberFormatter(ticket2.ticket_created_count, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        ticket1?.ticket_created_count,
                        ticket2?.ticket_created_count,
                        "increase"
                      )}
                      {this.changePer(
                        ticket2?.ticket_created_count,
                        ticket1?.ticket_created_count
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Tickets Closed</td>
                    <td className="text-center">Count</td>
                    <th className="text-center">
                      {ticket1 && ticket1.ticket_closer_count
                        ?  TapHelper.numberFormatter(ticket1.ticket_closer_count, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {ticket2 && ticket2.ticket_closer_count
                        ?  TapHelper.numberFormatter(ticket2.ticket_closer_count, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        ticket1?.ticket_closer_count,
                        ticket2?.ticket_closer_count,
                        "increase"
                      )}
                      {this.changePer(
                        ticket2?.ticket_closer_count,
                        ticket1?.ticket_closer_count
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Repair Tickets</td>
                    <td className="text-center">Count</td>
                    <th className="text-center">
                      {ticket1 && ticket1.repair_ticket
                        ? TapHelper.numberFormatter(ticket1.repair_ticket, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {ticket2 && ticket2.repair_ticket
                        ? TapHelper.numberFormatter(ticket2.repair_ticket, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        ticket1?.repair_ticket,
                        ticket2?.repair_ticket,
                        "increase"
                      )}
                      {this.changePer(
                        ticket2?.repair_ticket,
                        ticket1?.repair_ticket
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Downtime as per Ticket</td>
                    <td className="text-center">Count</td>
                    <th className="text-center">
                      {ticket1 && ticket1.total_downtime_as_per_ticket
                        ? TapHelper.numberFormatter(ticket1.total_downtime_as_per_ticket, 3)
                        : ""}
                    </th>
                    <th className="text-center">
                      {ticket2 && ticket2.total_downtime_as_per_ticket
                        ? TapHelper.numberFormatter(ticket2.total_downtime_as_per_ticket, 3)
                        : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        ticket1?.total_downtime_in_seconds,
                        ticket2?.total_downtime_in_seconds,
                        "increase"
                      )}
                      {this.changePer(
                        ticket2?.total_downtime_in_seconds,
                        ticket1?.total_downtime_in_seconds
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Mean Time to Repair (MTTR)</td>
                    <td className="text-center">Hours</td>
                    <th className="text-center">
                      {ticket1 && ticket1.mttr ? TapHelper.numberFormatter(ticket1.mttr, 3) : ""}
                    </th>
                    <th className="text-center">
                      {ticket2 && ticket2.mttr ? TapHelper.numberFormatter(ticket2.mttr, 3) : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(ticket1?.mttr_seconds, ticket2?.mttr_in_seconds, "increase")}
                      {this.changePer(ticket2?.mttr_seconds, ticket1?.mttr_in_seconds)}
                    </td>
                  </tr>
                  <tr>
                    <td>Mean Time between Failure (MTBF)</td>
                    <td className="text-center">Count</td>
                    <th className="text-center">
                      {ticket1 && ticket1.mtbf ? TapHelper.numberFormatter(ticket1.mtbf, 3) : ""}
                    </th>
                    <th className="text-center">
                      {ticket2 && ticket2.mtbf ? TapHelper.numberFormatter(ticket2.mtbf, 3) : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(ticket1?.mtbf, ticket2?.mtbf, "increase")}
                      {this.changePer(ticket2?.mtbf, ticket1?.mtbf)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <b>Service Checksheet</b>
                    </td>
                  </tr>
                  <tr>
                    <td>SCS Executed</td>
                    <td className="text-center">Count</td>
                    <th className="text-center">
                      {scs1 && scs1.scs_executed ? TapHelper.numberFormatter(scs1.scs_executed, 3) : ""}
                    </th>
                    <th className="text-center">
                      {scs2 && scs2.scs_executed ? TapHelper.numberFormatter(scs2.scs_executed, 3) : ""}
                    </th>
                    <td className="text-center">
                      {this.statusJsx(
                        scs1?.scs_executed,
                        scs2?.scs_executed,
                        "increase"
                      )}
                      {this.changePer(scs2?.scs_executed, scs1?.scs_executed)}
                    </td>
                  </tr>
                </Ax>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  refresh = () => {
    this.initialize(this.props, "refresh");
  };

  changeEvent = (e = null, type = null) => {
    // localStorage.removeItem(this.state?.key);
    let period1 = type === "period1" ? e.label : this.state.show_period.period1;
    let period2 = type === "period2" ? e.label : this.state.show_period.period2;
    this.setState(
      {
        show_period: { period1: period1, period2: period2 },
      },
      () => {
        this.initialize(this.props, type);
      }
    );
  };

  headerRowJsx = () => {
    return (
      <div className="row">
        <div className="col-5">
          {/*<h6 className="fw-bold primary_color">ASSETS</h6> */}
        </div>
        <div className="col-3">
          <TapSelect
            options={this.state.all_periods.filter(
              (st) => st.value !== this.state.selected_period.period2
            )}
            isSearchable={true}
            isClearable={false}
            placeholder="Select Period"
            containerHeight="33px"
            fontSize="93%"
            value={this.state.all_periods.find(
              (r) => r.value === this.state.selected_period.period1
            )}
            changeEvent={(selectedOption) =>
              this.tapSelectChange(
                selectedOption,
                "selected_period",
                "period1",
                () => this.changeEvent(selectedOption, "period1")
              )
            }
          />
        </div>
        <div className="col-3 p0">
          <TapSelect
            options={this.state.all_periods.filter(
              (st) => st.value !== this.state.selected_period.period1
            )}
            isSearchable={true}
            isClearable={false}
            placeholder="Select Period"
            containerHeight="33px"
            fontSize="93%"
            value={this.state.all_periods.find(
              (r) => r.value === this.state.selected_period.period2
            )}
            changeEvent={(selectedOption) =>
              this.tapSelectChange(
                selectedOption,
                "selected_period",
                "period2",
                () => this.changeEvent(selectedOption, "period2")
              )
            }
          />
        </div>
        <div className="col-1 pl0 ml0 text-center">
          <tapIcon.imageIcon
            icon={tapIcon.ListActionIcon}
            alt="action-icon"
            className="img-fluid px-2 btn btn-light btn-block"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-expanded="false"
          />
          <ul className="dropdown-menu dropdown-menu-end">
            <li
              className="dropdown-item"
              style={{ cursor: "pointer" }}
              onClick={this.refresh}
            >
              Refresh
            </li>
          </ul>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Ax>
        <Helmet>
          <title>Asset Dashboard</title>
        </Helmet>
        <div className="page_containt">
          <div className="pageTbl px-3">
            <div className="card mt-4">
              <div className="card-body">
                {this.headerRowJsx()}
                {this.dashboardTblJsx()}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="pageTbl bg-white pl0">
					<div className="page_containt p-4">
						<div className="tab-content">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										{this.headerRowJsx()}
										{this.dashboardTblJsx()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
      </Ax>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions: state.app && state.app.acl_info ? state.app.acl_info : [],
    all_periods:
      state.app && state.app.all_periods ? state.app.all_periods : [],
  };
};

export default connect(mapStateToProps)(AssetDashboard);
