import { connect } from "react-redux";
import { Modal } from "bootstrap";
import swal from "sweetalert";
import axios from "axios";
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { toast } from "react-toastify";
import React from "react";
import TapApiUrls, { ISC_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import TapSelect from "../../../components/ui/TapSelect";
import { Link } from 'react-router-dom';

class AdditionalAttributeWarehouse extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.iscAdditionAttrUrl =   ISC_API_BASE_URL_2 + '/additional_attribute';

        this.attrInitForm        =       {
            category             :       "warehouse",
            name                 :       '',
            type                 :       '',
        }
        this.state               =        {
            addAttrListing       :        [],
            openForm             :       false,
            listing_loading      :        false,
            editFormSubmiting    :        false,
            selectedAttribute    :         null,
            addAttrForm          :       { ...this.attrInitForm },
            options              :       ['Option 1', 'Option 2'],
        }

    }

    componentDidMount() {
        this.getAddAttrListing()
        this.addWarehouseAttrInit            =           new Modal(document.getElementById('addWarehouseAttrModal'), { keyboard: false, backdrop: false });
        if(this.props.openForm === true){
            this.addAttrModalInit()
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.openForm !== this.props.openForm) {
            this.addAttrModalInit()
        }
    }

    getAddAttrListing            =           ()         =>      {
        this.setState({ listing_loading: true })
        HttpAPICall.withAthorization('GET', this.iscAdditionAttrUrl, this.props.access_token, {}, {}, (resp) => {
            this.setState({ addAttrListing: resp.data.data })
        }).then(() => this.setState({ listing_loading: false }));
    }

    addAttrModalInit             =           (id)        =>         {
        this.addWarehouseAttrInit.show()
        if(id !== null && id !== undefined){
            this.setState({edit : true})
            let warehouseAttrList = this.state.addAttrListing.find(function (attr) {
                return attr.id == id;
            });
           this.setState({
               addAttrForm : {name : warehouseAttrList.name,type:warehouseAttrList.type,key:warehouseAttrList.key,category : "warehouse"},
               options     : warehouseAttrList.options.length > 0 ? warehouseAttrList.options.map(i => i) : []})
        }else{
            this.setState({edit : false})
        }
    }

    closeAttrForm       =       ()       =>      {
        this.setState({ addAttrForm: this.attrInitForm })
        this.props.closeForm()
    }

    AllAttributeTypes = [
        { value: 'text', label: 'Text' },
        { value: 'numeric', label: ' Numeric' },
        { value: 'date', label: ' Date' },
        { value: 'dropdown', label: 'Dropdown' }
    ]

    handleOptionValue       =           (e, i)      =>      {
        let options = [...this.state.options]
        options[i] = e.target.value
        this.setState({
            options
        })
    }

    handleDeleteOption      =       i        =>      e      => {
        e.preventDefault()
        if (i >= 0) {
            let options = [
                ...this.state.options.slice(0, i),
                ...this.state.options.slice(i + 1)
            ]
            this.setState({
                options
            })
        }
    }

    addOption             =       e       =>      {
        e.preventDefault()
        let options = this.state.options.concat([''])
        this.setState({
            options
        })
    }




    addWarehouseAttrModalJsx         =          ()      =>       {
        return (
            <div className="modal fade" id="addWarehouseAttrModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.edit ? "Edit" : "Add"} Additional Attribute (Warehouse)</h5>
                        </div>
                        <form onSubmit={this.submitWarehouseAttrForm}>
                            <div className="modal-body p-3">
                                <div className="row align-items-center my-3">
                                    <div className="col-md-3"><label className="form-label">Attribute Name</label></div>
                                    <div className="col-md-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addAttrForm.name}
                                            onChange={(e) => this.formInputHandler(e, 'addAttrForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            required={true}

                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mt-3 mb-1">
                                    <div className="col-md-3"><label className="form-label">Type</label></div>
                                    <div className="col-md-9">
                                        <TapSelect options={this.AllAttributeTypes}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addAttrForm', 'type')}
                                            value={this.AllAttributeTypes.find(a => a.value == this.state.addAttrForm.type)}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            placeholder="Please Select Attribute Type"
                                            containerHeight="33px"
                                            isDisabled={this.state.edit === true ? true : false}
                                            fontSize="93%" />
                                    </div>
                                </div>
                                <div className="row align-items-center my-1">
                                    <div className="offset-3 col-sm-9">
                                        {this.state.addAttrForm.type == "dropdown" ?
                                            <div>{this.state.options.length >= 2 &&
                                                this.state.options.map((i, index) => {
                                                    return (
                                                        <div className="row" key={index}>
                                                            <div className={this.state.options.length > 2 ? "col-sm-10" : "col-sm-12"}>
                                                                <input
                                                                    name={i}
                                                                    type="text"
                                                                    className="form-control my-2"
                                                                    value={i}
                                                                    // disabled={this.state.edit === true ? true : false}
                                                                    placeholder="Please Enter Options"
                                                                    required="true"
                                                                    onChange={(e) => this.handleOptionValue(e, index)}
                                                                />
                                                            </div>
                                                            {this.state.options.length > 2 ?
                                                                <div className="col-sm-2"><button className="btn btn-secondary my-3"
                                                                    onClick={this.handleDeleteOption(index)}>X</button></div> : null}
                                                        </div>
                                                    )
                                                })}
                                                <div className="link-primary text-end" onClick={this.addOption}>Add New Option</div>
                                            </div>
                                            : null}
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal" onClick={this.closeAttrForm}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    submitWarehouseAttrForm         =           (e)     =>          {
        e.preventDefault()
        this.setState({ editFormSubmiting: true });
        if (this.state.edit === true) {
            let frmData ;
            if(this.state.addAttrForm.type == "dropdown"){
                frmData = {...this.state.addAttrForm,options: this.state.options}
            }else{
                frmData = {...this.state.addAttrForm}
            }
            HttpAPICall.withAthorization('PUT', this.iscAdditionAttrUrl, this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addWarehouseAttrInit.hide();
                this.getAddAttrListing();
                this.props.closeForm()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ editFormSubmiting: false }))
        } else {
            let frmData ;
            if(this.state.addAttrForm.type == "dropdown"){
                frmData = {...this.state.addAttrForm,options: this.state.options}
            }else{
                frmData = {...this.state.addAttrForm}
            }
            HttpAPICall.withAthorization('POST', this.iscAdditionAttrUrl, this.props.access_token, {}, { ...frmData}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addWarehouseAttrInit.hide();
                this.getAddAttrListing();
                this.props.closeForm()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ editFormSubmiting: false }))
        }
    }

    deleteWarehouseAttr                  =           (id)            =>          {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {

                    HttpAPICall.withAthorization('DELETE', this.iscAdditionAttrUrl + '/' + id, this.props.access_token, {  }, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getAddAttrListing()
                    }).then(() => { });
                }
            });
    }



    render          =       ()      =>      {
        return (<Ax>
            <table className="table table-hover table-bordered bg-white ">
                <thead className="table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                        <th scope="col" className="text-start">Name</th>
                        <th scope="col" style={{ width: "15%" }} className="text-start">Type</th>
                        <th scope="col" style={{ width: "35%" }} className="text-start">Notes</th>
                        <th scope="col" style={{ width: "10%" }} >Action</th>

                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.addAttrListing.length > 0 
                            ? (this.state.addAttrListing.filter(i => i.category === "warehouse").map((item, k) => {
                                return (<tr key={k} className="text-center">
                                    <td>{k + 1}</td>
                                    <td className="text-capitalize text-start">{item.name}</td>
                                    <td className="text-capitalize text-start">{item.type}</td>
                                    <td className="text-start">{item.options !== null && item.options.length > 1 ?
                                        <div>
                                            {item.options.map((itemNew, index) => {
                                                return (
                                                    <span>{itemNew} {index < item.options.length - 1 ? ", " : ""}</span>
                                                );
                                            })}
                                        </div> : "-"}</td>
                                    <td>  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                        role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                            <li>
                                                <Link role="button"
                                                    onClick={() => {
                                                        if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                            return this.addAttrModalInit(item.id)
                                                        }
                                                    }}
                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Edit</Link>
                                            </li>
                                            <li>
                                                <Link role="button"
                                                    onClick={() => {
                                                        if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                            return this.deleteWarehouseAttr(item.id)
                                                        }
                                                    }}
                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete</Link>
                                            </li>
                                        </ul></td>
                                </tr>);
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            {this.addWarehouseAttrModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(AdditionalAttributeWarehouse);