import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import moment from "moment";
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import * as actions from "../../../store/actions/index"
import UpdateMyDashboardForm from '../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapColorCodes from '../../../services/TapColorCodes';
class MttrMtbfWidget extends AppBaseComponent {

    constructor() {
        super();
        this.state = {
            iam_user_sites: [],
            iam_asset_types: [],
            all_periods: [],
            mttrForm: { filter_site_id: '', search_asset_type: '', period: 'current_month' },
            loading: false,
            data: [],
        }
        this.updateMyDashboardRef = React.createRef();
        this.reportUrl = IAM_API_BASE_URL_2 + '/report/mttr_mtbf_report';
    }


    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('mttr_mtbf_widget')) {
            let mttrWidgetData = JSON.parse(localStorage.getItem('mttr_mtbf_widget'));
            if (mttrWidgetData) {
                let lastRefreshTime = moment(mttrWidgetData.time).format();
                let nextDay = moment(lastRefreshTime).add(1, 'days').format();
                lastRefreshTime = moment(lastRefreshTime).valueOf();
                nextDay = moment(nextDay).valueOf();
                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf();
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('mttr_mtbf_widget')
                    setTimeout(() => {
                        this.getDelayedTransactionDelayPeriod();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));

                } else {
                    this.setState({

                    })
                }
            } else {
                setTimeout(() => {
                    this.getDelayedTransactionDelayPeriod();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));
            }
        } else {
            setTimeout(() => {
                this.getDelayedTransactionDelayPeriod();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));
        }
    }

    getDelayedTransactionDelayPeriod = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            if (this.props.group_info && this.props.group_info.group_id == 'nccltd') {
                setTimeout(() => {
                    this.getMTTRListing();
                }, 9000);
            } else {
                this.getMTTRListing();
            }
        }, 2000);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter = (props) => {
        if (props.iam_user_sites.length > 0) {
            this.setState({
                iam_user_sites: props.iam_user_sites ? props.iam_user_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                iam_asset_types: props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                all_periods: props.all_periods ? props.all_periods.map((s) => { return { value: s.key, label: `${s.display}` } }) : [],
            }, () => {
                if (props.dashboard_filter_site_id || props.clearDashboardFilter == "yes") {

                    this.setState({
                        mttrForm: {
                            filter_site_id: props.dashboard_filter_site_id,
                            search_asset_type: '',
                            period: 'current_month'
                        }
                    }, () => this.getMTTRListing())
                }
            });
        }
    }

    getMTTRListing = (reload = false) => {
        this.setState({ loading: true });
        let params = {
            ...this.state.mttrForm, action: "widget",
            date_range: this.state.mttrForm && this.state.mttrForm.period ? this.state.mttrForm.period : 'current_month', reload: reload ? 'reload' : false
        };
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, null, (response) => {
            this.setState({
                data: response.data.data
            })
            if (this.state.mttrForm.period && this.state.mttrForm.period == 'current_month') {
                localStorage.setItem('mttr_mtbf_widget', JSON.stringify(response.data));
            }
            if (!this.state.mttrForm.filter_site_id && !this.state.mttrForm.search_asset_type) {
                localStorage.setItem('mttr_mtbf_widget', JSON.stringify(response.data));

            }
            if (reload !== false) {
                localStorage.setItem('mttr_mtbf_widget', JSON.stringify(response.data));
            }

        }).then(() => this.setState({ loading: false }));
    }

    pinMyDashboardHandler = (widgetData = null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData, { key: "mttr_mtbf", name: "MTTR & MTBF" })
    }

    render = () => {
        let allStatus = this.state.data

        let filterObj = {};
        if (this.state.mttrForm.filter_site_id) {
            filterObj = { ...filterObj, 'search_site_ids': this.state.mttrForm.filter_site_id }
        }
        if (this.state.mttrForm.search_asset_type) {
            filterObj = { ...filterObj, 'search_asset_type_ids': this.state.mttrForm.search_asset_type }
        }
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => { 
           
            return ("mttr_mtbf" == st.key) }) ? this.props.widget_keys.find(st => { return (st.key == "mttr_mtbf") }) : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-2 pe-0">
                        <h6 className="fw-bold primary_color">MTTR & MTBF</h6>
                    </div>
                    <div className="col-sm-3 p-0"></div>
                    <div className="col-sm-2 ps-0">
                        <TapSelect
                            isClearable={false}
                            placeholder="Select Period"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.state.all_periods}
                            value={this.state.all_periods.find(r => r.value === this.state.mttrForm.period)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'mttrForm', 'period', () => this.getMTTRListing())}
                        />
                    </div>
                    <div className="col-sm-2 ps-0">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Asset"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_asset_types.find(r => r.value === this.state.mttrForm.search_asset_type)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'mttrForm', 'search_asset_type', () => this.getMTTRListing())}
                        />
                    </div>
                    <div className="col-sm-2 p-0">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.find(r => r.value === this.state.mttrForm.filter_site_id)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'mttrForm', 'filter_site_id', () => this.getMTTRListing())}
                        />
                    </div>
                    <div className="col-sm-1 p-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <Link className={['dropdown-item'].join(' ')} onClick={() => this.getMTTRListing(true)}>Refresh</Link>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "50%" }} className="text-center" ></th>
                                    <th className="text-center">No of MTTR & MTBF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? (<tr>
                                        <td className="text-center" colSpan="2"><Loader /></td>
                                    </tr>)
                                    : <Ax>
                                        <tr>
                                            <th>MTTR</th>
                                            <td className="text-center">{allStatus.mttr || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>MTBF</th>
                                            <td className="text-center">{allStatus.mtbf || "-"}</td>
                                        </tr>
                                    </Ax>}



                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                        <span style={{ color: "red" }}> * Total Repair Ticket</span> : {allStatus.total_repair_ticket}
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                        Report as of {allStatus.time || "-"}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                ref={this.updateMyDashboardRef}
            />
        </div>);
    }

}


const mapStateToProps = state => {

    return {
        access_token: state.auth.access_token,
        group_info: state.app.group_info ? state.app.group_info : null,
        iam_user_sites: state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types: state.app && state.app.asset_type ? state.app.asset_type : [],
        widget_keys: state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
        all_periods: state.app && state.app.all_periods ? state.app.all_periods : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading: (access_token) => dispatch(actions.applicationInitLoading(access_token, false)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MttrMtbfWidget);
