import { Modal } from 'bootstrap';
import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import {useSelector} from 'react-redux'
import uuid from 'react-uuid';
import TapSelect from '../../components/ui/TapSelect';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import InputSubAssemblySearch from '../includes/ui/InputSubAssemblySearch';
import InputAssetSearch from '../includes/ui/InputAssetSearch';
import InputWorkstationSearch from '../includes/ui/InputWorkstationSearch';
import InputEmployeeSearch from '../includes/ui/InputEmployeeSearch';
import TapIcon from '../../services/TapIcon';
import { toast } from "react-toastify";


const UpdateMultipleTicketModal     = forwardRef((props, ref) => {


    const ticketEditFromDataAPIUrl      =   IAM_API_BASE_URL_2 + '/multiple_ticket_edit/form-data';
    const multipleTicketsEditAPIUrl     =   IAM_API_BASE_URL_2 + '/multiple_ticket_edit';


    let initFormData             =   {
        'loading'                       :   false,
        'submitting'                    :   false,
        'activity_ids'                  :   [],
        'ticket_for'                    :   'asset',
        'asset_id'                      :   null,
        'employee_id'                   :   null,
        'sub_assembly_id'               :   null,
        'site_id'                       :   null,
        'workstation_id'                :   null,
        'tagged_users'                  :   []
    };

    const [modalId, setModalId]             =   useState(uuid());
    const [formId, setformId]               =   useState(uuid());
    let [formData, setTicketFormData]       =   useState({...initFormData});
    let [ticketForList, setTicketForList]   =   useState([]);
    let [userList, setUserList]             =   useState([]);
    
    let [subAssembly, setSubAssembly]       =   useState(null);
    let [asset, setAsset]                   =   useState(null);
    let [workStation, setWorkStation]       =   useState(null);
    let [employee, setEmployee]             =   useState(null);
    let [site, setSite]                     =   useState(null);

    const access_token                      =   useSelector(state => state.auth.access_token);
    const iam_user_sites                    =   useSelector(state => state.app.user_sites ? state.app.user_sites.map(s => {
        return {...s, value : s.id, label : `${s.site_name} (${s.site_code})`, code: s.site_code}
    }) : []);
    

    const editTicketModalElem               =   useRef(null);
    const editTicketModal                   =   useRef(null);

    useEffect(() => {
        editTicketModalElem.current         =   document.getElementById(modalId);
        editTicketModal.current             =   new Modal(editTicketModalElem.current, {keyboard: false, backdrop :false});

        // Cleanup on unmount
        return () => {
            if (editTicketModal.current) {
                editTicketModal.current.dispose();
            }
        };
    }, []);

    useImperativeHandle(ref, () => ({

        initModalHandler: (activity_ids) => {
            setTicketFormData(initFormData);
            setAsset(null);
            setSubAssembly(null);
            setWorkStation(null);
            setEmployee(null);
            setSite(null);
            openModalHandler2(activity_ids);
        }
    }));


    const openModalHandler2 = (activity_ids=[]) => {
        if(editTicketModal) {
            ticketEditModalOpen();
            setTicketFormData(data => ({...data, activity_ids : activity_ids}));
            loadTicketsEditFormData();
        }
    }

    const ticketEditModalOpen       =   ()  => {
        if (editTicketModal.current) {
            editTicketModal.current.show();
        }
    }

    const ticketEditModalClose      =   ()  => {
        if (editTicketModal.current) {
            editTicketModal.current.hide();
        }
    }

    const loadTicketsEditFormData = () => {
        setTicketFormData(data => ({...data, loading : true}));

        HttpAPICall.withAthorization('GET', ticketEditFromDataAPIUrl, access_token,{site_id :props.site_id},{},(response) => {
            setTicketFormData(data => ({...data, loading : false}));
            setTicketForList(response.data.ticket_for.map(f => { return {value : f.key, 'label' : f.name }; }));
            setUserList(response.data.users.map(u => { return {...u, value : u.id, 'label' : u.full_name }; }));
        }).then(() => { } );
    }

    const setTicketForValue         =   (o)  =>  {
        setAsset(null);
        setSubAssembly(null);
        setWorkStation(null);
        setEmployee(null);
        setSite(null);
        setTicketFormData(data => ({...data, site_id:null,asset_id:null,sub_assembly_id:null,workstation_id:null,employee_id:null}));
        if(formData.ticket_for == 'asset') {
            let site_id         =   o && o.asset ? o.asset.site_id : null;
            let site            =   iam_user_sites.find(s => s.id == site_id);
            setTicketFormData(data => ({...data, site_id:site_id, asset_id:o ? o.value : null}));
            setAsset(o);
            setSite(site);
        } 
        if(formData.ticket_for == 'sub_assembly') {
            let site_id         =   o && o.subAssembly && o.subAssembly.site ? o.subAssembly.site.id : null;
            let site            =   iam_user_sites.find(s => s.id == site_id);
            setTicketFormData(data => ({...data, site_id:site_id, sub_assembly_id:o ? o.value : null}));
            setSubAssembly(o);
            setSite(site);
        }
        if(formData.ticket_for == 'workstation') {
            let site_id         =   o && o.workstation ? o.workstation.site_id : null;
            let site            =   iam_user_sites.find(s => s.id == site_id);
            setTicketFormData(data => ({...data, site_id:site_id, workstation_id:o ? o.value : null}));
            setWorkStation(o);
            setSite(site);
        }
        if(formData.ticket_for == 'employee') {
            let site_id         =   o && o.employee ? o.employee.site_id : null;
            let site            =   iam_user_sites.find(s => s.id == site_id);
            setTicketFormData(data => ({...data, site_id:site_id, employee_id:o ? o.value : null}));
            setEmployee(o);
            setSite(site);
        }
        if(formData.ticket_for == 'site') {
            setSite(o);
            setTicketFormData(data => ({...data, site_id: o ? o.value : null}));
        }
    }

    const submitTicketFormhandler   =   (e) => {
        e.preventDefault();
        setTicketFormData(data => ({...data, submitting : true}));
        HttpAPICall.withAthorization("PUT", multipleTicketsEditAPIUrl, access_token, {}, { ...formData }, (response) => {
            //1 - Modal Close :-
            ticketEditModalClose();
            //2 - Reset Ticket Form Data :-
            setTicketFormData(initFormData);
            setTicketForValue(null);
            //3 - Refresh Ticket List and Project Details :-
            if(props.afterSucess) {
                props.afterSucess()
            }
            //4 - Show Success Message :-
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            setTicketFormData(data => ({...data, submitting : false}));
        });
    } 

    const editTicketsModaljsx       =   ()  =>  { 
        return <>
            <div className="modal fade" id={modalId} tabIndex="-1" ref={editTicketModalElem}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Update Ticket</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={formData.submitting}  aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form name='' id={formId+'Form'} onSubmit={submitTicketFormhandler}>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-4"><label className="form-label require">Ticket IDs</label></div>
                                    <div className="col-sm-8">
                                    <TapSelect
                                        options={formData.activity_ids.map(a => { return {value : a, label : a} } )}
                                        value={formData.activity_ids.map(a => { return {value : a, label : a} } )}
                                        placeholder="Please Select Tickets"
                                        isMulti={true}
                                        isDisabled={true}
                                        isClearable={false}
                                    />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-4"><label className="form-label require">Ticket For</label></div>
                                    <div className="col-sm-8">
                                    <TapSelect
                                        options={ticketForList}
                                        placeholder="Please Select Ticket For"
                                        isSearchable={true}
                                        value={ticketForList.find(f=>f.value==formData.ticket_for)}
                                        changeEvent={o=> {
                                            setTicketFormData(data => ({...data, ticket_for : o ? o.value : '', site_id:null,asset_id:null,sub_assembly_id:null,workstation_id:null,employee_id:null}));
                                            setSite(null);
                                        }}
                                    />
                                    </div>
                                </div>
                                {formData.ticket_for === "asset" &&  <div className="row align-items-center my-3">
                                    <div className="col-4"><label className="form-label require" >Asset</label></div>
                                    <div className="col-8">
                                        <InputAssetSearch placeholder="Search Asset" required={true}
                                            changeEvent={(o) => setTicketForValue(o)}
                                            menuPlacement="top"
                                            isMulti={false}
                                            value={asset ? {...asset , label: asset.display_label} : null }
                                            search_site_id={props.site_id}
                                        />
                                    </div>
                                </div>}
                                {formData.ticket_for === "sub_assembly" &&  <div className="row align-items-center my-3">
                                    <div className="col-4"><label className="form-label require" >Sub Assembly</label></div>
                                    <div className="col-8">
                                        <InputSubAssemblySearch placeholder="Please Select Sub Assembly"
                                            changeEvent={(o) => setTicketForValue(o)}
                                            required={true}
                                            menuPlacement="top"
                                            isMulti={false}
                                            value={subAssembly}
                                            search_site_id={props.site_id}
                                        />
                                    </div>
                                </div>}
                                {formData.ticket_for === "workstation" &&  <div className="row align-items-center my-3">
                                    <div className="col-4"><label className="form-label require" >Workstation</label></div>
                                    <div className="col-8">
                                        <InputWorkstationSearch placeholder="Please Select Workstation"
                                            changeEvent={(o) => setTicketForValue(o)}
                                            required={true}
                                            menuPlacement="top"
                                            isMulti={false}
                                            value={workStation}
                                            search_site_id={props.site_id}
                                        />
                                    </div>
                                </div>}
                                {formData.ticket_for === "employee" &&  <div className="row align-items-center my-3">
                                    <div className="col-4"><label className="form-label require" >Employee</label></div>
                                    <div className="col-8">
                                        <InputEmployeeSearch placeholder="Please Select Employee"
                                            changeEvent={(o) => setTicketForValue(o)}
                                            required={true}
                                            menuPlacement="top"
                                            isMulti={false}
                                            value={employee}
                                            search_site_ids={[props.site_id]}
                                        />
                                    </div>
                                </div>}
                                <div className="row align-items-center my-3">
                                    <div className="col-4"><label className="form-label require" >Site</label></div>
                                    <div className="col-8">
                                        <TapSelect
                                            options={formData.ticket_for === "site" && props.site_id ? iam_user_sites.filter(s => s.id == props.site_id) : iam_user_sites}
                                            changeEvent={(o) => setTicketForValue(o)}
                                            required={true}
                                            value={site}
                                            isSearchable={true}
                                            placeholder="Please Select Site"
                                            isDisabled={ formData.ticket_for !== "site" }
                                        />
                                    </div>
                                </div>

                                <div className="row align-items-center my-3">
                                    <div className="col-4"><label className="form-label require" >Tagged User</label></div>
                                    <div className="col-8">
                                        <TapSelect
                                            options={userList}
                                            required={true}
                                            isMulti={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Please Select Users"
                                            value={userList.filter(u => formData.tagged_users.includes(u.id))}
                                            changeEvent={o => {
                                                setTicketFormData(data => ({...data, tagged_users : o.map(e => e.id)}));
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" disabled={formData.submitting}  data-bs-dismiss="modal">Close</button>
                            <button form={formId+'Form'}  type="submit" disabled={formData.submitting} className="btn btn-primary">Save {formData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    return <>
        {editTicketsModaljsx()}
    </>
});

export default UpdateMultipleTicketModal;