import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import ItemViewInList from '../includes/ItemViewInList';
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';

class InventoryAdjustmentDetail extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                      =       {
            adjustmentViewData         :       null,
            view_loading                :       false,
            itemDetails                 :       null,
        };

        this.InventoryAdjustmentUrl         =   INVENTORY_API_BASE_URL_2 + '/inventory_adjustment/detail/_replace_txn_id';
       
        this.itemDetailsRef                  =    React.createRef();
    }

    componentDidMount() {
        if(this.props && this.props.adjustmentId){
            this.getAdjustmentViewDetails(this.props.adjustmentId)
        }
        if(this.props && this.props.adjustmentData){
            this.setState({adjustmentViewData : this.props.adjustmentData})
        }
        this.viewItemModal             =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
     }
 
    componentWillReceiveProps(nextProps){
        if(nextProps.adjustmentId !== this.props.adjustmentId){
            this.getAdjustmentViewDetails(nextProps.adjustmentId)
        }
        if(nextProps.adjustmentData !== this.props.adjustmentData){
            this.setState({adjustmentViewData : nextProps.adjustmentData})
        }
    }
    
    getAdjustmentViewDetails = (transaction_id) => {
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', this.InventoryAdjustmentUrl.replace('_replace_txn_id', transaction_id), this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                adjustmentViewData: respData.data,
                adjustmentLoaded: false,
            });
        }).then(() => this.setState({ view_loading: false }));
    }

    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }

   
    render() {
        let adjustmentData = this.state.adjustmentViewData;
        let total_add       =   0;
        let total_subtact   =   0;

        if (adjustmentData && adjustmentData.items && adjustmentData.items.length > 0) {
            adjustmentData.items.forEach((item, key) => {
                if (item && item.qty &&  !item.qty.includes('-')) {
                    total_add += Number(item.qty);
                }
                if (item && item.qty &&  item.qty.includes('-')) {
                    total_subtact += Number(item.qty);
                }
            });
        }
        return (<Ax>
            {!this.state.view_loading
                ? (<Ax>
                    {adjustmentData
                        ? <div>
                            <table className="table table-bordered bg-white" >
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{adjustmentData.transaction_id}</td>
                                        <td scope="col">{adjustmentData.transaction_date_display}</td>
                                        <td scope="col" className="align-middle">
                                            {adjustmentData.status_data ? adjustmentData.status_data.name : ''}
                                        </td>  </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >Warehouse</td>
                                        <td colSpan={2}>{adjustmentData.warehouse ? <div><b>{adjustmentData.warehouse.name} ({adjustmentData.warehouse.code})</b> <br />
                                            {adjustmentData.warehouse ?
                                                <div className='pt-1'>Address : {adjustmentData.warehouse.address1 ? adjustmentData.warehouse.address1 + ", " : ""}
                                                    {adjustmentData.warehouse.address2 ? adjustmentData.warehouse.address2 + ", " : ""}
                                                    {adjustmentData.warehouse.landmark ? adjustmentData.warehouse.landmark + ", " : ""}
                                                    {adjustmentData.warehouse.city ? adjustmentData.warehouse.city + ". " : "-"} <br />
                                                    Destination of Supply : <b>{adjustmentData.warehouse.state_name}</b> <br />
                                                    GSTIN  : {adjustmentData.warehouse_gstin ?? "-"} <br />
                                                </div> : "-"}</div> : "-"} <br />
                                        </td>
                                    </tr>

                                    {adjustmentData.additional_attributes_data && adjustmentData.additional_attributes_data.length > 0
                                        ? adjustmentData.additional_attributes_data.map((attr) => {
                                            return (<tr key={attr.key}>
                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                <td scope="col" colSpan={2}>{attr.value_display ? attr.value_display : "-"}</td>
                                            </tr>)
                                        }) : null}
                                </tbody>
                            </table>

                            <table className="table table-bordered table-responsive bg-white my-3 table-sm" id="poTable">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ "width": '5%' }} className="text-center"><input type='checkbox' onChange={this.checkAllCancelItem} name="chk[]" id="all_check_po" /></th>
                                        <th>Item Details</th>
                                        <th style={{ width: "10%" }}>Unit Rate</th>
                                        <th style={{ width: "12%" }}>Adjustment Type</th>
                                        {this.props?.isc_configuration?.track_batch_expiry == 'Y' ? <Ax>
                                            <th scope="col" className="text-center" style={{ width: "15%" }}>Batch Number <br/> Expiry Date</th>

                                        </Ax> : null}
                                        <th style={{ width: "12%" }} className="text-center">Add Qty</th>
                                        <th style={{ width: "8%" }} className="text-center">Subtract Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        adjustmentData && adjustmentData.items && adjustmentData.items.length > 0 ? adjustmentData.items?.map((i, k) => {
                                            return (<tr>
                                                <td className='text-center'>
                                                    {k + 1}

                                                </td>

                                                <td>{i.item ?
                                                    <div className='row'>
                                                        <div className='col-sm-10'>
                                                            <ItemViewInList item={i.item} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.item)} />
                                                        </div>

                                                    </div>
                                                    : null}
                                                </td>
                                                <td className='text-center'>{i.rate ? i.rate : ""}</td>
                                                <td className='text-center'>{i.qty && i.qty.includes("-") ? "Subtract" : "Add"}</td>
                                                {this.props?.isc_configuration?.track_batch_expiry == 'Y' ? <Ax>
                                                    <td className='text-center'>{i.batch_number ? i.batch_number : "-"} <br /> {i.expiry_date_display ? i.expiry_date_display : ""}</td>
                                                </Ax> : null}
                                                <td className='text-center'>{i.qty && i.qty.includes("-") ? "" : i.qty}</td>
                                                <td className='text-center'>{i.qty && i.qty.includes("-") ? Math.abs(i.qty) : ""}</td>
                                            </tr>)

                                        }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className="row align-items-center mb-5">
                                <div className="col-6">
                                    <label htmlFor="notes" className="form-label">Notes</label>
                                    <div>{adjustmentData.notes ?? "-"}</div>

                                </div>
                                <div className="col-6">
                                    <table className="table  table-borderless">
                                        <tbody>
                                            <tr className="subtotal_bg_color">
                                                <th>Total Qty Added</th>
                                                <th className='text-end'>{total_add}</th>
                                            </tr>
                                            <tr className="subtotal_bg_color">
                                                <th>Total Qty Subtracted</th>
                                                <th className='text-end'>{total_subtact}</th>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        : null}
                </Ax>)
                : <Loader />}

            <ItemDetailsModal ref={this.itemDetailsRef} />

        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps)(InventoryAdjustmentDetail);