import React from 'react';

import { connect } from "react-redux";
import HttpAPICall from "../../services/HttpAPICall";
import Loader from "../../components/ui/Loader/Loader"
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Status from '../../components/ui/Status';
import { INVENTORY_API_BASE_URL_2 } from '../../services/TapApiUrls';
import Ax from '../../components/hoc/Ax';

class ItemAdditionalAttributes extends React.Component {

    constructor(props) {
        super(props);
        this.state                      =   {
            item_id                         :   null,
            refreshDetail                   :   false,
            additionalAttr                  :   []
        }
        this.itemAdditionalAttrUrl      =   INVENTORY_API_BASE_URL_2 + "/item/additional_attr";
    }

    id = this.props.item_enc_id;

    componentDidMount() {
        this.setState({ item_id: this.props.item_enc_id, refreshDetail: this.props.refreshDetail })

        this.getItemDetails(this.props.item_enc_id)
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.item_enc_id !== this.props.item_enc_id || nextProps.refreshDetail == true) {
            this.setState({ item_id: nextProps.item_enc_id, refreshDetail: nextProps.refreshDetail }, () => {
                this.getItemDetails(nextProps.item_enc_id)
            });

        }

    }

    changeTab = (key) => {
        let ParamObject = new URLSearchParams(this.props.props.location.search);
        let itemName, itemCategory;
        if (ParamObject.get('item_name')) {
            itemName = ParamObject.get('item_name')
        }
        if (ParamObject.get('item_category')) {
            itemCategory = ParamObject.get('item_category')
        }
        let obj = { item_name: itemName, item_category: itemCategory }
        this.props.props.history.push({ pathname: `/item_list/${key}/${this.state.item_id}`, search: "?" + new URLSearchParams(obj).toString() })
    }

    getItemDetails = (id) => {
        this.setState({ details_loading: true });
        HttpAPICall.withAthorization('GET', this.itemAdditionalAttrUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
            this.setState({ additionalAttr: resp.data })
        }).then(() => { this.setState({ details_loading: false }) })
    }

    render() {

        let ParamObject = new URLSearchParams(this.props.props.location.search);
        let {additionalAttr, item_id} = this.state;

        let itemCategory;
        if (ParamObject.get('item_category')) {
            itemCategory = ParamObject.get('item_category')
        }

        return (<section className="item_view_container bg-white">
            <Helmet><title>Item Additional Attributes</title></Helmet>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/details/${item_id}`} role={"button"} onClick={() => this.changeTab('details')}>
                            <button className="nav-link " id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                                type="button" role="tab" aria-controls="details" aria-selected="true">Details
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/interchangeable/${item_id}`} role={"button"} onClick={() => this.changeTab('interchangeable')}>
                            <button className="nav-link " id="interchangeable-tab" data-bs-toggle="tab" data-bs-target="#interchangeable"
                                type="button" role="tab" aria-controls="interchangeable" aria-selected="false">Interchangeable Items
                            </button>
                        </Link>

                    </li>
                    {itemCategory !== "services" && this.props.category !== "services" ?
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/inventory/${item_id}`} role={"button"} onClick={() => this.changeTab('inventory')}>
                                <button className="nav-link " id="inventory-tab" data-bs-toggle="tab" data-bs-target="#inventory"
                                    type="button" role="tab" aria-controls="inventory" aria-selected="false">Inventory Levels
                                </button>
                            </Link>
                        </li> : null}
                    {itemCategory !== "services" && this.props.category !== "services" ?
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/configuration/${item_id}`} onClick={() => this.changeTab('configuration')} role={"button"}>
                                <button className="nav-link" id="config-tab" data-bs-toggle="tab" data-bs-target="#config"
                                    type="button" role="tab" aria-controls="config" aria-selected="false">
                                    Configuration
                                </button>
                            </Link>
                        </li>
                        : null}
                    <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/additional_attribute/${item_id}`}
                            role={"button"}
                            onClick={() => this.changeTab("additional_attribute")}
                        >
                            <button
                                className="nav-link active"
                                id="additional_attribute-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#additional_attribute"
                                type="button"
                                role="tab"
                                aria-controls="additional_attribute"
                                aria-selected="false"
                            >
                                Additional Attributes
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/document/${this.state.item_enc_id}`}
                            role={"button"}
                            onClick={() => this.changeTab("document")}
                        >
                            <button
                                className="nav-link "
                                id="document-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#document"
                                type="button"
                                role="tab"
                                aria-controls="document"
                                aria-selected="false"
                            >
                              Document
                            </button>
                        </Link>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/child_item/${this.state.item_enc_id}`}
                            role={"button"}
                            onClick={() => this.changeTab("child_item")}
                        >
                            <button
                                className="nav-link "
                                id="child_item-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#child_item"
                                type="button"
                                role="tab"
                                aria-controls="child_item"
                                aria-selected="false"
                            >
                               Child Items
                            </button>
                        </Link>
                    </li>
                    */}
                </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    <div className="tab-content" id="myTabContent">

                        <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                            {this.state.details_loading ? <Loader />
                                : <div className="tab_content_header">
                                    {
                                        additionalAttr.length > 0 
                                        ? 
                                        <div>
                                            <div className="tab_content_wrapper">
                                                <div>
                                                    <span className="content_heading">Additional Attribute</span>
                                                </div>
                                            </div>
                                            <table className="table table-hover table-borderless">
                                                <tbody>
                                                    {additionalAttr.map((a, k) => (<tr key={k}>
                                                        <td className="details-label text-capitalize" style={{ "width": "34%" }}>{a.name}</td>
                                                        <td className="details-name" style={{ "width": "66%" }}>
                                                            {a.value ? a.value : "-"}
                                                        </td>
                                                    </tr>))}
                                                </tbody>
                                            </table>
                                        </div> 
                                        : null
                                    }
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    }
}

export default connect(mapStateToProps)(ItemAdditionalAttributes);


