import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';
import { toast } from "react-toastify";
import TapIcon from '../../../services/TapIcon';
import TapSelect from '../../../components/ui/TapSelect';
import HttpAPICall from '../../../services/HttpAPICall';
import { l, M, v } from 'react-select/dist/index-4bd03571.esm';
import { HRM_API_BASE_URL_2, ORG_API_BASE_URL_2, SCHEDULE_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { useSelector } from 'react-redux';
import Loader from '../../../components/ui/Loader/Loader';
import TapHelper from '../../../services/TapHelper';
import DatePicker from "react-datepicker";
import moment from 'moment';

const EmployeeShiftLinkingModal     = forwardRef((props, ref) => {


    let initFormData                        =   {
        loading                                 :   false,
        submitting                              :   false,
        employee_id                             :   '',
        shifts                                  :   [],
        shift_ids                               :   [],
        from_date                               :   '',
    };

    const [modalId, setModalId]             =   useState(uuid());
    const [employeeData, setEmployeeData]   =   useState();
    const [formId, setformId]               =   useState(uuid());
    const [formData, setFormData]           =   useState({...initFormData});
    const access_token                      =   useSelector(state => state.auth.access_token);

    const modalElem                         =   useRef(null);
    const modal                             =   useRef(null);
    let formDataUrl                         =   HRM_API_BASE_URL_2 + '/employee_shift_form_data/___replace_employee_id';
    let formDataSaveUrl                     =   HRM_API_BASE_URL_2 + '/employee_shift_save';


    useImperativeHandle(ref, () => ({
        initModalHandler: (employee_id) => {
            openModalHandler(employee_id);
        }
    }));


    const openModalHandler        =    (employee_id)  =>  {
        //Get Form Data of this Activity :-
        formDataInit(employee_id);
        modalOpen();
    }

    const modalOpen       =   ()  => {
        if (modal.current) { modal.current.show(); }
    }

    const modalClose      =   ()  => {
        if (modal.current) { modal.current.hide(); }
    }

    const formDataInit             =   (employee_id)  =>  {
        setFormData(pd => ({...pd, loading : true, employee_id : employee_id}));
        HttpAPICall.withAthorization('GET', formDataUrl.replace('___replace_employee_id', employee_id), access_token, {need_employee : 'Y'}, {}, (resp) => {
            setEmployeeData(resp.data);

            let shifts          =   resp.data.shifts.map(s => { return {label : s.display, value : s.id}; });
            setFormData(pd => ({...pd, shifts : shifts }));

        }).then(() => {
            setFormData(pd => ({...pd, loading : false}));
        });
    }

    useEffect(() => {
        modalElem.current                   =   document.getElementById(modalId);
        modal.current                       =   new Modal(modalElem.current, {keyboard: false, backdrop :false});
        // Cleanup on unmount
        return () => {
            if (modal.current) {
                modal.current.dispose();
            }
        };
    }, []);


    const submitEventHandler        =   (e) => {
        e.preventDefault();
        setFormData(pd => ({...pd, submitting : true}));
        let {employee_id, shift_ids}            =   {...formData};
        HttpAPICall.withAthorization("POST", formDataSaveUrl, access_token, {}, {employee_id, shift_ids}, (response) => {
            //1 - Modal Close :-
            modalClose();
            //2 - Reset Ticket Form Data :-
            setFormData(initFormData);
            if(props.afterSucess) {
                props.afterSucess()
            }
            //4 - Show Success Message :-
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            setFormData(pd => ({...pd, submitting : false}));
        });
    }
    
    return <>
        <div className="modal fade" id={modalId} tabIndex="-1" ref={modalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Employee Shift Liking</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={formData.submitting || formData.loading} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {formData.loading ? <Loader /> : <form name='' id={formId + 'Form'} onSubmit={submitEventHandler}>
                            <div className="row align-items-center mb-3">
                                <div className="col-sm-4"><label className="form-label">Employee</label></div>
                                <div className="col-sm-8">
                                    <input type="text" disabled value={employeeData && employeeData.employee && employeeData.employee.name + " (" + employeeData.employee.employee_code + ")"} className='form-control' />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-sm-4"><label className="form-label">Current Shifts</label></div>
                                <div className="col-sm-8">
                                    {employeeData && employeeData.employee_shifts && employeeData.employee_shifts.map(es => {
                                        return <input type="text" disabled value={es && es.shift.display} className='form-control' />
                                    })}
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <div className="col-sm-4"><label className="form-label require">New Shift</label></div>
                                <div className="col-sm-8">
                                    <TapSelect 
                                        required
                                        isClearable={true}
                                        isMulti={true}
                                        placeholder="Select New Shift"
                                        options={formData.shifts}
                                        value={formData.shifts.filter(s => formData.shift_ids.includes(s.value))}
                                        changeEvent={o => setFormData(d => ({...d, shift_ids : o.map(e => e.value)}))}
                                    />
                                </div>
                            </div>
                        </form>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" disabled={formData.submitting || formData.loading} data-bs-dismiss="modal">Close</button>
                        <button form={formId + 'Form'} type="submit" disabled={formData.submitting || formData.loading} className="btn btn-primary">Save {formData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
    </>
});

export default EmployeeShiftLinkingModal;