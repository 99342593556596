import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect, useSelector } from 'react-redux';
import HttpAPICall from "../../services/HttpAPICall";
import TapSelect from '../../components/ui/TapSelect';
import Loader from "../../components/ui/Loader/Loader";
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import TapIcon from '../../services/TapIcon';
import { toast } from 'react-toastify';
import SiteProductivityModal from './SiteProductivityModal';

const NewSiteProdAdd = (props) => {
    const site_productivity_url = IAM_API_BASE_URL_2 + '/site_productivity';
    const [formData, setFormData] = useState({
        transaction_date: "",
        site_id: "",
        activities: [],
    });
    const [allSites, setAllSites] = useState([]);
    const [activity_list_loading, setActivityListLoading] = useState(false);
    const [site_loading, setSiteLoading] = useState(false);
    const [editProductivity, setEditProductivity] = useState(false);
    const [saveFormSubmitting, setSaveFormSubmitting] = useState(false);
    const accessToken = useSelector(state => state.auth.access_token);
    const siteProductivityModalRef = useRef(null);
    useEffect(() => {
        getAllSites();
    }, []);

    const getAllSites = () => {
        setSiteLoading(true);
        HttpAPICall.withAthorization('GET', site_productivity_url + '/activity_list', accessToken, {}, {}, (resp) => {
            let respData = resp.data;
            setAllSites(respData && respData.length > 0 ? respData.map(dt => ({
                value: dt.id,
                label: `${dt.activity} (Code : ${dt.activity_code})`,
                code: dt.activity_code
            })) : [])
        },

        )
        setSiteLoading(false);

    };

    const initAttendanceModal = (activityId, activityName) => {
        if (siteProductivityModalRef.current) {
            siteProductivityModalRef.current.initModalHandler(activityId, activityName);
        }
    };


    const getActivityListBySite = (siteId) => {
        setActivityListLoading(true);
        HttpAPICall.withAthorization('GET', site_productivity_url + '/activity_list', accessToken, {}, {}, (resp) => {
            let respData = resp.data;
            setFormData(prevData => ({
                ...prevData,
                activities: respData && respData.length > 0 ? respData.map(dt => ({
                    id: dt.id,
                    activity_name: dt.activity,
                    code: dt.activity_code,
                    uom: dt.uom,
                    notes: dt.notes,
                    input_value: "",
                    workstation: ""
                })) : []
            }));

            setActivityListLoading(false);
        },);
    };

    const handleInputChange = (index, field, event) => {
        const newActivities = [...formData.activities];
        newActivities[index][field] = event.target.value;
        setFormData(prevData => ({ ...prevData, activities: newActivities }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // setSaveFormSubmitting(true);
        console.log(formData);
        // if (editProductivity) {
        //     HttpAPICall.withAthorization('PUT', site_productivity_url + '/edit', accessToken, null, { ...formData }, (response) => {
        //         toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
        //         const { location } = props;
        //         if (location && location.url) {
        //             props.history.push(location.url);
        //         } else {
        //             props.history.push({ pathname: `/site_productivity`, state: { Id: response.data.id } })
        //         }

        //     }).then(setSaveFormSubmitting(false))
        // } else {
        //     HttpAPICall.withAthorization('POST', site_productivity_url + '/add', accessToken, null, { ...formData }, (response) => {
        //         toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
        //         const { location } = props;
        //         if (location && location.url) {
        //             props.history.push(location.url);
        //         } else {
        //             props.history.push({ pathname: `/site_productivity`, state: { Id: response.data.id } })
        //         }
        //     }).then(setSaveFormSubmitting(false))
        // }
    };

    const closeEvent = () => {
        props.history.push({ pathname: `/site_productivity` });
    };
    const handleModalSave = (activityId, workstation) => {
        setFormData(prevData => {
            const updatedActivities = prevData.activities.map(activity => {
                if (activity.id === activityId) {
                    return { ...activity, workstation };
                }
                return activity;
            });
            return { ...prevData, activities: updatedActivities };
        });
    };

    const saveProductivityFormJsx = () => {
        return (
            <form className="bg-white p-3" onSubmit={handleSubmit}>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="transaction_date" className="form-label require">Date of Transaction</label>
                    </div>
                    <div className="col-sm-4">
                        <DatePicker
                            selected={formData.transaction_date ? moment(formData.transaction_date, 'YYYY-MM-DD').toDate() : null}
                            name="transaction_date"
                            onChange={(value) => setFormData({ ...formData, transaction_date: moment(value).format('YYYY-MM-DD') })}
                            maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            required={true}
                            placeholderText={`Please Enter Date of Transaction`}
                        />
                    </div>
                </div>
                <div className="row align-items-center mt-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Site</label>
                    </div>
                    <div className="col-sm-4">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                if (selectedOption) {
                                    setFormData({
                                        ...formData,
                                        site_id: selectedOption.value
                                    });
                                    getActivityListBySite(selectedOption.value);
                                } else {
                                    setFormData({
                                        ...formData,
                                        site_id: "",
                                        activities: []
                                    });
                                }
                            }}
                            options={allSites}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            value={allSites.find(m => m.value === formData.site_id) || null}
                            placeholder="Please Select Site"
                        />
                    </div>
                </div>
                {/* Activity List Table */}
                {formData.site_id && (
                    <div className="table-responsive mt-4">
                        {activity_list_loading ? (
                            <Loader />
                        ) : (
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: "25%" }}>Activity Name</th>
                                        <th style={{ width: "15%" }}>Activity Code</th>
                                        <th style={{ width: "10%" }}>UOM</th>
                                        <th style={{ width: "20%" }}>Value</th>
                                        <th style={{ width: "20%" }}>Notes</th>
                                        <th style={{ width: "5%" }}>#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.activities.length > 0 ? (
                                        formData.activities.map((activity, index) => (
                                            <tr key={activity.id}>
                                                <td>{activity.activity_name || "-"}</td>
                                                <td>{activity.code || "-"}</td>
                                                <td>{activity.uom || "-"}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={activity.input_value}
                                                        className="form-control"
                                                        placeholder="Enter Value"
                                                        onChange={(e) => handleInputChange(index, 'input_value', e)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={activity.notes}
                                                        className="form-control"
                                                        placeholder="Enter Notes"
                                                        onChange={(e) => handleInputChange(index, 'notes', e)}
                                                    />
                                                </td>
                                                <td className='text-center cursor_pointer' onClick={() => initAttendanceModal(activity.id, activity.activity_name)}> + </td>

                                            </tr>
                                        ))
                                    ) : (
                                        <tr className='text-center'><td colSpan="6">No records</td></tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                )}
                <div className="col-12 text-end fixed_footer mt-4">
                    <div className='row'>
                        <div className='offset-8 col-sm-4'>
                            <button type="button" disabled={saveFormSubmitting} onClick={closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                            <button type="submit" disabled={saveFormSubmitting} className="btn btn-primary mx-2">
                                Save {saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    return (
        <ApplicationLayout>
            <Helmet><title>Site Productivity - Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{editProductivity ? "Edit" : "Add"} Site Productivity</h3>
                    <div className="text-end mt15">
                        <button onClick={closeEvent} disabled={saveFormSubmitting} type="button" className="btn btn-secondary">
                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="Close" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {site_loading ? <Loader /> : saveProductivityFormJsx()}
                    </div>
                </div>
            </div>
            <SiteProductivityModal ref={siteProductivityModalRef} transaction_date={formData.transaction_date} onSave={handleModalSave} />
        </ApplicationLayout>
    );
};

export default NewSiteProdAdd;
