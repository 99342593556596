import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';
import swal from "sweetalert";
import RunningStatusListing from '../../action/serviceChecksheet/runningStatus/RunningStatusListing';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import { toast } from 'react-toastify';
import Ax from '../../../../components/hoc/Ax';
import ScsExecutionHistoryView from '../../../transactions/scsExecutionHistory/ScsExecutionHistoryView';
import Loader from '../../../../components/ui/Loader/Loader';

class ServiceChecksheet extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                  =   {
            assetData               :       null,
            shift                   :       null,
            counterlog              :       null,
            date                    :       '',
            transaction_id          :       '',
            view_loading            :       false,
            executionData           :       null
        }
        this.serviceChecksheetModalId      =   uuid();
        this.serviceChecksheetViewId       =    uuid();
        this.executionListRef              =   React.createRef();
    }
    
    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);
        this.serviceChecksheetModal            =   new Modal(document.getElementById(this.serviceChecksheetModalId), {keyboard: false, backdrop: false}); 
        this.serviceChecksheetViewModal        =   new Modal(document.getElementById(this.serviceChecksheetViewId), {keyboard: false, backdrop: false}); 
    }
    
    componentWillReceiveProps(nextProps) {
        this.initalizeComponent(nextProps);
    }
    
    initalizeComponent                  =   (pr)  =>  {
        this.setState({access_token : pr.access_token});
    }
    
    serviceChecksheetModalInit             =   (shiftDetail, counterlog, assetData = null)  =>  {
        this.serviceChecksheetModal.show();

        this.setState({
            assetData           :   assetData,
            shift               :   shiftDetail,
            counterlog          :   counterlog,
            date                :   counterlog ? counterlog.feed_date : '',
            transaction_id      :   counterlog ? counterlog.transaction_id : '',
        });
        let form = document.getElementById(`${this.serviceChecksheetModalId}Form`)
        form.reset();
       
   }

   serviceChecksheetViewModalInit             =   (shiftDetail, counterlog, assetData = null,id)  =>  {
        this.serviceChecksheetViewModal.show();

        this.setState({
            assetData           :   assetData,
            shift               :   shiftDetail,
            counterlog          :   counterlog,
            date                :   counterlog ? counterlog.feed_date : '',
            transaction_id      :   counterlog ? counterlog.transaction_id : '',
        });

        if(id){
            this.getExecutionHistoryData(id)
        }
   
    }

    getExecutionHistoryData           =      (id)        =>        {
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/scs/execution_detail/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                executionData    :  respData.data,
            });
        
        }).then(() => this.setState({view_loading: false}));
    }

   deleteSeviceChecksheetInit        =   (transaction_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', IAM_API_BASE_URL_2 + '/scs/execution_delete/' + transaction_id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteServiceChecksheet) {
                        this.props.afterDeleteServiceChecksheet()
                    }
                }).then(() => { });
            }
        });
    }
  
    submitServiceChecksheetForm                  =   (e) =>  {
        e.preventDefault()
        this.executionListRef.current.executeModalInit(this.state.assetData,this.state.counterlog)
    } 

    afterExecutionSubmitHandler        =   ()  =>  {
     
        this.serviceChecksheetModal.hide()
        if (this.props.afterSaveServiceChecksheet) {
            let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
            this.props.afterSaveServiceChecksheet(this.state.date, asset_id)
        }

    }
    
    serviceChecksheetModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id={this.serviceChecksheetModalId} tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>Execute ServiceChecksheet for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "27%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.submitServiceChecksheetForm} id={`${this.serviceChecksheetModalId}Form`}>
                              {this.state.assetData ? 
                                <RunningStatusListing
                                    assetData={this.state.assetData ? this.state.assetData : null}
                                    ref={this.executionListRef}
                                    counterlog={this.state.counterlog ? this.state.counterlog : null}
                                    afterExecutionSubmit={(e) => this.afterExecutionSubmitHandler(e)}
                                /> : null
                              }
                                
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" className="btn btn-primary" form={`${this.serviceChecksheetModalId}Form`}>Execute </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    serviceChecksheetViewJsx              =   ()  =>  {
        return (
            <div className="modal fade" id={this.serviceChecksheetViewId} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>View ServiceChecksheet for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "34%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                            {this.state.view_loading ? <Loader />
                                : this.state.executionData ? <ScsExecutionHistoryView executionData={this.state.executionData} /> : null}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render  () {
        return (
            <Ax>
                {this.serviceChecksheetModalJsx()}
                {this.serviceChecksheetViewJsx()}
            </Ax>
        )
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(ServiceChecksheet);

