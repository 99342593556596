import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import { Modal } from "bootstrap";
import dashboardStyles from "./Dashboard.module.css"
import { Chart } from "react-google-charts";
import TapSelect from "../../components/ui/TapSelect";
import Ax from "../../components/hoc/Ax";
import Loader from "../../components/ui/Loader/Loader";
import tapIcon from "../../services/TapIcon";
import axios from "axios";
import { toast } from "react-toastify";
import errorValidation from "../../services/ErrorValidation";
import FeedbackModal from "../../layouts/modal/FeedbackModal";
import moment from "moment";
import HttpAPICall from '../../services/HttpAPICall';
import { Link } from 'react-router-dom';
import InputAssetSearch from "../includes/ui/InputAssetSearch";
import AppBaseComponent from "../../components/AppBaseComponent";
import CounterlogAgeingWidget from './widgets/CounterlogAgeingWidget'
import TicketWidget from "./widgets/TicketWidget";
import ReportQuickNavigation from './widgets/ReportQuickNavigation';
import ItemQuickNavigation from './widgets/ItemQuickNavigation';
import PurchaseConsumption from './widgets/PurchaseConsumption';
import WalletSummary from './widgets/WalletSummary';
import { Helmet } from 'react-helmet';
import RequisitionWidget from './widgets/RequisitionWidget';
import PurchaseOrder from './widgets/PurchaseOrder';
import ServiceChecksheet from './widgets/ServiceChecksheet';
import RentalStatusWidget from './widgets/rental/RentalStatusWidget';
import DueDateWidget from './widgets/rental/DueDateWidget';
import TapApiUrls from '../../services/TapApiUrls';
import * as actions from '../../store/actions/index';
import { initialize } from 'react-ga';
import ComplianceWidget from './widgets/ComplianceWidget';
import FuelSummary from './widgets/FuelSummary';
import AssetExpense from './widgets/AssetExpense';
import TaskActivity from './widgets/TaskActivity';
import WarehouseIndentWidget from './widgets/WarehouseIndentWidget';
import GroupUpdateHeader from './includes/GroupUpdateHeader';
import AssetQuickNavigation from './includes/AssetQuickNavigation';
import AssetStatus from './widgets/AssetStatus';
import TransactionDelay from './widgets/TransactionDelay';
import WarehouseIndentAgeing from './widgets/WarehouseIndentAgeing';
import RequisitionAgeing from './widgets/RequisitionAgeing';
import PoAgeingWidget from './widgets/PoAgeingWidget';
import StockMovementWarehouseWidget from './widgets/StockMovementWarehouseWidget';
import EomployeeComplianceWidget from './widgets/EomployeeComplianceWidget';
import FuelConsumptionWidget from './widgets/FuelConsumptionWidget';
import AssetWiseSegregation from './widgets/AssetWiseSegregation';
import ProductivityWidget from './widgets/ProductivityWidget';
import HgSapTaskWidget from './widgets/HgSapTaskWidget';
import SubAssemblyWidget from './widgets/SubAssemblyWidget';
import MttrMtbfWidget from './widgets/MttrMtbfWidget';
import SiteProductivityWidget from './widgets/SiteProductivityWidget';
import SummaryWidget from './widgets/SummaryWidget';
import ProductivityTargetWidget from './widgets/ProductivityTargetWidget';



class Dashboard extends AppBaseComponent {


    constructor(props) {
        super(props);

        this.initDashboardFilterForm = {
            selected_site_id: '',
            selected_warehouse_id: ''
        }

        this.state = {
            firebase_app_version: 1,
            listing_loading: false,
            assetListing: [],
            chartData: [],
            colorCode: [],
            selectedDisplay: "",
            show: false,
            searchedAssets: [],
            iam_user_sites: [],
            widget_asset_status_data: [],
            widget_asset_status_time: '',
            widget_asset_status_current_site: null,
            widget_asset_status_loading: false,
            all_periods: [],
            transaction_delay_widget_form: { period: 'current_month', filter_site_id: '' },
            transaction_delay_widget_loading: false,
            transaction_delay_widget_data: [],
            counterlog_ageing_widget_loading: false,
            counterlog_ageing_widget_data: false,
            counterlog_ageing_widget_form: { tap_site_id: '' },
            dashboardFilterForm: { ...this.initDashboardFilterForm },
            all_warehouses: [],
            dashboard_filter_site_id: '',
            dashboard_filter_warehouse_id: '',
            clearDashboardFilter: "no",
            iam_asset_types: [],
            assetStatusForm: { filter_site_id: '', search_asset_type: '' },
            loadReleaseLisitng: false,
            currentTab: 'dashboard'
        }


    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        this.setState({ firebase_app_version: this.props.firebase_app_version });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.initilaizeFormFilter(nextProps);
        }
        this.setState({ firebase_app_version: nextProps.firebase_app_version });
    }

    initilaizeFormFilter = (props) => {
        this.setState({
            iam_user_sites: props.iam_user_sites ? props.iam_user_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
            all_periods: props.all_periods ? props.all_periods.map((s) => { return { value: s.key, label: `${s.display}`, display_label: s.display_with_date } }) : [],
            all_warehouses: props.all_warehouses
                ? props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` } })
                : [],
            iam_asset_types: props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
        });
    }

    addFeedbackModalInit = () => {
        this.setState({ show: true })
    }

    closeFeedback = () => {
        this.setState({ show: false })
    }



    onDashboardFilterSubmit = (e) => {
        e.preventDefault();

        if (this.state.dashboardFilterForm && this.state.dashboardFilterForm.selected_site_id) {
            this.setState({
                // widget_asset_status_current_site    :  this.state.dashboardFilterForm.selected_site_id,
                dashboard_filter_site_id: this.state.dashboardFilterForm.selected_site_id,

            }, () => {

            })
        }

        if (this.state.dashboardFilterForm && this.state.dashboardFilterForm.selected_warehouse_id) {
            this.setState({
                dashboard_filter_warehouse_id: this.state.dashboardFilterForm.selected_warehouse_id,
            }, () => {

            })
        }

        if (this.state.dashboardFilterForm && !this.state.dashboardFilterForm.selected_site_id && !this.state.dashboardFilterForm.selected_warehouse_id && this.state.dashboard_filter_site_id || this.state.dashboard_filter_warehouse_id) {
            this.setState({
                dashboard_filter_site_id: '',
                dashboard_filter_warehouse_id: '',
                clearDashboardFilter: "yes"
            }, () => {

            })
        }
    }

    addTrainingFormInit = () => {
        this.props.history.push('/training_list');

    }



    render() {
        let wallet_permission = this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet");
        let task_permission = this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-task-workflow");
        let sap_task_permission = this.props.permissions.group_sub_modules.find(s => s.sub_module == "hg_sap");
        // this.props.history.push('/training_list');
        return (
            <ApplicationLayout>
                <Helmet><title>Dashboard</title></Helmet>
                <div className="row m-0 bg-white">
                    <div className="col-sm-12 p-0">
                        <ul className="nav nav-tabs d-flex" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/my_dashboard')} className={`nav-link`} id="mydashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="mydashboard" aria-selected="true">My Dashboard </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/dashboard')} className={`nav-link active`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard </button>
                            </li>


                            <li className="nav-item ms-auto" role="presentation">
                                <button onClick={() => this.props.history.push('/new_releases')}
                                    className={`nav-link`} id="release-tab" data-bs-toggle="tab" data-bs-target="#release" type="button" role="tab" aria-controls="release" aria-selected="true">New Releases</button>
                            </li>
                            <li className="nav-item " role="presentation">
                                <button onClick={() => this.props.history.push('/help/all_tutorials')}
                                    className={`nav-link`} id="help-tab" data-bs-toggle="tab" data-bs-target="#help" type="button" role="tab" aria-controls="help" aria-selected="true">Help & Tutorial </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.addFeedbackModalInit()}
                                    className={`nav-link`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="true">FeedBack</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/training_list')}
                                    className={`nav-link`} id="training-tab" data-bs-toggle="tab" data-bs-target="#training" type="button" role="tab" aria-controls="training" aria-selected="true">Schedule a Training</button>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane  show active" id="home" role="tabpanel" aria-labelledby="dashboard-tab">
                        <div className="container-fluid p4" >
                            <div className={dashboardStyles.tab_content_header}>
                                <GroupUpdateHeader />
                                <hr className="m0 mt10" />
                                <div className="row">
                                    <div className="col-md-8">
                                        <AssetStatus indexProps={2} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                        <ServiceChecksheet indexProps={4} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />


                                        <SubAssemblyWidget indexProps={45} dashboard_search_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />

                                        {task_permission ? <TaskActivity indexProps={6} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} /> : null}
                                        {sap_task_permission && <HgSapTaskWidget indexProps={44} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />}
                                        {this.props.permissions && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-rental")
                                            ? <Ax>
                                                <RentalStatusWidget indexProps={8} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                                <DueDateWidget indexProps={10} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                            </Ax> : null}

                                            <TransactionDelay indexProps={12} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                      
                                        <CounterlogAgeingWidget indexProps={14} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                        <TicketWidget indexProps={16} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                        {this.props.permissions.group_modules.includes("isc")
                                            ? <Ax>
                                                {/* <AssetExpense/> */}
                                                <PurchaseConsumption indexProps={18} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                                <StockMovementWarehouseWidget indexProps={20} />
                                                <WarehouseIndentWidget indexProps={22} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                                <WarehouseIndentAgeing indexProps={24} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                                <RequisitionWidget indexProps={26} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                                <RequisitionAgeing indexProps={28} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                                <PurchaseOrder indexProps={30} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                                <PoAgeingWidget indexProps={32} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                            </Ax>
                                            : null}

                                        <ComplianceWidget indexProps={34} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                        {this.props.permissions && this.props.permissions.permissions && this.props.permissions.permissions.includes('hrm_compliance_view') && <EomployeeComplianceWidget indexProps={36} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />}
                                        <FuelConsumptionWidget indexProps={38} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                        <AssetWiseSegregation indexProps={17} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />

                                        <ProductivityWidget indexProps={39} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                        <MttrMtbfWidget indexProps={51} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                        {this.props.permissions && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-site-productivity")
                                            ? <Ax>
                                                <SiteProductivityWidget indexProps={52} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                                <ProductivityTargetWidget indexProps={53} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
                                            </Ax>
                                            : null}
                                        {/* <SummaryWidget indexProps={53} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} /> */}
                                        {!wallet_permission
                                            ? null
                                            : <WalletSummary indexProps={40} />}
                                        <FuelSummary indexProps={42} />
                                    </div>
                                    <div className="col-md-4">
                                        <AssetQuickNavigation props={this.props} />

                                        {this.props.permissions.group_modules.includes("isc")
                                            ? <ItemQuickNavigation props={this.props} />
                                            : null}
                                        <ReportQuickNavigation props={this.props} />
                                        <div className="card my-3">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h6 className="fw-bold primary_color">Dashboard Filters</h6>
                                                    </div>
                                                </div>
                                                <form onSubmit={this.onDashboardFilterSubmit}>
                                                    <div className="row">
                                                        <div className="col-12 mt-3">
                                                            <TapSelect
                                                                options={this.state.iam_user_sites}
                                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dashboardFilterForm', 'selected_site_id')}
                                                                value={this.state.iam_user_sites.find(s => s.value == this.state.dashboardFilterForm.selected_site_id)}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                placeholder="All Sites"
                                                                containerHeight="33px"
                                                                fontSize="93%"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-sm-9 pr0">
                                                            <TapSelect
                                                                options={this.state.all_warehouses}
                                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dashboardFilterForm', 'selected_warehouse_id')}
                                                                value={this.state.all_warehouses.find(s => s.value == this.state.dashboardFilterForm.selected_warehouse_id)}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                placeholder="All Warehouses"
                                                                containerHeight="33px"
                                                                fontSize="93%"
                                                            />
                                                        </div>
                                                        <div className="col-sm-3 text-end">
                                                            <button type="submit" className="btn btn-primary" style={{ height: "38px" }}>Go</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        {/* <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className={`${dashboardStyles.card_border} col-12`}>
                                                                <h6 className="fw-bold primary_color">Reports</h6>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-9 mt-3 pr0">
                                                                <TapSelect
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    placeholder="Please Select Report"
                                                                    required={true}
                                                                />
                                                            </div>
                                                            <div className="col-3 text-end mt-3 pl0">
                                                                <button onClick={this.closeMinimizeTable} type="button" className="btn btn-primary">
                                                                    Go</button>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                             <div className="col-12">
                                                                <h6 className="fw-bold mt-2"> Description:-</h6>
                                                                 <small>Please select report section & report to navigate on that report.</small>
                                                             </div>
                                                         </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>*/}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.show === true ? <FeedbackModal close={this.closeFeedback} /> : null}
            </ApplicationLayout>
        )
    }

}

const mapStateToProps = state => {

    return {
        access_token: state.auth.access_token,
        user_name: state.app.user_info?.name ?? '',
        group_info: state.app.group_info ? state.app.group_info : null,
        group_name: state.app.group_info ? state.app.group_info.group_name : '',
        iam_user_sites: state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods: state.app && state.app.all_periods ? state.app.all_periods : [],
        permissions: state.app && state.app.acl_info ? state.app.acl_info : [],
        firebase_app_version: state.app && state.app.firebase_app_version ? state.app.firebase_app_version : 1,
        all_warehouses: state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_asset_types: state.app && state.app.asset_type ? state.app.asset_type : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading: (access_token) => dispatch(actions.applicationInitLoading(access_token, false)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);