import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import ItemViewInList from '../includes/ItemViewInList';
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import ConsumptionHistoryModal from './ConsumptionHistoryModal';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';
import AllocationDetail from '../allocation/AllocationDetail';
import { Link } from 'react-router-dom';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import TicketDetailModal from '../../transactions/ticketTransaction/TicketDetailModal';
import ItemDemandDetail from '../itemDemand/ItemDemandDetail';

class ConsumptionDetailsModal extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            consumptionViewData: null,
            view_loading: false,
            itemDetails: null,
            allocationId: '',
            demandId: '',
            setting: null,
        };
        this.consumptionHistoryRef = React.createRef();
        this.itemDetailsRef = React.createRef();
        this.ticketDetailRef = React.createRef();
        this.CONSUMPTION_URL = INVENTORY_API_BASE_URL_2 + '/consumption';
    }

    componentDidMount() {
        if (this.props && this.props.consumptionId) {
            this.getConsumptionViewDetails(this.props.consumptionId)
        }
        if (this.props && this.props.consumptionData) {
            this.setState({ consumptionViewData: this.props.consumptionData })
        }
        this.demandModalView = new Modal(document.getElementById('demandModalView'), { keyboard: false, backdrop: false });
        this.viewItemModal = new Modal(document.getElementById('viewItemModal'), { keyboard: false, backdrop: false });
        this.viewAllocationModal = new Modal(document.getElementById('viewAllocationModal'), { keyboard: false, backdrop: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.consumptionId !== this.props.consumptionId) {
            this.getConsumptionViewDetails(nextProps.consumptionId)
        }
        if (nextProps.consumptionData !== this.props.consumptionData) {
            this.setState({ consumptionViewData: nextProps.consumptionData })
        }

    }


    getConsumptionViewDetails = (transaction_id) => {
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', this.CONSUMPTION_URL + '/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                consumptionViewData: respData.data,
                consumptionLoaded: false,
                setting: resp.data && resp.data.setting ? resp.data.setting : null,
            });

        }).then(() => this.setState({ view_loading: false }));

    }

    viewConsumptionModalInit = (data, k) => {
        if (this.state.consumptionViewData) {
            if (this.state.consumptionViewData.assetProfile || this.state.consumptionViewData.workstation) {
                let consumptionData = this.state.consumptionViewData;

                if (data) {
                    this.consumptionHistoryRef.current.modalInit(data, consumptionData?.workstation?.id, consumptionData?.assetProfile?.asset_id, consumptionData.consumption_type)
                }
            } else {
                toast.error('Please Select Asset/Workstation first', { position: toast.POSITION.TOP_RIGHT });
            }
        }

    }


    viewItemModalInit = (item) => {
        if (item) {
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    viewAllocationModalInit = (id) => {
        if (id) {
            this.viewAllocationModal.show();
            this.setState({ allocationId: id })
        }
    }

    getTicketDetail = (id) => {
        this.ticketDetailRef.current.ticketModalInit(id)
    }

    demandModalViewInit = (id) => {
        this.setState({
            demandId: id
        }, () => {
            setTimeout(() => this.demandModalView.show(), 1000)
        })

    }

    demandDetailsModalJsx = () => {
        return (
            <div className="modal fade" id="demandModalView" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Item Demand Detail </h5>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                <ItemDemandDetail demandId={this.state.demandId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    allocationDetailsModalJsx = () => {
        return (
            <div className="modal fade" id="viewAllocationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Allocation Detail </h5>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                <AllocationDetail allocationId={this.state.allocationId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        let consumptionDetails = this.state.consumptionViewData;
        let setting = this.state.setting ? this.state.setting : null;
        return (<Ax>
            {this.state.view_loading ? <Loader />
                : consumptionDetails ? <div>
                    <table className="table table-bordered bg-white">
                        <thead className="table-secondary">
                            <tr>
                                <th style={{ width: "33%" }}>ID</th>
                                <th style={{ width: "33%" }}>Date</th>
                                <th style={{ width: "33%" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{consumptionDetails.transaction_id}</td>
                                <td scope="col">{consumptionDetails.transaction_date_display}</td>
                                <td scope="col" className="align-middle">{consumptionDetails.status_data ? consumptionDetails.status_data.name : "-"}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper" >
                        <span className="content_heading">Consumption Detail</span>
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-3">
                        <tbody>
                            <tr>
                                <td style={{ width: "33%" }} >Warehouse</td>
                                <td colSpan={2}>{consumptionDetails.warehouse_detail ? <div><b>{consumptionDetails.warehouse_detail.name} ({consumptionDetails.warehouse_detail.code})</b> <br />
                                    {consumptionDetails.warehouse_detail ?
                                        <div className='pt-1'>Address : {consumptionDetails.warehouse_detail.address1 ? consumptionDetails.warehouse_detail.address1 + ", " : ""}
                                            {consumptionDetails.warehouse_detail.address2 ? consumptionDetails.warehouse_detail.address2 + ", " : ""}
                                            {consumptionDetails.warehouse_detail.landmark ? consumptionDetails.warehouse_detail.landmark + ", " : ""}
                                            {consumptionDetails.warehouse_detail.city ? consumptionDetails.warehouse_detail.city + ". " : "-"} <br />
                                            Destination of Supply : <b>{consumptionDetails.warehouse_detail.state_name}</b> <br />
                                            GSTIN  : {consumptionDetails.warehouse_gstin ?? "-"} <br />
                                        </div> : "-"}</div> : "-"} <br />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }} >
                                    {consumptionDetails.consumption_type === 'asset' && "Asset"}
                                    {consumptionDetails.consumption_type === 'workstation' && 'Workstation'} {consumptionDetails.consumption_type === 'sub_assembly' && 'Sub Assembly'}
                                    {consumptionDetails.consumption_type === 'employee' && 'Employee'}
                                </td>
                                <td colSpan={2}>
                                    {consumptionDetails.consumption_type === 'asset' &&
                                        <div className='text-capitalize'><b>{
                                            consumptionDetails.assetProfile.name} ({consumptionDetails.assetProfile?.asset_code ?? "-"})</b> <br />
                                            Asset Type (Asset Matrix) : {consumptionDetails.assetProfile.asset_type_name ?? "-"} ({consumptionDetails.assetProfile.asset_matrix_unique_id ?? "-"}) <br />
                                            Manufacturer (Model) : {consumptionDetails.assetProfile.manufacturer_name ?? "-"} ({consumptionDetails.assetProfile.model_name ?? "-"})<br />
                                            <br />
                                        </div>
                                    }
                                    {
                                        consumptionDetails.consumption_type === 'workstation' &&
                                        <div><b>{consumptionDetails?.workstation?.workstation_name} ({consumptionDetails?.workstation?.workstation_nickname ?? "-"})</b>
                                        </div>
                                    }

                                    {
                                        consumptionDetails.consumption_type === 'sub_assembly' &&
                                        <div><b>{consumptionDetails?.subAssembly?.name} ({consumptionDetails?.subAssembly?.code ?? "-"})</b>
                                        </div>
                                    }
                                    {
                                        consumptionDetails.consumption_type === 'employee' &&
                                        <div><b>{consumptionDetails?.employee?.display_full_name}</b><br />
                                            Mobile Number : {consumptionDetails?.employee?.mobile_display}<br />
                                            Status : <Status color={consumptionDetails?.employee?.status_color}>{consumptionDetails?.employee?.status_text}</Status>
                                        </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Ticket</td>
                                <td colSpan={2} >{consumptionDetails && consumptionDetails.ticket ? <Status color={consumptionDetails.ticket.status_color_code}> <Link onClick={() => this.getTicketDetail(consumptionDetails.ticket.activity_id)}>{consumptionDetails.ticket.activity_id} : {consumptionDetails.ticket.header}</Link> </Status> : "-"}</td>
                            </tr>
                            {consumptionDetails.setting && consumptionDetails.setting.isc_no_con_vourcher_number_date && consumptionDetails.setting.isc_no_con_vourcher_number_date == 'Y' && (<>
                                <tr>
                                    <td style={{ width: "33%" }} >Voucher Number & Date</td>
                                    <td style={{ width: "33%" }} >{consumptionDetails.voucher_number ? consumptionDetails.voucher_number : "-"}</td>
                                    <td style={{ width: "34%" }} >{consumptionDetails.voucher_date_display ? consumptionDetails.voucher_date_display : "-"}</td>
                                </tr>
                            </>)}

                        </tbody>
                    </table>
                    {consumptionDetails.transaction_type == "purchase_consumption" && consumptionDetails.purchase
                        ? <Ax>
                            <div className="tab_content_wrapper" >
                                <span className="content_heading">Purchase Detail</span>
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-3 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >Vendor</td>
                                        <td colSpan={2} >{consumptionDetails.purchase.vendor ? <div><b>{consumptionDetails.purchase.vendor.name} ({consumptionDetails.purchase.vendor.code})</b> <br />
                                            {consumptionDetails.purchase.vendor_address ?
                                                <div className='pt-1'>Address : {consumptionDetails.purchase.vendor_address.address1 ? consumptionDetails.purchase.vendor_address.address1 + ", " : ""}
                                                    {consumptionDetails.purchase.vendor_address.address2 ? consumptionDetails.purchase.vendor_address.address2 + ", " : ""}
                                                    {consumptionDetails.purchase.vendor_address.landmark ? consumptionDetails.purchase.vendor_address.landmark + ", " : ""}
                                                    {consumptionDetails.purchase.vendor_address.city ? consumptionDetails.purchase.vendor_address.city + ". " : "-"} <br />
                                                    Source of Supply : <b>{consumptionDetails.purchase.vendor_address.state_name}</b> <br />
                                                    GSTIN  : {consumptionDetails.purchase.vendor_gstin ?? "-"}</div> : "-"}</div> : "-"}</td>


                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }}>Bill Number & Date</td>
                                        <td style={{ width: "33%" }}>{consumptionDetails.purchase.purchase_bill_number ? consumptionDetails.purchase.purchase_bill_number : "-"}</td>
                                        <td style={{ width: "34%" }}>{consumptionDetails.purchase.purchase_bill_date ? consumptionDetails.purchase.purchase_bill_date_display : "-"}</td>
                                    </tr>
                                    {/* <tr>
                                <td style={{ width: "33%" }}>PO Number & Date</td>
                                <td style={{ width: "33%" }}>PON12321</td>
                                <td style={{ width: "34%" }}>08-Feb-2022</td>
                            </tr> */}

                                </tbody>
                            </table>
                        </Ax>
                        : null
                    }

                    <div className="tab_content_wrapper" >
                        <span className="content_heading">Item Detail</span>
                    </div>
                    <table className="table table-sm table-bordered table-responsive bg-white mt-3">
                        <thead className="table-secondary">
                            <tr>
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" className="text-center" style={{ width: "35%" }}>Item Details</th>
                                {this.props?.isc_configuration?.track_batch_expiry == 'Y' ? <Ax>
                                    <th scope="col" className="text-center" style={{ width: "10%" }}>Batch Number</th>
                                    <th scope="col" className="text-center" style={{ width: "10%" }}>Expiry Date</th>
                                </Ax> : null}
                                <th scope="col" className="text-center" style={{ width: "8%" }}>Demand ID</th>
                                <th scope="col" className="text-center" style={{ width: "8%" }}>Allocation</th>
                                <th scope="col" className="text-end" style={{ width: "8%" }}>Quantity</th>
                                <th scope="col" className="text-end" style={{ width: "8%" }}>Unit Rate</th>
                                <th scope="col" className="text-end" style={{ width: "8%" }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                consumptionDetails && consumptionDetails.items && consumptionDetails.items.length > 0 ? consumptionDetails.items?.map((i, k) => {
                                    let item_amount = parseFloat(i.qty * i.rate);
                                    return (<tr key={k}>
                                        <td className='text-center'>{k + 1}</td>
                                        <td>{i.item_profile ? <div className='row'>
                                            <div className='col-sm-10'>
                                                <ItemViewInList item={i.item_profile} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.item_profile)} />
                                            </div>
                                            <div className='col-sm-2 text-end'>
                                                <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu">
                                                    <li><a href="#" className="dropdown-item" onClick={() => this.viewConsumptionModalInit(i.item_profile, k)}>Consumption History</a></li>
                                                </ul>
                                            </div>
                                        </div> : null}</td>
                                        {this.props?.isc_configuration?.track_batch_expiry == 'Y' ? <Ax>
                                            <td className="text-center" >{i.batch_number ? i.batch_number : "-"}</td>
                                            <td className="text-center" >{i.expiry_date_display ? i.expiry_date_display : "-"}</td>
                                        </Ax> : null}
                                        <td className="text-center" >{i.demand_transaction_id ? <Link onClick={() => this.demandModalViewInit(i.demand_transaction_id)}>{i.demand_transaction_id}</Link> : "-"}</td>
                                        <td className="text-center" >{i.allocation_transaction_id ? <Link onClick={() => this.viewAllocationModalInit(i.allocation_transaction_id)}>{i.allocation_transaction_id}</Link> : "-"}</td>
                                        <td className="text-end" >{i.qty ? i.qty : "-"}</td>
                                        <td className="text-end" >{i.rate ? i.rate : "-"}</td>
                                        <td className='text-end'><CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(item_amount).toFixed(2)} /></td>
                                    </tr>)

                                }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                            }
                        </tbody>
                    </table>
                    <div className="row align-items-center mb-5">
                        <div className="col-6">
                            <label htmlFor="notes" className="form-label">Notes</label>
                            <div>{consumptionDetails.notes ?? "-"}</div>

                        </div>
                        <div className="col-6">
                            <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                            <table className="table  table-borderless">
                                <tbody>
                                    <tr className="subtotal_bg_color">
                                        <th>Sub-Total</th>
                                        <td className='float-end'>{consumptionDetails.total_amount ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(consumptionDetails.total_amount - (consumptionDetails.adjustment_amount)).toFixed(2)} /> : 0.00}</td>
                                    </tr>
                                    <tr className="subtotal_bg_color">
                                        <th>Adjustment</th>
                                        <td className='float-end'>{consumptionDetails.adjustment_amount ?? 0}</td>
                                    </tr>
                                    <tr className="subtotal_bg_color">
                                        <th>Final Total</th>
                                        <td className='float-end'>{consumptionDetails.total_amount ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={consumptionDetails.total_amount} /> : 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ConsumptionHistoryModal ref={this.consumptionHistoryRef} />
                    <ItemDetailsModal ref={this.itemDetailsRef} />
                    <TicketDetailModal ref={this.ticketDetailRef} />
                </div>

                    : null}
            {this.allocationDetailsModalJsx()}
            {this.demandDetailsModalJsx()}
        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency: state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps)(ConsumptionDetailsModal);