import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import * as actions from '../../../store/actions/index';
import TapIcon from '../../../services/TapIcon';

class ItemWhConfigModel extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                      =   {
            access_token                :   '',
            saveFormSubmitting          :   false,
            addConfigForm               :   [],
            tableActiveClass            :   false,
            classKey                    :   null,
        }
        this.saveItemWhUrl    =   INVENTORY_API_BASE_URL_2 + '/item_config/add';

    }

    componentDidMount                   =   ()  =>  {
        this.addConfigModal             =   new Modal(document.getElementById('addConfigModal'), {keyboard: false, backdrop :false});
    }

    addConfigInit                       =   (data = null)  =>  {
        this.setState({
            addConfigForm : [],
        }, () => {
            this.setState({addConfigForm : data});
            this.addConfigModal.show();
        });
    }

    submitConfigFormHandler = (e) => {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('POST', this.saveItemWhUrl, this.props.access_token, {}, { "data": this.state.addConfigForm }, (res) => {
            this.addConfigModal.hide();
            this.props.afterFormSubmit();
            this.setState({ addConfigForm: [] })
        }).then(() => this.setState({ saveFormSubmitting: false}));
    }  
    
    tableActive         =   (k) =>  {
        this.setState({
            tableActiveClass    :   true,
            classKey            :   k,
        });
    }

    addConfigModalJsx = () => {
        return (<div className="modal fade" id="addConfigModal" tabIndex="-1">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addConfigModalLabel">Item Warehouse Configuration</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body py-0 my-0">
                        <form id="addConfigModalForm" onSubmit={this.submitConfigFormHandler}>
                            <table className="table table-hover table-bordered table-sm">
                                <thead>
                                    <tr className="sticky-top table-secondary">
                                        <th style={{width: "30%"}}>Warehouse</th>
                                        <th style={{width: "15%"}}>Bin Location</th>
                                        <th style={{width: "10%"}}>Max Level</th>
                                        <th style={{width: "10%"}}>Min Level</th>
                                        <th style={{width: "10%"}}>Re-order Level</th>
                                        <th style={{width: "15%"}}>Replenishment Period</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.addConfigForm.map((i, k) => {
                                        return <tr className={(this.state.tableActiveClass && k === this.state.classKey) ? "table-active" : null} key={k}>
                                            <th>{i.warehouse_name}</th>
                                            <td>
                                                <input
                                                    name="bin_location"
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onClick={() => this.tableActive(k)}
                                                    placeholder="Bin Location"
                                                    value={this.state.addConfigForm[k].bin_location}
                                                    onChange={e => this.dynamicInputHandlerByKeyNameVal(e.target.value,'bin_location',k, 'addConfigForm')}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name="max_inventory_level"
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onClick={() => this.tableActive(k)}
                                                    placeholder="Max Level"
                                                    value={this.state.addConfigForm[k].max_inventory_level}
                                                    onChange={e => this.dynamicInputHandlerByKeyNameVal(e.target.value,'max_inventory_level',k, 'addConfigForm')}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name="min_inventory_level"
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onClick={() => this.tableActive(k)}
                                                    placeholder="Min Level"
                                                    value={this.state.addConfigForm[k].min_inventory_level}
                                                    onChange={e => this.dynamicInputHandlerByKeyNameVal(e.target.value,'min_inventory_level',k, 'addConfigForm')}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name="re_order_level"
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onClick={() => this.tableActive(k)}
                                                    placeholder="Re-order Level"
                                                    value={this.state.addConfigForm[k].re_order_level}
                                                    onChange={e => this.dynamicInputHandlerByKeyNameVal(e.target.value,'re_order_level',k, 'addConfigForm')}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name="replenishment_period"
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onClick={() => this.tableActive(k)}
                                                    placeholder="Replenishment Period"
                                                    value={this.state.addConfigForm[k].replenishment_period}
                                                    onChange={e => this.dynamicInputHandlerByKeyNameVal(e.target.value,'replenishment_period',k, 'addConfigForm')}
                                                />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                        <button type="submit" form="addConfigModalForm" className="btn btn-primary">Submit {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>)
    }
    
    render                          =   ()  =>  {
        return <Ax>{this.addConfigModalJsx()}</Ax>
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading  :   (access_token)  =>  dispatch(actions.applicationInitLoading(access_token,false)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ItemWhConfigModel);