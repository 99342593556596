import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import TapSelect from "../../../components/ui/TapSelect";
import { Modal } from 'bootstrap';
import ConsumptionDetailsModal from '../../inventory/consumption/ConsumptionDetailsModal';
import PurchaseDetail from '../../inventory/purchase/PurchaseDetail';
import InventoryTransferDetail from '../../inventory/inventoryTransfer/InventoryTransferDetail';
import InventoryAdjustmentDetail from '../../inventory/inventoryAdjustment/InventoryAdjustmentDetail';
import PurchaseReturnDetail from '../../inventory/purchaseReturn/PurchaseReturnDetail';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ItemWiseTransactionLedger extends AppBaseComponent {
    
    constructor(props) {
        super(props);
                
        this.reportFilterFormInitialState   =   {
            warehouse_id                        :   '',
            item_id                             :   '',
            transaction_type                    :   [],
            search_batch_number                 :   '',
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
        };
        
        this.reportFilterFormLabel          =   {
            warehouse_id                        :   'Search Warehouse : ',
            item_id                             :   'Search Item : ',
            date_range                          :   'Date Range : ',
            search_batch_number                 :   'Search Batch Number : ',
            transaction_type                    :   'Search Transaction Type : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            isc_configuration                   :   {},
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   null,
            listingData                         :   [1,2],
            listingMeta                         :   null,
            searchedAsset                       :   [],
            all_periods                         :   [],
            allStockTransactions                :   [],
            summaryData                         :   null,
            summary_loading                     :   false,
            transactionId                       :   "",
            transanctionType                    :   "",
            download_access                     :   false,  
            reportData                          :   null                   
        };

        this.reportUrl      =   INVENTORY_API_BASE_URL_2 + '/report/item-stock-ledger';
        this.formDataUrl    =   INVENTORY_API_BASE_URL_2 +'/report/item-stock-ledger/form_data'

        this.transactionSummaryUrl  =   INVENTORY_API_BASE_URL_2 + '/report/item-stock-ledger/summary';
        this.transactionListingUrl  =   INVENTORY_API_BASE_URL_2 + '/report/item-stock-ledger/list';

    }
    
    componentDidMount(){
       this.initalizeData(this.props)
       this.viewTransactionModal              =     new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.periods !== this.props.periods) {
            this.initalizeData(nextProps)
         }     
    }


    initalizeData       =       (pr)        =>      {
        const report_name   =   'item_stock_transaction_ledger';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        
        if(pr){
            let periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}];
            this.setState({
                all_periods :   periods,
            })
            
            
            HttpAPICall.withAthorization('GET', this.formDataUrl, pr.access_token, null, null, (response) => {
                this.setState({
                    reportData                  :   reportData.length > 0 ? reportData[0] : null,
                    download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                    all_warehouses              :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    allStockTransactions        :   response.data && response.data.stock_transactions && response.data.stock_transactions.length > 0 ? response.data.stock_transactions.map((m) => { return { value: m.key, label: `${m.name}` }}) : [],
                    formDataLoaded              :   true,
                },() => {
                    let ParamObject                 =   new URLSearchParams(this.props.location.search);
                    if ( ParamObject.get('search_item_ids')) {
                        let updateFilterForm        =        {date_range : "current_month"};
            
                        if (ParamObject.get('search_item_ids')) {    
                            updateFilterForm['search_item_ids']          =          [ParamObject.get('search_item_ids')];
                            updateFilterForm['item_id']                  =          ParamObject.get('search_item_ids');   
                        }

                        setTimeout(() => {
                            this.setState({
                                reportFilterForm               :        { ...this.state.reportFilterForm, ...updateFilterForm },
                                searchedItem                  :        {value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }
                            }, () => {
                                this.reportFilterhandler()
                                
                            })
                        }, 1000)
                    } else{
                        let data = {};
                        if (pr.location && pr.location.state && pr.location.state.warehouses_id && pr.location.state.date_range && pr.location.state.item_id) {
                            data.warehouse_id       =   pr.location.state.warehouses_id;
                            data.date_range         =   pr.location.state.date_range;
                            data.date_range_start   =   pr.location.state.date_range_start;
                            data.date_range_end     =   pr.location.state.date_range_end;
                            data.item_id            =   pr.location.state.item_id;
                            this.setState({
                                reportFilterForm    : {...this.state.reportFilterForm, ...data},
                                searchedItem        : {value : pr.location.state.item_id, display_label: pr.location.state.item_name}
                            }, ()=>{
                                this.reportFilterFormSubmit();
                            });
                        }else{
                            this.reportFilterhandler()
                        }

                    }
                }) ; 
            }, (err) => {

            });
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'warehouse_id') {
                        show_val            =   this.state.all_warehouses.filter((s) => value == s.value).map(s => s.label); 
                    }
                    if(key == 'item_id') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.display_label : '';
                    }
                    if(key == 'search_batch_number') {
                        show_val            =  this.state.reportFilterForm.search_batch_number;
                    }
                    if(key == 'search_date') {
                        show_val            =  moment(this.state.reportFilterForm.search_date).format("DD-MMM-YYYY");
                    }
                    if(key == 'transaction_type') {
                        show_val            =   this.state.allStockTransactions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.loadListingTblData(1);
        });
    }


    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false,
            summaryData                 :   null,
            summary_loading             :   true,
        });
        let params                       =   {...this.state.submittedReportFilterForm};   
        //Api to call Summary 
        HttpAPICall.withAthorization('GET', this.transactionSummaryUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                summaryData                 :   respData,
                reportDownloadBtn           :   true,
            });
        }).then(() => {
            this.setState({summary_loading: false});
        });


        this.loadListingOnPageClick(page)
    }

    loadListingOnPageClick                  =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false,
        });
        let params                       =   {page:page ,...this.state.submittedReportFilterForm};   
        //Api to call Lisitng 
        HttpAPICall.withAthorization('GET', this.transactionListingUrl, this.props.access_token, params,{},(response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    viewTransactionModalInit                =   (transaction_type,transaction_id)       =>      {
       this.setState({transanctionType : transaction_type,transactionId : transaction_id},
        () => this.viewTransactionModal.show())
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl + '/download', this.props.access_token, {...this.state.submittedReportFilterForm} , {} , (response) => 
        DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || !this.state.download_access} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse"  aria-expanded="true" >
            <div className={['p-3', 'filter_form'].join(' ')}>
                <form onSubmit={this.reportFilterFormSubmit} >

                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end  require">Item</label>
                        <div className="col-sm-6">
                            <InputItemSearch
                                placeholder="Search Item"
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'reportFilterForm', 'item_id');
                                    this.setState({
                                        searchedItem: selectedOption ? selectedOption : null
                                    }, () => { });
                                }}
                                required={true}
                                containerHeight="30px"
                                fontSize="93%"
                                value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                            />
                        </div>
                    </div>
                    <div className="row my-3 align-items-center">
                        <label className="col-sm-3 col-form-label text-end ">Item Batch Number</label>
                        <div className="col-sm-6">
                            <input
                                name="search_batch_number"
                                type="text"
                                value={this.state.reportFilterForm.search_batch_number}
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                                placeholder="Enter Batch Number"
                                style={{ height: "30px", fontSize: "93%" }}
                            />
                        </div>
                    </div>
                    <div className="row my-3 align-items-center">
                        <label className="col-sm-3 col-form-label text-end require">Warehouse</label>
                        <div className="col-sm-6">
                            <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'warehouse_id')}
                                options={this.state.all_warehouses}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                value={this.state.all_warehouses.find(m => m.value == this.state.reportFilterForm.warehouse_id)}
                                placeholder="Please Select Warehouse"
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label text-end ">Transaction Type</label>
                        <div className="col-sm-6">
                            <TapSelect
                                options={this.state.allStockTransactions}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'transaction_type')}
                                value={this.state.allStockTransactions.filter(u => this.state.reportFilterForm?.transaction_type?.includes(u.value))}
                                isSearchable={true}
                                isMulti={true}
                                isClearable={true}
                                placeholder="Select Transaction Type"
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end require">Select Period</label>
                        <div className="col-sm-6">
                            <PeriodSelectionWithCustom
                                periods={this.state.all_periods ? this.state.all_periods : []}
                                value={this.state.reportFilterForm.date_range}
                                startDate={this.state.reportFilterForm.date_range_start}
                                endDate={this.state.reportFilterForm.date_range_end}
                                required={true}
                                onSelectPeriod={(period, startDate, endDate) => {
                                    this.setState({
                                        reportFilterForm
                                            : {
                                            ...this.state.reportFilterForm,
                                            date_range: period,
                                            date_range_start: startDate ? startDate : this.state.reportFilterForm.date_range_start,
                                            date_range_end: endDate ? endDate : this.state.reportFilterForm.date_range_end
                                        }
                                    });
                                }}
                                className="form-control-sm"
                                containerHeight="30px"
                                fontSize="93%"
                                menuPlacement="bottom"

                            />
                        </div>
                    </div>
                    <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
                    
                </form>
                

            </div>

        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div >
            {this.state.summary_loading
                ? <Loader />
                : (this.state.summaryData ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
       
        return (<Ax>
            <div className="px-2 bg-white">

                <div className="pb0 pt0">
                    <div className='row'>
                        <div className='col-12'>
                            <table className='table table-borderless align-middle bg-white'>
                                {this.state.summary_loading
                                    ? <Loader /> : <tbody>
                                        <tr>
                                            <td style={{ width: "10%" }}>Item : </td>
                                            <th style={{ width: "40%" }}>{this.state.summaryData && this.state.searchedItem ? this.state.searchedItem.display_label : '-'}</th>
                                            <td style={{ width: "20%" }} className='text-end'>Opening Stock : </td>
                                            <th style={{ width: "30%" }} className='text-end'>
                                                {this.state.summaryData && this.state.summaryData.opening_stock ? this.state.summaryData.opening_stock : 0} </th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "20%" }}>Warehouse : </td>
                                            <th style={{ width: "30%" }}>{this.state.summaryData && this.state.all_warehouses && this.state.all_warehouses.length > 0 ? this.state.all_warehouses.filter((s) => this.state.reportFilterForm?.warehouse_id == s.value).map(s => s.label) : '-'}</th>
                                            <td style={{ width: "20%" }} className='text-end'>Total Received : </td>
                                            <th style={{ width: "30%" }} className='text-end'>  {this.state.summaryData && this.state.summaryData.total_received ? this.state.summaryData.total_received : 0} </th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "20%" }} >Transaction Type :</td>
                                            <th style={{ width: "30%" }}>{this.state.summaryData && this.state.allStockTransactions ? this.state.allStockTransactions.filter((s) => this.state.reportFilterForm?.transaction_type.includes(s.value)).map(s => s.label).join(', ') : "-"}</th>
                                            <td style={{ width: "20%" }} className='text-end'>Total Issued : </td>
                                            <th style={{ width: "30%" }} className='text-end'>{this.state.summaryData && this.state.summaryData.total_issued ? this.state.summaryData.total_issued : 0}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "20%" }} >Period :</td>
                                            <th style={{ width: "30%" }} >{this.state.summaryData && this.state.summaryData.period ? this.state.summaryData.period.display_with_date : "-"}</th>
                                            <td style={{ width: "20%" }} className='text-end'>Closing Stock : </td>
                                            <th style={{ width: "30%" }} className='text-end'>{this.state.summaryData && this.state.summaryData.closing_stock ? this.state.summaryData.closing_stock : 0} </th>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <table className="table table-bordered table-fixed table-sm align-middle bg-white my-2">
                <thead className="align-middle table-secondary">
                    <tr className='text-start'>
                        <th className="text-center" style={{ width: "5%" }}>S.No</th>
                        <th className='text-center' style={{ width: "10%" }}>Date of Transaction</th>
                        <th className='text-center' style={{ width: "10%" }}>Transaction Type</th>
                        <th className='text-center' style={{ width: "10%" }}>Transaction ID</th>
                        <th style={{ width: "30%" }}>Asset/Vendor/Workstation/Warehouse</th>
                        <th className='text-center' scope="col" style={{ width: "9%" }}>Status</th>
                        <th scope="col" className='text-end' style={{ width: "9%" }}>Received</th>
                        <th scope="col" className='text-end' style={{ width: "9%" }}>Issued</th>


                    </tr>
                </thead>
                {this.state.listing_loading
                    ? (<tr><td colSpan="8"><Loader /></td></tr>)
                    : <tbody>
                        {this.state.listingData && this.state.listingData.length > 0
                            ? (this.state.listingData.map((rowData, k) => {
                                let pathname = rowData.transaction_type == "purchase" || rowData.transaction_type == "purchase_consumption" ? "/purchase_list" : rowData.transaction_type == "consumption" ? "/consumption_list" : "/inventory_transfer_list";

                                return (<tr key={rowData.transaction_id}>
                                    <td className='text-center'>{this.state.listingMeta.from + k}</td>
                                    <td className='text-center'>{rowData.transaction_date_display ?? "-"}</td>
                                    <td className='text-center'>{rowData.transaction_type_display ?? "-"}</td>
                                    <td className='text-center'>
                                        <a href="#" role="button" className='link-primary' onClick={() => this.viewTransactionModalInit(rowData.transaction_type, rowData.transaction_id)}>{rowData.transaction_id ?? "-"}</a></td>
                                    <td>
                                        <small className='form-text'>
                                            <b>{rowData.transaction_type === "purchase" || rowData.transaction_type === 'purchase_return' ? "Vendor" : rowData.transaction_type === "inventory_transfer" || rowData.transaction_type === "opening_stock" || rowData.transaction_type === 'inventory_adjustment' ? "Warehouse" : rowData.asset_name ? "Asset" : rowData.employee_name ? "Employee" : "Workstation"} : </b>
                                        </small>
                                        {rowData.transaction_type === "purchase" || rowData.transaction_type === 'purchase_return' ? rowData.vendor_name ?? "-" : rowData.transaction_type === "inventory_transfer" || rowData.transaction_type === "opening_stock" || rowData.transaction_type === 'inventory_adjustment' ? rowData.warehouse_name ?? "-" : rowData.asset_name ? rowData.asset_name : rowData.employee_name ? rowData.employee_name : rowData.workstation_name}
                                        <div className='mt-1'> {rowData.transaction_type === "purchase_consumption" ? <Ax><small className='form-text'><b>Vendor : </b> </small> {rowData.vendor_name ?? "-"}</Ax> : null}</div>
                                    </td>

                                    <td className='text-center'>{rowData.status ?? "-"}</td>
                                    <td className='text-end'>{rowData.received && rowData.received !== 0 ? rowData.received : ""}</td>
                                    <td className='text-end'>{rowData.issued && rowData.issued !== 0 ? rowData.issued : ""}</td>


                                </tr>);

                            }))
                            : (<tr><td className="text-center" colSpan={10}>No Record Found</td></tr>)
                        }
                    </tbody>}
            </table>
        </Ax>);
    }

    transactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Transaction Id : {this.state.transactionId ? this.state.transactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            {this.state.transanctionType  == "purchase" 
                             ? <PurchaseDetail purchaseId={this.state.transactionId}/>
                             : this.state.transanctionType == "inventory_transfer" 
                             ? <InventoryTransferDetail inventoryId={this.state.transactionId}/>
                             : this.state.transanctionType == "inventory_adjustment" 
                             ? <InventoryAdjustmentDetail adjustmentId={this.state.transactionId}/>
                              : this.state.transanctionType == "purchase_return" 
                              ? <PurchaseReturnDetail purchaseReturnId={this.state.transactionId}/> :
                                <ConsumptionDetailsModal consumptionId={this.state.transactionId}/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.reportFilterFormJsx()}
                        {this.state.formDataLoaded
                            ? (<Ax>
                                
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta 
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingOnPageClick(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
            {this.transactionDetailsModalJsx()}
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ItemWiseTransactionLedger);