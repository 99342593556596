import React from 'react';
import { Helmet } from 'react-helmet';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import SiteProductivityList from './SiteProductivityList';

const SiteProductivityData = () => {


    return (
        <>
            <ApplicationLayout>
                <Helmet>
                    <title>Site Productivity - Configuration</title>
                </Helmet>
                <SiteProductivityList />
            </ApplicationLayout>
        </>
    );
};

export default SiteProductivityData;
