import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import TapSelect from "../../../components/ui/TapSelect";
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import ItemTransactionLedger from '../../inventory/includes/ItemTransactionLedger';
import download from '../../../services/DownloadFile';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ClosingStock extends AppBaseComponent {
    
    constructor(props) {
        super(props);
               
        this.reportFilterFormInitialState   =   {
            search_warehouse_ids                :   [],
            search_item_ids                     :   [],
            ignore_zero_balance                 :   'Y',
            search_batch_number                 :   '',
            date                                :    moment().format('YYYY-MM-DD'),
            search_item_tags                    :   [],
        };
        
        this.reportFilterFormLabel          =   {
            search_warehouse_ids                :   'Search Warehouse : ',
            search_item_ids                     :   'Search Items : ',
            ignore_zero_balance                 :   'Ignore Items with Zero Balance : ',
            search_batch_number                 :   'Search Batch Number :  ',
            date                                :   'Date : ',
            search_item_tags                    :   'Item Tags : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            isc_configuration                   :   {},
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            allItemTags                         :   [],
            listingMeta                         :   null,
            download_access                     :   false,   
            reportData                          :   null,                  
        };
        this.transactionLedgerRef      =        React.createRef();

        this.reportUrl      =   INVENTORY_API_BASE_URL_2 + '/report/item-closing-stock';
    }
    
    componentDidMount(){
        this.initilaizeAppDataToFilterForm(this.props);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_warehouses !== this.props.all_warehouses) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }

    initilaizeAppDataToFilterForm           =       (props)      =>      {
        
        const report_name   =   'item_closing_stock';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];

        if(props){
            this.setState({
                reportData                  :   reportData.length > 0 ? reportData[0] : null,
                download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                all_warehouses              :   props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                isc_configuration           :   props.isc_configuration,
                allItemTags                 :   props.item_tags && props.item_tags.length > 0 ? props.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                formDataLoaded              :   true
            }, () =>  {
                let updateFilterForm = {};
                let ParamObject = new URLSearchParams(props.location.search);
                if (ParamObject.get('search_item_ids')) {
                    updateFilterForm['search_item_ids'] = [ParamObject.get('search_item_ids')];   
                   
                    setTimeout(() => {
                        this.setState({
                            reportFilterForm        :    { ...this.state.reportFilterForm,...updateFilterForm },
                            searchedItem            :   [{value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }]
                        }, () => {
                            this.reportFilterFormSubmit()
                        })
                    }, 1000)
                } else {
                    this.reportFilterFormSubmit()
                } 
                
            }) ; 
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'ignore_zero_balance') {
                        show_val            =   this.state.reportFilterForm.ignore_zero_balance == "Y" ? "Yes" : "No";
                    }
                    if(key == 'search_batch_number') {
                        show_val            =  this.state.reportFilterForm.search_batch_number;
                    }
                    if(key == 'date') {
                        show_val            =  moment(this.state.reportFilterForm.date).format("DD-MMM-YYYY");
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    checkAllList() {
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox')
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                purchaseItemCollections[i].checked = document.getElementById('all_check_purchase').checked;
            }
        }
    }

    addToConsumptionHandler                  =   ()  =>  {
    
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
        let item_ids            =       [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    item_ids.push(purchaseItemCollections[i].value);
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.listingData?.find(data => data.item.id == purchaseItemCollections[i].value);
                    purchase_item_ids.push(selectedPurchaseData.item)
                }
            }
        }

        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        let item_ids_unique            =   item_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        }else if(warehouse_ids_unique.includes("") || warehouse_ids_unique.includes(null) ) {
            toast.error('Please select item with stock', { position: toast.POSITION.TOP_RIGHT });
        }else {
            let allSelectedItem         =    purchase_item_ids.flat();
           
            const key = 'id';

            const uniquItemIdsList      =      [...new Map(allSelectedItem.map(item => [item[key], item])).values()];
            const uniqueItemsWrapped = uniquItemIdsList.map(item => ({ item }));
             let purchaseStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   uniqueItemsWrapped
            };
         
          
             this.props.history.push({ pathname: `/consumption_list/add`,  state: { addConsumptionFromPurchase : purchaseStateData } });
        }
    }

    addToInventoryTransferHandler                  =   ()  =>  {
    
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
        let item_ids            =       [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    item_ids.push(purchaseItemCollections[i].value);
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.listingData?.find(data => data.item.id == purchaseItemCollections[i].value);
                    purchase_item_ids.push(selectedPurchaseData.item)
                }
            }
        }
        
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        let item_ids_unique            =   item_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        }else if(warehouse_ids_unique.includes("") || warehouse_ids_unique.includes(null) ) {
            toast.error('Please select item with stock', { position: toast.POSITION.TOP_RIGHT });
        }else {
            let allSelectedItem         =    purchase_item_ids.flat();
           
            const key = 'id';

            const uniquItemIdsList      =      [...new Map(allSelectedItem.map(item => [item[key], item])).values()];
            const uniqueItemsWrapped = uniquItemIdsList.map(item => ({ item }));
             let purchaseStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   uniqueItemsWrapped
            };

           this.props.history.push({ pathname: `/inventory_transfer_list/add`,  state: { addInventoryFromPurchase : purchaseStateData } });
      
        }
    }

    addToAllocationHandler                  =   ()  =>  {
    
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
        let item_ids            =       [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    item_ids.push(purchaseItemCollections[i].value);
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.listingData?.find(data => data.item.id == purchaseItemCollections[i].value);
                    purchase_item_ids.push(selectedPurchaseData.item)
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        let item_ids_unique            =   item_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        }else if(warehouse_ids_unique.includes("") || warehouse_ids_unique.includes(null) ) {
            toast.error('Please select item with stock', { position: toast.POSITION.TOP_RIGHT });
        }else {
            let allSelectedItem         =    purchase_item_ids.flat();
           
            const key = 'id';

            const uniquItemIdsList      =      [...new Map(allSelectedItem.map(item => [item[key], item])).values()];
            const uniqueItemsWrapped = uniquItemIdsList.map(item => ({ item }));
           let allocationStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   uniqueItemsWrapped
            };
           this.props.history.push({ pathname: `/allocation_list/add`,  state: { addAllocationFromPurchase : allocationStateData } });
        }
    }

    addToInventoryAdjustmentHandler                  =   ()  =>  {
    
        let purchase_item_ids        =      [];
        let warehouse_ids            =       [];
        let item_ids                 =       [];
        let stock_data              =       [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    item_ids.push(purchaseItemCollections[i].value);
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.listingData?.find(data => data.item.id == purchaseItemCollections[i].value);
                    purchase_item_ids.push(selectedPurchaseData.item)
                    let selectedStockData = selectedPurchaseData && selectedPurchaseData.stock_data && selectedPurchaseData.stock_data.length > 0 ? selectedPurchaseData.stock_data.find((data,k) => k == purchaseItemCollections[i].getAttribute("data-stockId")) : null;
                    stock_data.push({stock : selectedStockData,item:selectedPurchaseData.item})
                }
            }
        }
        
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        let item_ids_unique            =   item_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        }else {
             let addFromClosingStockData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   stock_data
            };
            this.props.history.push({ pathname: `/inventory_adjustment_add`,  state: {  addFromClosingStockData :  addFromClosingStockData } });
      
        }
    }

    getTransactionLedgerDetail              =   ()  =>  {
    
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.listingData?.find(data => data.item.id == purchaseItemCollections[i].value);
                    purchase_item_ids.push(selectedPurchaseData)
                }
            }
        }

        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        }else if(warehouse_ids_unique.includes("") || warehouse_ids_unique.includes(null) ) {
            toast.error('Please select item with stock', { position: toast.POSITION.TOP_RIGHT });
        }else if(purchase_item_ids.length > 1) {
            toast.error('Please select only 1 Item', { position: toast.POSITION.TOP_RIGHT });
        }else {
            let allSelectedItem         =    purchase_item_ids.flat();
            const key = 'id';

            const uniqueStockList      =      [...new Map(allSelectedItem.map(item => [item[key], item])).values()];
           
             let selectedItemData      = uniqueStockList[0];
             let selectedWarehouse    =  selectedItemData && selectedItemData.stock_data && selectedItemData.stock_data.length > 0 ? selectedItemData.stock_data.find(st => st.warehouse.id == warehouse_ids_unique[0]) : null;
             
            this.transactionLedgerRef.current.modalInit(selectedItemData.item,selectedWarehouse.warehouse.id,selectedWarehouse.warehouse,true)
            
        }
    }

    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    reportDownloadHandler              =    ()     =>         {
        this.setState({ excelDownloading: true});
        let params              =           { ...this.state.submittedReportFilterForm,download : 'download'};
        HttpAPICall.withAthorization('GET',  this.reportUrl , this.props.access_token,params,{},(response) => {
          let respData = response.data
           let file_path = respData && respData.file_path ? respData && respData.file_path : '';
           let message  = respData && respData.msg ? respData && respData.msg : '';
           let navigation  = respData && respData.navigation ? respData && respData.navigation : '';
            download.file(file_path,message,navigation,this.props);
        })
        .then(() => this.setState({excelDownloading : false}));
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (
                        <Ax>
                            <button disabled={this.state.reportDownloading || !this.state.download_access} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                {this.props.permissions.includes('isc-allocation-add') && <li><a className={"dropdown-item"} role="button" onClick={() => this.addToAllocationHandler()}>Add to Allocation</a></li>}
                                {this.props.permissions.includes('isc-consumption-add') && <li><a className={"dropdown-item"} role="button" onClick={() => this.addToConsumptionHandler()}>Add to Consumption</a></li>}
                                {this.props.permissions.includes('isc-inventory_transfer-add') && <li><a className={"dropdown-item"} role="button" onClick={() => this.addToInventoryTransferHandler()}>Add to Inventory Transfer</a></li>}
                                {this.props.permissions.includes('isc-inventory-adjustment-add') && <li><a className={"dropdown-item"} role="button" onClick={() => this.addToInventoryAdjustmentHandler()}>Add to Inventory Adjustment</a></li>}
                                <li><a className={"dropdown-item"} role="button" onClick={() => this.getTransactionLedgerDetail()}>Item Transaction Ledger</a></li>
                            </ul>
                        </Ax>
                    ) : null}
                    
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                        </button> 
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            options={this.state.all_warehouses}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.all_warehouses.filter(t => this.state.reportFilterForm.search_warehouse_ids.includes(t.value))}
                            placeholder="Please Select Warehouse"
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"

                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Ignore Items with Zero Balance</label>
                    <div className="col-sm-1">
                        <label>
                            <input
                                type="radio"
                                name="ignore_zero_balance"
                                value="Y"
                                onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                                checked={this.state.reportFilterForm.ignore_zero_balance == "Y"}
                            /> Yes
                        </label>
                    </div>
                    <div className="col-sm-2">
                        <label>
                            <input
                                type="radio"
                                name="ignore_zero_balance"
                                value="N"
                                onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                                checked={this.state.reportFilterForm.ignore_zero_balance == "N"} /> No
                        </label>
                    </div>
                </div>
                {this.state.isc_configuration && this.state.isc_configuration.track_batch_expiry === "N" ? null : <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Batch Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_batch_number"
                            type="text"
                            value={this.state.reportFilterForm.search_batch_number}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Batch Number"
                        />
                    </div>
                </div>}
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Date</label>
                    <div className="col-sm-6 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.reportFilterForm.date
                                ? moment(this.state.reportFilterForm.date).toDate()
                                : false
                        }
                        name="date"
                        onChange={(value, event) => this.formDateHandler('date', value, 'reportFilterForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Date `}
                    />
                       
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-fixed table-sm align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th className="text-center" style={{width:"5%"}}><input type='checkbox' id="all_check_purchase" onChange={this.checkAllList} /></th> 
                        <th className='text-start' style={{width:"16%"}}>Item</th>
                        <th className='text-start' style={{width:"16%"}}>Manufacturer (MPN)</th>
                        
                        {this.state.isc_configuration && this.state.isc_configuration.track_batch_expiry === "N" ? null : (<Ax>
                            <th scope="col" style={{ width: "10%" }} className='text-start'>Batch Number & Expiry Date</th>
                           
                        </Ax>)}
                        <th scope="col" style={{ width: "14%" }} className="text-center">Tag</th>
                        <th className="text-center" style={{width:"16%"}}>Warehouse</th>
                        <th scope="col" style={{ width: "6%" }} className="text-end" > Quantity</th>
                        <th scope="col" style={{ width: "6%" }} className="text-end">UOM</th>
                        <th scope="col" style={{ width: "6%" }} className="text-end">Unit Rate</th>
                        <th scope="col" style={{ width: "6%" }} className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? this.state.listingData.map((rowData, k) => {
                            return (rowData.stock_data && rowData.stock_data.length > 0 ? rowData.stock_data.map((st, index) => {
                                return (<Ax>
                                    <tr>
                                        <td className="text-center">
                                            <input type='checkbox'
                                                className="purchase_item_id_checkbox"
                                                name="purchase"
                                                value={rowData.item.id}
                                                data-warehouseId={st.warehouse.id}
                                                data-stockId={index}
                                            />
                                        </td>
                                        <td>{rowData.item ? rowData.item.name : ''}<br />
                                            <small className='form-text'><b>Code : </b> {rowData.item ? rowData.item.item_code : ''}</small>
                                        </td>
                                        <td>
                                            {rowData.item && rowData.item.manufacturer ? rowData.item.manufacturer.name : ''} <br />
                                            {rowData.item && rowData.item.manufacturer_part_no ? <small className='form-text'>
                                                <b>MPN : </b>{rowData.item.manufacturer_part_no}
                                            </small> : null}
                                        </td>
                                        {this.state.isc_configuration && this.state.isc_configuration.track_batch_expiry === "N" ? null : (<Ax>
                                            <td className='text-start'>{st.batch_number ? st.batch_number : ''} <br />
                                                {st.expiry_date ? <small className='form-text'>
                                                    <b>Date : </b> {st.expiry_date} </small> : null}
                                            </td>
                                        </Ax>)}
                                        <td>
                                            {
                                                rowData.item && rowData.item.tags && rowData.item.tags.length > 0
                                                    ? (rowData.item.tags.map((item, k) => { return (item.name) }).join(', '))
                                                    : '-'
                                            }
                                        </td>
                                        <td>{st.warehouse ? st.warehouse.name : '-'}</td>
                                        <td className="text-end">{st.qty ? st.qty : '-'}</td>
                                        <td className="text-center">{rowData.item.measuring_unit ? rowData.item.measuring_unit.name : '-'}</td>
                                        <td className="text-end">{st.rate ? st.rate : '-'}</td>
                                        <td className="text-end">{st.amount}</td>
                                    </tr>
                                </Ax>)
                            })
                            : <Ax>
                                <tr>
                                        <td className="text-center">
                                            <input type='checkbox'
                                                className="purchase_item_id_checkbox"
                                                name="purchase"
                                                value={rowData.item.id}
                                                data-warehouseId={""}
                                            />
                                        </td>
                                        <td>{rowData.item ? rowData.item.name : ''}<br />
                                            <small className='form-text'><b>Code : </b> {rowData.item ? rowData.item.item_code : ''}</small>
                                        </td>
                                        <td>
                                            {rowData.item && rowData.item.manufacturer ? rowData.item.manufacturer.name : ''} <br />
                                            {rowData.item && rowData.item.manufacturer_part_no ? <small className='form-text'>
                                                <b>MPN : </b>{rowData.item.manufacturer_part_no}
                                            </small> : null}
                                        </td>

                                       <td></td>


                                        <td>
                                            {
                                                rowData.item && rowData.item.tags && rowData.item.tags.length > 0
                                                    ? (rowData.item.tags.map((item, k) => { return (item.name) }).join(', '))
                                                    : '-'
                                            }
                                        </td>
                                        <td></td>
                                        <td className="text-end"></td>
                                        <td className="text-end"></td>
                                        <td className="text-end"></td>
                                    </tr>
                            </Ax>)
                        })
                        : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)}
                    
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Inventory Closing Stock - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.reportFilterFormJsx()}
                        <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                        {this.reportListingDisplayJsx()}
                        {this.state.listingMeta 
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null 
                        }
                    </div>
                </div>
            </div>
            <ItemTransactionLedger ref={this.transactionLedgerRef}/>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ClosingStock);