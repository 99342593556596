import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from "moment";
import {Chart} from "react-google-charts";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";

class RequistionAgeing extends AppBaseComponent {

    constructor() {
        super();
        this.state                      =   {
            loading                         :   false,
            data                            :   null,
            all_warehouses              :   [],
            user_filter                 :   [
                {value  :   'tagged_me',        label : 'My Tagged Requisition'},
                {value  :   'created_by_me',    label : 'Requisition Created By Me'},
            ],
           
            filterForm                  :   {
                search_warehouse_id     :   '',
                tagged_user_filter      :   ''
            }
        }
        this.updateMyDashboardRef      =   React.createRef();
    }
    
    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('requistionAgeingWidget')) {
            let requistionAgeingData = JSON.parse(localStorage.getItem('requistionAgeingWidget'));
            
            if (requistionAgeingData) {
                let lastRefreshTime = moment(requistionAgeingData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('requistionAgeingWidget')
                    setTimeout(() => {
                        this.getrequistionAgeing();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 17));
                    
                } else {
                    this.setState({
                        data            :      requistionAgeingData, 
                    })
                }

            }else{
                setTimeout(() => {
                    this.getrequistionAgeing();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 17));
            }
        } else {
            setTimeout(() => {
                this.getrequistionAgeing();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 17));;
        }
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.all_warehouses !== this.props.all_warehouses || nextProps.dashboard_search_warehouse_id !== this.props.dashboard_search_warehouse_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }
    
    initilaizeFormFilter               =   (props)  =>  {
       
        if(props.all_warehouses.length > 0) {
            this.setState({
                all_warehouses          :   props.all_warehouses
                                                ? props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) 
                                                : [],
            }, () => {
                
                 if(this.props.dashboard_search_warehouse_id || props.clearDashboardFilter == "yes"){
                   
                    this.setState({
                        filterForm              :   {
                            search_warehouse_id         :       props.dashboard_search_warehouse_id,
                        },
                        
                    },() =>  this.getrequistionAgeing())
                }
                
            });
        }
    
    }
    
    getrequistionAgeing                 =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {...this.state.filterForm ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/requisition_ageing_data', this.props.access_token, params, {} , (response) => {
            this.setState({data : response.data})
            if(!this.state.filterForm.search_warehouse_id){
                localStorage.setItem('requistionAgeingWidget',JSON.stringify(response.data));
            }
            if(reload !== false){
                localStorage.setItem('requistionAgeingWidget',JSON.stringify(response.data));
            }
        }).then(() => this.setState({loading: false}));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"isc_purchase_requistion_ageing",name:"Purchase Requistion Ageing"})
    }
   
    render                      =   ()  =>  {
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("isc_purchase_requistion_ageing" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "isc_purchase_requistion_ageing")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Purchase Requistion Ageing</h6>
                    </div>
                    <div className="col-3">
                    <TapSelect
                            options={this.state.user_filter}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.user_filter.find(m => m.value == this.state.filterForm.tagged_user_filter)}
                            placeholder="All Requisition"
                            containerHeight="33px"
                            fontSize="93%"
                            width="99%"
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'tagged_user_filter', () => this.getrequistionAgeing())}
                        />
                    </div>
                    <div className="col-3 p0">
                    <TapSelect
                            options={this.state.all_warehouses}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.all_warehouses.find(m => m.value == this.state.filterForm.search_warehouse_id)}
                            placeholder="All Warehouses"
                            containerHeight="33px"
                            fontSize="93%"
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'search_warehouse_id', () => this.getrequistionAgeing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.getrequistionAgeing(true)}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "36%" }}  className="text-start" >Ageing in Days</th>
                                    <th style={{ width: "32%" }}>Ageing from Voucher Date</th>
                                    <th style={{ width: "32%" }}>Ageing from Approval Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? (<tr>
                                        <td className="text-center" colSpan="3"><Loader /></td>
                                    </tr>)
                                    : this.state.data?.data?.map((d, k) => {
                                        let status_ids = this.state.data && this.state.data.allowed_status && this.state.data.allowed_status.length > 0 ? this.state.data.allowed_status.map(as => as.id) : []
                                        let obj = {search_status_ids : status_ids};
                                        if (this.state.filterForm && this.state.filterForm.search_warehouse_id) {
                                            obj['search_warehouse_ids'] = this.state.filterForm.search_warehouse_id;
                                        }
                                        if (this.state.filterForm.tagged_user_filter != '') {
                                            obj['tagged_user'] = this.state.filterForm.tagged_user_filter;
                                        }
                                        let to = d.to == null ? 10000 : d.to;
                                        return (<tr key={k} className="text-center">
                                            <td className="text-start" >{d.display}</td>
                                            <td ><Link to={{ pathname: "/requisition_list", search: "?" + new URLSearchParams({...obj,'search_voucher_ageing': d.from + "-" + to}).toString() }} >{d.voucher_count ? d.voucher_count : "0"}</Link></td>
                                            <td><Link to={{ pathname: "/requisition_list", search: "?" + new URLSearchParams({...obj,'search_approval_ageing': d.from + "-" + to}).toString() }} >{d.approved_count ? d.approved_count : "0"}</Link></td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 p0">
                        <div className="mt-3 mb-1">
                            {this.state.loading
                                ? <Loader />
                                : (<Chart
                                    width={'100%'}
                                    height={'100%'}
                                    chartType="BarChart"
                                    loader={`<div>Loading Chart</div>`}
                                    data={[['Status', `Transaction Ageing`, 'Approved Ageing']].concat(this.state.data?.data?.map((d) => { return [d.display, parseInt(d.voucher_count), parseInt(d.approved_count)]; }))}
                                    options={{
                                        legend: { position: 'top' },
                                        colors: ['#53A8E3', '#FBBC05'],
                                        // isStacked: true,
                                        chartArea: { width: '100%', left: '80', height: '100%' }
                                    }}
                                    rootProps={{ 'data-testid': '1.5' }}
                                />)}
                        </div>
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                    {this.state.data ? `By default Report is ignoring vouchers in Draft, Closed and Cancelled Status` : null}<br/>
                        Report as of {this.state.data ? this.state.data.time : "-"}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                ref={this.updateMyDashboardRef}
            />
        </div>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

export default connect(mapStateToProps)(RequistionAgeing);