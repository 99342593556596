import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link , useHistory} from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import swal from "sweetalert";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import PageHeader from '../../../includes/PageHeader';
import TapIcon from '../../../../services/TapIcon';
import Loader from '../../../../components/ui/Loader/Loader';
import { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import HttpAPICall from '../../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../../components/TapUi';
import TapSelect from '../../../../components/ui/TapSelect';
import TapHelper from '../../../../services/TapHelper';
import DownloadFile from '../../../../services/DownloadFile';
import DateService from '../../../../services/DateService';
import InputAssetSearch from '../../../includes/ui/InputAssetSearch';
import TapNavTabs from '../../../../components/ui/TapNavTabs';
import HGFuelReportChart from './HGFuelReportChart';


const HGFuelFeedInReport                  =   forwardRef((props, ref) => {

    let initListData                        =   {
        loading                                 :   false,
        data_loaded                             :   false,
        items                                   :   [],
        meta                                    :   null,
        page                                    :   1
    };
    
    let initFormData                        =   {
        per_page                                :   50,
        form_data_loaded                        :   false,
        loading                                 :   false,
        downloading                             :   false,
        submitting                              :   false,
        fuel_types                              :   [],
        hg_selected_fuel_types                  :   [],
        periods                                 :   [],
        date_range                              :   null,
        date_range_start                        :   null,
        date_range_end                          :   null,
        search_like_transaction_id              :   '',
        reset_filter                            :   false,
        selected_assets                         :   [],
        search_asset_ids                        :   [],
        sites                                   :   [],
        search_site_ids                         :   [],
    };

    let formDataLabel                       =   {
        search_like_transaction_id              :   'Transaction ID : ',
        search_site_ids                         :   'Sites ',
        search_asset_ids                        :   'Assets ',
        date_range                              :   'Date Range : ',
        hg_selected_fuel_types                  :   'Fuel Types : ',
    };


    const access_token                      =   useSelector(state => state.auth.access_token);
    const permissions                       =   useSelector(state => state.app.acl_info && state.app.acl_info.permissions ? state.app.acl_info.permissions : []);
    const all_periods                       =   useSelector(state => state.app.all_periods ? state.app.all_periods : []);
    const user_sites                        =   useSelector(state => state.app.user_sites ? state.app.user_sites : []);

    const [currentTab, setCurrentTab]       =   useState(null);
    const [itemsData, setItemsData]         =   useState({...initListData});
    const [formData, setFormData]           =   useState({...initFormData});
    const [searchedElems, setSearchedElems] =   useState([]);
    const [submittedFormData, setSubmittedFormData] =   useState({...initFormData});
    const [searchModalId, setSearchModalId] =   useState(uuid());
    const searchModalElem                   =   useRef(null);
    const searchModal                       =   useRef(null);
    const searchModalOpen                   =   ()  => searchModal.current && searchModal.current.show()
    const searchModalClose                  =   ()  => searchModal.current && searchModal.current.hide()

    let itemListUrl                         =   IAM_API_BASE_URL_2 + "/hg_sap/fuel_feedin_report";
    let itemListFormDataUrl                 =   IAM_API_BASE_URL_2 + "/hg_sap/fuel_feedin_report";



    useEffect(() => {
        searchModalElem.current             =   document.getElementById(searchModalId);
        searchModal.current                 =   new Modal(searchModalElem.current, {keyboard: false, backdrop :false});

        if(currentTab == 1 && itemsData.data_loaded == false) {
            //Initializing Item Listing :-
            loadItemsData(props.location.state && props.location.state.page_number || 1);
        }

        //Cleanup on unmount
        return () => {
            if (searchModal.current) {
                searchModal.current.dispose();
            }
        };
    }, [currentTab]);

    const listSearchModalInit           =   ()  =>  {
        searchModalOpen();
        if(!formData.form_data_loaded) {
            loadFormData();
        }
    }

    const loadFormData                  =   ()  =>  {
        setFormData(pd => ({...pd, form_data_loaded : false,  loading : true }));
        HttpAPICall.withAthorization('GET', itemListFormDataUrl, access_token, {action:'formdata'}, {}, (response) => {
            let respData                =   response.data;
            let fuel_types              =   respData && respData.fuel_types && respData.fuel_types.length > 0 ?  respData.fuel_types.map((s) => { return {value: s, label: s}; }) : [];

            let periods                 =   [...all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            let sites                   =   user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}})
  
            setFormData(pd => ({...pd, fuel_types : fuel_types, periods : periods, sites : sites }));
        }).then(() => {
            setFormData(pd => ({...pd, form_data_loaded : true,  loading : false }));
        });
    }

    const submitSearchFormHandler       =   (e) => {
        e.preventDefault();
        let searchedElems               =   [];
        let searchParams                =   {};
        Object.keys(formData).map((key)        =>  {
            let label                       =   formDataLabel[key];
            let value                       =   formData[key];
            if(value && value.length > 0) {
                searchParams[key]       =   value;
                if (label) {
                    let show_val        =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   formData.selected_assets ? formData.selected_assets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   formData.sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'date_range') {
                        show_val            =   formData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    if (key == 'hg_selected_fuel_types') {
                        show_val            =   formData.fuel_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        setSubmittedFormData(pd => ({...pd, ...formData}));
        setSearchedElems(searchedElems);

        //Close The Modal
        searchModalClose();
        //Initializing Item Listing :-
        loadItemsData(1);
    }

    const searchFormClear               =  ()  =>  {
        setSearchedElems([]);
        setFormData(pd => ({...pd, ...initFormData, reset_filter : true}));
        setSubmittedFormData(pd => ({...initFormData}));
    }
    useEffect(() => {
        if(formData.reset_filter) {
            setFormData(pd => ({...pd, reset_filter : false}));
            loadItemsData(1);
        }
    }, [formData.reset_filter]);



    const loadItemsData                 =   (page = 1)  =>  {
        setItemsData(pd => ({...pd, data_loaded : false, loading : true, page : page }));
        let params                      =   {
                                                action : 'data',
                                                page : page,
                                                ...TapHelper.pick(formData, ['search_like_transaction_id', 'hg_selected_fuel_types', 'date_range', 'date_range_start','date_range_end', 'search_asset_ids', 'search_site_ids'])
                                            };
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            let respData                =   response.data;
            let meta                    =   TapHelper.pick(respData, ['from', 'to', 'total', 'last_page','per_page','current_page']);
            setItemsData(pd => ({...pd, data_loaded : true, items : respData.data, meta : meta, sites : response.data.sites }));
        }).then(() => {
            setItemsData(pd => ({...pd, loading : false}));
        });
    }
    const reportDownloadHandler         =   ()  =>  {
        let params                      =   {
            action : 'download',
            ...TapHelper.pick(formData, ['search_like_transaction_id', 'hg_selected_fuel_types', 'date_range', 'date_range_start','date_range_end', 'asset_id'])
        };
        if(params.date_range == null || params.date_range == '') {
            swal({ title: "Download",
                text: "The download will be for Current Quarter's Transaction, in case you want different Transaction Period then please change by filter.",
                icon: "warning",
                buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    reportDownloadStart({...params , date_range : 'current_quarter'});
                }
            });
        } else {
            reportDownloadStart(params);
        }
    }
    const reportDownloadStart           =   (params)  =>  {
        setItemsData(pd => ({...pd, downloading : true }));
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            DownloadFile.file(response.data.file_path)
        }).then(() => {
            setItemsData(pd => ({...pd, downloading : false}));
        });
    }

    const voucherListJsx                =   ()  =>  {
        return <>
            <DisplaySearchCriteria searchedElems={searchedElems} onClearFilter={searchFormClear} />
            <table className="table table-sm table-bordered align-middle bg-white">
                <thead className="align-middle table-secondary">
                    {itemsData.minimizeTable ? <tr className='text-center'>
                        <th scope="col">Sales Invoice</th>
                    </tr>: 
                    <tr className='text-center'>
                        <th style={{ width: "3%" }}>S.No</th>
                        <th style={{ width: "13%" }}>Transaction ID</th>
                        <th style={{ width: "7%" }}>Date</th>
                        <th style={{ width: "17%" }}>Asset</th>
                        <th style={{ width: "10%" }}>Site</th>
                        <th style={{ width: "17%" }}>Meter Reading</th>
                        <th style={{ width: "8%" }}>Fuel Type</th>
                        <th style={{ width: "8%" }}>Qty</th>
                        <th style={{ width: "8%" }}>Rate</th>
                        <th style={{ width: "9%" }}>Amount</th>
                    </tr>}
                </thead>
                <tbody>
                {itemsData.loading 
                    ? <tr><td className='text-center' colSpan={9}><Loader /></td></tr>
                    : ((itemsData.items && itemsData.items.length > 0) 
                        ? itemsData.items.map((item,index) => {
                            return <tr className={['text-center'].join(' ')} key={index}>
                                <td className='text-center'>{itemsData.meta ? itemsData.meta.from + index : index}</td>
                                <td className='text-center'>{item.feedin_id}</td>
                                <td className='text-center'>{DateService.dateFormat(item.feed_datetime)}</td>
                                <td className='text-center'>{item.asset_name} <em>({item.asset_code})</em></td>
                                <td className='text-center'>{item.site_name}</td>
                                <td className='text-center'>{item.counter_log.map((cl,k) => {
                                    return <div key={k}>{cl.label} : {cl.reading} {cl.unit}</div>
                                } )}</td>
                                <td className='text-center'>{item.fuel_type}</td>
                                <td className='text-end'>{item.executed_qty}</td>
                                <td className='text-end'>{item.executed_unit_rate}</td>
                                <td className='text-end'>{item.executed_amount}</td>
                            </tr>
                        })
                        : <tr><td className='text-center' colSpan={9}>No Record</td></tr>  )
                }
                </tbody>
            </table>
            <DisplayListPagination meta={itemsData.meta} onPageChange={(e) => loadItemsData(e.selected + 1)} />
        </>
    }
 
    const topRightHeaderJsx             =   ()  =>  {
        return <>
         {currentTab == 1 &&  <>
            <button type="button" disabled={itemsData.downloading || itemsData.loading || formData.loading} className="btn btn-secondary" onClick={reportDownloadHandler} >
                <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                {itemsData.downloading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />}
            </button>
            <button type="button" disabled={itemsData.downloading || itemsData.loading || formData.loading}  className="btn btn-secondary" onClick={listSearchModalInit} >
                <TapIcon.imageIcon icon={TapIcon.SearchIcon} />
            </button>
            </> 
        }
        </>
    }

    const pageHeading                   =   () => {
        return <PageHeader title="Fuel FeedIn Report" name="Fuel FeedIn Report" rightTopHeadingJsx={topRightHeaderJsx()}  />
    }

    const searchModalJsx                =   () => {
        return <><div className="modal fade" id={searchModalId} tabIndex="-1" ref={searchModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Fuel FeedIn Search</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={itemsData.loading} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {formData.loading ? <Loader /> : <form name='' id={searchModalId + 'Form'} onSubmit={submitSearchFormHandler}>
                            <div className="row mb-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Transaction ID</label>
                                <div className="col-sm-4">
                                    <input
                                        type="text"
                                        value={formData.search_like_transaction_id}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={e => setFormData(d => ({...d, search_like_transaction_id : e.target.value}))}
                                        placeholder="Search by Transaction ID"
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Asset</label>
                                <div className="col-sm-9">
                                    <InputAssetSearch
                                        containerHeight="30px"
                                        fontSize="93%"
                                        placeholder="Search Assets"
                                        filter={true}
                                        isMulti={true}
                                        value={formData.selected_assets ? [...formData.selected_assets] : []}
                                        changeEvent={(so) => {
                                            setFormData(d => ({...d, 
                                                search_asset_ids    :   so ? so.map(s =>s.value) : [],
                                                selected_assets     :   so && so.length > 0 ? so.map(a => { a.label = a.display_label; return a; }) : [] 
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Site Name</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        changeEvent={o => setFormData(d => ({...d, search_site_ids : o.map(e => e.value)}))}
                                        placeholder="Select Sites"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        isClearable={true}
                                        options={formData.sites}
                                        value={formData.sites.filter(s => formData.search_site_ids.includes(s.value))}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Fuel Types</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        changeEvent={o => setFormData(d => ({...d, hg_selected_fuel_types : o.map(e => e.value)}))}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        isClearable={true}
                                        options={formData.fuel_types}
                                        value={formData.fuel_types.filter(s => formData.hg_selected_fuel_types.includes(s.value))}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Transaction Period</label>
                                <div className="col-sm-9">
                                    <PeriodSelectionWithCustom
                                        periods={formData.periods ? formData.periods : []}
                                        className="form-control-sm"
                                        containerHeight="33px"
                                        fontSize="93%"
                                        onSelectPeriod={(period, startDate, endDate) => {
                                            setFormData(d => ({...d, 
                                                date_range : period, 
                                                date_range_start :startDate ? startDate : null,
                                                date_range_end: endDate ? endDate : null  
                                            }))
                                        }}
                                        value={formData.date_range}
                                        startDate={formData.date_range_start}
                                        endDate={formData.date_range_end}
                                    />
                                </div>
                            </div>
                        </form>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button form={searchModalId + 'Form'} type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    }
    
    
    let reportTabs                          =   ()  =>  {
        return [
            { name  :   'Fuel Summary',         jsx :   currentTab == 0 && <HGFuelReportChart /> },
            { name  :   'Fuel FeedIn Report',   jsx :   currentTab == 1 && voucherListJsx() }
        ];
    }

    return  <ApplicationLayout>
        {pageHeading()}
        <div className="container-fluid pl5">
            <div className='page_containt row'>
                <div className='col-sm-12 pe-1 bg-white'>
                    <TapNavTabs tabs={reportTabs()} activetab={0} setTabValue={(activetab) => { setCurrentTab(activetab); }} />
                </div>
            </div>
        </div>
        {searchModalJsx()}
    </ApplicationLayout>

});

export default HGFuelFeedInReport;