import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ItemViewInList from '../includes/ItemViewInList';
import IndentDetailModal from '../warehouseIndent/IndentDetailModal';

class InventoryTransferDetail extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                      =       {
            inventoryData               :       null,
            view_loading                :       false,
        };
        this.indentDetailRef        =        React.createRef();
    }

    componentDidMount() {
        
        if(this.props && this.props.inventoryId){
            this.getInventoryTransferDetails(this.props.inventoryId)
        }
        if(this.props && this.props.inventoryData){
            this.setState({inventoryData : this.props.inventoryData})
        }
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.inventoryId !== this.props.inventoryId){
            this.getInventoryTransferDetails(nextProps.inventoryId)
         }
         if(nextProps.inventoryData !== this.props.inventoryData){
             this.setState({inventoryData : nextProps.inventoryData})
         }
         
     }
    

     getInventoryTransferDetails             =    (transaction_id)        =>  {
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET',  INVENTORY_API_BASE_URL_2 + '/inventory_transfer/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            
            this.setState({
                inventoryData             :   respData.data,
            });
            
        }).then(() => this.setState({view_loading : false}));     
        
     }

     viewIndentDetailModalInit                    =   (transaction_id)  =>  { 
        this.indentDetailRef.current.modalIndentInit(transaction_id)    
   }
    
    render() {
        let inventoryTransfer = this.state.inventoryData;
        let serialNumberCounter = 0;
        return (<Ax>
            {this.state.view_loading ? <Loader /> :
                inventoryTransfer ? (<div className="">
                    <table className="table table-bordered bg-white" >
                        <thead className="table-secondary">
                            <tr>
                                <th style={{ width: "33%" }}>ID</th>
                                <th style={{ width: "33%" }}>Date</th>
                                <th style={{ width: "33%" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{inventoryTransfer.transaction_id}</td>
                                <td scope="col">{inventoryTransfer.transaction_date_display}</td>
                                <td scope="col" className="align-middle">
                                    {inventoryTransfer.status_text ? inventoryTransfer.status_text : ''}
                                </td>  </tr>
                        </tbody>
                    </table>
                    <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                        <tbody>
                            <tr>
                                <td style={{ width: "33%" }} >Dispatching Warehouse</td>
                                <td colSpan={2}>{inventoryTransfer.warehouse_from_data ? <div><b>{inventoryTransfer.warehouse_from_data.name} ({inventoryTransfer.warehouse_from_data.code})</b> <br />
                                    {inventoryTransfer.warehouse_from_data ?
                                        <div className='pt-1'>Address : {inventoryTransfer.warehouse_from_data.address1 ? inventoryTransfer.warehouse_from_data.address1 + ", " : ""}
                                            {inventoryTransfer.warehouse_from_data.address2 ? inventoryTransfer.warehouse_from_data.address2 + ", " : ""}
                                            {inventoryTransfer.warehouse_from_data.landmark ? inventoryTransfer.warehouse_from_data.landmark + ", " : ""}
                                            {inventoryTransfer.warehouse_from_data.city ? inventoryTransfer.warehouse_from_data.city + ". " : "-"} <br />
                                            Destination of Supply : <b>{inventoryTransfer.warehouse_from_data.state_name}</b> <br />
                                            GSTIN  : {inventoryTransfer.warehouse_from_data.gstin ?? "-"} <br />
                                        </div> : "-"}</div> : "-"} <br />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }} >Receiving Warehouse</td>

                                <td colSpan={2}>{inventoryTransfer.warehouse_to_data ? <div><b>{inventoryTransfer.warehouse_to_data.name} ({inventoryTransfer.warehouse_to_data.code})</b> <br />
                                    {inventoryTransfer.warehouse_to_data ?
                                        <div className='pt-1'>Address : {inventoryTransfer.warehouse_to_data.address1 ? inventoryTransfer.warehouse_to_data.address1 + ", " : ""}
                                            {inventoryTransfer.warehouse_to_data.address2 ? inventoryTransfer.warehouse_to_data.address2 + ", " : ""}
                                            {inventoryTransfer.warehouse_to_data.landmark ? inventoryTransfer.warehouse_to_data.landmark + ", " : ""}
                                            {inventoryTransfer.warehouse_to_data.city ? inventoryTransfer.warehouse_to_data.city + ". " : "-"} <br />
                                            Destination of Supply : <b>{inventoryTransfer.warehouse_to_data.state_name}</b> <br />
                                            GSTIN  : {inventoryTransfer.warehouse_to_data.gstin ?? "-"} <br />
                                        </div> : "-"}</div> : "-"} <br />
                                </td>
                            </tr>
                            <tr>
                                <td scope="col">Invoice Number &  Date</td>
                                <td style={{ width: "33%" }} className="text-capitalize">{inventoryTransfer.invoice_number ?? "-"}</td>
                                <td style={{ width: "34%" }}>{inventoryTransfer.invoice_date_display ?? "-"}</td>

                            </tr>
                            <tr>
                                <td scope="col">Eway Bill Number &  Date</td>
                                <td style={{ width: "33%" }} className="text-capitalize">{inventoryTransfer.eway_number ?? "-"}</td>
                                <td style={{ width: "34%" }}>{inventoryTransfer.eway_date_display ?? "-"}</td>

                            </tr>
                            <tr>
                                <td scope="col">Document Number &  Date</td>
                                <td style={{ width: "33%" }} className="text-capitalize">{inventoryTransfer.document_number ?? "-"}</td>
                                <td style={{ width: "34%" }}>{inventoryTransfer.document_date_display ?? "-"}</td>

                            </tr>
                            <tr>
                                <td style={{ width: "33%" }} >Transporter Number</td>
                                <td colSpan={2}>
                                    {inventoryTransfer.transporter_number ? inventoryTransfer.transporter_number : "-"}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table table-hover table-bordered table-responsive bg-white my-3" id="poTable">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ "width": '5%' }} className="text-center">
                                           S.No
                                        </th>
                                        <th style={{ width: "32%" }}>Item Details</th>
                                        <th style={{ width: "10%" }} className="text-center">Indent</th>
                                        {this.props.isc_configuration?.track_batch_expiry == "Y" ?
                                            <th style={{ width: "21%" }} className="text-center">Batch Number & <br /> Expiry Date</th>
                                            : null}
                                        <th style={{ width: "8%" }} className="text-end">Quantity</th>
                                        <th style={{ width: "8%" }} className="text-end">Unit Rate</th>
                                        <th style={{ width: "8%" }} className="text-end">Tax</th>
                                        <th style={{ width: "8%" }} className="text-end">Amount</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inventoryTransfer && inventoryTransfer.transfer_items && inventoryTransfer.transfer_items.length > 0 ? inventoryTransfer.transfer_items?.map((i, k) => {
                                            return i.warehouse_indents.map((indent, rk) => {
                                                let total_qty = indent.items && indent.items.length > 0 && indent.items.map(s => s.qty).reduce((a, b) => a + b, 0);
                                                let total_rate = indent.items && indent.items.length > 0 ? indent.items[0].rate : 0;
                                                serialNumberCounter++;
                                               return (<tr key={k}>
                                                    <td className='text-center'>
                                                        {serialNumberCounter}
                                                    </td>
                                                    <td><ItemViewInList item={i.item_profile} viewItemModal={() => this.viewItemModalInit(i.item_profile)} /></td>
                                                    <td className="text-center  link-primary cursor_pointer" onClick={() => this.viewIndentDetailModalInit(indent.indent_transaction_id)}>{indent.indent_transaction_id ? indent.indent_transaction_id : "-"}</td>
                                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ?
                                                        <td className='text-center'>
                                                            {indent.items && indent.items.length > 0 ?
                                                                indent.items.map((itemData, rk) => {
                                                                    return (
                                                                        <div>{itemData.batch_number && itemData.expiry_date_display ? <span>{itemData.batch_number} & {itemData.expiry_date_display}</span> : itemData.batch_number && !itemData.expiry_date_display ? <span>{itemData.batch_number} </span> : !itemData.batch_number && itemData.expiry_date_display ? <span>{itemData.expiry_date_display} </span> : <span>BNA <sup>#</sup></span>} : <b> {itemData.qty}</b> </div>
                                                                    )
                                                                }) : null}

                                                        </td> : null}
                                                    <td className='text-end'> {indent.items && indent.items.length > 0 && indent.items.map(s => s.qty).reduce((a, b) => a + b, 0)}
                                                        {i.item_profile.measuring_unit && i.item_profile.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item_profile?.measuring_unit?.name}</span> : null}

                                                    </td>
                                                    <td className='text-end'>{indent.items && indent.items.length > 0 ? indent.items[0].rate : 0} </td>
                                                    <td className='text-end'>{indent.items && indent.items.length > 0 ? indent.items[0].gst : 0} %
                                                        {inventoryTransfer && inventoryTransfer.warehouse_from_data.gstin ? <span className="text-helping input-group-text text-end pr0">{inventoryTransfer.dispatching_address && inventoryTransfer.receiving_address ? (inventoryTransfer.dispatching_address?.state_id == inventoryTransfer.receiving_address?.state_id) ? "GST" : "IGST"
                                                            : inventoryTransfer.warehouse_to_data && inventoryTransfer.warehouse_to_data?.state_id == inventoryTransfer?.warehouse_from_data?.state_id ? "GST" : "IGST"}</span> : null}
                                                    </td>
                                                    <td className='text-end'>{total_qty * total_rate}</td>


                                                </tr>)

                                            })

                                        }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                    }
                                </tbody>
                            </table>
                    <div className="row align-items-center">
                        <div className="col-6">
                            <label htmlFor="notes" className="form-label">Notes</label>
                            <div>{inventoryTransfer.notes ?? "-"}</div>

                        </div>
                        <div className="col-6">
                            <table className="table  table-borderless">
                                <tbody>
                                    <tr className="subtotal_bg_color">
                                        <th>Sub-Total</th>
                                        <td className='float-end'>{inventoryTransfer.total_amount ? (parseFloat(inventoryTransfer.total_amount) - (parseFloat(inventoryTransfer.adjustment_amount ?? 0.0) + parseFloat(inventoryTransfer.total_tax))).toFixed(2) : 0.00}</td>
                                    </tr>
                                    {inventoryTransfer.tax_slabs && inventoryTransfer.tax_slabs.length > 0
                                        ? (inventoryTransfer.tax_slabs.map((ps, k) => {
                                            return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                        }))
                                        : null
                                    }
                                    <tr className="subtotal_bg_color">
                                        <th>Adjustment</th>
                                        <td className='float-end'>{inventoryTransfer.adjustment_amount ?? 0}</td>
                                    </tr>
                                    <tr className="subtotal_bg_color">
                                        <th>Final Total</th>
                                        <td className='float-end'>{inventoryTransfer.total_amount ? inventoryTransfer.total_amount : 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>) : null}
                <IndentDetailModal ref={this.indentDetailRef}/>
        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(InventoryTransferDetail);