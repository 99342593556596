import * as actionTypes from './actionTypes';

import { applicationInitLoading } from './app';

import axios from 'axios';
import HttpAPICall from '../../services/HttpAPICall';
import { appInitilizationDataLoad } from '.';
import TapApiUrls from '../../services/TapApiUrls';
import { INVENTORY_API_BASE_URL_2 } from '../../services/TapApiUrls';

export const iscAppDataInitilization   = () => {
    return {
        type                :   actionTypes.ISC_APP_DATA_INIT
    };
};



export const iscAppDataLoadSuccessfully   = (isc_app_data) => {
    return {
        type                    :   actionTypes.ISC_APP_DATA_SUCCESS,
        isc_data                :   isc_app_data,
    };
};


export const iscAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.ISC_APP_DATA_FAIL
    };
};



export const itemSearch = (searchElem,searchParam) => {

    return {
        type            :   actionTypes.ITEM_SEARCH,
        search_elem     :   searchElem,
        search_param    :   searchParam,
    };
};

export const itemSearchClear = () => {
    return {
        type: actionTypes.ITEM_CLEAR
    };
};
//***********************ITEM LISTING FUNCTIONS***************** *****/
export const itemListingDataInitilization   = () => {
    return {
        type                :   actionTypes.ITEM_LISTING_DATA_INIT
    };
};
export const itemListingDataLoadSuccessfully   = (item_listing_data,search_param,formSearchedElems) => {
    return {
        type                    :   actionTypes.ITEM_LISTING_DATA_SUCCESS,
        item_listing_data      :   item_listing_data,
        search_param            :   search_param,
        formSearchedElems       :   formSearchedElems
    };
};


export const itemListingAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.ITEM_LISTING_DATA_FAIL
    };
};

export const iscAppDataLoading         =   (user_info, group_info, acl_info)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
        if (acl_info.group_modules.includes("isc")) {
            HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/app_data', access_token, {})
                .then((response) => {
                    dispatch(iscAppDataLoadSuccessfully(response.data));
                    dispatch(appInitilizationDataLoad(user_info, group_info, acl_info));
                }).catch(function (err) {
                    dispatch(iscAppDataLoadFail());
                });
        }
    };
};
//***********************ASSET LISTING LOADING FUNCTION***************** *****/
export const itemListingDataLoading         =   (page,searchParm,cl_attribute,formSearchedElems)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
        let params                      =   {cl_attribute: cl_attribute, page: page, per_page: 50, ...searchParm};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_API_SERVER_2 + '/inventory/item' , access_token, params, {} ,(response) => {
            let respData                =   response.data;
            dispatch(itemListingDataLoadSuccessfully(respData,searchParm,formSearchedElems))
        })
            
        
    };
};
