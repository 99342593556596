import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import dashboardStyles from "./Dashboard.module.css"
import AppBaseComponent from "../../components/AppBaseComponent";
import { Helmet } from 'react-helmet';
import * as actions from '../../store/actions/index';
import GroupUpdateHeader from './includes/GroupUpdateHeader';
import AssetQuickNavigation from './includes/AssetQuickNavigation';
import ItemQuickNavigation from './widgets/ItemQuickNavigation';
import ReportQuickNavigation from './widgets/ReportQuickNavigation';
import TapSelect from '../../components/ui/TapSelect';
import ServiceChecksheet from './widgets/ServiceChecksheet';
import AssetStatus from './widgets/AssetStatus';
import ComplianceWidget from './widgets/ComplianceWidget';
import FeedbackModal from '../../layouts/modal/FeedbackModal';
import TaskActivity from './widgets/TaskActivity';
import RentalStatusWidget from './widgets/rental/RentalStatusWidget';
import DueDateWidget from './widgets/rental/DueDateWidget';
import TransactionDelay from './widgets/TransactionDelay';
import CounterlogAgeingWidget from './widgets/CounterlogAgeingWidget';
import TicketWidget from './widgets/TicketWidget';
import PurchaseConsumption from './widgets/PurchaseConsumption';
import WarehouseIndentWidget from './widgets/WarehouseIndentWidget';
import RequisitionWidget from './widgets/RequisitionWidget';
import PurchaseOrder from './widgets/PurchaseOrder';
import WalletSummary from './widgets/WalletSummary';
import FuelSummary from './widgets/FuelSummary';
import WarehouseIndentAgeing from './widgets/WarehouseIndentAgeing';
import RequisitionAgeing from './widgets/RequisitionAgeing';
import PoAgeingWidget from './widgets/PoAgeingWidget';
import StockMovementWarehouseWidget from './widgets/StockMovementWarehouseWidget';
import EomployeeComplianceWidget from './widgets/EomployeeComplianceWidget';
import FuelConsumptionWidget from './widgets/FuelConsumptionWidget';
import AssetWiseSegregation from './widgets/AssetWiseSegregation';
import ProductivityWidget from './widgets/ProductivityWidget';
import HgSapTaskWidget from './widgets/HgSapTaskWidget';
import SubAssemblyWidget from './widgets/SubAssemblyWidget';
import MttrMtbfWidget from './widgets/MttrMtbfWidget';
import SiteProductivityLineWIdget from '../siteProductivity/configuration/SiteProductivityLineWIdget';
import SiteProductivityBarWidget from '../siteProductivity/configuration/SiteProductivityBarWidget';
import SiteProductivityPieWidget from '../siteProductivity/configuration/SiteProductivityPieWidget';



class MyDashboard  extends AppBaseComponent {
    
    

    constructor(props) {
        super(props);

        this.initDashboardFilterForm                 =       {
            selected_site_id                    :       '',
            selected_warehouse_id               :       ''
        }

        this.state = {
            widget_keys                     :   [],
            all_warehouses                  :   [],
            iam_user_sites                  :   [],    
            dashboard_filter_site_id        :   '',
            dashboard_filter_site_id        :   '',
            dashboard_filter_warehouse_id   :   '',
            clearDashboardFilter            :   "no",
            dashboardFilterForm             :   {...this.initDashboardFilterForm},
            show                            :   false,
        }

       
    }

    componentDidMount  (){
        this.initilaizeFormFilter(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter                =   (props)  =>  {
        this.setState({
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            all_periods             :   props.all_periods ? props.all_periods.map((s) => { return {value: s.key, label: `${s.display}` ,display_label : s.display_with_date}}) : [],
            all_warehouses          :   props.all_warehouses
                                                ? props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) 
                                                : [],
            iam_asset_types          :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            widget_keys              :  props.widget_keys && props.widget_keys.length > 0 ? props.widget_keys : []
        });
    }

    onDashboardFilterSubmit         =       (e)         =>      {
        e.preventDefault();

        if(this.state.dashboardFilterForm && this.state.dashboardFilterForm.selected_site_id){
            this.setState({
                dashboard_filter_site_id            :  this.state.dashboardFilterForm.selected_site_id,
            },() => {
                
            })
        }

        if(this.state.dashboardFilterForm && this.state.dashboardFilterForm.selected_warehouse_id){
            this.setState({
                dashboard_filter_warehouse_id            :  this.state.dashboardFilterForm.selected_warehouse_id,
            },() => {
               
            })
        }

        if(this.state.dashboardFilterForm && !this.state.dashboardFilterForm.selected_site_id && !this.state.dashboardFilterForm.selected_warehouse_id && this.state.dashboard_filter_site_id || this.state.dashboard_filter_warehouse_id){
            this.setState({
                dashboard_filter_site_id            :  '',
                dashboard_filter_warehouse_id       :  '',
                clearDashboardFilter                :  "yes"
            },() => {
               
            })
        }
    }

    addFeedbackModalInit                 =   ()  =>  {
        this.setState({show : true})
    }

    closeFeedback =  () => {
        this.setState({show : false})
    }

    addTrainingFormInit         =       ()          =>      {
        this.props.history.push('/training_list');
        
    }

    renderWidgets() {
        let {widget_keys } = this.props;
    
    
        let components = {
            'assets'                    :       <AssetStatus indexProps={2} />,
            'scs_running_status'        :       <ServiceChecksheet indexProps={4} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'task_running_status'       :        <TaskActivity indexProps={6} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'commercial_status_count'   :       <RentalStatusWidget indexProps={8} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'commercial_due_dates'      :       <DueDateWidget indexProps={10} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'asset_transaction_delay'   :       <TransactionDelay indexProps={12} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'counterlog_ageing'         :       <CounterlogAgeingWidget indexProps={14} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'ticket'                    :       <TicketWidget indexProps={16} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'puchase_and_consumption'       :       <PurchaseConsumption indexProps={18} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'warehouse_indent'              :       <WarehouseIndentWidget indexProps={20} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'isc_warehouse_indent_ageing'   :       <WarehouseIndentAgeing indexProps={22} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter}  />,
            'purchase_requisition'          :      <RequisitionWidget indexProps={24} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'isc_purchase_requistion_ageing':      <RequisitionAgeing indexProps={26} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'purchase_order'                :       <PurchaseOrder indexProps={28} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'isc_purchase_order_ageing'     :       <PoAgeingWidget indexProps={30} dashboard_filter_warehouse_id={this.state.dashboard_filter_warehouse_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'iam-compliance'                :       <ComplianceWidget indexProps={32} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'wallet_summary'                :       <WalletSummary indexProps={34} />,
            'fuel_summary'                  :   <FuelSummary indexProps={36} />,
            'stock_movement_widget'         :   <StockMovementWarehouseWidget indexProps={38} />,
            'iam-asset-utilization'                :   <AssetWiseSegregation indexProps={17} />,
            'hrm-compliance'                :   <EomployeeComplianceWidget indexProps={40} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'fuel-consumption'                :   <FuelConsumptionWidget indexProps={42} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'iam-asset-productivity'                :   <ProductivityWidget indexProps={43} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'hg_task_status_count_widget'     :   <HgSapTaskWidget indexProps={44} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'sub_assembly_status'              :    <SubAssemblyWidget indexProps={45} dashboard_search_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'mttr_mtbf'                 :   <MttrMtbfWidget indexProps={51} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'productivity_target_achivement'                 :   <SiteProductivityLineWIdget indexProps={52} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'period_wise_site_productivity'                 :   <SiteProductivityBarWidget indexProps={53} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />,
            'productivity_share_for_period'                 :   <SiteProductivityPieWidget indexProps={54} dashboard_filter_site_id={this.state.dashboard_filter_site_id} clearDashboardFilter={this.state.clearDashboardFilter} />
        };
    
        // Filter userWidgetKeys to include only keys with corresponding components
        const filteredUserWidgetKeys = widget_keys.filter(widgetKey => components[widgetKey.key]);
    
        // Sort the filtered keys based on the 'ordo' property
        const sortedKeys = filteredUserWidgetKeys.sort((a, b) => parseFloat(a.ordo) - parseFloat(b.ordo));
    
        // Render the components in the sorted order
        return sortedKeys.map((widgetKey, index) => (
          <div key={index}>{components[widgetKey.key]}</div>
        ));
    }
    
    
    dashboardFilterFormJsx      =       ()      =>      {
        return <div className="card my-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold primary_color">Dashboard Filters</h6>
                    </div>
                </div>
                <form onSubmit={this.onDashboardFilterSubmit}>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <TapSelect
                                options={this.state.iam_user_sites}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dashboardFilterForm', 'selected_site_id')}
                                value={this.state.iam_user_sites.find(s => s.value == this.state.dashboardFilterForm.selected_site_id)}
                                isSearchable={true}
                                isClearable={true}
                                placeholder="All Sites"
                                containerHeight="33px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-9 pr0">
                            <TapSelect
                                options={this.state.all_warehouses}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dashboardFilterForm', 'selected_warehouse_id')}
                                value={this.state.all_warehouses.find(s => s.value == this.state.dashboardFilterForm.selected_warehouse_id)}
                                isSearchable={true}
                                isClearable={true}
                                placeholder="All Warehouses"
                                containerHeight="33px"
                                fontSize="93%"
                            />
                        </div>
                        <div className="col-sm-3 text-end">
                            <button type="submit" className="btn btn-primary" style={{ height: "38px" }}>Go</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    }

    render() {
     
        return (
            <ApplicationLayout>
                <Helmet><title>My Dashboard</title></Helmet>
                <div className="row m-0 bg-white">
                    <div className="col-sm-12 p-0">
                        <ul className="nav nav-tabs d-flex" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/my_dashboard')} className={`nav-link active`} id="mydashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="mydashboard" aria-selected="true">My Dashboard </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/dashboard')} className={`nav-link`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard </button>
                            </li>


                            <li className="nav-item ms-auto" role="presentation">
                                <button onClick={() => this.props.history.push('/new_releases')}
                                    className={`nav-link`} id="release-tab" data-bs-toggle="tab" data-bs-target="#release" type="button" role="tab" aria-controls="release" aria-selected="true">New Releases</button>
                            </li>
                            <li className="nav-item " role="presentation">
                                <button onClick={() => this.props.history.push('/help/all_tutorials')}
                                    className={`nav-link`} id="help-tab" data-bs-toggle="tab" data-bs-target="#help" type="button" role="tab" aria-controls="help" aria-selected="true">Help & Tutorial </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.addFeedbackModalInit()}
                                    className={`nav-link`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="true">FeedBack</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/training_list')}
                                    className={`nav-link`} id="training-tab" data-bs-toggle="tab" data-bs-target="#training" type="button" role="tab" aria-controls="training" aria-selected="true">Schedule a Training</button>
                            </li>

                        </ul>
                    </div>
                </div> 
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane  show active" id="home" role="tabpanel" aria-labelledby="mydashboard-tab">
                        <div className="container-fluid p4" >
                            <div className={dashboardStyles.tab_content_header}>
                                <GroupUpdateHeader />
                                <hr className="m0 mt10" />
                                <div className="row">
                                    <div className="col-md-8">
                                        {this.renderWidgets()}
                                    </div>
                                    <div className="col-md-4">
                                        <AssetQuickNavigation props={this.props} />
                                        {this.props.permissions.group_modules.includes("isc")
                                            ? <ItemQuickNavigation props={this.props} />
                                            : null}
                                        <ReportQuickNavigation props={this.props} />
                                        {this.dashboardFilterFormJsx()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {this.state.show === true ? <FeedbackModal close={this.closeFeedback} /> : null}
            </ApplicationLayout>
        )

        
    }
    
}

const mapStateToProps = state => {
    
    return {
        access_token            :   state.auth.access_token,
        user_name               :   state.app.user_info?.name??'',
        group_info              :   state.app.group_info ? state.app.group_info : null,
        group_name              :   state.app.group_info ? state.app.group_info.group_name : '',
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
        firebase_app_version     :   state.app && state.app.firebase_app_version ? state.app.firebase_app_version : 1,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

export default connect(mapStateToProps)(MyDashboard);