import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import AssetService from '../../../services/AssetService';
import uuid from 'react-uuid';
import DualCounterlogModal from './DualCounterlogModal';

class CounterlogModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.counterlogFormInit         =   {
            transaction_id                  :   null,
            asset_id                        :   '',
            feed_date                       :   '',
            feed_date_time_display          :   moment(new Date()),
            shift_id                        :   '',
            remarks                         :   '',
            hourmeter                       :   '',
            hourmeter2                      :   '',
            hourmeter3                      :   '',
            hourmeter4                      :   '',
            odometer                        :   '',
            odometer2                       :   '',
            odometer3                       :   '',
            operation_cycle                 :   '',
            operation_cycle2                :   '',
            operator_ids                    :   []
        };

        this.state                      =   {
            date                            :   moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            date_display                    :   '',
            shiftData                       :   null,
            saveFormSubmitting              :   false,
            loadingCounterlogFormData       :   false,
            access_token                    :   '',
            copy_reading                    :   'N',
            updateCounterlogForm            :   {...this.counterlogFormInit},
            previousShiftReading            :   null,
            latestCounterLog                :   null,
            linkedSite                      :   null,
            assetData                       :   null,
            editCounterlog                  :   null,
            employeeConfiguration           :   null,
            searchedEmployee                :   [],
            operator_exist                  :   'Y',
            defective_meter                 :   'N'
        }
        this.counterlogModalId          =   uuid();
        this.CounterLogModalRef         =   React.createRef();
        this.subAssemblyUrl             =   IAM_API_BASE_URL_2 + '/' + 'sub_assembly';
        this.counterlogModalFormId      =   this.counterlogModalId + 'Form';


        this.clFormDataUrl              =   IAM_API_BASE_URL_2 + '/counterlog/add_form_data';
        this.clShiftReadingPostUrl      =   IAM_API_BASE_URL_2 + '/counterlog/shift_reading';
        this.clShiftReadingPutUrl       =   IAM_API_BASE_URL_2 + '/counterlog/shift_detail';
 


        this.clShiftReadingDeleteUrl    =   IAM_API_BASE_URL_2 + '/counterlog/shift_detail/__replace_transaction_id';



        this.dualCounterlogModalRef     =   React.createRef();
    }

    componentDidMount() {
        this.updateCounterlogModal      =   new Modal(document.getElementById(this.counterlogModalId), {keyboard: false, backdrop: false});
    }
    
    updateCounterlogInit                =   (shiftData, date, assetData) => {
        if(shiftData) {
            if(this.props.setting && this.props.setting.enable_opening_closing_cl && this.props.setting.enable_opening_closing_cl == 'Y') {
                if (this.dualCounterlogModalRef.current) {
                    this.dualCounterlogModalRef.current.initModalHandler(shiftData.id, date, assetData.asset_id);
                }
                this.setState({
                    assetData                       :   assetData, 
                    latestCounterLog                :   assetData ? assetData.latest_counterlog : null 
                });
            } else {
                let feed_date_time_display          =   moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY') + ' ' + shiftData.shift_start;
                this.setState({
                    copy_reading                    :   'N',
                    operator_exist                  :   'Y',
                    defective_meter                 :   'N',
                    searchedEmployee                :   [],
                    loadingCounterlogFormData       :   true,
                    assetData                       :   assetData, 
                    shiftData                       :   shiftData, 
                    date                            :   date,
                    date_display                    :   moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY'),
                    updateCounterlogForm            :   {
                        ...this.counterlogFormInit,
                        feed_date_time_display  :   feed_date_time_display,
                        asset_id                :   assetData.asset_id,
                        feed_date               :   date,
                        shift_id                :   shiftData.id
                    }
                });
                this.updateCounterlogModal.show();
                let params                  =   {
                    asset_id                    :   assetData.asset_id,
                    feed_datetime               :   shiftData.feed_datetime,
                    get_previous_shift_reading  :   "Y",
                    get_linked_site             :   "Y"
                }
                
                HttpAPICall.withAthorization('GET', this.clFormDataUrl, this.props.access_token, params, null, (response) => {
                    let respData = response.data;
                    this.setState({
                        previousShiftReading    :   respData.previous_shift_reading,
                        linkedSite              :   respData.linked_site,
                        assetData               :   respData.asset,
                        latestCounterLog        :   respData.asset.latest_counterlog,
                        employeeConfiguration   :   respData.employee
                    },() => {
                        if(this.state.employeeConfiguration && this.state.employeeConfiguration.shift_operator_track == "Y" && this.state.previousShiftReading && this.state.previousShiftReading.operator_ids){
                            let previousOperator =  this.state.employeeConfiguration && this.state.employeeConfiguration.linked_employees?.length > 0 ? this.state.employeeConfiguration.linked_employees.filter(em => {return(this.state.previousShiftReading.operator_ids.includes(em.enc_id))}) : [];
                        
                        if(previousOperator && previousOperator.length > 0 ){
                            this.setState({
                                searchedEmployee : previousOperator.map(operator => {return({value : operator.enc_id,label : operator.display_full_name})}),
                                updateCounterlogForm    :   {...this.state.updateCounterlogForm,operator_ids :  previousOperator.map(operator => {return(operator.enc_id)})},});
                        }else{
                            this.setState({
                                searchedEmployee : null,
                                updateCounterlogForm    :   {...this.state.updateCounterlogForm,operator_ids :  []},
                            });
                        }
                        }
                    });
                }).then(() => this.setState({ loadingCounterlogFormData : false, }));
    
            }
        }
    }
    
    editCounterlogInit                  =   (shiftCounterLogDetail) =>  {
        if(shiftCounterLogDetail) {
            let asset               =   shiftCounterLogDetail.asset;
            let counterlog          =   shiftCounterLogDetail.counterlog;
            let shift               =   shiftCounterLogDetail.shift;

            if(this.props.setting && this.props.setting.enable_opening_closing_cl && this.props.setting.enable_opening_closing_cl == 'Y') {
                if (this.dualCounterlogModalRef.current) {
                    this.dualCounterlogModalRef.current.initModalHandler(shift.id, counterlog.feed_date, asset.asset_id);
                }
            } else {
                let assetMeters                 =   AssetService.avaialbleAssetMeters(shiftCounterLogDetail.asset);
                let meterReading                =   {};
                let absoluteReading             =   {};
                
            
                this.updateCounterlogModal.show();
                
                let params                  =   {
                    asset_id                    :   asset.asset_id,
                    feed_datetime               :   counterlog.feed_datetime,
                    get_previous_shift_reading  :   "Y",
                    get_linked_site             :   "Y"
                }
                this.setState({loadingCounterlogFormData : true})
                HttpAPICall.withAthorization('GET', this.clFormDataUrl, this.props.access_token, params, null, (response) => {
                    let respData = response.data;
                    let previousShiftReading =  respData.previous_shift_reading
                    this.setState({
                        previousShiftReading    :   respData.previous_shift_reading,
                        linkedSite              :   respData.linked_site,
                        assetData               :   respData.asset,
                        latestCounterLog        :   respData.asset.latest_counterlog,
                        employeeConfiguration   :   respData.employee,
                        copy_reading                    :   'N',
                        defective_meter                 :   counterlog && counterlog.defective_meter ? counterlog.defective_meter : 'N',
                        assetData                       :   asset,
                        shiftData                       :   {...shift,feed_datetime : counterlog.feed_datetime} ,
                        date                            :   counterlog.feed_date,
                        date_display                    :   counterlog.feed_date_display,
                    },() => {
                        assetMeters.forEach((meter, mk) => {
                            let input_state_key       =     AssetService.getCounterlogReadingInputKey(meter.key);
                            // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]};
                        
                            meterReading[input_state_key]  = counterlog[meter.abs_key];
                            absoluteReading[meter.abs_key]  = previousShiftReading ?  Number(counterlog[meter.abs_key]) - Number(previousShiftReading[meter.abs_key])  : 0 ;
                        });
                        this.setState({
                        
                            updateCounterlogForm            :   {
                                ...this.counterlogFormInit,
                                ...meterReading,
                                ...absoluteReading,
                                asset_id                :   asset.asset_id,
                                transaction_id          :   counterlog.transaction_id,
                                feed_date_time_display  :   counterlog.feed_date_time_display,
                                feed_date               :   counterlog.feed_date,
                                remarks                 :   counterlog.remarks, 
                            operator_ids              :  shift.operators && shift.operators.length > 0 ?  shift.operators.map(op => op.enc_id) : [],
                            },
                            operator_exist              :   counterlog.operator_exist,
                            searchedEmployee            :   shift.operators && shift.operators.length > 0 ?  shift.operators.map(op => {return({value:op.enc_id,label : op.display_full_name})})   : []
                        });
                        
                    });
                }).then(() => this.setState({ loadingCounterlogFormData : false, }));
            }
        }
    }

    deleteCounterlog                    =   (transaction_id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.clShiftReadingDeleteUrl.replace('__replace_transaction_id', transaction_id), this.props.access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    if(this.props.afterDeleteCounterlog) {
                        this.props.afterDeleteCounterlog()
                    }
                    if (this.dualCounterlogModalRef.current) {
                        this.dualCounterlogModalRef.current.initModalClose();
                    }
                });
            }
        });
    }
    
    copyPreviousHandler                 =   ()  =>  {
        if(this.state.copy_reading == "Y" && this.state.previousShiftReading) {
            this.setState({updateCounterlogForm : {
                ...this.state.updateCounterlogForm,
                hourmeter               :   this.state.previousShiftReading.hours_abs,
                hourmeter2              :   this.state.previousShiftReading.hours_abs2,
                hourmeter3              :   this.state.previousShiftReading.hours_abs3,
                hourmeter4              :   this.state.previousShiftReading.hours_abs4,
                odometer                :   this.state.previousShiftReading.odometer_abs,
                odometer2               :   this.state.previousShiftReading.odometer_abs2,
                odometer3               :   this.state.previousShiftReading.odometer_abs3,
                operation_cycle         :   this.state.previousShiftReading.cycle_abs,
                operation_cycle2        :   this.state.previousShiftReading.cycle_abs2
            }});
        }
    }
    
    submitUpdateCounterlogHandler       =   (e) =>  {
        e.preventDefault();
        let frmData = {...this.state.updateCounterlogForm,operator_exist : this.state.operator_exist,defective_meter : this.state.defective_meter}
        if(this.state.updateCounterlogForm && this.state.updateCounterlogForm.transaction_id) {
            this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('PUT', this.clShiftReadingPutUrl, this.props.access_token, {}, {...frmData}, (resp) => {
                this.afterSubmitUpdateCounterlogHandler(resp);
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('POST', this.clShiftReadingPostUrl, this.props.access_token, {}, {...frmData}, (resp) => {
                this.afterSubmitUpdateCounterlogHandler(resp);
           }).then(() => this.setState({ saveFormSubmitting: false }));
        }
    }
    
    afterSubmitUpdateCounterlogHandler  =   (response)  =>  {
        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
        let refreshAssetCard = true
        if(this.state.date && this.state.assetData && this.state.assetData.latest_counterlog && this.state.assetData.latest_counterlog.feed_date ){
            var date1 = new Date(this.state.date);
            var date2 = new Date(this.state.assetData.latest_counterlog.feed_date);
            var same = date2 <= date1 ? true : false;
            refreshAssetCard = same == true ? true : false
        }
        
        if(this.props.afterSubmitCounterlog){
            this.props.afterSubmitCounterlog(this.state.date, this.state.assetData.asset_id,refreshAssetCard);
        }
        this.updateCounterlogModal.hide();
    }

    render                              =   ()  =>  {
        let labels                          =   this.state.assetData && this.state.assetData.labels ? this.state.assetData.labels : {};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.assetData);
    
        return (
            <>
                <div className="modal fade" id={this.counterlogModalId} tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable">
                        <form className="bg-white p-1" onSubmit={this.submitUpdateCounterlogHandler} id={this.counterlogModalFormId}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="searchItemModalLabel">{this.state.editCounterlog ? "Edit" : "Update"} Counterlog for : {this.state.date_display} {this.state.shiftData ? this.state.shiftData.shift_name : ''} ({this.state.shiftData ? this.state.shiftData.shift_start : ''})</h5>
                                    <div className='text-end'>
                                        <h5 className="modal-title">Site : {this.state.linkedSite ? this.state.linkedSite.site_name : ''}</h5>
                                    </div>
                                </div>
                                {this.state.loadingCounterlogFormData
                                    ? <Loader />
                                    : <Ax>
                                        <div className='p-1 bg-light border-bottom'>
                                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="modal-body">

                                            <div className='row my-1'>
                                                <div className='offset-3 col-sm-4'>
                                                    <small className="form-text">Previous Shift Reading</small>
                                                </div>
                                                {this.state.defective_meter == 'Y' ?
                                                    <div className='col-sm-2' style={{ width: "20.83%" }}>
                                                        <small className="form-text">Absolute Running</small>
                                                    </div> : null}
                                                <div className={this.state.defective_meter == 'N' ? 'col-sm-5' : 'col-sm-2'} style={{ width: this.state.defective_meter == 'N' ? "41.66%" : "20.83%" }} >
                                                    <small className="form-text">Counter log Reading</small>
                                                </div>
                                            </div>

                                            {assetMeters.map((meter, k) => {
                                                let input_state_key = AssetService.getCounterlogReadingInputKey(meter.key);
                                                return <div className='row my-2' key={k}>
                                                    <div className='col-sm-3'><label className="form-label require">{meter.label}</label></div>
                                                    <div className='col-sm-4'>
                                                        <input
                                                            className="form-control"
                                                            value={this.state.previousShiftReading ? this.state.previousShiftReading[meter.abs_key] : ''}
                                                            disabled={true}
                                                            style={{ height: "30px", fontSize: "93%" }}
                                                        />
                                                        {this.state.latestCounterLog &&
                                                            this.state.previousShiftReading &&
                                                            this.state.latestCounterLog.counter_log_id != this.state.previousShiftReading.counter_log_id
                                                            ? <small className="form-text">
                                                                Latest Reading {this.state.latestCounterLog ? `${this.state.latestCounterLog[meter.abs_key]} @ ${this.state.latestCounterLog.feed_date_time_display}` : '-'}
                                                            </small>
                                                            : null}
                                                    </div>
                                                    {this.state.defective_meter == 'Y' ?
                                                        <div className={'col-sm-2'} style={{ width: "20.83%" }} >

                                                            <input
                                                                name={this.state.updateCounterlogForm[meter.abs_key]}
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => {
                                                                    if (e && e.target && e.target.value && this.state.previousShiftReading) {
                                                                        this.setState({
                                                                            updateCounterlogForm: {
                                                                                ...this.state.updateCounterlogForm,
                                                                                [meter.abs_key]: e.target.value,
                                                                                [input_state_key]: Number(e.target.value) + Number(this.state.previousShiftReading[meter.abs_key])
                                                                            }
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            updateCounterlogForm: {
                                                                                ...this.state.updateCounterlogForm,
                                                                                [meter.abs_key]: e.target.value,
                                                                                [input_state_key]: e.target.value
                                                                            }
                                                                        })
                                                                    }

                                                                }}
                                                                placeholder="Abs running"
                                                                disabled={this.state.copy_reading == "Y"}
                                                                value={this.state.updateCounterlogForm[meter.abs_key]}
                                                                required={true}
                                                                // autoFocus={true}
                                                                // tabIndex={}
                                                                style={{ height: "30px", fontSize: "93%" }}
                                                            />
                                                        </div> : null}
                                                    <div className={this.state.defective_meter == 'N' ? 'col-sm-5' : 'col-sm-2'} style={{ width: this.state.defective_meter == 'N' ? "41.66%" : "20.83%" }} >

                                                        <input
                                                            name={input_state_key}
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            onChange={(e) => this.formInputHandler(e, 'updateCounterlogForm')}
                                                            placeholder="Please Enter Reading"
                                                            disabled={this.state.copy_reading == "Y" || this.state.defective_meter == 'Y' ? true : false}
                                                            value={this.state.updateCounterlogForm[input_state_key]}
                                                            required={true}
                                                            tabIndex={k+1}
                                                            autoFocus={k == 0}
                                                            style={{ height: "30px", fontSize: "93%" }}
                                                        />
                                                    </div>
                                                </div>
                                            })}

                                            <div className="row align-items-center my-2">
                                                <div className="col-sm-3"><label className="form-label">Counterlog Date</label></div>
                                                <div className="col-md-4 add_calender_section">
                                                    <DatePicker
                                                        name="previous_cl_date"
                                                        className="form-control form-control-sm"
                                                        disabled={true}
                                                        value={this.state.previousShiftReading ? this.state.previousShiftReading.feed_date_time_display : null}
                                                    />
                                                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "5px" }} />
                                                </div>
                                                <div className="col-md-5 add_calender_section">
                                                    <DatePicker
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        disabled={true}
                                                        value={this.state.updateCounterlogForm.feed_date_time_display}
                                                    />
                                                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "5px" }} />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-2">
                                                <div className="col-sm-3"><label className="form-label">Remark</label></div>
                                                <div className="col-md-9">
                                                    <textarea
                                                        name="remarks"
                                                        value={this.state.updateCounterlogForm.remarks}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Please Enter Remark"
                                                        onChange={(e) => this.formInputHandler(e, 'updateCounterlogForm')}
                                                        style={{ height: "80%", fontSize: "93%" }}
                                                    />
                                                </div>
                                            </div>
                                            {this.props.group_permission && this.props.group_permission.group_modules.includes("hrm") && this.state.employeeConfiguration && this.state.employeeConfiguration.shift_operator_track == "Y"
                                                ? <div className="row align-items-center my-2">
                                                    <div className="col-sm-3"><label className={['form-label', this.state.employeeConfiguration && this.state.employeeConfiguration.shift_operator_required == "Y" ? "require" : ''].join(' ')}>Operator Name</label></div>
                                                    <div className="col-md-9">
                                                        <InputEmployeeSearch
                                                            placeholder="Please Select Employee"
                                                            changeEvent={(selectedOption) => {
                                                                this.setState({
                                                                    searchedEmployee: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                                });
                                                                this.tapSelectChange(selectedOption, 'updateCounterlogForm', 'operator_ids')
                                                            }}
                                                            menuPlacement="top"
                                                            search_site_ids={this.state.linkedSite && this.state.linkedSite.id ? [this.state.linkedSite.id] : ''}
                                                            loadDefaultOptions={true}
                                                            isMulti={true}
                                                            transaction_date={this.state.shiftData && this.state.shiftData.feed_datetime ? this.state.shiftData.feed_datetime : '' }
                                                            required={this.state.employeeConfiguration && this.state.employeeConfiguration.shift_operator_required == "Y" && this.state.operator_exist == 'Y' ? true : false}
                                                            disabled={this.state.operator_exist == "N" ? true : false}
                                                            linkedEmployee={this.state.employeeConfiguration && this.state.employeeConfiguration.linked_employees && this.state.employeeConfiguration.linked_employees.length > 0 ? this.state.employeeConfiguration.linked_employees : []}
                                                            value={this.state.searchedEmployee && this.state.searchedEmployee.length > 0 ? this.state.searchedEmployee.map(a => { a.label = a.label; return a; }) : []}
                                                        />
                                                    </div>
                                                    {this.state.employeeConfiguration && this.state.employeeConfiguration.shift_operator_required == "Y"
                                                        ? <div className='offset-3 col-md-9'>
                                                            <input
                                                                name="operator_exist "
                                                                type="checkbox"
                                                                value={this.state.operator_exist}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        operator_exist: this.state.operator_exist == 'Y' ? 'N' : 'Y',
                                                                        searchedEmployee: this.state.operator_exist == 'Y' ? [] : this.state.searchedEmployee,
                                                                        updateCounterlogForm: this.state.operator_exist == 'Y' ? { ...this.state.updateCounterlogForm, operator_ids: [] } : { ...this.state.updateCounterlogForm }
                                                                    })
                                                                }}
                                                                checked={this.state.operator_exist == 'N'}
                                                                className="form-check-input"
                                                                id="operator_exist"
                                                            />
                                                            <label className="form-check-label mx-2" htmlFor="operator_exist">Operator Not Available</label>
                                                        </div> : null}
                                                </div>
                                                : null}
                                        </div>
                                        <div className="modal-footer d-flex justify-content-between  row">
                                            <div className="col text-start">
                                                {this.state.previousShiftReading && JSON.stringify(this.state.previousShiftReading) !== JSON.stringify({}) && this.state.assetData && this.state.assetData.cl_attribute == 'Y'
                                                ? <div className='my-1 px-2'>
                                                    <input
                                                        name="defective_meter"
                                                        type="checkbox"
                                                        value={this.state.defective_meter}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                defective_meter: this.state.defective_meter == 'N' ? 'Y' : 'N',
                                                                updateCounterlogForm: {
                                                                    ...this.counterlogFormInit,
                                                                    feed_date: this.state.updateCounterlogForm.feed_date,
                                                                    feed_date_time_display: this.state.updateCounterlogForm.feed_date_time_display,
                                                                    remarks: this.state.updateCounterlogForm.remarks,
                                                                    operator_ids: this.state.updateCounterlogForm.operator_ids,
                                                                    asset_id: this.state.updateCounterlogForm.asset_id,
                                                                    transaction_id: this.state.updateCounterlogForm.transaction_id,
                                                                    shift_id: this.state.updateCounterlogForm.shift_id
                                                                }
                                                            })
                                                        }}
                                                        checked={this.state.defective_meter == 'Y'}
                                                        className="form-check-input"
                                                        id="defective_meter"
                                                        disabled={this.state.copy_reading == 'Y' ? true : false}
                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="defective_meter">CounterLog Meter Defective </label>
                                                </div> : null}
                                                <div className='my-1 px-2' >
                                                    <input
                                                        name="copy_reading"
                                                        type="checkbox"
                                                        value={this.state.copy_reading}
                                                        onChange={(e) => {
                                                            this.setState({ copy_reading: this.state.copy_reading == 'N' ? 'Y' : 'N' }, () => this.copyPreviousHandler())
                                                        }}
                                                        checked={this.state.copy_reading == 'Y'}
                                                        className="form-check-input"
                                                        id="copy_reading"
                                                        disabled={this.state.defective_meter == 'Y' ? true : false}
                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="copy_reading">Copy Reading from Previous Shift </label>
                                                </div>
                                            </div>
                                            <div className="col text-end">
                                                <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" onClick={() => { this.setState({ copy_reading: 'N' }) }}>Close</button>
                                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={this.counterlogModalFormId}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                            </div>

                                        </div>
                                    </Ax>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <DualCounterlogModal 
                    ref={this.dualCounterlogModalRef}

                    deleteCounterlog={(transaction_id) => {
                        this.deleteCounterlog(transaction_id);
                    }}


                    // afterDeleteCounterlog={() => {
                    //     this.setState({isActivityPerform : true, isCounterLogPerform : true},() => this.closeSummaryDetails())
                    // }}


                    afterCounterlogSave={(dates, asset_id) => {

                        console.log('dates, asset_id', dates, asset_id);

                        dates.forEach((date, i) => {
                            let refreshAssetCard = false;
                            if(this.state.latestCounterLog) {
                                var date1 = new Date(date);
                                var date2 = new Date(this.state.latestCounterLog.feed_date);
                                var same = date2 <= date1 ? true : false;
                                refreshAssetCard = same == true ? true : false
                            } else {
                                refreshAssetCard    =    true;
                            }
                            setTimeout(() => {
                                if(this.props.afterSubmitCounterlog) {
                                    this.props.afterSubmitCounterlog(date, asset_id,refreshAssetCard);
                                }
                            }, 1000 * (i+1));
                        })
                    }} 
                />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        group_permission        :   state.app.acl_info,
        setting                 :   state.app && state.app.setting ? state.app.setting : [],
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(CounterlogModal);