

import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Chart } from "react-google-charts";
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from "../../components/ui/TapSelect";
import TapIcon from '../../services/TapIcon';
import UpdateMyDashboardForm from "../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";
import { useParams } from 'react-router-dom';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { useSelector } from 'react-redux';
import WidgetFilterJsx from './WidgetFilterJsx';
import DateRangeService from '../../services/DateRangeService';
import DateService from '../../services/DateService';
import { ToastBody } from 'react-bootstrap';

function AssetProductivityBarWidget(props) {

    const accessToken           =   useSelector(state => state.auth.access_token);
    const iamUserSite           =   useSelector(state => state.app.user_sites);
    const {id}                  =   useParams();

    const initFilterForm        =   {
        filter_site_id              :   '',
        key                         :   'daily_productivity_widget'
    }

    const [widgetData, setWidgetData]   =   useState({
        filterForm                  :   initFilterForm,
        sites                       :   [],
        allPeriods                  :   [],
        remarkTime                  :   '',
        loading                     :   false,
        dailyOrMonthly              :   'daily',
        prodWidgetdata              :   [],
        date_range                  :   null,
    })

    const options               =   [
        { value: "daily", label: "Daily" },
        { value: "monthly", label: "Monthly" }
    ];

    useEffect(() => {
        initializeFormFilter();
    }, [id]);

    useEffect(() => {
        widgetDataApi();
    }, [id, widgetData.dailyOrMonthly, widgetData.date_range, widgetData.filterForm]);

    const initializeFormFilter  = () => {
        if(props.configDetailsData) {
            setWidgetData(pd => ({ ...pd,
                sites       :   iamUserSite.filter(site => props.configDetailsData.sites.map(site => site.id).includes(site.id)).map((s) => ({ value: s.id, label: `${s.site_name} (${s.site_code})`})),
            }));
        }
        handleFilterChange({value: 'daily'}, 'dailyOrMonthly');
    }

    const widgetDataApi         = (reload = '') => {
        const {configDetailsData}   =   props;
        if(configDetailsData) {
            setWidgetData(pre => ({...pre, loading : true}));
            let params              =   {...widgetData.filterForm,asset_prod_config_id : id, reload};
            let dateFrom            =   '';
            let dateTo              =   '';
            if(widgetData.dailyOrMonthly === 'daily') {
                dateFrom            =   DateService.getFirstAndLastDayByMonth(widgetData.date_range, 'first', 'YYYY-MM-DD');
                dateTo              =   DateService.getFirstAndLastDayByMonth(widgetData.date_range, 'last', 'YYYY-MM-DD');
            }else {
                dateFrom            =   DateService.getFirstAndLastDayByYear(widgetData.date_range, 'first','YYYY-MM-DD');
                dateTo              =   DateService.getFirstAndLastDayByYear(widgetData.date_range, 'last','YYYY-MM-DD');
            }
            if(configDetailsData.starting_date && dateFrom < configDetailsData.starting_date) {
                dateFrom            =   configDetailsData.starting_date;
            }
            if(configDetailsData.closing_date && dateTo > configDetailsData.closing_date) {
                dateTo              =   configDetailsData.closing_date;
            }

            params                  =   {...params, search_date_from : dateFrom, search_date_to : dateTo};

            if(dateFrom || dateTo) {
                HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset_productivity_config/widget', accessToken, params, {}, (res) => {
                    const respData      =   res.data;
                    setWidgetData(pre => ({
                        ...pre, 
                        prodWidgetdata  :   respData.data, 
                        remarkTime      :   respData.time
                    }));
                    // props.handleDetailData(respData);
                })
                .then(() => setWidgetData(pre => ({...pre, loading : false})))
                .catch(() => setWidgetData(pre => ({...pre, loading : false})));
            }
        }
    }

    const refreshHandle         =   ()  =>  {
        widgetDataApi('reload')
    }

    const handleFilterChange    = (selectedOption, field) => {        
        const selectedValue = selectedOption ? selectedOption.value : null;
        if (field === 'dailyOrMonthly') {
            const {configDetailsData}       =   props;
            if(configDetailsData) {

                const format                =   selectedValue === 'daily' ? 'MMM-YYYY' : 'YYYY';
                const steps                 =   selectedValue === 'daily' ? '+1 month' : '+1 year';   

                let starting_date           =   configDetailsData.starting_date 
                                                    ? configDetailsData.starting_date 
                                                    : DateService.dateFormat(new Date(new Date().getFullYear()-5, 0, 1), 'YYYY-MM-DD');
                let closing_date            =   configDetailsData.closing_date 
                                                    ? configDetailsData.closing_date 
                                                    : DateService.dateFormat(new Date()-5, 'YYYY-MM-DD');

                if(closing_date > DateService.dateFormat(new Date(), 'YYYY-MM-DD')) {
                    closing_date            =   DateService.dateFormat(new Date(), 'YYYY-MM-DD');
                }
                const dateRanges            =   DateRangeService(starting_date, closing_date, steps, format);
                setWidgetData(pre => ({
                    ...pre,
                    date_range      :   dateRanges[dateRanges.length-1],
                    dailyOrMonthly  :   selectedValue,
                    filterForm      :   {...widgetData.filterForm, key: selectedValue === 'monthly' ? 'month_productivity_widget' : initFilterForm.key},
                    allPeriods      :   dateRanges.map(p => ({ value: p, label: p }))
                }));
            }
        } else if(field === 'filter_site_id') {
            setWidgetData(pre => ({
                ...pre,
                filterForm      :   {...widgetData.filterForm, filter_site_id : selectedValue}
            }));
        } else {
            setWidgetData(pre => ({
                ...pre,
                date_range      :   selectedValue
            }));
        }
    }

    const widgetJsx             =   ()  =>  {
        const { prodWidgetdata, allPeriods, loading, dailyOrMonthly, filterForm, date_range, remarkTime, sites } =   widgetData;

        let data = [];
        let chartData = [['Month', 'Total Production'], ...data];
        if(prodWidgetdata.length > 0) {
            data = prodWidgetdata.map(d => [
                d.transaction_date,
                parseFloat(d.total_production)
            ]);
            chartData = [['Month', 'Total Production'], ...data];
        }

        return (
            <div className="row bg-white py-2">
                <div className="col-md-12">
                    <div className="card mt-1">
                        <div className="card-body">
                            <WidgetFilterJsx options={options} dailyOrMonthly={dailyOrMonthly} allPeriods={allPeriods} filterForm={filterForm} date_range={date_range} sites={sites} refreshHandle={refreshHandle} handleFilterChange={(value, key) => handleFilterChange(value, key)} name='Period Wise Asset Productivity'/>
                            <div className="row mt-2 align-items-center">
                                {
                                    loading 
                                    ?
                                    <Loader/>
                                    :
                                    prodWidgetdata.length > 0
                                    ?
                                    <>
                                        <div className="col-12 pr0 mr0 text-end">
                                            <Chart
                                                chartType={"ColumnChart"}
                                                data={chartData}
                                                options={{
                                                    legend: { position: 'left' },
                                                    colors: ['#53A8E3', '#EC4E4E'],
                                                    targetAxisIndex: 1,
                                                    pieHole: 0.65,
                                                    vAxis: {
                                                        viewWindow: {
                                                            min: 0, 
                                                        },
                                                    },
                                                    chartArea: { right: 10, top: 20, width: "76%", height: "69%" },
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-bordered table-borderless bg-white my-2">
                                                    <thead className="table-secondary">
                                                        <tr className="text-center">
                                                            <th className="text-start" >Date/Month</th>
                                                            {
                                                            prodWidgetdata.length > 0 
                                                            ?
                                                                <>
                                                                    {prodWidgetdata.map((i, r) => (
                                                                        <td className="text-end">
                                                                            {i.transaction_date} 
                                                                        </td>
                                                                    ))}
                                                                </>
                                                            : <th >No Record</th>
                                                            }
                                                        </tr>
                                                    </thead>
                                                    {
                                                        loading
                                                        ? 
                                                        <Loader />
                                                        : 
                                                        <tbody>
                                                            <tr className="text-center">
                                                                <th className="text-start" >Total Production</th>
                                                                {prodWidgetdata.length > 0 
                                                                ?
                                                                    <>
                                                                        {prodWidgetdata.map((i, r) => (
                                                                            <td className="text-end">
                                                                                {i.total_production} 
                                                                            </td>
                                                                        ))}
                                                                    </> 
                                                                : <td colSpan={6}>No Record</td>
                                                                }
                                                            </tr>
                                                        </tbody>
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                                <div className="col-sm-12  mt-1 text-muted fs11">
                                    Report as of {remarkTime}
                                </div>
                            </div> 
                        </div>
                        {/* <UpdateMyDashboardForm ref={this.updateMyDashboardRef} /> */}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {widgetJsx()}
        </>
    )

}

export default AssetProductivityBarWidget;



//     pinMyDashboardHandler = (widgetData = null) => {
//         this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData, { key: "period_wise_site_productivity", name: "Period Wise Site Productivity" });
//     }

//     refreshHandle = () => {
//         this.props.fetchProductivityWidget(this.props.configId, { ...this.state.filterForm, reload: "reload" });
//     }




