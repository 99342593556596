import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { connect } from 'react-redux';
import Status from '../../../components/ui/Status';
import Loader from '../../../components/ui/Loader/Loader';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import TapIcon from '../../../services/TapIcon';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { toast } from 'react-toastify';
import TicketDetailModal from './TicketDetailModal';
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import { DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';

class TicketsBoardManagement extends AppBaseComponent {

  constructor(props) {
    super(props);

      this.initAddRemarkForm           =          {
        status                       :          '',
        asset_status                 :          '',
        defect_codes                 :           [],
        remarks                       :           '',
        send_email_notification      :           "N"
    }

    this.ticketSearchFormInitialState   =   {
      'search_ticket_type_id'             :   '',
      'search_ticket'                     :   '',
      'search_ticket_id  '                :   null,
      'search_ticket_header'              :   '',
      'search_asset_id'                   :   null,
      'search_site_ids'                   :   '',
      'asset_group_ids'                   :   [],
      'search_asset_type'                 :   '',
      'search_tagged_users'               :   [],
      'search_ageing_days'                :   '',
      'closure_date_range'                :   '',
      'closure_date_range_start'          :   '',
      'closure_date_range_end'            :   '',
      'creation_date_range'               :   '',
      'creation_date_range_start'         :   '',
      'creation_date_range_end'           :   '',
      'due_date_range'                    :   '',
      'due_date_range_start'              :   '',
      'due_date_range_end'                :   '',
      'remark_ageing_days'                :   '',
      'search_ticket_status'              :   [],
      'ticket_with_asset'                 :   '',
      'search_tags'                       :   [],
      'search_employees'                  :   [],
      'search_workstation'                :   '',
      'search_sub_assembly'               :   '',
      'search_employee_id'                :   ''
  };

  this.ticketSearchFormLabel      =   {
      'search_ticket_type_id'         :   'Ticket Type : ',
      'search_ticket'                 :   'Ticket : ',
      'search_ticket_id'              :   'Ticket ID : ',
      'search_ticket_header'          :   'Ticket Header : ',
      'search_asset_id'               :   'Asset : ',
      'search_site_ids'               :   'Site : ',
      'asset_group_ids'               :   'Asset Type Groups : ',
      'search_asset_type'             :   'Asset Type : ',
      'search_tagged_users'           :   'Tagged Users : ',
      'search_ageing_days'            :   'Ageing Days : ',
      'closure_date_range'            :   'Completion Date Range : ',
      'creation_date_range'           :   'Creation Date Range : ',
      'due_date_range'                :   'Due Date Range : ',
      'remark_ageing_days'            :   'Remark Ageing Days : ',
      'search_ticket_status'          :   'Ticket Status : ',
      'ticket_with_asset'             :   'Ticket With Asset : ',
      'search_tags'                   :   'Tags : ',
      'search_employees'              :   'Linked Employees : ',
      'search_workstation'            :   'Workstation : ',
      'search_sub_assembly'           :   'Sub Assembly : ',
      'search_employee_id'             :   'Employee : '
  };


      this.state = {
          form_loading          :     false,
          columns               :     {},
          allStatusList         :     [],
          remark_form_loading   :    false,
          remarkFormData        :    [],
          allRemarkStatus       :    [],
          allAssetStatus        :    [],
          allDefectCode         :    [],
          addRemarkForm         :     {...this.initAddRemarkForm},
          ticketData            :     null,
          draggedItem           :     null,
          draggedItemSourceColumn   :   null,
          dragData                  :   null,
          currentStatusId           :   '',
          ticketSearchForm              :            {...this.ticketSearchFormInitialState},
            submittedTicketFilterForm     :            {...this.ticketSearchFormInitialState},
            allTicketType                 :            [],
            iam_asset_types               :            [],
            iam_user_sites                :            [],
            searchTicketOptions         :               [{value:'Y',label:'Yes'},{value:'N',label:'No'}],
            allAssetTypesGroupsList   :             [],
            allTaggedUsers            :             [],
            form_data_loading         :             false
      };
      this.ticketDetailRef          =   React.createRef();
      this.ticketListUrl            =   IAM_API_BASE_URL_2 + '/ticket/list';
      this.ticketFormDataUrl        =   IAM_API_BASE_URL_2 + '/ticket/list_form_data';
      this.remarkStatusFormDataUrl  =   IAM_API_BASE_URL_2 + '/ticket/remark_status_form_data';
      this.remarkStatusUrl          =   IAM_API_BASE_URL_2 + '/ticket/remark_status';

  }

  componentDidMount() {
    this.getTicketFormData();
    this.initalizeAppData(this.props)
    this.ticketSearchModal         =    new Modal(document.getElementById('ticketSearchModal'), {keyboard: false, backdrop :false});
    this.addRemarksDragModal            =    new Modal(document.getElementById('addRemarksDragModal'), {keyboard: false, backdrop :false});
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
       this.initalizeAppData(nextProps)
    }
        
}

  initalizeAppData    =   (props)     =>      {
    this.setState({
        iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
        iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`}}) : [],
        allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
    });
}


  allTicketOptions                   =       [{value: 'my_ticket', label: 'My Ticket (Created by me)'},
                                             {value: 'my_tagged_tickets', label: ' My Tagged Tickets'},]

  getTicketFormData = async () => {
    this.setState({ form_loading: true,form_data_loading : true });
  
    try {
      const resp = await HttpAPICall.withAthorization('GET', this.ticketFormDataUrl, this.props.access_token, {}, {});
      const respData = resp.data;
      const statusList = respData && respData.status_list && respData.status_list.length > 0 ? respData.status_list.filter(st => st.key !== 26 && st.key !== 10) : [];
      this.setState({
        filterFormData : resp.data,
        allTicketType : resp.data.ticket_types?.map((s) => { return {value: s.id, label: s.name}}),
        allTaggedUsers : resp.data.users?.map((s) => { return {value: s.id, label: s.full_name}}),
        allStatusList : resp.data.status_list?.map((s) => { return {value: s.key, label: s.name}}),
         ticketTags                  :   resp.data.tags && resp.data.tags.length > 0 ? resp.data.tags.map((tag) => { return {value: tag.name, label: tag.name}; }) : [],    
         ticket_allow_tag        :   resp.data && resp.data.setting && resp.data.setting.ticket_allow_tag,
         ticket_allow_employees  :   resp.data && resp.data.setting && resp.data.setting.ticket_allow_employees, 
    },() => this.setState({form_data_loading : false}))
      const apiCalls = statusList.map(element => {
        return this.loadListingTblData(1, element.key, element.name, element.color_code);
      });
  
      await Promise.all(apiCalls);
  
      this.setState({ form_loading: false });
    } catch (error) {
      console.error("Error loading form data:", error);
      this.setState({ form_loading: false });
    }
  };

  loadListingTblData = async (
    page = 1,
    status_id = '',
    status_name = '',
    status_color = ''
  ) => {
    this.setState({ listing_loading: true, listing_tbl_page: page });
  
    let params = {
      ...this.state.submittedTicketFilterForm,
      page: page,
      per_page: 50,
      search_ticket_status: [status_id],
      search_project_id: this.props?.projectId ? this.props.projectId : '',
      orderby: this.props?.projectId ? "project" : '',
    };
  
    try {
      const resp = await HttpAPICall.withAthorization(
        'GET',
        this.ticketListUrl,
        this.props.access_token,
        params,
        {}
      );
      const respData = resp.data;
      const newColumns = { ...this.state.columns };
  
      const columnId = `${status_id}`;
      const columnTitle = status_name;
  
      // Initialize column if it doesn't exist yet
      if (!newColumns[columnId]) {
        newColumns[columnId] = {
          id: columnId,
          title: columnTitle,
          status_text: status_name,
          status_color: status_color,
          items: [],
        };
      }
  
      // If no data returned, display "No Tickets Available" message
      if (respData.data.length === 0) {
        newColumns[columnId].items = [{ header: 'No Tickets Available for this Status' }];
      } else {
        // If data exists, update items for that status column
        newColumns[columnId].items = respData.data.map((item) => ({
          ...item,
          status_text: item.status_text || status_name,
          status_color: item.status_color || status_color,
        }));
      }
  
      this.setState({ columns: newColumns });
      return respData;
    } catch (error) {
      console.error('Error fetching ticket data:', error);
      throw error;
    } finally {
      this.setState({ listing_loading: false });
    }
  };
  
  
  onDragEnd       =     (result)     =>     {
    const { source, destination, draggableId } = result;
    console.log(source,destination,draggableId,'fff')

    // If the item is dropped outside of a droppable area, return
    if (!destination) return;

    // If the item is dropped in the same position, return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Get the source and destination columns
    const sourceColumn = this.state.columns[source.droppableId];
    const destinationColumn = this.state.columns[destination.droppableId];

    if (source.droppableId !== destination.droppableId) {
      let item = sourceColumn.items.find(st => st.activity_id == draggableId);
      this.setState({
        addRemarkForm: { ...this.initAddRemarkForm },
        draggedItem: item, // Store the dragged item in the state for later use
        draggedItemSourceColumn: sourceColumn,
        dragData: result
      })
      this.addRemarksDragModal.show()
      this.getAddRemarkFormData(item, destination.droppableId)
    } else {
      this.setState((prevState) => ({
        columns: {
          ...prevState.columns,
          [sourceColumn.id]: sourceColumn,
          [destinationColumn.id]: destinationColumn,
        },
      }));
    }
    

  };


  getAddRemarkFormData              =         (item,status_id)           =>          {
    this.setState({remark_form_loading: true, });
    HttpAPICall.withAthorization('GET',  this.remarkStatusFormDataUrl + '/' + item.activity_id, this.props.access_token, {}, {}, (resp) => {
        this.setState( {   remarkFormData  :     resp.data,
            ticketData            :     item,
            currentStatusId       :     status_id,
            addRemarkForm         :    {...this.state.addRemarkForm,status : status_id,status_color:item.status_color,status_text:item.status_text,
            defect_codes          : item.defect_codes && item.defect_codes.length > 0 ? item.defect_codes.map(s => s.id) : []},
            allRemarkStatus      :     resp.data.status_list?.map((s) => { return {value: s.key, label: s.name}}),
            allAssetStatus       :     resp.data.asset_status_list?.map((s) => { return {value: s.key, label: s.name}}),
            allDefectCode        :    resp.data.defect_codes &&  resp.data.defect_codes.length > 0 ?  resp.data.defect_codes?.map((s) => { return {value: s.id, label: s.name}}) : []
        })
    }).then(() => this.setState({remark_form_loading: false}));
  }


  submitAddRemarkDragForm = (e) => {
    e.preventDefault();
    const { source, destination, draggableId } = this.state.dragData;
    const sourceColumn = this.state.columns[source.droppableId];
    const destinationColumn = this.state.columns[destination.droppableId];
  
    let frmData = {
      ...this.state.addRemarkForm,
      activity_id: this.state.ticketData.activity_id,
      send_email_notification: this.state.addRemarkForm.send_email_notification,
    };
  
    this.setState({ saveFormSubmitting: true });
  
    HttpAPICall.withAthorization('PUT', this.remarkStatusUrl, this.props.access_token,{}, { ...frmData },  (response) => {
        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
  
        // Update the state of columns and rows after successful API response
        const updatedSourceColumn = { ...sourceColumn };
        updatedSourceColumn.items = updatedSourceColumn.items.filter(
          (item) => item.activity_id !== draggableId
        );
  
        const updatedDestinationColumn = { ...destinationColumn };
        updatedDestinationColumn.items.splice(
          destination.index,
          0,
          sourceColumn.items.find((item) => item.activity_id === draggableId)
        );
  
        this.setState((prevState) => ({
          columns: {
            ...prevState.columns,
            [updatedSourceColumn.id]: updatedSourceColumn,
            [updatedDestinationColumn.id]: updatedDestinationColumn,
          },
        }));
  
        this.addRemarksDragModal.hide();
      }
    ).then(() => {
      this.setState({ saveFormSubmitting: false });

    });
  };

  getTicketDetail     =     (id)      =>    {
    if(id) {
      this.ticketDetailRef.current.ticketModalInit(id)
    }
  }
  
  ticketSearchClear                  =        ()             =>        {
    this.setState({
        formSearchedElems              :   [],
        searchedAsset                  :   null,
        ticketSearchForm               :   {...this.ticketSearchFormInitialState},
        submittedTicketFilterForm      :   {...this.ticketSearchFormInitialState}
    }, () => {
        this.getTicketFormData(1);
        this.ticketSearchModal.hide();
    });
}


  ticketTransactionListingJsx        =       ()             =>        {
    let page_containt_style     =   {};
      if(this.props.createFromProject) {
          page_containt_style     =   {...page_containt_style} ;
      }

    return (<Ax>
    {
      this.props?.projectId 
      ? null
      :
      <div className="page_title row m0">
        <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
            <h3>Tickets</h3>
              <div className="text-end mt15">
                <button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={this.ticketSearchModalInit}>
                  <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                </button>
              </div>
        </div>
      </div>
    }
    <div className="container-fluid pl5">
      <div className="page_containt row" style={page_containt_style}>
          <div className={['pageTbl',this.state.minimizeTable ? 'col-3 ' : 'col-12'].join(' ')} >{this.ticketsBoardJsx()}</div>
      </div>
    </div>
    </Ax>);
}

  ticketsBoardJsx         =   ()      =>    {
    const { columns } = this.state;
    return(<Ax>
                    <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  onClearFilter={this.ticketSearchClear}  />
      <DragDropContext onDragEnd={this.onDragEnd}>
        {this.state.listing_loading || this.state.form_loading ? <div className='text-center'><Loader /></div>
          : <div className="row">
            <div className="col">
              <div className="horizontal-scroll-container">
                {Object.values(columns).map((column) => (
                  <div key={column.id} className="col ticket-column">
                    <div className="bg-light p-2"><b> <Status color={column.status_color}>{column.title}</Status></b></div>
                    <Droppable droppableId={column.id}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{ backgroundColor: "none" }}
                          className="list-group list-group-flush"
                        >
                          {column.items.map((item, index) => (
                            <Draggable
                              key={item.activity_id}
                              draggableId={item.activity_id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                // className="list-group-item"
                                >
                                  <div className="bg-white rounded">
                                    <div className='my-1' onClick={() => this.getTicketDetail(item.activity_id)}>
                                    <div className='d-flex justify-content-between px-2 text-sm' style={{ backgroundColor: "#DFFFFA", fontWeight: 400 }}>
                                      <div >
                                      {item.header.slice(0, 40)}{item.header.length > 40 ? '...' : ''}
                                      </div>
                                      <div onClick={() => this.getTicketDetail(item.activity_id)}>
                                        Ticket ID: {item.activity_id}
                                      </div>
                                    </div>
                                      {item.id && <Ax>
                                        <div className="px-2 mt-1 text-sm">
                                        {item.ticket_type ? item.ticket_type.name : ""}
                                        </div>
                                        <div className='d-flex justify-content-between px-2 text-sm'>
                                          <div>{item.created_at_date ? item.created_at_date : ""}</div>
                                          <div>Ageing : {item.aging ? item.aging : "0"} Days</div>
                                        </div>
                                        <hr className='bg-secondary my-1' />
                                        {item.asset && <div className="px-2 text-sm">{item.asset ? item.asset.name : ""} {item.asset && item.asset.asset_code ? <span>({item.asset.asset_code})</span> : ""}</div>}
                                        {/* <hr className='bg-secondary my-0' /> */}
                                        <div className="px-2 pb-1 text-sm">{item.site_name ? item.site_name : "-"}</div>
                                      </Ax>}
                                    </div>

                                  </div>

                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))}
              </div>
            </div>
          </div>}
      </DragDropContext>
    </Ax>)
  }
  
  addRemarksDragModalJsx                 =         ()            =>        {
      return (
          <div className="modal fade" id="addRemarksDragModal" tabIndex="-1">
              <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="addRemrk">Add Remark for Ticket : {this.state?.ticketData?.header}</h5>
                          <button type="button" className="btn-close" onClick={() => this.addRemarksDragModal.hide()}></button>
                      </div>
                      {this.state.remark_form_loading ? <Loader /> :
                          <form className="bg-white p-2" onSubmit={this.submitAddRemarkDragForm}>
                              <div className="modal-body">
                                  <div className="row align-items-center my-3">
                                      <div className="col-sm-3"><label className="form-label require">Ticket Status</label></div>
                                      <div className="col-sm-5">
                                          <TapSelect
                                              options={this.state.allRemarkStatus.filter(status => status.value == this.state.currentStatusId)}
                                              changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRemarkForm', 'status')}
                                              value={this.state.allRemarkStatus.find(a => a.value == this.state.addRemarkForm.status)}
                                              isSearchable={true}
                                              isClearable={false}
                                              required={true}
                                              placeholder="Please Select Status"
                                              containerHeight="30px"
                                              fontSize="93%"
                                          />
                                      </div>

                                  </div>
                                  <div className="row align-items-center my-3">
                                      <div className="col-sm-3"><label className="form-label require">Remarks</label></div>
                                      <div className="col-sm-5">
                                          <textarea
                                              required={true}
                                              name="remarks"
                                              value={this.state.addRemarkForm.remarks}
                                              onChange={(e) => this.formInputHandler(e, 'addRemarkForm')}
                                              className="form-control"
                                              style={{ height: "60px" }}
                                          />
                                      </div>

                                  </div>
                                  {this.state.remarkFormData.change_asset_status == "Y" ?
                                      <div className="row align-items-center my-3">
                                          <div className="col-sm-3"><label className="form-label ">Change Asset Status</label></div>
                                          <div className="col-sm-5">
                                              <TapSelect
                                                  options={this.state.allAssetStatus}
                                                  changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRemarkForm', 'asset_status')}
                                                  value={this.state.allAssetStatus.find(a => a.value == this.state.addRemarkForm.asset_status)}
                                                  isSearchable={true}
                                                  isClearable={true}
                                                  placeholder="New Asset Status"
                                                  containerHeight="30px"
                                                  fontSize="93%"
                                              />
                                          </div>
                                      </div> : null}

                                  {this.state.remarkFormData?.defect_codes?.length > 0 ?
                                      <div className="row align-items-center my-3">
                                          <div className="col-sm-3"><label className="form-label">Defect Code</label></div>
                                          <div className="col-sm-5">
                                              <TapSelect
                                                  options={this.state.allDefectCode}
                                                  changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRemarkForm', 'defect_codes')}
                                                  value={this.state.allDefectCode.filter(u => this.state.addRemarkForm?.defect_codes?.includes(u.value))}
                                                  isSearchable={true}
                                                  isMulti={true}
                                                  isClearable={true}
                                                  placeholder="Select Defect Code"
                                                  containerHeight="30px"
                                                  fontSize="93%"
                                              />
                                          </div>
                                          <div className="col-sm-4 form-text">
                                              On Adding Remark, Asset Status will be changed to the selected option
                                          </div>
                                      </div>
                                      : null}

                              </div>
                              <div className="modal-footer d-flex justify-content-between">
                                  <div className="mt-0">
                                      <input
                                          name="send_email_notification"
                                          type="checkbox"
                                          value="N"
                                          onChange={(e) => {
                                              let addRemarkForm = this.state.addRemarkForm;
                                              addRemarkForm = {
                                                  ...addRemarkForm, send_email_notification:
                                                      (addRemarkForm.send_email_notification ? addRemarkForm.send_email_notification : 'N') == 'Y' ? 'N' : 'Y'
                                              };
                                              this.setState({ addRemarkForm });
                                          }}
                                          className="form-check-input"

                                      />
                                      <label className="form-check-label mx-2">Send Email Notification</label>
                                  </div>
                                  <div>
                                      <button type="button" className="btn btn-secondary mx-2" onClick={() => this.addRemarksDragModal.hide()}>Close </button>
                                      <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                  </div>
                              </div>
                          </form>}
                  </div>
              </div>
          </div>
      );
  }

  ticketSearchModalJsx               =        ()             =>        {
    return (
        <div className="modal fade" id="ticketSearchModal" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchAssetModalLabel">Ticket Search</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {this.state.form_data_loading ? <Loader />
                        : <form id="ticketSearchForm" onSubmit={this.submitTicketSearchForm}>
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Ticket</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="All Tickets"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            options={this.allTicketOptions}
                                            value={this.allTicketOptions.find(r => r.value === this.state.ticketSearchForm.search_ticket)}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_ticket')}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Ticket Type</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Ticket Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            options={this.state.allTicketType}
                                            value={this.state.allTicketType.find(r => r.value === this.state.ticketSearchForm.search_ticket_type_id)}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_ticket_type_id')}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Ticket ID</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_ticket_id"
                                            className="form-control form-control-sm"
                                            placeholder="Ticket ID"
                                            autoComplete="off"
                                            value={this.state.ticketSearchForm.search_ticket_id}
                                            onChange={(e) => this.formInputHandler(e, 'ticketSearchForm')}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Ticket Header</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_ticket_header"
                                            className="form-control form-control-sm"
                                            placeholder="Ticket Header"
                                            autoComplete="off"
                                            value={this.state.ticketSearchForm.search_ticket_header}
                                            onChange={(e) => this.formInputHandler(e, 'ticketSearchForm')}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Ticket Status</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allStatusList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_ticket_status')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allStatusList.filter(s => this.state.ticketSearchForm.search_ticket_status.includes(s.value))}
                                            placeholder="Select Ticket Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Asset</label>
                                    <div className="col-sm-4">
                                        <InputAssetSearch
                                            placeholder="Search Asset"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_asset_id');
                                                this.setState({ searchedAsset: selectedOption ? selectedOption : null });
                                            }}
                                            value={this.state.searchedAsset ? { ...this.state.searchedAsset, label: this.state.searchedAsset.display_label } : null}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Site Name</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_user_sites.filter(s => this.state.ticketSearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Ticket With Asset</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Ticket With Asset"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            options={this.state.searchTicketOptions}
                                            value={this.state.searchTicketOptions.find(r => r.value === this.state.ticketSearchForm.ticket_with_asset)}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketSearchForm', 'ticket_with_asset')}
                                        />
                                    </div>

                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Ticket Employee</label>
                                    <div className="col-sm-4 mt-3">
                                        <InputEmployeeSearch
                                            placeholder="Search Employee"
                                            changeEvent={(selectedOption) => {
                                                this.setState({ 
                                                    ticketEmployee: selectedOption ? selectedOption : ''
                                                });
                                                this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_employee_id')
                                            }}
                                            
                                            menuPlacement="top"
                                            value={this.state.ticketEmployee && this.state.ticketEmployee }
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Workstation</label>
                                    <div className="col-sm-4 mt-3">
                                        <InputWorkstationSearch
                                            placeholder="Search Workstation"
                                            changeEvent={(selectedOption) => {
                                                this.setState({ 
                                                    searchedWorkstation: selectedOption ? selectedOption : '' 
                                                });
                                                this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_workstation')
                                            }}
                                            menuPlacement="top"
                                            isMulti={false}
                                            value={this.state.searchedWorkstation && this.state.searchedWorkstation}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Sub Assembly</label>
                                    <div className="col-sm-4 mt-3">
                                        <InputSubAssemblySearch
                                            placeholder="Search Sub Assembly"
                                            changeEvent={(selectedOption) => {
                                                this.setState({ 
                                                    searchedSubAssembly: selectedOption ? selectedOption : '' 
                                                });
                                                this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_sub_assembly')
                                            }}
                                            menuPlacement="top"
                                            isMulti={false}
                                            value={this.state.searchedSubAssembly && this.state.searchedSubAssembly}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Asset Type Group</label>
                                    <div className="col-sm-4 mt-3">
                                        <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    ticketSearchForm: {
                                                        ...this.state.ticketSearchForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.ticketSearchForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  mt-3">Asset Type</label>
                                    <div className="col-sm-4 mt-3">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_asset_type')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.ticketSearchForm.search_asset_type.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Tagged User</label>
                                    <div className="col-sm-4 mt-3">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select User"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                            options={this.state.allTaggedUsers}
                                            value={this.state.allTaggedUsers.filter(s => this.state.ticketSearchForm.search_tagged_users.includes(s.value))}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_tagged_users')}

                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Ticket Ageing</label>
                                    <div className="col-sm-4 mt-3">
                                        <input
                                            type="text"
                                            name="search_ageing_days"
                                            className="form-control form-control-sm"
                                            placeholder="Example. 0-5 for a range or 4 for Ticket Aging"
                                            autoComplete="off"
                                            value={this.state.ticketSearchForm.search_ageing_days}
                                            onChange={(e) => this.formInputHandler(e, 'ticketSearchForm')}
                                        />

                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Creation Date </label>
                                    <div className="col-sm-4 mt-3">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.filterFormData && this.state.filterFormData.periods !== undefined ? this.state.filterFormData.periods : []}
                                            required={false}
                                            value={this.state.ticketSearchForm.creation_date_range}
                                            startDate={this.state.ticketSearchForm.creation_date_range_start}
                                            endDate={this.state.ticketSearchForm.creation_date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    ticketSearchForm: { ...this.state.ticketSearchForm, creation_date_range: period, creation_date_range_start: startDate, creation_date_range_end: endDate }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Completion Date </label>
                                    <div className="col-sm-4 mt-3">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.filterFormData && this.state.filterFormData.periods !== undefined ? this.state.filterFormData.periods : []}
                                            required={false}
                                            value={this.state.ticketSearchForm.closure_date_range}
                                            startDate={this.state.ticketSearchForm.closure_date_range_start}
                                            endDate={this.state.ticketSearchForm.closure_date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    ticketSearchForm: { ...this.state.ticketSearchForm, closure_date_range: period, closure_date_range_start: startDate, closure_date_range_end: endDate }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm   mt-3">Due Date</label>
                                    <div className="col-sm-4 mt-3">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.filterFormData && this.state.filterFormData.periods !== undefined ? this.state.filterFormData.periods : []}
                                            required={false}
                                            value={this.state.ticketSearchForm.due_date_range}
                                            startDate={this.state.ticketSearchForm.due_date_range_start}
                                            endDate={this.state.ticketSearchForm.due_date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    ticketSearchForm: { ...this.state.ticketSearchForm, due_date_range: period, due_date_range_start: startDate, due_date_range_end: endDate }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Remark Ageing</label>
                                    <div className="col-sm-4 mt-3">
                                        <input
                                            type="text"
                                            name="remark_ageing_days"
                                            className="form-control form-control-sm"
                                            placeholder="Example. 0-5 for a range or 4 for Remark Aging"
                                            autoComplete="off"
                                            value={this.state.ticketSearchForm.remark_ageing_days}
                                            onChange={(e) => this.formInputHandler(e, 'ticketSearchForm')}
                                        />
                                    </div>
                                {
                                    this.state.ticket_allow_tag == 'Y' &&
                                    <Ax>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Tags</label>
                                    <div className="col-sm-4 mt-3 mt-3">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Tags"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                            options={this.state.ticketTags}
                                            value={this.state.ticketTags.filter(s => this.state.ticketSearchForm.search_tags && this.state.ticketSearchForm.search_tags.includes(s.value))}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_tags')}
                                        />
                                    </div>
                                    </Ax>
                                }
                                {
                                    this.state.ticket_allow_employees == 'Y' &&
                                    <Ax>
                                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">Linked Employees</label>
                                    <div className="col-sm-4 mt-3">
                                        <InputEmployeeSearch
                                            placeholder="Search Employees"
                                            changeEvent={(selectedOption) => {
                                                this.setState({ 
                                                    searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : [] 
                                                });
                                                this.tapSelectChange(selectedOption, 'ticketSearchForm', 'search_employees')
                                            }}
                                            
                                            menuPlacement="top"
                                            isMulti={true}
                                            value={this.state.searchedEmployees && this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    </Ax>
                                }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.ticketSearchClear}>Clear</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>}
                </div>
            </div>
        </div>
    );
}

ticketSearchModalInit               =       ()             =>        {
    this.ticketSearchModal.show();
    this.setState({filterLoading:true})
}

submitTicketSearchForm              =        (e = null)            =>        {
     e && e.preventDefault()
    let serachFormKeys              =   Object.keys(this.state.ticketSearchForm);
   
    let searchedElems               =   [];
    let searchParams                =   {};
    serachFormKeys.map((key)        =>  {
        
        let label                       =   this.ticketSearchFormLabel[key];
        let value                       =   this.state.ticketSearchForm[key];
        if(value !== null && value.toString().length > 0) {
            searchParams[key]      =   value;
       if(label) {
                let show_val            =   value;
                if(key == 'search_asset_id') {
                    show_val            =   this.state.searchedAsset ? this.state.searchedAsset.display_label : '';
                }
                if(key == 'search_sub_assembly') {
                    show_val            =   this.state.searchedSubAssembly ? this.state.searchedSubAssembly.label : '';
                }
                if(key == 'search_employee_id') {
                    show_val            =   this.state.ticketEmployee ? this.state.ticketEmployee.label : '';
                }
                if(key == 'search_workstation') {
                    show_val            =   this.state.searchedWorkstation ? this.state.searchedWorkstation.label : '';
                }
                if(key == 'search_employees') {
                    show_val            =   this.state.searchedEmployees && this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map((emp, i) => emp.label).join(', ') : '';
                }
                if(key == 'search_tags') {
                    show_val            =   this.state.ticketTags.filter(s => this.state.ticketSearchForm.search_tags && this.state.ticketSearchForm.search_tags.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_site_ids') {
                    show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_ticket_type_id') {
                    show_val            =   this.state.allTicketType.filter((s) => value === s.value).map(s => s.label).join(', ');
                }
                if(key == 'search_ticket_header') {
                    show_val            =   this.state.ticketSearchForm ? this.state.ticketSearchForm.search_ticket_header : '';
                }
                if(key == 'search_ticket_id') {
                    show_val            =   this.state.ticketSearchForm ? this.state.ticketSearchForm.search_ticket_id : '';
                }
                if(key == 'asset_group_ids') {
                    show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_asset_type') {
                    show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_ageing_days') {
                    show_val            =   this.state.ticketSearchForm ? this.state.ticketSearchForm.search_ageing_days : '';
                }
                if(key == 'closure_date_range') {
                    show_val            = this.state.filterFormData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                }
                if(key == 'creation_date_range') {
                    show_val            = this.state.filterFormData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                }
                if(key == 'due_date_range') {
                    show_val            = this.state.filterFormData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                }
                if(key == 'remark_ageing_days') {
                    show_val            = this.state.ticketSearchForm ? this.state.ticketSearchForm.remark_ageing_days : '';
                }
                if(key == 'search_tagged_users') {
                    show_val            =   this.state.allTaggedUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_ticket') {
                    show_val            = this.allTicketOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                }
                if(key == 'search_ticket_status') {
                    show_val            =   this.state.allStatusList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'ticket_with_asset') {
                    show_val            =   this.state.searchTicketOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
               
              searchedElems.push(label + `<b>${show_val}</b>`);

            }
        }
    });
    this.setState({
        submittedTicketFilterForm   :   {...this.state.ticketSearchForm},
        formSearchedElems           :   searchedElems
    }, () => {
        this.ticketSearchModal.hide()
        this.getTicketFormData();
    });
}

  render() {
   
          
    return this.props?.projectId 
    ?
    <Ax>
      {this.ticketTransactionListingJsx()}
      {this.addRemarksDragModalJsx()}
      {this.ticketSearchModalJsx()}
      <TicketDetailModal ref={this.ticketDetailRef} projectId={this.props.projectId}/>
    </Ax>  
    :  (
        <ApplicationLayout>
          <Helmet><title>Ticket List Board</title></Helmet>
          {this.ticketTransactionListingJsx()}
          {this.addRemarksDragModalJsx()}
          {this.ticketSearchModalJsx()}
          <TicketDetailModal ref={this.ticketDetailRef}/>
        </ApplicationLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
      access_token            :   state.auth.access_token,
      permissions             :   state.app.acl_info.permissions,
      iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
      iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
      asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [], 
  };
};


export default connect(mapStateToProps,null, null, { forwardRef: true })(TicketsBoardManagement);