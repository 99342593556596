
import * as actionTypes from './../actions/actionTypes';
import { updateObject } from './../utility';

const initialState                  =   {
    firebase_app_version                 :   '1',
    firebase_allowed_ip                  :   null,
    firebase_maintenance_mode           :   'N',
    user_info                           :   null,
    group_info                          :   null,
    acl_info                            :   null,
    appInitErrorMsg                     :   '',
    requision_form_data                 :   null,
    app_iam_data_loaded                 :   false,
    asset_type                          :   [],
    asset_matrix                        :   [],
    asset_variants                      :   [],
    manufactures                        :   [],
    models                              :   [],
    status_list                         :   [],
    user_sites                          :   [],
    group_sites                         :   [],
    all_periods                         :   [],
    setting                             :   null,
    app_isc_data_loaded                 :   false,
    isc_configuration                   :   null,
    warehouses                          :   [],
    item_tags                           :   [],
    search_param                        :   null,
    search_elem                         :   [],
    matrix_listing_loaded               :   true,
    matrix_listing                      :   [],
    matrix_meta                         :   null,
    matrix_total_count                  :   0,
    matrix_search_param                 :   null,
    matrixSearchedElems                 :   [],
    matrixInitErrorMsg                  :   '',
    report_listing                      :   [],
    non_measurable_asset_listing_loaded  :   false,
    non_measurable_asset_listing                   :   [],
    non_measurable_asset_meta                      :   null,
    non_measurable_asset_total_count               :   0,
    non_measurable_asset_search_param              :    null,
    non_measurable_formSearchedElems               :   [],
    non_measurable_assetInitErrorMsg               :   '',
    site_groups                                 :       [],
    default_currency                    :               '',
    currency_list                       :               [],
    asset_groups                        :                [],
    item_listing_loaded  :   false,
    item_listing                   :   [],
    item_meta                      :   null,
    item_total_count               :   0,
    item_search_param              :    null,
    formSearchedElems               :   [],
    itemInitErrorMsg               :   '',
    
};

const appInitilizationStart         =   (state) => {
    return updateObject(state, {
        user_info                       :   null,
        group_info                      :   null,
        acl_info                        :   null,
        appInitErrorMsg                 :   ''
    });
};

const appInitilizationSuccess       =   (state, action) => {
    return updateObject(state, {
        user_info                       :   action.user_info,
        group_info                      :   action.group_info,
        acl_info                        :   action.acl_info,
        appInitErrorMsg                 :   ''
    });
};

const appInitilizationFail          =   (state, action) => {
    return updateObject(state, {
        user_info                       :   null,
        group_info                      :   null,
        acl_info                        :   null,
        appInitErrorMsg                 :   action.error_msg
    });
};

const appIamDataInit                =   (state) =>  {
    return updateObject(state, {
        app_iam_data_loaded             :   false,
        asset_type                      :   [],
        asset_matrix                    :   [],
        asset_variants                  :   [],
        manufactures                    :   [],
        models                          :   [],
        status_list                     :   [],
        user_sites                      :   [],
        group_sites                     :   [],
        all_periods                     :   [],
        setting                         :   null,
        fuel_stations                   :   [],
        site_groups                     :   [],
        asset_groups                    :   []
    });
};
const appConfigurationDataInit                =   (state) =>  {
    return updateObject(state, {
        app_iam_data_loaded             :   false,
        activity_name                   :   [],
        user_sites                      :   [],
        Uom                             :   [],
        accounting_code                 :   [],
        status_list                     :   [],
    });
};

const appIscDataInit                =   (state) =>  {
    return updateObject(state, {
        app_isc_data_loaded             :   false,
        isc_configuration               :   null,
        warehouses                      :   [],
        item_tags                       :   []
    });
};

const appIamDataSuccess             =   (state, action) => {
    let group_sites = action.iam_data.group_sites && action.iam_data.group_sites.length > 0 ? action.iam_data.group_sites : [];
    
    return updateObject(state, {
        app_iam_data_loaded             :   true,
        asset_type                      :   action.iam_data.asset_type,
        asset_matrix                    :   action.iam_data.asset_matrix,
        asset_variants                  :   action.iam_data.asset_variants,
        manufactures                    :   action.iam_data.manufactures,
        models                          :   action.iam_data.models,
        status_list                     :   action.iam_data.status_list,
        group_sites                     :   group_sites,
        user_sites                      :   group_sites.filter(site => {return(site.associate_with_user && site.associate_with_user == 'Y')}),
        all_periods                     :   action.iam_data.all_periods,
        setting                         :   action.iam_data.setting,
        fuel_stations                   :   action.iam_data.fuel_stations,
        site_groups                     :   action.iam_data.site_groups,
        asset_groups                    :   action.iam_data.asset_type_groups,
    });
};
const appConfigurationDataSuccess             =   (state, action) => {
    return updateObject(state, {
        app_iam_data_loaded             :   true,
        activity_name                   :   action.configuration_data.activity_name,
        user_sites                      :   action.configuration_data.user_sites,
        Uom                             :   action.configuration_data.Uom,
        accounting_code                 :   action.configuration_data.accounting_code,
        status_list                     :   action.configuration_data.status_list,
    });
};
const appIscDataSuccess             =   (state, action) => {
    
    return updateObject(state, {
        app_isc_data_loaded             :   true,
        isc_configuration               :   action.isc_data.isc_configuration,
        warehouses                      :   action.isc_data.warehouses,
        item_tags                       :   action.isc_data.tags,
        default_currency                :   action.isc_data.currency,
        currency_list                   :   action.isc_data.currency_list
     });
};

const appIamDataFail                =   (state, action) => {
    return updateObject(state, {
        app_iam_data_loaded             :   false,
        asset_type                      :   [],
        asset_matrix                    :   [],
        asset_variants                  :   [],
        manufactures                    :   [],
        models                          :   [],
        status_list                     :   [],
        user_sites                      :   [],
        all_periods                     :   [],
        setting                         :   null,
        fuel_stations                  :   [],
        site_groups                     :   [],
        asset_groups                    :   []
    });
};
const appConfigurationDataFail                =   (state, action) => {
    return updateObject(state, {
        app_iam_data_loaded             :   false,
        activity_name                   :   [],
        user_sites                      :   [],
        Uom                             :   [],
        accounting_code                 :   [],
        status_list                     :   [],
    });
};
const appIscDataFail               =   (state) =>  {
    return updateObject(state, {
        app_isc_data_loaded             :   false,
        isc_configuration               :   null,
        warehouses                      :   [],
        item_tags                       :   []
    });
};

const requisionFormDataReset        = (state, action) =>    {
    return updateObject(state, {
        requision_form_data         :   null
    });
}

const requisionFormDataCreate       =   (state, action)  =>  {
    return updateObject(state, {
        requision_form_data         :   action.requisionFormData
    });
}

const assetListingInit                =   (state) => {
    return updateObject(state, {
        asset_listing_loaded            :   true,
        asset_listing                   :   [],
        asset_meta                      :   null,
        asset_total_count               :   0,
        asset_search_param              :    null,
        formSearchedElems               :   [],
        assetInitErrorMsg               :   ''
    });
};

const assetListingDataSuccess     =   (state, action) => {
    return updateObject(state, {
        asset_listing_loaded            :   false,
        asset_listing                   :   action.asset_listing_data.data,
        asset_meta                      :   action.asset_listing_data.meta,
        asset_total_count               :   action.asset_listing_data.meta.total,
        asset_search_param              :   action.search_param,
        formSearchedElems               :   action.formSearchedElems,
        assetInitErrorMsg               :   ''
    });
};

const assetListingFail          =   (state, action) => {
    return updateObject(state, {
        asset_listing_loaded            :   false,
        asset_listing                   :   [],
        asset_meta                      :   null,
        asset_total_count               :   0,
        asset_search_param              :   null,
        formSearchedElems               :   [],
        assetInitErrorMsg               :   action.error_msg
    });
};

const itemListingInit                =   (state) => {
    return updateObject(state, {
        item_listing_loaded            :   true,
        item_listing                   :   [],
        item_meta                      :   null,
        item_total_count               :   0,
        item_search_param              :    null,
        formSearchedElems               :   [],
        itemInitErrorMsg               :   ''
    });
};

const itemListingDataSuccess     =   (state, action) => {
    return updateObject(state, {
        item_listing_loaded            :   false,
        item_listing                   :   action.item_listing_data.data,
        item_meta                      :   action.item_listing_data.meta,
        item_total_count               :   action.item_listing_data.meta.total,
        item_search_param              :   action.search_param,
        formSearchedElems               :   action.formSearchedElems,
        itemInitErrorMsg               :   ''
    });
};

const itemListingFail          =   (state, action) => {
    return updateObject(state, {
        item_listing_loaded            :   false,
        item_listing                   :   [],
        item_meta                      :   null,
        item_total_count               :   0,
        item_search_param              :   null,
        formSearchedElems               :   [],
        itemInitErrorMsg               :   action.error_msg
    });
};
const configurationListingInit                =   (state) => {
    return updateObject(state, {
        configuration_listing_loaded            :   true,
        configuration_listing                   :   [],
        configuration_meta                      :   null,
        configuration_total_count               :   0,
        configuration_search_param              :    null,
        formSearchedElems               :   [],
        configurationInitErrorMsg               :   ''
    });
};

const configurationListingDataSuccess     =   (state, action) => {
    return updateObject(state, {
        configuration_listing_loaded            :   false,
        configuration_listing                   :   action.configuration_listing_data.data,
        configuration_meta                      :   action.configuration_listing_data.meta,
        configuration_total_count               :   action.configuration_listing_data.meta.total,
        configuration_search_param              :   action.search_param,
        formSearchedElems                       :   action.formSearchedElems,
        configurationInitErrorMsg               :   ''
    });
};

const configurationListingFail          =   (state, action) => {
    return updateObject(state, {
        configuration_listing_loaded            :   false,
        configuration_listing                   :   [],
        configuration_meta                      :   null,
        configuration_total_count               :   0,
        configuration_search_param              :   null,
        formSearchedElems                       :   [],
        configurationInitErrorMsg               :   action.error_msg
    });
};


const nonMeasurableAssetListingInit                =   (state) => {
    return updateObject(state, {
        non_measurable_asset_listing_loaded  :   true,
        non_measurable_asset_listing                   :   [],
        non_measurable_asset_meta                      :   null,
        non_measurable_asset_total_count               :   0,
        non_measurable_asset_search_param              :    null,
        non_measurable_formSearchedElems               :   [],
        non_measurable_assetInitErrorMsg               :   ''
    });
};

const nonMeasurableAssetListingDataSuccess     =   (state, action) => {
    return updateObject(state, {
        non_measurable_asset_listing_loaded            :   false,
        non_measurable_asset_listing                   :   action.asset_listing_data.data,
        non_measurable_asset_meta                      :   action.asset_listing_data.meta,
        non_measurable_asset_total_count               :   action.asset_listing_data.meta.total,
        non_measurable_asset_search_param              :   action.search_param,
        non_measurable_formSearchedElems               :   action.formSearchedElems,
        non_measurable_assetInitErrorMsg               :   ''
    });
};

const nonMeasurableAssetListingFail          =   (state, action) => {
    return updateObject(state, {
        non_measurable_asset_listing_loaded            :   false,
        non_measurable_asset_listing                   :   [],
        non_measurable_asset_meta                      :   null,
        non_measurable_asset_total_count               :   0,
        non_measurable_asset_search_param              :   null,
        non_measurable_formSearchedElems               :   [],
        non_measurable_assetInitErrorMsg               :   action.error_msg
    });
};

const setAPPVersionFromFB = (state, action) => {
    return updateObject(state, {
        firebase_app_version         :   action.firebase_app_version,
        firebase_maintenance_mode   :   action.firebase_maintenance_mode,
        firebase_allowed_ip         :   action.firebase_allowed_ip,
    });
}

const itemSearch = (state, action) => {
    return updateObject(state, {
        search_param : action.search_param,
        search_elem : action.search_elem,
    });
}

const itemSearchClear = (state) => {
    return updateObject(state, {
        search_param : null,
        search_elem : [],
    });
}


const assetMatrixListingInit                =   (state) => {
    return updateObject(state, {
        matrix_listing_loaded            :   true,
        matrix_listing                   :   [],
        matrix_meta                      :   null,
        matrix_total_count               :   0,
        matrix_search_param              :    null,
        matrixSearchedElems              :   [],
        matrixInitErrorMsg               :   ''
    });
};

const assetMatrixListingDataSuccess     =   (state, action) => {
    return updateObject(state, {
        matrix_listing_loaded            :   false,
        matrix_listing                   :   action.matrix_listing.data,
        matrix_meta                      :   action.matrix_listing.meta,
        matrix_total_count               :   action.matrix_listing.meta.total,
        matrix_search_param              :   action.params,
        matrixSearchedElems              :   action.formSearchedElems,
        matrixInitErrorMsg               :   ''
    });
};

const assetMatrixListingFail          =   (state, action) => {
    return updateObject(state, {
        matrix_listing_loaded            :   false,
        matrix_listing                   :   [],
        matrix_meta                      :   null,
        matrix_total_count               :   0,
        matrix_search_param              :   null,
        formSearchedElems                :   [],
        matrixInitErrorMsg               :   action.error_msg
    });
};


const reportListingInit                         =   (state) => {
    return updateObject(state, {
        report_listing                            :   [] ,
    });
};

const reportListingDataSuccess                    =   (state, action) => {
   
    return updateObject(state, {
       report_listing                      :     action.report_listing,
    });
};

const userDataInit                         =   (state) => {
    return updateObject(state, {
        user_info                            :   null ,
    });
};

const userDataSuccess                    =   (state, action) => {
   
    return updateObject(state, {
       user_info                      :     action.user_info,
    });
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REQUISION_FORM_DATA_INIT           :   return requisionFormDataReset(state);  break;
        case actionTypes.REQUISION_FORM_DATA_LOAD           :   return requisionFormDataCreate(state, action);  break;
        case actionTypes.APP_DATA_INIT                      :   return appInitilizationStart(state);  break;
        case actionTypes.APP_DATA_SUCCESS                   :   return appInitilizationSuccess(state, action);  break;
        case actionTypes.APP_DATA_FAIL                      :   return appInitilizationFail(state, action);  break;
        case actionTypes.IAM_APP_DATA_INIT                  :   return appIamDataInit(state);  break;
        case actionTypes.IAM_APP_DATA_SUCCESS               :   return appIamDataSuccess(state, action);  break;
        case actionTypes.IAM_APP_DATA_FAIL                  :   return appIamDataFail(state);  break;
        case actionTypes.CONFIGURATION_APP_DATA_INIT        :   return appConfigurationDataInit(state);  break;
        case actionTypes.CONFIGURATION_APP_DATA_SUCCESS     :   return appConfigurationDataSuccess(state, action);  break;
        case actionTypes.CONFIGURATION_APP_DATA_FAIL        :   return appConfigurationDataFail(state);  break;
        case actionTypes.ISC_APP_DATA_INIT                  :   return appIscDataInit(state);  break;
        case actionTypes.ISC_APP_DATA_SUCCESS               :   return appIscDataSuccess(state, action);  break;
        case actionTypes.ISC_APP_DATA_FAIL                  :   return appIscDataFail(state);  break;
        case actionTypes.ASSET_LISTING_DATA_INIT            :   return assetListingInit(state);  break;
        case actionTypes.ASSET_LISTING_DATA_SUCCESS         :   return assetListingDataSuccess(state, action);  break;
        case actionTypes.ASSET_LISTING_DATA_FAIL            :   return assetListingFail(state);  break;
        case actionTypes.ITEM_LISTING_DATA_INIT            :   return itemListingInit(state);  break;
        case actionTypes.ITEM_LISTING_DATA_SUCCESS         :   return itemListingDataSuccess(state, action);  break;
        case actionTypes.ITEM_LISTING_DATA_FAIL            :   return itemListingFail(state);  break;
        case actionTypes.NON_MEASURABLE_ASSET_LISTING_DATA_INIT            :   return nonMeasurableAssetListingInit(state);  break;
        case actionTypes.CONFIGURATION_LISTING_DATA_INIT    :   return configurationListingInit(state);  break;
        case actionTypes.CONFIGURATION_LISTING_DATA_SUCCESS :   return configurationListingDataSuccess(state, action);  break;
        case actionTypes.CONFIGURATION_LISTING_DATA_FAIL    :   return configurationListingFail(state);  break;
        case actionTypes.NON_MEASURABLE_ASSET_LISTING_DATA_INIT :   return nonMeasurableAssetListingInit(state);  break;
        case actionTypes.NON_MEASURABLE_ASSET_LISTING_DATA_SUCCESS         :   return nonMeasurableAssetListingDataSuccess(state, action);  break;
        case actionTypes.NON_MEASURABLE_ASSET_LISTING_DATA_FAIL            :   return nonMeasurableAssetListingFail(state);  break;
        case actionTypes.ITEM_SEARCH                        :   return itemSearch(state, action);  break;
        case actionTypes.ITEM_CLEAR                         :   return itemSearchClear(state);  break;
        case actionTypes.FIREBASE_APP_VERSION_UPDATE        :   return setAPPVersionFromFB(state, action);  break;
        case actionTypes.ASSET_MATRIX_LISTING_DATA_INIT     :   return assetMatrixListingInit(state);  break;
        case actionTypes.ASSET_MATRIX_LISTING_DATA_SUCCESS  :   return assetMatrixListingDataSuccess(state, action);  break;
        case actionTypes.ASSET_MATRIX_LISTING_DATA_FAIL     :   return assetMatrixListingFail(state);  break;
        case actionTypes.REPORT_LISTING_DATA_INIT           :   return reportListingInit(state);  break;
        case actionTypes.REPORT_LISTING_DATA_SUCCESS        :   return reportListingDataSuccess(state, action);  break;
        case actionTypes.USER_DATA_INIT                     :   return userDataInit(state, action);  break;
        case actionTypes.USER_DATA_SUCCESS                  :   return userDataSuccess(state, action);  break;
        default                                             :   return state;
    }
};

export default reducer;