import React from "react";
import TapIcon from "../../services/TapIcon";
import TapSelect from "../../components/ui/TapSelect";
import { Link } from 'react-router-dom';

export default function WidgetFilterJsx({options, dailyOrMonthly, allPeriods, filterForm, date_range, sites, refreshHandle, handleFilterChange, name}) {
    return (
        <div className="row">
            <div className="col-5">
                <h6 className="fw-bold primary_color">{name}</h6>
            </div>
            <div className="col-2 p0">
                <TapSelect
                    isSearchable={true}
                    placeholder="Choose"
                    containerHeight="33px"
                    fontSize="93%"
                    value={options.find(m => m.value === dailyOrMonthly)}
                    changeEvent={(selectedOption) => handleFilterChange(selectedOption, 'dailyOrMonthly')}
                    options={options}
                />
            </div>
            <div className="col-2">
                <TapSelect
                    isClearable={false}
                    placeholder="Select Period"
                    containerHeight="33px"
                    fontSize="93%"
                    options={allPeriods}
                    value={allPeriods.find(r => r.value === date_range)}
                    changeEvent={(selectedOption) => handleFilterChange(selectedOption, 'date_range')}
                />
            </div>
            <div className="col-2 p0">
                <TapSelect
                    options={sites}
                    isSearchable={true}
                    isClearable={true}
                    value={sites.find(m => m.value === filterForm.filter_site_id)}
                    placeholder="All Sites"
                    containerHeight="33px"
                    fontSize="93%"
                    changeEvent={(selectedOption) => handleFilterChange(selectedOption, 'filter_site_id')}
                />
            </div>
            <div className="col-sm-1 p-0 mx-0 text-center">
                <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                <ul className="dropdown-menu dropdown-menu-end">
                    <Link className={['dropdown-item'].join(' ')} onClick={refreshHandle}>Refresh</Link>
                    {/* <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li> */}
                </ul>
            </div>
        </div>
    )
}