import { connect } from "react-redux";
import { Modal } from "bootstrap";
import swal from "sweetalert";
import axios from "axios";
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { toast } from "react-toastify";
import React from "react";
import TapApiUrls, { ISC_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import { DisplayListPagination } from "../../../components/TapUi";
import { Link } from 'react-router-dom';

class TaxConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.attrInitForm        =       {
            name                 :       "",
            key                  :       ""
        }
        this.state               =        {
            addAttrListing       :        [],
            openForm             :        false,
            listing_loading      :        false,
            editFormSubmiting    :        false,
            selectedAttribute    :         null,
            options              :        [],
            totalListingCount    :         0,
            listing_tbl_page     :         1,
            addAttrForm          :       { ...this.attrInitForm },
        }

    }

    componentDidMount() {
        this.getAddAttrListing()
        this.addTaxAttrInit            =           new Modal(document.getElementById('addTaxAttrModal'), { keyboard: false, backdrop: false });
        if(this.props.openForm === true){
            this.addAttrModalInit()
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.openForm !== this.props.openForm) {
            this.addAttrModalInit()
        }
    }

    getAddAttrListing            =           ()         =>      {
        this.setState({ listing_loading: true })
        HttpAPICall.withAthorization('GET', ISC_API_BASE_URL_2 + '/tax_types', this.props.access_token, {}, {}, (resp) => {
            this.setState({ addAttrListing   :  resp.data.data })
        }).then(() => this.setState({ listing_loading: false }));
    }

    addAttrModalInit             =           (name)        =>         {
        this.addTaxAttrInit.show()
        if(name !== null){
            this.setState({edit : true})
            let taxList = this.state.addAttrListing.find(function (group) {
                return group.name == name;
            });
            this.setState({
                    addAttrForm :   {
                        name          :    taxList.name,
                        key           :    taxList.key,
                    }
                })
            if(taxList.slabs.length > 0){
                this.setState({
                    options : taxList.slabs
                })
            }else{
                this.setState({
                    options : ['-']
                })
            }
        }else{
            this.setState({edit : false})
        }
    }

    closeAttrForm       =       ()       =>      {
        this.setState({ addAttrForm: this.attrInitForm })
        this.props.closeForm()
    }

    handleOptionValue   =     (e, i)     =>       {
        let options = [...this.state.options]
        options[i] = e.target.value
        this.setState({
            options
        })
    }

    handleDeleteOption       =      i       =>   e      =>   {
        e.preventDefault()
        if (i > 0) {
            let options = [
                ...this.state.options.slice(0, i),
                ...this.state.options.slice(i + 1)
            ]
            this.setState({
                options
            })
        }
    }

    addOption = e => {
        e.preventDefault()
        let options = this.state.options.concat([''])
        this.setState({
            options
        })
    }


    addAttrModalJsx         =          ()      =>       {
        return (
            <div className="modal fade" id="addTaxAttrModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit {this.state.addAttrForm.name}</h5>
                        </div>
                        <form onSubmit={this.submitTaxAttrForm}>
                            <div className="modal-body p-3">
                                <div className="row align-items-center my-3">
                                    <div className="col-md-3"><label className="form-label require">{this.state.addAttrForm.name}</label></div>
                                    <div className="col-md-9">
                                    <div>{this.state.options.length >= 1 &&
                                                this.state.options.map((i, index) => {
                                                    return (
                                                        <div className="row">
                                                            <div className={this.state.options.length >=1 ? "col-sm-10" : "col-sm-12"}>
                                                                <input
                                                                    name={i}
                                                                    type="text"
                                                                    required="true"
                                                                    className="form-control my-2"
                                                                    value={i}
                                                                    placeholder="Please Enter Options"
                                                                    onChange={(e) => this.handleOptionValue(e, index)}
                                                                />
                                                            </div>
                                                            {this.state.options.length > 2 ?
                                                                <div className="col-sm-2"><button className="btn btn-secondary my-3" onClick={this.handleDeleteOption(index)}>X</button></div> : null}
                                                        </div>
                                                    )
                                                })}
                                               <div className="link-primary text-end" onClick={this.addOption}>Add New Tax Slab</div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal" onClick={this.closeAttrForm}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    submitTaxAttrForm         =           (e)     =>          {
        e.preventDefault()
        this.setState({ editFormSubmiting: true });
        if (this.state.edit === true) {
            let frmData;
            let result = this.state.options.map(function (x) {
                return parseInt(x, 10);
            });
            if(this.state.options[0] !== "-"){
                frmData = {
                    slabs       :   result
                };
            }else{
                frmData = {
                    slabs       :   []
                };
            }
            HttpAPICall.withAthorization('PUT', ISC_API_BASE_URL_2 + '/tax_type/' + this.state.addAttrForm.key, this.props.access_token, {}, { ...frmData}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addTaxAttrInit.hide();
                this.getAddAttrListing();
                this.props.closeForm()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ editFormSubmiting: false }))
        } 
    }

    render          =       ()      =>      {
        return (<Ax>
            <table className="table table-hover table-bordered bg-white table-fixed table-sm ">
                <thead className="table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                        <th scope="col" className="text-start">Tax Type</th>
                        <th scope="col" style={{ width: "45%" }} className="text-start">Tax Rates</th>
                        <th scope="col" style={{ width: "10%" }} >Action</th>

                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.addAttrListing.length > 0 
                            ? (this.state.addAttrListing.map((item, k) => {
                                return (<tr key={k} className="text-center">
                                    <td>{k + 1}</td>
                                    <td className="text-capitalize text-start">{item.name}</td>
                                    <td className="text-start">{item.slabs.length > 0 ? item.slabs.map(i => i + `%`).join(", ") : "-" }</td>
                                    <td>  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                        role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                            <li>
                                                <Link role="button"
                                                    onClick={() => {
                                                        if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                            return this.addAttrModalInit(item.name)
                                                        }
                                                    }}
                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Edit</Link>
                                            </li>
                                        </ul></td>
                                </tr>);
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            {/* <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.getAddAttrListing(e.selected+1)} /> */}
            {this.addAttrModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(TaxConfiguration);