import HttpAPICall from "../../../services/HttpAPICall";
import { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import { PRODUCTIVITY_WIDGET_START, PRODUCTIVITY_WIDGET_SUCCESS, PRODUCTIVITY_WIDGET_FAILURE, PRODUCTIVITY_PIE_WIDGET_START, PRODUCTIVITY_PIE_WIDGET_SUCCESS, PRODUCTIVITY_PIE_WIDGET_FAILURE,  PRODUCTIVITY_LINE_WIDGET_START, PRODUCTIVITY_LINE_WIDGET_SUCCESS, PRODUCTIVITY_LINE_WIDGET_FAILURE, PRODUCTIVITY_TARGET_WIDGET_START, PRODUCTIVITY_TARGET_WIDGET_SUCCESS, PRODUCTIVITY_TARGET_WIDGET_FAILURE } from "../actionTypes";

export const fetchProductivityWidget = (configId, params) => {
    return (dispatch) => {
        dispatch({ type: PRODUCTIVITY_WIDGET_START });
        const access_token  =   localStorage.getItem('access_token');
        const url           =   IAM_API_BASE_URL_2 + '/site_productivity_config/widget/' + configId;
        HttpAPICall.withAthorization('GET', url, access_token, params)
        .then((response) => {
            dispatch({ type: PRODUCTIVITY_WIDGET_SUCCESS, payload: response.data });
        }).catch((err) => {
            dispatch({ type: PRODUCTIVITY_WIDGET_FAILURE, payload : err });
        });
    };
};  


export const fetchProductivityPieWidget = (configId, params) => {
    return (dispatch) => {
        dispatch({ type: PRODUCTIVITY_PIE_WIDGET_START });

        const access_token  =   localStorage.getItem('access_token');
        const url           =   IAM_API_BASE_URL_2 + '/site_productivity_config/widget/' + configId;
        HttpAPICall.withAthorization('GET', url, access_token, params)
        .then((response) => {
            dispatch({ type: PRODUCTIVITY_PIE_WIDGET_SUCCESS, payload: response.data });
        }).catch((err) => {
            dispatch({ type: PRODUCTIVITY_PIE_WIDGET_FAILURE, payload : err });
        });
    };
};  


export const fetchProductivityLineWidget = (configId, params) => {
    return (dispatch) => {
        dispatch({ type: PRODUCTIVITY_LINE_WIDGET_START });

        const access_token  =   localStorage.getItem('access_token');
        const url           =   IAM_API_BASE_URL_2 + '/site_productivity_config/widget/' + configId;
        HttpAPICall.withAthorization('GET', url, access_token, params)
        .then((response) => {
            dispatch({ type: PRODUCTIVITY_LINE_WIDGET_SUCCESS, payload: response.data });
        }).catch((err) => {
            dispatch({ type: PRODUCTIVITY_LINE_WIDGET_FAILURE, payload : err });
        });
    };
};  

export const fetchProductivityTargetWidget = (configId, params) => {
    return (dispatch) => {
        dispatch({ type: PRODUCTIVITY_TARGET_WIDGET_START });

        const access_token  =   localStorage.getItem('access_token');
        const url           =   IAM_API_BASE_URL_2 + '/site_productivity_config/widget/' + configId;
        HttpAPICall.withAthorization('GET', url, access_token, params)
        .then((response) => {
            dispatch({ type: PRODUCTIVITY_TARGET_WIDGET_SUCCESS, payload: response.data });
        }).catch((err) => {
            dispatch({ type: PRODUCTIVITY_TARGET_WIDGET_FAILURE, payload : err });
        });
    };
};  

