import React from 'react';
import { toast } from 'react-toastify';
import {connect} from "react-redux";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { Collapse, Modal } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import HttpAPICall from "../../../services/HttpAPICall";
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import Loader from "../../../components/ui/Loader/Loader";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';

class AssetProductivityAnalysis extends AppBaseComponent {
    
    constructor() {
        super();
        this.assetAnalysisFilterFormInitialState    =   {
            site_group_ids                              :   [],
            search_site_ids                             :   [],
            asset_group_ids                             :   [],
            search_asset_type_ids                       :   [],
            search_asset_matrix_ids                     :   [],
            search_manufacturer_ids                     :   [],
            search_models                               :   [],
            search_variant                              :   [],
            search_asset_ids                            :   [],
            search_asset_type                           :   '',
            date_range                                  :   '',
            date_range_start                            :   '',
            date_range_end                              :   '',
            search_owner_id                             :   '',
            search_ownership_category                   :   '',
        };
        this.assetAnalysisSearchFormLabel           =   {
            site_group_ids                              :   'Site Groups : ',
            search_site_ids                             :   'Sites : ',
            asset_group_ids                             :   'Asset Type Groups : ',
            search_asset_type_ids                       :   'Asset Type : ',
            search_asset_matrix_ids                     :   'Asset Matrix : ',
            search_manufacturer_ids                     :   'Manufacturer : ',
            search_models                               :   'Models : ',
            search_variant                              :   'Variants : ',
            search_asset_ids                            :   'Assets : ',
            search_asset_type                           :   'Asset Type : ',
            date_range                                  :   'Date Range :  ',
            search_owner_id                             :   'Owner Name : ',
            search_ownership_category                   :   'Ownership Category : ',
        }

        this.state                                  =   {
            showSaveAssetAnalysisBtn                    :   false,
            showDownloadAssetAnalysisBtn                :   false,
            assetAnalysisDownloading                    :   false,
            showSaveAssetAnalysisForm                   :   false,
            listing_loading                             :   false,
            listingData                                 :   [],
            listingMeta                                 :   null,
            iam_asset_types                             :   [],
            iam_user_sites                              :   [],
            iam_asset_matrix                            :   [],
            iam_manufactures                            :   [],
            iam_asset_variants                          :   [],
            iam_models                                  :   [],
            searchedAsset                               :   null,
            searchedAssets                              :   [],
            selectedPeriod                              :   '',
            downloadReportBtn                           :   false,
            reportDownloading                           :   false,
            listing_tbl_page                            :   1,
            formSearchedElems                           :   [],
            allOwnershipCategory                        :   [],
            allOwnersList                               :   [],
            allSiteGroupsList                           :   [],
            assetAnalysisFilterForm                     :   {...this.assetAnalysisFilterFormInitialState},
            submittedAssetAnalysisFilterForm            :   {...this.assetAnalysisFilterFormInitialState},
            allAssetTypesGroupsList                     :   [],
            all_periods                                 :   [],
            reportData                          :   null,                  
        };

        this.reportUrl          =   IAM_API_BASE_URL_2 + '/report/asset_productivity_analysis';

    }
    
    componentDidMount(){
        setTimeout(() => {
            this.initilaizeFormFilter(this.props); 
        },1000 );
       
    }
    
    initilaizeFormFilter           =   (props)  =>  {


        const report_name   =   'asset_productivity_analysis';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })

        HttpAPICall.withAthorization('GET', this.reportUrl,
            this.props.access_token, {action : "formData"}, null, (response) => {
            const resp      =   response.data;    
            this.setState({
                allOwnershipCategory        :   resp.ownership_category && resp.ownership_category.length > 0 ? resp.ownership_category.map(oc => {return({value:oc.key,label : oc.name})}) : [],
                allOwnersList               :   resp.owners && resp.owners.length > 0 ? resp.owners.map(own => {return({value:own.id,label : own.name})}) : [],
                allSiteGroupsList           :   props.site_groups  &&  props.site_groups.length > 0 ?  props.site_groups.map(sg => {return({value:sg.id,label : sg.name,linked_sites : sg.site_ids})}) : [],
                iam_asset_types             :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                iam_user_sites              :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                iam_asset_matrix            :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
                iam_manufactures            :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
                iam_asset_variants          :   props.iam_asset_variants ? props.iam_asset_variants.map((v) => { return {value: v, label: v}}) : [],
                iam_models                  :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
                allAssetTypesGroupsList     :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                formDataLoaded              :   true,
                all_periods                 :   [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }],
            }, () => {
                this.reportFilterhandler();
            });
        });
    }
    
    reportAnalysisFilterFormSubmit       =   (event)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        let serachFormKeys                      =   Object.keys(this.state.assetAnalysisFilterForm);
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.assetAnalysisSearchFormLabel[key];
            let value                       =   this.state.assetAnalysisFilterForm[key];
            
            if(value && value.toString().length > 0) { 
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_owner_id') {
                        show_val            = this.state.allOwnersList.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_ownership_category') {
                        show_val            = this.state.allOwnershipCategory.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range    =   moment(this.state.assetAnalysisFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let  end_range      =   moment(this.state.assetAnalysisFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let  display_custom =   `Custom Date Range (${start_range} - ${end_range})`
                        show_val            =   this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });

        this.setState({
            submittedAssetAnalysisFilterForm : {...this.state.assetAnalysisFilterForm},
            formSearchedElems                   :  searchedElems,
            assetSearchParams                   :  assetSearchParams
        }, () => {
            this.loadListingTblData(1);
        });
    }

    loadListingTblData                      =   (page = 1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listing_tbl_page        :   page,
            downloadReportBtn       :   false,
            reportDownloading       :   false
        });
        let submittedAssetAnalysisFilterForm   = this.state.submittedAssetAnalysisFilterForm;
        delete submittedAssetAnalysisFilterForm.site_group_ids;
        
        let params                          =   {page:page,...submittedAssetAnalysisFilterForm, action : 'data'};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token,params,{},(response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                downloadReportBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

       
    reportDownloadHandler                   =   (type)  =>  {
        this.setState({ reportDownloading: true});
        let submittedAssetAnalysisFilterForm   = this.state.submittedAssetAnalysisFilterForm;
        delete submittedAssetAnalysisFilterForm.site_group_ids;
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...submittedAssetAnalysisFilterForm, action : type} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('assetProdAnalysisFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCalculator} /> Asset Productivity Analysis</h3>
                <div className="text-end mt15">
                <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
               
                {
                    this.state.downloadReportBtn 
                    ? (<Ax>
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-primary">
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                            {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><Link  className={['dropdown-item', this.state.reportData?.download_access === 'Y' ? '' : 'disabled'].join(' ')} onClick={() => this.reportDownloadHandler('download')}>Detailed Report Download</Link></li>
                            <li><Link  className={['dropdown-item', this.state.reportData?.download_access === 'Y' ? '' : 'disabled'].join(' ')} onClick={() => this.reportDownloadHandler('consolidated_download')}>Consolidated Report Download</Link></li>
                        </ul>
                    </Ax>) 
                    : null
                }
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }

    assetProdAnalysisFilterFormJsx            =   ()  =>  {
        return (<div id="assetProdAnalysisFilterForm" className="bg-white" >
            <form onSubmit={this.reportAnalysisFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
            <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {
                               this.setState({
                                 assetAnalysisFilterForm     :   {
                                     ...this.state.assetAnalysisFilterForm,
                                     site_group_ids             :   selectedOption &&  selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                     search_site_ids            :   selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                 }
                               })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.assetAnalysisFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetAnalysisFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.assetAnalysisFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    assetAnalysisFilterForm: {
                                        ...this.state.assetAnalysisFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.assetAnalysisFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetAnalysisFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.assetAnalysisFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetAnalysisFilterForm', 'search_asset_matrix_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.assetAnalysisFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetAnalysisFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.assetAnalysisFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetAnalysisFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.assetAnalysisFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetAnalysisFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            search_asset_type={[this.state.assetAnalysisFilterForm.search_asset_type]}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Ownership</label>
                    <div className="col-sm-6">
                        <div className='row'>
                            <div className='col-sm-5'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnershipCategory}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetAnalysisFilterForm', 'search_ownership_category');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnershipCategory.find(r => r.value === this.state.assetAnalysisFilterForm.search_ownership_category)}
                                    placeholder="Select Ownership Category"
                                />
                            </div>
                            <div className='col-sm-7'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnersList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetAnalysisFilterForm', 'search_owner_id');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnersList.find(r => r.value === this.state.assetAnalysisFilterForm.search_owner_id)}
                                    placeholder="Select Owner"
                                />
                            </div>
                        </div>


                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Date Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods}
                            value={this.state.assetAnalysisFilterForm.date_range}
                            startDate={this.state.assetAnalysisFilterForm.date_range_start}
                            endDate={this.state.assetAnalysisFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {

                                console.log('period, startDate, endDate', period, startDate, endDate);

                                this.setState({
                                    assetAnalysisFilterForm: { 
                                        ...this.state.assetAnalysisFilterForm, 
                                        date_range: period, 
                                        date_range_start: startDate, 
                                        date_range_end: endDate }
                                });
                            }}
                            required={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }

    assetAnalysisDisplayJsx               =    ()  =>    {
        return (<div className="bg-white">
             {this.state.listingMeta && this.assetAnalysisTableJsx()
            }
             <div className='bg-white text-center'>
                {this.state.listing_loading
                    ? (<Loader />)
                    : (null)}
            </div>
        </div>)
    }

    assetAnalysisTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                <tr>
                    <th style={{width:"17%"}}>Asset Name </th>
                    <th style={{width:"11%"}}>Code</th>
                    <th style={{width:"9%"}}>Running - HMR</th>
                    <th style={{width:"9%"}}>Running - KM</th>
                    <th style={{width:"9%"}}>Fuel Consumed</th>
                    <th style={{width:"9%"}}>Trips</th>
                    <th style={{width:"9%"}}>Trips Per HR</th>
                    <th style={{width:"9%"}}>Fuel Per Trips</th>
                    <th style={{width:"9%"}}>Productivity</th>
                    <th style={{width:"9%"}}>Fuel Per Productivity</th>
                </tr>
                </thead>
                <tbody>
                {this.state.listingData && this.state.listingData.length > 0
                    ?   (this.state.listingData.map((data, i) => { return (
                        <tr key={i}>
                            <td className={"fw-bold"}>{data.name}</td>
                            <td>{data.asset_code}</td>
                            <td className='text-end'>{data.hour_meter_running}</td>
                            <td className='text-end'>{data.odo_meter_running}</td>
                            <td className='text-end'>{data.total_fuel_feedin}</td>
                            <td className='text-end'>{data.trips}</td>
                            <td className='text-end'>{data.trips_per_hr}</td>
                            <td className='text-end'>{data.fuel_per_trip}</td>
                            <td className='text-end'>{data.productivity}</td>
                            <td className='text-end'>{data.fuel_per_productivity}</td>
                        </tr>
                    )}))
                    : <tr><td className="text-center" colSpan={9}>No Record Found</td></tr>
                }
                </tbody>
            </table>
        </Ax>)
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Asset Productivity Analysis - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.assetProdAnalysisFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  />
                                {this.assetAnalysisDisplayJsx()}
                            </Ax>)
                            : <Loader  />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null 
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}


    const mapStateToProps = state => {
        return {
            access_token            :   state.auth.access_token,
            iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
            iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
            iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
            iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
            iam_asset_variants      :   state.app && state.app.asset_variants ? state.app.asset_variants : [],
            iam_models              :   state.app && state.app.models ? state.app.models : [],
            asset_groups            :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
            site_groups             :   state.app && state.app && state.app.site_groups ? state.app.site_groups : [], 
            all_periods             :   state.app && state.app && state.app.all_periods ? state.app.all_periods : [], 
            report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
        };
    };

export default connect(mapStateToProps)(AssetProductivityAnalysis);