import React from 'react';
import { toast } from 'react-toastify';
import swal from "sweetalert"
import { connect } from 'react-redux';
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2, INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import TapIcon from '../../../services/TapIcon';
import Loader from '../../../components/ui/Loader/Loader';
import { Link } from "react-router-dom";
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';
import { Collapse } from 'bootstrap';
import * as actions from "../../../store/actions/configuration"
import SiteProductivityList from '../SiteProductivityList';
import dashboardStyles from "../../dashboard/Dashboard.module.css"
import SiteProductivityBarWidget from './SiteProductivityBarWidget';
import SiteProductivityLineWIdget from './SiteProductivityLineWIdget';
import SiteProductivityPieWidget from './SiteProductivityPieWidget';
import SiteProductivityTargetWidget from './SiteProductivityTargetWidget';
import SiteProductivitySummaryWidget from './SiteProductivitySummaryWidget';
class SiteProductivityConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.list_url = IAM_API_BASE_URL_2 + '/site_productivity_config/list';
        this.detail_url = IAM_API_BASE_URL_2 + '/site_productivity_config/detail';
        this.state = {
            minimizeTable: false,
            listing_tbl_page: 1,
            listing_loading: this.props.configuration_listing_loaded,
            totalListingCount: 0,
            listingMeta: null,
            listingData: [],
            configurationData: null,
            actionPerformed: false,
            site_productivity: false,
            site_productivity_detail: false,
            site_productivity_table_mini: false,
            configTag: true,
            activeTab: 'dashboard',
            productivityData: {},
        }

        this.siteProductivityModalRef = React.createRef();


    }


    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        const siteConfigParam = urlParams.get('siteconfiguration');
        if (this.props?.match?.params?.id) {
            if (siteConfigParam) {
                this.setState({ activeTab: 'site' });
            }
            this.setState({
                minimizeTable: true,
                // configTag :  ParamObject.get('site_productivity') ? false : true
            }, () => {
                this.viewProductivityDetail(this.props?.match?.params?.id)
            });
        } else {
            if (this.props.configuration_meta) {
                this.setState({ listing_tbl_page: this.props.configuration_meta.current_page });
            }
            if (this.props.configuration_meta) {
                this.loadListingTblData(this.props.configuration_meta.current_page);
            } else {
                this.loadListingTblData(1);
            }
        }

    }

    loadListingTblData = (page = 1) => {
        this.setState({ listing_tbl_page: page, listing_loading: true }, () => {
            this.props.onListingLoding();
            this.props.onListingSuccess(page, this.state.configurationSearchParams, "Y", this.state.formSearchedElems
            );
            setTimeout(() => {
                this.setState({
                    listing_loading: false
                });
            }, 2000);
        });
    };
    addConfigurationInit = () => {
        if (this.state.configurationData) {
            this.props.history.push({ pathname: `/site_productivity_configuration_add`, state: { configurationData: this.state.configurationData } })
        } else {
            this.props.history.push({ pathname: `/site_productivity_configuration_add` })
        }

    }

    addConfiguration = () => {
        this.props.history.push({
            pathname: "/site_productivity/add",
            url: this.props?.location?.pathname + "?site_productivity=true",
            state: { ConfigurationData: this.state.configurationData }
        })
    }
    viewProductivityDetail = (id, actionPerformed = false) => {
        if (id) {
            this.setState({ minimizeTable: true, actionPerformed: actionPerformed, })
            this.getConfigurationDetail(id)
        }
    }

    getConfigurationDetail = (id) => {
        //    if(transaction_id !== this.state.purchaseViewHeader){
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', this.detail_url + '/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                configurationData: respData.data,
            });

        }, (err) => {
            if (err) {
                this.setState({ minimizeTable: false })
            }
        }).then(() => this.setState({ view_loading: false }));
        //}
    }

    closeViewDetails = () => {
        this.setState({
            minimizeTable: false,
            configurationData: null
        }, () => {
            // if (this.state.actionPerformed) {
            this.loadListingTblData(this.state.listing_tbl_page);
            // }
            this.state?.collapseOpenClose === 'open' && this.assetListingHandler(true, 'close');
            this.props.history.push("/site_productivity_configuration");
        })

    }

    editConfigurationInit = () => {
        this.props.history.push({ pathname: `/site_productivity_configuration_add`, state: { configurationData: this.state.configurationData } })
    }

    changeStatusInit = () => {
        this.state?.collapseOpenClose === 'open' && this.assetListingHandler(true, 'close');
        let frmData = {
            status: this.state.configurationData.status && this.state.configurationData.status.status == 1 ? 2 : 1,
            id: this.state.configurationData.id ? this.state.configurationData.id : ""
        }
        swal({
            title: "Change Status",
            text: "Are you sure you want to proceed?",
            icon: "warning",
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/site_productivity_config/status', this.props.access_token, {}, { ...frmData }, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getConfigurationDetail(this.state.configurationData.id);
                        this.loadListingTblData(1);
                    }).then(() => {

                    });
                }
            });

    }
    assetListingHandler = (collapse = true, type = 'open') => {
        this.setState({
            collapseOpenClose: type
        });
        collapse && new Collapse(document.getElementById("assetListing"));
    };
    assetListingJsx = () => {
        return (
            <div id="assetListing" className="bg-white collapse">
                <div className="page_title row m0">
                    <div className="col-12 asset_collapse mr0 pr0">
                        <h3>Configuration</h3>
                        <div className="float-end">
                            <button
                                type="button"
                                onClick={() => this.assetListingHandler(true, 'close')}
                                className="btn btn-primary"
                                style={{
                                    height: "71%",
                                    lineHeight: "26px",
                                    maxHeight: "90%!important",
                                    marginTop: "10px",
                                }}
                            >
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faCaretLeft} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" bg-white position-relative z_index">
                    <div className="page_containt row" style={{ height: "81vh" }}>
                        <div className="pageTbl col-12">
                            <table className="table table-bordered table-hover table-sm table-fixed bg-white">
                                <thead className="table-secondary">
                                    <tr> <th scope="col" style={{ width: "15%" }}> Configuration </th> </tr>
                                </thead>
                                <tbody>
                                    {this.props.configuration_listing_loaded ? (
                                        <tr>
                                            <td className="text-center" colSpan="9">
                                                <Loader />
                                            </td>
                                        </tr>
                                    ) : this.props.configuration_listing.length > 0 ? (
                                        this.props.configuration_listing.map((item, index) =>
                                            this.minimizeTableTrJsx(item, index)
                                        )
                                    ) : (
                                        this.minimizeTableTrJsx(null)
                                    )}
                                </tbody>
                            </table>
                            <DisplayListPagination
                                meta={this.props.configuration_meta}
                                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                            />
                        </div>
                    </div>
                </div>

            </div>

        );
    };
    minimizeTableTrJsx = (item, index = 0) => {
        return item ? (
            <tr key={index}>
                <td style={{ width: "25%" }}>

                    <div className="text-capitalize link-primary cursor_pointer">
                        <Link
                            to={{
                                pathname: "/site_productivity_configuration/" + item.id,
                            }}
                            onClick={() => {
                                this.state?.collapseOpenClose === 'open' && this.assetListingHandler(true, 'close')
                                this.viewProductivityDetail(item.id, false)
                            }}
                        >{item.activity_name ? item.activity_name : "-"}   </Link></div>
                    <div className="mt-1">
                        <small className="text-capitalize ">{item.activity_code ? item.activity_code : "-"} </small>
                        <small className="float-end">{item.status ? <Status color={item.status.status_color_code}>{item.status.status_text ? item.status.status_text : ""}</Status> : ""}</small>
                    </div>


                </td>
            </tr>
        ) : (
            <tr key={index}>
                <td className="text-center" colSpan={9}>
                    No Record Found
                </td>
            </tr>
        );
    };


    productivitySearchModal = (type = 'searchModal') => {
        if (this.siteProductivityModalRef.current) {
            this.siteProductivityModalRef.current.initModalHandler(type);
        }

    }


    configurationListjsx = () => {

        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                {!this.state.minimizeTable && (<>
                    <div className='d-flex justify-content-between'>
                        <h3>Configuration</h3>
                        <div className="text-end mt15">
                            <button type="button" onClick={this.addConfigurationInit} disabled={this.state.listing_loading || !this.props.permissions.includes('site-productivity-configuration-add')} className="btn btn-primary" >
                                Add Configuration
                            </button>
                            <button type="button" disabled={this.state.listing_loading || !this.props.permissions.includes('site-productivity-configuration-add')} className="btn btn-secondary">
                                <Link style={{ color: "white" }} target="_blank" to='/general_configuration/site_productivity'>
                                    Additional Attribute
                                </Link>
                            </button>
                        </div>
                    </div>
                </>)}
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <>
                            <div className="col-12 tap_border_left asset_collapse">
                                <button
                                    type="button"
                                    onClick={this.assetListingHandler}
                                    className="btn btn-primary asset_collapse_button"
                                    style={{ height: "73%" }}>
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faCaretRight} />
                                </button>
                                <h3 className=' ps-5'>Configuration : {this.state.configurationData?.activity_name} ({this.state.configurationData?.activity_code})</h3>
                                <div className="text-end mt15">
                                    {
                                        this.state.site_productivity

                                            ?
                                            <>
                                                <button type="button"
                                                    className="btn btn-primary"
                                                    onClick={this.addConfiguration}
                                                    disabled={this.state.configurationData?.status?.status_text === "Inactive"}
                                                >
                                                    Add
                                                </button>
                                                <button type="button" className="btn btn-secondary"
                                                    onClick={() => this.productivitySearchModal('searchModal')}
                                                >
                                                    <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                                                </button>


                                                <button type="button" id='actionMenuButton' className="btn btn-secondary px-3" data-bs-toggle="dropdown" aria-expanded="false" >
                                                    <TapIcon.imageIcon icon={TapIcon.ActionIcon} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" role="button" disabled={!this.props.permissions.includes('site-productivity-configuration-edit')} onClick={() => {
                                                        this.state?.collapseOpenClose === 'open' && this.assetListingHandler(true, 'close')
                                                        this.editConfigurationInit()
                                                    }}>Edit</a></li>
                                                    <li><Link className="dropdown-item" disabled={!this.props.permissions.includes('site-productivity-configuration-change-status')} onClick={this.changeStatusInit} >Change Status</Link></li>
                                                </ul>
                                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                                    <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                                </button>
                                            </>
                                            :
                                            this.state.site_productivity_detail
                                                ?
                                                <>
                                                    <Link
                                                        to={{
                                                            pathname: "/site_productivity/add",
                                                            url: this.props?.location?.pathname + "?site_productivity=true",
                                                            state: { productivityData: this.state.productivityData }
                                                        }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"

                                                        >
                                                            Edit
                                                        </button>
                                                    </Link>
                                                    <button type="button" className="btn btn-secondary"
                                                        onClick={() => this.productivitySearchModal('delete')}
                                                    >
                                                        Delete
                                                    </button>
                                                    <button type="button" className="btn btn-secondary"
                                                        onClick={this.siteProductivityDetailClose}
                                                    >
                                                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    <button type="button"
                                                        className="btn btn-primary"
                                                        onClick={this.addConfiguration}
                                                        disabled={this.state.configurationData?.status?.status_text === "Inactive"}>
                                                        Add
                                                    </button>
                                                    <button type="button" id='actionMenuButton' className="btn btn-secondary px-3" data-bs-toggle="dropdown" aria-expanded="false" >
                                                        <TapIcon.imageIcon icon={TapIcon.ActionIcon} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" disabled={!this.props.permissions.includes('site-productivity-configuration-edit')} onClick={() => {
                                                            this.state?.collapseOpenClose === 'open' && this.assetListingHandler(true, 'close')
                                                            this.editConfigurationInit()
                                                        }}>Edit</a></li>
                                                        <li><Link className="dropdown-item" disabled={!this.props.permissions.includes('site-productivity-configuration-change-status')} onClick={this.changeStatusInit} >Change Status</Link></li>
                                                    </ul>
                                                    <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                                    </button>
                                                </>
                                    }
                                </div>
                            </div>
                        </>
                    )
                    : null
            }

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'd-none' : 'col-12'].join(' ')} >{this.configurationTableJsx()}</div>
                    {this.state.minimizeTable ? <div style={{ height: "100%", paddingRight: "6px" }}>{this.state.view_loading ? <Loader /> : this.configurationViewDetailJsx()}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    configurationTableJsx = () => {
        return (<Ax>
            <table style={{ backgroundColor: "white" }} className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Configuration</th>
                        </tr>)
                        : (
                            <tr>
                                <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                                <th scope="col" style={{ width: "25%" }}>Activity Name (Code)</th>
                                <th scope="col" style={{ width: "25%" }} className="text-center">Sites</th>
                                <th scope="col" style={{ width: "13%" }} className="text-center">UOM</th>
                                <th scope="col" style={{ width: "13%" }} className="text-center">Accounting Code</th>
                                <th scope="col" style={{ width: "11%" }} className="text-center">Status</th>
                                <th scope="col" style={{ width: "8%" }} className="text-center">Action</th>

                            </tr>)}
                </thead>
                <tbody>{this.props.configuration_listing_loaded ? (
                    <tr>
                        <td className="text-center" colSpan="9">
                            <Loader />
                        </td>
                    </tr>
                ) : this.props.configuration_listing.length > 0 ? (
                    this.props.configuration_listing.map((item, index) =>
                        this.listingTableTrJsx(item, index)
                    )
                ) : (
                    this.listingTableTrJsx(null)
                )}
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.props.configuration_meta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>

        )
    }
    listingTableTrJsx = (item, index) => {
        return item ? (
            <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className='text-capitalize link-primary cursor_pointer'>
                    <Link to={{ pathname: "/site_productivity_configuration/" + item.id }}
                        onClick={() => this.viewProductivityDetail(item.id, false)}>
                        {item.activity_name ? item.activity_name : "-"} ({item.activity_code ? item.activity_code : "-"})
                    </Link></td>
                <td>{item.first_site ? <span>{item.first_site.site_name} {item.sites_count > 1 && <span>(+{item.sites_count - 1}) </span>}</span> : ""}</td>
                <td>{item.uom ? item.uom.measuring_unit : ""}</td>
                <td>{item.account_Code ? item.account_Code.name : ""}</td>
                <td className="text-center">{item.status ? <Status color={item.status.status_color_code}>{item.status.status_text ? item.status.status_text : ""}</Status> : ""}</td>
                <td className="text-center">
                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" role="button">
                            <Link to={{ pathname: "/site_productivity_configuration/" + item.id }} onClick={() => this.viewProductivityDetail(item.id, false)}>
                                View</Link>
                        </a></li>
                    </ul>
                </td>
            </tr>
        ) : (
            <tr key={index}>
                <td className="text-center" colSpan={9}>
                    No Record Found
                </td>
            </tr>
        );
    };

    handleTags = (type = '') => {
        if (type === 'site_productivity') {
            this.setState({
                configTag: false,
                site_productivity: this.state.site_productivity_detail ? false : true
            });
        } else if (type === 'site_productivity_detail') {
            this.setState({
                site_productivity: false,
                site_productivity_detail: true
            });
        } else {
            this.setState({
                configTag: true,
                site_productivity: false,
                site_productivity_detail: false
            });
        }
    }

    siteProductivityDetailClose = () => {
        this.setState({
            site_productivity: true,
            site_productivity_detail: false,
            site_productivity_table_mini: true
        });
    }
    configurationViewDetailJsx = () => {
        let data = this.state.configurationData;
        return (
            <div className=''>
                <ul className="nav nav-tabs " style={{ backgroundColor: "white" }}
                    id="myTab" role="tablist">
                    <li>
                        <button className={`btn nav-link text-capitalize 
                          ${this.state.activeTab === 'dashboard' ? 'active' : ''}`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected={this.state.activeTab === 'dashboard'} onClick={() => { this.setState({ activeTab: 'dashboard' }); this.handleTags(); }}> Dashboard </button>
                    </li>
                    <li>
                        <button className={`btn nav-link text-capitalize ${this.state.activeTab === 'configuration' ? 'active' : ''}`} id="configuration-tab" data-bs-toggle="tab" data-bs-target="#configuration" type="button" role="tab" aria-controls="configuration" aria-selected={this.state.activeTab === 'configuration'} onClick={() => { this.setState({ activeTab: 'configuration' }); this.handleTags(); }} > Configuration
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`btn nav-link text-capitalize ${this.state.activeTab === 'site' ? 'active' : ''}`} id="site-tab" data-bs-toggle="tab" data-bs-target="#site" type="button" role="tab" aria-controls="site" aria-selected={this.state.activeTab === 'site'} onClick={() => { this.setState({ activeTab: 'site' }); this.handleTags('site_productivity'); }} > Site-Productivity </button>
                    </li>
                </ul>
                <section className="pageTbl" style={{ overflowY: "auto" }}>
                    <div className="tab-content" id="myTabContent">
                        <div className={`tab-pane ${this.state.activeTab === 'configuration' ? 'show active' : ''}`} id="configuration" role="tabpanel" aria-labelledby="configuration-tab" >
                            {this.state.configTag && data &&
                                <div className="tab_content_header mt-1" style={{ height: "calc(100vh - 165px)" }}>
                                    {this.state.view_loading ? <Loader /> : data
                                        ?
                                        <div>
                                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0  mt-3 ">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Activity Name</td>
                                                        <th>{data.activity_name ? data.activity_name : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Activity Code</td>
                                                        <th>{data.activity_code ? data.activity_code : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Unit of Measurement</td>
                                                        <th>{data.uom ? data.uom.measuring_unit : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Sites</td>
                                                        <th>{data.sites ? data.sites.map(st => { return (st.site_name) }).join(', ') : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Status</td>
                                                        <th>{data.status ? <Status color={data.status.status_color_code}>{data.status.status_text ? data.status.status_text : ""}</Status> : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>No of Trips/Sessions</td>
                                                        <th>{data.number_of_trip && data.number_of_trip == "Y" ? "Yes" : "No"}</th>
                                                    </tr>
                                                    {data.number_of_trip && data.number_of_trip == "Y" &&
                                                        <tr>
                                                            <td style={{ width: "33%" }}>No of Trips/Sessions Required</td>
                                                            <th>{data.number_of_trip_required && data.number_of_trip_required == "Y" ? "Yes" : "No"}</th>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Material</td>
                                                        <th>{data.material && data.material == "Y" ? "Yes" : "No"}</th>
                                                    </tr>
                                                    {data.material && data.material == "Y" &&
                                                        <tr>
                                                            <td style={{ width: "33%" }}>Material Required</td>
                                                            <th>{data.material_required && data.material_required == "Y" ? "Yes" : "No"}</th>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Attachment</td>
                                                        <th>{data.attachment && data.attachment == "Y" ? "Yes" : "No"}</th>
                                                    </tr>
                                                    {data.attachment && data.attachment == "Y" &&
                                                        <tr>
                                                            <td style={{ width: "33%" }}>Attachment Required</td>
                                                            <th>{data.attachment_required && data.attachment_required == "Y" ? "Yes" : "No"}</th>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td style={{ width: "33%" }}>WorkStation</td>
                                                        <th>{data.workstation && data.workstation == "Y" ? "Yes" : "No"}</th>
                                                    </tr>
                                                    {data.workstation && data.workstation == "Y" &&
                                                        <tr>
                                                            <td style={{ width: "33%" }}>WorkStation Required</td>
                                                            <th>{data.workstation_required && data.workstation_required == "Y" ? "Yes" : "No"}</th>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Employee</td>
                                                        <th>{data.employee && data.employee == "Y" ? "Yes" : "No"}</th>
                                                    </tr>
                                                    {data.employee && data.employee == "Y" &&
                                                        <tr>
                                                            <td style={{ width: "33%" }}>Employee Required</td>
                                                            <th>{data.employee_required && data.employee_required == "Y" ? "Yes" : "No"}</th>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Additional Attributes</td>
                                                        <th>{data.additionalAttr_details && data.additionalAttr_details.length > 0 ? data.additionalAttr_details.map(st => st.name).join(', ') : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Productivity Formula</td>
                                                        <th>{data.productivity_formula ? data.productivity_formula : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Accounting Code</td>
                                                        <th>{data.account_Code ? data.account_Code.name : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Starting Date</td>
                                                        <th>{data.starting_date_display ? data.starting_date_display : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Closing Date</td>
                                                        <th>{data.closing_date_display ? data.closing_date_display : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Target Type</td>
                                                        <th className='text-capitalize'>{data.target_type ? data.target_type : ""}</th>
                                                    </tr>
                                                    {data.target_type &&
                                                        <Ax>
                                                            <tr>
                                                                <td style={{ width: "33%" }}>Total Productivity Target</td>
                                                                <th className='text-capitalize'>{data.total_productivity_target ? data.total_productivity_target : ""}</th>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "33%" }}>Target Per Day</td>
                                                                <th className='text-capitalize'>{data.target_per_day ? data.target_per_day : ""}</th>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "33%" }}>Cost Per Unit Target</td>
                                                                <th className='text-capitalize'>{data.cost_per_unit_target ? data.cost_per_unit_target : ""}</th>
                                                            </tr>
                                                        </Ax>
                                                    }
                                                    <tr>
                                                        <td style={{ width: "33%" }}>Notes</td>
                                                        <th>{data.notes ? data.notes : ""}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        : null
                                    }
                                </div>
                            }
                        </div>
                        <div className={`tab-pane ${this.state.activeTab === 'site' ? 'show active' : ''}`} id="site" role="tabpanel" aria-labelledby="site-tab">
                            {!this.state.configTag && <SiteProductivityList ref={this.siteProductivityModalRef} handleTags={(type) => this.handleTags(type)} runBy="configuration" minimizeTable={this.state.site_productivity_table_mini} productivityDataHandle={(data) => this.setState({ productivityData: data })} configurationData={this.state.configurationData} />}
                        </div>
                        <div className={`tab-pane  ${this.state.activeTab === 'dashboard' ? 'show active' : ''}`} id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                            <div style={{ height: "78vh" }} className={dashboardStyles.tab_content_header}>
                                <div className='col-md-8'>
                                    {/* <SiteProductivitySummaryWidget /> */}
                                    <SiteProductivityBarWidget configId={this.state.configurationData?.id} />
                                    <SiteProductivityPieWidget configId={this.state.configurationData?.id} />
                                    <SiteProductivityLineWIdget configId={this.state.configurationData?.id} />
                                    <SiteProductivityTargetWidget configId={this.state.configurationData?.id} />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    render = () => {

        return (<ApplicationLayout>
            <Helmet><title>Site Productivity - Configuration</title></Helmet>
            <div className="asset_collapse">
                <div className="asset_table">{this.assetListingJsx()}</div>
                {this.configurationListjsx()}
            </div>
        </ApplicationLayout>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onListingLoding: (data) =>
            dispatch(actions.configurationListingDataInitilization()),
        onListingSuccess: (page, searchParm, formSearchedElems) =>
            dispatch(
                actions.configurationListingDataLoading(
                    page,
                    searchParm,
                    formSearchedElems
                )
            )
    };
};

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        configuration_listing_loaded:
            state.app && state.app.configuration_listing_loaded
                ? state.app.configuration_listing_loaded
                : false,
        configuration_listing:
            state.app && state.app.configuration_listing ? state.app.configuration_listing : [],
        configuration_meta: state.app && state.app.configuration_meta ? state.app.configuration_meta : null,
        configuration_total_count:
            state.app && state.app.configuration_total_count
                ? state.app.configuration_total_count
                : 0,
        configuration_param:
            state.app && state.app.configuration_search_param
                ? state.app.configuration_search_param
                : null,
        searchedElems:
            state.app && state.app.formSearchedElems
                ? state.app.formSearchedElems
                : [],
        all_periods: state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_user_sites: state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions: state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SiteProductivityConfiguration);


