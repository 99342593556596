

import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Chart } from "react-google-charts";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import TapIcon from '../../../services/TapIcon';
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { fetchProductivityTargetWidget } from '../../../store/actions/SiteProductivityAction/ProductivityWidgetAction';

class SiteProductivityLineWIdget extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initFilterForm = {
            filter_site_id: '',
            date_range: 'current_month',
            key: 'daily_target_widget'
        };

        this.state = {
            filterForm: { ...this.initFilterForm },
            iam_user_sites: [],
            all_periods: [],
            remark_time: '',
            siteLoading: false,
            dailyOrMonthly: 'daily',
        };
        this.updateMyDashboardRef = React.createRef();

        this.options = [
            { value: "daily", label: "Daily" },
            { value: "monthly", label: "Monthly" }
        ];
    }

    componentDidMount() {
        this.initializeFormFilter(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.iam_user_sites !== this.props.iam_user_sites ||
            nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id ||
            nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initializeFormFilter(nextProps);
        }
    }
    initializeFormFilter = (props) => {
        const { iam_user_sites, configId, fetchProductivityTargetWidget, all_periods } = props;
        const newPeriods = this.state.dailyOrMonthly === 'daily'
            ? all_periods.filter(p => p.key === 'current_month' ||
                p.key === 'current_week' ||
                p.key === 'previous_week' ||
                p.key === 'previous_month'
            )
            : all_periods.filter(p => p.key === 'current_quarter' ||
                p.key === 'previous_quarter' ||
                p.key === 'previous_year' ||
                p.key === 'current_year' ||
                p.key === 'trailing_twelve_months'
            );

        this.setState({
            siteLoading: true
        }, () => {
            if (iam_user_sites.length > 0) {
                this.setState({
                    iam_user_sites: iam_user_sites.map((s) => ({ value: s.id, label: `${s.site_name} (${s.site_code})` })),
                    all_periods: newPeriods.map(p => ({ value: p.key, label: p.display }))
                }, () => {
                    const { filterForm } = this.state;
                    fetchProductivityTargetWidget(configId, filterForm);
                });
            }
            this.setState({ siteLoading: false });
        });
    }
    
    handleFilterChange = (selectedOption, field) => {
        const { fetchProductivityTargetWidget, configId, all_periods } = this.props;

        if (field === 'dailyOrMonthly') {
            const newKey = selectedOption.value === 'daily' ? 'daily_target_widget' : 'monthly_target_widget';
            const newDateRange = selectedOption.value === 'daily' ? 'current_month' : 'current_year';
            const newPeriods = selectedOption.value === 'daily'
                ? all_periods.filter(p => p.key === 'current_month' ||
                    p.key === 'current_week' ||
                    p.key === 'previous_week' ||
                    p.key === 'previous_month'
                )
                : all_periods.filter(p => p.key === 'current_quarter' ||
                    p.key === 'previous_quarter' ||
                    p.key === 'previous_yeamonthly_target_widgetr' ||
                    p.key === 'current_year' ||
                    p.key === 'trailing_twelve_months'
                );
            this.setState(prevState => ({
                dailyOrMonthly: selectedOption.value,
                filterForm: {
                    ...prevState.filterForm,
                    key: newKey,
                    date_range: newDateRange
                },
                all_periods: newPeriods.map(p => ({ value: p.key, label: p.display }))
            }), () => {
                const { filterForm } = this.state;
                this.setState({ siteLoading: true }, () => {
                    fetchProductivityTargetWidget(configId, filterForm);
                    this.setState({ siteLoading: false });
                });
            });
        } else {
            this.setState(prevState => ({
                filterForm: {
                    ...prevState.filterForm,
                    [field]: selectedOption ? selectedOption.value : ''
                }
            }), () => {
                const { filterForm } = this.state;
                this.setState({ siteLoading: true }, () => {
                    fetchProductivityTargetWidget(configId, filterForm);
                    this.setState({ siteLoading: false });
                });
            });
        }
    }

    pinMyDashboardHandler = (widgetData = null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData, { key: "productivity_target_achivement", name: "Target Vs Achivement" })
    }


    refreshHandle = () => {
        this.props.fetchProductivityTargetWidget(this.props.configId, { ...this.state.filterForm, reload: "reload" });
    }


    render() {
        const { productivityWidgetdata, loadingWidget } = this.props;
        const { filterForm, dailyOrMonthly, all_periods, iam_user_sites } = this.state;
        let cumulativeTotal = 0;
        let target = 0;
        const data = productivityWidgetdata?.map(d => {
            cumulativeTotal = parseFloat(d.total_production);
            target = parseFloat(d.target);
            return [
                d.transaction_date,
                cumulativeTotal,
                target
            ];
        });
        
        const chartData =
            [['Month', 'Total Production', 'Target'], ...data];
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {
            return ("productivity_target_achivement" == st.key)
        }) ? this.props.widget_keys.find(st => { return (st.key == "productivity_target_achivement") }) : null;
        return (
            <div className="row bg-white py-2">
                <div className="col-md-12">
                    <div className="card mt-1">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-5">
                                    <h6 className="fw-bold primary_color">Target Vs Achivement (StandAlone)</h6>
                                </div>
                                <div className="col-2 p0">
                                    <TapSelect
                                        isSearchable={true}
                                        placeholder="Choose"
                                        containerHeight="33px"
                                        fontSize="93%"
                                        value={this.options.find(m => m.value === dailyOrMonthly)}
                                        changeEvent={(selectedOption) => this.handleFilterChange(selectedOption, 'dailyOrMonthly')}
                                        options={this.options}
                                    />
                                </div>
                                <div className="col-2">
                                    <TapSelect
                                        isClearable={false}
                                        placeholder="Select Period"
                                        containerHeight="33px"
                                        fontSize="93%"
                                        options={all_periods}
                                        value={all_periods.find(r => r.value === filterForm.date_range)}
                                        changeEvent={(selectedOption) => this.handleFilterChange(selectedOption, 'date_range')}
                                    />
                                </div>
                                <div className="col-2 p0">
                                    <TapSelect
                                        options={iam_user_sites}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={iam_user_sites.find(m => m.value === filterForm.filter_site_id)}
                                        placeholder="All Sites"
                                        containerHeight="33px"
                                        fontSize="93%"
                                        changeEvent={(selectedOption) => this.handleFilterChange(selectedOption, 'filter_site_id')}
                                    />
                                </div>
                                <div className="col-sm-1 p-0 mx-0 text-center">
                                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <Link className={['dropdown-item'].join(' ')} onClick={this.refreshHandle}>Refresh</Link>
                                        {/* <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="row mt-2 align-items-center">
                                {productivityWidgetdata && productivityWidgetdata.length > 0 ? (<>
                                    {loadingWidget ? (
                                        <Loader />
                                    ) :
                                        <>
                                            <div className="col-12 pr0 mr0 text-end">
                                                <Chart
                                                    chartType={"ColumnChart"}
                                                    data={chartData}
                                                    options={{
                                                        curveType: "function",
                                                        legend: { position: "left" },
                                                        colors: ['#53A8E3', '#EC4E4E'],
                                                        targetAxisIndex: 1,
                                                        vAxis: {
                                                            viewWindow: {
                                                                min: 0,
                                                            },
                                                        },
                                                        chartArea: { right: 0, top: 0, width: "80%", height: "70%" },
                                                    }}
                                                    rootProps={{ 'data-testid': '1' }}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-bordered table-borderless bg-white my-2">
                                                        <thead className="table-secondary">

                                                            <tr className="text-center">
                                                                <th className="text-start" >Date/Month</th>
                                                                {productivityWidgetdata && productivityWidgetdata.length > 0 ?
                                                                    <>
                                                                        {productivityWidgetdata.map((i, r) => {
                                                                            return (<th className="text-end">{i.transaction_date} </th>)
                                                                        })}
                                                                    </>
                                                                    : <th >No Record</th>}
                                                            </tr>
                                                        </thead>
                                                        {this.state.siteLoading
                                                            ? <Loader />
                                                            : (<tbody>
                                                                {productivityWidgetdata && productivityWidgetdata.length > 0 ? (
                                                                    <>
                                                                        <tr className="text-center">
                                                                            <td className="text-start">Total Production</td>
                                                                            {productivityWidgetdata.map((i, r) => {
                                                                                const productivity = parseFloat(i.total_production)
                                                                                const totalProductivity =
                                                                                    Number.isInteger(productivity) ?
                                                                                        productivity.toString() :
                                                                                        productivity.toFixed(3);
                                                                                return (
                                                                                    <td className="text-end" key={r}>
                                                                                        {totalProductivity}
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                        <tr className="text-center">
                                                                            <td className="text-start">Target</td>
                                                                            {productivityWidgetdata.map((i, r) => {
                                                                                const target = parseFloat(i.target)
                                                                                const targetData =
                                                                                    Number.isInteger(target) ?
                                                                                        target.toString() :
                                                                                        target.toFixed(3);
                                                                                return (
                                                                                    <td className="text-end" key={r}>
                                                                                        {targetData}
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    </>
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={6}>No Record</td>
                                                                    </tr>
                                                                )}

                                                            </tbody>)}

                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>

                                ) : null}

                                <div className="col-sm-12 mt-1 text-muted fs11">
                                    Report as of {this.props.productivityWidgettime}
                                </div>
                            </div>
                        </div>
                        <UpdateMyDashboardForm ref={this.updateMyDashboardRef} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProductivityTargetWidget: (configId, filterForm) => dispatch(fetchProductivityTargetWidget(configId, filterForm)),
    };
};

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        all_periods: state.app?.all_periods || [],
        iam_user_sites: state.app?.user_sites || [],
        default_currency: state.app?.default_currency || 'INR',
        widget_keys: state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
        productivityWidgetdata: state.SiteProductivityTargetWidgetReducer?.data?.data || [],
        productivityWidgettime: state.SiteProductivityTargetWidgetReducer?.data?.time || [],
        error: state.SiteProductivityTargetWidgetReducer?.error || null,
        loadingWidget: state.SiteProductivityTargetWidgetReducer?.loading || false,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteProductivityLineWIdget);



