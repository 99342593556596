import React from 'react';
function SiteProductivityDetails({ productivityData }) {
    let formula = productivityData && productivityData.configData && productivityData.configData.productivity_formula ? productivityData.configData.productivity_formula : ""
    if (productivityData && productivityData.additionalAttributes && productivityData.additionalAttributes.length > 0) {
        productivityData.additionalAttributes.forEach(production => {
            if (formula && formula.includes(production.formula_key)) {
                formula = formula.replace(production.formula_key, production.name)
            }
        })
    }
    return (
        <>
            {productivityData ? (
                <div>
                    <div className="tab_content_wrapper"> <span className="content_heading">Basic Details</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td style={{ "width": "25%" }}>Transaction ID</td>
                                <th>{productivityData.transaction_id ? productivityData.transaction_id : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Transaction Date</td>
                                <th>{productivityData.transaction_date_display ? productivityData.transaction_date_display : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Activity Name</td>
                                <th>{productivityData.activity_name ? productivityData.activity_name : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Activity Code</td>
                                <th>{productivityData.activity_code ? productivityData.activity_code : ""}</th>
                            </tr>

                            <tr>
                                <td style={{ "width": "25%" }}>Site</td>
                                <th>{productivityData.siteData ? productivityData.siteData.site_name : ""}</th>
                            </tr>

                            <tr>
                                <td style={{ "width": "25%" }}>No of Trips/Sessions</td>
                                <th>{productivityData.trip ? productivityData.trip : ""}</th>
                            </tr>

                            {productivityData.materialData && <tr>
                                <td style={{ "width": "25%" }}>Material</td>
                                <th>{productivityData.materialData.material_name}</th>
                            </tr>}

                            {productivityData.attachmentData && <tr>
                                <td style={{ "width": "25%" }}>Attachment</td>
                                <th>{productivityData.attachmentData.name}</th>
                            </tr>}

                            {productivityData.workstationData && <tr>
                                <td style={{ "width": "25%" }}>WorkStation</td>
                                <th>{productivityData.workstationData.workstation_name}</th>
                            </tr>}

                            {productivityData.employees && productivityData.employees.length > 0 && <tr>
                                <td style={{ "width": "25%" }}>Employee</td>
                                <th>{productivityData.employees.map(em => { return (<div>{em.display_full_name}</div>) })}</th>
                            </tr>}
                            {productivityData && productivityData.additionalAttributes && productivityData.additionalAttributes.length > 0
                                ? productivityData.additionalAttributes.map((attr) => {
                                    return (<tr key={attr.key}>
                                        <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                        <th scope="col">{attr.value_display ? attr.value_display : "-"}</th>
                                    </tr>)
                                }) : null}
                            <tr>
                                <td style={{ "width": "25%" }}>Productivity Formula</td>
                                <th>{formula ? formula : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Production</td>
                                <th>{productivityData.production ? <span>{productivityData.production} {productivityData.uom_name && productivityData.uom_name}</span> : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Accounting Code</td>
                                <th>{productivityData.accountCode ? productivityData.accountCode.name : ""}</th>
                            </tr>

                            <tr>
                                <td style={{ "width": "25%" }}>Target Type</td>
                                <th className='text-capitalize'>{productivityData && productivityData.configData.target_type ? productivityData.configData.target_type : ""}</th>
                            </tr>
                            {productivityData && productivityData.configData && productivityData.configData.target_type && <>
                                <tr>
                                    <td style={{ "width": "25%" }}>Total Productivity Target</td>
                                    <th className='text-capitalize'>{productivityData.configData && productivityData.configData.total_productivity_target ? productivityData.configData.total_productivity_target : ""}</th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Target Per Day</td>
                                    <th className='text-capitalize'>{productivityData.configData && productivityData.configData.target_per_day ? productivityData.configData.target_per_day : ""}</th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Cost Per Unit Target</td>
                                    <th className='text-capitalize'>{productivityData.configData && productivityData.configData.cost_per_unit_target ? productivityData.configData.cost_per_unit_target : ""}</th>
                                </tr> </>}

                            <tr>
                                <td style={{ "width": "25%" }}>Remarks</td>
                                <th>{productivityData.remarks ? productivityData.remarks : ""}</th>
                            </tr>

                        </tbody>
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Transaction Details</span></div>
                    <table className="table my-3 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "25%" }}>Transaction ID</td>
                                <th style={{ "width": "66%" }}>{productivityData.transaction_id ? productivityData.transaction_id : ""}   </th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Transaction</td>
                                <th style={{ "width": "66%" }}>{productivityData.transaction_date_display ? productivityData.transaction_date_display : '-'}   </th>
                            </tr>

                            <tr>
                                <td>Created By</td>
                                <th>{productivityData.createdBy ? productivityData.createdBy.full_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Entry</td>
                                <th style={{ "width": "66%" }}>{productivityData.created_at_display ? productivityData.created_at_display : '-'}   </th>
                            </tr>
                            <tr>
                                <td>Updated By</td>
                                <th>{productivityData.lastUpdatedBy ? productivityData.lastUpdatedBy.full_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Updation</td>
                                <th style={{ "width": "66%" }}>{productivityData.updated_at_display ? productivityData.updated_at_display : '-'}   </th>
                            </tr>
                            {/* <tr>
                                    <td style={{ "width": "25%" }}>Date of Update</td>
                                    <th style={{ "width": "66%" }}>{productivityData.created_at_display ? productivityData.created_at_display : '-'}   </th>
                                </tr> */}
                        </tbody>
                    </table>

                </div>
            ) : null}
        </>
    );
}

export default SiteProductivityDetails;

