import React from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { Collapse } from "bootstrap";
import { Modal } from "bootstrap";
import { Link } from "react-router-dom";
import TapSelect from "../../../components/ui/TapSelect";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import TapIcon from "../../../services/TapIcon";
import AssetDetailHorizontalCard from "../includes/AssetDetailHorizontalCard";
import { DisplaySearchCriteria, PeriodSelectionWithCustom } from "../../../components/TapUi";
import Status from "../../../components/ui/Status";
import ReactPaginate from "react-paginate";
import TicketDetail from "../../transactions/ticketTransaction/TicketDetail";
import download from "../../../services/DownloadFile";
import AssetHeader from "../includes/AssetHeader";
import { Helmet } from "react-helmet";

class TicketList extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.initShareEmail = {
      to_emails: [],
      message: "",
      subject: "",
      attachment: "N",
    };

    this.ticketSearchFormInitialState = {
      search_ticket_type_id: "",
      search_ticket: "",
      "search_ticket_id  ": null,
      search_ticket_header: "",
      search_site_ids: "",
      search_asset_type: "",
      search_tagged_users: [],
      search_ageing_days: "",
      closure_date_range: "",
      closure_date_range_start: "",
      closure_date_range_end: "",
      creation_date_range: "",
      creation_date_range_start: "",
      creation_date_range_end: "",
      due_date_range: "",
      due_date_range_start: "",
      due_date_range_end: "",
      remark_ageing_days: "",
      search_ticket_status: [],
    };

    this.ticketSearchFormLabel = {
      search_ticket_type_id: "Ticket Type : ",
      search_ticket: "Ticket : ",
      search_ticket_id: "Ticket ID : ",
      search_ticket_header: "Ticket Header : ",
      search_site_ids: "Site : ",
      search_asset_type: "Asset Type : ",
      search_tagged_users: "Tagged Users : ",
      search_ageing_days: "Ageing Days : ",
      closure_date_range: "Completion Date Range : ",
      creation_date_range: "Creation Date Range : ",
      due_date_range: "Due Date Range : ",
      remark_ageing_days: "Remark Ageing Days : ",
      search_ticket_status: "Ticket Status : ",
    };

    this.state = {
      access_token: "",
      assetDetail: null,
      listing_loading: false,
      listing_tbl_page: 1,
      ticket_listing: [],
      listingMeta: null,
      totalListingCount: 0,
      asset_loading: false,
      assetId: null,
      searchedItem: null,
      current_page: 1,
      minimizeTable: false,
      view_loading: false,
      ticketDetail: "",
      ticketHeader: "",
      ticketDownloading: false,
      shareEmailForm: { ...this.initShareEmail },
      options: [],
      addDocumentForm: { ...this.initAddDocument },
      share_email_loading: false,
      reportDownloading: false,
      ticketSearchForm: { ...this.ticketSearchFormInitialState },
      submittedTicketFilterForm: { ...this.ticketSearchFormInitialState },
      allTicketType: [],
      searchedAsset: null,
      iam_asset_types: [],
      iam_user_sites: [],
      filterFormData: [],
      form_loading: false,
      formSearchedElems: [],
      allTaggedUsers: [],
      allStatusList: [],
    };
    this.ticketDetailRef = React.createRef();

    this.ticketListUrl = IAM_API_BASE_URL_2 + "/ticket/list";
    this.ticketFormDataUrl = IAM_API_BASE_URL_2 + "/ticket/list_form_data";
    this.ticketShareUrl = IAM_API_BASE_URL_2 + "/ticket/share";
    this.ticketDownloadUrl = IAM_API_BASE_URL_2 + "/ticket/download";
    this.ticketDetailUrl = IAM_API_BASE_URL_2 + "/ticket/detail";
  }

  componentDidMount = () => {
    this.initalizeData(this.props);
    this.filterFormDataInit();
    this.shareTicketModal = new Modal(
      document.getElementById("shareTicketModal"),
      { keyboard: false, backdrop: false }
    );
    this.ticketSearchModal = new Modal(
      document.getElementById("ticketSearchModal"),
      { keyboard: false, backdrop: false }
    );
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.initalizeData(nextProps);
    }
  }

  initalizeData = (pr) => {
    this.setState(
      {
        assetId: pr.match?.params?.id,
        iam_asset_types: pr.iam_asset_types
          ? pr.iam_asset_types.map((at) => {
              return { value: at.id, label: at.asset_type_name };
            })
          : [],
        iam_user_sites: pr.iam_user_sites
          ? pr.iam_user_sites.map((s) => {
              return { value: s.id, label: `${s.site_name} (${s.site_code})` };
            })
          : [],
      },
      () => {
        this.getAsssetDetails(pr.match?.params?.id);
        this.loadListingTblData(1, pr.match?.params?.id);
      }
    );
  };

  filterFormDataInit = () => {
    this.setState({ form_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.ticketFormDataUrl,
      this.props.access_token,
      {},
      {},
      (resp) => {
        this.setState({
          filterFormData: resp.data,
          allTicketType: resp.data.ticket_types?.map((s) => {
            return { value: s.id, label: s.name };
          }),
          allTaggedUsers: resp.data.users?.map((s) => {
            return { value: s.id, label: s.full_name };
          }),
          allStatusList: resp.data.status_list?.map((s) => {
            return { value: s.key, label: s.name };
          }),
        });
      }
    ).then(() => {
      this.setState({ form_loading: false });
    });
  };

  allTicketOptions = [
    { value: "my_ticket", label: "My Ticket (Created by me)" },
    { value: "my_tagged_tickets", label: " My Tagged Tickets" },
  ];

  getAsssetDetails = (id) => {
    let Id = id ? id : this.state.assetId;
    this.setState({ asset_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      IAM_API_BASE_URL_2 + "/asset/" + Id,
      this.props.access_token,
      {},
      {},
      (response) => {
        this.setState({ assetDetail: response.data.data });
      },
      (error) => {
        toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
      }
    ).then(() => {
      this.setState({ asset_loading: false });
    });
  };

  //************************GET ticket LISTING**************** */
  loadListingTblData = (page = 1, id) => {
    this.setState({ listing_loading: true, listing_tbl_page: page });
    let params = {
      page: page,
      search_asset_id: id,
      ...this.state.submittedTicketFilterForm,
    };

    HttpAPICall.withAthorization(
      "GET",
      this.ticketListUrl,
      this.props.access_token,
      { ...params },
      {},
      (resp) => {
        let respData = resp.data;

        this.setState(
          {
            ticket_listing: resp.data.data,
            listingMeta: respData.meta,
            totalListingCount: respData.meta.total,
            current_page: respData.meta.current_page,
          },
          () => {}
        );
      }
    ).then(() => this.setState({ listing_loading: false }));
  };

  assetCardHandler = (collapse = true) => {
    new Collapse(document.getElementById("assetCard"));
    if (document.getElementById("pageTbl")) {
      document.getElementById("pageTbl").scroll({ top: 0, behavior: "smooth" });
    }
  };

  ticketSearchModalInit = () => {
    this.ticketSearchModal.show();
  };

  submitTicketSearchForm = (e = null) => {
    e && e.preventDefault();
    let serachFormKeys = Object.keys(this.state.ticketSearchForm);

    let searchedElems = [];
    let searchParams = {};
    serachFormKeys.map((key) => {
      let label = this.ticketSearchFormLabel[key];
      let value = this.state.ticketSearchForm[key];
      if (value !== null && value.toString().length > 0) {
        searchParams[key] = value;
        if (label) {
          let show_val = value;
          if (key == "search_site_ids") {
            show_val = this.state.iam_user_sites
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_ticket_type_id") {
            show_val = this.state.allTicketType
              .filter((s) => value === s.value)
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_ticket_header") {
            show_val = this.state.ticketSearchForm
              ? this.state.ticketSearchForm.search_ticket_header
              : "";
          }
          if (key == "search_ticket_id") {
            show_val = this.state.ticketSearchForm
              ? this.state.ticketSearchForm.search_ticket_id
              : "";
          }
          if (key == "search_asset_type") {
            show_val = this.state.iam_asset_types
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_ageing_days") {
            show_val = this.state.ticketSearchForm
              ? this.state.ticketSearchForm.search_ageing_days
              : "";
          }
          if (key == "closure_date_range") {
            show_val = this.state.filterFormData.periods
              .filter((s) => value.includes(s.key))
              .map((s) => s.display_with_date);
          }
          if (key == "creation_date_range") {
            show_val = this.state.filterFormData.periods
              .filter((s) => value.includes(s.key))
              .map((s) => s.display_with_date);
          }
          if (key == "due_date_range") {
            show_val = this.state.filterFormData.periods
              .filter((s) => value.includes(s.key))
              .map((s) => s.display_with_date);
          }
          if (key == "remark_ageing_days") {
            show_val = this.state.ticketSearchForm
              ? this.state.ticketSearchForm.remark_ageing_days
              : "";
          }
          if (key == "search_tagged_users") {
            show_val = this.state.allTaggedUsers
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_ticket") {
            show_val = this.allTicketOptions
              .filter((s) => value.includes(s.value))
              .map((s) => s.label);
          }
          if (key == "search_ticket_status") {
            show_val = this.state.allStatusList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }

          searchedElems.push(label + `<b>${show_val}</b>`);
        }
      }
    });
    this.setState(
      {
        submittedTicketFilterForm: { ...this.state.ticketSearchForm },
        formSearchedElems: searchedElems,
      },
      () => {
        this.ticketSearchModal.hide();
        this.loadListingTblData(1, this.state.assetId);
      }
    );
  };

  ticketSearchClear = () => {
    this.setState(
      {
        formSearchedElems: [],
        searchedAsset: null,
        ticketSearchForm: { ...this.ticketSearchFormInitialState },
        submittedTicketFilterForm: { ...this.ticketSearchFormInitialState },
      },
      () => {
        this.loadListingTblData(1, this.state.assetId);
        this.ticketSearchModal.hide();
      }
    );
  };

  //*********************VIEW TICKET DATA********************* */
  viewTicketDetails = (id) => {
    this.setState({ minimizeTable: true });
    this.getTicketDetail(id);
  };

  getTicketDetail = (id) => {
    this.setState({ view_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.ticketDetailUrl + "/" + id,
      this.props.access_token,
      {
        need_total_expense: "Y",
        need_ticket_allocation: "Y",
        need_ticket_consumption: "Y",
      },
      {},
      (resp) => {
        let respData = resp.data;
        this.setState({
          ticketDetail: respData.data,
          ticketHeader: respData.data.header,
        });
      }
    ).then(() => this.setState({ view_loading: false }));
  };

  afterSubmitEditTicketHandler = () => {
    this.loadListingTblData(1, this.state.assetId);
  };

  downloadTicketHandler = (activity_id) => {
    this.setState({ ticketDownloading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.ticketDownloadUrl + "/" + activity_id,
      this.props.access_token,
      {},
      {},
      (response) => {
        download.file(response.data.file_path);
      }
    ).then(() => this.setState({ ticketDownloading: false }));
  };

  //***************************TICKET SHARE EMAIL******************** */

  getShareEmailFormData = (id) => {
    this.setState({ share_email_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.ticketShareUrl + "/form_data/" + id,
      this.props.access_token,
      {},
      {},
      (resp) => {
        let options = resp.data.users
          .filter((s) => s.email !== "")
          .map((s) => {
            return { value: s.email, label: `${s.full_name} (${s.email})` };
          });
        this.setState({
          options: options,
          shareEmailForm: {
            ...this.state.shareEmailForm,
            subject: `Ticket - ${this.state.ticketDetail.activity_id} - ${this.state.ticketDetail.header} Status Update`,
          },
        });
      }
    ).then(() => this.setState({ share_email_loading: false }));
  };

  shareTicketModalInit = () => {
    this.shareTicketModal.show();
    this.getShareEmailFormData(this.state.ticketDetail.activity_id);
  };

  handleEmailChange = (selectedOption) => {
    this.setState({
      shareEmailForm: {
        ...this.state.shareEmailForm,
        to_emails: selectedOption,
      },
    });
  };

  handleEmailCreate = (inputText) => {
    const newValue = { value: inputText.toLowerCase(), label: inputText };
    this.setState({ options: [...this.state.options, newValue] });
    let obj = {};
    obj["value"] = newValue.value;
    obj["label"] = newValue.label;
    this.state.shareEmailForm.to_emails.push(obj);
  };

  submitShareDocument = (e) => {
    e.preventDefault();
    this.setState({ saveFormSubmitting: true });
    let frmData = {
      activity_id: this.state.ticketDetail.activity_id,
      to_emails: this.state.shareEmailForm.to_emails.map((s) => s.value),
      subject: this.state.shareEmailForm.subject,
      message: this.state.shareEmailForm.message,
      attachment: this.state.shareEmailForm.attachment,
    };
    HttpAPICall.withAthorization(
      "POST",
      this.ticketShareUrl,
      this.props.access_token,
      {},
      { ...frmData },
      (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.shareTicketModal.hide();
        this.setState({ shareEmailForm: { ...this.initShareEmail } });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  closeTicketView = () => {
    this.setState({ minimizeTable: false });
  };

  addRemarkModal = (id) => {
    this.ticketDetailRef.current.addRemarkInit(id);
  };

  addDocumentModal = (id) => {
    this.ticketDetailRef.current.addDocumentModalInit(id);
  };

  ticketExpenseHandler = (id) => {
    this.ticketDetailRef.current.ticketExpenseHandle();
  };

  addToAllocation = () => {
    let ticketData = this.state.ticketDetail ? this.state.ticketDetail : null;
    let asset = ticketData && ticketData.asset ? ticketData.asset : "";
    let site_id = ticketData && ticketData.site_id ? ticketData.site_id : "";
    let obj = {
      asset: asset,
      site_id: site_id,
      ticket_header: ticketData.header,
    };
    localStorage.setItem("selectedTicketToAdd", JSON.stringify(obj));
  };

  pageTitleBarJsx = () => {
    let obj = {
      search_asset_id: this.state?.assetDetail?.asset_id,
      asset_name: `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`,
    };
    return (
      <div className="page_title row m0">
        <div className="col-12 asset_collapse">
          <h3>{this.state?.assetDetail?.name}</h3>
          <AssetHeader
            assetDetail={this.state.assetDetail}
            afterChangeStatus={this.getAsssetDetails}
            parentProps={this.props}
          />
        </div>
      </div>
    );
  };

  horizontalAssetCardJsx = () => {
    return (
      <div>
        <div id="assetCard" className="collapse pr-1 mt-1" aria-expanded="true">
          {this.state.asset_loading ? (
            <div className="text-center pt-1">
              <Loader />
            </div>
          ) : (
            <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />
          )}
        </div>
      </div>
    );
  };

  ticketListJsx = () => {
    let ticketData = this.state.ticketDetail ? this.state.ticketDetail : null;
    let setting = ticketData && ticketData.setting ? ticketData.setting : null;
    return (
      <Ax>
        <div style={{ paddingTop: "4px" }}>
          <div className="page_title row m0" style={{ height: "45px" }}>
            <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
              <h3 style={{ paddingTop: "13px" }}>Ticket List</h3>
            </div>
            {!this.state.minimizeTable ? (
              <div className="col-sm-2 ">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  role="button"
                  onClick={this.assetCardHandler}
                >
                  {" "}
                  Asset Card
                </button>
              </div>
            ) : null}
            {!this.state.minimizeTable ? (
              <div className="col-sm-4 text-end mt7 ">
                <button
                  type="button"
                  className="btn btn-secondary"
                  disabled={this.state.listing_loading}
                  onClick={this.ticketSearchModalInit}
                >
                  <TapIcon.imageIcon
                    icon={TapIcon.SearchIcon}
                    className="img-fluid"
                    alt="item-action"
                  />
                </button>
              </div>
            ) : null}
            {this.state.minimizeTable ? (
              <Ax>
                <div className="col-sm-3 tap_border_left">
                  <h3 style={{ paddingTop: "13px" }}>
                    Ticket : {this.state.ticketHeader.slice(0, 25)}
                    {this.state.ticketHeader.length > 25 ? "..." : ""}
                  </h3>
                </div>
                <div className="col-sm-2 ">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    role="button"
                    onClick={this.assetCardHandler}
                  >
                    Asset Card
                  </button>
                </div>
                <div className="col-sm-4 text-end mt7">
                  <button
                    type="button"
                    id="actionMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="btn btn-primary"
                  >
                    Update
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end "
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        className={["dropdown-item"].join(", ")}
                        role="button"
                        onClick={() => this.addRemarkModal()}
                      >
                        Add Remark
                      </a>
                    </li>
                    <li>
                      <a
                        className={["dropdown-item"].join(", ")}
                        role="button"
                        onClick={() => this.addDocumentModal()}
                      >
                        Add Document
                      </a>
                    </li>
                    {setting &&
                      setting.ticket_expense &&
                      setting.ticket_expense == "Y" &&
                      this.state.ticketDetail &&
                      (this.state.ticketDetail.status !== 26 ||
                        this.state.ticketDetail !== 10) && (
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => this.ticketExpenseHandler()}
                          >
                            Add Expense
                          </Link>
                        </li>
                      )}
                    {setting &&
                      setting.ticket_allocation &&
                      setting.ticket_allocation == "Y" &&
                      this.state.ticketDetail &&
                      (this.state.ticketDetail.status !== 26 ||
                        this.state.ticketDetail !== 10) && (
                        <li>
                          {" "}
                          <Link
                            className="dropdown-item"
                            target="_blank"
                            role="button"
                            disabled={
                              !this.props.permissions.includes(
                                "isc-allocation-add"
                              )
                            }
                            onClick={() => this.addToAllocation()}
                            to={{
                              pathname: "/allocation_list/add",
                              search:
                                "?" +
                                new URLSearchParams({
                                  ticketId: ticketData.activity_id
                                    ? ticketData.activity_id
                                    : "",
                                }).toString(),
                            }}
                          >
                            Add Allocation
                          </Link>
                        </li>
                      )}
                    {setting &&
                      setting.ticket_consumption &&
                      setting.ticket_consumption == "Y" &&
                      this.state.ticketDetail &&
                      (this.state.ticketDetail.status !== 26 ||
                        this.state.ticketDetail !== 10) && (
                        <li>
                          {" "}
                          <Link
                            className="dropdown-item"
                            target="_blank"
                            role="button"
                            disabled={
                              !this.props.permissions.includes(
                                "isc-consumption-add"
                              )
                            }
                            onClick={() => this.addToAllocation()}
                            to={{
                              pathname: "/consumption_list/add",
                              search:
                                "?" +
                                new URLSearchParams({
                                  ticketId: ticketData.activity_id
                                    ? ticketData.activity_id
                                    : "",
                                }).toString(),
                            }}
                          >
                            Add Consumption
                          </Link>
                        </li>
                      )}
                  </ul>
                  <button
                    type="button"
                    id="shareTicket"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="btn btn-secondary"
                  >
                    <TapIcon.FontAwesomeIcon
                      icon={TapIcon.faShareAlt}
                      className="img-fluid"
                      alt="item-action"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="shareTicket"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        role="button"
                        onClick={this.shareTicketModalInit}
                      >
                        Share by Email
                      </a>
                    </li>
                  </ul>
                  <button
                    disabled={this.state.ticketDownloading}
                    onClick={() =>
                      this.downloadTicketHandler(
                        this.state.ticketDetail.activity_id
                      )
                    }
                    type="button"
                    className="btn btn-secondary"
                  >
                    Download PDF{" "}
                    {this.state.ticketDownloading ? (
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                  <button
                    onClick={this.closeTicketView}
                    type="button"
                    className="btn btn-secondary"
                  >
                    <TapIcon.imageIcon
                      icon={TapIcon.CloseIcon}
                      className="img-fluid"
                      alt="item-close"
                    />
                  </button>
                </div>
              </Ax>
            ) : null}
          </div>
        </div>
        <div className="container-fluid pl0 pr13">
          <div className="page_containt row">
            <div
              className={[
                "pl16",
                this.state.minimizeTable ? "col-3 mr0 pr0 wd26" : "col-12 pr0",
              ].join(" ")}
            >
              {this.ticketTableJsx()}
            </div>
            {this.state.minimizeTable ? (
              <div className="col-9 pr4 wd74 pl0 pageTbl pb-5">
                <div className=" bg-white p-2">
                  {this.state.view_loading ? (
                    <Loader />
                  ) : (
                    this.ticketViewDetailJsx()
                  )}
                </div>{" "}
              </div>
            ) : null}
          </div>
        </div>
      </Ax>
    );
  };

  ticketTableJsx = () => {
    return (
      <Ax>
        <DisplaySearchCriteria
          searchedElems={this.state.formSearchedElems}
          onClearFilter={this.ticketSearchClear}
        />
        <div
          className="table-responsive"
          style={{
            maxHeight:
              this.state.formSearchedElems &&
              this.state.formSearchedElems.length > 0
                ? "59vh"
                : "69vh",
          }}
        >
          <table
            className="table table-bordered bg-white align-items-center  table-sm table-hover table-fixed mb-0"
            id="assetTicketTable"
          >
            <thead className="table-secondary">
              {this.state.minimizeTable ? (
                <tr>
                  <th scope="col" style={{ width: "20%" }}>
                    Ticket{" "}
                  </th>
                </tr>
              ) : (
                <Ax>
                  <th
                    scope="col"
                    style={{ width: "5%" }}
                    rowSpan="2"
                    className="text-center"
                  >
                    S.No
                  </th>
                  <th
                    scope="col"
                    style={{ width: "12%" }}
                    className="text-center"
                    rowSpan="2"
                  >
                    Date of Creation
                  </th>
                  <th scope="col" style={{ width: "22%" }} rowSpan="2">
                    Header
                  </th>
                  <th
                    scope="col"
                    style={{ width: "14%" }}
                    rowSpan="2"
                    className="text-center"
                  >
                    Ticket Type
                  </th>
                  <th scope="col" style={{ width: "17%" }} rowSpan="2">
                    Last Updated
                  </th>
                  <th
                    scope="col"
                    style={{ width: "11%" }}
                    className="text-center"
                    colSpan={2}
                  >
                    Aging (Days)
                  </th>
                  <th
                    scope="col"
                    style={{ width: "10%" }}
                    className="text-center"
                    rowSpan="2"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    style={{ width: "5%" }}
                    className="text-center"
                    rowSpan="2"
                  >
                    Action
                  </th>
                </Ax>
              )}
            </thead>
            <tbody>
              {this.state.listing_loading ? (
                <tr>
                  <td colSpan="10">
                    <Loader />
                  </td>
                </tr>
              ) : this.state.ticket_listing.length > 0 ? (
                this.state.ticket_listing.map((item, index) => {
                  return (
                    <tr key={index}>
                      {this.state.minimizeTable ? (
                        <Ax>
                          <td
                            style={{ width: "25%" }}
                            onClick={() =>
                              this.viewTicketDetails(item.activity_id)
                            }
                          >
                            <div className="text-capitalize link-primary">
                              <a href="#">{item?.header}</a>
                            </div>
                            <div className="mt-1">
                              <small className="text-capitalize ">
                                {item?.ticket_type?.name}{" "}
                              </small>
                              <small className="float-end">
                                <Status color={item.status_color}>
                                  {item.status_text}
                                </Status>
                              </small>
                            </div>
                          </td>
                        </Ax>
                      ) : (
                        <Ax>
                          <td className="text-center">
                            {this.state.listingMeta
                              ? this.state.listingMeta.from + index
                              : index}
                          </td>
                          <td className="text-center">
                            {item.created_at ? item.created_at_date : "-"}
                          </td>
                          <td>
                            <a
                              role="button"
                              className="link-primary text-capitalize"
                              onClick={() =>
                                this.viewTicketDetails(item.activity_id)
                              }
                            >
                              {item?.header}
                            </a>
                          </td>
                          <td className="text-center">
                            {item.ticket_type ? item.ticket_type.name : "-"}
                          </td>

                          <td>
                            {item.last_updated_on_display ?? "-"} <br />
                            {item.latest_remark ? (
                              <small>{item.latest_remark?.remarks}</small>
                            ) : null}
                          </td>
                          <td className="text-center">{item?.aging}</td>
                          <td className="text-center">{item.remark_aging}</td>
                          <td className="text-center">
                            <Status color={item.status_color}>
                              {item.status_text}
                            </Status>
                          </td>
                          <td className="text-center">
                            <button
                              type="button"
                              id="actionMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              className="btn"
                            >
                              <TapIcon.FontAwesomeIcon
                                icon={TapIcon.faEllipsisV}
                              />
                            </button>
                            {/*  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    {/* <li><a className="dropdown-item" role="button" onClick={() => this.viewTicket(item.activity_id)} >View</a></li>
                                                                <li><a className="dropdown-item" role="button" onClick={() => this.downloadTicketHandler(item.activity_id)} >Download</a></li>
                                                </ul> */}
                          </td>
                        </Ax>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div>
            {this.state.listingMeta
              ? `Showing ${this.state.listingMeta.from || 0} to ${
                  this.state.listingMeta.to || 0
                } of ${this.state.totalListingCount} entries`
              : ``}
          </div>
          <div>
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              pageCount={this.state.listingMeta?.last_page ?? 0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageActive={this.state.listingMeta?.current_page ?? 1}
              onPageChange={(e) =>
                this.loadListingTblData(e.selected + 1, this.state.assetId)
              }
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination justify-content-end"
              pageClassName="page-item"
              nextClassName="page-item"
              previousClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              activeClassName="active"
            />
          </div>
        </div>
      </Ax>
    );
  };

  ticketViewDetailJsx = () => {
    let ticketData = this.state.ticketDetail;
    return (
      <div>
        {this.state.ticketDetail ? (
          <TicketDetail
            ref={this.ticketDetailRef}
            showAssetCard="N"
            ticketData={this.state.ticketDetail}
            afterSubmit={this.afterSubmitEditTicketHandler}
          />
        ) : null}
      </div>
    );
  };

  shareTicketModalJsx = () => {
    return (
      <div className="modal fade" id="shareTicketModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addAssetModalLabel">
                Share Ticket{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {this.state.share_email_loading ? (
              <Loader />
            ) : (
              <form id="addModalForm" onSubmit={this.submitShareDocument}>
                <div className="modal-body">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <label className="form-label require">To (Email)</label>
                    </div>
                    <div className="col-md-8">
                      <CreatableSelect
                        isMulti
                        required={true}
                        value={this.state.shareEmailForm.to_emails}
                        options={this.state.options}
                        onChange={(selectedItem) =>
                          this.handleEmailChange(selectedItem)
                        }
                        onCreateOption={(inputText, callback) =>
                          this.handleEmailCreate(inputText, callback)
                        }
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-md-4">
                      <label className="form-label require">Subject</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        name="subject"
                        type="text"
                        value={this.state.shareEmailForm.subject}
                        className="form-control text-capitalize"
                        autoComplete="off"
                        required={true}
                        onChange={(e) =>
                          this.formInputHandler(e, "shareEmailForm")
                        }
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                  <div className="row  mt-3">
                    <div className="col-md-4">
                      <label className="form-label require">Message</label>
                    </div>
                    <div className="col-md-8">
                      <textarea
                        name="message"
                        placeholder="Enter Message"
                        className="form-control"
                        value={this.state.shareEmailForm.message}
                        autoComplete="off"
                        required={true}
                        onChange={(e) =>
                          this.formInputHandler(e, "shareEmailForm")
                        }
                        style={{ height: "100px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <div className="mt-0">
                    <input
                      name="attachment"
                      type="checkbox"
                      value="N"
                      onChange={(e) => {
                        this.formInputHandler(e, "shareEmailForm");
                        this.setState({
                          shareEmailForm: {
                            ...this.state.shareEmailForm,
                            attachment:
                              this.state.shareEmailForm.attachment == "Y"
                                ? "N"
                                : "Y",
                          },
                        });
                      }}
                      className="form-check-input "
                    />
                    <label className="form-check-label mx-2">
                      Attach Documents of the Ticket with Email
                    </label>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary mx-2"
                      disabled={this.state.saveFormSubmitting ? true : false}
                      data-bs-dismiss="modal"
                      onClick={() => {
                        this.setState({
                          shareEmailForm: { ...this.initShareEmail },
                        });
                      }}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={this.state.saveFormSubmitting ? true : false}
                    >
                      Submit{" "}
                      {this.state.saveFormSubmitting ? (
                        <TapIcon.FontAwesomeIcon
                          icon={TapIcon.faSyncAlt}
                          className="fa-spin"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };

  ticketSearchModalJsx = () => {
    return (
      <div className="modal fade" id="ticketSearchModal" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchAssetModalLabel">
                Ticket Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {this.state.form_loading ? (
              <Loader />
            ) : (
              <form
                id="ticketSearchForm"
                onSubmit={this.submitTicketSearchForm}
              >
                <div className="modal-body">
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Ticket
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        placeholder="All Tickets"
                        containerHeight="30px"
                        fontSize="93%"
                        options={this.allTicketOptions}
                        value={this.allTicketOptions.find(
                          (r) =>
                            r.value ===
                            this.state.ticketSearchForm.search_ticket
                        )}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_ticket"
                          )
                        }
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Ticket Type
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Select Ticket Type"
                        containerHeight="30px"
                        fontSize="93%"
                        options={this.state.allTicketType}
                        value={this.state.allTicketType.find(
                          (r) =>
                            r.value ===
                            this.state.ticketSearchForm.search_ticket_type_id
                        )}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_ticket_type_id"
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm">
                      Ticket ID
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        name="search_ticket_id"
                        className="form-control form-control-sm"
                        placeholder="Ticket ID"
                        autoComplete="off"
                        value={this.state.ticketSearchForm.search_ticket_id}
                        onChange={(e) =>
                          this.formInputHandler(e, "ticketSearchForm")
                        }
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Ticket Header
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        name="search_ticket_header"
                        className="form-control form-control-sm"
                        placeholder="Ticket Header"
                        autoComplete="off"
                        value={this.state.ticketSearchForm.search_ticket_header}
                        onChange={(e) =>
                          this.formInputHandler(e, "ticketSearchForm")
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm">
                      Ticket Status
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        options={this.state.allStatusList}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_ticket_status"
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allStatusList.filter((s) =>
                          this.state.ticketSearchForm.search_ticket_status.includes(
                            s.value
                          )
                        )}
                        placeholder="Select Ticket Status"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Site Name
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        options={this.state.iam_user_sites}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_site_ids"
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.iam_user_sites.filter((s) =>
                          this.state.ticketSearchForm.search_site_ids.includes(
                            s.value
                          )
                        )}
                        placeholder="Select Site"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Tagged User
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Select User"
                        containerHeight="30px"
                        fontSize="93%"
                        isMulti={true}
                        options={this.state.allTaggedUsers}
                        value={this.state.allTaggedUsers.filter((s) =>
                          this.state.ticketSearchForm.search_tagged_users.includes(
                            s.value
                          )
                        )}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_tagged_users"
                          )
                        }
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Ticket Ageing
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        name="search_ageing_days"
                        className="form-control form-control-sm"
                        placeholder="Example. 0-5 for a range or 4 for Ticket Aging"
                        autoComplete="off"
                        value={this.state.ticketSearchForm.search_ageing_days}
                        onChange={(e) =>
                          this.formInputHandler(e, "ticketSearchForm")
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm">
                      Creation Date{" "}
                    </label>
                    <div className="col-sm-4">
                      <PeriodSelectionWithCustom
                        periods={
                          this.state.filterFormData &&
                          this.state.filterFormData.periods !== undefined
                            ? this.state.filterFormData.periods
                            : []
                        }
                        required={false}
                        value={this.state.ticketSearchForm.creation_date_range}
                        startDate={
                          this.state.ticketSearchForm.creation_date_range_start
                        }
                        endDate={
                          this.state.ticketSearchForm.creation_date_range_end
                        }
                        onSelectPeriod={(period, startDate, endDate) => {
                          this.setState({
                            ticketSearchForm: {
                              ...this.state.ticketSearchForm,
                              creation_date_range: period,
                              creation_date_range_start: startDate,
                              creation_date_range_end: endDate,
                            },
                          });
                        }}
                        className="form-control-sm"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Completion Date{" "}
                    </label>
                    <div className="col-sm-4">
                      <PeriodSelectionWithCustom
                        periods={
                          this.state.filterFormData &&
                          this.state.filterFormData.periods !== undefined
                            ? this.state.filterFormData.periods
                            : []
                        }
                        required={false}
                        value={this.state.ticketSearchForm.closure_date_range}
                        startDate={
                          this.state.ticketSearchForm.closure_date_range_start
                        }
                        endDate={
                          this.state.ticketSearchForm.closure_date_range_end
                        }
                        onSelectPeriod={(period, startDate, endDate) => {
                          this.setState({
                            ticketSearchForm: {
                              ...this.state.ticketSearchForm,
                              closure_date_range: period,
                              closure_date_range_start: startDate,
                              closure_date_range_end: endDate,
                            },
                          });
                        }}
                        className="form-control-sm"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Due Date
                    </label>
                    <div className="col-sm-4">
                      <PeriodSelectionWithCustom
                        periods={
                          this.state.filterFormData &&
                          this.state.filterFormData.periods !== undefined
                            ? this.state.filterFormData.periods
                            : []
                        }
                        required={false}
                        value={this.state.ticketSearchForm.due_date_range}
                        startDate={
                          this.state.ticketSearchForm.due_date_range_start
                        }
                        endDate={this.state.ticketSearchForm.due_date_range_end}
                        onSelectPeriod={(period, startDate, endDate) => {
                          this.setState({
                            ticketSearchForm: {
                              ...this.state.ticketSearchForm,
                              due_date_range: period,
                              due_date_range_start: startDate,
                              due_date_range_end: endDate,
                            },
                          });
                        }}
                        className="form-control-sm"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm">
                      Remark Ageing
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        name="remark_ageing_days"
                        className="form-control form-control-sm"
                        placeholder="Example. 0-5 for a range or 4 for Remark Aging"
                        autoComplete="off"
                        value={this.state.ticketSearchForm.remark_ageing_days}
                        onChange={(e) =>
                          this.formInputHandler(e, "ticketSearchForm")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Asset - Ticket List</title>
        </Helmet>
        {this.pageTitleBarJsx()}
        {this.horizontalAssetCardJsx()}
        {this.ticketListJsx()}
        {this.shareTicketModalJsx()}
        {this.ticketSearchModalJsx()}
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    item_tags: state.app && state.app.item_tags ? state.app.item_tags : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
    iam_user_sites:
      state.app && state.app.user_sites ? state.app.user_sites : [],
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  TicketList
);
