import React from 'react';
import {connect} from "react-redux";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import GeneralSetting from './GeneralSetting';
import Transporter from './Transporter';
import Division from './Division';
import CNFAgent from './CNFAgent';
import Handler from './Handler';
import Workflow from './Workflow';

class LogisticConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);
          
            this.initAddVendorForm      =       {
                name            :   '',
                status          :   '',
                id              :       ''
            }
            this.initMergeVendorForm      =       {
                primary_vendor            :   '',
                merge_vendor               :   '',
            }
          this.state                =          {
            list_loading            :         false,
            listingData             :         [],
            saveFormSubmitting      :         false,
            addVendorForm           :           {...this.initAddVendorForm},
            editFormSubmiting       :           false,
            mergeVendorForm         :           {...this.initMergeVendorForm},
            mergedToVendorData      :           null,
            listingMeta             :           null,
            primaryVendorData       :           null,

            transporter     :   false,
            division        :   false,
            cnfAgent        :   false,
            handler         :   false,
            genSetting      :   false,
        };
    }

    componentDidMount() {
    }

    render() {
      
        return (<section className="page_containt " style={{ top: "1px" }}>
            <div className=" bg-white">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                            type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="setting-tab" data-bs-toggle="tab" data-bs-target="#setting"
                            type="button" role="tab" aria-controls="setting" aria-selected="true" onClick={()=> this.setState({genSetting : true})}>Setting
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="transporter-tab" data-bs-toggle="tab" data-bs-target="#transporter"
                            type="button" role="tab" aria-controls="transporter" aria-selected="true" onClick={()=> this.setState({transporter : true})}>Transporter
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="division-tab" data-bs-toggle="tab" data-bs-target="#division"
                            type="button" role="tab" aria-controls="division" aria-selected="true" onClick={()=> this.setState({division : true})}>Division
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="cnf-agent-tab" data-bs-toggle="tab" data-bs-target="#cnf-agent"
                            type="button" role="tab" aria-controls="cnf-agent" aria-selected="true" onClick={()=> this.setState({cnfAgent : true})}>CHA Agent
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="handler-tab" data-bs-toggle="tab" data-bs-target="#handler"
                            type="button" role="tab" aria-controls="handler" aria-selected="true" onClick={()=> this.setState({handler : true})}>Handler
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                <div className="tab-pane show active" id="workflow" role="tabpanel" aria-labelledby="workflow-tab">
                        <div className="tab_content_header">
                        <Workflow/>
                        </div>
                    </div>
                <div className="tab-pane" id="setting" role="tabpanel" aria-labelledby="setting-tab">
                        <div className="tab_content_header">
                        {
                            this.state.genSetting &&
                            <GeneralSetting/>
                        }
                        </div>
                    </div>
                    <div className="tab-pane" id="transporter" role="tabpanel"
                        aria-labelledby="transporter-tab">
                        {
                            this.state.transporter &&
                            <Transporter/>
                        }
                    </div>
                    <div className="tab-pane" id="division" role="tabpanel"
                        aria-labelledby="division-tab">
                        {
                            this.state.division &&
                            <Division/>
                        }
                    </div>
                    <div className="tab-pane" id="cnf-agent" role="tabpanel"
                        aria-labelledby="cnf-agent-tab">
                        {
                            this.state.cnfAgent &&
                            <CNFAgent/>
                        }
                    </div>
                    <div className="tab-pane" id="handler" role="tabpanel"
                        aria-labelledby="handler-tab">
                        {
                            this.state.handler &&
                            <Handler/>
                        }
                    </div>
                </div>
            </div>
        </section>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
    };
};


export default connect(mapStateToProps)(LogisticConfiguration);