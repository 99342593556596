import React from 'react';
import { Modal } from 'bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import HttpAPICall from "../../../../../services/HttpAPICall";
import Ax from "../../../../../components/hoc/Ax";
import TapIcon from '../../../../../services/TapIcon';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import Loader from '../../../../../components/ui/Loader/Loader';
import { toast } from 'react-toastify';
import InputItemSearch from '../../../../includes/ui/InputItemSearch';
import TabNavigationAssetMatrix from '../TabnavigationAssetMatrix';


class SCSSetting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.changeIntervaFormInit = {
            status: 1,
            days_check: 'N',
            hour_meter_check: "N",
            odo_meter_check: 'N',
            opc_meter_check: 'N',
            days_interval: '',
            hour_meter_interval: '',
            odo_meter_interval: '',
            operation_cycle_interval: '',
        }

        this.state = {
            matrix_id: null,
            listing_loading: false,
            listing_tbl_page: 1,
            setting_listing: [],
            listingMeta: null,
            totalListingCount: 0,
            change_interval_loading: false,
            selectedAssetForInterval: [],
            changeIntervalForm: { ...this.changeIntervaFormInit },
            changeIntervalFormSubmitting: false,
            assetMatrixDetail: null,
            linked_items: [],
            linkItemRows: [{ item: null }],
            searchedItem: null,
            remove_previous_linked_item: 'N',
            asset_scs_id: null,
            scs_id: null,
            scsRowKey: 0,
            scs_name: ''
        }
    }

    id = this.props.matrix_enc_id;

    componentDidMount() {
        this.setState({
            matrix_id: this.props.matrix_enc_id,
            assetMatrixDetail: this.props.assetMatrixDetail
        });
        this.loadListingTblData(this.props.matrix_enc_id, this.state.listing_tbl_page)
        this.changeIntervalModal = new Modal(document.getElementById('changeIntervalModal'), { keyboard: false, backdrop: false });
        this.linkItemModal = new Modal(document.getElementById('linkItemModal'), { keyboard: false, backdrop: false });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.matrix_enc_id !== this.state.matrix_id) {
            this.setState({ matrix_id: nextProps.matrix_enc_id })
            this.loadListingTblData(nextProps.matrix_enc_id, this.state.listing_tbl_page)
        }
        if (nextProps.assetMatrixDetail !== this.state.assetMatrixDetail) {
            this.setState({ assetMatrixDetail: nextProps.assetMatrixDetail })
        }

    }

    loadListingTblData = (id, page = 1, merge_list = false) => {
        this.setState({ listing_loading: true, listing_tbl_page: page });
        let params = { page: page, per_page: 150 };

        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/scs/asset_matrix/list/' + id, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                setting_listing: merge_list == true ? resp.data.data : [...this.state.setting_listing, ...resp.data.data],
                listingMeta: respData.meta,
                totalListingCount: respData.meta.total,
                current_page: respData.meta.current_page
            }, () => {
                let all_scs_ids = this.state.setting_listing && this.state.setting_listing.length > 0 ? this.state.setting_listing.filter(s => s.id !== null).map((scs, key) => scs.id) : [];
                if (this.state.setting_listing && this.state.setting_listing.length > 0 && all_scs_ids.length > 0) {
                    this.getAllLinkedItemsList(all_scs_ids)
                }
            });

        }).then(() => this.setState({ listing_loading: false }));
    }

    loadMoreList = () => {
        if (this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page) {
            this.loadListingTblData(this.state.matrix_id, this.state.current_page + 1);
        }
    }

    getAllLinkedItemsList = (all_ids) => {
        let params = { asset_matrix_scs_ids: all_ids };
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/scs/asset_matrix/link_item', this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                linked_items: respData.data,
            })
        })
    }

    editSetting = (id) => {

        let selected_scs = this.state.setting_listing.find(scs => {
            if (scs.id) {
                return scs.id == id
            } else if (scs.scs_id) {
                return scs.scs_id == id
            } else {
                return scs.asset_matrix_id == id
            }
        });
        this.props.parentProps.history.push({ pathname: `/asset_matrix/edit/${this.state.matrix_id}`, state: { scsDetail: selected_scs } })
    }

    //*********************************Change Interval*********************** */

    checkAllList() {
        let settingItemCheckboxCollections = document.getElementsByClassName('setting_item_id_checkbox')
        if (settingItemCheckboxCollections && settingItemCheckboxCollections.length > 0) {
            for (let i = 0; i < settingItemCheckboxCollections.length; i++) {
                settingItemCheckboxCollections[i].checked = document.getElementById('all_check_settings').checked;
            }
        }
    }

    changeIntervalModalInit = () => {
        this.setState({ change_interval_loading: true, selectedAssetForInterval: [], changeIntervalForm: { ...this.changeIntervaFormInit } })
        let settingItemCheckboxCollections = document.getElementsByClassName('setting_item_id_checkbox');
        let selectedAssetForInterval = [];

        if (settingItemCheckboxCollections && settingItemCheckboxCollections.length > 0) {
            for (let i = 0; i < settingItemCheckboxCollections.length; i++) {
                if (settingItemCheckboxCollections[i].checked) {
                    let asset_scs = settingItemCheckboxCollections[i].value;

                    let newList = this.state.setting_listing.find(scs => {
                        if (scs.id) {
                            return scs.id == asset_scs
                        } else if (scs.scs_id) {
                            return scs.scs_id == asset_scs
                        } else {
                            return scs.asset_matrix_id == asset_scs
                        }
                    });
                    selectedAssetForInterval.push(newList)
                }
            }
        }


        if (selectedAssetForInterval.length == 0) {
            toast.error('Please select atleast one Service Checksheet', { position: toast.POSITION.TOP_RIGHT });
        } else {
            this.changeIntervalModal.show()
            this.setState({
                selectedAssetForInterval: selectedAssetForInterval, change_interval_loading: false,
                changeIntervalForm: {
                    ...this.changeIntervaFormInit,
                    days_check: 'Y',
                    hour_meter_check: this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.hour_meter == "yes" ? 'Y' : 'N',
                    odo_meter_check: this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.odo_meter == "yes" ? 'Y' : 'N',
                    opc_meter_check: this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.operation_cycle == "yes" ? 'Y' : 'N',
                },
            })
        }
    }

    submitChangeIntervalHandler = (e) => {
        e.preventDefault()
        let asset_scs_setting_ids = this.state.selectedAssetForInterval.map((s, k) => { return ({ asset_matrix_scs_id: s.id, scs_id: s.scs_id, asset_matrix_id: s.enc_asset_matrix_id }) })

        let changeIntervalForm = {
            ...this.state.changeIntervalForm,
            days_interval: this.state.changeIntervalForm.days_interval && this.state.changeIntervalForm.days_check == 'Y' ? parseInt(this.state.changeIntervalForm.days_interval) : null,
            odo_meter_interval: this.state.changeIntervalForm.odo_meter_interval && this.state.changeIntervalForm.odo_meter_check == 'Y' ? parseInt(this.state.changeIntervalForm.odo_meter_interval) : null,
            operation_cycle_interval: this.state.changeIntervalForm.operation_cycle_interval && this.state.changeIntervalForm.operation_cycle_check == 'Y' ? parseInt(this.state.changeIntervalForm.operation_cycle_interval) : null,
            hour_meter_interval: this.state.changeIntervalForm.hour_meter_interval && this.state.changeIntervalForm.hour_meter_check == 'Y' ? parseInt(this.state.changeIntervalForm.hour_meter_interval) : null
        }
        let frmData = { asset_matrix_scs: asset_scs_setting_ids, ...changeIntervalForm, asset_matrix_id: this.state.matrix_id };

        this.setState({ changeIntervalFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/scs/asset_matrix/change_interval', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.changeIntervalModal.hide()
            this.loadListingTblData(this.state.matrix_id, 1, true)
            this.setState({
                changeIntervalForm: { ...this.changeIntervaFormInit },
                selectedAssetForInterval: []
            })
        }).then(() => this.setState({ changeIntervalFormSubmitting: false }));
    }

    //*********************************LINK ITEMS*********************** */
    linkItemInit = (id, scs_id, index, name) => {
        this.setState({
            linkItemRows: [{ item: null }],
            asset_scs_id: id,
            scs_id: scs_id,
            scsRowKey: index,
            scs_name: name
        }, () => {
            let itemsLinked = this.state.linked_items && this.state.linked_items.length > 0 ? this.state.linked_items.find(li => (id && li.asset_matrix_scs_id == id)) : [];

            if (itemsLinked && itemsLinked.items && itemsLinked.items.length > 0) {

                let linkItemRows = this.state.linkItemRows;
                itemsLinked.items.forEach((i, k) => {

                    linkItemRows[k] = {
                        ...linkItemRows[k],
                        item: i.item,
                        qty: i.qty,
                    };

                    this.setState({ linkItemRows: linkItemRows });
                })

            }
        })

        this.linkItemModal.show()
    }

    onItemSelect = (selectedOption, key, edit) => {

        if (selectedOption && selectedOption.itemdata) {

            let item = selectedOption.itemdata;

            if (this.state.linkItemRows.find((linked_item) => linked_item.item && linked_item.item.id == item.id)) {
                toast.error('This Item is already available for Linking, please update the item', { position: toast.POSITION.TOP_RIGHT })
            } else {
                let linkItemRows = this.state.linkItemRows;
                linkItemRows[key] = {
                    ...linkItemRows[key],
                    item: item,
                    qty: 0,

                };
                this.setState({ linkItemRows: linkItemRows }, () => {
                    this.addNewItemRow()
                });
            }

        }

    }

    addNewItemRow = () => {
        this.setState({ linkItemRows: [...this.state.linkItemRows, { item: null }] });
    }

    removeItemRow = (k) => {
        let allRows = this.state.linkItemRows;
        if (allRows.length > 0) {
            allRows.splice(k, 1);
            this.setState({ linkItemRows: allRows });
        }
    }

    submitLinkedItemHandler = (e) => {
        e.preventDefault()
        let itemsLists = this.state.linkItemRows.filter(i => i.item ? true : false).map(i => {
            return {
                ...i,
                item_id: i.item.id,
                item: null,
                qty: i.qty
            }
        });
        let frmData = {
            asset_matrix_scs_id: this.state.asset_scs_id,
            scs_id: this.state.scs_id,
            asset_matrix_id: this.state.matrix_id,
            remove_previous_linked_item: this.state.remove_previous_linked_item,
            items: itemsLists
        };


        this.setState({ linkedItemFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/scs/asset_matrix/link_item', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.linkItemModal.hide()
            if (frmData.asset_matrix_scs_id == null) {
                this.loadListingTblData(this.state.matrix_id, 1, true)
            } else {
                let all_scs_ids = this.state.setting_listing && this.state.setting_listing.length > 0 ? this.state.setting_listing.filter(s => s.id !== null).map((scs, key) => scs.id) : [];
                if (this.state.setting_listing && this.state.setting_listing.length > 0 && all_scs_ids.length > 0) {
                    this.getAllLinkedItemsList(all_scs_ids)
                }
            }

            this.setState({ linkItemRows: [{ item: null }] })
        }).then(() => this.setState({ linkedItemFormSubmitting: false }));
    }

    changeIntervalModalJsx = () => {

        return (
            <div className="modal fade" id="changeIntervalModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Change Interval</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body ">
                            <table className="table table-bordered bg-white align-items-center  table-sm table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                                        <th scope="col" style={{ width: "70%" }} >Service Checksheet Name</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.change_interval_loading
                                        ? <tr colSpan={2}><td className='text-center'><Loader /></td></tr>
                                        : this.state.selectedAssetForInterval.map(((sa, key) => {
                                            return (<tr key={key + 1}>
                                                <td className='text-center'>{key + 1}</td>
                                                <td>{sa.name ? sa.name : "-"}</td>
                                            </tr>)
                                        }))}
                                </tbody>
                            </table>
                            <form onSubmit={this.submitChangeIntervalHandler} id="changeIntervalForm">
                                <div className='counterlog' style={{ padding: "10px", marginBottom: "0px" }}>

                                    <div className="row  my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Tracking status</label>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="status"
                                                    type="radio"
                                                    value={1}
                                                    onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                    checked={this.state.changeIntervalForm.status == 1}
                                                    className="form-check-input"
                                                    required={true}
                                                    id="trackingStatusFormEnable"
                                                />
                                                <label className="form-check-label" htmlFor="trackingStatusFormEnable">Enable</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="status"
                                                    type="radio"
                                                    value={2}
                                                    onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                    checked={this.state.changeIntervalForm.status == 2}
                                                    className="form-check-input"
                                                    required={true}
                                                    id="trackingStatusFormDisable"
                                                />
                                                <label className="form-check-label" htmlFor="trackingStatusFormDisable">Disable</label>
                                            </div>
                                        </div>

                                    </div>
                                    {this.state.changeIntervalForm.status == 1
                                        ? <Ax>
                                            {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.hour_meter == "yes"
                                                ? <div className="row  my-3">
                                                    <div className="col-sm-3">
                                                        <label htmlFor="site_id" className="form-label">Hour Meter </label>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="hour_meter_check"
                                                                type="radio"
                                                                value="Y"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.hour_meter_check == "Y"}
                                                                className="form-check-input"
                                                                id="hourMeterFormEnable"
                                                            />
                                                            <label className="form-check-label" htmlFor="hourMeterFormEnable">Enable</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="hour_meter_check"
                                                                type="radio"
                                                                value="N"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.hour_meter_check == "N"}
                                                                className="form-check-input"
                                                                id="hourMeterFormDisable"
                                                            />
                                                            <label className="form-check-label" htmlFor="hourMeterFormDisable">Disable</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="hour_meter_check"
                                                                type="radio"
                                                                value="unchange"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.hour_meter_check == "unchange"}
                                                                className="form-check-input"
                                                                id="hourMeterFormUnchanged"
                                                            />
                                                            <label className="form-check-label" htmlFor="hourMeterFormUnchanged">Unchanged</label>
                                                        </div>
                                                    </div>
                                                    {this.state.changeIntervalForm.hour_meter_check == "Y"
                                                        ? <Ax>

                                                            <div className="col-sm-3">
                                                                <input
                                                                    name="hour_meter_interval"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Hour Meter"
                                                                    onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                    style={{ height: "30px", fontSize: "93%" }}
                                                                    autoComplete="off"
                                                                    required={this.state.changeIntervalForm.hour_meter_check == "Y" ? true : false}
                                                                    value={this.state.changeIntervalForm.hour_meter_interval}
                                                                />

                                                            </div>
                                                        </Ax>
                                                        : null}

                                                </div>
                                                : null}
                                            {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.odo_meter == "yes"
                                                ? <div className="row  my-3">
                                                    <div className="col-sm-3">
                                                        <label htmlFor="site_id" className="form-label">Odo Meter </label>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="odo_meter_check"
                                                                type="radio"
                                                                value="Y"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.odo_meter_check == "Y"}
                                                                className="form-check-input"
                                                                id="odoMeterFormEnable"
                                                            />
                                                            <label className="form-check-label" htmlFor="odoMeterFormEnable">Enable</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="odo_meter_check"
                                                                type="radio"
                                                                value="N"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.odo_meter_check == "N"}
                                                                className="form-check-input"
                                                                id="odoMeterFormDisable"
                                                            />
                                                            <label className="form-check-label" htmlFor="odoMeterFormDisable">Disable</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="odo_meter_check"
                                                                type="radio"
                                                                value="unchange"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.odo_meter_check == "unchange"}
                                                                className="form-check-input"
                                                                id="odoMeterFormUnchanged"
                                                            />
                                                            <label className="form-check-label" htmlFor="odoMeterFormUnchanged">Unchanged</label>
                                                        </div>
                                                    </div>
                                                    {this.state.changeIntervalForm.odo_meter_check == "Y"
                                                        ? <Ax>

                                                            <div className="col-sm-3">
                                                                <input
                                                                    name="odo_meter_interval"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Odo Meter"
                                                                    onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                    style={{ height: "30px", fontSize: "93%" }}
                                                                    autoComplete="off"
                                                                    required={this.state.changeIntervalForm.odo_meter_check == "Y" ? true : false}
                                                                    value={this.state.changeIntervalForm.odo_meter_interval}
                                                                />

                                                            </div>
                                                        </Ax>
                                                        : null}

                                                </div>
                                                : null}
                                            {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.operation_cycle == "yes"
                                                ? <div className="row  my-3">
                                                    <div className="col-sm-3">
                                                        <label htmlFor="site_id" className="form-label">OPC Meter </label>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="opc_meter_check"
                                                                type="radio"
                                                                value="Y"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.opc_meter_check == "Y"}
                                                                className="form-check-input"
                                                                id="opcMeterFormEnable"
                                                            />
                                                            <label className="form-check-label" htmlFor="opcMeterFormEnable">Enable</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="opc_meter_check"
                                                                type="radio"
                                                                value="N"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.opc_meter_check == "N"}
                                                                className="form-check-input"
                                                                id="opcMeterFormDisable"
                                                            />
                                                            <label className="form-check-label" htmlFor="opcMeterFormDisable">Disable</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="opc_meter_check"
                                                                type="radio"
                                                                value="unchange"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                checked={this.state.changeIntervalForm.opc_meter_check == "unchange"}
                                                                className="form-check-input"
                                                                id="opcMeterFormUnchanged"
                                                            />
                                                            <label className="form-check-label" htmlFor="opcMeterFormUnchanged">Unchanged</label>
                                                        </div>
                                                    </div>
                                                    {this.state.changeIntervalForm.opc_meter_check == "Y"
                                                        ? <Ax>

                                                            <div className="col-sm-3">
                                                                <input
                                                                    name="opc_meter_interval"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter OPC Meter"
                                                                    onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                    style={{ height: "30px", fontSize: "93%" }}
                                                                    autoComplete="off"
                                                                    required={this.state.changeIntervalForm.opc_meter_check == "Y" ? true : false}
                                                                    value={this.state.changeIntervalForm.opc_meter_interval}
                                                                />

                                                            </div>
                                                        </Ax>
                                                        : null}

                                                </div>
                                                : null}
                                            <div className="row  my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Days </label>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            name="days_check"
                                                            type="radio"
                                                            value="Y"
                                                            onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                            checked={this.state.changeIntervalForm.days_check == "Y"}
                                                            className="form-check-input"
                                                            id="daysMeterFormEnable"
                                                        />
                                                        <label className="form-check-label" htmlFor="daysMeterFormEnable">Enable</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            name="days_check"
                                                            type="radio"
                                                            value="N"
                                                            onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                            checked={this.state.changeIntervalForm.days_check == "N"}
                                                            className="form-check-input"
                                                            id="daysMeterFormDisable"
                                                        />
                                                        <label className="form-check-label" htmlFor="daysMeterFormDisable">Disable</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            name="days_check"
                                                            type="radio"
                                                            value="unchange"
                                                            onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                            checked={this.state.changeIntervalForm.days_check == "unchange"}
                                                            className="form-check-input"
                                                            id="daysMeterFormUnchanged"
                                                        />
                                                        <label className="form-check-label" htmlFor="daysMeterFormUnchanged">Unchanged</label>
                                                    </div>
                                                </div>

                                                {this.state.changeIntervalForm.days_check == "Y"
                                                    ? <Ax>

                                                        <div className="col-sm-3">
                                                            <input
                                                                name="days_interval"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Days"
                                                                onChange={(e) => this.formInputHandler(e, 'changeIntervalForm')}
                                                                style={{ height: "30px", fontSize: "93%" }}
                                                                autoComplete="off"
                                                                required={this.state.changeIntervalForm.days_check == "Y" ? true : false}
                                                                value={this.state.changeIntervalForm.days_interval}
                                                            />

                                                        </div>
                                                    </Ax>
                                                    : null}

                                            </div>



                                        </Ax>
                                        : null}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.changeIntervalFormSubmitting} data-bs-dismiss="modal" className="btn btn-secondary" >Cancel</button>
                            <button type="submit" disabled={this.state.changeIntervalFormSubmitting} className="btn btn-primary" form="changeIntervalForm" >Save  {this.state.changeIntervalFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    linkItemModalJsx = () => {
        return (
            <div className="modal fade" id="linkItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="linkItemModalLabel">Link Items for : {this.state.scs_name} </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body ">
                            <form onSubmit={this.submitLinkedItemHandler} id="linkItemForm">
                                <table className="table table-bordered table-responsive bg-white ">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                                            <th scope="col" className="text-center" >Item Details</th>
                                            <th scope="col" className="text-center" style={{ width: "15%" }}>Quantity</th>
                                            <th scope="col" className="" style={{ width: "5%" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.linkItemRows.map((i, itemKey) => {


                                            let tdRows = (i.item) ? (<Ax>
                                                <td className="text-start">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className='col-sm-10'><span className="fw-bold">{i.item.name}</span></div>

                                                        </div>

                                                        <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                                        <div className="form-text"><b>Mfr: </b>{i.item?.manufacturer?.name ?? '-'} (MPN : {i.item?.manufacturer_part_no ?? "-"} )</div>
                                                        <div className="form-text"> {
                                                            i.item?.tags?.length > 0
                                                                ? (i.item?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                : null
                                                        }</div>

                                                    </div>



                                                </td>

                                                <td className='pt0'>
                                                    <input
                                                        name="qty"
                                                        type="number"
                                                        value={this.state.linkItemRows[itemKey].qty}
                                                        onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e.target.value, 'qty', itemKey, 'linkItemRows')}
                                                        className="form-control text-end"
                                                        autoComplete="off"
                                                        placeholder="Qty"

                                                        required={true}
                                                        min={1}
                                                        step=".01"

                                                    />
                                                    {i.item.measuring_unit && i.item.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span> : null}

                                                </td>

                                            </Ax>)
                                                : (<Ax>
                                                    <td>
                                                        <InputItemSearch
                                                            changeEvent={(selectedOption) => {
                                                                this.onItemSelect(selectedOption, itemKey)
                                                            }}

                                                            value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                                                        />
                                                    </td>
                                                    <td colSpan={1} className="text-center align-middle" >-</td>
                                                </Ax>);
                                            return (<tr key={itemKey}>
                                                <td className="text-center align-middle">{itemKey + 1}</td>
                                                {tdRows}
                                                <td className="text-center align-middle">
                                                    <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                                                        <TapIcon.imageIcon icon={TapIcon.CloseCircleIcon} className="img-fluid" />
                                                    </a>
                                                </td>
                                            </tr>);
                                        })}
                                    </tbody>
                                </table>
                                <div className="col-sm-12 d-flex justify-content-between">
                                    <div><input
                                        name="remove_previous_linked_item"
                                        type="checkbox"
                                        value={this.state.remove_previous_linked_item}
                                        onChange={(e) => {
                                            this.formInputHandler(e, 'remove_previous_linked_item');
                                            this.setState({
                                                remove_previous_linked_item: this.state.remove_previous_linked_item == 'N' ? 'Y' : 'N'
                                            })
                                        }}
                                        checked={this.state.remove_previous_linked_item == 'Y'}
                                        className="form-check-input"
                                    />
                                        <label className="form-check-label text-sm mx-2"> Remove All Previously Linked Items</label>
                                    </div>
                                    <div> <a role="button" onClick={this.addNewItemRow} className="link-primary"><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} /> Add Row </a></div>
                                </div>
                            </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.linkedItemFormSubmitting} data-bs-dismiss="modal" className="btn btn-secondary" >Cancel</button>
                            <button type="submit" disabled={this.state.linkedItemFormSubmitting} className="btn btn-primary" form="linkItemForm" >Save  {this.state.linkedItemFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    render() {
        let id = this.props.matrix_enc_id

        return (
            <Ax>
                <Helmet><title>Asset Matrix Details</title></Helmet>
                <div className="row ml1">
                    <div className="col-12 pl0 bg-white ">
                        <TabNavigationAssetMatrix matrixId={this.state.matrix_id} />
                        {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <Link to={`/asset_matrix/details/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        Details
                                    </button>
                                </Link>
                            </li> 
                            <li className="nav-item">
                                <Link to={`/asset_matrix/scs_setting/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize show active" role="button" >
                                        SCS Setting
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset_matrix/setting/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        Setting
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset_matrix/compliance/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        Compliance
                                    </button>
                                </Link>
                            </li>

                        </ul>*/}

                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane active " id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                <div className="page_containt">
                                    <div className="pageTbl p-2" style={{ height: "100%" }}>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Service Checksheet Setting</span>
                                            <div className="float-end">
                                                <button type="button" className="btn btn-secondary mx-2" role="button" onClick={this.changeIntervalModalInit}>Change Interval</button>
                                                <Link to={`/asset_matrix/add/${this.state.matrix_id}`}><button type="button" className="btn btn-secondary" role="button" >Add Custom SCS</button> </Link>

                                            </div>
                                        </div>
                                        <table className="table table-hover table-bordered my-3 align-items-center ">
                                            <thead className='table-secondary'>
                                                <tr >
                                                    <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllList} id="all_check_settings" /></th>
                                                    <th scope="col" style={{ width: "24%" }}>Name</th>
                                                    <th scope="col" style={{ width: "28%" }}>Linked Items</th>
                                                    <th scope="col" style={{ width: "9%" }} className="text-center">Status </th>
                                                    <th scope="col" style={{ width: "11%" }} >Interval</th>
                                                    <th scope="col" style={{ width: "17%" }}>Parameter</th>
                                                    <th scope="col" style={{ width: "5%" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody  >
                                                {this.state.setting_listing.length > 0
                                                    ? (this.state.setting_listing.length > 0 ? this.state.setting_listing.map((asset, index) => {
                                                        let assetId = asset.id ? asset.id : asset.scs_id ? asset.scs_id : asset.asset_matrix_id;
                                                        return (<tr key={index} >
                                                            <td className="text-center">
                                                                <input type='checkbox'
                                                                    className="setting_item_id_checkbox"
                                                                    value={assetId}
                                                                // data-scsId={asset.scs_id} 
                                                                // data-assetMatrixId={asset.asset_matrix_id}
                                                                />
                                                            </td>
                                                            <td>{asset.name ?? "-"}<br />
                                                                <small className='form-text'><b>Alias : </b> {asset.alias_name}</small> </td>
                                                            <td>
                                                                {this.state.linked_items && this.state.linked_items.length > 0 && asset.id !== null
                                                                    ? this.state.linked_items.filter(li => li.asset_matrix_scs_id == asset.id).map((item, index) => {

                                                                        return <Ax>{item.items?.length > 0 ? item.items.map((i, itemKey) => <div className='mt-1'>{itemKey + 1}.<b>{i.item.name}</b> <span className="text-sm">({i.item.item_code})</span> : {i.qty} {i.item && i.item.measuring_unit ? i.item.measuring_unit.name : ''} </div>) : " -"}<br /></Ax>

                                                                    })
                                                                    : "-"}
                                                            </td>
                                                            <td className="text-center">{asset.status == 2
                                                                ? <TapIcon.FontAwesomeIcon color={`${asset.status_color_code}`} icon={TapIcon.faTimes} />
                                                                : <TapIcon.FontAwesomeIcon color={`${asset.status_color_code}`} icon={TapIcon.faCheck} />
                                                            }
                                                                <div className="tooltip-element my-1"> ({asset.active_asset_scs} / {asset.all_asset_scs})
                                                                    <span className="tooltiptext">Active on ({asset.active_asset_scs}) out of ({asset.all_asset_scs}) assets</span>
                                                                </div>


                                                            </td>
                                                            <td> {asset.interval && asset.interval.length > 0 ? asset.interval.map((item, index) => { return (<div className="mt-1"> {item.display} <br /></div>) })
                                                                : "-"}</td>
                                                            <td className='text-center'>
                                                                {(asset.enable_parameter == 'Y') ? asset.parameter.type_display : '-'} <br />
                                                                {(asset.enable_parameter == 'Y' && asset.parameter && asset.parameter.critical_level) ? ('Critical Level : ' + asset.parameter.critical_level) : ''}
                                                            </td>
                                                            <td className="text-center">
                                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a role="button" className='dropdown-item' onClick={() => this.editSetting(assetId)}>Edit</a></li>
                                                                    <li><a className="dropdown-item" role="button" onClick={() => this.linkItemInit(asset.id, asset.scs_id, index, asset.name)}>
                                                                        Linked Items
                                                                    </a></li>
                                                                </ul>
                                                            </td>

                                                        </tr>)
                                                    })
                                                        : <tr><td colSpan="7" className="text-center">No Records</td></tr>)
                                                    : null
                                                }
                                            </tbody>
                                        </table>
                                        <div className='bg-white text-center'>
                                            {this.state.listing_loading
                                                ? (<Loader />)
                                                : (null)}
                                        </div>

                                        <div className='text-end mt-1 mb-5'>
                                            {this.state.listingMeta && this.state.current_page < this.state.listingMeta.last_page
                                                ? <button role="button" className="btn btn-secondary me-3 " style={{ lineHeight: "6.3px!important", height: "27px" }} onClick={() => this.loadMoreList()} >Load More</button>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.changeIntervalModalJsx()}
                {this.linkItemModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
    }
}

export default connect(mapStateToProps)(SCSSetting);
