import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import AssetHeader from '../includes/AssetHeader';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import DowntimeView from '../../transactions/downtime/DowntimeView';
import DatePicker from 'react-datepicker';

class Downtime extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.downtimeUrl            =   IAM_API_BASE_URL_2 + "/downtime";
    
        this.downtimeSearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_asset_ids'              :   [],
            'asset_group_ids'               :    [],  
            'search_asset_type_ids'         :   [],
            'site_group_ids'                :    [],
            'search_site_ids'               :   [],
            'search_downtime_types'          :   [],
            'search_section_ids'            :   [],
            'search_sub_section_ids'        :   [],
            'search_downtime_from'          :   '',
            'search_downtime_to'            :   '',
            'search_defect_codes'           :   [],
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
        };

        this.downtimeSearchFormLabel         =   {
            'search_transaction_id'          :   'Transaction ID : ',
            'search_asset_ids'               :   'Asset : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            'search_asset_type_ids'          :   'Asset Type : ',
            'site_group_ids'                 :   'Site Groups : ',
            'search_site_ids'                :   'Site : ',
            'search_downtime_types'           :   'Type of Downtime : ',
            'search_section_ids'                 :   'Section : ',
            'search_downtime_from'           :   'Downtime Duration more than Hr : ',
            'search_downtime_to'             :   'Downtime Duration less than Hr : ',
            'search_sub_section_ids'             :   'Sub Section : ',
            'search_defect_codes'             :   'Defect Codes : ',
            'date_range'                     :   'Transaction Period : ',
        };

        this.state                         =     {  
            minimizeTable                   :       false,   
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            downtime_listing              :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            downtimeViewData                  :       null,
            viewDataLoading                 :       false,
            access_token                    :       '',
            all_periods                     :        [],
            downtimeSearchForm              :          {...this.downtimeSearchInitState},
            formSearchedElems               :         [],
            downtimeSearchParams            :          null,
            file_downloading                :         false,
            allTransactionDelay             :         [],
            searchedAssets                  :          [],
            iam_asset_types                 :           [],
            iam_group_sites                 :           [],
            allDowntimeTypes                :           [],
            allSectionList                  :           [],
            allSubSectionList               :           [],
            allSiteGroupsList               :           [],
            allAssetTypesGroupsList         :            [],
            allDefectCodes                  :           [],
            downtimeviewData                    :           null,
        };
       
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.searchFormDataInit(this.props)
        this.downtimeSearchModal         =    new Modal(document.getElementById('downtimeSearchModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        this.setState({assetId              :       pr.match?.params?.id,
            },() => {
                    
            this.getAsssetDetails(pr.match?.params?.id)
            this.loadListingTblData(1,pr.match?.params?.id)
            this.searchFormDataInit(pr)
        })
    }
    

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    loadListingTblData                 =   (page = 1,id)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,need_asset_profile : 'N',need_counterlog: 'Y',need_section:'Y',per_page : 50,...this.state.downtimeSearchParams,search_asset_ids : [id ? id : this.state.assetId]};
        HttpAPICall.withAthorization('GET',  this.downtimeUrl + '/list', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                downtime_listing        :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    viewDowntimeDetail           =       (id)        =>      {
        this.setState({minimizeTable : true})
        this.getDowntimeDetail(id)
        
    }

    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    getDowntimeDetail                 =   (downtime_id='')  =>  {
        let total_hours = "00:00";
        this.setState({viewDataLoading: true})
        HttpAPICall.withAthorization('GET', this.downtimeUrl + '/detail' , this.props.access_token , {transaction_id : downtime_id,need_section:'Y',need_defect_codes:'Y'}, {}, (response) => {
            let respData = response.data;
           this.setState({
                downtimeViewData : respData.data,  
            },() => {
               
            })
            
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({viewDataLoading: false}))
    }



    closeView           =       ()        =>      {
        this.setState({minimizeTable : false})
    }
   

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        
        HttpAPICall.withAthorization('GET', this.downtimeUrl + '/list_form_data', this.props.access_token, {})
            .then((response) => {
               let respData = response.data
              
              
                this.setState({
                    iam_asset_types         :       props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                    all_periods             :       all_periods,
                    allDefectCodes        :   response.data && response.data.defect_codes &&  response.data.defect_codes.length > 0 ? response.data.defect_codes.map((dt,k) => {return({value:dt.id,label:dt.name})}) : [],
                    iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                    allDowntimeTypes        :       respData && respData.downtime_types && respData.downtime_types.length > 0 ? respData.downtime_types.map((at) => { return { value: at.id, label: at.name } }) : [],
                    allSectionList          :       response.data.sections && response.data.sections.length > 0 ? response.data.sections.map(section => {return({value : section.id,label : section.component,subcomponents : section.subcomponents})}) : [],
                    allSiteGroupsList       :      props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                })
                    
                   
        }) 
    }

    downtimeSearchModalInit     =       ()      =>      {
        this.downtimeSearchModal.show()
    }

    downtimeSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems           :   [],
            downtimeSearchParams        :   null,
            searchedAssets              :   [],
            downtimeSearchForm          :   {...this.downtimeSearchInitState}
        }, () => {
            this.downtimeSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    downtimeSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.downtimeSearchForm);
        let searchedElems               =   [];
        let downtimeSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.downtimeSearchFormLabel[key];
            let value                       =   this.state.downtimeSearchForm[key];
           
            if(value && value !== "Invalid date" && value.toString().length > 0) {
                downtimeSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.downtimeSearchForm && this.state.downtimeSearchForm.search_transaction_id ? this.state.downtimeSearchForm.search_transaction_id : '';
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_downtime_types') {
                        show_val            =   this.state.allDowntimeTypes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_section_ids') {
                        show_val            =   this.state.allSectionList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_defect_codes') {
                        show_val            =   this.state.allDefectCodes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_sub_section_ids') {
                        show_val            =   this.state.allSubSectionList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_downtime_from') {
                        show_val            =   this.state.downtimeSearchForm && this.state.downtimeSearchForm.search_downtime_from ?  moment(this.state.downtimeSearchForm.search_downtime_from).format('HH:mm') : '';
                    }
                    if(key == 'search_downtime_to') {
                        show_val            =   this.state.downtimeSearchForm && this.state.downtimeSearchForm.search_downtime_to ?  moment(this.state.downtimeSearchForm.search_downtime_to).format('HH:mm') : '';
                    }
                    
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.downtimeSearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.downtimeSearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            downtimeSearchParams        :       {...downtimeSearchParams,
                search_downtime_from    :       this.state.downtimeSearchForm.search_downtime_from ?  moment(this.state.downtimeSearchForm.search_downtime_from).format('HH:mm') : '',
                search_downtime_to      :       this.state.downtimeSearchForm.search_downtime_to ?  moment(this.state.downtimeSearchForm.search_downtime_to).format('HH:mm') : '',
            }
        }, () => {
            this.downtimeSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    SecondsToHHMM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    }

    
   
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader  assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props} />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div> 
            <div id="assetCard" className="collapse pr-1 mt-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />}
        </div>
        </div>)
    }

    downtimeListJsx            =       ()      =>    {

        return (<Ax>
            <div style={{ paddingTop: "4px" }}>
                <div className="page_title row m0" style={{ height: "45px" }}>
                    <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                        <h3 style={{ paddingTop: "13px" }}>Downtime List</h3>
                    </div>
                    {!this.state.minimizeTable
                        ? <div className='col-sm-2 '>
                            <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                        </div> : null}
                    {!this.state.minimizeTable
                        ? <div className='col-sm-4 text-end mt7 '>
                           <button type="button"  disabled={this.state.listing_loading} onClick={this.downtimeSearchModalInit} className="btn btn-secondary" >
                        <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                   
                        </div> : null}
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <div className="col-sm-3 tap_border_left">
                                    <h3 className="text-capitalize">Downtime  : {this.state.downtimeViewData && this.state.downtimeViewData.transaction_id ? this.state.downtimeViewData.transaction_id : '-'}  </h3>
                                    </div>
                                    <div className='col-sm-2 '>
                                        <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                    </div>
                                    <div className='col-sm-4 text-end mt7'>
                                    
                                        <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </Ax>
                            )
                            : null
                    }
                </div>
            </div>
            <div className="container-fluid pl0 pr13">
                <div className="page_containt row">
                    <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.downtimeTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0 pageTbl pb-5"><div className=" bg-white p-2" >{this.state.view_loading ? <Loader /> : this.downtimeViewJsx()}</div> </div> : null}
                </div>
            </div>
        </Ax>);
    }

    downtimeTableJsx                   =   ()  =>  {
        return (
            <Ax>
                 <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.downtimeSearchClear} />
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>
                    <table className="table table-bordered bg-white align-items-center  table-sm table-hover table-fixed mb-0">
                        <thead className="table-secondary">
                        {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Downtime</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "12%" }} className='text-center'>Date</th>
                            <th scope="col" style={{ width: "14%" }}>Transaction ID</th>
                            <th scope="col" style={{ width: "14%" }}>Type of Downtime</th>
                            <th scope="col" style={{ width: "10%" }} className='text-center'>Downtime Hours</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                        </thead>
                        <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="9"><Loader /></td></tr>)
                        : (this.state.downtime_listing && this.state.downtime_listing.length > 0
                            ? (this.state.downtime_listing.map((data, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} >
                                                        <div className="text-capitalize link-primary cursor_pointer" onClick={() => this.viewDowntimeDetail(data.transaction_id)}>{data.transaction_id ? data.transaction_id : "-"}</div>
                                                        <div className="mt-1">
                                                           <small className="float-end">{data.downtime_type ? data.downtime_type.name : "-"}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td>{data.feed_datetime ? data.feed_datetime : "-"}</td>
                                                <td className={[this.props.permissions.includes('downtime-view') ? 'link-primary cursor_pointer' : 'disabled'].join(' ')}
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('downtime-view')) {
                                                            this.viewDowntimeDetail(data.transaction_id)
                                                        }
                                                    }}
                                            >{data.transaction_id ? data.transaction_id : "-"}</td>
                                                <td>{data.downtime_type ? data.downtime_type.name : "-"}</td>
                                                <td className='text-center'>{data.downtime_seconds ? this.SecondsToHHMM(data.downtime_seconds) : "0"}</td>
                                               
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <a role="button"
                                                            onClick={() => {
                                                                if (this.props.permissions.includes('downtime-view')) {
                                                                    return this.viewDowntimeDetail(data.transaction_id)
                                                                }
                                                            }}
                                                            className={['dropdown-item', this.props.permissions.includes('downtime-view') ? '' : 'disabled'].join(' ')}
                                                            title={!this.props.permissions.includes('downtime-view') ? 'You do not have permission to perform this action' : ''}
                                                            style={{ "pointerEvents": "all" }}
                                                        >View</a>
                                                       </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="9" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>

                    </table>

                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                    />
                </div>

            </Ax>
        );
    }

    downtimeViewJsx                =   ()   =>   {
      
        return (<div>
            <div className="row mr0">
                <div className="col-sm-12 pr0 pb-4">
                    <DowntimeView downtimeViewData={this.state.downtimeViewData} />
                </div>

            </div>

        </div>)
    }

    downtimeSearchModalJsx         =   ()  =>  {
        return (
            <div className="modal fade" id="downtimeSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="downtimeSearchModalLabel">Downtime Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.downtimeSearchFormSubmit} id="downtimeSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.downtimeSearchForm.search_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "downtimeSearchForm")}
                                        />
                                    </div>

                                </div>

                               
                                <div className="row my-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Type of Downtime</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allDowntimeTypes}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'downtimeSearchForm', 'search_downtime_types')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allDowntimeTypes.filter(s => this.state.downtimeSearchForm?.search_downtime_types?.includes(s.value))}
                                            placeholder="All"
                                            isMulti={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Section</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allSectionList}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'downtimeSearchForm', 'search_section_ids')

                                            }
                                            }
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            value={this.state.allSectionList.filter(s => this.state.downtimeSearchForm.search_section_ids.includes(s.value))}
                                            placeholder="Select Section"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Defect Codes</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allDefectCodes}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'downtimeSearchForm', 'search_defect_codes')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allDefectCodes.filter(s => this.state.downtimeSearchForm.search_defect_codes.includes(s.value))}
                                            placeholder="Select Defect Codes"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Downtime Duration (Range)</label>
                                    <div className="col-sm-5">
                                        <DatePicker
                                            onChange={(value, event) => {
                                                this.setState({ downtimeSearchForm: { ...this.state.downtimeSearchForm, search_downtime_from: value } });
                                            }}
                                            selected={this.state.downtimeSearchForm?.search_downtime_from ? this.state.downtimeSearchForm.search_downtime_from : ''}
                                            className="form-control form-control-sm"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="Hours"
                                            dateFormat="HH:mm"
                                            autoComplete={"off"}
                                            placeholderText={`Select Downtime From`}
                                        />
                                    </div>
                                    <div className="col-sm-5">
                                        <DatePicker
                                            onChange={(value, event) => {
                                                this.setState({ downtimeSearchForm: { ...this.state.downtimeSearchForm, search_downtime_to: value } });
                                            }}
                                            selected={this.state.downtimeSearchForm?.search_downtime_to ? this.state.downtimeSearchForm.search_downtime_to : ''}
                                            className="form-control form-control-sm"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="Hours"
                                            dateFormat="HH:mm"
                                            autoComplete={"off"}
                                            placeholderText={`Select Downtime To`}
                                        />
                                    </div>
                                </div>


                                <div className="row my-2 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.downtimeSearchForm.date_range}
                                            startDate={this.state.downtimeSearchForm.date_range_start}
                                            endDate={this.state.downtimeSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    downtimeSearchForm
                                                        : {
                                                        ...this.state.downtimeSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


   
    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset - Downtime List</title></Helmet>
             {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.downtimeListJsx()}
            {this.downtimeSearchModalJsx()}
        
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],    
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Downtime);