import React from "react";
import axios from "axios";
import moment from "moment";
import TapSelect from "../../../components/ui/TapSelect";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from "../../../services/HttpAPICall";
import {
  IAM_API_BASE_URL_2,
  INVENTORY_API_BASE_URL_2,
  ISC_API_BASE_URL_2,
  ORG_API_BASE_URL_2,
} from "../../../services/TapApiUrls";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import AddVendorModal from "../../includes/ui/AddVendorModal";
import InputItemSearch from "../../includes/ui/InputItemSearch";
import { Helmet } from "react-helmet";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import WarehouseAddress from "../../groupData/warehouse/WarehouseAddress";
import AddItemModal from "../../items/itemsAdd/AddItemModal";
import ItemEditModal from "../../items/itemsAdd/ItemEditModal";
import ItemDetailsModal from "../../items/itemDetails/ItemDetailsModal";
import Vendor from "../includes/Vendor";
import ConsumptionHistoryModal from "../consumption/ConsumptionHistoryModal";
import POHistoryModal from "./POHistoryModal";
import ItemSearchModal from "../../items/itemSearchModal";
import { DisplayListPagination } from "../../../components/TapUi";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import AssetMiniDetail from "../../includes/AssetMiniDetail";
import InputEmployeeSearch from "../../includes/ui/InputEmployeeSearch";
import NumberFormatter from "../../../services/NumberFormater";
import AdditionalAttributeForm from "../../includes/ui/AdditionalAttributeForm";
import TapInputNumber from "../../../components/ui/TapInputNumber";
import InputWorkstationSearch from "../../includes/ui/InputWorkstationSearch";

class PurchaseOrderAdd extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.initPOForm = {
      transaction_id: "",
      transaction_date: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      warehouse_id: "",
      vendor_id: "",
      purchase_order_type: "purchase_order",
      purchase_order_type_name: "Purchase Order",
      vendor_address_id: "",
      expected_delivery_date: "",
      priority: "",
      status: 2,
      currency: "INR",
      conversion_rate: 1,
      notes: "",
      tnc: [],
      adjustment_amount: "",
      quotation_name: "",
      quotation_date: "",
      authorized_signatory_id: "",
      po_for: "",
      asset_id: "",
      workstation_id: "",
      employee_id: "",
      currency_display: "",
      tcs_rate: "",
      tcs_type: "Percentage",
      users: [],

    };

    this.addTermForm = {
      name: "",
      text: "",
    };

    this.initAddTermForm = {
      name: "",
      text: "",
      tag_ids: []
    };

    this.initPOItemRow = {
      item: null,
      req_allocation: [],
      req_allocation_loading: false,
      id: "",
      qty: 0,
      allocated_qty: 0,
      additional_qty: 0,
      rate: 0,
      remark: "",
      gst: 0,
      discount_rate: 0,
      discount_type: "Percentage",
    };

    this.initState = {
      termCoditionLoading: false,
      termCoditions: [],
      oldTNC                          :   [],
      pOFormDataLoaded: false,
      pOEditFormDataLoaded: false,
      pOForm: { ...this.initPOForm },
      addTermForm: { ...this.initAddTermForm },
      poItemRows: [this.initPOItemRow],
      addTermFormSubmitting: false,
      transactionIdSetting: null,
      allWarehouses: [],
      allStatusOptions: [],
      allPriorityOptions: [],
      allCurrencyList: [],
      allDiscountType: [],
      allGstSlabs: [],
      user_status: [],
      warehouse_state_id: null,
      warehouseDetailLoading: false,
      warehouseDetail: null,
      searchedVendor: null,
      searchedVendorAdressList: [],
      selectedVendorAdress: null,
      gstTypeName: "",
      vendorDetails: null,
      vendorAddressList: [],
      vendor_details_loading: false,
      saveFormSubmitting: false,
      currency_loading: false,
      editCurrency: false,
      itemSearchedDetails: null,
      itemRowNumber: 0,
      poItemRow: null,
      poItemRowKey: 0,
      update_item_purchase_price: "Y",
      selectedShippingAddress: null,
      orgInfoData: null,
      address_loading: false,
      warehouseAddressList: [],
      warehouseListingMeta: null,
      totalAddressCount: 0,
      allUsersList: [],
      approved_requisition_listing: false,
      requistion_listing: [],
      totalRequisitionListingCount: 0,
      reqListingMeta: null,
      allReqItemLists: [],
      gst_as_applicable: "N",
      po_allow_po_for: "N",
      warehouseSites: [],
      selectedAsset: null,
      selectedWorkstation: null,
      searchedEmployee: null,
      po_enable_authorized_signatory: "N",
      po_allow_backdate: "N",
      po_title: "",
      formData: "",
      multiple_currency_available: "N",
      gst_not_applicable: "N",
      listingLoaded: false,
      isc_enable_tcs: "Y",
      tcs_applicable: "Y",
      allTCSType: [],
      allUsers: [],
      po_allow_edit_transaction_date: "Y",
      update_item_consumption_price: "Y",
      buttonDisabled: false,
      allPOTypes: [],
      searchWarehouseAddress: "",
      clearSearchAddress: true,
      showItemSearchModal: false,
      showItemAddModal: false,
      showItemEditModal: false,
      pickTNCModalLoader: false,
      tncModalLoader: false,
      tncTags: [],
      searchTNCByTags: [],
    };
    this.state = { ...this.initState };
    this.addItemModalRef = React.createRef();
    this.editItemModalRef = React.createRef();
    this.addVendorModalRef = React.createRef();
    this.consumptionHistoryRef = React.createRef();
    this.poHistoryRef = React.createRef();
    this.itemDetailsRef = React.createRef();
    this.itemSearchRef = React.createRef();
    this.addWarehouseAddressRef = React.createRef();
    this.currency_convert_url = ORG_API_BASE_URL_2;

    this.purchaseOrderAddUrl = INVENTORY_API_BASE_URL_2 + "/purchase_order";
    this.poFormDataUrl = this.purchaseOrderAddUrl + "/add_form_data";
    this.requisitionPendingQtyUrl =
      INVENTORY_API_BASE_URL_2 + "/requisition_pending_qty";
    this.requisitionItemDetailUrl =
      INVENTORY_API_BASE_URL_2 + "/requisition_item_detail";
    this.poPreviousTnc =
      INVENTORY_API_BASE_URL_2 + "/purchase_order_previous_tnc";
    this.itemUrl = INVENTORY_API_BASE_URL_2 + "/item";
    this.vendorUrl = INVENTORY_API_BASE_URL_2 + "/vendor";
    this.requisitionReportUrl =
      INVENTORY_API_BASE_URL_2 + "/report/item-wise-requisition";
    this.warehouseShipAddUrl =
      INVENTORY_API_BASE_URL_2 + "/warehouse/shipping_address";
    this.warehouseUrl = ISC_API_BASE_URL_2 + "/warehouse";
    this.TagUrlList = IAM_API_BASE_URL_2 + '/sub_assembly_tag/list';
  }

  componentDidMount() {
    this.initilaizeFormData(this.props);
    this.addTermsModal = new Modal(document.getElementById("addTermsModal"), {
      keyboard: false,
      backdrop: false,
    });
    this.reqDetailModal = new Modal(document.getElementById("reqDetailModal"), {
      keyboard: false,
      backdrop: false,
    });
    this.addTnCInPOModal = new Modal(
      document.getElementById("addTnCInPOModal"),
      { keyboard: false, backdrop: false }
    );
    this.viewItemModal = new Modal(document.getElementById("viewItemModal"), {
      keyboard: false,
      backdrop: false,
    });
    this.warehouseAddressModal = new Modal(
      document.getElementById("warehouseAddressModal"),
      { keyboard: false, backdrop: false }
    );
    this.pickReqModal = new Modal(document.getElementById("pickReqModal"), {
      keyboard: false,
      backdrop: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location && this.props.location.state) {
      if (nextProps.all_warehouses !== this.props.all_warehouses) {
        this.initilaizeFormData(nextProps);
      }
    }
  }

  initilaizeFormData = (props) => {
    this.loadPOFormdata(props);
    this.setState({
      allWarehouses: props.all_warehouses
        ? props.all_warehouses.map((s) => {
          return {
            value: s.id,
            label: `${s.name} (${s.code})`,
            site_id: s.site_id,
          };
        })
        : [],
      orgInfoData: props.orgInfoData ? props.orgInfoData : null,
    });

    this.getTermListing();
  };

  loadPOFormdata = (props) => {
    let sites = props.iam_group_sites;
    this.setState({ pOFormDataLoaded: true });
    HttpAPICall.withAthorization(
      "GET",
      this.poFormDataUrl,
      this.props.access_token,
      null,
      null,
      (response) => {
        let formData = response.data;
        let defaultCurrency =
          props && props.default_currency ? props.default_currency : "INR";
        let poCurrency =
          props && props.default_currency ? props.default_currency : "INR";
        let editPO = false;
        if (
          props.location &&
          props.location.state &&
          props.location.state.poData
        ) {
          let purchseOrderData = props.location.state.poData;
          poCurrency = purchseOrderData.currency
            ? purchseOrderData.currency
            : "";
          editPO = true;
          this.setState({ buttonDisabled: true });
        }

        if (
          props.location &&
          props.location.state &&
          props.location.state.clonePODetail
        ) {
          let purchseOrderData = props.location.state.clonePODetail;
          poCurrency = purchseOrderData.currency
            ? purchseOrderData.currency
            : "";
          editPO = false;
          this.setState({ buttonDisabled: true });
        }

        let currencyList =
          props && props.currency_list && props.currency_list.length > 0
            ? props.currency_list.map((s) => {
              return { value: s.code, label: s.code };
            })
            : [];
        this.setState({
          allStatusOptions:
            formData && formData.status && formData.status.length > 0
              ? formData.status.map((s) => {
                return { value: s.id, label: s.name };
              })
              : [],
          allCurrencyList: currencyList,
          allDiscountType:
            formData && formData.discount_type
              ? formData.discount_type.map((s) => {
                return { value: s, label: s === "Fixed" ? poCurrency : "%" };
              })
              : [],
          allGstSlabs:
            formData && formData.gst_slabs
              ? formData.gst_slabs.map((s) => {
                return { value: s, label: s + "%" };
              })
              : [],
          allPriorityOptions:
            formData && formData.priorities && formData.priorities.length > 0
              ? formData.priorities.map((s) => {
                return { value: s.key, label: s.name };
              })
              : [],
          allPOTypes:
            formData &&
              formData.purchase_order_type &&
              formData.purchase_order_type.length > 0
              ? formData.purchase_order_type.map((s) => {
                return { value: s.key, label: s.name, type_data: s };
              })
              : [],
          defaultCurrency: defaultCurrency,
          multiple_currency_available:
            formData && formData.setting && formData.setting.multiple_currency
              ? formData.setting.multiple_currency
              : "N",
          formData: formData,
          allUsersList:
            formData && formData.users && formData.users.length > 0
              ? formData.users.map((s) => {
                return { value: s.id, label: s.full_name };
              })
              : [],
          pOForm: { ...this.state.pOForm, currency: defaultCurrency },
          po_allow_po_for:
            formData && formData.setting && formData.setting.po_allow_po_for
              ? formData.setting.po_allow_po_for
              : "N",
          po_enable_authorized_signatory:
            formData &&
              formData.setting &&
              formData.setting.po_enable_authorized_signatory
              ? formData.setting.po_enable_authorized_signatory
              : "N",
          po_allow_backdate:
            formData && formData.setting && formData.setting.po_allow_backdate
              ? formData.setting.po_allow_backdate
              : "N",
          po_allow_edit_transaction_date:
            editPO == true &&
              formData &&
              formData.setting &&
              formData.setting.po_allow_edit_transaction_date
              ? formData.setting.po_allow_edit_transaction_date
              : "Y",
          isc_enable_tcs:
            formData && formData.setting && formData.setting.isc_enable_tcs
              ? formData.setting.isc_enable_tcs
              : "Y",
          allTCSType:
            formData && formData.discount_type
              ? formData.discount_type.map((s) => {
                return { value: s, label: s === "Fixed" ? poCurrency : "%" };
              })
              : [],
          allUsers:
            formData && formData.users
              ? formData.users.map((s) => {
                return {
                  value: s.id,
                  label: s.full_name,
                  site_ids: s.linked_sites,
                };
              })
              : [],
        });
      }
    ).then(() => {
      this.setState({ pOFormDataLoaded: false });
      //Remanage Status of Purchase Order :-
      this.filterAllStatusOptions();
      let transactionIdSetting = this.state.allPOTypes
        ? this.state.allPOTypes.find(
          (pt) => pt.value == this.state.pOForm.purchase_order_type
        )
        : null;
      this.setState({
        transactionIdSetting: transactionIdSetting
          ? transactionIdSetting.type_data
          : null,
      });
      //In Edit Mode :-
      if (
        props.location &&
        props.location.state &&
        props.location.state.poData
      ) {
        //1 - Edit Mode of Existing Purchase Order :-
        let purchseOrderData = props.location.state.poData;
        //in Items Data : (props.location.state.poData.items)
        //if same Item got than we will merge and process start :-

        let updateFormState = [];
        if (purchseOrderData.additional_attributes_data) {
          purchseOrderData.additional_attributes_data.map((i, k) => {
            let name = "additional_attributes_" + i.key;
            updateFormState[name] = i.value;
          });
        }

        let poItemRows = props.location.state.poData.items.map((po_item, k) => {
          let allocated_qty = Number(
            po_item.req_allocation.reduce(
              (s, b) => s + Number(b.qty ? b.qty : 0),
              0
            )
          );

          return {
            ...this.initPOItemRow,
            item: {
              ...po_item.item,
              isAddRemark: po_item.remark ? true : false,
              isAddDueDate: po_item.due_date ? true : false,
            },
            req_allocation_loading: true,
            req_allocation: [],
            prev_req_allocation: po_item.req_allocation,
            id: po_item.item.id,
            qty: Number(po_item.qty) - Number(po_item.cancelled_qty),
            allocated_qty: allocated_qty,
            additional_qty: po_item.additional_qty,
            rate: po_item.rate_vc,
            remark: po_item.remark,
            gst: po_item.gst,
            discount_type: po_item.discount_type,
            discount_rate: po_item.discount_rate_vc,
            due_date: po_item.due_date,
          };
        });
        let allReqItemsList = poItemRows.flatMap((poItemRow) => {
          // Extracting the req_allocation array from each poItemRow

          const reqAllocation = poItemRow.prev_req_allocation;

          // Mapping the req_item_id values from the req_allocation array
          const reqItemIds = reqAllocation.map((item) => {
            return { ...item, req_item_id: item.req_item_id };
          });

          // Returning an array of objects with req_item_id values
          return reqItemIds;
        });

        this.setState(
          {
            poItemRows: poItemRows,
            allReqItemLists:
              allReqItemsList && allReqItemsList.length > 0
                ? allReqItemsList
                : [],
          },
          () => {
            //get all Previous Allocation :-

            this.getSetMultipleReqPendingQuantity(
              purchseOrderData.warehouse_id,
              poItemRows
            );
          }
        );

        this.setState(
          {
            pOForm: {
              ...this.initPOForm,
              purchase_order_type_name:
                purchseOrderData.purchase_order_type_name
                  ? purchseOrderData.purchase_order_type_name
                  : "Purchase Order",
              purchase_order_type: purchseOrderData.purchase_order_type
                ? purchseOrderData.purchase_order_type
                : "purchase_order",
              transaction_date: purchseOrderData.transaction_date
                ? purchseOrderData.transaction_date
                : "",
              transaction_id: purchseOrderData.transaction_id
                ? purchseOrderData.transaction_id
                : "",
              warehouse_id: purchseOrderData.warehouse_id
                ? purchseOrderData.warehouse_id
                : "",
              vendor_id: purchseOrderData.vendor
                ? purchseOrderData.vendor.id
                : "",
              expected_delivery_date: purchseOrderData.expected_delivery_date
                ? purchseOrderData.expected_delivery_date
                : "",
              priority: purchseOrderData.priority
                ? purchseOrderData.priority
                : "",
              status: purchseOrderData.status ? purchseOrderData.status : 1,
              currency: purchseOrderData.currency
                ? purchseOrderData.currency
                : "",
              conversion_rate: purchseOrderData.conversion_rate
                ? parseFloat(purchseOrderData.conversion_rate).toFixed(2)
                : "",
              notes: purchseOrderData.notes ? purchseOrderData.notes : "",
              adjustment_amount: purchseOrderData.adjustment_amount_vc,
              tnc: purchseOrderData.tnc,
              shipping_address_id: purchseOrderData.shipping_address_id,
              quotation_name: purchseOrderData.quotation_name
                ? purchseOrderData.quotation_name
                : "",
              quotation_date: purchseOrderData.quotation_date
                ? purchseOrderData.quotation_date
                : "",
              authorized_signatory_id: purchseOrderData.authorized_signatory_id
                ? purchseOrderData.authorized_signatory_id
                : "",
              po_for: purchseOrderData.po_for ? purchseOrderData.po_for : "",
              asset_id: purchseOrderData.assetProfile
                ? purchseOrderData.assetProfile.asset_id
                : null,
              workstation_id: purchseOrderData.workstation
                ? purchseOrderData.workstation.id
                : null,
              tcs_rate: purchseOrderData.tcs_rate
                ? purchseOrderData.tcs_rate
                : "",
              tcs_type: purchseOrderData.tcs_type
                ? purchseOrderData.tcs_type
                : "Percentage",
              tcs_applicable:
                purchseOrderData.tcs_rate && purchseOrderData.tcs_rate > 0
                  ? "Y"
                  : "N",
              employee_id:
                purchseOrderData.employee && purchseOrderData.employee.enc_id
                  ? purchseOrderData.employee.enc_id
                  : null,
              users: purchseOrderData.users ? purchseOrderData.users : [],
              ...updateFormState,
            },
            searchedVendor: {
              value: purchseOrderData.vendor.id,
              display_label: purchseOrderData.vendor.name,
            },
            vendorDetails: {
              value: purchseOrderData.vendor.id,
              display_label: purchseOrderData.vendor.name,
            },
            selectedVendorAdress: purchseOrderData.vendor_address
              ? {
                ...purchseOrderData.vendor_address,
                gstin: purchseOrderData.vendor_gstin,
                id: purchseOrderData.vendor_address_id,
                country_id: purchseOrderData.vendor_address.country_id
                  ? purchseOrderData.vendor_address.country_id
                  : 99,
              }
              : null,
            poDetail: purchseOrderData,
            selectedShippingAddress: purchseOrderData.warehouse_shipping_address
              ? {
                ...purchseOrderData.warehouse_shipping_address,
                id: purchseOrderData.shipping_address_id,
              }
              : null,
            user_status: purchseOrderData.user_status,
            gst_as_applicable: purchseOrderData.gst_as_applicable
              ? purchseOrderData.gst_as_applicable
              : "N",
            gst_not_applicable: purchseOrderData.gst_not_applicable
              ? purchseOrderData.gst_not_applicable
              : "N",
            po_title: purchseOrderData.title ? purchseOrderData.title : "",
            isc_enable_tcs:
              (purchseOrderData.tcs_rate && purchseOrderData.tcs_rate > 0) ||
                this.state.isc_enable_tcs == "Y"
                ? "Y"
                : "N",
          },
          () => {
            this.getWarehouseDetail(this.state.pOForm.warehouse_id, true);
            this.getVendorDetails(this.state.pOForm.vendor_id, true);
            this.handleVendorChange(this.state.searchedVendor, true);
            let transactionIdSetting = this.state.allPOTypes
              ? this.state.allPOTypes.find(
                (pt) => pt.value == this.state.pOForm.purchase_order_type
              )
              : null;
            this.setState({
              transactionIdSetting: transactionIdSetting
                ? transactionIdSetting.type_data
                : null,
            });
            //Remanage Status of Purchase Order :-
            this.filterAllStatusOptions();
            let assetProfile = purchseOrderData.assetProfile;
            if (assetProfile) {
              this.setState({
                selectedAsset: {
                  ...assetProfile,
                  label: `${assetProfile.name} (${assetProfile.asset_code})`,
                },
              });
            }

            let employeeProfile = purchseOrderData.employee;
            if (employeeProfile) {
              this.setState({
                searchedEmployee: {
                  value: employeeProfile.enc_id,
                  label: `${employeeProfile.display_full_name}`,
                },
              });
            }

            let workstation = purchseOrderData.workstation;
            if (workstation) {
              this.setState({
                searchedWorkstation: {
                  value: workstation.id,
                  label: workstation.workstation_name,
                },
              });
            }
          }
        );
      } else if (
        props.location &&
        props.location.state &&
        props.location.state.reqItemsforPO
      ) {
        //2 - In Raise PO from Requisition :-
        this.setState({ pOEditFormDataLoaded: true });

        let reqItemsforPO = props.location.state.reqItemsforPO;

        if (
          reqItemsforPO.selected_purchase_order &&
          reqItemsforPO.selected_purchase_order != ""
        ) {
          //2.1 - In case of Existing Purchase Order :-
          const poDetailUrl =
            this.purchaseOrderAddUrl +
            "/" +
            reqItemsforPO.selected_purchase_order;
          const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.access_token,
          };
          Promise.all([
            axios({ method: "GET", headers: headers, url: poDetailUrl }),
            axios({
              method: "GET",
              headers: headers,
              url: this.requisitionItemDetailUrl,
              params: { req_item_ids: reqItemsforPO.req_item_ids },
            }),
          ])
            .then((results) => {
              let purchseOrderData = results[0].data.data;
              let reqItems = results[1].data;
              let poItemRows = purchseOrderData.items.map((po_item, k) => {
                let allocated_qty = Number(
                  po_item.req_allocation.reduce(
                    (s, b) => s + Number(b.qty ? b.qty : 0),
                    0
                  )
                );
                return {
                  ...this.initPOItemRow,
                  item: {
                    ...po_item.item,
                    isAddRemark: po_item.remark ? true : false,
                  },
                  req_allocation_loading: true,
                  req_allocation: [],
                  prev_req_allocation: po_item.req_allocation
                    ? po_item.req_allocation
                    : [],
                  id: po_item.item.id,
                  qty: po_item.qty,
                  allocated_qty: allocated_qty,
                  additional_qty: po_item.qty - allocated_qty,
                  rate: po_item.rate,
                  remark: po_item.remark,
                  isAddRemark: po_item.remark ? true : false,
                  gst: po_item.gst,
                  discount_type: po_item.discount_type,
                  discount_rate: po_item.discount_rate,
                };
              });

              reqItems.forEach((ir, key) => {
                //check item is alredy exist in item row or not
                let poItemRow = poItemRows.find(
                  (poir) => poir.id == ir.item.id
                );
                if (poItemRow) {
                  let poItemRowKey = this.findKey(poItemRows, poItemRow);
                  if (poItemRowKey) {
                    let prev_req_allocations = poItemRow.prev_req_allocation;
                    if (prev_req_allocations.length > 0) {
                      let prev_req_allocation = prev_req_allocations.find(
                        (pra) => pra.req_item_id == ir.req_item_id
                      );

                      if (prev_req_allocation) {
                        let prevReqAllocationKey = this.findKey(
                          prev_req_allocations,
                          prev_req_allocation
                        );
                        prev_req_allocations[prevReqAllocationKey] = {
                          ...prev_req_allocation,
                          qty:
                            Number(prev_req_allocation.qty) +
                            Number(ir.pending_qty),
                        };
                      } else {
                        prev_req_allocations.push({
                          req_item_id: ir.req_item_id,
                          req_id: ir.requisition_transaction_id,
                          qty: ir.pending_qty,
                          item_id: ir.item.id,
                        });
                      }
                    } else {
                      prev_req_allocations = [
                        ...prev_req_allocations,
                        {
                          req_item_id: ir.req_item_id,
                          req_id: ir.requisition_transaction_id,
                          qty: ir.pending_qty,
                          item_id: ir.item.id,
                        },
                      ];
                    }
                    poItemRows[poItemRowKey] = {
                      ...poItemRow,
                      item: {
                        ...ir.item,
                        isAddRemark: ir.remark ? true : false,
                      },
                      qty: poItemRow.qty + ir.pending_qty,
                      prev_req_allocation: prev_req_allocations,
                      remark: ir.remark,
                      isAddRemark: ir.remark ? true : false,
                    };
                  }
                } else {
                  poItemRows.push({
                    ...this.initPOItemRow,
                    item: { ...ir.item, isAddRemark: ir.remark ? true : false },
                    remark: ir.remark,
                    isAddRemark: ir.remark ? true : false,
                    id: ir.item.id,
                    qty: ir.pending_qty,
                    rate: ir.item.purchase_price ? ir.item.purchase_price : 0,
                    req_allocation_loading: true,
                    req_allocation: [],
                    prev_req_allocation: [
                      {
                        req_item_id: ir.req_item_id,
                        req_id: ir.requisition_transaction_id,
                        qty: ir.pending_qty,
                        item_id: ir.item.id,
                      },
                    ],
                  });
                }
              });
              let allReqItemsList = poItemRows.flatMap((poItemRow) => {
                // Extracting the req_allocation array from each poItemRow

                const reqAllocation = poItemRow.prev_req_allocation;

                // Mapping the req_item_id values from the req_allocation array
                const reqItemIds = reqAllocation.map((item) => {
                  return { ...item, req_item_id: item.req_item_id };
                });

                // Returning an array of objects with req_item_id values
                return reqItemIds;
              });
              this.setState(
                {
                  poItemRows: poItemRows,
                  allReqItemLists:
                    allReqItemsList && allReqItemsList.length > 0
                      ? allReqItemsList
                      : [],
                },
                () => {
                  //get all Previous Allocation :-
                  this.getSetMultipleReqPendingQuantity(
                    purchseOrderData.warehouse_id,
                    poItemRows
                  );
                }
              );
              this.setState(
                {
                  pOForm: {
                    ...this.initPOForm,
                    transaction_date: purchseOrderData.transaction_date
                      ? purchseOrderData.transaction_date
                      : "",
                    transaction_id: purchseOrderData.transaction_id
                      ? purchseOrderData.transaction_id
                      : "",
                    warehouse_id: purchseOrderData.warehouse_id
                      ? purchseOrderData.warehouse_id
                      : "",
                    vendor_id: purchseOrderData.vendor
                      ? purchseOrderData.vendor.id
                      : "",
                    expected_delivery_date:
                      purchseOrderData.expected_delivery_date
                        ? purchseOrderData.expected_delivery_date
                        : "",
                    priority: purchseOrderData.priority
                      ? purchseOrderData.priority
                      : "",
                    status: purchseOrderData.status
                      ? purchseOrderData.status
                      : 1,
                    currency: purchseOrderData.currency
                      ? purchseOrderData.currency
                      : "",
                    conversion_rate: purchseOrderData.conversion_rate
                      ? purchseOrderData.conversion_rate
                      : "",
                    notes: purchseOrderData.notes ? purchseOrderData.notes : "",
                    adjustment_amount: purchseOrderData.adjustment_amount,
                    tnc: purchseOrderData.tnc,
                    quotation_name: purchseOrderData.quotation_name
                      ? purchseOrderData.quotation_name
                      : "",
                    quotation_date: purchseOrderData.quotation_date
                      ? purchseOrderData.quotation_date
                      : "",
                  },
                  searchedVendor: {
                    value: purchseOrderData.vendor.id,
                    display_label: purchseOrderData.vendor.name,
                  },
                  vendorDetails: {
                    value: purchseOrderData.vendor.id,
                    display_label: purchseOrderData.vendor.name,
                  },
                  selectedVendorAdress: purchseOrderData.vendor_address
                    ? {
                      ...purchseOrderData.vendor_address,
                      gstin: purchseOrderData.vendor_gstin,
                    }
                    : null,
                  poDetail: purchseOrderData,
                  user_status: purchseOrderData.user_status,
                },
                () => {
                  this.getWarehouseDetail(this.state.pOForm.warehouse_id);
                  this.getVendorDetails(this.state.pOForm.vendor_id);
                  this.handleVendorChange(this.state.searchedVendor);
                  //Remanage Status of Purchase Order :-
                  this.filterAllStatusOptions();
                }
              );
            })
            .then(() => {
              this.setState({ pOEditFormDataLoaded: false });
            });
        } else {
          //2.2 - In Case of new Purchase Order :-

          let params = { req_item_ids: reqItemsforPO.req_item_ids };
          HttpAPICall.withAthorization(
            "GET",
            this.requisitionItemDetailUrl,
            this.props.access_token,
            params,
            null,
            (response) => {
              let reqItems = response.data;

              let poItemRows = reqItems.map((ir, k) => {
                return {
                  ...this.initPOItemRow,
                  item: { ...ir.item, isAddRemark: ir.remark ? true : false },
                  remark: ir.remark,
                  id: ir.item.id,
                  qty: ir.pending_qty,
                  gst: ir.item.gst ? ir.item.gst : 0,
                  rate: ir.item.purchase_price ? ir.item.purchase_price : 0,
                  req_allocation_loading: true,
                  req_allocation: [],
                  prev_req_allocation: [
                    {
                      req_item_id: ir.req_item_id,
                      req_id: ir.requisition_transaction_id,
                      qty: ir.pending_qty,
                      item_id: ir.item.id,
                    },
                  ],
                };
              });
              let allReqItemsList = poItemRows.flatMap((poItemRow) => {
                // Extracting the req_allocation array from each poItemRow

                const reqAllocation = poItemRow.prev_req_allocation;

                // Mapping the req_item_id values from the req_allocation array
                const reqItemIds = reqAllocation.map((item) => {
                  return { ...item, req_item_id: item.req_item_id };
                });

                // Returning an array of objects with req_item_id values
                return reqItemIds;
              });
              this.setState(
                {
                  poItemRows: poItemRows,
                  pOForm: {
                    ...this.initPOForm,
                    warehouse_id: reqItemsforPO.warehouse_id
                      ? reqItemsforPO.warehouse_id
                      : "",
                  },
                  allReqItemLists:
                    allReqItemsList && allReqItemsList.length > 0
                      ? allReqItemsList
                      : [],
                },
                () => {
                  //get all Previous Allocation :-
                  this.getSetMultipleReqPendingQuantity(
                    reqItemsforPO.warehouse_id,
                    poItemRows
                  );
                  this.getWarehouseDetail(this.state.pOForm.warehouse_id);
                }
              );
            }
          ).then(() => {
            this.setState({ pOEditFormDataLoaded: false });
          });
        }
      } else if (
        props.location &&
        props.location.state &&
        props.location.state.clonePODetail
      ) {
        let purchseOrderData = props.location.state.clonePODetail;
        let req_item_ids = purchseOrderData.items.map((item, key) => {
          if (item.req_allocation && item.req_allocation.length > 0) {
            return item.req_allocation.map((id) => id.req_item_id);
          }
        });
        let req_ids = req_item_ids.flat();

        let poItemRows = purchseOrderData.items.map((ir, k) => {
          return {
            ...this.initPOItemRow,
            item: { ...ir.item, isAddRemark: ir.remark ? true : false },
            id: ir.item.id,
            qty: ir.qty,
            remark: ir.remark,
            rate: ir.rate_vc ? ir.rate_vc : 0,
            gst: ir.gst,
            discount_type: ir.discount_type,
            discount_rate: ir.discount_rate_vc,
            req_allocation_loading: true,
            req_allocation: [],
            prev_req_allocation: [
              {
                req_item_id: ir.req_item_id,
                req_id: ir.requisition_transaction_id,
                qty: ir.pending_qty,
                item_id: ir.item.id,
              },
            ],
          };
        });
        let allReqItemsList = poItemRows.flatMap((poItemRow) => {
          // Extracting the req_allocation array from each poItemRow

          const reqAllocation = poItemRow.prev_req_allocation;

          // Mapping the req_item_id values from the req_allocation array
          const reqItemIds = reqAllocation.map((item) => {
            return { ...item, req_item_id: item.req_item_id };
          });

          // Returning an array of objects with req_item_id values
          return reqItemIds;
        });

        this.setState(
          {
            poItemRows: poItemRows,
            allReqItemLists:
              allReqItemsList && allReqItemsList.length > 0
                ? allReqItemsList
                : [],
            pOForm: {
              ...this.initPOForm,
              purchase_order_type: purchseOrderData.purchase_order_type
                ? purchseOrderData.purchase_order_type
                : "",
              transaction_date: moment(new Date(), "YYYY-MM-DD").format(
                "YYYY-MM-DD"
              ),
              transaction_id: "",
              warehouse_id: purchseOrderData.warehouse_id
                ? purchseOrderData.warehouse_id
                : "",
              vendor_id: purchseOrderData.vendor
                ? purchseOrderData.vendor.id
                : "",
              priority: purchseOrderData.priority
                ? purchseOrderData.priority
                : "",
              status: purchseOrderData.status ? purchseOrderData.status : 1,
              currency: purchseOrderData.currency
                ? purchseOrderData.currency
                : "",
              conversion_rate: purchseOrderData.conversion_rate
                ? purchseOrderData.conversion_rate
                : "",
              notes: purchseOrderData.notes ? purchseOrderData.notes : "",
              adjustment_amount: purchseOrderData.adjustment_amount_vc,
              tnc: purchseOrderData.tnc,
              quotation_name: purchseOrderData.quotation_name
                ? purchseOrderData.quotation_name
                : "",
              quotation_date: purchseOrderData.quotation_date
                ? purchseOrderData.quotation_date
                : "",
              gst_not_applicable: purchseOrderData.gst_not_applicable
                ? purchseOrderData.gst_not_applicable
                : "N",
              gst_as_applicable: purchseOrderData.gst_as_applicable
                ? purchseOrderData.gst_as_applicable
                : "N",
              tcs_rate: purchseOrderData.tcs_rate
                ? purchseOrderData.tcs_rate
                : "",
              tcs_type: purchseOrderData.tcs_type
                ? purchseOrderData.tcs_type
                : "Percentage",
              tcs_applicable:
                purchseOrderData.tcs_rate && purchseOrderData.tcs_rate > 0
                  ? "Y"
                  : "N",
            },
            searchedVendor: {
              value: purchseOrderData.vendor.id,
              display_label: purchseOrderData.vendor.name,
            },
            vendorDetails: {
              value: purchseOrderData.vendor.id,
              display_label: purchseOrderData.vendor.name,
            },
            selectedVendorAdress: purchseOrderData.vendor_address
              ? {
                ...purchseOrderData.vendor_address,
                gstin: purchseOrderData.vendor_gstin,
                id: purchseOrderData.vendor_address_id,
                country_id: purchseOrderData.vendor_address.country_id
                  ? purchseOrderData.vendor_address.country_id
                  : 99,
              }
              : null,
            poDetail: null,
            user_status: purchseOrderData.user_status,
            isc_enable_tcs:
              (purchseOrderData.tcs_rate && purchseOrderData.tcs_rate > 0) ||
                this.state.isc_enable_tcs == "Y"
                ? "Y"
                : "N",
          },
          () => {
            //get all Previous Allocation :-
            this.getSetMultipleReqPendingQuantity(
              purchseOrderData.warehouse_id,
              poItemRows
            );
            this.getWarehouseDetail(this.state.pOForm.warehouse_id);
            this.getVendorDetails(this.state.pOForm.vendor_id, true);
            let transactionIdSetting = this.state.allPOTypes
              ? this.state.allPOTypes.find(
                (pt) => pt.value == this.state.pOForm.purchase_order_type
              )
              : null;
            this.setState({
              transactionIdSetting: transactionIdSetting
                ? transactionIdSetting.type_data
                : null,
            });
          }
        );
      }
      else if (
        props.location &&
        props.location.state &&
        props.location.state.itemDemandPOData
      ) {
        let purchseOrderData = props.location.state.itemDemandPOData;
        let req_item_ids = purchseOrderData.demandData.items.map((item, key) => {
          if (item.req_allocation && item.req_allocation.length > 0) {
            return item.req_allocation.map((id) => id.req_item_id);
          }
        });
        let req_ids = req_item_ids.flat();

        let poItemRows = purchseOrderData.demandData.items.map((ir, k) => {
          return {
            ...this.initPOItemRow,
            item: { ...ir.item_profile, isAddRemark: ir.remark ? true : false },
            id: ir.id,
            qty: ir.qty,
            remark: ir.remark,
            rate: ir.rate_vc ? ir.rate_vc : 0,
            gst: ir.gst,
            discount_type: ir.discount_type,
            discount_rate: ir.discount_rate_vc,
            req_allocation_loading: true,
            req_allocation: [],
            prev_req_allocation: [
              {
                req_item_id: ir.req_item_id,
                req_id: ir.requisition_transaction_id,
                qty: ir.pending_qty,
                item_id: ir.id,
              },
            ],
          };
        });
        let allReqItemsList = poItemRows.flatMap((poItemRow) => {
          // Extracting the req_allocation array from each poItemRow

          const reqAllocation = poItemRow.prev_req_allocation;

          // Mapping the req_item_id values from the req_allocation array
          const reqItemIds = reqAllocation.map((item) => {
            return { ...item, req_item_id: item.req_item_id };
          });

          // Returning an array of objects with req_item_id values
          return reqItemIds;
        });

        this.setState(
          {
            poItemRows: poItemRows,
            allReqItemLists:
              allReqItemsList && allReqItemsList.length > 0
                ? allReqItemsList
                : [],
            pOForm: {
              ...this.initPOForm,
              purchase_order_type: purchseOrderData.purchase_order_type
                ? purchseOrderData.purchase_order_type
                : "",
              transaction_date: moment(new Date(), "YYYY-MM-DD").format(
                "YYYY-MM-DD"
              ),
              transaction_id: "",
              warehouse_id: purchseOrderData.warehouse_id
                ? purchseOrderData.warehouse_id
                : "",
              vendor_id: purchseOrderData.vendor
                ? purchseOrderData.vendor.id
                : "",
              priority: purchseOrderData.priority
                ? purchseOrderData.priority
                : "",
              status: purchseOrderData.status ? purchseOrderData.status : 1,
              currency: purchseOrderData.currency
                ? purchseOrderData.currency
                : "",
              conversion_rate: purchseOrderData.conversion_rate
                ? purchseOrderData.conversion_rate
                : "",
              notes: purchseOrderData.notes ? purchseOrderData.notes : "",
              adjustment_amount: purchseOrderData.adjustment_amount_vc,
              tnc: purchseOrderData.tnc,
              quotation_name: purchseOrderData.quotation_name
                ? purchseOrderData.quotation_name
                : "",
              quotation_date: purchseOrderData.quotation_date
                ? purchseOrderData.quotation_date
                : "",
              gst_not_applicable: purchseOrderData.gst_not_applicable
                ? purchseOrderData.gst_not_applicable
                : "N",
              gst_as_applicable: purchseOrderData.gst_as_applicable
                ? purchseOrderData.gst_as_applicable
                : "N",
              tcs_rate: purchseOrderData.tcs_rate
                ? purchseOrderData.tcs_rate
                : "",
              tcs_type: purchseOrderData.tcs_type
                ? purchseOrderData.tcs_type
                : "Percentage",
              tcs_applicable:
                purchseOrderData.tcs_rate && purchseOrderData.tcs_rate > 0
                  ? "Y"
                  : "N",
            },
            // searchedVendor: {
            //   value: purchseOrderData?.vendor.id,
            //   display_label: purchseOrderData?.vendor.name,
            // },
            // vendorDetails: {
            //   value: purchseOrderData?.vendor.id,
            //   display_label: purchseOrderData?.vendor.name,
            // },
            selectedVendorAdress: purchseOrderData.vendor_address
              ? {
                ...purchseOrderData.vendor_address,
                gstin: purchseOrderData.vendor_gstin,
                id: purchseOrderData.vendor_address_id,
                country_id: purchseOrderData.vendor_address.country_id
                  ? purchseOrderData.vendor_address.country_id
                  : 99,
              }
              : null,
            poDetail: null,
            user_status: purchseOrderData.user_status,
            isc_enable_tcs:
              (purchseOrderData.tcs_rate && purchseOrderData.tcs_rate > 0) ||
                this.state.isc_enable_tcs == "Y"
                ? "Y"
                : "N",
          },
          () => {
            //get all Previous Allocation :-
            this.getSetMultipleReqPendingQuantity(
              purchseOrderData.warehouse_id,
              poItemRows
            );
            this.getWarehouseDetail(this.state.pOForm.warehouse_id);
            this.getVendorDetails(this.state.pOForm.vendor_id, true);
            let transactionIdSetting = this.state.allPOTypes
              ? this.state.allPOTypes.find(
                (pt) => pt.value == this.state.pOForm.purchase_order_type
              )
              : null;
            this.setState({
              transactionIdSetting: transactionIdSetting
                ? transactionIdSetting.type_data
                : null,
            });
          }
        );
      }
    });
  };

  searchTNCHandle  = (selectedOption) => {

    if(selectedOption.length > 0) {
        const tags = selectedOption.map(tag => tag.value);
        const termCoditions = this.state.oldTNC;


        let tcData = termCoditions.filter(item => item.tags.some(tag => tags.includes(tag.id))).filter((tc, index, tcDataArr) => index === tcDataArr.findIndex(t => t.name === tc.name));

        this.setState({
            searchTNCByTags : tags,
            termCoditions : tcData
        });         
    }else {
        this.setState({
            searchTNCByTags : [],
            termCoditions : this.state.oldTNC
        });  
    }
    

}

  filterAllStatusOptions = () => {
    let allStatusOptions = this.state.allStatusOptions;
    if (this.state.user_status.length > 0) {
      allStatusOptions = allStatusOptions.filter((allStatus) =>
        this.state.user_status.find((ns) => ns.id == allStatus.value)
          ? true
          : false
      );
      this.setState({
        allStatusOptions: allStatusOptions,
      });
    }
  };

  getWarehouseDetail = (warehouse_id, edit = false) => {
    if (warehouse_id) {
      this.setState({
        warehouseDetailLoading: true,
        warehouseDetail: null,
        warehouseSites: [],
      });
      HttpAPICall.withAthorization(
        "GET",
        this.warehouseUrl + "/" + warehouse_id,
        this.props.access_token,
        {},
        {},
        (resp) => {
          let respData = resp.data;
          let sites =
            respData.data && respData.data.sites.length > 0
              ? respData.data.sites.map((st) => st.id)
              : [];
          this.setState({
            warehouseDetail: respData.data,
            selectedShippingAddress: edit
              ? this.state.selectedShippingAddress
              : null,
            warehouse_state_id:
              respData.data && respData.data.state_id
                ? respData.data.state_id
                : null,
            warehouseSites: respData.data.sites,
            allUsers:
              this.state.allUsers &&
                this.state.allUsers.length > 0 &&
                sites &&
                sites.length > 0
                ? this.state.allUsers.filter((us) =>
                  us.site_ids.some((value) => sites.includes(value))
                )
                : this.state.allUsers,
          });
        }
      ).then(() => this.setState({ warehouseDetailLoading: false }));
    } else {
      this.setState({ warehouseDetail: null, selectedShippingAddress: null });
      this.setState({
        gstTypeName:
          this.state.selectedVendorAdress &&
            this.state.warehouseDetail &&
            this.state.selectedVendorAdress.state_id ==
            this.state.warehouseDetail.state_id
            ? "GST"
            : "IGST",
      });
    }
  };

  getVendorDetails = (id, edit = false) => {
    if (id) {
      this.setState({ vendor_details_loading: true });
      HttpAPICall.withAthorization(
        "GET",
        this.vendorUrl + "/" + id,
        this.props.access_token,
        null,
        null,
        (response) => {
          let selectedVendorAdress =
            response.data.data && response.data.data.addressList
              ? response.data.data.addressList[0]
              : [];
          this.setState({
            vendorDetails: response.data.data,
            vendorAddressList: response.data.data.addressList,
            selectedVendorAdress:
              edit == false
                ? selectedVendorAdress
                : this.state.selectedVendorAdress,
          });
        }
      ).then(() => {
        this.setState({ vendor_details_loading: false });
        this.setState({
          gstTypeName:
            this.state.selectedVendorAdress &&
              this.state.warehouseDetail &&
              this.state.selectedVendorAdress.state_id ==
              this.state.warehouseDetail.state_id
              ? "GST"
              : "IGST",
        });
      });
    }
  };

  handleVendorChange = (selectedOption, edit = false) => {
    this.setState(
      {
        pOForm: {
          ...this.state.pOForm,
          vendor_id: selectedOption ? selectedOption.value : null,
          tnc: edit == true ? this.state.pOForm.tnc : [],
        },
        vendorDetails: selectedOption ? selectedOption : null,
        searchedVendorAdressList:
          selectedOption && selectedOption.vendor
            ? selectedOption.vendor.addressList
            : null,
        selectedVendorAdress:
          selectedOption && selectedOption.vendor && edit == false
            ? selectedOption.vendor.addressList[0]
            : this.state.selectedVendorAdress,
        searchedVendor: selectedOption ? selectedOption : null,
      },
      () => {
        if (edit == false) {
          this.setState({
            gst_as_applicable: "N",
            gst_not_applicable:
              this.state.selectedVendorAdress &&
                this.state.selectedVendorAdress.country_id !== 99
                ? "Y"
                : "N",
          });
        }
      }
    );
  };

  getTermListing = () => {
    this.setState({ termCoditionLoading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.purchaseOrderAddUrl + "/tnc",
      this.props.access_token,
      {},
      {},
      (response) => {
        this.setState({
          termCoditions: response.data.data,
          oldTNC                  :   response.data.data

        });
      }
    ).then(() => this.setState({ termCoditionLoading: false }));
  };

  changeAddressHandler = (id) => {
    if (id) {
      let selectedVendorAdress =
        this.state.vendorDetails && this.state.vendorDetails.addressList
          ? this.state.vendorDetails.addressList.find((a) => a.id == id)
          : null;
      this.setState({
        selectedVendorAdress: selectedVendorAdress,
      });
    }
  };

  pickTermsInit = () => {
    this.addTnCInPOModal.show();
    // this.getTermListing()
    this.setState({ pickTNCModalLoader: true });

    HttpAPICall.withAthorization('GET', this.TagUrlList, this.props.access_token, { type: 'po' }, {}, (resp) => {
      this.setState({
        tncTags: resp.data && resp.data.length > 0 ? resp.data.map(val => ({ label: val.name, value: val.id })) : []
      });
    })
      .then(() => this.setState({ pickTNCModalLoader: false }))
      .catch(() => this.setState({ pickTNCModalLoader: false }))
  };

  addTncInPO = (k) => {
    let selectedTnc = this.state.termCoditions[k];
    if (selectedTnc) {
      this.setState(
        {
          pOForm: {
            ...this.state.pOForm,
            tnc: [
              ...this.state.pOForm.tnc,
              { ...selectedTnc, id: "", editMode: false },
            ],
          },
        },
        () => { }
      );
    }
  };

  getTermListingofPreviousVendor = () => {
    if (this.state.pOForm && this.state.pOForm.vendor_id) {
      this.setState({ termCoditionLoading: true });
      let params = {
        vendor_id: this.state.pOForm.vendor_id,
        transaction_id:
          this.state.pOForm && this.state.pOForm.transaction_id
            ? this.state.pOForm.transaction_id
            : null,
      };
      HttpAPICall.withAthorization(
        "GET",
        this.poPreviousTnc,
        this.props.access_token,
        { ...params },
        {},
        (response) => {
          if (response.data && response.data.length > 0) {
            this.setState(
              {
                pOForm: {
                  ...this.state.pOForm,
                  tnc: response.data.map((s) => {
                    return {
                      name: s.name,
                      text: s.text,
                      id: "",
                      editMode: false,
                    };
                  }),
                },
              },
              () => { }
            );
          } else {
            toast.error("No T&C available for this vendor", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      ).then(() => this.setState({ termCoditionLoading: false }));
    } else {
      toast.error("Please select Vendor First", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  addTermsModalInit = () => {
    this.setState({ addTermForm: { ...this.initAddTermForm } });
    this.addTermsModal.show();
    if (this.state.tncTags.length <= 0) {
      this.setState({
        tncModalLoader: true
      });
      HttpAPICall.withAthorization('GET', this.TagUrlList, this.props.access_token, { type: 'po' }, {}, (resp) => {
        this.setState({
          tncTags: resp.data && resp.data.length > 0 ? resp.data.map(val => ({ label: val.name, value: val.id })) : []
        });
      }).then(() => {
        this.setState({ tncModalLoader: false })
      });
    }
  };

  convertCurrency = (selectedOption) => {
    let params = { to: this.state.defaultCurrency, from: selectedOption.value };
    this.setState({ currency_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.currency_convert_url + "/currency/convert",
      this.props.access_token,
      params,
      null,
      (response) => {
        let resp = response.data;
        // let updatedPoItemRows = [];
        // let poItemRows          =   this.state.poItemRows;
        // updatedPoItemRows       =   poItemRows.map((po,key) => {
        //     let item                =   po.item;
        //     let rate                =   item && item.purchase_price ? item.purchase_price : 0;
        //     if(item && item.purchase_currency !== this.state.pOForm.currency && item.purchase_price && resp.rate){
        //         rate        =   Number(item.purchase_price) / Number(resp.rate);
        //     }
        //         return({
        //             ...po,
        //             rate : parseFloat(rate).toFixed(2)
        //         })
        // })

        this.setState({
          pOForm: {
            ...this.state.pOForm,
            conversion_rate: resp.rate,
          },
          //poItemRows      :   updatedPoItemRows,
          editCurrency: false,
          allDiscountType: this.state.allDiscountType.map((s) => {
            return {
              value: s.value,
              label: s.value === "Fixed" ? selectedOption.value : "%",
            };
          }),
          allTCSType: this.state.allTCSType.map((s) => {
            return {
              value: s.value,
              label: s.value === "Fixed" ? selectedOption.value : "%",
            };
          }),
        });
      }
    ).then(() => this.setState({ currency_loading: false }));
  };

  addNewItemRow = () => {
    this.setState({
      poItemRows: [...this.state.poItemRows, { ...this.initPOItemRow }],
    });
  };

  submitAddTermForm = (e) => {
    e.preventDefault();
    let frmData = { ...this.state.addTermForm };
    this.setState({ addTermFormSubmitting: true });
    HttpAPICall.withAthorization(
      "POST",
      this.purchaseOrderAddUrl + "/tnc",
      this.props.access_token,
      {},
      { ...frmData },
      (response) => {
        this.getTermListing();
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.addTermsModal.hide();
      }
    ).then(() => this.setState({ addTermFormSubmitting: false }));
  };

  removeItemRow = (k, req_allocation_ids = []) => {
    let allRows = this.state.poItemRows;
    if (allRows.length > 1) {
      allRows.splice(k, 1);
      this.setState({ poItemRows: allRows });
    }

    if (
      req_allocation_ids &&
      req_allocation_ids.length > 0 &&
      this.state.allReqItemLists &&
      this.state.allReqItemLists.length > 0
    ) {
      const filteredIds = this.state.allReqItemLists.filter(
        (id) => !req_allocation_ids.includes(id.req_item_id)
      );
      this.setState({
        allReqItemLists: filteredIds,
      });
    }
  };


  onItemSelect = (selectedOption, key, qty = 1, edit = false) => {
    if (this.state.pOForm.warehouse_id) {
      if (selectedOption && selectedOption.itemdata) {
        let item = selectedOption.itemdata;
        let rate = item && item.purchase_price ? item.purchase_price : 0;

        if (
          this.state.poItemRows.find(
            (po_item) => po_item.item && po_item.item.id == item.id
          ) &&
          edit == false
        ) {
          toast.error(
            "This Item is already available in this PO / Requisition, please update the item",
            { position: toast.POSITION.TOP_RIGHT }
          );
        } else {
          if (
            item &&
            item.purchase_currency !== this.state.pOForm.currency &&
            item.purchase_price
          ) {
            let poItemRows = this.state.poItemRows;
            poItemRows[key] = {
              ...poItemRows[key],
              req_allocation_loading: true,
              item: { ...item, measuring_unit: item?.measuring_unit },
              qty: qty,
              rate: parseFloat(rate).toFixed(2),
              gst:
                item.gst && this.state.gst_as_applicable == "N" ? item.gst : 0,
            };
            this.setState(
              {
                poItemRows: poItemRows,
              },
              () => {
                HttpAPICall.withAthorization(
                  "GET",
                  this.currency_convert_url + "/currency/convert",
                  this.props.access_token,
                  {
                    from: item.purchase_currency,
                    to: this.state.pOForm.currency,
                  },
                  null,
                  (response) => {
                    let resp = response.data;
                    rate = Number(item.purchase_price) * Number(resp.rate);
                    let poItemRows = this.state.poItemRows;
                    poItemRows[key] = {
                      ...poItemRows[key],
                      req_allocation_loading: true,
                      item: { ...item, measuring_unit: item?.measuring_unit },
                      qty: qty,
                      rate: parseFloat(rate).toFixed(2),
                      gst:
                        item.gst && this.state.gst_as_applicable == "N"
                          ? item.gst
                          : 0,
                    };
                    this.setState({ poItemRows: poItemRows }, () => {
                      this.getPendingQuantity(item.id, key);
                      if (!this.state.poItemRows.find((ir) => !ir.item_profile)) {
                        setTimeout(() => {
                          this.addNewItemRow();
                        }, 1000);
                      }
                    });
                  }
                );
              }
            );
          } else {
            let poItemRows = this.state.poItemRows;
            poItemRows[key] = {
              ...poItemRows[key],
              item: { ...item, measuring_unit: item?.measuring_unit },
              qty: qty,
              rate: parseFloat(rate).toFixed(2),
              gst:
                item.gst && this.state.gst_as_applicable == "N" ? item.gst : 0,
            };
            this.setState({ poItemRows: poItemRows }, () => {
              this.getPendingQuantity(item.id, key);
              if (!this.state.poItemRows.find((ir) => !ir.item)) {
                setTimeout(() => {
                  this.addNewItemRow();
                }, 1000);
              }
            });
          }
        }
      }
    } else {
      toast.error("Please Select Warehouse First", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  itemViewModalInit = (item, k) => {
    if (item) {
      this.itemDetailsRef.current.modalInit(item);
      this.setState({ itemRowNumber: k })
    }

  }

  addRemark = (data, k) => {
    let allRows = this.state.poItemRows;
    let itemdata = { ...data, isAddRemark: true };
    allRows[k] = { ...this.state.poItemRows[k], item: itemdata };
    this.setState({ poItemRows: allRows });
  };

  addDueDate = (data, k) => {
    let allRows = this.state.poItemRows;
    let itemdata = { ...data, isAddDueDate: true };
    allRows[k] = { ...this.state.poItemRows[k], item: itemdata };
    this.setState({ poItemRows: allRows });
  };

  addItemModalInit = () => {
    this.addItemModalRef.current.itemAddModalInit();
  };

  editItemModalInit = (data, k) => {
    this.setState({ itemRowNumber: k, itemSearchedDetails: data })
    this.editItemModalRef.current.itemEditModalInit(data);
  };

  viewConsumptionModalInit = (data, k) => {
    if (data) {
      this.consumptionHistoryRef.current.modalInit(data);
    }
  };

  viewPOHistoryModalInit = (data, k) => {
    if (data) {
      this.poHistoryRef.current.modalInit(data, this.state.pOForm.warehouse_id);
    }
  };

  submitEditItem = () => {

    HttpAPICall.withAthorization(
      "GET",
      this.itemUrl + "/" + this.state.itemSearchedDetails.id,
      this.props.access_token,
      null,
      null,
      (response) => {

        let selectedItem = {
          label: (
            <Ax>
              <b>Item : </b> {response.data.data.name}{" "}
              <small> ({response.data.data.item_code})</small>
              <br />
              <small>
                <b>Manufacturer : </b>{" "}
                {response.data.data.manufacturer
                  ? response.data.data.manufacturer.name
                  : ""}
                <br />
                <b>MPN : </b> {response.data.data.manufacturer_part_no}
              </small>
            </Ax>
          ),
          value: response.data.data.id,
          display_label: `${response.data.data.name} (${response.data.data.item_code})`,
          itemdata: response.data.data,
        };
        this.setState(
          {
            itemSearchedDetails: response.data.data,
          },
          () => {
            this.onItemSelect(
              selectedItem,
              this.state.itemRowNumber,
              this.state.poItemRows[this.state.itemRowNumber]?.qty,
              true
            );
          }
        );
      }
    );
  };

  addPurchaseOrderFormSubmitEvent = (e) => {
    e.preventDefault();
  };

  saveFormSubmitHandler = (e, status = 2) => {
    e.preventDefault();
    let items = [...this.state.poItemRows];
    let itemsLists = items
      .filter((i) => (i.item ? true : false))
      .map((i, k) => {
        return {
          ...i,
          id: i.item.id,
          item: null,
          ordo: k,
        };
      });
    let poStatus = status ? status : this.state.pOForm.status;
    let additional_attributes = {};
    this.state.formData.additional_attributes.forEach((i, k) => {
      let name = "additional_attributes_" + i.key;
      additional_attributes[i.key] = this.state.pOForm[name]
        ? this.state.pOForm[name]
        : "";
    });
    //In case of edit :-
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.poData
    ) {
      poStatus = this.state.pOForm.status;
    }

    let pOForm = {
      ...this.state.pOForm,
      transaction_date: moment(
        this.state.pOForm.transaction_date,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD"),
      vendor_address_id: this.state.selectedVendorAdress
        ? this.state.selectedVendorAdress.id
        : "",
      adjustment_amount: Number(this.state.pOForm.adjustment_amount),
      status: poStatus,
      shipping_address_id: this.state.selectedShippingAddress
        ? this.state.selectedShippingAddress.id
        : null,
      gst_as_applicable: this.state.gst_as_applicable,
      gst_not_applicable: this.state.gst_not_applicable,
      additional_attributes: additional_attributes,
    };
    let formData = {
      ...pOForm,
      items: itemsLists,
      update_item_purchase_price: this.state.update_item_purchase_price,
      update_item_consumption_price: this.state.update_item_consumption_price,
    };
    let showErrors = [];
    if (formData.transaction_date == "") {
      showErrors = [...showErrors, "Please enter Transaction Date."];
    }
    if (formData.warehouse_id == "") {
      showErrors = [...showErrors, "Please select Warehouse."];
    }
    if (formData.vendor_id == "") {
      showErrors = [...showErrors, "Please select Vendor."];
    }
    if (formData.status == "") {
      showErrors = [...showErrors, "Please select Status."];
    }
    if (itemsLists.length == 0) {
      showErrors = [...showErrors, "Please select Item and their qty."];
    }
    if (formData.tcs_type == "Percentage" && formData.tcs_rate > 100) {
      showErrors = [...showErrors, "TCS Percentage cannot be greater than 100"];
    }
    itemsLists.forEach((item, key) => {
      if (Number(item.qty) <= 0) {
        showErrors = [...showErrors, "Quantity can not be Zero(0)."];
      }
    });
    let itemCombinationList = itemsLists
      .map((v) => {
        return [{ item_id: v.id }];
      })
      .flat();
    let foundDuplicateCombination = itemCombinationList.find((al, index) => {
      if (al.item_id) {
        return itemCombinationList.find(
          (x, ind) => al.item_id === x.item_id && index !== ind
        );
      } else {
        return null;
      }
    });
    if (showErrors.length > 0) {
      toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
    } else if (foundDuplicateCombination) {
      toast.error("PO cannot have dublicate Items", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      this.setState({ saveFormSubmitting: true });
      if (this.state.poDetail) {
        HttpAPICall.withAthorization(
          "PUT",
          this.purchaseOrderAddUrl,
          this.props.access_token,
          {},
          formData,
          (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.props.history.push({
              pathname: `/purchase_order_list`,
              state: { poId: this.state.poDetail.transaction_id },
            });
          }
        ).then(() => this.setState({ saveFormSubmitting: false }));
      } else {
        HttpAPICall.withAthorization(
          "POST",
          this.purchaseOrderAddUrl,
          this.props.access_token,
          {},
          formData,
          (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.props.history.push({
              pathname: `/purchase_order_list`,
              state: { poId: response.data.transaction_id },
            });
          }
        ).then(() => this.setState({ saveFormSubmitting: false }));
      }
    }
  };

  getPendingQuantity = (item_id, key) => {
    this.dynamicInputHandlerByKeyNameVal(
      true,
      "req_allocation_loading",
      key,
      "poItemRows",
      () => {
        this.dynamicInputHandlerByKeyNameVal(
          [],
          "req_allocation",
          key,
          "poItemRows"
        );
      }
    );
    let item_ids = [item_id];
    let params = { warehouse_id: this.state.pOForm.warehouse_id, item_ids };
    HttpAPICall.withAthorization(
      "GET",
      this.requisitionPendingQtyUrl,
      this.props.access_token,
      params,
      {},
      (resp) => {
        let itemReqData = resp.data.filter((rq) => item_id == rq.id);
        let req_allocation = itemReqData.map((ir) => {
          return {
            req_item_id: ir.req_item_id,
            req_id: ir.requisition.transaction_id,
            transaction_date: ir.requisition.transaction_date_display,
            qty: ir.pending_qty,
            new_qty: ir.pending_qty,
            max_qty: ir.pending_qty,
            ir: ir,
          };
        });

        let total_item_qty = Number(
          req_allocation.reduce((s, b) => s + Number(b.qty ? b.qty : 0), 0)
        );

        let additional_qty = 0;
        let poItemRows = this.state.poItemRows;
        poItemRows[key] = {
          ...poItemRows[key],
          req_allocation_loading: false,
          req_allocation: req_allocation,
          additional_qty: 0,
          allocated_qty: total_item_qty,
          qty:
            total_item_qty == 0
              ? this.state.poItemRows[key]?.qty
              : total_item_qty + 0,
        };
        this.setState({ poItemRows: poItemRows });
      }
    ).then(() => { });
  };

  getSetMultipleReqPendingQuantity = (warehouse_id, poItemRows) => {
    this.setState({ buttonDisabled: true });
    let item_ids = [];
    let req_item_ids = [];
    poItemRows.forEach((po_item, key) => {
      item_ids.push(po_item.id);
      if (
        po_item.prev_req_allocation &&
        po_item.prev_req_allocation.length > 0
      ) {
        po_item.prev_req_allocation.forEach((po_item_allocation, pok) => {
          req_item_ids.push(po_item_allocation.req_item_id);
        });
      }
    });
    let params = { warehouse_id: warehouse_id, item_ids, req_item_ids };
    HttpAPICall.withAthorization(
      "GET",
      this.requisitionPendingQtyUrl,
      this.props.access_token,
      params,
      {},
      (resp) => {
        poItemRows.forEach((poItemRow, key) => {
          let itemReqData = resp.data.filter((rq) => poItemRow.id == rq.id);
          let prev_req_allocation = poItemRow.prev_req_allocation;
          let req_allocation = itemReqData.map((ir) => {
            let prev_req_item_data = prev_req_allocation
              ? prev_req_allocation.find(
                (pra) => pra.req_item_id == ir.req_item_id
              )
              : null;
            let qty = prev_req_item_data ? prev_req_item_data.qty : 0;
            return {
              req_item_id: ir.req_item_id,
              req_id: ir.requisition.transaction_id,
              transaction_date: ir.requisition.transaction_date_display,
              ir: ir,
              qty: qty,
              new_qty: qty,
              max_qty: Number(ir.pending_qty) + Number(qty),
            };
          });
          let total_item_qty = Number(
            req_allocation.reduce((s, b) => s + Number(b.qty ? b.qty : 0), 0)
          );
          let additional_qty = poItemRow.additional_qty;
          poItemRow = {
            ...poItemRow,
            req_allocation_loading: false,
            req_allocation: req_allocation,
            allocated_qty: total_item_qty,
            allApiCallsCompleted: true,
          };
          poItemRows[key] = poItemRow;
        });
        this.setState({ poItemRows: poItemRows }, () => {
          this.handleButtonEnable();
        });
      }
    ).then(() => { });
  };

  handleButtonEnable = () => {
    const allApiCallsCompleted = this.state.poItemRows
      .filter((i) => (i.item ? true : false))
      .every((item) => item.allApiCallsCompleted);
    this.setState({ buttonDisabled: !allApiCallsCompleted });
  };

  requistionDetailInit = (key) => {
    let poItemRows = this.state.poItemRows;
    let poItemRow = poItemRows[key];

    if (poItemRow) {
      let req_allocation = poItemRow.req_allocation.map(
        (item_req_allocation) => {
          item_req_allocation.new_qty = item_req_allocation.qty;
          return item_req_allocation;
        }
      );
      poItemRow.additional_qty = poItemRow.qty - poItemRow.allocated_qty;
      poItemRows[key] = poItemRow;
      this.setState({ poItemRows: poItemRows, poItemRowKey: key }, () => {
        this.reqDetailModal.show();
      });
    }
  };

  submitAllocatedItem = (e) => {
    e.preventDefault();
    let poItemRows = this.state.poItemRows;
    let poItemRow = poItemRows[this.state.poItemRowKey];
    if (poItemRow) {
      let req_allocation = poItemRow.req_allocation.map((a, i) => {
        a.qty = a.new_qty;
        return a;
      });
      poItemRow.allocated_qty = Number(
        req_allocation.reduce((s, b) => s + Number(b.qty ? b.qty : 0), 0)
      );

      poItemRow.qty =
        Number(poItemRow.additional_qty ? poItemRow.additional_qty : 0) +
        Number(poItemRow.allocated_qty);

      poItemRows[this.state.poItemRowKey] = poItemRow;
      this.setState({ poItemRows: poItemRows }, () => {
        this.reqDetailModal.hide();
      });
    }
  };

  itemSearchModalInit = () => {
    let allRows = this.state.poItemRows;
    let item_ids = [];
    if (allRows && allRows.length > 0) {
      item_ids = allRows
        .filter((i) => (i.item ? true : false))
        .map((item) => {
          return item?.item?.id;
        });
    }
    this.itemSearchRef.current.searchItemModalInit(item_ids);
  };

  addItemsfromSearch = (selectedItems) => {
    let poItemRows = selectedItems.map((po_item, k) => {
      let allocated_qty = 0;
      return {
        ...this.initPOItemRow,
        item: { ...po_item.item, isAddRemark: po_item.remark ? true : false },
        req_allocation_loading: true,
        req_allocation: [],
        prev_req_allocation: [],
        id: po_item.item.id,
        qty: po_item.qty,
        allocated_qty: allocated_qty,
        additional_qty: 0,
        rate: po_item.rate,
        remark: po_item.remark,
        gst: po_item.gst,
        discount_type: "",
        discount_rate: "",
      };
    });
    let allRows = this.state.poItemRows
      ? this.state.poItemRows.filter((i) => (i.item ? true : false))
      : [];
    this.setState(
      {
        poItemRows: allRows.concat(poItemRows),
      },
      () => {
        //get all Previous Allocation :-
        this.getSetMultipleReqPendingQuantity(
          this.state.pOForm.warehouse_id,
          this.state.poItemRows
        );
      }
    );
  };

  reqDetailModalJsx = () => {
    let poItemRow = this.state.poItemRows[this.state.poItemRowKey];
    let allocated_qty =
      poItemRow &&
        poItemRow.req_allocation &&
        Array.isArray(poItemRow.req_allocation)
        ? Number(
          poItemRow.req_allocation.reduce(
            (s, b) => s + Number(b.new_qty ? b.new_qty : 0),
            0
          )
        )
        : 0;
    let total_quantity = poItemRow
      ? Number(poItemRow.additional_qty ? poItemRow.additional_qty : 0) +
      Number(allocated_qty)
      : Number(allocated_qty);
    return (
      <div className="modal fade" id="reqDetailModal" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="reqDetailModalLabel">
                Requisition Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="tab_content_wrapper">
                <span className="content_heading">Item Detail</span>
              </div>
              <table className="table table-hover table-borderless my-2">
                <tbody>
                  <tr>
                    <td style={{ width: "25%" }}>Item Name</td>
                    <td>
                      {poItemRow && poItemRow.item ? poItemRow.item.name : ""}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>Item Name</td>
                    <td>
                      {poItemRow && poItemRow.item
                        ? poItemRow.item.item_code
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Warehouse</td>
                    <td>
                      {this.state.warehouseDetail &&
                        this.state.warehouseDetail.name
                        ? this.state.warehouseDetail.name
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <form
                className="bg-white p-3"
                onSubmit={this.submitAllocatedItem}
                id="allocatedItemForm"
              >
                <table className="table table-bordered table-responsive bg-white my-2">
                  <thead className="table-secondary">
                    <tr className="text-center">
                      <th scope="col" style={{ width: "6%" }}>
                        S.No
                      </th>
                      <th scope="col" style={{ width: "18%" }}>
                        Req ID
                      </th>
                      <th scope="col" style={{ width: "18%" }}>
                        Req Date
                      </th>
                      <th scope="col" style={{ width: "18%" }}>
                        Req quantity
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Pending for PO
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Allocated Quantity{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {poItemRow && poItemRow.req_allocation.length > 0
                      ? poItemRow.req_allocation.map((a, i) => {
                        return (
                          <tr className="text-center" key={a.id + i}>
                            <td>{i + 1}</td>
                            <td>{a.req_id} </td>
                            <td>{a.transaction_date}</td>
                            <td>{a.ir.qty}</td>
                            <td>{parseFloat(a.ir.pending_qty).toFixed(2)}</td>
                            <td>
                              <TapInputNumber
                                className="form-control form-control-sm text-end"
                                placeholder="Allocated Quantity"
                                name="new_qty"
                                value={a.new_qty}
                                onChange={(newValue) => {
                                  poItemRow.req_allocation[i] = {
                                    ...a,
                                    new_qty: newValue,
                                  };
                                  this.dynamicInputHandlerByKeyNameVal(
                                    poItemRow.req_allocation,
                                    "req_allocation",
                                    this.state.poItemRowKey,
                                    "poItemRows"
                                  );
                                }}
                                max={a.max_qty ? a.max_qty : a.ir.pending_qty}
                                min={0}
                              />
                            </td>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </table>
                <table className="table table-borderless table-responsive bg-white my-2">
                  <tbody>
                    <tr>
                      <td style={{ width: "80%" }} className="text-end">
                        Additional Quantity without Requisition :{" "}
                      </td>
                      <td className="texts-start" style={{ width: "20%" }}>
                        <TapInputNumber
                          className="form-control form-control-sm text-end"
                          placeholder="Additonal Quantity"
                          name="additional_qty"
                          value={poItemRow ? poItemRow.additional_qty : 0}
                          onChange={(newValue) =>
                            this.dynamicInputHandlerByKeyNameVal(
                              newValue,
                              "additional_qty",
                              this.state.poItemRowKey,
                              "poItemRows"
                            )
                          }
                          min={0}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Total Quantity : </td>
                      <td className="text-end">{total_quantity}</td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary mx-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                form="allocatedItemForm"
              >
                Save & Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  goToListing = () => this.props.history.goBack();

  addWarehouseAddressInit = () => {
    this.addWarehouseAddressRef.current.addAddressModalInit(
      this.state.pOForm.warehouse_id
    );
  };

  changeShipAddressInit = () => {
    this.warehouseAddressModal.show();
    this.getWarehouseAddressList(this.state.pOForm.warehouse_id);
  };

  getWarehouseAddressList = (id, page = 1) => {
    this.setState({ address_loading: true });
    const params = {
      search_by_keywords: this.state.searchWarehouseAddress,
      page: page,
      per_page: 50,
    };
    HttpAPICall.withAthorization(
      "GET",
      this.warehouseShipAddUrl + "/" + id,
      this.props.access_token,
      params
    )
      .then((response) => {
        let respData = response.data;

        this.setState({
          warehouseAddressList: respData.data,
          warehouseListingMeta: respData.meta,
          totalAddressCount: respData.meta.total,
        });
      })
      .then(() => {
        this.setState({ address_loading: false });
      });
  };

  changeWarehouseAddressHandler = (id) => {
    if (id) {
      let selectedAdress =
        this.state.warehouseAddressList &&
          this.state.warehouseAddressList.length > 0
          ? this.state.warehouseAddressList.find((a) => a.id == id)
          : null;
      this.setState({
        selectedShippingAddress: selectedAdress,
      });
    } else {
      this.setState({
        selectedShippingAddress: null,
      });
    }
  };

  pickReqItemModalInit = () => {
    this.pickReqModal.show();
    if (!this.state.listingLoaded) {
      this.getApprovedRequisitionList();
    }
  };

  getApprovedRequisitionList = (page = 1) => {
    this.setState({ approved_requisition_listing: true });
    let params = {
      search_warehouse_ids: [this.state.pOForm.warehouse_id],
      search_status_ids: [3, 4],
      min_pending_qty: 0.01,
      page: page,
    };
    HttpAPICall.withAthorization(
      "GET",
      this.requisitionReportUrl,
      this.props.access_token,
      { ...params },
      {},
      (response) => {
        let respData = response.data;
        let data = respData.data.map((rd) => {
          return { ...rd, new_qty: parseFloat(rd.pending_qty).toFixed(2) };
        });
        this.setState({
          requistion_listing: [...this.state.requistion_listing, ...data],
          reqListingMeta: {
            from: respData.from,
            to: respData.to,
            total: respData.total,
            last_page: respData.last_page,
            current_page: respData.current_page,
          },
          listingLoaded: true,
          totalRequisitionListingCount: respData.total,
        });
      }
    ).then(() => this.setState({ approved_requisition_listing: false }));
  };

  checkAllReqItemList = () => {
    let reqItemCheckboxCollections =
      document.getElementsByClassName("req_id_checkbox");
    if (reqItemCheckboxCollections && reqItemCheckboxCollections.length > 0) {
      for (let i = 0; i < reqItemCheckboxCollections.length; i++) {
        reqItemCheckboxCollections[i].checked =
          document.getElementById("all_check_req").checked;
      }
    }
  };

  submitPickReqFormSubmit = (e) => {
    e.preventDefault();

    let reqItemCheckboxCollections =
      document.getElementsByClassName("req_id_checkbox");
    let allSelectedItems = [];
    let allQty = [];

    if (reqItemCheckboxCollections && reqItemCheckboxCollections.length > 0) {
      for (let i = 0; i < reqItemCheckboxCollections.length; i++) {
        if (reqItemCheckboxCollections[i].checked) {
          // reqItemCheckboxCollections[i].disabled = true;
          let req_item_id = reqItemCheckboxCollections[i].value;
          let selectedReqItem = this.state.requistion_listing.find(
            (po, o) => po.req_item_id == req_item_id
          );
          allQty.push(reqItemCheckboxCollections[i].getAttribute("data-qty"));
          allSelectedItems.push(selectedReqItem);
        }
      }
    }

    if (allSelectedItems.length == 0) {
      toast.error("Please select atleast one Item", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let checkBlankQty =
        allQty.filter(function (item) {
          return item == "" || item == null;
        }).length == 0;
      if (checkBlankQty == false) {
        toast.error(
          "Please fill the Qty and Pending Qty should be more than 0",
          { position: toast.POSITION.TOP_RIGHT }
        );
      } else {
        this.handleAddSelectedReqItems(allSelectedItems);
      }
    }
  };

  handleAddSelectedReqItems = (allItems) => {
    this.setState({ savePickItemSubmitting: true });
    let params = { req_item_ids: allItems.map((it) => it.req_item_id) };

    HttpAPICall.withAthorization(
      "GET",
      this.requisitionItemDetailUrl,
      this.props.access_token,
      params,
      null,
      (response) => {
        let reqItems = response.data;
        let poItemRows = [...this.state.poItemRows];

        reqItems.forEach((ir) => {
          let itemFromAllItems = allItems.find(
            (it) => it.req_item_id === ir.req_item_id
          );

          // Find existing item with the same item_id
          let existingItem = poItemRows.find(
            (item) => item.item && item.item.id === ir.item.id
          );

          if (existingItem) {
            let additional_qty =
              existingItem && existingItem.additional_qty
                ? existingItem.additional_qty
                : 0;

            // Find the index of the existing allocation with matching req_item_id
            let existingAllocationIndex =
              existingItem.prev_req_allocation.findIndex(
                (allocation) => allocation.req_item_id === ir.req_item_id
              );
            let existingAllocationItem = existingItem.req_allocation.find(
              (allocation) => allocation.req_item_id === ir.req_item_id
            );
            //let totalQty = Number(existingItem.allocated_qty) + Number(additional_qty);

            // if (existingAllocationIndex !== -1) {
            if (existingAllocationIndex !== -1) {
              // If the allocation with matching req_item_id is found, update its qty
              existingItem.prev_req_allocation[existingAllocationIndex].qty =
                Number(existingAllocationItem.new_qty);
              // existingItem.prev_req_allocation[existingAllocationIndex].item_id = ir.item.id;
            } else {
              // If the allocation with matching req_item_id is not found, add a new entry
              existingItem.prev_req_allocation.push({
                req_item_id: ir.req_item_id,
                req_id: ir.requisition_transaction_id,
                qty: itemFromAllItems.new_qty,
                item_id: ir.item.id,
                //qty: totalQty,
              });
              let totalQtyFromAllocations =
                existingItem.prev_req_allocation.reduce((acc, allocation) => {
                  return acc + Number(allocation.qty);
                }, 0);

              let newTotalQty =
                totalQtyFromAllocations + Number(additional_qty);

              // Update existingItem.qty with the new total quantity
              existingItem.qty = newTotalQty;
            }
          } else {
            // The item doesn't exist in poItemRows, create a new item object
            let newItem = {
              ...this.initPOItemRow,
              item: ir.item,
              id: ir.item.id,
              qty: itemFromAllItems.new_qty,
              gst: ir.item.gst ? ir.item.gst : 0,
              rate: ir.item.purchase_price ? ir.item.purchase_price : 0,
              req_allocation_loading: true,
              req_allocation: [],
              prev_req_allocation: [
                {
                  req_item_id: ir.req_item_id,
                  req_id: ir.requisition_transaction_id,
                  qty: itemFromAllItems.new_qty,
                  item_id: ir.item.id,
                },
              ],
            };

            poItemRows.push(newItem);
          }
        });

        // Remove items with { item: null }
        poItemRows = poItemRows.filter((item) => item.item !== null);

        this.setState(
          {
            poItemRows: poItemRows,
            allReqItemLists: allItems.map((it) => ({
              ...it,
              req_item_id: it.req_item_id,
            })),
          },
          () => {
            //get all Previous Allocation :-
            this.getSetMultipleReqPendingQuantity(
              this.state.pOForm.warehouse_id,
              poItemRows
            );
            this.getWarehouseDetail(this.state.pOForm.warehouse_id);
            this.pickReqModal.hide();
          }
        );
      }
    ).then(() => {
      this.setState({ savePickItemSubmitting: false });
    });
  };

  loadMoreList = () => {
    if (
      this.state.reqListingMeta &&
      this.state.reqListingMeta.current_page <
      this.state.reqListingMeta.last_page
    ) {
      // this.setState({listingLoaded : false},() => {
      this.getApprovedRequisitionList(
        this.state.reqListingMeta.current_page + 1
      );
      //})
    }
  };

  pageTitleJsx = () => {
    return (
      <div className="page_title row m0">
        <div className="col-12">
          <h3>
            {this.state.poDetail ? "Edit" : "New Purchase Order"}{" "}
            {this.state.po_title}
          </h3>
          <div className="text-end mt15">
            <button
              onClick={this.goToListing}
              type="button"
              className="btn btn-secondary"
            >
              <tapIcon.imageIcon
                icon={tapIcon.CloseIcon}
                className="img-fluid"
                alt="Close PO"
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  termConditionJsx = () => {
    return (
      <div className="row align-items-center my-3">
        <div className="col-12">
          <div className="tab_content_wrapper">
            <span className="content_heading">Terms & Condititon </span>
            <div className="float-end">
              <a
                role="button"
                className="link-primary"
                onClick={this.addTermsModalInit}
              >
                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add New{" "}
              </a>
              <a
                role="button"
                className="link-primary cursor_pointer mx-2"
                onClick={this.pickTermsInit}
              >
                + Pick Terms & Condititon{" "}
              </a>
            </div>
          </div>
          <table className="table table-bordered table-responsive bg-white table-sm my-3">
            <thead className="table-secondary">
              <tr>
                <th scope="col" className="text-center" style={{ width: "8%" }}>
                  S.No
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  Name
                </th>
                <th scope="col" style={{ width: "62%" }}>
                  Description
                </th>
                <th
                  scope="col"
                  className="text-center"
                  style={{ width: "10%" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.termCoditionLoading ? (
                <tr>
                  <td className="text-center" colSpan="3">
                    <Loader />
                  </td>
                </tr>
              ) : this.state.pOForm?.tnc?.length == 0 ? (
                <tr>
                  <td
                    className="text-center link-primary cursor_pointer"
                    colSpan="4"
                    onClick={this.getTermListingofPreviousVendor}
                  >
                    + Pick T&C from previous PO of selected Vendor
                  </td>
                </tr>
              ) : (
                this.state.pOForm?.tnc?.map((po_tnc, k) => {
                  return (
                    <tr key={k}>
                      <td>{k + 1}</td>
                      <td>
                        {po_tnc.editMode ? (
                          <input
                            name="text"
                            type="text"
                            value={po_tnc.name}
                            onChange={(e) =>
                              this.dynamicInputHandlerTwoLevelByKeyNameVal(
                                e.target.value,
                                "name",
                                k,
                                "tnc",
                                "pOForm"
                              )
                            }
                            className="form-control form-control-sm"
                            autoComplete="off"
                            placeholder="Notes"
                          />
                        ) : (
                          po_tnc.name
                        )}
                      </td>
                      <td>
                        {po_tnc.editMode ? (
                          <input
                            name="text"
                            type="text"
                            value={po_tnc.text}
                            onChange={(e) =>
                              this.dynamicInputHandlerTwoLevelByKeyNameVal(
                                e.target.value,
                                "text",
                                k,
                                "tnc",
                                "pOForm"
                              )
                            }
                            className="form-control form-control-sm"
                            autoComplete="off"
                            placeholder="Notes"
                          />
                        ) : (
                          po_tnc.text
                        )}
                      </td>
                      <td className="text-center">
                        {po_tnc.editMode ? (
                          <button
                            className="btn btn-light"
                            onClick={() =>
                              this.dynamicInputHandlerTwoLevelByKeyNameVal(
                                false,
                                "editMode",
                                k,
                                "tnc",
                                "pOForm"
                              )
                            }
                          >
                            <tapIcon.FontAwesomeIcon
                              icon={tapIcon.faCheck}
                              color="#45BEA9"
                            />
                          </button>
                        ) : (
                          <Ax>
                            <button
                              type="button"
                              id="actionMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              className="btn"
                            >
                              <tapIcon.FontAwesomeIcon
                                icon={tapIcon.faEllipsisV}
                              />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() =>
                                    this.dynamicInputHandlerTwoLevelByKeyNameVal(
                                      true,
                                      "editMode",
                                      k,
                                      "tnc",
                                      "pOForm"
                                    )
                                  }
                                >
                                  Edit
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() =>
                                    this.dynamicInputDeleteTwoLevelByKeyNameVal(
                                      k,
                                      "tnc",
                                      "pOForm"
                                    )
                                  }
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </Ax>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  purchaseOrderItemJsx = () => {
    let sub_total_amount = 0;
    let gst_total_amount = 0;
    let gst_slab = [];
    let total_amount = 0;
    let purchase_summary = [];
    let adjustment_amount = Number(this.state.pOForm.adjustment_amount);

    this.state.poItemRows.forEach((item, key) => {
      if (item && item.item) {
        let item_amount = Number(item.qty * item.rate);
        let discount_amout = Number(
          item.discount_rate && item.discount_rate > 0
            ? item.discount_type == "Percentage"
              ? (item_amount * item.discount_rate) / 100
              : item.discount_rate
            : 0
        );
        let item_with_discount = item_amount - discount_amout;
        let tax_amount = 0;

        if (
          item.gst &&
          item.gst > 0 &&
          this.state.selectedVendorAdress &&
          this.state.selectedVendorAdress.gstin
        ) {
          tax_amount = (item_with_discount * item.gst) / 100;
          if (!gst_slab[item.gst]) {
            gst_slab[item.gst] = [];
          }
          gst_slab[item.gst].push(tax_amount);
        }
        let item_final_amount = item_with_discount + tax_amount;
        sub_total_amount += item_with_discount;
        gst_total_amount += tax_amount;
        total_amount += item_final_amount;
      }
    });

    purchase_summary.push({
      key: "Sub Total",
      value: sub_total_amount.toFixed(2),
    });

    if (gst_total_amount > 0) {
      gst_slab.forEach((tax_amounts, gst) => {
        let total_tax = tax_amounts.reduce((sum, a) => sum + a, 0);
        if (
          this.state.selectedVendorAdress &&
          this.state.warehouseDetail &&
          this.state.selectedVendorAdress.state_id ==
          this.state.warehouseDetail.state_id
        ) {
          let tax = gst / 2;
          purchase_summary.push({
            key: "CGST " + tax + "%",
            value: (total_tax / 2).toFixed(2),
          });
          purchase_summary.push({
            key: "SGST " + tax + "%",
            value: (total_tax / 2).toFixed(2),
          });
        } else {
          purchase_summary.push({
            key: "IGST " + gst + "%",
            value: total_tax.toFixed(2),
          });
        }
      });
    } else if (gst_total_amount == 0 && this.state.gst_as_applicable == "Y") {
      if (
        this.state.selectedVendorAdress &&
        this.state.warehouseDetail &&
        this.state.selectedVendorAdress.state_id ==
        this.state.warehouseDetail.state_id
      ) {
        purchase_summary.push({ key: "CGST ", value: "As applicable" });
        purchase_summary.push({ key: "SGST ", value: "As applicable" });
      } else {
        purchase_summary.push({ key: "IGST ", value: "As applicable" });
      }
    }

    let tcs_amount = Number(
      this.state.pOForm.tcs_rate && this.state.pOForm.tcs_rate >= 0
        ? this.state.pOForm.tcs_type == "Percentage"
          ? (sub_total_amount * this.state.pOForm.tcs_rate) / 100
          : Number(this.state.pOForm.tcs_rate)
        : 0
    );

    return (
      <Ax>
        <div className="my-3 row">
          <div className="col-sm-12">
            <table className="table table-bordered table-responsive bg-white ">
              <thead className="table-secondary">
                <tr>
                  <th scope="col" style={{ width: "5%" }}>
                    S.No
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ width: "30%" }}
                  >
                    Item Details
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ width: "12%" }}
                  >
                    Requisition
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ width: "8%" }}
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ width: "12%" }}
                  >
                    Unit Rate
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ width: "8%" }}
                  >
                    Discount
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ width: "8%" }}
                  >
                    GST
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ width: "12%" }}
                  >
                    Amount
                  </th>
                  <th scope="col" className="" style={{ width: "5%" }}></th>
                </tr>
              </thead>
              <tbody>
                {this.state.poItemRows.map((itemRow, itemKey) => {
                  let item = itemRow.item;
                  let req_allocation_ids = [];
                  if (
                    itemRow &&
                    itemRow.req_allocation &&
                    itemRow.req_allocation.length > 0
                  ) {
                    req_allocation_ids = itemRow.req_allocation.map(
                      (rq) => rq.req_item_id
                    );
                  }

                  let item_with_discount = 0;
                  if (item) {
                    let item_amount = Number(itemRow.qty * itemRow.rate);
                    let discount_amout = Number(
                      itemRow.discount_rate && itemRow.discount_rate > 0
                        ? itemRow.discount_type == "Percentage"
                          ? (item_amount * itemRow.discount_rate) / 100
                          : itemRow.discount_rate
                        : 0
                    );
                    item_with_discount = item_amount - discount_amout;
                  }
                  return (
                    <tr className="" key={itemKey}>
                      <td className="text-center align-middle">
                        {itemKey + 1}
                      </td>
                      {item ? (
                        <Ax>
                          <td>
                            <div className="row">
                              <div className="col-sm-10">
                                <span
                                  className="fw-bold link-primary cursor_pointer"
                                  onClick={() => {
                                    this.itemViewModalInit(item);
                                    this.viewItemModalInit();
                                  }}
                                >
                                  {item.name}
                                </span>
                                <div className="form-text">
                                  Code: <b>{item.item_code}</b>
                                </div>
                                <div className="form-text">
                                  Mfr:{" "}
                                  <b>
                                    {item && item.manufacturer
                                      ? item.manufacturer.name
                                      : "-"}
                                  </b>
                                  (MPN:{" "}
                                  <b>
                                    {item && item.manufacturer_part_no
                                      ? item.manufacturer_part_no
                                      : "-"}
                                  </b>{" "}
                                  )
                                </div>
                              </div>
                              <div className="col-sm-2 text-end">
                                <tapIcon.imageIcon
                                  icon={tapIcon.ListActionIcon}
                                  alt="action-icon"
                                  className="img-fluid pr10 pl10"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-expanded="false"
                                />
                                <ul className="dropdown-menu cursor_pointer">
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      this.addRemark(itemRow.item, itemKey);
                                    }}
                                  >
                                    Add Remark
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      this.addDueDate(itemRow.item, itemKey);
                                    }}
                                  >
                                    Add Due Date
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      this.itemViewModalInit(item);
                                      this.viewItemModalInit();
                                    }}
                                  >
                                    View Details
                                  </li>
                                  {this.props?.permissions?.permissions?.includes(
                                    "isc-item-edit"
                                  ) && (
                                      <li
                                        className="dropdown-item"
                                        onClick={() => {
                                          //this.itemViewModalInit(item, itemKey);
                                          this.editItemModalInit(item, itemKey);
                                        }}
                                      >
                                        Edit Item
                                      </li>
                                    )}

                                  <li>
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                      onClick={() =>
                                        this.viewConsumptionModalInit(
                                          itemRow.item,
                                          itemKey
                                        )
                                      }
                                    >
                                      Consumption History
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="dropdown-item"
                                      onClick={() =>
                                        this.viewPOHistoryModalInit(
                                          itemRow.item,
                                          itemKey
                                        )
                                      }
                                    >
                                      PO History
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {item &&
                              (
                                <div className="my-1">
                                  <textarea
                                    name="remark"
                                    value={this.state.poItemRows[itemKey].remark}
                                    onChange={(e) =>
                                      this.dynamicInputHandlerByKeyNameVal(
                                        e.target.value,
                                        "remark",
                                        itemKey,
                                        "poItemRows"
                                      )
                                    }
                                    className="form-control mt10"
                                    placeholder="Item Remarks"
                                    rows="1"
                                  />
                                </div>
                              )}
                            {item &&
                              item.isAddDueDate &&
                              item.isAddDueDate == true ? (
                              <div className="my-1">
                                <DatePicker
                                  selected={
                                    this.state.poItemRows[itemKey].due_date
                                      ? moment(
                                        this.state.poItemRows[itemKey]
                                          .due_date,
                                        "YYYY-MM-DD"
                                      ).toDate()
                                      : false
                                  }
                                  name="due_date"
                                  onChange={(value) =>
                                    this.dynamicFormDateHandler(
                                      value,
                                      "due_date",
                                      itemKey,
                                      "poItemRows"
                                    )
                                  }
                                  dateFormat="dd-MMM-yyyy"
                                  className="form-control"
                                  showMonthDropdown
                                  showYearDropdown
                                  autoComplete="off"
                                  scrollMonthYearDropdown
                                  placeholderText="Please Enter Item Due Date"
                                />{" "}
                              </div>
                            ) : null}
                          </td>
                          {this.state.poItemRows[itemKey]
                            .req_allocation_loading ? (
                            <td colSpan="6">
                              <Loader />
                            </td>
                          ) : (
                            <Ax>
                              <td className="text-center">
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.requistionDetailInit(itemKey)
                                  }
                                  className="btn btn-link text-decoration-none"
                                  disabled={
                                    this.state.poItemRows[itemKey]
                                      ?.req_allocation?.length == 0
                                  }
                                >
                                  + Allocate
                                </button>
                                {this.state.poItemRows[
                                  itemKey
                                ].req_allocation.map((rl, k) => {
                                  return rl.qty > 0 ? (
                                    <div key={k} className="text-center my-1">
                                      {rl.req_id} : {rl.qty}
                                    </div>
                                  ) : null;
                                })}

                                {this.state.poItemRows[itemKey]?.req_allocation
                                  .length > 0 &&
                                  this.state.poItemRows[itemKey]?.additional_qty >
                                  0 ? (
                                  <div className="text-center my-1">
                                    ADDL. :{" "}
                                    {
                                      this.state?.poItemRows[itemKey]
                                        ?.additional_qty
                                    }
                                  </div>
                                ) : null}
                              </td>
                              <td>
                                <TapInputNumber
                                  name="qty"
                                  value={this.state.poItemRows[itemKey].qty}
                                  onChange={(newValue) => {
                                    const updatedRows = [
                                      ...this.state.poItemRows,
                                    ];
                                    updatedRows[itemKey].qty = newValue; // Update the quantity in the corresponding row
                                    this.setState({ poItemRows: updatedRows });
                                  }}
                                  placeholder="Qty"
                                  required={true}
                                  disabled={
                                    this.state.poItemRows[itemKey]
                                      .req_allocation.length > 0
                                      ? true
                                      : false
                                  }
                                />
                                <span className="text-helping input-group-text text-end pr0">
                                  {item.measuring_unit && item.measuring_unit.name}
                                </span>
                              </td>
                              <td>
                                <TapInputNumber
                                  name="rate"
                                  value={this.state.poItemRows[itemKey].rate}
                                  onChange={(newValue) =>
                                    this.dynamicInputHandlerByKeyNameVal(
                                      newValue,
                                      "rate",
                                      itemKey,
                                      "poItemRows"
                                    )
                                  }
                                  className="form-control text-end"
                                  placeholder="Qty"
                                  required={true}
                                  min={0}
                                />
                              </td>
                              <td>
                                <TapInputNumber
                                  name="discount_rate"
                                  value={
                                    this.state.poItemRows[itemKey].discount_rate
                                  }
                                  onChange={(newValue) =>
                                    this.dynamicInputHandlerByKeyNameVal(
                                      newValue,
                                      "discount_rate",
                                      itemKey,
                                      "poItemRows"
                                    )
                                  }
                                  className="form-control text-end"
                                  placeholder="Discount"
                                />
                                <TapSelect
                                  changeEvent={(selectedOption, e) => {
                                    this.dynamicInputHandlerByKeyNameVal(
                                      selectedOption.value,
                                      "discount_type",
                                      itemKey,
                                      "poItemRows"
                                    );
                                  }}
                                  options={this.state.allDiscountType}
                                  isSearchable={false}
                                  value={this.state.allDiscountType.find(
                                    (s) =>
                                      s.value ==
                                      this.state.poItemRows[itemKey]
                                        .discount_type
                                  )}
                                  placeholder="Discount"
                                />
                              </td>
                              <td className="text-end rightSelectText">
                                {this.state.gst_as_applicable == "N" &&
                                  this.state.gst_not_applicable == "N" &&
                                  this.state.selectedVendorAdress &&
                                  this.state.selectedVendorAdress.country_id ==
                                  99 ? (
                                  <TapSelect
                                    changeEvent={(selectedOption, e) => {
                                      this.dynamicInputHandlerByKeyNameVal(
                                        selectedOption.value,
                                        "gst",
                                        itemKey,
                                        "poItemRows"
                                      );
                                    }}
                                    options={this.state.allGstSlabs}
                                    isSearchable={false}
                                    value={this.state.allGstSlabs.find(
                                      (s) =>
                                        s.value ==
                                        this.state.poItemRows[itemKey].gst
                                    )}
                                    placeholder="GST"
                                  />
                                ) : (
                                  "-"
                                )}
                                {this.state.selectedVendorAdress &&
                                  this.state.selectedVendorAdress.gstin &&
                                  this.state.gst_not_applicable == "N" &&
                                  this.state.selectedVendorAdress.country_id ==
                                  99 ? (
                                  item ? (
                                    <span className="text-helping input-group-text text-end pr0">
                                      {this.state.gstTypeName}
                                    </span>
                                  ) : null
                                ) : (
                                  <div className="text-center">
                                    NA <sup className="text-danger">#</sup>
                                  </div>
                                )}
                              </td>
                              <td className="text-end className='pt0'">
                                <NumberFormatter
                                  number={item_with_discount}
                                  precision={2}
                                />
                              </td>
                            </Ax>
                          )}
                        </Ax>
                      ) : (
                        <Ax>
                          <td className="align-middle">
                            <InputItemSearch
                              changeEvent={(selectedOption) => {
                                this.onItemSelect(selectedOption, itemKey);
                              }}
                              only_active_item="Y"
                              search_category={
                                this.state.pOForm.purchase_order_type ==
                                  "work_order"
                                  ? "services"
                                  : null
                              }
                            />
                          </td>
                          <td colSpan="6"></td>
                        </Ax>
                      )}
                      <td className="text-center align-middle">
                        {this.state.poItemRows.length > 1 ? (
                          <a
                            href="#"
                            onClick={() =>
                              this.removeItemRow(itemKey, req_allocation_ids)
                            }
                          >
                            <tapIcon.imageIcon
                              icon={tapIcon.CloseCircleIcon}
                              className="img-fluid"
                            />
                          </a>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          {/* <div className='col-sm-5 d-flex flex-row'>
                    <span className='mx-2 form-label'>Currency : </span>
                    <TapSelect placeholder="Currency"
                        containerHeight="20px"
                        fontSize="93%"
                        width="80px"
                        className="form-control "
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'pOForm', 'currency');
                            this.convertCurrency(selectedOption);
                        }}
                        options={this.state.allCurrencyList}
                        value={this.state.allCurrencyList.find(m => m.value == this.state.pOForm.currency)}
                    />
                    {this.state.pOForm.currency == this.state.defaultCurrency
                        ? null
                        : <Ax>
                            (1 {this.state.pOForm.currency} : {
                                this.state.editCurrency
                                    ? <input placeholder="Currency Ratio" className='form-control  mx-2' name="conversion_rate" value={this.state.pOForm.conversion_rate} onChange={(e) => this.formInputHandler(e, 'pOForm')} style={{ height: "20px", width: "25%", fontSize: "93%" }} />
                                    : <span className='link-primary mx-2' onClick={() => this.setState({ editCurrency: true })}>{this.state.currency_loading ? <Loader /> : <Ax>{this.state.pOForm.conversion_rate} {this.state.defaultCurrency}</Ax>}</span>})
                        </Ax>}
                </div> */}
          <div className="col-sm-12 text-end">
            {this.state.pOForm &&
              this.state.pOForm.warehouse_id &&
              this.state.pOForm.vendor_id ? (
              <a
                role="button"
                onClick={this.itemSearchModalInit}
                className="link-primary me-3"
              >
                <tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item{" "}
              </a>
            ) : null}

            {this.props?.permissions?.permissions?.includes("isc-item-add") && (
              <a
                role="button"
                onClick={this.addItemModalInit}
                className="link-primary me-3"
              >
                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item
              </a>
            )}
            <a
              role="button"
              onClick={this.addNewItemRow}
              className="link-primary"
            >
              <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row{" "}
            </a>
          </div>
        </div>
        <div className="col-sm-12 my-1">
          <input
            name="gst_as_applicable"
            type="checkbox"
            value={this.state.gst_as_applicable}
            onChange={(e) => {
              this.setState(
                {
                  gst_as_applicable:
                    this.state.gst_as_applicable == "N" ? "Y" : "N",
                },
                () => {
                  if (this.state.gst_as_applicable == "Y") {
                    let poItemRows = this.state.poItemRows;
                    let updatedPoItemRows = poItemRows.map((it) => {
                      return { ...it, gst: 0 };
                    });

                    this.setState({
                      poItemRows: updatedPoItemRows,
                      gst_not_applicable: "N",
                    });
                  }
                }
              );
            }}
            checked={this.state.gst_as_applicable == "Y"}
            className="form-check-input"
            id="gst_as_applicable"
            disabled={
              this.state.selectedVendorAdress &&
                this.state.selectedVendorAdress.country_id !== 99
                ? true
                : false
            }
          />
          <label
            className="form-check-label mx-2 text-sm"
            htmlFor="gst_as_applicable"
          >
            Mark GST as Applicable
          </label>
          <br />
        </div>
        <div className="col-sm-12 my-1">
          <input
            name="gst_not_applicable"
            type="checkbox"
            value={this.state.gst_not_applicable}
            onChange={(e) => {
              this.setState(
                {
                  gst_not_applicable:
                    this.state.gst_not_applicable == "N" ? "Y" : "N",
                },
                () => {
                  if (this.state.gst_not_applicable == "Y") {
                    let poItemRows = this.state.poItemRows;
                    let updatedPoItemRows = poItemRows.map((it) => {
                      return { ...it, gst: 0 };
                    });

                    this.setState({
                      poItemRows: updatedPoItemRows,
                      gst_as_applicable: "N",
                    });
                  }
                }
              );
            }}
            checked={this.state.gst_not_applicable == "Y"}
            className="form-check-input"
            id="gst_not_applicable"
            disabled={
              this.state.selectedVendorAdress &&
                this.state.selectedVendorAdress.country_id !== 99
                ? true
                : false
            }
          />
          <label
            className="form-check-label mx-2 text-sm"
            htmlFor="gst_not_applicable"
          >
            GST not Applicable
          </label>
          <br />
        </div>
        <div className="col-sm-12 my-1">
          <input
            name="update_item_purchase_price"
            type="checkbox"
            value={this.state.update_item_purchase_price}
            onChange={(e) => {
              this.setState({
                update_item_purchase_price:
                  this.state.update_item_purchase_price == "N" ? "Y" : "N",
              });
            }}
            checked={this.state.update_item_purchase_price == "Y"}
            className="form-check-input"
            id="update_item_purchase_price"
          />
          <label
            className="form-check-label mx-2 text-sm"
            htmlFor="update_item_purchase_price"
          >
            Update Item Purchase Price{" "}
          </label>
          <br />
        </div>
        <div className="col-sm-12">
          <input
            name="update_item_consumption_price"
            type="checkbox"
            value={this.state.update_item_consumption_price}
            onChange={(e) => {
              this.setState({
                update_item_consumption_price:
                  this.state.update_item_consumption_price == "N" ? "Y" : "N",
              });
            }}
            checked={this.state.update_item_consumption_price == "Y"}
            className="form-check-input"
            id="update_item_consumption_price"
          />
          <label
            className="form-check-label mx-2 text-sm"
            htmlFor="update_item_consumption_price"
          >
            Update Item Consumption Price{" "}
          </label>
          <br />
        </div>

        <div className="col-12 mt0">
          {this.state.selectedVendorAdress &&
            this.state.selectedVendorAdress.gstin ? null : (
            <small className="text-danger">
              #NA : GST Not Applicable to Vendor with No GSTIN
            </small>
          )}
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-6">
            <label htmlFor="notes" className="form-label">
              Notes
            </label>
            <textarea
              name="notes"
              className="form-control"
              autoComplete="off"
              required
              placeholder="Please Enter Notes"
              onChange={(e) => {
                this.formInputHandler(e, "pOForm");
              }}
              value={this.state.pOForm.notes}
              style={{ height: "80px" }}
            />
          </div>
          <div className="col-sm-6">
            <table className="table  table-borderless">
              <tbody>
                {purchase_summary && purchase_summary.length > 0
                  ? purchase_summary.map((ps, k) => {
                    return (
                      <tr key={k} className="subtotal_bg_color">
                        <th>
                          {ps.key} ({this.state.pOForm.currency})
                        </th>
                        <td className="text-end">{ps.value}</td>
                      </tr>
                    );
                  })
                  : null}
                {this.state.isc_enable_tcs == "Y" && (
                  <tr className="subtotal_bg_color">
                    <th>
                      <div className="row align-items-center">
                        <div className="col-sm-4">
                          <input
                            name="tcs_applicable"
                            type="checkbox"
                            value={this.state.tcs_applicable}
                            onChange={(e) => {
                              this.setState(
                                {
                                  tcs_applicable:
                                    this.state.tcs_applicable == "N"
                                      ? "Y"
                                      : "N",
                                },
                                () => {
                                  if (this.state.tcs_applicable == "N") {
                                    this.setState({
                                      pOForm: {
                                        ...this.state.pOForm,
                                        tcs_rate: 0,
                                      },
                                    });
                                  }
                                }
                              );
                            }}
                            checked={this.state.tcs_applicable == "Y"}
                            className="form-check-input"
                            id="tcs_applicable"
                          />
                          <label
                            className="form-check-label mx-1 "
                            htmlFor="tcs_applicable"
                          >
                            Enable TCS
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <TapSelect
                            changeEvent={(selectedOption, e) => {
                              this.tapSelectChange(
                                selectedOption,
                                "pOForm",
                                "tcs_type"
                              );
                            }}
                            options={this.state.allTCSType}
                            isSearchable={false}
                            value={this.state.allTCSType.find(
                              (s) => s.value == this.state.pOForm.tcs_type
                            )}
                            placeholder="TCS"
                            disabled={this.state.tcs_applicable == "N"}
                          />
                        </div>
                        <div className="col-sm-5">
                          <input
                            name="tcs_rate"
                            type="number"
                            value={this.state.pOForm.tcs_rate}
                            onChange={(e) => this.formInputHandler(e, "pOForm")}
                            className="form-control text-end"
                            autoComplete="off"
                            placeholder="TCS Amount"
                            disabled={this.state.tcs_applicable == "N"}
                          />
                        </div>
                      </div>
                    </th>
                    <td className="float-end" style={{ lineHeight: 3 }}>
                      {" "}
                      {tcs_amount ? (
                        <NumberFormatter number={tcs_amount} precision={2} />
                      ) : (
                        <NumberFormatter number={tcs_amount} precision={2} />
                      )}
                    </td>
                  </tr>
                )}

                <tr className="subtotal_bg_color">
                  <th>Adjustment</th>
                  <td>
                    <input
                      name="adjustment_amount"
                      type="number"
                      value={this.state.pOForm.adjustment_amount}
                      onChange={(e) => this.formInputHandler(e, "pOForm")}
                      className="form-control text-end"
                      autoComplete="off"
                      placeholder="Adjustment"
                    />
                  </td>
                </tr>

                <tr className="subtotal_bg_color">
                  <th>Final Total ({this.state.pOForm.currency})</th>
                  <td className="float-end">
                    {" "}
                    {adjustment_amount ? (
                      <NumberFormatter
                        number={total_amount + adjustment_amount + tcs_amount}
                        precision={2}
                      />
                    ) : (
                      <NumberFormatter
                        number={total_amount + tcs_amount}
                        precision={2}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Ax>
    );
  };

  savePurchaseOrderFormJsx = () => {
    let transactionDate =
      this.state.pOForm && this.state.pOForm.transaction_date
        ? moment(this.state.pOForm.transaction_date).format(
          "YYYY-MM-DD 00:00:00"
        )
        : null;
    return (
      <form
        className="bg-white p-3"
        onSubmit={this.addPurchaseOrderFormSubmitEvent}
      >
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label require ">
              PO Type
            </label>
          </div>
          <div className="col-sm-4">
            <TapSelect
              changeEvent={(selectedOption) => {
                this.tapSelectChange(
                  selectedOption,
                  "pOForm",
                  "purchase_order_type",
                  () => {
                    this.setState({
                      transactionIdSetting: selectedOption
                        ? selectedOption.type_data
                        : null,
                    });
                  }
                );
              }}
              options={this.state.allPOTypes}
              isSearchable={true}
              isClearable={true}
              required={true}
              isDisabled={this.state.poDetail}
              value={this.state.allPOTypes.find(
                (s) => s.value == this.state.pOForm.purchase_order_type
              )}
              placeholder="Select PO Type"
            />
          </div>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label ">
              P.O. Number{" "}
            </label>
          </div>
          <div className="col-sm-4">
            <input
              name="transaction_id"
              type="text"
              className="form-control"
              autoComplete="off"
              value={this.state.pOForm.transaction_id}
              onChange={(e) => this.formInputHandler(e, "pOForm")}
              disabled={
                this.state.transactionIdSetting &&
                this.state.transactionIdSetting.type == "system_generate"
              }
              placeholder={
                this.state.transactionIdSetting &&
                  this.state.transactionIdSetting.type == "system_generate"
                  ? "System Generated"
                  : "Enter PO ID"
              }
              required={
                this.state.transactionIdSetting &&
                this.state.transactionIdSetting.type != "system_generate"
              }
            />
          </div>
          <div className="col-5 form-text">
            {this.state.transactionIdSetting &&
              this.state.transactionIdSetting.type == "system_generate"
              ? this.state.transactionIdSetting.msg
              : "You have selected manual numbering"}
          </div>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="transaction_date" className="form-label require ">
              Date of Transaction
            </label>
          </div>
          <div className="col-sm-4 add_calender_section">
            <DatePicker
              selected={
                this.state.pOForm.transaction_date
                  ? moment(
                    this.state.pOForm.transaction_date,
                    "YYYY-MM-DD"
                  ).toDate()
                  : false
              }
              name="transaction_date"
              onChange={(value, event) =>
                this.formDateHandler("transaction_date", value, "pOForm")
              }
              dateFormat="dd-MMM-yyyy"
              maxDate={moment(new Date(), "YYYY-MM-DD").toDate()}
              minDate={
                this.state.po_allow_backdate == "N" &&
                moment(new Date(), "YYYY-MM-DD").toDate()
              }
              className="form-control"
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
              scrollMonthYearDropdown
              required={true}
              disabled={
                this.state.po_allow_edit_transaction_date == "N" ? true : false
              }
              placeholderText="Please Enter Date of Transaction"
            />
            <tapIcon.imageIcon
              icon={tapIcon.CalenderIcon}
              alt="purchase_date"
              className="add_calender-icon"
            />
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label require">
              Warehouse
            </label>
          </div>
          <div className="col-sm-6">
            <TapSelect
              changeEvent={(selectedOption) => {
                this.tapSelectChange(
                  selectedOption,
                  "pOForm",
                  "warehouse_id",
                  () => {
                    this.setState(
                      {
                        selectedAsset: null,
                        selectedWorkstation: null,
                        searchedEmployee: null,

                        pOForm: {
                          ...this.state.pOForm,
                          workstation_id: "",
                          asset_id: "",
                          employee_id: "",
                          users: [],
                        },
                      },
                      () => {
                        this.getWarehouseDetail(
                          selectedOption ? selectedOption.value : null
                        );
                      }
                    );
                  }
                );
              }}
              options={this.state.allWarehouses}
              isSearchable={true}
              isClearable={true}
              required={true}
              value={this.state.allWarehouses.find(
                (m) => m.value == this.state.pOForm.warehouse_id
              )}
              placeholder="Please Select Warehouse"
            />
          </div>
          <div className="col-sm-3">
            {this.state.pOForm.warehouse_id ? (
              <Ax>
                {" "}
                <button
                  type="button"
                  id="actionMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn btn-secondary mx-2 pl0 pr0"
                  style={{ height: "38px" }}
                >
                  <tapIcon.imageIcon
                    icon={tapIcon.ActionIcon}
                    className="img-fluid"
                    alt="item-action"
                  />
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end cursor_pointer"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => this.changeShipAddressInit()}
                    >
                      Change Ship-To Address
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => this.addWarehouseAddressInit()}
                    >
                      Add Ship-To Address
                    </a>
                  </li>
                </ul>
              </Ax>
            ) : null}
          </div>
        </div>
        {this.state.warehouseDetail ? (
          <div className="row align-items-center mb-3 fs13">
            <div className="offset-3 col-sm-3">
              <div>
                <label className="form-label">
                  Organization :{" "}
                  {this.state.warehouseDetail &&
                    this.state.warehouseDetail.organization
                    ? this.state.warehouseDetail.organization.name
                    : this.state?.orgInfoData?.group_name}
                </label>
              </div>
              <label className="form-label">
                {this.state.warehouseDetail.address1
                  ? this.state.warehouseDetail.address1 + ", "
                  : ""}
                <br />
                {this.state.warehouseDetail.address2
                  ? this.state.warehouseDetail.address2 + ", "
                  : ""}
                <br />
                {this.state.warehouseDetail.landmark
                  ? this.state.warehouseDetail.landmark + ", "
                  : ""}
                <br />
                {this.state.warehouseDetail.city
                  ? this.state.warehouseDetail.city
                  : ""}
              </label>
              <br />
              <label className="form-label">
                State :{" "}
                {this.state.warehouseDetail &&
                  this.state.warehouseDetail.state ? (
                  <span>{this.state.warehouseDetail.state.name}</span>
                ) : (
                  ""
                )}
              </label>{" "}
              <br />
              <label className="form-label">
                GSTIN :{" "}
                {this.state.warehouseDetail &&
                  this.state.warehouseDetail.gstin ? (
                  <span>{this.state.warehouseDetail.gstin}</span>
                ) : (
                  ""
                )}
              </label>
            </div>
            <div className="col-sm-3">
              <div>
                <label className="form-label"> &nbsp; </label>
              </div>
              <div>
                <b>Ship To Address</b>
              </div>
              {this.state.selectedShippingAddress ? (
                <Ax>
                  <label className="form-label">
                    {this.state.selectedShippingAddress.address1 ? (
                      <span>
                        {this.state.selectedShippingAddress.address1}, <br />
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.selectedShippingAddress.address2 ? (
                      <span>
                        {this.state.selectedShippingAddress.address2}, <br />
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.selectedShippingAddress.landmark ? (
                      <span>
                        {this.state.selectedShippingAddress.landmark}, <br />
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.selectedShippingAddress.city ? (
                      <span>{this.state.selectedShippingAddress.city}, </span>
                    ) : (
                      ""
                    )}{" "}
                  </label>
                  <br />
                  {this.state.selectedShippingAddress &&
                    this.state.selectedShippingAddress.state_name ? (
                    <Ax>
                      <label className="form-label">
                        State :{" "}
                        <span>
                          {this.state.selectedShippingAddress.state_name}
                        </span>
                      </label>
                      <br />
                    </Ax>
                  ) : (
                    ""
                  )}
                  {this.state.selectedShippingAddress &&
                    this.state.selectedShippingAddress.gstin ? (
                    <Ax>
                      <label className="form-label">
                        GSTIN :{" "}
                        <span>{this.state.selectedShippingAddress.gstin}</span>{" "}
                      </label>
                    </Ax>
                  ) : (
                    ""
                  )}
                </Ax>
              ) : (
                <Ax>
                  <label className="form-label">
                    {this.state.warehouseDetail.address1 ? (
                      <span>
                        {this.state.warehouseDetail.address1}, <br />
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.warehouseDetail.address2 ? (
                      <span>
                        {this.state.warehouseDetail.address2}, <br />
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.warehouseDetail.landmark ? (
                      <span>
                        {this.state.warehouseDetail.landmark}, <br />
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.warehouseDetail.city ? (
                      <span>{this.state.warehouseDetail.city}, </span>
                    ) : (
                      ""
                    )}{" "}
                  </label>
                  <br />
                  {this.state.warehouseDetail &&
                    this.state.warehouseDetail.state ? (
                    <Ax>
                      <label className="form-label">
                        State :{" "}
                        <span>{this.state.warehouseDetail.state.name}</span>{" "}
                      </label>
                      <br />{" "}
                    </Ax>
                  ) : (
                    ""
                  )}
                  {this.state.warehouseDetail &&
                    this.state.warehouseDetail.gstin ? (
                    <label className="form-label">
                      GSTIN : <span>{this.state.warehouseDetail.gstin}</span>{" "}
                    </label>
                  ) : (
                    ""
                  )}
                </Ax>
              )}
            </div>
          </div>
        ) : this.state.warehouseDetailLoading ? (
          <Loader />
        ) : null}
        <Vendor
          vendorDetails={this.state.vendorDetails}
          details_loading={this.state.details_loading}
          vendorAddressList={this.state.vendorAddressList}
          vendor_details_loading={this.state.vendor_details_loading}
          selectedVendorAdress={this.state.selectedVendorAdress}
          searchedVendor={this.state.searchedVendor}
          onChange={this.handleVendorChange}
          getVendorDetails={this.getVendorDetails}
          vendorRef={this.addVendorModalRef}
          changeAddressHandler={this.changeAddressHandler}
        />

        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label require ">
              Status
            </label>
          </div>
          <div className="col-sm-4">
            <TapSelect
              changeEvent={(selectedOption) => {
                this.tapSelectChange(selectedOption, "pOForm", "status");
              }}
              options={this.state.allStatusOptions}
              isSearchable={true}
              isClearable={true}
              required={true}
              isDisabled={this.state.poDetail}
              value={this.state.allStatusOptions.find(
                (s) => s.value == this.state.pOForm.status
              )}
              placeholder="Select Status"
            />
          </div>
        </div>
        {this.state.po_allow_po_for == "Y" && (
          <Ax>
            <div className="row align-items-center my-3">
              <div className="col-sm-3">
                <label className="form-label">Purchase Order For</label>
              </div>
              <div className="col-sm-7">
                <div className="form-check form-check-inline">
                  <input
                    name="po_for"
                    type="radio"
                    value="asset"
                    onChange={(e) => this.formInputHandler(e, "pOForm")}
                    checked={this.state.pOForm.po_for === "asset"}
                    className="form-check-input"
                    id="pOFormAsset"
                  />
                  <label className="form-check-label" htmlFor="pOFormAsset">
                    Asset
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    name="po_for"
                    type="radio"
                    value="workstation"
                    onChange={(e) => this.formInputHandler(e, "pOForm")}
                    checked={this.state.pOForm.po_for === "workstation"}
                    className="form-check-input"
                    required={true}
                    id="pOForm_workstation"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="pOForm_workstation"
                  >
                    Workstation
                  </label>
                </div>
                {this.props.permissions.group_modules.includes("hrm") ? (
                  <div className="form-check form-check-inline">
                    <input
                      name="po_for"
                      type="radio"
                      value="employee"
                      onChange={(e) => this.formInputHandler(e, "pOForm")}
                      checked={this.state.pOForm.po_for === "employee"}
                      className="form-check-input"
                      required={true}
                      id="pOForm_employee"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="pOForm_employee"
                    >
                      Employee
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
            {this.state.pOForm.po_for == "asset" ? (
              <Ax>
                <div className="row align-items-center my-3">
                  <div className="col-sm-3">
                    <label className="form-label require">Asset</label>
                  </div>
                  <div className="col-sm-7">
                    <InputAssetSearch
                      changeEvent={(selectedOption) => {
                        this.tapSelectChange(
                          selectedOption,
                          "pOForm",
                          "asset_id"
                        );
                        this.setState({
                          selectedAsset: selectedOption
                            ? {
                              ...selectedOption.asset,
                              label: selectedOption.display_label,
                            }
                            : null,
                        });
                      }}
                      placeholder="Search Asset"
                      search_site_id={this.state.warehouseSites.map(
                        (w) => w.id
                      )}
                      filter={true}
                      required={
                        this.state.pOForm.po_for == "asset" ? true : false
                      }
                      value={
                        this.state.selectedAsset
                          ? { ...this.state.selectedAsset }
                          : null
                      }
                    />
                  </div>
                </div>
                {this.state.selectedAsset ? (
                  <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8">
                      <AssetMiniDetail assetDetail={this.state.selectedAsset} />
                    </div>
                  </div>
                ) : null}
              </Ax>
            ) : this.state.pOForm.po_for == "workstation" ? (
              <Ax>
                <div className="row align-items-center my-3">
                  <div className="col-sm-3">
                    <label
                      htmlFor="workstation_id"
                      className="form-label require"
                    >
                      Workstation
                    </label>
                  </div>
                  <div className="col-sm-7">
                    <InputWorkstationSearch
                      placeholder="Please Select Workstation"
                      changeEvent={(selectedOption) => {
                        this.setState({
                          searchedWorkstation: selectedOption
                            ? selectedOption
                            : null,
                          pOForm: {
                            ...this.state.pOForm,
                            workstation_id: selectedOption
                              ? selectedOption.value
                              : "",
                          },
                        });
                      }}
                      menuPlacement="top"
                      required={
                        this.state.pOForm.po_for == "workstation" ? true : false
                      }
                      search_site_id={this.state.warehouseSites.map(
                        (wk) => wk.id
                      )}
                      value={
                        this.state.searchedWorkstation
                          ? this.state.searchedWorkstation
                          : null
                      }
                    />
                  </div>
                </div>
              </Ax>
            ) : this.state.pOForm.po_for == "employee" ? (
              <Ax>
                {this.props.permissions.group_modules.includes("hrm") ? (
                  <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                      <label htmlFor="voucher_number" className="form-label">
                        Employee
                      </label>
                    </div>
                    <div className="col-sm-7">
                      <InputEmployeeSearch
                        placeholder="Please Select Employee"
                        search_site_ids={this.state.warehouseSites.map(
                          (w) => w.id
                        )}
                        changeEvent={(selectedOption) => {
                          this.tapSelectChange(
                            selectedOption,
                            "pOForm",
                            "employee_id",
                            () => {
                              this.setState({
                                searchedEmployee: selectedOption
                                  ? selectedOption
                                  : null,
                              });
                            }
                          );
                        }}
                        required={
                          this.state.pOForm.po_for == "employee" ? true : false
                        }
                        transaction_date={
                          transactionDate ? transactionDate : null
                        }
                        value={
                          this.state.searchedEmployee
                            ? this.state.searchedEmployee
                            : null
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </Ax>
            ) : null}
          </Ax>
        )}
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label ">
              Priority & Delivery Date
            </label>
          </div>
          <div className="col-sm-4">
            <TapSelect
              changeEvent={(selectedOption) =>
                this.tapSelectChange(selectedOption, "pOForm", "priority")
              }
              options={this.state.allPriorityOptions}
              isSearchable={true}
              isClearable={true}
              value={this.state.allPriorityOptions.find(
                (s) => s.value == this.state.pOForm.priority
              )}
              placeholder="Select Priority"
            />
          </div>
          <div className="col-sm-4 add_calender_section">
            <DatePicker
              selected={
                this.state.pOForm.expected_delivery_date
                  ? moment(
                    this.state.pOForm.expected_delivery_date,
                    "YYYY-MM-DD"
                  ).toDate()
                  : false
              }
              name="expected_delivery_date"
              onChange={(value, event) =>
                this.formDateHandler("expected_delivery_date", value, "pOForm")
              }
              dateFormat="dd-MMM-yyyy"
              className={"form-control"}
              minDate={moment(new Date(), "YYYY-MM-DD").toDate()}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
              scrollMonthYearDropdown
              placeholderText={`Please Enter Delivery  Date`}
            />
            <tapIcon.imageIcon
              icon={tapIcon.CalenderIcon}
              alt="purchase_date"
              className="add_calender-icon"
            />
          </div>
        </div>
        <div className="row align-items-center mb-3 mt-3">
          <div className="col-3">
            <label htmlFor="site_id" className="form-label ">
              Quotation Number & Date
            </label>
          </div>
          <div className="col-4">
            <input
              name="quotation_name"
              type="text"
              className="form-control"
              autoComplete="off"
              value={this.state.pOForm.quotation_name}
              onChange={(e) => this.formInputHandler(e, "pOForm")}
              placeholder="Enter Quotation Name"
            />
          </div>
          <div className="col-4 add_calender_section">
            <DatePicker
              selected={
                this.state.pOForm.quotation_date
                  ? moment(
                    this.state.pOForm.quotation_date,
                    "YYYY-MM-DD"
                  ).toDate()
                  : false
              }
              name="quotation_date"
              onChange={(value, event) =>
                this.formDateHandler("quotation_date", value, "pOForm")
              }
              dateFormat="dd-MMM-yyyy"
              className={"form-control"}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
              scrollMonthYearDropdown
              placeholderText={`Please Enter Quotation Date`}
            />

            <tapIcon.imageIcon
              icon={tapIcon.CalenderIcon}
              alt="purchase_date"
              className="add_calender-icon"
            />
          </div>
        </div>
        <div className="row align-items-center mb-3 mt-3">
          <div className="col-3">
            <label htmlFor="site_id" className="form-label ">
              Tag Users
            </label>
          </div>
          <div className="col-4">
            <TapSelect
              changeEvent={(selectedOption) =>
                this.tapSelectChange(selectedOption, "pOForm", "users")
              }
              options={this.state.allUsers}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
              value={this.state.allUsers.filter((t) =>
                this.state.pOForm.users.includes(t.value)
              )}
              placeholder="Please Select User"
            />
          </div>
        </div>
        {this.state.multiple_currency_available == "Y" && (
          <div className="row align-items-center mb-3 mt-3">
            <div className="col-3">
              <label htmlFor="site_id" className="form-label ">
                Currency
              </label>
            </div>
            <div className={"col-4"}>
              <TapSelect
                placeholder="Currency"
                className="form-control "
                changeEvent={(selectedOption) => {
                  this.tapSelectChange(
                    selectedOption,
                    "pOForm",
                    "currency",
                    () => {
                      this.convertCurrency(selectedOption);
                    }
                  );
                }}
                options={this.state.allCurrencyList}
                value={this.state.allCurrencyList.find(
                  (m) => m.value == this.state.pOForm.currency
                )}
              />
            </div>
            {this.state.pOForm.currency == this.state.defaultCurrency ? null : (
              <Ax>
                {this.state.currency_loading ? (
                  <div className="col-4 ">
                    {" "}
                    <Loader />
                  </div>
                ) : (
                  <div className="col-4 d-flex justify-content-between">
                    <Ax>
                      <div>
                        <input
                          placeholder="Currency"
                          className="form-control"
                          name="conversion_rate"
                          value={`1 ${this.state.pOForm.currency} = `}
                          disabled="true"
                        />
                      </div>
                      <div className="text-end">
                        <input
                          placeholder="Currency Ratio"
                          type="number"
                          min="0"
                          autoComplete="off"
                          className="form-control"
                          name="conversion_rate"
                          value={this.state.pOForm.conversion_rate}
                          onChange={(e) => this.formInputHandler(e, "pOForm")}
                        />
                      </div>
                      <div>
                        <input
                          placeholder="Currency"
                          className="form-control"
                          name="conversion_rate"
                          value={this.state.defaultCurrency}
                          disabled="true"
                        />
                      </div>
                    </Ax>
                  </div>
                )}
              </Ax>
            )}
          </div>
        )}
        {this.state.po_enable_authorized_signatory == "Y" && (
          <div className="row align-items-center mb-3 mt-3">
            <div className="col-3">
              <label htmlFor="site_id" className="form-label ">
                Authorized Signatory
              </label>
            </div>
            <div className="col-4">
              <TapSelect
                changeEvent={(selectedOption) => {
                  this.tapSelectChange(
                    selectedOption,
                    "pOForm",
                    "authorized_signatory_id"
                  );
                }}
                options={this.state.allUsersList}
                isSearchable={true}
                isClearable={true}
                value={this.state.allUsersList.find(
                  (m) => m.value == this.state.pOForm.authorized_signatory_id
                )}
                placeholder="Select Authorized Signatory"
              />
            </div>
          </div>
        )}
        {this.state.formData &&
          this.state.formData.additional_attributes &&
          this.state.formData.additional_attributes.length > 0 && (
            <AdditionalAttributeForm
              additionalAttributes={this.state.formData.additional_attributes}
              formState={this.state.pOForm}
              onAttributeChange={(value, attributeName) => {
                this.setState(() => ({
                  pOForm: {
                    ...this.state.pOForm,
                    [attributeName]: value,
                  },
                }));
              }}
            />
          )}

        <div className="row align-items-center my-3 ">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label">
              Purchase Requisition <br />
            </label>
          </div>
          <div className="col-sm-7">
            <button
              type="button"
              disabled={
                this.state.pOForm.warehouse_id && this.state.pOForm.warehouse_id
                  ? false
                  : true
              }
              className="btn btn-link text-decoration-none"
              onClick={() => this.pickReqItemModalInit()}
            >
              <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Pick Item from
              Approved Purchase Requisition
            </button>
          </div>
        </div>
        {this.purchaseOrderItemJsx()}
        {this.termConditionJsx()}
        {!this.state.buttonDisabled && (
          <div className="col-12 text-end fixed_footer">
            <button
              type="button"
              disabled={
                this.state.saveFormSubmitting || this.state.buttonDisabled
              }
              onClick={this.goToListing}
              className="btn btn-secondary ms-2"
            >
              Cancel
            </button>
            {this.state.allStatusOptions &&
              this.state.allStatusOptions.find((s) => s.value == 1) ? (
              <button
                type="button"
                disabled={
                  this.state.saveFormSubmitting || this.state.buttonDisabled
                }
                className="btn btn-secondary ms-2"
                onClick={(e) => {
                  this.saveFormSubmitHandler(e, 1);
                }}
              >
                Save as a Draft{" "}
                {this.state.saveFormSubmitting ? (
                  <tapIcon.FontAwesomeIcon
                    icon={tapIcon.faSyncAlt}
                    className="fa-spin"
                  />
                ) : (
                  ""
                )}
              </button>
            ) : null}
            {this.state.allStatusOptions &&
              this.state.allStatusOptions.find((s) => s.value == 2) ? (
              <button
                type="button"
                disabled={
                  this.state.saveFormSubmitting || this.state.buttonDisabled
                }
                className="btn btn-primary ms-2"
                onClick={(e) => {
                  this.saveFormSubmitHandler(
                    e,
                    this.state.pOForm && this.state.pOForm.status
                  );
                }}
              >
                Save{" "}
                {this.state.saveFormSubmitting ? (
                  <tapIcon.FontAwesomeIcon
                    icon={tapIcon.faSyncAlt}
                    className="fa-spin"
                  />
                ) : (
                  ""
                )}
              </button>
            ) : null}
          </div>
        )}
      </form>
    );
  };

  addTermsModalJsx = () => {
    return (
      <div className="modal fade" id="addTermsModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTermsModalLabel">
                Terms & Condition
              </h5>
            </div>
            {
              this.state.tncModalLoader ?
                <Loader />
                :
                <>
                  <form
                    onSubmit={this.submitAddTermForm}
                    id="addTermsForm"
                    method="post"
                    encType="multipart/form-data"
                  >
                    <div className="modal-body">
                      <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                          <label htmlFor="transaction Type" className="form-label ">
                            Name
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <input
                            name="name"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => {
                              this.formInputHandler(e, "addTermForm");
                            }}
                            value={this.state.addTermForm.name}
                            placeholder="Please Enter Term Name"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                          <label htmlFor="transaction Type" className="form-label ">Tag</label>
                        </div>
                        <div className="col-sm-9">
                          <TapSelect
                            options={this.state.tncTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTermForm', 'tag_ids')}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.tncTags.filter(s => this.state.addTermForm.tag_ids.includes(s.value))}
                            isMulti={true}
                            placeholder="Select Tag"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                          <label
                            htmlFor="transaction Type"
                            className="form-label require "
                          >
                            Value
                          </label>
                        </div>
                        <div className="col-sm-9">
                          <textarea
                            name="text"
                            className="form-control"
                            autoComplete="off"
                            required
                            placeholder="Please Enter Text"
                            onChange={(e) => {
                              this.formInputHandler(e, "addTermForm");
                            }}
                            value={this.state.addTermForm.text}
                            style={{ height: "80px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        disabled={this.state.addTermFormSubmitting}
                        className="btn btn-secondary mx-2"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        disabled={this.state.addTermFormSubmitting}
                        className="btn btn-primary"
                      >
                        Submit{" "}
                        {this.state.addTermFormSubmitting ? (
                          <tapIcon.FontAwesomeIcon
                            icon={tapIcon.faSyncAlt}
                            className="fa-spin"
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </form>
                </>
            }
          </div>
        </div>
      </div>
    );
  };

  addTncInPOModalJsx = () => {
    return (
      <div className="modal fade" id="addTnCInPOModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTnCInPOModalLabel">
                Pick Terms & Condititon
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {
                this.state.pickTNCModalLoader
                  ?
                  <Loader />
                  :
                  <>
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                      </div>
                      <div className="col-sm-6">
                        <TapSelect
                          options={this.state.tncTags}
                          changeEvent={(selectedOption) => this.searchTNCHandle(selectedOption)}
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.tncTags.filter(s => this.state.searchTNCByTags.includes(s.value))}
                          isMulti={true}
                          placeholder="Select Tag"
                          containerHeight="32px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <table
                      className="table table-bordered table-responsive bg-white my-2"
                      id="poTermTable"
                    >
                      <thead className="table-secondary">
                        <tr className="text-center">
                          <th
                            scope="col"
                            className="text-start"
                            style={{ width: "20%" }}
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="text-start"
                            style={{ width: "50%" }}
                          >
                            Description
                          </th>
                          <th scope="col" style={{ width: "20%" }}>Tag</th>
                          <th
                            scope="col"
                            className="text-start"
                            style={{ width: "10%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.termCoditions?.map((tnc, k) => {
                          let picked = this.state.pOForm?.tnc?.find(
                            (potnc) => potnc.name == tnc.name
                          );
                          return (
                            <tr key={k}>
                              <td>{tnc.name}</td>
                              <td>{tnc.text}</td>
                              <td>{tnc.tags.length > 0 &&
                                tnc.tags.map(tag => tag.name).join(', ')
                              }
                              </td>
                              <td className="text-center">
                                <button
                                  disabled={picked}
                                  onClick={() => this.addTncInPO(k)}
                                  className={[
                                    "btn",
                                    "py-1",
                                    "px-3",
                                    picked ? "btn-light" : "btn-secondary",
                                  ].join(" ")}
                                >
                                  {picked ? (
                                    <tapIcon.FontAwesomeIcon
                                      color="green"
                                      icon={tapIcon.faCheck}
                                    />
                                  ) : (
                                    "Pick"
                                  )}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  warehouseAddressModalJsx = () => {
    return (
      <div className="modal fade" id="warehouseAddressModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="vendorDetailsModalLabel">
                Warehouse Ship to Address Lists
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ searchWarehouseAddress: "" })}
              ></button>
            </div>

            {!this.state.address_loading ? (
              this.state.warehouseAddressList ? (
                <div className="modal-body">
                  <div className="row mb-3">
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({
                            searchWarehouseAddress: e.target.value,
                          })
                        }
                        placeholder="Search Address, Pin Code, GSTIN"
                        value={this.state.searchWarehouseAddress}
                      />
                    </div>
                    <div className="col-sm-2 text-end">
                      <button
                        type="button"
                        className="btn btn-secondary btn-lg"
                        onClick={() => {
                          this.getWarehouseAddressList(
                            this.state.pOForm.warehouse_id,
                            1
                          );
                          this.setState({ clearSearchAddress: false });
                        }}
                        disabled={
                          this.state.searchWarehouseAddress.length === 0
                            ? true
                            : false
                        }
                      >
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} />{" "}
                      </button>
                    </div>
                    <div className="col-sm-2">
                      <button
                        type="button"
                        className="btn btn-secondary btn-lg"
                        onClick={() =>
                          this.setState({ searchWarehouseAddress: "" }, () => {
                            this.getWarehouseAddressList(
                              this.state.pOForm.warehouse_id,
                              1
                            );
                            this.setState({ clearSearchAddress: true });
                          })
                        }
                        disabled={
                          this.state.searchWarehouseAddress.length === 0 ||
                            this.state.clearSearchAddress
                            ? true
                            : false
                        }
                      >
                        <tapIcon.imageIcon
                          icon={tapIcon.CloseIcon}
                          className="img-fluid"
                        />
                      </button>
                    </div>
                  </div>
                  <table className="table table-hover table-bordered">
                    <tbody>
                      {this.state.warehouseDetail ? (
                        <tr>
                          <td>
                            <b> Same as Warehouse Address</b>
                          </td>
                          <td className="text-center" style={{ width: "15%" }}>
                            {!this.state.selectedShippingAddress ? (
                              <button
                                type="button"
                                className="btn btn-secondary"
                              >
                                Selected
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  this.changeWarehouseAddressHandler(null);
                                }}
                              >
                                Select
                              </button>
                            )}
                          </td>
                        </tr>
                      ) : null}
                      {this.state.warehouseAddressList.length > 0
                        ? this.state.warehouseAddressList.map((a, i) => {
                          return (
                            <tr>
                              <td>
                                <div className="py-1">
                                  Address :{" "}
                                  <b>
                                    {a.address1 ? a.address1 + ", " : ""}
                                    {a.address2 ? a.address2 + ", " : ""}
                                    {a.landmark ? a.landmark + ", " : ""}
                                    {a.city ? a.city : ""}
                                  </b>
                                </div>
                                <div className="py-1">
                                  District, State & Pincode :{" "}
                                  <b>
                                    {a.district_name
                                      ? a.district_name + ", "
                                      : ""}
                                    {a.state_name ? a.state_name : ""}
                                    {a.pincode ? " & " + a.pincode : ""}
                                  </b>
                                </div>
                                <div className="py-1">
                                  GSTIN : <b>{a.gstin ? a.gstin : ""}</b>
                                </div>
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                {this.state.selectedShippingAddress &&
                                  a.id ==
                                  this.state.selectedShippingAddress.id ? (
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    Selected
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      this.changeWarehouseAddressHandler(
                                        a.id
                                      );
                                    }}
                                  >
                                    Select
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                  <DisplayListPagination
                    meta={this.state.warehouseListingMeta}
                    onPageChange={(e) =>
                      this.getWarehouseAddressList(
                        this.state.pOForm.warehouse_id,
                        e.selected + 1
                      )
                    }
                  />
                </div>
              ) : null
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    );
  };

  pickReqItemModalJsx = () => {
    return (
      <div className="modal fade" id="pickReqModal" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="pickIndentItemModalLabel">
                Pick Item from Approved Purchase Requisition
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="p-1 bg-light border-bottom">
              <table className="table table-sm mb-0 table-borderless text-sm">
                <tbody>
                  <tr>
                    <td style={{ width: "26%" }}> Warehouse </td>
                    <th>
                      {this.state.warehouseDetail
                        ? `${this.state.warehouseDetail.name}`
                        : "-"}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <form
              onSubmit={this.submitPickReqFormSubmit}
              id="submitPickIndentForm"
            >
              <div className="modal-body">
                <table className="table table-bordered table-sm align-middle bg-white my-2">
                  <thead className="align-middle table-secondary">
                    <tr>
                      <th className="text-center" style={{ width: "5%" }}>
                        <input
                          type="checkbox"
                          onChange={this.checkAllReqItemList}
                          id="all_check_req"
                        />
                      </th>
                      <th className="text-center" style={{ width: "11%" }}>
                        Date of Transaction
                      </th>
                      <th className="text-center" style={{ width: "11%" }}>
                        Transaction ID
                      </th>
                      <th style={{ width: "20%" }}>Item Name</th>
                      <th style={{ width: "15%" }}>Item Code </th>
                      <th style={{ width: "11%" }} className="text-end">
                        Req Qty{" "}
                      </th>
                      <th style={{ width: "11%" }} className="text-end">
                        Pending Qty{" "}
                      </th>
                      <th style={{ width: "15%" }} className="text-end">
                        Qty{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.requistion_listing &&
                      this.state.requistion_listing.length > 0 ? (
                      this.state.requistion_listing.map((rowData, k) => {
                        return (
                          <tr key={k}>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                name="req_id_checkbox"
                                className="req_id_checkbox"
                                value={rowData.req_item_id}
                                data-itemId={rowData.item_id}
                                data-qty={rowData?.new_qty}
                                disabled={this.state.allReqItemLists.find(
                                  (element) => {
                                    if (
                                      element?.req_item_id ==
                                      rowData?.req_item_id
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                )}
                                checked={this.state.allReqItemLists.find(
                                  (element) => {
                                    if (
                                      element?.req_item_id ==
                                      rowData?.req_item_id
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                )}
                              />
                            </td>
                            <td className="text-center">
                              {rowData.transaction_date_display
                                ? rowData.transaction_date_display
                                : "-"}
                            </td>
                            <td className="text-center">
                              {rowData.transaction_id
                                ? rowData.transaction_id
                                : "-"}
                            </td>
                            <td>
                              {rowData.item_name ? rowData.item_name : "-"}
                            </td>
                            <td>
                              {rowData.item_code ? rowData.item_code : "-"}
                            </td>
                            <td className="text-end">{rowData.qty}</td>
                            <td className="text-end">
                              {parseFloat(rowData.pending_qty).toFixed(2)}
                            </td>
                            <td>
                              <TapInputNumber
                                className="form-control form-control-sm text-end"
                                placeholder="Qty"
                                name="new_qty"
                                onChange={(e) => {
                                  this.state.requistion_listing[k] = {
                                    ...rowData,
                                    new_qty: e,
                                  };
                                  this.dynamicInputHandlerByKeyNameVal(
                                    e,
                                    "new_qty",
                                    k,
                                    "requistion_listing"
                                  );
                                }}
                                value={rowData.new_qty}
                                max={rowData.pending_qty}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : !this.state.approved_requisition_listing ? (
                      <tr>
                        <td className="text-center" colSpan={8}>
                          No Record Found
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                {this.state.approved_requisition_listing ? (
                  <div className="text-center">
                    <Loader />
                  </div>
                ) : null}

                <div className="text-end mt-1 mb-5">
                  {this.state.reqListingMeta &&
                    this.state.reqListingMeta.current_page <
                    this.state.reqListingMeta.last_page ? (
                    <button
                      type="button"
                      className="btn btn-secondary me-3 "
                      style={{ lineHeight: "6.3px!important", height: "27px" }}
                      onClick={() => this.loadMoreList()}
                    >
                      Load More
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={this.state.savePickItemSubmitting}
                  className="btn btn-primary"
                  form="submitPickIndentForm"
                >
                  Add to PO{" "}
                  {this.state.savePickItemSubmitting ? (
                    <tapIcon.FontAwesomeIcon
                      icon={tapIcon.faSyncAlt}
                      className="fa-spin"
                    />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render = () => {

    return (
      <ApplicationLayout>
        <Helmet>
          <title>Purchase Order Add</title>
        </Helmet>
        {this.pageTitleJsx()}
        <div className="container-fluid">
          <div className="page_containt row pl2">
            <div className="pageTbl col-12 pl4">
              {this.state.pOFormDataLoaded ||
                this.state.pOEditFormDataLoaded ? (
                <Loader />
              ) : (
                this.savePurchaseOrderFormJsx()
              )}

              {this.addTermsModalJsx()}
              {this.addTncInPOModalJsx()}
              {this.reqDetailModalJsx()}
              {this.warehouseAddressModalJsx()}
              {this.pickReqItemModalJsx()}
            </div>
          </div>
        </div>

        <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />
        <ItemEditModal
          parentProps={this.props}
          ref={this.editItemModalRef}
          afterSubmitItem={this.submitEditItem}
        />
        <ItemDetailsModal ref={this.itemDetailsRef} />
        <AddVendorModal
          parentProps={this.props}
          ref={this.addVendorModalRef}
          afterEditSubmit={(selectedVendor) =>
            this.handleVendorChange(selectedVendor)
          }
        />
        <ConsumptionHistoryModal ref={this.consumptionHistoryRef} />
        <POHistoryModal ref={this.poHistoryRef} />
        <ItemSearchModal
          ref={this.itemSearchRef}
          afterSelectingItems={(selectedItems) => {
            this.addItemsfromSearch(selectedItems);
          }}
        />
        <WarehouseAddress ref={this.addWarehouseAddressRef} />
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    all_warehouses:
      state.app && state.app.warehouses ? state.app.warehouses : [],
    orgInfoData: state.app.group_info ? state.app.group_info : null,
    permissions: state.app.acl_info,
    iam_group_sites:
      state.app && state.app.user_sites ? state.app.group_sites : [],
    default_currency:
      state.app && state.app.default_currency ? state.app.default_currency : "",
    currency_list:
      state.app && state.app.currency_list && state.app.currency_list.length > 0
        ? state.app.currency_list
        : [],
  };
};

export default connect(mapStateToProps)(PurchaseOrderAdd);
