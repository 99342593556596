import React from 'react';

import { connect } from "react-redux";
import HttpAPICall from "../../services/HttpAPICall";
import Ax from "../../components/hoc/Ax";

import Loader from "../../components/ui/Loader/Loader";
import Status from "../../components/ui/Status"

import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../services/TapApiUrls';
import moment from 'moment';
import { Link } from 'react-router-dom';

class ItemCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            itemData: [],
        }
    }

    id = this.props.item_enc_id;

    componentDidMount() {

        if (this.props && this.props.item_enc_id) {
            this.getItemDetails(this.props.item_enc_id)
        }
        if (this.props && this.props.itemData) {
            this.setState({ itemData: this.props.itemData })
        }
    }

    getItemDetails = (id) => {
        this.setState({ details_loading: true });
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/item/' + id, this.props.access_token, { opening_stock: "Y" }, {}, (resp) => {
            this.setState({ itemData: resp.data.data })
        }).then(() => { this.setState({ details_loading: false }) })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.item_enc_id !== this.props.item_enc_id) {
            this.getItemDetails(nextProps.item_enc_id)
        }
        if (nextProps.itemData !== this.props.itemData) {
            this.setState({ itemData: nextProps.itemData })
        }
        if (nextProps.refreshDetail == true) {
            this.getItemDetails(nextProps.item_enc_id)
        }
    }
    render() {
        return (
            <Ax>
                <div className="pageTbl bg-white">
                    {this.state.details_loading ? <Loader /> :
                        <div>
                            {this.state.itemData && (
                                <table className="table table-hover table-bordered bg-white">
                                    <tbody>
                                        <tr>
                                            {this.state.itemData?.image ?
                                                <td className="fs14 fw-normal" style={{ width: "15%", maxHeight: "14em" }}>
                                                    <img src={this.state.itemData?.image ? this.state.itemData?.image : ""} className="img-fluid d-block w-100"   />
                                                </td>
                                            : ""}
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                                Item Name  :<br />
                                                <a href= "#" className="pt-2"> {this.state.itemData?.name || "-"}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Item Code :<br />
                                                <div className="pt-2"> <b> {this.state.itemData?.item_code ?? '-'}</b></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Category  : <br />
                                                <div className="pt-2"> <b>  {this.state.itemData?.category_display ? this.state.itemData?.category_display : "-"}</b></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tags  : <br />
                                                <div className="pt-2"> <b> {
                                                    this.state.itemData?.tags?.length > 0
                                                        ? (this.state.itemData?.tags.map((item, k) => <span key={k} className="badge bg-secondary mt-1">{item.name}</span>))
                                                        : '-'
                                                }</b></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Manufacturer  : <br />
                                                <div className="pt-2"> <b>{this.state.itemData?.manufacturer?.name ?? '-'}</b></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Status  : <br />
                                                <td className="details-name pt-2">{this.state.itemData && this.state.itemData.status ? <Status color={this.state.itemData.status_color}>{this.state.itemData.status_text}</Status> : ""}</td>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    }

                </div>

            </Ax>

        )
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    }
}

export default connect(mapStateToProps)(ItemCard);
