import * as actionTypes from './actionTypes';

import axios from 'axios';
import HttpAPICall from '../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';



//***********************ASSET LISTING FUNCTIONS***************** *****/
export const assetMatrixListingDataInitilization   = () => {
    return {
        type                :   actionTypes.ASSET_MATRIX_LISTING_DATA_INIT
    };
};



export const assetMatrixListingDataLoadSuccessfully   = (listing,params,formSearchedElems) => {
    return {
        type                           :   actionTypes.ASSET_MATRIX_LISTING_DATA_SUCCESS,
        matrix_listing                 :   listing, 
        params                         :   params,
        formSearchedElems              :   formSearchedElems
    };
};


export const assetListingAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.ASSET_MATRIX_LISTING_DATA_FAIL
    };
};


//***********************ASSET MAtrix LISTING LOADING FUNCTION***************** *****/
export const assetMatrixListingDataLoading         =   (params,formSearchedElems)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
       
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset_matrix', access_token, {...params}, {} ,(response) => {
            let respData                =   response.data;
            dispatch(assetMatrixListingDataLoadSuccessfully(respData,params,formSearchedElems))
        })
            
        
    };
};