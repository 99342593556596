import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import Ax from '../../../../components/hoc/Ax';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2, INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import TapSelect from '../../../../components/ui/TapSelect';


class TermCondititon extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initAddTermForm    =   {
            key                     :   this.props.salesOrderKey ? this.props.salesOrderKey : 'quotation',
            name                    :   '',
            text                    :   '',
            tag_ids                 :   []
        }

        this.state              =   {
           listing_loading          :   false,
           termsListing             :   [],
           addTermFormSubmitting    :   false,
           addTermForm              :   {...this.initAddTermForm},
           edit                     :   false,
           tags                     :   [],
           modalLoader              :   false,
        }

        this.quoteTncListUrl    =   INVENTORY_API_BASE_URL_2  + "/term_condition/list";  
        this.quoteTncEditUrl    =   INVENTORY_API_BASE_URL_2  + "/term_condition/edit"; 
        this.quoteTncAddUrl     =   INVENTORY_API_BASE_URL_2  + "/term_condition/add"; 
        this.quoteTncDeleteUrl  =   INVENTORY_API_BASE_URL_2  + "/term_condition/delete";
        this.TagUrlList         =   IAM_API_BASE_URL_2 + '/sub_assembly_tag/list';                
    }

    componentDidMount(){
        this.addTermsModal      =   new Modal(document.getElementById('addTermsModal'), {keyboard: false, backdrop :false});
        this.getTermsListing();
    }
    
    getTermsListing         =       ()          =>      {
        this.setState({ listing_loading : true});
        HttpAPICall.withAthorization('GET', this.quoteTncListUrl, this.props.access_token,{key:this.props.salesOrderKey ? this.props.salesOrderKey : 'quotation'},{},(response) => {
            this.setState({termsListing : response.data.data})
         }).then(() => this.setState({listing_loading : false}));
    }

    addTermsModalInit     =       ()      =>      {

        this.addTermsModal.show(); 
        this.setState({
            edit : false,
            modalLoader : true
        },() =>  {
            HttpAPICall.withAthorization('GET', this.TagUrlList, this.props.access_token, { type: this.props.salesOrderKey }, {}, (resp) => {
                this.setState({ 
                    tags: resp.data && resp.data.length > 0 ? resp.data.map(val => ({label : val.name, value: val.id})) : [] 
                });
            }).then(() => {
                this.setState({modalLoader : false})
            });
        });
    }

    editTermForm            =       (id)      =>      {
        
        this.addTermsModal.show(); 
        this.setState({
            modalLoader : true
        },() =>  {
            HttpAPICall.withAthorization('GET', this.TagUrlList, this.props.access_token, { type: this.props.salesOrderKey }, {}, (resp) => {
                this.setState({ 
                    tags: resp.data && resp.data.length > 0 ? resp.data.map(val => ({label : val.name, value: val.id})) : [] 
                });
            }).then(() => {
                let termList         =  this.state.termsListing.find((term) => term.id == id);
                this.setState({
                    modalLoader : false,
                    addTermForm :  {
                        id         :   termList.id,
                        name       :   termList.name,
                        text       :   termList.text,
                        tag_ids    :   termList.tags.map(tag => tag.id)
                    },
                    edit        :   true
                });
            });
        });    
  
    }

    submitAddTermForm    =          (e)      =>      {
        e.preventDefault()
        let frmData = {...this.state.addTermForm}

        this.setState({addTermFormSubmitting: true})
           
        if(this.state.edit){
            HttpAPICall.withAthorization('PUT', this.quoteTncEditUrl, this.props.access_token, {} , {...frmData,key:this.props.salesOrderKey ? this.props.salesOrderKey : 'quotation'} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addTermsModal.hide();
                this.getTermsListing()
                this.setState({addTermForm  :  {...this.initAddTermForm},edit : false})
            }).then(() => this.setState({addTermFormSubmitting: false}))
        }else{
            HttpAPICall.withAthorization('POST', this.quoteTncAddUrl, this.props.access_token, {} , {...frmData,key:this.props.salesOrderKey ? this.props.salesOrderKey : 'quotation'} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addTermsModal.hide();
                this.getTermsListing()
                this.setState({addTermForm  :  {...this.initAddTermForm}})
            }).then(() => this.setState({addTermFormSubmitting: false}))
        }
     }

     
    deleteTerm       =       (id)      =>      {
        swal({
            title: "DELETE",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.quoteTncDeleteUrl, this.props.access_token, {key:this.props.salesOrderKey ? this.props.salesOrderKey : 'quotation', id}, {}, (response) => {
                        this.getTermsListing();
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                     })
                }
            });
     }

    //*********************************EDIT SETTING JSX*************************
    addTermsModalJsx              =   ()  =>  {

        return (
            <div className="modal fade" id="addTermsModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addTermsModalLabel">Terms & Condition</h5>
                        </div>
                        <form onSubmit={this.submitAddTermForm} id="addTermsForm" method="post" encType="multipart/form-data">
                        {
                            this.state.modalLoader 
                            ?
                            <Loader/>
                            :
                            <>
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label ">Name</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => { this.formInputHandler(e, 'addTermForm') }}
                                            value={this.state.addTermForm.name}
                                            placeholder="Please Enter Term Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label ">Tag</label>
                                    </div>
                                    <div className="col-sm-9">

                                        <TapSelect
                                            options={this.state.tags}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTermForm', 'tag_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.tags.filter(s => this.state.addTermForm.tag_ids.includes(s.value))}
                                            isMulti={true}
                                            placeholder="Select Tag"
                                        />
                                    </div>
                                </div>

                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require ">Value</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <textarea
                                            name="text"
                                            className="form-control"
                                            autoComplete="off"
                                            required
                                            placeholder="Please Enter Text"
                                            onChange={(e) => { this.formInputHandler(e, 'addTermForm') }}
                                            value={this.state.addTermForm.text}
                                            style={{ height: "80px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addTermFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addTermForm: { ...this.initAddTermForm } }) }}>Close</button>
                                <button type="submit" disabled={this.state.addTermFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addTermFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                            </>
                        }
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <Ax>
            {/* disabled={!this.props.permissions.includes('isc-configuration-edit')} */}
                <div className="tab_content_wrapper">
                    <span className="content_heading">Terms and Condition</span>
                    <div className="float-end"><button className="btn btn-primary" onClick={this.addTermsModalInit} >Add </button></div>
                </div>
                <table className="table table-hover table-bordered my-3 ">
                    <thead className='table-secondary'>
                        <tr>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Sr.No</th>
                            <th scope="col" style={{ width: "25%" }}>Name</th>
                            <th scope="col" style={{ width: "35%" }}>Value</th>
                            <th scope="col" style={{ width: "25%" }}>Tag</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {this.state.listing_loading
                            ? <tr>
                                <td className="text-center" colSpan="5"><Loader /></td>
                            </tr>
                            :
                            (this.state.termsListing && this.state.termsListing.length > 0
                                ? this.state.termsListing.map((term, index) => {
                                    return (<tr key={term.id}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>{term.name}</td>
                                        <td>{term.text}</td>
                                        <td>
                                            {term.tags.length > 0 &&
                                               term.tags.map(tag => tag.name).join(', ')
                                            }
                                        </td>
                                        <td className="ml-2 text-center">
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                                data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                                            />
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <Link role="button"
                                                        onClick={() => {
                                                            return this.editTermForm(term.id)

                                                            // if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                            //     return this.editTermForm(term.id)
                                                            // }
                                                        }}
                                                        // , this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'
                                                        className={['dropdown-item cursor_pointer'].join(' ')}
                                                        title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Edit</Link>
                                                </li>
                                                <li>
                                                    <Link role="button"
                                                        onClick={() => {
                                                            if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                                return this.deleteTerm(term.id)
                                                            }
                                                        }}
                                                        className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Delete</Link>
                                                </li>
                                                
                                            </ul>
                                        </td>
                                    </tr>)
                                })
                                : <tr><td className="text-center" colSpan="4">No Record</td></tr>)
                        }
                    </tbody>
                </table>
                {this.addTermsModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(TermCondititon);