import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapHelper from '../../../services/TapHelper';
import TapIcon from '../../../services/TapIcon';
import PageHeader from '../../includes/PageHeader';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import DownloadFile from '../../../services/DownloadFile';
import { toast } from 'react-toastify';
import swal from "sweetalert";
const ServiceCheckSheetHistoryAnalysisReport  =   forwardRef((props) => {

    let initListData = {
        loading                                     :     false,
        data_loaded                                 :     false,
        show                                        :     false,
        items                                       :     [],
        meta                                        :     null,
        page                                        :     1
    };

    let initFormData = {
        show                                        :     true,
        per_page                                    :     50,
        form_data_loaded                            :     false,
        loading                                     :     false,
        downloading                                 :     false,
        submitting                                  :     false,
        periods                                     :     [],
        site_groups                                 :     [],
        selected_site_groups                        :     [],
        date_range                                  :     null,
        date_range_start                            :     null,
        date_range_end                              :     null,
        reset_filter                                :     false,
        selected_assets                             :     [],
        search_asset_ids                            :     [],
        sites                                       :     [],
        search_site_ids                             :     [],
        search_scs_name                                 :     '',
        search_transaction_id                       :     '',
    };
              
    let formDataLabel = {
        date_range                                  :     'Date Range : ',
        selected_site_groups                        :     'Site Group  : ',
        search_site_ids                             :     'Sites : ',
        search_scs_name                             :     'Service Checksheet Name : ',
        search_transaction_id                       :     'Transaction ID  : ',
    };

    const access_token          =     useSelector(state => state.auth.access_token);
    const all_periods           =     useSelector(state => state.app.all_periods ? state.app.all_periods   : []);
    const user_sites            =     useSelector(state => state.app.user_sites ? state.app.user_sites     : []);
    const site_groups           =     useSelector(state => state.app.site_groups ? state.app.site_groups   : []);

    const [scsHistoryData, setScsHistoryData]                   =    useState({ ...initListData });
    const [formData, setFormData]                     =    useState({ ...initFormData });
    const [searchedElems, setSearchedElems]           =    useState([]);
    const [submittedFormData, setSubmittedFormData]   =    useState({ ...initFormData });

    let scsHistoryReportUrl = IAM_API_BASE_URL_2 + "/report/service_execution_history";

    useEffect(() => {
        toggleFilterForm(true);
    }, []);

    useEffect(() => {
        if (formData.show && !formData.form_data_loaded) {
            loadFormData();
        }
    }, [formData.show]);

    const toggleFilterForm                =  (show)     => {
        setFormData(pd => ({ ...pd, show: show }))
        if (document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    const loadFormData                    =  ()         => {
        setFormData(pd => ({
            ...pd,
            form_data_loaded  :   true,
            loading           :   false,
            periods           :   [...all_periods, { key: 'custom', display: 'Custom Date Range' }],
            site_groups       :   site_groups.map((s) => { return { ...s, value: s.id, label: s.name } }),
            sites             :   user_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }),
        }));
    }

    const closeReport = () => props.history.push({ pathname: `/report` })

    const submitSearchFormHandler         =  (e)        => {
        e.preventDefault();
        toggleFilterForm(false);
        let searchedElems = [];
        let searchParams = {};
        Object.keys(formData).map((key) => {
            let label = formDataLabel[key];
            let value = formData[key];
            if (value && value.length > 0) {
                searchParams[key] = value;
                if (label) {
                    let show_val = value;
                    if (key == 'search_asset_ids') {
                        show_val = formData.selected_assets ? formData.selected_assets.map(s => s.display_label).join(', ') : '';
                    }
                    if (key == 'search_site_ids') {
                        show_val = formData.sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'date_range') {
                        show_val = formData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    if (key == 'selected_site_groups') {
                        show_val = formData.site_groups.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_scs_name') {
                        show_val            =  formData.search_scs_name   ;
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  formData.search_transaction_id   ;
                    }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        setSubmittedFormData(pd => ({ ...pd, ...formData }));
        setSearchedElems(searchedElems);
        loadItemsData(1);
    }
    const loadItemsData                   =  (page = 1) => {
        setScsHistoryData(pd => ({ ...pd, show: true, data_loaded: false, loading: true, page: page }));
        let params = {
            action   : 'data',
            page     : page,
            ...TapHelper.pick(formData, [ 'search_asset_matrix_ids', 'search_site_ids', 'search_asset_ids', 'date_range', 'date_range_start', 'date_range_end', 'search_asset_ids', 'search_site_ids', ])
        };
        HttpAPICall.withAthorization('GET', scsHistoryReportUrl, access_token, params, {}, (response) => {
            let respData   =  response.data;
            let meta       =  TapHelper.pick(respData, ['from', 'to', 'total', 'last_page', 'per_page', 'current_page']);
            setScsHistoryData(pd => ({ ...pd, data_loaded: true, items: respData.data, meta: meta, sites: response.data.sites, }));
        }).then(() => {
            setScsHistoryData(pd => ({ ...pd, loading: false }));
        });
    }
   const  activityExceldownload           =   ()  =>  {
        swal({
            title: "Download",
            text: "The download will be for trailing three months period, in case you want a different criteria then please use search button before downloading.",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    reportDownloadHandler()
                }
            });
    }


    const reportDownloadHandler           =  ()         => {
        const paramsFormData  =   {...TapHelper.pick(formData, ['date_range', 'date_range_start', 'date_range_end', 'search_asset_ids', 'search_site_ids',])};
        let params = {
            ...paramsFormData,
            action: 'download',
            date_range : paramsFormData.date_range ? paramsFormData.date_range : 'current_quarter',
        };
        
        setScsHistoryData(pd => ({ ...pd, downloading: true }));
        HttpAPICall.withAthorization('GET', scsHistoryReportUrl, access_token, params, {}, (response) => {
            DownloadFile.file(response.data.file_path)
            toast.success('File Downloaded Successfully', {position: toast.POSITION.TOP_RIGHT})
        }).then(() => {
            setScsHistoryData(pd => ({ ...pd, downloading: false }));
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        });
    }
    const formInputHandler = (e, afterCallbackFn = null) => {
        const { name, value, type } = e.target;
        const newValue = type === 'number' ? (value ? Number(value) : value) : value;
    
        setFormData(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    
        if (afterCallbackFn) {
            afterCallbackFn();
        }
    };
    

    const filterFormJsx                   =  ()         => {
        return <form onSubmit={submitSearchFormHandler} className='py-3'>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Transaction ID</label>
                <div className="col-sm-6">
                    <input
                        name="search_transaction_id"
                        type="text"
                        value={formData.search_transaction_id}
                        onChange={formInputHandler}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Search transaction id"
                    />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Service Checksheet Name</label>
                <div className="col-sm-6">
                    <input
                        name="search_scs_name"
                        type="text"
                        value={formData.search_scs_name}
                        onChange={formInputHandler}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Service Checksheet Name "
                    />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                <div className="col-sm-6">
                    <InputAssetSearch
                        placeholder="Search Assets"
                        filter={true}
                        isMulti={true}
                        value={formData.selected_assets ? [...formData.selected_assets] : []}
                        changeEvent={(so) => {
                            setFormData(d => ({
                                ...d,
                                search_asset_ids: so ? so.map(s => s.value) : [],
                                selected_assets: so && so.length > 0 ? so.map(a => { a.label = a.display_label; return a; }) : []
                            }));
                        }} />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Site Group</label>
                <div className="col-sm-6">
                    <TapSelect
                        options={formData.site_groups}
                        changeEvent={so => {
                            setFormData(d => ({
                                ...d,
                                selected_site_groups: so.map(e => e.value),
                                search_site_ids: so.length > 0 ? so.map(s => s.site_ids).flat() : []
                            }));
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={formData.site_groups.filter(s => formData.selected_site_groups.includes(s.value))}
                        placeholder="Select Site Group"
                    />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Site Name</label>
                <div className="col-sm-6">
                    <TapSelect
                        changeEvent={o => setFormData(d => ({ ...d, search_site_ids: o.map(e => e.value) }))}
                        placeholder="Select Sites"
                        isClearable={true}
                        options={formData.sites}
                        value={formData.sites.filter(s => formData.search_site_ids.includes(s.value))}
                        isMulti={true}
                    />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Transaction Period</label>
                <div className="col-sm-6">
                    <PeriodSelectionWithCustom
                        periods={formData.periods ? formData.periods : []}
                        className="form-control-sm"
                        onSelectPeriod={(period, startDate, endDate) => {
                            setFormData(d => ({
                                ...d,
                                date_range: period,
                                date_range_start: startDate ? startDate : null,
                                date_range_end: endDate ? endDate : null
                            }))
                        }}
                        // required={true}
                        value={formData.date_range}
                        startDate={formData.date_range_start}
                        endDate={formData.date_range_end}
                    />
                </div>
            </div>
            <div className="row my-4  border-bottom">
                <div className="pb-3 col-sm-9 text-end">
                    <div className='d-flex justify-content-end align-items-center gap-2'>
                        <button type="submit" className="btn btn-primary px-4">Show Report</button>
                    </div>
                </div>
            </div>
        </form>
    }

    const voucherListJsx                  =  ()         => {
        return <>
            <DisplaySearchCriteria searchedElems={searchedElems} />
            <table className="table table-sm table-bordered align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr className='text-center'>
                        <th style={{ width: "20%" }}>Asset</th>
                        <th style={{ width: "25%" }}>Service CheckSheet Name</th>
                        <th style={{ width: "10%" }}>Site</th>
                        <th style={{ width: "10%" }}>Previous SCS</th>
                        <th style={{ width: "10%" }}>Current SCS</th>
                        <th style={{ width: "10%" }}>Execution Interval</th>
                        <th style={{ width: "10%" }}>Recommended Interval</th>
                        <th style={{ width: "7%" }}>Deviation</th>
                        <th style={{ width: "10%" }}>Execution Percentage</th>
                    </tr>
                </thead>
                <tbody>
                    {scsHistoryData.loading
                        ? <tr><td className='text-center' colSpan={9}><Loader /></td></tr>
                        : ((scsHistoryData.items && scsHistoryData.items.length > 0)
                            ? scsHistoryData.items.map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.asset_profile.name || "-"}<br /></td>
                                    <td>
                                        {item.service_checksheet_name || "-"}
                                    </td>
                                    <td>
                                       {item?.site?.site_name || "-"}
                                    </td>
                                    <td className='text-center'>{item.interval.map((d, i) => {
                                        return (<>
                                            {d.prev_execute_service || "-"}<br />
                                        </>)
                                    })}</td>
                                    <td className='text-center'>{item.interval.map((d, i) => {
                                        return (<>
                                            {d.execute_service || "-"} <br></br>
                                        </>)
                                    })}</td>
                                    <td className='text-center'>{item.interval.map((d, i) => {
                                        return (<>
                                            {d.execution_interval || "-"} <br></br>
                                        </>)
                                    })}</td>
                                    <td className='text-center'>{item.interval.map((d, i) => {
                                        return (<>
                                            {d.interval || "-"} <br></br>
                                        </>)
                                    })}</td>
                                    <td className='text-center'>{item.interval.map((d, i) => {
                                        return (<>
                                            {d.deviation || "-"} <br></br>
                                        </>)
                                    })}</td>
                                    <td>{item.interval.map((d, i) => {
                                        return (<>
                                            {d.execution_percentage ? `${d.execution_percentage} %` : "-"}
                                            <br></br>
                                        </>)
                                    })}</td>
                                </tr>
                            })
                            : <tr><td className='text-center' colSpan={8}>No Record</td></tr>)
                    }
                </tbody>
            </table>
            <DisplayListPagination meta={scsHistoryData.meta} onPageChange={(e) => loadItemsData(e.selected + 1)} />
        </>
    }

    const topRightHeaderJsx               =  ()         => {
        return <>
            <div className="text-end mt-2">
                <button type="button" disabled={scsHistoryData.downloading || scsHistoryData.loading || formData.loading} className="btn btn-secondary" onClick={() => toggleFilterForm(!formData.show)} >
                    Change Filter
                </button>
                {scsHistoryData.data_loaded && <button type="button" disabled={scsHistoryData.downloading || scsHistoryData.loading || formData.loading} className="btn btn-secondary" onClick={activityExceldownload}>
                    <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                    {scsHistoryData.downloading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />}
                </button>}
                <button type="button" className="btn btn-secondary mx-1" onClick={closeReport}>
                    <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                </button>
            </div>
        </>
    }

    return <ApplicationLayout>
        <PageHeader title="SCS Execution History & Analysis Report" name="SCS Execution History & Analysis Report" rightTopHeadingJsx={topRightHeaderJsx()} />
        <div className="container-fluid pl5">
            <div className="page_containt row">
                <div id="pageTbl" className="pageTbl col-sm-12 pb-3">
                    <div className={["bg-white", formData.show ? 'tbShow' : 'tbHide'].join(" ")} >
                        {formData.loading ? <Loader /> : filterFormJsx()}
                    </div>
                    {scsHistoryData.show && voucherListJsx()}
                </div>
            </div>
        </div>
    </ApplicationLayout>

});

export default ServiceCheckSheetHistoryAnalysisReport;