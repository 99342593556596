import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapApiUrls, { APP_BASE_URL_2, INVENTORY_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import TapSelect from '../../components/ui/TapSelect';
import TapIcon from '../../services/TapIcon';
import Loader from '../../components/ui/Loader/Loader';
import {Modal} from "bootstrap";
import { Link } from 'react-router-dom';
import Ax from '../../components/hoc/Ax';

class AddtionalAttribute extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.attrInitForm        =       {
            name                 :       '',
            type                 :       '',
        }

        this.state                      =   {
            category                :       '',
            listing_loading         :         false,
            attribute_list          :          [],
            saveFormSubmitting      :         false,
            selectedAttribute       :          null,
            addAttrForm             :          { ...this.attrInitForm },
            options                 :          ['Option 1', 'Option 2'],
            edit                    :          false
        }

        this.attributeUrl           =       APP_BASE_URL_2 + '/isc/additional_attribute';
    }

    componentDidMount() {
        this.initilaizeFormData(this.props);
        this.additionalAttributeModal         =    new Modal(document.getElementById('additionalAttributeModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.category !== this.props.category) {
            this.initilaizeFormData(nextProps)
        }    
    }
    
    initilaizeFormData      =       (pr)        =>      {
        this.setState({
            category       :       pr.category ? pr.category : ''
        },() => {
            this.getAdditionalAttributes()
        })
    }

    getAdditionalAttributes = () => {
        this.setState({ listing_loading: true })
        HttpAPICall.withAthorization('GET', this.attributeUrl, this.props.access_token, {category : this.state.category}, {}, (resp) => {
            this.setState({attribute_list: resp.data.data })
            if(this.props && this.props.getAttributeList){
                this.props.getAttributeList(resp.data.data)
            }
        }).then(() => this.setState({ listing_loading: false }));
    }

    additionalAttributeModalInit       =       (id = null)      =>      {
        this.additionalAttributeModal.show() 
        
        if(id !== null && id !== undefined){
            let additionalAttributeAttrlist = this.state.attribute_list.find(function (attr) {
                return attr.id == id;
            });
          
           if(additionalAttributeAttrlist){
                this.setState({
                    edit                :       true,
                    addAttrForm: {
                        ...this.attrInitForm,
                        name            :       additionalAttributeAttrlist.name,
                        type            :       additionalAttributeAttrlist.type,
                        key             :       additionalAttributeAttrlist.key,
                    },
                    options             :       additionalAttributeAttrlist.options.length > 0 ? additionalAttributeAttrlist.options.map(i => i) : []
                })
           }
        }else{
            this.setState({addAttrForm: this.attrInitForm, edit: false })
            let form = document.getElementById('rentalAttributeForm')
            form.reset(); 
        }
         
    }

    AllAttributeTypes = [
        { value: 'text',        label: 'Text' },
        { value: 'numeric',     label: 'Numeric' },
        { value: 'date',        label: 'Date' },
        { value: 'datetime',    label: 'Date Time' },
        { value: 'dropdown',    label: 'Dropdown' }
    ]

    handleOptionValue = (e, i) => {
        let options = [...this.state.options]
        options[i] = e.target.value
        this.setState({
            options
        })
    }

    handleDeleteOption = i => e => {
        e.preventDefault()
        if (i >= 0) {
            let options = [
                ...this.state.options.slice(0, i),
                ...this.state.options.slice(i + 1)
            ]
            this.setState({
                options
            })
        }
    }

    addOption = e => {
        e.preventDefault()
        let options = this.state.options.concat([''])
        this.setState({
            options
        })
    }

    submitAdditonalAttribute          =      (e)    =>  {
        e.preventDefault()
        this.setState({saveFormSubmitting : true})
        let frmData ;
            if(this.state.addAttrForm.type == "dropdown"){
                frmData = {...this.state.addAttrForm,options: this.state.options}
            }else{
                frmData = {...this.state.addAttrForm}
            }
        if(this.state.edit){
            HttpAPICall.withAthorization('PUT', this.attributeUrl, this.props.access_token, {}, { ...frmData,category : this.state.category}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.additionalAttributeModal.hide()
                this.getAdditionalAttributes()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ saveFormSubmitting: false }))
        }else{
            HttpAPICall.withAthorization('POST', this.attributeUrl, this.props.access_token, {}, { ...frmData,category : this.state.category}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.additionalAttributeModal.hide()
                this.getAdditionalAttributes()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ saveFormSubmitting: false }))
        }
       
    }

    deleteAddionalAttr                  =           (id)            =>          {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {

                    HttpAPICall.withAthorization('DELETE', this.attributeUrl + '/' + id, this.props.access_token, {  }, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getAdditionalAttributes()
                    }).then(() => { });
                }
            });
    }
         

    additionalAttributeModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="additionalAttributeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">{this.state.edit == true ? "Update" : "Add"} Additional Attribute</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="rentalAttributeForm" onSubmit={this.submitAdditonalAttribute}>
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <label className="col-md-3 col-form-label col-form-label-sm">Attribute Name</label>
                                    <div className="col-md-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addAttrForm.name}
                                            onChange={(e) => this.formInputHandler(e, 'addAttrForm')}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            required={true}
                                            placeholder="Enter Attribute Name"

                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mt-3 mb-1">
                                    <label className="col-md-3 col-form-label col-form-label-sm">Type</label>
                                    <div className="col-md-9">
                                        <TapSelect options={this.AllAttributeTypes}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addAttrForm', 'type')}
                                            value={this.AllAttributeTypes.find(a => a.value == this.state.addAttrForm.type)}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            placeholder="Please Select Attribute Type"
                                            containerHeight="33px"
                                            isDisabled={this.state.edit === true ? true : false}
                                            fontSize="93%" />
                                    </div>
                                </div>
                                <div className="row align-items-center my-1">
                                    <div className="offset-3 col-sm-9">
                                        {this.state.addAttrForm.type == "dropdown" ?
                                            <div>{this.state.options.length >= 2 &&
                                                this.state.options.map((i, index) => {
                                                    return (
                                                        <div className="row" key={index}>
                                                            <div className={this.state.options.length > 2 ? "col-sm-10" : "col-sm-12"}>
                                                                <input
                                                                    name={i}
                                                                    type="text"
                                                                    className="form-control my-2"
                                                                    value={i}
                                                                    // disabled={this.state.edit === true ? true : false}
                                                                    placeholder="Please Enter Options"
                                                                    required={true}
                                                                    onChange={(e) => this.handleOptionValue(e, index)}
                                                                />
                                                            </div>
                                                            {this.state.options.length > 2 ?
                                                                <div className="col-sm-2"><button className="btn btn-secondary my-3"
                                                                    onClick={this.handleDeleteOption(index)}>X</button></div> : null}
                                                        </div>
                                                    )
                                                })}
                                                <div className="link-primary text-end cursor_pointer" onClick={this.addOption}>Add New Option</div>
                                            </div>
                                            : null}
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        
        return <div className="bg-white pb-5">
                <div className="tab_content_wrapper mt-2"><span className="content_heading">Additonal Attributes</span>
                    <div className="text-end "><button className="btn btn-primary" onClick={() => this.additionalAttributeModalInit()}>Add</button></div>
                </div>
                <table className="table table-hover table-bordered my-3 table-sm ">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{ width: "10%" }} className="text-center">S.No</th>
                            <th scope="col" className="text-start">Name</th>
                            <th scope="col" style={{ width: "15%" }}>Type</th>
                            <th scope="col" style={{ width: "35%" }}>Notes</th>
                            <th scope="col" style={{ width: "10%" }} className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? (<tr><td colSpan="5"><Loader /></td></tr>)
                            : (this.state.attribute_list.length > 0
                                ? (this.state.attribute_list.map((item, k) => {
                                    return (<tr key={k}>
                                        <td className="text-center" >{k + 1}</td>
                                        <td className="text-capitalize ">{item.name}</td>
                                        <td className="text-capitalize">{item.type}</td>
                                        
                                        <td>{item.options !== null && item.options.length > 1 ?
                                            <div>
                                                {item.options.map((itemNew, index) => {
                                                    return (
                                                        <span key={index}>{itemNew} {index < item.options.length - 1 ? ", " : ""}</span>
                                                    );
                                                })}
                                            </div> : "-"}</td>
                                        <td className='text-center'>  <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                            role="button" aria-expanded="false" />
                                            <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                                <li>
                                                    <Link role="button"
                                                        onClick={() => {
                                                                    this.additionalAttributeModalInit(item.id)
                                                        }}
                                                        className={['dropdown-item cursor_pointer'].join(' ')}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Edit</Link>
                                                </li>
                                                <li>
                                                    <Link role="button"
                                                        onClick={() => {
                                                                    this.deleteAddionalAttr(item.id)
                                                        }}
                                                        className={['dropdown-item cursor_pointer'].join(' ')}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Delete</Link>
                                                </li>
                                                
                                            </ul>
                                        </td>
                                    </tr>);
                                }))
                                : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                            )
                        }
                    </tbody>
                </table>
            {this.additionalAttributeModalJsx()}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(AddtionalAttribute);