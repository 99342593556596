import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';

class DocumentList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.documentSearchInitState         =   {
            'search_by_name'                 :   '',
            'search_by_number'               :   '',
            'search_asset_ids'               :   [],
            'search_link_types'              :   []
        };

        this.documentSearchFormLabel         =   {
            'search_by_name'             :   'Document Name : ',
            'search_by_number'           :   'Document Number : ',
            'search_asset_ids'           :   'Assets : ',
            'search_link_types'          :    'Document Types : '
        };

        this.state                      =     {
            minimizeTable                :     false,
            listing_tbl_page             :     1,
            listing_loading              :     false,
            totalListingCount            :     0,
            listingMeta                  :     null,
           document_listing              :     [],
           shiftDetailLoading            :     false,
           assetData                     :     null,
           counterlog                    :     null,
           shift                         :     null,
           shiftCounterLogDetail         :     null,
           shiftViewId                   :     '',
           documentSearchForm            :     {...this.documentSearchInitState},
           submittedDocumentSearchForm   :     {...this.documentSearchInitState},
           formSearchedElems             :     [],
           searchedAssets                :     [],
           total_document_size           :     0,
           total_document_no             :     0,
           summary_loading              :      false,
           allDocumentTypes             :       [
                                                  {value:'asset',label:'Asset'}  ,
                                                  {value:'user_profile',label:'User Profile'},
                                                  {value:'site',label:'Site'},
                                                  {value:'ticket',label:'Ticket'},
                                                  {value:'shift',label:'Shift Counterlog'}    
                                                ]
        };

        this.docListUrl         =   ORG_API_BASE_URL_2 + '/document/listing';
        this.docSummaryUrl      =   ORG_API_BASE_URL_2 + '/document/summary'
         
    }
    
    
    componentDidMount() {
       this.documentSearchFormSubmit()
       this.documentSearchModal                    =    new Modal(document.getElementById('documentSearchModal'), {keyboard: false, backdrop :false});
       this.shiftDetailModal                =      new Modal(document.getElementById('shiftDetailModal'), {keyboard: false, backdrop :false});
    }

    documentSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.documentSearchForm);
        let searchedElems               =   [];
        let documentSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.documentSearchFormLabel[key];
            let value                       =   this.state.documentSearchForm[key];
            if(value && value.toString().length > 0) {
                documentSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_by_name') {
                        show_val            =   this.state.documentSearchForm.search_by_name;
                    }
                    if(key == 'search_by_number') {
                        show_val            =   this.state.documentSearchForm.search_by_number;
                    }
                   
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_link_types') {
                        show_val            =   this.state.allDocumentTypes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedDocumentSearchForm         :   {...this.state.documentSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.documentSearchModal.hide();
            this.loadListingTblData(1)
            this.fetchDocumentSummary()
        });
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedDocumentSearchForm};
        HttpAPICall.withAthorization('GET', this.docListUrl, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                document_listing        :           respData.data,
                
                    listingMeta         :           {
                        from            :           respData.from,
                        to               :          respData.to,
                        total            :          respData.total,
                        last_page        :          respData.last_page,
                        current_page    :           respData.current_page
                    },
                
                totalListingCount       :           respData.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    fetchDocumentSummary                 =   ()  =>         {
        this.setState({summary_loading: true});
        let params                      =   {...this.state.submittedDocumentSearchForm};
        HttpAPICall.withAthorization('GET', this.docSummaryUrl, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                total_document_size     :      respData.total_size,
                total_document_no       :      respData.total_no
            });
        }).then(() => this.setState({summary_loading: false}));
    }

    documentSearchModalInit     =       ()      =>      {
        this.documentSearchModal.show()
    }

    documentSearchClear         =       ()     =>      {
        this.setState({
            documentSearchForm            :   {...this.documentSearchInitState},
            submittedDocumentSearchForm   :   {...this.documentSearchInitState},
            formSearchedElems             :   [],
            searchedAssets                :     []
        }, () => {
            this.documentSearchModal.hide();
            this.loadListingTblData(1);
            this.fetchDocumentSummary()
        });
    }
    

    shiftDocumentShowHandler        =   (id)        =>      {
        this.shiftDetailModal.show();
        this.fetchShiftDetail(id);
        this.setState({shiftViewId : id})
        
    }

    fetchShiftDetail                    =   (id) => {
        
        this.setState({shiftDetailLoading : true});
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/shift_detail/' + id, this.props.access_token, {}, {}, (resp) => {
             this.setState({
                assetData                   :   resp.data.asset,
                counterlog                  :   resp.data.counterlog,
                shift                       :   resp.data.shift,
                shiftCounterLogDetail       :   resp.data,
            });
            
        }, () => {
            
        }).then(() => {
            this.setState({shiftDetailLoading : false});
        });
    }
   
     //***********************fuelStation JSX****************

    documentListjsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Document </h3>
                <div className="text-end mt15">
                    <button type="button" disabled={this.state.listing_loading} onClick={this.documentSearchModalInit} className="btn btn-secondary" >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>

                </div>
            </div>
            
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.documentListingTableJsx()}</div>
                 
                </div>
            </div>
        </Ax>);
    }

    documentListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" style={{ height: "100%" }}>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.documentSearchClear}   /> 
            <div className="py-1">
                {this.state.summary_loading ? <Loader />
                    : <Ax><div className="row p-2">
                        <div className="col">Total Document Number : <span className='fw500' >{this.state.total_document_no ? this.state.total_document_no : '0'}</span> </div>
                        <div className="col text-end">Total Document Size : <span className='fw500' >{this.state.total_document_size ? this.state.total_document_size : '0'}</span>  </div>
                    </div></Ax>}
            </div>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr>
                        <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                        <th scope="col" style={{ width: "10%" }}>Category</th>
                        <th scope="col" style={{ width: "20%" }}> Associated To</th>
                        <th scope="col" style={{ width: "20%" }}>Document Location</th>
                        <th scope="col" style={{ width: "15%" }}>Document Name</th>
                        <th scope="col" style={{ width: "15%" }}>Document Number</th>
                        <th scope="col" style={{ width: "10%" }}>Size</th>
                        <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="8"><Loader /></td></tr>)
                        : (this.state.document_listing.length > 0
                            ? (this.state.document_listing.map((document, index) => {
                                return (<tr key={index} >

                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td>{document.category ? document.category : "-"}</td>
                                    <td>{document.associated_to ? document.associated_to : "-"}</td>
                                    <td>
                                        {
                                            document.link_type == 'item' && document.document_location && document.document_location.id && <Link to={{ pathname: `/item_list/document/${document.document_location.id}`, search: "?" + new URLSearchParams({ 'item_name': document.document_location.name, 'category' : document.document_location.category}).toString() }} target="_blank" >View</Link> 
                                        }
                                        {document.link_type == 'ticket' && document.document_location && document.document_location.activity_id && <Link to={{ pathname: "/ticket", search: "?" + new URLSearchParams({ 'ticket_id': document.document_location && document.document_location.activity_id ? document.document_location.activity_id : '' }).toString() }} target="_blank" >Ticket ({document.document_location.activity_id})</Link> }

                                        {document.link_type == 'asset' && document.document_location && document.document_location.id && <Link to={{ pathname: `/asset/document/${document.document_location.id}` }} target="_blank" >Asset Document</Link>}

                                        {document.link_type == 'shift' && document.document_location && document.document_location.link_id && <Link onClick={() => this.shiftDocumentShowHandler(document.document_location.link_id)}>Shift ({document.document_location.link_id})</Link>}

                                        {document.link_type == 'user_profile' && document.document_location && document.document_location.id && <Link to={{ pathname: "/user_list", search: "?" + new URLSearchParams({ 'user_id': document.document_location && document.document_location.id ? document.document_location.id : '' }).toString() }} target="_blank" >User</Link>}

                                        {document.link_type == 'site' && document.document_location && document.document_location.id && <span>Site</span>}

                                        {document.link_type == 'employee_compliance' && document.document_location && document.document_location.employee_id && <Link to={{pathname:"/employee_list", search: "?" + new URLSearchParams({ 'id': document.document_location.employee_id}).toString()}} target="_blank">{document.document_location.compliance_type}</Link>}

                                        {document.link_type == 'sub_assembly' && document.document_location && document.document_location.id && <Link to={{ pathname: `/sub_assembly/${document.document_location.id}` }} target="_blank" >{document.document_location.card_number}</Link>}

                                        {document.link_type == 'expense' && document.document_location && document.document_location.transaction_id && <Link to={{pathname:"/expense_list", search: "?" + new URLSearchParams({ 'transaction_id': document.document_location.transaction_id}).toString()}} target="_blank">View</Link>}


                                    </td>
                                    <td>{document.name ? document.name : '-'}</td>
                                    <td>{document.number ? document.number : '-'}</td>
                                    <td>{document.size_display ? document.size_display : '-'}</td>


                                    <td className="text-center">

                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu cursor_pointer">
                                            <li> <a target='_blank' href={document.file} className='dropdown-item'>View</a>   </li>
                                            <li> <a target='_blank' href={document.file_download} className='dropdown-item'>Download</a>   </li>
                                        </ul>
                                    </td>

                                </tr>)
                            }))
                            : (<tr><td colSpan="8" className="text-center">No Records</td></tr>)
                        )
                    }



                </tbody>
            </table>

            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
           
        </div>)
    }

    shiftDetailModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="shiftDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="shiftDetailModalLabel">Shift Detail for {this.state.shiftViewId ? this.state.shiftViewId : "-"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            {this.state.shiftDetailLoading
                                ? <Loader />
                                : <Ax>
                                    <Ax>
                                        <div className="tab_content_wrapper fw-bold my-2">Asset Details</div>
                                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Asset Name</td>
                                                    <th>{this.state.assetData ? this.state.assetData.name : '-'}</th>

                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Code</td>
                                                    <th>{this.state.assetData ? this.state.assetData.asset_code : '-'}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "25%" }}>Asset Type</td>
                                                    <th>{this.state.assetData ? this.state.assetData.asset_type_name : ''}</th>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </Ax>

                                    <div className="tab_content_wrapper fw-bold my-2">Shift Details</div>
                                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "25%" }}>Roster Name</td>
                                                <th>{this.state.shift ? this.state.shift.roster_name : ''}</th>

                                            </tr>
                                            <tr>
                                                <td style={{ width: "25%" }}>Shift Name</td>
                                                <th>{this.state.shift ? this.state.shift.shift_name : ''}</th>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "25%" }}>Shift Start Time</td>
                                                <th>{this.state.shift ? this.state.shift.shift_start : ''}</th>
                                            </tr>

                                        </tbody>
                                    </table>


                                    <div className="tab_content_wrapper fw-bold my-2">Counter Log Details

                                    </div>
                                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                        <tbody>
                                            {this.state.counterlog && this.state.counterlog.display && this.state.counterlog.display.length > 0 ? this.state.counterlog.display.map(counterlog => {
                                                return (

                                                    <tr>
                                                        <td style={{ width: "25%" }}>{counterlog.label}</td>
                                                        <th>{counterlog.reading} {counterlog.unit}</th>

                                                    </tr>


                                                )
                                            }) : <tr><td className='text-center'>No Counterlog</td></tr>}
                                            <tr>
                                                <td>Site</td>
                                                <th>{this.state.counterlog && this.state.counterlog.site ? <span>{this.state.counterlog.site.site_name} </span> : ''}</th>
                                            </tr>
                                            <tr>
                                                <td>Remarks</td>
                                                <th>{this.state.counterlog ? <span>{this.state.counterlog.remarks}</span> : '-'}</th>
                                            </tr>
                                            {this.props.permissions && this.props.permissions.group_modules && this.props.permissions.group_modules.includes("hrm")
                                                ? <tr>
                                                    <td>Operator</td>
                                                    <th>{this.state.shift && this.state.shift.operators && this.state.shift.operators.length > 0 ? <ol>{this.state.shift.operators.map(operator => { return (<li>{operator.display_full_name}</li>) })}</ol> : '-'} </th>
                                                </tr> : null}
                                        </tbody>
                                    </table>
                                    <div className="tab_content_wrapper fw-bold my-3">Transaction Analysis</div>
                                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "25%" }}>Transaction ID</td>
                                                <th>{this.state.counterlog ? this.state.counterlog.transaction_id : ''}</th>
                                            </tr>
                                            <tr>
                                                <td>Date of Entry</td>
                                                <th>{this.state.counterlog ? this.state.counterlog.created_at_display : ''}</th>
                                            </tr>
                                            <tr>
                                                <td>Transaction Entered By</td>
                                                <th>{this.state.counterlog && this.state.counterlog.user ? this.state.counterlog.user.full_name : ''}</th>
                                            </tr>
                                        </tbody>
                                    </table>

                                </Ax>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" disabled={this.state.saveDetailFormSubmitting} data-bs-dismiss="modal" >Close</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    documentSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="documentSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="documentSearchModalLabel">Document Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.documentSearchFormSubmit} id="documentSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="search_by_name"
                                            className="form-control form-control-sm"
                                            placeholder="Enter user Name"
                                            autoComplete="off"
                                            value={this.state.documentSearchForm.search_by_name}
                                            onChange={(e) => this.formInputHandler(e, "documentSearchForm")}
                                        />
                                    </div>

                                </div>

                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Document Number</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="search_by_number"
                                            className="form-control form-control-sm"
                                            placeholder="Enter user Number"
                                            autoComplete="off"
                                            value={this.state.documentSearchForm.search_by_number}
                                            onChange={(e) => this.formInputHandler(e, "documentSearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Document Type</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allDocumentTypes}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'documentSearchForm', 'search_link_types');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            value={this.state.allDocumentTypes.filter(s => this.state.documentSearchForm.search_link_types.includes(s.value))}
                                            placeholder="Select Document Types"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Asset</label>
                                    <div className="col-sm-9">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'documentSearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>

                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

     
    render                              =   ()  =>  {
      
        return (<ApplicationLayout>
        <Helmet><title>Document Listing</title></Helmet>
             {this.documentListjsx()} 
             {this.shiftDetailModalJsx()}
             {this.documentSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};

export default connect(mapStateToProps)(DocumentList);