import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from 'react-toastify';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Link } from 'react-router-dom';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';
import PurchaseOrderDetailModal from './PurchaseOrderDetailModal';
import NumberFormatter from '../../../services/NumberFormater';
import DateService from '../../../services/DateService';
import LogisticViewModal from '../logistic/LogisticViewModal';

class POHistoryModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                            =         {
            poDetailList                      :         [],
            poDetailLoading                   :         false,
            itemName                          :         '',
            itemData                          :         null,
            warehouse_id                    :         null,
            listing_tbl_page                  :          1,
            listingMeta                       :          null,
            totalListingCount                 :         0,
            indent_transaction_id              :         ''
        };
        this.PODetailModalRef                 =   React.createRef();
        this.LogisticViewModalRef             =   React.createRef();

        this.poReportUrl      =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase-order';
    }
    
    
    
    componentDidMount(){
        this.viewPOHistoryModal             =   new Modal(document.getElementById('viewPOHistoryModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (data,warehouse_id,indent_transaction_id='')  =>  {
        this.viewPOHistoryModal.show()
            if(data){
                  this.setState({
                    itemData            :        data,
                    warehouse_id        :        warehouse_id ? warehouse_id : null,
                    indent_transaction_id    :       indent_transaction_id,
                  },() => this.fetchPOHistoryData())
            }
    }

    fetchPOHistoryData     =       (page = 1)      =>      {
        this.setState({poDetailLoading : true, poDetailList : [],listing_tbl_page: page});
        let params =  {
                    search_warehouse_ids        :    this.state.warehouse_id !== undefined && this.state.warehouse_id !== null ? [this.state.warehouse_id] : [],
                    search_item_ids             :    this.state.itemData ? [this.state.itemData.id] : null,
                    indent_transaction_id        :     this.state.indent_transaction_id,
                    page                        :    page}
                    
        HttpAPICall.withAthorization('GET', this.poReportUrl, this.props.access_token, {...params}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                poDetailList          :    respData.data,
                itemName              :    this.state.itemData ? this.state.itemData.name : '',
                listingMeta           :     respData,
                totalListingCount     :     respData.total,
            },()=>  this.viewPOHistoryModal.show())
        
        }).then(() => {
            this.setState({poDetailLoading : false});
        });
    }

    poDetailModalInit          =       (req_item_id)           =>      {
        if(this.props.permissions.includes('isc-po-view')){
            this.PODetailModalRef.current.modalPOInit(req_item_id); 
        } 
    }

    logisticDetailModal = (val) => {
        this.LogisticViewModalRef.current.showModalInit(val);
    }

    
      pOHistoryModalJsx                     =   ()  =>  {

          return (
              <div className="modal fade" id="viewPOHistoryModal" tabIndex="-1">
                  <div className="modal-dialog modal-xl modal-dialog-scrollable">
                      <div className="modal-content  ">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addItemModalLabel">PO History</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className='p-1 bg-light border-bottom'>
                              <table className='table table-sm mb-0 table-borderless text-sm'>
                                  <tbody>
                                      {this.state.indent_transaction_id ? <tr>
                                          <td style={{ width: "15%" }}> Indent  </td>
                                          <th>{this.state.indent_transaction_id ? this.state.indent_transaction_id : ""}</th>
                                      </tr> : <tr>
                                          <td style={{ width: "15%" }}> Warehouse  </td>
                                          <th>{this.state.poDetailList && this.state.poDetailList.length > 0 && this.state.poDetailList[0]?.warehouse_name ? `${this.state.poDetailList[0]?.warehouse_name}` : "-"}</th>
                                      </tr>}
                                      <tr>
                                          <td style={{ width: "15%" }}> Item  </td>
                                          <th>{this.state.itemName  ?this.state.itemName : "-"}</th>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                        <div className="modal-body ">
                            {!this.state.poDetailLoading 
                              ?
                                <table className="table table-bordered  bg-white ">
                                    <thead className="table-secondary">
                                        <tr className="text-end">
                                            <th scope="col" style={{ width: "8%" }} className="text-start">PO Number</th>
                                            <th scope="col" style={{ width: "8%" }} className="text-center">PO Date</th>
                                            <th scope="col" style={{ width: "14%" }} className="text-start" >Vendor Name</th>
                                            {this.state.indent_transaction_id && <th scope="col" style={{ width: "14%" }} className="text-start" >Warehouse</th> }
                                            <th scope="col" style={{ width: "6%" }}>Total Qty</th>
                                            <th scope="col" style={{ width: "6%" }}>Unit Rate</th>
                                            <th scope="col" style={{ width: "6%" }}>Amount</th>
                                            <th scope="col" style={{ width: "6%" }}>Pending Qty</th>
                                            <th scope="col" style={{ width: "6%" }}>Due Date</th>
                                            <th scope="col" style={{ width: "20%" }}>Logistic Data</th>
                                            <th scope="col" style={{ width: "6%" }} className='text-center'>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.poDetailList.length > 0
                                            ? (this.state.poDetailList.map((poi, k) => {
                                                return (<tr key={k}>
                                                    <td className={[this.props.permissions.includes('isc-po-view') ? 'link-primary cursor_pointer' : 'disabled'].join(' ')} onClick={() => this.poDetailModalInit(poi.transaction_id)}>{poi.transaction_id}</td>
                                                    <td>{poi.transaction_date_display}</td>
                                                    <td className='text-capitalize'>{poi.vendor_name}</td>
                                                    {this.state.indent_transaction_id && <td className='text-capitalize'>{poi.warehouse_name}</td>}
                                                    <td className='text-end'>{poi.qty}</td>
                                                    <td className='text-end'><NumberFormatter number={poi.rate} precision={2} /></td>
                                                    <td className='text-end'><NumberFormatter number={poi.qty * poi.rate} precision={2} /></td>
                                                    <td className='text-end'>{poi.pending_qty}</td>
                                                    <td className='text-end'>{poi.due_date_display ? poi.due_date_display : poi.expected_delivery_date_display ? poi.expected_delivery_date_display : "" }</td>
                                                    <td className='text-center'>
                                                        {poi.logistic_edd_data && poi.logistic_edd_data.length > 0 && poi.logistic_edd_data.map((d, k) => <div key={k}>
                                                        <Link onClick={() => this.logisticDetailModal(d.transaction_id)} >
                                                        {d.transaction_id} (EDD : {d.delivery_date ? DateService.dateFormat(d.delivery_date) : 'NA'})</Link></div>)}
                                                    </td>
                                                    <td className='text-center'>{poi.status_name}</td>
                                                </tr>);
                                            }))
                                            : (<tr><td className="text-center" colSpan={11}>No Record Found</td></tr>)
                                        }
                                    </tbody>
                                </table>
                              : <Loader />}
                            <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.fetchPOHistoryData(e.selected + 1)} />
                        </div>
                    </div>
                </div>
            </div>
          );
    }

    render() {
        return (<Ax>
            {this.pOHistoryModalJsx()}
            <PurchaseOrderDetailModal parentProps={this.props} ref={this.PODetailModalRef}/>
            <LogisticViewModal ref={this.LogisticViewModalRef}/>
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(POHistoryModal);
