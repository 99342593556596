import { PRODUCTIVITY_WIDGET_START, PRODUCTIVITY_WIDGET_SUCCESS, PRODUCTIVITY_WIDGET_FAILURE, PRODUCTIVITY_PIE_WIDGET_START, PRODUCTIVITY_PIE_WIDGET_SUCCESS, PRODUCTIVITY_PIE_WIDGET_FAILURE,   PRODUCTIVITY_LINE_WIDGET_START, PRODUCTIVITY_LINE_WIDGET_SUCCESS, PRODUCTIVITY_LINE_WIDGET_FAILURE, PRODUCTIVITY_TARGET_WIDGET_START, PRODUCTIVITY_TARGET_WIDGET_FAILURE, PRODUCTIVITY_TARGET_WIDGET_SUCCESS } from "../../actions/actionTypes";


const SiteProductivityBarWidgetReducer = (initialState = { loading: false, data: null, error: null }, action) => {
    switch (action.type) {
        case PRODUCTIVITY_WIDGET_START:
            return { ...initialState, loading: true };
        case PRODUCTIVITY_WIDGET_SUCCESS:
            return {
                ...initialState,
                loading: false,
                data: action.payload
            }
        case PRODUCTIVITY_WIDGET_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: action.payload
            };
        default:
            return initialState;
    }
}

const SiteProductivityPieWidgetReducer = (initialState = { loading: false, data: null, error: null }, action) => {
    switch (action.type) {
        case PRODUCTIVITY_PIE_WIDGET_START:
            return { ...initialState, loading: true };
        case PRODUCTIVITY_PIE_WIDGET_SUCCESS:
            return {
                ...initialState,
                loading: false,
                data: action.payload
            }
        case PRODUCTIVITY_PIE_WIDGET_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: action.payload
            };
        default:
            return initialState;
    }
}


const SiteProductivityLineWidgetReducer = (initialState = { loading: false, data: null, error: null }, action) => {
    switch (action.type) {
        case PRODUCTIVITY_LINE_WIDGET_START:
            return { ...initialState, loading: true };
        case PRODUCTIVITY_LINE_WIDGET_SUCCESS:
            return {
                ...initialState,
                loading: false,
                data: action.payload
            }
        case PRODUCTIVITY_LINE_WIDGET_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: action.payload
            };
        default:
            return initialState;
    }
}
const SiteProductivityTargetWidgetReducer = (initialState = { loading: false, data: null, error: null }, action) => {
    switch (action.type) {
        case PRODUCTIVITY_TARGET_WIDGET_START:
            return { ...initialState, loading: true };
        case PRODUCTIVITY_TARGET_WIDGET_SUCCESS:
            return {
                ...initialState,
                loading: false,
                data: action.payload
            }
        case PRODUCTIVITY_TARGET_WIDGET_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: action.payload
            };
        default:
            return initialState;
    }
}
export {
    SiteProductivityLineWidgetReducer,
    SiteProductivityBarWidgetReducer,
    SiteProductivityPieWidgetReducer,
    SiteProductivityTargetWidgetReducer
};
