import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../../components/hoc/Ax";
import Loader from "../../../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import ExpenseView from './ExpenseView';

class ExpenseViewModal extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                        =             {
            viewDataLoading             :            false,
            expenseData                 :            null,
            counterlog                  :            null,
            assetData                   :            null,
            shift                       :            null,
            transaction_id              :            '',
            date                        :            '',     
        }
        this.expenseModalId             =       uuid();
    }

    componentDidMount() {
           this.intializeData(this.props)
           this.viewExpenseModal        =   new Modal(document.getElementById('expenseViewModal'), {keyboard: false, backdrop: false}); 
    }

    intializeData       =       (pr)      =>      {
        this.setState({access_token : pr.access_token})
    }

    showExpenseDetailInit           =       (shiftDetail, counterlog, assetData = null,id = null,need_asset_profile='N')        =>      {
        this.viewExpenseModal.show()
        this.setState({
            assetData           :   assetData,
            shift               :   shiftDetail,
            counterlog          :   counterlog,
            date                :   counterlog ? counterlog.feed_date : '',
        })
        if(id){
            this.getExpenseDetail(id,need_asset_profile)
        }
    }

    getExpenseDetail                 =   (id,need_asset_profile)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/expense/detail/' + id, this.state.access_token, {need_asset_profile:need_asset_profile}, {}, (response) => {
            this.setState({
                expenseData: response.data.data
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }


    render                              =   ()  =>  {
        let expenseData = this.state.expenseData;
        return (<Ax>
            <div className="modal fade" id='expenseViewModal' tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>Upload Shift Activity for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" disabled={this.state.addActivityFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {this.state.viewDataLoading
                            ? <Loader />
                            : <div className="modal-body mx-1">
                               
                                {expenseData ?
                                    <Ax>
                                        <ExpenseView expenseData={this.state.expenseData}/>
                                    </Ax>
                                    : null}
                            </div>}
                    </div>
                </div>
            </div>
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ExpenseViewModal);