import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL, APP_BASE_URL_2 } from '../../../services/TapApiUrls';
import { toast } from 'react-toastify';
import TapIcon from '../../../services/TapIcon';
import {Link} from "react-router-dom";
import LogisticEditModal from './LogisticEditModal';
import OriginEditModal from './OriginEditModal';
import DestinationEditModal from './DestinationEditModal';
import DocumentModal from './DocumentModal';
import swal from 'sweetalert';
import DateService from '../../../services/DateService';
import InventoryTransferDetail from '../inventoryTransfer/InventoryTransferDetail';
import { Modal } from 'bootstrap';
import PurchaseDetail from '../purchase/PurchaseDetail';
import PurchaseOrderDetailModal from '../purchaseOrder/PurchaseOrderDetailModal';
import StepsDetailTableFields from './StepsDetailTableFields';
import FieldsChangeWithStatus from './FieldsChangeWithStatus';

class LogisticDetail extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                  =   {
            logisticStatus              :   [],
            addCommentFormSubmitting    :   false,
            logisticStatusData          :   {status : this.props.logisticViewData.status ? this.props.logisticViewData.status.id : '', notes : this.props.logisticViewData.notes, logistic_id : this.props.logisticViewData.id},
            purchaseTransactionId       :   '',
            inventoryTransactionId      :   '',
            poTransactionId             :   '',
            allAllowedStatus            :   [],
            allStepsWithFields          :   [],
            allFields                   :   null,
        }

        this.LogisticEditModalRef       =   React.createRef(); 
        this.originEditModalRef         =   React.createRef(); 
        this.destinationEditModalRef    =   React.createRef(); 
        this.documentModalRef           =   React.createRef(); 
        this.PODetailModalRef           =   React.createRef();

        this.logisticDocDeleteUrl       =   APP_BASE_URL + '/logistic/logistic_document/delete';
        this.logisticUrl                =   APP_BASE_URL_2 + '/logistic';

    }

    componentDidMount() { 
        this.initilaizeFormData();
        this.viewInventoryTransactionModal      =   new Modal(document.getElementById('viewInventoryTransactionModal'), {keyboard: false, backdrop :false});
        this.viewPurchaseTransactionModal       =   new Modal(document.getElementById('viewPurchaseTransactionModal'), {keyboard: false, backdrop :false});
    }

    initilaizeFormData                  =   () =>  {

        const {logisticViewData, logistic_steps}          =   this.props;

        const allStepsWithFields  =   logisticViewData && logisticViewData.status && logistic_steps && logistic_steps.length > 0 ? logistic_steps.find(step => step.status_id === logisticViewData.status.id).steps : [];
        this.setState({
            allAllowedStatus     :   logisticViewData && logisticViewData.user_status && logisticViewData.user_status.length > 0 ? logisticViewData.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [],
            allStepsWithFields
        });
    }

    deleteLogistocDocument              =   (doc_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.logisticDocDeleteUrl + '/' + doc_id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.props.getLogisticViewDetails(this.props.logisticViewData.transaction_id);
                }).then(() => { });
            }
        });
    }

    handleLogisticEditModal =   (type) => {
       
        const {logisticViewData, logistic_steps}    =   this.props;

        this.setState({
            allFields :  logistic_steps.filter(status => status.status_id === logisticViewData.status.id)[0]?.steps.filter(step => step.step_name === type)[0]?.fields
        }, () => {
            switch(type) {
                case "Origin Details" :
                    this.originEditModalRef.current.showModalInit("Origin");
                    break;
                case "Destination Details" :
                    this.destinationEditModalRef.current.showModalInit("Destination");
                    break;
                case "Tracking Details" :
                    this.LogisticEditModalRef.current.showModalInit("Tracking");
                    break;
                case "Consigment Details" :
                    this.LogisticEditModalRef.current.showModalInit("Consigment");
                    break;
                case "Billing Details" :
                    this.LogisticEditModalRef.current.showModalInit("Billing");
                    break;
                case "CHA Details" :
                    this.LogisticEditModalRef.current.showModalInit("C&F");
                    break;
                case "Expense Details" : // Delivered Details
                    this.LogisticEditModalRef.current.showModalInit("Expense");
                    break;
                case "Purchase & Inventory" :
                    this.LogisticEditModalRef.current.showModalInit("Purchase & Inventory");
                    break;
                default :
                    this.LogisticEditModalRef.current.showModalInit("Transaction");
            }
        })
    }

    handleDocumentModal =   () => {
        this.documentModalRef.current.showModalInit();
    }

    closeView               =   () => {
        this.props.closeView();
    }

    LogisticDetailJsx       =   ()  => {
        const logisticViewData  =   this.props.logisticViewData;
        const canEdit           =   logisticViewData.can_edit;
        let originType          =   'Origin Detail';
        let destinationType     =   'Destination Detail';

        let status              =   logisticViewData.status ? logisticViewData.status.name : null;
        
        let origin_type_detail  =   '';
        if(logisticViewData.origin_type_detail) {
            origin_type_detail  = logisticViewData.origin_type_detail ? `${logisticViewData.origin_type_detail.name} (${logisticViewData.origin_type_detail.code})` : ''
        }

        let destination_type_detail  =   '';
        if(logisticViewData.destination_type_detail) {
            destination_type_detail  = logisticViewData.destination_type_detail ? `${logisticViewData.destination_type_detail.name} (${logisticViewData.destination_type_detail.code})` : ''
        }
        if(logisticViewData.origin_type) {
            if(logisticViewData.origin_type === 'warehouse') {
                originType  = 'Warehouse';
            }else if(logisticViewData.origin_type === 'vendor') {
                originType  = 'Vendor';
            }else if(logisticViewData.origin_type === 'customer') {
                originType  = 'Customer';
            }else if(logisticViewData.origin_type === 'manual_entry') {
                originType  = 'Origin Detail';
            } 
        }

        if(logisticViewData.destination_type) {
            if(logisticViewData.destination_type === 'warehouse') {
                destinationType  = 'Warehouse';
            }else if(logisticViewData.destination_type === 'vendor') {
                destinationType  = 'Vendor';
            }else if(logisticViewData.destination_type === 'customer') {
                destinationType  = 'Customer';
            }else if(logisticViewData.destination_type === 'manual_entry') {
                destinationType  = 'Destination Detail';
            } 
        }   
        const {allStepsWithFields}    =   this.state;
        
        return (
            <Ax>     
                <div className="tab_content_header">
                    <table className="table table-bordered bg-white" >
                        <thead className="table-secondary">
                            <tr>
                                <td style={{ width: "25%"}}>ID</td>
                                <td style={{ width: "25%" }}>Date</td>
                                <td style={{ width: "25%" }}>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="fs14 fw500" scope="col">{logisticViewData.transaction_id}</td>
                                <td className="fs14 fw500" scope="col">{logisticViewData.transaction_date ? DateService.dateTimeFormat(logisticViewData.transaction_date, 'DD-MMM-YYYY') : ''}</td>
                                <td className="fs14 fw500" scope="col">
                                    {logisticViewData.status ? logisticViewData.status?.name : ''}
                                </td>  
                            </tr>
                        </tbody>
                    </table>
                    
                    {
                        allStepsWithFields && allStepsWithFields.length > 0 && allStepsWithFields.map((step) => {
                            return (
                                <>
                                {
                                logisticViewData.logistic_type === "International" ?
                                <div className="tab_content_wrapper mt-4">
                                    <span className="content_heading">{step.step_name}</span>
                                    {
                                        (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                                        <span className="text-end dropdown">
                                            <button type="button" onClick={() => this.handleLogisticEditModal(step.step_name)} className="btn btn-light">
                                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                                            </button>
                                        </span>
                                    }
                                </div> 
                                : step.step_name !== "CHA Details" &&
                                <div className="tab_content_wrapper mt-4">
                                    <span className="content_heading">{step.step_name}</span>
                                    {
                                        (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                                        <span className="text-end dropdown">
                                            <button type="button" onClick={() => this.handleLogisticEditModal(step.step_name)} className="btn btn-light">
                                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                                            </button>
                                        </span>
                                    }
                                </div>
                                }
                                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                        <tbody>
                                                {
                                                    step.fields && step.fields.length > 0 && step.fields.map((field) => {
                                                        return (<Ax key={field}>
                                                            <StepsDetailTableFields logisticViewData={logisticViewData} field={field} originType = {originType} origin_type_detail = {origin_type_detail} destinationType = {destinationType} destination_type_detail={destination_type_detail}/>
                                                        </Ax>)
                                                    })
                                                }
                                        </tbody>
                                    </table>
                                </>
                            )
                        })
                    }
                    <div>
                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Tagging Details</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleLogisticEditModal("Purchase & Inventory")} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                        {
                            (logisticViewData?.purchase_order_transaction_ids && logisticViewData.purchase_order_transaction_ids.length > 0) || (logisticViewData?.purchase_transaction_ids && logisticViewData.purchase_transaction_ids.length > 0) || (logisticViewData?.inv_transfer_transaction_ids && logisticViewData.inv_transfer_transaction_ids.length > 0) || (logisticViewData?.usersList.length > 0)
                            ?
                            <>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "30%" }}>Users</td>
                                <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData?.usersList.length > 0 && logisticViewData?.usersList.map(user => user.full_name).join(', ')}
                                </td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "30%" }}>Purchase Order</td>
                                <td className="fs14 fw500" style={{ "width": "70%" }}>
                                {
                                    (logisticViewData?.purchase_order_transaction_ids && logisticViewData.purchase_order_transaction_ids.length > 0) &&
                                    logisticViewData.purchase_order_transaction_ids.map((val, i) => {
                                        return <Link key={i} onClick={() => this.poDetailModalInit(val)}>{val} {(i !== logisticViewData.purchase_order_transaction_ids.length -1) ? ', ' : ''}</Link>;
                                    })
                                }
                                </td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "30%" }}>Purchase</td>
                                <td className="fs14 fw500" style={{ "width": "70%" }}>
                                {
                                    (logisticViewData?.purchase_transaction_ids && logisticViewData.purchase_transaction_ids.length > 0) &&
                                    logisticViewData.purchase_transaction_ids.map((val, i) => {
                                        return <Link key={i} onClick={() => this.purchaseModal(val)}>{val}{(i !== logisticViewData.purchase_transaction_ids.length -1) ? ', ' : ''}</Link>;
                                    })
                                }
                                </td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "30%" }}>Inventory Transfer</td>
                                <td className="fs14 fw500" style={{ "width": "70%" }}>
                                {
                                    (logisticViewData?.inv_transfer_transaction_ids && logisticViewData.inv_transfer_transaction_ids.length > 0) &&
                                    logisticViewData.inv_transfer_transaction_ids.map((val, i) => {
                                        return <Link key={i} onClick={() => this.invTransferModal(val)}>{val} {(i !== logisticViewData.inv_transfer_transaction_ids.length -1) ? ', ' : ''}</Link>;
                                    })
                                }
                                </td>
                            </tr>
                            </>
                            :<tr><td className='text-center' colSpan="2">No Record Found</td></tr>
                        }
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Logistic Document</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit') && this.props.permissions.includes('logistic_doc_add'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleDocumentModal()} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPlus} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-bordered bg-white my-2">
                        <thead className="table-secondary">
                            <tr>
                                <td style={{ "width": "5%" }}>S.No</td>
                                <td style={{ "width": "35%" }}>Name</td>
                                <td style={{ "width": "30%" }}>Number</td>
                                <td style={{ "width": "10%" }}>View</td>
                                <td style={{ "width": "10%" }}>Download</td>
                                <td style={{ "width": "10%" }}>Delete</td>
                            </tr>
                        </thead>
                        <tbody>
                            {logisticViewData.documents && logisticViewData.documents.length > 0 ? logisticViewData.documents.map((d,i) => {
                                return <tr className='text-center'>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.number}</td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <Link role="button"
                                            target='_blank'
                                            to={{pathname : d.document}}
                                            className={[d.view_permission ? '' : 'disabled-link'].join(' ')}
                                            title={d.view_permission ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faEye}  /></Link>
                                    </td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <Link role="button"
                                            target='_blank'
                                            to={{pathname : d.download_link}}
                                            className={[d.downlaod_permission ? '' : 'disabled-link'].join(' ')}
                                            title={d.downlaod_permission ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload}  /></Link>
                                    </td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <a role="button"
                                            onClick={() => {
                                                if (this.props.permissions.includes('logistic_doc_delete')) {
                                                   return this.deleteLogistocDocument(d.doc_id)
                                               }
                                            }}
                                            className={[this.props.permissions.includes('logistic_doc_delete') ? '' : 'disabled-link'].join(' ')}
                                            title={!this.props.permissions.includes('logistic_doc_delete') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt}  /></a>
                                    </td>
                                </tr>
                            })
                            : <tr><td className='text-center' colSpan="6">No Document Found</td></tr>
                        }
                        </tbody>
                    </table>
                        {
                            <FieldsChangeWithStatus logisticViewData={logisticViewData}  getLogisticViewDetails={this.props.getLogisticViewDetails} loadListingTblData={this.props.loadListingTblData}/>
                        }
                    <hr/>
                    <div className="my-1">
                        {
                            logisticViewData.comments && logisticViewData.comments.length > 0 
                            ? 
                            logisticViewData.comments.map((c, k) => {
                                return (
                                    <Ax key={k}>
                                        <div className="row">
                                            {
                                                c.msg 
                                                ? 
                                                <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> 
                                                : 
                                                <div className='col-sm-8'>-</div>
                                            }
                                            <div className="col-sm-4 text-end">
                                                <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                <span> {DateService.dateTimeFormat(c.created_at)}</span>
                                            </div>
                                            <div className="col-sm-8 my-1">{c.notes}</div>
                                        </div>
                                        <hr />
                                    </Ax>
                                );
                            })
                            : null
                        }
                    </div>
                </div>
            </div>       
            </Ax>
        )
    }

    invTransferModal    =   (invId) => {
        this.setState({
            inventoryTransactionId :   invId
       }, () => {
            this.viewInventoryTransactionModal.show();
       }); 
    }

    purchaseModal = (purId) => {
        this.setState({
            purchaseTransactionId : purId
        }, () => {
            this.viewPurchaseTransactionModal.show();
        })
    }

    poDetailModalInit          =       (req_item_id)           =>      {
        this.PODetailModalRef.current.modalPOInit(req_item_id); 
    }


    purchaseDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewPurchaseTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Transaction Id : {this.state.purchaseTransactionId ? this.state.purchaseTransactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>
                        </div>
                        <div className="modal-body">
                          <PurchaseDetail purchaseId={this.state.purchaseTransactionId}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    inventoryTransactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewInventoryTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Transaction Id : {this.state.inventoryTransactionId ? this.state.inventoryTransactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>
                        </div>
                        <div className="modal-body">
                          <InventoryTransferDetail inventoryId={this.state.inventoryTransactionId}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    
    render() {
        return (
            <Ax>
                {this.LogisticDetailJsx()}
                <LogisticEditModal ref = {this.LogisticEditModalRef} LogisticDetail = {this.props.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.props.getLogisticViewDetails(transaction_id)} allFields={this.state.allFields}/>
                <OriginEditModal ref = {this.originEditModalRef} logisticDetail = {this.props.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.props.getLogisticViewDetails(transaction_id)}/>
                <DestinationEditModal ref = {this.destinationEditModalRef} logisticDetail = {this.props.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.props.getLogisticViewDetails(transaction_id)}/>
                <DocumentModal ref = {this.documentModalRef} logisticDetail = {this.props.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.props.getLogisticViewDetails(transaction_id)}/>
                {this.inventoryTransactionDetailsModalJsx()}
                {this.purchaseDetailsModalJsx()}
                <PurchaseOrderDetailModal parentProps={this.props} ref={this.PODetailModalRef}/>
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(LogisticDetail);