
import React, { forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from "sweetalert";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import TapSelect from '../../../components/ui/TapSelect';
import TapHelper from '../../../services/TapHelper';
import DownloadFile from '../../../services/DownloadFile';
import DateService from '../../../services/DateService';
import TapNavTabs from '../../../components/ui/TapNavTabs';
import PageHeader from '../../includes/PageHeader';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Loader from '../../../components/ui/Loader/Loader';
import moment from 'moment';
import Chart from 'react-google-charts';


const SiteProductivityReport = forwardRef((props, ref) => {

    let initListData = {
        loading: false,
        data_loaded: false,
        items: [],
        meta: null,
        page: 1,
        days: []
    };

    let initFormData = {
        per_page: 50,
        form_data_loaded: false,
        loading: false,
        downloading: false,
        submitting: false,
        periods: [],
        date_range: 'current_month',
        date_range_start: null,
        date_range_end: null,
        search_activity_code: '',
        reset_filter: false,
        search_site_ids: [],
        search_activity_ids: [],
        search_units: [],
    };

    let formDataLabel = {
        search_activity_ids: 'Activity : ',
        search_activity_code: 'Activity Code : ',
        search_site_ids: 'Sites ',
        date_range: 'Date Range : ',
        search_units: 'Production Unit : ',
        entry_date_range: 'Date of Entry : ',
    };


    const access_token = useSelector(state => state.auth.access_token);
    const all_periods = useSelector(state => state.app && state.app.all_periods ? [...state.app.all_periods, { key: 'custom', display: 'Custom Date Range' }] : [],);
    const filteredPeriods = all_periods.filter(p =>
        p.key === 'current_month' ||
        p.key === 'current_week' ||
        p.key === 'previous_week' ||
        p.key === 'previous_month' ||
        p.key === 'custom'
    );

    const user_sites = useSelector(state => state.app.user_sites ? state.app.user_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : []);
    const [siteProductivityData, setSiteProductivityData] = useState({ ...initListData });
    const [formData, setFormData] = useState({ ...initFormData });
    const [searchedElems, setSearchedElems] = useState([]);
    const [submittedFormData, setSubmittedFormData] = useState({ ...initFormData });
    const [searchModalId, setSearchModalId] = useState(uuid());
    const [allActivitesList, setAllActivitesList] = useState([]);
    const [allProductionUnitList, setAllProductionUnitList] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [loadedTabs, setLoadedTabs] = useState([]);
    const [widgetData, setWidgetData] = useState({
        prodWidgetdata: [],
        loading: false,
        chartData: [],
        days: []
    });

    const searchModalElem = useRef(null);
    const searchModal = useRef(null);
    const searchModalOpen = () => searchModal.current && searchModal.current.show()
    const searchModalClose = () => searchModal.current && searchModal.current.hide()

    let itemListUrl = IAM_API_BASE_URL_2 + "/site_productivity_report";


    useEffect(() => {
        searchModalElem.current = document.getElementById(searchModalId);

        searchModal.current = new Modal(searchModalElem.current, { keyboard: false, backdrop: false });
        let newLoadedTabs = loadedTabs.filter((item, i, ar) => ar.indexOf(item) === i);
        newLoadedTabs.push(currentTab);
        setLoadedTabs(newLoadedTabs);
        if (currentTab == 1 && siteProductivityData.data_loaded == false) {
            loadItemsData(props.location.state && props.location.state.page_number || 1);
        }
        return () => {
            if (searchModal.current) {
                searchModal.current.dispose();
            }
        };
    }, [currentTab]);
    const listSearchModalInit = () => {
        searchModalOpen();
        if (!formData.form_data_loaded) {
            loadFormData();
        }
    }
    useEffect(() => {
        loadItemsData(1);
        setSearchedElems([
            `Date Range: <b>${formData.date_range}</b>`
        ]);
    }, []);

    const closeReport = () => {
        props.history.push('/report');
    }
    const loadFormData = () => {
        setFormData(pd => ({ ...pd, form_data_loaded: false, loading: true }));
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, { action: 'formData' }, {}, (response) => {
            let respData = response.data;
            setAllActivitesList(respData && respData.activity_data && respData.activity_data.length > 0 ? respData.activity_data.map((td) => { return { value: td.id, label: td.activity_name } }) : [])
            setAllProductionUnitList(respData && respData.uom && respData.uom.length > 0 ? respData.uom.map((td) => { return { value: td.id, label: td.measuring_unit } }) : [])
            setFormData(pd => ({
                ...pd,
            }));
        }).then(() => {
            setFormData(pd => ({ ...pd, form_data_loaded: true, loading: false }));
        });
    }

    const submitSearchFormHandler = (e) => {
        e.preventDefault();
        let searchedElems = [];
        let searchParams = {};
        Object.keys(formData).map((key) => {
            let label = formDataLabel[key];
            let value = formData[key];
            if (value && value.length > 0) {
                searchParams[key] = value;
                if (label) {
                    let show_val = value;
                    if (key == 'search_site_ids') {
                        show_val = user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_activity_ids') {
                        show_val = allActivitesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_units') {
                        show_val = allProductionUnitList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'date_range') {
                        let start_range = moment(formData.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(formData.date_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val = all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }

                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        setSubmittedFormData(pd => ({ ...pd, ...formData }));
        setSearchedElems(searchedElems);
        searchModalClose();
        loadItemsData(1, searchParams);
    }
    const tapSelectChange = (selectedOption, formName, fieldName) => {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: selectedOption.map(o => o.value),
        }));
    };

    const searchFormClear = () => {
        setSearchedElems([]);
        setFormData(pd => ({ ...pd, ...initFormData, reset_filter: true }));
        setSubmittedFormData(pd => ({ ...initFormData }));
    }


    useEffect(() => {
        if (formData.reset_filter) {
            setFormData(pd => ({ ...pd, reset_filter: false }));
            loadItemsData(1);

        }
    }, [formData.reset_filter]);

    const loadItemsData = (page = 1, searchParams = {}) => {
        setSiteProductivityData(pd => ({ ...pd, data_loaded: false, loading: true, page: page }));
        const defaultDateRange = {
            date_range: 'current_month',
        };

        let params = {
            action: 'data',
            page: page,
            ...defaultDateRange,
            ...TapHelper.pick(formData, ['date_range_start', 'date_range_end']),
            ...searchParams
        };

        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            let respData = response.data;
            const meta = {
                from: respData.data.from,
                to: respData.data.to,
                total: respData.data.total,
                last_page: respData.data.last_page,
                per_page: respData.data.per_page,
                current_page: respData.data.current_page
            };
            setSiteProductivityData(pd => ({
                ...pd, data_loaded: true, items: respData.data.data, days: respData.days,
                meta: meta
            }));
        }).then(() => {
            setSiteProductivityData(pd => ({ ...pd, loading: false }));
        });
        widgetDataApi(params);
    }
    const widgetDataApi = (params = {}) => {
        setWidgetData(pre => ({ ...pre, loading: true }));
        const widgetParams = { ...params, action: 'report_dashboard' };

        HttpAPICall.withAthorization('GET', itemListUrl, access_token, widgetParams, {}, (res) => {
            const respData = res.data;
            const days = respData.days;
            const prodWidgetdata = respData.data;
            const activitiesName = prodWidgetdata.map(d => d.activity_name);
            const activitiesKey = prodWidgetdata.map(p => p.activity_name + p.site_name + p.uom);
            const chartData = [['Date', ...activitiesName]];

            days.forEach(day => {
                let value = [];
                activitiesKey.forEach(a => {
                    const dateWiseData = prodWidgetdata.find(p => p.activity_name + p.site_name + p.uom === a).date_wise_data;
                    let v = dateWiseData.find(d => d.date === day).value;
                    value.push(v ? parseFloat(v) : 0);
                });
                chartData.push([DateService.dateFormat(day, 'DD-MMM'), ...value]);
            });

            setWidgetData(pre => ({
                ...pre,
                prodWidgetdata,
                days,
                loading: false,
                chartData
            }));
        }).catch(() => setWidgetData(pre => ({ ...pre, loading: false })));
    };


    const reportDownloadHandler = () => {
        const date_range = initFormData.date_range ? initFormData.date_range : 'current_month';
        let params = {
            action: 'download',
            ...TapHelper.pick(formData, ['date_range', 'date_range_start', 'date_range_end',]),
            date_range
        };
        if (params.date_range == null || params.date_range == '') {
            swal({
                title: "Download",
                text: "The download will be for Current Quarter's Transaction, in case you want different Transaction Period then please change by filter.",
                icon: "warning",
                buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    reportDownloadStart({ ...params, date_range: 'current_month' });
                }
            });
        } else {
            reportDownloadStart(params);
        }
    }
    const reportDownloadStart = (params) => {
        setSiteProductivityData(pd => ({ ...pd, downloading: true }));
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            DownloadFile.file(response.data.file_path)
        }).then(() => {
            setSiteProductivityData(pd => ({ ...pd, downloading: false }));
        });
    }

    const siteProductivityTable = () => {
        return <>
            <DisplaySearchCriteria searchedElems={searchedElems} onClearFilter={searchFormClear} />
            <div className="table-responsive">
                <table className="table table-sm table-bordered align-middle bg-white">
                    <thead className="align-middle table-secondary">
                        <tr className='text-center'>
                            <th style={{ width: "3%" }}>S.No</th>
                            <th style={{ width: "13%" }}>Activity Name</th>
                            <th style={{ width: "7%" }}>Code</th>
                            <th style={{ width: "17%" }}>UOM</th>
                            <th style={{ width: "17%" }}>Site </th>
                            {siteProductivityData.days.length > 0 && siteProductivityData.days.map((date, index) => (
                                <th key={index}>
                                    {DateService.dateFormat(date, 'DD-MMM')}
                                </th>
                            ))}
                            <th style={{ width: "8%" }}>Total</th>
                            <th style={{ width: "8%" }}>Unit Rate</th>
                            <th style={{ width: "9%" }}>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {siteProductivityData.loading
                            ? <tr><td className='text-center' colSpan={8 + siteProductivityData.days.length}><Loader /></td></tr>
                            : ((siteProductivityData.items && siteProductivityData.items.length > 0)
                                ? siteProductivityData.items.map((item, index) => {
                                    return <tr className={['text-center'].join(' ')} key={index}>
                                        <td className='text-center'>{siteProductivityData.meta ? siteProductivityData.meta.from + index : index}</td>
                                        <td className='text-center'>{item.activity_name || "-"}</td>
                                        <td className='text-center'>{item.activity_code || "-"}</td>
                                        <td className='text-center'>{item.uom || "-"}</td>
                                        <td className='text-center'>{item.site_name || "-"}</td>
                                        {siteProductivityData.days.map((date, dateIndex) => {
                                            const valueForDate = item.date_wise_data.find(valueItem => valueItem.date === date);
                                            return (
                                                <td key={dateIndex} className='text-end'>
                                                   {parseFloat(valueForDate.value || 0).toFixed(3).replace(/\.?0+$/, '')} 
                                                </td>
                                            );
                                        })}
                                        <td className='text-end'>{parseFloat(item.total || 0).toFixed(3).replace(/\.?0+$/, '')}</td>
                                        <td className='text-end'> {parseFloat(item.cost_per_unit_target || 0).toFixed(3).replace(/\.?0+$/, '')}</td>
                                        <td className='text-end'>{parseFloat(item.value || 0).toFixed(3).replace(/\.?0+$/, '')}</td>
                                    </tr>
                                })
                                : <tr><td className='text-center' colSpan={8 + siteProductivityData.days.length}>No Record Found</td></tr>)
                        }
                    </tbody>
                </table>
            </div>
            <DisplayListPagination meta={siteProductivityData.meta} onPageChange={(e) => loadItemsData(e.selected + 1)} />

        </>
    }

    const topRightHeaderJsx = () => {
        return <>
            <button type="button" disabled={siteProductivityData.downloading || siteProductivityData.loading || formData.loading} className="btn btn-secondary" onClick={reportDownloadHandler} >
                <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                {siteProductivityData.downloading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />}
            </button>
            <button type="button" disabled={siteProductivityData.downloading || siteProductivityData.loading || formData.loading} className="btn btn-secondary" onClick={listSearchModalInit} >
                <TapIcon.imageIcon icon={TapIcon.SearchIcon} />
            </button>
            <button type="button" disabled={siteProductivityData.downloading || siteProductivityData.loading} className="btn btn-secondary mx-1" onClick={closeReport}>
                <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
            </button>
        </>
    }

    const pageHeading = () => {
        return <PageHeader title="Site Productivity Report" name="Site Productivity Report" rightTopHeadingJsx={topRightHeaderJsx()} />
    }

    const searchModalJsx = () => {
        return <><div className="modal fade"
            id={searchModalId} tabIndex="-1" ref={searchModalElem}>
            <div className="modal-dialog modal-lg " >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Site Productivity Search</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={siteProductivityData.loading} aria-label="Close"></button>
                    </div>
                    <div className="modal-body"
                    >
                        {formData.loading ? <Loader /> : <form id={searchModalId + 'Form'} onSubmit={submitSearchFormHandler}>
                            <div className='row mb-3'>
                                <label className="col-sm-2 col-form-label col-form-label-sm">Activity Name</label>
                                <div className="col-sm-4">
                                    <TapSelect
                                        options={allActivitesList}
                                        changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'formData', 'search_activity_ids')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={allActivitesList.filter(s => formData.search_activity_ids.includes(s.value))}
                                        placeholder="Select Activity"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                                <label className="col-sm-1 col-form-label col-form-label-sm">Code</label>
                                <div className="col-sm-5">
                                    <input
                                        type="text"
                                        name="search_activity_code"
                                        className="form-control form-control-sm"
                                        placeholder="Activity Code"
                                        autoComplete="off"
                                        value={formData.search_activity_code}
                                        onChange={e => setFormData(d => ({ ...d, search_activity_code: e.target.value }))}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-2 col-form-label col-form-label-sm">UOM</label>
                                <div className="col-sm-4">
                                    <TapSelect
                                        options={allProductionUnitList}
                                        changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'formData', 'search_units')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={allProductionUnitList.filter(s => formData.search_units.includes(s.value))}
                                        placeholder="Unit of Measurement"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                                <label className="col-sm-1 col-form-label col-form-label-sm">Site</label>
                                <div className="col-sm-5">
                                    <TapSelect
                                        options={user_sites ? user_sites : []}
                                        changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'formData', 'search_site_ids')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={user_sites.filter(s => formData.search_site_ids.includes(s.value))}
                                        placeholder="Select Site"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm">Transaction Period</label>
                                <div className="col-sm-10">
                                    <PeriodSelectionWithCustom
                                        periods={filteredPeriods}
                                        value={formData.date_range}
                                        startDate={formData.date_range_start}
                                        endDate={formData.date_range_end}
                                        onSelectPeriod={(period, startDate, endDate) => {

                                            setFormData
                                                ({
                                                    ...formData,
                                                    date_range: period,
                                                    date_range_start: startDate ? startDate : null,
                                                    date_range_end: endDate ? endDate : null
                                                }
                                                );
                                        }}
                                        className="form-control-sm"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />

                                </div>

                            </div>
                        </form>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button form={searchModalId + 'Form'} type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    }

    const widgetJsx = () => {
        const { prodWidgetdata, loading, chartData } = widgetData;

        return (
            <div className="row bg-white py-2">
                <div className="col-md-12">
                    <div className="card mt-1">
                        <div className="card-body">
                            <DisplaySearchCriteria searchedElems={searchedElems} onClearFilter={searchFormClear} />
                            <div className="row mt-3">
                                <div className="col-5">
                                    <h6 className="fw-bold primary_color">Summary Widget</h6>
                                </div>
                            </div>
                            <div className="row mt-2 align-items-center">
                                {
                                    loading
                                    ?
                                    <Loader />
                                    :
                                    widgetData.prodWidgetdata.length > 0
                                    ?
                                    <>
                                        <div className="col-12 pr0 mr0 text-end my-4">
                                            <Chart
                                                chartType={"LineChart"}
                                                data={chartData}
                                                options={{
                                                    legend: { position: 'left', labeledValueText: 'both' },
                                                    targetAxisIndex: 1,
                                                    chartArea: { right: 10, top: 20, width: "76%", height: "69%" },
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="col-12 text-center">No data available</div>
                                }
                            </div>
                            <div className="table-responsive mt-4">
                                <table className="table table-sm table-bordered align-middle bg-white">
                                    <thead className="align-middle table-secondary">
                                        <tr className='text-center'>
                                            <th style={{ width: "3%" }}>S.No</th>
                                            <th style={{ width: "13%" }}>Activity Name</th>
                                            <th style={{ width: "7%" }}>Code</th>
                                            <th style={{ width: "17%" }}>UOM</th>
                                            <th style={{ width: "17%" }}>Site </th>
                                            {widgetData.days.length > 0 && widgetData.days.map((date, index) => (
                                                <th key={index}>
                                                    {DateService.dateFormat(date, 'DD-MMM')}
                                                </th>
                                            ))}
                                            <th style={{ width: "8%" }}>Total</th>
                                            {/* <th style={{ width: "8%" }}>Unit Rate</th>
                                                <th style={{ width: "9%" }}>Value</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {widgetData.loading
                                            ?
                                            <tr>
                                                <td className='text-center' colSpan={6 + widgetData.days.length}><Loader /></td>
                                            </tr>
                                            :
                                            ((widgetData.prodWidgetdata && widgetData.prodWidgetdata.length > 0)
                                            ? widgetData.prodWidgetdata.map((item, index) => {
                                                return (
                                                    <tr className={['text-center'].join(' ')} key={index}>
                                                        <td className='text-center'>
                                                            {prodWidgetdata.meta ? prodWidgetdata.meta.from + index : index + 1}
                                                        </td>
                                                        <td className='text-center'>
                                                            {item.activity_name || "-"}
                                                        </td>
                                                        <td className='text-center'>
                                                            {item.activity_code || "-"}
                                                        </td>
                                                        <td className='text-center'>
                                                            {item.uom || "-"}
                                                        </td>
                                                        <td className='text-center'>
                                                            {item.site_name || "-"}
                                                        </td>
                                                        {widgetData.days.map((date, dateIndex) => {
                                                            const valueForDate = item.date_wise_data.find(valueItem => valueItem.date === date);
                                                            return (
                                                                <td key={dateIndex} className='text-end'>
                                                                    <td key={dateIndex} className='text-end'>
                                                                        {parseFloat(valueForDate.value || 0).toFixed(3).replace(/\.?0+$/, '')}
                                                                    </td>
                                                                </td>
                                                            );
                                                        })}
                                                           <td className='text-end'>{parseFloat(item.total || 0).toFixed(3).replace(/\.?0+$/, '')}</td>
                                                        {/* <td className='text-end'>{item.cost_per_unit_target || "-"}</td>
                                                        <td className='text-end'>{item.value || "-"}</td> */}
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td className='text-center' colSpan={6 + widgetData.days.length}>No Record Found</td>
                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
    let reportTabs = () => {
        return [
            { name: 'Summary', jsx: loadedTabs.includes(0) && widgetJsx() },
            { name: 'Site Productivity Report', jsx: loadedTabs.includes(1) && siteProductivityTable() },
        ];
    }


    return <ApplicationLayout>
        {pageHeading()}
        <div className="container-fluid pl5">
            <div className='page_containt row'>
                <div className='col-sm-12 pe-1 bg-white'>
                    <TapNavTabs tabReinitialize="N" tabs={reportTabs()} activetab={0} setTabValue={(activetab) => { setCurrentTab(activetab); }} />
                </div>
            </div>
        </div>
        {searchModalJsx()}
    </ApplicationLayout>

});

export default SiteProductivityReport;