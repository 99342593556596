import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import moment from 'moment';
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import swal from "sweetalert";
import CounterlogModal from './CounterlogModal';
import Document from './ShiftActivityModals/Document';
import FuelBalance from './ShiftActivityModals/FuelBalance';
import Downtime from './ShiftActivityModals/Downtime';
import ServiceChecksheet from './ShiftActivityModals/ServiceChecksheet';
import Productivity from './ShiftActivityModals/Productivity';
import Activity from './ShiftActivityModals/Activity';
import Expense from './ShiftActivityModals/expense/Expense';
import FuelFeedIn from './ShiftActivityModals/FuelFeedIn';
import { Helmet } from 'react-helmet';
import ExpenseViewModal from './ShiftActivityModals/expense/ExpenseViewModal';
import TaskDetail from '../../transactions/assetTask/TaskDetail';
import TaskSaveModal from './ShiftActivityModals/task/TaskSaveModal';
import TapHelper from '../../../services/TapHelper';
import EngagementTime from './ShiftActivityModals/EngagementTime';
 
class ShiftSummaryDetail extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initState              =   {
            access_token                :   '',
            transaction_id              :   '',
            assetData                   :   null,
            counterlog                  :   null,
            shift                       :   null,
            shiftDetailLoading          :   false,
            displayAssetDetail          :   false,
            fuelfeed_in                 :   [],
            fuelfeed_in_loading         :   false,
            downtime                    :   null,
            downtime_loading            :   false,
            executed_scs                :   [],
            executed_scs_loading        :   false,
            productivity                :   [],
            productivity_loading        :   false,
            activity                    :   [],
            activity_loading            :   false,
            task_loading                :   false,
            activity_task               :   [],
            fuelbalance                 :   null,
            fuelbalance_loading         :   false,
            document                    :   [],
            document_loading            :   false,
            expense                     :   [],
            expense_loading             :   false,
            isActivityPerform           :   false,
            isCounterLogPerform         :   false,
            shiftCounterLogDetail       :   null,
            iam_task_workflow           :   'N',
            taskTransactionId           :   '',
            engagement_loading          :   false,
            engagement                  :   []
        }
        
        this.state                      =   {...this.initState};
        
        this.fuelOpeningBalanceRef      =   React.createRef();
        this.uploadDocumentRef          =   React.createRef();
        this.CounterLogModalRef         =   React.createRef();
        this.DowntimeModalRef           =   React.createRef();
        this.serviceChecksheetModalRef  =   React.createRef();
        this.productivityModalRef       =   React.createRef();
        this.activityModalRef           =   React.createRef();
        this.viewExpenseRef             =   React.createRef();
        this.logExpenseRef              =   React.createRef();
        this.logFuelFeedInRef           =   React.createRef();
        this.taskWorkflowRef            =   React.createRef();
        this.engagementModalRef         =       React.createRef();

        this.task_delete_url            =   IAM_API_BASE_URL_2 + '/counterlog/activity_task_delete';
        this.clShiftReadingPutUrl       =   IAM_API_BASE_URL_2 + '/counterlog/shift_detail/__replace_txn_id';
    }
     
    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);
        this.taskDetailModal        =   new Modal(document.getElementById('taskDetailModal'), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.transaction_id != nextProps.transaction_id) {
            this.initalizeComponent(nextProps);
        }
    }
    
    initalizeComponent                  =   (pr)  =>  {
        this.setState({
            access_token                :   pr.access_token, 
            transaction_id              :   pr.transaction_id,
            displayAssetDetail          :   pr.displayAssetDetail ? pr.displayAssetDetail : false,
            iam_task_workflow           :   pr.group_permission && pr.group_permission.group_sub_modules && pr.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow") ? 'Y' : 'N',
        }, () => {
            this.fetchShiftDetail();
            this.fetchShiftActivities({
                fuelfeed_in         :       'Y',
                downtime            :       'Y',
                executed_scs        :       'Y',
                productivity        :       'Y',
                activity            :       'Y',
                activity_task       :       this.state.iam_task_workflow == 'Y' ? 'Y' : 'N',
                fuelbalance         :       'Y',
                expense             :       'Y',
                engagement_time     :       'Y',
                document            :       'Y',
                need_section        :       'Y'
            })
        });
    }

    refreshComponent                  =   ()  =>  {
       
        this.fetchShiftDetail({summary_refresh : 'Y'});
        this.fetchShiftActivities({
            fuelfeed_in         :       'Y',
            downtime            :       'Y',
            executed_scs        :       'Y',
            productivity        :       'Y',
            activity            :       'Y',
            activity_task       :       this.state.iam_task_workflow == 'Y' ? 'Y' : 'N',
            fuelbalance         :       'Y',
            expense             :       'Y',
            document            :       'Y',
            need_section        :       'Y',
            engagement_time     :       'Y',
        })
      
        this.setState({isActivityPerform : true, isCounterLogPerform : false})
        
    }
    
    fetchShiftDetail                    =   (params = null) => {
        this.setState({shiftDetailLoading : true});
        HttpAPICall.withAthorization('GET', this.clShiftReadingPutUrl.replace('__replace_txn_id', this.state.transaction_id), this.state.access_token, {...params}, {}, (resp) => {
             this.setState({
                assetData                   :   resp.data.asset,
                counterlog                  :   resp.data.counterlog,
                shift                       :   resp.data.shift,
                shiftCounterLogDetail       :   resp.data,
            });
        }, () => { }).then(() => {
            this.setState({shiftDetailLoading : false});
        });
    }
    
    fetchShiftActivities                =   (params)  =>  {
        
        if(params.fuelfeed_in && params.fuelfeed_in == "Y") {
            this.setState({fuelfeed_in_loading : true , fuelfeed_in : []});
        }
        if(params.downtime && params.downtime == "Y") {
            this.setState({downtime_loading : true , downtime : null});
        }
        if(params.executed_scs && params.executed_scs == "Y") {
            this.setState({executed_scs_loading : true , executed_scs : []});
        }
        if(params.productivity && params.productivity == "Y") {
            this.setState({productivity_loading : true , productivity : []});
        }
        if(params.activity && params.activity == "Y") {
            this.setState({activity_loading : true , activity : []});
        }
        if(params.activity_task && params.activity_task == "Y") {
            this.setState({task_loading : true , activity_task : []});
        }
        if(params.fuelbalance && params.fuelbalance == "Y") {
            this.setState({fuelbalance_loading : true , fuelbalance : null});
        }
        if(params.expense && params.expense == "Y") {
            this.setState({expense_loading : true , expense : []});
        }
        if(params.document && params.document == "Y") {
            this.setState({document_loading : true , document : []});
        }
        if(params.engagement_time && params.engagement_time == "Y") {
            this.setState({engagement_loading : true , engagement : []});
        }
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/shift_activity_list/' + this.state.transaction_id, this.state.access_token, params, {}, (resp) => { 
            if(params.fuelfeed_in && params.fuelfeed_in == "Y") {
                this.setState({fuelfeed_in_loading : false , fuelfeed_in : resp.data.fuelfeed_in});
            }
            if(params.downtime && params.downtime == "Y") {
                this.setState({downtime_loading : false , downtime : resp.data.downtime});
            }
            if(params.executed_scs && params.executed_scs == "Y") {
                this.setState({executed_scs_loading : false , executed_scs : resp.data.executed_scs});
            }
            if(params.productivity && params.productivity == "Y") {
                this.setState({productivity_loading : false , productivity : resp.data.productivity});
            }
            if(params.activity && params.activity == "Y") {
                this.setState({activity_loading : false , activity : resp.data.activity});
            }
            if(params.activity_task && params.activity_task == "Y") {
                this.setState({task_loading : false , activity_task : resp.data.activity_tasks});
            }
            if(params.fuelbalance && params.fuelbalance == "Y") {
                this.setState({fuelbalance_loading : false , fuelbalance : resp.data.fuelbalance});
            }
            if(params.expense && params.expense == "Y") {
                this.setState({expense_loading : false , expense : resp.data.expense});
            }
            if(params.document && params.document == "Y") {
                this.setState({document_loading : false , document : resp.data.document});
            }
            if(params.engagement_time && params.engagement_time == "Y") {
                this.setState({engagement_loading : false , engagement : resp.data.engagements});
            }
        }, () => {
           
        }).then(() => {
            
        });
    }
    
    deleteCounterlog                    =   ()  =>  {
        this.CounterLogModalRef.current.deleteCounterlog(this.state.counterlog.transaction_id);
    }
    
    editCounterlogModalInit             =   ()  =>  {
        if (this.state.shiftCounterLogDetail){
            this.CounterLogModalRef.current.editCounterlogInit(this.state.shiftCounterLogDetail)
      } 
    }
    
    closeSummaryDetails                 =   ()  =>  {
        
        this.props.onCloseEvent(this.state.counterlog.feed_date, this.state.counterlog.asset_id, this.state.isActivityPerform, this.state.isCounterLogPerform);
        //this.setState({...this.initState}, () => { });
    }
    
    uploadDocumentInit                  =   () =>  {
        this.uploadDocumentRef.current.uploadDocumentModalInit(this.state.shift, this.state.counterlog, this.state.assetData);
    }
    
    deleteDocument                      =   (id)  =>  {
        this.uploadDocumentRef.current.deleteDocument(this.state.counterlog.transaction_id, id);
    }
    
    fuelBalanceModalInit                =   () =>  {
        this.fuelOpeningBalanceRef.current.fuelOpeningBalanceModalInit(this.state.shift, this.state.counterlog, this.state.assetData, this.state.fuelbalance);
    }

    editProductivityModalInit                =   (data) =>  {
        if(data){
            HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/productivity/' + data.productivity_id , this.state.access_token ? this.state.access_token : this.props.access_token, {need_counterlog : 'Y',need_site : 'Y',need_user : 'Y'}, {}, (response) => {
               let  productivityData= {...response.data.data,counterlog : {...response.data.data.counterlog,site: response.data.data.site}} ;
                this.productivityModalRef.current.uploadProductivityModalInit(this.state.shift, this.state.counterlog, this.state.assetData, productivityData);
              
            }, (error) => {
                toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
            })
        }
    }

    addProductivityModalInit                =   () =>  {
        this.productivityModalRef.current.uploadProductivityModalInit(this.state.shift, this.state.counterlog, this.state.assetData);
    }

    addActivityModalInit                =   () =>  {
        this.activityModalRef.current.uploadActivityModalInit(this.state.shift, this.state.counterlog, this.state.assetData);
    }

    addDowntimeInit                =   (id) =>  {
        this.DowntimeModalRef.current.uploadDowntimeModalInit(this.state.shift, this.state.counterlog, this.state.assetData,id);
    }
    editEngagementTimeInit                =   (id) =>  {
        this.engagementModalRef.current.uploadEngagementModalInit(this.state.shift, this.state.counterlog, this.state.assetData,id);
    }
    addEngagementTimeInit                =   () =>  {
        this.engagementModalRef.current.uploadEngagementModalInit(this.state.shift, this.state.counterlog, this.state.assetData);
    }

    addServiceChecksheetInit                  =   () =>  {
      this.serviceChecksheetModalRef.current.serviceChecksheetModalInit(this.state.shift, this.state.counterlog, this.state.assetData); 
    }

    addExpenseInit                  =   () =>  {
        this.logExpenseRef.current.uploadExpenseModalInit(this.state.shift, this.state.counterlog, this.state.assetData); 
    }

    viewProductivityModalInit                =   (data) =>  {
        this.productivityModalRef.current.viewProductivityModalInit(this.state.shift, this.state.counterlog, this.state.assetData,data);
    }

    editActivityModalInit                =   (id) =>  {
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/activity/' + id, this.state.access_token, {}, {}, (response) => {
            this.activityModalRef.current.uploadActivityModalInit(this.state.shift, this.state.counterlog, this.state.assetData,response.data.data);
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
       
    }

    viewActivityInit                =   (data) =>  {
        this.activityModalRef.current.viewActivityModalInit(this.state.shift, this.state.counterlog, this.state.assetData,data);
    }
    
    editExpenseModalInit                =   (data) =>  {
        this.logExpenseRef.current.uploadExpenseModalInit(this.state.shift, this.state.counterlog, this.state.assetData, data);
    }

    viewExpenseInit                =   (id) =>  {
       if(id){
        this.viewExpenseRef.current.showExpenseDetailInit(this.state.shift, this.state.counterlog, this.state.assetData,id);
       }
    }

    editFuelFeedInModalInit                =   (data) =>  {
        this.logFuelFeedInRef.current.uploadFuelFeedInModalInit(this.state.shift, this.state.counterlog, this.state.assetData, data,this.state.assetData.asset_id);
    }

    addFuelFeedInit                =   () =>  {
        this.logFuelFeedInRef.current.uploadFuelFeedInModalInit(this.state.shift, this.state.counterlog, this.state.assetData,null,this.state.assetData.asset_id);
    }

    fuelBalanceDeleteInit               =   () =>  {
        this.fuelOpeningBalanceRef.current.deleteFuelOpeningBalanceInit(this.state.counterlog.transaction_id);
    }

    downtimeDeleteInit               =   (transaction_id) =>  {
        this.DowntimeModalRef.current.deleteDowntimeInit(this.state.counterlog.transaction_id,transaction_id);
    }

    deleteEngagementTime               =   (transaction_id) =>  {
        this.engagementModalRef.current.deleteEngagementTimeInit(this.state.counterlog.transaction_id,transaction_id);
    }

    activityDeleteInit               =   (id) =>  {
        this.activityModalRef.current.deleteActivityInit(id);
    }

    serviceChecksheetDeleteInit               =   (id) =>  {
        this.serviceChecksheetModalRef.current.deleteSeviceChecksheetInit(id);
    }

    serviceChecksheetViewInit                =   (id) =>  {
        this.serviceChecksheetModalRef.current.serviceChecksheetViewModalInit(this.state.shift, this.state.counterlog, this.state.assetData,id);
    }

    productivityDeleteInit               =   (id) =>  {
        this.productivityModalRef.current.deleteProductivityInit(id);
    }

    expenseDeleteInit               =   (id) =>  {
        this.logExpenseRef.current.deleteExpenseInit(id);
    }

    deleteFuelFeedInInit               =   (id) =>  {
        this.logFuelFeedInRef.current.deleteFuelFeedInInit(id);
    }

    viewFuelFeedInInit               =   (id) =>  {
        this.logFuelFeedInRef.current.viewFuelFeedInModalInit(this.state.shift, this.state.counterlog, this.state.assetData,id);
    }

    viewDowntimeInit               =   (id) =>  {
        this.DowntimeModalRef.current.viewDowntimeDetailModalInit(this.state.shift, this.state.counterlog, this.state.assetData,id);
    }
    viewEngagementInit               =   (id) =>  {
        this.engagementModalRef.current.viewEngagementDetailModalInit(this.state.shift, this.state.counterlog, this.state.assetData,id);
    }
    
    taskModalInit                   =       (id,status)        =>      {
        if(id) {
            let counterlog = this.state.counterlog;
            let site     = this.state.counterlog && this.state.counterlog.site ? this.state.counterlog.site : null;
            let one_task_in_progress = 'N';
            if(this.state.activity_task && this.state.activity_task.length > 0){
                one_task_in_progress = 'Y'
            }
            
            if(status == 'Y'){
                this.taskWorkflowRef.current.uploadTaskModalInit(this.state.assetData,site,this.state.shift,this.state.counterlog,one_task_in_progress,id);
            }else{
                this.taskWorkflowRef.current.uploadTaskModalInit(this.state.assetData,site,null,this.state.counterlog,one_task_in_progress,id);
            }
            
           
        }
        
    }

    taskDelete                    =   (transaction_id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.task_delete_url, this.props.access_token, {transaction_id : transaction_id}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.fetchShiftActivities({activity_task : "Y",productivity:'Y'});
                    this.setState({isActivityPerform : true});
                });
            }
        });
    }

    SecondsToHHMM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }

    summaryHeadingJsx                   =   ()  =>  {
        return <div className="mt-1">
            <div className="bg-white row m0 text-decoration-none d-flex align-items-center">
                <div className="col py-2">
                    <h5 className="fs-6 m-0">
                        <span>Logsheet : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                        {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                    </h5>
                </div>
                <div className='col text-end my-2'>
                    <button type="button" className="btn btn-primary ms-1" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} className="img-fluid mr3" alt="item-action" /> 
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                        <li>
                            <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-fuel-feedin-add')) {
                                        return this.addFuelFeedInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-fuel-feedin-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-fuel-feedin-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Log FuelFeedIn</a>
                        </li>
                        <li>
                            <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('downtime-add')) {
                                        return this.addDowntimeInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('downtime-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('downtime-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Log Downtime</a>
                        </li>
                        
                        <li>
                            <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-asset-productivity-add')) {
                                        return this.addProductivityModalInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-asset-productivity-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-asset-productivity-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Log Productivity</a>
                        </li>  
                       {this.state.iam_task_workflow == 'N' &&  <li>
                            <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-asset-activity-add')) {
                                        return this.addActivityModalInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-asset-activity-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-asset-activity-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Log Activity</a>
                        </li> }
                        <li>
                            <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-asset-scs-execute')) {
                                        return this.addServiceChecksheetInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-asset-scs-execute') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-asset-scs-execute') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Log Service Checksheet</a>
                        </li> 
                        <li>
                            <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-shift-counterlog-expense-add')) {
                                        return this.addExpenseInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-shift-counterlog-expense-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-shift-counterlog-expense-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Add Expense</a>
                        </li> 
                        <li>
                            <a role="button"
                                onClick={() => {this.addEngagementTimeInit()}}
                                className={['dropdown-item',].join(' ')}
                                style={{ "pointerEvents": "all" }}
                            >Add Engagement Time</a>
                        </li> 

                        <li>
                            <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-counterlog-fuel-balance-add')) {
                                        return this.fuelBalanceModalInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-counterlog-fuel-balance-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-counterlog-fuel-balance-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Update Fuel Opening Balance</a>
                        </li>   
                        <li>
                            <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-asset-counterlog-doc-add')) {
                                        return this.uploadDocumentInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-asset-counterlog-doc-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-asset-counterlog-doc-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Add Document</a>
                        </li>           
                       
                    </ul>
                    <button type="button" className="btn btn-secondary ms-1" onClick={() => this.refreshComponent()} disabled={this.state.shiftDetailLoading}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className={['cursor_pointer', 'img-fluid', this.state.shiftDetailLoading ? "fa-spin" : ''].join(' ')} color="white" />
                    </button>
                    <button type="button" className="btn btn-secondary ms-1" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                        <li className='dropdown-item' onClick={() => this.editCounterlogModalInit()}>Edit Counterlog</li>
                        <li className='dropdown-item' onClick={() => this.deleteCounterlog()}>Delete Counterlog</li>
                    </ul>
                    <button type="button" className="btn btn-secondary ms-1" onClick={this.closeSummaryDetails} disabled={this.state.shiftDetailLoading} >
                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="cursor_pointer" color="white" />
                    </button>
                </div>    
            </div>
        </div>
    }
    
    shiftReadingDetailJsx               =   ()  =>  {
        return <div className="py-2">
            {this.state.shiftDetailLoading
                ? <Loader />
                : <Ax>
                    {this.state.displayAssetDetail
                        ? <Ax>
                            <div className="tab_content_wrapper fw-bold my-2">Asset Details</div>
                            <div className="row py-2 border-top border-bottom">
                                <div className="col-6">Asset Name</div>
                                <div className="col-6 px-0 fw-bold">{this.state.assetData ? this.state.assetData.name : ''}</div>
                            </div>
                            <div className="row py-2 border-bottom">
                                <div className="col-6">Code</div>
                                <div className="col-6 px-0 fw-bold">{this.state.assetData ? this.state.assetData.asset_code : ''}</div>
                            </div>
                            <div className="row py-2 border-bottom">
                                <div className="col-6">Asset Type</div>
                                <div className="col-6 px-0 fw-bold">{this.state.assetData ? this.state.assetData.asset_type_name : ''}</div>
                            </div>
                        </Ax>
                        : null
                    }
                    <div className="tab_content_wrapper fw-bold my-2">Shift Details</div>
                    <div className="row py-2 border-top border-bottom">
                        <div className="col-6">Roster Name</div>
                        <div className="col-6 px-0 fw-bold">{this.state.shift ? this.state.shift.roster_name : ''}</div>
                    </div>
                    <div className="row py-2 border-bottom">
                        <div className="col-6">Shift Name</div>
                        <div className="col-6 px-0 fw-bold">{this.state.shift ? this.state.shift.shift_name : ''}</div>
                    </div>
                    <div className="row py-2 border-bottom">
                        <div className="col-6">Shift Start Time</div>
                        <div className="col-6 px-0 fw-bold">{this.state.shift ? this.state.shift.shift_start : ''}</div>
                    </div>

                    <div className="tab_content_wrapper fw-bold my-2">Counter Log Details
                        <div className='float-end'>
                            <button type="button" className="btn btn-secondary btn-sm mx-2" onClick={() => this.editCounterlogModalInit()}><tapIcon.FontAwesomeIcon icon={tapIcon.faPencilAlt} /></button>
                            <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.deleteCounterlog()}><tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt} /></button>

                        </div>
                    </div>
                    {this.state.counterlog && this.state.counterlog.display.map(counterlog => {
                        return (
                            <div className="row py-2 border-top border-bottom">
                                <div className="col-6">{counterlog.label}</div>
                                <div className="col-6 px-0 fw-bold">{counterlog.reading} {counterlog.unit}</div>
                            </div>
                        )
                    })}

                    <div className="row py-2 border-bottom">
                        <div className="col-6">Site</div>
                        <div className="col-6 px-0 fw-bold">{this.state.counterlog && this.state.counterlog.site ? <span>{this.state.counterlog.site.site_name} </span> : ''}</div>
                    </div>
                    <div className="row py-2 border-bottom">
                        <div className="col-6">Remarks</div>
                        <div className="col-6 px-0">{this.state.counterlog ? <span>{this.state.counterlog.remarks}</span> : ''}</div>
                    </div>
                    <div className="row py-2 border-bottom">
                        <div className="col-6">CL Meter Defective</div>
                        <div className="col-6 px-0 fw-bold">{this.state.counterlog && this.state.counterlog.defective_meter && this.state.counterlog.defective_meter == 'Y' ? <span>Yes </span> : 'No'}</div>
                    </div>
                    {this.props.group_permission && this.props.group_permission.group_modules.includes("hrm")
                        ? <div className="row py-2 border-bottom">
                            <div className="col-5">Operator</div>
                            <div className="col-7 px-0 pe-2 fw-bold">{this.state.shift && this.state.shift.operators && this.state.shift.operators.length > 0 ? <ol>{this.state.shift.operators.map(operator => {return(<li>{operator.display_full_name}</li>)} )}</ol> : '-'} </div>
                        </div> : null}
                    <div className="tab_content_wrapper fw-bold my-3">Transaction Analysis</div>
                    <div className="row py-2 border-top border-bottom">
                        <div className="col-6">Transaction ID</div>
                        <div className="col-6 px-0 fw-bold">{this.state.counterlog ? this.state.counterlog.transaction_id : ''}</div>
                    </div>
                    <div className="row py-2 border-bottom">
                        <div className="col-6">Date of Entry</div>
                        <div className="col-6 px-0 fw-bold">{this.state.counterlog ? this.state.counterlog.created_at_display : ''}</div>
                    </div>
                    <div className="row py-2 border-bottom">
                        <div className="col-6">Transaction Entered By</div>
                        <div className="col-6 px-0 fw-bold">{this.state.counterlog && this.state.counterlog.user ? this.state.counterlog.user.full_name : ''}</div>
                    </div>
                </Ax>
            }
        </div>
    }
    
    shiftSummaryJsx                     =   ()  =>  {
       
        return <div className="py-2">
            <Ax>
                {!this.state.fuelfeed_in_loading && this.state.fuelfeed_in && this.state.fuelfeed_in.length == 0 && !this.state.downtime_loading && this.state.downtime && JSON.stringify(this.state.downtime) == JSON.stringify({}) && !this.state.productivity_loading && this.state.productivity && this.state.productivity.length == 0
                    && !this.state.activity_loading && this.state.activity && this.state.activity.length == 0 && !this.state.executed_scs_loading && this.state.executed_scs && this.state.executed_scs.length == 0 && !this.state.fuelbalance_loading && !this.state.fuelbalance && !this.state.document_loading && this.state.document && this.state.document.length == 0 && this.state.expense && this.state.expense.length == 0
                    && !this.state.task_loading && this.state.activity_task && this.state.activity_task.length == 0 ? <div className="alert alert-secondary text-center align-middle" role="alert">
                    No Transaction Recorded
                </div> : null}
                {this.state.fuelfeed_in_loading || this.state.fuelfeed_in && this.state.fuelfeed_in.length > 0 ? <div className="tab_content_wrapper fw-bold mb-3">Fuel Feed-In</div> : null}
                {this.state.fuelfeed_in_loading
                    ? <Loader />
                    : this.state.fuelfeed_in && this.state.fuelfeed_in.length > 0
                        ? <Ax>
                            <table className=" my-3 table table-bordered table-hover">
                                <thead className="bg-light">
                                    <tr>
                                        <th style={{ width: "15%" }}>Transaction ID</th>
                                        <th style={{ width: "25%" }}>Fuel Station</th>
                                        <th style={{ width: "20%" }}>Fuel Type</th>
                                        <th style={{ width: "10%" }} className="text-end">Quantity</th>
                                        <th style={{ width: "10%" }} className="text-end">Unit</th>
                                        <th style={{ width: "10%" }} className="text-end">Amount</th>
                                        <th style={{ width: "10%" }} className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fuelfeed_in && this.state.fuelfeed_in.length > 0
                                        ? this.state.fuelfeed_in.map((fuel, k) => {
                                            return (<tr>
                                                <td className='link-primary cursor_pointer' onClick={() => this.viewFuelFeedInInit(fuel.transaction_id)}>{fuel.feedin_id ? fuel.feedin_id : "-"}</td>
                                                <td>{fuel.fuelStation ? fuel.fuelStation.fuelstation_name : fuel.other_station}</td>
                                                <td>{fuel.fuel_type && fuel.fuel_type ? fuel.fuel_type : "-"}</td>
                                                <td className="text-end fw-bold">{fuel.executed_qty ? `${fuel.executed_qty} ${fuel.fuel_unit}` : 0}</td>
                                                <td className="text-end">{fuel.unit_rate ? `${fuel.unit_rate}` : 0}</td>
                                                <td className="text-end">{fuel.executed_amount ? fuel.executed_amount : 0}</td>
                                                <td className="text-center">
                                                    <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                        <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                        <li>
                                                            <a role="button"
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('iam-fuel-feedin-update')) {
                                                                        return this.editFuelFeedInModalInit(fuel)
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('iam-fuel-feedin-update') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('iam-fuel-feedin-update') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Edit</a>
                                                        </li>


                                                        <li className='dropdown-item' onClick={() => this.viewFuelFeedInInit(fuel.transaction_id)}>View</li>
                                                        <li>
                                                            <a role="button"
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('iam-fuel-feedin-delete')) {
                                                                        return this.deleteFuelFeedInInit(fuel.transaction_id)
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('iam-fuel-feedin-delete') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('iam-fuel-feedin-delete') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Delete</a>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </tr>)
                                        }) : <tr><td colSpan={5} className="text-center">No Record</td></tr>}
                                </tbody>
                            </table>

                        </Ax>
                        : null
                }
                {this.state.downtime_loading || this.state.downtime && this.state.downtime.length > 0 ? <div className="tab_content_wrapper fw-bold mb-3">Downtime</div> : null}
                {this.state.downtime_loading
                    ? <Loader />
                    : this.state.downtime && this.state.downtime && this.state.downtime.length > 0
                        ? <Ax>
                            <table className=" my-3 table table-bordered table-hover ">
                                <thead className="bg-light">
                                    <tr>
                                        <th style={{ width: "15%" }}>Transaction ID</th>
                                        <th style={{ width: "15%" }}>Type of Downtime</th>
                                        <th style={{ width: "10%" }}>Hours</th>
                                        <th style={{ width: "20%" }}>Section</th>
                                        <th style={{ width: "10%" }} className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.downtime && this.state.downtime && this.state.downtime.length > 0
                                        ? this.state.downtime.map((dt, k) => {
                                            return (<tr key={k}>
                                                <td
                                                    className={[this.props.permissions.includes('downtime-view') ? 'link-primary cursor_pointer' : 'disabled'].join(' ')}
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('downtime-view')) {
                                                            this.viewDowntimeInit(dt.transaction_id)
                                                        }
                                                    }} >{dt.transaction_id ? dt.transaction_id : "-"}</td>
                                                <td>{dt.downtime_type ? dt.downtime_type.name : "-"}</td>
                                                <td>{dt.downtime_seconds ? this.SecondsToHHMM(dt.downtime_seconds) : "0"}</td>
                                                <td>{dt.section_data ? dt.section_data.component : "-"}</td>
                                                <td className="text-center">
                                                    <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                        <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                        <li>
                                                            <a role="button"
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('downtime-view')) {
                                                                        return this.viewDowntimeInit(dt.transaction_id)
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('downtime-view') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('downtime-view') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >View</a>
                                                        </li>
                                                        <li>
                                                            <a role="button"
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('downtime-edit')) {
                                                                        return this.addDowntimeInit(dt.transaction_id)
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('downtime-edit') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('downtime-edit') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Edit</a>
                                                        </li>
                                                        <li>
                                                            <a role="button"
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('downtime-delete')) {
                                                                        return this.downtimeDeleteInit(dt.transaction_id)
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('downtime-delete') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('downtime-delete') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Delete</a>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </tr>)
                                        })

                                        : <tr><td colSpan={5} className="text-center">No Record</td></tr>}
                                </tbody>

                            </table>
                        </Ax>
                        : null
                }
                {this.state.productivity_loading || this.state.productivity && this.state.productivity.length > 0 ? <div className="tab_content_wrapper fw-bold mb-3">Productivity</div> : null}
                {this.state.productivity_loading
                    ? <Loader />
                    : this.state.productivity && this.state.productivity.length > 0 ?
                        <Ax>
                            <table className=" my-3 table table-bordered table-hover table-sm ">
                                <thead className="bg-light">
                                    <tr>
                                        <th style={{ width: "15%" }}>Transaction ID</th>
                                        <th style={{ width: "19%" }}>Activity</th>
                                        <th style={{ width: "20%" }}>Net Productivity</th>
                                        <th style={{ width: "18%" }}>Material Name</th>

                                        <th style={{ width: "18%" }}>Attachment</th>
                                        <th style={{ width: "10%" }} className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.productivity && this.state.productivity.length > 0
                                        ? this.state.productivity.map((productivity, k) => {
                                            return (<tr>
                                                <td>{productivity.activity_id ? <span className='link-primary cursor_pointer' onClick={() => this.viewProductivityModalInit(productivity)}>{productivity.activity_id}</span> : "-"}</td>
                                                <td>{productivity.activityData && productivity.activityData.activity_name ? productivity.activityData.activity_name : "-"}</td>
                                                <td className='fw-bold'>{productivity.production ? `${productivity.production} ${productivity.production_unit ? productivity.production_unit : ''}` : "-"}</td>
                                                <td>{productivity.materialData && productivity.materialData.material_name ? productivity.materialData.material_name : "-"}</td>

                                                <td>{productivity.attachmentData && productivity.attachmentData.name ? productivity.attachmentData.name : "-"}</td>
                                                <td className="text-center">
                                                    <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                        <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                        <li>
                                                            <a role="button"
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('iam-asset-productivity-update') && productivity.with_activity == 'N') {
                                                                        return this.editProductivityModalInit(productivity)
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('iam-asset-productivity-update') && productivity.with_activity == 'N' ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('iam-asset-productivity-update') || productivity.with_activity == 'Y' ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Edit</a>
                                                        </li>
                                                        <li className='dropdown-item' onClick={() => this.viewProductivityModalInit(productivity)} >View</li>
                                                        <li>
                                                            <a role="button"
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('iam-asset-productivity-delete') && productivity.with_activity == 'N') {
                                                                        return this.productivityDeleteInit(productivity.id)
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('iam-asset-productivity-delete') && productivity.with_activity == 'N' ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('iam-asset-productivity-delete') || productivity.with_activity == 'Y'  ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Delete</a>
                                                        </li>

                                                    </ul>
                                                </td>
                                            </tr>)
                                        }) : <tr><td colSpan={5} className="text-center">No Record</td></tr>}
                                </tbody>
                            </table>
                        </Ax>
                        : null
                }
                
                {this.state.activity_loading || this.state.activity && this.state.activity.length > 0 ? <div className="tab_content_wrapper fw-bold mb-3">Asset Activity</div> : null}
                {this.state.activity_loading
                    ? <Loader />
                    : this.state.activity && this.state.activity.length > 0
                        ? <table className=" my-3 table table-bordered table-hover ">
                            <thead className="bg-light">
                                <tr>
                                    <th style={{ width: "15%" }}>Transaction ID</th>
                                    <th style={{ width: "18%" }}>Activity</th>
                                    <th style={{ width: "18%" }}>Activity Name</th>
                                    <th style={{ width: "18%" }}>Productivity</th>
                                    <th style={{ width: "21%" }}>Workstation</th>
                                    <th style={{ width: "10%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.activity && this.state.activity.length > 0
                                    ? this.state.activity.map((activity, k) => {
                                        return (<tr>
                                            <td>{activity.asset_activity_id ?
                                                <span className='link-primary cursor_pointer' onClick={() => this.viewActivityInit(activity)}>{activity.asset_activity_id}</span>
                                                : "-"}</td>
                                            <td className='fw-bold'>{activity.activity_type_name ? `${activity.activity_count} ${activity.activity_type_name}` : "-"}</td>
                                            <td>{activity.activity_name ? activity.activity_name : "-"}</td>
                                            <td className='fw-bold'>{activity.productivity ? `${activity.productivity.production} ${activity.productivity.production_unit ? activity.productivity.production_unit : ''}` : "-"}</td>
                                            <td>{activity.workstation_from ? activity.workstation_from_name : "-"} {activity.workstation_to ? `- ${activity.workstation_to_name}` : ""}</td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                    <li>
                                                        <a role="button"
                                                            onClick={() => {
                                                                if (this.props.permissions.includes('iam-asset-activity-update')) {
                                                                    return this.editActivityModalInit(activity.asset_activity_id)
                                                                }
                                                            }}
                                                            className={['dropdown-item', this.props.permissions.includes('iam-asset-activity-update') ? '' : 'disabled'].join(' ')}
                                                            title={!this.props.permissions.includes('iam-asset-activity-update') ? 'You do not have permission to perform this action' : ''}
                                                            style={{ "pointerEvents": "all" }}
                                                        >Edit</a>
                                                    </li>
                                                    <li className='dropdown-item' onClick={() => this.viewActivityInit(activity)} >View</li>
                                                    <li>
                                                        <a role="button"
                                                            onClick={() => {
                                                                if (this.props.permissions.includes('iam-asset-activity-delete')) {
                                                                    return this.activityDeleteInit(activity.asset_activity_id)
                                                                }
                                                            }}
                                                            className={['dropdown-item', this.props.permissions.includes('iam-asset-activity-delete') ? '' : 'disabled'].join(' ')}
                                                            title={!this.props.permissions.includes('iam-asset-activity-delete') ? 'You do not have permission to perform this action' : ''}
                                                            style={{ "pointerEvents": "all" }}
                                                        >Delete</a>
                                                    </li>

                                                </ul>
                                            </td>
                                        </tr>)
                                    }) : <tr><td colSpan={6} className="text-center">No Record</td></tr>}
                            </tbody>
                        </table>
                        : null
                }
                {this.state.task_loading || this.state.activity_task && this.state.activity_task.length > 0 ? <div className="tab_content_wrapper fw-bold mb-3">Task</div> : null}
                {this.state.task_loading
                    ? <Loader />
                    : this.state.activity_task && this.state.activity_task.length > 0
                        ? <table className=" my-3 table table-bordered table-hover ">
                            <thead className="bg-light">
                                <tr>
                                    <th style={{ width: "15%" }}>Transaction ID</th>
                                    <th style={{ width: "18%" }}>Activity Name</th>
                                    <th style={{ width: "18%" }}>Productivity</th>
                                    <th style={{ width: "21%" }}>Status</th>
                                    <th style={{ width: "10%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.activity_task && this.state.activity_task.length > 0
                                    ? this.state.activity_task.map((task, k) => {
                                        return (<tr>
                                            <td className='link-primary' onClick={() => this.taskModalInit(task.transaction_id, task.task_in_progress)}>{task.transaction_id ? task.transaction_id : "-"}</td>
                                            <td>{task.activity_detail ? task.activity_detail.name : "-"}</td>
                                            <td>{task.productivity && task.productivity.production ? <span>{task.productivity.production} {task.productivity.production_unit ? <span className='text-sm'>({task.productivity.production_unit})</span> : "-"} </span> : "-"}</td>
                                            <td>{task.task_in_progress == 'Y' ? "InProgress" : "Closed"}</td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                    <li className='dropdown-item link-primary' onClick={() => this.taskModalInit(task.transaction_id, task.task_in_progress)}>View</li>
                                                    <li>
                                                                <a role="button"
                                                                    onClick={() => {
                                                                        if (this.props.permissions.includes('iam-asset-task-delete')) {
                                                                            return this.taskDelete(task.transaction_id)
                                                                        }
                                                                    }}
                                                                    className={['dropdown-item', this.props.permissions.includes('iam-asset-task-delete') ? '' : 'disabled'].join(' ')}
                                                                    title={!this.props.permissions.includes('iam-asset-task-delete') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                >Delete</a>
                                                            </li>
                                                   
                                                </ul>
                                            </td>
                                        </tr>)
                                    }) : <tr><td colSpan={6} className="text-center">No Record</td></tr>}
                            </tbody>
                        </table>
                        : null
                }
                {this.state.executed_scs_loading || this.state.executed_scs && this.state.executed_scs.length > 0 ? <div className="tab_content_wrapper fw-bold mb-3">Executed Checksheet</div> : null}
                {this.state.executed_scs_loading
                    ? <Loader />
                    : this.state.executed_scs && this.state.executed_scs.length > 0
                        ? <table className=" my-3 table table-bordered table-hover ">
                            <thead className="bg-light">
                                <tr>
                                    <th style={{ width: "15%" }}>Transaction ID</th>
                                    <th style={{ width: "25%" }}>Checksheet Name</th>
                                    <th style={{ width: "15%" }}>Section</th>
                                    <th style={{ width: "15%" }}>Sub-Section</th>
                                    <th style={{ width: "20%" }}>Parameter</th>
                                    <th style={{ width: "10%" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.executed_scs && this.state.executed_scs.length > 0
                                    ? this.state.executed_scs.map((ff, k) => {
                                        return (<tr>
                                            <td className='link-primary cursor_pointer' onClick={() => this.serviceChecksheetViewInit(ff.id)}>{ff.transaction_id ? ff.transaction_id : "-"}</td>
                                            <td className='fw-bold'>{ff.scs_setting && ff.scs_setting.name ? ff.scs_setting.name : "-"}</td>
                                            <td>{ff.scs_setting && ff.scs_setting.section && ff.scs_setting.section.component ? ff.scs_setting.section.component : "-"}</td>
                                            <td>{ff.scs_setting && ff.scs_setting.sub_section && ff.scs_setting.sub_section.subcomponent ? ff.scs_setting.sub_section.subcomponent : "-"}</td>
                                            <td> {ff.parameter && ff.parameter.execute_display ? <span><tapIcon.FontAwesomeIcon icon={tapIcon.faCircle} style={{ color: `${ff.parameter.execute_display.color}` }} /> {ff.parameter.execute_display.text}</span> : "-"} </td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                    <li className='dropdown-item' onClick={() => this.serviceChecksheetViewInit(ff.id)}>View</li>
                                                    <li>
                                                        <a role="button"
                                                            onClick={() => {
                                                                if (this.props.permissions.includes('iam-asset-scs-execute-delete')) {
                                                                    return this.serviceChecksheetDeleteInit(ff.id)
                                                                }
                                                            }}
                                                            className={['dropdown-item', this.props.permissions.includes('iam-asset-scs-execute-delete') ? '' : 'disabled'].join(' ')}
                                                            title={!this.props.permissions.includes('iam-asset-scs-execute-delete') ? 'You do not have permission to perform this action' : ''}
                                                            style={{ "pointerEvents": "all" }}
                                                        >Delete</a>
                                                    </li>

                                                </ul>
                                            </td>
                                        </tr>)
                                    }) : <tr><td colSpan={5} className="text-center">No Record</td></tr>}

                            </tbody>
                        </table>
                        : null
                }
                {this.state.expense_loading || this.state.expense && this.state.expense.length > 0 ? <div className="tab_content_wrapper fw-bold mb-3">Expense</div> : null}
                {this.state.expense_loading
                    ? <Loader />
                    : this.state.expense && this.state.expense.length > 0
                        ? <table className=" my-3 table table-bordered table-hover">
                            <thead className="bg-light">
                                <tr>
                                    <th style={{ width: "15%" }}>Transaction ID</th>
                                    <th style={{ width: "19%" }}>Expense Head</th>
                                    <th style={{ width: "24%" }}>Expense Details</th>
                                    <th style={{ width: "8%" }} className="text-end">Amount</th>
                                    <th style={{ width: "24%" }}>Payee Name</th>
                                    <th style={{ width: "10%" }} className="text-center">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.state.expense && this.state.expense.length > 0
                                    ? this.state.expense.map((expense, k) => {
                                        let expense_head_name = TapHelper.firstStringPlusFormatter(expense.expense_heads.map(h => h.expense_head));
                                        return (<tr>
                                            <td className='link-primary cursor_pointer' onClick={() => this.viewExpenseInit(expense.transaction_id)}>{expense.transaction_id ? expense.transaction_id : "-"}</td>
                                            <td>{expense_head_name}</td>
                                            <td>{expense.details ? expense.details : "-"}</td>
                                            <td className="text-end fw-bold">{expense.amount ? expense.amount : "0"}</td>
                                            <td>{expense.payee_name ? expense.payee_name : expense.employeeData ? expense.employeeData.display_full_name : expense.vendorData ? expense.vendorData.name : "-"} </td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                    <li>
                                                        <a role="button"
                                                            onClick={() => {
                                                                if (this.props.permissions.includes('iam-asset-expense-update') && expense.can_edit == 'Y') {
                                                                    return this.editExpenseModalInit(expense)
                                                                }
                                                            }}
                                                            className={['dropdown-item', this.props.permissions.includes('iam-asset-expense-update') && expense.can_edit == 'Y' ? '' : 'disabled'].join(' ')}
                                                            title={!this.props.permissions.includes('iam-asset-expense-update') && expense.can_edit == 'N' ? 'You do not have permission to perform this action' : ''}
                                                            style={{ "pointerEvents": "all" }}
                                                        >Edit</a>
                                                    </li>
                                                    <li className='dropdown-item' onClick={() => this.viewExpenseInit(expense.transaction_id)}>View</li>
                                                    <li>
                                                        <a role="button"
                                                            onClick={() => {
                                                                if (this.props.permissions.includes('iam-asset-expense-delete') && expense.can_edit == 'Y' ) {
                                                                    return this.expenseDeleteInit(expense.transaction_id)
                                                                }
                                                            }}
                                                            className={['dropdown-item', this.props.permissions.includes('iam-asset-expense-delete') && expense.can_edit == 'Y' ? '' : 'disabled'].join(' ')}
                                                            title={!this.props.permissions.includes('iam-asset-expense-delete') && expense.can_edit == 'N' ? 'You do not have permission to perform this action' : ''}
                                                            style={{ "pointerEvents": "all" }}
                                                        >Delete</a>
                                                    </li>

                                                </ul>
                                            </td>
                                        </tr>)
                                    }) : <tr><td colSpan={5} className="text-center">No Record</td></tr>}
                            </tbody>
                        </table>
                        : null
                }
                {this.state.fuelbalance_loading || this.state.fuelbalance ? <div className="tab_content_wrapper fw-bold mb-3">Fuel Opening Balance</div> : null}
                {this.state.fuelbalance_loading
                    ? <Loader />
                    : this.state.fuelbalance
                        ? <table className="my-3 table table-bordered">
                            <thead className="bg-light">
                                <tr>
                                    <th style={{ width: "20%" }} className="text-">Remaing Fuel</th>
                                    <th style={{ width: "20%" }} className="text-">Fuel Unit</th>
                                    <th>Remarks</th>
                                    <th style={{ width: "15%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.fuelbalance
                                    ? <tr>
                                        <td className='fw-bold'>{this.state.fuelbalance.closing_qty}</td>
                                        <td>{this.state.fuelbalance.fuel_unit}</td>
                                        <td>{this.state.fuelbalance ? this.state.fuelbalance.remarks : "-"}</td>
                                        <td className="text-center">
                                            <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end cursor_pointer">


                                                <li className='dropdown-item' onClick={() => this.fuelBalanceModalInit()}>Update</li>
                                                <li>
                                                    <a role="button"
                                                        onClick={() => {
                                                            if (this.props.permissions.includes('iam-counterlog-fuel-balance-delete')) {
                                                                return this.fuelBalanceDeleteInit()
                                                            }
                                                        }}
                                                        className={['dropdown-item', this.props.permissions.includes('iam-counterlog-fuel-balance-delete') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props.permissions.includes('iam-counterlog-fuel-balance-delete') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Delete</a>
                                                </li>

                                            </ul>
                                        </td>
                                    </tr>
                                    : <tr><td colSpan="4" className="text-center">No Fuel balance</td></tr>
                                }
                            </tbody>
                        </table>
                        : null
                }

                {this.state.document_loading || this.state.document && this.state.document.length > 0 ? <div className="tab_content_wrapper fw-bold my-3">Shift Document</div> : null}
                {this.state.document_loading
                    ? <Loader />
                    : this.state.document && this.state.document.length > 0
                        ? <table className="my-3 table table-bordered table-hover">
                            <thead className="bg-light">
                                <tr>
                                    <th style={{ width: "40%" }}>Document Name (Number)</th>
                                    <th style={{ width: "15%" }} className="text-center">Location</th>
                                    <th style={{ width: "15%" }} className="text-center">View</th>
                                    <th style={{ width: "15%" }} className="text-center">Download</th>
                                    <th style={{ width: "15%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.document && this.state.document.length > 0
                                    ? this.state.document.map((ff, k) => {
                                        return (<tr>
                                            <td className='text-capitalize'>
                                                {ff.name??'-'} {ff.number && <span>({ff.number})</span>}
                                            </td>
                                            <td className="text-center">{ff.longitude && ff.latitude ? <a target="_blank" href={`https://www.google.com/maps/search/${ff.latitude},${ff.longitude}`}>View Location</a> : "-"}</td>
                                            <td className="text-center">{ff.view_permission == true ? <a target="_blank" href={ff.document}><tapIcon.FontAwesomeIcon icon={tapIcon.faEye} /></a> : "-"}</td>
                                            <td className="text-center">{ff.downlaod_permission == true ? <a target="_blank" href={ff.download_link}><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /></a> : "-"}</td>
                                            <td className="text-center"> <tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt} color={"blue"} onClick={() => this.deleteDocument(ff.doc_id)} /> </td> </tr>)
                                    })
                                    : <tr><td colSpan={5} className="text-center">No Record</td></tr>
                                }
                            </tbody>
                        </table>
                        : null
                }
                {this.state.engagement_loading || this.state.engagement && this.state.engagement.length > 0 ? <div className="tab_content_wrapper fw-bold mb-3">Engagement</div> : null}
                {this.state.engagement_loading
                    ? <Loader />
                    : this.state.engagement && this.state.engagement && this.state.engagement.length > 0
                        ? <Ax>
                            <table className=" my-3 table table-bordered table-hover ">
                                <thead className="bg-light">
                                    <tr>
                                        <th style={{ width: "15%" }}>Transaction ID</th>
                                        <th style={{ width: "15%" }}>Start Time</th>
                                        <th style={{ width: "10%" }}>End Time</th>
                                        <th style={{ width: "20%" }}>Duration</th>
                                        <th style={{ width: "10%" }} className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.engagement && this.state.engagement && this.state.engagement.length > 0
                                        ? this.state.engagement.map((dt, k) => {
                                            return (<tr key={k}>
                                                <td onClick={() => this.viewEngagementInit(dt.transaction_id)}
                                                    className={['link-primary cursor_pointer'].join(' ')}
                                                 >{dt.transaction_id ? dt.transaction_id : "-"}</td>
                                                <td>{dt.start_time ?  moment(dt.start_time, 'HH:mm:ss').format('hh:mm A') : "-"}</td>
                                                <td>{dt.end_time ? moment(dt.end_time, 'HH:mm:ss').format('hh:mm A')  : "0"}</td>
                                                <td>{dt.engagement_seconds ? this.SecondsToHHMM(dt.engagement_seconds) : "0"}</td>
                                                <td className="text-center">
                                                    <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                        <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                        <li className='dropdown-item' onClick={() => this.editEngagementTimeInit(dt.transaction_id)}>
                                                            Edit
                                                        </li>
                                                        <li className='dropdown-item' onClick={() => this.viewEngagementInit(dt.transaction_id)}>
                                                            View
                                                        </li>
                                                        <li className='dropdown-item' onClick={() => this.deleteEngagementTime(dt.transaction_id)}>
                                                            Delete
                                                        </li>
                                                        
                                                    </ul>
                                                </td>
                                            </tr>)
                                        })

                                        : <tr><td colSpan={5} className="text-center">No Record</td></tr>}
                                </tbody>

                            </table>
                        </Ax>
                        : null
                }
            </Ax>

        </div>
    }

    taskDetailModalJsx          =       ()      =>      {
        return (<div className="modal fade" id={'taskDetailModal'} tabIndex="-1">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">
                            <span>View Task  for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                            {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className='p-1 bg-light border-bottom'>
                        <table className='table table-sm mb-0 table-borderless text-sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: "25%" }}> Asset Name (Code)   </td>
                                    <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                </tr>
                                <tr>
                                    <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-body">
                       
                        <TaskDetail
                            taskTransactionId={this.state.taskTransactionId}
                            assetData={this.state.assetData}
                        />
                    </div>
                    <div className="modal-footer d-flex justify-content-between row">
                        <div className="col text-start">

                            <div className="col text-end">
                                <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" >Close</button>

                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>)}
    
    render                              =   () =>  {
        return <Ax>
            <Helmet><title>Shift Summary</title></Helmet>
            {this.summaryHeadingJsx()}
            <div className="row mx-0 bg-white mt-1">
                <div className="col-sm-3 border-end pageTbl" style={{maxHeight : this.state.displayAssetDetail ? "81vh" : "74vh"}}>{this.shiftReadingDetailJsx()}</div>
                <div className="col-sm-9 pageTbl" style={{maxHeight : this.state.displayAssetDetail ? "81vh" : "74vh" }}>{this.shiftSummaryJsx()}</div>
            </div>
            
            <FuelBalance ref={this.fuelOpeningBalanceRef} afterDeleteFuelBalance={() => {
                this.fetchShiftActivities({fuelbalance : "Y"});
                this.setState({isActivityPerform : true});
            }} afterSaveFuelBalance={() => {
                this.fetchShiftActivities({fuelbalance : "Y"});
                this.setState({isActivityPerform : true});
            }}  />
            <Document ref={this.uploadDocumentRef} afterDeleteDocument={() => {
                this.fetchShiftActivities({document : "Y"});
                this.setState({isActivityPerform : true});
            }} afterSaveDocument={() => {
                this.fetchShiftActivities({document : "Y"});
                this.setState({isActivityPerform : true});
            }} />
            <CounterlogModal ref={this.CounterLogModalRef} afterSubmitCounterlog={(date,asset_id,refreshAssetCard = true) => {
                this.fetchShiftDetail();
                this.setState({isActivityPerform : true, isCounterLogPerform : refreshAssetCard})
            }} afterDeleteCounterlog={() => {
                this.setState({isActivityPerform : true, isCounterLogPerform : true},() => this.closeSummaryDetails())
            }} />
            <Downtime ref={this.DowntimeModalRef} afterDeleteDowntime={() => {
                this.fetchShiftActivities({downtime : "Y",need_section : 'Y'});
                this.setState({isActivityPerform : true, isCounterLogPerform : false})
            }}  afterSaveDowntime={() => {
                this.fetchShiftActivities({downtime : "Y",need_section : 'Y'});
                this.setState({isActivityPerform : true});
            }} />
             <EngagementTime ref={this.engagementModalRef} afterDeleteEngagement={() => {
                this.fetchShiftActivities({engagement_time : "Y"});
                this.setState({isActivityPerform : true, isCounterLogPerform : false})
            }}  afterSaveEngagementTime={() => {
                this.fetchShiftActivities({engagement_time : "Y"});
                this.setState({isActivityPerform : true});
            }} />
            <ServiceChecksheet ref={this.serviceChecksheetModalRef} afterDeleteServiceChecksheet={() => {
                this.fetchShiftActivities({executed_scs : "Y"});
                this.setState({isActivityPerform : true, isCounterLogPerform : false})
            }} afterSaveServiceChecksheet={() => {
                this.fetchShiftActivities({executed_scs : "Y"});
                this.setState({isActivityPerform : true});
            }} />
             <Productivity ref={this.productivityModalRef} afterSaveProductivity={() => {
                this.fetchShiftActivities({productivity : "Y"});
                this.setState({isActivityPerform : true});
            }} afterDeleteProductivity={() => {
                this.fetchShiftActivities({productivity : "Y"});
                this.setState({isActivityPerform : true, isCounterLogPerform : false})
            }} />
            <Activity ref={this.activityModalRef} afterSaveActivity={() => {
                this.fetchShiftActivities({activity : "Y",productivity : 'Y'});
                this.setState({isActivityPerform : true});
            }} afterDeleteActivity={() => {
                this.fetchShiftActivities({activity : "Y",productivity : 'Y'});
                this.setState({isActivityPerform : true, isCounterLogPerform : false})
            }} />
            <Expense ref={this.logExpenseRef} afterSaveExpense={() => {
                this.fetchShiftActivities({expense:"Y"});
                this.setState({isActivityPerform : true});
            }} afterDeleteExpense={() => {
                this.fetchShiftActivities({expense : 'Y'});
                this.setState({isActivityPerform : true, isCounterLogPerform : false})
            }} />
            <ExpenseViewModal ref={this.viewExpenseRef}/>
            <FuelFeedIn ref={this.logFuelFeedInRef} afterSaveFuelFeedIn={() => {
                this.fetchShiftActivities({expense:"Y",fuelfeed_in : "Y"});
                this.setState({isActivityPerform : true});
            }} afterDeleteFuelFeedIn={() => {
                this.fetchShiftActivities({expense : 'Y',fuelfeed_in : "Y"});
                this.setState({isActivityPerform : true, isCounterLogPerform : false})
            }} />
             <TaskSaveModal ref={this.taskWorkflowRef} afterSaveTask={() => {
                this.fetchShiftActivities({activity_task : 'Y',productivity : "Y"});
                this.setState({isActivityPerform : true});
            }}  />
            {/* {this.taskDetailModalJsx()} */}
        </Ax> 
    }

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        group_permission        :   state.app.acl_info,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(ShiftSummaryDetail);