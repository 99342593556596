import React from 'react';
import { connect } from 'react-redux';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from '../../../components/hoc/Ax';
import tapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import AppBaseComponent from '../../../components/AppBaseComponent';
import uuid from 'react-uuid';

class ManualEntryModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.addressFormInitialState    =   { 
            address1                        :   '',
            address2                        :   '',
            landmark                        :   '',
            city                            :   '',
            country_id                      :   99,
            state_id                        :   null,
            district_name                   :   null,
            pincode                         :   null,
            gstin                           :   '',
        }

        this.state                      =   {
            addressFormSubmitting           :   false,
            loading_form                    :   false,
            addNewDistrict                  :   false,
            addNewPinCode                   :   false,
            addAddressForm                  :   {...this.addressFormInitialState},
            allStateList                    :   [],
            allCountryList                  :   [],
            allDistrictList                 :   [],
            allPincodeList                  :   [],

            modalType                       :   '',        
        };
       
        this.modalId                =    uuid();   
        this.stateListUrl           =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl        =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl            =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.pincodeListUrl         =   ORG_API_BASE_URL_2  +   '/pincode_list';
       
    }
    
  
    componentDidMount(){
        this.initilaizeFormData()
        this.addModal                  =   new Modal(document.getElementById(this.modalId), {keyboard: false, backdrop :false});
    }

    showModalInit           =   (type)  =>  {  
        this.setState({
            modalType : type
        }, () => {
            this.addModal.show();
        });
    }

    initilaizeFormData      =       ()      =>      {
        this.setState({loading_form: true})

        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/vendor' + '/add_form_data', this.props.access_token, null, null, (response) => {
            let respData = response.data
            this.setState({
                allCountryList            :       respData && respData.country ?  respData.country.map((s) => { return {value: s.id, label: s.name}; }) : [],             

            },() => {
                if(this.state.addAddressForm && this.state.addAddressForm.country_id){
                    this.countryChangeHandler(this.state.addAddressForm.country_id)
                }
            });
        }).then(() => this.setState({ loading_form: false }));
    }

    countryChangeHandler              =        (selected_country)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    allOperationsStateList      :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    addAddressForm               :    {...this.state.addAddressForm,state_id: '',district_name : '',pincode:''},
                    addNewDistrict              :   false ,
                    addNewPinCode               :   false 
                });
            });
        }
        
    }

    //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
    stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList             :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    addAddressForm              :    {...this.state.addAddressForm,pincode:''}
                });
            });
        }
    }

      //FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT
      districtChangeHandler              =        (selected_district)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            let params = {district_name : selectedDistrict,state_id:this.state.addAddressForm.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList              :   response.data.map((s) => { return {value: s.pincode, label: s.pincode}; }),
                    addNewPinCode               :   response.data && response.data.length > 0 ? false : true,
                });
            });
        }
    }


    //submit add Origin Form
    addAddressFormSubmit            =       (e)      =>  {
       e.preventDefault();
       this.setState({addressFormSubmitting : true});
       const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        let errors                  =   [];
        if(this.state.addAddressForm.gstin && this.state.addAddressForm.gstin.trim() != '' && !pattern.test(this.state.addAddressForm.gstin.trim())) {
            this.setState({addressFormSubmitting : false});
            errors                  =   [...errors, 'Please enter valid GST No.'];
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            this.setState({addressFormSubmitting : true});
            toast.error(errorMsg, {position: toast.POSITION.TOP_RIGHT});
        } else { 
            const address  =   this.state.addAddressForm;
            this.setState({
                addNewDistrict  : false,
                addNewPinCode   : false,
                addAddressForm  : this.addressFormInitialState,
            }, () => {
                this.setState({
                    addressFormSubmitting: false
                })
                this.addModal.hide();
            });
            return this.props.manualAddress(address, this.state.modalType);
        }
    }
   

    //*********************************ITEM ADD JSX*********************** */
    modalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id={this.modalId} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addModalLabel">Add {this.state.modalType}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleCancel}></button>
                        </div>
                        <div className="modal-body ">
                            {
                            this.state.loading_form 
                            ? 
                            <Loader/>
                            : 
                            <form onSubmit={this.addAddressFormSubmit} id="addAddressForm" method="post" encType="multipart/form-data">
                                <Ax>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Address Line 1</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                name="address1"
                                                type="text"
                                                className="form-control"
                                                value={this.state.addAddressForm.address1}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                placeholder="Please Enter Address Line 1"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Address Line 2</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                name="address2"
                                                type="text"
                                                className="form-control"
                                                value={this.state.addAddressForm.address2}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                placeholder="Please Enter Address Line 2"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Landmark</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                name="landmark"
                                                type="text"
                                                className="form-control"
                                                value={this.state.addAddressForm.landmark}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                placeholder="Please Enter Landmark"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">City</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                name="city"
                                                type="text"
                                                className="form-control"
                                                value={this.state.addAddressForm.city}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                placeholder="Please Enter City"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Country</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addAddressForm', 'country_id');
                                                    this.countryChangeHandler(selectedOption)
                                                }
                                                }
                                                options={this.state.allCountryList}
                                                isSearchable={true}
                                                isClearable={false}
                                                value={this.state.allCountryList.find(m => m.value == this.state.addAddressForm.country_id)}
                                                placeholder="Please Select Country"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label require">State (Source of Supply)</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                                options={this.state.allStateList}
                                                isSearchable={true}
                                                isClearable={true}
                                                required={true}
                                                value={this.state.allStateList.find(m => m.value == this.state.addAddressForm.state_id)}
                                                placeholder="Please Select State"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">District</label>
                                        </div>
                                        <div className="col-sm-6">
                                            {this.state.addNewDistrict
                                                ? <input
                                                    name="district_name"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.addAddressForm.district_name}
                                                    onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                    maxLength="20"
                                                    placeholder="Please Enter District"
                                                />
                                                : <TapSelect
                                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                                    options={this.state.allDistrictList}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.allDistrictList.find(m => m.value == this.state.addAddressForm.district_name)}
                                                    placeholder="Please Select District"
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="addNewDistrict"
                                                type="checkbox"
                                                value={this.state.addNewDistrict}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewDistrict: !this.state.addNewDistrict,
                                                        addAddressForm: { ...this.state.addAddressForm, district_id: '', pincode: '' }
                                                    })
                                                }}
                                                checked={this.state.addNewDistrict}
                                                className="form-check-input"
                                                id="newDistrict"
                                            />
                                            <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">PIN Code</label>
                                        </div>
                                        <div className="col-sm-6">
                                            {
                                                this.state.addNewPinCode
                                                ? <input
                                                    name="pincode"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.addAddressForm.pincode}
                                                    onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                    maxLength="20"
                                                    placeholder="Please Enter PinCode"
                                                />
                                                : 
                                                <TapSelect
                                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'pincode') }}
                                                    options={this.state.allPincodeList}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.allPincodeList.find(m => m.value == this.state.addAddressForm.pincode)}
                                                    placeholder="Please Select PIN Code"
                                                />
                                            }
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="addNewPinCode"
                                                type="checkbox"
                                                value={this.state.addNewPinCode}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewPinCode: !this.state.addNewPinCode,
                                                        addAddressForm: { ...this.state.addAddressForm, pincode: '' }
                                                    })
                                                }}
                                                checked={this.state.addNewPinCode}
                                                className="form-check-input"
                                                id="newPinCode"
            
                                            />
                                            <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">GSTIN</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                name="gstin"
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addAddressForm.gstin}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                maxLength="20"
                                                placeholder="Please Enter GSTIN Number"
                                            />
                                        </div>
                                    </div>
                                </Ax>
                            </form>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.addressFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal"
                            onClick={this.handleCancel}>Cancel</button>
                            <button type="submit" disabled={this.state.addressFormSubmitting} className="btn btn-primary" form="addAddressForm">
                                Submit {this.state.addressFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    handleCancel        =   ()  =>  {
        this.setState({
            addNewDistrict  : false,
            addNewPinCode   : false,
            addAddressForm  : this.addressFormInitialState,
        }, () => {
            this.props.modalCancel(this.state.modalType);
        })
    }

    render() {
        return (<Ax>{this.modalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(ManualEntryModal);