import { ASSET_PRODUCTIVITY_CONFIGURATION_FAILURE, ASSET_PRODUCTIVITY_CONFIGURATION_START, ASSET_PRODUCTIVITY_CONFIGURATION_SUCCESS, ASSET_PROD_CONFIG_FORMDATA_START, ASSET_PROD_CONFIG_FORMDATA_SUCCESS, ASSET_PROD_CONFIG_FORMDATA_FAILURE } from "../../actions/actionTypes";


const AssetProdConfigFormDataReducer = (initialState = { loading: false, data: null, error: null }, action) => {
    switch (action.type) {
        case ASSET_PROD_CONFIG_FORMDATA_START:
            return { ...initialState, loading: true };
        case ASSET_PROD_CONFIG_FORMDATA_SUCCESS:
            return {
                ...initialState,
                loading: false,
                data: action.payload
            }
        case ASSET_PROD_CONFIG_FORMDATA_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: action.payload
            };
        default:
            return initialState;
    }
}


const AssetProductivityConfigurationReducer = (initialState = { loading: false, data: null, error: null }, action) => {
    switch (action.type) {
        case ASSET_PRODUCTIVITY_CONFIGURATION_START:
            return { ...initialState, loading: true };
        case ASSET_PRODUCTIVITY_CONFIGURATION_SUCCESS:
            return {
                ...initialState,
                loading: false,
                data: action.payload
            }
        case ASSET_PRODUCTIVITY_CONFIGURATION_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: action.payload
            };
        default:
            return initialState;
    }
}


export {
    AssetProdConfigFormDataReducer,
    AssetProductivityConfigurationReducer,
};
