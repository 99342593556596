import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import moment from "moment";
import ScsExecutionHistoryView from '../../transactions/scsExecutionHistory/ScsExecutionHistoryView';
import ChangeAssetStatus from '../includes/ChangeAssetStatus';
import AssetHeader from '../includes/AssetHeader';
import InactivateAsset from '../includes/InactivateAsset';
import { Helmet } from 'react-helmet';

class ExecutionHistory extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.executeSearchInitState         =   {
            'search_site_ids'               :   [],
            'search_scs_name'               :   '',
            'search_transaction_id'         :   null,
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'scs_transaction_delay'          :   ''
        };

        this.executeSearchFormLabel         =   {
            'search_site_ids'                :   'Site : ',
            'search_scs_name'               :   'SCS Name : ',
            'search_transaction_id'         :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'scs_transaction_delay'          :   'Transaction Delay : '
        };
        this.state                          =       {
            access_token                    :       '',
            assetDetail                     :       null,
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            listingData                     :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            asset_loading                   :       false,
            assetId                         :       null,
            view_loading                    :       false,
            minimizeTable               :          false,
            scsHistoryListing           :          [],
            page                        :          1,
            scsDetails                  :          [],
            card_loading                :          false,
            executionId                 :          null,
            executionData               :          null,
            executeSearchForm           :         {...this.executeSearchInitState},
            formSearchedElems           :         [],
            searchedAsset               :        [],
            executeSearchParams           :        null,
            iam_asset_types             :        [],
            iam_group_sites              :        [],
            all_periods                 :        [],
            allTransactionDelay         :       []
        }
    
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.executionSearchModal         =    new Modal(document.getElementById('executionSearchModal'), {keyboard: false, backdrop :false});

    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (props)        =>      {
       
        let all_periods             =       [...props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/scs/execution_form_data', this.props.access_token, {})
            .then((response) => {
                this.setState({
                assetId                 :        props?.match?.params?.id,
                iam_asset_types         :    props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                iam_group_sites         :    props.iam_group_sites ? props.iam_group_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                all_periods             :    all_periods,
                allTransactionDelay     :    response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : []
             },() => {
                    let ParamObject                 =       new URLSearchParams(this.props.location.search);
                    let updateFilterForm = {}
                    if(ParamObject.get('search_scs_name')){
                        updateFilterForm['search_scs_name']        =   ParamObject.get('search_scs_name')
                    }

                    setTimeout(() => {
                        this.setState({
                            executeSearchForm: { ...this.state.executeSearchForm, ...updateFilterForm }
                        },
                            function () {
                                this.getAsssetDetails(props.match?.params?.id)
                               if(ParamObject.get('search_scs_name')){
                                this.executeSearchFormSubmit()
                               }else{
                                 this.loadListingTblData(1,props.match?.params?.id)
                               }

                            });
                    }, 1000)
             });
        })
       
    }

  
    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    //************************GET executionHistory LISTING**************** */
    loadListingTblData                 =   (page = 1,id)  =>         {
        this.setState({listing_loading: true})
        let params = {page: page,search_asset_ids : [id],...this.state.executeSearchParams,get_asset_profile:"Y"}
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/scs/execution_history' , this.props.access_token, {...params}, {}, (response) => {
           let respData = response.data;
            this.setState({
                scsHistoryListing   :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
            })
        }).then(() => this.setState({listing_loading: false }));
    }

  
    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }

    executeSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.executeSearchForm);
        let searchedElems               =   [];
        let executeSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.executeSearchFormLabel[key];
            let value                       =   this.state.executeSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                executeSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_scs_name') {
                        show_val            =   this.state.executeSearchForm && this.state.executeSearchForm.search_scs_name ? this.state.executeSearchForm.search_scs_name : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.executeSearchForm && this.state.executeSearchForm.search_transaction_id ? this.state.executeSearchForm.search_transaction_id : '';
                    }
                    if(key == 'scs_transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                       let  start_range = moment(this.state.executeSearchForm.date_range_start).format('DD-MMM-YYYY');
                       let end_range = moment(this.state.executeSearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range = moment(this.state.executeSearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.executeSearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems : searchedElems,
           executeSearchParams : executeSearchParams
        }, () => {
            this.loadListingTblData(1,this.state.assetId);
            this.executionSearchModal.hide();
        });
    }

    executionSearchModalInit        =       ()      =>      {
        this.executionSearchModal.show()
    }

    executeSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems   :   [],
            executeSearchParams   :   null,
            searchedAsset       :   null,
            executeSearchForm     :   {...this.executeSearchInitState}
        }, () => {
            this.loadListingTblData(1,this.state.assetId);
            this.executionSearchModal.hide();
            this.props.history.push(`/assets/execution_history/${this.state.assetId}`);
        });
    }

     //***********************DOWNLOAD EXCEL **************************** */
     executionDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.executeSearchParams};
         if(this.state.executeSearchParams &&  JSON.stringify(this.state.executeSearchParams) !== JSON.stringify({}) ){
             this.executionDownloadStart(params);
         } else {
             swal({ title: "Download",
             text: "The download will be for Trailing 12 months Transaction, in case you want different Transaction Period then please change by filter.",
             icon: "warning",
             buttons: ["Cancel", "Okay"],
             }).then(willDownload => {
                 if (willDownload) {
                     params = {...params , date_range: "trailing_twelve_months",search_asset_ids : [this.state.assetId]}
                      this.executionDownloadStart(params);
                 }
             });
         }
     }
     
     executionDownloadStart                   =   (params)  =>  {
         this.setState({reportDownloading : true});
         HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/scs/execution_history', this.props.access_token, {...params, download : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
     }

    deleteLinkJsx                       =   (transaction_id)  =>  {
        return <a role="button" 
            onClick={() => { if(this.props.permissions.includes('iam-asset-scs-execute-delete'))  { 
                    return this.deleteScsExecution(transaction_id) 
                } }} 
            className={['dropdown-item', this.props.permissions.includes('iam-asset-scs-execute-delete') ? '' : 'disabled'].join(' ')} 
            title={!this.props.permissions.includes('iam-asset-scs-execute-delete') ? 'You do not have permission to perform this action' : ''} 
            style={{"pointerEvents" : "all"}}
        >Delete</a>;
    }

    deleteScsExecution = (id) => {
        swal({
            title: "Delete",
            text: "Are you sure want to delete this execution of this service checksheet permanently?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_IAM_API_SERVER + '/scs/execution_delete/' + id, this.props.access_token,{},{},(resp) => {
                        toast.success(resp.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.loadListingTblData(1,this.state.assetId)
                        this.closeMinimizeTable()
                    });
                }
            });
    }

    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}/>
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {    
        return (<div>
            <div id="assetCard" className="collapse pr-1 mt-1" aria-expanded="true" >
                {this.state.asset_loading ? <div className='text-center pt-1'><Loader /></div>
                    : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail}/>}
            </div>
        </div>)
    }

    viewHistory         =       (id)        =>      {
        this.setState({ minimizeTable: true, view_loading: true })
        this.getExecutionHistoryData(id)
       
    }

    getExecutionHistoryData           =      (id)        =>        {
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/scs/execution_detail/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                executionData    :  respData.data,
            });
        }).then(() => this.setState({view_loading: false}));
    }

   executionHistoryListJsx            =       ()      =>    {

       return (<Ax>
           <div style={{ paddingTop: "4px" }}>
               <div className="page_title row m0" style={{ height: "45px" }}>
                   <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                       <h3 style={{ paddingTop: "13px" }}>SCS Execution History </h3>
                   </div>
                   {!this.state.minimizeTable
                       ? <div className='col-sm-2 '>
                           <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                       </div> : null}

                   {!this.state.minimizeTable
                       ? <div className='col-sm-4 text-end mt7 '>
                           <button type="button" onClick={this.executionSearchModalInit} className="btn btn-secondary">
                               <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                           </button>
                           <button type="button" className="btn btn-secondary"
                               disabled={this.state.reportDownloading || this.state.listing_loading}
                               onClick={this.executionDownloadHandler} >
                               <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                               {this.state.reportDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                           </button>
                       </div> : null}
                   {
                       this.state.minimizeTable
                           ? (
                               <Ax>
                                   <div className="col-sm-3 tap_border_left">
                                       <h3 style={{ paddingTop: "13px" }}>SCS : {this.state.executionData ? this.state.executionData.transaction_id : "-"}</h3>
                                   </div>
                                   <div className='col-sm-2 '>
                                       <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                   </div>
                                   <div className='col-sm-4 text-end mt7'>
                                       <span className="dropdown">
                                           <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                               <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                           </button>
                                           <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                               <li>{this.deleteLinkJsx(this.state.executionData?.id)}</li>
                                           </ul>
                                       </span>
                                       <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                           <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                       </button>
                                   </div>
                               </Ax>
                           )
                           : null
                   }
               </div>
           </div>
           <div className="container-fluid pl0 pr13">
               <div className="page_containt row">
                   <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.executionHistoryTableJsx()}</div>
                   {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0"><div className="bg-white p-2" style={{ maxHeight: "69vh", overflowY: "scroll" }}>{this.state.view_loading ? <Loader /> : this.executionHistoryViewDetailJsx()}</div> </div> : null}
               </div>
           </div>
       </Ax>);
    }

    executionHistoryTableJsx                   =   ()  =>  {
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.executeSearchClear} />
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>
                    <table className="table table-bordered bg-white table-fixed table-hover " id="assetExecutionHistoryTable">
                        <thead className="table-secondary">
                            {this.state.minimizeTable
                                ? (<tr>
                                    <th scope="col" style={{ width: "20%" }}>Transaction</th>
                                </tr>)
                                : (<tr>
                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                    <th scope="col" style={{ width: "12%" }}>Transaction Date</th>
                                    <th scope="col" style={{ width: "12%" }}>Transaction Id</th>
                                    <th scope="col" style={{ width: "23%" }}>CheckSheet Name</th>
                                    <th scope="col" style={{ width: "13%" }} className="text-center">Executed Site</th>
                                    <th scope="col" style={{ width: "14%" }}>Parameter</th>
                                    <th scope="col" style={{ width: "16%" }}>CounterLog</th>
                                    <th scope="col" style={{ width: "5%" }}>Action</th>
                                </tr>)
                            }
                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                                : (this.state.scsHistoryListing.length > 0
                                    ? (this.state.scsHistoryListing.map((scs, index) => {

                                        return <tr>
                                            {
                                                this.state.minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td><a role="button" className="link-primary" onClick={() => this.viewHistory(scs.id)} >
                                                                {scs.transaction_id}</a>
                                                                <br /><small>{scs.scs_setting?.alias_name ? scs.scs_setting?.alias_name : scs.scs_setting?.name}</small>
                                                            </td>

                                                        </Ax>
                                                    )
                                                    : (<Ax>
                                                        <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                        <td className='text-center'>{scs.feed_date_display ? scs.feed_date_display : "-"}</td>
                                                        <td><a role="button" className="link-primary" onClick={() => this.viewHistory(scs.id)} >{scs.transaction_id}</a></td>
                                                        <td >{scs.scs_setting?.alias_name ? scs.scs_setting?.alias_name : scs.scs_setting?.name}</td>
                                                        <td>{scs.site ? scs.site.site_name : "-"}</td>
                                                        <td >{scs.parameter && scs.parameter.execute_display && scs.parameter.execute_display
                                                            ? <div>
                                                                <Status color={scs.parameter.execute_display.color}>{scs.parameter.type == "range" ? scs.parameter.execute_level : scs.parameter.execute_display.text}</Status>
                                                                {scs.parameter.type == "range" ? <div className='my-1'>{scs.parameter.type_display}</div> : null}
                                                            </div>
                                                            : 'NA'}</td>
                                                        <td className="text-start"> {scs.executedCounterlog && scs.executedCounterlog.length > 0 ? scs.executedCounterlog.map((item, index) => { return (<div className="mt-1"> {item.label} : {item.reading}  {item.unit} <br /></div>) })
                                                            : "-"}</td>
                                                        <td >
                                                        <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" role="button" onClick={() => this.viewHistory(scs.id)}>View Execution</a></li>
                                                                <li>{this.deleteLinkJsx(scs?.id)}</li>
                                                            </ul>
                                                        </td>

                                                    </Ax>
                                                    )}

                                        </tr>
                                    }))
                                    : (<tr><td colSpan="8" className="text-center">No Records</td></tr>))
                            }
                        </tbody>
                    </table>
                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1, this.state.assetId)}
                    />
                </div>
            </Ax>
        );
    }


    executionHistoryViewDetailJsx                =   ()   =>   {
        return (<div>
          {this.state.view_loading ? <div className='text-center'><Loader/></div>
          :  this.state.executionData ?  <ScsExecutionHistoryView executionData={this.state.executionData} /> : null}
        </div>)
    }

    executionSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="executionSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="executionSearchModalLabel">SCS Execution History Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.executeSearchFormSubmit} id="executionSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            value={this.state.executeSearchForm.search_transaction_id}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'executeSearchForm')}
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">SCS Name</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_scs_name"
                                            value={this.state.executeSearchForm.search_scs_name}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'executeSearchForm')}
                                            placeholder="SCS Name"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Execution Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'executeSearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.executeSearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Execution Site Name"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'executeSearchForm', 'scs_transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.executeSearchForm.scs_transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.executeSearchForm.entry_date_range}
                                            startDate={this.state.executeSearchForm.entry_date_range_start}
                                            endDate={this.state.executeSearchForm.entry_date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    executeSearchForm
                                                        : {
                                                        ...this.state.executeSearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"

                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.executeSearchForm.date_range}
                                            startDate={this.state.executeSearchForm.date_range_start}
                                            endDate={this.state.executeSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    executeSearchForm
                                                        : {
                                                        ...this.state.executeSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        
                                        />
                                      
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset - SCS Execution History</title></Helmet>
            {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.executionHistoryListJsx()}
            {this.executionSearchModalJsx()}
            <ChangeAssetStatus ref={this.ChangeAssetStatusModalRef} afterChangeStatus={this.getAsssetDetails} />
            <InactivateAsset ref={this.InactivateAssetModalRef} afterChangeStatus={this.getAsssetDetails} />
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app.acl_info.permissions,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ExecutionHistory);