import React from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';
import TapSelect from '../../../components/ui/TapSelect';
import TapIcon from '../../../services/TapIcon';
import AssetService from '../../../services/AssetService';
import { min } from 'moment/moment';

class TaskDetail extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state                      =       {
            taskData                    :       null,
            view_loading                :       false,
            previousStepDetails         :       [],
            nextStepFields              :       [],
            nextStepDetail              :       null,
            max_step_level              :       0,
            assetData                   :       null
        };

        this.task_detail_url            =   IAM_API_BASE_URL_2 + '/counterlog/activity_task/detail';
        
    }

    componentDidMount() {
        if(this.props && this.props.taskTransactionId){
            this.getTaskDetail(this.props.taskTransactionId);
           
        }
        if(this.props && this.props.assetData){
            this.setState({assetData : this.props.assetData})
        }
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.taskTransactionId !== this.props.taskTransactionId){
            this.getTaskDetail(nextProps.taskTransactionId)
           
         }
         if(nextProps.assetData !== this.props.assetData){
             this.setState({assetData : nextProps.assetData})
         }
         
     }
    

     getTaskDetail       =   (id)        =>      {

        alert('vvvvvvvvv');

        this.setState({ view_loading: true })
        let params = {transaction_id : id}
        HttpAPICall.withAthorization('GET', this.task_detail_url, this.props.access_token, { ...params }, {}, (response) => {
           
            let respData             =       response.data;
           // let nextStepFields       =       respData && respData.steps && respData.steps.length > 0 && respData.next_step ? respData.steps.filter(st => st.id == respData.next_step.id) : [];
            var max_step_level       =       respData && respData.steps && respData.steps.length > 0 ?   Math.max.apply(Math, respData.steps.map(st => st.level)) : 0;
             
            this.setState({
                 taskData                :       respData && respData.taskData ? respData.taskData  :   null,
                 previousStepDetails     :       respData && respData.steps && respData.steps.length > 0 && respData.next_step ?  respData.steps.filter(st => respData.next_step && st.id !== respData.next_step.id && st.level && st.level <= respData?.next_step?.level) : respData && respData.steps && respData.steps.length > 0 && !respData.next_step ? respData.steps : [],
                 nextStepFields          :      [],
                 nextStepDetail          :       respData && respData.next_step ? respData.next_step : null,
                 max_step_level          :       max_step_level,  
             })
            
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({view_loading: false}));
    }

    deleteTaskInit                  =       (transaction_id ,step_id)       =>      {
        
    }

    startingReadingsJsx             =   (step, taskDetail, asset)  =>  {
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {detail}                    =   {...step}; 
        return <Ax>
            {assetMeters.map((meter, k) => {
                let input_state_key     =   AssetService.getTaskStartingReadingInputKey(meter.key);
                return <Ax>
                    <tr key={k}>
                        <td style={{ width: "33%" }}>{meter.label}</td>
                        <th>{detail && detail[input_state_key] ? detail[input_state_key] : ''}</th>
                    </tr>
                    <tr key={k}>
                        <td style={{ width: "33%" }}>Date & Time</td>
                        <th>{detail  ? detail.start_cl_date_time_display : ''}</th>
                    </tr>
                </Ax>
                
            })}
        
        </Ax>
    }

    startingTimeJsx         =       (step, taskDetail)      =>      {
        let {detail}                    =   {...step}; 
        return (<tr>
            <td>Task Start Time </td>
            <th>{taskDetail && taskDetail.task_start_at_display ? taskDetail.task_start_at_display : "-"}</th>

        </tr>)
    }

    operatorJsx                     =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <tr>
            <td>Operator</td>
            <th>{detail ? detail.operators.map(o => o.display_full_name).join(', ') : ''}</th>
        </tr>
    }

    attachmentJsx                   =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <tr>
            <td>Attachment</td>
            <th>{detail && detail.attachment_name ? detail.attachment_name : ''}</th>
        </tr>
    }

    materialJsx                     =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <tr>
            <td>Material</td>
            <th>{detail && detail.material_name ? detail.material_name : ''}</th>
        </tr>
    }

    productivityJsx                 =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Net Productivity</td>
                <th>{detail && detail.production ? detail.production : ''}</th>
            </tr>
            <tr>
                <td>Productivity Units</td>
                <th>{detail && detail.production ? detail.production_unit : ''}</th>
            </tr>
            {detail.production_all_aa && detail.production_all_aa.length > 0 && detail.production_all_aa.map((attribute, pk) => <Ax key={pk}>
                <tr>
                    <td>{attribute.name}</td>
                    <th>{detail && detail.production_additional_attributes && detail.production_additional_attributes[attribute.key] ? detail.production_additional_attributes[attribute.key] : "-"}</th>
                </tr>

            </Ax>)}
        </Ax>
    }

    workstationFromJsx              =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Workstation From</td>
                <th>{detail && detail.workStationFrom ? detail.workStationFrom.name : ''}</th>
            </tr>
          
        </Ax>
    }

    workstationToJsx                =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Workstation To</td>
                <th>{detail && detail.workStationTo ? detail.workStationTo.name : ''}</th>
            </tr>
        </Ax>
    }

    workstationJsx                  =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Workstation</td>
                <th>{detail && detail.workStation ? detail.workStation.name : ''}</th>
            </tr>
        </Ax>
    }

    unloadedWeightJsx               =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Unloaded Weight</td>
                <th>{detail && detail.unloaded_weight ? detail.unloaded_weight : ''} {detail && detail.unloaded_weight_unit ? detail.unloaded_weight_unit : ''}</th>
            </tr>
         
        </Ax> 
    }

    loadedWeightJsx                 =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Loaded Weight</td>
                <th>{detail && detail.loaded_weight ? detail.loaded_weight : ''} {detail && detail.loaded_weight_unit ? detail.loaded_weight_unit : ''}</th>
            </tr>
            
        </Ax> 
    }

    productivityRemarkJsx           =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Productivity Remark</td>
                <th>{detail && detail.productivity_remark ? detail.productivity_remark : ''}</th>
            </tr>
        </Ax> 
    }

    counterlogReadingsJsx           =   (step, taskDetail, asset)   =>  {
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {detail}                    =   {...step}; 
        return <Ax>
            {assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskCounterLogReadingInputKey(meter.key);
                return <tr>
                    <td>{meter.label}</td>
                    <th>{detail && detail[input_state_key] ? detail[input_state_key] : ''}</th>
                </tr>
            })}
            <tr>
                <td>CounterLog Date & Time</td>
                <th>{detail ? detail.cl_date_time_display : ''}</th>
            </tr>
        </Ax>
    }
    closingReadingsJsx              =   (step, taskDetail, asset) => {
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {detail}                    =   {...step}; 
        return <Ax>
            {assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskClosingReadingInputKey(meter.key);
                return <tr>
                    <td>{meter.label}</td>
                    <th>{detail && detail[input_state_key] ? detail[input_state_key] : ''}</th>
                </tr>

            })}
            <tr>
                <td>Closing CounterLog Date & Time</td>
                <th>{detail ? detail.end_cl_date_time_display : ''}</th>
            </tr>
        </Ax>
    }

    closingRemarkJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Closing Remark</td>
                <th>{detail && detail.closing_remarks ? detail.closing_remarks : ''}</th>
            </tr>
        </Ax>
    }

    activityCountJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Activity Type</td>
                <th>{detail && detail.activity_type_display ? detail.activity_type_display : ''}</th>
            </tr>
            <tr>
                <td>No. of Session or Trip</td>
                <th>{detail && detail.activity_count ? detail.activity_count : ''}</th>
            </tr>
        </Ax>
    }

    taskCompleteTimeJsx             =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step}; 
       
        return <Ax>
             <tr>
                <td>Task Complete Date & Time</td>
                <th>{taskDetail && taskDetail.task_close_at_date_display  ? taskDetail.task_close_at_date_display + ' ' + taskDetail.task_close_at_time_display : ''}</th>
            </tr>
        </Ax>
    }

    momentHumanize(eventDuration, unit) {
        var eventMDuration = moment.duration(eventDuration, unit);
        var eventDurationArray = [];
       
        if (eventMDuration.days() > 0) {
            eventDurationArray.push(eventMDuration.days() + ' Days');
            eventMDuration.subtract(eventMDuration.days(), 'Days')
        }
        if (eventMDuration.hours() > 0) {
            eventDurationArray.push(eventMDuration.hours() + ' Hours');
            eventMDuration.subtract(eventMDuration.hours(), 'Hours')
        }
        if (eventMDuration.minutes() > 0) {
            eventDurationArray.push(eventMDuration.minutes() + ' Min');
        }
        return eventDurationArray.length === 1 ? eventDurationArray[0] : 
        eventDurationArray.join(' ')
    }

    analysisJsx                 =       ()          =>      {
        let taskDetail = this.state.taskData;
        let startingReadingDetail   =   this.state.previousStepDetails && this.state.previousStepDetails.find(ps => ps.fields.find(fs => fs.field_key == 'starting_readings'));
        let closingReadingDetail    =   this.state.previousStepDetails && this.state.previousStepDetails.find(ps => ps.fields.find(fs => fs.field_key == 'closing_readings'));
        let unloadedStep  =   this.state.previousStepDetails && this.state.previousStepDetails.find(ps => ps.fields.find(fs => fs.field_key == 'unloaded_weight'));
        let loadedStep    =   this.state.previousStepDetails && this.state.previousStepDetails.find(ps => ps.fields.find(fs => fs.field_key == 'loaded_weight'));

        let unloadedDetail  =   unloadedStep && unloadedStep.detail ? unloadedStep.detail : null;
        let loadedDetail    =   loadedStep && loadedStep.detail ? loadedStep.detail : null;
        
        var task_start_at = taskDetail && taskDetail.task_start_at ? moment(taskDetail.task_start_at) : "";//now
        var task_close_at = taskDetail && taskDetail.task_close_at ? moment(taskDetail.task_close_at) : ""
        let daydiffenence           =   task_start_at && task_close_at ? task_close_at.diff(task_start_at, 'days') : '';
        let hourDiffernce           =   task_start_at && task_close_at ? task_close_at.diff(task_start_at, 'hours') : '';
        let minuteDiffernce         =   task_start_at && task_close_at ? task_close_at.diff(task_start_at, 'minutes') : '';

        let starting_reading_detail = startingReadingDetail && startingReadingDetail.detail ? startingReadingDetail.detail : null;
        let closing_reading_detail  =  closingReadingDetail && closingReadingDetail.detail ? closingReadingDetail.detail : null;
        let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.assetData);
       
        
        return ( <Ax>
            <Ax>
            {closing_reading_detail || loadedDetail && unloadedDetail ?   <div className="tab_content_wrapper fw-bold my-2">Analysis </div> : null}
                <table className="table table-bordered bg-white">
                    <tbody>
                        {taskDetail && taskDetail.task_in_progress == 'N'
                            ? <Ax>
                                <tr>
                                    <td style={{ width: "33%" }}>Total Task Duration  </td>
                                    <th>{minuteDiffernce ? this.momentHumanize(minuteDiffernce, 'minutes') : '-'}</th>
                                </tr>
                                {starting_reading_detail && closing_reading_detail && assetMeters.map((meter, k) => {
                                    let input_state_starting_key = AssetService.getTaskStartingReadingInputKey(meter.key);
                                    let input_state_closing_key = AssetService.getTaskClosingReadingInputKey(meter.key);
                                    return <tr>
                                        <td>{meter.label}</td>
                                        <th>{Number(parseFloat(closing_reading_detail[input_state_closing_key]).toFixed(2)) - Number(parseFloat(starting_reading_detail[input_state_starting_key]).toFixed(2))}</th>
                                    </tr>

                                })}
                                {loadedDetail && unloadedDetail ?
                                    <tr>
                                        <td style={{ width: "33%" }}>Weight Difference  </td>
                                        <th>{Number(loadedDetail.loaded_weight) - Number(unloadedDetail.unloaded_weight)}</th>
                                    </tr> : null}
                            </Ax> : null}

                    </tbody>
                </table>
            </Ax>
        </Ax>
    )
    }
    
    
    render() {
        let taskData = this.state.taskData;

        let previous_field_keys  =       this.state.previousStepDetails && this.state.previousStepDetails.length > 0 ? this.state.previousStepDetails.map(fl => {return(fl.fields.map(fd => fd.field_key))}).flat() : [];
        
        return (<Ax>
            {this.state.view_loading ? <div className='text-center'> <Loader /></div> :
                <div>
                    {this.state.taskData ?
                        <Ax>
                            <table className="table table-bordered bg-white">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{this.state.taskData.transaction_id ? this.state.taskData.transaction_id : "-"}</td>
                                        <td scope="col">{this.state.taskData.task_datetime_display ? this.state.taskData.task_datetime_display : "-"}</td>
                                        <td scope="col">{this.state.taskData.task_in_progress && this.state.taskData.task_in_progress == 'Y' ? "Inprogress" : "Closed"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Ax>
                        : null}

                    {this.state.previousStepDetails && this.state.previousStepDetails.length > 0
                        ? this.state.previousStepDetails.map(step => {
                            let {detail, fields}            =   {...step};
                            let form_fields                 =   fields || [];
                            let form_field_keys             =   form_fields.map(ff => ff.field_key);   
                            let additionalAttributeInputs   =   form_fields.filter(ff => ff.is_additional_attribute == 'Y');
                            let additional_attribute_data   =   taskData ? taskData.additional_attribute_data : [];
                            let asset                       =   this.state.assetData;
                
                            return(<Ax>
                                 <div className="tab_content_wrapper fw-bold my-2">
                                    {step.name} 
                                    <div className='float-end'>
                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faPencilAlt} color="primary_color"  className="mx-2"/>
                                        {taskData && taskData.completed_step_level && taskData.completed_step_level == step.level  && step.can_delete == 'Y' ?  <TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt} color="primary_color" className="mx-3" onClick={() => this.deleteTaskInit()}/> : null}
                                    </div>
                                </div> 
                                 {step.level == 1 ?
                                    <table className="table mt-2 mb-0 table-hover table-bordered">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "33%" }}>Activity Name</td>
                                                <th>{this.state.taskData && this.state.taskData.activity && this.state.taskData.activity.activity_name ? this.state.taskData.activity.activity_name : "-"} </th>
                                            </tr>


                                        </tbody>
                                    </table> : null}
                                    <table className="table mb-0 table-hover table-bordered">
                                        <tbody>
                                {form_field_keys.map((form_field_key, k) => {
                                    return <Ax key={k}>
                                        {form_field_key == 'starting_readings' && this.startingReadingsJsx(step, taskData, asset)}
                                        {form_field_key == 'task_start_time' && !form_field_keys.includes('starting_readings') && this.startingTimeJsx(step, taskData)}
                                        {form_field_key == 'operator' && this.operatorJsx(step, taskData)}
                                        {form_field_key == 'productivity' && this.productivityJsx(step, taskData)}
                                        {form_field_key == 'attachment' && this.attachmentJsx(step, taskData)}
                                        {form_field_key == 'material' && this.materialJsx(step, taskData)}
                                        {form_field_key == 'workstation_from' && this.workstationFromJsx(step, taskData)}
                                        {form_field_key == 'workstation_to' && this.workstationToJsx(step, taskData)}
                                        {form_field_key == 'workstation' && this.workstationJsx(step, taskData)}
                                        {form_field_key == 'unloaded_weight' && this.unloadedWeightJsx(step, taskData)}
                                        {form_field_key == 'loaded_weight' && this.loadedWeightJsx(step, taskData)}
                                        {form_field_key == 'productivity_remark' && this.productivityRemarkJsx(step, taskData)}
                                        {form_field_key == 'counterlog_readings' && this.counterlogReadingsJsx(step, taskData, asset)}
                                        {form_field_key == 'closing_readings' && this.closingReadingsJsx(step, taskData, asset)}
                                        {form_field_key == 'closing_remarks' && this.closingRemarkJsx(step, taskData)}
                                        {form_field_key == 'activity_count' && this.activityCountJsx(step, taskData)}
                                        {form_field_key == 'task_complete_time' && !form_field_keys.includes('closing_readings') && this.taskCompleteTimeJsx(step, taskData)}  
                                    </Ax>
                                })}
                                        {additionalAttributeInputs.map((aa, aak) => {
                                            let additional_attribute_detail = aa.additional_attribute;
                                            let saved_data = additional_attribute_data.find(aad => aad.key == additional_attribute_detail.key);
                                            return <tr key={aak}>
                                                <td>{additional_attribute_detail.name}</td>
                                                <th>{saved_data ? saved_data.value_display : ''}</th>
                                            </tr>
                                        })}
                               
                                        <tr>
                                            <td className="text-start" style={{ width: "33%" }}>Entry By</td>
                                            <th>{step.detail && step.detail.step_submit_user ? step.detail.step_submit_user.full_name : "-"}</th>

                                        </tr>
                                        <tr>
                                            <td className="text-start" style={{ width: "33%" }}>Entry Timestamp</td>
                                            <th>{step.detail && step.detail.step_submit_at_display ? step.detail.step_submit_at_display : "-"}</th>

                                        </tr>

                                </tbody>
                                    </table>
                                   
                            </Ax>)
                        }) : null}
                        
                        {this.analysisJsx()}
                   
                </div>}
        </Ax>)

    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps)(TaskDetail);