import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../services/HttpAPICall";
import Loader from "../../components/ui/Loader/Loader";
import tapIcon from "../../services/TapIcon"
import TapSelect from '../../components/ui/TapSelect';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapIcon from '../../services/TapIcon';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import Ax from "../../components/hoc/Ax";
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import DatePicker from "react-datepicker";
import moment from 'moment';

class RosterSetting extends AppBaseComponent {

    constructor(props) {
        super(props);
          
          this.initRosterShiftForm      =       {
            roster_name                 :       "",
            no_of_shift                 :       "",
          }
       
          this.state                =          {
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            roster_listing                  :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            addShiftForm                    :       {...this.initRosterShiftForm},
            shifts                           :       [],
            saveFormSubmitting              :       false,
            AllShiftNumbers                 :       [{value : 1,label : 1},{value : 2,label : 2},{value : 3,label : 3},{value : 4,label : 4}]
        };
    }

    componentDidMount() {
        this.loadListingTblData();
        this.addRosterModal            =   new Modal(document.getElementById('addRosterModal'), {keyboard: false, backdrop :false});
    }

    //************************GET PURCHASE LISTING**************** */
    loadListingTblData                 =   ()  =>         {
        this.setState({listing_loading: true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/roster_listing', this.props.access_token,{}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                roster_listing      :       respData,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    rosterMappingInit         =       ()          =>      {
        this.addRosterModal.show();
        this.setState({
            addShiftForm    :       {...this.initRosterShiftForm},
            shifts          :       [],
        })
    }

    addShiftRows             =      (num)           =>       {
        if(num && num.value){
            let shifts = []
            for(let i=0; i<num.value; i++){
                var obj = {};
                obj["name"] = '';
                obj["shift_start"] = ''
                shifts.push(obj);
            }
    
            this.setState({shifts : shifts})
        }
       
    };

    submitRosterForm        =       (e) => {
        e.preventDefault();

        let frmData = {...this.state.addShiftForm,shifts : this.state.shifts}
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/asset/roster_add', this.props.access_token, {}, { ...frmData }, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.addRosterModal.hide();
            this.loadListingTblData()
           this.setState({
            addShiftForm    :       {...this.initRosterShiftForm},
            shifts          :       [],
        })
        }).then(() => this.setState({ saveFormSubmitting: false }));

    }

    addRosterModalJsx               =        ()             =>        {
      
        return (
            <div className="modal fade" id="addRosterModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Add New Roster</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.submitRosterForm} id="addRosterForm">
                                <div className="row my-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require"> Roster Name</label>
                                    <div className="col-sm-8">
                                        <input
                                            name="roster_name"
                                            type="text"
                                            value={this.state.addShiftForm.roster_name}
                                            onChange={(e) => this.formInputHandler(e, 'addShiftForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Roster Name"
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">No of Shifts in 24 Hours</label>
                                    <div className="col-sm-8">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addShiftForm', 'no_of_shift');
                                                this.addShiftRows(selectedOption)
                                            }}
                                            value={this.state.AllShiftNumbers.find(s => s.value == this.state.addShiftForm.no_of_shift)}
                                            options={this.state.AllShiftNumbers}
                                            isSearchable={false}
                                            placeholder="Select No of Shifts"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            required={true}
                                        />

                                    </div>
                                </div>
                                {this.state.shifts && this.state.shifts.length > 0
                                    ? this.state.shifts.map((shifts, i) => {
                                      
                                        return (<div className="row my-3">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">Shift Name & Start Time</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    value={this.state.shifts[i].name}
                                                    onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e.target.value, 'name', i, 'shifts')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Enter Shift Name"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <div className="col-sm-4 add_calender_section">
                                                <DatePicker
                                                    onChange={(value, event) => {
                                                        this.dynamicFormDateHandler(value, 'shift_start', i, 'shifts', 'HH:mm')
                                                    }}
                                                    selected={
                                                        this.state.shifts[i].shift_start
                                                            ? moment(this.state.shifts[i].shift_start, 'HH:mm').toDate()
                                                            : false
                                                    }
                                                    className="form-control form-control-sm"
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeFormat="hh:mm a"
                                                    timeIntervals={5}
                                                    timeCaption="Hours"
                                                    dateFormat="hh:mm a"
                                                    autoComplete={"off"}
                                                    placeholderText={`Select Limit`}

                                                />
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                    alt="requisition_date"
                                                    className="add_calender-icon"
                                                />

                                            </div>
                                        </div>)
                                    })

                                    : null}
                            </form>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                            <button type="submit" className="btn btn-primary" form="addRosterForm" disabled={this.state.saveFormSubmitting}>Submit {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    rosterSettingListJsx            =       ()      =>    {

        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Roster List</h3>
                <div className="text-end mt15">
                    <button type="button" className="btn btn-primary" onClick={this.rosterMappingInit}>
                        New Roster
                    </button>
                </div>
            </div>
          

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl','col-12'].join(' ')} >{this.rosterSettingTableJsx()}</div>
                   
                </div>
            </div>
        </Ax>);
    }

    rosterSettingTableJsx            =   ()   =>   {
      
        return (<Ax>

            <table className="table bg-white table-fixed table-bordered">
                <thead className='table-secondary'>
                    <tr>
                        <td style={{ width: "5%" }} className="text-center">S.No</td>

                        <td style={{ width: "10%" }}>Roster ID</td>
                        <td style={{ width: "25%" }}>Roster Name</td>
                        <td style={{ width: "10%" }} className="text-center">No of Shifts</td>
                        <td style={{ width: "20%" }}>Shift Name</td>
                        <td style={{ width: "15%" }}>Shift Start Time</td>
                        <td style={{ width: "15%" }}>Shift Hours</td>

                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? <tr className='text-center'><td colSpan={5}><Loader /></td></tr>
                        :
                        this.state.roster_listing && this.state.roster_listing.length > 0
                            ? this.state.roster_listing.map((roster, k) => {
                                return (<Ax>
                                    {roster.shifts && roster.shifts.length > 0
                                        ? roster.shifts.map((ros, key) => {
                                            return (<tr>
                                                {key == 0 && <Ax>
                                                    <td className="text-center" rowSpan={roster.no_of_shift}>{k + 1}</td>
                                                    <td rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.tap_roster_id ? roster.tap_roster_id : "-"}</td>
                                                    <td rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.roster_name ? roster.roster_name : "-"}</td>
                                                    <td className="text-center" rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.no_of_shift ? roster.no_of_shift : "0"}</td>

                                                </Ax>}
                                                <td>{ros.shift_name}</td>
                                                <td>{ros.shift_start}</td>
                                                <td>{ros.shift_time}</td>
                                            </tr>)
                                        }) : null}
                                </Ax>)
                            })
                            : <tr><td colSpan={6} className="text-center">No Record</td></tr>
                    }

                </tbody>


            </table>

        </Ax>)
    }
   
    render() {
        return (
            <ApplicationLayout>
                 <Helmet><title>Roster Setting List</title></Helmet>
                {this.rosterSettingListJsx()}
                {this.addRosterModalJsx()}
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        iam_user_sites          :        state.app && state.app.user_sites ? state.app.user_sites : []
    };
};


export default connect(mapStateToProps)(RosterSetting);