import React, { useImperativeHandle, forwardRef, useRef, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import swal from "sweetalert";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import {Chart} from "react-google-charts";

import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import PageHeader from '../../../includes/PageHeader';
import TapIcon from '../../../../services/TapIcon';
import Loader from '../../../../components/ui/Loader/Loader';
import { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import HttpAPICall from '../../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../../components/TapUi';
import TapSelect from '../../../../components/ui/TapSelect';
import TapHelper from '../../../../services/TapHelper';
import DownloadFile from '../../../../services/DownloadFile';
import DateService from '../../../../services/DateService';
import InputAssetSearch from '../../../includes/ui/InputAssetSearch';
import TapNavTabs from '../../../../components/ui/TapNavTabs';
import DataService from '../../../../services/DataService';
import TapColorCodes from '../../../../services/TapColorCodes';


const HGFuelReportChart = forwardRef((props, ref) => {


    let initFormData                    =   {
        data_loaded                         :   false,
        loading                             :   false,
        submitting                          :   false,
        items                               :   [],
        data                                :   null,
        time                                :   null,
        periods                             :   [],
        date_range                              :   'current_month',
        sites                               :   [],
        search_site_id                      :   null,
    };


    const [formData, setFormData]       =   useState({...initFormData});
    const access_token                  =   useSelector(state => state.auth.access_token);
    const all_periods                   =   useSelector(state => state.app.all_periods ? state.app.all_periods : []);
    const user_sites                    =   useSelector(state => state.app.user_sites ? state.app.user_sites : []);


    let itemListUrl                     =   IAM_API_BASE_URL_2 + "/hg_sap/fuel_feedin_report";
    
    useEffect(() => {
        loadFormData();
    }, []);

    useEffect(() => {
        loadItemsData();
    }, [formData.search_site_id, formData.date_range]);

 

    const loadFormData                  =   ()  =>  {
        let periods                         =   all_periods.map((s) => { return {value: s.key, label: `${s.display}`}}) ;
        let sites                           =   user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}});
        setFormData(pd => ({...pd, periods : periods, sites : sites }));
    }

    const reloadItemsData               =   ()  =>  loadItemsData(true)


    const loadItemsData                 =   (reload=false)  =>  {
        setFormData(pd => ({...pd, loading : true }));
        let params                      =   {
                                                action : 'widget',
                                                ...TapHelper.pick(formData, ['search_site_id', 'date_range']),
                                                reload  : reload ? 'reload' : false
                                            };
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            setFormData(pd => ({...pd, data : response.data.data, time : response.data.time }));
        }).then(() => {
            setFormData(pd => ({...pd, loading : false}));
        }); 
    }

    return <div className='row'>
        <div className='col-sm-8'>
        <div className="card mt-3">
        <div className="card-body">
            <div className="row">
                <div className="col-sm-3">
                    <h6 className="fw-bold primary_color">Fuel Summary Chart</h6>
                </div>
                <div className='col-sm-4'>
                    <TapSelect
                        required={true}
                        placeholder="Select Period"
                        containerHeight="33px"
                        fontSize="93%"
                        options={formData.periods}
                        value={formData.periods.find(r => r.value === formData.date_range)}
                        changeEvent={(so) => DataService.handleFormState('date_range', so ? so.value : null, formData, setFormData) }
                    />
                </div>
                <div className='col-sm-4'>
                    <TapSelect
                        changeEvent={(so) => DataService.handleFormState('search_site_id', so ? so.value : null, formData, setFormData) }
                        placeholder="Select Site"
                        containerHeight="33px"
                        fontSize="93%"
                        isClearable={true}
                        options={formData.sites}
                        value={formData.sites.find(s => formData.search_site_id == s.value)}
                    />
                </div>
                <div className="col-sm-1 px-0 mx-0">
                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={reloadItemsData}>Refresh</li>
                        </ul>
                    </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-sm-6">
                    <table className="table table-hover table-bordered table-borderless bg-white">
                        <thead className="table-secondary">
                            <tr className="text-center" >
                                <th style={{ width: "50%" }} className="text-start">Fuel type</th>
                                <th style={{ width: "50%" }}>Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            formData.loading
                                ? <tr><td colSpan={2} className="text-center"><Loader /></td></tr>
                                : ( formData.data && formData.data.map((d,k) => {
                                    return <tr>
                                        <td className="text-center">{d.fuel_type}</td>
                                        <td className="text-center">{d.total_qty}</td>
                                    </tr>
                                })) 
                            }
                        </tbody>
                    </table>
                </div>
                <div className='col-sm-6'>
                    <Chart
                        chartType="PieChart"
                        data={[["Fuel Type", "Total Qty"]].concat((formData.data ? formData.data : []).map((d,k) =>[d.fuel_type, d.total_qty]))}
                        options={{
                            legend: { position: 'labeled', labeledValueText: 'both' },
                            pieSliceText: 'none',
                            sliceVisibilityThreshold: 0,
                            slices: TapColorCodes(),
                            chartArea: { width: '90%', height: '90%' },
                          }}
                        // width={"100%"}
                        // height={"400px"}
                    />
                </div>
            </div>
            <div className="col-sm-12 text-muted fs11">Report as of {formData.time || "-"}</div>
        </div>
    </div>
        </div>
    </div>
});

export default HGFuelReportChart;