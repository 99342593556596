import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import  { INVENTORY_API_BASE_URL_2 } from "../../services/TapApiUrls";
import HttpAPICall from "../../services/HttpAPICall";
import Loader from "../../components/ui/Loader/Loader";
import Ax from "../../components/hoc/Ax";
import { Helmet } from "react-helmet";

class ChildItems extends React.Component {
    constructor(props) {
        super(props);
        this.state              =   {
            itemId                 :   null,
            refreshDetail           :   false,
            configList              :   [],
            listing_loading         :   false,
        };
        this.inventoryUrl           =   INVENTORY_API_BASE_URL_2 + '/item_config/list';
    }

    componentDidMount() {
        this.setState({
            itemId: this.props.item_enc_id,
            refreshDetail: this.props.refreshDetail,
        });
        this.getChildItemsList(this.props.item_enc_id);
    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.item_enc_id !== this.props.item_enc_id){
            this.getChildItemsList(nextProps.item_enc_id);
            this.setState({itemId : nextProps.item_enc_id,itemName : nextProps.itemName});
        }
    }
 
    getChildItemsList = (itemId=null) => {
        this.setState({ listing_loading: true });
        HttpAPICall.withAthorization('GET', this.inventoryUrl, this.props.access_token, { 'search_item_id': itemId }, {}, (resp) => {
            this.setState({
                configList: resp.data.data,
            });
        }).then(() => this.setState({ listing_loading: false }));

    }

    changeTab = (key) => {
        let ParamObject = new URLSearchParams(this.props.props.location.search);
        let itemName, itemCategory;
        if (ParamObject.get("item_name")) {
            itemName = ParamObject.get("item_name");
        }
        if (ParamObject.get("item_category")) {
            itemCategory = ParamObject.get("item_category");
        }
        let obj = { item_name: itemName, item_category: itemCategory };
        this.props.props.history.push({
            pathname: `/item_list/${key}/${this.state.itemId}`,
            search: "?" + new URLSearchParams(obj).toString(),
        });
    };


    


    render() {
        let itemCategory;
        let showTabHeader =  true;
        if(this.props.showTabHeader !== undefined || this.props.showTabHeader == false){
            showTabHeader = false
        }
       
        if (this.props && this.props.props && this.props.props.location && this.props.props.location.search) {
            let ParamObject = new URLSearchParams(this.props.props.location.search);

            
            if (ParamObject.get("item_category")) {
                itemCategory = ParamObject.get("item_category");
            }
        }
       
        return (<Ax>
            <Helmet><title>Child Items </title></Helmet>
            <section className="item_view_container bg-white">
               
                {showTabHeader == true  && <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/details/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab("details")} >
                            <button className="nav-link " id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true" >Details</button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/interchangeable/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab("interchangeable")}>
                            <button className="nav-link " id="interchangeable-tab" data-bs-toggle="tab" data-bs-target="#interchangeable" type="button" role="tab" aria-controls="interchangeable" aria-selected="false">Interchangeable Items</button>
                        </Link>
                    </li>
                    {itemCategory !== "services" && this.props.category !== "services" ? (
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/inventory/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab("inventory")} >
                                <button className="nav-link" id="inventory-tab" data-bs-toggle="tab" data-bs-target="#inventory" type="button" role="tab" aria-controls="inventory" aria-selected="false" >
                                    Inventory Levels
                                </button>
                            </Link>
                        </li>
                    ) : null}
                    {itemCategory !== "services" && this.props.category !== "services" ? (
                        <li className="nav-item" role="presentation">
                            <Link
                                to={`/item_list/configuration/${this.state.itemId}`}
                                onClick={() => this.changeTab("configuration")}
                                role={"button"}
                            >
                                <button
                                    className="nav-link "
                                    id="config-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#config"
                                    type="button"
                                    role="tab"
                                    aria-controls="config"
                                    aria-selected="false"
                                >
                                    Configuration
                                </button>
                            </Link>
                        </li>
                    ) : null}
                    <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/additional_attribute/${this.state.itemId}`}
                            role={"button"}
                            onClick={() => this.changeTab("additional_attribute")}
                        >
                            <button
                                className="nav-link "
                                id="additional_attribute-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#additional_attribute"
                                type="button"
                                role="tab"
                                aria-controls="additional_attribute"
                                aria-selected="false"
                            >
                                Additional Attributes
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/child_item/${this.state.item_enc_id}`}
                            role={"button"}
                            onClick={() => this.changeTab("child_item")}
                        >
                            <button
                                className="nav-link active"
                                id="child_item-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#child_item"
                                type="button"
                                role="tab"
                                aria-controls="child_item"
                                aria-selected="false"
                            >
                               Child Items
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link
                            to={`/item_list/document/${this.state.item_enc_id}`}
                            role={"button"}
                            onClick={() => this.changeTab("document")}
                        >
                            <button
                                className="nav-link "
                                id="document-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#document"
                                type="button"
                                role="tab"
                                aria-controls="document"
                                aria-selected="false"
                            >
                              Document
                            </button>
                        </Link>
                    </li>
                </ul>}
                <div className="tab-content" id="myTabContent"></div>
                <div className="tab_content_header">
                    <table className="table table-hover table-bordered table-responsive bg-white mt-3 mb-5">
                        <thead className="table-secondary">
                            <tr className="text-start">
                                <th style={{ width: "5%" }} className="text-center">
                                    S.No
                                </th>
                                <th style={{ width: "15%" }} >
                                    Item Name
                                </th>
                                <th style={{ width: "14%" }}>
                                    Item Code
                                </th>
                                <th style={{ width: "15%" }}>
                                  Manufacturer
                                </th>
                                <th style={{ width: "12%" }}>
                                WareHouse
                                </th>
                                <th style={{ width: "12%" }}>
                                    Quantity
                                </th>
                                <th style={{ width: "10%" }}>
                                    Unit Of Measurement
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ?
                                <tr>
                                    <td className="text-center" colSpan="8">
                                        <Loader />
                                    </td>
                                </tr>
                                :
                                this.state.configList.map((data, key) => {
                                    return (
                                        data.warehouses.map((whConfig, k) => {
                                            return (
                                              <tr key={k}>
                                                <td className="text-center">{(k += 1)}</td>
                                                <td>
                                                  <b>{whConfig.name}</b>
                                                </td>
                                                <td>
                                                  {whConfig.item_config &&
                                                    whConfig.item_config
                                                      .bin_location}
                                                </td>
                                                <td className="text-end">
                                                  {whConfig.item_config &&
                                                    whConfig.item_config
                                                      .max_inventory_level}
                                                </td>
                                                <td className="text-end">
                                                  {whConfig.item_config &&
                                                    whConfig.item_config
                                                      .min_inventory_level}
                                                </td>
                                                <td className="text-end">
                                                  {whConfig.item_config &&
                                                    whConfig.item_config
                                                      .re_order_level}
                                                </td>
                                                <td className="text-end">
                                                  {whConfig.item_config &&
                                                    whConfig.item_config
                                                      .replenishment_period}
                                                </td>
                                              </tr>
                                            );
                                        })
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
                
            </section>
            </Ax>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        access_token: state.auth.access_token,
        isc_configuration:
            state.app && state.app.isc_configuration
                ? state.app.isc_configuration
                : null,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ChildItems);
