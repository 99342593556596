import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import TapApiUrls, { IAM_API_BASE_URL_2 } from "../../../../services/TapApiUrls";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import Status from "../../../../components/ui/Status";
import React from "react";
import {number} from "prop-types";
import swal from "sweetalert";
import axios from "axios";

class GeneralSetting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initGeneralConfigForm      =   {
            due_soon_days                   :    '',
        };

        this.state                          =        {
            generalConfigloading            :       false,
            general_config_list             :       [],
            generalConfigForm               :       {...this.initGeneralConfigForm},
        };
    }

    componentDidMount() {
        this.getGeneralSettingListing()
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
    }

    getGeneralSettingListing              =    ()           =>     {
        this.setState({generalConfigloading : true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/rental/configuration', this.props.access_token , {}, {}, (resp) => {
            this.setState({general_config_list  : resp.data})
        }).then(() => this.setState({generalConfigloading: false}));
    }

    editConfigurationModalInit          =       ()          =>      {
        this.editGeneralConfiguration.show()
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                this.state.general_config_list.map((k) => {
                    let name = `${k.key}`;
                    let value = `${k.value}`
                    generalConfigForm[name] = value
                });
            }
            this.setState({generalConfigForm})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/rental/configuration', this.props.access_token, {} , {...this.state.generalConfigForm} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }


    editGeneralConfigurationModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">

                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Mark Due Soon if the period is less than given days</label></div>
                                    <div className="col-md-3">
                                        <input
                                            name="due_soon_days"
                                            type="number"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            value={this.state.generalConfigForm.due_soon_days}
                                            onChange={(e) => this.formInputHandler(e,'generalConfigForm')}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    generalConfigurationJsx               =    ()           =>     {
        return (<table className="table table-hover table-bordered my-4 ">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                    <th scope="col" className="text-start">Configuration</th>
                    <th scope="col" style={{ width: "25%" }} >Value</th>
                </tr>
            </thead>
            <tbody>
            { this.state.generalConfigloading
                ? (<tr><td colSpan="3"><Loader/></td></tr>)
                : (this.state.general_config_list.length > 0
                        ? (this.state.general_config_list.map((g , k) => {
                            return (<tr key={k} className="text-center">
                                <td>{k+1}</td>
                                <td className="text-start">{g.configuration}</td>
                                <td>{g.display}</td>
                            </tr>);
                        }))
                        : (<tr><td colSpan="3" className="text-center">No Records</td></tr>)
                )
            }
            </tbody>
        </table>)
    }

    render                                  =     ()           =>     {
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
                <div className="text-end "><button className="btn btn-primary" onClick={() => this.editConfigurationModalInit()}>Edit </button></div>
            </div>
            {this.generalConfigurationJsx()}
            {this.editGeneralConfigurationModalJsx()}
            
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(GeneralSetting);