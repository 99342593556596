import React from 'react';
import { connect } from 'react-redux';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from '../../../components/hoc/Ax';
import tapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import AppBaseComponent from '../../../components/AppBaseComponent';

class AddCustomerModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.addCustomerFormInitialState =     { 
            name                        :       '',
            code                        :       '',
            notes                       :       '',
            state_id                    :       null,
            gstin                       :       '',
            address1                    :       '',
            label                       :       '',
            address2                    :       '',
            city                        :       '',
            landmark                    :       '',
            district_name               :       null,
            pincode                     :       null,
            country_id                  :       99,  
        }

        this.initContactForm        =       {
            name            :       '',
            email           :       '',
            mobile          :       '',
            primary         :   'Y'
        }

        this.state                      =    {
            customerAddFormSubmitting     :    false,
            addCustomerForm               :    {...this.addCustomerFormInitialState},
            allStateList                :    [],
            allCountryList              :    [],
            allDomainList               :    [],
            allDistrictList             :    [],
            allPincodeList              :    [],
            customerFormDataLoaded        :    false,
            customerStateListLoaded       :    false,
            editCustomer                  :    false,
            customerData                  :    null,
            contacts                    :    [{name:'',email:'',mobile:'',primary:'Y'}],
            addNewDistrict              :    false,
            addNewPinCode               :    false,
            loading_form                :    false,
            addContactForm              :    {...this.initContactForm}
        };

        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.customerUrl          =   INVENTORY_API_BASE_URL_2 + '/customer';
    }
    
  
    componentDidMount(){
        this.initilaizeFormData()
        this.addCustomerModal                  =   new Modal(document.getElementById('addCustomerModal'), {keyboard: false, backdrop :false});
    }

    customerAddModalInit      =   (editData)  =>  {  
        this.addCustomerModal.show()
        
        if(editData){
            this.setState({
                addCustomerForm         :  {
                    ...this.addCustomerFormInitialState,
                    id                          : editData.id,
                    name                        :  editData.name,
                    code                        :  editData.code ? editData.code : "-",
                    notes                       :  editData.notes ? editData.notes : "-",
                 },
                    // contacts                    :  editData.contacts ? editData.contacts : this.state.contacts,
                    customerData                  :  editData,
                    editCustomer                  :  true
                
            }, () => {
               if(editData && editData.addressList && editData.addressList.length > 0 ){
                 this.stateChangeHandler(editData.addressList[0].state_id)
               }
            });
        }else{
            this.setState({
                addCustomerForm : {...this.addCustomerFormInitialState},
                editCustomer                  :  false,
                addNewDistrict             :       false,
                 addNewPinCode             :       false
            })
        }
       
    }

    initilaizeFormData      =       ()      =>      {
        this.setState({loading_form: true})
        // if(!this.state.customerFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.customerUrl + '/add_form_data', this.props.access_token, null, null, (response) => {
                let respData = response.data
                this.setState({
                    customerFormDataLoaded       :       true,
                    allCountryList            :       respData && respData.country ?  respData.country.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    allDomainList              :       respData && respData.domain ? respData.domain.map((s) => { return {value: s, label: s}}) : []                  

                },() => {
                    if(this.state.addCustomerForm && this.state.addCustomerForm.country_id){
                        this.countryChangeHandler(this.state.addCustomerForm.country_id)
                    }
                });
            }).then(() => this.setState({ loading_form: false }));
    }

   

    customerModalClose                    =   ()  =>  {
        this.props.closeEvent();
    }

    countryChangeHandler              =        (selected_country)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    allOperationsStateList      :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    addCustomerForm               :    {...this.state.addCustomerForm,state_id: '',district_name : '',pincode:''},
                    addNewDistrict              :   false ,
                    addNewPinCode               :   false 
                });
            });
        }
        
    }

    //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
    stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList             :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    addCustomerForm               :    {...this.state.addCustomerForm,pincode:'',pincode_name:''}
                });
            });
        }
    }

      //FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT
      districtChangeHandler              =        (selected_district)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            let params = {district_name : selectedDistrict,state_id:this.state.addCustomerForm.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList              :   response.data.map((s) => { return {value: s.pincode, label: s.pincode}; }),
                    addNewPinCode               :   response.data && response.data.length > 0 ? false : true,
                });
            });
        }
    }


    //submit add Customer Form
    addCustomerFormSubmit            =       (e)      =>  {
       e.preventDefault()
       const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        let errors                  =   [];
        if(this.state.addCustomerForm.gstin && this.state.addCustomerForm.gstin.trim() != '' && !pattern.test(this.state.addCustomerForm.gstin.trim())) {
            errors                  =   [...errors, 'Please enter valid GST No.'];
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            toast.error(errorMsg, {position: toast.POSITION.TOP_RIGHT});
        } else {
            this.setState({customerAddFormSubmitting : true })
            let frmData;
         
            frmData = { ...this.state.addCustomerForm, contacts: this.state.contacts[0]?.name !== "" ? this.state.contacts : [] }
             
            
            if (this.state.editCustomer == true) {
                HttpAPICall.withAthorization('PUT', this.customerUrl, this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.setState({
                        addCustomerForm: { ...this.addCustomerFormInitialState,addNewDistrict: false,addNewPinCode :false },

                    });

                    if(this.props.afterEditSubmit){
                        this.getCustomerDetails(this.state.customerData.id)
                    }
                    this.addCustomerModal.hide();
                    if(this.props.afterEditFormSubmit) {
                        this.props.afterEditFormSubmit(this.state.customerData.id)
                    }


                }).then(() => this.setState({ customerAddFormSubmitting: false }));
            } else {
                HttpAPICall.withAthorization('POST', this.customerUrl, this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.setState({
                        addCustomerForm: { ...this.addCustomerFormInitialState,addNewDistrict: false,addNewPinCode :false  },

                    })
                    this.addCustomerModal.hide()
                }).then(() => this.setState({ customerAddFormSubmitting: false }));
            }
            
        }
    }

    getCustomerDetails        =       (id)        =>  {
        HttpAPICall.withAthorization('GET', this.customerUrl, this.props.access_token, {}, { }, (response) => {
           let customer = response.data.data.find(data => data.id == this.state.customerData.id);
         
          if(customer){
            let searchedCustomer = {
                label               :   (<Ax>
                                         {customer.name} ({customer.code}) <br/>
                                        </Ax>),
                value               :   customer.id,
                display_label       :   `${customer.name} (${customer.code})`,
                customer              :   customer
            };
    
             this.props.afterEditSubmit(searchedCustomer)
          }

        }).then(() => this.setState({ customerAddFormSubmitting: false }));
    }

    

    //*********************************ITEM ADD JSX*********************** */
    customerAddModalJsx                     =   ()  =>  {
     
        return (
            <div className="modal fade" id="addCustomerModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addCustomerModalLabel">{!this.state.editCustomer ? "Add" : "Edit"} Customer</h5>
                        </div>
                        {this.state.customerFormDataLoaded ?
                            <div className="modal-body ">
                               {this.state.loading_form ? <Loader/>
                               : <form onSubmit={this.addCustomerFormSubmit} id="addCustomerForm" method="post" encType="multipart/form-data">
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Details</span>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label require">Customer Name</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <input
                                                name="name"
                                                type="text"
                                                className="form-control"
                                                required={true}
                                                value={this.state.addCustomerForm.name}
                                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                placeholder="Enter Customer Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label ">Code</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <input
                                                name="code"
                                                type="text"
                                                className="form-control"
                                                value={this.state.addCustomerForm.code}
                                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                placeholder="Enter Customer Code"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label ">Notes</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <textarea
                                                name="notes"
                                                className="form-control"
                                                value={this.state.addCustomerForm.notes}
                                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                style={{ height: "60px" }}
                                                placeholder="Enter Note"
                                            />
                                        </div>
                                    </div>
                                    {this.state.editCustomer == false ?
                                        <Ax>
                                            <div className="tab_content_wrapper">
                                                <span className="content_heading">Address</span>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label require">Label</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="label"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addCustomerForm.label}
                                                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter Address Label"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Address Line 1</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="address1"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addCustomerForm.address1}
                                                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter Address Line 1"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Address Line 2</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="address2"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addCustomerForm.address2}
                                                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter Address Line 2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Landmark</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="landmark"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addCustomerForm.landmark}
                                                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter Landmark"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">City</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="city"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addCustomerForm.city}
                                                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter City"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Country</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addCustomerForm', 'country_id');
                                                            this.countryChangeHandler(selectedOption)
                                                        }
                                                        }
                                                        options={this.state.allCountryList}
                                                        isSearchable={true}
                                                        isClearable={false}
                                                        value={this.state.allCountryList.find(m => m.value == this.state.addCustomerForm.country_id)}
                                                        placeholder="Please Select Country"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label require">State</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                                        options={this.state.allStateList}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={this.state.allStateList.find(m => m.value == this.state.addCustomerForm.state_id)}
                                                        placeholder="Please Select State"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">District</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    {this.state.addNewDistrict
                                                        ? <input
                                                            name="district_name"
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            value={this.state.addCustomerForm.district_name}
                                                            onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                            maxLength="20"
                                                            placeholder="Please Enter District"
                                                        />
                                                        : <TapSelect
                                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                                            options={this.state.allDistrictList}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            value={this.state.allDistrictList.find(m => m.value == this.state.addCustomerForm.district_name)}
                                                            placeholder="Please Select District"
                                                        />}
                                                </div>
                                                <div className="col-sm-2">
                                                    <input
                                                        name="addNewDistrict"
                                                        type="checkbox"
                                                        value={this.state.addNewDistrict}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                addNewDistrict: !this.state.addNewDistrict,
                                                                addCustomerForm: { ...this.state.addCustomerForm, district_id: '', pincode: '' }
                                                            })
                                                        }}
                                                        checked={this.state.addNewDistrict}
                                                        className="form-check-input"
                                                        id="newDistrict"

                                                    />
                                                    <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">PIN Code</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    {this.state.addNewPinCode
                                                        ? <input
                                                            name="pincode"
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            value={this.state.addCustomerForm.pincode}
                                                            onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                            maxLength="20"
                                                            placeholder="Please Enter PinCode"
                                                        />
                                                        : <TapSelect
                                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'pincode') }}
                                                            options={this.state.allPincodeList}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            value={this.state.allPincodeList.find(m => m.value == this.state.addCustomerForm.pincode)}
                                                            placeholder="Please Select PIN Code"
                                                        />}
                                                </div>
                                                <div className="col-sm-2">
                                                    <input
                                                        name="addNewPinCode"
                                                        type="checkbox"
                                                        value={this.state.addNewPinCode}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                addNewPinCode: !this.state.addNewPinCode,
                                                                addCustomerForm: { ...this.state.addCustomerForm, pincode: '' }
                                                            })
                                                        }}
                                                        checked={this.state.addNewPinCode}
                                                        className="form-check-input"
                                                        id="newPinCode"

                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">GSTIN</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="gstin"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addCustomerForm.gstin}
                                                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                                        maxLength="20"
                                                        placeholder="Please Enter GSTIN Number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="tab_content_wrapper">
                                                <span className="content_heading">Primary Contact Details </span>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Contact Person Name</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.contacts[0].name}
                                                        onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                                        maxLength="200"
                                                        placeholder="Please Enter Person Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Email ID</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="email"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        maxLength="200"
                                                        required={this.state.contacts[0].name !== "" ? true : false}
                                                        value={this.state.contacts[0].email}
                                                        onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                                        placeholder="Please Enter Person Email ID"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label"> Mobile Number </label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="mobile"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.contacts[0].mobile}
                                                        required={this.state.contacts[0].name !== "" ? true : false}
                                                        onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                                        placeholder="Please Enter Person Mobile Number"
                                                        pattern="\d*"
                                                        max="20"
                                                    />
                                                </div>
                                            </div>

                                        </Ax>
                                        : null}



                                </form>}
                            </div>

                            : <Loader />}
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.customerAddFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        addCustomerForm: { ...this.addCustomerFormInitialState },

                                    })
                                }}
                            >Cancel</button>
                            <button type="submit" disabled={this.state.customerAddFormSubmitting} className="btn btn-primary" form="addCustomerForm">
                                Submit {this.state.customerAddFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render() {
        return (<Ax>{this.customerAddModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(AddCustomerModal);