import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import DatePicker from "react-datepicker";



class CustomerProjectAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.addCustomerFormInitialState    =   { 
            project_name                        :   '',
            project_code                        :   '',
            customer_id                         :   '',
            status                              :   '',
            notes                               :   '',
            state_id                            :   null,
            gstin                               :   '',
            address1                            :   '',
            label                               :   '',
            address2                            :   '',
            city                                :   '',
            landmark                            :   '',
            district_name                       :   '',
            pincode                             :   null,
            country_id                          :   99,
            project_type                        :  '',
            start_date                          :  '',
            end_date                            :   '',
            
        }

        this.state                          =   {
            saveFormSubmitting                  :   false,
            addCustomerForm                     :   {...this.addCustomerFormInitialState},
            allStateList                        :   [],
            allCountryList                      :   [],
            allDistrictList                     :   [],
            allPincodeList                      :   [],
            allOperationsDistrictList           :   [],
            allOperationsStateList              :   [],
            customerFormDataLoaded              :   false,
            editCustomer                        :   false,
            contacts                            :   [{manager_name:'',email:'',mobile:'',primary:'Y',designation:''}],
            addNewDistrict                      :   false,
            addNewPinCode                       :   false,
            allStatusOptions                    :   [],
            allProjectTypes                     :   [],
            addProjectFromCustomer              :   false
        };

        this.customerFormUrl        =   INVENTORY_API_BASE_URL_2 + '/customer_project/form_data'; 
        this.customerAddUrl        =   INVENTORY_API_BASE_URL_2 + '/customer_project/add'; 
        this.customerEditUrl        =   INVENTORY_API_BASE_URL_2 + '/customer_project/update';   
        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';

    }

    componentDidMount() {
        this.initilaizeFormData()
    }
    
    initilaizeFormData                  =   () =>  {
        this.setState({customerFormDataLoaded : true})

        HttpAPICall.withAthorization('GET', this.customerFormUrl, this.props.access_token, null, null, (response) => {
            let formData                 =   response.data;
            
            this.setState({
                allCountryList          :        formData && formData.country && formData.country.length > 0 ? formData.country.map((s) => { return { value: s.id, label: s.name }; }) : [],
                allProjectTypes         :       formData && formData.project_types && formData.project_types.length > 0 ? formData.project_types.map((s) => { return { value: s.id, label: s.name }; }) : [],
                allStatusOptions        :       formData && formData.status && formData.status.length > 0 ? formData.status.map((s) => { return { value: s.id, label: s.name }; }) : []
            },() => {
                
                if(this.props.location && this.props.location.state && this.props.location.state.customerData) {
                    let customerData   =   this.props.location.state.customerData
                   this.setState({
                       addCustomerForm         :  {
                           ...this.addCustomerFormInitialState,
                           enc_id                          :   customerData.id,
                           project_name                        :   customerData.project_name,
                           project_code                        :   customerData.project_code ? customerData.project_code : "",
                           project_type                         :   customerData.project_type ? customerData.project_type.id : '',
                           notes                                :   customerData.notes ? customerData.notes : "",
                           status                               :   customerData.status ? customerData.status.id : '',
                           customer_id                          :   customerData.customer ? customerData.customer.id : '',
                           start_date                           :   customerData.start_date ? customerData.start_date : '',
                            end_date                            :   customerData.end_date ? customerData.end_date : '',
                            state_id                            :   customerData.address ? customerData.address.state_id : '',
                            gstin                               :   customerData.address ? customerData.address.gstin : '',
                            address1                            :   customerData.address ? customerData.address.address1 : '',
                            label                               :   customerData.address ? customerData.address.label : '',
                            address2                            :   customerData.address ? customerData.address.address2 : '',
                            city                                :   customerData.address ? customerData.address.city : '',
                            landmark                            :   customerData.address ? customerData.address.landmark : '',
                            district_name                       :   customerData.address ? customerData.address.district_name : '',
                            pincode                             :   customerData.address ? customerData.address.pincode : '',
                            country_id                          :   customerData.address ? customerData.address.country_id : 99,
                          
                       },
                           searchedCustomer            :    customerData.customer ? {...customerData.customer,label : customerData.customer.name,display_label : customerData.customer.name,value:customerData.customer.id} : null,
                           editCustomer                :   true
                       
                   }, () => {
                    //this.countryChangeHandler(customerData.address.country_id,true)
                    // this.stateChangeHandler(customerData.address.state_id,true,customerData.address ? customerData.address.district_id : '')
                    // this.districtChangeHandler(customerData.address.district_id,true,customerData.address ? customerData.address.pincode : '')
                   });
               } else {
                   this.setState({ editCustomer: false })
               }

                if (this.props.location && this.props.location.state && this.props.location.state.addProjectFromCustomer) {
                    let customerData = this.props.location.state.addProjectFromCustomer
                    this.setState({
                        addCustomerForm: {
                            ...this.addCustomerFormInitialState,
                            customer_id     :           customerData ? customerData.id : '',
                        },
                        searchedCustomer    :           customerData ? { ...customerData, label: customerData.name, display_label: customerData.name, value: customerData.id } : null,
                        editCustomer        :           false,
                        addProjectFromCustomer  :   true,

                    }, () => {

                    });
                }

               if(this.state.addCustomerForm && this.state.addCustomerForm.country_id){
                    let customerDataExist = this.props.location && this.props.location.state && this.props.location.state.customerData ? true : false;
                    let state_id    =  this.props.location && this.props.location.state && this.props.location.state.customerData ? this.props.location.state.customerData.address.state_id  : '';
                    let district_name    =  this.props.location && this.props.location.state && this.props.location.state.customerData ? this.props.location.state.customerData.address.district_name  : '';
                    let pincode    =  this.props.location && this.props.location.state && this.props.location.state.customerData ? this.props.location.state.customerData.address.pincode  : '';
                    this.countryChangeHandler(this.state.addCustomerForm.country_id,customerDataExist,state_id,district_name,pincode)
               }

           });
        
        }).then(() => {
            this.setState({ customerFormDataLoaded: false })
            
        });
        
    }

    //FUNCTION TO GET STATE LIST ON SELECTING COUNTRY
    countryChangeHandler              =        (selected_country,edit=false,state_id,district_name,pincode)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    addCustomerForm               :   edit == false ?  {...this.state.addCustomerForm,state_id: '',district_name : '',pincode:''} : {...this.state.addCustomerForm,state_id:state_id},
                    addNewDistrict              :   edit == false ? false : this.state.addNewDistrict ,
                    addNewPinCode               :    edit == false ? false : this.state.addNewPinCode ,
                },() => {
                    if(edit == true){
                        this.stateChangeHandler(state_id,true,district_name,pincode)
                    }
                });
            });
        }
        
    }

    //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
    stateChangeHandler              =        (selected_state,edit=false,district_name,pincode)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList             :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    addCustomerForm              :   edit == false ?  {...this.state.addCustomerForm,pincode:'',pincode_name:''} : {...this.state.addCustomerForm,district_name:district_name}
                },() => {
                    if(edit == true){
                        this.districtChangeHandler(district_name,true,pincode)
                    }
                });
            });
        }
    }

    

      //FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT
      districtChangeHandler              =        (selected_district,edit=false,pincode)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            
            let params = {district_name : selectedDistrict,state_id:this.state.addCustomerForm.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList              :   response.data.map((s) => { return {value: s.pincode, label: s.pincode}; }),
                    addNewPinCode               :   response.data && response.data.length > 0 ? false : true,
                    addCustomerForm              :   edit == false ?  {...this.state.addCustomerForm,pincode:'',pincode_name:''} : {...this.state.addCustomerForm,pincode:pincode}
                });
            });
        }
    }

    //****************************CLOSE Customer *********************/
    closeEvent              =           ()      =>      {
        if(this.state.editCustomer){
            this.props.history.push({pathname: `/customer_project_list`, state: {customerData : this.props.location.state.customerData }})
        }else if(this.state.addProjectFromCustomer && this.state.searchedCustomer){
            this.props.history.push({pathname: `/customer_list`, state: {customerId : this.state.searchedCustomer.value }})
        }else{
            this.props.history.push({pathname: `/customer_project_list`})
        }
    }

    //submit add Customer Form
    addCustomerFormSubmitEvent            =       (e)      =>  {
       e.preventDefault()
       const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        let errors                  =   [];
        if(this.state.addCustomerForm.gstin && this.state.addCustomerForm.gstin.trim() != '' && !pattern.test(this.state.addCustomerForm.gstin.trim())) {
            errors                  =   [...errors, 'Please enter valid GST No.'];
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            toast.error(errorMsg, {position: toast.POSITION.TOP_RIGHT});
        } else {
            this.setState({ saveFormSubmitting: true })
            let frmData;
            if (this.state.editCustomer) {
                frmData = { ...this.state.addCustomerForm }
            } else {
                frmData = { ...this.state.addCustomerForm, contacts: this.state.contacts }
            }
            if (this.state.editCustomer) {
                HttpAPICall.withAthorization('PUT', this.customerEditUrl, this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/customer_project_list`, state: {customerData : this.props.location.state.customerData }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                HttpAPICall.withAthorization('POST',  this.customerAddUrl, this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/customer_project_list`})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }

    saveCustomerFormJsx                  =   ()  =>  {
        return (<form className="bg-white p-3" onSubmit={this.addCustomerFormSubmitEvent}>
            <div className="tab_content_wrapper">
                <span className="content_heading">Details</span>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Customer</label>
                </div>
                <div className="col-sm-4">
                    <InputCustomerSearch
                        required={true}
                        placeholder="Search Customer"
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption,'addCustomerForm','customer_id')
                            this.setState({
                                searchedCustomer: selectedOption ? selectedOption : null,
                            });
                        }}
                        only_active="Y"
                        disabled={this.state.addProjectFromCustomer}
                        value={this.state.searchedCustomer ? { ...this.state.searchedCustomer, label: this.state.searchedCustomer.display_label } : null}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Project Name</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="project_name"
                        type="text"
                        className="form-control"
                        value={this.state.addCustomerForm.project_name}
                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                        autoComplete="off"
                        maxLength="250"
                        placeholder="Enter Project Name"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Project Code</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="project_code"
                        type="text"
                        className="form-control"
                        value={this.state.addCustomerForm.project_code}
                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                        autoComplete="off"
                        maxLength="250"
                        placeholder="Enter Project Code"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addCustomerForm', 'status');
                        }}
                        options={this.state.allStatusOptions}
                        isSearchable={true}
                        isClearable={true}
                        //required={true}
                        value={this.state.allStatusOptions.find(s => s.value == this.state.addCustomerForm.status)}
                        placeholder="Select Status"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Notes</label>
                </div>
                <div className="col-sm-4">
                    <textarea
                        name="notes"
                        value={this.state.addCustomerForm.notes}
                        onChange={(e) => this.formInputHandler(e, "addCustomerForm")}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
            </div>

            <div className="tab_content_wrapper">
                <span className="content_heading"> Project Details</span>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Project Type</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addCustomerForm', 'project_type');
                        }}
                        options={this.state.allProjectTypes}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allProjectTypes.find(s => s.value == this.state.addCustomerForm.project_type)}
                        placeholder="Select Project Type"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Start Date</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.addCustomerForm.start_date ? moment(this.state.addCustomerForm.start_date, 'YYYY-MM-DD').toDate() : false}
                        name="start_date"
                        onChange={(value, event) => this.formDateHandler('start_date', value, 'addCustomerForm')}
                        dateFormat="dd-MMM-yyyy"
                        //maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        //required={true}
                        placeholderText="Please Enter Start Date"
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">End Date</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.addCustomerForm.end_date ? moment(this.state.addCustomerForm.end_date, 'YYYY-MM-DD').toDate() : false}
                        name="end_date"
                        onChange={(value, event) => this.formDateHandler('end_date', value, 'addCustomerForm')}
                        dateFormat="dd-MMM-yyyy"
                       // maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        //required={true}
                        placeholderText="Please Enter End Date"
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
             <div className="tab_content_wrapper">
                    <span className="content_heading">Address</span>
                </div>
                <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label require">Label</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="label"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.label}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Label"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Address Line 1</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="address1"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.address1}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Line 1"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Address Line 2</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="address2"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.address2}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Line 2"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Landmark</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="landmark"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.landmark}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Landmark"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">City</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="city"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.city}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter City"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Country</label>
                        </div>
                        <div className="col-sm-4">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addCustomerForm', 'country_id');
                                    this.countryChangeHandler(selectedOption)
                                 }
                                }
                                options={this.state.allCountryList}
                                isSearchable={true}
                                isClearable={false}
                                value={this.state.allCountryList.find(m => m.value == this.state.addCustomerForm.country_id)}
                                placeholder="Please Select Country"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label require">State</label>
                        </div>
                        <div className="col-sm-4">
                            <TapSelect
                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                options={this.state.allStateList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allStateList.find(m => m.value == this.state.addCustomerForm.state_id)}
                                placeholder="Please Select State"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">District</label>
                        </div>
                        <div className="col-sm-4">
                            {this.state.addNewDistrict
                                ? <input
                                    name="district_name"
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.addCustomerForm.district_name}
                                    onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                    maxLength="20"
                                    placeholder="Please Enter District"
                                />
                                : <TapSelect
                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                    options={this.state.allDistrictList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allDistrictList.find(m => m.value == this.state.addCustomerForm.district_name)}
                                    placeholder="Please Select District"
                                />}
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="addNewDistrict"
                                type="checkbox"
                                value={this.state.addNewDistrict}
                                onChange={(e) => {
                                    this.setState({ 
                                        addNewDistrict: !this.state.addNewDistrict,
                                        addCustomerForm   :   {...this.state.addCustomerForm,district_name: '',pincode:''}
                                     })
                                }}
                                checked={this.state.addNewDistrict}
                                className="form-check-input"
                                id="newDistrict"

                            />
                            <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">PIN Code</label>
                        </div>
                        <div className="col-sm-4">
                            {this.state.addNewPinCode
                                ? <input
                                    name="pincode"
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.addCustomerForm.pincode}
                                    onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                    maxLength="20"
                                    placeholder="Please Enter PinCode"
                                />
                                : <TapSelect
                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'pincode') }}
                                    options={this.state.allPincodeList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allPincodeList.find(m => m.value == this.state.addCustomerForm.pincode)}
                                    placeholder="Please Select PIN Code"
                                />}
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="addNewPinCode"
                                type="checkbox"
                                value={this.state.addNewPinCode}
                                onChange={(e) => {
                                    this.setState({ 
                                        addNewPinCode: !this.state.addNewPinCode ,
                                        addCustomerForm   :   {...this.state.addCustomerForm,pincode:''}
                                    })
                                }}
                                checked={this.state.addNewPinCode}
                                className="form-check-input"
                                id="newPinCode"

                            />
                            <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">GSTIN</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="gstin"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addCustomerForm.gstin}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                maxLength="20"
                                placeholder="Please Enter GSTIN Number"
                            />
                        </div>
                    </div>
            {!this.state.editCustomer && <Ax>
                <div className="tab_content_wrapper">
                    <span className="content_heading">Contact Details </span>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Name</label>
                    </div>
                    <div className="col-sm-4">
                        <input
                            name="manager_name"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={this.state.contacts[0].manager_name}
                            onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                            maxLength="200"
                            placeholder="Please Enter Name"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Designation</label>
                    </div>
                    <div className="col-sm-4">
                        <input
                            name="designatio"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={this.state.contacts[0].designation}
                            onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                            maxLength="200"
                            placeholder="Please Enter Designation"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Email ID</label>
                    </div>
                    <div className="col-sm-4">
                        <input
                            name="email"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            maxLength="200"
                            value={this.state.contacts[0].email}
                            onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                            placeholder="Please Enter  Email ID"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label"> Mobile Number </label>
                    </div>
                    <div className="col-sm-4">
                        <input
                            name="mobile"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={this.state.contacts[0].mobile}
                            onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                            placeholder="Please Enter Person Mobile Number"
                            pattern="\d*"
                            max="20"
                        />
                    </div>
                </div>
            </Ax>}
               

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
          <Helmet><title>Customer Project - Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.props.location && this.props.location.state && this.props.location.state.customerData  ? "Edit" : "Add"} Customer Project</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       {!this.state.customerFormDataLoaded ? this.saveCustomerFormJsx()
                        : <Loader/> }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(CustomerProjectAdd);