import { useState } from 'react'

const DataService               =   {

    handleFormState             :   function(state_key, value, stateData, stateFn) {
        let data                    =   {...stateData};
        data[state_key]             =   value;
        stateFn({...data});
    },

    dynamicInputHandlerByKeyVal :   function(object_key, state_key, value, stateData, stateFn) {
        stateData[object_key]         =   {...stateData[object_key] , [state_key] : value};
        stateFn(d => ([...stateData]));
    },

 


    
};

export default DataService;