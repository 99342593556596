import React from 'react';
import {Link} from 'react-router-dom';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import ApplicationLayout from "../../../../../layouts/ApplicationLayout";
import {connect} from "react-redux";
import HttpAPICall from "../../../../../services/HttpAPICall";
import Ax from "../../../../../components/hoc/Ax";
import { Helmet } from 'react-helmet';
import Loader from '../../../../../components/ui/Loader/Loader';
import TapSelect from '../../../../../components/ui/TapSelect';
import Status from '../../../../../components/ui/Status';
import TapIcon from '../../../../../services/TapIcon';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import { toast } from 'react-toastify';

class AddScsSetting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.settingsEditFormInit           =       {
            name                             :       '',
            alias_name                       :       '',
            asset_matrix_id                  :       '',
            asset_matrix                     :       '',
            asset_type                       :       '',
            component                        :       '',
            subcomponent                     :       '',
            description                      :       '',
            status                           :        1,
            days_check                       :        'N',
            hour_meter_check                 :        "N",
            odo_meter_check                  :        'N',
            operation_cycle_check            :        'N',
            days_interval                    :        '',
            hour_meter_interval              :        '',
            odo_meter_interval               :        '',
            operation_cycle_interval         :        '',
            enable_parameter                 :       'N',
            parameter_type                   :       'range',
            options                          :       [{key : 'ok' , val : 'Checked and OK' },
                                                     {key : 'required_attention' , val : 'May Required Attention' },
                                                     {key : 'required_immediateattention' , val : 'Required Immediate Attention' }],
            from                             :       '',
            to                               :       '',
            critical_level                   :       ''
         }

        this.state                           =        {
            assetMatrixDetail               :        null,
            matrix_id                       :        null,
            settingsEditForm                :       {...this.settingsEditFormInit},
            settingsEditFormSubmitting      :       false,
            scsType                         :       '',
            allSections                     :       [],
            allSubSections                  :       [] ,
            scsDetailLoading                :       false,    
        }
    }

    id              =   this.props.matrix_enc_id;

    componentDidMount() {
        
        this.setState({
            matrix_id           :       this.props.matrix_enc_id,
            assetMatrixDetail   :       this.props.assetMatrixDetail,
            scsType             :       this.props.tabScreen
        },() => {this.initializeForm(this.props)});
    }

    componentWillReceiveProps(nextProps) {
      
        if(nextProps.matrix_enc_id !== this.state.matrix_id ) {
            this.setState({matrix_id:nextProps.matrix_enc_id})
        }
        if(nextProps.tabScreen !== this.state.scsType ) {
            this.setState({scsType:nextProps.tabScreen})
        }
        if (nextProps.assetMatrixDetail !== this.state.assetMatrixDetail) {
            this.setState({ assetMatrixDetail: nextProps.assetMatrixDetail },
                () => { this.initializeForm(nextProps) })
        }
    }

    initializeForm           =       (pr)        =>     {
      
        let detail = pr.assetMatrixDetail;
        this.setState({allSections            :        detail && detail.sections && detail.sections.length > 0 ? detail.sections.map((s,i) => {return({value : s.id,label : s.component , subcomponents : s.subcomponents })})  : []},
        () => {
            if(this.state.scsType == "edit"){
                let scsData = pr?.parentProps.location?.state?.scsDetail;
                
                let params = null;
                if(scsData && scsData.id){
                    params  =  {asset_matrix_scs_id : scsData.id}
                }else{
                 params  =  {asset_matrix_id : scsData.enc_asset_matrix_id , scs_id : scsData.scs_id}
                }
     
                this.setState({ scsDetailLoading: true });
                 HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/scs/asset_matrix/detail', this.props.access_token, {...params}, {}, (response) => {  
                     let respData = response.data.data;
            
                     let subSection = respData.component_id  ? this.state.allSections.find(s => respData.component_id == s.value) : null;
                     
                     this.setState({
                         allSubSections: subSection && subSection.subcomponents && subSection.subcomponents.length > 0 ? subSection.subcomponents.map((s, i) => { return ({ value: s.sub_component_id, label: s.subcomponent }) }) : [],
                         settingsEditForm: {
                             ...this.settingsEditFormInit,
                            asset_matrix_scs_id              :        respData.id,
                            asset_matrix_id                  :        respData.enc_asset_matrix_id,
                            scs_id                           :        respData.scs_id,
                            asset_matrix                     :        respData.asset_matrix && respData.asset_matrix.asset_matrix_unique_id ? respData.asset_matrix.asset_matrix_unique_id : '',
                            name                             :        respData.name ? respData.name : "",
                            alias_name                       :        respData.alias_name ? respData.alias_name : "",
                            asset_type                       :        respData.asset_type && respData.asset_type.asset_type_name ? respData.asset_type.asset_type_name : "",
                            component                        :        respData.component_id,
                            subcomponent                     :        respData.subcomponent_id,
                            description                      :        respData.description,
                            status                           :        respData.status,
                            days_check                       :        respData.days_check ? respData.days_check :  'N',
                            hour_meter_check                 :        respData.hour_meter_check ? respData.hour_meter_check :  'N',
                            odo_meter_check                  :        respData.odo_meter_check ? respData.odo_meter_check :  'N',
                            operation_cycle_check            :        respData.operation_cycle_check ? respData.operation_cycle_check :  'N',
                            days_interval                    :        respData.days_interval ? respData.days_interval :  '',
                            hour_meter_interval              :        respData.hour_meter_interval ? respData.hour_meter_interval :  '',
                            odo_meter_interval               :        respData.odo_meter_interval ? respData.odo_meter_interval :  '',
                            operation_cycle_interval         :        respData.operation_cycle_interval ? respData.operation_cycle_interval :  '',
                            enable_parameter                 :        respData.enable_parameter ? respData.enable_parameter : 'N',
                            parameter_type                   :        respData.parameter ? respData.parameter.type : '',
                            to                               :        respData.parameter && respData.parameter.type == "range" ? respData.parameter.to : '',
                            from                             :        respData.parameter && respData.parameter.type == "range" ? respData.parameter.from : '',
                            critical_level                   :        respData.parameter && respData.parameter.type == "range" ? respData.parameter.critical_level : '', 
                            options                          :         [{key : 'ok' , val : 'Checked and OK' },
                                                                        {key : 'required_attention' , val : 'May Required Attention' },
                                                                        {key : 'required_immediateattention' , val : 'Required Immediate Attention' }],       
                         },
                     })
                 }).then(() => this.setState({ scsDetailLoading : false }));
            }else{
                this.setState({ scsDetailLoading: true });
                 this.setState({
                     settingsEditForm       :       {
                         ...this.settingsEditFormInit,
                         asset_matrix_id    :       this.state.matrix_id,
                         asset_matrix       :       detail.asset_matrix_unique_id ? detail.asset_matrix_unique_id : '',
                         asset_type         :       detail.assetType && detail.assetType.asset_type_name ? detail.assetType.asset_type_name : ""
                     },
             },() => this.setState({ scsDetailLoading : false }))
            }
        })
       
    }

    closeSettings       =       ()      =>      {
        this.props.parentProps.history.goBack()
    }

    allExtraParameterOption         =       [
        {value : 'range' , label : 'Range Start - Close'},
        {value : 'options' , label : 'Predefined Drop-down'},
    ]

    submitSettingsForm          =       (e)     =>      {
        e.preventDefault();
        
        let settingsEditForm;
         if(this.state.settingsEditForm.parameter_type == "range"){
           settingsEditForm             =            {...this.state.settingsEditForm,
                                                            parameter : {
                                                                type : this.state.settingsEditForm.parameter_type,
                                                                to   : this.state.settingsEditForm.to ,
                                                                from : this.state.settingsEditForm.from,
                                                                critical_level      :       this.state.settingsEditForm.critical_level, 
                                                            },
                                                     }
         }else{
            settingsEditForm             =            {...this.state.settingsEditForm,
                                                            parameter : {
                                                                type : this.state.settingsEditForm.parameter_type,
                                                                options : this.state.settingsEditForm.options 
                                                            }
                                                        }
         }
        if (this.state.scsType == "add") {
            delete settingsEditForm.alias_name;
            this.setState({ settingsEditFormSubmitting: true });
            HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/scs/asset_matrix', this.props.access_token, {}, { ...settingsEditForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.closeSettings()
            }).then(() => this.setState({ settingsEditFormSubmitting: false }));
        }else{
            this.setState({ settingsEditFormSubmitting: true });
            HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/scs/asset_matrix', this.props.access_token, {}, { ...settingsEditForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.closeSettings()
            }).then(() => this.setState({ settingsEditFormSubmitting: false }))
        }
      
    }

    render() {

      let from                =   this.state.settingsEditForm.from;
      let to                  =   this.state.settingsEditForm.to;
      let critical_level      =   this.state.settingsEditForm.critical_level;
      let danderlevel,successlevel ;

      if(from >= 0 && to >= 0 && critical_level > 0) {
          if(from > to && critical_level > to && critical_level < from) {
              danderlevel     =   parseInt(((critical_level-to)/(from-to))*100);
              successlevel    =   100 - danderlevel;
          }else if(to > from && critical_level > from && critical_level < to) {
              successlevel    =   parseInt(((critical_level-from)/(to-from))*100);
              danderlevel     =   100 - successlevel;
          }             
      }
     
        return (
            <Ax>
                <Helmet><title>Asset Matrix - Scs Setting</title></Helmet>

                <div className="row ml1">
                    <div className="col-12 pl0 bg-white ">
                        <div className="pageTbl p-2" style={{ maxHeight: "80vh" }}>
                            {this.state.scs_details_loading
                                ? <div className='text-center'><Loader /></div>
                                : <Ax>
                                    {this.state.assetMatrixDetail ?
                                        <form className={['pr10', 'filter_form'].join(' ')} onSubmit={this.submitSettingsForm}>
                                            <div className="tab_content_wrapper my-2">
                                                <span className="content_heading">{this.state.scsType == "add" ? "Add Custom" : "Edit"} Service Checksheet</span>
                                                <div className='float-end'>
                                                    <button type="button" className="btn btn-secondary" onClick={this.closeSettings}>
                                                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-action" /></button>
                                                </div>
                                            </div>
                                            {this.state.scsDetailLoading ? <Loader />
                                                : <Ax>
                                                    <div className="row my-3">
                                                        <label className="col-sm-4 col-form-label  require">Name</label>
                                                        <div className="col-sm-4">
                                                            <input
                                                                name="name"
                                                                type="text"
                                                                value={this.state.settingsEditForm.name}
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                                placeholder="Please enter Name"
                                                                disabled={this.state.scsType == "edit" ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.state.scsType == "edit"
                                                        ? <div className="row my-3">
                                                            <label className="col-sm-4 col-form-label require">Alias Name</label>
                                                            <div className="col-sm-4">
                                                                <input
                                                                    name="alias_name"
                                                                    type="text"
                                                                    value={this.state.settingsEditForm.alias_name}
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                    onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                                    placeholder="Please enter Alias Name"
                                                                />
                                                            </div>
                                                        </div>
                                                        : null}
                                                    <div className="row my-3">
                                                        <label className="col-sm-4 col-form-label">Asset Matrix ID</label>
                                                        <div className="col-sm-4">
                                                            <input
                                                                name="asset_matrix"
                                                                type="text"
                                                                value={this.state.settingsEditForm.asset_matrix}
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                                placeholder="Please enter Asset Matrix ID"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <label className="col-sm-4 col-form-label">Asset Type</label>
                                                        <div className="col-sm-4">
                                                            <input
                                                                name="asset_type"
                                                                type="text"
                                                                value={this.state.settingsEditForm.asset_type}
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                                placeholder="Please enter Asset  Type"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <label className="col-sm-4 col-form-label">Section</label>
                                                        <div className="col-sm-4">
                                                            <TapSelect
                                                                options={this.state.allSections}
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'settingsEditForm', 'component');
                                                                    let subSection = selectedOption && selectedOption.value ? this.state.allSections.find(s => selectedOption.value == s.value) : null
                                                                    this.setState({
                                                                        allSubSections: subSection && subSection.subcomponents && subSection.subcomponents.length > 0 ? subSection.subcomponents.map((s, i) => { return ({ value: s.sub_component_id, label: s.subcomponent }) }) : []
                                                                    })
                                                                }}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                value={this.state.allSections.find(s => this.state.settingsEditForm.component == s.value)}
                                                                placeholder="Please enter Section Name"
                                                                isDisabled={this.state.scsType == "edit" ? true : false}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <label className="col-sm-4 col-form-label">Sub-Section</label>
                                                        <div className="col-sm-4">
                                                            <TapSelect
                                                                options={this.state.allSubSections}
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'settingsEditForm', 'subcomponent');
                                                                }}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                value={this.state.allSubSections.find(s => this.state.settingsEditForm.subcomponent == s.value)}
                                                                placeholder="Please enter Sub Section Name"
                                                                isDisabled={this.state.scsType == "edit" ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <label className="col-sm-4 col-form-label">Description</label>
                                                        <div className="col-sm-4">
                                                            <textarea
                                                                name="description"
                                                                value={this.state.settingsEditForm.description}
                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                className="form-control"
                                                                style={{ height: "40px" }}
                                                                disabled={this.state.scsType == "edit" ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row  my-3">
                                                        <div className="col-sm-4">
                                                            <label className="col-sm-4 col-form-label">Tracking Status</label>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    name="status"
                                                                    type="radio"
                                                                    value={1}
                                                                    onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                    checked={this.state.settingsEditForm.status == 1}
                                                                    className="form-check-input"
                                                                    required={true}
                                                                    id="trackingStatusFormEnable"
                                                                />
                                                                <label className="form-check-label mr-2" htmlFor="trackingStatusFormEnable">Enable</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    name="status"
                                                                    type="radio"
                                                                    value={2}
                                                                    onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                    checked={this.state.settingsEditForm.status == 2}
                                                                    className="form-check-input "
                                                                    required={true}
                                                                    id="trackingStatusFormDisable"
                                                                />
                                                                <label className="form-check-label" htmlFor="trackingStatusFormDisable">Disable</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {this.state.settingsEditForm.status == 1
                                                        ? <Ax>
                                                            {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType?.hour_meter == "yes"
                                                                ? <div className="row  my-3">
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="site_id" className="col-form-label">Hour Meter </label>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                name="hour_meter_check"
                                                                                type="radio"
                                                                                value="Y"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                checked={this.state.settingsEditForm.hour_meter_check == "Y"}
                                                                                className="form-check-input"
                                                                                id="hourMeterFormEnable"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="hourMeterFormEnable">Enable</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                name="hour_meter_check"
                                                                                type="radio"
                                                                                value="N"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                checked={this.state.settingsEditForm.hour_meter_check == "N"}
                                                                                className="form-check-input"
                                                                                id="hourMeterFormDisable"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="hourMeterFormDisable">Disable</label>
                                                                        </div>
                                                                        {this.state.scsType == "edit"
                                                                            ? <div className="form-check form-check-inline">
                                                                                <input
                                                                                    name="hour_meter_check"
                                                                                    type="radio"
                                                                                    value="unchange"
                                                                                    onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                    checked={this.state.settingsEditForm.hour_meter_check == "unchange"}
                                                                                    className="form-check-input"
                                                                                    id="hourMeterFormUnchanged"
                                                                                />
                                                                                <label className="form-check-label" htmlFor="hourMeterFormUnchanged">Unchanged</label>
                                                                            </div>

                                                                            : null}
                                                                    </div>


                                                                    {this.state.settingsEditForm.hour_meter_check == "Y" || this.state.settingsEditForm.hour_meter_check == "unchange"
                                                                        ? <div className="col-sm-2">
                                                                            <input
                                                                                name="hour_meter_interval"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Hour Meter"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                style={{ height: "30px", fontSize: "93%" }}
                                                                                autoComplete="off"
                                                                                disabled={this.state.settingsEditForm.hour_meter_check == "unchange" ? true : false}
                                                                                value={this.state.settingsEditForm.hour_meter_interval}
                                                                            />

                                                                        </div>
                                                                        : null}

                                                                </div>
                                                                : null}
                                                            {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType?.odo_meter == "yes"
                                                                ? <div className="row  my-3">
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="site_id" className="col-form-label">Odo Meter </label>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                name="odo_meter_check"
                                                                                type="radio"
                                                                                value="Y"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                checked={this.state.settingsEditForm.odo_meter_check == "Y"}
                                                                                className="form-check-input"
                                                                                id="odoMeterFormEnable"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="odoMeterFormEnable">Enable</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                name="odo_meter_check"
                                                                                type="radio"
                                                                                value="N"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                checked={this.state.settingsEditForm.odo_meter_check == "N"}
                                                                                className="form-check-input"
                                                                                id="odoMeterFormDisable"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="odoMeterFormDisable">Disable</label>
                                                                        </div>
                                                                        {this.state.scsType == "edit"
                                                                            ? <div className="form-check form-check-inline">
                                                                                <input
                                                                                    name="odo_meter_check"
                                                                                    type="radio"
                                                                                    value="unchange"
                                                                                    onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                    checked={this.state.settingsEditForm.odo_meter_check == "unchange"}
                                                                                    className="form-check-input"
                                                                                    id="odoMeterFormUnchanged"
                                                                                />
                                                                                <label className="form-check-label" htmlFor="odoMeterFormUnchanged">Unchanged</label>
                                                                            </div>

                                                                            : null}
                                                                    </div>

                                                                    {this.state.settingsEditForm.odo_meter_check == "Y" || this.state.settingsEditForm.odo_meter_check == "unchange"
                                                                        ? <div className="col-sm-2">
                                                                            <input
                                                                                name="odo_meter_interval"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter Odo Meter"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                style={{ height: "30px", fontSize: "93%" }}
                                                                                autoComplete="off"
                                                                                disabled={this.state.settingsEditForm.odo_meter_check == "unchange" ? true : false}
                                                                                value={this.state.settingsEditForm.odo_meter_interval}
                                                                            />

                                                                        </div>
                                                                        : null}

                                                                </div>
                                                                : null}
                                                            {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType?.operation_cycle == "yes"
                                                                ? <div className="row  my-3">
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="site_id" className="col-form-label">OPC Meter </label>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                name="opc_meter_check"
                                                                                type="radio"
                                                                                value="Y"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                checked={this.state.settingsEditForm.opc_meter_check == "Y"}
                                                                                className="form-check-input"
                                                                                id="opcMeterFormEnable"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="opcMeterFormEnable">Enable</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input
                                                                                name="opc_meter_check"
                                                                                type="radio"
                                                                                value="N"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                checked={this.state.settingsEditForm.opc_meter_check == "N"}
                                                                                className="form-check-input"
                                                                                id="opcMeterFormDisable"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="opcMeterFormDisable">Disable</label>
                                                                        </div>
                                                                        {this.state.scsType == "edit"
                                                                            ? <div className="form-check form-check-inline">
                                                                                <input
                                                                                    name="opc_meter_check"
                                                                                    type="radio"
                                                                                    value="unchange"
                                                                                    onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                    checked={this.state.settingsEditForm.opc_meter_check == "unchange"}
                                                                                    className="form-check-input"
                                                                                    id="opcMeterFormUnchanged"
                                                                                />
                                                                                <label className="form-check-label" htmlFor="opcMeterFormUnchanged">Unchanged</label>
                                                                            </div>

                                                                            : null}

                                                                    </div>
                                                                    {this.state.settingsEditForm.opc_meter_check == "Y" || this.state.settingsEditForm.opc_meter_check == "unchange"
                                                                        ? <div className="col-sm-2">
                                                                            <input
                                                                                name="opc_meter_interval"
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Enter OPC Meter"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                style={{ height: "30px", fontSize: "93%" }}
                                                                                autoComplete="off"
                                                                                disabled={this.state.settingsEditForm.opc_meter_check == "unchange" ? true : false}
                                                                                value={this.state.settingsEditForm.opc_meter_interval}
                                                                            />

                                                                        </div>
                                                                        : null}

                                                                </div>
                                                                : null}
                                                            <div className="row  my-3">
                                                                <div className="col-sm-4">
                                                                    <label htmlFor="site_id" className="col-form-label">Days </label>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            name="days_check"
                                                                            type="radio"
                                                                            value="Y"
                                                                            onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                            checked={this.state.settingsEditForm.days_check == "Y"}
                                                                            className="form-check-input"
                                                                            id="daysMeterFormEnable"
                                                                        />
                                                                        <label className="form-check-label" htmlFor="daysMeterFormEnable">Enable</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            name="days_check"
                                                                            type="radio"
                                                                            value="N"
                                                                            onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                            checked={this.state.settingsEditForm.days_check == "N"}
                                                                            className="form-check-input"
                                                                            id="daysMeterFormDisable"
                                                                        />
                                                                        <label className="form-check-label" htmlFor="daysMeterFormDisable">Disable</label>
                                                                    </div>
                                                                    {this.state.scsType == "edit"
                                                                        ? <div className="form-check form-check-inline">
                                                                            <input
                                                                                name="days_check"
                                                                                type="radio"
                                                                                value="unchange"
                                                                                onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                                checked={this.state.settingsEditForm.days_check == "unchange"}
                                                                                className="form-check-input"
                                                                                id="daysMeterFormUnchanged"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="daysMeterFormUnchanged">Unchanged</label>
                                                                        </div>

                                                                        : null}
                                                                </div>

                                                                {this.state.settingsEditForm.days_check == "Y" || this.state.settingsEditForm.days_check == "unchange"
                                                                    ? <div className="col-sm-2">
                                                                        <input
                                                                            name="days_interval"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Days"
                                                                            onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                            style={{ height: "30px", fontSize: "93%" }}
                                                                            autoComplete="off"
                                                                            required={true}
                                                                            disabled={this.state.settingsEditForm.days_check == "unchange" ? true : false}
                                                                            value={this.state.settingsEditForm.days_interval}
                                                                        />

                                                                    </div>
                                                                    : null}

                                                            </div>



                                                        </Ax>
                                                        : null}
                                                    <div className="row  my-3">
                                                        <div className="col-sm-4">
                                                            <label className="col-sm-12 col-form-label">Extra Parameter Required ?</label>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    name="enable_parameter"
                                                                    type="radio"
                                                                    value='Y'
                                                                    onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                    checked={this.state.settingsEditForm.enable_parameter == 'Y'}
                                                                    className="form-check-input"
                                                                    required={true}
                                                                    id="extraparameterFormEnable"
                                                                />
                                                                <label className="form-check-label" htmlFor="extraparameterFormEnable">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    name="enable_parameter"
                                                                    type="radio"
                                                                    value='N'
                                                                    onChange={(e) => this.formInputHandler(e, 'settingsEditForm')}
                                                                    checked={this.state.settingsEditForm.enable_parameter == 'N'}
                                                                    className="form-check-input"
                                                                    required={true}
                                                                    id="extraparamterFormDisable"
                                                                />
                                                                <label className="form-check-label" htmlFor="extraparamterFormDisable">No</label>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    {this.state.settingsEditForm.enable_parameter == 'Y'
                                                        ? <Ax>
                                                            <div className="row  my-3">
                                                                <div className="col-sm-4">
                                                                    <label className="col-sm-12 col-form-label">Extra Parameter Option ?</label>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <TapSelect
                                                                        options={this.allExtraParameterOption}
                                                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'settingsEditForm', 'parameter_type')}
                                                                        isClearable={true}
                                                                        value={this.allExtraParameterOption.find(s => this.state.settingsEditForm.parameter_type == s.value)}
                                                                        placeholder="Select Extra Parameter Option"
                                                                        menuPlacement="top"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {this.state.settingsEditForm.parameter_type == 'options'
                                                                ? <div className="row  my-3">
                                                                    <div className="col-sm-4">
                                                                        <label className="col-sm-12 col-form-label">Available Options</label>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <ol>
                                                                            {this.state.settingsEditForm.options && this.state.settingsEditForm.options.length > 0
                                                                                ? this.state.settingsEditForm.options.map((o, i) => {
                                                                                    return (<li className='my-1' ><Status color={o.key == 'ok' ? '#198754' : o.key == 'required_attention' ? '#ffc107' : '#dc3545'}>{o.val}</Status></li>)
                                                                                })
                                                                                : null}
                                                                        </ol>
                                                                    </div>
                                                                </div>
                                                                : <Ax>
                                                                    <div className="row  my-3">
                                                                        <div className="col-sm-4">
                                                                            <label className="col-sm-12 col-form-label">Range ( Start - Close )</label>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <input
                                                                                name="from"
                                                                                type="text"
                                                                                value={this.state.settingsEditForm.from}
                                                                                className="form-control"
                                                                                autoComplete="off"
                                                                                onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                                                placeholder="Please enter Start Range - "

                                                                            />

                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <input
                                                                                name="to"
                                                                                type="text"
                                                                                value={this.state.settingsEditForm.to}
                                                                                className="form-control"
                                                                                autoComplete="off"
                                                                                onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                                                placeholder="Please enter Close Range"

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row  my-3">
                                                                        <div className="col-sm-4">
                                                                            <label className="col-sm-12 col-form-label">Critical Level</label>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <input
                                                                                name="critical_level"
                                                                                type="number"
                                                                                value={this.state.settingsEditForm.critical_level}
                                                                                className="form-control"
                                                                                autoComplete="off"
                                                                                onChange={(e) => this.formInputHandler(e, "settingsEditForm")}
                                                                                placeholder="Enter Critical Level "

                                                                            />

                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            {danderlevel && successlevel
                                                                                ? <Ax><div className="progress height20 bg-danger">
                                                                                    <div className="progress-bar bg-success" style={{ width: `${successlevel * 90 / 100}%` }}>
                                                                                        <span className="badge bg-success">{successlevel}%</span>
                                                                                        <span className="sr-only">{successlevel}% Complete (success)</span>
                                                                                    </div>
                                                                                    <div className="progress-bar bg-warning" style={{ width: `${danderlevel * 90 / 100}%` }}>
                                                                                        <span className="badge text-right bg-warning">{danderlevel}%</span>
                                                                                        <span className="sr-only">{danderlevel}% Complete (danger)</span>
                                                                                    </div>
                                                                                </div>

                                                                                </Ax>
                                                                                : null}

                                                                        </div>

                                                                    </div>
                                                                    <div className="row  my-3">

                                                                        <div className=" offset-4 col-sm-4">
                                                                            <ol>
                                                                                {this.state.settingsEditForm.options && this.state.settingsEditForm.options.length > 0
                                                                                    ? this.state.settingsEditForm.options.map((o, i) => {
                                                                                        return (<li className='my-1' ><Status color={o.key == 'ok' ? '#198754' : o.key == 'required_attention' ? '#ffc107' : '#dc3545'}>{o.val}</Status></li>)
                                                                                    })
                                                                                    : null}
                                                                            </ol>
                                                                        </div>
                                                                    </div>
                                                                </Ax>}

                                                        </Ax>
                                                        : null}
                                                    <div className='row'>
                                                        <div className="col-12 text-end fixed_footer">
                                                            <button type="button" disabled={this.state.settingsEditFormSubmitting} onClick={this.closeSettings} className="btn btn-secondary ms-2">Cancel</button>
                                                            <button type="submit" disabled={this.state.settingsEditFormSubmitting} className="btn btn-primary mx-2">
                                                                Save {this.state.settingsEditFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Ax>}
                                        </form>
                                        : null}
                                </Ax>}
                        </div>
                    </div>
                </div>

            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
    }
}

export default connect(mapStateToProps)(AddScsSetting);
