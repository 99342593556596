import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {PeriodSelectionWithCustom, DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import RunningStatusViewModal from '../../assets/action/serviceChecksheet/runningStatus/RunningStatusViewModal';
import LinkedItemsViewModal from '../../assets/action/serviceChecksheet/runningStatus/LinkedItemsViewModal';
import { Modal } from 'bootstrap';
import  ReactPaginate  from 'react-paginate';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemsConsumptionHistory from '../../inventory/consumption/ItemsConsumptionHistory';

class ServiceChecksheetExecutionHistoryReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.cancelToken                    =   null;
        this.reportUrl                      =   IAM_API_BASE_URL_2 + '/scs/asset/listing_with_running_status';

        this.reportFilterFormInitialState   =   {
            search_asset_type_ids             :       [],
            search_asset_ids                  :       [],
            search_site_ids                   :       [],
            scs_name                          :       '',
            scs_status                        :       []
        };
        
        this.reportFilterFormLabel          =   {
            search_asset_type_ids           :       'Asset Type : ',
            search_asset_ids                :       'Assets : ',
            search_site_ids                 :       'Sites : ', 
            scs_name                        :       'Service Checksheet Name : ',
            scs_status                      :       'Status : '
        };
         
        
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            listingMeta                         :   null,
      };

      // reportData                          :   null,                  
        // const report_name   =   'item_closing_stock';
        // const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        // reportData                  :   reportData.length > 0 ? reportData[0] : null,
        // report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
         // className={['dropdown-item', this.state.reportData?.download_access === 'Y' ? '' : 'disabled'].join(' ')}


    }
    
    componentDidMount                   =   ()  =>  {
        this.initPageComponent(this.props);
 }
    
    componentWillReceiveProps           =   (nextProps)  =>  {
        if(this.props.iam_user_sites != nextProps.iam_user_sites || this.props.access_token != nextProps.access_token) {
            this.initPageComponent(nextProps);
        }
    }
    
    initPageComponent                       =   (pr)  =>  {
        //Initaolaize Data :-
       this.setState({
            access_token                :   pr.access_token,
            formDataLoaded              :   true,
            iam_asset_types             :   pr.iam_asset_types ? pr.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites              :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
       }, () => {
          
        });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                  
                   

                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'scs_status') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'scs_name') {
                        show_val            =  this.state.reportFilterForm.scs_name   ;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1)    =>  {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
       
        let params                      =   {page:page,get_asset_profile : "Y",...this.state.submittedReportFilterForm };
        
        if (this.cancelToken) {
            this.cancelToken.cancel("Operation canceled due to new request.")
        }
        this.cancelToken                =   axios.CancelToken.source();
        axios({
            method          :   'GET',
            headers         :   { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.state.access_token },
            params          :   params,
            url             :   this.reportUrl,
            cancelToken     :   this.cancelToken.token
        }).then((response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.meta.from,
                    to                          :   respData.meta.to,
                    total                       :   respData.meta.total,
                    last_page                   :   respData.meta.last_page,
                    per_page                    :   respData.meta.per_page,
                    current_page                :   respData.meta.current_page
                },
                totalListingCount               :   respData.meta.total
            });
           
        }).catch((err) => {
            if (axios.isCancel(err)) {
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   ()  =>  {
        new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> SCS Execution History & Analysis Report</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  => {
        return (<div id="reportFilterForm" className="bg-white collapse " aria-expanded="true">
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Service Checksheet Name/Alias Name</label>
                    <div className="col-sm-6">
                        <input
                            name="scs_name"
                            type="text"
                            value={this.state.reportFilterForm.scs_name   }
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Service Checksheet Name / Alias Name"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        {/* <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        /> */}
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        {/* <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        /> */}
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        {/* <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="bottom"
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        /> */}
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4" onClick={this.reportFilterhandler}>Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            {this.reportTableJsx()}
            {/* { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            } */}
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered bg-white align-items-center  table-sm table-hover  mb-0" id="rowDataReportTable">
                <thead>
                    <tr>
                    <th scope="col" style={{ width: "20%" }}>Service Checksheet Name</th>
                        <th scope="col" style={{ width: "20%" }}>Asset Name</th>
                        
                        <th scope="col" style={{ width: "20%" }}>Last CS Done</th>
                        <th scope="col" style={{ width: "15%" }} >Interval</th>
                        <th scope="col" style={{ width: "15%" }} className="text-start">Actual Running</th>
                        <th scope="col" style={{ width: "10%" }}>Percentage</th>
                    </tr>
                </thead>
                <tbody>
                   <tr>
                    
                   <td rowSpan={2}>Replace - Gear Oil</td>
                    <td rowSpan={2}>Camper - 0287 (HR26 EP 0287)</td>
                        <td>13500.00 KM </td>
                        <td>30000 KM </td>
                        <td>500 KM </td>
                        <td>1.67 % </td>
                           
                   </tr>
                   <tr><td>23-May-2023</td>
                   <td>Disabled</td>
                   <td>5 Days</td>
                   <td>NA </td>
                   </tr>
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet>Service Checksheet Execution History - Report</Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.reportFilterFormJsx()}
                        {this.state.formDataLoaded
                            ? (<Ax>
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {/* <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} /> */}
                    </div>
                </div>
            </div>
           
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(ServiceChecksheetExecutionHistoryReport);