import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import React from "react";
import { IAM_API_BASE_URL_2 } from "../../../../services/TapApiUrls";
import Status from "../../../../components/ui/Status";
import TapSelect from "../../../../components/ui/TapSelect";
import { DisplayListPagination, DisplaySearchCriteria } from "../../../../components/TapUi";

class ActivityCodeList extends AppBaseComponent {

    constructor(props) {
        super(props);

        
        this.initActivityCodeForm      =   {
            site_ids            :       [],
            activity_id         :       '',
            activity_name       :       '',
            code                :       '',
            all_sites           :       false
        };

        this.initActivitySearchForm         =       {
            search_code                      :       '',
            search_activity_ids              :       [],
            search_site_ids                  :       [],
        }

        this.activitySearchFormLabel     =       {
            search_code           :       'Activity Code : ',
            search_activity_ids        :       'Activity Name : ',
            search_site_ids            :       'Sites : ',

        }

        this.initActivityCodeUpdateForm      =   {
            status              :       '2'
        };

        this.state                              =   {
            activity_loading                 :      false,
            activity_code_list               :      null,
            activityCodeAddForm              :     {...this.initActivityCodeForm},
            editFormSubmiting                :      false,
            iam_user_sites                   :      [],
            activity_form_loading                 :      false,
            activity_list                    :      [],
            activityCodeData                 :      null,
            selectedActivityName             :      null,
            activitySearchForm               :       {...this.initActivitySearchForm},
            submittedActivityFilterForm     :       {...this.initActivitySearchForm},
            formSearchedElems                 :        [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            selectedActivityCodes               :       [],
            updateActivityCodeForm              :       {...this.initActivityCodeUpdateForm}
        };
    }

    componentDidMount() {
        this.getActivityCodeList(1);
        this.activityCodeAddForm        =        new Modal(document.getElementById('activityCodeAddFormModal'), {keyboard: false, backdrop :false});
        this.activitySearchModal        =        new Modal(document.getElementById('activitySearchModal'), {keyboard: false, backdrop :false});
        this.updateActivityCodeModal    =        new Modal(document.getElementById('updateActivityCodeModal'), {keyboard: false, backdrop :false})
        this.setState({
            iam_user_sites          :   this.props.iam_user_sites  && this.props.iam_user_sites.length > 0 ? this.props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`,name:s.site_name}}) : [],
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.setState({
                iam_user_sites          :   nextProps.iam_user_sites && nextProps.iam_user_sites.length > 0 ? nextProps.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`,name:s.site_name}}) : [],
            })
        }
   }

    //functions for general configuration
      getActivityCodeList              =    (page=1)           =>     {
        this.setState({activity_loading : true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/activity_codes', this.props.access_token , {...this.state.submittedActivityFilterForm,page:page}, {}, (resp) => {     
            let respData = resp.data;
            this.setState({
            activity_code_list      : respData.data,
            listingMeta            :       respData.meta,
            totalListingCount      :       respData.meta.total,
        })
        }).then(() => this.setState({activity_loading: false}));
    }

    addActivityCodeModalInit     =       (data=null,code_data=null,site)      =>      {
        this.activityCodeAddForm.show();
        // if(!this.state.formDataLoaded){
            this.getActivityList();
        // }
       
        if(data){
            this.setState({
                activityCodeData        :       data,
                activityCodeAddForm         :       {
                    activity_id         :   data.id,
                    activity_name       :   data.activity_name,
                    site_ids            :   [data.site_id],
                    code                :   data.code ? data.code : "",
                    all_sites           :   false
                }
            })
        }else{
            this.setState({activityCodeAddForm              :     {...this.initActivityCodeForm},activityCodeData : null})
        }
    }

    getActivityList              =    ()           =>     {
        this.setState({activity_form_loading : true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/activity_name_list', this.props.access_token , {}, {}, (resp) => {     
         this.setState({
            activity_list   : resp.data && resp.data.length > 0 ? resp.data.map(at => {return({value:at.id,label:at.activity_name,id:at.id})}) : [],
            formDataLoaded  :   true
        })
        }).then(() => this.setState({activity_form_loading: false}));
    }

    onSelectingAllSites             =           (e,key)             =>      {
        let checkedSite = document.getElementById('all_sites').checked
            if(checkedSite == true) {
                this.setState({
                    activityCodeAddForm : {...this.state.activityCodeAddForm,
                    all_sites  :  true,
                    site_ids  :  this.state.iam_user_sites && this.state.iam_user_sites.length > 0 ? this.state.iam_user_sites.map(us => us.value) : []
                }})
            }else{
            this.setState({
                activityCodeAddForm : {...this.state.activityCodeAddForm,
                    all_sites  :  false,
            }})
        }
    }

    activityCodeSearchModalInit     =       ()      =>      {
        this.activitySearchModal.show();
        if(!this.state.formDataLoaded){
            this.getActivityList();
        }
    }

    submitActivityCodeFormHandler        =    (e)          =>     {
        e.preventDefault();
         this.setState({ editFormSubmiting: true });
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/activity_codes', this.props.access_token, {}, { ...this.state.activityCodeAddForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.activityCodeAddForm.hide();
            this.getActivityCodeList();
            this.setState({ activityCodeAddForm: { ...this.initActivityCodeForm } })
        }).then(() => this.setState({ editFormSubmiting: false }));
       
    }

    submitActivitySearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.activitySearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.activitySearchFormLabel[key];
            let value                       =   this.state.activitySearchForm[key];
           
            if(value && value.length > 0) {
                searchParams[key]      =   value;
           if(label) {
                    let show_val            =   value;
                    if(key == 'search_activity_ids') {
                        show_val            =  this.state.activity_list.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_code') {
                        show_val            =   this.state.activitySearchForm ? this.state.activitySearchForm.search_code : '';
                    }
                    
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedActivityFilterForm   :   {...this.state.activitySearchForm},
            formSearchedElems               :   searchedElems
        }, () => {
            this.activitySearchModal.hide()
            this.getActivityCodeList(1);
        });
    }

    activitySearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            activitySearchForm            :   {...this.initActivitySearchForm},
            submittedActivityFilterForm     :   {...this.initActivitySearchForm}
        }, () => {
            this.getActivityCodeList(1);
            this.activitySearchModal.hide();
        });
    }

    checkAllItem() {
        
        let activityCollections      =   document.getElementsByClassName('activity_id_checkbox')
        if(activityCollections && activityCollections.length > 0) {
            for (let i = 0; i < activityCollections.length; i++) {
                activityCollections[i].checked = document.getElementById('all_check_code').checked;
            }
        }
    }

    updateActivityStatusInit       =       ()      =>      {
        // this.setState({cancelledItemList : []} , () => {
        let itemList          =        [];
        var showErrorMessage                =   false;
        let activityItemCheckbox      =   document.getElementsByClassName('activity_id_checkbox');
        if(activityItemCheckbox && activityItemCheckbox.length > 0) {
            for (let i = 0; i < activityItemCheckbox.length; i++) {
                if(activityItemCheckbox[i].checked) {
                    let activityId = activityItemCheckbox[i].value;
                   let siteId =  activityItemCheckbox[i].getAttribute("data-siteId");
                    let data = this.state.activity_code_list.find(i => i.site_id == siteId && i.id == activityId);
                    itemList.push({...data,activity_id:data.id,site:data.site_id})
                }
            }
        }
        if(itemList && itemList.length == 0 && !showErrorMessage){
            toast.error('Please select an Item', { position: toast.POSITION.TOP_RIGHT });
        }else{
          this.updateActivityCodeModal.show()
           this.setState({
                selectedActivityCodes   :   itemList
           })
        }
   
    }

    submitActivityCodeUpdateStatusForm        =    (e)          =>     {
        e.preventDefault();
         this.setState({ editFormSubmiting: true });
         
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/activity_codes_status', this.props.access_token, {}, { ...this.state.updateActivityCodeForm,activities : this.state.selectedActivityCodes }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateActivityCodeModal.hide();
            this.getActivityCodeList();
            this.setState({updateActivityCodeForm: { ...this.initActivityCodeUpdateForm },selectedActivityCodes : [] })
        }).then(() => this.setState({ editFormSubmiting: false }));
       
    }


    addActivityFormModalJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="activityCodeAddFormModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.activityCodeData ? "Edit" : "Add"} Activity Code</h5>
                        </div>
                        <form onSubmit={this.submitActivityCodeFormHandler}>
                            <div className="modal-body">
                                <div className="mb-3 align-items-center row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Activity Name</label>
                                    </div>
                                    <div className="col-7">
                                       {this.state.activityCodeData ? <input
                                            name="activity_name"
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={this.state.activityCodeAddForm.activity_name}
                                            autoComplete="off"
                                            required={true}
                                            placeholder="Activity Name"
                                            onChange={(e) => this.formInputHandler(e, 'activityCodeAddForm')}
                                        />
                                        : <TapSelect
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption.value, 'activityCodeAddForm', 'activity_name',() => {
                                                this.setState({
                                                    selectedActivityName : selectedOption ? selectedOption : null,
                                                    activityCodeAddForm :   {...this.state.activityCodeAddForm,activity_id : selectedOption ? selectedOption.id : ''}
                                                })
                                            });
                                        }}
                                        options={this.state.activity_list}
                                        isSearchable={true}
                                        isClearable={true}
                                        required={true}
                                        className="form-control form-control-sm"
                                        value={this.state.activity_list.find(s => s.value == this.state?.selectedActivityName?.value)}
                                        placeholder="Select Activity"
                                    />}
                                    </div>
                                </div>
                                <div className="my-3 align-items-center row">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="form-label">Activity Code</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="code"
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={this.state.activityCodeAddForm.code}
                                            autoComplete="off"
                                            placeholder="Activity Code"
                                            onChange={(e) => this.formInputHandler(e, 'activityCodeAddForm')}
                                        />
                                    </div>
                                </div>
                                <div className="my-3 align-items-center row">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="form-label">Sites</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'activityCodeAddForm', 'site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            required={true}
                                            value={this.state.iam_user_sites.filter(s => this.state.activityCodeAddForm.site_ids.includes(s.value))}
                                            isDisabled={this.state.activityCodeAddForm.all_sites == true ? true : false}
                                            placeholder={this.state.activityCodeAddForm.all_sites == true ? "All Sites" : "Please Select Sites"}
                                        />
                                    </div>
                                   {/* {!this.state.activityCodeData &&  */}
                                    <div className='col-sm-2'>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="all_sites"
                                                type="checkbox"
                                                value={true}
                                                onChange={(e) => { this.onSelectingAllSites(e, 'all_sites') }}
                                                className="form-check-input"
                                                id="all_sites"
                                                checked={this.state.activityCodeAddForm.all_sites == true}
                                            />
                                            <label className="form-check-label" htmlFor="all_sites">All Sites</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    activitySearchModalJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="activitySearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Activity Code Search</h5>
                        </div>
                        <form onSubmit={this.submitActivitySearchForm}>
                            <div className="modal-body">
                                <div className="mb-3 align-items-center row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Activity Name</label>
                                    </div>
                                    <div className="col-7">
                                       <TapSelect
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'activitySearchForm', 'search_activity_ids')}
                                        options={this.state.activity_list}
                                        isSearchable={true}
                                        isClearable={true}
                                        className="form-control form-control-sm"
                                        isMulti={true}
                                        value={this.state.activity_list.filter(s => this.state.activitySearchForm.search_activity_ids.includes(s.value))}
                                        placeholder="Select Activity"
                                    />
                                    </div>
                                </div>
                                <div className="my-3 align-items-center row">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="form-label">Activity Code</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="search_code"
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={this.state.activitySearchForm.search_code}
                                            autoComplete="off"
                                            placeholder="Activity Code"
                                            onChange={(e) => this.formInputHandler(e, 'activitySearchForm')}
                                        />
                                    </div>
                                </div>
                                <div className="my-3 align-items-center row">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="form-label">Sites</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'activitySearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.iam_user_sites.filter(s => this.state.activitySearchForm.search_site_ids.includes(s.value))}
                                            placeholder={"Select Sites"}
                                        />
                                    </div>
                                 
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updateActivityCodeModalJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="updateActivityCodeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Activity Code</h5>
                        </div>
                        <div className={this.state.selectedActivityCodes && this.state.selectedActivityCodes.length > 18 ? "p-1 tab_content_header bg-light border-bottom" : "p-1 bg-light border-bottom" }>
                            <table className='table table-bordered text-sm table-sm'>
                                <thead className="bg-light">
                                    <tr>
                                        <th style={{ width: "26%" }}>Activity Name</th>
                                        <th style={{ width: "30%" }}>Activity Code</th>
                                        <th>Sites</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.selectedActivityCodes && this.state.selectedActivityCodes.length > 0 && this.state.selectedActivityCodes.map((item, key) => {
                                        return (<tr key={key}>
                                            <td>{item.activity_name}</td>
                                            <td>{item.site_name ? item.site_name : ""}</td>
                                            <td>{item.code ? item.code : ""}</td>
                                        </tr>)
                                    })}

                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.submitActivityCodeUpdateStatusForm}>
                            <div className="modal-body">

                                <div className="my-1 align-items-center row">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="form-label">Status</label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="status"
                                                value="1"
                                                onChange={(e) => this.formInputHandler(e, "updateActivityCodeForm")}
                                                checked={this.state.updateActivityCodeForm.status == "1"}
                                            /> Active
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="status"
                                                value="2"
                                                onChange={(e) => this.formInputHandler(e, "updateActivityCodeForm")}
                                                checked={this.state.updateActivityCodeForm.status == "2"}
                                            /> InActive
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                                  =     ()           =>     {
       
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">Activity Code List</span>
                <div className="text-end ">
                    <button type="button" className="btn btn-secondary ms-1" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                        <li className='dropdown-item' onClick={() => this.addActivityCodeModalInit()}>Add</li>
                        <li className='dropdown-item' onClick={() => this.updateActivityStatusInit()}>Update Status</li>
                    </ul>
                    <button type="button" className="btn btn-secondary mx-2" onClick={this.activityCodeSearchModalInit}  >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon}  className="img-fluid" alt="item-action" />
                    </button>
                    
                </div>
            </div>
            <div className="my-2">
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.activitySearchClear} />
            </div>
            <table className="table table-hover table-bordered my-4 ">
                <thead className="table-secondary">
                    <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>
                        <input type='checkbox' onChange={this.checkAllItem} name="chk[]"  id="all_check_code" />
                    </th>
                        <th scope="col">Activity</th> 
                        <th scope="col" style={{ width: "30%" }} >Site Name</th>
                        <th scope="col" style={{ width: "20%" }} >Activity Code</th>
                        <th scope="col" style={{ width: "12%" }} >Status</th>
                        <th scope="col" style={{ width: "10%" }} >Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.activity_loading ? (
                        <tr className="text-center">
                            <td colSpan={4}>
                                <Loader />
                            </td>
                        </tr>
                    ) : this.state.activity_code_list && this.state.activity_code_list.length > 0 ? (
                        this.state.activity_code_list.map((item, index) => 
                              {return (<tr key={`${index}`}>
                            <td className='text-center'>
                                <input
                                    type='checkbox'
                                    className="activity_id_checkbox"
                                    value={item.id}
                                    data-siteId={item.site_id}
                                />
                            </td>
                                        <td>{item.activity_name}</td>
                                        <td>{item.site_name ? item.site_name : ""}</td>
                                        <td>{item.code  ? item.code :  ""}</td>
                                        <td>{item.status_data  ? <Status color={item.status_data.status_color_code}>{item.status_data.status_text}</Status> :  ""}</td>
                                        <td>
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#"
                                                role="button" aria-expanded="false" />
                                            <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                                <li className="dropdown-item" onClick={() => this.addActivityCodeModalInit(item)}>Edit</li>
                                                {/* <li className="dropdown-item">Delete</li> */}
                                            </ul>
                                        </td>

                                    </tr>)}
                        )
                            
                    ) : (
                        <tr className="text-center">
                            <td colSpan={4}>No Records</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="mb-3">
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.getActivityCodeList(e.selected + 1)}
            />
            </div>
            {this.addActivityFormModalJsx()}
            {this.activitySearchModalJsx()}
            {this.updateActivityCodeModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        iam_user_sites          :        state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(ActivityCodeList);