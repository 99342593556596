import React from 'react';
import { connect } from 'react-redux';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import {toast} from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import {Collapse, Modal} from "bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Helmet } from 'react-helmet';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import AssetHeader from '../includes/AssetHeader';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import TaskSavedStep from '../counterlog/ShiftActivityModals/task/TaskSavedStep';
import AssetService from '../../../services/AssetService';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';

class AssetTask extends AppBaseComponent {
    
    constructor() {
        super();

        this.shift_task_detail_url          =   IAM_API_BASE_URL_2 + '/counterlog/activity_task/detail';
        this.task_edit_form_data_url        =   IAM_API_BASE_URL_2 + '/counterlog/activity_task_step_form_data';
        this.task_edit_url                  =   IAM_API_BASE_URL_2 + '/counterlog/activity_task_step';
        this.activity_task_list_url         =   IAM_API_BASE_URL_2 + '/counterlog/activity_task_list';
        this.activity_task_formdata         =   IAM_API_BASE_URL_2 + '/counterlog/activity_task_list_form_data';

        this.taskSearchInitState         =   {
            'search_like_transaction_id'    :   '',
            'search_activity_ids'           :    [],
            'site_group_ids'                :    [],
            'search_site_ids'               :   [],
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
        };

        this.taskSearchFormLabel         =   {
            'site_group_ids'                 :   'Site Groups : ',
            'search_activity_ids'            :   'Activity : ',
            'search_site_ids'                :   'Site : ',
            'search_like_transaction_id'     :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
        };

        this.taskSaveFormInit       =   {
            start_cl_date_time          :   new Date(),
            cl_date_time                :   '',
            end_cl_date_time            :   '',
            operator_ids                :   [],
            loading                     :   false,
            activity_id                 :   '',
            counterlog_transaction_id   :   '',
            step_id                     :   '',
            task_start_at               :   new Date(),
            additional_attributes       :   null,
            production                  :   '',
            production_additional_attributes : null,
            task_close_at               :   '',
            production_unit             :   ''
        };

        this.initState = {
            edit_loading_form_data: false,
            activities: [],
            one_task_in_progress            :   'N',
            task_start_initialized: false,
            selected_activity_id            :   '',
            selected_activity               :   null,
            taskProceeding                  :   false,
            taskFormFieldData               :   null,
            higherlevel_completed_steps            :   [],
            editMode                        :   'N',
           
            asset                            :  null,
            counterlog                          :   null,
            shift                               :   null,
            site                              :     null,

            new_workstation_from            :   false,
            new_workstation_to              :   false,
            new_workstation                 :   false,
            new_material                    :   false,
            new_attachement                 :   false,
            taskSaveForm                    :   {...this.taskSaveFormInit},
            searchedEmployee                :  [],
            addNewUnit                      :   'N',
            addNewWorkstation           :   'N',
            addNewWorkstationFrom       :   'N',
            addNewWorkstationTo         :   'N',
            taskEdited : false,
            copy_latest_reading_start       :   false,
            copy_latest_reading             :   false,
            copy_latest_reading_end         :   false,
            edit_current_step            :          null,
            edit_form_fields             :          [],
            edit_form_data               :         null,
            editstepData                :           null,
            edit_completed_steps         :          [],
            edit_selected_activity       :        null,


        };

        this.state                      =  {
            assetCardDetails            :         [],
            task_listing             :         [],
            taskData                :         [],
            listingMeta                 :         null,
            listing_loading             :         false,
            listing_tbl_page            :         1,
            card_loading                :         false,
            totalListingCount           :         0,
            view_loading                :         false,
            taskSearchParams        :          null,
            iam_asset_types             :          [],
            iam_group_sites             :          [],
            all_periods                 :          [],
             taskSearchForm             :          {...this.taskSearchInitState},
            formSearchedElems           :          [],
            taskSearchParams        :          null,
            file_downloading            :          false,
            allTransactionDelay         :          [],
            searchedAssets              :           [],
            searchedEmployees           :           [],
            allSiteGroupsList           :           [],
            minimizeTable               :           false,
            transactionId               :           '',
            searchedAssets              :           [],
            searchedEmployees           :           [],
            allActivitesList            :           [],
            allSiteGroupsList           :           [],
            counterlog_transaction_id    :          '',
            form_fields                 :           [],
            form_data                   :           null,
            task_start_initialized      :           false,
            completed_steps             :           [],
            taskData                    :           null,
            editMode                    :           'N',
            loading_form_data           :           false,
            counterlog_transaction_id   :           '',
            actionPerformed             :           false,
            loading                     :           false,
            ...this.initState       
        }
    }

    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props);
        this.searchFormDataInit(this.props);
        this.taskSearchModal         =    new Modal(document.getElementById('taskSearchModal'), {keyboard: false, backdrop :false});
        this.taskEditModal         =    new Modal(document.getElementById('taskEditModal'), {keyboard: false, backdrop :false});
     }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        this.setState({
                assetId                 :       pr.match?.params?.id,
                // all_warehouses          :        pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                // all_periods             :        all_periods ,
                // isc_configuration       :        pr.isc_configuration
            },() => {
                    
            this.getAsssetDetails(pr.match?.params?.id)
            this.loadListingTblData(1,pr.match?.params?.id)
             this.searchFormDataInit(pr)
        })
    }
    

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data,assetCardDetails : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    loadListingTblData                 =   (page = 1,id)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,need_asset_profile : 'N',need_site:'Y',search_asset_id:id? id : this.state.assetId};
        HttpAPICall.withAthorization('GET',  this.activity_task_list_url, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
       
               let respData = resp.data;
            this.setState({
                 task_listing              :           respData.data,
                listingMeta                :           respData.meta,
                totalListingCount          :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }
        

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
        if(this.state.actionPerformed){
            this.loadListingTblData(1);
        }
    }
   
    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    viewTaskDetail =  (id,asset_id = null,counterlog_transaction_id) => {
        this.setState({ minimizeTable: true, transactionId: id,counterlog_transaction_id : counterlog_transaction_id })
          
        this.progressTaskInitialize(id,counterlog_transaction_id);
    }

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
        if(this.state.actionPerformed){
            this.loadListingTblData(this.state.listing_tbl_page)
        }
    }

    progressTaskInitialize              =  (task_transaction_id = null,counterlog_transaction_id= null ) => {
        
        this.setState({ loading_form_data: true, one_task_in_progress: "Y", heading: 'Update Task',...this.initState });
        let url         =   this.shift_task_detail_url;
        let params      =   { counterlog_transaction_id: counterlog_transaction_id };
        if(task_transaction_id) {
            params      =   { transaction_id: task_transaction_id };
        }
        HttpAPICall.withAthorization('GET', url, this.props.access_token, params, null, (response) => {
            let respData            =   response.data;
            let current_step        =   respData.next_step;
            let stepData            =   (current_step && respData.steps) ? respData.steps.find(s => s.id == current_step.id) : null;
            let form_fields         =   stepData ? stepData.fields : [];
            let taskData            =   respData.taskData;
           
            this.setState({
                current_step            :   current_step && current_step.can_submit == 'Y' ? current_step : null,
                form_fields             :   current_step && current_step.can_submit == 'Y'? form_fields : [],
                form_data               :   stepData ? stepData.form_data : null,
                task_start_initialized  :   true,
                completed_steps         :   current_step ? respData.steps.filter(s => s.level < current_step.level) : respData.steps,
                taskData                :   taskData,
                editMode                 :   'N',
            });
           
        }).then(() => this.setState({ loading_form_data: false, }));
    }

    taskSearchModalInit        =       ()      =>      {
        this.taskSearchModal.show()
    }

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        HttpAPICall.withAthorization('GET', this.activity_task_formdata, this.props.access_token, {})
            .then((response) => {
               let respData = response.data
              
              
                this.setState({
                    iam_asset_types         :       props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                    iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                    all_periods             :       all_periods,
                    allActivitesList        :       respData && respData.activities ? respData.activities.map((td) => { return {value: td.id, label: td.name}}) : [],
                    allSiteGroupsList       :       props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    },() => {
                    
                        this.loadListingTblData(1);
                    
                })
                   
            }) 
    }

    taskSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems           :   [],
            taskSearchParams    :   null,
            searchedAssets              :   [],
            taskSearchForm      :   {...this.taskSearchInitState}
        }, () => {
            this.taskSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    taskSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.taskSearchForm);
        let searchedElems               =   [];
        let taskSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.taskSearchFormLabel[key];
            let value                       =   this.state.taskSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                taskSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activity_ids') {
                        show_val            =  this.state.allActivitesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_like_transaction_id') {
                        show_val            =  this.state.taskSearchForm && this.state.taskSearchForm.search_like_transaction_id ? this.state.taskSearchForm.search_like_transaction_id : '';
                    }
                    
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.taskSearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.taskSearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            taskSearchParams      :       taskSearchParams
        }, () => {
            this.taskSearchModal.hide();
            this.loadListingTblData(1)
        });
    }
    editTaskForm              =  (step,transaction_id) => {
        this.taskEditModal.show();
        this.setState({...this.initState},() =>{
         this.fetchShiftDetail()
         this.setState({ edit_loading_form_data: true, one_task_in_progress: "Y", heading: 'Update Task', });
 
         let url         =   this.task_edit_form_data_url;
         let params      =   { transaction_id: transaction_id,step_id:step && step.id ? step.id : '' };
        
          HttpAPICall.withAthorization('GET', url, this.props.access_token, params, null, (response) => {
             let respData            =   response.data;
           
             let current_step        =   respData.step;
             let stepData            =   respData.step;
             let form_fields         =   stepData ? stepData.fields : [];
             let stepDetail          =   current_step ? this.state.completed_steps.find(s => s.level == current_step.level) : null;
             let detail              =  stepDetail && stepDetail.detail ? stepDetail.detail : null;
           
             this.setState({
               
                     edit_current_step            :   current_step,
                     edit_form_fields             :   form_fields,
                     edit_form_data               :   stepData ? stepData.form_data : null,
                     editstepData                :   stepData && stepData.detail ? stepData.detail : null,
                     task_start_initialized      :   true,
                     editMode                    :   'Y',
                     edit_completed_steps         :   current_step ? this.state.completed_steps.filter(s => s.level < current_step.level) : this.state.completed_steps,
                     selected_activity           :   this.state.taskData && this.state.taskData.activity ? {...this.state.taskData.activity,name:this.state.taskData.activity.activity_name} : null
             },() => {
                
                 let stepDetail          =   current_step && this.state.completed_steps && this.state.completed_steps.length > 0 ? this.state.completed_steps.find(s => s.level== current_step.level) : null;
                 let detail              =  stepDetail && stepDetail.detail ? stepDetail.detail : null;
             
                 if(detail){
                     let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.assetCardDetails);
                     let meterReading                =   {};
                     let counterlogMeterReading      =   {};
                     let closingReading              =   {};
                     assetMeters.forEach((meter, mk) => {
                         let input_state_key       =     AssetService.getTaskStartingReadingInputKey(meter.key);
                         meterReading[input_state_key]  = detail[input_state_key];
                     });
         
                     assetMeters.forEach((meter, mk) => {
                         let input_state_key       =     AssetService.getTaskCounterLogReadingInputKey(meter.key);
                         counterlogMeterReading[input_state_key]  = detail[input_state_key];
                     });
         
                     assetMeters.forEach((meter, mk) => {
                         let input_state_key       =     AssetService.getTaskClosingReadingInputKey(meter.key);
                         closingReading[input_state_key]  = detail[input_state_key];
                     });
         
                     let additional_attribute_data = [];
                         if (this.state.taskData && this.state.taskData.additional_attributes) {
                             Object.keys(this.state.taskData.additional_attributes).map((k) => {
                                 
                                 additional_attribute_data[k] = this.state.taskData.additional_attributes[k]
                             });
                         }
                     let productivity_additional_attribute_data = [];
                     if (detail && detail.production_additional_attributes) {
                         Object.keys(detail.production_additional_attributes).map((k) => {
                             
                             productivity_additional_attribute_data[k] = detail.production_additional_attributes[k]
                         });
                     }
                   
                     this.setState({
                         taskSaveForm : {
                             ...this.state.taskSaveForm,
                             ...meterReading,
                             ...counterlogMeterReading,
                             ...closingReading,
                             activity_count      :       detail.activity_count ? detail.activity_count : '' ,
                             activity_type       :       detail.activity_type ? detail.activity_type : '',
                             closing_remarks     :      detail.closing_remarks ? detail.closing_remarks : '',
                             task_close_at       :      this.state.taskData && this.state.taskData.task_close_at ? this.state.taskData.task_close_at : '',
                             start_cl_date_time  :       detail.start_cl_date_time ? detail.start_cl_date_time : this.state.taskSaveForm.start_cl_date_time,
                             operator_ids        :       detail.operators && detail.operators.length > 0 ? detail.operators.map(op => op.enc_id) : [],
                             workstation_from    :       detail.workstation_from  ? detail.workstation_from : "",
                             workstation_to      :       detail.workstation_to  ? detail.workstation_to : "",
                             workstation_id      :     detail.workstation_id  ? detail.workstation_id : "",
                             production          :      detail.production ? detail.production : "",
                             production_unit     :      detail.production_unit ? detail.production_unit : "",
                             material_id         :      detail.material_id  ? detail.material_id : "",
                             attachment_id       :      detail.attachment_id ? detail.attachment_id : "",
                             cl_date_time        :      detail.cl_date_time  ? detail.cl_date_time : "",
                             loaded_weight       :      detail.loaded_weight ? detail.loaded_weight : "",
                             unloaded_weight     :      detail.unloaded_weight ? detail.unloaded_weight : "",
                             loaded_weight_unit  :    detail.loaded_weight_unit ? detail.loaded_weight_unit : "",
                             unloaded_weight_unit   :    detail.unloaded_weight_unit ? detail.unloaded_weight_unit : "",
                             productivity_remark    :    detail.productivity_remark ? detail.productivity_remark : "",
                             additional_attributes :    {...additional_attribute_data},
                             production_additional_attributes : {...productivity_additional_attribute_data},
                             end_cl_date_time    :       detail.end_cl_date_time ? detail.end_cl_date_time : "",
                             step_id             :       stepDetail && stepDetail.id ? stepDetail.id : ''
                         },
                         searchedEmployee        :       detail.operators && detail.operators.length > 0 ? detail.operators.map(op => {return({...op,value:op.enc_id,label : op.display_full_name})})  : [],
                         searchedWorkstationFrom   :       detail.workStationFrom ? {...detail.workStationFrom, value : detail.workStationFrom.id, label : (detail.workStationFrom.name)} : null,
                         searchedWorkstationTo     :       detail.workStationTo ? {...detail.workStationTo, value : detail.workStationTo.id, label : (detail.workStationTo.name)} : null,
                         searchedWorkstation       :       detail.workStation ? {...detail.workStation, value : detail.workStation.id, label : (detail.workStation.name)} : null,
                     })
                 }
             });
             
             
          
         }).then(() => this.setState({ edit_loading_form_data: false, }))
        })
        
         
     }
 
     fetchShiftDetail                    =   (params = null) => {
         
         this.setState({shiftDetailLoading : true});
         HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/shift_detail/' + this.state.counterlog_transaction_id, this.props.access_token, {...params}, {}, (resp) => {
              this.setState({
                 asset                       :   resp.data.asset,
                 counterlog                  :   resp.data.counterlog,
                 shift                       :   resp.data.shift,
                 site                        :   resp.data.counterlog && resp.data.counterlog.site ?  resp.data.counterlog.site : null,
                 shiftCounterLogDetail       :   resp.data,
             });
             
         }, () => {
             
         }).then(() => {
             this.setState({shiftDetailLoading : false});
         });
     }
 
     copyLatestToStartReadingHandler     =   ()  =>  {
         let {latest_counterlog}         =   {...this.state.edit_form_data};
         this.setState({copy_latest_reading_start : !this.state.copy_latest_reading_start}, () => {
             if(this.state.copy_latest_reading_start) {
                 let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.assetCardDetails);
                 let copyReading                =   {};
                 assetMeters.forEach((meter, mk) => {
                     let input_state_key       =     AssetService.getTaskStartingReadingInputKey(meter.key);
                     // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]};
 
                     copyReading[input_state_key]  = latest_counterlog[meter.abs_key];
                 });
                 this.setState({
                     taskSaveForm        :   {...this.state.taskSaveForm, ...copyReading}
                 });
             }
         });
     }
 
     copyLatestToCounterlogReadingHandler=   ()  =>  {
         let {latest_counterlog}         =   {...this.state.edit_form_data};
         this.setState({copy_latest_reading : !this.state.copy_latest_reading}, () => {
             if(this.state.copy_latest_reading) {
                 let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.asset);
                 let copyReading                =   {};
                 assetMeters.forEach((meter, mk) => {
                     let input_state_key       =     AssetService.getTaskCounterLogReadingInputKey(meter.key);
                     copyReading[input_state_key]  = latest_counterlog[meter.abs_key];
                 });
                 copyReading['cl_date_time']  = new Date(latest_counterlog.feed_datetime);
                 this.setState({
                     taskSaveForm        :   {...this.state.taskSaveForm, ...copyReading}
                 });
             }
         });
     }
 
     copyLatestToClosingReadingHandler=   ()  =>  {
         let {latest_counterlog}         =   {...this.state.edit_form_data};
         this.setState({copy_latest_reading_end : !this.state.copy_latest_reading_end}, () => {
             if(this.state.copy_latest_reading_end) {
                 let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.asset);
                 let copyReading                =   {};
                 assetMeters.forEach((meter, mk) => {
                     let input_state_key       =     AssetService.getTaskClosingReadingInputKey(meter.key);
                     copyReading[input_state_key]  = latest_counterlog[meter.abs_key];
                 });
                 copyReading['end_cl_date_time']  = new Date(latest_counterlog.feed_datetime);
                 this.setState({
                     taskSaveForm        :   {...this.state.taskSaveForm, ...copyReading}
                 });
             }
         });
     }
 
     taskEditFormSubmit         =        (e)     =>      {
         e && e.preventDefault();
 
         let taskSaveFormData            =   {...this.state.taskSaveForm, step_id : this.state.edit_current_step ? this.state.edit_current_step.id : ''};
         let taskEditFormData            =   {
             ...taskSaveFormData, 
             transaction_id              :   this.state?.taskData?.transaction_id ,
             counterlog_transaction_id   : this.state.counterlog.transaction_id,
             cl_date_time                :   moment(this.state.taskSaveForm.cl_date_time).format('YYYY-MM-DD HH:mm:ss'),
             end_cl_date_time            :   moment(this.state.taskSaveForm.end_cl_date_time).format('YYYY-MM-DD HH:mm:ss'),
             task_close_at                :   moment(this.state.taskSaveForm.task_close_at).format('YYYY-MM-DD HH:mm:ss'),
             task_start_at               :   moment(this.state.taskSaveForm.task_start_at).format('YYYY-MM-DD HH:mm:ss'),
             start_cl_date_time          :   moment(this.state.taskSaveForm.start_cl_date_time).format('YYYY-MM-DD HH:mm:ss'),
         };
        
         this.formInputHandler(true,'loading','taskSaveForm');
         this.setState({loading : true})
         HttpAPICall.withAthorization('PUT', this.task_edit_url, this.props.access_token, null, taskEditFormData, (response) => {
             let respData = response.data;
             toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
             this.taskEditModal.hide();
           
             this.viewTaskDetail(this.state?.taskData?.transaction_id,this.state.asset.asset_id,this.state.counterlog_transaction_id)
             setTimeout(() =>  this.setState({actionPerformed : true,...this.initState}),2000)
         }).then(() => {
             setTimeout(() => this.formInputHandler(false,'loading','taskSaveForm'), 1000);
             this.setState({loading : false})
         });
     }
 
     calulateProductivityFormula         =   (linkedFormula)  =>  {
         let {production_additional_attributes} =   {...this.state.edit_form_data};
         if(production_additional_attributes && production_additional_attributes.length> 0) {
             let list = production_additional_attributes.filter(key => key.type == "numeric"  ? true : false);
             list.forEach((paa, k) => {
                 window[paa.key] = this.state.taskSaveForm && this.state.taskSaveForm.production_additional_attributes && this.state.taskSaveForm.production_additional_attributes[paa.key] 
                                     ? parseFloat(this.state.taskSaveForm.production_additional_attributes[paa.key]) : '';
             });
             try {
                 let net_productivity = linkedFormula && linkedFormula.formula ?  eval(linkedFormula.formula) : 0;
                 this.setState({
                     taskSaveForm     :       {
                         ...this.state.taskSaveForm,
                         production                :       net_productivity ? parseFloat(net_productivity).toFixed(2) : this.state.taskSaveForm.production
                     }
                 })
             } catch(e) {
                 this.setState({
                     taskSaveForm     :       {
                         ...this.state.taskSaveForm,
                         production                :       0
                     }
                 })
                 
             }
         }
     }

    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader  assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props} />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div> 
            <div id="assetCard" className="collapse pr-1 mt-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />}
        </div>
        </div>)
    }

    taskListJsx            =       ()      =>    {

        return (<Ax>
            <div style={{ paddingTop: "4px" }}>
                <div className="page_title row m0" style={{ height: "45px" }}>
                    <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                        <h3 style={{ paddingTop: "13px" }}>Task List</h3>
                    </div>
                    {!this.state.minimizeTable
                        ? <div className='col-sm-2 '>
                            <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                        </div> : null}
                    {!this.state.minimizeTable
                        ? <div className='col-sm-4 text-end mt7 '>
                           <button type="button" disabled={this.state.listing_loading} onClick={this.taskSearchModalInit} className="btn btn-secondary" >
                                <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                           {/*   <button type="button" className="btn btn-secondary"
                                disabled={this.state.reportDownloading || this.state.listing_loading}
                                onClick={this.consumptionDownloadHandler} >
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button> */}
                        </div> : null}
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <div className="col-sm-3 tap_border_left">
                                        <h3 className="text-capitalize" style={{ paddingTop: "12px" }}>Task : {this.state.transactionId}</h3>
                                    </div>
                                    <div className='col-sm-2 '>
                                        <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                    </div>
                                    <div className='col-sm-4 text-end mt7'>

                                        <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </Ax>
                            )
                            : null
                    }
                </div>
            </div>
            <div className="container-fluid pl0 pr13">
                <div className="page_containt row">
                    <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.taskListingTable()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0 pageTbl pb-5"><div className=" bg-white p-2" >{this.state.view_loading ? <Loader /> : this.taskViewJsx()}</div> </div> : null}
                </div>
            </div>
        </Ax>);
    }


    taskListingTable                   =   ()  =>  {
      
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.executeSearchClear} />
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>

                    {/* <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.taskSearchClear} /> */}
                    <table className="table table-bordered bg-white table-hover table-fixed table-sm">
                        <thead className="table-secondary">
                            {this.state.minimizeTable
                                ? (<tr>
                                    <th scope="col" style={{ width: "20%" }}>Task</th>
                                </tr>)
                                : (<tr>
                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                    <th scope="col" style={{ width: "12%" }}>Transaction Date</th>
                                    <th scope="col" style={{ width: "12%" }}>Task ID</th>
                                    <th scope="col" style={{ width: "14%" }}>Activity</th>
                                    <th scope="col" style={{ width: "14%" }}>Site</th>
                                    <th scope="col" style={{ width: "14%" }}>Productivity</th>
                                    <th scope="col" style={{ width: "12%" }}>Next Step</th> 
                                    <th scope="col" style={{ width: "10%" }}>Status</th>
                                    <th scope="col" style={{ width: "5%" }}>Action</th>
                                </tr>)
                            }

                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ? (<tr><td colSpan="8"><Loader /></td></tr>)
                                : (this.state.task_listing.length > 0
                                    ? (this.state.task_listing.map((data, index) => {
                                        return (<tr key={index} >
                                            {
                                                this.state.minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td style={{ width: "25%" }} onClick={() => this.viewTaskDetail(data.transaction_id, data.asset_id, data.counterlog_transaction_id)} >
                                                                <div>
                                                                    <span className="text-capitalize link-primary cursor_pointer"> {data.transaction_id ? data.transaction_id : "-"}</span>
                                                                    <small className="float-end">{data.status ? data.status : "-"}</small>
                                                                </div>
                                                                <div className="mt-1">
                                                                    <small className="text-capitalize ">{data.activity_detail ? data.activity_detail.name : "-"} </small>
                                                                    <small className="float-end">{data.productivity && data.productivity.production ? <span>{data.productivity.production} {data.productivity.production_unit ? <span className='text-sm'>({data.productivity.production_unit})</span> : "-"} </span> : ""}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    : (<Ax>
                                                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                        <td>{data.task_date_display ? data.task_date_display : "-"} </td>
                                                        <td onClick={() => this.viewTaskDetail(data.transaction_id, data.asset_id,data.counterlog_transaction_id)} className="link-primary  cursor_pointer">{data.transaction_id ? data.transaction_id : "-"} </td>
                                                        <td>{data.activity_detail ? data.activity_detail.name : "-"} </td>
                                                        <td>{data.site && data.site.site_name ? data.site.site_name : "-"}</td>
                                                        <td>{data.productivity && data.productivity.production ? <span>{data.productivity.production} {data.productivity.production_unit ? <span className='text-sm'>({data.productivity.production_unit})</span> : "-"} </span> : "-"}</td>
                                                        <td>{data.nextStep && data.nextStep.name ? data.nextStep.name : "-"}</td>
                                                        <td>{data.status ? data.status : "-"} </td>

                                                        <td className="text-center">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li onClick={() => this.viewTaskDetail(data.transaction_id, data.asset_id,data.counterlog_transaction_id)} className="link-primary  cursor_pointer"><a className="dropdown-item" role="button">View</a></li>

                                                            </ul>
                                                        </td>
                                                    </Ax>
                                                    )}
                                        </tr>)
                                    }))
                                    : (<tr><td colSpan="8" className="text-center">No Records</td></tr>)
                                )
                            }


                        </tbody>

                    </table>
                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1, this.state.assetId)}
                    />
                </div>
            </Ax>
        );
    }

    taskViewJsx                      =       ()             =>        {
        
        return (<div>
            <div className="row">
                <div className="col-12 pr-1 mb-5">
                {this.state.loading_form_data ? <div className='text-center'><Loader /></div>
                        : <TaskSavedStep
                            completed_steps={this.state.completed_steps}
                            taskData={this.state.taskData}
                            asset={this.state.assetCardDetails}
                            editMode={this.state.editMode}
                            onModalRefresh={() => {this.progressTaskInitialize(this.state.transactionId,this.state.counterlog_transaction_id);this.setState({actionPerformed : true})}}
                            onRequestClose={() => {this.closeMinimizeTable();this.loadListingTblData(this.state.listing_tbl_page)}}
                            showCard={true}
                            onEditTaskForm={(step, transaction_id) => { this.editTaskForm(step, transaction_id) }}
                        />}
                </div>

            </div>
        </div>)
    }

    taskSearchModalJsx         =   ()  =>  {
        return (
            <div className="modal fade" id="taskSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="taskSearchModalLabel">Task Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.taskSearchFormSubmit} id="taskSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_like_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.taskSearchForm.search_like_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "taskSearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Activity Name</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allActivitesList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'taskSearchForm', 'search_activity_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allActivitesList.filter(s => this.state.taskSearchForm.search_activity_ids.includes(s.value))}
                                            placeholder="Select Activity"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                               
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Group</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allSiteGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    taskSearchForm: {
                                                        ...this.state.taskSearchForm,
                                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allSiteGroupsList.filter(s => this.state.taskSearchForm.site_group_ids.includes(s.value))}
                                            placeholder="Select Site Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Executed Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'taskSearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.taskSearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Executed Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                              

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.taskSearchForm.date_range}
                                            startDate={this.state.taskSearchForm.date_range_start}
                                            endDate={this.state.taskSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    taskSearchForm
                                                        : {
                                                        ...this.state.taskSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    taskEditModalJsx         =   ()  =>  {
        let taskData                    =   this.state.taskData;
        let asset                       =   this.state.assetCardDetails;
        let { activity }                =   { ...this.state.taskData };
        let { edit_form_fields,selected_activity } = { ...this.state };
        let form_field_keys = edit_form_fields && edit_form_fields.length > 0 ?  edit_form_fields.map(ff => ff.field_key) : [];
        let additionalAttributeInputs   =   edit_form_fields.filter(ff => ff.is_additional_attribute == 'Y');
       
        return (
            <div className="modal fade" id="taskEditModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="taskSearchModalLabel">Task Update : {this.state.transactionId ? this.state.transactionId : ""}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.taskEditFormSubmit} id="taskEditForm">
                            <div className="modal-body">
                                {this.state.edit_loading_form_data ?
                                    <div className='text-center'> <Loader /></div>
                                    : <div>
                                        {edit_form_fields && edit_form_fields.length > 0
                                            ? <div>
                                                <div>
                                                    {this.state.edit_current_step && this.state.edit_current_step.level == 1 &&
                                                        <div className="row ">

                                                            <div className='col-sm-3'>
                                                                <div className='fw-bold require col-form-label-sm'>Activity Name
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-7'>
                                                                <input
                                                                    // name="selected_activity"
                                                                    type="text"
                                                                    value={selected_activity.name}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    disabled={true}
                                                                    placeholder="Please enter Activity"
                                                                />

                                                            </div ></div>}
                                                    <div className="tab_content_wrapper fw-bold my-2">  {this.state.edit_current_step && this.state.edit_current_step.name ? this.state.edit_current_step.name : ''}</div>
                                                    {form_field_keys.map((form_field_key, k) => {
                                                        return <Ax key={k}>
                                                            {form_field_key == 'starting_readings' && this.startingReadingsJsx()}
                                                            {form_field_key == 'task_start_time' && !form_field_keys.includes('starting_readings') && this.taskStartingTimeJsx()}
                                                            {form_field_key == 'operator' && this.operatorJsx()}
                                                            {form_field_key == 'productivity' && this.productivityJsx()}
                                                            {form_field_key == 'attachment' && this.attachmentJsx()}
                                                            {form_field_key == 'material' && this.materialJsx()}
                                                            {form_field_key == 'workstation_from' && this.workstationFromJsx()}
                                                            {form_field_key == 'workstation_to' && this.workstationToJsx()}
                                                            {form_field_key == 'workstation' && this.workstationJsx()}
                                                            {form_field_key == 'loaded_weight' && this.loadedWeightJsx()}
                                                            {form_field_key == 'unloaded_weight' && this.unloadedWeightJsx()}
                                                            {form_field_key == 'productivity_remark' && this.productivityRemarkJsx()}
                                                            {form_field_key == 'counterlog_readings' && this.counterlogReadingsJsx()}
                                                           
                                                            {form_field_key == 'activity_count' && this.activityCountJsx()}
                                                            {form_field_key == 'closing_readings' && this.closingReadingsJsx()}
                                                            {form_field_key == 'task_complete_time' && !form_field_keys.includes('closing_readings') && this.taskCompleteTimeJsx()}
                                                            {form_field_key == 'closing_remarks' && this.closingRemarkJsx()}
                                                       </Ax>
                                                    })}

                                                    {additionalAttributeInputs && additionalAttributeInputs.length > 0 ?

                                                        additionalAttributeInputs.map((i, k) => {
                                                            let additional_attribute_detail = i.additional_attribute;
                                                            let name = additional_attribute_detail.name;
                                                            let input = null;
                                                            if (i.additional_attribute.type == 'text') {
                                                                input = <input
                                                                    name={name}
                                                                    type="text"
                                                                    value={this.state.taskSaveForm.additional_attributes && this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key] ? this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key] : ''}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            taskSaveForm: { ...this.state.taskSaveForm, additional_attributes: { ...this.state.taskSaveForm.additional_attributes, [additional_attribute_detail.key]: e.target.value } }
                                                                        })

                                                                    }}

                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${additional_attribute_detail.name}`}
                                                                />
                                                            } else if (i.additional_attribute.type == 'numeric') {
                                                                input = <input
                                                                    name={name}
                                                                    type="number"
                                                                    id={i.formula_key}

                                                                    value={this.state.taskSaveForm.additional_attributes && this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key] ? this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key] : ''}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            taskSaveForm: { ...this.state.taskSaveForm, additional_attributes: { ...this.state.taskSaveForm.additional_attributes, [additional_attribute_detail.key]: e.target.value } }
                                                                        })

                                                                    }}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${additional_attribute_detail.name}`}
                                                                />
                                                            } else if (i.additional_attribute.type == 'date') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.taskSaveForm.additional_attributes && this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key]
                                                                            ? moment(this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.dynamicFormDateHandler(moment(value).format('YYYY-MM-DD'), additional_attribute_detail.key, 'additional_attributes', 'taskSaveForm')}

                                                                    dateFormat="dd-MMM-yyyy"
                                                                    className={"form-control form-control-sm"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${additional_attribute_detail.name}`}

                                                                />
                                                            } else if (i.additional_attribute.type == 'dropdown') {
                                                                let options = additional_attribute_detail.options.map((t) => { return { value: t, label: t } });
                                                                let value = '';
                                                                if (this.state.taskSaveForm && this.state.taskSaveForm.additional_attributes && this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key]) {
                                                                    value = this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key];
                                                                }

                                                                input = <TapSelect
                                                                    menuPlacement="top"
                                                                    changeEvent={(selectedOption) => this.dynamicInputHandlerByKeyVal(selectedOption.value, additional_attribute_detail.key, 'additional_attributes', 'taskSaveForm')}

                                                                    options={options}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    value={options.find(o => o.value == value)}
                                                                    placeholder={`Please Select ${additional_attribute_detail.name}`}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                />
                                                            } else if (i.additional_attribute.type == 'datetime') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.taskSaveForm.additional_attributes && this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key]
                                                                            ? moment(this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.dynamicInputHandlerByKeyVal(moment(value).format('YYYY-MM-DD HH:mm:ss'), additional_attribute_detail.key, 'additional_attributes', 'taskSaveForm')}

                                                                    dateFormat="dd-MMM-yyyy hh:mm a"
                                                                    className={"form-control form-control-sm"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    showTimeSelect
                                                                    timeIntervals={5}
                                                                    timeCaption="Time"
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${additional_attribute_detail.name}`}

                                                                />
                                                            }

                                                            return (<div key={k} className="row align-items-center my-2">
                                                                <div className="col-sm-3 col-form-label-sm col-form-label fw-bold text-capitalize">{additional_attribute_detail.name}</div>
                                                                <div className="col-sm-7">{input}</div>

                                                            </div>);
                                                        })

                                                        : null}
                                                </div>
                                            </div> : null}

                                    </div>}


                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.loading} data-bs-dismiss="modal" onClick={() => {this.setState({...this.initState})}}>Close  {this.state.loading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.loading}>Save  {this.state.loading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    startingReadingsJsx             =       ()      =>  {
        let {asset, site, shift, counterlog}        =   {...this.state};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {latest_counterlog}         =   {...this.state.edit_form_data};
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(shift){
            if(shift && shift.shift_start == shift.shift_end){
                max_time  = moment(shift.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(shift.shift_end, "hh:mm a").toDate()
            }
        }
        return(<div style={{border:"1px",borderStyle:"dashed",color:"#979C9F"}} className="my-2">
            <div className="row align-items-center my-2 p-2">
                <div className="col-sm-3" ><label className="form-label text-color">Starting Date & Time</label></div>
                <div className="col-md-7 ">
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.start_cl_date_time
                                ? moment(this.state.taskSaveForm.start_cl_date_time).toDate()
                                : false
                        }
                        name="start_cl_date_time"
                        onChange={(value, event) => { this.formDateHandler('start_cl_date_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Starting Date & Time`}
                        minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        minTime={moment(shift ? shift.shift_start : new Date(), "hh:mm a ").toDate()}
                        maxTime={max_time}
                    />
                </div>

            </div>
            {assetMeters && assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskStartingReadingInputKey(meter.key);
                return (<div className="row my-2 p-2 ">

                    <div className='col-sm-3'>
                        <div className='fw-bold col-form-label-sm text-color' >{meter.label}</div>
                    </div>
                    <div className='col-sm-7'>
                        <input
                            name={input_state_key}
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'taskSaveForm')}
                            placeholder="Please Enter Reading"
                            value={this.state.taskSaveForm[input_state_key]}

                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />
                    </div>
                    <div className='offset-3 col-sm-9'>
                        {latest_counterlog

                            ? <span className="text-sm form-text">
                               Latest Reading {latest_counterlog[meter.abs_key]} @ {latest_counterlog.feed_date_time_display}
                            </span>
                            : null}
                    </div>
                </div>)
            })}

            <div className='row mb-1'>
                <div className='offset-3 col-sm-9'>
                    <input
                        name="copy_latest_reading_start"
                        type="checkbox"
                        value={this.state.copy_latest_reading_start}
                        onChange={(e) =>  this.copyLatestToStartReadingHandler()}
                        checked={this.state.copy_latest_reading_start == true}
                        className="form-check-input"
                        id="copy_latest_reading_start"
                    />
                    <label className="form-check-label mx-2 text-color" htmlFor="copy_latest_reading_start">Copy Reading from Latest Counterlog </label>
                </div>
            </div>
                            
        </div>)
    }

    taskStartingTimeJsx         =       ()      =>      {
        let {asset, site, shift, counterlog}        =   {...this.state};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {latest_counterlog}         =   {...this.state.edit_form_data};
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(shift){
            if(shift && shift.shift_start == shift.shift_end){
                max_time  = moment(shift.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(shift.shift_end, "hh:mm a").toDate()
            }
        }
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Task Start Time</div>
            </div>
            <div className='col-sm-7'>
                <DatePicker
                    selected={
                        this.state.taskSaveForm.task_start_at
                            ? moment(this.state.taskSaveForm.task_start_at).toDate()
                            : false
                    }
                    name="task_start_at"
                    onChange={(value, event) => { this.formDateHandler('task_start_at', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                    dateFormat="dd-MMM-yyyy hh:mm a"
                    className={"form-control form-control-sm"}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    timeIntervals={5}
                    timeCaption="Time"
                    autoComplete="off"
                    scrollMonthYearDropdown
                    required={true}
                    placeholderText={`Please Enter Starting Date & Time`}
                    minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                    maxDate={moment(this.state.counterlog && this.state.counterlog.feed_date ? new Date(this.state.counterlog.feed_date) : new Date(), 'YYYY-MM-DD').toDate()}
                    minTime={moment(shift ? shift.shift_start : new Date(), "hh:mm a ").toDate()}
                    maxTime={max_time}
                />

            </div>

        </div>)
    }

    operatorJsx         =       ()      =>      {
       
        return (<div className="row ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Operator
                </div>
            </div>
            <div className='col-sm-7'>
                <InputEmployeeSearch
                    placeholder="Please Select Employee"
                    changeEvent={(selectedOption) => {
                        this.setState({ 
                            searchedEmployee: selectedOption && selectedOption.length > 0 ? selectedOption : [] 
                        });
                        this.tapSelectChange(selectedOption, 'taskSaveForm', 'operator_ids')
                    }}
                    
                    menuPlacement="top"
                    transaction_date={this.state.taskData && this.state.taskData.task_date ? this.state.taskData.task_date : this.state.counterlog && this.state.counterlog.feed_datetime ? this.state.counterlog.feed_datetime : null }
                    search_site_ids={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                    containerHeight="30px"
                    fontSize="93%"
                    isMulti={true}
                    value={this.state.searchedEmployee && this.state.searchedEmployee.length > 0 ? this.state.searchedEmployee.map(a => { a.label = a.label; return a; }) : []}
                />

            </div>

        </div>)
    }

    productivityJsx     =       ()      =>      {
        let {production_additional_attributes, apply_prodution_formula, production_formula} =   {...this.state.edit_form_data};
        let production_units            =       this.state.edit_form_data && this.state.edit_form_data.production_units && this.state.edit_form_data.production_units.length > 0 ? this.state.edit_form_data.production_units.map(at => {return({value:at.measuring_unit,label:at.measuring_unit})})  : []
        let formulaApplicable       =   apply_prodution_formula == 'Y' ? 'Y' : 'N';
        let linkedFormula           =   production_formula && JSON.stringify(production_formula) !== JSON.stringify({}) 
                                            ? production_formula : null;

        let taskFormPaaState        =   this.state.taskSaveForm.production_additional_attributes;

        let linkedFormulaHavingMeter =  linkedFormula && linkedFormula.formula && (linkedFormula.formula.includes('running_hrm') || linkedFormula.formula.includes('running_km') || linkedFormula.formula.includes('running_opc')) ? true : false;

        let formula                 =   linkedFormula && linkedFormula.formula ? linkedFormula.formula : null;

        if(linkedFormula && linkedFormula.unit_name && (!this.state.taskSaveForm.production_unit  || linkedFormula.unit_name != this.state.taskSaveForm.production_unit)) {
            // this.formInputHandler(linkedFormula.unit_name, 'production_unit', 'taskSaveForm');
            this.setState({taskSaveForm :{...this.state.taskSaveForm,production_unit : linkedFormula.unit_name }})
            // setTimeout(() => this.formInputHandler(linkedFormula.unit_name, 'production_unit', 'taskSaveForm') ,500);
        }

        production_additional_attributes && production_additional_attributes.forEach(production => {
            if (formula && formula.includes(production.formula_key)) {
                formula = formula.replace(production.formula_key, production.name)
            }
        });
        return (<div>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm require'>Net Productivity</div>
                </div>
                <div className='col-sm-7'>
                    <input
                        name="production"
                        type="number"
                        autoFocus={true}
                        value={this.state.taskSaveForm.production}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Net Productivity"
                        required={true}
                        disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                    />

                </div>
                {linkedFormulaHavingMeter == true && formulaApplicable == 'Y' ?
                    <div className='text-danger text-sm offset-3 col-sm-7'>The formula contain total running (hours/km/opc) hence it cannot be calculated on this screen</div>
                    : null}
                <div className='text-sm offset-3 col-sm-7 form-text '>{linkedFormula && linkedFormula.formula ? <span>Net Productivity = {formula ? formula : "-"}</span> : ""}</div>

            </div>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm require'>Production Units</div>
                </div>
                <div className='col-sm-7'>
                    {this.state.addNewUnit == "Y"
                        ? <input
                            name="production_unit"
                            type="text"
                            value={this.state.taskSaveForm.production_unit}
                            className="form-control form-control-sm"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                            placeholder="Please enter Productivity Unit"
                            required={true}
                            disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                        />
                        :
                        <TapSelect
                            options={production_units}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'taskSaveForm', 'production_unit');

                            }}
                            isSearchable={true}
                            value={production_units.find(s => this.state.taskSaveForm.production_unit == s.value)}
                            isClearable={true}
                            placeholder="Select Productivity Unit"
                            autoCompelete="off"
                            required={true}
                            containerHeight="30px"
                            fontSize="93%"
                            menuPlacement="top"
                            isDisabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}

                        />
                    }
                </div>
                <div className="col-sm-2">
                    <input
                        name="addNewUnit"
                        type="checkbox"
                        value={this.state.addNewUnit}
                        onChange={(e) => {
                            this.setState({
                                addNewUnit: this.state.addNewUnit == 'N' ? 'Y' : 'N',
                                taskSaveForm: { ...this.state.taskSaveForm, unit: '' }
                            })
                        }}
                        checked={this.state.addNewUnit == 'Y'}
                        className="form-check-input"
                        id="addNewUnit"
                        disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                    //disabled={this.state.linkedProductivityFormula ? true : false}

                    />
                    <label className="form-check-label text-sm mx-2" htmlFor="addNewUnit">Add New </label>
                </div>

            </div>
            {
                production_additional_attributes && production_additional_attributes.length > 0
                    ? (
                        <Ax>

                            {
                                production_additional_attributes.map((i, k) => {
                                  
                                    let name = i.name;
                                    let input = null;
                                    if (i.type == 'text') {
                                        input = <input
                                            name={name}
                                            type="text"
                                            id={i.formula_key}
                                            value={this.state.taskSaveForm.production_additional_attributes && this.state.taskSaveForm.production_additional_attributes[i.key] ? this.state.taskSaveForm.production_additional_attributes[i.key] : '' }
                                            onChange={(e) => {
                                                this.setState({
                                                    taskSaveForm : {...this.state.taskSaveForm,production_additional_attributes : {...this.state.taskSaveForm.production_additional_attributes,[i.key] : e.target.value}}
                                                })
                                                
                                            }}
                                            
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder={`Please enter ${i.name}`}
                                        />
                                    } else if (i.type == 'numeric') {
                                        input = <input
                                            name={name}
                                            type="number"
                                            id={i.formula_key}
                                            value={this.state.taskSaveForm.production_additional_attributes && this.state.taskSaveForm.production_additional_attributes[i.key] ? this.state.taskSaveForm.production_additional_attributes[i.key] : '' }
                                            onChange={(e) => {
                                                this.setState({
                                                    taskSaveForm : {...this.state.taskSaveForm,production_additional_attributes : {...this.state.taskSaveForm.production_additional_attributes,[i.key] : e.target.value}}
                                                },() => this.calulateProductivityFormula(linkedFormula) )
                                                
                                            }}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder={`Please enter ${i.name}`}
                                        />
                                    } else if (i.type == 'date') {
                                        input = <DatePicker
                                          selected={
                                            this.state.taskSaveForm.production_additional_attributes && this.state.taskSaveForm.production_additional_attributes[i.key]
                                                    ? moment(this.state.taskSaveForm.production_additional_attributes[i.key]).toDate()
                                                    : false
                                            }
                                            name={name}
                                            onChange={(value, event) => this.dynamicFormDateHandler(moment(value).format('YYYY-MM-DD'), i.key, 'production_additional_attributes',  'taskSaveForm')}
                                            dateFormat="dd-MMM-yyyy"
                                            className={"form-control form-control-sm"}
                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete='off'
                                            scrollMonthYearDropdown
                                            placeholderText={`Please Enter ${i.name}`}

                                        />
                                    } else if (i.type == 'dropdown') {
                                        let options = i.options.map((t) => { return { value: t, label: t } });
                                        let data = this.state.taskSaveForm?.production_additional_attributes
                                        input = <TapSelect
                                            menuPlacement="top"
                                            changeEvent={(selectedOption) => this.dynamicInputHandlerByKeyVal(selectedOption.value, i.key, 'production_additional_attributes',  'taskSaveForm')}
                                            options={options}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={options.find(o => o.value ==  (data && data[i.key] ? data[i.key] : '') )}
                                            placeholder={`Please Select ${i.name}`}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                        
                                    }

                                    return (<div key={k} className="row align-items-center my-2">
                                        <div className="col-sm-3 col-form-label-sm col-form-label fw-bold">{i.name}</div>
                                        <div className="col-sm-7">{input}</div>

                                    </div>);
                                })
                            }
                        </Ax>
                    )
                    : null
            }
        </div>)
    }

    attachmentJsx            =       ()      =>      {
        let {attachments}                   =   {...this.state.edit_form_data};
        let attachemntList = attachments && attachments.length > 0 ? attachments.map(at => {return({value:at.id,label:at.name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Attachment</div>
                </div>
                <div className='col-sm-7'>
                    <TapSelect
                        options={attachemntList}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'taskSaveForm', 'attachment_id');
                        }}
                        isSearchable={true}
                        value={attachemntList.find(s => this.state.taskSaveForm.attachment_id == s.value)}
                        isClearable={true}
                        placeholder="Select Attchment"
                        autoCompelete="off"
                        menuPlacement={'top'}
                        containerHeight="30px"
                        fontSize="93%"
                    />

                </div>

            </div>
           
        </Ax>)
    }

    materialJsx            =       (formData=null)      =>      {
        let {materials}                     =   {...this.state.edit_form_data};
        let materialList = materials && materials.length > 0 ? materials.map(at => {return({value:at.id,label:at.material_name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Material</div>
                </div>
                <div className='col-sm-7'>
                    <TapSelect
                        options={materialList}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'taskSaveForm', 'material_id');
                        }}
                        isSearchable={true}
                        value={materialList.find(s => this.state.taskSaveForm.material_id == s.value)}
                        isClearable={true}
                        placeholder="Select Material"
                        autoCompelete="off"
                        menuPlacement={'top'}
                        containerHeight="30px"
                        fontSize="93%"
                    />

                </div>

            </div>
           
        </Ax>)
    }

    workstationFromJsx         =       ()      =>      {
        return (<div className="row my-1">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation From</div>
            </div>
            <div className='col-sm-7'>
                {this.state.addNewWorkstationFrom == "Y"
                    ? <input
                        name="workstation_from_name"
                        type="text"
                        value={this.state.taskSaveForm.workstation_from_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Workstation From"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstationFrom: selectedOption ? selectedOption : null,
                                taskSaveForm: { ...this.state.taskSaveForm, workstation_from: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstationFrom ? this.state.searchedWorkstationFrom : null}
                    />
                }
            </div>
            <div className="col-sm-2">
                <input
                    name="addNewWorkstationFrom"
                    type="checkbox"
                    value={this.state.addNewWorkstationFrom}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstationFrom: this.state.addNewWorkstationFrom == 'N' ? 'Y' : 'N',
                            taskSaveForm: { ...this.state.taskSaveForm, workstation_from_name: '', workstation_from: '' },
                            searchedWorkstationFrom: null
                        })
                    }}
                    checked={this.state.addNewWorkstationFrom == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstationFrom"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstationFrom">Add New </label>
            </div>


        </div>)
    }

    workstationToJsx         =       ()      =>      {
        return (<div className="row my-1 ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation To</div>
            </div>

            <div className='col-sm-7'>
                {this.state.addNewWorkstationTo == "Y"
                    ? <input
                        name="workstation_to_name"
                        type="text"
                        value={this.state.taskSaveForm.workstation_to_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Workstation to"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstationTo: selectedOption ? selectedOption : null,
                                taskSaveForm: { ...this.state.taskSaveForm, workstation_to: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstationTo ? this.state.searchedWorkstationTo : null}
                    />
                }
            </div>
            <div className="col-sm-2">
                <input
                    name="addNewWorkstationTo"
                    type="checkbox"
                    value={this.state.addNewWorkstationTo}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstationTo: this.state.addNewWorkstationTo == 'N' ? 'Y' : 'N',
                            taskSaveForm: { ...this.state.taskSaveForm, workstation_to_name: '', workstation_to: '' },
                            searchedWorkstationTo: null
                        })
                    }}
                    checked={this.state.addNewWorkstationTo == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstationTo"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstationTo">Add New </label>
            </div>

        </div>)
    }

    workstationJsx         =       ()      =>      {
        return (<div className="row my-1 ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation</div>
            </div>
            <div className='col-sm-7'>
                {this.state.addNewWorkstation == "Y"
                    ? <input
                        name="workstation_name"
                        type="text"
                        value={this.state.taskSaveForm.workstation_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Workstation"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstation: selectedOption ? selectedOption : null,
                                taskSaveForm: { ...this.state.taskSaveForm, workstation_id: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                    />
                }
            </div>
            <div className="col-sm-2">
                <input
                    name="addNewWorkstation"
                    type="checkbox"
                    value={this.state.addNewWorkstation}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstation: this.state.addNewWorkstation == 'N' ? 'Y' : 'N',
                            taskSaveForm: { ...this.state.taskSaveForm, workstation_name: '', workstation_id: '' },
                            searchedWorkstation: null
                        })
                    }}
                    checked={this.state.addNewWorkstation == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstation"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstation">Add New </label>
            </div>


        </div>)
    }

    loadedWeightJsx         =       ()      =>      {
        let {loaded_weight_units}           =   {...this.state.edit_form_data};
        let unitList = loaded_weight_units && loaded_weight_units.length > 0 ? loaded_weight_units.map(at => {return({value:at,label:at})})  : []
        if(unitList && unitList.length > 0 && (!this.state.taskSaveForm.loaded_weight_unit  ||  unitList[0].value != this.state.taskSaveForm.loaded_weight_unit)) {
            this.setState({taskSaveForm : {...this.state.taskSaveForm,loaded_weight_unit : unitList[0].value}});
        }
        return (<div className="row my-1">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Loaded Weight</div>
            </div>

            <div className='col-sm-4'>
                <input
                    name="loaded_weight"
                    type="text"
                    value={this.state.taskSaveForm.loaded_weight}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                    placeholder="Please enter Loaded Weight"
                />

            </div>
            <div className='col-sm-3'>
                <TapSelect
                    options={unitList}
                    changeEvent={(selectedOption) => {
                        this.tapSelectChange(selectedOption, 'taskSaveForm', 'loaded_weight_unit');
                    }}
                    isSearchable={true}
                    value={unitList.find(s => this.state.taskSaveForm.loaded_weight_unit == s.value)}
                    isClearable={true}
                    placeholder="Unit"
                    autoCompelete="off"
                    menuPlacement={'top'}
                    containerHeight="30px"
                    fontSize="93%"
                />
            </div>

        </div>)
    }

    unloadedWeightJsx         =       ()      =>      {
        let {unloaded_weight_units}           =   {...this.state.edit_form_data};
        let unitList = unloaded_weight_units && unloaded_weight_units.length > 0 ? unloaded_weight_units.map(at => {return({value:at,label:at})})  : []
        if(unitList && unitList.length > 0 && (!this.state.taskSaveForm.unloaded_weight_unit  ||  unitList[0].value != this.state.taskSaveForm.unloaded_weight_unit)) {
            this.setState({taskSaveForm : {...this.state.taskSaveForm,unloaded_weight_unit : unitList[0].value}});
        }
        return (<div className="row my-1">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Unloaded Weight</div>
            </div>

            <div className='col-sm-4'>
                <input
                    name="unloaded_weight"
                    type="text"
                    value={this.state.taskSaveForm.unloaded_weight}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                    placeholder="Please enter Unloaded Weight"
                />

            </div>
            <div className='col-sm-3'>
                <TapSelect
                    options={unitList}
                    changeEvent={(selectedOption) => {
                        this.tapSelectChange(selectedOption, 'taskSaveForm', 'unloaded_weight_unit');
                    }}
                    isSearchable={true}
                    value={unitList.find(s => this.state.taskSaveForm.unloaded_weight_unit == s.value)}
                    isClearable={true}
                    placeholder="Unit"
                    autoCompelete="off"
                    menuPlacement={'top'}
                    containerHeight="30px"
                    fontSize="93%"
                />
            </div>

        </div>)
    }

    productivityRemarkJsx       =       ()      =>      {
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Remark</div>
            </div>
            <div className='col-sm-7'>
                <textarea
                    name="productivity_remark"
                    value={this.state.taskSaveForm.productivity_remark}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                    placeholder="Remark"
                />

            </div>

        </div>)
    }

    counterlogReadingsJsx             =       ()      =>  {
        let {asset, site, shift, counterlog}        =   {...this.state};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {latest_counterlog}         =   {...this.state.edit_form_data};
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(shift){
            if(shift && shift.shift_start == shift.shift_end){
                max_time  = moment(shift.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(shift.shift_end, "hh:mm a").toDate()
            }
        }
        return(<div style={{border:"1px",borderStyle:"dashed",color:"#979C9F"}} className="my-2">
          <div className="row align-items-center my-2 p-2">
                <div className="col-sm-3"><label className="form-label text-color">CounterLog Date & Time</label></div>
                <div className="col-md-7 ">
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.cl_date_time
                                ? moment(this.state.taskSaveForm.cl_date_time).toDate()
                                : false
                        }
                        name="cl_date_time"
                        onChange={(value, event) => { this.formDateHandler('cl_date_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Counterlog Date & Time`}
                        minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        minTime={moment(shift ? shift.shift_start : new Date(), "hh:mm a ").toDate()}
                        maxTime={max_time}
                    />
                </div>

            </div>
            {assetMeters && assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskCounterLogReadingInputKey(meter.key);
                return (<div className="row my-2 p-2 ">

                    <div className='col-sm-3'>
                        <div className='fw-bold col-form-label-sm text-color'>{meter.label}</div>
                    </div>
                    <div className='col-sm-7'>
                        <input
                            name={input_state_key}
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'taskSaveForm')}
                            placeholder="Please Enter Reading"
                            value={this.state.taskSaveForm[input_state_key]}

                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />
                    </div>
                    
                </div>)
            })}
             <div className='row mb-1'>
                <div className='offset-3 col-sm-9'>
                    <input
                        name="copy_latest_reading"
                        type="checkbox"
                        value={this.state.copy_latest_reading}
                        onChange={(e) =>  this.copyLatestToCounterlogReadingHandler()}
                        checked={this.state.copy_latest_reading == true}
                        className="form-check-input"
                        id="copy_latest_reading"
                    />
                    <label className="form-check-label mx-2 text-color" htmlFor="copy_latest_reading">Copy Reading from Latest Counterlog </label>
                </div>
            </div>
        </div>)
    }

    closingReadingsJsx             =       ()      =>  {
        let {asset, site, shift, counterlog}        =   {...this.state};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {latest_counterlog}         =   {...this.state.edit_form_data};
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(shift){
            if(shift && shift.shift_start == shift.shift_end){
                max_time  = moment(shift.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(shift.shift_end, "hh:mm a").toDate()
            }
        }
        return(<div style={{border:"1px",borderStyle:"dashed",color:"#979C9F"}} className="my-2">
              <div className="row align-items-center my-2 p-2">
                <div className="col-sm-3"><label className="form-label text-color">Closing Date & Time</label></div>
                <div className="col-md-7 ">
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.end_cl_date_time
                                ? moment(this.state.taskSaveForm.end_cl_date_time).toDate()
                                : false
                        }
                        name="end_cl_date_time"
                        onChange={(value, event) => { this.formDateHandler('end_cl_date_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Starting Date & Time`}
                        minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        minTime={moment(shift ? shift.shift_start : new Date(), "hh:mm a ").toDate()}
                        maxTime={max_time}
                    />
                </div>

            </div>
            {assetMeters && assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskClosingReadingInputKey(meter.key);
                return (<div className="row my-2 p-2">

                    <div className='col-sm-3'>
                        <div className='fw-bold col-form-label-sm text-color'>{meter.label}</div>
                    </div>
                    <div className='col-sm-7'>
                        <input
                            name={input_state_key}
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'taskSaveForm')}
                            placeholder="Please Enter Reading"
                            value={this.state.taskSaveForm[input_state_key]}

                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />
                    </div>
                    
                </div>)
            })}
              <div className='row mb-1'>
                <div className='offset-3 col-sm-9'>
                    <input
                        name="copy_latest_reading_end"
                        type="checkbox"
                        value={this.state.copy_latest_reading_end}
                        onChange={(e) =>  this.copyLatestToClosingReadingHandler()}
                        checked={this.state.copy_latest_reading_end == true}
                        className="form-check-input"
                        id="copy_latest_reading_end"
                    />
                    <label className="form-check-label mx-2 text-color" htmlFor="copy_latest_reading_end">Copy Reading from Latest Counterlog </label>
                </div>
            </div>
           
        </div>)
    }

    closingRemarkJsx       =       ()      =>      {
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Closing Remark</div>
            </div>
            <div className='col-sm-7'>
                <textarea
                    name="closing_remarks"
                    value={this.state.taskSaveForm.closing_remarks}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                    placeholder="Remark"
                />

            </div>

        </div>)
    }

    activityCountJsx            =       ()      =>      {
        let {activity_type}             =   {...this.state.edit_form_data};
        let activityType = activity_type && activity_type.length > 0 ? activity_type.map(at => {return({value:at.key,label:at.name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Activity Type</div>
                </div>
                <div className='col-sm-7'>
                    <TapSelect
                        options={activityType}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'taskSaveForm', 'activity_type');
                        }}
                        isSearchable={true}
                        value={activityType.find(s => this.state.taskSaveForm.activity_type == s.value)}
                        isClearable={true}
                        placeholder="Select Activity"
                        autoCompelete="off"
                        menuPlacement={'top'}
                        containerHeight="30px"
                        fontSize="93%"
                    />

                </div>

            </div>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm '>No. of Session or Trip</div>
                </div>
                <div className='col-sm-7'>
                    <input
                        name="activity_count"
                        type="number"
                        value={this.state.taskSaveForm.activity_count}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter No. of Session/Trip"
                    />

                </div>

            </div>
        </Ax>)
    }

    taskCompleteTimeJsx        =   ()      =>      {
        let {asset, site, shift, counterlog}        =   {...this.state};
    
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(shift){
            if(shift && shift.shift_start == shift.shift_end){
                max_time  = moment(shift.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(shift.shift_end, "hh:mm a").toDate()
            }
        }
        
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Task Close Time</div>
            </div>
            <div className='col-sm-7'>
                <DatePicker
                    selected={
                        this.state.taskSaveForm.task_close_at
                            ? moment(this.state.taskSaveForm.task_close_at).toDate()
                            : false
                    }
                    name="task_close_at"
                    onChange={(value, event) => { this.formDateHandler('task_close_at', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                    dateFormat="dd-MMM-yyyy hh:mm a"
                    className={"form-control form-control-sm"}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    timeIntervals={5}
                    timeCaption="Time"
                    autoComplete="off"
                    scrollMonthYearDropdown
                    required={true}
                    placeholderText={`Please Enter Closing Date & Time`}
                    minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                    minTime={moment(shift ? shift.shift_start : new Date(), "hh:mm a ").toDate()}
                    maxTime={new Date().setHours(0, 0, 0, 0)}
                    maxDate={new Date()}
                />

            </div>

        </div>)
    }

    render = () => {
        return (<ApplicationLayout>
         <Helmet><title>Task List</title></Helmet>
         {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.taskListJsx()}
            {this.taskSearchModalJsx()}
            {this.taskEditModalJsx()}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
    };
};

export default connect(mapStateToProps)(AssetTask);