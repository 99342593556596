import React from 'react';
import moment from 'moment';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import HttpAPICall from '../../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../../components/TapUi';
import { Helmet } from 'react-helmet';
import TapSelect from '../../../../components/ui/TapSelect';
import download from '../../../../services/DownloadFile';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import { Modal,Tooltip } from 'bootstrap';
import InputAssetSearch from '../../../includes/ui/InputAssetSearch';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import CounterlogModal from '../CounterlogModal';
import ShiftSummaryDetail from '../ShiftSummaryDetail';
import Downtime from '../ShiftActivityModals/Downtime';
import FuelBalance from '../ShiftActivityModals/FuelBalance';
import Document from '../ShiftActivityModals/Document';
import ServiceChecksheet from '../ShiftActivityModals/ServiceChecksheet';
import Productivity from '../ShiftActivityModals/Productivity';
import Activity from '../ShiftActivityModals/Activity';
import Expense from '../ShiftActivityModals/expense/Expense';
import FuelFeedIn from '../ShiftActivityModals/FuelFeedIn';
import TaskSaveModal from '../ShiftActivityModals/task/TaskSaveModal';
import EngagementTime from '../ShiftActivityModals/EngagementTime';

class DailyCounterlog extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.counterlogFilterFormInitialState   =   {
            site_group_ids                          :    [],
            search_site_ids                         :   [],
            'asset_group_ids'                       :    [],
            search_asset_type_ids                   :   [],
            search_asset_matrix_ids                 :   [],
            search_manufacturer_ids                 :   [],
            search_models                           :   [],
            search_asset_ids                        :   [],
            search_asset_category                   :   'Y',
            counterlog_category                     :   '',  
            search_roster_shift_id                  :   '',
        };
        
        this.counterlogFilterFormLabel          =   {
            site_group_ids                          :   'Site Group : ',
            search_site_ids                         :   'Sites : ',
            'asset_group_ids'                       :   'Asset Type Groups : ',
            search_asset_type_ids                   :   'Asset Type : ',
            search_asset_matrix_ids                 :   'Asset Matrix : ',
            search_manufacturer_ids                 :   'Manufacturer : ',
            search_models                           :   'Models : ',
            search_asset_ids                        :   'Assets : ',
            search_asset_category                   :   'Asset Category : ',
            counterlog_category                     :   'CounterLog Category : ',
            search_roster_shift_id                  :   'Roster Shift : ',
        }

        this.state                          =   {
            form_data_loading               :   false,
            formDataLoaded                  :   false,
            searchedAssets                  :   [],
            iam_asset_types                 :   [],
            iam_user_sites                  :   [],
            iam_asset_matrix                :   [],
            iam_manufactures                :   [],
            iam_models                      :   [],
            iam_asset_category              :   [],
            all_counterlog_category         :   [],
            roster_shift_list               :   [],
            counterlogFilterForm            :   {...this.counterlogFilterFormInitialState},
            submittedCounterlogFilterForm   :   {...this.counterlogFilterFormInitialState}, 
            formSearchedElems               :   [],
            listing_loading                 :   false,
            listing_tbl_page                :   1,
            counterlog_listing              :   [],
            listingMeta                     :   null,
            rosterMeta                      :   null,
            totalListingCount               :   0, 
            date                            :   new Date(), 
            isLoading                       :   false,
            currentTransactionId            :   '',
            displayShiftSummary             :   false,
            file_downloading                :   false,
            allSiteGroupsList               :   [],
            allAssetTypesGroupsList         :   [],
            counterlog_header               :   [],
        };
        
        this.CounterLogModalRef           =   React.createRef();
        this.uploadDocumentRef            =   React.createRef();
        this.fuelOpeningBalanceRef        =   React.createRef();
        this.serviceChecksheetRef         =   React.createRef();
        this.logDowntimeRef               =   React.createRef();
        this.logProductivityRef           =   React.createRef();
        this.logActivityRef               =   React.createRef();
        this.logExpenseRef                =   React.createRef();
        this.logFuelFeedInRef             =   React.createRef();
        this.taskWorkflowRef              =   React.createRef();
        this.tooltipRef                  =   React.createRef();
        this.logEngagementTimeRef        =   React.createRef();
    }

    componentDidMount() {
      setTimeout(() => {
        this.initilaizeAppDataToFilterForm(this.props);
      },1000)
      this.setState({listing_loading : true})
      this.counterlogSearchModal              =       new Modal(document.getElementById('counterlogSearchModal'), {keyboard: false, backdrop :false});
    
    }

    // componentWillReceiveProps(nextProps){
    //     this.initilaizeAppDataToFilterForm(nextProps);
    // }
 
    initilaizeAppDataToFilterForm           =   (props)  =>  {
        if(!this.state.formDataLoaded){
            this.setState({form_data_loading : true})
            HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/asset_wise_shift_reading_form_data' , this.props.access_token, {}, {}, (resp) => {
                        
                this.setState({
                    iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                    formDataLoaded          :   true,
                    iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                    iam_asset_matrix        :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
                    iam_manufactures        :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
                    iam_models              :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
                    iam_asset_category      :   resp.data.asset_category && resp.data.asset_category.length > 0 ? resp.data.asset_category.map(ac => { return {value: ac.key, label: ac.name}}) : [],
                    roster_shift_list       :   resp.data.roster_shift_list && resp.data.roster_shift_list.length > 0 ? resp.data.roster_shift_list.map(rs => { return {value: rs.roster_shift_id, label: rs.display_name, roster_id : rs.roster_id, shift_id : rs.shift_id}}) : [],
                    all_counterlog_category :   resp.data.counterlog_category && resp.data.counterlog_category.length > 0 ? resp.data.counterlog_category.map(ac => { return {value: ac.key, label: ac.text}}) : [],
                    allSiteGroupsList       :   props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                },() => this.submitCounterlogSearchForm());
            }).then(() => this.setState({form_data_loading: false}));           
        }
    
    }

 
    //***************COUNTERLOG SEARCH******************** */
    counterlogSearchModalInit       =       ()      =>  {
        this.counterlogSearchModal.show()
    }

    //************************GET PO LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {...this.state.submittedCounterlogFilterForm,page:page,per_page : 50,date : this.state.date ? moment(this.state.date).format('YYYY-MM-DD') : ''};
        delete params.search_roster_shift_id;
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/asset_wise_shift_reading' , this.props.access_token, params, {}, (resp) => {
          let respData            =       resp.data;          
            if(respData.rosterMeta && respData.rosterMeta.any_shift_started && respData.rosterMeta.any_shift_started == 'N'){
                this.goToPreviousDateHandler()
            }else{
                this.setState({
                    counterlog_listing          :       respData.data,
                    listingMeta                :       respData.meta,
                    counterlog_header          :        respData.active_columns && respData.active_columns.length > 0 ? respData.active_columns.map(column => {return({...column,selected: column.status === 1})}): [],
                    rosterMeta                 :       respData.rosterMeta,
                    totalListingCount          :       respData.meta.total,
                },() => {
                  
                });
            }

        }).then(() => this.setState({listing_loading: false}));
       
    }

    refreshLogsheet                     =   ()  =>  this.loadListingTblData(1)

    goToPreviousDateHandler         =       ()      =>      {
        swal({
            title: "Shift not Started",
            text: "Shift for the current date still not started. Navigating to the previous day.",
            icon: "warning",
             dangerMode: true,
             showCancelButton: false,
             showConfirmButton: true,
            buttons: "Ok",
        }).then(willNavigate => {
            if (willNavigate) { 
               this.dateChangeHandler('prev')
            }
        });
    }

    dateChangeHandler       =   (type)   =>  {
        if(type == 'prev'){
         let date = this.state.date;
         this.setState({date : moment(date).subtract(1, 'days').format('YYYY-MM-DD')},() => this.loadListingTblData(1))
         
        }else{
            let date = this.state.date;
            this.setState({date : moment(date).add(1, 'days').format('YYYY-MM-DD')},() => this.loadListingTblData(1))           
        }
    }

    //***************SEARCH FORM******************** */
    submitCounterlogSearchForm          =   (event)  =>  {
        event && event.preventDefault()
        let serachFormKeys                      =   Object.keys(this.state.counterlogFilterForm);
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        let search_roster_id                    =   '';
        let search_shift_id                     =   '';
        serachFormKeys.map((key)        =>  {
            let label                       =   this.counterlogFilterFormLabel[key];
            let value                       =   this.state.counterlogFilterForm[key];
            if(value && value.length > 0) {
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_roster_shift_id') {
                        show_val            = this.state.roster_shift_list.filter((s) => value.includes(s.value)).map(s => s.label);
                        let roster_shift = this.state.roster_shift_list.find((s) => s.value === value);
                        search_roster_id = roster_shift.roster_id;
                        search_shift_id = roster_shift.shift_id;
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_asset_category') {
                        show_val            = this.state.iam_asset_category.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'counterlog_category') {
                        show_val            = this.state.all_counterlog_category.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedCounterlogFilterForm           :   {...this.state.counterlogFilterForm, search_roster_id, search_shift_id},
            formSearchedElems                       :   searchedElems
        }, () => {
            this.loadListingTblData(1);
            this.counterlogSearchModal.hide()
        });
    }
    
    logsheetDownloadHandler              =  ()  =>  {
        this.setState({file_downloading: true});
        let params                      =   {
            ...this.state.submittedCounterlogFilterForm,
            date            :   this.state.date ? moment(this.state.date).format('YYYY-MM-DD') : '',
            download        :   'download'
        };
        delete params.search_roster_shift_id;
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/asset_wise_shift_reading', this.props.access_token,params,{},(resp) => {
            download.file(resp.data.file_path);
        }).then(() => this.setState({file_downloading: false}));
    }

    clearSearchForm         =       ()      =>      {
        this.setState({
            counterlogFilterForm             :           {...this.counterlogFilterFormInitialState},
            formSearchedElems                :          [],
            submittedCounterlogFilterForm    :          {...this.counterlogFilterFormInitialState},
        }, () => {
            this.loadListingTblData(1);
            this.counterlogSearchModal.hide()
        })
    }
    
    viewShiftSummary                    =   (transaction_id)  =>  {
        this.setState({displayShiftSummary : true, currentTransactionId : transaction_id});
    }
    
    closeShiftSummary                   =   (date, asset_id,isActivityPerform, isCounterLogPerform)  =>  {
        this.setState({displayShiftSummary : false, currentTransactionId : ''});
        if(isActivityPerform || isCounterLogPerform) {
            this.afterUpdateCounterlogSubmit(date, asset_id);
        }
    }
    
    updateCounterlogModalInit           =   (shiftData, asset) =>  {
        let date                            =   moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.CounterLogModalRef.current.updateCounterlogInit(shiftData, date, asset);
    }
    
    afterUpdateCounterlogSubmit         =   (date, asset_id) =>  {
        let itemList                        =   [...this.state.counterlog_listing];
        let dateIndex                       =   itemList.findIndex(i => i.asset_id== asset_id);
        itemList[dateIndex]                 =   {...this.state.counterlog_listing[dateIndex], isLoading : true};
        this.setState({counterlog_listing : itemList});
       
        let params                          =   {page:1,per_page:50,search_asset_ids : [asset_id], date : moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD')};
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/asset_wise_shift_reading', this.props.access_token, params, {}, (resp) => {
            if(resp.data && resp.data.data) {
                itemList[dateIndex]         =   resp.data.data[0];
                this.setState({counterlog_listing : itemList});
            }
        });
    }
    
    uploadDocumentInit                  =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.uploadDocumentRef.current.uploadDocumentModalInit(shiftData, shiftData.counterlog, asset);
        }
    }
    
    fuelOpeningBalanceInit              =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.fuelOpeningBalanceRef.current.fuelOpeningBalanceModalInit(shiftData, shiftData.counterlog, asset);
        }
    }

    serviceChecksheetInit              =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.serviceChecksheetRef.current.serviceChecksheetModalInit(shiftData, shiftData.counterlog, asset);
        }
    }

    downtimeInit              =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logDowntimeRef.current.uploadDowntimeModalInit(shiftData, shiftData.counterlog, asset);
        }
    }

    productivityInit              =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logProductivityRef.current.uploadProductivityModalInit(shiftData, shiftData.counterlog, asset);
        }
    }

    taskInit              =   (shiftData, asset,task_detail = []) =>  {
        if(shiftData && shiftData.counterlog) {
            let dayRosterCLData                 =   asset && asset.dayRosterClData;
            let site                            =   dayRosterCLData ? dayRosterCLData.site : null;
            let shift                          =   shiftData ? shiftData : null;
            
            let one_task_in_progress                =   'N';
            let shift_summary                   =   shift ? shift.shift_summary : null;
          
            if(shift_summary && shift_summary.task && shift_summary.task.enable) {
                let display                     =   shift_summary.task.display;
                if (display && display.length > 0 && display.find(d => d.task_in_progress == 'Y')) {
                    one_task_in_progress            =   'Y';
                }
                   
               this.taskWorkflowRef.current.uploadTaskModalInit(asset,site,shift,shift.counterlog,one_task_in_progress);
            } 
           
        }
    }

    activityInit              =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logActivityRef.current.uploadActivityModalInit(shiftData, shiftData.counterlog, asset);
        }
    }

    expenseInit              =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logExpenseRef.current.uploadExpenseModalInit(shiftData, shiftData.counterlog, asset);
        }
    }

    fuelFeedInInit              =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logFuelFeedInRef.current.uploadFuelFeedInModalInit(shiftData, shiftData.counterlog, asset,null,asset ? asset.asset_id : '');
        }
    }

    engagemenTimeInit              =   (shiftData, asset) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logEngagementTimeRef.current.uploadEngagementModalInit(shiftData, shiftData.counterlog, asset);
        }
    }


 
    dailyCounterlogListjsx            =       ()      =>    {
        return (<Ax>
            <div className="page_title row m0">
                <div className="col">
                    <h3>Daily Logsheet</h3>
                </div>
                <div className="col-5 mt15">
                    <div className="input-group justify-content-center">
                        <button className="btn btn-secondary px-3" type="button" disabled={this.state.listing_loading} onClick={() => this.dateChangeHandler('prev')}>
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowLeft} className="me-1" />  Prev
                        </button>
                        <div className="add_calender_section">
                            <DatePicker
                                selected={ this.state.date ? moment(this.state.date).toDate() : false }
                                name="date"
                               
                                onChange={(value, event) => {
                                    this.formDateStateHandler(value, 'date','YYYY-MM-DD',() => this.loadListingTblData(1))
                                }}
                               
                                dateFormat="dd-MMM-yyyy"
                                className="form-control form-control-sm"
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                scrollMonthYearDropdown
                                required={true}
                                maxDate={new Date()}
                                disabled={this.state.listing_loading}
                                placeholderText={`Please Enter Date `}
                            />
                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "6px" }} />
                        </div>
                        <button className="btn btn-secondary  px-3" type="button" disabled={this.state.listing_loading || (this.state.rosterMeta && !this.state.rosterMeta.next_date)} onClick={() => this.dateChangeHandler('next')}>
                            Next <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowRight} className="ms-1" />
                        </button>
                    </div>
                </div>
                <div className="col text-end mt15">
                    <button type="button" className="btn btn-secondary" onClick={this.refreshLogsheet} disabled={this.state.listing_loading}>
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className={[this.state.listing_loading ? "fa-spin" : ''].join(' ')} color="white" />
                    </button>
                    <button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={() => this.counterlogSearchModalInit()}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} />
                    </button>
                    <button type="button" className="btn btn-secondary" disabled={this.state.file_downloading || this.state.listing_loading} onClick={this.logsheetDownloadHandler} >
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                        {this.state.file_downloading ? <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" /> : null}
                    </button>
                </div>
            </div>
            <div className="container-fluid pl5">
                <div className="page_containt row" >
                    <div className={['pageTbl', 'col-12'].join(' ')} >
                        {this.logsheetTableJsx()}
                    </div>
                </div>
            </div>
        </Ax>);
    }

    logsheetTableJsx                   =   ()  =>  {
        let task_submodule_available = this.props.group_permission && this.props.group_permission.group_sub_modules && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow") ? 'Y' : 'N';
        
        let tblHeading                  =   this.state.counterlog_header.filter(header => {
                                            if (header.key === "task" && task_submodule_available === 'N') {
                                                return false; // Exclude headers where key is "task" and task_submodule_available is 'N'
                                            }
                                            return header.status === 1; // Keep headers where status is 1
                                        }).map(header => {
                                            if (header.key == "date_asset") { header.name = "Asset"; }
                                            return header;
                                        });
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.clearSearchForm} />
                <table className="table table-bordered bg-white table-fixed  table-hover  mb-2 counterlog_table" id="assetDailyLogsheetTable">
                    <thead className='table-secondary text-center'>
                        {tblHeading.map((header,hk) => {
                            return <th style={{ width: header.width+"%" }}>{header.name}</th>
                        })}
                    </thead>
                    <tbody>
                        {!this.state.listing_loading
                            ?
                            this.state.counterlog_listing && this.state.counterlog_listing.length > 0 ? this.state.counterlog_listing.map((s, i) => {
                               
                                return s.dayRosterClData && s.dayRosterClData.roster ?
                                    s.dayRosterClData.roster.map((sf, key) => {
                                        let remarks     =   sf.shift_summary && sf.shift_summary.remark ? sf.shift_summary.remark : "";
                                        let remarksToShow = remarks.slice(0,30);
                                        return (
                                            <tr className='text-center align-middle'>{key == 0 ? <Ax>
                                                <td className="va_middle text-start" rowspan={s?.dayRosterClData?.roster?.length}><b><Link to={`/assets/logsheet/${s.asset_id}`}>{s.name ? s.name : "-"}</Link></b>

                                                    <br />{s.asset_code ? s.asset_code : "-"}<br />
                                                    Site : {s.dayRosterClData && s.dayRosterClData.site ? `${s.dayRosterClData.site.site_name}` : ''}</td>
                                            </Ax> : null}
                                                <td>{sf.shift_name} <br /> <span className="text-sm">{sf.shift_start}</span></td>
                                                {s.isLoading && s.isLoading == true ? <td colSpan={9} className="text-center"><Loader /></td>
                                                    : <Ax>
                                                        <td>{sf.counterlog && sf.counterlog.display
                                                            ? <span className="text-start cursor_pointer" onClick={() => {
                                                                if(this.props.setting && this.props.setting.enable_opening_closing_cl && this.props.setting.enable_opening_closing_cl == 'Y' && sf.closing_shift_cl_exist == 'N') {
                                                                    this.updateCounterlogModalInit(sf, s)
                                                                } else {
                                                                    this.viewShiftSummary(sf.counterlog.transaction_id);
                                                                }
                                                            }}>
                                                                {sf.counterlog && sf.counterlog.display && sf.counterlog.display.length == 1
                                                                    ? <div className="mt-1 link-primary  text-center">View</div> 
                                                                    : sf.counterlog.display.filter(s => !moment(s.reading, "DD-MMM-YYYY", true).isValid()).map((item, index) => {
                                                                        return (<div className="mt-1 link-primary">{item.label + ': ' + item.reading}</div>)
                                                                    })}
                                                            </span>
                                                            : <a role="button" className='link-primary' onClick={() => this.updateCounterlogModalInit(sf, s)}>No Record</a>}</td>
                                                        {sf.counterlog ?
                                                            ((this.props.setting && this.props.setting.enable_opening_closing_cl && this.props.setting.enable_opening_closing_cl == 'Y' && sf.closing_shift_cl_exist == 'N')
                                                                ? (<td className='text-center align-middle' colSpan={tblHeading.length - 3}>
                                                                    <button onClick={() => this.updateCounterlogModalInit(sf, s)} className='btn btn-link text-decoration-none'>Closing CounterLog not available, Click to enter</button>
                                                                </td>) 
                                                                : (<Ax>
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "fuel_feedIn") && <td className='align-bottom'>{sf.shift_summary.fuelfeedin && sf.shift_summary.fuelfeedin.enable == true && sf.shift_summary.fuelfeedin.display !== "" ? sf.shift_summary.fuelfeedin.display : ""}
                                                                        <div role="button"
                                                                            onClick={() => {
                                                                                if (s.fuel_feed_in == "yes") {
                                                                                    return this.fuelFeedInInit(sf, s)
                                                                                }
                                                                            }}
                                                                            className={[' p-0',s.fuel_feed_in == "yes"  ? '' : 'disabled-anchor-link'].join(' ')}
                                                                            title={s.fuel_feed_in == "no" ? 'Asset does not have fuelfeedin facility' : ''}
                                                                            style={{ "pointerEvents": "all" }}
                                                                        ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                        
                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "downtime") &&  <td className='align-bottom'>{sf.shift_summary.downtime && sf.shift_summary.downtime.enable == true && sf.shift_summary.downtime.display && sf.shift_summary.downtime.display.length > 0  ? sf.shift_summary.downtime.display.map(db => {return(<div>{db.key} : {db.val}</div>)}) : ""}
                                                                        <div role="button"
                                                                            onClick={() => {
                                                                                if (this.props.permissions.includes('downtime-add')) {
                                                                                    return this.downtimeInit(sf, s)
                                                                                }
                                                                            }}
                                                                            className={['', this.props.permissions.includes('downtime-add') ? '' : 'disabled-anchor-link'].join(' ')}
                                                                            title={!this.props.permissions.includes('downtime-add') ? 'You do not have permission to perform this action' : ''}
                                                                            style={{ "pointerEvents": "all" }}
                                                                        ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                
                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "productivity") &&  <td className='align-bottom'>{sf.shift_summary.prodction && sf.shift_summary.prodction.enable == true && sf.shift_summary.prodction.display !== "" ? sf.shift_summary.prodction.display : ""}
                                                                        <div className='cursor_pointer' onClick={() => this.productivityInit(sf, s)}>
                                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "8px" }} />
                                                                        </div>
                                                                    </td>}
                                                                    {task_submodule_available == 'Y' && this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "task") &&
                                                                        
                                                                        <td className='align-bottom'>{sf.shift_summary.task && sf.shift_summary.task.enable == true && sf.shift_summary.task.display && sf.shift_summary.task.display.length > 0 ? sf.shift_summary.task.display.map(tk => { return (<div className='text-sm'>{tk.total} × {tk.activity_name} {tk.task_in_progress == 'N' ? <span className='text-success'>(Closed)</span> : <span className='link-primary'>(Inprogress)</span>}</div>) }) : ""}
                                                                            <div role="button"
                                                                                onClick={() => {
                                                                                    if (this.props.permissions.includes('iam-task-workflow-execution')) {
                                                                                        return this.taskInit(sf, s, sf?.shift_summary?.task.display)
                                                                                    }
                                                                                }}
                                                                                className={['', this.props.permissions.includes('iam-task-workflow-execution')  ? '' : 'disabled-anchor-link'].join(' ')}
                                                                                title={!this.props.permissions.includes('iam-task-workflow-execution') ? 'You do not have permission to perform this action' : ''}
                                                                                style={{ "pointerEvents": "all" }}
                                                                            ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                            
                                                                        </td> }
                                                                        
                                                                
                                                                
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "scs")  && <td className='align-bottom'>{sf.shift_summary.scs && sf.shift_summary.scs.enable == true && sf.shift_summary.scs.display !== "" ? sf.shift_summary.scs.display : ""}
                                                                        <div className='cursor_pointer' onClick={() => this.serviceChecksheetInit(sf, s)}>
                                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "8px" }} />
                                                                        </div>
                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "expense")  && <td className='align-bottom'>{sf.shift_summary.expense && sf.shift_summary.expense.enable == true && sf.shift_summary.expense.display !== "" ? sf.shift_summary.expense.display : ""}
                                                                        <div className='cursor_pointer' onClick={() => this.expenseInit(sf, s)}>
                                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "8px" }} />
                                                                        </div>
                                                                    </td>}
                                                                
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "opening_balance")  &&  <td className='align-bottom'>{sf.shift_summary.fuelbalance && sf.shift_summary.fuelbalance.enable == true && sf.shift_summary.fuelbalance.display !== "" ? sf.shift_summary.fuelbalance.display : ""}
                                                                    <div role="button"
                                                                            onClick={() => {
                                                                                if (s.fuel_feed_in == "yes") {
                                                                                    return this.fuelOpeningBalanceInit(sf, s)
                                                                                }
                                                                            }}
                                                                            className={[' p-0',s.fuel_feed_in == "yes"  ? '' : 'disabled-anchor-link'].join(' ')}
                                                                            title={s.fuel_feed_in == "no" ? 'Asset does not have fuelfeedin facility' : ''}
                                                                            style={{ "pointerEvents": "all" }}
                                                                        ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                        
                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "docs")  &&   <td className='align-bottom'>{sf.shift_summary.doc && sf.shift_summary.doc.enable == true && sf.shift_summary.doc.display !== "" ? sf.shift_summary.doc.display : ""}
                                                                        <div className='cursor_pointer' onClick={() => this.uploadDocumentInit(sf, s)}>
                                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "8px" }} />
                                                                        </div>
                                                                    </td>}
                                                                    
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "cl_remarks")  &&  
                                                                        <td ref={this.tooltipRef} className='align-bottom cursor_pointer tooltip-trigger text-sm'
                                                                        data-bs-toggle='tooltip'  title={remarks} >
                                                                        {remarksToShow} {remarks.length > 30 ? '...' : ''}
                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "operator")  &&  
                                                                        <td className='align-bottom text-start text-sm'>
                                                                            <ol>
                                                                                {sf.shift_summary && sf.shift_summary.operators && sf.shift_summary.operators.length > 0 && sf.shift_summary.operators.map(op => {
                                                                                    return (<li>{op.name}</li>)
                                                                                })}
                                                                            </ol>
                                                                        
                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "engagement_time") &&  <td className='align-bottom'> 
                                                                    {sf.shift_summary.engagement && sf.shift_summary.engagement.enable == true && sf.shift_summary.engagement.display ? sf.shift_summary.engagement.display : ""}
                                                                        <div className='cursor_pointer' onClick={() => this.engagemenTimeInit(sf, s)}>
                                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "8px" }} />
                                                                        </div>
                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "activity") ? <td className='align-bottom'>{sf.shift_summary.activity && sf.shift_summary.activity.enable == true && sf.shift_summary.activity.display !== "" ? sf.shift_summary.activity.display : ""}
                                                                            <div className='cursor_pointer' onClick={() => this.activityInit(sf, s)}>
                                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} style={{ width: "8px" }} />
                                                                            </div>
                                                                        </td> :null}
                                                                </Ax>)
                                                            )
                                                            : <td colSpan={tblHeading.length-3} className="text-center">-</td>}

                                                    </Ax>}
                                            </tr>
                                        )

                                    })
                                    : <tr className='text-center '>
                                        <td className="va_middle text-start"><b><Link to={`/assets/logsheet/${s.asset_id}`}>{s.name ? s.name : "-"}</Link></b>

                                            <br />{s.asset_code ? s.asset_code : "-"}<br />
                                            Site : {s.dayRosterClData && s.dayRosterClData.site ? `${s.dayRosterClData.site.site_name}` : ''}</td>

                                        <td className='text-center' colSpan={tblHeading.length}>No Roster Linked</td>
                                    </tr>

                            }

                            )
                                : <tr><td className='text-center' colSpan={11}>No Record</td></tr>
                            : <tr><td className='text-center' colSpan={11}><Loader /></td></tr>}
                    </tbody>

                </table>
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                />
            </Ax>
        );
    }

     //***********************SEARCH counterlog JSX****************
     counterlogSearchModalJsx               =        ()             =>        {
         return (
             <div className="modal fade" id="counterlogSearchModal" tabIndex="-1">
                 <div className="modal-dialog modal-lg">
                     <div className="modal-content">
                         <div className="modal-header">
                             <h5 className="modal-title" id="searchAssetModalLabel">Counterlog Search</h5>
                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                         <form id="counterlogSearchForm" onSubmit={this.submitCounterlogSearchForm}>
                             {this.state.form_data_loading ? <Loader />
                                 : <div className='modal-body'>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Name</label>
                                         <div className="col-sm-10">
                                             <InputAssetSearch
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'counterlogFilterForm', 'search_asset_ids');
                                                     this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                 }}
                                                 isMulti={true}

                                                 style={{ height: "30px", fontSize: "93%" }}
                                                 value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type Group</label>
                                         <div className="col-sm-10">
                                             <TapSelect
                                                 options={this.state.allAssetTypesGroupsList}
                                                 changeEvent={(selectedOption) => {

                                                     this.setState({
                                                         counterlogFilterForm: {
                                                             ...this.state.counterlogFilterForm,
                                                             asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                             search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                         }
                                                     })
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.allAssetTypesGroupsList.filter(s => this.state.counterlogFilterForm.asset_group_ids.includes(s.value))}
                                                 placeholder="Select Asset Type Group"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />
                                         </div>
                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Type</label>
                                         <div className="col-sm-10">
                                             <TapSelect
                                                 options={this.state.iam_asset_types}
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'counterlogFilterForm', 'search_asset_type_ids')}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 value={this.state.iam_asset_types.filter(s => this.state.counterlogFilterForm.search_asset_type_ids.includes(s.value))}
                                                 placeholder="Select Asset Type"
                                             />
                                         </div>
                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Roster Shift</label>
                                         <div className="col-sm-10">
                                             <TapSelect
                                                 options={this.state.roster_shift_list}
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'counterlogFilterForm', 'search_roster_shift_id')}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 value={this.state.roster_shift_list.find(rs => rs.value === this.state.counterlogFilterForm.search_roster_shift_id)}
                                                 placeholder="Select Roster Shift"
                                             />
                                         </div>
                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Site Group</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.allSiteGroupsList}
                                                 changeEvent={(selectedOption) => {

                                                     this.setState({
                                                         counterlogFilterForm: {
                                                             ...this.state.counterlogFilterForm,
                                                             site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                             search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                         }
                                                     })
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.allSiteGroupsList.filter(s => this.state.counterlogFilterForm.site_group_ids.includes(s.value))}
                                                 placeholder="Select Site Group"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />
                                         </div>
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Current Site</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.iam_user_sites}
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'counterlogFilterForm', 'search_site_ids')}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 value={this.state.iam_user_sites.filter(s => this.state.counterlogFilterForm.search_site_ids.includes(s.value))}
                                                 placeholder="Select Sites"
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Category</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.iam_asset_category}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'counterlogFilterForm', 'search_asset_category')}
                                                 value={this.state.iam_asset_category.find(r => r.value === this.state.counterlogFilterForm.search_asset_category)}
                                                 placeholder="ALL"
                                             />
                                         </div>
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Manufacturer</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.iam_manufactures}
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'counterlogFilterForm', 'search_manufacturer_ids')}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 value={this.state.iam_manufactures.filter(s => this.state.counterlogFilterForm.search_manufacturer_ids.includes(s.value))}
                                                 placeholder="Select Manufacturer"
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">

                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Counterlog Category</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.all_counterlog_category}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'counterlogFilterForm', 'counterlog_category')}
                                                 value={this.state.all_counterlog_category.find(r => r.value === this.state.counterlogFilterForm.counterlog_category)}
                                                 placeholder="Select Counterlog Category"
                                             />
                                         </div>
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Matrix</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.iam_asset_matrix}
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'counterlogFilterForm', 'search_asset_matrix_ids')}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 value={this.state.iam_asset_matrix.filter(s => this.state.counterlogFilterForm.search_asset_matrix_ids.includes(s.value))}
                                                 placeholder="Select Asset Matrix"
                                             />
                                         </div>

                                     </div>

                                 </div>}
                             <div className="modal-footer">
                                 <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.clearSearchForm}>Clear</button>
                                 <button type="submit" className="btn btn-primary">Search</button>
                             </div>
                         </form>
                     </div>
                 </div>
             </div>
         );
   }


    render                              =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Daily Logsheet</title></Helmet>
            
            {this.state.displayShiftSummary
             ?  <ShiftSummaryDetail transaction_id={this.state.currentTransactionId} displayAssetDetail={true} 
                    onCloseEvent={(date, asset_id, isActivityPerform, isCounterLogPerform) => {
                        this.closeShiftSummary(date, asset_id, isActivityPerform, isCounterLogPerform)
                    }} 
                />
             :  this.dailyCounterlogListjsx()
            }
            {this.counterlogSearchModalJsx()}
            
            <TaskSaveModal ref={this.taskWorkflowRef} afterSaveTask={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id)}}/>
            <CounterlogModal ref={this.CounterLogModalRef} afterSubmitCounterlog={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <ServiceChecksheet ref={this.serviceChecksheetRef} afterSaveServiceChecksheet={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <Document ref={this.uploadDocumentRef} afterSaveDocument={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <FuelBalance ref={this.fuelOpeningBalanceRef} afterSaveFuelBalance={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <Downtime ref={this.logDowntimeRef}  afterSaveDowntime={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <EngagementTime ref={this.logEngagementTimeRef}  afterSaveEngagementTime={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <Productivity ref={this.logProductivityRef}  afterSaveProductivity={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <Activity ref={this.logActivityRef}  afterSaveActivity={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <Expense ref={this.logExpenseRef}  afterSaveExpense={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <FuelFeedIn ref={this.logFuelFeedInRef}  afterSaveFuelFeedIn={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        group_permission        :   state.app.acl_info,
        permissions             :   state.app.acl_info.permissions,
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        asset_groups            :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        setting                 :   state.app && state.app.setting ? state.app.setting : [],
    };
};

export default connect(mapStateToProps)(DailyCounterlog);