import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import Ax from "../../../../../components/hoc/Ax";
import Loader from "../../../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import TapHelper from '../../../../../services/TapHelper';

class ExpenseView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                        =             {
            expenseData                 :            null,        
        }
       
    }

    componentDidMount() {
           this.intializeData(this.props) 
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.expenseData != nextProps.expenseData || this.props.expenseId !== nextProps.expenseId) {
            this.intializeData(nextProps);
        }
    }

    intializeData       =       (pr)      =>      {
        this.setState({access_token : pr.access_token},() => {
            let transaction_id = pr && pr.expenseId ? pr.expenseId : pr && pr.expenseData ? pr.expenseData.transaction_id : '';
            if(pr.expenseId){
                this.getExpenseDetail(transaction_id)
            }else{
                this.setState({expenseData : pr.expenseData})
            }
        })
    }

    getExpenseDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true });
        let params      =   {need_asset_profile : this.props.need_asset_profile, need_site: this.props.need_site};
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/expense/detail/' + id, this.state.access_token, params, {}, (response) => {
            this.setState({
                expenseData: response.data.data
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    render                              =   ()  =>  {
        let expenseData = this.state.expenseData;
        return (<Ax>
            {this.state.viewDataLoading
                ? <Loader />
                : <Ax>
                    {expenseData ?
                        <Ax>
                            <div className="tab_content_wrapper"> <span className="content_heading">Expense Detail</span></div>
                            <table className="table my-2 table-hover table-bordered">
                                <tbody>
                                    {expenseData.site ?
                                        <tr>
                                            <td style={{ "width": "25%" }}>Site</td>
                                            <th style={{ "width": "65%" }}>{expenseData.site.site_name ? expenseData.site.site_name : ""}   </th>
                                        </tr>
                                        : null
                                    }
                                    <tr>
                                        <td style={{ "width": "25%" }}>Expense Details</td>
                                        <th style={{ "width": "65%" }}>{expenseData.details ? expenseData.details : ""}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Expense Amount in ₹</td>
                                        <th style={{ "width": "65%" }}>{expenseData.amount ? expenseData.amount : ""}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Payee</td>
                                        <th style={{ "width": "65%" }} className="text-capitalize">{expenseData.payee ? expenseData.payee : ""}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Payee Name</td>
                                        <th style={{ "width": "65%" }} className="text-capitalize">
                                            {expenseData.payee == "employee" && expenseData.employeeData ? expenseData.employeeData.display_full_name
                                                : expenseData.payee == "vendor" && expenseData.vendorData ? expenseData.vendorData.name : expenseData.payee_name ? expenseData.payee_name : ""}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Wallet</td>
                                        <th style={{ "width": "65%" }} className="text-capitalize">{expenseData.walletData && expenseData.walletData.wallet_name ? expenseData.walletData.wallet_name : ""}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Reference Number</td>
                                        <th style={{ "width": "65%" }} >{expenseData.reference_number ? expenseData.reference_number : ""}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Document</td>
                                        <th style={{ "width": "65%" }} >{expenseData.invoice_url ? <a target="_blank" href={expenseData.invoice_url}>View Document</a> : ""}   </th>
                                    </tr>
                                    {expenseData && expenseData.assets && expenseData.assets.length > 0 && <Ax>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Section</td>
                                        <th style={{ "width": "65%" }} >{expenseData.section && expenseData.section.component ? expenseData.section.component : ""}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Sub-Section</td>
                                        <th style={{ "width": "65%" }} >{expenseData.sub_section && expenseData.sub_section.subcomponent ? expenseData.sub_section.subcomponent : ""}  </th>
                                    </tr></Ax> }
                                    <tr>
                                        <td style={{ "width": "25%" }}>Notes</td>
                                        <th style={{ "width": "65%" }} >{expenseData.notes ? expenseData.notes : ""}   </th>
                                    </tr>

                                </tbody>
                            </table>
                          {expenseData && expenseData.ticket && <Ax>
                            <div className="tab_content_wrapper mt-2">
                                <span className="content_heading">Ticket Details</span>
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>Ticket ID</td>
                                        <th style={{ width: "65%" }}>{expenseData?.ticket?.activity_id}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Ticket Type</td>
                                        <th style={{ width: "65%" }}>{expenseData?.ticket?.ticket_type}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Ticket Header</td>
                                        <th style={{ width: "65%" }}>{expenseData?.ticket?.header}</th>
                                    </tr>
                                </tbody>
                            </table>
                            </Ax>}  
                         
                            <div className="tab_content_wrapper mt-2">
                                <span className="content_heading">Expense Head BreakUp</span>
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="col" style={{width:"5%"}}>S.No.</th>
                                        <th scope="col" style={{width:"50%"}}>Expense Head BreakUp</th>
                                        <th scope="col" className="text-end" style={{width:"45%"}}>Expense Head Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    expenseData?.expense_heads?.length > 0 && expenseData?.expense_heads.map((head, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{head.expense_head}</td>
                                                <td className="text-end">{TapHelper.dispayAsAmount(head.head_amount)}</td>
                                            </tr>  
                                        )
                                    })
                                }  
                                </tbody>
                            </table>
                            {expenseData && expenseData.assets && expenseData.assets.length > 0
                                ? <Ax>
                                    <div className="tab_content_wrapper  my-2"> <span className="content_heading">Asset Detail</span></div>
                                    <table className="table my-2 table-hover table-bordered align-items-center">
                                        <thead>
                                            <tr>
                                                <th className='text-center' style={{ width: "5%" }}>S.No</th>
                                                <th style={{ width: "25%" }}>Asset Name</th>
                                                <th style={{ width: "15%" }}>Asset Code</th>
                                                <th style={{ width: "15%" }}>Asset Type</th>
                                                <th style={{ width: "20%" }}>Counterlog</th>
                                                <th className='text-end' style={{ width: "10%" }}>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {expenseData.assets.map((asset, i) => {
                                                return (
                                                    <tr key={i + 1}>
                                                        <td>{i + 1}</td>
                                                        <td>{asset.name ? asset.name : ""}</td>
                                                        <td>{asset.asset_code ? asset.asset_code : ""}</td>
                                                        <td>{asset.asset_type_name ? asset.asset_type_name : ""}</td>
                                                        <td>
                                                            {expenseData.counterlog && expenseData.counterlog.display && expenseData.counterlog.display.length > 0
                                                                ? expenseData.counterlog.display.map(counterlog => {
                                                                    return (<div>
                                                                        {counterlog.label ? counterlog.label : ''} : <b>{counterlog.reading} {counterlog.unit}</b>
                                                                    </div>)
                                                                })
                                                                : ""}
                                                        </td>
                                                        <td className='text-end'>{ asset.expense_amount ? TapHelper.dispayAsAmount(asset.expense_amount) : 0}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Ax>
                                : null}

                            <div className="tab_content_wrapper  my-2"> <span className="content_heading">Transaction Details</span></div>
                            <table className="table my-2 table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Transaction ID</td>
                                        <th style={{ "width": "65%" }}>{expenseData.transaction_id ? expenseData.transaction_id : ''}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Date of Transaction</td>
                                        <th style={{ "width": "65%" }}>{expenseData.expense_date_display ? expenseData.expense_date_display : ''}   </th>
                                    </tr>

                                    <tr>
                                        <td>Executed By</td>
                                        <th>{expenseData.user ? expenseData.user.full_name : ""}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Date of Entry</td>
                                        <th style={{ "width": "65%" }}>{expenseData.created_at_display ? expenseData.created_at_display : ''}   </th>
                                    </tr>
                                </tbody>
                            </table>
                        </Ax>
                        : null}

                </Ax>}

        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(ExpenseView);