import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link , useHistory} from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import swal from "sweetalert";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import TapNavTabs from '../../../../components/ui/TapNavTabs';
import AdditionalAttribute from '../../AdditionalAttribute';
import CounterlogView from './CounterlogView';
import CounterlogSetting from './CounterlogSetting';


const CounterlogConfiguration                  =   forwardRef((props, ref) => {

    const [currentTab, setCurrentTab]       =   useState(0);
    const [loadedTabs, setLoadedTabs]       =   useState([]);
    
    useEffect(() => {
        let newLoadedTabs   = loadedTabs.filter((item, i, ar) => ar.indexOf(item) === i);
        newLoadedTabs.push(currentTab);
        setLoadedTabs(newLoadedTabs);
    }, [currentTab]);

    let reportTabs                          =   ()  =>  {
        return [
            { name  :   'General Settings',     jsx :   loadedTabs.includes(0) && <CounterlogSetting /> },
            { name  :   'Counterlog View',      jsx :   loadedTabs.includes(1) && <CounterlogView /> },
            { name  :   'Additional Attribute', jsx :   loadedTabs.includes(2) && <AdditionalAttribute category="counterlog" /> },
        ];
    }

    return  <>
        <TapNavTabs tabReinitialize="N" tabs={reportTabs()} activetab={0} setTabValue={(activetab) => { setCurrentTab(activetab); }} />
    </>
});

export default CounterlogConfiguration;