import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';

class FundTransferDetailModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                      =       {
            FundTransferData            :       null,
            view_loading                :       false,
        };
        
    }

    componentDidMount() {
        if(this.props && this.props.fundTransferId){
            this.getFundTransferDetails(this.props.fundTransferId)
        }
        if(this.props && this.props.fundTransferData){
            this.setState({fundTransferData : this.props.fundTransferData})
        }
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.fundTransferId !== this.props.fundTransferId){
            this.getFundTransferDetails(nextProps.fundTransferId)
         }
         if(nextProps.fundTransferData !== this.props.fundTransferData){
             this.setState({fundTransferData : nextProps.fundTransferData})
         }
         
     }
    

     getFundTransferDetails             =    (transaction_id)        =>  {
        this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/wallet/fund_transfer/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 fundTransferData            : respData,
             });
            
         }).then(() => this.setState({view_loading: false}));
        
     }
    
    render() {
        let fundTransferData = this.state.fundTransferData
        return (<Ax>
             {this.state.view_loading ?  <Loader/> :
               fundTransferData ?  (<div className="pageTbl">
                    <div className="p-2 mb-3">
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Fund Transfer Detail</span>
                        </div>
                        <table className="table mt-2 table-hover table-borderless">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Transaction ID</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{fundTransferData.activity_id}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Date of Transaction</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{fundTransferData.transfer_date_display}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Fund Transfer From</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{fundTransferData.wallet_from.wallet_name??"-"}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Fund Transfer To</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{fundTransferData.wallet_to.wallet_name??"-"}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Amount	</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{fundTransferData.amount}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Reference	</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{fundTransferData.ref_no == null ? "-"  : fundTransferData.ref_no}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Notes</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{fundTransferData.notes == null ? "-"  : fundTransferData.notes}</th>
                            </tr>
                           </tbody>
                    </table>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Transaction Detail</span>
                        </div>
                        <table className="table mt-2 table-hover table-borderless">
                            <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Entry Created By</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{fundTransferData.created_by == null ? "-"  : fundTransferData.created_by.full_name}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Created On</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{fundTransferData.created_at_display == null ? "-"  : fundTransferData.created_at_display}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Last Updated By</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{fundTransferData.updated_by == null ? "-"  : fundTransferData.updated_by.full_name}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Last Updated Date</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{fundTransferData.updated_at_display == null ? "-"  : fundTransferData.updated_at_display}</th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>) : null}
        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps)(FundTransferDetailModal);