import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import Ax from '../../../../components/hoc/Ax';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { ISC_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';


class TransactionSetting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initEditSettingForm           =         {
            name                            :        'Requisition',
            type                            :        "manual",
            prefix                          :        "",
            numbering                       :        "",
            activity_id                     :        ""
        }
        this.state                          =        {
            transactionList                 :        [],
            listing_loading                 :       false,
            editSettingFormSubmitting       :       false,
            editSettingForm                 :       {...this.initEditSettingForm}
        }
        
    }

    componentDidMount(){
        this.getTransactionListing()
        this.editSettingModal            =        new Modal(document.getElementById('editSettingModal'), {keyboard: false, backdrop :false});
    }

    getTransactionListing       =       ()      =>  {
        this.setState({ listing_loading : true});
        HttpAPICall.withAthorization('GET',  ISC_API_BASE_URL_2 + '/activity/setting/transaction_id' , this.props.access_token,{},{},(response) => {
            this.setState({transactionList: response.data.data})
         }).then(() => this.setState({listing_loading : false}));
    }

    editSettingFormInit         =       (id)      =>  {
        this.editSettingModal.show()
        let transactionList = this.state.transactionList.find(function (transaction) {
            return transaction.activity_id == id;
        });
        this.setState({
            editSettingForm              :            {
                name                    :            transactionList.activity,
                type                    :           transactionList.type,
                prefix                  :           transactionList.prefix === "" ? "" : transactionList.prefix,
                numbering               :           transactionList.start_from_dispaly === "" ? "" : transactionList.start_from_dispaly,
                activity_id             :           transactionList.activity_id
            },
        })
    }
    onTypeChange        =       ( )     =>      {
        this.setState({editSettingForm : {
            ...this.state.editSettingForm,
            prefix : "",
            numbering : "",
            type : "manual"
        }})
    }

    submitEditSettingForm           =       (e)      =>      {
        e.preventDefault()
        let frmData = {
            activity_id         :   this.state.editSettingForm.activity_id,
            type                :   this.state.editSettingForm.type,
            prefix              :   this.state.editSettingForm.prefix,
            start_from          :   parseInt(this.state.editSettingForm.numbering),
            padded_integer      :   this.state.editSettingForm.numbering.length
           };
           this.setState({editSettingFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', ISC_API_BASE_URL_2 + '/activity/setting/transaction_id', this.props.access_token, {} , {...frmData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editSettingModal.hide();
            this.getTransactionListing()
            this.setState({editSettingForm  :  {...this.initEditSettingForm}})
        }).then(() => this.setState({editSettingFormSubmitting: false}))
    }

    //*********************************EDIT SETTING JSX*************************
    editSettingModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="editSettingModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editSettingModalLabel">Update Requisition</h5>
                        </div>
                        <form onSubmit={this.submitEditSettingForm} id="editSettingForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Transaction</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.editSettingForm.name}
                                            disabled={true}
                                            placeholder="Please Enter  Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label className="form-label require">ID Type</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="type"
                                                type="radio"
                                                value="manual"
                                                onChange={(e) => {this.formInputHandler(e,'editSettingForm');this.onTypeChange()}}
                                                checked={this.state.editSettingForm.type === "manual"}
                                                className="form-check-input"
                                                required={true}
                                                id="editSettingManual"
                                            />
                                            <label className="form-check-label" htmlFor="editSettingManual">Manual</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="type"
                                                type="radio"
                                                value="system_generate"
                                                onChange={(e) => {this.formInputHandler(e,'editSettingForm')}}
                                                checked={this.state.editSettingForm.type === "system_generate"}
                                                className="form-check-input"
                                                required={true}
                                                id="editSettingSystem"
                                            />
                                            <label className="form-check-label" htmlFor="editSettingSystem">Auto-Increment</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">ID Prefix</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="prefix"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e,'editSettingForm')}
                                            value={this.state.editSettingForm.prefix}
                                            disabled={this.state.editSettingForm.type === "manual" ? true : false}
                                            placeholder="Please Enter Prefix"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Numbering</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="numbering"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e,'editSettingForm')}
                                            value={this.state.editSettingForm.numbering}
                                            disabled={this.state.editSettingForm.type === "manual" ? true : false}
                                            placeholder="Please Enter Numbering"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.editSettingFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ editSettingForm: { ...this.initEditSettingForm } }) }}>Close</button>
                                <button type="submit" disabled={this.state.editSettingFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.editSettingFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            );
    }


    render() {
        return (
            <Ax>
                <div className="tab_content_wrapper">
                    <span className="content_heading">Transaction ID Settings</span>
                </div>
                <table className="table table-hover table-bordered my-3 ">
                    <thead className='table-secondary'>
                        <tr>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Sr.No</th>
                            <th scope="col" style={{ width: "15%" }}>Transaction</th>
                            <th scope="col" style={{ width: "20%" }}>ID Type</th>
                            <th scope="col" style={{ width: "25%" }}>ID Prefix</th>
                            <th scope="col" style={{ width: "25%" }}>Numbering</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {this.state.listing_loading
                            ? <tr>
                                <td className="text-center" colSpan="6"><Loader /></td>
                            </tr>
                            :
                            this.state.transactionList.filter(i => i.activity === "Requisition").map((item, index) => {
                                return <tr key={item.activity_id}>
                                    <td scope="row" className='text-center'>{item.activity_id}</td>
                                    <td scope="row">{item.activity}</td>
                                    <td scope="row">{item.type_display}</td>
                                    <td scope="row">{item.prefix === "" || item.prefix === null ? "-" : item.prefix}</td>
                                    <td scope="row">{item.start_from_dispaly === "" ? "-" : item.start_from_dispaly}</td>
                                    <td scope="row" className="ml-2 text-center">
                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                            data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                                        />
                                        <ul className="dropdown-menu">
                                            <li className="dropdown-item" onClick={(e) => this.editSettingFormInit(item.activity_id)}>Edit</li>

                                        </ul>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                {this.editSettingModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(TransactionSetting);