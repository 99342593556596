import React from 'react';
import moment from 'moment';
import swal from "sweetalert";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import { Helmet } from 'react-helmet';
import TapSelect from '../../../components/ui/TapSelect';
import download from '../../../services/DownloadFile';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Modal } from 'bootstrap';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import ItemViewInList from '../includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Link } from 'react-router-dom';
import TapIcon from '../../../services/TapIcon';
import DatePicker from 'react-datepicker';
import IndentDetailModal from '../warehouseIndent/IndentDetailModal';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import DateService from '../../../services/DateService';
import LogisticViewModal from '../logistic/LogisticViewModal';


class InventoryTransferList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initInventorySearchForm = {
            search_like_transaction_id: '',
            search_eway_number: '',
            search_invoice_number: '',
            search_item_batch_number: '',
            search_item_ids: [],
            search_warehouse_from_ids: [],
            search_warehouse_to_ids: [],
            search_item_tags: [],
            search_status_ids: [],
            date_range: null,
            date_range_start: null,
            date_range_end: null,
        }

        this.inventorySearchFormLabel = {
            search_like_transaction_id: 'Transaction ID : ',
            search_eway_number: 'Eway Number : ',
            search_invoice_number: 'Invoice Number : ',
            search_item_batch_number: 'Batch Number : ',
            search_item_ids: 'Items : ',
            search_warehouse_from_ids: 'Dispatching Warehouse : ',
            search_warehouse_to_ids: 'Receiving Warehouse : ',
            search_item_tags: 'Item Tags : ',
            search_status_ids: 'Search Status : ',
            date_range: 'Date Range : ',
        }

        this.updateCommentFormInit = {
            status: '',
            notes: '',
            date_of_dispatch: '',
            date_of_receive: ''
        }

        this.updateDispatchFormInit = {
            eway_number: '',
            eway_date: '',
            document_number: '',
            document_date: '',
            transporter_number: '',
            vechicle_registration_no: '',
            no_of_boxes: '',
            weight: '',
            unit_of_weight: ''
        }

        this.initRemarkItemFormdata             =   {
            item_profile                        :   null,
            prev_remark                         :   '',
            remark                              :   '',
            transaction_id                      :   '',
            inventory_transfer_item_ids         :   []
        }

        this.state = {
            minimizeTable: false,
            listing_loading: false,
            listing_tbl_page: 1,
            inventory_listing: [],
            listingMeta: null,
            totalListingCount: 0,
            inventoryItemId: null,
            inventoryDetail: null,
            detail_loading: false,
            addCommentForm: { ...this.updateCommentFormInit },
            searchedItem: [],
            inventorySearchForm: { ...this.initInventorySearchForm },
            submittedInventoryFilterForm: { ...this.initInventorySearchForm },
            formSearchedElems: [],
            allAllowedStatus: [],
            addCommentFormSubmitting: false,
            pdfDownloading: false,
            all_warehouses: [],
            allDispatchingWarehouses: [],
            allReceivingWarehouses: [],
            allStatusOptions: [],
            allItemTags: [],
            updateDispatchForm: { ...this.updateDispatchFormInit },
            updateDispatchFormSubmitting: false,
            approved_indent_listing: false,
            indent_listing: [],
            remarkSubmitting: false,
            remarkItemFormdata: {...this.initRemarkItemFormdata},
            allWeightUnits: [{ value: "gm", label: "Gram" }, { value: "kg", label: "KilloGram" }, { value: "tonne", label: "Tonne" }]
        };
        this.commentRef = React.createRef();
        this.itemDetailsRef = React.createRef();
        this.indentDetailRef = React.createRef();
        this.LogisticViewModalRef = React.createRef();

        this.warehouseIndentItemWiseUrl = INVENTORY_API_BASE_URL_2 + '/warehouse_indent/item_wise';
        this.inventoryTransferReportUrl = INVENTORY_API_BASE_URL_2 + '/report/item-wise-inventory-tranfer';
        this.inventoryTransferUrl = INVENTORY_API_BASE_URL_2 + '/inventory_transfer';
        this.inventoryTransferListUrl           =   INVENTORY_API_BASE_URL_2 + '/inventory_transfers';
        this.itemRemarkEditUrl                  =   INVENTORY_API_BASE_URL_2 + '/inventory_transfer/item_remark';

    }

    componentDidMount() {
        this.loadListingTblData(1)
        this.initializeData(this.props)
        if (this.props.location && this.props.location.state && this.props.location.state.inventoryId) {
            this.viewInventoryTransferDetail(this.props.location.state.inventoryId)
        }
        this.inventorySearchModal = new Modal(document.getElementById('inventorySearchModal'), { keyboard: false, backdrop: false });
        this.updateDispatchModal = new Modal(document.getElementById('updateDispatchModal'), { keyboard: false, backdrop: false });
        this.updateRemarkModal = new Modal(document.getElementById('updateRemarkModal'), { keyboard: false, backdrop: false });
        this.linkIndentModal = new Modal(document.getElementById('linkIndentModal'), { keyboard: false, backdrop: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
        }
        let ParamObject = new URLSearchParams(this.props.location.search);
        if (ParamObject.get('search_id')) {
            let id = ParamObject.get('search_id')
            this.viewInventoryTransferDetail(id)
        }
    }

    initializeData = (pr) => {
        if (pr) {
            let all_periods = [...pr.all_periods, { key: 'custom', display: 'Custom Date Range' }];

            HttpAPICall.withAthorization('GET', this.inventoryTransferUrl + '/add_form_data', pr.access_token, null, null, (response) => {
                let formData = response.data;
                this.setState({
                    formDataLoaded: formData ? true : false,
                    all_periods: all_periods,
                    allItemTags: pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return { value: s.id, label: s.name }; }) : [],
                    allDispatchingWarehouses: pr.all_warehouses ? pr.all_warehouses.map((s) => { return { value: s.id, label: s.name, site_id: s.site_id }; }) : [],
                    allReceivingWarehouses: formData && formData.warehouses ? formData.warehouses.map((s) => { return { value: s.id, label: s.name, site_id: s.site_id }; }) : [],
                    allStatusOptions: formData && formData.status ? formData.status.map((s) => { return { value: s.key, label: s.status } }) : [],
                })
            }, (err) => {

            });
        }
    }

    //************************GET PO LISTING**************** */
    loadListingTblData = (page = 1) => {
        this.setState({ listing_loading: true, listing_tbl_page: page });
        let params = { ...this.state.submittedInventoryFilterForm, page: page };
        HttpAPICall.withAthorization('GET', this.inventoryTransferListUrl, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                inventory_listing: respData.data,
                listingMeta: respData.meta,
                totalListingCount: respData.meta.total,
            });
        }).then(() => this.setState({ listing_loading: false }));
    }

    openAddInventoryTransferScreen = () => {
        this.props.history.push('/inventory_transfer_list/add')
    }

    viewInventoryTransferDetail = (transaction_id) => {
        this.setState({ minimizeTable: true })
        if (transaction_id !== this.state.inventoryItemId) {
            this.getInventoryTransferDetail(transaction_id)
        }
    }

    inventorySearchModalInit = () => {
        this.inventorySearchModal.show()
    }

    submitInventorySearchForm = (e) => {
        e && e.preventDefault()
        let serachFormKeys = Object.keys(this.state.inventorySearchForm);

        let searchedElems = [];
        let searchParams = {};
        serachFormKeys.map((key) => {

            let label = this.inventorySearchFormLabel[key];
            let value = this.state.inventorySearchForm[key];

            if (value && value.length > 0) {
                searchParams[key] = value;
                if (label) {

                    let show_val = value;

                    if (key == 'search_like_transaction_id') {
                        show_val = this.state.inventorySearchForm ? this.state.inventorySearchForm.search_like_transaction_id : '';
                    }
                    if (key == 'search_invoice_number') {
                        show_val = this.state.inventorySearchForm ? this.state.inventorySearchForm.search_invoice_number : '';
                    }
                    if (key == 'search_item_batch_number') {
                        show_val = this.state.inventorySearchForm ? this.state.inventorySearchForm.search_item_batch_number : '';
                    }
                    if (key == 'search_eway_number') {
                        show_val = this.state.inventorySearchForm ? this.state.inventorySearchForm.search_eway_number : '';
                    }
                    if (key == 'search_item_ids') {
                        show_val = this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if (key == 'search_warehouse_from_ids') {
                        show_val = this.state.allDispatchingWarehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_warehouse_to_ids') {
                        show_val = this.state.allReceivingWarehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_item_tags') {
                        show_val = this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_status_ids') {
                        show_val = this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'date_range') {
                        let start_range = moment(this.state.inventorySearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.inventorySearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }

                    searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedInventoryFilterForm: { ...this.state.inventorySearchForm },
            formSearchedElems: searchedElems
        }, () => {
            this.inventorySearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    inventorySearchClear = () => {
        this.setState({
            formSearchedElems: [],
            inventorySearchForm: { ...this.initInventorySearchForm },
            submittedInventoryFilterForm: { ...this.initInventorySearchForm },
            searchedItem: [],
        }, () => {
            this.loadListingTblData(1);
            this.inventorySearchModal.hide();
        });
    }

    //***********************DOWNLOAD PDF **************************** */
    downloadPdfHandler = (transaction_id, key) => {
        this.setState({ pdfDownloading: true });
        HttpAPICall.withAthorization('GET', this.inventoryTransferUrl + '/' + transaction_id, this.props.access_token, { download: key }, {}, (response) => {
            download.file(response.data.file_path);
        })
            .then(() => this.setState({ pdfDownloading: false }));
    }

    getInventoryTransferDetail = (transaction_id) => {
        this.setState({ detail_loading: true })
        HttpAPICall.withAthorization('GET', this.inventoryTransferUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                inventoryDetail: respData.data,
                inventoryItemId: respData.data.transaction_id,
                allAllowedStatus: respData.data && respData.data.userStatus && respData.data.userStatus.length > 0 ? respData.data.userStatus.map((s) => { return { value: s.key, label: s.status }; }) : [],
                addCommentForm: { ...this.updateCommentFormInit, status: respData.data.status }
            });

        }, (err) => {
            if (err) {
                this.setState({ minimizeTable: false })
            }
        }).then(() => this.setState({ detail_loading: false }));
    }

    openUpdateInventoryScreen = (transaction_id) => {
        if (!this.state.inventoryDetail) {
            HttpAPICall.withAthorization('GET', this.inventoryTransferUrl + '/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.props.history.push({ pathname: `/inventory_transfer_list/add`, state: { inventoryTransferData: respData.data } })
            })
        } else {
            this.props.history.push({ pathname: `/inventory_transfer_list/add`, state: { inventoryTransferData: this.state.inventoryDetail } })
        }
    }

    closeViewDetails = () => {
        this.setState({ minimizeTable: false })
    }

    updateComment = () => {
        this.commentRef.current.scrollIntoView()
    }

    //*************************SUBMIT COMMENT FORM ********************** */
    submitUpdateStatusForm = (e) => {
        e.preventDefault()
        this.setState({ addCommentFormSubmitting: true })
        let frmData = {
            ...this.state.addCommentForm,
            transaction_id: this.state.inventoryItemId
        }

        HttpAPICall.withAthorization('PUT', this.inventoryTransferUrl + '/change_status', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getInventoryTransferDetail(this.state.inventoryItemId);
            this.loadListingTblData(1)
            this.setState({ addCommentForm: { ...this.updateCommentFormInit } })
        }).then(() => this.setState({ addCommentFormSubmitting: false }));
    }

    //***************************DELETE INVENTORY TRANSFER ******************** */
    deleteInventoryTransfer = (transaction_id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.inventoryTransferUrl + '/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({ minimizeTable: false })
                        this.loadListingTblData(1)
                    })
                }
            });
    }

    viewItemModalInit = (item) => {
        if (item) {
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    //***************************UPDATE DISPATCH DETAIL ******************** */
    updateDispatchModalInit = () => {
        this.updateDispatchModal.show();
        if (document.getElementById("updateDispatchForm")) {
            let form = document.getElementById("updateDispatchForm")
            form.reset();
        }

        if (this.state.inventoryDetail) {
            let detail = this.state.inventoryDetail;
            this.setState({
                updateDispatchForm: {
                    transaction_id: detail.transaction_id ? detail.transaction_id : '',
                    eway_number: detail.eway_number ? detail.eway_number : '',
                    eway_date: detail.eway_date ? detail.eway_date : null,
                    document_number: detail.document_number ? detail.document_number : '',
                    document_date: detail.document_date ? detail.document_date : null,
                    transporter_number: detail.transporter_number ? detail.transporter_number : '',
                    vechicle_registration_no: detail.vechicle_registration_no ? detail.vechicle_registration_no : '',
                    no_of_boxes: detail.no_of_boxes ? detail.no_of_boxes : '',
                    weight: detail.weight ? detail.weight : '',
                    unit_of_weight: detail.unit_of_weight ? detail.unit_of_weight : '',
                    date_of_dispatch: detail.date_of_dispatch ? detail.date_of_dispatch : '',
                    date_of_receive: detail.date_of_receive ? detail.date_of_receive : ''
                }
            })
        }
    }
    //***************************UPDATE ITEM REMARKS ******************** */
    updateRemarksModalInit = (indent_item, item_profile) => {
        let transaction_item        =   indent_item.items[0];
        this.setState({
            remarkItemFormdata  :   {
                ...this.state.remarkItemFormdata,
                item_profile                :   item_profile,
                prev_remark                 :   transaction_item.remark,
                remark                      :   transaction_item.remark,
                transaction_id              :   transaction_item.transaction_id,
                inventory_transfer_item_id  :   transaction_item.id,
                inventory_transfer_item_ids :   indent_item.items.map(i => i.id)
            }
        }, () => {
            this.updateRemarkModal.show();
        });
    }

    handleRemarkForm = (e) => {
        e.preventDefault();
        let { item_profile, inventory_transfer_item_id, transaction_id, remark, prev_remark } = this.state.remarkItemFormdata;

        if (!item_profile) {
            toast.error('Item not found', { position: toast.POSITION.TOP_RIGHT });
        } else if (prev_remark == remark) {
            this.updateRemarkModal.hide();
        } else {
            this.setState({ remarkSubmitting: true });
            let data            =   {
                inventory_transfer_item_id  :   inventory_transfer_item_id,
                transaction_id              :   transaction_id,
                remark                      :   remark
            };
            HttpAPICall.withAthorization('PUT', this.itemRemarkEditUrl, this.props.access_token, {}, data, (response) => {
                this.getInventoryTransferDetail(transaction_id);
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            }).then(() => {
                this.updateRemarkModal.hide();
                this.setState({ remarkSubmitting: false });
            });
        }
    }

    submitUpdateDispatchForm = (e) => {
        e.preventDefault();
        this.setState({ updateDispatchFormSubmitting: true })
        let frmData = {
            ...this.state.updateDispatchForm,
            transaction_id: this.state.inventoryItemId
        }
        HttpAPICall.withAthorization('PUT', this.inventoryTransferUrl + '/edit_dispatch_detail', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getInventoryTransferDetail(this.state.inventoryItemId);
            this.loadListingTblData(1)
            this.setState({ updateDispatchForm: { ...this.updateDispatchFormInit } })
            this.updateDispatchModal.hide()
        }).then(() => this.setState({ updateDispatchFormSubmitting: false }));
    }

    checkAllCancelItem() {

        let poItemCollections = document.getElementsByClassName('inventory_item_id_checkbox')
        if (poItemCollections && poItemCollections.length > 0) {
            for (let i = 0; i < poItemCollections.length; i++) {
                poItemCollections[i].checked = document.getElementById('all_check_po').checked;
            }
        }
    }

    addToConsumptionModalInit = () => {
        // this.setState({cancelledItemList : []} , () => {
        let itemList = [];
        var showErrorMessage = false;
        let allocationItemForCheckbox = document.getElementsByClassName('inventory_item_id_checkbox');
        if (allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                if (allocationItemForCheckbox[i].checked) {
                    let itemId = allocationItemForCheckbox[i].value;
                    let data = this.state.inventoryDetail.transfer_items.find(i => i.item_profile.id == itemId);
                    itemList.push({ item: data.item_profile })
                }
            }
        }
        if (itemList && itemList.length == 0 && !showErrorMessage) {
            toast.error('Please select an Item', { position: toast.POSITION.TOP_RIGHT });
        } else {

            let consumptionData = { items: itemList, warehouse_id: this.state.inventoryDetail.warehouse_to }

            this.props.history.push({ pathname: `/consumption_list/add`, state: { addConsumptionFromInventoryTransfer: consumptionData } })
        }

    }

    addToAllocationModalInit = () => {
        // this.setState({cancelledItemList : []} , () => {
        let itemList = [];
        var showErrorMessage = false;
        let allocationItemForCheckbox = document.getElementsByClassName('inventory_item_id_checkbox');
        if (allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                if (allocationItemForCheckbox[i].checked) {
                    let itemId = allocationItemForCheckbox[i].value;
                    let data = this.state.inventoryDetail.transfer_items.find(i => i.item_profile.id == itemId);
                    itemList.push({ item: data.item_profile })
                }
            }
        }
        if (itemList && itemList.length == 0 && !showErrorMessage) {
            toast.error('Please select an Item', { position: toast.POSITION.TOP_RIGHT });
        } else {

            let consumptionData = { items: itemList, warehouse_id: this.state.inventoryDetail.warehouse_to }

            this.props.history.push({ pathname: `/allocation_list/add`, state: { addAllocationFromInventoryTransfer: consumptionData } })
        }

    }

    viewIndentDetailModalInit = (transaction_id) => {
        if (transaction_id) {
            this.indentDetailRef.current.modalIndentInit(transaction_id)
        }
    }

    downloadItemWiseHandler = (transaction_id) => {
        this.setState({ pdfDownloading: true });
        HttpAPICall.withAthorization('GET', this.inventoryTransferReportUrl, this.props.access_token, { action: "download", search_like_transaction_id: transaction_id }, {}, (response) => {
            download.file(response.data.file_path);
        })
            .then(() => this.setState({ pdfDownloading: false }));
    }

    linkToIndentInit = () => {

        let itemList = [];
        let indentLinked = [];
        let purchaseQty = [];
        let selectedItemIds = [];
        var showErrorMessage = false;
        let allocationItemForCheckbox = document.getElementsByClassName('inventory_item_id_checkbox');
        if (allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                if (allocationItemForCheckbox[i].checked) {
                    let itemId = allocationItemForCheckbox[i].value;
                    let itemIds = allocationItemForCheckbox[i].getAttribute("data-itemIds");
                    itemList.push(itemId);
                    indentLinked.push(allocationItemForCheckbox[i].getAttribute("data-indentId"));
                    purchaseQty.push(allocationItemForCheckbox[i].getAttribute("data-purchaseQty"));
                    if (itemIds) {
                        selectedItemIds = selectedItemIds.concat(itemIds.split(',').map(Number));
                    }
                }
            }
        }

        let indentList = indentLinked && indentLinked.length > 0 ? indentLinked.filter(indent => indent !== null) : indentLinked;

        if (itemList && itemList.length == 0 && !showErrorMessage) {
            toast.error('Please select an Item', { position: toast.POSITION.TOP_RIGHT });
        } else if (itemList && itemList.length > 1 && !showErrorMessage) {
            toast.error('Only one Item can be adjusted at a time', { position: toast.POSITION.TOP_RIGHT });
        } else if (indentList && indentList.length >= 1 && !showErrorMessage) {
            toast.error('Indent is already Linked with this Item', { position: toast.POSITION.TOP_RIGHT });
        } else {
            this.linkIndentModal.show();
            this.getIndentList(itemList[0], purchaseQty[0], selectedItemIds[0]);
        }
    }

    getIndentList = (item_id, purchase_qty = 0, selected_item_id) => {
        this.setState({ approved_indent_listing: true })
        let obj = { min_purchase_or_pending_qty: purchase_qty, search_transaction_id: this.state.indent_transaction_id }

        let params = {
            search_receiving_warehouse: this.state.inventoryDetail ? this.state.inventoryDetail.warehouse_to : "",
            ...obj,
            search_status_ids: [4],
            page: 1,
            search_item_ids: [item_id]
        }
        HttpAPICall.withAthorization('GET', this.warehouseIndentItemWiseUrl, this.props.access_token, { ...params }, {}, (response) => {
            let respData = response.data;
            this.setState({
                indent_listing: respData.data && respData.data.length > 0 ? respData.data.map(data => { return ({ ...data, transfer_qty: purchase_qty, warehouse_indent_item_id: selected_item_id }) }) : [],
            }, () => {

            });
        }).then(() => this.setState({ approved_indent_listing: false }))
    }

    checkAllIndentItem() {

        let poItemCollections = document.getElementsByClassName('indent_item_id_checkbox')
        if (poItemCollections && poItemCollections.length > 0) {
            for (let i = 0; i < poItemCollections.length; i++) {
                poItemCollections[i].checked = document.getElementById('all_check_indent').checked;
            }
        }
    }

    submitLinkIndentForm = (e) => {
        e && e.preventDefault();

        let indentLinked = [];
        let warehouseIndentId = [];
        let selectedItemIds = []; // Array to hold all selected item IDs
        var showErrorMessage = false;

        let allocationItemForCheckbox = document.getElementsByClassName('indent_item_id_checkbox');

        if (allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                if (allocationItemForCheckbox[i].checked) {
                    let id = allocationItemForCheckbox[i].value;
                    indentLinked.push(id);
                    warehouseIndentId.push(allocationItemForCheckbox[i].getAttribute("data-warehouseItemId"));
                    selectedItemIds.push(allocationItemForCheckbox[i].getAttribute("data-warehouseIndentItemId"));

                }
            }
        }

        let indentList = indentLinked && indentLinked.length > 0 ? indentLinked.filter(indent => indent !== null) : indentLinked;
        let warehouseIndent = warehouseIndentId && warehouseIndentId.length > 0 ? warehouseIndentId.filter(indent => indent !== null) : warehouseIndentId;
        let selectedItemId = selectedItemIds && selectedItemIds.length > 0 ? selectedItemIds.filter(indent => indent !== null) : selectedItemIds;

        if (indentList && indentList.length == 0 && !showErrorMessage) {
            toast.error('Please select an Item', { position: toast.POSITION.TOP_RIGHT });
        } else if (indentList && indentList.length > 1 && !showErrorMessage) {
            toast.error('Only one Item can be Linked at a time', { position: toast.POSITION.TOP_RIGHT });
        } else {


            let frmData = {
                inventory_transfer_item_id: selectedItemId && selectedItemId.length > 0 ? selectedItemId[0] : '',
                warehouse_indent_transaction_id: warehouseIndent[0],
                warehouse_indent_item_id: indentList[0],
                transaction_id: this.state.inventoryItemId,
            };

            HttpAPICall.withAthorization('PUT', this.inventoryTransferUrl + '/item_link_with_indent', this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.getInventoryTransferDetail(this.state.inventoryItemId);
                this.linkIndentModal.hide();
            }).then(() => this.setState({ updateDispatchFormSubmitting: false }));
        }
    };






    inventoryTransferListjsx = () => {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Inventory Transfer </h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button" disabled={!this.props.permissions.includes('isc-inventory_transfer-add')} className="btn btn-primary" onClick={this.openAddInventoryTransferScreen}>New Inventory Transfer</button> : null}

                    <button type="button" className="btn btn-secondary" onClick={this.inventorySearchModalInit} disabled={this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    {this.state.minimizeTable && this.props.permissions.includes('isc-inventory_transfer-add') ? <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" href="" onClick={this.openAddInventoryTransferScreen} >New Inventory Transfer</a></li>
                        </ul>
                    </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Inventory Transfer : {this.state.inventoryItemId} </h3>
                            <div className="text-end mt15">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    disabled={this.state.detail_loading}
                                    onClick={() => this.updateComment()} >Update Status </button>
                                <button
                                    disabled={this.state.pdfDownloading || this.state.detail_loading}
                                    type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                    {this.state.pdfDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : ''}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <Link className="dropdown-item" disabled={this.state.detail_loading} onClick={() => this.downloadPdfHandler(this.state.inventoryItemId, 'download')}>Download</Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" disabled={this.state.detail_loading} onClick={() => this.downloadPdfHandler(this.state.inventoryItemId, 'gatepass')}>Download Gatepass</Link>
                                    </li>
                                    <li>
                                        <a href='#' className={['dropdown-item', (this.state.detail_loading) ? 'disabled' : ''].join(' ')} onClick={() => this.downloadItemWiseHandler(this.state.inventoryItemId)}>Item Wise Download</a>
                                    </li>
                                </ul>

                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                    <li>
                                        <a href='#' className={['dropdown-item', (this.state.detail_loading || this.state.inventoryDetail?.can_edit == 'N' || !this.props.permissions.includes('isc-inventory_transfer-edit')) ? 'disabled' : ''].join(' ')} onClick={() => this.openUpdateInventoryScreen(this.state.inventoryItemId)}>Edit</a>
                                    </li>
                                    <li>
                                        <a href='#' className={['dropdown-item', (this.state.detail_loading || this.state.inventoryDetail?.can_delete == "N" || !this.props.permissions.includes('isc-inventory_transfer-delete')) ? 'disabled' : ''].join(' ')} onClick={() => this.deleteInventoryTransfer(this.state.inventoryItemId)}>Delete</a>
                                    </li>
                                    <li>
                                        <a href='#' className={['dropdown-item', (this.state.detail_loading || this.state.inventoryDetail?.status !== 36 || !this.props.permissions.includes('isc-consumption-add')) ? 'disabled' : ''].join(' ')} onClick={() => this.addToConsumptionModalInit(this.state.inventoryItemId)}>Add To Consumption</a>
                                    </li>
                                    <li>
                                        <a href='#' className={['dropdown-item', (this.state.detail_loading || this.state.inventoryDetail?.status !== 36 || !this.props.permissions.includes('isc-allocation-add')) ? 'disabled' : ''].join(' ')} onClick={() => this.addToAllocationModalInit(this.state.inventoryItemId)}>Add To Allocation</a>
                                    </li>
                                    <li>
                                        <a href='#' className={['dropdown-item', (this.state.detail_loading || this.state.inventoryDetail?.status !== 36) ? 'disabled' : ''].join(' ')} onClick={() => this.linkToIndentInit(this.state.inventoryItemId)}>Adjust Item Against Approved Indent</a>
                                    </li>


                                </ul>

                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.inventoryTransferListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.inventoryTransferViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    inventoryTransferListingTableJsx = () => {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.inventorySearchClear} />
            <table className="table table-bordered table-fixed  bg-white">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Transfer Order ID (Date)</th>
                        </tr>)
                        : (<tr className='text-center'>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "10%" }}>Date</th>
                            <th scope="col" style={{ width: "12%" }}> Transfer Order ID</th>
                            <th scope="col" style={{ width: "21%" }} className='text-start'>Dispatching Warehouse</th>
                            <th scope="col" style={{ width: "20%" }} className='text-start'>Receiving Warehouse</th>
                            <th scope="col" style={{ width: "8%" }} className='text-end'>Total Quantity</th>
                            <th scope="col" style={{ width: "8%" }} className='text-end'>Amount</th>
                            <th scope="col" style={{ width: "10%" }}>Status</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="8"><Loader /></td></tr>)
                        : (this.state.inventory_listing.length > 0
                            ? (this.state.inventory_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td>
                                                        <a role="button" className="link-primary" onClick={() => this.viewInventoryTransferDetail(item.transaction_id)} >{item.transaction_id}</a>
                                                        <br /><small>{item.transaction_date_display}</small>
                                                        <small style={{ float: 'right' }}><Status color={item.status_color}>{item.status_text}</Status></small>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                                <td className='text-center'>{item.transaction_date_display}</td>
                                                <td className='text-center' onClick={() => this.viewInventoryTransferDetail(item.transaction_id)}><a role="button" className="link-primary text-center">{item.transaction_id}</a></td>

                                                <td>{item.warehouse_from_data ? item.warehouse_from_data.name : '-'}</td>
                                                <td className='text-capitalize'>{item.warehouse_to_data ? <div>{item.warehouse_to_data.name}</div>
                                                    : "-"}</td>

                                                <td className="text-end">{parseInt(item.total_qty)}</td>
                                                <td className="text-end"><CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(item.total_amount ? item.total_amount : 0).toFixed(2)} /></td>
                                                <td className='text-center'><Status color={item.status_color}>{item.status_text}</Status></td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewInventoryTransferDetail(item.transaction_id)}>View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="7=9" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    logisticDetailModal = (val) => {
        this.LogisticViewModalRef.current.showModalInit(val);
    }

    inventoryTransferViewDetails = () => {
        let inventoryTransfer = this.state.inventoryDetail;
        let serialNumberCounter = 0;
        const dispatchingDetail = inventoryTransfer?.dispatching_address ? inventoryTransfer.dispatching_address : inventoryTransfer?.warehouse_from_data;
        const receivingDetail = inventoryTransfer?.receiving_address ? inventoryTransfer.receiving_address : inventoryTransfer?.warehouse_to_data;
        return (<div className='tab_content_header '>
            {!this.state.detail_loading
                ? (<Ax>
                    {inventoryTransfer
                        ? <div>
                            <table className="table table-bordered bg-white" >
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{inventoryTransfer.transaction_id}</td>
                                        <td scope="col">{inventoryTransfer.transaction_date_display}</td>
                                        <td scope="col" className="align-middle">
                                            {inventoryTransfer.status_text ? inventoryTransfer.status_text : ''}
                                        </td>  </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >Dispatching Warehouse</td>
                                        <td colSpan={2}>{inventoryTransfer.warehouse_from_data ? <div><b>{inventoryTransfer.warehouse_from_data.name} ({inventoryTransfer.warehouse_from_data.code})</b> <br />
                                            {dispatchingDetail ?
                                                <div className='pt-1'>Address : {dispatchingDetail.address1 ? dispatchingDetail.address1 + ", " : ""}
                                                    {dispatchingDetail.address2 ? dispatchingDetail.address2 + ", " : ""}
                                                    {dispatchingDetail.landmark ? dispatchingDetail.landmark + ", " : ""}
                                                    {dispatchingDetail.city ? dispatchingDetail.city + ". " : "-"} <br />
                                                    Destination of Supply : <b>{dispatchingDetail.state_name}</b> <br />
                                                    GSTIN  : {dispatchingDetail.gstin ?? "-"} <br />
                                                </div> : "-"}</div> : "-"} <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} >Receiving Warehouse</td>

                                        <td colSpan={2}>{inventoryTransfer.warehouse_to_data ? <div><b>{inventoryTransfer.warehouse_to_data.name} ({inventoryTransfer.warehouse_to_data.code})</b> <br />
                                            {receivingDetail ?
                                                <div className='pt-1'>Address : {receivingDetail.address1 ? receivingDetail.address1 + ", " : ""}
                                                    {receivingDetail.address2 ? receivingDetail.address2 + ", " : ""}
                                                    {receivingDetail.landmark ? receivingDetail.landmark + ", " : ""}
                                                    {receivingDetail.city ? receivingDetail.city + ". " : "-"} <br />
                                                    Destination of Supply : <b>{receivingDetail.state_name}</b> <br />
                                                    GSTIN  : {receivingDetail.gstin ?? "-"} <br />
                                                </div> : "-"}</div> : "-"} <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Invoice Number &  Date</td>
                                        <td style={{ width: "33%" }} className="text-capitalize">{inventoryTransfer.invoice_number ?? "-"}</td>
                                        <td style={{ width: "34%" }}>{inventoryTransfer.invoice_date_display ?? "-"}</td>

                                    </tr>
                                    <tr>
                                        <td scope="col">Logistic Details</td>
                                        <td colSpan={2}>
                                            {inventoryTransfer.logistic_edd_data && inventoryTransfer.logistic_edd_data.length > 0 && inventoryTransfer.logistic_edd_data.map((d, k) => {
                                                return <div><Link key={k} onClick={() => this.logisticDetailModal(d.transaction_id)} >{d.transaction_id} (EDD : {d.delivery_date ? DateService.dateFormat(d.delivery_date) : 'NA'})</Link></div>
                                            })}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table table-hover table-bordered table-responsive bg-white my-3" id="poTable">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ "width": '5%' }} className="text-center">
                                            <input type='checkbox' onChange={this.checkAllCancelItem} name="chk[]" id="all_check_po" />
                                        </th>
                                        <th style={{ width: "32%" }}>Item Details</th>
                                        <th style={{ width: "10%" }} className="text-center">Indent</th>
                                        {this.props.isc_configuration?.track_batch_expiry == "Y" ?
                                            <th style={{ width: "21%" }} className="text-center">Batch Number & <br /> Expiry Date</th>
                                            : null}
                                        <th style={{ width: "8%" }} className="text-end">Quantity</th>
                                        <th style={{ width: "8%" }} className="text-end">Unit Rate</th>
                                        <th style={{ width: "8%" }} className="text-end">Tax</th>
                                        <th style={{ width: "8%" }} className="text-end">Amount</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inventoryTransfer && inventoryTransfer.transfer_items && inventoryTransfer.transfer_items.length > 0 ? inventoryTransfer.transfer_items?.map((i, k) => {
                                            return i.warehouse_indents.map((indent, rk) => {
                                                let total_qty = indent.items && indent.items.length > 0 && indent.items.map(s => s.qty).reduce((a, b) => a + b, 0);
                                                let total_rate = indent.items && indent.items.length > 0 ? indent.items[0].rate : 0;
                                                let remark = indent.items && indent.items.length > 0 ? indent.items[0].remark : '';
                                                serialNumberCounter++;
                                                let itemIds = indent.items.map(item => item.id).join(',');
                                                return (<tr key={k}>
                                                    <td className='text-center'>
                                                        {serialNumberCounter} <br /> <br />
                                                        <input
                                                            type='checkbox'
                                                            className="inventory_item_id_checkbox"
                                                            value={i.item_profile.id}
                                                            data-indentId={indent.indent_transaction_id ? indent.indent_transaction_id : null}
                                                            data-purchaseQty={total_qty}
                                                            data-itemIds={itemIds}
                                                        />
                                                    </td>
                                                    <td className="text-start">{i.item_profile && <div className='row'>
                                                        <div className='col-sm-10'>
                                                            <ItemViewInList item={i.item_profile} remark={remark} viewItemModal={() => this.viewItemModalInit(i.item_profile)} />
                                                        </div>
                                                        <div className='col-sm-2 text-end'>
                                                            <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="px-2" data-bs-toggle="dropdown" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                                                <li className='dropdown-item py-1' onClick={() => this.updateRemarksModalInit(indent, i.item_profile)}>
                                                                    <button type='button' className='btn btn-link text-start text-decoration-none w-100 text-dark mx-0'>Update Remarks</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        </div>}
                                                    </td>
                                                    <td className="text-center  link-primary cursor_pointer" onClick={() => this.viewIndentDetailModalInit(indent.indent_transaction_id)}>{indent.indent_transaction_id ? indent.indent_transaction_id : "-"}</td>
                                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ?
                                                        <td className='text-center'>
                                                            {indent.items && indent.items.length > 0 ?
                                                                indent.items.map((itemData, rk) => {
                                                                    return (
                                                                        <div>{itemData.batch_number && itemData.expiry_date_display ? <span>{itemData.batch_number} & {itemData.expiry_date_display}</span> : itemData.batch_number && !itemData.expiry_date_display ? <span>{itemData.batch_number} </span> : !itemData.batch_number && itemData.expiry_date_display ? <span>{itemData.expiry_date_display} </span> : <span>BNA <sup>#</sup></span>} : <b> {itemData.qty}</b> </div>
                                                                    )
                                                                }) : null}

                                                        </td> : null}
                                                    <td className='text-end'> {indent.items && indent.items.length > 0 && indent.items.map(s => s.qty).reduce((a, b) => a + b, 0)}
                                                        {i.item_profile.measuring_unit && i.item_profile.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item_profile?.measuring_unit?.name}</span> : null}

                                                    </td>
                                                    <td className='text-end'>{indent.items && indent.items.length > 0 ? indent.items[0].rate : 0} </td>
                                                    <td className='text-end'>{indent.items && indent.items.length > 0 ? indent.items[0].gst : 0} %
                                                        {inventoryTransfer && inventoryTransfer.warehouse_from_data.gstin ? <span className="text-helping input-group-text text-end pr0">{
                                                            inventoryTransfer.dispatching_address && inventoryTransfer.receiving_address ? (inventoryTransfer.dispatching_address?.state_id == inventoryTransfer.receiving_address?.state_id) ? "GST" : "IGST"
                                                                : inventoryTransfer.warehouse_to_data && inventoryTransfer.warehouse_to_data?.state_id == inventoryTransfer?.warehouse_from_data?.state_id ? "GST" : "IGST"}</span> : null
                                                        }
                                                    </td>
                                                    <td className='text-end'><CurrencyFormatter currencyCode={this.props.default_currency} amount={total_qty * total_rate} /></td>


                                                </tr>)

                                            })

                                        }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <label htmlFor="notes" className="form-label">Notes</label>
                                    <div>{inventoryTransfer.notes ?? "-"}</div>

                                </div>
                                <div className="col-6">
                                    <table className="table  table-borderless">
                                        <tbody>
                                            <tr className="subtotal_bg_color">
                                                <th>Sub-Total</th>
                                                <td className='float-end'>{inventoryTransfer.total_amount ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={(parseFloat(inventoryTransfer.total_amount) - (parseFloat(inventoryTransfer.adjustment_amount ?? 0.0) + parseFloat(inventoryTransfer.total_tax))).toFixed(2)} /> : 0.00}</td>
                                            </tr>
                                            {inventoryTransfer.tax_slabs && inventoryTransfer.tax_slabs.length > 0
                                                ? (inventoryTransfer.tax_slabs.map((ps, k) => {
                                                    return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                }))
                                                : null
                                            }
                                            <tr className="subtotal_bg_color">
                                                <th>Adjustment</th>
                                                <td className='float-end'>{inventoryTransfer.adjustment_amount ?? 0}</td>
                                            </tr>
                                            <tr className="subtotal_bg_color">
                                                <th>Final Total</th>
                                                <td className='float-end'>{inventoryTransfer.total_amount ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={inventoryTransfer.total_amount} /> : 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab_content_wrapper my-1">
                                <span className="content_heading">Dispatching Details</span>
                                <div className='text-end'>
                                    <Link role="button" className="link-primary" onClick={() => this.updateDispatchModalInit()}><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Update Dispatch Detail </Link>
                                </div>
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-3">
                                <tbody>

                                    <tr>
                                        <td scope="col" style={{ width: "33%" }}>Eway Bill Number</td>
                                        <th style={{ width: "20%" }} className="text-capitalize">{inventoryTransfer.eway_number ?? "-"}</th>
                                        <td scope="col">Eway Bill Date</td>
                                        <th style={{ width: "25%" }}>{inventoryTransfer.eway_date_display ?? "-"}</th>

                                    </tr>
                                    <tr>
                                        <td scope="col">Docket Number</td>
                                        <th className="text-capitalize">{inventoryTransfer.document_number ?? "-"}</th>
                                        <td scope="col">Docket Date</td>
                                        <th>{inventoryTransfer.document_date_display ?? "-"}</th>

                                    </tr>
                                    <tr>
                                        <td scope="col">Transporter Name</td>
                                        <th className="text-capitalize"> {inventoryTransfer.transporter_number ? inventoryTransfer.transporter_number : "-"}</th>
                                        <td scope="col">Vehicle No</td>
                                        <th>{inventoryTransfer.vechicle_registration_no ? inventoryTransfer.vechicle_registration_no : "-"}</th>

                                    </tr>
                                    <tr>
                                        <td scope="col">Number of Boxes</td>
                                        <th className="text-capitalize">{inventoryTransfer.no_of_boxes ? inventoryTransfer.no_of_boxes : "-"}</th>
                                        <td scope="col">Weight</td>
                                        <th>{inventoryTransfer.weight ? inventoryTransfer.weight : "-"} {inventoryTransfer.unit_of_weight ? inventoryTransfer.unit_of_weight : ""}</th>

                                    </tr>
                                    <tr>
                                        <td scope="col">Date of Dispatch</td>
                                        <th className="text-capitalize">{inventoryTransfer.date_of_dispatch ? DateService.dateTimeFormat(inventoryTransfer.date_of_dispatch, 'DD-MMM-YYYY') : "-"}</th>
                                        <td scope="col">Date of Receipt</td>
                                        <th>{inventoryTransfer.date_of_receive ? DateService.dateTimeFormat(inventoryTransfer.date_of_receive, 'DD-MMM-YYYY') : "-"} </th>

                                    </tr>
                                </tbody>
                            </table>
                            <div >
                                <div className="tab_content_wrapper mt-3" ref={this.commentRef}>
                                    <span className="content_heading">Comments & History</span>
                                </div>
                                <form onSubmit={this.submitUpdateStatusForm}>
                                    <div className="my-3 row">
                                        <label className="col-sm-3 col-form-label">Change status to</label>
                                        <div className="col-sm-5">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addCommentForm', 'status', () => {
                                                        this.setState({
                                                            addCommentForm: { ...this.state.addCommentForm, date_of_dispatch: '', date_of_receive: '' }
                                                        })
                                                    });
                                                }}
                                                value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                                options={this.state.allAllowedStatus}
                                                isSearchable={false}
                                                isDisabled={this.state.inventoryDetail?.status == 36 || !this.props.permissions.includes('isc-inventory_transfer-status-change')}
                                                placeholder="Please Select Status"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-3 row">
                                        {(inventoryTransfer.status !== 36 && inventoryTransfer.status !== 35 && (this.state.addCommentForm.status == 35 || this.state.addCommentForm.status == 36)) && <Ax>
                                            <label className="col-sm-3 col-form-label require">Date of Dispatch</label>
                                            <div className="col-sm-4 add_calender_section">
                                                <DatePicker
                                                    selected={this.state.addCommentForm.date_of_dispatch ? moment(this.state.addCommentForm.date_of_dispatch, 'YYYY-MM-DD').toDate() : false}
                                                    name="date_of_dispatch"
                                                    onChange={(value, event) => this.formDateHandler('date_of_dispatch', value, 'addCommentForm')}
                                                    dateFormat="dd-MMM-yyyy"
                                                    maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                    className="form-control"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    autoComplete="off"
                                                    scrollMonthYearDropdown
                                                    required={true}
                                                    placeholderText="Please Enter Date of Dispatch"
                                                />
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                            </div>
                                        </Ax>}
                                        {(inventoryTransfer && inventoryTransfer.status !== 36 && this.state.addCommentForm.status == 36) && <Ax>
                                            <label className={[this.state.addCommentForm.status == 36 && inventoryTransfer && inventoryTransfer.status == 35 ? 'col-sm-3' : 'col-sm-2', 'col-form-label', 'require'].join(' ')}>Date of Receipt</label>
                                            <div className={this.state.addCommentForm.status == 36 && inventoryTransfer && inventoryTransfer.status == 35 ? "col-sm-5 add_calender_section " : "col-sm-3 add_calender_section"}>
                                                <DatePicker
                                                    selected={this.state.addCommentForm.date_of_receive ? moment(this.state.addCommentForm.date_of_receive, 'YYYY-MM-DD').toDate() : false}
                                                    name="date_of_receive"
                                                    onChange={(value, event) => this.formDateHandler('date_of_receive', value, 'addCommentForm')}
                                                    dateFormat="dd-MMM-yyyy"
                                                    maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                    minDate={moment(this.state.addCommentForm.date_of_dispatch, 'YYYY-MM-DD').toDate()}
                                                    className="form-control "
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    autoComplete="off"
                                                    scrollMonthYearDropdown
                                                    required={true}
                                                    placeholderText="Please Enter Date of Receipt"
                                                />
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                            </div>
                                        </Ax>}
                                    </div>
                                    <div className="my-2">
                                        <label className="form-label">Comments</label>
                                        <textarea
                                            name="notes"
                                            value={this.state.addCommentForm.notes}
                                            onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                            className="form-control"
                                            placeholder="Please Add a comment"
                                            required
                                            style={{ height: "100px" }}
                                        />
                                    </div>
                                    <div className="col-12 text-end">
                                        <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                            Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                        </button>
                                    </div>
                                </form>

                            </div>
                            <hr />
                            <div className="my-1">
                                {
                                    inventoryTransfer.comments && inventoryTransfer.comments.length > 0 ? inventoryTransfer.comments.map((c, k) => {
                                        return (<Ax key={k}><div className="row">
                                            {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                            <div className="col-sm-4 text-end">
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                <span> {c.created_at_display}</span>
                                            </div>
                                            <div className="col-sm-8 my-1">{c.notes}</div>
                                        </div><hr /></Ax>);
                                    })
                                        : null
                                }
                            </div>
                        </div>
                        : null}
                </Ax>)
                : <Loader />}

        </div>)
    }

    //***********************SEARCH INVENTORY JSX****************
    inventorySearchModalJsx = () => {

        return (
            <div className="modal fade" id="inventorySearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchInventoryModalLabel">Inventory Transfer Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="inventorySearchForm" onSubmit={this.submitInventorySearchForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_like_transaction_id"
                                            type="text"
                                            value={this.state.inventorySearchForm.search_like_transaction_id}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "inventorySearchForm")}
                                            placeholder="Please enter Transaction ID"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Eway Bill Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_eway_number"
                                            type="text"
                                            value={this.state.inventorySearchForm.search_eway_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "inventorySearchForm")}
                                            placeholder="Please enter Eway Bill Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Invoice Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_invoice_number"
                                            type="text"
                                            value={this.state.inventorySearchForm.search_invoice_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "inventorySearchForm")}
                                            placeholder="Please enter Invoice Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Batch Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_item_batch_number"
                                            type="text"
                                            value={this.state.inventorySearchForm.search_item_batch_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "inventorySearchForm")}
                                            placeholder="Item Batch Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Dispatching Warehouse</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allDispatchingWarehouses}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'inventorySearchForm', 'search_warehouse_from_ids')}
                                            value={this.state.allDispatchingWarehouses.filter(u => this.state.inventorySearchForm?.search_warehouse_from_ids?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Dispatching Warehouses"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Receiving Warehouse</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allReceivingWarehouses}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'inventorySearchForm', 'search_warehouse_to_ids')}
                                            value={this.state.allReceivingWarehouses.filter(u => this.state.inventorySearchForm?.search_warehouse_to_ids?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Receiving Warehouses"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                    <div className="col-sm-10">
                                        <InputItemSearch
                                            placeholder="Search Item"
                                            isMulti={true}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'inventorySearchForm', 'search_item_ids');
                                                this.setState({
                                                    searchedItem: selectedOption ? selectedOption : null
                                                }, () => { });
                                            }}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Item Tags</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allItemTags}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'inventorySearchForm', 'search_item_tags')}
                                            value={this.state.allItemTags.filter(u => this.state.inventorySearchForm?.search_item_tags?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Item Tags"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Status</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allStatusOptions}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'inventorySearchForm', 'search_status_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allStatusOptions.filter(s => this.state.inventorySearchForm.search_status_ids.includes(s.value))}
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.inventorySearchForm.date_range}
                                            startDate={this.state.inventorySearchForm.date_range_start}
                                            endDate={this.state.inventorySearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    inventorySearchForm
                                                        : {
                                                        ...this.state.inventorySearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    //***********************UPDATE DISPATCH JSX****************
    updateDispatchModalJsx = () => {

        return (
            <div className="modal fade" id="updateDispatchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchUpdateDispatchModalLabel">Update Dispatch for : {this.state.inventoryDetail && this.state.inventoryDetail.transaction_id ? this.state.inventoryDetail.transaction_id : "-"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="updateDispatchForm" onSubmit={this.submitUpdateDispatchForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">

                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Eway Bill Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="eway_number"
                                            type="text"
                                            value={this.state.updateDispatchForm.eway_number}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "updateDispatchForm")}
                                            placeholder="Please enter Eway Bill Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Eway Bill Date</label>
                                    <div className="col-sm-4 add_calender_section" >
                                        <DatePicker
                                            selected={
                                                this.state.updateDispatchForm.eway_date
                                                    ? moment(this.state.updateDispatchForm.eway_date, 'YYYY-MM-DD').toDate()
                                                    : false
                                            }
                                            name="eway_date"
                                            onChange={(value, event) => this.formDateHandler('eway_date', value, 'updateDispatchForm')}
                                            dateFormat="dd-MMM-yyyy"
                                            className={"form-control form-control-sm"}
                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete="off"
                                            scrollMonthYearDropdown
                                            placeholderText={`Please Enter Eway Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" style={{ top: "6px" }} />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Docket Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="document_number"
                                            type="text"
                                            value={this.state.updateDispatchForm.document_number}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "updateDispatchForm")}
                                            placeholder="Please enter Document Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Docket Date</label>
                                    <div className="col-sm-4 add_calender_section" >
                                        <DatePicker
                                            selected={
                                                this.state.updateDispatchForm.document_date
                                                    ? moment(this.state.updateDispatchForm.document_date, 'YYYY-MM-DD').toDate()
                                                    : false
                                            }
                                            name="document_date"
                                            onChange={(value, event) => this.formDateHandler('document_date', value, 'updateDispatchForm')}
                                            dateFormat="dd-MMM-yyyy"
                                            className={"form-control form-control-sm"}
                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete="off"
                                            scrollMonthYearDropdown
                                            placeholderText={`Please Enter Docket Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" style={{ top: "6px" }} />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">

                                    <label className="col-sm-2 col-form-label col-form-label-sm">Transporter Name</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="transporter_number"
                                            type="text"
                                            value={this.state.updateDispatchForm.transporter_number}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "updateDispatchForm")}
                                            placeholder="Please enter Transporter Name"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Vehicle No</label>
                                    <div className="col-sm-4" >
                                        <input
                                            name="vechicle_registration_no"
                                            type="text"
                                            value={this.state.updateDispatchForm.vechicle_registration_no}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "updateDispatchForm")}
                                            placeholder="Please enter Vehicle Number"
                                        />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">

                                    <label className="col-sm-2 col-form-label col-form-label-sm">Number of Boxes</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="no_of_boxes"
                                            type="number"
                                            value={this.state.updateDispatchForm.no_of_boxes}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "updateDispatchForm")}
                                            placeholder="Please enter Number of Boxes"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Weight & Unit</label>
                                    <div className="col-sm-2" >
                                        <input
                                            name="weight"
                                            type="number"
                                            value={this.state.updateDispatchForm.weight}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "updateDispatchForm")}
                                            placeholder=" Weight"
                                        />
                                    </div>
                                    <div className="col-sm-2" >
                                        <TapSelect
                                            options={this.state.allWeightUnits}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'updateDispatchForm', 'unit_of_weight')}
                                            value={this.state.allWeightUnits.find(u => this.state.updateDispatchForm?.unit_of_weight == u.value)}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Unit"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    {(this.state.inventoryDetail?.status == 35 || this.state.inventoryDetail?.status == 36) && <Ax>
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Date of Dispatch</label>
                                        <div className="col-sm-4">
                                            <DatePicker
                                                selected={this.state.updateDispatchForm.date_of_dispatch ? moment(this.state.updateDispatchForm.date_of_dispatch, 'YYYY-MM-DD').toDate() : false}
                                                name="date_of_dispatch"
                                                onChange={(value, event) => this.formDateHandler('date_of_dispatch', value, 'updateDispatchForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                className="form-control form-control-sm"
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                required={true}
                                                placeholderText="Please Enter Date of Dispatch"
                                            />
                                        </div>
                                    </Ax>}
                                    {(this.state.inventoryDetail?.status == 36) && <Ax> <label className="col-sm-2 col-form-label col-form-label-sm  ">Date of Receipt</label>
                                        <div className="col-sm-4" >
                                            <DatePicker
                                                selected={this.state.updateDispatchForm.date_of_receive ? moment(this.state.updateDispatchForm.date_of_receive, 'YYYY-MM-DD').toDate() : false}
                                                name="date_of_receive"
                                                onChange={(value, event) => this.formDateHandler('date_of_receive', value, 'updateDispatchForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                className="form-control form-control-sm"
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                required={true}
                                                placeholderText="Please Enter Date of Receipt"
                                            />
                                        </div>
                                    </Ax>}

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.updateDispatchFormSubmitting} >Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.updateDispatchFormSubmitting}>Submit {this.state.updateDispatchFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    //***********************UPDATE REMARK JSX****************
    updateRemarkModalJsx = () => {
        let inventoryTransfer = this.state.inventoryDetail;

        let {item_profile}          =   this.state.remarkItemFormdata;

        return (
            <div className="modal fade" id="updateRemarkModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Update Remarks</h5>
                            <button
                                disabled={this.state.remarkSubmitting}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        {this.state.view_loading ? <Loader /> : inventoryTransfer && <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "30%" }}>Item ID </td>
                                        <th>{inventoryTransfer.transaction_id || "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%" }}>Item Name </td>
                                        <th>{item_profile ? item_profile.name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%" }}>Item Code </td>
                                        <th>{item_profile ? item_profile.item_code : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                        <div className="modal-body ">
                            <form className="" id="indentRemark" onSubmit={this.handleRemarkForm} >
                                <div className="row align-items-center ">
                                    <div className="col-sm-12">
                                        <label htmlFor="notes" className="form-label">Remarks</label>
                                        <textarea
                                            name="Remarks"
                                            value={this.state.remarkItemFormdata.remark}
                                            onChange={e => {
                                                this.setState({
                                                    remarkItemFormdata: {...this.state.remarkItemFormdata, remark : e.target.value}
                                                });
                                            }}
                                            className="form-control"
                                            autoComplete='off'
                                            style={{ height: "80px" }}
                                        />
                                    </div>
                                </div>
                            </form>
                            <div className="modal-footer">
                                <button disabled={this.state.remarkSubmitting} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button disabled={this.state.remarkSubmitting} type="submit" form='indentRemark' className="btn btn-primary">save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
    linkIndentModalJsx = () => {
        let detail = this.state.indent_listing && this.state.indent_listing.length > 0 ? this.state.indent_listing[0] : null;
        return (
            <div className="modal fade" id="linkIndentModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="linkIndentModalLabel">Link Indent for : {this.state.inventoryDetail && this.state.inventoryDetail.transaction_id ? this.state.inventoryDetail.transaction_id : "-"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        {detail && <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Item Name (Code)   </td>
                                        <th>{detail && detail.item ? `${detail.item.name} (${detail.item.item_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Warehouse   </td>
                                        <th>{detail && detail.warehouse ? `${detail.warehouse.name} (${detail.warehouse.code})` : "-"}</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>}
                        <form id="linkIndentForm" onSubmit={this.submitLinkIndentForm}>
                            <div className="modal-body">
                                <table className="table table-bordered table-fixed  table-sm align-middle bg-white mt-2">
                                    <thead className="align-middle table-secondary">
                                        <tr className='text-center'>
                                            <th rowSpan={2} style={{ width: "5%" }}> <input type='checkbox' diabled={true} onChange={this.checkAllIndentItem} name="chk[]" id="all_check_indent" /></th>
                                            <th rowSpan={2} style={{ width: "9%" }}>Transaction ID</th>
                                            <th rowSpan={2} style={{ width: "7%" }}>Transaction Date</th>
                                            <th rowSpan={2} scope="col" style={{ width: "10%" }} >Indent Qty</th>
                                            <th scope="col" style={{ width: "15%" }} >Purchase Qty</th>
                                            <th scope="col" style={{ width: "15%" }} >Pending Qty</th>
                                            <th rowSpan={2} scope="col" style={{ width: "10%" }} >Transfer Qty</th>
                                        </tr>
                                        <tr className='text-center'>
                                            <th scope="col" colSpan={2} style={{ width: "15%" }} >Quantity Available to Adjust</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.approved_indent_listing ? (<tr><td className="text-center" colSpan={5}><Loader /></td></tr>)
                                            : this.state.indent_listing && this.state.indent_listing.length > 0
                                                ? (this.state.indent_listing.map((rowData, k) => {

                                                    return (<tr key={k} className="text-center">
                                                        <td>
                                                            <input
                                                                type='checkbox'
                                                                className="indent_item_id_checkbox"
                                                                value={rowData.wi_item_id}
                                                                data-purchaseQty={rowData.transfer_qty}
                                                                data-warehouseItemId={rowData.transaction_id}
                                                                data-warehouseIndentItemId={rowData.warehouse_indent_item_id} />
                                                        </td>
                                                        <td>{rowData.transaction_id}</td>
                                                        <td>{rowData.transaction_date_display}</td>
                                                        <td>{rowData.indent_qty ? rowData.indent_qty : 0}</td>
                                                        <td>{rowData.purchase_qty ? rowData.purchase_qty : 0}</td>
                                                        <td>{rowData.pending_qty ? rowData.pending_qty : 0}</td>
                                                        <td>{rowData.transfer_qty ? rowData.transfer_qty : 0}</td>
                                                    </tr>);

                                                }))
                                                : (<tr><td className="text-center" colSpan={5}>No Record Found</td></tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.updateDispatchFormSubmitting} >Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.updateDispatchFormSubmitting}>Submit {this.state.updateDispatchFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }



    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>Inventory Transfer Listing</title></Helmet>

            {this.inventoryTransferListjsx()}
            {this.inventorySearchModalJsx()}
            {this.updateDispatchModalJsx()}
            {this.updateRemarkModalJsx()}
            {this.linkIndentModalJsx()}
            <ItemDetailsModal ref={this.itemDetailsRef} />
            <IndentDetailModal ref={this.indentDetailRef} />
            <LogisticViewModal ref={this.LogisticViewModalRef} />
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        all_warehouses: state.app && state.app.warehouses ? state.app.warehouses : [],
        all_periods: state.app && state.app.all_periods ? state.app.all_periods : [],
        item_tags: state.app && state.app.item_tags ? state.app.item_tags : [],
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency: state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        permissions: state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(InventoryTransferList);