import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from "../../services/TapApiUrls";
import HttpAPICall from "../../services/HttpAPICall";
import Loader from "../../components/ui/Loader/Loader";
import Ax from "../../components/hoc/Ax";
import { Helmet } from "react-helmet";
import tapIcon from "../../services/TapIcon";
import TapSelect from '../../components/ui/TapSelect';
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import TapIcon from "../../services/TapIcon";
import AppBaseComponent from "../../components/AppBaseComponent";
import DateService from "../../services/DateService";

class Document extends AppBaseComponent {
    constructor(props) {
        super(props);
        this.addItemDocumentInitial = {
            name: "",
            number: "",
            file: null,
            file_name: null,
            base64_document: null
        }
        this.state = {
            documentData : {
                loading : false,
                data : [],
            },
            itemId: null,
            other: 'N',
            documentTypes: [],
            formDataLoading: false,
            addItemDocumentForm: { ...this.addItemDocumentInitial },
            saveFormSubmitting: false,
            
        };
        this.documentTypeUrl    =   ORG_API_BASE_URL_2 + '/setting/doc_types';
        this.documentListUrl    =   INVENTORY_API_BASE_URL_2 + '/item_document/list';
        this.documentAddUrl     =   INVENTORY_API_BASE_URL_2 + '/item_document/add';
        this.documentDeleteUrl  =   INVENTORY_API_BASE_URL_2 + '/item_document/delete';
    }
    componentDidMount() {        
        this.getDocumentList(this.props.item_enc_id)
        this.setState({
            itemId: this.props.item_enc_id,
        });
        this.addItemDocumentModal = new Modal(document.getElementById('addItemModal'), { keyboard: false, backdrop: false });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.item_enc_id !== this.props.item_enc_id) {
            this.getDocumentList(nextProps.item_enc_id);
            this.setState({ itemId: nextProps.item_enc_id, itemName: nextProps.itemName });
        }
    }

    changeTab = (key) => {
        const {itemDetail}  =   this.props;
        let obj = { item_name: itemDetail?.name, item_category: itemDetail?.category };
        this.props.props.history.push({
            pathname: `/item_list/${key}/${this.state.itemId}`,
            search: "?" + new URLSearchParams(obj).toString(),
        });
    };



    getDocumentList = (item_id) => {
        this.setState({ 
            documentData : {...this.state.documentData, loading: true}
        });
        HttpAPICall.withAthorization('GET', this.documentListUrl + '/' + item_id,
            this.props.access_token, {}, {}).then((response) => {
                let respData = response.data;
                this.setState({
                    documentData : {...this.state.documentData, loading: false, data: respData}
                });
            }).then(() => {
                this.setState({ 
                    documentData : {...this.state.documentData, loading: false}
                });
            });
    }

    addDocumentModalInit = () => {
        //Initialize Search Form :-
        this.addItemDocumentModal.show();
        let form = document.getElementById("addDocumentModalForm");
        form.reset();
        this.setState({ other: 'N' });
        this.loadDocTypesFromApi();
    }

    loadDocTypesFromApi = () => {
        this.setState({ formDataLoading: true })
        HttpAPICall.withAthorization('GET', this.documentTypeUrl, this.props.access_token, {doc_type : 'isc_doc_types'}, {}, (response) => {
            this.setState({
                documentTypes: response.data.map(s => { return ({ value: s, label: s }) }),
            });
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ formDataLoading: false }))
    }

    handleFileChange = async (e) => {
        let file = e.target.files[0];
        let filename = file.name
        const base64 = await this.convertBase64(file);
        this.setState({ addItemDocumentForm: { ...this.state.addItemDocumentForm, base64_document: base64, file_name: filename } })
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    addItemDocument = (event) => {
        event.preventDefault();
        const {itemId, addItemDocumentForm} =   this.state;
        let item_id     =   itemId 
        let frmData     =   {
            item_id,
            name            :   addItemDocumentForm.name,
            number          :   addItemDocumentForm.number,
            file_name       :   addItemDocumentForm.file_name,
            base64_document :   addItemDocumentForm.base64_document
        };
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('POST', this.documentAddUrl, this.props.access_token, null, frmData, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addItemDocumentModal.hide();
                this.getDocumentList(item_id)
                this.setState({
                    addItemDocumentForm: { ...this.addItemDocumentInitial },
                })
            }
        ).then(() => this.setState({ saveFormSubmitting: false }))
    }

    //function todelete
    deleteDocument = (doc_id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.documentDeleteUrl + '/' + doc_id, this.props.access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.getDocumentList(this.state.itemId);
                }).then(() => {});
            }
        });
    }

    //function for document modal
    addDocumentModalJsx = () => {
        const {documentTypes, addItemDocumentForm, other} = this.state;
        
        return (
            <div className="modal fade" id="addItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addItemModalLabel">Add Item Document</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.formDataLoading ? <Loader />
                            : <form onSubmit={this.addItemDocument} id="addDocumentModalForm">
                                <div className="modal-body">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document Type</label>
                                        </div>
                                        <div className="col-sm-7">
                                            {this.state.other == "Y"
                                                ? <input
                                                    name="name"
                                                    type="text"
                                                    value={addItemDocumentForm.name}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addItemDocumentForm")}
                                                    placeholder="Please enter Type Name"
                                                    required={true}
                                                />
                                                : <TapSelect
                                                    options={documentTypes}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemDocumentForm', 'name')}
                                                    value={documentTypes.find(u => addItemDocumentForm.name == u.value)}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Select Document Type"
                                                    autoCompelete="off"
                                                    required={true}
                                                    autoFocus={true}
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="other"
                                                type="checkbox"
                                                value={other}
                                                onChange={(e) => { this.setState({ other: other == 'N' ? 'Y' : 'N' }) }}
                                                checked={other == 'Y'}
                                                className="form-check-input"
                                                id="other"
                                            />
                                            <label className="form-check-label mx-2" htmlFor="other">New </label>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-3">
                                            <label className="form-label">Document Number</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="number"
                                                type="text"
                                                value={addItemDocumentForm.number}
                                                onChange={(e) => this.formInputHandler(e, "addItemDocumentForm")}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Enter Document Number"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="file"
                                                type="file"
                                                value={addItemDocumentForm.file}
                                                onChange={(e) => {
                                                    this.formInputHandler(e, "addItemDocumentForm");
                                                    this.handleFileChange(e)
                                                }}
                                                className="form-control"
                                                autoComplete="off"
                                                required={true}
                                                placeholder="Upload Document"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal">Close {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                    <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary">Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
        );
    }
    render() {
        let itemCategory;
        let showTabHeader = true;
        if (this.props.showTabHeader !== undefined || this.props.showTabHeader == false) {
            showTabHeader = false
        }
        if (this.props && this.props.props && this.props.props.location && this.props.props.location.search) {
            let ParamObject = new URLSearchParams(this.props.props.location.search);
            if (ParamObject.get("item_category")) {
                itemCategory = ParamObject.get("item_category");
            }
        }
        const { loading, data } =   this.state.documentData;
        const { permissions }   =   this.props;
        return (<Ax>
            <Helmet><title>Document</title></Helmet>
            <section className="item_view_container bg-white">
                {showTabHeader == true && <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/details/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab("details")}>
                            <button className="nav-link " id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true">Details</button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/interchangeable/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab("interchangeable")}>
                            <button className="nav-link " id="interchangeable-tab" data-bs-toggle="tab" data-bs-target="#interchangeable" type="button" role="tab" aria-controls="interchangeable" aria-selected="false">Interchangeable Items
                            </button>
                        </Link>
                    </li>
                    {itemCategory !== "services" && this.props.category !== "services" ? (
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/inventory/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab("inventory")}>
                                <button className="nav-link" id="inventory-tab" data-bs-toggle="tab" data-bs-target="#inventory" type="button" role="tab" aria-controls="inventory" aria-selected="false">Inventory Levels
                                </button>
                            </Link>
                        </li>
                    ) : null}
                    {itemCategory !== "services" && this.props.category !== "services" ? (
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/configuration/${this.state.itemId}`} onClick={() => this.changeTab("configuration")} role={"button"}>
                                <button className="nav-link " id="config-tab" data-bs-toggle="tab" data-bs-target="#config" type="button" role="tab" aria-controls="config" aria-selected="false">Configuration</button>
                            </Link>
                        </li>
                    ) : null}
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/additional_attribute/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab("additional_attribute")}>
                            <button className="nav-link " id="additional_attribute-tab" data-bs-toggle="tab" data-bs-target="#additional_attribute" type="button" role="tab" aria-controls="additional_attribute" aria-selected="false">Additional Attributes
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/document/${this.state.item_enc_id}`} role={"button"} onClick={() => this.changeTab("document")}>
                            <button className="nav-link active" id="document-tab" data-bs-toggle="tab" data-bs-target="#document" type="button" role="tab" aria-controls="document" aria-selected="false">Document</button>
                        </Link>
                    </li> 
                </ul>}
                <div className="tab-content" id="myTabContent"></div>
                <div className="tab_content_header">
                    <div className="tab_content_wrapper pe-0 mt-2">
                        <span className="content_heading">Item Documents</span>
                        <div className="float-end">
                            <button className="btn btn-primary" disabled={!permissions?.includes('isc-item-edit')} onClick={this.addDocumentModalInit}>Add Document</button>
                        </div>
                    </div>
                    <table className="table table-bordered mt-3">
                        <thead className="table-secondary">
                            <tr>
                                <th style={{ width: "5%" }}>S.No</th>
                                <th style={{ width: "31%" }}>Document Name</th>
                                <th style={{ width: "20%" }}>Document Number</th>
                                <th style={{ width: "12%" }}>Uploaded By</th>
                                <th style={{ width: "12%" }}>Uploaded At</th>
                                <th style={{ width: "10%" }} className="text-center">View</th>
                                <th style={{ width: "10%" }} className="text-center">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            loading 
                            ? <tr><td className="text-center" colSpan={7}><Loader/></td></tr>
                            :
                            data.length > 0 
                                ?
                                data.map((doc, index) => (
                                    <tr key={doc.id}>
                                        <td>{index + 1}</td>
                                        <td>{doc.name}</td>
                                        <td>{doc.number}</td>
                                        <td>{doc.feed_date_time ? DateService.dateTimeFormat(doc.feed_date_time, 'DD-MMM-YYYY') : ''}</td>
                                        <td>{doc.created_by}</td>
                                        <td scope="col" className="text-center">
                                            <a role="button" target='_blank' href={doc.path}> <TapIcon.FontAwesomeIcon icon={TapIcon.faEye} /></a>
                                        </td>
                                        <td className="text-center">
                                            <a role="button" className={[permissions?.includes('isc-item-edit') ? '' : 'disabled'].join(' ')}
                                            title={!permissions?.includes('isc-item-edit') ? 'You do not have permission to perform this action' : ''} onClick={() => { 
                                                permissions?.includes('isc-item-edit') && this.deleteDocument(doc.id) 
                                            }}
                                                style={{ "pointerEvents": "all" }}
                                            ><TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt} color={"blue"} /></a>
                                        </td>
                                    </tr>
                                ))
                                :
                                <tr><td className="text-center" colSpan={7}>No Record Found</td></tr>
                        }
                        </tbody>
                    </table>
                </div>

            </section>
            {this.addDocumentModalJsx()}
        </Ax>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions: state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Document);
