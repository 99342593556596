import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import AssetHeader from '../includes/AssetHeader';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import download from '../../../services/DownloadFile';
import moment from 'moment';
import FuelFeedInView from '../../transactions/fuelFeedIn/FuelFeedInView';

class FuelFeedIn extends AppBaseComponent {
    
    constructor(props) {
        super(props);
    
        this.fuelFeedInSearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_quantity'               :   '',
            'search_amount'                 :   '',
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'fuel_transaction_delay'         :   ''
        };

        this.fuelFeedInSearchFormLabel         =   {
            'search_quantity'                :   'Quantity : ',
            'search_amount'                  :   'Amount : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'fuel_transaction_delay'         :   'Transaction Delay : '
        };

        this.state                         =     {  
            minimizeTable                   :       false,   
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            fuelFeedIn_listing              :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            fuelFeedInData                  :       null,
            viewDataLoading                 :       false,
            access_token                    :       '',
            all_periods                     :        [],
            searchedAssets                  :        [],
            iam_asset_types                 :        [],
            iam_group_sites                 :        [],
            all_periods                     :        [],
            fuelFeedInSearchForm            :         {...this.fuelFeedInSearchInitState},
            formSearchedElems               :         [],
            fuelFeedInSearchParams          :         null,
            file_downloading                :         false,
            allTransactionDelay             :         []
        };
        this.logFuelFeedInRef              =   React.createRef();
       
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.searchFormDataInit(this.props)
        this.fuelFeedInSearchModal         =    new Modal(document.getElementById('fuelFeedInSearchModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        this.setState({assetId              :       pr.match?.params?.id,
            },() => {
                    
            this.getAsssetDetails(pr.match?.params?.id)
            this.loadListingTblData(1,pr.match?.params?.id)
            this.searchFormDataInit(pr)
        })
    }
    

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    loadListingTblData(page,id){
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,need_asset_profile : 'N',search_asset_ids : [id ? id : this.state.assetId],...this.state.fuelFeedInSearchParams};
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/fuelfeedin_listing', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                fuelFeedIn_listing         :           respData.data,
                listingMeta                :           respData.meta,
                totalListingCount          :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }



    viewFuelFeedInDetail(id){
        this.setState({minimizeTable:true})
        this.getFuelFeedInDetail(id)
    }

    
    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    getFuelFeedInDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/fuelfeedin_detail/' + id, this.state.access_token ? this.state.access_token : this.props.access_token, {need_asset_profile : 'N'}, {}, (response) => {
            this.setState({
                fuelFeedInData: response.data.data
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }



    closefuelFeedInView                    =      ()              =>         {
        this.setState({minimizeTable:false})
    }

   

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/counterlog/fuelfeedin_listing_form_data', this.props.access_token, {})
            .then((response) => {
        
                this.setState({
                    iam_asset_types         :       props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                    iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                    all_periods             :       all_periods,
                    allTransactionDelay     :    response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : []
                },() => {
                    
                });
            })
    }

    fuelFeedInSearchModalInit        =       ()      =>      {
        this.fuelFeedInSearchModal.show()
    }

    fuelFeedInSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems   :   [],
            fuelFeedInSearchParams   :   null,
            searchedAsset       :   null,
            fuelFeedInSearchForm     :   {...this.fuelFeedInSearchInitState}
        }, () => {
            this.fuelFeedInSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    fuelFeedInSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.fuelFeedInSearchForm);
        let searchedElems               =   [];
        let fuelFeedInSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.fuelFeedInSearchFormLabel[key];
            let value                       =   this.state.fuelFeedInSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                fuelFeedInSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_quantity') {
                        show_val            =   this.state.fuelFeedInSearchForm && this.state.fuelFeedInSearchForm.search_quantity ? this.state.fuelFeedInSearchForm.search_quantity : '';
                    }
                    if(key == 'search_amount') {
                        show_val            =   this.state.fuelFeedInSearchForm && this.state.fuelFeedInSearchForm.search_amount ? this.state.fuelFeedInSearchForm.search_amount : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.fuelFeedInSearchForm && this.state.fuelFeedInSearchForm.search_transaction_id ? this.state.fuelFeedInSearchForm.search_transaction_id : '';
                    }
                    if(key == 'fuel_transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.fuelFeedInSearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.fuelFeedInSearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range          =      moment(this.state.fuelFeedInSearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =     moment(this.state.fuelFeedInSearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =     `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            fuelFeedInSearchParams      :       fuelFeedInSearchParams
        }, () => {
            this.fuelFeedInSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    fuelFeedInDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.fuelFeedInSearchParams,search_asset_ids:[this.state.assetId]};
        if(this.state.fuelFeedInSearchParams !== null){
            this.fuelFeedInDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_month"}
                    this.fuelFeedInDownloadStart(params);
                }
            });
        }
    }
    

    fuelFeedInDownloadStart              =    (params)     =>         {
        this.setState({ file_downloading: true});
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/counterlog/fuelfeedin_listing', this.props.access_token,{download : "download",...params},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({file_downloading: false}));
    }

    deleteFuelFeedInInit               =   (id) =>  {
        this.logFuelFeedInRef.current.deleteFuelFeedInInit(id);
    }
   
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader  assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props} />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div> 
            <div id="assetCard" className="collapse pr-1 mt-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />}
        </div>
        </div>)
    }

    fuelFeedInListJsx            =       ()      =>    {

        return (<Ax>
            <div style={{ paddingTop: "4px" }}>
                <div className="page_title row m0" style={{ height: "45px" }}>
                    <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                        <h3 style={{ paddingTop: "13px" }}>Fuel FeedIn List</h3>
                    </div>
                    {!this.state.minimizeTable
                        ? <div className='col-sm-2 '>
                            <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                        </div> : null}
                    {!this.state.minimizeTable
                        ? <div className='col-sm-4 text-end mt7 '>
                           <button type="button"  disabled={this.state.listing_loading} onClick={this.fuelFeedInSearchModalInit} className="btn btn-secondary" >
                        <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <button type="button" className="btn btn-secondary"
                        disabled={this.state.file_downloading || this.state.listing_loading}
                        onClick={this.fuelFeedInDownloadHandler} >
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                        {this.state.file_downloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                        </div> : null}
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <div className="col-sm-3 tap_border_left">
                                    <h3 className="text-capitalize">Fuel FeedIn  : {this.state.fuelFeedInData && this.state.fuelFeedInData.transaction_id ? this.state.fuelFeedInData.transaction_id : '-'}  </h3>
                                    </div>
                                    <div className='col-sm-2 '>
                                        <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                    </div>
                                    <div className='col-sm-4 text-end mt7'>
                                    <button type="button" className="btn btn-secondary ms-1" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                    <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                    <li className='dropdown-item' onClick={() => this.deleteFuelFeedInInit(this.state.fuelFeedInData?.transaction_id)}>Delete</li>
                                </ul>
                                        <button onClick={this.closefuelFeedInView} type="button" className="btn btn-secondary">
                                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </Ax>
                            )
                            : null
                    }
                </div>
            </div>
            <div className="container-fluid pl0 pr13">
                <div className="page_containt row">
                    <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.fuelFeedInTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0 pageTbl pb-5"><div className=" bg-white p-2" >{this.state.view_loading ? <Loader /> : this.fuelFeedInViewDetailJsx()}</div> </div> : null}
                </div>
            </div>
        </Ax>);
    }

    fuelFeedInTableJsx                   =   ()  =>  {
        return (
            <Ax>
                 <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.fuelFeedInSearchClear} /> 
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>
                    <table className="table table-bordered bg-white align-items-center  table-sm table-hover table-fixed mb-0">
                        <thead className="table-secondary">
                            {this.state.minimizeTable
                                ? (<tr>
                                    <th scope="col" style={{ width: "20%" }}>FuelFeed In</th>
                                </tr>)
                                : (<tr>
                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                    <th scope="col" style={{ width: "12%" }} className='text-center'>Transaction Date</th>
                                    <th scope="col" style={{ width: "15%" }}>Transaction ID</th>
                                    <th scope="col" style={{ width: "20%" }}>Site</th>
                                    <th scope="col" style={{ width: "12%" }}>Fuel Station</th>
                                    <th scope="col" style={{ width: "13%" }}>Fuel Type</th>
                                    <th scope="col" style={{ width: "10%" }} className='text-end'>Quantity</th>
                                    <th scope="col" style={{ width: "5%" }}>Action</th>
                                </tr>)
                            }

                        </thead>
                        <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.fuelFeedIn_listing.length > 0
                            ? (this.state.fuelFeedIn_listing.map((FuelFeedIn, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} onClick={() => this.viewFuelFeedInDetail(FuelFeedIn.transaction_id)} >
                                                        <div className="text-capitalize link-primary cursor_pointer">{FuelFeedIn.transaction_id ? FuelFeedIn.transaction_id : "-"}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{FuelFeedIn.site && FuelFeedIn.site.site_name ?FuelFeedIn.site.site_name : '-' } </small>
                                                            <small className="float-end">{FuelFeedIn.qty ? <span>{parseInt(FuelFeedIn.qty)}</span> : '-' }</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                               <td className='text-center' >{FuelFeedIn.transaction_date_display ? FuelFeedIn.transaction_date_display.substr(0, FuelFeedIn.transaction_date_display.indexOf(' ')) : "-"}</td>
                                               <td className='link-primary cursor_pointer'  onClick={() => this.viewFuelFeedInDetail(FuelFeedIn.transaction_id)} >{FuelFeedIn.transaction_id ? FuelFeedIn.transaction_id : "-"}</td>
                                               <td>{FuelFeedIn.site && FuelFeedIn.site.site_name ?FuelFeedIn.site.site_name : '-' }</td>
                                               <td className='text-capitalize'>{FuelFeedIn.fuelStation && FuelFeedIn.fuelStation.fuelstation_name ?  FuelFeedIn.fuelStation.fuelstation_name : FuelFeedIn.other_station ? FuelFeedIn.other_station : "-" }</td>
                                               <td>{FuelFeedIn.fuel_type ? FuelFeedIn.fuel_type : '-' }</td>
                                               
                                                <td className='text-end'>{FuelFeedIn.qty ? parseInt(FuelFeedIn.qty) : '-' }</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewFuelFeedInDetail(FuelFeedIn.transaction_id)}  >View</a></li>

                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="7" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>

                    </table>

                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                    />
                </div>

            </Ax>
        );
    }

    fuelFeedInViewDetailJsx                =   ()   =>   {
      
        return (<div>
            <div className="row mr0">
                <div className="col-sm-12 pr0 pb-4">
                <FuelFeedInView fuelFeedInData={this.state.fuelFeedInData} />
                </div>
                
                </div>
          
        </div>)
    }

    fuelFeedInSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="fuelFeedInSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="fuelFeedInSearchModalLabel">Fuel FeedIn Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.fuelFeedInSearchFormSubmit} id="fuelFeedInSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.fuelFeedInSearchForm.search_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "fuelFeedInSearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Fuel Quantity</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_quantity"
                                            className="form-control form-control-sm"
                                            placeholder=" Example. 0-5 for a range or 4 for specific Fuel Quantity"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "fuelFeedInSearchForm")}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Fuel Amount</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_amount"
                                            className="form-control form-control-sm"
                                            placeholder=" Example. 0-5 for a range or 4 for specific Fuel Amount"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "fuelFeedInSearchForm")}
                                        />
                                    </div>
                                </div>
                                
                               
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'fuelFeedInSearchForm', 'fuel_transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.fuelFeedInSearchForm.fuel_transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.fuelFeedInSearchForm.entry_date_range}
                                            startDate={this.state.fuelFeedInSearchForm.entry_date_range_start}
                                            endDate={this.state.fuelFeedInSearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    fuelFeedInSearchForm
                                                        : {
                                                        ...this.state.fuelFeedInSearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.fuelFeedInSearchForm.date_range}
                                            startDate={this.state.fuelFeedInSearchForm.date_range_start}
                                            endDate={this.state.fuelFeedInSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    fuelFeedInSearchForm
                                                        : {
                                                        ...this.state.fuelFeedInSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

   
    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset - Fuel FeedIn List</title></Helmet>
             {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.fuelFeedInListJsx()}
            {this.fuelFeedInSearchModalJsx()}
        
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(FuelFeedIn);