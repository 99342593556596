import React from 'react';
import axios from 'axios';
import moment from 'moment';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2, INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import { Helmet } from 'react-helmet';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import WarehouseAddress from '../../inventory/includes/WarehouseAddress';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemSearchModal from '../../items/itemSearchModal';
import NumberFormatter from '../../../services/NumberFormater';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import Customer from '../includes/Customer';
import AddCustomerModal from '../includes/AddCustomerModal';
import InputDealSearch from '../includes/InputDealSearch';


class QuotationAdd extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initQuoteForm              =   {
            transaction_id                  :   '',
            transaction_date                :   moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD'),
            warehouse_id                    :   '',
            customer_id                     :   '',
            customer_address_id             :   '',
            expected_validity_date          :   '',
            priority                        :   '',
            status                          :   2,
            currency                        :   'INR',
            conversion_rate                 :   1,
            notes                           :   '',
            tnc                             :   [],
            adjustment_amount               :   '',
            authorized_signatory_id         :   '',
            currency_display                :   '',
            tcs_rate                        :   '',
            tcs_type                        :   'Percentage',
            users                           :   [],
        }
        
        this.addTermForm                =   {
            name                            :   '',
            text                            :   ''
        }
        
        this.initAddTermForm            =   {
            name                            :   '',
            text                            :   '',
            tag_ids                         :   []
        };
        
        this.initQuoteItemForm          =   {
            item                            :   null,
            id                              :   '',
            qty                             :   0,
            rate                            :   0,
            remark                          :   '',
            gst                             :   0,
            discount_rate                   :   0,
            discount_type                   :   'Percentage'
        };
        
        this.initState                  =   {
            termCoditionLoading             :   false,
            termCoditions                   :   [],
            oldTNC                          :   [],
            quoteFormDataLoaded             :   true,
            quoteEditFormDataLoaded         :   true,
            quoteForm                       :   {...this.initQuoteForm},
            addTermForm                     :   {...this.initAddTermForm},
            quoteItemRows                   :   [this.initQuoteItemForm],
            addTermFormSubmitting           :   false,
            transactionIdSetting            :   null,
            allWarehouses                   :   [],
            allStatusOptions                :   [],
            allPriorityOptions              :   [],
            allCurrencyList                 :   [],
            allDiscountType                 :   [],
            allGstSlabs                     :   [],
            user_status                     :   [],
            warehouse_state_id              :   null,
            warehouseDetailLoading          :   false,
            warehouseDetail                 :   null,
            searchedCustomer                :   null,
            searchedCustomerAdressList      :   [],
            selectedCustomerAdress          :   null,
            gstTypeName                     :   '',
            CustomerDetails                 :   null,
            CustomerAddressList             :   [],
            Customer_details_loading        :   false,
            saveFormSubmitting              :   false,
            currency_loading                :   false,
            editCurrency                    :   false,
            itemSearchedDetails             :   null,
            itemRowNumber                   :   0,
            quoteItemRow                    :   null,
            quoteItemRowKey                 :   0,
            update_item_purchase_price      :   'Y',
            warehouseAddressList            :   [],
            warehouseListingMeta            :   null,
            totalAddressCount               :   0,
            allUsersList                    :   [],
            gst_as_applicable               :   'N',
            warehouseSites                  :   [],
            formData                        :   '',
            multiple_currency_available     :   'N',
            gst_not_applicable              :   'N',
            listingLoaded                   :   false,
            allTCSType                      :   [],
            allUsers                        :   [],
            buttonDisabled                  :   false,
            searchWarehouseAddress          :   '',
            clearSearchAddress              :   true,
            searchedOriginCustomer          :   null ,
            quote_enable_authorized_signatory : 'N',
            quote_enable_tcs                :   'Y',
            tcs_applicable                  :   'Y',
            showItemSearchModal             :   false,
            showItemAddModal                :   false,
            searchedDeal                    :   null,
            showItemEditModal               :   false,
            transactionFromDeal             :   false,
            tncTags                         :   [],
            tncModalLoader                  :   false,
            pickTNCModalLoader              :   false,
            searchTNCByTags                 :   [],
        }
        this.state                      =   {...this.initState}
        this.addItemModalRef            =   React.createRef();
        this.editItemModalRef           =   React.createRef();
        this.addCustomerModalRef        =   React.createRef();
        this.consumptionHistoryRef      =   React.createRef();
        this.poHistoryRef               =   React.createRef();
        this.itemSearchRef              =   React.createRef();
        this.addWarehouseAddressRef     =   React.createRef();
        this.currency_convert_url       =   ORG_API_BASE_URL_2;

        this.QuotationAddUrl            =   INVENTORY_API_BASE_URL_2 + '/quotation/add';
        this.QuotationEditUrl           =   INVENTORY_API_BASE_URL_2 + '/quotation/update';
        this.quoteFormDataUrl           =   INVENTORY_API_BASE_URL_2 + '/quotation/form_data';
        this.quotePreviousTnc           =   INVENTORY_API_BASE_URL_2 + '/quotation/previous_tnc';
        this.itemUrl                    =   INVENTORY_API_BASE_URL_2 + '/item';
        this.CustomerUrl                =   INVENTORY_API_BASE_URL_2 + '/customer'
        this.warehouseShipAddUrl        =   INVENTORY_API_BASE_URL_2  + '/warehouse/shipping_address';
        this.warehouseUrl               =   ISC_API_BASE_URL_2  + '/warehouse';
        this.termConditonUrl            =   INVENTORY_API_BASE_URL_2 + '/term_condition/list';
        this.termConditonAddUrl         =   INVENTORY_API_BASE_URL_2 + '/term_condition/add';
        this.itemDetailUrl              =   INVENTORY_API_BASE_URL_2 + '/item';
        this.TagUrlList                 =   IAM_API_BASE_URL_2 + '/sub_assembly_tag/list';                

    }
    
    componentDidMount() {
       this.initilaizeFormData(this.props)
        this.addTermsModal              =   new Modal(document.getElementById('addTermsModal'), {keyboard: false, backdrop :false});
        this.addTnCInPOModal            =   new Modal(document.getElementById('addTnCInPOModal'), {keyboard: false, backdrop :false});
        this.viewItemModal              =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
        this.warehouseAddressModal      =   new Modal(document.getElementById('warehouseAddressModal'), {keyboard: false, backdrop :false});
     }

     
    
     componentWillReceiveProps(nextProps){
        if(nextProps.all_warehouses !== this.props.all_warehouses) {
            this.initilaizeFormData(nextProps)
        } 
    }


    initilaizeFormData              =   (props)  =>  {
        this.loadPOFormdata(props);
        this.setState({
            allWarehouses           :   props.all_warehouses ? props.all_warehouses.map((s) => { return { value: s.id, label: `${s.name} (${s.code})`, site_id: s.site_id }; }) : [],
            orgInfoData             :   props.orgInfoData ? props.orgInfoData : null,
        });
        this.getTermListing();
    }
    
    loadPOFormdata          =   (props) =>   {
        let sites                       =   props.iam_group_sites;
        this.setState({quoteFormDataLoaded : false});
        HttpAPICall.withAthorization('GET', this.quoteFormDataUrl, this.props.access_token, null, null, (response) => {
            let formData                =   response.data;
            let defaultCurrency         =   props && props.default_currency  ? props.default_currency : 'INR';
            let poCurrency              =   props && props.default_currency  ? props.default_currency : 'INR';
            let editPO                  =   false;

            let currencyList            =   props && props.currency_list && props.currency_list.length > 0 ? props.currency_list.map((s) => { return {value: s.code, label: s.code}; }) : [];
            this.setState({
                allStatusOptions            :   formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                allCurrencyList             :   currencyList,
                allDiscountType             :   formData && formData.discount_type ? formData.discount_type.map((s) => { return {value: s, label: s === "Fixed" ? poCurrency : "%"}; }) : [],
                allGstSlabs                 :   formData && formData.gst_slabs ? formData.gst_slabs.map((s) => { return {value: s, label: s +'%' }}) : [],
                allPriorityOptions          :   formData && formData.priorities && formData.priorities.length > 0 ? formData.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                defaultCurrency             :   defaultCurrency,
                allQuoteTypes          :   formData && formData.quotation_types && formData.quotation_types.length > 0 ? formData.quotation_types.map((s) => { return {value: s.key, label: s.name,type_data : s}; }) : [],
                formData                    :   formData,
                multiple_currency_available :   formData && formData.setting && formData.setting.multiple_currency ? formData.setting.multiple_currency : 'N',
                allUsersList                :   formData && formData.users && formData.users.length > 0 ?  formData.users.map((s) => { return {value: s.id, label: s.full_name}; }) : [],
                quoteForm                      :   {...this.state.quoteForm , currency : defaultCurrency},
                quote_enable_authorized_signatory             :  formData && formData.setting && formData.setting.quote_enable_authorized_signatory  ? formData.setting.quote_enable_authorized_signatory : 'N', 
                quote_enable_tcs                 :  formData && formData.setting && formData.setting.quote_enable_tcs  ? formData.setting.quote_enable_tcs : 'Y',                        
                allTCSType                    :   formData && formData.discount_type ? formData.discount_type.map((s) => { return {value: s, label: s === "Fixed" ? poCurrency : "%"}; }) : [],
                allUsers                     :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
            });
        }).then(() => {
            this.setState({quoteFormDataLoaded : true});
            //Remanage Status of Purchase Order :-
            this.filterAllStatusOptions();
            let transactionIdSetting = this.state.allQuoteTypes ? this.state.allQuoteTypes.find(pt => pt.value == "quotation") : null
            this.setState({
                transactionIdSetting   : transactionIdSetting ? transactionIdSetting.type_data : null
            })
            let ParamObject                 =       new URLSearchParams(this.props.location.search);
            //In Edit Mode :- 
            if(props.location && props.location.state && props.location.state.quotationData) {
           
                //1 - Edit Mode of Existing Purchase Order :- 
                let quotationData    =   props.location.state.quotationData;
                //in Items Data : (props.location.state.quotationData.items)
                //if same Item got than we will merge and process start :-

                let updateFormState = [];
                if (quotationData.additional_attribute_data) {
                    quotationData.additional_attribute_data.map((i, k) => {
                        let name = 'additional_attributes_' + i.key;
                        updateFormState[name] = i.value
                    });
    
                }
                
                let quoteItemRows   =   props.location.state.quotationData.items.map((po_item, k) => {
                    return {...this.initQuoteForm, 
                        item            :   {...po_item.item_profile,isAddRemark : po_item.remark ? true : false,isAddDueDate:po_item.due_date ? true :false},
                        id              :   po_item.item_profile.id,
                        qty             :   Number(po_item.qty) ,
                        rate            :   po_item.rate_vc,
                        remark          :   po_item.remark,
                        gst             :   po_item.gst,
                        discount_type   :   po_item.discount_type,
                        discount_rate   :   po_item.discount_rate_vc,
                    };
                });
               
                this.setState({
                    quoteItemRows  : quoteItemRows,
                    quoteForm  :   {
                        ...this.initQuoteForm,
                        transaction_date        :   quotationData.transaction_date ? quotationData.transaction_date : '' ,
                        transaction_id          :   quotationData.transaction_id ? quotationData.transaction_id : '',
                        warehouse_id            :   quotationData.warehouse.id ? quotationData.warehouse.id : '',
                        customer_id             :   quotationData.customer ? quotationData.customer.id : '',
                        customer_address_id     :   quotationData.customerAddress ? quotationData.customerAddress.id : '',
                        expected_validity_date  :   quotationData.validity_date ? quotationData.validity_date : '',
                        priority                :   quotationData.priority ? quotationData.priority : '',
                        status                  :   quotationData.status ? quotationData.status.id : 1,
                        currency                :   quotationData.currency ? quotationData.currency : '',
                        conversion_rate         :   quotationData.conversion_rate ? parseFloat(quotationData.conversion_rate).toFixed(2) : '',
                        notes                   :   quotationData.notes ? quotationData.notes : '',
                        adjustment_amount       :   quotationData.adjustment_amount_vc,
                        tnc                     :   quotationData.tnc,
                        authorized_signatory_id :   quotationData.authorized_signatory_id ? quotationData.authorized_signatory_id : "",
                        tcs_rate                :   quotationData.tcs_rate_vc  ? quotationData.tcs_rate_vc : '',
                        tcs_type                :   quotationData.tcs_type ? quotationData.tcs_type :'Percentage',
                        tcs_applicable          :   quotationData.tcs_rate && quotationData.tcs_rate > 0 ? 'Y' :'N',
                        users                   :   quotationData.users ? quotationData.users.map(st => st.enc_id) : [],
                        customer_project_id     :   quotationData.customerProject ? quotationData.customerProject.id : '',
                        deal_id                 :   quotationData.deal_data ? quotationData.deal_data.id : '',
                        ...updateFormState
                    },
                    searchedDeal                :   quotationData.deal_data ?  {value : quotationData.deal_data.id  ,display_label : quotationData.deal_data.header,label:quotationData.deal_data.header } : null,
                    searchedCustomer          :   {value : quotationData.customer.id  ,display_label : quotationData.customer.name },
                    CustomerDetails           :   {value : quotationData.customer.id  ,display_label : quotationData.customer.name },
                    selectedCustomerAdress    :   quotationData.customerAddress ? {...quotationData.customerAddress, gstin : quotationData.customer_gstin, country_id:quotationData.customerAddress.country_id ? quotationData.customerAddress.country_id : 99} : null,
                    quotationDetail                :   quotationData,
                    user_status             :   quotationData.user_status,
                    selectedProject         :   quotationData.customerProject ? quotationData.customerProject : null,
                    quote_enable_tcs          :       (quotationData.tcs_rate && quotationData.tcs_rate > 0) || this.state.quote_enable_tcs == 'Y' ? 'Y' :'N',
                   
                }, () => {
                    this.getWarehouseDetail(this.state.quoteForm.warehouse_id,true);
                    this.getCustomerDetails(this.state.quoteForm.customer_id,true);
                    this.handleCustomerChange(this.state.searchedCustomer,true);
                    let transactionIdSetting = this.state.allQuoteTypes ? this.state.allQuoteTypes.find(pt => pt.value == "quotation") : null
                    this.setState({
                        transactionIdSetting   : transactionIdSetting ? transactionIdSetting.type_data : null
                    })
                    //Remanage Status of Purchase Order :-
                    this.filterAllStatusOptions();
                });
            }else if(props.location && props.location.state && props.location.state.cloneQuoteDetail) {
           
                let quotationData    =   props.location.state.cloneQuoteDetail;
                //in Items Data : (props.location.state.quotationData.items)
                //if same Item got than we will merge and process start :-
                let updateFormState = [];
                if (quotationData.additional_attribute_data) {
                    quotationData.additional_attribute_data.map((i, k) => {
                        let name = 'additional_attributes_' + i.key;
                        updateFormState[name] = i.value
                    });
                }

                let quoteItemRows          =   props.location.state.cloneQuoteDetail.items.map((po_item, k) => {
                    return {...this.initQuoteForm, 
                        item                        :   {...po_item.item_profile,isAddRemark : po_item.remark ? true : false,isAddDueDate:po_item.due_date ? true :false},
                        id                          :   po_item.item_profile.id,
                        qty                         :   Number(po_item.qty) ,
                        rate                        :   po_item.rate_vc,
                        remark                      :   po_item.remark,
                        gst                         :   po_item.gst,
                        discount_type               :   po_item.discount_type,
                        discount_rate               :   po_item.discount_rate_vc,
                    };
                });
                this.setState({ quoteItemRows  : quoteItemRows });

                this.setState({
                    quoteForm               :   {
                        ...this.initQuoteForm,
                        transaction_date        :    '' ,
                        warehouse_id            :   quotationData.warehouse.id ? quotationData.warehouse.id : '',
                        customer_id             :   quotationData.customer ? quotationData.customer.id : '',
                        expected_validity_date  :   quotationData.validity_date ? quotationData.validity_date : '',
                        priority                :   quotationData.priority ? quotationData.priority : '',
                        status                  :   quotationData.status ? quotationData.status.id : 1,
                        currency                :   quotationData.currency ? quotationData.currency : '',
                        conversion_rate         :   quotationData.conversion_rate ? parseFloat(quotationData.conversion_rate).toFixed(2) : '',
                        notes                   :   quotationData.notes ? quotationData.notes : '',
                        deal_id                 :   quotationData.deal_data ? quotationData.deal_data.id : '',
                        adjustment_amount       :   quotationData.adjustment_amount_vc,
                        tnc                     :   quotationData.tnc,
                        authorized_signatory_id :   quotationData.authorized_signatory_id ? quotationData.authorized_signatory_id : "",
                        tcs_rate                :     quotationData.tcs_rate_vc  ? quotationData.tcs_rate_vc : '',
                        tcs_type                :    quotationData.tcs_type ? quotationData.tcs_type :'Percentage',
                        tcs_applicable          :     quotationData.tcs_rate && quotationData.tcs_rate > 0 ? 'Y' :'N',
                        users                   :   quotationData.users ? quotationData.users.map(st => st.enc_id) : [],
                        customer_project_id     :   quotationData.customerProject ? quotationData.customerProject.id : '',
                        ...updateFormState
                    },
                    searchedDeal            :  quotationData.deal_data ?  {value : quotationData.deal_data.id  ,display_label : quotationData.deal_data.header,label:quotationData.deal_data.header } : null,
                    searchedCustomer        :    quotationData.customer ? {value : quotationData.customer.id  ,display_label : quotationData.customer.name } : null,
                    CustomerDetails         :   quotationData.customer ? {value : quotationData.customer.id  ,display_label : quotationData.customer.name } : null,
                    selectedCustomerAdress  :   quotationData.customerAddress ? {...quotationData.customerAddress , gstin : quotationData.customer_gstin,id:quotationData.customer_address_id,country_id:quotationData.customerAddress.country_id ? quotationData.customerAddress.country_id : 99} : null,
                    quotationDetail         :   null,
                    selectedProject         :   quotationData.customerProject ? quotationData.customerProject : null,
                    user_status             :   quotationData.user_status,
                    quote_enable_tcs        :       (quotationData.tcs_rate && quotationData.tcs_rate > 0) || this.state.quote_enable_tcs == 'Y' ? 'Y' :'N'
                }, () => {
                    this.getWarehouseDetail(this.state.quoteForm.warehouse_id,true);
                    this.getCustomerDetails(this.state.quoteForm.customer_id,true);
                    this.handleCustomerChange(this.state.searchedCustomer,true)
                    let transactionIdSetting = this.state.allQuoteTypes ? this.state.allQuoteTypes.find(pt => pt.value == "quotation") : null
                    this.setState({
                        transactionIdSetting   : transactionIdSetting ? transactionIdSetting.type_data : null
                    })
                    //Remanage Status of Purchase Order :-
                    this.filterAllStatusOptions();
                })
            }
            if(ParamObject.get('dealId')) {
                const storedData = localStorage.getItem('selectedDealToAddQuotation');

                if (storedData) {
                    const {deal_header,customerData,customerProjectData } = JSON.parse(storedData);
                    let deal_id = ParamObject.get('dealId')

                    this.setState({quoteForm  :   {
                        ...this.initQuoteForm,
                        deal_id                 :   deal_id ? deal_id : '',
                        customer_project_id         :   customerProjectData ? customerProjectData.id : '',
                        customer_id         :   customerData ? customerData.id : '',
                    },
                    searchedCustomer          :   {value : customerData.id  ,display_label : customerData.name },
                    CustomerDetails           :   {value : customerData.id  ,display_label : customerData.name },
                     selectedCustomerAdress    :  customerProjectData ? customerProjectData.address :  null,
                    selectedProject         :   customerProjectData ? customerProjectData : null,
                    transactionFromDeal     :   true,
                    searchedDeal                :  deal_id ?  {value : deal_id  ,display_label : deal_header,label:deal_header } : null,
                    }, () => {
                   let transactionIdSetting = this.state.allQuoteTypes ? this.state.allQuoteTypes.find(pt => pt.value == "quotation") : null
                    this.setState({
                        transactionIdSetting   : transactionIdSetting ? transactionIdSetting.type_data : null
                    })
                    this.getCustomerDetails(this.state.quoteForm.customer_id,this.state.quoteForm.customer_project_id ? true : false);
                    this.handleCustomerChange(this.state.searchedCustomer,true)
                    //Remanage Status of Purchase Order :-
                    this.filterAllStatusOptions();
                    
                });
                } 
            } 
        });
    }
    
    filterAllStatusOptions                  =   ()  =>  {
        let allStatusOptions                    =   this.state.allStatusOptions;
        if(this.state.user_status.length > 0) {
            allStatusOptions                    =   allStatusOptions.filter(allStatus =>  this.state.user_status.find(ns => ns.id == allStatus.value) ? true : false );
            this.setState({
                allStatusOptions        :   allStatusOptions
            });
        }
    }
    
    onWarehouseChange                   =   (selectedOption) =>  {

        if(selectedOption){
            this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
        }else {
            this.setState({
                warehouseDetail :   null
            });
        }
     }
     
     getWarehouseDetail                  =   (warehouse_id)  =>  {
         if (warehouse_id) {
             this.setState({warehouseDetailLoading : true, warehouseDetail : null, warehouseSites : []});
             HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                 let respData = resp.data;
                 let sites = respData.data && respData.data.sites.length > 0 ? respData.data.sites.map(st => st.id) : []
                 this.setState({
                     warehouseDetail         :   respData.data,
                     warehouseSites          :   respData.data.sites,
                     allUsers                :   this.state.allUsers && this.state.allUsers.length > 0 && sites && sites.length > 0 ?  this.state.allUsers.filter(us =>  us.site_ids.some(value => sites.includes(value))) : this.state.allUsers
                 });
                
             }).then(() => {
                 this.setState({warehouseDetailLoading : false});
             });
         } else {
             this.setState({warehouseDetail: null,warehouseSites : []})
         }
     }

    getCustomerDetails                        =   (id,edit= false) =>  {
        if(id) {
            this.setState({Customer_details_loading : true})
            HttpAPICall.withAthorization('GET', this.CustomerUrl + '/' + id, this.props.access_token, null, null, (response) => {
                let selectedCustomerAdress   =   response.data.data && response.data.data.addressList ? response.data.data.addressList[0] : [];
                this.setState({
                    CustomerDetails           :   response.data.data,
                    CustomerAddressList       :   response.data.data.addressList,
                     selectedCustomerAdress    :  edit == false ? selectedCustomerAdress : this.state.selectedCustomerAdress,
                });
                
            }).then(() => {
                this.setState({ Customer_details_loading: false });
                this.setState({gstTypeName :  this.state.selectedCustomerAdress && this.state.warehouseDetail && this.state.selectedCustomerAdress.state_id == this.state.warehouseDetail.state_id ? 'GST' : 'IGST'});
            });
        }
    }

    handleCustomerChange = (selectedOption, edit=false) => {
        this.setState({
            quoteForm                     :   {
                ...this.state.quoteForm,
                customer_id               :   selectedOption ? selectedOption.value : null,
                // tnc                     :   edit == true ? this.state.quoteForm.tnc : []
            },
            CustomerDetails               :   selectedOption ? selectedOption : null,
            selectedProject               :   edit == true ? this.state.selectedProject : null  ,
            searchedCustomerAdressList    :   selectedOption && selectedOption.Customer ? selectedOption.Customer.addressList : null,
            selectedCustomerAdress        :   selectedOption && selectedOption.Customer && edit == false ? selectedOption.Customer.addressList[0] : this.state.selectedCustomerAdress,
            searchedCustomer              :   selectedOption ? selectedOption : null,
        });
    };
    
    getTermListing                          =   ()  =>  {
        this.setState({ termCoditionLoading: true });
        HttpAPICall.withAthorization('GET', this.termConditonUrl, this.props.access_token, {key:"quotation"}, {}, (response) => {
            this.setState({
                termCoditions           :   response.data.data,
                oldTNC                  :   response.data.data
            });
        }).then(() => this.setState({ termCoditionLoading: false }));
    }
    
    changeAddressHandler                    =   (id)    =>  {
        if(id){
            let selectedCustomerAdress  =   this.state.CustomerDetails && this.state.CustomerDetails.addressList 
                                                ? this.state.CustomerDetails.addressList.find(a => a.id == id) : null;
            this.setState({
                selectedCustomerAdress   :       selectedCustomerAdress
            })
        }
    }
   
    pickTermsInit                           =   ()  =>  {
        this.addTnCInPOModal.show();
        this.setState({pickTNCModalLoader : true});

        HttpAPICall.withAthorization('GET', this.TagUrlList, this.props.access_token, { type: 'quotation' }, {}, (resp) => {
            this.setState({ 
                tncTags: resp.data && resp.data.length > 0 ? resp.data.map(val => ({label : val.name, value: val.id})) : [] 
            });
        })
        .then(() => this.setState({pickTNCModalLoader : false}))
        .catch(() => this.setState({pickTNCModalLoader : false}))

    }

    
    addTncInPO                              =   (k)  => {
        let selectedTnc               =   this.state.termCoditions[k];
        if(selectedTnc) {
            this.setState({
                quoteForm   :   {...this.state.quoteForm, 
                                tnc :   [...this.state.quoteForm.tnc, {...selectedTnc, id : '', editMode : false}]
                            }
            }, () => { });
        }
    }

    getTermListingofPreviousCustomer                          =   ()  =>  {
        if(this.state.quoteForm && this.state.quoteForm.customer_id){
            this.setState({ termCoditionLoading: true });
            let params = {
                customer_id : this.state.quoteForm.customer_id,
                transaction_id      :   this.state.quoteForm && this.state.quoteForm.transaction_id ? this.state.quoteForm.transaction_id : null
            }
             HttpAPICall.withAthorization('GET', this.quotePreviousTnc, this.props.access_token, {...params}, {}, (response) => {
            
                if(response.data && response.data.length > 0) {
                    this.setState({
                        quoteForm   :   {...this.state.quoteForm, 
                                        tnc :   response.data.map(s =>  {return{name : s.name,text:s.text,id:'',editMode  :false}} ) 
                                    }
                    }, () => { });
                }else{
                    toast.error('No T&C available for this Customer', {position: toast.POSITION.TOP_RIGHT })
                }
            }).then(() => this.setState({ termCoditionLoading: false }));
        }else{
            toast.error('Please select Customer First', {position: toast.POSITION.TOP_RIGHT })
        }
        
    }
    
    addTermsModalInit                       =   ()  =>  {
        this.setState({
            addTermForm : {...this.initAddTermForm},
        });
        this.addTermsModal.show();

        if(this.state.tncTags.length <= 0) {
            this.setState({
                tncModalLoader: true
            });
            HttpAPICall.withAthorization('GET', this.TagUrlList, this.props.access_token, { type: 'quotation' }, {}, (resp) => {
                this.setState({ 
                    tncTags: resp.data && resp.data.length > 0 ? resp.data.map(val => ({label : val.name, value: val.id})) : [] 
                });
            }).then(() => {
                this.setState({tncModalLoader : false})
            });
        }

    }
    
    convertCurrency                         =   (selectedOption)   =>  {
        let params = {to : this.state.defaultCurrency , from : selectedOption.value}
        this.setState({currency_loading : true})
        HttpAPICall.withAthorization('GET', this.currency_convert_url + '/currency/convert', this.props.access_token, params, null, (response) => {
            let resp = response.data;
           
            this.setState({
                quoteForm  : {
                    ...this.state.quoteForm,
                    conversion_rate    : resp.rate,
                },
                //quoteItemRows      :   updatedPoItemRows,
                editCurrency    :  false,
                allDiscountType :    this.state.allDiscountType.map((s) => { return {value: s.value, label: s.value === "Fixed" ? selectedOption.value : "%"}; }),
                allTCSType      :    this.state.allTCSType.map((s) => { return {value: s.value, label: s.value === "Fixed" ? selectedOption.value : "%"}; }),
            });
       }).then(() => this.setState({currency_loading : false}))
    }
    
    addNewItemRow                           =   ()  =>  {
        this.setState({quoteItemRows : [...this.state.quoteItemRows, {...this.initQuoteItemForm} ] });
    }
    
    submitAddTermForm                       =   (e)  =>  {
        e.preventDefault()
        let frmData                             =   {...this.state.addTermForm};
        this.setState({addTermFormSubmitting: true})
        HttpAPICall.withAthorization('POST', this.termConditonAddUrl, this.props.access_token, {} , {...frmData,key:"quotation"} , (response) => {
            this.getTermListing();
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.addTermsModal.hide();
        }).then(() => this.setState({addTermFormSubmitting: false}));
    }
    
    removeItemRow                           =   (k,req_allocation_ids=[]) =>  {
        let allRows                 =   this.state.quoteItemRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({quoteItemRows : allRows});
        }

        if(req_allocation_ids && req_allocation_ids.length > 0 && this.state.allReqItemLists && this.state.allReqItemLists.length > 0){
            const filteredIds = this.state.allReqItemLists.filter(id => !req_allocation_ids.includes(id.req_item_id));
            this.setState({
                allReqItemLists : filteredIds
            })
        }
       
    }
    
    onItemSelect            =   (selectedOption, key, qty = 1,edit = false)  =>  {
       
        if(selectedOption && selectedOption.itemdata) {
            let item                =   selectedOption.itemdata;
            let rate                =   item && item.purchase_price ? item.purchase_price : 0;
            
            
            // if(this.state.quoteItemRows.find((po_item) => po_item.item && po_item.item.id == item.id) && edit == false ){
            //     toast.error('This Item is already available in this Quotation, please update the item', {position: toast.POSITION.TOP_RIGHT })
            // } else {
                if(item && item.purchase_currency !== this.state.quoteForm.currency && item.purchase_price){
                    let quoteItemRows          =   this.state.quoteItemRows;
                    quoteItemRows[key]     =   {...quoteItemRows[key],
                        req_allocation_loading  :   false,
                        item                    :   item,
                        qty                     :   qty,
                        rate                    :   parseFloat(rate).toFixed(2),
                        gst                     :   item.gst   ? item.gst : 0,
                    };
                    this.setState({
                        quoteItemRows : quoteItemRows
                    },() => {
                        HttpAPICall.withAthorization('GET', this.currency_convert_url + '/currency/convert', this.props.access_token, {from:item.purchase_currency,to :this.state.quoteForm.currency}, null, (response) => {
                            let resp = response.data;
                            rate        =   Number(item.purchase_price) * Number(resp.rate);
                            let quoteItemRows          =   this.state.quoteItemRows;
                            quoteItemRows[key]     =   {...quoteItemRows[key],
                                req_allocation_loading  :   false,
                                item                    :   item,
                                qty                     :   qty,
                                rate                    :   parseFloat(rate).toFixed(2),
                                gst                     :   item.gst   ? item.gst : 0,
                            };
                            this.setState({quoteItemRows : quoteItemRows}, () => {
                                if(!this.state.quoteItemRows.find(ir => !ir.item)) {
                                    setTimeout(() => {this.addNewItemRow()}, 1000);
                                }
                            });
                        })
                    })
                    
                }else{
                    let quoteItemRows          =   this.state.quoteItemRows;
                    quoteItemRows[key]     =   {...quoteItemRows[key],
                        item                    :   item,
                        qty                     :   qty,
                        rate                    :   parseFloat(rate).toFixed(2),
                        gst                     :   item.gst   ? item.gst : 0,
                    };
                    this.setState({quoteItemRows : quoteItemRows}, () => {
                        if(!this.state.quoteItemRows.find(ir => !ir.item)) {
                            setTimeout(() => {this.addNewItemRow()}, 1000);
                        }
                    }); 
                }
                
            // }
        }
    }
    

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.quoteItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.quoteItemRows[k] , item  : itemdata};
        this.setState({quoteItemRows : allRows});
    }

    addDueDate       =   (data,k)      =>      {
        let allRows                 =   this.state.quoteItemRows;
        let itemdata = {...data,isAddDueDate : true}
        allRows[k]                  =   {...this.state.quoteItemRows[k] , item  : itemdata};
        this.setState({quoteItemRows : allRows});
    }

    itemViewModalInit                       =   (data,k)  =>  {
        this.setState({ itemSearchedDetails        :    data,itemRowNumber : k})
    }

    itemSearchModalInit             =       ()          =>      {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows                 =   this.state.quoteItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });
        
    }

    editItemModalInit          =       (data)           =>      {
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal }), () => {
            if (this.state.showItemEditModal) {
                let allRows                 =   this.state.quoteItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if(this.editItemModalRef.current){
                    this.editItemModalRef.current.itemEditModalInit(data); 
                }
                
            }
        });
        
    }

    addItemModalInit          =       ()           =>      {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows                 =   this.state.quoteItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }

                if(this.addItemModalRef.current){
                    this.addItemModalRef.current.itemAddModalInit(); 
                }
            
            }
        });
        
    }

     //********************EDIT ITEM DETAILS ******************************* */


    submitEditItem          =       ()      =>      {
        HttpAPICall.withAthorization('GET',this.itemDetailUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    response.data.data
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber,this.state.quoteItemRows[this.state.itemRowNumber]?.qty)
               });
        })
       
    }
    
    addPurchaseOrderFormSubmitEvent         =   (e) => {
        e.preventDefault();
    }
    
    saveFormSubmitHandler                   =   (e)  =>  {
        e.preventDefault();
        let items                   =   [...this.state.quoteItemRows];
        let itemsLists              =   items.filter(i => i.item ? true : false)
                                                .map((i,k) => { return {
                                                    ...i, 
                                                    id : i.item.id, 
                                                    item : null,
                                                    ordo  : k
                                                }});
        let quoteStatus                =   this.state.quoteForm.status;
        let additional_attributes = {};
        this.state.formData.additional_attributes.forEach((i, k) => {
            let name = 'additional_attributes_' + i.key;
            additional_attributes[i.key] = this.state.quoteForm[name] ? this.state.quoteForm[name] : '';
        });
        //In case of edit :-
        if(this.props.location && this.props.location.state && this.props.location.state.quotationData) {
            quoteStatus                =   this.state.quoteForm.status;
        }
        
        let quoteForm               =     {
            ...this.state.quoteForm, 
            transaction_date        :   moment(this.state.quoteForm.transaction_date,'YYYY-MM-DD').format('YYYY-MM-DD'),
            customer_address_id     :   this.state.selectedCustomerAdress ? this.state.selectedCustomerAdress.id : '',
            adjustment_amount       :   Number(this.state.quoteForm.adjustment_amount),
            status : quoteStatus,
            additional_attributes   :   additional_attributes,
            customer_project_id     :   this.state.selectedProject ? this.state.selectedProject.id : ''
        };
        let formData  = {
            ...quoteForm,
            items                   :   itemsLists,
        };
        
        let showErrors              =   [];
        if(formData.transaction_date == '') {
            showErrors                      =   [...showErrors , 'Please enter Transaction Date.'];
        }
        if(formData.warehouse_id == '') {
            showErrors                      =   [...showErrors , 'Please select Warehouse.'];
        }
        if(formData.customer_id == '') {
            showErrors                      =   [...showErrors , 'Please select Customer.'];
        }
        if(formData.status == '') {
            showErrors                      =   [...showErrors , 'Please select Status.'];
        }
        if(itemsLists.length == 0) {
            showErrors                      =   [...showErrors , 'Please select Item and their qty.'];
        }
        if(formData.tcs_type == 'Percentage' && formData.tcs_rate > 100) {
            showErrors                      =   [...showErrors , 'TCS Percentage cannot be greater than 100'];
        }
        itemsLists.forEach((item,key) => {
            if(Number(item.qty) <= 0) {
                showErrors                      =   [...showErrors , 'Quantity can not be Zero(0).'];
            }
        });
        let itemCombinationList         =   itemsLists.map( v => {return [{item_id : v.id}]}).flat();
        let foundDuplicateCombination   =   itemCombinationList.find((al, index) => {
            if (al.item_id) {
                return itemCombinationList.find((x, ind) => al.item_id === x.item_id && index !== ind)
            } else {
                return null
            }
        });
        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        }else if(foundDuplicateCombination){
            toast.error('Quotaton cannot have dublicate Items', { position: toast.POSITION.TOP_RIGHT });
        } else {
            
            this.setState({ saveFormSubmitting: true });
            if(this.state.quotationDetail){
                HttpAPICall.withAthorization('PUT', this.QuotationEditUrl, this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/quotation_list`, state: {id :  this.state.quotationDetail.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                HttpAPICall.withAthorization('POST', this.QuotationAddUrl , this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/quotation_list`, state: {id :  response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }
    
    
    handleButtonEnable = () => {
        const allApiCallsCompleted = this.state.quoteItemRows.filter(i => i.item ? true : false).every(item => item.allApiCallsCompleted);
        this.setState({ buttonDisabled: !allApiCallsCompleted });
    };


    itemSearchModalInit             =       ()          =>      {
        let allRows                 =   this.state.quoteItemRows;
        let item_ids                =   [];
        if(allRows && allRows.length > 0){
            item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
        }
        this.itemSearchRef.current.searchItemModalInit(item_ids)
    }

    addItemsfromSearch          =       (selectedItems)     =>  {
        let quoteItemRows = selectedItems.map((po_item, k) => {
            let allocated_qty = 0
            return {
                ...this.initQuoteItemForm,
                item                        :       { ...po_item.item, isAddRemark: po_item.remark ? true : false },
                req_allocation_loading      :       true,
                req_allocation              :       [],
                prev_req_allocation         :        [],
                id                          :       po_item.item.id,
                qty                         :       po_item.qty,
                allocated_qty               :       allocated_qty,
                additional_qty              :       0,
                rate                        :        po_item.rate,
                remark                      :        po_item.remark,
                gst                         :        po_item.gst,
                discount_type               :        '',
                discount_rate               :       ''
            };
        });
        let allRows = this.state.quoteItemRows ?  this.state.quoteItemRows.filter(i => i.item ? true : false) : [];
        this.setState({
            quoteItemRows: allRows.concat(quoteItemRows) 
        }, () => {
            
        });
    
    }
     
    goToListing                             =   ()  =>  {
        if (this.state?.quotationDetail) {
            this.props.history.push({
              pathname: `/quotation_list`,
              state: { quoteId: this.state.quotationDetail.transaction_id },
            });
          } else {
            this.props.history.push({ pathname: `/quotation_list` });
          }
    }

    handleSelectedProject = (selectedProject = null) => {
        // Use the selectedProject value as needed
        this.setState({selectedProject : selectedProject})
    }
      
    pageTitleJsx                            =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3>{this.state.quotationDetail ? `Edit Quotation ID : ${this.state.quotationDetail.transaction_id}` : "New Quotation"}</h3>
                <div className="text-end mt15">
                    <button onClick={this.goToListing} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close PO" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    termConditionJsx                        =   ()  =>  {
        return (<div className="row align-items-center my-3">
            <div className="col-12">
                <div className="tab_content_wrapper" >
                    <span className="content_heading">Terms & Condititon </span>
                    <div className="float-end">
                        <a role="button" className="link-primary"  onClick={this.addTermsModalInit}><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add New </a>
                       <a role="button" className="link-primary cursor_pointer mx-2" onClick={this.pickTermsInit}>+ Pick Terms & Condititon </a>
                    </div>
                </div>
                <table className="table table-bordered table-responsive bg-white table-sm my-3">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" className="text-center" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "30%" }}>Name</th>
                            {/* <th scope="col" style={{ width: "20%" }}>Tag</th> */}
                            <th scope="col" style={{ width: "55%" }}>Description</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.termCoditionLoading 
                        ? <tr><td className="text-center" colSpan="5"><Loader /></td></tr>
                        : (this.state.quoteForm?.tnc?.length == 0
                            ? <tr><td className="text-center link-primary cursor_pointer" colSpan="5" onClick={this.getTermListingofPreviousCustomer}>+ Pick T&C from previous Quotation of selected Customer</td></tr>
                            : (this.state.quoteForm?.tnc?.map((po_tnc, k) => {
                                return <tr key={k}>
                                    <td>{k+1}</td>
                                    <td>{po_tnc.editMode 
                                            ? <input
                                                    name="text"
                                                    type="text"
                                                    value={po_tnc.name}
                                                    onChange={(e) => this.dynamicInputHandlerTwoLevelByKeyNameVal(e.target.value,'name', k, 'tnc', 'quoteForm')}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    placeholder="Notes"
                                                /> 
                                            : po_tnc.name
                                        }
                                    </td>
                                    {/* <td>{po_tnc.editMode 
                                            ? <input
                                                    name="tag"
                                                    type="text"
                                                    value={po_tnc.tag}
                                                    onChange={(e) => this.dynamicInputHandlerTwoLevelByKeyNameVal(e.target.value,'tag', k, 'tnc', 'quoteForm')}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    placeholder="Notes"
                                                /> 
                                            : po_tnc.tag
                                        }
                                    </td> */}
                                    <td>{po_tnc.editMode 
                                            ? <input
                                                    name="text"
                                                    type="text"
                                                    value={po_tnc.text}
                                                    onChange={(e) => this.dynamicInputHandlerTwoLevelByKeyNameVal(e.target.value,'text', k, 'tnc', 'quoteForm')}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    placeholder="Notes"
                                                /> 
                                            : po_tnc.text
                                        }
                                    </td>
                                    <td className="text-center">
                                        { po_tnc.editMode
                                            ? <button
                                                className="btn btn-light"
                                                    onClick={() => this.dynamicInputHandlerTwoLevelByKeyNameVal(false, 'editMode', k, 'tnc' ,'quoteForm')}
                                                ><tapIcon.FontAwesomeIcon icon={tapIcon.faCheck} color="#45BEA9" />
                                              </button>
                                            : <Ax>
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" role="button"
                                                        onClick={() => this.dynamicInputHandlerTwoLevelByKeyNameVal(true, 'editMode', k, 'tnc' ,'quoteForm')}
                                                    >Edit</a></li>
                                                    <li><a 
                                                        className="dropdown-item" 
                                                        role="button"
                                                        onClick={() => this.dynamicInputDeleteTwoLevelByKeyNameVal(k, 'tnc' ,'quoteForm')}
                                                    >Delete</a></li>
                                                </ul>
                                            </Ax>
                                        }
                                    </td>
                                </tr>
                            }))
                        ) 
                    }
                    </tbody>
                </table>
            </div>
        </div>)
    }
    
    purchaseOrderItemJsx                    =   ()  =>  {
        let sub_total_amount                    =   0;
        let gst_total_amount                    =   0;
        let gst_slab                            =   [];
        let total_amount                        =   0;
        let purchase_summary                    =   [];
        let adjustment_amount                   =   Number(this.state.quoteForm.adjustment_amount);
      
        
        this.state.quoteItemRows.forEach((item,key) => {
            if(item && item.item) {
                let item_amount             =   Number(item.qty * item.rate);
                let discount_amout          =   Number(item.discount_rate && item.discount_rate > 0
                                                    ?  item.discount_type == "Percentage" ? (item_amount*item.discount_rate/100) : item.discount_rate
                                                    : 0);
                let item_with_discount      =   item_amount - discount_amout;
                let tax_amount              =   0;
                
                if(item.gst && item.gst > 0 && this.state.selectedCustomerAdress && this.state.selectedCustomerAdress.gstin) {
                    tax_amount              =   item_with_discount * item.gst / 100;
                    if(!gst_slab[item.gst]) {
                        gst_slab[item.gst]  =   [];
                    }
                    gst_slab[item.gst].push(tax_amount);
                }
                let item_final_amount       =   item_with_discount + tax_amount;
                sub_total_amount           +=   item_with_discount;
                gst_total_amount           +=   tax_amount;
                total_amount               +=   item_final_amount; 
            }
        });
        
        purchase_summary.push({key : 'Sub Total' , value : sub_total_amount.toFixed(2)});

        if (gst_total_amount > 0) {
            gst_slab.forEach((tax_amounts, gst) => {
                let total_tax = tax_amounts.reduce((sum, a) => sum + a, 0);
                if (this.state.selectedCustomerAdress && this.state.warehouseDetail && this.state.selectedCustomerAdress.state_id == this.state.warehouseDetail.state_id) {
                    let tax = gst / 2;
                    purchase_summary.push({ key: 'CGST ' + tax + '%', value: (total_tax / 2).toFixed(2) });
                    purchase_summary.push({ key: 'SGST ' + tax + '%', value: (total_tax / 2).toFixed(2) });
                } else {
                    purchase_summary.push({ key: 'IGST ' + gst + '%', value: total_tax.toFixed(2) });
                }
            });
        } else if (gst_total_amount == 0 && this.state.gst_as_applicable == 'Y') {
            if (this.state.selectedCustomerAdress && this.state.warehouseDetail && this.state.selectedCustomerAdress.state_id == this.state.warehouseDetail.state_id) {
             
                purchase_summary.push({ key: 'CGST ', value: "As applicable" });
                purchase_summary.push({ key: 'SGST ', value: "As applicable" });
            } else {
                purchase_summary.push({ key: 'IGST ', value: "As applicable" });
            }
          
        }

        let tcs_amount                          =   Number(this.state.quoteForm.tcs_rate && this.state.quoteForm.tcs_rate >= 0
            ?  this.state.quoteForm.tcs_type == "Percentage" ? (sub_total_amount*this.state.quoteForm.tcs_rate/100) :  Number(this.state.quoteForm.tcs_rate)
            : 0);

            

        return (<Ax>
            <div className="my-3 row">
                <div className="col-sm-12">
                    <table className="table table-bordered table-responsive bg-white ">
                        <thead className="table-secondary">
                            <tr>
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" className="text-center" style={{ width: "30%" }}>Item Details</th>
                                <th scope="col" className="text-center" style={{ width: "8%" }}>Quantity</th>
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Unit Rate</th>
                                <th scope="col" className="text-center" style={{ width: "8%" }}>Discount</th>
                                <th scope="col" className="text-center" style={{ width: "8%" }}>GST</th>
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Amount</th>
                                <th scope="col" className="" style={{ width: "5%" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.quoteItemRows.map((itemRow, itemKey) => {
                                let item = itemRow.item;
                                
                                let item_with_discount = 0;
                                if (item) {
                                    let item_amount = Number(itemRow.qty * itemRow.rate);
                                    let discount_amout = Number(itemRow.discount_rate && itemRow.discount_rate > 0
                                        ? itemRow.discount_type == "Percentage" ? (item_amount * itemRow.discount_rate / 100) : itemRow.discount_rate
                                        : 0);
                                    item_with_discount = item_amount - discount_amout;
                                }
                                return <tr className="" key={itemKey}>
                                    <td className="text-center align-middle">{itemKey + 1}</td>
                                    {item
                                        ? (<Ax><td>
                                            <div className="row">
                                                <div className='col-sm-10'>
                                                    <span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>{item.name}</span>
                                                    <div className="form-text">Code: <b>{item.item_code}</b></div>
                                                    <div className="form-text">Mfr: <b>{item && item.manufacturer ? item.manufacturer.name : '-'}</b>
                                                        (MPN: <b>{item && item.manufacturer_part_no ? item.manufacturer_part_no : "-"}</b> )</div>

                                                </div>
                                                <div className='col-sm-2 text-end'>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu cursor_pointer">
                                                        <li className="dropdown-item" onClick={() => { this.addRemark(itemRow.item, itemKey) }}>Add Remark</li>
                                                        <li className="dropdown-item" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>View Details</li>
                                                        {this.props?.permissions?.permissions?.includes("isc-item-edit") &&  <li><a href="#" className="dropdown-item" onClick={() => {this.setState({showItemEditModal : false},() => {this.itemViewModalInit(item, itemKey); this.editItemModalInit(item)})  }}>Edit Item</a></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                            {item && item.isAddRemark && item.isAddRemark == true
                                                ?
                                                <div className='my-1'>
                                                    <textarea
                                                        name="remark"
                                                        value={this.state.quoteItemRows[itemKey].remark}
                                                        onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e.target.value, 'remark', itemKey, 'quoteItemRows')}
                                                        className="form-control mt10"
                                                        placeholder="Item Remarks"
                                                        rows="1"
                                                    />
                                                </div>
                                                : null}

                                        </td>
                                            {this.state.quoteItemRows[itemKey].req_allocation_loading
                                                ? (<td colSpan="5"><Loader /></td>)
                                                : <Ax>
                                                   
                                                    <td>
                                                        <TapInputNumber
                                                            name="qty"
                                                            value={this.state.quoteItemRows[itemKey].qty}
                                                            onChange={(newValue) => {
                                                                const updatedRows = [...this.state.quoteItemRows];
                                                                updatedRows[itemKey].qty = newValue; // Update the quantity in the corresponding row
                                                                this.setState({ quoteItemRows: updatedRows });
                                                            }}
                                                            placeholder="Qty"
                                                            required={true}
                                                        />
                                                        
                                                        <span className="text-helping input-group-text text-end pr0">{item.measuring_unit ? item.measuring_unit.name : ''}</span>
                                                    </td>
                                                    <td>
                                                        
                                                        <TapInputNumber
                                                            name="rate"
                                                            value={this.state.quoteItemRows[itemKey].rate}
                                                            onChange={(newValue) => this.dynamicInputHandlerByKeyNameVal(newValue, 'rate', itemKey, 'quoteItemRows')}
                                                            className="form-control text-end"
                                                            placeholder="Qty"
                                                            required={true}
                                                            min={0}
                                                        />
                                                     
                                                    </td>
                                                    <td>
                                                        <TapInputNumber
                                                            name="discount_rate"
                                                            value={this.state.quoteItemRows[itemKey].discount_rate}
                                                            onChange={(newValue) => this.dynamicInputHandlerByKeyNameVal(newValue, 'discount_rate', itemKey, 'quoteItemRows')}
                                                            className="form-control text-end"
                                                            placeholder="Discount"
                                                        />
                                                        <TapSelect
                                                            changeEvent={(selectedOption, e) => {
                                                                this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'discount_type', itemKey, 'quoteItemRows')
                                                            }}
                                                            options={this.state.allDiscountType}
                                                            isSearchable={false}
                                                            value={this.state.allDiscountType.find(s => s.value == this.state.quoteItemRows[itemKey].discount_type)}
                                                            placeholder="Discount"
                                                        />
                                                    </td>
                                                    <td className='text-end rightSelectText'>
                                                        {this.state.warehouseDetail && this.state.warehouseDetail.gstin ? <TapSelect
                                                            changeEvent={(selectedOption, e) => {
                                                                this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'gst', itemKey, 'quoteItemRows')
                                                            }}
                                                            options={this.state.allGstSlabs}
                                                            isSearchable={false}
                                                            value={this.state.allGstSlabs.find(s => s.value == this.state.quoteItemRows[itemKey].gst)}
                                                            placeholder="GST"
                                                        /> : "-"}
                                                        {(this.state.warehouseDetail && this.state.warehouseDetail.gstin)
                                                            ? item ? <span className="text-helping input-group-text text-end pr0">{this.state.gstTypeName}</span> : null

                                                            : <div className='text-center'>NA <sup className='text-danger'>#</sup></div>}
                                                    </td>
                                                    <td className="text-end className='pt0'"><NumberFormatter number={item_with_discount} precision={2}/></td>
                                                </Ax>
                                            }
                                        </Ax>)
                                        : <Ax>
                                            <td className="align-middle">
                                                <InputItemSearch
                                                    changeEvent={(selectedOption) => {
                                                        this.onItemSelect(selectedOption, itemKey);
                                                    }}
                                                    only_active_item='Y'
                                                />
                                            </td>
                                            <td colSpan="5"></td>
                                        </Ax>
                                    }
                                    <td className="text-center align-middle">
                                        {this.state.quoteItemRows.length > 1
                                            ? <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                                                <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                            </a>
                                            : null
                                        }
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="offset-8 col-sm-4 text-end">
                    {this.state.quoteForm && this.state.quoteForm.warehouse_id  ?
                    <a role="button"  onClick={() => {this.setState({showItemSearchModal : false},() => this.itemSearchModalInit())}} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                    : null} 
                      {this.props?.permissions?.permissions?.includes("isc-item-add") &&  <a role="button" onClick={() => {this.setState({showItemAddModal : false},() => this.addItemModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                        <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                    </div>
            </div>

            <div className="row align-items-center my-3">
                <div className="col-sm-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        className="form-control"
                        autoComplete="off"
                        required
                        placeholder="Please Enter Notes"
                        onChange={(e) => { this.formInputHandler(e, 'quoteForm') }}
                        value={this.state.quoteForm.notes}
                        style={{ height: "80px" }}
                    />
                </div>
                <div className="col-sm-6">
                    <table className="table  table-borderless">
                        <tbody>
                            {purchase_summary && purchase_summary.length > 0
                                ? (purchase_summary.map((ps, k) => {
                                    return <tr key={k} className="subtotal_bg_color"><th>{ps.key} ({this.state.quoteForm.currency})</th><td className="text-end">{ps.value}</td></tr>
                                }))
                                : null
                            }
                            {this.state.quote_enable_tcs == 'Y' && <tr className="subtotal_bg_color">
                                <th>
                                   <div className='row align-items-center'>
                                        <div className='col-sm-4'>
                                        <input
                                        name="tcs_applicable"
                                        type="checkbox"
                                        value={this.state.tcs_applicable}
                                        onChange={(e) => {
                                            this.setState({
                                                tcs_applicable: this.state.tcs_applicable == 'N' ? 'Y' : 'N',
                                            },() => {
                                                if(this.state.tcs_applicable == 'N'){
                                                    this.setState({
                                                        quoteForm      :       {
                                                            ...this.state.quoteForm,
                                                            tcs_rate     :   0
                                                        }
                                                    })
                                                }
                                            })
                                        }}
                                        checked={this.state.tcs_applicable == 'Y'}
                                        className="form-check-input"
                                        id="tcs_applicable"
                                    />
                                    <label className="form-check-label mx-1 " htmlFor="tcs_applicable">Enable TCS</label>
                                        </div>
                                        <div className='col-sm-3'>
                                            <TapSelect
                                                changeEvent={(selectedOption, e) => {
                                                    this.tapSelectChange(selectedOption, 'quoteForm', 'tcs_type');
                                                }}
                                                options={this.state.allTCSType}
                                                isSearchable={false}
                                                value={this.state.allTCSType.find(s => s.value == this.state.quoteForm.tcs_type)}
                                                placeholder="TCS"
                                                disabled={this.state.tcs_applicable == 'N'}
                                            />
                                        </div>
                                        <div className='col-sm-5'>

                                            <input
                                                name="tcs_rate"
                                                type="number"
                                                value={this.state.quoteForm.tcs_rate}
                                                onChange={(e) => this.formInputHandler(e, 'quoteForm')}
                                                className="form-control text-end"
                                                autoComplete="off"
                                                placeholder="TCS Amount"
                                                disabled={this.state.tcs_applicable == 'N'}
                                            />
                                        </div>
                                   </div>
                                </th>
                                <td className='float-end' style={{lineHeight:3}}> {tcs_amount ? <NumberFormatter number={tcs_amount} precision={2} /> : <NumberFormatter number={tcs_amount} precision={2} />}</td>
                               
                            </tr>}
                          
                            <tr className="subtotal_bg_color">
                                <th>Adjustment</th>
                                <td><input
                                    name="adjustment_amount"
                                    type="number"
                                    value={this.state.quoteForm.adjustment_amount}
                                    onChange={(e) => this.formInputHandler(e, 'quoteForm')}
                                    className="form-control text-end"
                                    autoComplete="off"
                                    placeholder="Adjustment"
                                /></td>
                            </tr>

                            <tr className="subtotal_bg_color">
                                <th>Final Total ({this.state.quoteForm.currency})</th>
                                <td className='float-end'> {adjustment_amount ? <NumberFormatter number={total_amount + adjustment_amount + tcs_amount} precision={2} /> : <NumberFormatter number={total_amount + tcs_amount} precision={2} />}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </Ax>);
    }
     
    saveQuotaionFormJsx                =   ()  =>  {
        let transactionDate = this.state.quoteForm && this.state.quoteForm.transaction_date ?  moment(this.state.quoteForm.transaction_date).format('YYYY-MM-DD 00:00:00') : null;
       return (<form className="bg-white p-3" onSubmit={this.addPurchaseOrderFormSubmitEvent}>
        
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Quotation Number </label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="transaction_id"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.quoteForm.transaction_id}
                        onChange={(e) => this.formInputHandler(e, 'quoteForm')}
                        disabled={this.state.transactionIdSetting && this.state.transactionIdSetting.type == 'system_generate'}
                        placeholder={this.state.transactionIdSetting && this.state.transactionIdSetting.type == 'system_generate' ? 'System Generated' : 'Enter Quotation'}
                        required={this.state.transactionIdSetting && this.state.transactionIdSetting.type != 'system_generate'}
                    />
                </div>
                <div className="col-5 form-text">
                    {this.state.transactionIdSetting && this.state.transactionIdSetting.type == 'system_generate' ? this.state.transactionIdSetting.msg : 'You have selected manual numbering'}
                </div>
              
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="transaction_date" className="form-label require ">Date of Quotation</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.quoteForm.transaction_date ? moment(this.state.quoteForm.transaction_date, 'YYYY-MM-DD').toDate() : false}
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'quoteForm')}
                        dateFormat="dd-MMM-yyyy"
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText="Please Enter Date of Transaction"
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'quoteForm', 'warehouse_id', () => {
                                this.onWarehouseChange(selectedOption);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allWarehouses.find(m => m.value == this.state.quoteForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    />
                </div>
            </div>
            
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }
            <Customer
                CustomerDetails={this.state.CustomerDetails}
                details_loading={this.state.details_loading}
                CustomerAddressList={this.state.CustomerAddressList}
                Customer_details_loading={this.state.Customer_details_loading}
                selectedCustomerAdress={this.state.selectedCustomerAdress}
                searchedCustomer={this.state.searchedCustomer}
                onChange={this.handleCustomerChange}
                getCustomerDetails={this.getCustomerDetails}
                CustomerRef={this.addCustomerModalRef}
                changeAddressHandler={this.changeAddressHandler}
                onSelectProject={this.handleSelectedProject}
                selectedProject={this.state.selectedProject}
                disabled={this.state.transactionFromDeal == true ? true : false}
            />

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require ">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'quoteForm', 'status');
                        }}
                        options={this.state.allStatusOptions}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.quotationDetail}
                        value={this.state.allStatusOptions.find(s => s.value == this.state.quoteForm.status)}
                        placeholder="Select Status"
                    />
                </div>
            </div>
            
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Priority & Validity</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'quoteForm', 'priority')}
                        options={this.state.allPriorityOptions}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allPriorityOptions.find(s => s.value == this.state.quoteForm.priority)}
                        placeholder="Select Priority"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.quoteForm.expected_validity_date
                                ? moment(this.state.quoteForm.expected_validity_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="expected_validity_date"
                        onChange={(value, event) => this.formDateHandler('expected_validity_date', value, 'quoteForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Validity  Date`}

                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Deals</label>
                </div>

               
               <div className="col-sm-4">
                   <InputDealSearch
                       changeEvent={(selectedOption) => {
                           this.tapSelectChange(selectedOption, 'quoteForm', 'deal_id');
                           this.setState({ searchedDeal: selectedOption ? selectedOption : null });
                       }}
                       menuPlacement="top"
                       search_customer_id={this.state.quoteForm.customer_id}
                       search_customer_project_id={this.state.selectedProject ? this.state.selectedProject.id : ''}
                       value={this.state.searchedDeal}
                       changeDeal={(deal) => {
                           this.tapSelectChange(deal ? deal.deal_id : '', 'quoteForm', 'deal_id');
                           this.setState({ searchedDeal: deal ? deal: null });
                       }}
                       showSearch={true}
                       disabled={!this.state.quoteForm.customer_id || this.state.transactionFromDeal}
                   />
               </div>
               
            </div>
           
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label ">Tag Users</label>
                </div>
                <div className="col-4">
                <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'quoteForm', 'users')}
                        options={this.state.allUsers}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allUsers.filter(t => this.state.quoteForm.users.includes(t.value))}
                        placeholder="Please Select User"
                    />
                </div>
               
            </div>
            {this.state.multiple_currency_available == 'Y' && <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label ">Currency</label>
                </div>
                <div className={ "col-4"}>
                    <TapSelect placeholder="Currency"
                        className="form-control "
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'quoteForm', 'currency',() =>  {
                                this.convertCurrency(selectedOption);
                            });
                            
                        }}
                        options={this.state.allCurrencyList}
                        value={this.state.allCurrencyList.find(m => m.value == this.state.quoteForm.currency)}
                    />
                     
                </div>
                {this.state.quoteForm.currency == this.state.defaultCurrency
                    ? null
                    : <Ax>
                        {this.state.currency_loading ? <div className='col-4 '> <Loader /></div>

                            : <div className='col-4 d-flex justify-content-between'>

                                <Ax>
                                    <div><input placeholder="Currency" className='form-control' name="conversion_rate" value={`1 ${this.state.quoteForm.currency} = `} disabled="true" /></div>
                                    <div className='text-end'><input placeholder="Currency Ratio" type='number' min="0" autoComplete='off' className='form-control' name="conversion_rate" value={this.state.quoteForm.conversion_rate} onChange={(e) => this.formInputHandler(e, 'quoteForm')} /></div>
                                    <div><input placeholder="Currency" className='form-control' name="conversion_rate" value={this.state.defaultCurrency} disabled="true" /></div>
                                </Ax>
                               
                            </div>}
                    </Ax>
                }

               
                
            </div>}
            {this.state.quote_enable_authorized_signatory == 'Y' && <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label ">Authorized Signatory</label>
                </div>
                <div className="col-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'quoteForm', 'authorized_signatory_id');
                        }}
                        options={this.state.allUsersList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allUsersList.find(m => m.value == this.state.quoteForm.authorized_signatory_id)}
                        placeholder="Select Authorized Signatory"
                    />

                </div>

            </div>}
            {this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0 && <AdditionalAttributeForm
                    additionalAttributes={this.state.formData.additional_attributes}
                    formState={this.state.quoteForm}
                    onAttributeChange={(value,attributeName) => {
                        this.setState(() => ({
                        quoteForm: {
                            ...this.state.quoteForm,
                            [attributeName]: value
                        }
                    }))}
                } 
                />}
         

            {this.purchaseOrderItemJsx()}
            {this.termConditionJsx()}
           {!this.state.buttonDisabled && <div className="col-12 text-end fixed_footer">
               <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} onClick={this.goToListing} className="btn btn-secondary ms-2">Cancel</button>
               <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} className="btn btn-secondary ms-2" onClick={(e) => { this.saveFormSubmitHandler(e); }}>
                   Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
               </button>
               <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} className="btn btn-primary ms-2" onClick={(e) => { this.saveFormSubmitHandler(e); }}>
                   Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
               </button>

           </div>}

        </form>)
    }

    searchTNCHandle  = (selectedOption) => {

        if(selectedOption.length > 0) {
            const tags = selectedOption.map(tag => tag.value);
            const termCoditions = this.state.oldTNC;
    
            let tcData = termCoditions.filter(item => item.tags.some(tag => tags.includes(tag.id))).filter((tc, index, tcDataArr) => index === tcDataArr.findIndex(t => t.name === tc.name));

            this.setState({
                searchTNCByTags : tags,
                termCoditions : tcData
            });         
        }else {
            this.setState({
                searchTNCByTags : [],
                termCoditions : this.state.oldTNC
            });  
        }
        

    }
    
    addTermsModalJsx                        =   ()  =>  {
        return (
            <div className="modal fade" id="addTermsModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addTermsModalLabel">Terms & Condition</h5>
                        </div>
                        {
                            this.state.tncModalLoader ?
                            <Loader/>
                            :
                            <>
                                <form onSubmit={this.submitAddTermForm} id="addTermsForm" method="post" encType="multipart/form-data">
                                    <div className="modal-body">
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label ">Name</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => { this.formInputHandler(e, 'addTermForm') }}
                                                    value={this.state.addTermForm.name}
                                                    placeholder="Please Enter Term Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label ">Tag</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <TapSelect
                                                    options={this.state.tncTags}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTermForm', 'tag_ids')}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.tncTags.filter(s => this.state.addTermForm.tag_ids.includes(s.value))}
                                                    isMulti={true}
                                                    placeholder="Select Tag"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require ">Value</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <textarea
                                                    name="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    required
                                                    placeholder="Please Enter Text"
                                                    onChange={(e) => { this.formInputHandler(e, 'addTermForm') }}
                                                    value={this.state.addTermForm.text}
                                                    style={{ height: "80px" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" 
                                            disabled={this.state.addTermFormSubmitting} 
                                            className="btn btn-secondary mx-2" 
                                            data-bs-dismiss="modal" 
                                        >Close</button>
                                        <button type="submit" disabled={this.state.addTermFormSubmitting} className="btn btn-primary">
                                            Submit {this.state.addTermFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                        </button>
                                    </div>
                                </form>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
    
    addTncInPOModalJsx                      =   ()  =>  {
        return (
            <div className="modal fade" id="addTnCInPOModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addTnCInPOModalLabel">Pick Terms & Condititon</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        {
                            this.state.pickTNCModalLoader 
                            ?
                            <Loader/>
                            :
                            <>
                            <div className="row align-items-center">
                            <div className="col-sm-6">
                            </div>
                            <div className="col-sm-6">
                                <TapSelect
                                    options={this.state.tncTags}
                                    changeEvent={(selectedOption) => this.searchTNCHandle(selectedOption)}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.tncTags.filter(s => this.state.searchTNCByTags.includes(s.value))}
                                    isMulti={true}
                                    placeholder="Select Tag"
                                    containerHeight="32px"
                                    fontSize="93%"
                                />
                            </div>
                            </div>
                            <table className="table table-bordered table-responsive bg-white my-2" id="poTermTable">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" className="text-start" style={{ width: "20%" }}>Name</th>
                                        <th scope="col" className="text-start" style={{ width: "50%" }}>Description</th>
                                        <th scope="col" style={{ width: "20%" }}>Tag</th>
                                        <th scope="col" className="text-start" style={{ width: "10%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.termCoditions?.map((tnc, k) => {
                                            let picked = this.state.salesForm?.tnc?.find((potnc) => potnc.name == tnc.name);
                                        return <tr key={k}>
                                            <td>{tnc.name}</td>
                                            <td>{tnc.text}</td>
                                            <td>{tnc.tags.length > 0 &&
                                                    tnc.tags.map(tag => tag.name).join(', ')
                                                }
                                            </td>
                                            <td className="text-center">
                                                <button 
                                                    disabled={picked}
                                                    onClick={() => this.addTncInPO(k)} 
                                                    className={["btn", "py-1", "px-3",  picked ? "btn-light" : "btn-secondary"].join(' ')} 
                                                >{picked 
                                                    ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} />
                                                    : 'Pick' }
                                                </button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            </>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Quotation Add</title></Helmet>
            {this.pageTitleJsx()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        { this.state.quoteFormDataLoaded  && this.state.quoteEditFormDataLoaded
                            ?   this.saveQuotaionFormJsx()
                            :   <Loader/>
                        }
                        {this.addTermsModalJsx()}
                        {this.addTncInPOModalJsx()}
                    </div>
                </div>
            </div>

            {this.state.showItemAddModal && <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
            {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
            {this.state.showItemSearchModal && <ItemSearchModal ref={this.itemSearchRef}
                            afterSelectingItems={(selectedItems) => {
                                this.addItemsfromSearch(selectedItems);
                            }}
                        />}
            
            <ItemDetailsModal itemData={this.state.itemSearchedDetails} closeEvent={this.itemDetailsClose} /> 
             <AddCustomerModal parentProps={this.props} ref={this.addCustomerModalRef} afterEditSubmit={(selectedCustomer) => this.handleCustomerChange(selectedCustomer)}/> 
            
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        orgInfoData             :   state.app.group_info ? state.app.group_info : null,
        permissions            :   state.app.acl_info,
        iam_group_sites         :   state.app && state.app.user_sites ? state.app.group_sites : [],
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : '',
        currency_list           :   state.app && state.app.currency_list && state.app.currency_list.length > 0 ? state.app.currency_list : []
    };
};

export default connect(mapStateToProps)(QuotationAdd);