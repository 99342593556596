import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import Status from '../../../components/ui/Status';
import { Helmet } from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from "moment";
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import DatePicker from "react-datepicker";
import AssetRentalView from '../../assets/measurableAsset/assetRental/AssetRentalView';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';

class AssetRental extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState       =   {
            search_asset_ids                         :   null,
            search_site_ids                         :   [],
            'asset_group_ids'                       :    [],
            search_asset_type_ids                   :   [],
            deployment_date_range                   :   '',
            deployment_date_range_start             :   '',
            deployment_date_range_end               :   '',
            dispatch_date_range                     :   '',
            dispatch_date_range_start               :   '',
            dispatch_date_range_end                 :   '',
            tentative_release_date_range            :   '',
            tentative_release_date_range_start      :   '',
            tentative_release_date_range_end        :   '',
            wo_due_date_range                       :   '',
            wo_due_date_range_start                 :   '',
            wo_due_date_range_end                   :   '',
            search_asset_category                   :   '',
            search_status_ids                       :   [],
            search_client_ids                       :   [],
            wo_due_date_status                      :   [],
            tentative_release_date_status           :   []
        };
        
        this.reportFilterFormLabel              =   {
            search_asset_ids                         :   "Assets : ",
            search_site_ids                         :   'Sites : ',
            'asset_group_ids'                       :   'Asset Type Groups : ',
            search_asset_type_ids                   :   'Asset Type : ',
            search_client_ids                       :   'Client Name : ',
            search_asset_category                   :   'Asset Category : ',
            search_status_ids                       :   'Commercial Status : ',
            deployment_date_range                   :   'Date of Deployment Range : ',
            dispatch_date_range                     :   'Date of Dispatch Range : ',
            tentative_release_date_range            :   'Date of Tentative Release Range : ',
            wo_due_date_range                       :   'Date of WO Expiry Range : ',
            wo_due_date_status                      :   'Date of WO Expiry Status :  ',
            tentative_release_date_status           :   'Date of Tentative Release Status : '
        }

        this.initRentalForm         =       {
            status                  :       '',
            client_id               :       '',
            client_name             :       '',
            rental_amount           :       null,
            potential_rent_amount   :       null,
            remark                  :       '',
            wo_detail               :       '',
            wo_date	                :       '',
            wo_due_date             :       '',
            date_dispatch           :       '',
            date_receipt            :       '',
            date_deployment         :       '',
            date_release            :       '',
            date_release_tentative  :       '',
            state_id                :       '',
            district_id             :       '',
            status_change_date      :       ''
        }

        this.initState                      =       {
            additional_attribute           :        [],
            addNewClient                   :        'N',
            addRentalForm                  :        {...this.initRentalForm},
            saveFormSubmitting             :        false,
            update_status                  :       'N',
            update_client                  :       'N',
            update_rental_amount           :       'N',
            update_potential_rent_amount   :       'N',
            update_remark                  :       'N',
            update_wo_detail               :       'N',
            update_wo_date	               :       'N',
            update_wo_due_date             :       'N',
            update_date_dispatch           :       'N',
            update_date_receipt            :       'N',
            update_date_deployment         :       'N',
            update_date_release            :       'N',
            update_date_release_tentative  :       'N',
            update_location                :       'N',
        }
        
        this.state                              =   {
            iam_asset_types                 :           [],
            iam_user_sites                  :           [],
            formDataLoaded                  :           false,
            filterFormData                  :           null,
            reportFilterForm                :            {...this.reportFilterFormInitialState},
            submittedReportFilterForm       :            {...this.reportFilterFormInitialState},
            listing_loading                 :            false,
            downloadReportBtn               :            false,
            formSearchedElems               :            [],
            listingData                     :            [],
            listingMeta                     :            null,
            reportDownloadBtn               :            false,
            reportDownloading               :            false,
            all_periods                     :            [],
            allStatusList                   :            [],
            allClientList                   :             [],
            allAttributeList                :            [],
            allStateList                    :            [],
            allDistrictList                 :            [],
            allFormStatusList               :            [],
            allFormClientList               :            [],
            selected_asset_ids              :           [],
            ...this.initState,
            updateFormDataLoading           :           false,
            updateFormDataLoaded            :           false,
            additional_attributes           :           [],
            selectedAssetId                 :           '',
            selectedAssetName               :           '',
            selectedSiteName                :           '',
            iam_asset_category              :           [],
            allDueDateStatus                :           [],
            allAssetTypesGroupsList         :           [],
            reportData                      :   null,  
            searchedAssets                   : null,                
        };

        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.formDataUrl        =   IAM_API_BASE_URL_2 + '/report/asset_with_rental/form_data';
        this.reportUrl          =   IAM_API_BASE_URL_2 + '/report/asset_with_rental';


    }
    
    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
        this.updateRentalModal      =   new Modal(document.getElementById("updateRentalDetailModal"), {keyboard: false, backdrop: false});
        this.showRentalDetail       =   new Modal(document.getElementById("showRentalDetailModal"), {keyboard: false, backdrop: false});
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {

        const report_name   =   'asset_rental';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        
        let all_periods             =       [...props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        this.setState({
            reportData              :   reportData.length > 0 ? reportData[0] : null,
            all_periods             :   all_periods ,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        }, () => this.filterFormDataInit());
    }
    
    filterFormDataInit                      =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, null, null, (response) => {
        let respData = response.data.data;  
            this.setState({
                formDataLoaded           :        true,
                allDueDateStatus         :        respData.date_status && respData.date_status.length > 0 ? respData.date_status.map(u => {   return { value: u.key, label: <Status color={u.status_color}>{u.status_text}</Status>,display_label : u.status_text} }) : [], 
                iam_asset_category       :        respData.asset_category && respData.asset_category.length > 0 ? respData.asset_category.map(ac => { return {value: ac.key, label: ac.name}}) : [],
                allStatusList            :        respData.status && respData.status.length > 0 ? respData.status.map(u => {   return { value: u.id, label: <Status color={u.color}>{u.name}</Status>,display_label : u.name} }) : [],
                allClientList            :        respData.clients && respData.clients.length > 0 ? respData.clients.map(u => {   return { value: u.id, label: u.name} }) : [],
            },() => {
                let all_status             =       [...this.state.allStatusList , {value : 'no_status' , label : "Asset Without Rental Status",display_label : "Asset Without Rental Status" }] ;
                let all_duedates_status    =       [...this.state.allDueDateStatus , {value : 'N' , label : "Asset With No Due Dates",display_label : "Asset With No Due Dates" }] ;
                this.setState({allStatusList : all_status,allDueDateStatus : all_duedates_status},() => {
                    let ParamObject                 =   new URLSearchParams(this.props.location.search);
                    
                    if(ParamObject.get('asset_category')  || ParamObject.get('search_status_ids')) {
                        let selectedStatus = this.state.allStatusList.filter(s => ParamObject.get('search_status_ids').includes(s.value) ).map(s => s.value)
                        let updateFilterForm        =   {search_asset_category : ParamObject.get('asset_category'),search_status_ids : selectedStatus};

                        if(ParamObject.get('search_site_ids')){
                            updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_ids')]
                        }
                        if(ParamObject.get('search_asset_type_ids')){
                            updateFilterForm['search_asset_type_ids']        =   [ParamObject.get('search_asset_type_ids')]
                        }

                        this.setState({reportFilterForm :   {...this.state.reportFilterForm, ...updateFilterForm}});
                    }
                    if(ParamObject.get('tentative_release_date_status')) {
                        let selectedDueDate = this.state.allDueDateStatus.filter(s => ParamObject.get('tentative_release_date_status').includes(s.value) ).map(s => s.value)
                        let updateFilterForm        =   {tentative_release_date_status : selectedDueDate};

                        if(ParamObject.get('search_site_ids')){
                            updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_ids')]
                        }
                        if(ParamObject.get('search_asset_type_ids')){
                            updateFilterForm['search_asset_type_ids']        =   [ParamObject.get('search_asset_type_ids')]
                        }

                        this.setState({reportFilterForm :   {...this.state.reportFilterForm, ...updateFilterForm}});
                    }
                    if(ParamObject.get('wo_due_date_status')) {
                        let selectedDueDate = this.state.allDueDateStatus.filter(s => ParamObject.get('wo_due_date_status').includes(s.value) ).map(s => s.value)
                        let updateFilterForm        =   {wo_due_date_status : selectedDueDate};

                        if(ParamObject.get('search_site_ids')){
                            updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_ids')]
                        }
                        if(ParamObject.get('search_asset_type_ids')){
                            updateFilterForm['search_asset_type_ids']        =   [ParamObject.get('search_asset_type_ids')]
                        }

                        this.setState({reportFilterForm :   {...this.state.reportFilterForm, ...updateFilterForm}});
                    }
                })
            });
            this.reportFilterFormSubmit();
        }, (err) => { });
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        let serachFormKeys                      =   Object.keys(this.state.reportFilterForm);
        
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.length > 0) {
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusList.filter((s) => value.includes(s.value)).map(s => s.display_label).join(', ');
                    }
                    if(key == 'wo_due_date_status') {
                        show_val            =   this.state.allDueDateStatus.filter((s) => value.includes(s.value)).map(s => s.display_label).join(', ');
                    }
                    if(key == 'tentative_release_date_status') {
                        show_val            =   this.state.allDueDateStatus.filter((s) => value.includes(s.value)).map(s => s.display_label).join(', ');
                    }
                    if(key == 'search_client_ids') {
                        show_val            =   this.state.allClientList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_category') {
                        show_val            = this.state.iam_asset_category.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'deployment_date_range') {
                        let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                        let  start_range         =      moment(this.state.reportFilterForm.deployment_date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.deployment_date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date && !ParamObject.get('date_range_start') ? s.display_with_date : display_custom)
                    }
                    if(key == 'dispatch_date_range') {
                        let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                        let  start_range         =      moment(this.state.reportFilterForm.dispatch_date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.dispatch_date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date && !ParamObject.get('date_range_start') ? s.display_with_date : display_custom)
                    }
                    if(key == 'tentative_release_date_range') {
                        let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                        let  start_range         =      moment(this.state.reportFilterForm.tentative_release_date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.tentative_release_date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date && !ParamObject.get('date_range_start') ? s.display_with_date : display_custom)
                    }
                    if(key == 'wo_due_date_range') {
                        let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                        let  start_range         =      moment(this.state.reportFilterForm.wo_due_date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.wo_due_date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date && !ParamObject.get('date_range_start') ? s.display_with_date : display_custom)
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page =1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listingMeta             :   null,
            listing_tbl_page        :   page,
            reportDownloadBtn       :   false,
            reportDownloading       :   false
        });
        let params                          =   {page:page,...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   respData.meta
            });
        }, (err) => {
            
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    checkAllList() {
        let assetCheckboxCollections      =   document.getElementsByClassName('asset_id_checkbox')
        if(assetCheckboxCollections && assetCheckboxCollections.length > 0) {
            for (let i = 0; i < assetCheckboxCollections.length; i++) {
                assetCheckboxCollections[i].checked = document.getElementById('all_check_asset').checked;
            }
        }
    }
    
    updateRentalHandler                  =   ()  =>  {
        let asset_ids                     =   [];
      
        let assetCheckboxCollections      =   document.getElementsByClassName('asset_id_checkbox');
        if(assetCheckboxCollections && assetCheckboxCollections.length > 0) {
            for (let i = 0; i < assetCheckboxCollections.length; i++) {
                if(assetCheckboxCollections[i].checked) {
                    asset_ids.push(assetCheckboxCollections[i].value);
                }
            }
        }
       
        if (asset_ids.length == 0) {
            toast.error('Please select atleast one Asset', { position: toast.POSITION.TOP_RIGHT });
        } else {
           this.updateRentalModal.show()
           this.setState({selected_asset_ids : asset_ids})
           this.setState({...this.initState})
           this.getAddRentalFormData()
           
            if (!this.state.updateFormDataLoaded) { 
                this.fetchStateListfromApi()
            }
        }
    }

    getAddRentalFormData       =      ()   =>     {
        this.setState({updateFormDataLoading : true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/rental/add_form_data', this.props.access_token , {}, {}, (resp) => {
            let respData                =   resp.data.data;
            this.setState({
                updateFormDataLoaded     :        true,
                formData                 :        respData ? respData : null,
                additional_attributes    :       respData && respData.additional_attributes && respData.additional_attributes.length > 0  ? respData.additional_attributes.map(v => ({...v, isChecked: false})) : [],
                allFormStatusList        :        respData.status && respData.status.length > 0 ? respData.status.map(u => {   return { value: u.id, label: <Status color={u.color}>{u.name}</Status>} }) : [],
                allFormClientList        :        respData.clients && respData.clients.length > 0 ? respData.clients.map(u => {   return { value: u.id, label: u.name} }) : [],
           })
              
        }).then(() => this.setState({updateFormDataLoading :  false}));
    }

    fetchStateListfromApi       =      ()   =>     {
        HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token , {}, {}, (resp) => {
            let respData                =   resp.data;
            this.setState({
                allStateList             :       respData ? respData.map((s) => { return {value: s.id, label: s.name}; }) : [], 
           })
        })
    }

     //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
     stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
           
                params = {state_ids : selected_state.value ? [selected_state.value] : [selected_state]}
           
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList  :  response.data.map((s) => { return {value: s.id, label: s.name}; })
                });
            });
        }
    }

    onAdditionalAttributeUpdateChecked = (i) => (event) => {
        this.setState((state, props) => {
          state.additional_attributes[i].isChecked = !state.additional_attributes[i].isChecked;
          return {
            additional_attributes: state.additional_attributes
          }
        })
    }

    submitAssetRentalForm           =       (e)     =>      {
        e.preventDefault()
        let rentalForm = {}

            if(this.state.update_status == "Y"){
                rentalForm['status'] = this.state.addRentalForm.status;
               rentalForm['status_change_date'] = this.state.addRentalForm.status_change_date;
            }
            if(this.state.update_client == "Y"){
                rentalForm['client_id'] = this.state.addRentalForm.client_id;
               rentalForm['client_name'] = this.state.addRentalForm.client_name;
            }
            if(this.state.update_rental_amount == "Y"){
                rentalForm['rental_amount'] = this.state.addRentalForm.rental_amount;
            }
            if(this.state.update_potential_rent_amount == "Y"){
                rentalForm['potential_rent_amount'] = this.state.addRentalForm.potential_rent_amount;
            }
            if(this.state.update_remark == "Y"){
                rentalForm['remark'] = this.state.addRentalForm.remark;
            }
            if(this.state.update_wo_detail == "Y"){
                rentalForm['wo_detail'] = this.state.addRentalForm.wo_detail;
            }
            if(this.state.update_wo_date == "Y"){
                rentalForm['wo_date'] = this.state.addRentalForm.wo_date;
            }
            if(this.state.update_wo_due_date == "Y"){
                rentalForm['wo_due_date'] = this.state.addRentalForm.wo_due_date;
            }
            if(this.state.update_date_dispatch == "Y"){
                rentalForm['date_dispatch'] = this.state.addRentalForm.date_dispatch;
            }
            if(this.state.update_date_receipt == "Y"){
                rentalForm['date_receipt'] = this.state.addRentalForm.date_receipt;
            }
            if(this.state.update_date_deployment == "Y"){
                rentalForm['date_deployment'] = this.state.addRentalForm.date_deployment;
            }
            if(this.state.update_date_release == "Y"){
                rentalForm['date_release'] = this.state.addRentalForm.date_release;
            }
            if(this.state.update_date_release_tentative == "Y"){
                rentalForm['date_release_tentative'] = this.state.addRentalForm.date_release_tentative;
            }
            if(this.state.update_location == "Y"){
                rentalForm['state_id'] = this.state.addRentalForm.state_id;
               rentalForm['district_id'] = this.state.addRentalForm.district_id;
            }
           
       

        let additional_attributes = {};
            if(this.state.additional_attributes && this.state.additional_attributes.length > 0){
                this.state.additional_attributes.filter(at => at.isChecked == true).forEach((i, k) => {
                    let name = 'additional_attributes_' + i.key;
                    additional_attributes[i.key] = this.state.addRentalForm[name] ? this.state.addRentalForm[name] : '';
                });
            }
       
        let frmData = {asset_ids : this.state.selected_asset_ids,data: {...rentalForm,additional_attributes : additional_attributes}}

        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/rental_assets_detail' , this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateRentalModal.hide();
            this.setState({...this.initState})
            this.loadListingTblData(1)
            let form = document.getElementById('updateRentalModalForm')
            form.reset();
        }).then(() => this.setState({ saveFormSubmitting : false }));
    }

    showAssetRentalDetail           =      (id,assetName,siteName)     =>      {
        this.setState({selectedAssetId : id,selectedAssetName : assetName,selectedSiteName : siteName})
        this.showRentalDetail.show()
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :           [],
            reportFilterForm                :           {...this.reportFilterFormInitialState},
            submittedReportFilterForm       :           {...this.reportFilterFormInitialState},
        }, () => {
            this.loadListingTblData(1);
        });
    }

    showRentalDetailJsx              =   ()  =>  {

        return (
            <div className="modal fade" id={"showRentalDetailModal"} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0"> Rental Detail  </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "27%" }}> Asset Name   </td>
                                        <th>{this.state.selectedAssetName ? `${this.state.selectedAssetName}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Site Name </td><th>{this.state.selectedSiteName ? `${this.state.selectedSiteName} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    
                                <div className="modal-body">
                                <AssetRentalView showEdit={"N"} assetId={this.state.selectedAssetId} />
                                   </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        
                    </div>
                </div>
            </div>
        );
    }

    updateRentalModalJsx              =   ()  =>  {

        return (
            <div className="modal fade" id={"updateRentalDetailModal"} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0"> Update Rental Detail  </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitAssetRentalForm} id="updateRentalModalForm">
                            {this.state.updateFormDataLoading
                                ? <Loader />
                                : <div className="modal-body">
                                    <div className="tab_content_wrapper "> <span className="content_heading">Status</span>  </div>
                                    <div className="row mb-2 mt-1 align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_status"
                                                type="checkbox"
                                                value={this.state.update_status}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_status: this.state.update_status == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_status == 'Y' ? { ...this.state.addRentalForm, status: '', status_change_date: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_status == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm require">Commercial Status</label>
                                        <div className="col-sm-6">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Select Status"
                                                options={this.state.allFormStatusList}
                                                value={this.state.allFormStatusList.find(su => this.state.addRentalForm.status == su.value)}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRentalForm', 'status')}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                isDisabled={this.state.update_status == "N" ? true : false}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_status"
                                                type="checkbox"
                                                value={this.state.update_status}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_status: this.state.update_status == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_status == 'Y' ? { ...this.state.addRentalForm, status: '', status_change_date: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_status == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm require">Date of Status Change</label>
                                        <div className="col-sm-6 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.status_change_date
                                                        ? moment(this.state.addRentalForm.status_change_date).toDate()
                                                        : false
                                                }
                                                name="status_change_date"
                                                onChange={(value, event) => this.formDateHandler('status_change_date', value, 'addRentalForm', 'YYYY-MM-DD hh:mm:ss')}
                                                dateFormat="dd-MMM-yyyy hh:mm a"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Date of Status Change`}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                showTimeSelect
                                                timeFormat="hh:mm aa"
                                                timeIntervals={5}
                                                timeCaption="Time"
                                                disabled={this.state.update_status == "N" ? true : false}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{ top: "8px" }}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_remark"
                                                type="checkbox"
                                                value={this.state.update_remark}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_remark: this.state.update_remark == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_status == 'Y' ? { ...this.state.addRentalForm, remark: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_remark == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Remarks</label>
                                        <div className="col-sm-6">
                                            <textarea
                                                name="remark"
                                                className='form-control form-control-sm'
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                value={this.state.addRentalForm.remark}
                                                disabled={this.state.update_remark == "N" ? true : false}
                                                placeholder='Enter Remarks' />
                                        </div>

                                    </div>
                                    <div className="tab_content_wrapper "> <span className="content_heading">Client</span>  </div>
                                    <div className="row mb-2 mt-1 align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_client"
                                                type="checkbox"
                                                value={this.state.update_client}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_client: this.state.update_client == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_status == 'Y' ? { ...this.state.addRentalForm, client_id: '', client_name: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_client == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Client Name</label>
                                        <div className="col-sm-6">
                                            {this.state.addNewClient == "N"
                                                ? <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder="Select Client"
                                                    options={this.state.allClientList}
                                                    value={this.state.allClientList.filter(su => this.state.addRentalForm.client_id == su.value)}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRentalForm', 'client_id')}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    isDisabled={this.state.update_client == "N" ? true : false}
                                                />
                                                :
                                                <input
                                                    name="client_name"
                                                    type="text"
                                                    value={this.state.addRentalForm.client_name}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                    disabled={this.state.update_client == "N" ? true : false}
                                                    placeholder="Please enter Client Name" />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="addNewClient"
                                                type="checkbox"
                                                value={this.state.addNewClient}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewClient: this.state.addNewClient == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.addNewClient == 'N' ? { ...this.state.addRentalForm, client_id: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.addNewClient == 'Y'}
                                                className="form-check-input"
                                                id="addNewClient"
                                                disabled={this.state.update_client == "N" ? true : false}
                                            />
                                            <label className="form-check-label text-sm mx-2" htmlFor="addNewClient">Add New </label>
                                        </div>

                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_location"
                                                type="checkbox"
                                                value={this.state.update_location}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_location: this.state.update_location == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_location == 'Y' ? { ...this.state.addRentalForm, state_id: '', district_id: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_location == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Location</label>
                                        <div className="col-sm-3">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addRentalForm', 'state_id');
                                                    this.stateChangeHandler(selectedOption)
                                                }}
                                                options={this.state.allStateList}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allStateList.find(m => m.value == this.state.addRentalForm.state_id)}
                                                placeholder="Please Select State"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                isDisabled={this.state.update_location == "N" ? true : false}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addRentalForm', 'district_id') }}
                                                options={this.state.allDistrictList}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allDistrictList.find(m => m.value == this.state.addRentalForm.district_id)}
                                                placeholder="Select District"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                isDisabled={this.state.update_location == "N" ? true : false}
                                            />
                                        </div>

                                    </div>
                                    <div className="tab_content_wrapper "> <span className="content_heading">Rental Amount</span>  </div>
                                    <div className="row mb-2 mt-1 align-items-center ">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_rental_amount"
                                                type="checkbox"
                                                value={this.state.update_rental_amount}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_rental_amount: this.state.update_rental_amount == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_rental_amount == 'Y' ? { ...this.state.addRentalForm, rental_amount: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_rental_amount == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Rental Amount</label>
                                        <div className="col-sm-6">
                                            <input
                                                name="rental_amount"
                                                type="text"
                                                value={this.state.addRentalForm.rental_amount}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                disabled={this.state.update_rental_amount == "N" ? true : false}
                                                placeholder="Enter Rental Amount" />
                                        </div>

                                    </div>
                                    <div className="row my-1  align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_potential_rent_amount"
                                                type="checkbox"
                                                value={this.state.update_potential_rent_amount}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_potential_rent_amount: this.state.update_potential_rent_amount == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_potential_rent_amount == 'Y' ? { ...this.state.addRentalForm, potential_rent_amount: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_potential_rent_amount == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Potential Rent Amount</label>
                                        <div className="col-sm-6">
                                            <input
                                                name="potential_rent_amount"
                                                type="text"
                                                value={this.state.addRentalForm.potential_rent_amount}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                disabled={this.state.update_potential_rent_amount == "N" ? true : false}
                                                placeholder="Enter Potential Rent Amount" />

                                        </div>

                                    </div>

                                    <div className="tab_content_wrapper "> <span className="content_heading">Work Order</span>  </div>
                                    <div className="row mb-2 mt-1 align-items-center ">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_wo_detail"
                                                type="checkbox"
                                                value={this.state.update_wo_detail}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_wo_detail: this.state.update_wo_detail == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_wo_detail == 'Y' ? { ...this.state.addRentalForm, wo_detail: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_wo_detail == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Work Order Number</label>
                                        <div className="col-sm-6">
                                            <input
                                                name="wo_detail"
                                                type="text"
                                                value={this.state.addRentalForm.wo_detail}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addRentalForm")}
                                                disabled={this.state.update_wo_detail == "N" ? true : false}
                                                placeholder="Enter Work Order Detail" />
                                        </div>

                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_wo_date"
                                                type="checkbox"
                                                value={this.state.update_wo_date}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_wo_date: this.state.update_wo_date == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_wo_date == 'Y' ? { ...this.state.addRentalForm, wo_date: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_wo_date == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">WO Date</label>
                                        <div className="col-sm-6 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.wo_date
                                                        ? moment(this.state.addRentalForm.wo_date).toDate()
                                                        : false
                                                }
                                                name="wo_date"
                                                onChange={(value, event) => this.formDateHandler('wo_date', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter WO Date`}
                                                disabled={this.state.update_wo_date == "N" ? true : false}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{ top: "8px" }}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_wo_due_date"
                                                type="checkbox"
                                                value={this.state.update_wo_due_date}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_wo_due_date: this.state.update_wo_due_date == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_wo_due_date == 'Y' ? { ...this.state.addRentalForm, wo_due_date: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_wo_due_date == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">WO Expiry Date</label>
                                        <div className="col-sm-6 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.wo_due_date
                                                        ? moment(this.state.addRentalForm.wo_due_date).toDate()
                                                        : false
                                                }
                                                name="wo_due_date"
                                                onChange={(value, event) => this.formDateHandler('wo_due_date', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter WO Due Date`}
                                                disabled={this.state.update_wo_due_date == "N" ? true : false}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{ top: "8px" }}
                                            />
                                        </div>

                                    </div>
                                    <div className="tab_content_wrapper "> <span className="content_heading">Deployment</span>  </div>
                                    <div className="row mb-2 mt-1 align-items-center">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_date_dispatch"
                                                type="checkbox"
                                                value={this.state.update_date_dispatch}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_date_dispatch: this.state.update_date_dispatch == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_date_dispatch == 'Y' ? { ...this.state.addRentalForm, date_dispatch: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_date_dispatch == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Date of Disptach</label>
                                        <div className="col-sm-6 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_dispatch
                                                        ? moment(this.state.addRentalForm.date_dispatch).toDate()
                                                        : false
                                                }
                                                name="date_dispatch"
                                                onChange={(value, event) => this.formDateHandler('date_dispatch', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Date of Dispatch`}
                                                disabled={this.state.update_date_dispatch == "N" ? true : false}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{ top: "8px" }}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2 align-items-center  ">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_date_receipt"
                                                type="checkbox"
                                                value={this.state.update_date_receipt}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_date_receipt: this.state.update_date_receipt == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_date_receipt == 'Y' ? { ...this.state.addRentalForm, date_receipt: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_date_receipt == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Date of Receipt at Site</label>
                                        <div className="col-sm-6 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_receipt
                                                        ? moment(this.state.addRentalForm.date_receipt).toDate()
                                                        : false
                                                }
                                                name="date_receipt"
                                                onChange={(value, event) => this.formDateHandler('date_receipt', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Date of Receipt at Site`}
                                                disabled={this.state.update_date_receipt == "N" ? true : false}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{ top: "8px" }}
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-2 align-items-center  ">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_date_deployment"
                                                type="checkbox"
                                                value={this.state.update_date_deployment}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_date_deployment: this.state.update_date_deployment == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_date_deployment == 'Y' ? { ...this.state.addRentalForm, date_deployment: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_date_deployment == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Date of Deployment</label>
                                        <div className="col-sm-6 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_deployment
                                                        ? moment(this.state.addRentalForm.date_deployment).toDate()
                                                        : false
                                                }
                                                name="date_deployment"
                                                onChange={(value, event) => this.formDateHandler('date_deployment', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Date of Deployment`}
                                                disabled={this.state.update_date_deployment == "N" ? true : false}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{ top: "8px" }}
                                            />
                                        </div>

                                    </div>
                                    <div className="row align-items-center mb-2  ">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_date_release_tentative"
                                                type="checkbox"
                                                value={this.state.update_date_release_tentative}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_date_release_tentative: this.state.update_date_release_tentative == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_date_release_tentative == 'Y' ? { ...this.state.addRentalForm, date_release_tentative: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_date_release_tentative == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Tentative Release Date</label>
                                        <div className="col-sm-6 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_release_tentative
                                                        ? moment(this.state.addRentalForm.date_release_tentative).toDate()
                                                        : false
                                                }
                                                name="date_release_tentative"
                                                onChange={(value, event) => this.formDateHandler('date_release_tentative', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Tentative Release Date `}
                                                disabled={this.state.update_date_release_tentative == "N" ? true : false}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{ top: "8px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 align-items-center ">
                                        <div className='col-sm-1'>
                                            <input
                                                name="update_date_release"
                                                type="checkbox"
                                                value={this.state.update_date_release}
                                                onChange={(e) => {
                                                    this.setState({
                                                        update_date_release: this.state.update_date_release == 'N' ? 'Y' : 'N',
                                                        addRentalForm: this.state.update_date_release == 'Y' ? { ...this.state.addRentalForm, date_release: '' } : { ...this.state.addRentalForm }
                                                    })
                                                }}
                                                checked={this.state.update_date_release == 'Y'}
                                                className="form-check-input"
                                            />
                                        </div>
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Release Date</label>
                                        <div className="col-sm-6 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addRentalForm.date_release
                                                        ? moment(this.state.addRentalForm.date_release).toDate()
                                                        : false
                                                }
                                                name="date_release"
                                                onChange={(value, event) => this.formDateHandler('date_release', value, 'addRentalForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Enter Release Date`}
                                                disabled={this.state.update_date_release == "N" ? true : false}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                                style={{ top: "8px" }}
                                            />
                                        </div>
                                    </div>

                                    {
                                        this.state.additional_attributes && this.state.additional_attributes.length > 0
                                            ? (

                                                <Ax>
                                                    <div className="tab_content_wrapper"><span className="content_heading">Additional Attributes</span></div>
                                                    {
                                                        this.state.additional_attributes.map((i, k) => {
                                                            let name = 'additional_attributes_' + i.key;
                                                            let input = null;

                                                            if (i.type == 'text') {
                                                                input = <input
                                                                    disabled={i.isChecked == false ? true : false}
                                                                    name={name}
                                                                    type="text"
                                                                    value={this.state.addRentalForm[name] ? this.state.addRentalForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addRentalForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'numeric') {
                                                                input = <input
                                                                    name={name}
                                                                    type="number"
                                                                    value={this.state.addRentalForm[name] ? this.state.addRentalForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addRentalForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                    disabled={i.isChecked == false ? true : false}
                                                                />
                                                            } else if (i.type == 'date') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.addRentalForm[name]
                                                                            ? moment(this.state.addRentalForm[name]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.formDateHandler(name, value, 'addRentalForm')}
                                                                    dateFormat="dd-MMM-yyyy"
                                                                    className={"form-control form-control-sm"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${i.name}`}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                    disabled={i.isChecked == false ? true : false}
                                                                />
                                                            } else if (i.type == 'dropdown') {
                                                                let options = i.options.map((t) => { return { value: t, label: t } });
                                                                input = <TapSelect
                                                                    menuPlacement="top"
                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRentalForm', name)}
                                                                    options={options}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    value={options.find(o => o.value == this.state.addRentalForm[name])}
                                                                    placeholder={`Please Select ${i.name}`}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                    isDisabled={i.isChecked == false ? true : false}
                                                                />
                                                            }

                                                            return (<div key={k} className="row align-items-center mb-3 mt-3">

                                                                <div className='col-sm-1'>

                                                                    <input type="checkbox"
                                                                        name={i.isChecked}
                                                                        value={i.isChecked == true ? false : true}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked == true) {

                                                                                let allRows = this.state.additional_attributes;
                                                                                allRows[k] = { ...this.state.additional_attributes[k], isChecked: true };
                                                                                this.setState({ allRows });

                                                                            } else {
                                                                                let allRows = this.state.additional_attributes;
                                                                                allRows[k] = { ...this.state.additional_attributes[k], isChecked: false };
                                                                                this.setState({ allRows, addRentalForm: { ...this.state.addRentalForm, [name]: '' } });
                                                                            }
                                                                        }}
                                                                        checked={i.isChecked == true} />


                                                                </div>
                                                                <div className="col-sm-3 col-form-label col-form-label-sm">{i.name}</div>
                                                                <div className="col-sm-6">{input}</div>

                                                            </div>);
                                                        })
                                                    }
                                                </Ax>
                                            )
                                            : null
                                    }
                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.saveFormSubmitting} onClick={() => { this.setState({ ...this.initState }) }}>Clear</button>
                                <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting}>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCarAlt} /> Asset Rental</h3>
                <div className="text-end mt15">
                    <button type="button"  className="btn btn-primary"  disabled={this.props.permissions.includes('iam-asset-rental-update') && this.state.formDataLoaded ? false : true} onClick={this.updateRentalHandler}>Update Rental Detail</button>
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-secondary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>) : null}
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div id="reportFilterForm" className="bg-white collapse" >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">
                        Asset
                    </label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({
                                    searchedAssets: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Category</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_category}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_category')}
                            value={this.state.iam_asset_category.find(r => r.value ===  this.state.reportFilterForm.search_asset_category  )}
                            placeholder="ALL"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Commercial Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusList}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allStatusList.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="ALL"
                        />
                    </div>

                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Client Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allClientList}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_client_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allClientList.filter(s => this.state.reportFilterForm.search_client_ids.includes(s.value))}
                            placeholder="Select Client"
                        />
                    </div>

                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">WO Expiry Date Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allDueDateStatus}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'wo_due_date_status')}
                            value={this.state.allDueDateStatus.filter(s => this.state.reportFilterForm.wo_due_date_status.includes(s.value))}
                            isMulti={true}
                            placeholder="ALL"
                            isClearable={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Tentative Release Date Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allDueDateStatus}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tentative_release_date_status')}
                            value={this.state.allDueDateStatus.filter(s => this.state.reportFilterForm.tentative_release_date_status.includes(s.value))}
                            isMulti={true}
                            placeholder="ALL"
                            isClearable={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Date of Deployment Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.deployment_date_range}
                            startDate={this.state.reportFilterForm.deployment_date_range_start}
                            endDate={this.state.reportFilterForm.deployment_date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        deployment_date_range: period,
                                        deployment_date_range_start: startDate ? startDate : null,
                                        deployment_date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Date of Dispatch Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.dispatch_date_range}
                            startDate={this.state.reportFilterForm.dispatch_date_range_start}
                            endDate={this.state.reportFilterForm.dispatch_date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        dispatch_date_range: period,
                                        dispatch_date_range_start: startDate ? startDate : null,
                                        dispatch_date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Tentative Release Date Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.tentative_release_date_range}
                            startDate={this.state.reportFilterForm.tentative_release_date_range_start}
                            endDate={this.state.reportFilterForm.tentative_release_date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        tentative_release_date_range: period,
                                        tentative_release_date_range_start: startDate ? startDate : null,
                                        tentative_release_date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">WO Expiry Date Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.wo_due_date_range}
                            startDate={this.state.reportFilterForm.wo_due_date_range_start}
                            endDate={this.state.reportFilterForm.wo_due_date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        wo_due_date_range: period,
                                        wo_due_date_range_start: startDate ? startDate : null,
                                        wo_due_date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ?   <Loader />
                :   (this.state.listingMeta ? 
                    this.reportTableJsx()
                    
                     : null)
            } 
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-fixed table-sm align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th style={{ width: "5%" }} className='text-center'><input type='checkbox' id="all_check_asset" onChange={this.checkAllList} /></th>
                        <th style={{ width: "14%" }}>Asset</th>
                        <th style={{ width: "8%" }}>Asset Code</th>
                        <th style={{ width: "10%" }}>Asset Type</th>
                        <th style={{ width: "10%" }}>Site</th>
                        <th style={{ width: "8%" }}>Commercial Status</th>
                        <th style={{ width: "9%" }}>Status Since</th>
                        <th style={{ width: "10%" }}>Client Name</th>
                        <th style={{ width: "8%" }} >Rental Amount</th>
                        <th style={{ width: "9%" }}>Tentative Release Date</th>
                        <th style={{ width: "10%" }}>WO Expiry Date</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData.length > 0
                        ? (<Ax> {this.state.listingData.map((rowData, k) => (<tr key={k}>
                            <td className='text-center'>
                                <input type='checkbox'
                                    className="asset_id_checkbox"
                                    name="asset_checkbox"
                                    value={rowData.asset_id}
                                />
                            </td>
                            <td onClick={() => this.showAssetRentalDetail(rowData.asset_id,rowData.name,rowData.site)} className="link-primary cursor_pointer">{rowData.name}</td>
                            <td>{rowData.asset_code}</td>
                            <td>{rowData.asset_type_name}</td>
                            <td>{rowData.site}</td>
                            <td>{rowData.rental_detail && rowData.rental_detail.status_data ? <Status color={rowData.rental_detail.status_data.color}>{rowData.rental_detail.status_data.name}</Status> : "-"}</td>
                            <td>{rowData.rental_detail ?  rowData.rental_detail.status_change_date_display : "-"}</td>
                            <td>{rowData.rental_detail && rowData.rental_detail.client ? rowData.rental_detail.client.name : "-"}</td>
                            <td className='text-end'>{rowData.rental_detail && rowData.rental_detail.rental_amount ? rowData.rental_detail.rental_amount : "-"}</td>
                            <td>{rowData.rental_detail && rowData.rental_detail.date_release_tentative_display ? rowData.rental_detail.date_release_tentative_display : "-"}</td>
                            <td>{rowData.rental_detail && rowData.rental_detail.wo_due_date_display ? rowData.rental_detail.wo_due_date_display : "-"}</td>

                        </tr>))
                        }</Ax>)
                        : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }
    

    render                                  =   ()  =>  {
      
        return (<ApplicationLayout> 
         <Helmet><title>Asset Rental - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ?   <Ax>
                                    {this.reportFilterFormJsx()}
                                    <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.reportSearchClear}  />
                                    {this.reportListingDisplayJsx()}
                                
                                </Ax>
                            :   <Loader  />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null 
                        }
                    
                    </div>
                </div>
            </div>
            {this.updateRentalModalJsx()}
            {this.showRentalDetailJsx()}
        </ApplicationLayout>);
    }

   
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        permissions             :   state.app.acl_info.permissions,
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(AssetRental);