import React, { forwardRef, useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import TapIcon from '../../services/TapIcon';
import Loader from '../../components/ui/Loader/Loader';
import { DisplayListPagination } from '../../components/TapUi';
import Status from '../../components/ui/Status';
import { useSelector } from 'react-redux';
import HttpAPICall from '../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { Collapse } from 'bootstrap';
import AssetProdConfigDetail from './AssetProdConfigDetail';
import { useParams, useHistory } from 'react-router-dom';
import swal from "sweetalert"
import { toast } from 'react-toastify';
import TapHelper from '../../services/TapHelper';


const AssetProductivityReport                  =   forwardRef((props, ref) => {

    const accessToken                       =   useSelector(state => state.auth.access_token);

    const [minimizeTable, setMinimizeTable] =   useState(false);

    const [configData, setConfigData]       =   useState({loading : false, data : [], meta : null});
    const [detailData, setDetailData]       =   useState({data : null, statusChange : false, loading: false});    
    const [tablePage, setTablePage]         =   useState(1);
    const [collapseOpenClose, setCollapseOpenClose] = useState(false);

    const assetProdConfigDetailRef          =   useRef(null);
    const [activeTab, setActiveTab]         = useState(null);

    const {id}              =   useParams();
    const navigate          =   useHistory();



    const configUrl         =   IAM_API_BASE_URL_2 + '/asset_productivity_config';

    useEffect(() => {        
        id && handleDetailData();
        configListingApi(tablePage);
    }, []);

    const configListingApi     =   (tablePage)  =>  {
        setTablePage(tablePage);
        setConfigData(pre => ({...pre, loading : true}));
        let params              =   {page : tablePage};
        HttpAPICall.withAthorization('GET', configUrl + '/list', accessToken, params, {}, (res) => {
            const respData      =   res.data;
            const meta          =   {
                from                :   respData.from,
                to                  :   respData.to,
                total               :   respData.total,
                last_page           :   respData.last_page,
                per_page            :   respData.per_page,
                current_page        :   respData.current_page
            };

            
            setConfigData(pre => ({
                ...pre, 
                data : respData.data, 
                meta,
            }));
        })
        .then(() => setConfigData(pre => ({...pre, loading : false})))
        .catch(() => setConfigData(pre => ({...pre, loading : false})));
    }
 

    const viewProductivityDetail = (item =null  ,type = null) => {
        // if (id) {
        //     setMinimizeTable(true)
        // }
        setDetailData(pre => ({...pre, loading: true}));
        setMinimizeTable(true);
        item && setDetailData(pre => ({...pre, data: item, statusChange : false}));
        setDetailData(pre => ({...pre, loading: false}));
    }

    const closeViewDetails = () => {
        setMinimizeTable(false);
        configListingApi(tablePage);
        collapseOpenClose === 'open' && assetListingHandler(true, 'close');
        props.history.push("/asset_productivity_configuration");

    }

    const handleDetailData    =   (item = null, type = null)  =>  {
        setDetailData(pre => ({...pre, loading: true}));
        setMinimizeTable(true);
        item && setDetailData(pre => ({...pre, data: item, statusChange : false}));
    }
    const afterDetailApi    =   ()  => {
        setDetailData(pre => ({...pre, loading: false}));
    }


    const assetconfigurationTableJsx = () => {
        return (<>
            <table style={{ backgroundColor: "white" }} className="table table-bordered table-fixed bg-white">
                <thead className="table-secondary" >
                    {minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Asset Configuration</th>
                        </tr>)
                        : (
                            <tr>
                                <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                                <th scope="col" style={{ width: "12%" }}>Config Name</th>
                                <th scope="col" style={{ width: "20%" }} className="text-center">Activity Name</th>
                                <th scope="col" style={{ width: "20%" }} className="text-center">Sites</th>
                                <th scope="col" style={{ width: "15%" }} className="text-center">UOM</th>
                                <th scope="col" style={{ width: "11%" }} className="text-center">Accounting Code</th>
                                <th scope="col" style={{ width: "10%" }} className="text-center">Status</th>
                                <th scope="col" style={{ width: "7%" }} className="text-center">Action</th>

                            </tr>)}
                </thead>
                <tbody>
                    {
                        configData.loading 
                        ?
                        <tr>
                            <td className="text-center" colSpan="8">
                                <Loader />
                            </td>
                        </tr>
                        :
                        configData.data.length > 0 
                        ? configData.data.map((item, index) => (
                            <tr key={item.id}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-capitalize link-primary cursor_pointer">
                                    <Link to={{ pathname: "/asset_productivity_configuration/" + item.id }} onClick={() =>  handleDetailData(item)}>
                                        {item.name}
                                    </Link>
                                </td>
                                <td className='text-center'>
                                    {item.activity_detail ? item.activity_detail.activity_name : "-"} 
                                </td>
                                <td>
                                    {
                                    item.sites.length > 0 
                                    ? 
                                    <>
                                        <span>{item.sites[0].site_name}</span>
                                        {
                                            item.sites.length > 1 && <span> (+{item.sites.length-1})</span>
                                        }
                                    </>
                                    : ''
                                    }
                                </td>
                                <td>{item.uom ? item.uom.measuring_unit : ""}</td>
                                <td>{item.account_code ? item.account_code.name : ""}</td>
                                <td className="text-center">{item.status ? <Status color={item.status.status_color_code}>{item.status.status_text ? item.status.status_text : ""}</Status> : ""}</td>
                                <td className="text-center">
                                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <Link className="dropdown-item" to={{ pathname: "/asset_productivity_configuration/" + item.id }} onClick={() =>  handleDetailData(item)}>
                                                View
                                            </Link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td className="text-center" colSpan={8}>
                                No Record Found
                            </td>
                        </tr>

                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={configData.meta}
                onPageChange={(e) => configListingApi(e.selected + 1)}
            />
        </>

        )
    }

    const addConfigurationInit  = () => {
        // if(assetConfigurationData) {
        //     props.history.push({ pathname: `/asset_productivity_configuration_add`, state: { configurationData: assetConfigurationData } })
        // }else {
        navigate.push('/asset_productivity_configuration_add');
            // props.history.push({ pathname: `/asset_productivity_configuration_add`});
        // }
    }
    const assetListingHandler = (collapse = true, type = 'open') => {
        setCollapseOpenClose(type);
        collapse && new Collapse(document.getElementById("configListingId"));
    };

    const changeStatusHandler   =   ()  =>  {
        const frmData   =   {id : detailData.data.id, status : detailData.data.status === 1 ? 2 : 1}
        swal({
            title: "Change Status",
            text: "Are you sure you want to proceed?",
            icon: "warning",
            buttons: ["No", "Yes"],
        }).then(willChangeStatus => {
            if (willChangeStatus) {
                HttpAPICall.withAthorization('PUT', configUrl + '/status', accessToken, {}, frmData, (response) => {
                    const resp  =    response.data;
                    toast.success(resp.msg, { position: toast.POSITION.TOP_RIGHT });
                    setDetailData(pre => ({...pre, statusChange : true}));
                    configListingApi(tablePage);
                }).then(() => {
                });
            }
        });
    }

    const editConfigurationInit =   ()  => {
        navigate.push({pathname : '/asset_productivity_configuration_add', state : detailData.data});
    }


    const productivitySearchModalInit   =   ()  =>  {
        if(assetProdConfigDetailRef.current) {
            assetProdConfigDetailRef.current.productivityListHandler('open_modal');
        }
    }

    const productivityDownloadInit   =   ()  =>  {
        if(assetProdConfigDetailRef.current) {
            assetProdConfigDetailRef.current.productivityListHandler('download');
        }
    }

    const minimizeTableTrJsx = (item, index = 0) => {
        return item ? (
            <tr key={index}>
                <td style={{ width: "25%" }}>
                    <div className="text-capitalize link-primary">
                        <Link to={{ pathname: "/asset_productivity_configuration/" + item.id, }}
                            onClick={() => {
                                collapseOpenClose === 'open' && assetListingHandler(true, 'close')
                                viewProductivityDetail(item, false)
                            }}>
                            {item.name ? TapHelper.textTruncate(item.name, 30) : "-"}
                        </Link>
                        <span className='float-end'>
                            <Link to={{ pathname: "/asset_productivity_configuration/" + item.id, }}
                            onClick={() => {
                                collapseOpenClose === 'open' && assetListingHandler(true, 'close')
                                viewProductivityDetail(item, false)
                            }}>
                            {item.activity_detail ? item.activity_detail.activity_name : "-"}
                            </Link>
                        </span>
                    </div>
                    <div className="text-capitalize pt-1">
                        <span>
                            {
                                item.sites.length > 0 
                                ? 
                                <>
                                    <span>{item.sites[0].site_name}</span>
                                    {
                                        item.sites.length > 1 && <span> (+{item.sites.length-1})</span>
                                    }
                                </>
                                : ''
                            }
                        </span>
                        <small className="float-end">{item.status ? <Status color={item.status.status_color_code}>{item.status.status_text ? item.status.status_text : ""}</Status> : ""}</small>
                    </div>
                </td>
            </tr>
        ) : (
            <tr key={index}>
                <td className="text-center" colSpan={7}>
                    No Record Found
                </td>
            </tr>
        );
    };


    const assetListingJsx = () => {
        return (
            <div id="configListingId" className="bg-white collapse">
                <div className="page_title row m0">
                    <div className="col-12 asset_collapse mr0 pr0">
                        <h3>Configuration</h3>
                        <div className="float-end">
                            <button
                                type="button"
                                onClick={() => assetListingHandler(true, 'close')}
                                className="btn btn-primary"
                                style={{
                                    height: "71%",
                                    lineHeight: "26px",
                                    maxHeight: "90%!important",
                                    marginTop: "10px",
                                }}
                            >
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faCaretLeft} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" bg-white position-relative z_index">
                    <div className="page_containt row" style={{ height: "81vh" }}>
                        <div className="pageTbl col-12">
                            <table className="table table-bordered table-hover table-sm table-fixed bg-white">
                                <thead className="table-secondary">
                                    <tr> <th scope="col" style={{ width: "15%" }}>Asset Configuration </th> </tr>
                                </thead>
                                <tbody>
                                {
                                    configData.loading 
                                    ?
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <Loader />
                                        </td>
                                    </tr>
                                    :
                                    configData.data.length > 0 
                                    ? configData.data.map((item, index) => minimizeTableTrJsx(item, index))
                                    : minimizeTableTrJsx(null)
                                }
                                </tbody>
                            </table>
                            <DisplayListPagination
                                meta={configData.meta}
                                onPageChange={(e) => configListingApi(e.selected + 1)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const assetconfigurationListjsx = () => {
        return (
        <>
            <div className="page_title row m0">
                <div className={minimizeTable ? "col-3" : "col-12"}>
                    {
                    !minimizeTable && 
                        <>
                            <div className='d-flex justify-content-between'>
                                <h3>Asset Configuration</h3>
                                <div className="text-end mt15">
                                    <button type="button" disabled={configData.loading} onClick={addConfigurationInit} className="btn btn-primary" >Add Configuration</button>
                                </div>
                            </div>
                        </>
                    }
                </div>
                {
                    minimizeTable
                        ? (
                            <>
                                <div className="col-12 tap_border_left asset_collapse">
                                    <button type="button" onClick={assetListingHandler} className="btn btn-primary asset_collapse_button" style={{ height: "73%" }}>
                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faCaretRight} />
                                    </button>
                                    <h3 className=' ps-5'>Asset Configuration : {detailData?.data?.name}</h3>
                                    <div className="text-end mt15">
                                        {activeTab == 'asset_productivity' && <>
                                            <button type="button" onClick={productivitySearchModalInit} className="btn btn-secondary" ><TapIcon.imageIcon icon={TapIcon.SearchIcon} /></button>
                                            <button type="button" className="btn btn-secondary" onClick={productivityDownloadInit} > <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} /></button>
                                        </>}
                                        <button type="button" id='actionMenuButton' className="btn btn-secondary px-3" data-bs-toggle="dropdown" aria-expanded="false" disabled={detailData.loading}>
                                            <TapIcon.imageIcon icon={TapIcon.ActionIcon} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><button className="dropdown-item" role="button" 
                                            onClick={() => {editConfigurationInit()}}
                                            >
                                                Edit</button></li>
                                            <li>
                                                <Link className="dropdown-item" onClick={changeStatusHandler}>Change Status</Link>
                                            </li>
                                        </ul>
                                        <button type="button" className="btn btn-secondary" onClick={closeViewDetails} disabled={detailData.loading}>
                                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
                        : null
                }

            </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', minimizeTable ? 'd-none' : 'col-12'].join(' ')} >{assetconfigurationTableJsx()}
                    </div>
                    {
                        minimizeTable 
                        ?
                        <div style={{ height: "100%", paddingRight: "6px" }}>
                            <AssetProdConfigDetail ref={assetProdConfigDetailRef} getActiveTab={(tab_key) => { setActiveTab(tab_key); }} data={detailData.data} afterDetailApi={afterDetailApi} handleDetailData={(item) => handleDetailData(item)}/>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </>);
    }

    return (
        <>
            <ApplicationLayout>
                <Helmet><title>Asset Productivity - Configuration</title></Helmet>
                <div className="asset_collapse">
                    {
                        minimizeTable &&  <div className="asset_table">{assetListingJsx()}</div>
                    }
                    {assetconfigurationListjsx()}
                </div>
            </ApplicationLayout>
        </>
    )

});

export default AssetProductivityReport;



