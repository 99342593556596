import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse, Modal } from 'bootstrap';
import { DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import { toast } from 'react-toastify';
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapIcon from '../../../services/TapIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import RunningStatusViewModal from '../../assets/action/serviceChecksheet/runningStatus/RunningStatusViewModal';

class ServiceChecksheetDueReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.cancelToken                    =   null;

        this.reportFilterFormInitialState   =   {
            search_item_tags                  :       [],
            search_item_ids                   :       [],
            'asset_group_ids'                 :       [],
            search_asset_type_ids             :       [],
            search_asset_ids                  :       [],
            site_group_ids                    :       [],
            search_site_ids                   :       [],
            search_name                       :       '',
            search_scs_running_status         :       []
        };
        
        this.reportFilterFormLabel          =   {
            search_item_tags                :       'Item Tags : ',
            search_item_ids                 :       'Items : ', 
            'asset_group_ids'               :       'Asset Type Groups : ',
            search_asset_type_ids           :       'Asset Type : ',
            search_asset_ids                :       'Assets : ',
            search_site_ids                 :       'Sites : ',
            site_group_ids                  :       'Site Group : ', 
            search_name                     :       'Service Checksheet Name : ',
            search_scs_running_status       :       'Status : '
        };
         
        this.executeFormInit                =   {
            odometer                        :   '',
            hourmeter                       :   '',
            hourmeter2                      :   '' , 
            hourmeter3                      :   '' , 
            hourmeter4                      :   '' ,
            date_time                       :   '',
            odometer2                       :   '',
            odometer3                       :   '',
            operation_cycle                 :   '',
            operation_cycle2                :   '',
          }
  
        this.state                          =   {
            formDataLoaded                  :   false,
            reportDownloadBtn               :   false,
            reportDownloading               :   false,
            reportFilterForm                :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm       :   {...this.reportFilterFormInitialState},
            listing_loading                 :   false,
            searchedItem                    :   [],
            listingData                     :   [],
            listingMeta                     :   null,
            allItemTags                     :   [],
            manufactures                    :   [],
            allStatusOptions                :   [],
            searchedAssets                  :   [],
            iam_asset_types                 :   [],
            iam_user_sites                  :   [],
            linked_items                    :   [],
            selectedAssetForExecution       :   [],
            execution_loading               :   false,
            asset_loading                   :   false,
            executeForm                     :   {...this.executeFormInit},
            executeFormSubmitting           :   false,
            totalListingCount               :   0,
            time                            :   '',
            allSiteGroupsList               :   [],
            allAssetTypesGroupsList         :   [],
            scsList                         :   [],
            reportData                      :   null,    
            assetDetail                     :   null,              
        };
        this.RunningStatusModalRef          =     React.createRef();  
        this.reportUrl                      =   IAM_API_BASE_URL_2 + '/report/service_checksheet_due';
    }
    
    componentDidMount                       =   (pr)  =>  {
        // this.loadListingTblData();
        this.initPageComponent(this.props);
         this.runningStatusSearchModal          =    new Modal(document.getElementById('runningStatusSearchModal'), {keyboard: false, backdrop :false});
        //  this.getAsssetDetails("9xrI")
    }
  
    componentWillReceiveProps               =   (nextProps)  =>  {
        if(this.props.iam_user_sites != nextProps.iam_user_sites) {
            this.initPageComponent(nextProps);
        }
    }

    initPageComponent                       =   (pr)  =>  {

        const report_name  =   'service_checksheet_due_report';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        //Initaolaize Data :-
        let Status                      =   [{value: 'over_due', label: 'OverDue'}, {value: 'due_soon', label: 'Due-Soon'}, {value: 'in_limit', label: 'Within Limits'}];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            access_token                :   pr.access_token,
            formDataLoaded              :   false,
            listing_loading             :   true,
            iam_asset_types             :   pr.iam_asset_types ? pr.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites              :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],          
            allStatusOptions            :   Status,
            allSiteGroupsList           :   pr.site_groups && pr.site_groups.length > 0 ? pr.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            allAssetTypesGroupsList     :   pr.asset_groups && pr.asset_groups.length > 0 ? pr.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        }, () => {
            
            let search_scs_running_status              =   ['over_due','due_soon','in_limit'];
            let ParamObject             =   new URLSearchParams(this.props.location.search);
            let search_site_ids         =   [];
            let search_asset_type_ids   =   [];
            let search_asset_ids        =   [];
            let assetName               =   [];
            if(ParamObject.get('scs_status')) {
                search_scs_running_status              =   ParamObject.get('scs_status');
            }
            if(ParamObject.get('search_site_ids')) {
                let site                =   this.state.iam_user_sites.find(us => us.value == ParamObject.get('search_site_ids'));
                if(site) {
                    search_site_ids     =   [site.value];
                }
            }
            if(ParamObject.get('search_asset_type_ids')) {
                let assetType           =   this.state.iam_asset_types.find(us => us.value == ParamObject.get('search_asset_type_ids'));
                if(assetType) {
                    search_asset_type_ids=   [assetType.value];
                }
            }
            if(ParamObject.get('search_asset_ids')) {
                let assetId           =    ParamObject.get('search_asset_ids');
                let asset_name           =    ParamObject.get('asset_name');
                if(assetId) {
                    search_asset_ids    =   [assetId];
                    assetName           =   [{label:asset_name,display_label:asset_name}]
                }
            }
            this.setState({
                formDataLoaded          :   true, 
                reportFilterForm        :   {
                    ...this.state.reportFilterForm,
                    search_scs_running_status              :   search_scs_running_status,
                    search_site_ids         :   search_site_ids,
                    search_asset_type_ids   :   search_asset_type_ids,
                    search_asset_ids        :   search_asset_ids
                },
                searchedAssets          :   assetName
            }, () => {
               
                this.reportFilterFormSubmit();
            });
        });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                  
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_scs_running_status') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_name') {
                        show_val            =  this.state.reportFilterForm.search_name   ;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
         
        });
    }
    
    loadListingTblData                      =   (page=1,reload=false)    =>  {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });

        let params                      =   {page:page,get_asset_profile : "Y",...this.state.submittedReportFilterForm,reload : reload ? 'reload' : false };
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (res) => {
            let respData                =   res.data;
            this.setState({
                listingData         :   respData.data.data,
                scsList             :   respData.scs_list,
                time                :   respData.time ? respData.time :'',
                reportDownloadBtn   :   true,
                listingMeta         :   {
                    from                :   respData.data.from,
                    to                  :   respData.data.to,
                    total               :   respData.data.total,
                    last_page           :   respData.data.last_page,
                    per_page            :   respData.data.per_page,
                    current_page        :   respData.data.current_page
                },
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    runningStatusModalInit                  =   (id,name,alias_name)      =>  {
        let scsRunningData          =   this.state.listingData.find(sc => sc.asset_id == id);
        let interval                =   [];
        if(scsRunningData && scsRunningData.scs_runnuing_status) {
            let intData     =   scsRunningData.scs_runnuing_status.find(d => d.scs_name === alias_name).interval;
            interval        =   intData.length > 0 ? intData : [];
        }
        
        if(scsRunningData){
            scsRunningData={...scsRunningData, alias_name, interval}
            const asset_profile     =   {
                name            :   scsRunningData.name, 
                asset_code      :   scsRunningData.asset_code, 
                asset_type_name :   scsRunningData.asset_type_name
            };
            this.RunningStatusModalRef.current.runningStatusModalInit({...scsRunningData, asset_profile}); 
        }
    } 

    linkAssetHandler                        =   (id) => {
        this.props.history.push('/asset/dashboard/' + id)
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, action : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   ()  =>  {
        new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport = () => {
        this.props.history.push('/report');
    }

    getAsssetDetails                        =   (id)  =>  {
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset/' + id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} />{this.state.reportData?.name}</h3>
                <div className='text-end mt15'>
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N' || !this.state.reportData} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button disabled={this.state.listing_loading || this.state.reportDownloading} type="button" onClick={() => this.loadListingTblData(1, true)} className="btn btn-secondary"><tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className={this.state.reportDownloading || this.state.listing_loading ? "fa-spin" : ''} /></button>
                    <button type="button" disabled={this.state.listing_loading || this.state.reportDownloading} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>

        </div>);
    }
    
    reportFilterFormJsx                     =   ()  => {
        return (<div id="reportFilterForm" className="bg-white collapse " aria-expanded="true">
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Service Checksheet Name/Alias Name</label>
                    <div className="col-sm-6">
                        <input
                            name="search_name"
                            type="text"
                            value={this.state.reportFilterForm.search_name}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Service Checksheet Name / Alias Name"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {
                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="bottom"
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="bottom"
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            menuPlacement="top"
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_scs_running_status')}
                            value={this.state.allStatusOptions.filter(u => this.state.reportFilterForm?.search_scs_running_status?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            menuPlacement="top"
                            placeholder="Select Status"
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4" onClick={this.reportFilterhandler}>Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        const listingData                   =   this.state.listingData;
        const scsList                       =   this.state.scsList;
        return (
        <div className="bg-white">
            <table className="table table-bordered bg-white align-items-center table-hover  mb-1" id="rowDataReportTable">
                <tbody>
                    <tr>
                        <td className="asset-cell">
                            Asset Name
                        </td>
                        {
                            listingData && listingData.length > 0
                                ?
                                listingData.map((data, i) => (
                                    <td>
                                        {data.name || "-"}
                                    </td>
                                ))
                                : ''
                        }
                    </tr>
                    <tr>
                        <td>Asset Code</td>
                        {
                            listingData && listingData.length > 0 
                            ?
                            listingData.map((data, i) => {
                                return (<td key={i}>{data.asset_code }</td>)
                            })
                            : ''
                        }
                    </tr>
                    <tr>
                        <td>Asset Type</td>
                        {
                            listingData && listingData.length > 0 
                            ?
                            listingData.map((data, i) => {
                                return (<td key={i}>{data.asset_type_name }</td>)
                            })
                            : ''
                        }
                    </tr>
                    <tr>
                        <td>Site Name</td>
                        {
                            listingData && listingData.length > 0 
                            ?
                            listingData.map((data, i) => {
                                return (<td key={i}>{data.site }</td>)
                            })
                            : ''
                        }
                    </tr>
                    <tr>
                        <th>Service Checksheet Name</th>
                        {
                            listingData && listingData.length > 0 
                            ?
                            listingData.map((data, i) => {
                                return (<td key={i}></td>)
                            })
                            : <td></td>
                        }
                    </tr>

                    {
                        listingData && listingData.length > 0 && scsList.length > 0 
                        ?
                        scsList.map((scs, i) => {
                            return (
                            <tr key={i}>
                                <td>{scs}</td>
                                {
                                    listingData.map((asset) => {
                                        let scsRunningData      =   asset.scs_runnuing_status.find((assetScs) => assetScs.scs_name === scs);
                                        if (scsRunningData) {
                                            let maxRunning      =   0; 
                                            let matchedData     =   null;
                                            console.log(scsRunningData.interval);
                                            
                                            if(scsRunningData.interval && scsRunningData.interval.length > 0) {
                                                maxRunning = Math.max(...scsRunningData?.interval.map(d => d.running_percentage));
                                                matchedData = scsRunningData?.interval.find(i => i.running_percentage === maxRunning);
                                            }

                                            return <td className="asset-cell "><span style={{ color: scsRunningData.bg_color }}>{scsRunningData?.max_running} %<br />
                                                <div>{matchedData ? <>({matchedData?.total_running} / {matchedData?.interval}) {matchedData?.unit}</> : ""}</div>
                                            </span><br />
                                             <div>
                                                <button className="hover-button " data-bs-toggle="dropdown"><TapIcon.FontAwesomeIcon icon={tapIcon.faCaretDown} /></button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <Link className="dropdown-item" onClick={() => this.runningStatusModalInit(asset.asset_id, asset.name,scsRunningData.scs_name)}>
                                                                View
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href={`/assets/execution_history/${asset.asset_id}?search_scs_name=${encodeURIComponent(scs)}`}
                                                                className="dropdown-item"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                History
                                                            </a>
                                                        </li>
                                                    </ul>
                                            </div>
                                            </td>
                                        }else{
                                            return <td>NA</td>
                                        }
                                    })
                                }
                            </tr>
                            )
                        })
                        :''
                    }
                </tbody>
            </table>
        </div>
        )
    }
        
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet>Service Checksheet Due - Report</Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.reportFilterFormJsx()}
                            {!this.state.listing_loading && this.state.time && <div style={{backgroundColor:"white"}} className='px-3 py-1 text-end'>
                                    <small className='form-text '>Report is based on Service Checksheet Data at ( {this.state.time} )</small>
                                </div>}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} /> 
                                {
                                    this.state.listing_loading 
                                    ? <Loader/>
                                    :
                                    <>
                                    {this.reportListingDisplayJsx()}
                                    <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                                    </>
                                } 
                    </div>
                </div>
            </div>
             <RunningStatusViewModal parentProps={this.props} ref={this.RunningStatusModalRef}/>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ServiceChecksheetDueReport);