import React from "react";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import TapIcon from "../../../services/TapIcon";
import DateService from "../../../services/DateService";
import EmployeeEducationModal from "./EmployeeEducationModal";

class EmployeeEducationDetailList extends AppBaseComponent {
  constructor() {
    super();

    this.state = {
      emp_id: "",
      list_loading: false,
      education_list: [],
    };

    this.educationModalRef = React.createRef();

    this.emp_education_url = HRM_API_BASE_URL_2 + '/employee_education/:replace_id'; 

  }

  componentDidMount() {
    this.initialDataLoading(this.props);
  }

  initialDataLoading = (pr) => {
    this.setState({ emp_id: pr.emp_id });
    this.listLoading(pr.emp_id);
  };

  listLoading = (emp_id) => {
    this.setState({ list_loading: true });
    HttpAPICall.withAthorization("GET", this.emp_education_url.replace(':replace_id', emp_id), this.props.access_token, {}, {}, (response) => {
        this.setState({
          education_list: response.data,
        });
      }
    ).then(() => this.setState({ list_loading: false }));
  };

  handleModal = (type, id = null) => {
    this.educationModalRef.current.modalHandle(type, id);
  };

  deleteDetail = (id) => {
    swal({
      title: "Delete",
      text: "This action cannot be reversed, Are you sure you want to proceed?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Yes"],
    }).then((willDelete) => {
      if (willDelete) {
        HttpAPICall.withAthorization("DELETE",this.emp_education_url.replace(':replace_id', id), this.props.access_token, {}, {}, (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.listLoading(this.state.emp_id);
          }
        );
      }
    });
  };

  render() {
    return (
      <Ax>
        <div className="tab_content_wrapper mt-3">
          <span className="content_heading">Education Details</span>
          <div className="text-end">
            <button
              type="button"
              onClick={() => this.handleModal("Add")}
              className="btn btn-light btn-sm shadow-sm"
            >
              <TapIcon.FontAwesomeIcon
                icon={TapIcon.faPencilAlt}
                alt="item-action"
              />
            </button>
          </div>
        </div>
        <table
          className="table table-bordered bg-white align-items-center  table-sm table-hover mt-3 mb-4"
          id="settingsTable"
        >
          <thead className="table-secondary">
            <tr className="text-center">
              <th scope="col" style={{ width: "5%" }}>
                S.No
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Institute Name
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Degree/Diploma
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Specialization
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Date of Completion
              </th>
              <th scope="col" style={{ width: "5%" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.list_loading ? (
              <tr className="text-center">
                <td colSpan={6}>
                  <Loader />
                </td>
              </tr>
            ) : this.state.education_list.length > 0 ? (
              this.state.education_list.map((data, i) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{i + 1}</td>
                    <td>{data.institute_name}</td>
                    <td className="text-center">{data.degree_diploma}</td>
                    <td className="text-center">{data.specialization}</td>
                    <td className="text-center">
                      {data.date_of_completion
                        ? DateService.dateTimeFormat(
                            data.date_of_completion,
                            "DD-MMM-YYYY"
                          )
                        : ""}
                    </td>
                    <td className="text-center">
                      <TapIcon.imageIcon
                        icon={TapIcon.ListActionIcon}
                        alt="action-icon"
                        className="img-fluid px-3"
                        data-bs-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-expanded="false"
                      />
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => this.handleModal("Edit", data.id)}
                          >
                            Edit
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => this.deleteDetail(data.id)}
                          >
                            Delete
                          </Link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan={6}>No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
        <EmployeeEducationModal
          ref={this.educationModalRef}
          emp_id={this.state.emp_id}
          listLoading={() => this.listLoading(this.state.emp_id)}
        />
      </Ax>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(
  mapStateToProps,
  null,
  null
)(EmployeeEducationDetailList);
