import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import moment from "moment";
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import * as actions from "../../../store/actions/index"
import UpdateMyDashboardForm from '../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class SubAssemblyWidget extends AppBaseComponent {

    constructor() {
        super();
        this.state                      =   {
            iam_user_sites              :   [],
            status                      :   [],
            subAssemblyForm             :   {search_site_id : '',search_status_id:''},
            loading                     :   false,
            data                        :   [],
            remark_time                 :   '',
        }
        this.updateMyDashboardRef      =   React.createRef();
    }


    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('subAssemblyWidget')) {
            let subAssemblyWidgetData = JSON.parse(localStorage.getItem('subAssemblyWidget'));
            if (subAssemblyWidgetData) {
                let lastRefreshTime     =   moment(subAssemblyWidgetData.time).format();
                let nextDay             =   moment(lastRefreshTime).add(1, 'days').format();
                lastRefreshTime         =   moment(lastRefreshTime).valueOf();
                nextDay                 =   moment(nextDay).valueOf();
                let latestDataTime      =   moment(nextDay - lastRefreshTime).valueOf();
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('subAssemblyWidget')
                    setTimeout(() => {
                        this.getDelayedTransactionDelayPeriod();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));
                   
                } else {
                    this.setState({
                        data            :      subAssemblyWidgetData.data,
                        remark_time     :      subAssemblyWidgetData.time, 
                    })
                }
            }else{
                setTimeout(() => {
                    this.getDelayedTransactionDelayPeriod();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));
            }
        } else {
            setTimeout(() => {
                this.getDelayedTransactionDelayPeriod();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));
        }
    }

    getDelayedTransactionDelayPeriod            =   ()  =>  {
        this.setState({loading: true});
        setTimeout(() => {
            if(this.props.group_info && this.props.group_info.group_id == 'nccltd') {
                setTimeout(() => {
                    this.getSubAssemblyListing();
                }, 9000);
            } else {
                this.getSubAssemblyListing();
            }
        }, 2000);
    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites ||  nextProps.dashboard_search_site_id !== this.props.dashboard_search_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter               =   (props)  =>  {
        if(props.iam_user_sites.length > 0) {
            this.setState({
                iam_user_sites           :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            }, () => {  
                if(props.dashboard_search_site_id || props.clearDashboardFilter == "yes"){
                    this.setState({
                        subAssemblyForm    :       {
                            search_site_id          :       props.dashboard_search_site_id,
                            search_status_id        :       ''
                        }
                    }, () => this.getSubAssemblyListing())
                }
            });
        }
    }

    getSubAssemblyListing                =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {...this.state.subAssemblyForm ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/sub_assembly/dashboard_widget', this.props.access_token, params, {}, (response) => {
            let resp = response.data;
            this.setState({
                data            :   resp.data,
                status          :   resp && resp.status && resp.status.length > 0 ?  resp.status.map((c) => { return {value: c.id, label: c.name}; }) : [],  
                remark_time     :   resp.time, 
            })
  
            if(!this.state.subAssemblyForm.search_site_id && !this.state.subAssemblyForm.search_status_id){
                localStorage.setItem('subAssemblyWidget',JSON.stringify(resp));
               
            }
            if(reload !== false){
                localStorage.setItem('subAssemblyWidget',JSON.stringify(resp));
            }
        }).then(() => this.setState({ loading: false }));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"sub_assembly_status",name:"Sub Assembly"})
    }
  
    render                      =   ()  =>  {
        let widgetStateData = this.state.data;
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("sub_assembly_status" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "")})  : null;

        let totalLinked = 0;
        let totalDeLinked = 0;

        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Sub Assembly</h6>
                    </div>
                    <div className="col-3">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'subAssemblyForm', 'search_status_id', () => this.getSubAssemblyListing());
                            }}
                            options={this.state.status}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select Status"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.status.find(c => c.value == this.state.subAssemblyForm.search_status_id)}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.find(r => r.value === this.state.subAssemblyForm.search_site_id)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'subAssemblyForm', 'search_site_id', () => this.getSubAssemblyListing()) }
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <Link className={['dropdown-item'].join(' ')} onClick={() => this.getSubAssemblyListing(true)}>Refresh</Link>
                                <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "50%" }} className="text-center" >Category</th>
                                    <th className="text-center">Linked</th>
                                    <th className="text-center">De-linked</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? (<tr>
                                        <td className="text-center" colSpan="3"><Loader /></td>
                                    </tr>)
                                    : <Ax>
                                        {
                                            widgetStateData && widgetStateData.length > 0 && widgetStateData.map((s, index) => {
                                            totalLinked += s.linked_assets
                                            totalDeLinked += s.delinked_assets
                                            return (<tr key={index + 1}>
                                                <th>{s.category}</th>
                                                <td className="text-center">
                                                    <Link target="_blank"
                                                    to={{ pathname: '/sub_assembly', 
                                                    search: "?" + new URLSearchParams({...this.state.subAssemblyForm, linking_status : 'Y', search_category_id : s.id})
                                                    }}>{s.linked_assets}</Link>
                                                </td>
                                                <td className="text-center">
                                                    <Link target="_blank"
                                                    to={{ pathname: '/sub_assembly', search: "?" + new URLSearchParams({...this.state.subAssemblyForm, linking_status : 'Y', search_category_id : s.id})
                                                    }}>{s.delinked_assets}</Link>
                                                </td>
                                            </tr>)
                                        })
                                        }
                                        <tr>
                                            <th>Total</th>
                                            <td className="text-center">{totalLinked}</td>
                                            <td className="text-center">{totalDeLinked}</td>
                                        </tr>
                                    </Ax>
                                    }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 p0">
                    { this.state.loading
                            ? <Loader />
                            : (widgetStateData ? (<Chart
                                chartType="PieChart"
                                data={[['', '']].concat([{name : 'Linked', count : totalLinked}, {name : 'De-linked', count : totalDeLinked}].map((d) =>
                                { return [d.name, d.count]; }))}
                                options={{
                                    legend: {position: 'labeled',labeledValueText: 'both'},
                                    pieSliceText: 'none',
                                    sliceVisibilityThreshold :0,
                                    slices: this.state.data?.map((d) => {return {color : d.color} }),
                                    chartArea: {width: '90%', height: '90%' },
                                }}
                                rootProps={{ 'data-testid': '1' }} />) : null)  
                        }
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                    Report as of {this.state.remark_time}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }
    
}


const mapStateToProps = state => {
    
    return {
        access_token            :   state.auth.access_token,
        group_info              :   state.app.group_info ? state.app.group_info : null,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading  :   (access_token)  =>  dispatch(actions.applicationInitLoading(access_token,false)),
       
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(SubAssemblyWidget);
