import React, {useImperativeHandle, useRef, useEffect, forwardRef, useState} from "react";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import TapIcon from "../../../services/TapIcon";
import TapSelect from "../../../components/ui/TapSelect";
import { useSelector } from "react-redux";
import HttpAPICall from "../../../services/HttpAPICall";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import Loader from "../../../components/ui/Loader/Loader";
import InputEmployeeSearch from "../../includes/ui/InputEmployeeSearch";

const EmployeeSearchModal = forwardRef(({searchHandle, listingLoading, fileDownloading, formSearchedElems}, ref) => {

    const access_token                  =   useSelector(state => state.auth.access_token);

    const formDataUrl                   =   HRM_API_BASE_URL_2 + '/employee/add_form_data';
    
    const modalElem                     =   useRef(null);
    const searchModal                   =   useRef(null);
    const modalID                       =   uuid();

    const employeeSearchInitState       =   {
        search_name                         :   "",
        search_like_employee_code           :   "",
        search_mobile                       :   "",
        status_search                       :   "",
        search_reporting_manager            :   "",
        search_site_ids                     :   [],
        tags_search                         :   [],
        search_employee_type_ids            :   [],
        search_designation_ids              :   [],
        search_division_ids                 :   [],
    };
    const employeeSearchFormLabel       =   {
        search_name                         :   "Employee Name : ",
        search_like_employee_code           :   "Employee ID : ",
        search_mobile                       :   "Employee Number : ",
        search_site_ids                     :   "Sites : ",
        status_search                       :   "Status : ",
        tags_search                         :   "Tags : ",
        search_employee_type_ids            :   "Employee Type : ",
        search_designation_ids              :   "Designation : ",
        search_division_ids                 :   "Department : ",
        search_reporting_manager            :   "Reporting Manager : "
      };

    const [employeeSearchForm, setEmployeeSearchForm]   =   useState(employeeSearchInitState);

    const user_sites                    =   useSelector(state => state.app && state.app.user_sites ? state.app.user_sites : []);
    const asset_type                    =   useSelector(state => state.app && state.app.asset_type ? state.app.asset_type : []);
    const asset_groups                  =   useSelector(state => state.app && state.app.asset_groups ? state.app.asset_groups : []);
    const [formData, setFormData]       =   useState({
        data_loaded                         :   false,
        loading                             :   false,
        manager_data                        :   {},
        iam_user_sites                      :   [],
        iam_asset_types                     :   [],
        assetTypesGroupsList                :   [],
        employeeTags                        :   [],
        allStatus                           :   [],
        employeeTypes                       :   [],
        designation                         :   [],
        division                            :   []
    });           
    useEffect(() => {    
        modalElem.current               =   document.getElementById(modalID);
        searchModal.current             =   new Modal(modalElem.current, {keyboard: false, backdrop :false});
        return () => {
            if (searchModal.current) {
                searchModal.current.dispose();
            }
        };
    }, []);

    useImperativeHandle(ref, () => ({
        initModalHandler: () => {
            openModalHandle()
        }
    }));


    const formDataApi                   =   () => {
        setFormData(pre => ({...pre, loading : true}));
        HttpAPICall.withAthorization("GET", formDataUrl, access_token, {type : 'config'}, {}, (resp) => {
            const {tags, status, employee_type, designation, division}   =   resp.data;
            setFormData(pre => ({...pre,
                data_loaded                         :   true,
                employeeTags                        :   tags && tags.length > 0 ? tags.map((s) => ({value: s, label: s })) : [],
                allStatus                           :   status && status.length > 0 ? status.map((s) => ({ value: s.key, label: s.name })) : [],
                iam_user_sites                      :   user_sites.map((s) => ({value: s.id, label: s.site_name })),
                iam_asset_types                     :   asset_type.map((at) => ({value: at.id, label: at.asset_type_name })),
                assetTypesGroupsList                :   asset_groups.map((ag) => ({value: ag.id, label: ag.name })),
                employeeTypes                       :   employee_type && employee_type.length > 0 ? employee_type.map((et) => ({value: et.id, label: et.name })) : [],
                designation                         :   designation && designation.length > 0 ? designation.map((des) => ({value: des.id, label: des.name })) : [],
                division                            :   division && division.length > 0 ? division.map((div) => ({value: div.id, label: div.name })) : []
            }))
        }).then(() => setFormData(pre => ({...pre, loading : false})));

    }
    const searchModalOpen               =   ()  => searchModal.current && searchModal.current.show();
    const searchModalClose              =   ()  => {
        searchModal.current && searchModal.current.hide()
    };

    const openModalHandle               =   () => {
        formSearchedElems.length === 0 && setEmployeeSearchForm(employeeSearchInitState);
        searchModalOpen();        
        if(!formData.data_loaded) {
            formDataApi();
        }
    }

    const handleOnSelect                =   (selectVal, key) => {
        const data          =   selectVal.length > 0 ? selectVal.map(d => d.value) : selectVal.value;
        setEmployeeSearchForm(pre => ({
            ...pre, [key] : data
        }));
    }
    const handleOnChange                =   (e) => {
        setEmployeeSearchForm(pre => ({
            ...pre, [e.target.name] : e.target.value
        }));
    }

    const searchFormSubmit              =   (e) => {
        e.preventDefault();
        //Get All Keys :-
        let serachFormKeys = Object.keys(employeeSearchForm);
        let searchedElems = [];
        let employeeSearchParams = {};
        serachFormKeys.map((key) => {
            let label = employeeSearchFormLabel[key];
            let value = employeeSearchForm[key];
            if (value && value.toString().length > 0) {
                employeeSearchParams[key] = value;
                if (label) {
                    let show_val = value;
                    if (key === "search_name") {
                        show_val = employeeSearchForm.search_name;
                    }
                    if (key === "search_like_employee_code") {
                        show_val = employeeSearchForm.search_like_employee_code;
                    }
                    if (key === "search_mobile") {
                        show_val = employeeSearchForm.search_mobile;
                    }
                    // manager_data
                    if (key === "search_reporting_manager") {
                        show_val = formData.manager_data?.label;
                    }
                    if (key === "email_search") {
                        show_val = employeeSearchForm.email_search;
                    }
                    if (key === "search_site_ids") {
                        show_val = formData.iam_user_sites.filter((s) => value.includes(s.value)).map((s) => s.label)
                        .join(", ");
                    }
                    if (key === "search_designation_ids") {
                        show_val = formData.designation.filter((s) => value.includes(s.value)).map((s) => s.label)
                        .join(", ");
                    }
                    if (key === "search_division_ids") {
                        show_val = formData.division.filter((s) => value.includes(s.value)).map((s) => s.label)
                        .join(", ");
                    }
                    if (key === "search_employee_type_ids") {
                        show_val = formData.employeeTypes.filter((s) => value.includes(s.value)).map((s) => s.label)
                        .join(", ");
                    }
                    if (key == "tags_search") {
                        show_val = formData.employeeTags.filter((s) => value.includes(s.value)).map((s) => s.label)
                        .join(", ");
                    }
                    if (key == "status_search") {
                        show_val = formData.allStatus.filter((s) => value == s.value).map((s) => s.label).join(", ");
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        searchHandle(searchedElems, employeeSearchForm);
        searchModalClose();
    }

    return <>
        <div className="modal fade" id={modalID} tabIndex="-1" ref={modalElem}>
            <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="employeeSearchModalLabel">Employee Search</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={searchModalClose} aria-label="Close"></button>
                </div>
                    <div className="modal-body">
                        {
                            formData.loading 
                            ?   <Loader/> 
                            : 
                            <>
                                <form onSubmit={searchFormSubmit} id="employeeSearchForm" >
                                    <div className="row align-items-center mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">Name</label>
                                        <div className="col-sm-4">
                                            <input
                                            type="text"
                                            name="search_name"
                                            onChange={(e) => handleOnChange(e)}
                                            className="form-control form-control-sm"
                                            placeholder="Enter Employee Name"
                                            autoComplete="off"
                                            value={employeeSearchForm.search_name}
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">
                                            Employee ID
                                        </label>
                                        <div className="col-sm-4">
                                            <input
                                            type="text"
                                            onChange={(e) => handleOnChange(e)}
                                            name="search_like_employee_code"
                                            className="form-control form-control-sm"
                                            placeholder="Enter Employee ID"
                                            autoComplete="off"
                                            value={employeeSearchForm.search_like_employee_code}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">
                                            Mobile Number
                                        </label>
                                        <div className="col-sm-4">
                                            <input
                                            type="number"
                                            onChange={(e) => handleOnChange(e)}
                                            name="search_mobile"
                                            className="form-control form-control-sm"
                                            placeholder="Enter Employee Number"
                                            autoComplete="off"
                                            value={employeeSearchForm.search_mobile}
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">Site</label>
                                        <div className="col-sm-4">
                                        <TapSelect
                                            options={formData.iam_user_sites}
                                            changeEvent={(selectedOption) => {
                                                handleOnSelect(selectedOption, "search_site_ids");
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            value={formData.iam_user_sites.filter((s) =>
                                                employeeSearchForm.search_site_ids?.includes(s.value)
                                            )}
                                            placeholder="Select Sites"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">Employee Type</label>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                options={formData.employeeTypes}
                                                changeEvent={(selectedOption) => {
                                                    handleOnSelect(selectedOption, "search_employee_type_ids");
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                menuPlacement="bottom"
                                                value={formData.employeeTypes.filter((s) => employeeSearchForm.search_employee_type_ids?.includes(s.value))}
                                                placeholder="Select Employee Type"
                                                containerHeight="30px"
                                            fontSize="93%"
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">
                                            Designation
                                        </label>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                options={formData.designation}
                                                changeEvent={(selectedOption) => {
                                                    handleOnSelect(selectedOption, "search_designation_ids");
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                value={formData.designation.filter((s) => employeeSearchForm.search_designation_ids?.includes(s.value)
                                                )}
                                                placeholder="Select Designation"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">
                                            Department
                                        </label>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                options={formData.division}
                                                changeEvent={(selectedOption) => {
                                                    handleOnSelect(selectedOption, "search_division_ids");
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                value={formData.division.filter((s) => employeeSearchForm.search_division_ids?.includes(s.value)
                                                )}
                                                placeholder="Select Department"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">Tags</label>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                options={formData.employeeTags}
                                                changeEvent={(selectedOption) => {
                                                    handleOnSelect(selectedOption, "tags_search");
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                menuPlacement="bottom"
                                                value={formData.employeeTags.filter((s) => employeeSearchForm.tags_search?.includes(s.value))}
                                                placeholder="Select Tags"
                                                containerHeight="30px"
                                            fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">
                                            Status
                                        </label>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                options={formData.allStatus}
                                                changeEvent={(selectedOption) => {
                                                    handleOnSelect(selectedOption, "status_search");
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                                menuPlacement="bottom"
                                                value={formData.allStatus.find((s) => employeeSearchForm.status_search == s.value
                                                )}
                                                placeholder="Select Status"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-start">
                                            Reporting Manager
                                        </label>
                                        <div className="col-sm-4">
                                            <InputEmployeeSearch
                                                placeholder="Search"
                                                changeEvent={(selectedOption) => {
                                                    handleOnSelect(selectedOption, "search_reporting_manager");
                                                    setFormData(pre => ({...pre, manager_data: {...selectedOption}}))
                                                }}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={
                                                    employeeSearchForm.search_reporting_manager ? formData.manager_data : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </form>
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={searchModalClose}>Close</button>
                        <button type="submit" form="employeeSearchForm" className="btn btn-primary">Search</button>
                    </div>
            </div>
            </div>
        </div>
    </>
});
export default EmployeeSearchModal;