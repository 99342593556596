import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import AssetService from '../../../services/AssetService';

class ResetCounterlogModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
    
        this.counterlogFormInit         =   {
            reset_datetime                       :   moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            remarks                             :   '',
            hourmeter                           :   '',
            hourmeter2                          :   '',
            hourmeter3                          :   '',
            hourmeter4                          :   '',
            odometer                            :   '',
            odometer2                           :   '',
            odometer3                           :   '',
            operation_cycle                     :   '',
            operation_cycle2                    :   '',
            hourmeter_old                       :   '',
            hourmeter2_old                      :   '',
            hourmeter3_old                      :   '',
            hourmeter4_old                      :   '',
            odometer_old                        :   '',
            odometer2_old                       :   '',
            odometer3_old                       :   '',
            operation_cycle_old                 :   '',
            operation_cycle2_old                :   '',
        };

        this.state                      =   {
            assetData                      :   null,
            resetCounterlogForm            :   {...this.counterlogFormInit},
            copy_reading                    :   'N',
            latestCounterlog               :    null,
            saveFormSubmitting             :    false,
            enable_asset_meter_code         :   'N'
        }
    }

    componentDidMount() {
        this.resetCounterlogModal      =   new Modal(document.getElementById('resetCounterlogModal'), {keyboard: false, backdrop: false});
    }
    
    resetCounterlogInit                =   (asset_detail,enable_asset_meter_code = 'N',settingData=null) => {
     
        this.resetCounterlogModal.show();
        let meterReading                =   {};

        if(settingData){
            let assetMeters                 =   AssetService.avaialbleAssetMeters(asset_detail);
           
            assetMeters.forEach((meter, mk) => {
                let input_state_key       =     AssetService.getCounterlogReadingInputKey(meter.key);
                let old_meter_code_input_key   =    meter.abs_key + '_meter_code_old';
                let meter_code_input_key       =    meter.abs_key + '_meter_code';
               
                meterReading[old_meter_code_input_key]  = settingData[meter_code_input_key];
            });
       }

      
        this.setState({
            resetCounterlogForm     :       {...this.counterlogFormInit,...meterReading},
            copy_reading            :       'N',
            assetData               :       asset_detail,
            enable_asset_meter_code :       enable_asset_meter_code,
            latestCounterlog        :       asset_detail && asset_detail.latest_counterlog ? asset_detail.latest_counterlog : null, 

        },() => {
         
        })
          
    }

    copyLatestReadingHandler                 =   ()  =>  {
        if(this.state.copy_reading == "Y" && this.state.latestCounterlog) {
            this.setState({resetCounterlogForm : {
                ...this.state.resetCounterlogForm,
                hourmeter_old               :   this.state.latestCounterlog.hours_abs,
                hourmeter2_old              :   this.state.latestCounterlog.hours_abs2,
                hourmeter3_old              :   this.state.latestCounterlog.hours_abs3,
                hourmeter4_old              :   this.state.latestCounterlog.hours_abs4,
                odometer_old                :   this.state.latestCounterlog.odometer_abs,
                odometer2_old               :   this.state.latestCounterlog.odometer_abs2,
                odometer3_old               :   this.state.latestCounterlog.odometer_abs3,
                operation_cycle_old         :   this.state.latestCounterlog.cycle_abs,
                operation_cycle2_old        :   this.state.latestCounterlog.cycle_abs2,
                reset_datetime              :  moment(this.state.latestCounterlog.feed_datetime).format('YYYY-MM-DD HH:mm:ss')
            }});
        }
    }
  
    submitResetCounterlogHandler        =       (e)     =>      {
        e.preventDefault();
        
        let resetCounterlogForm = {...this.state.resetCounterlogForm}
        let frmData      =   {
                                asset_id : this.state.assetData && this.state.assetData.asset_id ? this.state.assetData.asset_id : '',
                                ...resetCounterlogForm
                                
                            }
                          
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('POST', IAM_API_BASE_URL_2 + '/counterlog/reset_meter', this.props.access_token, {}, {...frmData}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.resetCounterlogModal.hide();
            if(this.props.afterResetCounterlogSubmit){
                this.props.afterResetCounterlogSubmit()
            }
            this.setState({ 
                resetCounterlogForm    :   {...this.counterlogFormInit},
                copy_reading           :   'N',
            })
        }).then(() => this.setState({ saveFormSubmitting: false }));
      
    }

    render                              =   ()  =>  {
        let labels                          =   this.state.assetData && this.state.assetData.labels ? this.state.assetData.labels : {};
        let assetMeters                    =   AssetService.avaialbleAssetMeters(this.state.assetData);
       
        return (
            <div className="modal fade" id="resetCounterlogModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <form className="bg-white p-1" onSubmit={this.submitResetCounterlogHandler} id="resetCounterlogForm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Reset Counterlog</h5>

                            </div>

                            <div className='p-1 bg-light border-bottom'>
                                <table className='table table-sm mb-0 table-borderless text-sm'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                            <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-body">
                                <div className='row my-1'>
                                    <div className='offset-3 col-sm-4'>
                                        <small className="form-text">Old Reading</small>
                                    </div>
                                    <div className='col-sm-5'>
                                        <small className="form-text">New Reading</small>
                                    </div>
                                </div>
                                {assetMeters.map((meter, k) => {
                                    let input_state_key = AssetService.getCounterlogReadingInputKey(meter.key);
                                    let old_input_state_key = input_state_key + '_old';
                                    let meter_code_input_key   =    meter.abs_key + '_meter_code';
                                    let old_meter_code_input_key   =    meter.abs_key + '_meter_code_old'
                                    return (<Ax>
                                        <div className='row my-2'>
                                            <div className='col-sm-3'><label className="form-label require">{meter.label}</label></div>
                                            <div className='col-sm-4'>
                                                <input
                                                    name={old_input_state_key}
                                                    type="number"
                                                    value={this.state.resetCounterlogForm[old_input_state_key]}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, 'resetCounterlogForm')}
                                                    autoFocus={true}
                                                    placeholder="Please Enter Old Reading"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                                {this.state.latestCounterlog ? <span className="text-sm form-text">
                                                    Latest Reading {this.state.latestCounterlog ? `${this.state.latestCounterlog[meter.abs_key]} @ ${this.state.latestCounterlog.feed_date_display}` : '-'}
                                                </span>
                                                    : null}
                                            </div>
                                            <div className='col-sm-5'>
                                                <input
                                                    name={input_state_key}
                                                    type="number"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, 'resetCounterlogForm')}
                                                    placeholder="Please Enter Reading"
                                                    value={this.state.resetCounterlogForm[input_state_key]}
                                                    required={true}

                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            {this.state.enable_asset_meter_code == 'Y' && <div>
                                                <div className='row my-2'>
                                                    <div className='col-sm-3'><label className="form-label require">{meter.label} (Meter Code)</label></div>
                                                    <div className='col-sm-4'>
                                                        <input
                                                            name={old_meter_code_input_key}
                                                            className="form-control"
                                                            disabled="true"
                                                            value={this.state.resetCounterlogForm[old_meter_code_input_key]}
                                                            style={{ height: "30px", fontSize: "93%" }}
                                                        />
                                                      
                                                    </div>
                                                    <div className='col-sm-5'>
                                                        <input
                                                            name={meter_code_input_key}
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            onChange={(e) => this.formInputHandler(e, 'resetCounterlogForm')}
                                                            placeholder="Please Enter Meter Code Reading"
                                                            value={this.state.resetCounterlogForm[meter_code_input_key]}
                                                            required={true}

                                                            style={{ height: "30px", fontSize: "93%" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </Ax>)
                                })}
                            
                                <div className="row align-items-center my-2">
                                    <div className="col-sm-3"><label className="form-label">Reset Counterlog Date</label></div>
                                    <div className="col-md-9 add_calender_section">

                                        <DatePicker
                                            selected={
                                                this.state.resetCounterlogForm.reset_datetime
                                                    ? moment(this.state.resetCounterlogForm.reset_datetime, 'YYYY-MM-DD HH:mm:ss').toDate()
                                                    : false
                                            }
                                            name="reset_datetime"
                                            onChange={(value, event) => {
                                                this.formDateHandler('reset_datetime', value, 'resetCounterlogForm', 'YYYY-MM-DD HH:mm:ss')
                                            }}

                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete="off"
                                            scrollMonthYearDropdown
                                            placeholderText={`Please Enter Counterlog Date`}
                                            showTimeSelect

                                            timeIntervals={5}
                                            timeCaption="Time"
                                            className={"form-control form-control-sm"}

                                            dateFormat="dd-MMM-yyyy hh:mm a"
                                            minDate={this.state.latestCounterlog ? moment(new Date(this.state.latestCounterlog.feed_datetime), 'YYYY-MM-DD').toDate() : ''}
                                            required={true}

                                        />
                                        <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "5px" }} />
                                    </div>
                                </div>
                                <div className="row align-items-center my-2">
                                    <div className="col-sm-3"><label className="form-label">Remark</label></div>
                                    <div className="col-md-9">
                                        <textarea
                                            name="remarks"
                                            value={this.state.resetCounterlogForm.remarks}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Remark"
                                            onChange={(e) => this.formInputHandler(e, 'resetCounterlogForm')}
                                            style={{ height: "80%", fontSize: "93%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between row">
                                <div className="col text-start">
                                    <input
                                        name="copy_reading"
                                        type="checkbox"
                                        value={this.state.copy_reading}
                                        onChange={(e) => {
                                            this.setState({ copy_reading: this.state.copy_reading == 'N' ? 'Y' : 'N' }, () => this.copyLatestReadingHandler())
                                        }}
                                        checked={this.state.copy_reading == 'Y'}
                                        className="form-check-input"
                                        id="copy_reading"
                                    />
                                    <label className="form-check-label mx-2" htmlFor="copy_reading">Copy Old Reading from Latest Reading </label>
                                </div>
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" >Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form='resetCounterlogForm'>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(ResetCounterlogModal);