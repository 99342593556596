import React from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import axios from 'axios';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from "../../../components/ui/Status"
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import TapSelect from '../../../components/ui/TapSelect';
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import download from '../../../services/DownloadFile';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import DateService from '../../../services/DateService';

class CustomerProjectList extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initContactForm               =      {
            manager_name                           :      "",
            email                          :      "",
            mobile                         :      "",
            designation                     :   ""
        }

        this.initAddressForm            =   {
            label                           :   "Primary Address",
            gstin                           :   '',
            address1                        :   '',
            address2                        :   '',
            city                            :   '',
            landmark                        :   '',
            district_name                   :   null,
            pincode                         :   null,
            country_id                      :   99,
            district_id                     :   '',
        }

        this.initCustomerSearchForm     =   {
            search_project_name                     :   '',
            search_project_code                     :   '',
            search_project_types                     :  [],
            search_customer_ids                     :   [],
            search_state_ids                :   [],
            search_gstin                    :   '',
            search_status                   :   ''
        }

        this.customerSearchFormLabel        =   {
            search_project_name                     :   'Customer Project Name : ',
            search_project_code                     :   'Customer Project Code : ',
            search_project_types                     :   'Customer Project Types : ',
            search_customer_ids                     :   'Customers : ',
            search_state_ids                :   'State : ',
            search_gstin                    :   'GSTIN : ',
            search_status                   :   'Status : '
        };

        this.initChangeStatusInit       =   {
            id                              :   '',
            status                          :   ''
        }

        this.state                          =   {
            customerAddScreenView               :   false,
            customerUpdateScreenView            :   false,
            minimizeTable                       :   false,
            customerFormDataLoaded              :   false,
            customerAddFormData                 :   [],
            stateList                           :   [],
            listing_loading                     :   false,
            details_loading                     :   false,
            listing_tbl_page                    :   1,
            customer_listing                    :   [],
            listingMeta                         :   null,
            totalListingCount                   :   0,
            customerDetails                     :   [],
            customerViewDetails                 :   [],
            addContactFormSubmitting            :   false,
            customerBulkUploadFormSubmiting     :   false,
            addContactForm                      :   {...this.initContactForm},
            editContactDetails                  :   false,
            allStateList                        :   [],
            allCountryList                      :   [],
            allDistrictList                     :   [],
            allPincodeList                      :   [],
            editAddressDetails                  :   false,
            addAddressForm                      :   {...this.initAddressForm},
            customerAddressFormDataLoaded       :   false,
            addAddressFormSubmitting            :   false,
            customerSearchForm                  :   {...this.initCustomerSearchForm},
            searchFormDataLoaded                :   false,
            allSearchStatesList                 :   [],
            searchedCustomers                   :   [],
            search_form_loading                 :   false,
            submittedCustomerSearchForm         :   {...this.initCustomerSearchForm},
            formSearchedElems                   :   [],
            reportDownloading                   :   false,
            addNewDistrict                      :   false,
            addNewPinCode                       :   false,
            loading_address                     :   true,
            allStatusList                       :   [],
            allProjectTypes                     :  [],
            changeStatusForm                    :   {...this.initChangeStatusInit},
            saveFormSubmitting                  :   false,
        };

        this.customerURL                =   INVENTORY_API_BASE_URL_2 + '/customer_project/list';
        this.customerFormURL            =   INVENTORY_API_BASE_URL_2 + '/customer_project/form_data'; 
        this.customerDetailURL          =   INVENTORY_API_BASE_URL_2 + '/customer_project/detail'; 
        this.customerChangeStatusUrl    =    INVENTORY_API_BASE_URL_2 + '/customer_project/change_status'; 

        this.addContactUrl              =   INVENTORY_API_BASE_URL_2 + '/customer_project/contact_add';
        this.editContactUrl              =   INVENTORY_API_BASE_URL_2 + '/customer_project/contact_edit';
        this.deleteContactUrl              =   INVENTORY_API_BASE_URL_2 + '/customer_project/contact_delete';
        this.primaryContactUrl              =   INVENTORY_API_BASE_URL_2 + '/customer_project/contact_make_primary';
        
        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';
        
    }

    componentDidMount() {
        this.loadListingTblData(1);
        this.loadSearchFormData();
        this.searchCustomerModal                  =   new Modal(document.getElementById('searchCustomerModal'), {keyboard: false, backdrop :false});
        this.addContactModal                  =   new Modal(document.getElementById('addContactModal'), {keyboard: false, backdrop :false});
        this.customerBulkUploadModal            =   new Modal(document.getElementById('customerBulkUploadModal'), {keyboard: false, backdrop :false});
        this.changeStatusModal             =   new Modal(document.getElementById('changeStatusModal'), {keyboard: false, backdrop :false});
        //****************After Add and update ***************************/
            if(this.props.location && this.props.location.state && this.props.location.state.customerId){
                this.viewCustomerDetail(this.props.location.state.customerId)
            }
            if(this.props.location && this.props.location.state && this.props.location.state.customerData){
                this.setState({minimizeTable : true})
                this.viewCustomerDetail(this.props.location.state.customerData.id)
            }
    }

    //***********************GET CUSTOMER LISTING************************
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedCustomerSearchForm};
        HttpAPICall.withAthorization('GET',  this.customerURL, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                customer_listing    : respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount   : respData.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    loadSearchFormData      =       ()      =>      {
        if(!this.state.customerFormDataLoaded){
            this.setState({search_form_loading : true})
            HttpAPICall.withAthorization('GET', this.customerFormURL, this.props.access_token, null, null, (response) => {
                let respData = response.data;

                this.setState({
                    customerFormDataLoaded          :       true,
                    allProjectTypes         :        respData &&  respData.project_types &&  respData.project_types.length > 0 ?  respData.project_types.map((s) => { return { value: s.id, label: s.name }; }) : [],
                    allSearchStatesList             :       respData && respData.states && respData.states.length > 0 ? respData.states.map((s) => { return {value: s.id, label: s.name}; }) : [], 
                    allStatusList                   :        respData && respData.status && respData.status.length > 0 ? respData.status.map((s) => { return {value: s.id, label: s.name}; }) : [], 
                });
            }).then(() => this.setState({search_form_loading : false}));
        }
    }

    
   //****************OPEN  ADD Customer SCREEN*******************
    openAddCustomerScreen       =      ()      =>     {
        this.props.history.push('/customer_project_add')
    }

    //*********************OPEN  UPDATE Customer SCREEN********************
    openUpdateCustomerScreen       =      (id)      =>     {
        if(this.state.customerViewDetails && this.state.customerViewDetails.id == id) {
            this.props.history.push({pathname: `/customer_project_add`, state: {customerData : this.state.customerViewDetails }})
        } else {
            HttpAPICall.withAthorization('GET', this.customerURL + '/' + id , this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.props.history.push({pathname: `/customer_project_add`, state: {customerData : respData.data }})   
            });
        }
    }


    //******************VIEW CUSTOMER DETAILS***********************************
    viewCustomerDetail          =      (id)        =>     {
        this.setState({ minimizeTable           :   true})
        this.setState({details_loading : true})
        HttpAPICall.withAthorization('GET', this.customerDetailURL + '/' + id, this.props.access_token, null, null, (response) => {
            this.setState({
                customerViewDetails       :    response.data,  
            });
        }).then(() => this.setState({details_loading: false}))
    }

    //**************************CLOSE VIEW CUSTOMER DETAILS*********************
    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    //*****************************ADD CONTACT MODAL INIT*******************//
    addContactInit          =       ()      =>      {
        this.addContactModal.show()
    }

    //******************************EDIT CONTACT FORM************************
    editContact             =      (id)        =>     {
        if(id){
            let contactDetails = this.state.customerViewDetails.contacts.find(i => i.id == id)
            this.setState({addContactForm : {...contactDetails,contact_id : id},editContactDetails : true})
            this.addContactModal.show()
        }
    }

    //******************************SET PRIMARY CONTACT FORM***********************
    setPrimaryContact           =       (id)    =>      {
        HttpAPICall.withAthorization('PUT',  this.primaryContactUrl , this.props.access_token, {contact_id : id, project_id : this.state?.customerViewDetails?.id }, {}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.viewCustomerDetail(this.state.customerViewDetails.id)
        }) 
    }

    //**************************DELETE PRIMARY CONTACT FORM**********************
    deletePrimaryContact           =       (id)    =>      {
        HttpAPICall.withAthorization('DELETE',  this.deleteContactUrl + '/' + this.state?.customerViewDetails?.id + '/' + id, this.props.access_token, {}, {}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.viewCustomerDetail(this.state.customerViewDetails.id)
        }) 
    }

    //***********************ADD CONTACT FORM SUBMIT****************************
    addContactFormSubmit        =       (e)      =>      {
       e.preventDefault()
   
       if(this.state.addContactForm.manager_name == ''  && this.state.addContactForm.mobile == ''){
        toast.error('Please fill the form', { position: toast.POSITION.TOP_RIGHT });
       }else{
        this.setState({addContactFormSubmitting : true});
        if(this.state.editContactDetails){
            HttpAPICall.withAthorization('PUT', this.editContactUrl, this.props.access_token, {}, {...this.state.addContactForm,project_id : this.state.customerViewDetails ? this.state.customerViewDetails.id : null}, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addContactModal.hide()
                this.viewCustomerDetail(this.state.customerViewDetails.id)
                this.setState({addContactForm : {...this.initContactForm}})
            }).then(() => this.setState({addContactFormSubmitting: false, editContactDetails : false}));
        }else{
            let frmData = {
                ...this.state.addContactForm,
                project_id : this.state.customerViewDetails ? this.state.customerViewDetails.id : null
            };
            HttpAPICall.withAthorization('POST',this.addContactUrl, this.props.access_token, {}, {...frmData}, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addContactModal.hide()
                this.viewCustomerDetail(this.state.customerViewDetails.id)
                this.setState({addContactForm : {...this.initContactForm}})
            }).then(() => this.setState({addContactFormSubmitting: false}));
        }
       }
       
    }
     
    /**
     * Bulk Upload :-
     */
    customerBulkUploadModalInit               =   ()  =>  {
        document.getElementById('addCustomerFileSelector').value = null;
        //Initialize Search Form :-
        this.customerBulkUploadModal.show();
    }
    
    downloadSampleFile                  =   ()      => {
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/customer_download_sample_add_excel', this.props.access_token,
        {},{},(response) => 
        {   DownloadFile.file(response.data.file_path)
            toast.success('File Downloaded Successfully', {position: toast.POSITION.TOP_RIGHT})
            this.customerBulkUploadModal.hide();
        })
    }
    
    customerBulkUploadFormSubmit            =   (event) => {
        event.preventDefault();
        this.setState({customerBulkUploadFormSubmiting : true});
        const data          =   new FormData(document.getElementById('customerBulkUploadForm'));
        let headers         =   { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data','Authorization' : 'Bearer ' + this.props.access_token };
        axios.post(INVENTORY_API_BASE_URL_2 + '/customer_add_by_excel', data, { headers: headers })
        .then((response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.customerBulkUploadModal.hide();
            //Load Add Item Form Data :-
            DownloadFile.file(response.data.generated_file.file_path);
            setTimeout(() => {
                this.loadListingTblData(1);
            },1000)
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to upload customer Add File.', {position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            this.setState({customerBulkUploadFormSubmiting : false});
        });
    }

    customerSearchFormInit        =       ()  => {
        this.searchCustomerModal.show()
    }

    customerSearchFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
       
        let serachFormKeys                  =   Object.keys(this.state.customerSearchForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};

        serachFormKeys.map((key)        =>  {
            let label                       =   this.customerSearchFormLabel[key];
            let value                       =   this.state.customerSearchForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_project_name') {
                        show_val            =   this.state.customerSearchForm.search_project_name;
                    }
                    if(key == 'search_project_code') {
                        show_val            =   this.state.customerSearchForm.search_project_code;
                    }
                    if(key == 'search_customer_ids') {
                        show_val            =   this.state.customerSearchForm.search_project_code;
                    }
                    if(key == 'search_state_ids') {
                        show_val            =   this.state.allSearchStatesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_status') {
                        show_val            =   this.state.allStatusList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }

                    if(key == 'search_gstin') {
                        show_val            =   this.state.customerSearchForm.search_gstin;
                    }
                    if(key == 'search_project_types') {
                        show_val            =   this.state.allProjectTypes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'search_customer_ids') {
                        show_val            =   this.state.searchedCustomers ? this.state.searchedCustomers.map(s => s.label).join(', ') : '';
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedCustomerSearchForm         :   {...this.state.customerSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
           this.searchCustomerModal.hide();
        });
    }

    customerSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems               :   [],
            customerSearchForm              :   {...this.initCustomerSearchForm},
            submittedCustomerSearchForm     :   {...this.initCustomerSearchForm},
            searchedCustomers               :    []
        }, () => {
            this.loadListingTblData(1);
        });
    }

    downloadCustomerReport              =    ()     =>         {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET',  this.customerURL , this.props.access_token,{download : "download"},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({reportDownloading: false}));
    }

    changeStatusInit        =       ()      =>      {
        this.changeStatusModal.show();
        let detail = this.state.customerViewDetails ? this.state.customerViewDetails : null;
        this.setState({
            changeStatusForm    :       {
                status          :       detail && detail.status ? detail.status.id : '',
                id              :       detail && detail.id ? detail.id : ""
            }
        })
    }

    customerListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Customer Project</h3>
                <div className="text-end mt15">
                    {this.state.minimizeTable
                        ? <Ax>
                            {this.props.permissions.includes('customer-project-add') && <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                  
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    { //this.props.permissions.includes('customer-add') && 
                                    <Ax>
                                        <li><Link className="dropdown-item" onClick={this.openAddCustomerScreen}>Add Customer</Link></li>
                                        {
                                            // <li><Link className="dropdown-item" onClick={this.customerBulkUploadModalInit}>Bulk Upload</Link></li>
                                        }
                                    </Ax>
                                    }
                                </ul>
                            </span>}

                        </Ax>
                        : <Ax>
                            <button type="button" disabled={!this.props.permissions.includes('customer-project-add')} className="btn btn-primary" onClick={this.openAddCustomerScreen}>Add </button>{
                              
                                    <button type="button" onClick={this.customerSearchFormInit} className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    // </Ax> 
                                }
                        </Ax>
                    }
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Customer Project : {this.state.customerViewDetails && this.state.customerViewDetails.project_name ? this.state.customerViewDetails.project_name : "-"} </h3>
                            <div className="text-end mt15">
                            
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{cursor : "pointer"}}>
                                    <li>
                                        <a role="button"
                                            onClick={() => {
                                                if (this.props.permissions.includes('customer-project-manage-contact')) {
                                                    return this.addContactInit()
                                                }
                                            }}
                                            className={['dropdown-item', this.props.permissions.includes('customer-project-manage-contact') ? '' : 'disabled'].join(' ')}
                                            title={!this.props.permissions.includes('customer-project-manage-contact') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Add Contact</a>
                                    </li>
                                    <li>
                                        <a role="button"
                                            onClick={() => {
                                                if (this.props.permissions.includes('customer-project-edit')) {
                                                    return this.openUpdateCustomerScreen(this.state.customerViewDetails?.id)
                                                }
                                            }}
                                            className={['dropdown-item', this.props.permissions.includes('customer-project-edit') ? '' : 'disabled'].join(' ')}
                                            title={!this.props.permissions.includes('customer-project-edit') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                            >Edit Customer</a>
                                    </li>

                                    <li>
                                        <a role="button"
                                            onClick={() => {
                                                if (this.props.permissions.includes('customer-project-change-status')) {
                                                    return this.changeStatusInit()
                                                }
                                            }}
                                            className={['dropdown-item', this.props.permissions.includes('customer-project-change-status') ? '' : 'disabled'].join(' ')}
                                            title={!this.props.permissions.includes('customer-project-change-status') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                            >Change Status</a>
                                    </li>
                                
                               
                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.customerListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr4 pl4">{this.customerViewDetails()}</div> : null }
                </div>
            </div>
        </Ax>);
    }

    customerListingTableJsx            =   ()   =>   {
        return(<Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.customerSearchClear}   /> 
            <table className="table table-bordered table-fixed  bg-white  table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Customer Project</th>
                        </tr>)
                        : (<tr className="text-center">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "14%" }}>Project Type</th>
                            <th scope="col" style={{ width: "17%" }}>Project Name</th>
                            <th scope="col" style={{ width: "17%" }}>Project Code</th>
                            <th scope="col" style={{ width: "17%" }}>Customer</th>
                            <th scope="col" style={{ width: "17%" }}>Location</th>
                            <th scope="col" style={{ width: "8%" }} className='text-center'>Status</th>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.customer_listing.length > 0
                            ? (this.state.customer_listing.map((customer, index) => {
                                return (<tr key={customer.id} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} onClick={() => this.viewCustomerDetail(customer.id)}>
                                                        <div className="text-capitalize link-primary cursor_pointer">{customer?.project_name}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">Code : {customer?.project_code??"-"} </small>
                                                            <small className="float-end">{customer.status ? customer.status.name : ''}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td >{customer.project_type ? customer.project_type.name : '' }</td>
                                                <td className='text-capitalize link-primary cursor_pointer'  onClick={() => this.viewCustomerDetail(customer.id)}>{customer.project_name}</td>
                                                <td>{customer.project_code ? customer.project_code : '' }</td>
                                                <td>{customer.customer ? customer.customer.name : '' }</td>
                                                <td>{customer.address && customer.address.state_name ? customer.address.state_name : '' }</td>
                                                <td>{customer.status ? customer.status.name : ''}</td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewCustomerDetail(customer.id)} >View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
        </Ax>)
    }

    customerViewDetails                =   ()   =>   {
        let customerDetails = this.state.customerViewDetails;
        let address     =   customerDetails && customerDetails.address ? customerDetails.address : null;
        return (<section className="item_view_container bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="address-tab" data-bs-toggle="tab" data-bs-target="#address"
                        type="button" role="tab" aria-controls="address" aria-selected="false">Address
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    {this.state.details_loading ? <Loader />
                        : (<div className="tab_content_header">
                            {customerDetails && <Ax>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Details</span>
                                </div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Customer Name</td>
                                            <th style={{ "width": "66%" }}>{customerDetails.customer.name ? customerDetails.customer.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Project Name</td>
                                            <th style={{ "width": "66%" }}>{customerDetails.project_name ? customerDetails.project_name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Code</td>
                                            <th style={{ "width": "66%" }}>{customerDetails.project_code ? customerDetails.project_code : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Status</td>
                                            <th style={{ "width": "66%" }}>{customerDetails.status ? customerDetails.status.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Notes</td>
                                            <th style={{ "width": "66%" }}>{customerDetails.notes ? customerDetails.notes : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Project Details</span>
                                </div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Project Type</td>
                                            <th style={{ "width": "66%" }}>{customerDetails.project_type ? customerDetails.project_type.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Start Date</td>
                                            <th style={{ "width": "66%" }}>{customerDetails.start_date ? DateService.dateTimeFormat(customerDetails.start_date, 'DD-MMM-YYYY') : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>End Date</td>
                                            <th style={{ "width": "66%" }}>{customerDetails.end_date ? DateService.dateTimeFormat(customerDetails.end_date, 'DD-MMM-YYYY') : "-"}</th>
                                        </tr>

                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper align-items-center">
                                    <span className="content_heading">Contact Details </span>
                                </div>
                                <table className="table table-hover table-bordered my-3 table-sm">
                                    <thead className="table-secondary" >
                                        <tr>
                                            <th style={{ width: "5%" }} className='text-center'>S.No</th>
                                            <th style={{ width: "30%" }}>Name</th>
                                            <th style={{ width: "15%" }}>Designation</th>
                                            <th style={{ width: "15%" }}>Email ID</th>
                                            <th style={{ width: "15%" }} className='text-center'>Mobile Number</th>
                                            <th className='text-center' style={{ width: "15%" }}>Primary Contact</th>
                                            {this.props.permissions.includes('customer-project-manage-contact') && <th style={{ width: "5%" }}>Action</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerDetails?.contacts?.length > 0 ? customerDetails?.contacts?.map((c, i) => {
                                            return (<tr>
                                                <td className='text-center'>{i + 1}</td>
                                                <td>{c.manager_name}</td>
                                                <td> {c.designation ?? "-"}</td>
                                                <td> {c.email ?? "-"}</td>
                                                <td className='text-center'> {c.mobile ?? "-"}</td>
                                                <td className='text-center'> {c.primary && c.primary == 'Y' ? "Yes" : "No"}</td>
                                                {this.props.permissions.includes('customer-project-manage-contact') &&
                                                       <td className="text-center">
                                                     <Ax>
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" role="button" onClick={() => this.editContact(c.id)}>Edit</a></li>
                                                                <li><a className={`dropdown-item ${customerDetails.contacts.length === 1 ? "disabled" : ""}`} role="button" onClick={() => this.deletePrimaryContact(c.id)}>Delete</a></li>
                                                               {customerDetails && customerDetails.contacts && customerDetails.contacts.length > 1 && <li><a className="dropdown-item" role="button" onClick={() => this.setPrimaryContact(c.id)}>Set to Primary</a></li>}
                                                            </ul>
                                                        </Ax>
                                                    
                                                </td>}
                                            </tr>)
                                        })
                                            : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                                    </tbody>
                                </table>
                            </Ax>}
                        </div>
                        )}
                </div>
                <div className="tab-pane" id="address" role="tabpanel" aria-labelledby="address-tab">
                    {this.state.details_loading ? <Loader />
                        : (<div className="tab_content_header">

                           {customerDetails && address && <div className="card my-2">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className='col-sm-5'>{address.label ? address.label : "-"}</div>
                                       
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='py-1'>Address : <b>{address.address1 ? address.address1 + ", " : ""}
                                        {address.address2 ? address.address2 + ", " : ""}
                                        {address.landmark ? address.landmark + ", " : ""}
                                        {address.city ? address.city + ". " : "-"}</b></div>
                                    <div className='py-1'>District, State & Pincode : <b>{address.district_name ? address.district_name + ", " : "- , "}
                                        {address.state_name ? address.state_name : " - "}
                                        {address.pincode ? " & " + address.pincode : ""}</b></div>
                                    <div className='py-1'>GSTIN : <b>{address.gstin ? address.gstin : "- "}</b></div>
                                </div>
                            </div>}
                        </div>)}
                </div>
            </div>
        </section>)
    }

    //*********************************ADD CONTACT JSX*************************
    addContactModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="addContactModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">{this.state.editContactDetails ? " Edit" : "Add"} Contact</h5>
                        </div>
                        <form onSubmit={this.addContactFormSubmit} id="addContactForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Name</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="manager_name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addContactForm.manager_name}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            maxLength="200"
                                            placeholder="Please Enter Name"
                                        />
                                    </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Designation</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="designation"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addContactForm.designation}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            maxLength="200"
                                            placeholder="Please Enter Designation"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Email ID</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="email"
                                            type="email"
                                            className="form-control"
                                            autoComplete="off"
                                            maxLength="200"
                                            value={this.state.addContactForm.email}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            placeholder="Please Enter Person Email ID"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label"> Mobile Number </label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="mobile"
                                            type="number"
                                            className="form-control"
                                            autoComplete="off"
                                          
                                            value={this.state.addContactForm.mobile}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            placeholder="Please Enter Person Mobile Number"
                                            pattern="[0-9.]+"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addContactFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={()=>{this.setState({addContactForm : {...this.initContactForm}, editContactDetails : false})}}>Close</button>
                                <button type="submit" disabled={this.state.addContactFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addContactFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            );
    }

    
    customerBulkUploadModalJsx            =   ()  =>  {
        return (
            <div className="modal fade" id="customerBulkUploadModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemBulkUploadModalLabel">Bulk Add Customer</h5>
                            <button type="button" disabled={this.state.customerBulkUploadFormSubmiting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.customerBulkUploadFormSubmit} id="customerBulkUploadForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-12">
                                        <input
                                            name="document"
                                            id="addCustomerFileSelector"
                                            type="file"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 mt-2 form-text"># Excel Sheet with Extention xls,xlm,xlsx,ods Allowed</div>
                                    <div className="col-12 mt-2 form-text"># Miximum 100 MB Size of Excel Sheet Allowed</div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <div><a type="button" className='float-left' href='#' onClick={this.downloadSampleFile}>Download Sample File</a></div>
                                <div>
                                    <button type="button" disabled={this.state.customerBulkUploadFormSubmiting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.customerBulkUploadFormSubmiting} className="btn btn-primary">
                                        Submit {this.state.customerBulkUploadFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    searchCustomerModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="searchCustomerModal" tabIndex="-1">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">Search Customer Project</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.customerSearchFormSubmit} id="searchCustomerForm">
                            {this.state.search_form_loading ? <Loader />
                                : <div className="modal-body">
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Customer Project Name</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_project_name"
                                                type="text"
                                                value={this.state.customerSearchForm.search_project_name}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "customerSearchForm")}
                                                placeholder="Please enter Customer Project Name" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Customer Project Code</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_project_code"
                                                type="text"
                                                value={this.state.customerSearchForm.search_project_code}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "customerSearchForm")}
                                                placeholder="Please enter Customer Project Code" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Project Type</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'customerSearchForm', 'search_project_types')}
                                                options={this.state.allProjectTypes}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Project Types"
                                                value={this.state.allProjectTypes.filter(t => this.state.customerSearchForm.search_project_types.includes(t.value))}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Customer</label>
                                        <div className="col-sm-10">
                                        <InputCustomerSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'customerSearchForm', 'search_customer_ids');
                                                this.setState({ searchedCustomers: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.searchedCustomers.length > 0 ? this.state.searchedCustomers.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    
                                        </div>

                                    </div>
                                    {/* <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">State</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'customerSearchForm', 'search_state_ids')}
                                                options={this.state.allSearchStatesList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select States"
                                                value={this.state.allSearchStatesList.filter(t => this.state.customerSearchForm.search_state_ids.includes(t.value))}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div> */}
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">GSTIN</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_gstin"
                                                type="text"
                                                value={this.state.customerSearchForm.search_gstin}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "customerSearchForm")}
                                                placeholder="Please enter GSTIN" />
                                        </div>

                                    </div>
                                  
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Status</label>
                                        <div className="col-sm-10">
                                        <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'customerSearchForm', 'search_status')}
                                                options={this.state.allStatusList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select Customer Status"
                                                value={this.state.allStatusList.filter(t => this.state.customerSearchForm.search_status.includes(t.value))}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">
                                    Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            );
    }

    submitChangeStatusForm          =       (e)      =>      {
        e.preventDefault();
        this.setState({saveFormSubmitting : true})
        
           HttpAPICall.withAthorization('PUT', this.customerChangeStatusUrl, this.props.access_token, {}, { ...this.state.changeStatusForm }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.viewCustomerDetail(this.state?.customerViewDetails?.id);
               this.loadListingTblData(1);
               this.changeStatusModal.hide();
               this.setState({changeStatusForm : {...this.initChangeStatusInit}})
           }).then(() => this.setState({ saveFormSubmitting : false }));
    }


    changeStatusModalJsx            =       ()      =>      {
        return( <div className="modal fade" id="changeStatusModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="changeStatusModalLabel">Change Status</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className='p-1 bg-light border-bottom'>
                        <table className='table table-sm mb-0 table-borderless text-sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: "26%" }}>Customer Project Name</td>
                                    <th>{this.state.customerViewDetails && this.state.customerViewDetails.project_name ?this.state.customerViewDetails.project_name  : ''}</th>
                                </tr>
                                <tr>
                                    <td>Customer Project Code </td><th>{this.state.customerViewDetails && this.state.customerViewDetails.project_code ?this.state.customerViewDetails.project_code  : ''}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                   
                    <form className="bg-white" onSubmit={this.submitChangeStatusForm} id="changeStatusForm">

                    <div className="my-3 row">
                        <label className="col-sm-3 col-form-label">Change status to</label>
                        <div className="col-sm-5">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'changeStatusForm', 'status');
                                }}
                                value={this.state.allStatusList.find(s => s.value == this.state.changeStatusForm.status)}
                                options={this.state.allStatusList}
                                isSearchable={false}
                                placeholder="Please Select Status"
                            />
                        </div>
                    </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            >Close  </button>
                           <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Update {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Customer Project Listing</title></Helmet>
            {this.customerListjsx()}
            {this.addContactModalJsx()}
            {this.customerBulkUploadModalJsx()}
            {this.searchCustomerModalJsx()}
            {this.changeStatusModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions          :   state.app && state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [] ,
    };
};

export default connect(mapStateToProps)(CustomerProjectList);