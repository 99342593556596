import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Loader from '../../components/ui/Loader/Loader';
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../components/TapUi';
import { useSelector } from 'react-redux';
import TapIcon from '../../services/TapIcon';
import Ax from '../../components/hoc/Ax';
import SiteProductivityDetails from './SiteProductivityDetails';
import swal from "sweetalert"
import { toast } from 'react-toastify';
import TapSelect from '../../components/ui/TapSelect';
import moment from 'moment';
import InputEmployeeSearch from '../includes/ui/InputEmployeeSearch';
import InputWorkstationSearch from '../includes/ui/InputWorkstationSearch';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import uuid from 'react-uuid';
import download from '../../services/DownloadFile';

const SiteProductivityList = forwardRef((props, ref, onDelete) => {
    
    const [listingData, setListingData] = useState([]);
    const [listingMeta, setListingMeta] = useState(null);
    const [listing_loading, setListing_loading] = useState(true);
    const [minimizeTable, setMinimizeTable] = useState(false);
    const [view_loading, setView_loading] = useState(false);
    const [download_loading, setDownloadLoading] = useState(false);
    const [productivitySearchInitState, setProductivitySearchInitState] = useState({
        'search_transaction_id': '',
        'search_activity_ids': [],
        'search_activity_code': '',
        'search_units': [],
        'search_material_ids': [],
        'search_attachment_ids': [],
        'site_group_ids': [],
        'search_site_ids': [],
        'search_employee_ids': [],
        'search_workstation_ids': [],
        'search_account_code_ids': [],
        'date_range': '',
        'date_range_start': null,
        'date_range_end': null,
        'entry_date_range': '',
        'entry_date_range_start': null,
        'entry_date_range_end': null,
    })

    const [productivitySearchFormLabel, setProductivitySearchFormLabel] = useState({
        'site_group_ids': 'Site Groups : ',
        'search_site_ids': 'Site : ',
        'search_activity_ids': 'Activity : ',
        'search_activity_code': 'Activity Code: ',
        'search_units': 'Production Unit : ',
        'search_material_ids': 'Material : ',
        'search_attachement_ids': 'Attachment : ',
        'search_employee_ids': 'Employee : ',
        'search_workstation_ids': 'Workstation : ',
        'search_account_code_ids': 'Account Code : ',
        'search_transaction_id': 'Transaction ID : ',
        'date_range': 'Transaction Period : ',
        'entry_date_range': 'Date of Entry : ',
    });
    const [formSearchedElems, setFormSearchedElems] = useState([]);
    const [productivitySearchParams, setProductivitySearchParams] = useState(null);
    const [iam_group_sites, setIam_group_sites] = useState([]);
    const [allActivitesList, setAllActivitesList] = useState([]);
    const [allMaterialList, setAllMaterialList] = useState([]);
    const [allProductionUnitList, setAllProductionUnitList] = useState([]);
    const [allSiteGroupsList, setAllSiteGroupsList] = useState([]);
    const [all_periods, setAll_periods] = useState([]);
    const [allWorkstations, setAllWorkstations] = useState([]);
    const [allAttachments, setAllAttachments] = useState([]);
    const [allAccountCodesList, setAllAccountCodesList] = useState([]);
    const [searchEmployees, setSearchEmployees] = useState([]);
    const [searchedWorkstations, setSearchedWorkstations] = useState([]);
    const [productivitySearchForm, setProductivitySearchForm] = useState({ ...productivitySearchInitState });
    const [productivityData, SetProductivityData] = useState(null);
    const list_url = IAM_API_BASE_URL_2 + '/site_productivity/list';
    const detail_url = IAM_API_BASE_URL_2 + '/site_productivity/detail';
    const delete_url = IAM_API_BASE_URL_2 + '/site_productivity/delete';
    const list_formdata_url = IAM_API_BASE_URL_2 + '/site_productivity/list_form_data';
    const access_token = useSelector(state => state.auth.access_token);
    const all_periods_data = useSelector(state => state.app.all_periods || []);
    const iam_group_sites_data = useSelector(state => state.app.group_sites || []);
    const site_groups_data = useSelector(state => state.app.site_groups || []);
    const permissions = useSelector(state => state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [])


     const siteProductivityModalElem               =   useRef(null);
        const siteProductivityModal                   =   useRef(null);
    const [modalId, setModalId]             =   useState(uuid());


   useEffect(() => {
        siteProductivityModalElem.current         =   document.getElementById(modalId);
        siteProductivityModal.current             =   new Modal(siteProductivityModalElem.current, {keyboard: false, backdrop :false});

        // Cleanup on unmount
        return () => {
            if (siteProductivityModal.current) {
                siteProductivityModal.current.dispose();
            }
        };
    }, []);


 useImperativeHandle(ref, () => ({

        initModalHandler: (type) => {
            console.log(type);
            
            type === 'searchModal' ? openModalHandler() : deleteProductivityInit()
        }
    }));
    const openModalHandler = () => {
        if(siteProductivityModal) {
            siteProductivityModalOpen();
        }
    }
    const siteProductivityModalOpen       =   ()  => {
        if (siteProductivityModal.current) {
            siteProductivityModal.current.show();
        }
    }

    const siteProductivityModalClose      =   ()  => {
        if (siteProductivityModal.current) {
            siteProductivityModal.current.hide();
        }
    }
    useEffect(() => {
        if (props?.minimizeTable) {
            closeViewDetails();
        }
    }, [props?.minimizeTable]);

    const initalizeData = (props) => {

        let all_periods = [all_periods_data, { key: 'custom', display: 'Custom Date Range' }];

        setIam_group_sites(iam_group_sites_data ? iam_group_sites_data.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [])
        setAll_periods(all_periods)
        setAllSiteGroupsList(site_groups_data && site_groups_data.length > 0 ? site_groups_data.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [])


    }

    const searchFormDataInit = () => {

        HttpAPICall.withAthorization('GET', list_formdata_url, access_token, {})
            .then((response) => {
                let respData = response.data
                setAllActivitesList(respData && respData.activity_data && respData.activity_data.length > 0 ? respData.activity_data.map((td) => { return { value: td.id, label: td.activity_name } }) : [])
                setAllProductionUnitList(respData && respData.uom && respData.uom.length > 0 ? respData.uom.map((td) => { return { value: td.id, label: td.measuring_unit } }) : [])
                setAllMaterialList(respData && respData.materials && respData.materials.length > 0 ? respData.materials.map(a => { return ({ value: a.id, label: a.material_name }) }) : [])
                setAllWorkstations(respData && respData.workstation && respData.workstation.length > 0 ? respData.workstation.map(a => { return ({ value: a.id, label: a.workstation_name }) }) : [])
                setAllAccountCodesList(respData && respData.account_codes && respData.account_codes.length > 0 ? respData.account_codes.map(a => { return ({ value: a.id, label: a.name }) }) : [])
                setAllAttachments(respData && respData.attachments && respData.attachments.length > 0 ? respData.attachments.map(a => { return ({ value: a.id, label: a.name }) }) : [])
            })
    }
    const viewProductivityDetail = (id) => {
        if (props?.runBy && props.runBy === 'configuration') {
            props.handleTags('site_productivity_detail')
        }
        setMinimizeTable(true)
        setView_loading(true)
        HttpAPICall.withAthorization('GET', detail_url + '/' + id, access_token, {}, {}, (resp) => {
            let respData = resp.data
            SetProductivityData(respData.data);
            if(props?.runBy && props.runBy === 'configuration') {
                props.productivityDataHandle(respData.data);
            }
            setView_loading(false)
        }).catch(() => {
            setMinimizeTable(false);
            setView_loading(false)
        });
    };

    const loadListingTblData = (page = 1) => {
        setListing_loading(true);
       let  search_configuration_id =""

       if(props?.configurationData?.id){
        search_configuration_id=props.configurationData.id
       }

        HttpAPICall.withAthorization('GET', list_url, access_token, { page, ...productivitySearchParams ,search_configuration_id}, {}, (resp) => {
            let respData = resp.data;
            setListingData(respData.data)
            setListingMeta(respData.meta)
        }).then(() => setListing_loading(false));
    }
    const closeViewDetails = () => {
        console.log(5454);

        setMinimizeTable(false)
    }
    useEffect(() => {
        loadListingTblData(1);
        if (props.location && props.location.state && props.location.state.Id) {
            viewProductivityDetail(props.location.state.Id);
        }
        searchFormDataInit();
        initalizeData(props);
    }, [productivitySearchParams]);
    useEffect(() => {
        loadListingTblData(1);
    }, [productivitySearchParams]);


    const deleteProductivityInit = () => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', delete_url + '/' + productivityData?.id, access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    setMinimizeTable(false);
                    loadListingTblData(1);
                });
            }
        });
    };
    const productivitySearchModalInit = () => {
        siteProductivityModalOpen();
    }
  
    const productivitySearchClear = () => {
        setFormSearchedElems([])
        setProductivitySearchParams(null)
        setProductivitySearchForm({ ...productivitySearchInitState })
        siteProductivityModalClose()
        loadListingTblData(1)

    }
    const tapSelectChange = (selectedOption, formName, fieldName) => {
        setProductivitySearchForm((prev) => ({
            ...prev,
            [fieldName]: selectedOption.map(o => o.value),
        }));
    };

    const productivitySearchFormSubmit = (event = null) => {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys = Object.keys(productivitySearchForm);
        let searchedElems = [];
        let searchParams = {};
        serachFormKeys.map((key) => {
            let label = productivitySearchFormLabel[key];
            let value = productivitySearchForm[key];
            if (value && value !== "Invalid date" && value.length > 0) {
                searchParams[key] = value;
                if (label) {
                    let show_val = value;

                    if (key == 'site_group_ids') {
                        show_val = allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if (key == 'search_site_ids') {
                        show_val = iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_activity_ids') {
                        show_val = allActivitesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_units') {
                        show_val = allProductionUnitList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_material_ids') {
                        show_val = allMaterialList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_workstation_ids') {
                        show_val = searchedWorkstations ? searchedWorkstations.map(s => s.label).join(', ') : '';
                    }
                    // if(key == 'search_workstation_ids') {
                    //     show_val            =  allWorkstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    // }
                    if (key == 'search_attachment_ids') {
                        show_val = allAttachments.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_account_code_ids') {
                        show_val = allAccountCodesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_transaction_id') {
                        show_val = productivitySearchForm && productivitySearchForm.search_transaction_id ? productivitySearchForm.search_transaction_id : '';
                    }
                    if (key == 'search_activity_code') {
                        show_val = productivitySearchForm && productivitySearchForm.search_activity_code ? productivitySearchForm.search_activity_code : '';
                    }
                    if (key == 'search_employee_ids') {
                        show_val = searchEmployees ? searchEmployees.map(s => s.label).join(', ') : '';
                    }


                    if (key == 'date_range') {
                        let start_range = moment(productivitySearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(productivitySearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val = all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if (key == 'entry_date_range') {
                        let start_range = moment(productivitySearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(productivitySearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val = all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }

                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-

        setFormSearchedElems(searchedElems)
        setProductivitySearchParams(searchParams);
        siteProductivityModalClose()
        loadListingTblData(1)

    }

    const downloadExcel =   () => {
        setDownloadLoading(pre => !pre);
        const date_range    =   productivitySearchInitState.date_range ? productivitySearchInitState.date_range : 'current_month';
        const params        =   {download : "download", ...productivitySearchInitState, date_range};
        HttpAPICall.withAthorization('GET', list_url, access_token, params, {}, (resp) => {
            download.file(resp.data.file_path);
        }).then(() => setDownloadLoading(pre => !pre));

    }

    const path = window.location.pathname;
    const heightStyle = path === '/site_productivity' ? 'calc(100vh - 120px)' : 'calc(100vh - 170px)';
    const heightStyleDetails = path === '/site_productivity' ? 'calc(100vh - 150px)' : 'calc(100vh - 200px)';
    const headershow = path === '/site_productivity' ? 'd-flex' : 'd-none';
    const headerpadding = path === '/site_productivity' ? 'pl5' : 'pl2 pr2';
    return (<>
        <div className={`container-fluid pl2 pr2 overflow-hidden ${headerpadding}`} >
            <div className={`page_title row m0 ${headershow}`}>
                <div className={minimizeTable ? "col-3" : "col-12"}>
                    <h3>Site Productivity</h3>
                    {!minimizeTable && (
                        <div className="text-end mt15">
                            <Link to="/site_productivity/add">
                                <button type="button"
                                    disabled={listing_loading || !permissions?.includes('site-productivity-add')} className="btn btn-primary">
                                    Add
                                </button>
                            </Link>
                            {/* <Link to="/site_productivity/add_new">
                                <button type="button"
                                    disabled={listing_loading || !permissions?.includes('site-productivity-add')} className="btn btn-primary">
                                    Add New
                                </button>
                            </Link> */}
                            <button type="button" disabled={listing_loading || download_loading} className="btn btn-secondary" onClick={downloadExcel}>
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                                {download_loading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}
                            </button>
                            <button type="button" className="btn btn-secondary"
                                onClick={productivitySearchModalInit}
                                disabled={listing_loading}>
                                <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                        </div>
                    )}
                </div>
                {minimizeTable && (
                    <div className="col-9 tap_border_left">
                        <h3>Site Productivity : {productivityData?.activity_name} ({productivityData?.activity_code})</h3>
                        <div className="text-end mt15">
                            <Link
                                to={{
                                    pathname: "/site_productivity/add",
                                    state: { productivityData: productivityData }
                                }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    disabled={!permissions?.includes('site-productivity-edit')}
                                >
                                    Edit
                                </button>
                            </Link>
                            <button type="button" className="btn btn-secondary" disabled={!permissions.includes('site-productivity-delete')}
                                onClick={deleteProductivityInit}
                            >
                                Delete
                            </button>
                            <button type="button" className="btn btn-secondary"
                                onClick={closeViewDetails}
                            >
                                <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div className="page_containt row" style={{ height: heightStyle }}>
                <div style={{ overflowY: "auto", height: "100%", overflowX: "hidden" }} className={['pageTbl', minimizeTable ? 'col-3' : 'col-12'].join(' ')}>
                    <DisplaySearchCriteria searchedElems={formSearchedElems} onClearFilter={productivitySearchClear} />
                    <table className="table table-bordered table-fixed bg-white table-sm" style={{ backgroundColor: "white" }}>
                        <thead className="table-secondary">
                            {minimizeTable ? (
                                <tr>
                                    <th scope="col" style={{ width: "15%" }}>Site Productivity</th>
                                </tr>
                            ) : (
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                                    <th scope="col" style={{ width: "10%" }}>Transaction ID</th>
                                    <th scope="col" style={{ width: "10%" }}>Transaction Date</th>
                                    <th scope="col" style={{ width: "18%" }}>Activity Name</th>
                                    <th scope="col" style={{ width: "18%" }}>Activity Code</th>
                                    <th scope="col" style={{ width: "18%" }}>Sites</th>
                                    <th scope="col" style={{ width: "13%" }} className="text-center">Production</th>
                                    <th scope="col" style={{ width: "8%" }} className="text-center">Action</th>
                                </tr>
                            )}
                        </thead>
                        <tbody>
                            {listing_loading
                                ? (<tr><td colSpan="7"><Loader /></td></tr>)
                                : (listingData && listingData.length > 0
                                    ? (listingData.map((item, index) => {
                                        return (<tr key={index} >
                                            {
                                                minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td style={{ width: "25%" }}>
                                                                <div className="text-capitalize link-primary cursor_pointer" onClick={() => viewProductivityDetail(item.id)}>{item.transaction_id ? item.transaction_id : "-"}</div>
                                                                <div className="mt-1">
                                                                    <small className="text-capitalize ">{item.activity_name ? item.activity_name : "-"} </small>
                                                                    <small className="float-end">{item.site_detail ? item.site_detail.site_name : ""}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    : (<Ax>
                                                        <td className="text-center">{listingMeta ? listingMeta.from + index : index}</td>
                                                        <td className='text-center link-primary cursor_pointer' onClick={() => viewProductivityDetail(item.id)}>{item.transaction_id ? item.transaction_id : ""}</td>
                                                        <td className="text-center">{item.transaction_date_display ? item.transaction_date_display : ""}</td>
                                                        <td className='text-capitalize '>{item.activity_name ? item.activity_name : "-"}</td>
                                                        <td className='text-capitalize '>{item.activity_code ? item.activity_code : "-"}</td>

                                                        {/* <td>{item.uom ? item.uom : ""}</td> */}
                                                        <td>{item.siteData ? item.siteData.site_name : ""}</td>
                                                        <td className="text-center">{item.production ? item.production : ""} {item.uom_name ? item.uom_name : ""}</td>
                                                        <td className="text-center">
                                                            <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" role="button" onClick={() => viewProductivityDetail(item.id)} >View</a></li>
                                                            </ul>
                                                        </td>


                                                    </Ax>
                                                    )}
                                        </tr>)
                                    }))
                                    : (<tr><td colSpan="7" className="text-center">No Records</td></tr>)
                                )
                            }
                        </tbody>
                    </table>
                    <DisplayListPagination
                        meta={listingMeta}
                        onPageChange={(e) => loadListingTblData(e.selected + 1)}
                    />
                </div>
                {minimizeTable ? <div className="col-9 pr2 pl4" >
                    <div className="bg-white p-2" style={{ backgroundColor: "white", height: "100%" }} >
                        {view_loading ?
                            <Loader />
                            :
                            <div className='tab_content_header' style={{ height: heightStyleDetails }} >
                                <SiteProductivityDetails productivityData={productivityData} />
                            </div>
                        }

                    </div></div> : null}
            </div>
            <div className="modal fade" id={modalId} tabIndex="-1" ref={siteProductivityModalElem}>
                <div className="modal-dialog modal-lg modal-dialog-scrollable ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="productivitySearchModalLabel">Productivity Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <form onSubmit={productivitySearchFormSubmit} id="productivitySearchForm">

                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={productivitySearchForm.search_transaction_id}
                                            onChange={(e) => setProductivitySearchForm({ ...productivitySearchForm, search_transaction_id: e.target.value })}
                                        />
                                    </div>
                                    <label className="col-sm-1 col-form-label col-form-label-sm text-end">Code</label>

                                    <div className="col-sm-5">
                                        <input
                                            type="text"
                                            name="search_activity_code"
                                            className="form-control form-control-sm"
                                            placeholder="Activity Code"
                                            autoComplete="off"
                                            value={productivitySearchForm.search_activity_code}
                                            onChange={(e) => setProductivitySearchForm({ ...productivitySearchForm, search_activity_code: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Activity Name</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={allActivitesList}
                                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivitySearchForm', 'search_activity_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={allActivitesList.filter(s => productivitySearchForm.search_activity_ids.includes(s.value))}
                                            placeholder="Select Activity"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-1 col-form-label col-form-label-sm text-end">UOM</label>
                                    <div className="col-sm-5">
                                        <TapSelect
                                            options={allProductionUnitList}
                                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivitySearchForm', 'search_units')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={allProductionUnitList.filter(s => productivitySearchForm.search_units.includes(s.value))}
                                            placeholder="Unit of Measurement"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Group</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={allSiteGroupsList}
                                            changeEvent={(selectedOption) => {

                                                setProductivitySearchForm({
                                                    ...productivitySearchForm,
                                                    site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                    search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                })

                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={allSiteGroupsList.filter(s => productivitySearchForm.site_group_ids.includes(s.value))}
                                            placeholder="Select Site Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">

                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={iam_group_sites}
                                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivitySearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={iam_group_sites.filter(s => productivitySearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>


                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Material</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={allMaterialList}
                                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivitySearchForm', 'search_material_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={allMaterialList.filter(s => productivitySearchForm.search_material_ids.includes(s.value))}
                                            placeholder="Select Material"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Attachment</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={allAttachments}
                                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivitySearchForm', 'search_attachment_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={allAttachments.filter(s => productivitySearchForm.search_attachment_ids.includes(s.value))}
                                            placeholder="Select Attachment"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Workstation</label>
                                    <div className="col-sm-10">
                                        <InputWorkstationSearch
                                            placeholder="Please Select Workstation"
                                            changeEvent={(selectedOption) => {
                                                tapSelectChange(selectedOption, 'productivitySearchForm', 'search_workstation_ids');
                                                setSearchedWorkstations(selectedOption && selectedOption.length > 0 ? selectedOption : []);

                                            }}
                                            menuPlacement="top"
                                            isMulti={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={searchedWorkstations.length > 0 ? searchedWorkstations.map(a => { a.label = a.label; return a; }) : []}
                                        />
                                        {/* <TapSelect
                                            options={allWorkstations}
                                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivitySearchForm', 'search_workstation_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={allWorkstations.filter(s => productivitySearchForm.search_workstation_ids.includes(s.value))}
                                            placeholder="Select Workstations"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        /> */}
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Accounting Code</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={allAccountCodesList}
                                            changeEvent={(selectedOption) => tapSelectChange(selectedOption, 'productivitySearchForm', 'search_account_code_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={allAccountCodesList.filter(s => productivitySearchForm.search_account_code_ids.includes(s.value))}
                                            placeholder="Select Accounting Codes"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Employees</label>
                                    <div className="col-sm-10">
                                        <InputEmployeeSearch
                                            changeEvent={(selectedOption) => {

                                                tapSelectChange(selectedOption, 'productivitySearchForm', 'search_employee_ids');

                                                setSearchEmployees(selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                );
                                            }}
                                            isMulti={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            placeholder="Select Employee"
                                            value={searchEmployees.length > 0 ? searchEmployees.map(a => { a.label = a.label; return a; }) : []}
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={all_periods ? all_periods : []}
                                            value={productivitySearchForm.entry_date_range}
                                            startDate={productivitySearchForm.entry_date_range_start}
                                            endDate={productivitySearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                setProductivitySearchForm
                                                    ({
                                                        ...productivitySearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    })

                                            }}
                                        />

                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={all_periods ? all_periods : []}
                                            value={productivitySearchForm.date_range}
                                            startDate={productivitySearchForm.date_range_start}
                                            endDate={productivitySearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {

                                                setProductivitySearchForm
                                                    ({
                                                        ...productivitySearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                    );
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" form="productivitySearchForm" >Search</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </>
    );
});


export default SiteProductivityList;


