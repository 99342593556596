import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import DateService from '../../../services/DateService';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapHelper from '../../../services/TapHelper';
import TapIcon from '../../../services/TapIcon';
import PageHeader from '../../includes/PageHeader';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import DownloadFile from '../../../services/DownloadFile';

const FuelConsumptionFuelTankReport         =   forwardRef((props, ref) => {
    
    let initListData                        =   {
        loading                                 :   false,
        data_loaded                             :   false,
        show                                    :   false,
        items                                   :   [],
        meta                                    :   null,
        page                                    :   1
    };
    
    let initFormData                        =   {
        show                                    :   true,
        per_page                                :   50,
        form_data_loaded                        :   false,
        loading                                 :   false,
        downloading                             :   false,
        submitting                              :   false,
        periods                                 :   [],
        site_groups                             :   [],
        selected_site_groups                    :   [],
        asset_type_groups                       :   [],
        selected_asset_type_groups              :   [],
        date_range                              :   null,
        date_range_start                        :   null,
        date_range_end                          :   null,
        reset_filter                            :   false,
        selected_assets                         :   [],
        search_asset_ids                        :   [],
        sites                                   :   [],
        asset_types                             :   [],
        asset_matrixs                           :   [],
        search_site_ids                         :   [],
        search_asset_type_ids                   :   [],  
        search_asset_matrix_ids                 :   [],
        search_asset_type                       :   [],   
        search_asset_matrix                     :   [],
    };

    let formDataLabel                       =   {
        search_asset_matrix_ids                 :   'Asset Matrix ',
        date_range                              :   'Date Range : ',
        selected_site_groups                    :   'Site Group  : ',
        search_site_ids                         :   'Sites ',
        selected_asset_groups                   :   'Asset Groups  : ',
        selected_asset_type_groups              :   'Asset Type Group  : ',
        search_asset_type_ids                   :   'Asset Type ',
    };

    const access_token                      =   useSelector(state => state.auth.access_token);
    const permissions                       =   useSelector(state => state.app.acl_info && state.app.acl_info.permissions ? state.app.acl_info.permissions : []);
    const all_periods                       =   useSelector(state => state.app.all_periods ? state.app.all_periods : []);
    const user_sites                        =   useSelector(state => state.app.user_sites ? state.app.user_sites : []);
    const asset_type                        =   useSelector(state => state.app.asset_type ? state.app.asset_type : []);
    const site_groups                       =   useSelector(state => state.app.site_groups ? state.app.site_groups : []);
    const asset_type_groups                 =   useSelector(state => state.app.asset_groups ? state.app.asset_groups : []);
    const asset_matrix                      =   useSelector(state => state.app.asset_matrix ? state.app.asset_matrix : []);

    const [itemsData, setItemsData]         =   useState({...initListData});
    const [formData, setFormData]           =   useState({...initFormData});
    const [searchedElems, setSearchedElems] =   useState([]);
    const [submittedFormData, setSubmittedFormData] =   useState({...initFormData});


    let itemListUrl                         =   IAM_API_BASE_URL_2 + "/report/fuel_consumption_based_on_tank_status";

    useEffect(() => {
        toggleFilterForm(true);
    }, []);

    useEffect(() => {
        if(formData.show && !formData.form_data_loaded) {
            loadFormData();
        }
    }, [formData.show]);

    const toggleFilterForm              =   (show)  => {
        setFormData(pd => ({...pd, show : show }));



        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
 
    }

    const loadFormData                  =   ()  => {
        setFormData(pd => ({...pd,
            form_data_loaded:   true,  
            loading         :   false,
            periods         :   [...all_periods , {key : 'custom' , display : 'Custom Date Range'}],
            asset_matrixs   :   asset_matrix.map((s) => { return {value: s.id, label: s.asset_matrix_unique_id}}),
            site_groups     :   site_groups.map((s) => { return {...s, value: s.id, label: s.name}}),
            sites           :   user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}),
            asset_type_groups:   asset_type_groups.map((s) => { return {...s, value: s.id, label: s.name}}),
            asset_types     :   asset_type.map((s) => { return {value: s.id, label: s.asset_type_name}}),
        }));
    }

    const closeReport                   =   () => props.history.push({pathname: `/report`})

    const submitSearchFormHandler       =   (e) => {
        e.preventDefault();

        toggleFilterForm(false);

        let searchedElems               =   [];
        let searchParams                =   {};
        Object.keys(formData).map((key)     =>  {
            let label                       =   formDataLabel[key];
            let value                       =   formData[key];
            if(value && value.length > 0) {
                searchParams[key]       =   value;
                if (label) {
                    let show_val        =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   formData.selected_assets ? formData.selected_assets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   formData.sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   formData.asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            =   formData.asset_matrixs.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'date_range') {
                        show_val            =   formData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    if (key == 'selected_site_groups') {
                        show_val            =   formData.site_groups.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        setSubmittedFormData(pd => ({...pd, ...formData}));
        setSearchedElems(searchedElems);
        loadItemsData(1);
    }

    const loadItemsData                 =   (page = 1)  =>  {
        setItemsData(pd => ({...pd, show : true,  data_loaded : false, loading : true, page : page}));
        let params                      =   {
                                                action : 'data',
                                                page : page,
                                                ...TapHelper.pick(formData, ['search_asset_type_ids', 'search_asset_matrix_ids', 'search_site_ids', 'search_asset_ids', 'date_range', 'date_range_start','date_range_end', 'search_asset_ids', 'search_site_ids'])
                                            };
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            let respData                =   response.data;
            let meta                    =   TapHelper.pick(respData, ['from', 'to', 'total', 'last_page','per_page','current_page']);
            setItemsData(pd => ({...pd, data_loaded : true, items : respData.data, meta : meta, sites : response.data.sites, }));
        }).then(() => {
            setItemsData(pd => ({...pd, loading : false}));
        });
    }

    const reportDownloadHandler         =   ()  =>  {
        let params                      =   {
            action : 'download',
            ...TapHelper.pick(formData, ['search_asset_type_ids', 'search_asset_matrix_ids', 'search_site_ids', 'search_asset_ids', 'date_range', 'date_range_start','date_range_end', 'search_asset_ids', 'search_site_ids'])
        };
        setItemsData(pd => ({...pd, downloading : true }));
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            DownloadFile.file(response.data.file_path)
        }).then(() => {
            setItemsData(pd => ({...pd, downloading : false}));
        });
    }

    const filterFormJsx                 =   ()  =>  {
        return <form onSubmit={submitSearchFormHandler} className='py-3'>
            <div className="row mb-3">
                <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                <div className="col-sm-6">
                    <InputAssetSearch
                        placeholder="Search Assets"
                        filter={true}
                        isMulti={true}
                        value={formData.selected_assets ? [...formData.selected_assets] : []}
                        changeEvent={(so) => {
                            setFormData(d => ({
                                ...d,
                                search_asset_ids: so ? so.map(s => s.value) : [],
                                selected_assets: so && so.length > 0 ? so.map(a => { a.label = a.display_label; return a; }) : []
                            }));
                        }} />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Site Group</label>
                <div className="col-sm-6">
                    <TapSelect
                        options={formData.site_groups}
                        changeEvent={so => {
                            setFormData(d => ({ ...d, 
                                selected_site_groups: so.map(e => e.value),
                                search_site_ids : so.length > 0 ? so.map(s => s.site_ids).flat() : []
                            }));
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={formData.site_groups.filter(s => formData.selected_site_groups.includes(s.value))}
                        placeholder="Select Site Group"
                    />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Site Name</label>
                <div className="col-sm-6">
                    <TapSelect
                        changeEvent={o => setFormData(d => ({ ...d, search_site_ids: o.map(e => e.value) }))}
                        placeholder="Select Sites"
                        isClearable={true}
                        options={formData.sites}
                        value={formData.sites.filter(s => formData.search_site_ids.includes(s.value))}
                        isMulti={true}
                    />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Asset type Group</label>
                <div className="col-sm-6">
                    <TapSelect
                        options={formData.asset_type_groups}
                        changeEvent={so => {
                            setFormData(d => ({ ...d, 
                                selected_asset_type_groups: so.map(e => e.value),
                                search_asset_type_ids : so.length > 0 ? so.map(s => s.asset_type_ids).flat() : []
                            }));
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={formData.asset_type_groups.filter(s => formData.selected_asset_type_groups.includes(s.value))}
                        placeholder="Asset type Group"
                    />
                </div>
            </div> 
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                <div className="col-sm-6">
                    <TapSelect
                        options={formData.asset_types}
                        changeEvent={o => setFormData(d => ({ ...d, search_asset_type_ids: o.map(e => e.value) }))}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={formData.asset_types.filter(s => formData.search_asset_type_ids.includes(s.value))}
                        placeholder="Select Asset Type"
                    />
                </div>
            </div>
            
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                <div className="col-sm-6">
                    <TapSelect
                        options={formData.asset_matrixs}
                        changeEvent={o => setFormData(d => ({ ...d, search_asset_matrix_ids: o.map(e => e.value) }))}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={formData.asset_matrixs.filter(s => formData.search_asset_matrix_ids.includes(s.value))}
                        placeholder="Select Asset Matrix"
                    />
                </div>
            </div>
            <div className="row my-3">
                <label className="col-sm-3 col-form-label text-end require">Transaction Period</label>
                <div className="col-sm-6">
                    <PeriodSelectionWithCustom
                        periods={formData.periods ? formData.periods : []}
                        className="form-control-sm"
                        onSelectPeriod={(period, startDate, endDate) => {
                            setFormData(d => ({ ...d,
                                date_range: period,
                                date_range_start: startDate ? startDate : null,
                                date_range_end: endDate ? endDate : null
                            }))
                        }}
                        required={true}
                        value={formData.date_range}
                        startDate={formData.date_range_start}
                        endDate={formData.date_range_end}
                    />
                </div>
            </div>
            <div className="row my-4  border-bottom">
                <div className="pb-3 col-sm-9 text-end">
                    <div className='d-flex justify-content-end align-items-center gap-2'>
                        <button type="submit" className="btn btn-primary px-4">Show Report</button>
                    </div>
                </div>
            </div>
        </form>
    }

    const voucherListJsx                =   ()  =>  {
        return <>
            <DisplaySearchCriteria searchedElems={searchedElems}  />
            <table className="table table-sm table-bordered align-middle bg-white">
                <thead className="align-middle table-secondary">
                    {itemsData.minimizeTable ? <tr className='text-center'>
                        <th scope="col">Sales Invoice</th>
                    </tr>: 
                    <tr className='text-center'>
                        <th style={{ width: "3%" }}>S.No</th>
                        <th style={{ width: "8%" }}>Asset</th>
                        <th style={{ width: "8%" }}>Asset Code</th>
                        <th style={{ width: "8%" }}>Current Site</th>
                        <th style={{ width: "8%" }}>Asset Meter</th>
                        <th style={{ width: "16%" }}>Previous Fuel Feed-in</th>
                        <th style={{ width: "16%" }}>Current Fuel Feed-in</th>
                        <th style={{ width: "8%" }}>Running </th>
                        <th style={{ width: "10%" }}>Total Fuel Issued </th>
                        <th style={{ width: "8%" }}>Fuel Consumption</th>
                        <th style={{ width: "8%" }}>Fuel Economy</th>
                    </tr>}
                </thead>
                <tbody>
                {itemsData.loading 
                    ? <tr><td className='text-center' colSpan={9}><Loader /></td></tr>
                    : ((itemsData.items && itemsData.items.length > 0) 
                        ? itemsData.items.map((item,index) => {
                            return <tr className={['text-center'].join(' ')} key={index}>
                                <td className='text-center'>{itemsData.meta ? itemsData.meta.from + index : index}</td>
                                <td className='text-center'>{item.asset_name}</td>
                                <td className='text-center'>{item.asset_code}</td>
                                <td className='text-center'>{item.site_name}</td>
                                <td className='text-end'>{item.asset_readings.map(r => <div>{r.label}</div>) }</td>
                                <td className='text-end'>{item.asset_readings.map(r => <div>{r.start}</div>) }</td>
                                <td className='text-end'>{item.asset_readings.map(r => <div>{r.end}</div>) }</td>
                                <td className='text-end'>{item.asset_readings.map(r => r.label != 'Date' && r.running && <div>{r.running_display} {r.unit}</div>) }</td>
                                <td className='text-end'>{item.fuel_consume}</td>
                                <td className='text-end'>{item.fuel_consumption}</td>
                                <td className='text-end'>{item.fuel_economy}</td>
                            </tr>
                        })
                        : <tr><td className='text-center' colSpan={9}>No Record</td></tr>  )
                }
                </tbody>
            </table>
            <DisplayListPagination meta={itemsData.meta} onPageChange={(e) => loadItemsData(e.selected + 1)} />
        </>
    }

    const topRightHeaderJsx             =   ()  =>  {
        return <>
            <div className="text-end mt-2">
                <button type="button" disabled={itemsData.downloading || itemsData.loading || formData.loading} className="btn btn-secondary" onClick={() => toggleFilterForm(!formData.show)} >
                    Change Filter
                </button>
                {itemsData.data_loaded && <button type="button" disabled={itemsData.downloading || itemsData.loading || formData.loading} className="btn btn-secondary" onClick={reportDownloadHandler}>
                    <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                    {itemsData.downloading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />}
                </button>}
                <button type="button"  className="btn btn-secondary mx-1" onClick={closeReport}>
                    <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                </button>
            </div>
        </>
    }

    return  <ApplicationLayout>
        <PageHeader title="Fuel Consumption Based on Fuel Tank Status" name="Fuel Consumption Based on Fuel Tank Status" rightTopHeadingJsx={topRightHeaderJsx()} />
        <div className="container-fluid pl5">
            <div className="page_containt row">
                <div id="pageTbl" className="pageTbl col-sm-12 pb-3">
                    <div className={["bg-white", formData.show ? 'tbShow' : 'tbHide' ].join(" ")} >
                        {formData.loading ? <Loader /> : filterFormJsx()}
                    </div>
                    {itemsData.show && voucherListJsx()}
                </div>
            </div>
        </div>
    </ApplicationLayout>

});

export default FuelConsumptionFuelTankReport;