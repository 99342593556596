import React from 'react';
import {connect} from "react-redux";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import ApplicationLayout from '../../layouts/ApplicationLayout';
import ReactPaginate from "react-paginate";
import Ax from "../../components/hoc/Ax";
import tapIcon from "../../services/TapIcon"
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from "../../components/ui/TapSelect";
import {Modal} from "bootstrap";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import TapApiUrls, { APP_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { DisplayListPagination } from '../../components/TapUi';
import { Link } from 'react-router-dom';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapIcon from '../../services/TapIcon';
import AssetPermission from './AssetPermission';
import AppPermission from './AppPermission';
import ReportPermission from './ReportPermission';


class RoleListing extends AppBaseComponent {
  
    constructor() {
        super();
     
        this.state = {
            minimizeTable           :   false,
            view_loading            :   false, 
            listing_loading         :   false,
            role_listing            :   [],
            totalListingCount       :   [],
            listing_tbl_page        :   1 ,
            formDataLoaded          :   false,
            form_loading            :   false,
            allRolesList            :   [],
            updateRole              :   {parent_role : '',role : '',id:''},
            updateVisibiltyForm     :   {visibility : '',role : '',id:''},
            updateLockingForm       :   {locking_applicable: 'N',transaction_locking: '',role : '',id:''},
            saveFormSubmitting      :   false,
            allVisibilityOptions    :   [{value:"site",label:"Site"},{value:"asset",label:"Asset"}],
            updateAssetPermission   :   {
                permission_asset        :   "N"
            },
            reportPermission        :   false,
        }
   }

    componentDidMount() {
        this.loadListingTblData(1);
        this.updateRoleModal             =        new Modal(document.getElementById('updateRoleModal'), {keyboard: false, backdrop :false});
        this.updateVisibilityModal       =        new Modal(document.getElementById('updateVisibilityModal'), {keyboard: false, backdrop :false});
        this.updateTransactionModal       =        new Modal(document.getElementById('updateTransactionModal'), {keyboard: false, backdrop :false});
        this.updateAssetPermissionModal       =   new Modal(document.getElementById('updateAssetPermissionModal'), {keyboard: false, backdrop :false});
    }

    loadListingTblData                 =   (page = 1,id=null)  =>         {
       
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,};
        HttpAPICall.withAthorization('GET',  APP_BASE_URL_2 + '/group/roles', this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                role_listing            :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
                
        },() => {
            if(id && this.state.role_listing && this.state.role_listing.length > 0){
               this.setState({
                 roleData                :           this.state.role_listing.find(rl => rl.id == id) 
               }) 
            }
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    addRoleInit         =       ()      =>      {
        this.props.history.push('/add_role')
    }

    viewRoleDetail      =       (id)   => {
        this.setState({minimizeTable : true})
        this.getRoleDetail(id)
    }

    getRoleDetail      =        (data)        =>      {
       setTimeout(() => {
            this.setState({
                view_loading : false
            })
       },1000)
       
       if(data) {
           this.setState({minimizeTable : true, roleData : data,view_loading : true });
       }
      
    }

    closeViewDetails        =   ()      =>      {
        this.setState({minimizeTable  : false})
    }

    updateRoleModalInit     =     (data)        =>      {
        this.updateRoleModal.show();
        if (!this.state.formDataLoaded) {
            this.getUpdateRoleFormData()
        }
      
        if (data) {
            this.setState({
                updateRole: { 
                    id          :   data.id,
                    parent_role :   data.reportee.id, 
                    role        :   data.role }
            })
        }
    }

    getUpdateRoleFormData       =   (e)      =>      {
        this.setState({form_loading : true})
        HttpAPICall.withAthorization('GET', APP_BASE_URL_2 + '/group/allroles', this.props.access_token, {}, {}, (resp) => {
           let respData = resp.data
           this.setState({
                formDataLoaded : true,
                allRolesList   :  respData && respData.data.length > 0 ? respData.data.map(rl => {return({value:rl.id,label:rl.role})}): [] 
            })
          }).then(() => this.setState({form_loading : false}))
    }

    submitUpdateRoleForm        =       (e)      =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', APP_BASE_URL_2 + '/group/role', this.props.access_token, {}, { ...this.state.updateRole }, (resp) => {
            toast.success(resp.data?.msg, { position: toast.POSITION.TOP_CENTER })
            this.updateRoleModal.hide()
            this.loadListingTblData(1, this.state.updateRole.id)

        }).then(() => this.setState({ saveFormSubmitting: false }))
    }

    updateVisibilityModalInit     =     (data)        =>      {
        this.updateVisibilityModal.show();
       
        if (data) {
            this.setState({
                updateVisibiltyForm: { 
                    id          :   data.id,
                    visibility  :   data.visibility, 
                    role        :   data.role }
            })
        }
    }
    

    submitUpdateVisibilityForm        =       (e)      =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', APP_BASE_URL_2 + '/group/role/visibility', this.props.access_token, {}, { ...this.state.updateVisibiltyForm }, (resp) => {
            toast.success(resp.data?.msg, { position: toast.POSITION.TOP_CENTER })
            this.updateVisibilityModal.hide()
            this.loadListingTblData(1, this.state.updateVisibiltyForm.id)
        }).then(() => this.setState({ saveFormSubmitting: false }))
    }

    updateTransactionModalInit     =     (data)        =>      {
        this.updateTransactionModal.show();
       
        if (data) {
            this.setState({
                updateLockingForm: { 
                    id                  :   data.id,
                    transaction_locking :   data.transaction_locking,
                    locking_applicable  :   data.transaction_locking == null ? "N" : "Y",
                    role                :   data.role }
            })
        }
    }

    submitUpdateLockingForm        =       (e)      =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', APP_BASE_URL_2 + '/group/role/transaction_locking', this.props.access_token, {}, { ...this.state.updateLockingForm }, (resp) => {
            toast.success(resp.data?.msg, { position: toast.POSITION.TOP_CENTER })
            this.updateTransactionModal.hide()
            this.loadListingTblData(1, this.state.updateLockingForm.id)
        }).then(() => this.setState({ saveFormSubmitting: false }))
    }

    deleteRole              =       (id)        =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', APP_BASE_URL_2 + '/group/role/'+ id, this.props.access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.loadListingTblData(1);
                    this.setState({minimizeTable : false})
                    
                });
            }
        });
    }

    updatePermissionAssetInit                 =   (id)  =>  {
        this.updateAssetPermissionModal.show();
    }

    submitUpdateAssetPermission         =       (e)     =>      {
        e.preventDefault();
        this.updateAssetPermissionModal.hide();
    }

    roleListjsx            =       ()      =>    {
      
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Role List</h3>
                <div className="text-end mt15">
                    <button type="button" disabled={this.state.listing_loading} onClick={this.addRoleInit} className="btn btn-primary" >Add </button>
                </div>
            </div>
            {
                this.state.minimizeTable
                ? (
                    <div className="col-9 tap_border_left">
                        <h3>Role : {this.state.roleData && this.state.roleData.role  ? this.state.roleData.role : "-"} </h3>
                        <div className="text-end mt15">
                            <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                            </button>
                        </div>
                    </div>
                )
                : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.roleListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl0">{this.state.view_loading ? <Loader /> : this.roleViewDetailJsx()}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    roleListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" style={{ height: "100%" }}>
        
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "20%" }}>Role</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center"> S.No</th>
                            <th scope="col" style={{ width: "20%" }}>Role</th>
                            <th scope="col" style={{ width: "20%" }}>Reportee Role</th>
                            <th scope="col" style={{ width: "20%" }}>Transaction Lock Duration</th>
                            <th scope="col" style={{ width: "20%" }}>Visibility</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="6"><Loader /></td></tr>)
                        : (this.state.role_listing && this.state.role_listing.length > 0
                            ? (this.state.role_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td>
                                                        <Link role="button" onClick={() => this.viewRoleDetail(item)} className="link-primary">{item.role}</Link>
                                                        <br />
                                                    </td>
                                                </Ax>
                                            )
                                            : (
                                                <Ax>
                                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                                    <td><Link role="button" onClick={() => this.viewRoleDetail(item)} className="link-primary">{item.role}</Link></td>
                                                    <td>{item?.reportee?.role ?? "-"}</td>
                                                    <td>{item?.transaction_locking ?? "-"}</td>
                                                    <td className="text-capitalize">{item?.visibility ?? "-"}</td>
                                                    <td >
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />

                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" role="button" onClick={() => this.viewRoleDetail(item)} >Permission</a></li>
                                                            <li> <Link role="button" onClick={() => {
                                                                if (item.can_update === "Y") {
                                                                    return this.updateRoleModalInit(item)
                                                                }
                                                            }}
                                                                className={['dropdown-item', item.can_update === "Y" ? '' : 'disabled'].join(' ')}
                                                                title={item.can_update === "Y" ? '' : 'You do not have permission to perform this action'}
                                                                style={{ "pointerEvents": "all" }}>Update Role</Link>
                                                            </li>
                                                            <li> <Link role="button" onClick={() => {
                                                                if (item.can_update === "Y") {
                                                                    return this.updateVisibilityModalInit(item)
                                                                }
                                                            }}
                                                                className={['dropdown-item', item.can_update === "Y" ? '' : 'disabled'].join(' ')}
                                                                title={item.can_update === "Y" ? '' : 'You do not have permission to perform this action'}
                                                                style={{ "pointerEvents": "all" }}>Update Visibility</Link>
                                                            </li>
                                                            <li> <Link role="button" onClick={() => {
                                                                if (item.can_update === "Y") {
                                                                    return this.updateTransactionModalInit(item)
                                                                }
                                                            }}
                                                                className={['dropdown-item', item.can_update === "Y" ? '' : 'disabled'].join(' ')}
                                                                title={item.can_update === "Y" ? '' : 'You do not have permission to perform this action'}
                                                                style={{ "pointerEvents": "all" }}>Set Trn Lock Period</Link>
                                                            </li>
                                                            <li> <Link role="button" onClick={() => {
                                                                if (item.can_update === "Y") {
                                                                    return this.deleteRole(item.id)
                                                                }
                                                            }}
                                                                className={['dropdown-item', item.can_update === "Y" ? '' : 'disabled'].join(' ')}
                                                                title={item.can_update === "Y" ? '' : 'You do not have permission to perform this action'}
                                                                style={{ "pointerEvents": "all" }}>Delete</Link>
                                                            </li>
                                                        </ul>

                                                    </td>
                                                </Ax>
                                            )
                                    }
                                </tr>)
                            }))
                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </div>)
    }

    roleViewDetailJsx                       =   ()  =>  {
        let role                           =   this.state.roleData;
        return (<section className="item_view_container bg-white">
            <div className="tab_content_header">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="permission-tab" data-bs-toggle="tab" data-bs-target="#permission"
                        type="button" role="tab" aria-controls="permission" aria-selected="false">Permission (Asset Management)
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="mobileapp-tab" data-bs-toggle="tab" data-bs-target="#mobileapp"
                        type="button" role="tab" aria-controls="mobileapp" aria-selected="false">Mobile App Visibility
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="report-tab" data-bs-toggle="tab" data-bs-target="#report"
                    type="button" role="tab" aria-controls="report" aria-selected="false" onClick={() => this.setState({reportPermission : true})} >Report Permission</button>
                </li>

            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel"
                    aria-labelledby="dashboard-tab">
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Details</span>
                        {
                            role && role.can_update == "Y" ?
                                <div className="float-end">
                                    <button className="btn btn-primary" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false">
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" role="button" onClick={() => this.updateRoleModalInit(role)}>Update Role</a></li>
                                        <li><a className="dropdown-item" role="button" onClick={() => this.deleteRole(role.id)}>Delete Role</a></li>
                                    </ul>
                                </div> : null
                        }
                    </div>
                    <table className="table table-hover table-borderless mt-2">
                        <tbody>
                            <tr>
                                <td className="details-label" style={{ "width": "34%" }}>Role Name </td>
                                <td className="details-name" style={{ "width": "66%" }}>{role?.role}</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{ "width": "34%" }}>Reportee Role </td>
                                <td className="details-name" style={{ "width": "66%" }}>{role?.reportee === null ? "NA" : role.reportee.role}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Asset Management</span>
                        {
                            role && role.can_update == "Y" ?
                                <div className="float-end">
                                    <button className="btn btn-primary" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false">
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" role="button" onClick={this.updatePermissionAssetInit}>Update Permission</a></li>
                                        <li><a className="dropdown-item" role="button" onClick={() => this.updateVisibilityModalInit(role)}>Update Visibility</a></li>
                                        <li><a className="dropdown-item" role="button" onClick={() => this.updateTransactionModalInit(role)}>Update Tr. Locking Period</a></li>

                                    </ul>
                                </div> : null
                        }
                    </div>
                    <table className="table table-hover table-borderless mt-2">
                        <tbody>
                            <tr>
                                <td className="details-label" style={{ "width": "34%" }}>Permission for Asset Management </td>
                                <td className="details-name text-capitalize" style={{ "width": "66%" }}>Yes</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{ "width": "34%" }}>Transaction Lock Duration </td>
                                <td className="details-name" style={{ "width": "66%" }}>{role?.transaction_locking === null ? "-" : role.transaction_locking}</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{ "width": "34%" }}>Visibility </td>
                                <td className="details-name text-capitalize" style={{ "width": "66%" }}>{role?.visibility}</td>
                            </tr>

                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Inventory Module</span></div>
                    <table className="table table-hover table-borderless mt-2">
                        <tbody>
                            <tr>
                                <td className="details-label" style={{ "width": "34%" }}>Permission for Inventory Module</td>
                                <td className="details-name text-capitalize" style={{ "width": "66%" }}>Yes</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{ "width": "34%" }}>Transaction Lock Duration </td>
                                <td className="details-name" style={{ "width": "66%" }}>-</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{ "width": "34%" }}>Visibility </td>
                                <td className="details-name" style={{ "width": "66%" }}>-</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className="tab-pane " id="permission" role="tabpanel" aria-labelledby="permission-tab">
                    <AssetPermission roleData={this.state.roleData}/>
                </div>
                <div className="tab-pane " id="mobileapp" role="tabpanel" aria-labelledby="mobileapp-tab">
                    <AppPermission roleData={this.state.roleData} />
                </div>
                <div className="tab-pane " id="report" role="tabpanel" aria-labelledby="report-tab">
                    {
                        this.state.reportPermission &&
                        <ReportPermission roleData={this.state.roleData} />
                    }
                </div>
            </div>
            </div>
        </section>);
    }

    updateRoleModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateRoleModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Role</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitUpdateRoleForm} id="updateRoleModalForm">
                            <div className="modal-body">
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Role Name</label></div>
                                    <div className="col-sm-8">
                                        <input
                                            name="role"
                                            type="text"
                                            className="form-control"
                                            value={this.state.updateRole.role}
                                            placeholder="Please Enter Name"
                                            onChange={(e) => this.formInputHandler(e, "updateRole")}/>
                                    </div>
                                </div>
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Reportee Role</label></div>
                                    <div className="col-sm-8">
                                        <TapSelect
                                            options={this.state.allRolesList.filter(r => r.value != this.state.updateRole.parent_role)}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Please Select Reportee Role"
                                            required={true}
                                            value={this.state.allRolesList.find(rl => this.state.updateRole.parent_role == rl.value)}
                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'updateRole', 'parent_role') }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                            <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updateVisibilityModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateVisibilityModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Visibility</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitUpdateVisibilityForm} id="updateVisibilityModalForm">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Role Name</label></div>
                                    <div className="col-sm-8">
                                        <input
                                            name="role"
                                            type="text"
                                            className="form-control"
                                            value={this.state.updateVisibiltyForm.role}
                                            placeholder="Please Enter Name"
                                            disabled
                                            onChange={(e) => this.formInputHandler(e, "updateVisibiltyForm")} />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Visibility</label></div>
                                    <div className="col-sm-8 text-capitalize" >
                                        <TapSelect
                                            options={this.state.allVisibilityOptions}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Please Select Visibility"
                                            required={true}
                                            value={this.state.allVisibilityOptions.find(rl => this.state.updateVisibiltyForm.visibility == rl.value)}
                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'updateVisibiltyForm', 'visibility') }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updateTransactionModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Transaction</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitUpdateLockingForm} id="updateRoleModal">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Role Name</label></div>
                                    <div className="col-sm-8">
                                        <input
                                            name="role"
                                            type="text"
                                            className="form-control"
                                            value={this.state.updateLockingForm.role}
                                            placeholder="Please Enter Name"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Locking   Applicable</label></div>
                                    <div className="col-sm-2">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="locking_applicable"
                                                type="radio"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, 'updateLockingForm')}
                                                checked={this.state.updateLockingForm.locking_applicable == "Y"}
                                                className="form-check-input"
                                                id="lockingApplicableEnable"
                                            />
                                            <label className="form-check-label" htmlFor="lockingApplicableEnable">Enable</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="locking_applicable"
                                                type="radio"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, 'updateLockingForm')}
                                                checked={this.state.updateLockingForm.locking_applicable == "N"}
                                                className="form-check-input"
                                                id="lockingApplicableDisable"
                                            />
                                            <label className="form-check-label" htmlFor="lockingApplicableDisable">Disable</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Transaction Lock Period (Days) </label></div>
                                    <div className="col-sm-8">
                                        <input name="transaction_locking"
                                            type="number"
                                            value={this.state.updateLockingForm.transaction_locking}
                                            className="form-control"
                                            disabled={this.state.updateLockingForm.locking_applicable === "N" ? true : false}
                                            placeholder="Please Enter Locking Period"
                                            onChange={(e) => this.formInputHandler(e, 'updateLockingForm')} />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updatePermissionModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateAssetPermissionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Permission</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitUpdateAssetPermission} id="updateRoleModal">
                            <div className="modal-body">
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label require">Permission for Asset Management</label></div>
                                    <div className="col-sm-2">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="permission_asset"
                                                type="radio"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, 'updateAssetPermission')}
                                                checked={this.state.updateAssetPermission.permission_asset == "Y"}
                                                className="form-check-input"
                                                id="lockingApplicableEnable"
                                            />
                                            <label className="form-check-label" htmlFor="lockingApplicableEnable">Yes</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="permission_asset"
                                                type="radio"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, 'updateAssetPermission')}
                                                checked={this.state.updateAssetPermission.permission_asset == "N"}
                                                className="form-check-input"
                                                id="lockingApplicableDisable"
                                            />
                                            <label className="form-check-label" htmlFor="lockingApplicableDisable">No</label>
                                        </div>
                                    </div>
                                  </div>
                                </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
       
        return (
            <ApplicationLayout>
                  <Helmet><title>Roles List</title></Helmet>
                  {this.roleListjsx()}
                  {this.updateRoleModalJsx()}
                  {this.updateVisibilityModalJsx()}
                  {this.updateTransactionModalJsx()}
                  {this.updatePermissionModalJsx()}
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};


export default connect(mapStateToProps)(RoleListing);