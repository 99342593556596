import React from 'react';
import {connect} from "react-redux";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import { Modal } from 'bootstrap';
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import * as actions from '../../../store/actions/index'
import Helmet from 'react-helmet';
import axios from 'axios';
import DownloadFile from "../../../services/DownloadFile";
import { toast } from 'react-toastify';


 class AssetMatrixList extends AppBaseComponent {

    constructor() {
        super();

        this.initMatrixSearchForm         =       {
            asset_matrix_type                 :         'my',
            search_asset_matrix_unique_id     :         '',
            search_asset_matrix_code          :         '',
            'asset_group_ids'                 :    [],
            search_asset_type_ids             :         [],
            search_manufacturer_ids           :         [],
            search_models                     :         [],
            search_fuel_type_ids              :         [],
            search_variants                   :         '',
            search_capacity                   :         '',
            enable_counterlog_meters          :         '',
            search_by_keywords                 :         ''
        }

        this.matrixSearchFormLabel     =       {
            asset_matrix_type                :          'Asset Matrix : ',
            search_asset_matrix_unique_id    :          'Asset Matrix ID : ',
            search_asset_matrix_code         :          'Asset Matrix Code : ',
            'asset_group_ids'                :          'Asset Type Groups : ',
            search_asset_type_ids            :          'Asset Types : ',
            search_manufacturer_ids          :          'Manufacturer : ',
            search_models                    :          'Models : ',
            search_fuel_type_ids             :          'Fuel Types : ',
            search_variants                  :          'Variants : ',
            search_capacity                  :          'Capacity : ',
            enable_counterlog_meters         :          'Counterlog : ',
            search_by_keywords                 :         'Search : '
        }

        this.state = {
            minimizeTable                    :          false,
            assetMatrixSearchForm            :          {...this.initMatrixSearchForm},
            submittedMatrixFilterForm        :          {...this.initMatrixSearchForm},
            formSearchedElems                :          [],
            iam_asset_types                  :          [],
            iam_manufactures                 :          [],
            iam_models                       :          [],
            asset_counterlog_meter           :          [],
            asset_matrix_type                :          [],
            listing_loading                  :          false,
            listing_tbl_page                 :          1,
            matrix_listing                   :          [],
            listingMeta                      :          null,
            totalListingCount                :          0,
            asset_fuel_types                 :          [],
            formDataLoaded                   :          false,
            matrix_param                     :          {},
            matrix_listing_loaded            :          false,
            matrix_meta                      :           null,
            matrix_total_count               :            0,
            assetBulkUploadFormSubmiting     :          false,
             allAssetTypesGroupsList         :   []
        }
    }

    componentDidMount(){  
        this.initilaizeFormFilter(this.props);
        this.assetBulkUploadModal                =      new Modal(document.getElementById('assetBulkUploadModal'), {keyboard: false, backdrop :false});
        this.assetMatrixSearchModal              =       new Modal(document.getElementById('assetMatrixSearchModal'), {keyboard: false, backdrop :false});
    }


    initilaizeFormFilter                    =   (props)  =>  {
        this.setState({form_data_loading : true,listing_loading : true})
        HttpAPICall.withAthorization('GET',  IAM_API_BASE_URL_2 + '/asset_matrix_form_data', this.props.access_token, {}, {}, (resp) => {
            let respData  = resp.data
            this.setState({
                formDataLoaded          :   true,
                asset_counterlog_meter  :   resp.data && resp.data.counterlog_meters && respData.counterlog_meters.length > 0 ? respData.counterlog_meters.map((at) => { return {value: at.key, label: at.Text}}) : [],
                asset_matrix_type       :   resp.data && resp.data.asset_matrix_type && respData.asset_matrix_type.length > 0 ? respData.asset_matrix_type.map((at) => { return {value: at.key, label: at.text}}) : [],
                asset_fuel_types        :   resp.data && resp.data.fuel_types && respData.fuel_types.length > 0 ? respData.fuel_types.map((at) => { return {value: at.id, label: at.fuel_type}}) : [],
                iam_asset_types         :   resp.data && resp.data.asset_types && respData.asset_types.length > 0 ? respData.asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                iam_manufactures        :   resp.data && resp.data.manufactures && respData.manufactures.length > 0 ? respData.manufactures.map((m) => { return {value: m.id, label: m.manufacturer}}) : [],
                iam_models              :   resp.data && resp.data.models && respData.models.length > 0 ? respData.models.map((m) => { return {value: m.id, label: m.model}}) : [],
                allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
            },() => {
                let ParamObject                 =   new URLSearchParams(this.props.location.search);
            
                if(ParamObject.get('transaction_id')) {
                    
                    let updateFilterForm        =   {search_asset_matrix_unique_id : ParamObject.get('transaction_id')};
                    
                    this.setState({assetMatrixSearchForm :   {...this.state.assetMatrixSearchForm, ...updateFilterForm},
                                    },() => this.submitAssetMatrixSearchForm());
                }else{
                    
                    this.setState({assetMatrixSearchForm :   {...this.state.assetMatrixSearchForm, ...props.matrix_param},
                                    },() => this.submitAssetMatrixSearchForm());
                }

                if(this.props && this.props.location && this.props.location.state && this.props.location.state.search_by_keywords){
                    this.setState({
                        assetMatrixSearchForm               :          {...this.state.assetMatrixSearchForm,search_by_keywords :this.props.location.state.search_by_keywords },
                    },() => this.submitAssetMatrixSearchForm())
                 }
                
            });
        }).then(() => this.setState({form_data_loading: false}));
    }

    submitAssetMatrixSearchForm       =   (event)  =>  {
        event && event.preventDefault()
     
        let serachFormKeys                      =   Object.keys(this.state.assetMatrixSearchForm);
    
        let searchedElems                       =   [];

        serachFormKeys.map((key)        =>  {
            let label                       =   this.matrixSearchFormLabel[key];
            let value                       =   this.state.assetMatrixSearchForm[key];
            if(value && value.toString().length > 0) {
                
                if(label) {
                    let show_val            =   value;
                    if(key == 'asset_matrix_type') {
                        show_val            =   this.state.asset_matrix_type && this.state.asset_matrix_type.length > 0 ?  this.state.asset_matrix_type.filter(as => as.value == this.state.assetMatrixSearchForm.asset_matrix_type).map(s => s.label) : '';
                    }
                    if(key == 'search_asset_matrix_unique_id') {
                        show_val            =   this.state.assetMatrixSearchForm.search_asset_matrix_unique_id ? this.state.assetMatrixSearchForm.search_asset_matrix_unique_id : '' ;
                    }
                    if(key == 'search_by_keywords') {
                        show_val            =   this.state.assetMatrixSearchForm.search_by_keywords ? this.state.assetMatrixSearchForm.search_by_keywords : '' ;
                    }
                    if(key == 'search_asset_matrix_code') {
                        show_val            =   this.state.assetMatrixSearchForm.search_asset_matrix_code ? this.state.assetMatrixSearchForm.search_asset_matrix_code : '' ;
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_fuel_type_ids') {
                        show_val            = this.state.asset_fuel_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_variants') {
                        show_val            =   this.state.assetMatrixSearchForm.search_variants ? this.state.assetMatrixSearchForm.search_variants : '' ;
                    }
                    if(key == 'search_capacity') {
                        show_val            =   this.state.assetMatrixSearchForm.search_capacity ? this.state.assetMatrixSearchForm.search_capacity : '' ;
                    }
                    if(key == 'enable_counterlog_meters') {
                        show_val            = this.state.asset_counterlog_meter.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    searchedElems.push(label + show_val);
                }
            }
        });
        
        this.setState({
            submittedMatrixFilterForm           :    {...this.state.assetMatrixSearchForm},
            formSearchedElems                   :     searchedElems,
        }, () => {
            this.loadListingTblData(1);
            this.assetMatrixSearchModal.hide()
        });
    }

    clearSearchForm         =       ()      =>      {
        this.setState({
            assetMatrixSearchForm            :          {...this.initMatrixSearchForm},
            formSearchedElems                :          ["Asset Matrix : My Asset matrix"],
            submittedMatrixFilterForm        :          {...this.initMatrixSearchForm},
        }, () => {
           this.loadListingTblData(1)
        })
    }

     //************************GET consumption LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
        // this.setState({ listing_tbl_page: page});
         this.props.onListingLoading()
        let submittedMatrixFilterForm = this.state.submittedMatrixFilterForm;
        delete submittedMatrixFilterForm?.page
        let params                      =   {...submittedMatrixFilterForm,page : page};
        
         this.props.onListingSuccess(params,this.state.formSearchedElems)
    }

    viewAssetMatrixDetail         =       (id)      =>      {
        this.props.history.push(`/asset_matrix/details/${id}`)
    }

    matrixModalInit     =   ()      =>  {
        this.assetMatrixSearchModal.show()
    }

    assetBulkUploadModalInit               =   ()  =>  {
        document.getElementById('addAssetFileSelector').value = null;
        
        this.assetBulkUploadModal.show();
    }

    assetBulkUploadFormSubmit            =   (event) => {
        event.preventDefault();
        this.setState({assetBulkUploadFormSubmiting : true});
        const data          =   new FormData(document.getElementById('assetBulkUploadForm'));
        let headers         =   { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data','Authorization' : 'Bearer ' + this.props.access_token };
        axios.post(IAM_API_BASE_URL_2 + '/asset_add_by_excel', data, { headers: headers })
        .then((response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.assetBulkUploadModal.hide();
            //Load Add Item Form Data :-
            //this.loadListingTblData(1);
            DownloadFile.file(response.data.generated_file.file_path);
            setTimeout(() => {
                this.loadListingTblData(1);
            },1000)
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to upload Asset Add File.', {position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            this.setState({assetBulkUploadFormSubmiting : false});
        });
    }

    downloadSampleFile                  =   ()      => {
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/asset_add_download_sample_excel', this.props.access_token,
        {} , {} , (response) => 
        {   DownloadFile.file(response.data.file_path)
            toast.success('File Downloaded Successfully', {position: toast.POSITION.TOP_RIGHT})
            this.assetBulkUploadModal.hide();
        })
    }

    goToAddAsset                =               (id,asset_matrix_unique_id)          =>      {
        this.props.history.push({ pathname: `/add_asset/${id}`, state: { matrixId: asset_matrix_unique_id ,search_by_keywords:this.state.assetMatrixSearchForm?.search_by_keywords,routedFrom:'Asset Matrix List'} })
      
    }

    assetMatrixListJsx            =       ()      =>    {

        return (<Ax><div className="page_title row m0">
            <div className={"col-12"}>
                <h3>Asset Matrix </h3>
                {!this.state.minimizeTable
                    ? <div className="text-end mt15">
                        <button type="button" className="btn btn-secondary" onClick={this.matrixModalInit}>
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <span className="dropdown">
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={this.state.excelDownloading == true}>
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><Link className="dropdown-item" onClick={this.assetBulkUploadModalInit} role="button" >Bulk Upload</Link></li>
                            </ul>
                        </span>
                    </div>
                    : null}
            </div>

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', 'col-12'].join(' ')} >{this.assetMatrixTableJsx()}</div>
                </div>
            </div>
        </Ax>);
    }

    assetMatrixTableJsx            =   ()   =>   {
     
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.clearSearchForm}/>
            <table className="table table-bordered bg-white ">
                <thead className="table-secondary">
                    <tr>
                        <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                        <th scope="col" style={{ width: "10%" }} className="text-center">Asset Matrix ID</th>
                        <th scope="col" style={{ width: "19%" }}>Asset Type</th>
                        <th scope="col" style={{ width: "20%" }}>Manufacturer</th>
                        <th scope="col" style={{ width: "11%" }}>Model</th>
                        <th scope="col" style={{ width: "11%" }}>Variant</th>
                        <th scope="col" style={{ width: "11%" }}>Capacity</th>
                        <th scope="col" style={{ width: "8%" }}>Fuel Type</th>
                        <th scope="col" style={{ width: "5%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.matrix_listing_loaded
                        ? (<tr><td colSpan="9"><Loader /></td></tr>)
                        : (this.props.matrix_listing.length > 0
                            ? (this.props.matrix_listing.map((item, index) => {
                                return (<tr key={index} >

                                    <td className="text-center">{this.props.matrix_meta ? this.props.matrix_meta.from + index : index}</td>
                                    <td className="text-center">
                                        <a role="button" onClick={() => this.viewAssetMatrixDetail(item.id)} className="link-primary text-capitalize">{item.asset_matrix_unique_id}</a>
                                    </td>
                                    <td>{item.assetType ? item.assetType.asset_type_name : "-"}</td>
                                    <td>{item.manufacturer ? item.manufacturer.manufacturer : "-"}</td>
                                    <td>{item.model_detail ? item.model_detail.model : "-"}</td>
                                    <td>{item.variant ? item.variant : "-"}</td>
                                    <td>{item.capacity ? item.capacity : "-"}</td>
                                    <td>{item.fuelType ? item.fuelType.fuel_type : "-"}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button"  onClick={() => this.viewAssetMatrixDetail(item.id)} >View</a></li>
                                            <li><a className="dropdown-item" role="button"  onClick={() => this.goToAddAsset(item.id,item.asset_matrix_unique_id)} >Add Asset</a></li>
                                        </ul>
                                    </td>

                                </tr>)
                            }))
                            : (<tr><td colSpan="9" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.props.matrix_meta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

     //***********************SEARCH assetMatrix JSX****************
     assetMatrixSearchModalJsx               =        ()             =>        {
     
         return (
             <div className="modal fade" id="assetMatrixSearchModal" tabIndex="-1">
                 <div className="modal-dialog modal-xl">
                     <div className="modal-content">
                         <div className="modal-header">
                             <h5 className="modal-title" id="searchAssetModalLabel">Asset Matrix Search</h5>
                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                         <form id="assetMatrixSearchForm" onSubmit={this.submitAssetMatrixSearchForm}>
                             {this.state.form_data_loading ? <Loader />
                                 : <div className='modal-body'>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Search </label>
                                         <div className="col-sm-10">
                                             <input
                                                 name="search_by_keywords"
                                                 type="text"
                                                 value={this.state.assetMatrixSearchForm.search_by_keywords}
                                                 className="form-control form-control-sm"
                                                 autoComplete="off"
                                                 onChange={(e) => this.formInputHandler(e, "assetMatrixSearchForm")}
                                                 placeholder="Please search Asset Matrix"
                                                 style={{ height: "30px", fontSize: "93%" }}
                                             />
                                         </div>


                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Matrix</label>
                                         <div className="col-sm-4">
                                             {this.state.asset_matrix_type && this.state.asset_matrix_type.length > 0
                                                 ? this.state.asset_matrix_type.map(as => {
                                                     return (<div className="form-check form-check-inline">
                                                         <input
                                                             name="asset_matrix_type"
                                                             type="radio"
                                                             value={as.value}
                                                             onChange={(e) => this.formInputHandler(e, 'assetMatrixSearchForm')}
                                                             checked={as.value == this.state.assetMatrixSearchForm.asset_matrix_type ? true : false}
                                                             className="form-check-input"
                                                             required={true}
                                                             id={as.value}
                                                         />
                                                         <label className="form-check-label form-label-sm" htmlFor={as.value}>{as.label}</label>
                                                     </div>)
                                                 }) : null}
                                         </div>
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Matrix ID</label>
                                         <div className="col-sm-4">
                                             <input
                                                 name="search_asset_matrix_unique_id"
                                                 type="text"
                                                 value={this.state.assetMatrixSearchForm.search_asset_matrix_unique_id}
                                                 className="form-control"
                                                 autoComplete="off"
                                                 onChange={(e) => this.formInputHandler(e, "assetMatrixSearchForm")}
                                                 placeholder="Please enter Asset Matrix ID"
                                                 style={{ height: "30px", fontSize: "93%" }}
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Type Group</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.allAssetTypesGroupsList}
                                                 changeEvent={(selectedOption) => {

                                                     this.setState({
                                                         assetMatrixSearchForm: {
                                                             ...this.state.assetMatrixSearchForm,
                                                             asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                             search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                         }
                                                     })
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.allAssetTypesGroupsList.filter(s => this.state.assetMatrixSearchForm.asset_group_ids.includes(s.value))}
                                                 placeholder="Select Asset Type Group"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />

                                         </div>
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Type</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.iam_asset_types}
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'assetMatrixSearchForm', 'search_asset_type_ids');
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.iam_asset_types.filter(s => this.state.assetMatrixSearchForm.search_asset_type_ids.includes(s.value))}
                                                 placeholder="Select Asset Type"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Matrix Code</label>
                                         <div className="col-sm-10">
                                             <input
                                                 name="search_asset_matrix_code"
                                                 type="text"
                                                 value={this.state.assetMatrixSearchForm.search_asset_matrix_code}
                                                 className="form-control"
                                                 autoComplete="off"
                                                 onChange={(e) => this.formInputHandler(e, "assetMatrixSearchForm")}
                                                 placeholder="Please enter Asset Matrix Code"
                                                 style={{ height: "30px", fontSize: "93%" }}
                                             />

                                         </div>


                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Manufacturer</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.iam_manufactures}
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'assetMatrixSearchForm', 'search_manufacturer_ids');
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.iam_manufactures.filter(s => this.state.assetMatrixSearchForm.search_manufacturer_ids.includes(s.value))}
                                                 placeholder="Select Manufacturer"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />

                                         </div>
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Model</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.iam_models}
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'assetMatrixSearchForm', 'search_models');
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.iam_models.filter(s => this.state.assetMatrixSearchForm.search_models.includes(s.value))}
                                                 placeholder="Select Model"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Variant</label>
                                         <div className="col-sm-4">
                                             <input
                                                 name="search_variants"
                                                 type="text"
                                                 value={this.state.assetMatrixSearchForm.search_variants}
                                                 className="form-control"
                                                 autoComplete="off"
                                                 onChange={(e) => this.formInputHandler(e, "assetMatrixSearchForm")}
                                                 placeholder="Please enter Variant"
                                                 style={{ height: "30px", fontSize: "93%" }}
                                             />

                                         </div>
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Capacity</label>
                                         <div className="col-sm-4">
                                             <input
                                                 name="search_capacity"
                                                 type="text"
                                                 value={this.state.assetMatrixSearchForm.search_capacity}
                                                 className="form-control"
                                                 autoComplete="off"
                                                 onChange={(e) => this.formInputHandler(e, "assetMatrixSearchForm")}
                                                 placeholder="Please enter Capacity"
                                                 style={{ height: "30px", fontSize: "93%" }}
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Fuel Type</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.asset_fuel_types}
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'assetMatrixSearchForm', 'search_fuel_type_ids');
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.asset_fuel_types.filter(s => this.state.assetMatrixSearchForm.search_fuel_type_ids.includes(s.value))}
                                                 placeholder="Select Fuel Types"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />

                                         </div>
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Enable Counterlog</label>
                                         <div className="col-sm-4">
                                             <TapSelect
                                                 options={this.state.asset_counterlog_meter}
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'assetMatrixSearchForm', 'enable_counterlog_meters');
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.asset_counterlog_meter.filter(s => this.state.assetMatrixSearchForm.enable_counterlog_meters.includes(s.value))}
                                                 placeholder="Select Counterlog Meter"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />
                                         </div>

                                     </div>
                                 </div>}
                             <div className="modal-footer">
                                 <button type="button" className="btn btn-secondary" onClick={this.clearSearchForm}>Clear</button>
                                 <button type="submit" className="btn btn-primary">Search</button>
                             </div>
                         </form>
                     </div>
                 </div>
             </div>
         );
    }

    assetBulkUploadModalJsx            =   ()  =>  {
        return (
            <div className="modal fade" id="assetBulkUploadModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemBulkUploadModalLabel">Bulk Add Asset</h5>
                            <button type="button" disabled={this.state.assetBulkUploadFormSubmiting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.assetBulkUploadFormSubmit} id="assetBulkUploadForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-12">
                                        <input
                                            name="document"
                                            id="addAssetFileSelector"
                                            type="file"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 mt-2 form-text"># Excel Sheet with Extention xls,xlm,xlsx,ods Allowed</div>
                                    <div className="col-12 mt-2 form-text"># Miximum 100 MB Size of Excel Sheet Allowed</div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <div><a type="button" className='float-left' href='#' onClick={this.downloadSampleFile}>Download Sample File</a></div>
                                <div>
                                    <button type="button" disabled={this.state.assetBulkUploadFormSubmiting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.assetBulkUploadFormSubmiting} className="btn btn-primary">
                                        Submit {this.state.assetBulkUploadFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render() {
      
        return (
            <ApplicationLayout>
             <Helmet><title>Asset Matrix List</title></Helmet>
                {this.assetMatrixListJsx()}
                {this.assetMatrixSearchModalJsx()}
                {this.assetBulkUploadModalJsx()}
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        matrix_param            :   state.app && state.app.matrix_search_param ? state.app.matrix_search_param : {},
        matrix_listing_loaded   :   state.app && state.app.matrix_listing_loaded ? state.app.matrix_listing_loaded : false,
        matrix_listing          :   state.app && state.app.matrix_listing ? state.app.matrix_listing : [],
        matrix_meta             :   state.app && state.app.matrix_meta ? state.app.matrix_meta : null,
        matrix_total_count      :   state.app && state.app.matrix_total_count ? state.app.matrix_total_count : 0,
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],    
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onListingLoading          :   (data) => dispatch(actions.assetMatrixListingDataInitilization()),
        onListingSuccess        :   (params,formSearchedElems) => dispatch(actions.assetMatrixListingDataLoading(params,formSearchedElems)),
    };
};

export default connect(mapStateToProps , mapDispatchToProps)(AssetMatrixList);

