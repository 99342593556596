import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import DatePicker from "react-datepicker";
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import CustomerDetail from './CustomerDetail';


const CustomerDetailModal = forwardRef((props, ref) => {

    let initData                            =   {
        loading                                 :   false,
        customer                                :   null,
    };
    const [customerData, setCustomerData]   =   useState({...initData});
    const [modalId, setModalId]             =   useState(uuid());
    const modalElem                         =   useRef(null);
    const modal                             =   useRef(null);
    const access_token                      =   useSelector(state => state.auth.access_token);


    let customerUrl                         =   INVENTORY_API_BASE_URL_2 + '/customer';

    useImperativeHandle(ref, () => ({

        initModalHandler: (customer_id) => {
            openModalHandler(customer_id);
        }
    }));

    const modalOpen                         =   ()  => {
        if (modal.current) { modal.current.show(); }
    }

    const modalClose                        =   ()  => {
        if (modal.current) { modal.current.hide(); }
    }

    useEffect(() => {
        modalElem.current                   =   document.getElementById(modalId);
        modal.current                       =   new Modal(modalElem.current, {keyboard: false, backdrop :false});
        // Cleanup on unmount
        return () => {
            if (modal.current) {
                modal.current.dispose();
            }
        };
    }, []);


    const customerDataInit                  =   (customer_id) => {
        setCustomerData(d => ({...d, loading : true}));
        HttpAPICall.withAthorization('GET', customerUrl + '/' + customer_id, access_token, null, null, (response) => {
            let respData                    =   response.data;
            setCustomerData(d => ({...d, customer : respData.data}));
        }).then(() => {
            setCustomerData(d => ({...d, loading : false}));
        });
    }

    const openModalHandler        =    (customer_id)  =>  {
        //Get Customer Data:-
        customerDataInit(customer_id);
        modalOpen();
    }
 
    return <>
        <div className="modal fade" id={modalId} tabIndex="-1" ref={modalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">View Customer</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <CustomerDetail 
                            loading={customerData.loading} 
                            customerDetails={customerData.customer}
                            performAction={false}
                        /> 
                    </div>
                </div>
            </div>
        </div>
    </>
});

export default CustomerDetailModal;