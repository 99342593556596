import {connect} from "react-redux";
import {Modal} from "bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import {toast} from "react-toastify";
import React from "react";
import * as actions from '../../../store/actions/index';
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";

class InventoryGeneralSettings extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                              =   {
            generalConfigloading                :      false,
            general_config_list                 :      false,
            editFormSubmiting                   :      false,
            activation_date                     :      '',
            generalConfigFormData      :   {
                track_batch_expiry            :    'N',
                negative_stock                :     'Y',
                isc_enable_tcs                :     'Y'
            },
            editPart                            :       '',
            config_loading                  :           false,
            config_list                     :           null,
            settingLoading                  :           false,
            setting_list                    :           null
        };

        this.settingEditUrl     =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl         =   ORG_API_BASE_URL_2  + '/settings';

        this.configurationUrl   =   INVENTORY_API_BASE_URL_2 + '/configuration';

    }

    componentDidMount() {
        this.getConfigurationListing();
        this.getGeneralSettingListing()
        // this.getInventoryConfigListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
    }

    getConfigurationListing              =    ()            =>     {
        this.setState({generalConfigloading : true})
        HttpAPICall.withAthorization('GET', this.configurationUrl, this.props.access_token , {}, {}, (resp) => {
            this.setState({general_config_list  : resp.data.data})
        }).then(() => this.setState({generalConfigloading: false}));
    }

    getGeneralSettingListing = () => {
        let params = {
            keys : ['isc_enable_tcs' ],
        }
        this.setState({ settingLoading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            this.setState({ setting_list: resp.data.data })
        }).then(() => this.setState({ settingLoading: false }));
    }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.config_list;
          return (<table className="table table-hover table-bordered my-2 bg-white ">
              <thead className="table-secondary">
                  <tr className="text-center">
                      <th scope="col" style={{ width: "5%" }}>S.No</th>
                      <th scope="col" className="text-start">Configuration</th>
                      <th scope="col" style={{ width: "25%" }} >Value</th>
                      <th scope="col" style={{ width: "10%" }} >Action</th>
                  </tr>
              </thead>
              <tbody>
                  {this.state.generalConfigloading || this.state.config_loading || this.state.settingLoading
                      ? (<tr><td colSpan="4"><Loader /></td></tr>)
                      :  (<Ax><tr className="text-center">
                          <td>1</td>
                          <td className="text-start">Date of Activation(Opening Balance)</td>
                          <td>{this.state.general_config_list.activation_date ? moment(this.state.general_config_list.activation_date).format('DD-MMM-YYYY') : "-"}</td>
                          <td><tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                                         role="button" aria-expanded="false"/>
                                <ul className="dropdown-menu" style={{cursor:"pointer"}} >
                                    <li className="dropdown-item"   onClick={() => this.editGeneralConfigurationInit("date")}>Edit</li>
                                </ul>
                         </td>
                      </tr>
                      <tr className="text-center">
                          <td>2</td>
                          <td className="text-start">Do you want to track batch number and expiry date</td>
                          <td>{this.state.general_config_list.track_batch_expiry === "N" ? "No" : "Yes"}</td>
                          <td><tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                                         role="button" aria-expanded="false"/>
                                <ul className="dropdown-menu" style={{cursor:"pointer"}} >
                                    <li className="dropdown-item"   onClick={() => this.editGeneralConfigurationInit("track_batch")}>Edit</li>
                                </ul>
                         </td>
                      </tr>
                      <tr className="text-center">
                          <td>3</td>
                          <td className="text-start">Prevent stock from going below zero</td>
                          <td>{this.state.general_config_list.negative_stock == "N" ? "Yes" : "No"}</td>
                          <td><tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                                         role="button" aria-expanded="false"/>
                                <ul className="dropdown-menu" style={{cursor:"pointer"}} >
                                    <li className="dropdown-item"   onClick={() => this.editGeneralConfigurationInit("negative_stock")}>Edit</li>
                                </ul>
                         </td>
                      </tr>
                      <tr className="text-center">
                          <td>4</td>
                          <td className="text-start">Enable TCS</td>
                          <td>{this.state.setting_list && this.state.setting_list.isc_enable_tcs == "Y" ? "Yes" : "No"}</td>
                          <td><tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                                         role="button" aria-expanded="false"/>
                                <ul className="dropdown-menu" style={{cursor:"pointer"}} >
                                    <li className="dropdown-item"   onClick={() => this.editGeneralConfigurationInit("isc_enable_tcs")}>Edit</li>
                                </ul>
                         </td>
                      </tr>
                      {/* <tr className="text-center">
                    <td>4</td>
                    <td className="text-start">Allow Transactions in Foreign Currency</td>
                    <td>Yes</td>
                </tr>
                <tr className="text-center">
                    <td>5</td>
                    <td className="text-start">Select Currency Format</td>
                    <td>Yes</td>
                </tr> */}
                      </Ax>) }
              </tbody>
          </table>)}

      editGeneralConfigurationInit          =    (name)           =>     {
        this.editGeneralConfiguration.show();
        this.setState({editPart : name});
        this.setState({
                        generalConfigFormData     :  {...this.state.generalConfigFormData, 
                         negative_stock           :  this.state.general_config_list.negative_stock,
                        track_batch_expiry        :  this.state.general_config_list.track_batch_expiry},
                        activation_date           :  this.state.general_config_list.activation_date ? this.state.general_config_list.activation_date : '',
                        isc_enable_tcs           :  this.state.setting_list && this.state.setting_list.isc_enable_tcs ? this.state.setting_list.isc_enable_tcs : 'N',
                    });
      }

      editGeneralConfigurationModalJsx      =    ()           =>     {
      
          return (
              <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                  <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                          </div>
                          <form onSubmit={this.submitGeneralConfigurationForm}>
                              <div className="modal-body p-3">
                                  {this.state.editPart == "track_batch"
                                      ? (<div className="row align-items-center mb-2 mt-2 px-2">
                                          <div className="col-md-9"><label className="form-label">Do you want to track batch number and expiry date</label></div>
                                          <div className="col-md-1">
                                              <label>
                                                  <input
                                                      type="radio"
                                                      name='track_batch_expiry'
                                                      value="Y"
                                                      onChange={(e) => this.formInputHandler(e, 'generalConfigFormData')}
                                                      checked={this.state.generalConfigFormData.track_batch_expiry == "Y"}
                                                  /> Yes
                                              </label>
                                          </div>
                                          <div className="col-md-2">
                                              <label>
                                                  <input
                                                      type="radio"
                                                      name='track_batch_expiry'
                                                      value="N"
                                                      onChange={(e) => this.formInputHandler(e, 'generalConfigFormData')}
                                                      checked={this.state.generalConfigFormData.track_batch_expiry == "N"}
                                                  /> No
                                              </label>
                                          </div>
                                      </div>)
                                      : this.state.editPart == "negative_stock" 
                                      ? (<div className="row align-items-center mb-2 mt-2 px-2">
                                          <div className="col-md-9"><label className="form-label">Prevent stock from going below zero</label></div>
                                          <div className="col-md-1">
                                              <label>
                                                  <input
                                                      type="radio"
                                                      name='negative_stock'
                                                      value="N"
                                                      onChange={(e) => this.formInputHandler(e, 'generalConfigFormData')}
                                                      checked={this.state.generalConfigFormData.negative_stock == "N"}
                                                  /> Yes
                                              </label>
                                          </div>
                                          <div className="col-md-2">
                                              <label>
                                                  <input
                                                      type="radio"
                                                      name='negative_stock'
                                                      value="Y"
                                                      onChange={(e) => this.formInputHandler(e, 'generalConfigFormData')}
                                                      checked={this.state.generalConfigFormData.negative_stock == "Y"}
                                                  /> No
                                              </label>
                                          </div>
                                      </div>) 
                                      : this.state.editPart == "isc_enable_tcs" 
                                      ? (<div className="row align-items-center mb-2 mt-2 px-2">
                                          <div className="col-md-9"><label className="form-label">Enable TCS</label></div>
                                          <div className="col-md-1">
                                              <label>
                                                  <input
                                                      type="radio"
                                                      name='isc_enable_tcs'
                                                      value="Y"
                                                      onChange={(e) => this.formInputHandler(e, 'generalConfigFormData')}
                                                      checked={this.state.generalConfigFormData.isc_enable_tcs == "Y"}
                                                  /> Yes
                                              </label>
                                          </div>
                                          <div className="col-md-2">
                                              <label>
                                                  <input
                                                      type="radio"
                                                      name='isc_enable_tcs'
                                                      value="N"
                                                      onChange={(e) => this.formInputHandler(e, 'generalConfigFormData')}
                                                      checked={this.state.generalConfigFormData.isc_enable_tcs == "N"}
                                                  /> No
                                              </label>
                                          </div>
                                      </div>) : (<div className="row align-items-center mb-2 mt-2 px-2">
                                          <div className="col-md-9"><label className="form-label">Opening Balance Date</label></div>

                                              <div className="col-3 add_calender_section">
                                                  <DatePicker
                                                      selected={
                                                          this.state.activation_date
                                                              ? moment(this.state.activation_date).toDate()
                                                              : false
                                                      }
                                                      name="activation_date"
                                                      onChange={(value, event) => {
                                                        this.formDateStateHandler(value, 'activation_date','YYYY-MM-DD')
                                                    }}
                                                      
                                                      maxDate={moment().toDate()}
                                                      dateFormat="dd-MMM-yyyy"
                                                      className={"form-control"}
                                                      showMonthDropdown
                                                      showYearDropdown
                                                      autoComplete="off"
                                                      scrollMonthYearDropdown
                                                      placeholderText={`Please Enter Date`}

                                                  />
                                                  <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                              </div>
                                      </div>)}
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                  <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          );
    }

      submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        
       let frmData;
        if(this.state.editPart == 'isc_enable_tcs'){
            frmData = {isc_enable_tcs : this.state.generalConfigFormData.isc_enable_tcs}
            this.setState({editFormSubmiting : true});
            HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.editGeneralConfiguration.hide();
                this.getGeneralSettingListing();
                this.props.onIscConfigurationChange(this.props.user_info,this.props.group_info,this.props.acl_info);
            }).then(() => this.setState({editFormSubmiting: false}))
        }else{
            if(this.state.editPart == 'date'){
                frmData = {activation_date : this.state.activation_date}
            }else if(this.state.editPart == 'negative_stock'){
                frmData = {negative_stock : this.state.generalConfigFormData.negative_stock}
            }else{
                frmData = {track_batch_expiry : this.state.generalConfigFormData.track_batch_expiry}
            }
            this.setState({editFormSubmiting : true});
            HttpAPICall.withAthorization('PUT', this.configurationUrl, this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.editGeneralConfiguration.hide();
                this.getConfigurationListing()
                this.props.onIscConfigurationChange(this.props.user_info,this.props.group_info,this.props.acl_info);
            }).then(() => this.setState({editFormSubmiting: false}));
        }
    }

    render                                  =     ()           =>     {
        return (<Ax>
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general"
                                type="button" role="tab" aria-controls="general" aria-selected="true">General Settings
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane  show active" id="general" role="tabpanel"
                            aria-labelledby="general-tab">
                            <div className="tab_content_header">
                                {this.generalConfigurationJsx()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {this.editGeneralConfigurationModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        user_info               :        state.app && state.app.user_info ? state.app.user_info : null,
        group_info               :        state.app && state.app.group_info ? state.app.group_info : null,
        acl_info               :        state.app && state.app.acl_info ? state.app.acl_info : null,
    };
};

const mapDispatchToProps        =   dispatch    =>  {
    return {
        onIscConfigurationChange: (user_info,group_info,acl_info) => {dispatch(actions.iscAppDataLoading(user_info,group_info,acl_info));}
    };
};


export default connect(mapStateToProps,mapDispatchToProps)(InventoryGeneralSettings);