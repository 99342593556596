import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";
import AppBaseComponent from '../../../components/AppBaseComponent';
import Ax from "../../../components/hoc/Ax";
import Loader from '../../../components/ui/Loader/Loader';
import uuid from "react-uuid";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import DateService from '../../../services/DateService';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapIcon from '../../../services/TapIcon';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import TapSelect from '../../../components/ui/TapSelect';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import moment from 'moment';
import { toast } from 'react-toastify';
import PurchaseReturnDetail from './PurchaseReturnDetail';
import swal from 'sweetalert';
import download from '../../../services/DownloadFile';


class PurchaseReturnList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initPurchaseReturnSearchForm   =   {
            'search_transaction_id'             :   '',
            'search_purchase_transaction_id'    :   '',
            date_range                          :   null,
            date_range_start                    :   null,
            date_range_end                      :   null,
            search_purchase_bill_number         :   '',
            search_purchase_order_number        :   '',
            search_e_way_bill_number            :   '',
            search_vendor_ids                   :   [],
            search_warehouse_ids                :   [],
            search_item_ids                     :   [],
            search_status_ids                   :   [],
            search_item_tags                    :   [],
            search_manufacturer_ids             :   [],
        }

        this.purchaseSearchFormLabel        =       {
            search_transaction_id           :       'Transaction ID : ',
            'search_purchase_transaction_id'    :   'Purchase Trannsaction ID : ',
            date_range                      :       'Date Range : ',
            search_purchase_bill_number     :       'Bill Number : ',
            search_warehouse_ids            :       'Warehouse : ',
            search_vendor_ids               :       'Vendor : ',
            search_item_ids                 :       'Items : ',
            search_status_ids               :        'Status : ',
            search_item_tags                :       'Item Tags : ',
            search_manufacturer_ids         :       'Manufacturer : ',
        }

        this.per_page                       =   25;

        this.state                          =   {
            minimizeTable                       :   false,
            reportDownloading                   :   false,

            data                                :   [],
            meta                                :   null,
            listLoading                         :   false,
            listingPage                         :   1,
            totalData                           :   0,
            viewLoading                         :   false,
            all_warehouses                      :   [],
            all_periods                         :   [],
            allStatusOptions                    :   [],
            purchaseReturnDetail                :   null,
            openTransactionId                   :   '',
            searchedVendor                      :   [],
            searchedItem                        :   [],
            listFilterFormSubmitting            :   false,
            allItemTags                         :   [],
            manufacturers                       :   [],
            formSearchedElems                   :   [],
            purchaseReturnSearchForm            :   {...this.initPurchaseReturnSearchForm},
            submittedPurchaseReturnFilterForm   :   {...this.initPurchaseReturnSearchForm},
            addCommentForm                  :       {...this.updateCommentFormInit},
            allAllowedStatus                :       [],
            pdfDownloading                  :   false,
        };

        this.myRef                  =   React.createRef();
        this.listFilterFormModalId  =   uuid();
        this.itemDetailsRef         =   React.createRef();
        this.purReturnUrl           =   INVENTORY_API_BASE_URL_2 + '/purchase_return';

    }

    componentDidMount() {
        this.initializeData(this.props);
        this.listFilterFormModal            =   new Modal(document.getElementById(this.listFilterFormModalId),{keyboard:false,backdrop:false});
        
       
    }

    initializeData                      =   (pr)  =>  {

         if(pr){
                let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
               
                HttpAPICall.withAthorization('GET', this.purReturnUrl + '/form_data', pr.access_token, null, null, (response) => {
                  
                    this.setState({
                        all_warehouses          :        pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                        all_periods             :        all_periods ,
                        manufacturers                       :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                        allItemTags           :       pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                        allStatusOptions        :        response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    }, () => {
                        
                        if(pr && pr.location && pr.location.state && pr.location.state.purchaseReturnId){
                           
                            this.setState({
                                minimizeTable : true,
                                viewLoading : true
                            })
                         }  
                    });
                }, (err) => {
    
                });
            }
        
        this.loadListingTblData(1,pr);
    }

    loadListingTblData                  =   (page = 1,pr=null)  =>  {
        this.setState({listLoading: true, listing_tbl_page: page});
        let params                      =   {page:page, per_page : this.per_page,...this.state.submittedPurchaseReturnFilterForm};
        HttpAPICall.withAthorization('GET', this.purReturnUrl + '/list', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                data            :   respData.data,
                meta            :   respData.meta,
                totalData       :   respData.meta.total, 
            },() => {
                if(pr && pr.location && pr.location.state && pr.location.state.purchaseReturnId){
                    this.viewPurchaseReturnDetail(pr.location.state.purchaseReturnId)
                 }
            });
        }).then(() => this.setState({listLoading: false}));
    }

    viewPurchaseReturnDetail            =   (transaction_id, refresh=false)  =>  {
        this.setState({viewLoading : true,minimizeTable : true, openTransactionId : transaction_id});
        if(this.state.purchaseReturnDetail && this.state.purchaseReturnDetail.transaction_id == transaction_id && refresh == false) {
            this.setState({viewLoading: false});
        } else {
            //Get Detail of Purchase Return Detail :-
            HttpAPICall.withAthorization('GET', this.purReturnUrl + '/detail/' + transaction_id, this.props.access_token, {need_purchase_detail:'Y'}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    purchaseReturnDetail        :   resp.data.data,
                    allAllowedStatus     :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                    addCommentForm       :   {...this.updateCommentFormInit , status : respData.data.status_data ? respData.data.status_data.id : ''}
                });
                
            }).then(() => this.setState({viewLoading: false}));
        }
    }

    closePurchaseReturnDetail           =   ()  =>  {
        this.setState({viewLoading : false,minimizeTable : false, openTransactionId: null});
    }

    purchaseDownloadHandler             =   ()  =>  {

    }

    listSearchModalInit             =   ()  =>  {
        this.listFilterFormModal.show();
    }

    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    submitPurchaseReturnSearchForm                =       (e)          =>          {
        e &&  e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.purchaseReturnSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.purchaseSearchFormLabel[key];
            let value                       =   this.state.purchaseReturnSearchForm[key];
           
            if(value   && value.length > 0) {
                searchParams[key]      =   value;
           if(label) {
               
                    let show_val            =   value;
                    if(key == 'search_vendor_ids') {
                        show_val            =   this.state.searchedVendor ? this.state.searchedVendor.map(s => s.vendor.name).join(', ') : '';
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.purchaseReturnSearchForm ? this.state.purchaseReturnSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_purchase_bill_number') {
                        show_val            =   this.state.purchaseReturnSearchForm ? this.state.purchaseReturnSearchForm.search_purchase_bill_number : '';
                    }
                                    
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.manufacturers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        
                        let  start_range = moment(this.state.purchaseReturnSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.purchaseReturnSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                        
                    }
                   
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedPurchaseReturnFilterForm   :   {...this.state.purchaseReturnSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.listFilterFormModal.hide()
            this.loadListingTblData(1);
        });
    }

    purchaseReturnSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedVendor                  :   [],
            searchedItem                    :   [],
            purchaseReturnSearchForm            :   {...this.initPurchaseReturnSearchForm},
            submittedPurchaseReturnFilterForm     :   {...this.initPurchaseReturnSearchForm}
        }, () => {
            this.loadListingTblData(1);
            this.listFilterFormModal.hide();
            this.props.history.push('/purchase_return_list')
        });
    }

    updateComment           =           ()          =>      {
        this.myRef.current.scrollIntoView()
    }

    purchaseReturnClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedVendor                  :   [],
            searchedItem                    :   [],
            purchaseReturnSearchForm            :   {...this.initPurchaseReturnSearchForm},
            submittedPurchaseReturnFilterForm     :   {...this.initPurchaseReturnSearchForm}
        }, () => {
            this.listFilterFormModal.hide();
        });
    }

    submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
             ...this.state.addCommentForm,
             transaction_id : this.state.purchaseReturnDetail.transaction_id
           }
           HttpAPICall.withAthorization('PUT', this.purReturnUrl + '/comment', this.props.access_token, {}, { ...frmData }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.viewPurchaseReturnDetail(this.state.purchaseReturnDetail.transaction_id,true);
               this.loadListingTblData(1)
               this.setState({addCommentForm : {...this.updateCommentFormInit}})
           }).then(() => this.setState({ addCommentFormSubmitting : false }));
    }

    openUpdatePurchaseScreen        =   ()      =>  {
        this.props.history.push({ pathname: `/purchase_return_add`, state: { purchaseReturnData: this.state.purchaseReturnDetail } })
    }
   
    //***********************DELETE PDF **************************** */
    deletePurchaseReturn              =           (transaction_id)              =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.purReturnUrl + '/delete/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({minimizeTable : false})
                        this.loadListingTblData(1)
                     }).then(() => {

                    });
                }
            });
    }



    //***********************DOWNLOAD PDF **************************** */
    pdfDownloadHandler              =    (transaction_id)     =>         {
        this.setState({ pdfDownloading: true});
        HttpAPICall.withAthorization('GET', this.purReturnUrl + '/detail/' + transaction_id, this.props.access_token,{action : "download", need_purchase_detail : 'Y'},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({pdfDownloading: false}));
    }


    purchaseReturnDetailJsx             =   ()  =>  {
        let {purchaseReturnDetail}         =   {...this.state};
       
        return (this.state.viewLoading ? <Loader /> : (purchaseReturnDetail && <Ax>
            <div className='p-2'>
                <PurchaseReturnDetail purchaseReturnData={purchaseReturnDetail}/> 

                <div className="tab_content_wrapper" ref={this.myRef}>
                    <span className="content_heading">Comments & History</span>
                </div>
                <form onSubmit={this.submitUpdateCommentForm}>
                    <div className="my-3 row">
                        <label className="col-sm-3 col-form-label">Change status to</label>
                        <div className="col-sm-5">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                }}
                                value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                options={this.state.allAllowedStatus}
                                isSearchable={false}
                                isDisabled={!this.props.permissions.includes('isc-purchase-return-status-change')}
                                placeholder="Please Select Status"
                            />
                        </div>
                    </div>
                    <div className="my-2">
                        <label className="form-label">Comments</label>
                        <textarea
                            name="notes"
                            value={this.state.addCommentForm.notes}
                            onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                            className="form-control"
                            placeholder="Please Add a comment"
                            required
                            style={{ height: "100px" }}
                        />
                    </div>
                    <div className="col-12 text-end">
                        <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Update {this.state.addCommentFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </div>
                </form>
                <hr />
                <div className="my-1">
                    {
                        purchaseReturnDetail.comments && purchaseReturnDetail.comments.length > 0 ? purchaseReturnDetail.comments.map((c, k) => {
                            return (<Ax key={k}><div className="row">
                                {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                <div className="col-sm-4 text-end">
                                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                    <span> {c.created_at_display}</span>
                                </div>
                                <div className="col-sm-8 my-1">{c.notes}</div>
                            </div><hr /></Ax>);
                        })
                            : null
                    }
                </div>

            </div>

        </Ax>))
    }

    // this.state.minimizeTable
    purchaseListingTableJsx             =   ()  =>  {
        return <Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.purchaseReturnSearchClear} />
            <table className="table table-bordered table-fixed bg-white table-sm">
                <thead className="table-secondary" >
                    <tr>
                    {this.state.minimizeTable 
                      ? <th>Purchase Return</th>
                      : <Ax>
                            <th style={{ width: "5%" }}>S.No</th>
                            <th style={{ width: "10%" }}>Transaction ID</th>
                            <th style={{ width: "10%" }} className='text-center'>Date</th>
                            <th style={{ width: "10%" }}>Purchase ID</th>
                            <th style={{ width: "17%" }}>Warehouse</th>
                            <th style={{ width: "15%" }} className='text-center'>Vendor</th>
                            <th style={{ width: "6%" }} className='text-center'>Total Quantity</th>
                            <th style={{ width: "8%" }} className='text-end'>Amount</th>
                            <th style={{ width: "8%" }} className='text-center'>Status</th>
                            <th style={{ width: "5%" }}>Action</th>
                        </Ax>
                    }
                    </tr>
                </thead>
                <tbody>
                {this.state.listLoading 
                    ? <tr><td colSpan="10"><Loader /></td></tr>
                    : (this.state.data.length > 0 ? this.state.data.map((item,index) =>  <tr className={['text-center', this.state.openTransactionId == item.transaction_id ? 'bg-light' : ''].join(' ')} key={index}>
                        {this.state.minimizeTable 
                            ? <td className='text-start'>
                                <Link className="text-capitalize link-primary" onClick={() => this.viewPurchaseReturnDetail(item.transaction_id)}>{item.transaction_id}</Link>
                                <div className='float-end text-xs'>{DateService.dateTimeFormat(item.transaction_date,'DD-MMM-YYYY')}</div>
                                <div className="mt-1">
                                    <span className="text-sm">{item.warehouse?.name ?? "-"}</span>
                                    <em className="float-end text-sm text-muted">{item.status_data ? item.status_data.name : "-"}</em>
                                </div> 
                            </td>
                            : <Ax>
                                    <td>{this.state.meta ? this.state.meta.from + index : index}</td>
                                    <td><Link className="" role="button" onClick={() => this.viewPurchaseReturnDetail(item.transaction_id)}>{item.transaction_id}</Link></td>
                                    <td>{DateService.dateTimeFormat(item.transaction_date,'DD-MMM-YYYY')}</td>
                                    <td>{item.purchase_transaction_id}</td>
                                    <td>{item.warehouse ? item.warehouse.name : ''}</td>
                                    <td>{item.vendor ? item.vendor.name : ''}</td>
                                    <td>{item.total_qty}</td>
                                    <td>{item.currency} <CurrencyFormatter currencyCode={item.currency ? item.currency : "INR"} amount={item.total_amount} /></td>
                                    <td>{item.status_data ? item.status_data.name : ''}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" role="button" onClick={() => this.viewPurchaseReturnDetail(item.transaction_id)}>View</Link></li>
                                        </ul>
                                    </td>
                                </Ax>
                            }
                        </tr> ): <tr><td colSpan={11} className='text-center'>No Record</td></tr>)
                    }
                </tbody>
            </table>
            <DisplayListPagination meta={this.state.meta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
        </Ax>
    }

    purchaseReturnListjsx               =   ()  =>  {
        let {purchaseReturnDetail}      =   this.state;
        return <Ax>
            <div className="page_title row m0">
                <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-12"}>
                    <h3>Purchase Return</h3>
                    <div className="text-end mt15">
                        {/* {!this.state.minimizeTable && <button type="button" className="btn btn-secondary" onClick={this.purchaseDownloadHandler}>
                            <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                            {this.state.reportDownloading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}
                        </button>} */}
                        <button type="button" className="btn btn-secondary" onClick={this.listSearchModalInit}>
                            <TapIcon.imageIcon icon={TapIcon.SearchIcon} />
                        </button>
                    </div>
                </div>
                {this.state.minimizeTable && <div className="col-sm-9">
                    <h3>Purchase Return : {purchaseReturnDetail && purchaseReturnDetail.transaction_id ? purchaseReturnDetail.transaction_id : ""}</h3>
                    <div className="text-end mt15">
                        <button type="button" disabled={(this.state.purchaseReturnDetail && this.state.purchaseReturnDetail.can_edit == 'N') || !this.props.permissions.includes('isc-purchase-return-edit') || this.state.viewLoading ? true  : false} className="btn btn-secondary" onClick={() => this.openUpdatePurchaseScreen(this.state.purchaseReturnDetail?.transaction_id)}>
                            Edit
                        </button>
                        <button disabled={this.state.viewLoading} type="button" className="btn btn-secondary" onClick={() => this.updateComment()} >Update Status </button>
                        <button disabled={this.state.viewLoading || this.state.pdfDownloading} type="button" className="btn btn-secondary" onClick={() => this.pdfDownloadHandler(this.state.purchaseReturnDetail?.transaction_id)} >
                            <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                                {this.state.pdfDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                        <button disabled={this.state.viewLoading} type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className={!this.props.permissions.includes('isc-purchase-return-delete') ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={!this.props.permissions.includes('isc-purchase-return-delete') ? true : false} href="#" onClick={() => this.deletePurchaseReturn(this.state.purchaseReturnDetail?.transaction_id)}>Delete</a></li>
                        </ul>
                        <button type="button" className="btn btn-secondary" onClick={this.closePurchaseReturnDetail}>
                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} />
                        </button>
                    </div>
                </div>}
            </div>
            <div className='container-fluid pl5'>
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-sm-3' : 'col-sm-12'].join(' ')} >{this.purchaseListingTableJsx()}</div>
                    {this.state.minimizeTable && <div className="tap_border_left col-sm-9 p-2 pageTbl bg-white" >{this.purchaseReturnDetailJsx()}</div>}
                </div>
            </div>
        </Ax>
    }

    listFilterFormModalJsx = () => {
        return <div className="modal fade" id={this.listFilterFormModalId} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Purchase Return Search</h5>
                    </div>
                    <div className="modal-body">
                        <form id={this.listFilterFormModalId + 'Form'} onSubmit={this.submitPurchaseReturnSearchForm}>
                            <div className="row mb-3">
                                <label className="col-sm-2 col-form-label col-form-label-sm">Transaction ID</label>
                                <div className="col-sm-4">
                                    <input
                                        name="search_transaction_id"
                                        type="text"
                                        value={this.state.purchaseReturnSearchForm.search_transaction_id}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder='Transaction ID'
                                        onChange={(e) => this.formInputHandler(e, "purchaseReturnSearchForm")}
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>
                                <label className="col-sm-2 col-form-label col-form-label-sm">Pur Trans. ID</label>
                                <div className="col-sm-4">
                                    <input
                                        name="search_purchase_transaction_id"
                                        type="text"
                                        value={this.state.purchaseReturnSearchForm.search_purchase_transaction_id}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder='Purchase Transaction ID'
                                        onChange={(e) => this.formInputHandler(e, "purchaseReturnSearchForm")}
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">

                                <label className="col-sm-2 col-form-label col-form-label-sm">Bill Number</label>
                                <div className="col-sm-10">
                                    <input
                                        name="search_purchase_bill_number"
                                        type="text"
                                        value={this.state.purchaseReturnSearchForm.search_purchase_bill_number}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder='Bill Number'
                                        onChange={(e) => this.formInputHandler(e, "purchaseReturnSearchForm")}
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm ">Warehouse</label>
                                <div className="col-sm-10">
                                    <TapSelect
                                        options={this.state.all_warehouses}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'purchaseReturnSearchForm', 'search_warehouse_ids')}
                                        value={this.state.all_warehouses.filter(u => this.state.purchaseReturnSearchForm?.search_warehouse_ids?.includes(u.value))}
                                        isSearchable={true}
                                        isMulti={true}
                                        isClearable={true}
                                        placeholder="Select Warehouses"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>

                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm ">Vendor</label>
                                <div className="col-sm-10">
                                    <InputVendorSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'purchaseReturnSearchForm', 'search_vendor_ids');
                                            this.setState({ searchedVendor: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                        }}
                                        isMulti={true}
                                        value={this.state.searchedVendor.length > 0 ? this.state.searchedVendor.map(a => { a.label = a.display_label; return a; }) : []}
                                        containerHeight="30px"
                                        fontSize="93%"

                                    />
                                </div>


                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                <div className="col-sm-10">
                                    <InputItemSearch
                                        placeholder="Search Item"
                                        isMulti={true}
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'purchaseReturnSearchForm', 'search_item_ids');
                                            this.setState({
                                                searchedItem: selectedOption ? selectedOption : null
                                            }, () => { });
                                        }}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                    />
                                </div>

                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm">Item Tags</label>
                                <div className="col-sm-10">
                                    <TapSelect
                                        options={this.state.allItemTags}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'purchaseReturnSearchForm', 'search_item_tags')}
                                        value={this.state.allItemTags.filter(u => this.state.purchaseReturnSearchForm?.search_item_tags?.includes(u.value))}
                                        isSearchable={true}
                                        isMulti={true}
                                        isClearable={true}
                                        menuPlacement="top"
                                        placeholder="Select Item Tags"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm  ">Manufacturer</label>
                                <div className="col-sm-10">
                                    <TapSelect
                                        options={this.state.manufacturers}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'purchaseReturnSearchForm', 'search_manufacturer_ids')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}

                                        value={this.state.manufacturers.filter(s => this.state.purchaseReturnSearchForm.search_manufacturer_ids.includes(s.value))}
                                        placeholder="Select Manufacturer"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm ">Status</label>
                                <div className="col-sm-10">
                                    <TapSelect
                                        options={this.state.allStatusOptions}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'purchaseReturnSearchForm', 'search_status_ids')}
                                        value={this.state.allStatusOptions.filter(u => this.state.purchaseReturnSearchForm?.search_status_ids?.includes(u.value))}
                                        isMulti={true}
                                        isClearable={true}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>

                            </div>

                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                <div className="col-sm-10">
                                    <PeriodSelectionWithCustom
                                        periods={this.state.all_periods && this.state.all_periods !== undefined ? this.state.all_periods : []}
                                        value={this.state.purchaseReturnSearchForm.date_range}
                                        startDate={this.state.purchaseReturnSearchForm.date_range_start}
                                        endDate={this.state.purchaseReturnSearchForm.date_range_end}
                                        onSelectPeriod={(period, startDate, endDate) => {
                                            this.setState({
                                                purchaseReturnSearchForm: {
                                                    ...this.state.purchaseReturnSearchForm,
                                                    date_range: period,
                                                    date_range_start: startDate ? startDate : null,
                                                    date_range_end: endDate ? endDate : null
                                                }
                                            });
                                        }}
                                        className="form-control-sm"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" disabled={this.state.listFilterFormSubmitting} data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary" form={this.listFilterFormModalId + 'Form'} disabled={this.state.listFilterFormSubmitting} >Save {this.state.listFilterFormSubmitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
    }



    render                              =   ()  =>  {
        return <ApplicationLayout>
            <Helmet><title>Purchase Return Listing</title></Helmet>
            {this.purchaseReturnListjsx()}
            <ItemDetailsModal ref={this.itemDetailsRef} />
            {this.listFilterFormModalJsx()}
        </ApplicationLayout>;
    }

}



const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []

    };

//         all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
//         all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
//         isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
//         permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []

};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PurchaseReturnList);