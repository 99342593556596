import React, { forwardRef, useRef, useEffect, useState, useImperativeHandle } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import TapIcon from '../../services/TapIcon';
import Loader from '../../components/ui/Loader/Loader';
import Status from '../../components/ui/Status';
import { useSelector } from 'react-redux';
import HttpAPICall from '../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { Collapse } from 'bootstrap';
import { useParams } from 'react-router-dom';
import ProductivityList from '../transactions/productivity/ProductivityList';
import dashboardStyles from "../dashboard/Dashboard.module.css"
import AssetProductivityBarWidget from './AssetProductivityBarWidget';
import AssetProductivityPieWidget from './AssetProductivityPieWidget';
import AssetProductivityCumutativeWidget from './AssetProductivityCumutativeWidget';
import AssetProductivityStandAloneWidget from './AssetProductivityStandAloneWidget';
import DateRangeService from '../../services/DateRangeService';

const AssetProdConfigDetail                  =   forwardRef((props, ref) => {

    const accessToken               =   useSelector(state => state.auth.access_token);

    const configUrl                 =   IAM_API_BASE_URL_2 + '/asset_productivity_config';
    const [configDetailsData, setConfigDetailsData] =   useState({loading : false, data : null});
    const [activeTab, setActiveTab]                 =   useState('dashboard');

    const productivityListRef       =   useRef(null);
    const { id }                    =   useParams();


    useEffect(() => {
        props.getActiveTab && props.getActiveTab(activeTab);
    }, [activeTab])

    useEffect(() => {
        configDetailApi();
    }, [id]);


    useImperativeHandle(ref, () => ({

        productivityListHandler: (type='open_modal') => {
            productivityListActionHandler(type);
        }
    }));

    const configDetailApi   =   () => {        
        const {data}            =   props;       
        
        if(data || id) {
            const configId      =   id || data?.id;  
            setConfigDetailsData(pre => ({...pre, loading : true}));
            HttpAPICall.withAthorization('GET', configUrl + '/detail/' + configId, accessToken, {}, {}, (res) => {
                const respData      =   res.data;
                setConfigDetailsData(pre => ({
                    ...pre, 
                    data : respData, 
                }));
                props.handleDetailData(respData);
                props.afterDetailApi();
            })
            .then(() => setConfigDetailsData(pre => ({...pre, loading : false})))
            .catch(() => setConfigDetailsData(pre => ({...pre, loading : false})));

        }
    }

    const productivityListActionHandler         =   (type='open_modal')  =>  {
        if(productivityListRef.current) {
            if(type=='open_modal') {
                productivityListRef.current.productivitySearchModalInit();
            }
            if(type=='download') {
                productivityListRef.current.productivityDownloadHandler();
            }
        }
    } 


    const detailTableJsx   =   ()  =>  {
        const {data}                =   configDetailsData;
        return (
            <table className="table table-hover table-bordered  table-responsive bg-white mb-0  mt-3 "> 
                <tbody>
                    <tr>
                        <td style={{ width: "33%" }}>Configuration</td>
                        <th>{data?.name}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Activity Name</td>
                        <th>{data?.activity_detail?.activity_name}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Unit of Measurement</td>
                        <th>{data?.uom?.measuring_unit}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Sites</td>
                        <th>
                            {data && data.sites.length > 0 ? data.sites.map(s => s.site_name).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Asset Type</td>
                        <th>
                            {data && data.asset_type.length > 0 ? data.asset_type.map(at => at.name).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Asset Matrix</td>
                        <th>
                            {data && data.asset_matrix.length > 0 ? data.asset_matrix.map(am => am.name).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Manufacturer</td>
                        <th>
                            {data && data.manufacturers.length > 0 ? data.manufacturers.map(m => m.name).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Model</td>
                        <th>
                            {data && data.models.length > 0 ? data.models.map(m => m.name).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Asset Variants</td>
                        <th>
                            {data && data.asset_variants.length > 0 ? data.asset_variants.map(av => av).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Status</td>
                        <th>{data?.status ? <Status color={data?.status.status_color_code}>{data?.status.status_text ? data?.status.status_text : ""}</Status> : ""}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Material</td>
                        <th>
                            {data && data.materials.length > 0 ? data.materials.map(m => m.material_name).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Attachment</td>
                        <th>
                            {data && data.attachments.length > 0 ? data.attachments.map(a => a.name).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>WorkStation</td>
                        <th>
                            {data && data.workstations.length > 0 ? data.workstations.map(w => w.workstation_name).join(', ') : ''}
                        </th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Accounting Code</td>
                        <th>{data?.account_code?.name}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Starting Date</td>
                        <th>{data?.starting_date}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Closing Date</td>
                        <th>{data?.closing_date}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Target Type</td>
                        <th>{data?.target_type}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Total Productivity Target</td>
                        <th>{data?.total_productivity_target}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Target Per Day</td>
                        <th>{data?.target_per_day}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Cost Per Unit Target</td>
                        <th>{data?.cost_per_unit_target}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "33%" }}>Notes</td>
                        <th>{data?.notes}</th>
                    </tr>
                </tbody>
            </table>
        )
    }

    return (
        <>
            <section className="" style={{ overflowY: "auto" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li>
                            <button className={`nav-link text-capitalize ${activeTab === 'dashboard' ? 'active' : ''}`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected={activeTab === 'dashboard'} onClick={() => setActiveTab('dashboard')}>Dashboard</button>

                        </li>
                        <li>
                            <button className={`btn nav-link text-capitalize ${activeTab === 'configuration' ? 'active' : ''}`} id="configuration-tab" data-bs-toggle="tab" data-bs-target="#configuration" type="button" role="tab" aria-controls="configuration" aria-selected={activeTab === 'configuration'} onClick={() => setActiveTab('configuration')}>Configuration
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`btn nav-link text-capitalize ${activeTab === 'asset_productivity' ? 'active' : ''}`} id="asset-productivity-tab" data-bs-toggle="tab" data-bs-target="#asset_productivity" type="button" role="tab" aria-controls="asset-productivity" aria-selected={activeTab === 'asset_productivity'} onClick={() => setActiveTab('asset_productivity')}>Asset Productivity</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                        {/* */}
                            <div style={{ height: "75vh" }} className={dashboardStyles.tab_content_header}>
                                <div className='col-md-8'>
                                    {
                                        configDetailsData.loading
                                        ? 
                                        <Loader/>
                                        :
                                        <>
                                        <AssetProductivityBarWidget configDetailsData={configDetailsData.data}/>
                                        <AssetProductivityPieWidget configDetailsData={configDetailsData.data}/>
                                        <AssetProductivityCumutativeWidget configDetailsData={configDetailsData.data}/>
                                        <AssetProductivityStandAloneWidget configDetailsData={configDetailsData.data}/>
                                        </>
                                    }
                                    {/* 
                                    <SiteProductivityLineWIdget configId={this.state.configurationData?.id} />
                                    <SiteProductivityTargetWidget configId={this.state.configurationData?.id} /> */}
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'configuration' ? 'show active' : ''}`} id="configuration" role="tabpanel" aria-labelledby="configuration-tab" > 
                            <div className="tab_content_header mt-1" style={{ height: "calc(100vh - 165px)" }}> 
                                {
                                    activeTab === 'configuration' 
                                    ? 
                                    (configDetailsData.loading 
                                        ? <Loader/>
                                        :
                                        detailTableJsx())
                                    : ''
                                }
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab.asset_productivity ? 'show active' : ''}`} id="asset_productivity" role="tabpanel" aria-labelledby="asset-productivity-tab">
                            { activeTab === 'asset_productivity' && <ProductivityList ref={productivityListRef} runBy={'asset_prod_config'} configDetailsData={configDetailsData.data} /> }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
});

export default AssetProdConfigDetail;



