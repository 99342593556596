import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import DatePicker from "react-datepicker";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { Collapse } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import HttpAPICall from "../../../services/HttpAPICall";
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import Loader from "../../../components/ui/Loader/Loader";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class DailyProgressReport extends AppBaseComponent {

    constructor() {
        super();

        this.reportUrl              =   IAM_API_BASE_URL_2 + '/report/dpr';
        this.formDataUrl            =   IAM_API_BASE_URL_2 + '/report/dpr/form_data';


        this.dailyProgressFilterFormInitialState   =   {
            search_site_ids                               :   [],
            'asset_group_ids'                             :    [],
            search_asset_type_ids                         :   [],
            search_asset_matrix_ids                       :   [],
            search_manufacturer_ids                       :   [],
            search_models                                 :   [],
            search_variant                                :   [],
            search_asset_ids                              :   [],
            search_asset_type                             :   '',
            date                                          :   '',
        };

        this.dailyProgressSearchFormLabel        =    {
            search_site_ids                               :   'Sites : ',
            'asset_group_ids'                             :   'Asset Type Groups : ',
            search_asset_type_ids                         :   'Asset Type : ',
            search_asset_matrix_ids                       :   'Asset Matrix : ',
            search_manufacturer_ids                       :   'Manufacturer : ',
            search_models                                 :   'Models : ',
            search_variant                                :   'Variants : ',
            search_asset_ids                              :   'Assets : ',
            search_asset_type                             :   'Asset Type : ',
            date                                          :   'Date  :  ',
            search_asset_category                        :   'Asset Category : '
        }

        this.state                                   =   {
            showSaveDailyProgressBtn                     :   false,
            showDownloadDailyProgressBtn                 :   false,
            dailyProgressDownloading                     :   false,
            showSaveDailyProgressForm                    :   false,
            listing_loading                              :   false,
            listingData                                  :   [],
            listingMeta                                  :   null,
            iam_asset_types                              :   [],
            iam_user_sites                               :   [],
            iam_asset_matrix                             :   [],
            iam_manufactures                             :   [],
            iam_asset_variants                           :   [],
            iam_models                                   :   [],
            searchedAsset                                :   null,
            searchedAssets                               :   [],
            allAssetCategory                             :  [],
            selectedPeriod                               :   '',
            downloadReportBtn                            :   false,
            reportDownloading                            :   false,
            listing_tbl_page                             :   1,
            formSearchedElems                            :   [],
            permissions                                   :  null,
            dailyProgressFilterForm                      :   {...this.dailyProgressFilterFormInitialState},
            submittedDailyProgressFilterForm              :   {...this.dailyProgressFilterFormInitialState},
            allAssetTypesGroupsList                     :   [],
            reportData                          :   null,                  
        };

    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        this.dailyProgressFormData(this.props)
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    dailyProgressFormData            =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.formDataUrl,
            this.props.access_token, null, null, (response) => {
            this.setState({
                allAssetCategory              :   response.data.asset_category && response.data.asset_category.length > 0 ? response.data.asset_category.map(ac => { return {value: ac.key, label: ac.name}}) : [],
                formDataLoaded                :   true,
            });
            
            let ParamObject                 =   new URLSearchParams(this.props.location.search);
            
            if(ParamObject.get('asset_id')) {
                let yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD')
                let updateFilterForm        =   {date : yesterday,search_asset_ids : [ParamObject.get('asset_id')]};
                
                this.setState({dailyProgressFilterForm :   {...this.state.dailyProgressFilterForm, ...updateFilterForm},
                                searchedAssets         :   [{value : ParamObject.get('asset_id'),
                            display_label              :  `${ParamObject.get('asset_name')} (${ParamObject.get('asset_code')})`}]},() => this.reportPerformanceFilterFormSubmit(),this.reportFilterhandler(false));
            }else{
                 this.reportFilterhandler(true);
            }
            
        });
    }

    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('dailyProgressFilterFormJsx'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    initilaizeFormFilter                    =   (props)  =>  {
        const report_name   =   'daily_status_report';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];

        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            permissions             :   props.permissions,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_asset_matrix        :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
            iam_manufactures        :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
            iam_asset_variants      :   props.iam_asset_variants ? props.iam_asset_variants.map((v) => { return {value: v, label: v}}) : [],
            iam_models              :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        });
    }

    reportPerformanceFilterFormSubmit       =   (event)  =>  {
        event && event.preventDefault()
        event && this.reportFilterhandler(true);
        let serachFormKeys                      =   Object.keys(this.state.dailyProgressFilterForm);

        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.dailyProgressSearchFormLabel[key];
            let value                       =   this.state.dailyProgressFilterForm[key];
            if(value && value.length > 0) {
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_category') {
                        show_val            = this.state.allAssetCategory.filter((s) => value.includes(s.key)).map(s => s.label);
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date') {
                        show_val            = moment(this.state.dailyProgressFilterForm.date).format('DD-MMM-YYYY');
                    }
                    searchedElems.push(label + show_val);
                }
            }
        });

        this.setState({
            submittedDailyProgressFilterForm    : {...this.state.dailyProgressFilterForm},
            formSearchedElems                   :  searchedElems,
            assetSearchParams                   :  assetSearchParams
        }, () => {
            this.loadListingTblData(1);
        });
    }

    loadListingTblData                      =   (page = 1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listing_tbl_page        :   page,
            downloadReportBtn       :   false,
            reportDownloading       :   false
        });
        
        let params                          =   {page:page,...this.state.submittedDailyProgressFilterForm};
        HttpAPICall.withAthorization('GET',this.reportUrl, this.props.access_token,params,{},(response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                downloadReportBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {
            this.reportFilterhandler();
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    reportDownloadHandler                  =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedDailyProgressFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCalculator} /> Daily Progress Report</h3>
                <div className="text-end mt15">
                    {this.state.downloadReportBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                     <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button> 
                </div>
            </div>
        </div>);
    }

    dailyProgressFilterFormJsx            =   ()  =>  {
      
        return (<div id="dailyProgressFilterFormJsx" className="bg-white collapse" >
            <form onSubmit={this.reportPerformanceFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'dailyProgressFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.dailyProgressFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    dailyProgressFilterForm: {
                                        ...this.state.dailyProgressFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.dailyProgressFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'dailyProgressFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.dailyProgressFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'dailyProgressFilterForm', 'search_asset_matrix_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.dailyProgressFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'dailyProgressFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.dailyProgressFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'dailyProgressFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.dailyProgressFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'dailyProgressFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            search_asset_type={[this.state.dailyProgressFilterForm.search_asset_type]}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Category</label>
                    <div className="col-sm-6">
                        <TapSelect
                            menuPlacement='top'
                            options={this.state.allAssetCategory}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'dailyProgressFilterForm', 'search_asset_category');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.allAssetCategory.find(r => r.value === this.state.dailyProgressFilterForm.search_asset_category)}
                            placeholder="Select Asset Category"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Date </label>
                    <div className="col-sm-6 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.dailyProgressFilterForm.date
                                ? moment(this.state.dailyProgressFilterForm.date).toDate()
                                : false
                        }
                        name="date"
                        onChange={(value, event) => this.formDateHandler('date', value, 'dailyProgressFilterForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Date `}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }

    dailyProgressDisplayJsx               =    ()  =>    {
        return (<div className="bg-white">
            { this.state.listing_loading ? <Loader /> : null }
           { !this.state.listing_loading && this.state.downloadReportBtn == true
                ? this.dailyProgressTableJsx() : null
            }
        </div>)
    }

    dailyProgressTableJsx                          =   ()  =>  {

        return (<Ax>
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th style={{ width: "15%" }}>Asset Name </th>
                        <th style={{ width: "10%" }}>Code</th>
                        <th style={{ width: "15%" }}>Asset Type</th>
                        <th style={{ width: "15%" }}>Site Name</th>
                        <th style={{ width: "9%" }} className='text-center'>Hours</th>
                        <th style={{ width: "9%" }} className='text-center'>Trips/Sessions </th>
                        <th style={{ width: "9%" }} className='text-center'>Productivity</th>
                        <th style={{ width: "9%" }} className='text-center'>Diesel </th>
                        {this.state.permissions.group_modules.includes("isc") ?
                            <th style={{ width: "9%" }} className='text-end'>Item Expense</th>
                            : null}
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData.length > 0
                        ? (this.state.listingData.map((data) => {
                            return (
                                <tr>
                                    <td className={"fw-bold"}>{data.name}</td>
                                    <td>{data.asset_code}</td>
                                    <td>{data.asset_type_name}</td>
                                    <td>{data.site} ({data.site_code})</td>
                                    <td className='text-center'>{data.running
                                        ? data.running.running_hours && data.running.running_hours !== 0
                                            ? data.running.running_hours_display : data.running.running_odometer && data.running.running_odometer !== 0
                                                ? data.running?.running_odometer_display : data.running?.running_cycle_display
                                        : "-"}</td>
                                    <td className='text-center'>{data.activity ? data.activity : "-"}</td>
                                    <td className='text-center'>{data.productvity ? data.productvity : "-"}</td>
                                    <td className='text-center'>{data.fuel_feed_in ? <span>{data.fuel_feed_in} {data.fuel_unit}</span> : "-"}</td>
                                    {this.state.permissions.group_modules.includes("isc") ?
                                        <td className='text-end'>{data.spare_parts_amount ? data.spare_parts_amount : "-"}</td>
                                        : null}
                                </tr>
                            )
                        }))
                        : <tr><td className="text-center" colSpan={9}>No Record Found</td></tr>
                    }
                </tbody>
            </table>
        </Ax>)
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Daily Progress - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.dailyProgressFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.dailyProgressDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_asset_variants      :   state.app && state.app.asset_variants ? state.app.asset_variants : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(DailyProgressReport);