import { connect } from "react-redux";
import { Modal } from "bootstrap";
import swal from "sweetalert";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import React from "react";
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import { ISC_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import { Link } from 'react-router-dom';

class ManufacturerConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.manufacturesUrl    =   ISC_API_BASE_URL_2 + "/manufactures";  
        

        this.attrInitForm       =   {
            name                 :       "",
            brand_name           :       '',
            description          :       '',
        }
        this.state               =        {
            addAttrListing       :        [],
            openForm             :        false,
            listing_loading      :        false,
            editFormSubmiting    :        false,
            selectedAttribute    :         null,
            listingMeta          :        [],
            totalListingCount    :         0,
            listing_tbl_page     :         1,
            addAttrForm          :       { ...this.attrInitForm },
        }

    }

    componentDidMount() {
        this.getAddAttrListing(1)
        this.addManufacturerAttrInit            =           new Modal(document.getElementById('addManufacturerAttrModal'), { keyboard: false, backdrop: false });
        if(this.props.openForm === true){
            this.addAttrModalInit()
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.openForm !== this.props.openForm) {
            this.addAttrModalInit()
        }
    }

    getAddAttrListing            =           (page = 1)         =>      {
        this.setState({ listing_loading: true , listing_tbl_page: page})
        HttpAPICall.withAthorization('GET', this.manufacturesUrl, this.props.access_token, {page:page,per_page:20}, {}, (resp) => {
            this.setState({ addAttrListing   :  resp.data.data , 
                            listingMeta      :   {
                                from         :  resp.data.meta.from,
                                to           :  resp.data.meta.to,
                                total        :  resp.data.meta.total,
                                last_page    : resp.data.meta.last_page,
                                current_page : resp.data.meta.current_page
                            },
                           totalListingCount :  resp.data.meta.total})
        }).then(() => this.setState({ listing_loading: false }));
    }

    addAttrModalInit             =           (id)        =>         {
        this.addManufacturerAttrInit.show()
        if(id !== null && id !== undefined){
            this.setState({edit : true})
            let manufacturerList = this.state.addAttrListing.find(function (manufacture) {
                return manufacture.id == id;
            });
            this.setState({
                addAttrForm :   {
                    name          :    manufacturerList.name,
                    id            :    manufacturerList.id,
                    description   :    manufacturerList.description !== null ? manufacturerList.description  : "-",
                    brand_name    :    manufacturerList.brand_name  !== ""  ? manufacturerList.brand_name   : "-"
                }
            })
        }else{
            this.setState({edit : false})
        }
    }

    closeAttrForm       =       ()       =>      {
        this.setState({ addAttrForm: this.attrInitForm })
        this.props.closeForm()
    }


    addAttrModalJsx         =          ()      =>       {
        return (
            <div className="modal fade" id="addManufacturerAttrModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.edit ? "Edit" : "Add"} Manufacturer</h5>
                        </div>
                        <form onSubmit={this.submitManufacturerAttrForm}>
                            <div className="modal-body p-3">
                                <div className="row align-items-center my-3">
                                    <div className="col-md-3"><label className="form-label require">Manufacturer Name</label></div>
                                    <div className="col-md-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addAttrForm.name}
                                            onChange={(e) => this.formInputHandler(e, 'addAttrForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            required={true}
                                            
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mt-3 mb-1">
                                    <div className="col-md-3"><label className="form-label">Common Name</label></div>
                                    <div className="col-md-9">
                                        <input
                                            name="brand_name"
                                            type="text"
                                            value={this.state.addAttrForm.brand_name}
                                            onChange={(e) => this.formInputHandler(e, 'addAttrForm')}
                                            className="form-control"
                                            autoComplete="off" 
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mt-3 mb-1">
                                    <div className="col-md-3"><label className="form-label">Notes</label></div>
                                    <div className="col-md-9">
                                        <textarea
                                            name="description"
                                            type="text"
                                            value={this.state.addAttrForm.description}
                                            onChange={(e) => this.formInputHandler(e, 'addAttrForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            style={{height: "65px",width:"100%"}}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal" onClick={this.closeAttrForm}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    submitManufacturerAttrForm         =           (e)     =>          {
        e.preventDefault()
        this.setState({ editFormSubmiting: true });
        if (this.state.edit === true) {
            let frmData = {...this.state.addAttrForm}
            HttpAPICall.withAthorization('PUT', ISC_API_BASE_URL_2 + '/manufacture', this.props.access_token, {}, { ...frmData}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addManufacturerAttrInit.hide();
                this.getAddAttrListing();
                this.props.closeForm()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ editFormSubmiting: false }))
        } else {
            let frmData = {...this.state.addAttrForm}
            HttpAPICall.withAthorization('POST', ISC_API_BASE_URL_2 + '/manufacture', this.props.access_token, {}, { ...frmData}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addManufacturerAttrInit.hide();
                this.getAddAttrListing();
                this.props.closeForm()
                this.setState({addAttrForm: this.attrInitForm, edit: false })
            }).then(() => this.setState({ editFormSubmiting: false }))
        }
    }

    deleteManufacturerAttr                  =           (id)            =>          {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                        HttpAPICall.withAthorization('DELETE', ISC_API_BASE_URL_2 + '/manufacture/' + id, this.props.access_token, {  }, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getAddAttrListing(this.state.listing_tbl_page)
                    }).then(() => { });
                }
            });
    }

    render          =       ()      =>      {
        return (<div className="page_containt">
            <div className="pageTbl">
               <div className="pr-2">
               <table className="table table-hover table-bordered bg-white table-fixed table-sm ">
                    <thead className="table-secondary">
                        <tr className="text-center">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-start">Name</th>
                            <th scope="col" style={{ width: "25%" }} className="text-start">Common Name</th>
                            <th scope="col" style={{ width: "35%" }} className="text-start">Notes</th>
                            <th scope="col" style={{ width: "10%" }} >Action</th>

                        </tr>
                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? (<tr><td colSpan="5"><Loader /></td></tr>)
                            : (this.state.addAttrListing.length > 0
                                ? (this.state.addAttrListing.map((item, k) => {
                                    return (<tr key={k} className="text-center">
                                        <td>{this.state.listingMeta ? this.state.listingMeta.from + k : k}</td>
                                        <td className="text-capitalize text-start">{item.name}</td>
                                        <td className="text-capitalize text-start">{item.brand_name}</td>
                                        <td className="text-start">{item.description ? item.description : "-"}</td>
                                        <td>  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                            role="button" aria-expanded="false" />
                                            <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                                <li>
                                                    <Link role="button"
                                                        onClick={() => {
                                                            if (this.props?.permissions?.includes('isc-item-manufacturer-edit')) {
                                                                return this.addAttrModalInit(item.id)
                                                            }
                                                        }}
                                                        className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-item-manufacturer-edit') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props?.permissions?.includes('isc-item-manufacturer-edit') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Edit</Link>
                                                </li>
                                                <li>
                                                    <Link role="button"
                                                        onClick={() => {
                                                            if (this.props?.permissions?.includes('isc-item-manufacturer-delete')) {
                                                                return this.deleteManufacturerAttr(item.id)
                                                            }
                                                        }}
                                                        className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-item-manufacturer-delete') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props?.permissions?.includes('isc-item-manufacturer-delete') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Delete</Link>
                                                </li>
                                                
                                            </ul></td>
                                    </tr>);
                                }))
                                : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                            )
                        }
                    </tbody>
                </table>
                <div className="row" style={{ zIndex: 999 }}>
                    <div className="col">
                        {this.state.listingMeta ? `Showing ${this.state.listingMeta.from || 0} to ${this.state.listingMeta.to || 0} of ${this.state.totalListingCount} entries` : ``}
                    </div>
                    <div className="col">
                        <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            pageCount={this.state.listingMeta?.last_page ?? 0}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={0}
                            onPageActive={this.state.listingMeta?.current_page ?? 1}
                            onPageChange={(e) => this.getAddAttrListing(e.selected + 1)}
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination justify-content-end"
                            pageClassName="page-item"
                            nextClassName="page-item"
                            previousClassName="page-item"
                            pageLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                </div>
               </div>

                {this.addAttrModalJsx()}
            </div>
        </div>);
    }

}


const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(ManufacturerConfiguration);