import React from 'react';
import { connect } from 'react-redux';
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL_2, INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from '../../../components/hoc/Ax';
import tapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import AppBaseComponent from '../../../components/AppBaseComponent';
import uuid from 'react-uuid';
import Vendor from '../includes/Vendor';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';

class DestinationEditModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.manualAddressInitialState    =   { 
            address1                        :   '',
            address2                        :   '',
            landmark                        :   '',
            city                            :   '',
            country_id                      :   '',
            state_id                        :   null,
            district_name                   :   null,
            pincode                         :   null,
            gstin                           :   '',
        }

        this.state                      =   {
            editFormSubmitting              :   false,
            loading_form                    :   false,
            addNewDistrict                  :   false,
            addNewPinCode                   :   false,

            destination_detail_loading           :   false,

            destinationDetailsLoading            :   false,
            destinationVendorDetailsLoading      :   false,
            destinationVendorDetails             :   null,
            destinationVendorAddressList         :   [],
            destinationSelectedVendorAdress      :   null,
            destinationSearchedVendor            :   null,
            searchedDestinationCustomer          :   null,

            manualAddress                   :   {...this.manualAddressInitialState},
            allStateList                    :   [],
            allCountryList                  :   [],
            allDistrictList                 :   [],
            allPincodeList                  :   [],
            
            all_warehouses                  :   [],
            modalType                       :   '', 
            logisticDetail                  :   {},   
            transport_days                  :   '',    
        };
       
        this.modalId                =   uuid();   
        this.logisticEditUrl        =   APP_BASE_URL_2 + '/logistic/edit';
        this.logisticFormDataUrl    =   APP_BASE_URL_2 + '/logistic/form_data';
        this.stateListUrl           =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl        =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl            =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.warehouseUrl           =   ISC_API_BASE_URL_2  +   '/warehouse';
        this.customerURL            =   INVENTORY_API_BASE_URL_2 + '/customer';   
       
    }
    
  
    componentDidMount(){
        this.addModal                  =   new Modal(document.getElementById(this.modalId), {keyboard: false, backdrop :false});
    }

    showModalInit           =   (type)  =>  {  
        const logisticDetail = this.props.logisticDetail;
        this.setState({
            loading_form    :   true,
            modalType       :   type,
            logisticDetail  :   {...logisticDetail},
        }, () => {
            this.addModal.show();
        });

        if(logisticDetail.destination_type === 'vendor') {
            this.setState({
                destinationSearchedVendor:   logisticDetail.destination_vendor_id && {value : logisticDetail.destination_vendor_id, display_label : logisticDetail.destination_type_detail ? `${logisticDetail.destination_type_detail.name} (${logisticDetail.destination_type_detail.code})` : ''}
            })
        }

        if(logisticDetail.destination_type === 'customer') {
            this.setState({
                searchedDestinationCustomer:   logisticDetail.destination_customer_id && [{value : logisticDetail.destination_customer_id, label : logisticDetail.destination_type_detail && `${logisticDetail.destination_type_detail.name}`}]
            })
        }

        if(logisticDetail.destination_type === 'manual_entry') {
            this.setState({
                manualAddress   :   {...logisticDetail.display_destination_address},
            });
        }

        HttpAPICall.withAthorization('GET', this.logisticFormDataUrl, this.props.access_token, null, null, (response) => {
            const formData                   =   response.data;
            this.setState({
                all_warehouses              :    this.props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),   
                allCountryList             :   formData && formData.country ?  formData.country.map((s) => { return {value: s.id, label: s.name}; }) : [],     
            });
        }).then(() => { this.setState({ loading_form: false}) });
    }

    countryChangeHandler              =        (selected_country)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    allOperationsStateList      :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    manualAddress               :    {...this.state.manualAddress,state_id: '',district_name : '',pincode:''},
                    addNewDistrict              :   false ,
                    addNewPinCode               :   false 
                });
            });
        }
        
    }

    //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
    stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList             :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    manualAddress               :    {...this.state.manualAddress,pincode:''}
                });
            });
        }
    }

      //FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT
      districtChangeHandler              =        (selected_district)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            let params = {district_name : selectedDistrict,state_id:this.state.manualAddress.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList              :   response.data.map((s) => { return {value: s.pincode, label: s.pincode}; }),
                    addNewPinCode               :   response.data && response.data.length > 0 ? false : true,
                });
            });
        }
    }

    //********************** Handle Destination Type Radio Button *******************/
    handleDestinationType                  =   (destination_type) => {
        if(destination_type === "warehouse") {
            this.setState({
                logisticDetail         :   {
                    ...this.state.logisticDetail,
                    destination_type             :   destination_type,
                    destination_vendor_id        :   null,
                    destination_customer_id      :   null,
                    destination_address          :   null,
                    display_destination_address  :   null,
                },
                destinationManualChecked         :   false,
            });
        }else if(destination_type === "vendor") {
            this.setState({
                logisticDetail         :   {
                    ...this.state.logisticDetail,
                    destination_type             :   destination_type,
                    destination_warehouse_id     :   null,
                    destination_customer_id      :   null,
                    destination_address          :   null,
                    display_destination_address  :   null,
                },
                destinationManualChecked         :   false,
            });
        }else if(destination_type === "customer") {
            this.setState({
                logisticDetail         :   {
                    ...this.state.logisticDetail,
                    destination_type             :   destination_type,
                    destination_vendor_id        :   null,
                    destination_warehouse_id     :   null,
                    destination_address          :   null,
                    display_destination_address  :   null,
                },
                destinationManualChecked         :   false,
            });
        }else {
            this.setState({
                logisticDetail         :   {
                    ...this.state.logisticDetail,
                    destination_type             :   destination_type,
                    destination_vendor_id        :   null,
                    destination_warehouse_id     :   null,
                    destination_customer_id      :   null,
                    destination_address          :   null,
                    display_destination_address  :   null,
                },
            });
        }
    }

    //submit add Destination Form
    editLogisticFormSubmit      =   (e) =>  {
        e.preventDefault();
        this.setState({editFormSubmitting : true});
 
         const data = {...this.state.logisticDetail};
 
         HttpAPICall.withAthorization('PUT', this.logisticEditUrl, this.props.access_token, null, data, (response) => {
             toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
             this.props.getLogisticViewDetails(this.state.logisticDetail.transaction_id);
             this.addModal.hide();
         }).then(() => this.setState({ editFormSubmitting: false }));
 
     }

    //********************Handle Destination VENDOR ******************************* */
    handleDestinationVendor     =   (selectedOption,edit=false) => {
        this.setState({
            logisticDetail             :   {
                ...this.state.logisticDetail,
                destination_vendor_id            :   selectedOption ? selectedOption.value : null
            },
            destinationVendorDetails             :   selectedOption ? selectedOption : null,
            searchedVendorAdressList        :   selectedOption && selectedOption.vendor ? selectedOption.vendor.addressList : null,
            destinationSelectedVendorAdress      :   selectedOption && selectedOption.vendor && edit == false ? selectedOption.vendor.addressList[0] : this.state.destinationSelectedVendorAdress,
            destinationSearchedVendor   :     selectedOption ? selectedOption : null
        },() => {
            if(edit == false){
                this.setState({
                   gst_not_applicable          :      this.state.destinationSelectedVendorAdress && this.state.destinationSelectedVendorAdress.country_id !== 99 ? 'Y' : 'N'
                })
           }
        })
    }

    //********************GET Destination VENDOR DETAILS ******************************* */
    getDestinationVendorDetails     =   (id,edit=false) =>  {

        if(id) {
            this.setState({destinationVendorDetailsLoading : true})
            HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/vendor' + '/' + id, this.props.access_token, null, null, (response) => {
                let destinationSelectedVendorAdress   =   response.data.data && response.data.data.addressList ? response.data.data.addressList[0] : null;

                const address1      =   destinationSelectedVendorAdress ? destinationSelectedVendorAdress.address1 : null; 
                const address2      =   destinationSelectedVendorAdress.address2; 
                const landmark      =   destinationSelectedVendorAdress ? destinationSelectedVendorAdress.landmark : null; 
                const city          =   destinationSelectedVendorAdress ? destinationSelectedVendorAdress.city : null;  
                const country_id    =   destinationSelectedVendorAdress ? destinationSelectedVendorAdress.country_id : null; 
                const state_id      =   destinationSelectedVendorAdress ? destinationSelectedVendorAdress.state_id : null; 
                const district_id   =   destinationSelectedVendorAdress ? destinationSelectedVendorAdress.district_id : null; 
                const gstin         =   destinationSelectedVendorAdress ? destinationSelectedVendorAdress.gstin : null; 
                const pincode       =   destinationSelectedVendorAdress ? destinationSelectedVendorAdress.pincode : null; 
                
                const logisticDetail    =   this.state.logisticDetail;

                this.setState({
                    destinationVendorDetails            :   response.data.data,
                    destinationVendorAddressList        :   response.data.data.addressList,
                    destinationSelectedVendorAdress     :   edit == false ? destinationSelectedVendorAdress : this.state.destinationSelectedVendorAdress,

                    logisticDetail                 :   {
                        id              :   logisticDetail.id,
                        transaction_id  :   logisticDetail.transaction_id,
                        destination_vendor_id : id,
                        destination_type : 'vendor', 
                        destination_address : {address1, address2, landmark, city, country_id, state_id, district_id, pincode, gstin},
                    }
                });
            }).then(() => {
                this.setState({ destinationVendorDetailsLoading : false });
                this.setState({gstTypeName :  this.state.destinationSelectedVendorAdress && this.state.warehouseDetail && this.state.destinationSelectedVendorAdress.state_id == this.state.warehouseDetail.state_id ? 'GST' : 'IGST'});
            });
        }
    }

    //********************GET WAREHOUSE DETAILS API ******************************* */
    getWarehouseDetails             =       (selectedOption)        =>          {

        if(selectedOption){
            let  id = selectedOption.value ? selectedOption.value : selectedOption
            
            this.setState({
                destination_detail_loading       :   true
            }); 

            HttpAPICall.withAthorization('GET',  this.warehouseUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                const address1      =   respData.data.address1; 
                const address2      =   respData.data.address2; 
                const landmark      =   respData.data.landmark; 
                const city          =   respData.data.city;   
                const country_id    =   respData.data.country_id; 
                const state_id      =   respData.data.state_id; 
                const district_id   =   respData.data.district_id; 
                const gstin         =   respData.data.gstin; 
                const pincode       =   respData.data.pincode; 

                const country       =   respData.data.country?.name; 
                const state         =   respData.data.state_name; 
                const district      =   respData.data.district_name; 

                const logisticDetail    =   this.state.logisticDetail;

                this.setState({
                    logisticDetail                  :   {
                        id      :   logisticDetail.id,
                        transaction_id  :   logisticDetail.transaction_id,
                        destination_warehouse_id : id,
                        destination_type : 'warehouse',
                        destination_address : {address1, address2, landmark, city, country_id, state_id, district_id, pincode, gstin},
                        display_destination_address : {address1, address2, landmark, city, country, state, district, pincode, gstin}
                    },
                    destination_detail_loading           :   false,
                });
            });
        }else{
            this.setState({
                destination_detail_loading           :   false,
            }); 
        }
    }

    //********************GET CUSTOMER DETAILS API ******************************* */
    getCustomerDetail       =   (selectedOption) => {
        if(selectedOption){
            const  id = selectedOption.value ? selectedOption.value : selectedOption;
            this.setState({
                destination_detail_loading       :   true
            }); 

            HttpAPICall.withAthorization('GET', this.customerURL + '/' + id, this.props.access_token, {}, {}, (resp) => {
                const respData          =   resp.data.data;
                const customerAddress   =   respData.addressList[0];     

                const address1      =   customerAddress.address1; 
                const address2      =   customerAddress.address2; 
                const landmark      =   customerAddress.landmark; 
                const city          =   customerAddress.city;   
                const country_id    =   customerAddress.country_id; 
                const state_id      =   customerAddress.state_id; 
                const district_id   =   customerAddress.district_id; 
                const gstin         =   customerAddress.gstin; 
                const pincode       =   customerAddress.pincode; 

                const country       =   customerAddress.country_name; 
                const state         =   customerAddress.state_name; 
                const district      =   customerAddress.district_name; 

                const logisticDetail    =   this.state.logisticDetail;


                this.setState({
                    destination_detail_loading           :   false,
                    logisticDetail                  :   {
                        id                          :   logisticDetail.id,
                        transaction_id              :   logisticDetail.transaction_id,
                        destination_customer_id          : id,
                        destination_type                 : 'customer',
                        destination_address              : {address1, address2, landmark, city, country_id, state_id, district_id, pincode, gstin},
                        display_destination_address      : {address1, address2, landmark, city, country, state, district, pincode, gstin}
                    },
                }); 
            });
        }else{
            this.setState({
                destination_detail_loading           :   false,
            }); 
        }
    }
   

    //*********************************ITEM ADD JSX*********************** */
    modalJsx                     =   ()  =>  {
        const logisticDetail            =   this.state.logisticDetail;
        return (
            <div className="modal fade" id={this.modalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addModalLabel">Edit {this.state.modalType}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body ">
                            {
                            this.state.loading_form 
                            ? 
                            <Loader/>
                            : 
                            <form onSubmit={this.editLogisticFormSubmit} id="editDestinationLogisticForm" method="post" encType="multipart/form-data">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-2">
                                        <label htmlFor="site_id" className="form-label ">Destination Type</label>
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <input
                                                    name="destination_type"
                                                    type="radio"
                                                    value={"warehouse"}
                                                    onChange={(e) => this.handleDestinationType('warehouse')}
                                                    checked={logisticDetail.destination_type === 'warehouse' ? true : false}
                                                    className="form-check-input "
                                                    id="destination_warehouse"
                                                />
                                                <label className="form-check-label mx-2" htmlFor="destination_warehouse">Warehouse</label>
                                            </div>
                                            <div className="col-sm-3">
                                                <input
                                                    name="destination_type"
                                                    type="radio"
                                                    value={"vendor"}
                                                    onChange={(e) => this.handleDestinationType('vendor')}
                                                    className="form-check-input"
                                                    checked={logisticDetail.destination_type === 'vendor' ? true : false}
                                                    id="destination_vendor"
                                                />
                                                <label className="form-check-label mx-2" htmlFor="destination_vendor">Vendor</label>
                                            </div>
                                            <div className="col-sm-3">
                                                <input
                                                    name="destination_type"
                                                    type="radio"
                                                    value={"customer"}
                                                    onChange={(e) => this.handleDestinationType('customer')}
                                                    className="form-check-input"
                                                    checked={logisticDetail.destination_type === 'customer' ? true : false}
                                                    id="destination_customer"
                                                />
                                                <label className="form-check-label mx-2" htmlFor="destination_customer">Customer</label>
                                            </div>
                                            <div className="col-sm-3">
                                                <input
                                                    name="destination_type"
                                                    type="radio"
                                                    value={"manual_entry"}
                                                    className="form-check-input "
                                                    id="destination_manual_entry"
                                                    onChange={(e) => this.handleDestinationType('manual_entry')}
                                                    checked={logisticDetail.destination_type === 'manual_entry' ? true : false}
                                                />
                                                <label className="form-check-label mx-2" htmlFor="destination_manual_entry">Manual Entry</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    logisticDetail.destination_type === 'warehouse' && 
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-6">
                                            <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'logisticDetail', 'destination_warehouse_id'); this.getWarehouseDetails(selectedOption);}}
                                                options={this.state.all_warehouses}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.all_warehouses.find(m => m.value == logisticDetail.destination_warehouse_id)}
                                                placeholder="Select Warehouse"
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    logisticDetail.destination_type === 'vendor' &&
                                    <div className="row align-items-center my-3">
                                    <div className="col-sm-2"></div>
                                        <div className="col-sm-6">
                                            <Vendor
                                                labelDisable={true}
                                                showAddVendor={false}
                                                vendorDetails={this.state.destinationVendorDetails}
                                                details_loading={this.state.destinationDetailsLoading}
                                                vendorAddressList={this.state.destinationVendorAddressList}
                                                vendor_details_loading={this.state.destinationVendorDetailsLoading}
                                                selectedVendorAdress={this.state.destinationSelectedVendorAdress}
                                                searchedVendor={this.state.destinationSearchedVendor}
                                                onChange={this.handleDestinationVendor}
                                                getVendorDetails={this.getDestinationVendorDetails}
                                                // changeAddressHandler={this.changeAddressHandler}
                                            />
                                        </div>
                                    </div>
                                }
                                {    
                                    logisticDetail.destination_type === 'customer' &&

                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-6">
                                            <InputCustomerSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'logisticDetail', 'destination_customer_id');
                                                    this.getCustomerDetail(selectedOption);
                                                    this.setState({ searchedDestinationCustomer: selectedOption ? selectedOption : null });
                                                }}
                                                menuPlacement="top"
                                                value={this.state.searchedDestinationCustomer}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.destination_detail_loading 
                                    ?
                                    <Loader/>
                                    :
                                    (logisticDetail.display_destination_address && ['warehouse', 'customer'].includes(logisticDetail.destination_type)) 
                                    ?
                                    <div className="row align-items-center mt1 pb-2">
                                        <div className="offset-2 col-sm-8">
                                            <label className='form-label'>Address : 
                                            {
                                                logisticDetail.display_destination_address.address1 ? `${logisticDetail.display_destination_address.address1}, ` : ''
                                            }
                                            {
                                                logisticDetail.display_destination_address.address2 ? `${logisticDetail.display_destination_address.address2}, ` : ''
                                            }
                                            {
                                                logisticDetail.display_destination_address.landmark ? `${logisticDetail.display_destination_address.landmark}, ` : ''
                                            }
                                            {
                                                logisticDetail.display_destination_address.city ? `${logisticDetail.display_destination_address.city}, ` : ''
                                            }
                                            </label><br />
                                            <label className='form-label'>Destination of Supply : 
                                                <span>
                                                {
                                                    logisticDetail.display_destination_address.state ? logisticDetail.display_destination_address.state : ''
                                                }
                                                </span>
                                            </label> <br />
                                            <label className='form-label'>GSTIN : 
                                                <span>
                                                {
                                                    logisticDetail.display_destination_address.gstin ? logisticDetail.display_destination_address.gstin : ''
                                                }
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    :
                                    (
                                    (logisticDetail.destination_type === 'vendor' && logisticDetail.display_destination_address && this.state.destinationSearchedVendor)
                                    ?
                                    <div className="row align-items-center mt1 pb-2">
                                        <div className="offset-2 col-sm-8">
                                            <label className='form-label'>Address : 
                                            {
                                                logisticDetail.display_destination_address.address1 ? `${logisticDetail.display_destination_address.address1}, ` : ''
                                            }
                                            {
                                                logisticDetail.display_destination_address.address2 ? `${logisticDetail.display_destination_address.address2}, ` : ''
                                            }
                                            {
                                                logisticDetail.display_destination_address.landmark ? `${logisticDetail.display_destination_address.landmark}, ` : ''
                                            }
                                            {
                                                logisticDetail.display_destination_address.city ? `${logisticDetail.display_destination_address.city}, ` : ''
                                            }
                                            </label><br />
                                            <label className='form-label'>Destination of Supply : 
                                                <span>
                                                {
                                                    logisticDetail.display_destination_address.state ? logisticDetail.display_destination_address.state : ''
                                                }
                                                </span>
                                            </label> <br />
                                            <label className='form-label'>GSTIN : 
                                                <span>
                                                {
                                                    logisticDetail.display_destination_address.gstin ? logisticDetail.display_destination_address.gstin : ''
                                                }
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    : null
                                    ) 
                                }
                                {
                                    logisticDetail.destination_type === 'manual_entry' &&
                                    this.manualEntryJsx()
                                }
                            </form>
                            }
                        </div>
                        {
                            this.state.logisticDetail.destination_type !== 'manual_entry' &&
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.editFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" disabled={this.state.editFormSubmitting} className="btn btn-primary" form="editDestinationLogisticForm">
                                    Submit {this.state.editFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    editDestinationManualAddressSubmit             =   (e)  =>  {
        e.preventDefault();
        this.setState({editFormSubmitting : true});

        const logisticDetail    =   this.state.logisticDetail;
        const data = {
            destination_type : logisticDetail.destination_type, 
            destination_address : this.state.manualAddress, 
            id : this.state.logisticDetail.id,
        }
        HttpAPICall.withAthorization('PUT', this.logisticEditUrl, this.props.access_token, null, data, (response) => {
             toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
             this.props.getLogisticViewDetails(logisticDetail.transaction_id);
             this.addModal.hide();
         }).then(() => this.setState({ editFormSubmitting: false }));
 


    };   


    manualEntryJsx                  =    ()  =>  {
        return (
            <Ax>
                <form id="editManualAddress" method="post" encType="multipart/form-data">
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Address Line 1</label>
                        </div>
                        <div className="col-sm-6">
                            <input
                                name="address1"
                                type="text"
                                className="form-control"
                                value={this.state.manualAddress.address1}
                                onChange={(e) => this.formInputHandler(e, 'manualAddress')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Line 1"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Address Line 2</label>
                        </div>
                        <div className="col-sm-6">
                            <input
                                name="address2"
                                type="text"
                                className="form-control"
                                value={this.state.manualAddress.address2}
                                onChange={(e) => this.formInputHandler(e, 'manualAddress')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Line 2"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Landmark</label>
                        </div>
                        <div className="col-sm-6">
                            <input
                                name="landmark"
                                type="text"
                                className="form-control"
                                value={this.state.manualAddress.landmark}
                                onChange={(e) => this.formInputHandler(e, 'manualAddress')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Landmark"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">City</label>
                        </div>
                        <div className="col-sm-6">
                            <input
                                name="city"
                                type="text"
                                className="form-control"
                                value={this.state.manualAddress.city}
                                onChange={(e) => this.formInputHandler(e, 'manualAddress')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter City"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Country</label>
                        </div>
                        <div className="col-sm-6">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'manualAddress', 'country_id');
                                    this.countryChangeHandler(selectedOption)
                                }
                                }
                                options={this.state.allCountryList}
                                isSearchable={true}
                                isClearable={false}
                                value={this.state.allCountryList.find(m => m.value == this.state.manualAddress.country_id)}
                                placeholder="Please Select Country"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label require">State (Source of Supply)</label>
                        </div>
                        <div className="col-sm-6">
                            <TapSelect
                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'manualAddress', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                options={this.state.allStateList}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                value={this.state.allStateList.find(m => m.value == this.state.manualAddress.state_id)}
                                placeholder="Please Select State"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">District</label>
                        </div>
                        <div className="col-sm-6">
                            {this.state.addNewDistrict
                                ? <input
                                    name="district_name"
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.manualAddress.district_name}
                                    onChange={(e) => this.formInputHandler(e, 'manualAddress')}
                                    maxLength="20"
                                    placeholder="Please Enter District"
                                />
                                : <TapSelect
                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'manualAddress', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                    options={this.state.allDistrictList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allDistrictList.find(m => m.value == this.state.manualAddress.district_name)}
                                    placeholder="Please Select District"
                                />}
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="addNewDistrict"
                                type="checkbox"
                                value={this.state.addNewDistrict}
                                onChange={(e) => {
                                    this.setState({
                                        addNewDistrict: !this.state.addNewDistrict,
                                        manualAddress: { ...this.state.manualAddress, district_id: '', pincode: '' }
                                    })
                                }}
                                checked={this.state.addNewDistrict}
                                className="form-check-input"
                                id="newDistrict"
                            />
                            <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">PIN Code</label>
                        </div>
                        <div className="col-sm-6">
                            {
                                this.state.addNewPinCode
                                ? <input
                                    name="pincode"
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.manualAddress.pincode}
                                    onChange={(e) => this.formInputHandler(e, 'manualAddress')}
                                    maxLength="20"
                                    placeholder="Please Enter PinCode"
                                />
                                : 
                                <TapSelect
                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'manualAddress', 'pincode') }}
                                    options={this.state.allPincodeList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allPincodeList.find(m => m.value == this.state.manualAddress.pincode)}
                                    placeholder="Please Select PIN Code"
                                />
                            }
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="addNewPinCode"
                                type="checkbox"
                                value={this.state.addNewPinCode}
                                onChange={(e) => {
                                    this.setState({
                                        addNewPinCode: !this.state.addNewPinCode,
                                        manualAddress: { ...this.state.manualAddress, pincode: '' }
                                    })
                                }}
                                checked={this.state.addNewPinCode}
                                className="form-check-input"
                                id="newPinCode"

                            />
                            <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">GSTIN</label>
                        </div>
                        <div className="col-sm-6">
                            <input
                                name="gstin"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.manualAddress.gstin}
                                onChange={(e) => this.formInputHandler(e, 'manualAddress')}
                                maxLength="20"
                                placeholder="Please Enter GSTIN Number"
                            />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" disabled={this.state.editFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Cancel</button>
                        <button onClick={this.editDestinationManualAddressSubmit} disabled={this.state.editFormSubmitting} className="btn btn-primary" >
                            Submit {this.state.editFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </div>
                </form>
            </Ax>
        )
    }
    

    render() {
        return (<Ax>{this.modalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
    };
};
    
  

export default connect(mapStateToProps,null, null, { forwardRef: true })(DestinationEditModal);