import React from "react";
import AppBaseComponent from "../../components/AppBaseComponent";
import { APP_BASE_URL_2 } from "../../services/TapApiUrls";
import { connect } from "react-redux";
import ApplicationLayout from "../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import Ax from "../../components/hoc/Ax";
import Loader from "../../components/ui/Loader/Loader";
import TapIcon from "../../services/TapIcon";
import HttpAPICall from "../../services/HttpAPICall";
import { toast } from "react-toastify";


class IntegrateVendor extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.iotIntegrationForm     =   {
            username                    :   '',
            password                    :   '',
        }

        this.state                  =   {
            listing_loading             :   false,
            iot_provider_vendor         :   [],
            listingMeta                 :   null,
            statusData                  :   [],
            iotProvider                 :   [],
            addIOTProviderForm          :   {...this.iotIntegrationForm},
            saveFormSubmitting          :   false,
            iotForm                     :   [],     
            iotFormBtn                  :   false,     
        };

        this.iotVendorUrl           =   `${APP_BASE_URL_2}/org/iot_vendor`;

    }

    componentDidMount           =   ()  =>  {
        this.initializeData();
    }

    initializeData              =   ()  =>  {

        const iot_provider_id   =   this.props.match.params.id;

        HttpAPICall.withAthorization('GET',  `${this.iotVendorUrl}/form_data`, this.props.access_token, {}, {}, (resp) => { 
            const respData      =   resp.data;
            if(respData.iot_providers.length > 0) {
                const available_keys    =   respData.iot_providers.filter(data => {
                    return data.id === iot_provider_id;
                })[0].available_keys;
                this.setState({
                    iotForm     :   available_keys,
                    iotFormBtn  :   true,
                    
                });
            }
        });
        
    };


    addIOTProviderIntegration   =   (e) => {
        e.preventDefault();

        
        this.setState({saveFormSubmitting  :   true});
        const data              =   {provider_credential : {...this.state.addIOTProviderForm, auth_type : "Basic Auth", grant_type : "password"}};
        HttpAPICall.withAthorization('PUT',  `${this.iotVendorUrl}/add/${this.props.match.params.id}`, this.props.access_token, {}, data, (resp) => { 
            toast.success(resp.data.msg, {position: toast.POSITION.TOP_RIGHT});
            setTimeout(() => {
                this.setState({
                    listing_loading : false
                }, () => {
                    this.props.history.push('/iot_integration')
                });
            }, 500);
        }).then(() => this.setState({saveFormSubmitting  :   false}));

    }

    intergrationJsx             =   ()  =>  {
        return (
            <form className="bg-white p-3" onSubmit={this.addIOTProviderIntegration} id="addIotForm">
                {this.state.formDataLoading 
                    ?
                    <Loader/>  
                    :
                    <Ax>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">IoT Integration</span>
                        </div>
                        {
                            this.state.iotForm.length > 0 &&
                            this.state.iotForm.map((data, i) =>{
                                return (
                                    <div className="row align-items-center my-3" key={i}>
                                        <div className="col-3">
                                            <label htmlFor={data.name} className="form-label require">{data.name}</label>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                name={data.key}
                                                type="text"
                                                id={data.name}
                                                className="form-control"
                                                required={true}
                                                autoComplete="off"
                                                value={data?.value}
                                                onChange={(e) => this.formInputHandler(e,"addIOTProviderForm")}
                                                placeholder={`Please Enter ${data.name}`}
                                                disabled={data.hasOwnProperty('value') ? true : false}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="col-12 text-end fixed_footer">
                        {
                            this.state.iotFormBtn && 
                            <Ax>
                                <button id="iotCancelButton" type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">
                                    Cancel
                                </button>
                                <button id="iotSaveButton" type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </Ax>
                        }
                        </div>
                    </Ax>
                }

            </form>
        );
    }

    closeEvent                  =   ()  =>  {
        this.props.history.push('/iot_integration')
    }

    render () {
        return <ApplicationLayout>
                    <Helmet><title>IoT Integration with Cognecto</title></Helmet>
                    <div className="page_title row m0">
                    <div className="col-12">
                        <h3>IoT Integration with Cognecto</h3>
                        <div className="text-end mt15">
                            <button onClick={this.closeEvent} type="button" className="btn btn-secondary">
                                <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="page_containt row pl2">
                        <div className="pageTbl col-12 pl4">
                            {this.intergrationJsx()}
                        </div>
                    </div>
                </div>
                </ApplicationLayout>
    }

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};

export default connect(mapStateToProps)(IntegrateVendor);