
import React from 'react';
import { connect } from "react-redux";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { Collapse, Modal } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import HttpAPICall from "../../../services/HttpAPICall";
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from "../../../components/TapUi/index";
import Loader from "../../../components/ui/Loader/Loader";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AssetMTTRMTBFReport extends AppBaseComponent {

    constructor() {
        super();

        this.assetMttrMtbfFilterFormInitialState = {
            site_group_ids: [],
            search_site_ids: [],
            'asset_group_ids': [],
            search_asset_type_ids: [],
            search_asset_matrix_ids: [],
            search_manufacturer_ids: [],
            search_models: [],
            search_variant: [],
            search_asset_ids: [],
            search_asset_type: '',
            date_range: '',
            date_range_start: '',
            date_range_end: '',
            search_asset_category: 'Y',
            search_owner_id: '',
            search_ownership_category: '',

        };

        this.assetMttrMtbfSearchFormLabel = {
            site_group_ids: 'Site Groups : ',
            search_site_ids: 'Sites : ',
            'asset_group_ids': 'Asset Type Groups : ',
            search_asset_type_ids: 'Asset Type : ',
            search_asset_matrix_ids: 'Asset Matrix : ',
            search_manufacturer_ids: 'Manufacturer : ',
            search_models: 'Models : ',
            search_variant: 'Variants : ',
            search_asset_ids: 'Assets : ',
            search_asset_type: 'Asset Type : ',
            date_range: 'Date Range :  ',
            search_asset_category: 'Asset Category : ',
            search_owner_id: 'Owner Name : ',
            search_ownership_category: 'Ownership Category : ',
        }

        this.initCustomDownloadState = {
            asset_grouping: 'asset_type'
        }

        this.state = {
            showSaveAssetMttrMtbfBtn: false,
            showDownloadAssetMttrMtbfBtn: false,
            assetMttrMtbfDownloading: false,
            showSaveAssetMttrMtbfForm: false,
            listing_loading: false,
            listingData: [],
            listingMeta: null,
            iam_asset_types: [],
            iam_user_sites: [],
            iam_asset_matrix: [],
            iam_manufactures: [],
            iam_asset_variants: [],
            iam_models: [],
            searchedAsset: null,
            searchedAssets: [],
            assetMttrMtbfFormData: { periods: [], asset_category: [] },
            selectedPeriod: '',
            downloadReportBtn: false,
            reportDownloading: false,
            listing_tbl_page: 1,
            formSearchedElems: [],
            allOwnershipCategory: [],
            allOwnersList: [],
            allSiteGroupsList: [],
            customDownloadForm: { ...this.initCustomDownloadState },
            assetMttrMtbfFilterForm: { ...this.assetMttrMtbfFilterFormInitialState },
            submittedAssetMttrMtbfFilterForm: { ...this.assetMttrMtbfFilterFormInitialState },
            allAssetTypesGroupsList: [],
            reportData: null,
        };
        this.reportUrl = IAM_API_BASE_URL_2 + '/report/mttr_mtbf_report';

    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        this.assetMttrMtbfFormDataInit()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeFormFilter(nextProps);
        }
    }


    assetMttrMtbfFormDataInit = () => {
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, { action: "formdata" }, null, (response) => {
            this.setState({
                assetMttrMtbfFormData: response.data,
                formDataLoaded: true,
                allOwnershipCategory: response.data.ownership_category && response.data.ownership_category.length > 0 ? response.data.ownership_category.map(oc => { return ({ value: oc.key, label: oc.name }) }) : [],
                allOwnersList: response.data.owners && response.data.owners.length > 0 ? response.data.owners.map(oc => { return ({ value: oc.id, label: oc.name }) }) : [],

            });
            this.reportFilterhandler();
        });
    }

    reportFilterhandler = () => {
        new Collapse(document.getElementById('assetMttrMtbfFilterFormJsx'));
        if (document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    initilaizeFormFilter = (props) => {

        const report_name = 'asset_mttr_mtbf_report';
        const reportData = props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];



        this.setState({
            reportData: reportData.length > 0 ? reportData[0] : null,
            iam_asset_types: props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
            iam_user_sites: props.iam_user_sites ? props.iam_user_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
            iam_asset_matrix: props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return { value: am.id, label: am.asset_matrix_unique_id } }) : [],
            iam_manufactures: props.iam_manufactures ? props.iam_manufactures.map((m) => { return { value: m.id, label: m.manufacturer_name } }) : [],
            iam_asset_variants: props.iam_asset_variants ? props.iam_asset_variants.map((v) => { return { value: v, label: v } }) : [],
            iam_models: props.iam_models ? props.iam_models.map((m) => { return { value: m.id, label: m.model_name } }) : [],
            allAssetTypesGroupsList: props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
            allSiteGroupsList: props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
        });
    }

    reportMttrMtbfFilterFormSubmit = (event) => {
        event.preventDefault()
        this.reportFilterhandler();
        let serachFormKeys = Object.keys(this.state.assetMttrMtbfFilterForm);
        let searchedElems = [];
        let assetSearchParams = {};
        serachFormKeys.map((key) => {
            let label = this.assetMttrMtbfSearchFormLabel[key];
            let value = this.state.assetMttrMtbfFilterForm[key];

            if (value && value.toString().length > 0) {

                assetSearchParams[key] = value;
                if (label) {
                    let show_val = value;

                    if (key == 'site_group_ids') {
                        show_val = this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if (key == 'search_asset_ids') {
                        show_val = this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if (key == 'asset_group_ids') {
                        show_val = this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_asset_type_ids') {
                        show_val = this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_site_ids') {
                        show_val = this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_asset_category') {
                        show_val = this.state.assetMttrMtbfFormData.asset_category.filter((s) => value.includes(s.key)).map(s => s.name);
                    }
                    if (key == 'search_asset_matrix_ids') {
                        show_val = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_manufacturer_ids') {
                        show_val = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_models') {
                        show_val = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_owner_id') {
                        show_val = this.state.allOwnersList.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if (key == 'search_ownership_category') {
                        show_val = this.state.allOwnershipCategory.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'date_range') {
                        show_val = this.state.assetMttrMtbfFormData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });

        this.setState({
            submittedAssetMttrMtbfFilterForm: { ...this.state.assetMttrMtbfFilterForm },
            formSearchedElems: searchedElems,
            assetSearchParams: assetSearchParams
        }, () => {
            this.loadListingTblData(1);
        });
    }

    loadListingTblData = (page = 1) => {
        this.setState({
            listing_loading: true,
            listingData: [],
            listing_tbl_page: page,
            downloadReportBtn: false,
            reportDownloading: false
        });
        let submittedAssetMttrMtbfFilterForm = this.state.submittedAssetMttrMtbfFilterForm;
        delete submittedAssetMttrMtbfFilterForm.site_group_ids;

        let params = { page: page, ...submittedAssetMttrMtbfFilterForm };
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData = response.data;
            this.setState({
                listingData: respData.data,
                downloadReportBtn: true,
                listingMeta: {
                    from: respData.from,
                    to: respData.to,
                    total: respData.total,
                    last_page: respData.last_page,
                    per_page: respData.per_page,
                    current_page: respData.current_page
                }
            });
        }, (err) => {
            this.reportFilterhandler();
        }).then(() => {
            this.setState({ listing_loading: false });
        });
    }

    reportDownloadHandler = () => {
        this.setState({ reportDownloading: true });

        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {
            ...this.state.submittedAssetMttrMtbfFilterForm, action: "download",
        }, {}, (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({ reportDownloading: false }));
    }


    submitcustomDownloadForm = (e) => {
        e.preventDefault()
        let selectedColumn = [];
        localStorage.setItem('selectedColumnForAssetMatrix', selectedColumn);
        localStorage.setItem('selectedGroupingForAssetMatrix', this.state.customDownloadForm.asset_grouping);
        this.setState({ saveFormSubmitting: true });
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, { ...this.state.submittedAssetMttrMtbfFilterForm, ...this.state.customDownloadForm, selectedColumn, action: "download" }, {}, (response) =>
            DownloadFile.file(response.data.file_path)).then(() => {
                this.setState({ reportDownloading: false, saveFormSubmitting: false })

            });
    }

    closeReport = () => {
        this.props.history.goBack()
    }

    pageTitleBarJsx = () => {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCalculator} />
                    <span className='ps-2'>
                        {this.state.reportData?.name}
                    </span>
                </h3>
                <div className="text-end mt15">
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.state.downloadReportBtn ?
                        <button type="button" onClick={this.reportDownloadHandler} disabled={this.state.reportDownloading} className="btn btn-primary">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                            {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}
                        </button>
                        : null}
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }

    assetMttrMtbfFilterFormJsx = () => {
        let asset_category = this.state.assetMttrMtbfFormData && this.state.assetMttrMtbfFormData.asset_category ?
            this.state.assetMttrMtbfFormData.asset_category.map(ac => { return { value: ac.key, label: ac.name } }) : [];

        return (<div id="assetMttrMtbfFilterFormJsx" className="bg-white" >
            <form onSubmit={this.reportMttrMtbfFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    assetMttrMtbfFilterForm: {
                                        ...this.state.assetMttrMtbfFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.assetMttrMtbfFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.assetMttrMtbfFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    assetMttrMtbfFilterForm: {
                                        ...this.state.assetMttrMtbfFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.assetMttrMtbfFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.assetMttrMtbfFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_asset_matrix_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.assetMttrMtbfFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.assetMttrMtbfFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.assetMttrMtbfFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            search_asset_type={[this.state.assetMttrMtbfFilterForm.search_asset_type]}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Category</label>
                    <div className="col-sm-6">
                        <TapSelect
                            menuPlacement='top'
                            options={asset_category}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_asset_category');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            value={asset_category.find(r => r.value === this.state.assetMttrMtbfFilterForm.search_asset_category)}
                            placeholder="Select Asset Category"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Ownership</label>
                    <div className="col-sm-6">
                        <div className='row'>
                            <div className='col-sm-5'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnershipCategory}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_ownership_category');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnershipCategory.find(r => r.value === this.state.assetMttrMtbfFilterForm.search_ownership_category)}
                                    placeholder="Select Ownership Category"
                                />
                            </div>
                            <div className='col-sm-7'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnersList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetMttrMtbfFilterForm', 'search_owner_id');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnersList.find(r => r.value === this.state.assetMttrMtbfFilterForm.search_owner_id)}
                                    placeholder="Select Owner"
                                />
                            </div>
                        </div>


                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Date Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.assetMttrMtbfFormData && this.state.assetMttrMtbfFormData.periods ? this.state.assetMttrMtbfFormData.periods : []}
                            value={this.state.assetMttrMtbfFilterForm.date_range}
                            startDate={this.state.assetMttrMtbfFilterForm.date_range_start}
                            endDate={this.state.assetMttrMtbfFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    assetMttrMtbfFilterForm: { ...this.state.assetMttrMtbfFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate }
                                });
                            }}
                            required={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end ">
                        <div className='d-flex justify-content-end align-items-center gap-2'>
                            <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                        </div>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }

    assetMttrMtbfDisplayJsx = () => {
        return (<div className="bg-white">
            {this.state.listing_loading ? <Loader /> : null}
            {!this.state.listing_loading && this.state.downloadReportBtn == true
                ? this.assetMttrMtbfTableJsx() : null
            }
        </div>)
    }

    assetMttrMtbfTableJsx = () => {

        return (<Ax>
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th style={{ width: "15%" }}>Asset Name</th>
                        <th style={{ width: "11%" }}>Asset Code</th>
                        <th style={{ width: "10%" }}>Running HRS</th>
                        <th style={{ width: "10%" }}>Tickets</th>
                        <th style={{ width: "10%" }}>Repair Tickets</th>
                        <th style={{ width: "14%" }}>Total Downtime (Hr)</th>
                        <th style={{ width: "10%" }} className="text-center">MTTR (Hr)</th>
                        <th style={{ width: "10%" }} className="text-center">MTBF</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((data) => {
                            return (
                                <tr className='align-middle'>
                                    <td className={"fw-bold"}>{data.asset_name || "-"}</td>
                                    <td>{data.asset_code || "-"}</td>
                                    <td>{data.running_hrs || ""}</td>
                                    <td>{data.no_of_tickets || ""} </td>
                                    <td className="text-center">{data.repair_tickets || ""}</td>
                                    <td className="text-center">{data.total_downtime || ""}</td>
                                    <td className="text-center">{data.mttr || ""}</td>
                                    <td className="text-center">{data.mtbf || ""}</td>
                                </tr>
                            )
                        }))
                        : <tr><td className="text-center" colSpan={8}>No Record Found</td></tr>
                    }
                </tbody>
            </table>

        </Ax>)
    }


    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>Asset Mttr-Mtbf - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.assetMttrMtbfFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.assetMttrMtbfDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}


const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        iam_asset_types: state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites: state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix: state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures: state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_asset_variants: state.app && state.app.asset_variants ? state.app.asset_variants : [],
        iam_models: state.app && state.app.models ? state.app.models : [],
        asset_groups: state.app && state.app.asset_groups ? state.app.asset_groups : [],
        site_groups: state.app && state.app.site_groups ? state.app.site_groups : [],
        report_listing: state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(AssetMTTRMTBFReport);

