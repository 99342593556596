import {connect} from "react-redux";
import {Modal} from "bootstrap";
import swal from "sweetalert";
import axios from "axios";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import React from "react";
import { Link } from 'react-router-dom';
import Status from "../../../../components/ui/Status";
import { ISC_API_BASE_URL_2 } from "../../../../services/TapApiUrls";
import { DisplayListPagination } from "../../../../components/TapUi";

class ItemTags extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.itemTagUrl         =   ISC_API_BASE_URL_2 + "/tags";  
        this.tagStatusUrl       =   ISC_API_BASE_URL_2 + "/tag";   

        this.itemTagInitForm    =               {
            name                :                '',
            description         :               '',
        }
        this.state              =               {
           tagListing           :               [],
           listing_loading      :               false,
           editFormSubmiting    :               false,
           addItemTagForm       :               {...this.itemTagInitForm},
           listingMeta          :               null,
           totalListingCount    :               0
        }
      
    }

    componentDidMount() {
        this.getTagListing()
        this.addItemTagsInit        =        new Modal(document.getElementById('addItemTagsModal'), {keyboard: false, backdrop :false});
    }

    getTagListing                  =            (page=1)             =>          {
        
        this.setState({listing_loading : true})
        HttpAPICall.withAthorization('GET', this.itemTagUrl, this.props.access_token , {page:page}, {}, (resp) => {
            this.setState({
                tagListing  : resp.data.data,
                listingMeta      :   {
                    from         :  resp.data.meta.from,
                    to           :  resp.data.meta.to,
                    total        :  resp.data.meta.total,
                    last_page    : resp.data.meta.last_page,
                    current_page : resp.data.meta.current_page
                },
               totalListingCount :  resp.data.meta.total
            })
        }).then(() => this.setState({listing_loading: false}));
    }

    addItemTagsModalInit            =          (id)            =>          {
        this.addItemTagsInit.show()
        if(id !== null && id !== undefined){
            this.setState({edit : true})
            let itemTaglist = this.state.tagListing.find(function (tag) {
                return tag.id == id;
            });
            this.setState({
                addItemTagForm :   {
                    name          :    itemTaglist.name,
                    id            :    itemTaglist.id,
                    description   :  itemTaglist.description
                }
            })
        }else{
            this.setState({edit : false})
        }
    }

    closeTagForm                   =            ()             =>          {
        this.setState({addItemTagForm : this.itemTagInitForm})
    }


    addItemTagsModalJsx            =            ()             =>          {
        return (
            <div className="modal fade" id="addItemTagsModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.edit ? "Edit" : "Add"} Item Tag</h5>
                        </div>
                        <form onSubmit={this.submitItemTagForm}>
                            <div className="modal-body p-3">
                                <div className="row align-items-center my-3">
                                    <div className="col-md-3"><label className="form-label">Item Tag Name </label></div>
                                    <div className="col-md-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addItemTagForm.name}
                                            onChange={(e) => this.formInputHandler(e, 'addItemTagForm')}
                                            className="form-control"
                                            autoComplete="off"
                                        />
                                    </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                    <div className="col-md-3"><label className="form-label">Description </label></div>
                                    <div className="col-md-9">
                                        <textarea
                                            name="description"
                                            style={{height:"80px"}}
                                            value={this.state.addItemTagForm.description}
                                            onChange={(e) => this.formInputHandler(e, 'addItemTagForm')}
                                            className="form-control"
                                            autoComplete="off"
                                        />
                                    </div>
                                 </div>
                             </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting}  data-bs-dismiss="modal" onClick={this.closeTagForm}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }    

    submitItemTagForm              =            (e)            =>          {
        e.preventDefault()
        this.setState({editFormSubmiting : true});
        if(this.state.edit === true){
            HttpAPICall.withAthorization('PUT', this.itemTagUrl, this.props.access_token, {} , {...this.state.addItemTagForm}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addItemTagsInit.hide();
                this.getTagListing();
                this.setState({addItemTagForm : this.itemTagInitForm})
            }).then(() => this.setState({editFormSubmiting: false}))
        }else{
            HttpAPICall.withAthorization('POST', this.itemTagUrl, this.props.access_token, {} , {...this.state.addItemTagForm}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addItemTagsInit.hide();
                this.getTagListing();
                this.setState({addItemTagForm : this.itemTagInitForm,edit:false})
            }).then(() => this.setState({editFormSubmiting: false}))
        }
    }

    changeItemTagStatus            =         (id,status)       =>          {
        swal({
            title: "Change Status",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    let statusNew;
                    if (status == 0 || status == 2) {
                        statusNew = 1;
                    } else if (status == 1) {
                        statusNew = 2;
                    }
                    let frmData = {
                        status: statusNew,
                    };
                    HttpAPICall.withAthorization('PUT', this.tagStatusUrl + '/' + id, this.props.access_token, {...frmData},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                       this.getTagListing()
                    }).then(() => {
                        
                    });
                }
            });
    }

    deleteItemTag                  =           (id)            =>          {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    axios({
                        method: 'DELETE',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
                        url: this.tagStatusUrl + '/' + id
                    }).then((response) => {
                       
                        this.getTagListing()
                        setTimeout(() => {toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});},1)
                    }).catch((error) => {
                        if(error.response?.data?.status_code == 422){
                            swal({
                                title: "Delete",
                                text: "This Ticket Code is already linked with tickets,Do you still want to Delete It?",
                                icon: "warning",
                                // dangerMode: true,
                                buttons: ["No", "Yes"],
                            }).then(forceDelete => {
                                    if (forceDelete) {
                                        HttpAPICall.withAthorization('DELETE', this.tagStatusUrl + '/' + id, this.props.access_token, {force : "Y"},{} ,(response) => {
                                            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                                            this.getTagListing()
                                        }).then(() => {});
                                    } });}
                    }).then(() => {  });  }
                });
    }


    render                         =            ()             =>          {
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">Item Tags</span>
            <div className="text-end "><button className="btn btn-primary" disabled={!this.props.permissions.includes('isc-item-tag-add')} onClick={() => {this.addItemTagsModalInit()}}>Add</button></div>
            </div>
            <table className="table table-hover table-bordered my-4 ">
              <thead className="table-secondary">
                  <tr className="text-center">
                      <th scope="col" style={{ width: "5%" }}>S.No</th>
                      <th scope="col" className="text-start">Item Tags</th>
                      <th scope="col" style={{ width: "35%" }} className="text-start" >Description</th>
                      <th scope="col" style={{ width: "15%" }} >Status</th>
                      <th scope="col" style={{ width: "10%" }} >Action</th>
                      
                  </tr>
              </thead>
              <tbody>
              { this.state.listing_loading
                ? (<tr><td colSpan="5"><Loader/></td></tr>)
                : (this.state.tagListing.length > 0
                        ? (this.state.tagListing.map((item , k) => {
                            return (<tr key={k} className="text-center">
                                <td>{k+1}</td>
                                <td className="text-capitalize text-start">{item.name}</td>
                                <td className="text-start">{item.description !== null ?  item.description  : "-"}</td>
                                <td><Status color={item.status_color}>{item.status_text}</Status></td>
                                <td>  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                    role="button" aria-expanded="false" />
                                    <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                        <li>
                                            <Link role="button"
                                                onClick={() => {
                                                    if (this.props?.permissions?.includes('isc-item-tag-edit')) {
                                                        return this.addItemTagsModalInit(item.id)
                                                    }
                                                }}
                                                className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-item-tag-edit') ? '' : 'disabled'].join(' ')}
                                                title={!this.props?.permissions?.includes('isc-item-tag-edit') ? 'You do not have permission to perform this action' : ''}
                                                style={{ "pointerEvents": "all" }}
                                            >Edit</Link>
                                        </li>
                                        <li>
                                            <Link role="button"
                                                onClick={() => {
                                                    if (this.props?.permissions?.includes('isc-item-tag-deactivate')) {
                                                        return this.changeItemTagStatus(item.id, item.status)
                                                    }
                                                }}
                                                className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-item-tag-deactivate') ? '' : 'disabled'].join(' ')}
                                                title={!this.props?.permissions?.includes('isc-item-tag-deactivate') ? 'You do not have permission to perform this action' : ''}
                                                style={{ "pointerEvents": "all" }}
                                            >{item.status == 1 ? "Deactivate" : "Activate"}</Link>
                                        </li>
                                        <li>
                                            <Link role="button"
                                                onClick={() => {
                                                    if (this.props?.permissions?.includes('isc-item-tag-delete')) {
                                                        return this.deleteItemTag(item.id)
                                                    }
                                                }}
                                                className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-item-tag-delete') ? '' : 'disabled'].join(' ')}
                                                title={!this.props?.permissions?.includes('isc-item-tag-delete') ? 'You do not have permission to perform this action' : ''}
                                                style={{ "pointerEvents": "all" }}
                                            >Delete</Link>
                                        </li>

                                    </ul>
                                </td>
                            </tr>);
                        }))
                        : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                )
            }
              </tbody>
          </table>
          <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.getTagListing(e.selected + 1)}
            />
           {this.addItemTagsModalJsx()} 
        </Ax>);
    }

}


const mapStateToProps           =        state              =>           {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(ItemTags);