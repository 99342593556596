import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link , useHistory} from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import uuid from 'react-uuid';
import { Modal } from 'bootstrap';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import PageHeader from '../../includes/PageHeader';
import TapIcon from '../../../services/TapIcon';
import { HRM_API_BASE_URL_2, INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import Loader from '../../../components/ui/Loader/Loader';
import TapHelper from '../../../services/TapHelper';
import DateService from '../../../services/DateService';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import TapSelect from '../../../components/ui/TapSelect';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import SalesInvoiceDetail from './includes/SalesInvoiceDetail';
import DataService from '../../../services/DataService';
import download from '../../../services/DownloadFile';

const SalesInvoiceList                  =   forwardRef((props, ref) => {

    let initListData                        =   {
        loading                                 :   false,
        minimizeTable                           :   false,
        items                                   :   [],
        sites                                   :   [],
        meta                                    :   null,
        page                                    :   1
    };
    let initVoucherData                     =   {
        transaction_id                          :   '',
        loading                                 :   false,
        data                                    :   null
    };
    let initCommentFormData                 =   {
        loading                                 :   false,
        submitting                              :   false,
        transaction_id                          :   '',
        status_list                             :   [],
        status_id                               :   '',
        notes                                   :   ''
    };
    let initFormData                        =   {
        per_page                                :   50,
        data_loaded                             :   false,
        loading                                 :   false,
        downloading                             :   false,
        submitting                              :   false,
        item_tags                               :   [],
        search_item_tags                        :   [],
        status_list                             :   [],
        search_status_ids                       :   [],
        warehouses                              :   [],
        search_warehouse_ids                    :   [],
        periods                                 :   [],
        date_range                              :   null,
        date_range_start                        :   null,
        date_range_end                          :   null,
        voucher_date_range                      :   null,
        voucher_date_range_start                :   null,
        voucher_date_range_end                  :   null,
        searched_customers                      :   [],
        search_customer_ids                     :   [],
        searched_items                          :   [],
        search_item_ids                         :   [],
        search_like_transaction_id              :   '',
        search_so_transaction_id                :   '',
        search_voucher_number                   :   '',
    };

    let formDataLabel                       =   {
        search_item_tags                        :   'Item Tags : ',
        search_status_ids                       :   'Search Status : ',
        search_warehouse_ids                    :   'Warehouses : ',
        date_range                              :   'Date Range : ',
        voucher_date_range                      :   'Voucher Date Range : ',
        search_customer_ids                     :   'Customers ',
        search_item_ids                         :   'Items : ',
        search_like_transaction_id              :   'Transaction ID : ',
        search_so_transaction_id                :   'Sales Order Transaction ID',
        search_voucher_number                   :   'Voucher Number :',
    };
    let itemListUrl                         =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/list";
    let itemListFormDataUrl                 =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/list_form_data";
    let voucherDetailUrl                    =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/datail";
    let voucherDownloadUrl                  =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/download";
    let siCommentAddUrl                     =   INVENTORY_API_BASE_URL_2 + "/sales_invoice/comment_add";

    
    const dispatch                          =   useDispatch();
    const access_token                      =   useSelector(state => state.auth.access_token);
    const default_currency                  =   useSelector(state => state.app.default_currency || 'INR');
    const permissions                       =   useSelector(state => state.app.acl_info && state.app.acl_info.permissions ? state.app.acl_info.permissions : []);
    const all_periods                       =   useSelector(state => state.app.all_periods ? state.app.all_periods : []);
    const all_warehouses                    =   useSelector(state => state.app.warehouses ? state.app.warehouses : []);
    const all_item_tags                     =   useSelector(state => state.app.item_tags ? state.app.item_tags : []);


    const [itemsData, setItemsData]         =   useState({...initListData});
    const [voucherData, setVoucherData]     =   useState({...initVoucherData});
    const [formData, setFormData]           =   useState({...initFormData});
    const [searchedElems, setSearchedElems] =   useState([]);
    const [submittedFormData, setSubmittedFormData] =   useState({...initFormData});
    const [commentFormData, setCommentFormData]     =   useState({...initCommentFormData});


    const [searchModalId, setSearchModalId] =   useState(uuid());
    const searchModalElem                   =   useRef(null);
    const searchModal                       =   useRef(null);
    const searchModalOpen                   =   ()  => searchModal.current && searchModal.current.show()
    const searchModalClose                  =   ()  => searchModal.current && searchModal.current.hide()

    useEffect(() => {
        searchModalElem.current             =   document.getElementById(searchModalId);
        searchModal.current                 =   new Modal(searchModalElem.current, {keyboard: false, backdrop :false});

        //Initializing Item Listing :-
        loadItemsData(props.location.state && props.location.state.page_number || 1);

        if(props.location && props.location.state && props.location.state.voucherData) {
            let voucherData                 =   props.location.state.voucherData;
            setVoucherDetailStateData(voucherData, voucherData.transaction_id);
        }
        //Cleanup on unmount
        return () => {
            if (searchModal.current) {
                searchModal.current.dispose();
            }
        };
    }, []);

    const loadItemsData                     =   (page = 1)  =>  {
        setItemsData(pd => ({...pd, loading : true, page : page }));
        let params                      =   {
                                                page : page,
                                                ...formData
                                            };
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, params, {}, (response) => {
            let respData                =   response.data;
            let meta                    =   TapHelper.pick(respData, ['from', 'to', 'total', 'last_page','per_page','current_page']);
            setItemsData(pd => ({...pd, items : respData.data, meta : meta, sites : response.data.sites }));
        }).then(() => {
            setItemsData(pd => ({...pd, loading : false}));
        });
    }

    const listSearchModalInit           =   ()  =>  {
        searchModalOpen();
        if(!formData.data_loaded) {
            loadFormData();
        }
    }

    const loadFormData                  =   ()  =>  {
        setFormData(pd => ({...pd, data_loaded : false,  loading : true }));
        HttpAPICall.withAthorization('GET', itemListFormDataUrl, access_token, {}, {}, (response) => {
            let respData                =   response.data;
            let status_list             =   respData && respData.status && respData.status.length > 0 ?  respData.status.map((s) => { return {value: s.id, label: s.name}; }) : [];
            let periods                 =   [...all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            let warehouses              =   all_warehouses ? all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [];
            let item_tags               =   all_item_tags ?  all_item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [];

            setFormData(pd => ({...pd, status_list : status_list, periods : periods, warehouses : warehouses, item_tags : item_tags }));

        }).then(() => {
            setFormData(pd => ({...pd, data_loaded : true,  loading : false }));
        });
    }

    const submitSearchFormHandler       =   (e)  => {
        e.preventDefault();
        let searchedElems               =   [];
        let searchParams                =   {};
        Object.keys(formData).map((key)        =>  {
            let label                       =   formDataLabel[key];
            let value                       =   formData[key];
            if(value && value.length > 0) {
                searchParams[key]      =   value;
                if (label) {
                    let show_val = value;
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        setSubmittedFormData(pd => ({...pd, ...formData}));
        setSearchedElems(searchedElems);

        //Close The Modal
        searchModalClose();
        //Initializing Item Listing :-
        loadItemsData(1);
    }

    const searchFormClear               =   ()  =>  {
        setSearchedElems([]);
        setFormData(pd => ({...initFormData}));
        setSubmittedFormData(pd => ({...initFormData}));
        //Initializing Item Listing :-
        loadItemsData(1);
    }

    const viewVoucherDetail             =   (transaction_id)  =>  {
        setItemsData(pd => ({...pd, minimizeTable: true}));
        setVoucherData(pd => ({...pd, transaction_id: transaction_id}));
        
        //Initializing Voucher Detail :-
        initVoucherDetail(transaction_id);
    }

    const handlerVoucherAdd             =   () => {
        props.history.push({pathname: "/sales_invoice_add", state : {listing_page_number : itemsData.page}  });
    }

    const handlerVoucherEdit            =   ()  =>  {
        if(voucherData.transaction_id && voucherData.data) {
            let state_data              =   {listing_page_number : itemsData.page, editVoucherData : voucherData.data};
            props.history.push({pathname: "/sales_invoice_add", state : state_data  });
        }
    }

    const handlerVoucherDownload        =   (transaction_id)  =>  {
        let params                      =   {transaction_id : transaction_id };
        setVoucherData((pd) => ({...pd, downloading : true}));
        HttpAPICall.withAthorization('GET', voucherDownloadUrl, access_token, params, {}, (response) => {
            download.file(response.data.file_path);
        }).then(() => {
            setVoucherData((pd) => ({...pd, downloading : false}));
        });
    }


    const initVoucherDetail             =   (transaction_id)  =>  {
        setVoucherData(pd => ({...pd, loading : true }));
        setCommentFormData(pd => ({...initCommentFormData, loading : true }));
        let params                      =   {transaction_id : transaction_id, need_comments : 'Y' };
        HttpAPICall.withAthorization('GET', voucherDetailUrl, access_token, params, {}, (response) => {
            let respData                =   response.data;
            setVoucherDetailStateData(respData, transaction_id);
        }).then(() => {
            setVoucherData(pd => ({...pd, loading : false}));
            setCommentFormData(pd => ({...pd, loading : false }));
        });
    }

    const setVoucherDetailStateData     =   (voucherData, transaction_id)  =>  {
        setVoucherData(pd => ({...pd, data : voucherData }));
        setCommentFormData(pd => ({...pd,
            status_list         :   voucherData.change_status_list && voucherData.change_status_list.length > 0 
                                        ? voucherData.change_status_list.map((s) => { return {value: s.id, label: s.name}; })
                                        : [],
            status_id           :   voucherData.status_id,
            transaction_id      :   transaction_id
        }));
    }

    const closeVoucherDetail            =   ()  =>  {
        setVoucherData(pd => ({...pd, transaction_id: null}));
        setItemsData(pd => ({...pd, minimizeTable: false}));
    }

    const searchModalJsx                =   () => {
        return <><div className="modal fade" id={searchModalId} tabIndex="-1" ref={searchModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Sales Invoice Search</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={itemsData.loading} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {formData.loading ? <Loader /> : <form name='' id={searchModalId + 'Form'} onSubmit={submitSearchFormHandler}>
                            <div className="row mb-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Transaction ID</label>
                                <div className="col-sm-4">
                                    <input
                                        type="text"
                                        value={formData.search_like_transaction_id}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={e => setFormData(d => ({...d, search_like_transaction_id : e.target.value}))}
                                        placeholder="Search by Transaction ID"
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>
                                <label className="col-sm-2 col-form-label col-form-label-sm">Voucher Number</label>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        value={formData.search_voucher_number}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={e => setFormData(d => ({...d, search_voucher_number : e.target.value}))}
                                        placeholder="Search By Voucher Number"
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Sales Order Transaction ID</label>
                                <div className="col-sm-4">
                                    <input
                                        type="text"
                                        value={formData.search_so_transaction_id}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={e => setFormData(d => ({...d, search_so_transaction_id : e.target.value}))}
                                        placeholder="Search by Sales Order Transaction ID"
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Status</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        changeEvent={o => setFormData(d => ({...d, search_status_ids : o.map(e => e.value)}))}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        isClearable={true}
                                        options={formData.status_list}
                                        value={formData.status_list.filter(s => formData.search_status_ids.includes(s.value))}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Warehouse</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        changeEvent={o => setFormData(d => ({...d, search_warehouse_ids : o.map(e => e.value)}))}
                                        placeholder="Select Warehouses"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        isClearable={true}
                                        options={formData.warehouses}
                                        value={formData.warehouses.filter(s => formData.search_warehouse_ids.includes(s.value))}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Customer</label>
                                <div className="col-sm-9">
                                    <InputCustomerSearch
                                        changeEvent={(so) => {
                                            setFormData(d => ({...d, 
                                                search_customer_ids : so.map(e => e.value),
                                                searched_customers : so && so.length > 0 ? so : [] 
                                            }))
                                        }}
                                        isMulti={true}
                                        value={formData.searched_customers ? formData.searched_customers.map(a => { a.label = a.display_label; return a; }) : []}
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Item Tags</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        changeEvent={o => setFormData(d => ({...d, search_item_tags : o.map(e => e.value)}))}
                                        placeholder="Select Item Tags"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        isClearable={true}
                                        options={formData.item_tags}
                                        value={formData.item_tags.filter(s => formData.search_item_tags.includes(s.value))}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Item</label>
                                <div className="col-sm-9">
                                    <InputItemSearch
                                        changeEvent={(so) => {
                                            setFormData(d => ({...d, 
                                                search_item_ids : so.map(e => e.value),
                                                searched_items : so && so.length > 0 ? so : [] 
                                            }))
                                        }}
                                        isMulti={true}
                                        value={formData.searched_items ? formData.searched_items.map(a => { a.label = a.display_label; return a; }) : []}
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Voucher Period</label>
                                <div className="col-sm-9">
                                    <PeriodSelectionWithCustom
                                        periods={formData.periods ? formData.periods : []}
                                        className="form-control-sm"
                                        containerHeight="33px"
                                        fontSize="93%"
                                        onSelectPeriod={(period, startDate, endDate) => {
                                            setFormData(d => ({...d, 
                                                voucher_date_range : period, 
                                                voucher_date_range_start :startDate ? startDate : null,
                                                voucher_date_range_end: endDate ? endDate : null  
                                            }))
                                        }}
                                        value={formData.voucher_date_range}
                                        startDate={formData.voucher_date_range_start}
                                        endDate={formData.voucher_date_range_end}
                                    />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Transaction Period</label>
                                <div className="col-sm-9">
                                    <PeriodSelectionWithCustom
                                        periods={formData.periods ? formData.periods : []}
                                        className="form-control-sm"
                                        containerHeight="33px"
                                        fontSize="93%"
                                        onSelectPeriod={(period, startDate, endDate) => {
                                            setFormData(d => ({...d, 
                                                date_range : period, 
                                                date_range_start :startDate ? startDate : null,
                                                date_range_end: endDate ? endDate : null  
                                            }))
                                        }}
                                        value={formData.date_range}
                                        startDate={formData.date_range_start}
                                        endDate={formData.date_range_end}
                                    />
                                </div>
                            </div>
                        </form>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button form={searchModalId + 'Form'} type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    }

    const submitUpdateCommentForm       =   (e)  =>  {
        e.preventDefault();
        let data                                =   {...TapHelper.pick(commentFormData, ['transaction_id','status_id','notes'])};
        setCommentFormData(d => ({...d, submitting : true}));
        HttpAPICall.withAthorization('PUT', siCommentAddUrl, access_token, {}, data, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            let respData                        =   response.data;
            viewVoucherDetail(respData.transaction_id);
        }).then(() => {
            setCommentFormData(d => ({...d, submitting : false}));
        });
    }

    const voucherCommentFormJsx         =   ()  =>  {
        return <>
            <div className="tab_content_wrapper"><span className="content_heading">Comments & History</span></div>
            <form onSubmit={submitUpdateCommentForm}>
                <div className="my-3 row">
                    <label className="col-sm-3 col-form-label">Change status to</label>
                    <div className="col-sm-5">
                        <TapSelect
                            changeEvent={o => DataService.handleFormState('status_id', o ? o.value : null,commentFormData, setCommentFormData)}
                            placeholder="Select Status"
                            options={commentFormData.status_list}
                            value={commentFormData.status_list.find(d => commentFormData.status_id == d.value)}
                        />
                    </div>
                </div>
                <div className="my-2">
                    <label className="form-label">Comments</label>
                    <textarea
                        required
                        value={commentFormData.notes}
                        onChange={e => DataService.handleFormState('notes', e.target.value,commentFormData, setCommentFormData)}
                        className="form-control"
                        placeholder="Please Add a comment"
                        style={{ height: "100px" }}
                        minLength={10}
                    />
                </div>
                <div className="col-12 text-end">
                    <button type="submit" disabled={commentFormData.submitting || commentFormData.loading} className="btn btn-primary mx-2">
                        Update {commentFormData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}
                    </button>
                </div>
            </form>
        </>
    }

    const voucherDetailJsx              =   ()  =>  {
       return voucherData.loading 
        ? <div className='text-center'><Loader /></div>
        : <>
            <SalesInvoiceDetail voucher={voucherData.data} transaction_id={voucherData.transaction_id}></SalesInvoiceDetail>
            {voucherCommentFormJsx()}
            {voucherData.data && voucherData.data.comments && voucherData.data.comments.length > 0 &&  <> <hr />
                { voucherData.data.comments.map((c, k) => {
                    return (<div key={k}><div className="row">
                            {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg.split("|").join("<br />") }} /> : <div className='col-sm-8'>-</div>}
                                <div className="col-sm-4 text-end">
                                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} style={{marginRight : "5px"}} className="img-fluid pr-5 fs12 " alt="item-action" />
                                    <span>{DateService.dateTimeFormat(c.created_at)}</span>
                                </div>
                            <div className="col-sm-12 my-1 text-sm">{c.notes}</div>
                        </div><hr /></div>);
                    })
                }
                </>
            }
        </>
    }
   
    const voucherListJsx                =   ()  =>  {
        return <>
            <DisplaySearchCriteria searchedElems={searchedElems} onClearFilter={searchFormClear} />
            <table className="table table-sm table-bordered align-middle bg-white">
                <thead className="align-middle table-secondary">
                    {itemsData.minimizeTable ? <tr className='text-center'>
                        <th scope="col">Sales Invoice</th>
                    </tr>: 
                    <tr className='text-center'>
                        <th style={{ width: "5%" }}>S.No</th>
                        <th style={{ width: "10%" }}>Transaction ID</th>
                        <th style={{ width: "10%" }}>Date</th>
                        <th style={{ width: "10%" }}>Warehouse</th>
                        <th style={{ width: "10%" }}>Customer</th>
                        <th style={{ width: "10%" }}>Total Quantity</th>
                        <th style={{ width: "11%" }}>Amount</th>
                        <th style={{ width: "11%" }}>Status</th>
                        <th style={{ width: "6%" }}>Action</th>
                    </tr>}
                </thead>
                <tbody>
                {itemsData.loading 
                    ? <tr><td className='text-center' colSpan={9}><Loader /></td></tr>
                    : ((itemsData.items && itemsData.items.length > 0) 
                        ? itemsData.items.map((item,index) => {
                            return <tr className={['text-center', voucherData.transaction_id == item.transaction_id ? 'bg-light' : ''].join(' ')} key={index}>
                                {itemsData.minimizeTable ? <>
                                    <td className='text-start'>
                                        <Link onClick={() => viewVoucherDetail(item.transaction_id)} className="text-decoration-none" role="button">{item.transaction_id}</Link>
                                        <div className='float-end text-xs'>{DateService.dateFormat(item.transaction_date)}</div>
                                        <div className="mt-1">
                                            <span className="text-sm text-capitalize">{item.customer?.name ?? "-"  }</span>
                                            <span className="float-end text-sm">{item.status_data ? item.status_data.name : "-"}</span>
                                        </div>
                                    </td>
                                </> : <>
                                    <td className='text-center'>{itemsData.meta ? itemsData.meta.from + index : index}</td>
                                    <td><button onClick={() => viewVoucherDetail(item.transaction_id)} className="btn btn-link text-decoration-none" role="button">{item.transaction_id}</button></td>
                                    <td>{item.transaction_date && DateService.dateFormat(item.transaction_date)}</td>
                                    <td>{item.warehouse?.name ?? "-"  }</td>
                                    <td>{item.customer?.name ?? "-"  }</td>
                                    <td className='text-end'>{item.total_qty ? item.total_qty : "0"  }</td>
                                    <td className='text-end'>{item?.total_amount ? <CurrencyFormatter currencyCode={default_currency} amount={parseFloat(item?.total_amount).toFixed(2)} /> : 0}</td>
                                    <td className='text-center'>{item.status_data ? item.status_data.name : "-"}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn"><TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} /></button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" role="button" onClick={() => viewVoucherDetail(item.transaction_id)}>View</Link></li>
                                        </ul>
                                    </td>
                                </>}
                            </tr>
                        })
                        : <tr><td className='text-center' colSpan={9}>No Record</td></tr>  )
                }
                </tbody>
            </table>
            <DisplayListPagination meta={itemsData.meta} onPageChange={(e) => loadItemsData(e.selected + 1)} />
        </>
    }
    
    const topRightHeaderJsx             =   ()  =>  {
        return <>
            {!itemsData.minimizeTable && <button type="button" className="btn btn-primary"  disabled={itemsData.loading || !permissions.includes('scrm-sales-invoice-add')} onClick={handlerVoucherAdd}>New Sales Invoice</button>}
            <button type="button" className="btn btn-secondary" disabled={itemsData.loading} onClick={listSearchModalInit} >
                <TapIcon.imageIcon icon={TapIcon.SearchIcon} />
            </button>
        </>
    }

    const pageHeading                   =   () => {
        return itemsData.minimizeTable 
        ? <>
            <Helmet><title>Sales Invoice Detail</title></Helmet>
            <div className="page_title m0 row align-middle">
                <div className='col-sm-12'>
                    <div className='row '>
                        <div className='col-sm-3'>
                            <h3 className='mb-0'>Sales Invoice</h3>
                            <div className='text-end d-flex align-items-center justify-content-end mt15'>
                                {topRightHeaderJsx()}
                            </div>
                        </div>
                        <div className='col-sm-9'>
                            <h3 className='mb-0'>Sales Invoice : {voucherData.transaction_id}</h3>
                            <div className='text-end d-flex align-items-center justify-content-end mt15'>
                                <button disabled={voucherData.loading || voucherData.downloading} type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    {voucherData.downloading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {/* <li className='dropdown-item py-1'>
                                        <button disabled={!permissions.includes('scrm-sales-invoice-edit')} className="btn btn-link text-start text-decoration-none w-100 text-dark mx-0" onClick={() => handlerVoucherEdit()}>Edit</button>
                                    </li> */}
                                    <li className='dropdown-item py-1'>
                                        <button className="btn btn-link text-start text-decoration-none w-100 text-dark mx-0" onClick={() => handlerVoucherDownload(voucherData.transaction_id)}>Download</button>
                                    </li>
                                </ul>
                                <button type="button" className="btn btn-secondary" disabled={voucherData.loading} onClick={closeVoucherDetail} ><TapIcon.imageIcon icon={TapIcon.CloseIcon} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        :  <PageHeader title="Sales Invoice - List" name="Sales Invoice" rightTopHeadingJsx={topRightHeaderJsx()} />
    }

    return  <ApplicationLayout>
        {pageHeading()}
        <div className="container-fluid pl5">
            <div className='page_containt row'>
                <div className={['pageTbl', itemsData.minimizeTable ? 'col-sm-3' : 'col-sm-12'].join(' ')} >{voucherListJsx()}</div>
                {itemsData.minimizeTable && <div className="tap_border_left col-sm-9 p-2 pageTbl bg-white" >{voucherDetailJsx()}</div>}
            </div>
        </div>
        {searchModalJsx()}
    </ApplicationLayout>

});

export default SalesInvoiceList;